import { SocialLinkItem } from './social-link-item/social-link-item.component';
import { Container } from './social-links.styled';

type SocialLinksListProps = {
  socialLinks: SocialLinkData[],
  trackSocialLinkClick?: (socialChannel: string) => void,
}

export const SocialLinksList = ({
  socialLinks,
  trackSocialLinkClick,
}:SocialLinksListProps): JSX.Element => {
  return (
    <Container>
      {socialLinks.map((socialLink) => (
        <SocialLinkItem
          key={ socialLink.url }
          socialLink={ socialLink }
          trackSocialLinkClick={ trackSocialLinkClick }
        />
      ))}
    </Container>
  );
};

SocialLinksList.defaultProps = {
  trackSocialLinkClick: undefined,
};
