import styled from 'styled-components';
import { THEME } from '../../../../../../../themes';
import {
  ButtonFont,
  CaptionFont,
} from '../../../../../../../themes/fonts';

export const  Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Name = styled.div`
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  ${ButtonFont};
  line-height: 1.2;
  letter-spacing: 0.1px;
  text-transform: capitalize;
  font-weight: bold;
`;
export const Description = styled.div`
  opacity: ${THEME.OPACITY.MED};
  ${CaptionFont};
  color:${THEME.COLORS.CONTENT_PRIMARY};
`;

export const WidgetContainer = styled.div`
  width: 100%;
  height: 100%;
`;
