import { useCallback, useMemo } from 'react';
import {
  PositionStatisticsResponse,
} from '../../../../store/rtk-query/apis/position/dto/response/position-statistics.response';
import { MATCH_STATUSES } from '../../../../consts';
import {
  FUNNEL_GRAPH_OPTIMAL_LIKED_PEOPLE, FUNNEL_GRAPH_OPTIMAL_REPLIED_PEOPLE,
  FUNNEL_GRAPH_OPTIMAL_REVIEWED_PEOPLE,
  FUNNEL_GRAPH_START_POSITION_MAX, FUNNEL_GRAPH_START_POSITION_MIN,
} from './position-performance-funnel.consts';

export type UsePositionPerformance = {
  reviewedMatchesCount: number,
  toReviewMatchesCount: number,
  engagedMatchesCount: number,
  engagedMatchesRatio: number,
  contactedMatchesRatio: number,
  repliedConversationsRatio: number,
  peopleMatchesOptimalLevel: number,
  peopleMatchesActualLevel: number,
  peopleLikedLevel: number,
  peopleLikedOptimalLevel: number,
  peopleContactedLevel: number,
  peopleContactedOptimalLevel: number,
  peopleRepliedLevel: number,
  peopleRepliedOptimalLevel: number,
  matchesTalentsCompaniesCardinality: number,
  followupsMessagesCount: number,
  messagesOpenRate: number,
  messagesReplyRate: number;
  sentMessagesCount: number,
};

export const usePositionPerformance = (positionStatistics: PositionStatisticsResponse): UsePositionPerformance => {
  const {
    reviewedMatchesCount,
    toReviewMatchesCount,
    engagedMatchesCount,
    engagedMatchesRatio,
    contactedMatchesRatio,
    repliedConversationsRatio,
  } = useMemo(() => {
    const toReviewMatches = positionStatistics.engagementStatusStatistics.find((statistics) => statistics.name === MATCH_STATUSES.TO_REVIEW)?.amount || 0;
    const engagedMatches = positionStatistics.engagementStatusStatistics.find((statistics) => statistics.name === MATCH_STATUSES.ENGAGED)?.amount || 0;
    const declinedMatches = positionStatistics.engagementStatusStatistics.find((statistics) => statistics.name === MATCH_STATUSES.DECLINED)?.amount || 0;
    const reviewedMatches = engagedMatches + declinedMatches;

    return {
      reviewedMatchesCount: reviewedMatches,
      toReviewMatchesCount: toReviewMatches,
      engagedMatchesCount: engagedMatches,
      engagedMatchesRatio: engagedMatches / reviewedMatches,
      contactedMatchesRatio: positionStatistics.contactedMatches / reviewedMatches,
      repliedConversationsRatio: positionStatistics.repliedConversations / reviewedMatches,
    };
  }, [positionStatistics]);

  const createGraphPoint = useCallback((value: number, startPosition = FUNNEL_GRAPH_START_POSITION_MAX) => {
    if (!reviewedMatchesCount) {
      return 1;
    }

    return 1 - (value * (1 - startPosition));
  }, [reviewedMatchesCount]);

  const {
    peopleMatchesOptimalLevel,
    peopleMatchesActualLevel,
    peopleLikedLevel,
    peopleLikedOptimalLevel,
    peopleContactedLevel,
    peopleContactedOptimalLevel,
    peopleRepliedLevel,
    peopleRepliedOptimalLevel,
  } = useMemo(() => {
    const peopleMatchesOptimal = FUNNEL_GRAPH_OPTIMAL_REVIEWED_PEOPLE > reviewedMatchesCount ?
      FUNNEL_GRAPH_START_POSITION_MAX : FUNNEL_GRAPH_START_POSITION_MIN;
    const peopleMatchesLevel = FUNNEL_GRAPH_OPTIMAL_REVIEWED_PEOPLE > reviewedMatchesCount ?
      FUNNEL_GRAPH_START_POSITION_MIN : FUNNEL_GRAPH_START_POSITION_MAX;

    const likedOptimal = FUNNEL_GRAPH_OPTIMAL_REVIEWED_PEOPLE > reviewedMatchesCount ?
      createGraphPoint(FUNNEL_GRAPH_OPTIMAL_LIKED_PEOPLE, peopleMatchesOptimal) : createGraphPoint(FUNNEL_GRAPH_OPTIMAL_LIKED_PEOPLE, peopleMatchesLevel);

    const repliedOptimal = FUNNEL_GRAPH_OPTIMAL_REVIEWED_PEOPLE > reviewedMatchesCount ?
      createGraphPoint(FUNNEL_GRAPH_OPTIMAL_REPLIED_PEOPLE, peopleMatchesOptimal) : createGraphPoint(FUNNEL_GRAPH_OPTIMAL_REPLIED_PEOPLE, peopleMatchesLevel);

    const likedActualLevel = createGraphPoint(engagedMatchesRatio, peopleMatchesLevel);
    const contactedActualLevel = createGraphPoint(contactedMatchesRatio, peopleMatchesLevel);
    const repliedActualLevel = createGraphPoint(repliedConversationsRatio, peopleMatchesLevel);

    if (!reviewedMatchesCount) {
      return {
        peopleMatchesOptimalLevel: peopleMatchesOptimal,
        peopleMatchesActualLevel: 1,
        peopleLikedLevel: 1,
        peopleLikedOptimalLevel: likedOptimal,
        peopleContactedLevel: 1,
        peopleContactedOptimalLevel: likedOptimal,
        peopleRepliedLevel: 1,
        peopleRepliedOptimalLevel: repliedOptimal,
      };
    }

    return {
      peopleMatchesOptimalLevel: peopleMatchesOptimal,
      peopleMatchesActualLevel: peopleMatchesLevel,
      peopleLikedLevel: likedActualLevel,
      peopleLikedOptimalLevel: likedOptimal,
      peopleContactedLevel: contactedActualLevel,
      peopleContactedOptimalLevel: likedOptimal,
      peopleRepliedLevel: repliedActualLevel,
      peopleRepliedOptimalLevel: repliedOptimal,
    };
  }, [contactedMatchesRatio, createGraphPoint, engagedMatchesRatio, repliedConversationsRatio, reviewedMatchesCount]);

  return {
    peopleContactedLevel,
    peopleContactedOptimalLevel,
    peopleLikedLevel,
    peopleLikedOptimalLevel,
    peopleMatchesActualLevel,
    peopleMatchesOptimalLevel,
    peopleRepliedLevel,
    peopleRepliedOptimalLevel,
    contactedMatchesRatio,
    engagedMatchesCount,
    engagedMatchesRatio,
    repliedConversationsRatio,
    reviewedMatchesCount,
    toReviewMatchesCount,
    matchesTalentsCompaniesCardinality: positionStatistics.matchesTalentsCompaniesCardinality,
    followupsMessagesCount: positionStatistics.followupsMessagesCount || 0,
    messagesOpenRate: positionStatistics.messagesOpenRate,
    sentMessagesCount: positionStatistics.sentMessagesCount,
    messagesReplyRate: positionStatistics.messagesReplyRate,
  };
};
