import React, { useState } from 'react';
import log from 'loglevel';
import { THEME } from '../../../themes';
import { CONFIG } from '../../../config';
import SvgIcon from '../SvgIcon';
import {
  ContentContainer,
  SnackBarActionButton,
  SnackBarContainer, SnackBarDescription, SnackBarImage,
  SnackBarTitle,
} from './styled';
import { DismissButton } from './DismissButton';

type CustomSnackBarProps = {
  backgroundColor?: string,
  icon?: React.FC,
  iconColor?: string,
  title?: string,
  titleColor?: string,
  description?: string,
  descriptionColor?: string,
  actionTitle?: string,
  actionColor?: string,
  onActionClick?: () => void,
  onStartClosing?: () => void,
  dismissButtonColor?: string,
  dismissBackgroundColor?: string,
  autoDismissDuration?: number,
  shouldAutoDismiss?: boolean,
  isOpen?: boolean,
  onClose?: () => void,
  anchor?: AnchorDirections,
}
const TAG = '[CUSTOM_SNACK_BAR]';
const ANCHOR = {
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  TOP_CENTER: 'top-center',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_RIGHT: 'bottom-right',
};

const logger = log.getLogger(TAG);
export const CustomSnackBar = ({
  backgroundColor,
  icon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  actionTitle,
  actionColor,
  onActionClick = undefined,
  dismissButtonColor,
  dismissBackgroundColor,
  autoDismissDuration,
  shouldAutoDismiss,
  onStartClosing,
  isOpen,
  anchor,
  onClose,
}: CustomSnackBarProps): JSX.Element => {
  const [isClose, setIsClose] = useState(!isOpen);

  const getPosition = () => {
    let POSITION = {
      top: true,
      left: false,
      center: true,
    };

    switch (anchor) {
      case ANCHOR.TOP_CENTER: {
        POSITION = { top: true, left: false, center: true };
        break;
      }

      case ANCHOR.TOP_LEFT: {
        POSITION = { top: true, left: true, center: false };
        break;
      }

      case ANCHOR.TOP_RIGHT: {
        POSITION = { top: true, left: false, center: false };
        break;
      }
      case ANCHOR.BOTTOM_CENTER: {
        POSITION = { top: false, left: false, center: true };
        break;
      }

      case ANCHOR.BOTTOM_RIGHT: {
        POSITION = { top: false, left: false, center: false };
        break;
      }

      case ANCHOR.BOTTOM_LEFT: {
        POSITION = { top: false, left: true, center: false };
        break;
      }

      default: {
        logger.debug('direction unsupported');

        return POSITION;
      }
    }

    return POSITION;
  };

  const position = getPosition();

  const renderImage = () => {
    if (icon) {
      return (
        <SnackBarImage
          size="24px"
          color={ iconColor }
        >
          <SvgIcon Icon={ icon } size="small" />
        </SnackBarImage>
      );
    }

    return null;
  };

  const renderTitle = () => {
    if (title) {
      return (
        <SnackBarTitle color={ titleColor }>
          {title}
        </SnackBarTitle>
      );
    }

    return null;
  };

  const renderDescription = () => {
    if (description) {
      return (
        <SnackBarDescription color={ descriptionColor } title={ description }>
          {description}
        </SnackBarDescription>
      );
    }

    return null;
  };

  const renderActionButton = () => {
    if (!!actionTitle && onActionClick && typeof onActionClick === 'function') {
      return (
        <SnackBarActionButton
          color={ actionColor }
          onClick={ onActionClick }
        >
          {actionTitle}
        </SnackBarActionButton>
      );
    }

    return null;
  };

  const hideBannerHandler = () => {
    setIsClose(true);
    if (onStartClosing) {
      onStartClosing();
    }
  };

  return (
    <SnackBarContainer
      onAnimationEnd={ () => {
        if (isClose) {
          onClose?.();
          setIsClose(false);
        }
      } }
      backgroundColor={ backgroundColor }
      isOpen={ isOpen }
      isClose={ isClose }
      position={ position }
    >
      <ContentContainer>
        {renderImage()}
        {renderTitle()}
        {renderDescription()}
        {renderActionButton()}
      </ContentContainer>

      <DismissButton
        backgroundColor={ dismissBackgroundColor }
        color={ dismissButtonColor }
        onClick={ hideBannerHandler }
        onCounterDone={ hideBannerHandler }
        counterEnabled={ isOpen && !!shouldAutoDismiss }
        counter={ autoDismissDuration }
      />
    </SnackBarContainer>
  );
};

CustomSnackBar.defaultProps = {
  backgroundColor: THEME.COLORS.PRIMARY_DARK,
  icon: undefined,
  iconColor: THEME.COLORS.PURE_WHITE,
  title: '',
  titleColor: THEME.COLORS.PURE_WHITE,
  description: '',
  descriptionColor: THEME.COLORS.PURE_WHITE,
  actionTitle: '',
  actionColor: THEME.COLORS.ACCENT,
  onActionClick: undefined,
  dismissButtonColor: THEME.COLORS.ACCENT,
  dismissBackgroundColor: THEME.COLORS_RGB.WHITE_DIVIDER,
  autoDismissDuration: CONFIG.SNACKBAR_AUTO_DISMISS_DURATION, // 7 sec
  shouldAutoDismiss: false,
  isOpen: false,
  anchor: 'top-center',
  onClose: undefined,
  onStartClosing: undefined,
};
