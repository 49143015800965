import styled from 'styled-components';
import { THEME } from '../../../themes';

export const Container = styled.div`
  position: relative;
  word-break: break-word;
  white-space: pre-line;
`;
export const TypingContainer = styled.div`
  position: absolute;
`;

export const Blinker = styled.span`
  opacity: ${THEME.OPACITY.HIGHEST};
  border-left: 7px solid black;
  animation: blinker 0.9s steps(2, start) infinite;

  @keyframes blinker {
    to {
      visibility: hidden;
    }
  }
`;

export const GhostText = styled.div`
  opacity: ${THEME.OPACITY.TRANSPARENT};
`;
