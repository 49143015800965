import styled from 'styled-components';
import { THEME } from '../../../../themes';
import {
  Body1Font, OverlineFont, Subtitle1Font, Title1Font, Title3Font,
} from '../../../../themes/fonts';
import { ASSETS } from '../../../../assets';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  width: 100%;
`;

export const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINO};
  margin-bottom: ${THEME.SPACING.MEDIUM};
  align-items: center;
`;

export const FooterText = styled.div`
  display: flex;
  flex-direction: column;
  ${Subtitle1Font};
  align-items: center;
  gap: ${THEME.SPACING.TINO};
  color: ${THEME.COLORS.ACCENT};
  margin-top: ${THEME.SPACING.LOOSE};
`;

export const Description = styled.div<{isSupportRowVisible: boolean}>`
  ${Subtitle1Font};
  display: flex;
  font-weight: 700;
  gap: ${THEME.SPACING.TINO};
  color: ${({ isSupportRowVisible }) => !isSupportRowVisible ? THEME.COLORS.ACCENT : THEME.COLORS.PRIMARY_DARK};
  align-items: center;
`;

export const DescriptionSubtitle = styled.div`
  ${Body1Font};
  display: flex;
  flex-direction: row;
  color: ${THEME.COLORS_RGB.PRIMARY_DARK_MED};
`;

export const ChatLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;

export const SupportText = styled.div`
  display: flex;
  align-items: center;
  white-space: pre;
`;

export const HeaderTitle = styled.div`
  ${Title1Font};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${THEME.COLORS.PRIMARY_DARK};
  white-space: pre-line;
  text-align: center;
`;

export const HeaderDescription = styled.div`
  ${Title3Font};
  color: ${THEME.COLORS.CONTENT_A};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: ${THEME.COLORS_RGB.PRIMARY_DARK_MED};
  display: flex;
  align-items: start;
  gap: ${THEME.SPACING.BASE};
  white-space: pre-line;
  text-align: center;
`;

export const DescriptionIcon = styled(ASSETS.BELL)<{ disableMargin?: boolean }>`
  margin-top: ${({ disableMargin }) => disableMargin ? undefined : THEME.SPACING.TINY}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${`calc(${THEME.SPACING.GIANT} + ${THEME.SPACING.GRAND}) 0px ${THEME.SPACING.GIANT} 0px`};
  cursor: pointer;
`;

export const ButtonTitle = styled.div`
  ${OverlineFont};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-top: ${THEME.SPACING.BASE};
`;
