export enum OnboardingStep {
  Registration = 'REGISTRATION',
  TransitionWelcomeUser = 'TRANSITION_WELCOME_USER',
  LetsBegin = 'LETS_BEGIN',
  Experience = 'EXPERIENCE',
  Background = 'BACKGROUND',
  Education = 'EDUCATION',
  JobDescription = 'JOB_DESCRIPTION',
  EmailVerification = 'EMAIL_VERIFICATION',
  OnboardingCompleted = 'ONBOARDING_COMPLETED',
}
