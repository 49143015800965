import styled  from 'styled-components';
import { ASSETS } from '../../../../../assets';
import { iconCss } from '../base-text-editor-controls/text-alignment-controls.styled';

export const AlignLeftIcon = styled(ASSETS.ALIGN_LEFT)`
  ${iconCss}
`;

export const AlignCenterIcon = styled(ASSETS.ALIGN_CENTER)`
  ${iconCss}
`;

export const AlignRightIcon = styled(ASSETS.ALIGN_RIGHT)`
  ${iconCss}
`;
