import styled from 'styled-components';
import Dialog from '../../../../components/dialogs/base-dialog/base-dialog.component';
import { Scrollbar } from '../../../../themes/scrollbar';
import MatchCard from '../match-card/match-card.component';
import { CardContainer } from '../match-card/match-card.styled';

export const StyledDialog = styled(Dialog)`
   overflow: hidden;
`;

export const BodyWrapper = styled(Dialog.Body)`
  ${Scrollbar};
  max-height: 640px;
  overflow-y: scroll;
  padding-bottom: 0;
`;

export const StyledCard = styled(MatchCard)`
  ${CardContainer} {
    padding-top: 0;
    padding-bottom: 0;  
  } 
`;
