import { ConversationMinimizedResponseDto } from '../../../../../../store/rtk-query/apis/outreach/dto/response/conversation-minimized.response';
import { ConversationGroupProps } from '../conversation-group';
import { ConversationItemProps } from '../../conversation-item/conversation-item';
import { ConversationGroupsEnum } from '../../../../../../enums/outreach/conversation-groups.enum';
import { getConversationItemProps, getGroupIcon } from '../conversation-group.util';

export const transformConversationResponseToComponentPropsGroupedByPosition = (
  conversationsMap: Map<string, ConversationMinimizedResponseDto>,
  sortedConversations: Map<ConversationGroupsEnum, string[]>,
): ConversationGroupProps[] => {
  const tempConversationList = [] as ConversationGroupProps[];

  sortedConversations.forEach((value, key) => {
    tempConversationList.push({
      groupNameKey: key,
      GroupIcon: getGroupIcon(key),
      items: value.reduce((acc, conversationId) => {
        if (!conversationsMap.has(conversationId)) {
          return acc;
        }
        const conversation = conversationsMap.get(conversationId);

        acc.push(getConversationItemProps(conversation));

        return acc;
      }, [] as ConversationItemProps[]),
    });
  });

  return tempConversationList;
};
