import { FC, useEffect, useRef } from 'react';
import { Container } from './confetti.styled';

const CONFETTI_INTERVAL_DURATION_MS = 35;

const Confetti: FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (containerRef.current) {
      const confettiColors = ['#fce18a', '#ff726d', '#b48def', '#f4306d'];
      const confettiAnimations = ['slow', 'medium', 'fast'];

      // eslint-disable-next-line consistent-return
      const createConfetti = () => {
        const containerEl = containerRef.current;

        if (!containerEl) {
          return;
        }

        const confettiEl = document.createElement('div');
        const confettiSize = `${Math.floor(Math.random() * 3) + 7}px`;
        const confettiBackground = confettiColors[Math.floor(Math.random() * confettiColors.length)];
        const confettiLeft = `${Math.floor(Math.random() * containerEl.offsetWidth)}px`;
        const confettiAnimation = confettiAnimations[Math.floor(Math.random() * confettiAnimations.length)];

        // Apply inline styles to the confetti element
        confettiEl.style.left = confettiLeft;
        confettiEl.style.width = confettiSize;
        confettiEl.style.height = confettiSize;
        confettiEl.style.backgroundColor = confettiBackground;
        confettiEl.classList.add('confetti');
        confettiEl.classList.add(`confetti--animation-${confettiAnimation}`);

        containerEl.appendChild(confettiEl);

        // Remove the confetti after a timeout
        setTimeout(() => {
          containerEl.removeChild(confettiEl);
        }, 3000);
      };

      const confettiInterval = setInterval(createConfetti, CONFETTI_INTERVAL_DURATION_MS);

      return () => {
        clearInterval(confettiInterval);
      };
    }
  }, []);

  return <Container ref={ containerRef } />;
};

export default Confetti;
