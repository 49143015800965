import log from 'loglevel';
import { MatchResponse } from '../dto/response/match.response';
import { MATCH_STATUSES } from '../../../../../consts';
import { MatchPublishedWebsocketDto } from '../dto/socket/match-published.socket';

const logger = log.getLogger('NEW_MATCH_WEBSOCKET_LISTENERS');

export const newMatchForPendingMatchesWebsocketListeners = (
  { match }: MatchPublishedWebsocketDto,
  cachePositionId: string,
  updateCachedData: (args: (matches: PaginationResponse<MatchResponse[]>) => void) => void,
): void => {
  updateCachedData((data: PaginationResponse<MatchResponse[]>) => {
    if (data && cachePositionId === match.positionId) {
      if (match.engagement?.status === MATCH_STATUSES.TO_REVIEW) {
        const foundMatch = data.results.find((cachedMatch) => cachedMatch.id === match.id);
        if (!foundMatch) {
          data.results.push(match);
          logger.debug('New match was added from socket event', match.id);
        }
      }
    }
  });
};
