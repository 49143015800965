import { QueryMethods } from '../../enums/query-methods.enums';
import { GetQuery } from '../../@types/rtk-query';
import { ACCOUNT_ID_PATH_PARAM, ROUTES, SUB_DOMAIN_PATH_PARAM } from './account.consts';

export const getMyAccountQuery = (subDomain: string): GetQuery => ({
  url: ROUTES.ACCOUNT_INFO.replace(SUB_DOMAIN_PATH_PARAM, subDomain),
  method: QueryMethods.Get,
});

export const getAccountQuery = (accountId: string): GetQuery => ({
  url: ROUTES.ACCOUNT_BY_ID.replace(ACCOUNT_ID_PATH_PARAM, accountId),
  method: QueryMethods.Get,
});
