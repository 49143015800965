export const ONBOARDING_ANALYTICS_EVENTS = {
  SIGN_UP_PAGE_VIEWED: 'sign_up_page_viewed',
  ONBOARDING_SECOND_FORM_PAGE_VIEWED: 'second_form_onboarding_page_viewed',
  ONBOARDING_FIRST_FORM_PAGE_VIEWED: 'first_form_onboarding_page_viewed',
  ONBOARDING_COMPANY_LINKEDIN_CLICKED: 'clicked_on_add_company_linkedin',
  ONBOARDING_JOB_DESCRIPTION_PAGE_VIEWED: 'add_job_description_page_viewed',
  ONBOARDING_ADD_LINK_JOB_DESCRIPTION_CLICKED: 'clicked_on_job_description_add_link',
  ONBOARDING_ADD_FILE_JOB_DESCRIPTION_CLICKED: 'clicked_on_job_description_add_file',
  ONBOARDING_CONTINUE_JOB_DESCRIPTION_CLICKED: 'clicked_on_job_description_continue',
  ONBOARDING_SKIP_JOB_DESCRIPTION_CLICKED: 'clicked_on_job_description_skip',
  ONBOARDING_CONTINUE_BTN_FIRST_FORM_CLICKED: 'clicked_on_continue_first_form',
  ONBOARDING_CONTINUE_BTN_SECOND_FORM_CLICKED: 'clicked_on_continue_second_form',
  ONBOARDING_CONTINUE_BTN_COMPANY_POP_UP_CLICKED: 'clicked_on_continue_company_pop_up',
  ONBOARDING_LOGIN_CLICKED: 'clicked_on_login_existing_user',
  ONBOARDING_COMPLETED: 'onboarding_completed',
};

export const ONBOARDING_LOCATION = {
  REMOTE_COUNTRY: 'united state',
  REMOTE: 'remote',
  SPECIFIC_LOCATION: 'specific_location',
};
