import { PositionResponse } from '../dto/response/position.response';
import { positionQuery } from '../position.toolkit-api';

export const useGetOpenPositionsQuery = (skip = false, refetchOnMountOrArgChange = false): AppSelectorType<PositionResponse[]> => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch,
  } = positionQuery.useGetOpenPositionsQuery(
    undefined,
    { skip, refetchOnMountOrArgChange },
  );

  return {
    data: data || [] as PositionResponse[],
    error,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
