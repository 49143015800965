import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import {
  Body2Font, CaptionFont, Subtitle1Font, Subtitle2Font,
} from '../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../utils/colors';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { ASSETS } from '../../../../../assets';

export const Container = styled.div`
  min-height: 277px;
  padding: ${THEME.SPACING.MEDIUM};
  background-color: ${THEME.COLORS.LIGHT_GREY};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${THEME.SPACING.MEDIUM};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const Title = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const Description = styled.div`
  ${Body2Font};
  line-height: 1.57;
  letter-spacing: 0.25px;
  color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.MED)};
`;

export const EditStepsButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.PRIMARY};
`;

export const SignatureIcon = styled(ASSETS.SIGNATURE)`
  height: 19px;
  fill: ${THEME.COLORS.PURE_WHITE};
`;

export const Body = styled.div`
  display: flex;
  gap: ${THEME.SPACING.BASE};
  flex-wrap: wrap;
`;

export const StepCardContainer = styled.div`
  flex: 1 0 21%;
  background-color: ${THEME.COLORS.PURE_WHITE};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  height: 160px;
  max-width: 216px;
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.BASE} ${THEME.SPACING.MICRO};
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const StepText = styled.div`
  ${Subtitle2Font};
  line-height: 1.29;
  letter-spacing: 0.1px;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const ManualLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 109px;
  padding: ${THEME.SPACING.MICRO} ${THEME.SPACING.TINY};
  background-color: ${hexToRgbA(THEME.COLORS.WARNING, THEME.OPACITY.DIVIDER)};
  border-radius: ${THEME.RADIUS.TIDY};
`;

export const ManualLabel = styled.div`
  ${CaptionFont};
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const InfoIcon = styled(ASSETS.QUESTION_FILLED)`
  width: 12px;
  height: 12px;
`;
