import styled, { css } from 'styled-components';
import IconButton from '../../../../../../../components/Common/Buttons/icon-button/icon-button.component';
import { THEME } from '../../../../../../../themes';
import { ASSETS } from '../../../../../../../assets';
import { hexToRgbA } from '../../../../../../../utils/colors';
import { Subtitle1Font } from '../../../../../../../themes/fonts';
import { SimilarTitleStatus } from './similar-title-item.enums';

export const Text = styled.div`
  color: ${THEME.COLORS.CONTENT_A};
  ${Subtitle1Font};
`;

const ButtonStyle = css`
  background-color: ${THEME.COLORS.LIGHT_GREY};
  width: 32px;
  height: 32px;
`;

export const AcceptButton = styled(IconButton)`
  ${ButtonStyle};
  color: ${THEME.COLORS.WARNING};
`;

export const AcceptIcon = styled(ASSETS.BADGE_STAR)`
  width: 16px;
  height: 16px;
`;

export const RejectButton = styled(IconButton)`
  ${ButtonStyle};
  color: ${THEME.COLORS.ACCENT};
`;

export const RejectIcon = styled(ASSETS.X_SIGN)`
  width: 12px;
  height: 12px;
`;

const AcceptedStyle = css`
  & ${AcceptButton} {
    color: ${THEME.COLORS.PURE_WHITE};
    background-color: ${THEME.COLORS.WARNING};
  }
`;

const RejectedStyle = css`
  text-decoration: line-through;

  & ${RejectButton} {
    color: ${THEME.COLORS.LIGHT_GREY};
    background-color: ${THEME.COLORS.ACCENT};
  }
  
  & ${Text} {
    opacity: ${THEME.OPACITY.LOW};
  }
`;

const getStyle = (status?: SimilarTitleStatus) => {
  switch (status) {
    case SimilarTitleStatus.Accepted:
      return AcceptedStyle;
    case SimilarTitleStatus.Rejected:
      return RejectedStyle;
    default:
      return null;
  }
};

export const Container = styled.div<{ status?: SimilarTitleStatus }>`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.BASE};
  border: 1px solid ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
  padding: ${THEME.SPACING.TINY};
  border-radius: ${THEME.RADIUS.CURVY};
  ${({ status }) => getStyle(status)};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.MICRO};
`;
