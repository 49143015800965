import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withShowHide } from '../../HOC/WithShowHide';
import STRING_KEYS from '../../../language/keys';
import {
  ArrowDownIcon, Container,
  CustomDropdownMenu,
  DEFAULT_DROPDOWN_MENU_WIDTH_PX,
  Title,
} from './dropdown.styled';

function Dropdown({
  filterMenuOpen,
  selectedItemId,
  items,
  itemSelectHandler,
  menuOpenHandler,
  menuCloseHandler,
  menuWidthPx = DEFAULT_DROPDOWN_MENU_WIDTH_PX,
}: DropdownProps): JSX.Element {
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (filterMenuOpen) {
      if (menuOpenHandler) {
        menuOpenHandler();
      }
    } else if (menuCloseHandler) {
      menuCloseHandler();
    }
  }, [filterMenuOpen, menuOpenHandler, menuCloseHandler]);

  const getSelectedItem = () => {
    if (selectedItemId) {
      return items?.find((item) => item.id === selectedItemId);
    }

    return null;
  };

  const selectedItem = getSelectedItem();

  return (
    <Container filterMenuOpen={ !!filterMenuOpen }>
      <Title>{ selectedItem?.title || translate(STRING_KEYS.SELECT_FROM_LIST) }</Title>
      <ArrowDownIcon />
      { filterMenuOpen && (
        <CustomDropdownMenu
          items={ items }
          selectedItemId={ selectedItemId }
          onItemSelect={ (itemId: string) => itemSelectHandler && itemSelectHandler(itemId) }
          menuWidthPx={ menuWidthPx }
        />
      ) }
    </Container>
  );
}
export default withShowHide(Dropdown);
