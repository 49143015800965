/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, Ref } from 'react';
import { PerfectButtonVariants } from '../perfect-base-button/perfect-base-button.enums';
import { IconButtonProps } from './icon-button';
import { ButtonContainer } from './icon-button.styled';

const IconButton = forwardRef((
  props: IconButtonProps,
  ref: Ref<HTMLButtonElement>,
) => {
  const {
    variant, StartIcon, disabled, ...otherProps
  } = props;

  const convertIconVariantToBaseVariant = (iconVariant?: string): PerfectButtonVariants | undefined => {
    if (iconVariant === PerfectButtonVariants.Fill) {
      return PerfectButtonVariants.Fill;
    }

    if (iconVariant === PerfectButtonVariants.Ghost) {
      return PerfectButtonVariants.Ghost;
    }

    return undefined;
  };

  return (
    <ButtonContainer
      disabled={ disabled }
      StartIcon={ StartIcon }
      variant={ convertIconVariantToBaseVariant(variant) }
      { ...otherProps }
      ref={ ref }
    />
  );
});

IconButton.displayName = 'IconButton';

export default IconButton;
