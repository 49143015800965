import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, isEqual } from 'lodash';
import STRING_KEYS from '../../../../../language/keys';
import { Autocomplete } from '../../../../../components/Common/create-position-autocomplete/autocomplete.component';
import useDebounce from '../../../../../hooks/debounce';
import { useOnboardingLetsBegin } from '../../../../../store/slices/onboarding-state/selectors/onboarding-selectors.hooks';
import {
  COMPONENT_NAME, ELEMENT_TYPE, EVENT_GROUP,
} from '../../../../../consts/analytics';
import { PAGE_NAMES } from '../../../../../consts/pages';
import { TypeAndSelectMessage } from '../../../../create-position/components/autocompletes/type-and-select-message/type-and-select-message.component';
import {
  ONBOARDING_LOCATION,
} from '../../../consts/onboarding-analytics.const';
import { CREATE_POSITION_ANALYTICS_EVENTS } from '../../../../create-position/consts/position-analytics.consts';
import { useAnalyticsContext } from '../../../../../contexts/analytics/useAnalyticsContext';
import {
  resetPositionLocation,
  setPositionRemoteLocation,
  updatePositionLocation,
} from '../../../../../store/slices/create-position-state/create-position-state.toolkit-slice';
import { IconButtonVariants } from '../../../../../components/Common/Buttons/icon-button/icon-button.enums';
import { PerfectButtonHoverVariants } from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { LocationDto } from '../../../../../models/dto/location.dto';
import { TrackEventPayload } from '../../../../../@types/analytics/eventPayload';
import { formatLocation } from '../../../../../utils/location';
import { useLocationsAutocomplete } from '../../../../../hooks/location-autocomplete-hook/location-autocomplete.hook';
import { REMOTE_POSITION } from '../../../../../hooks/location-autocomplete-hook/location-autocomplete-hook.consts';
import { InputStyle } from '../../input/input.component';
import {
  AddButtonIcon,
  RemoveButtonIcon,
  StyledIconButton,
} from '../../../../../components/Common/create-position-autocomplete/autocomplete.styled';
import { LocationMenuItem } from './location-menu-item/location-menu-item.component';
import { LocationMenuItemDirection, LocationMenuItemSize } from './location-menu-item/location-menu-item.enums';
import { Container } from './location-autocomplete.styled';

type LocationAutocompleteProps = {
  inputElementId: string,
  onSentenceCompleted: () => void,
  onLocationChanged?: () => void,
  onAddAnotherLocation?: () => void,
  showAddAnotherLocationButton?: boolean,
  onRemoveLocation?: () => void,
  stepNumber: number,
  locationIndex: number,
  showRemoteOptions: boolean,
}

export const LocationAutocomplete = ({
  inputElementId,
  onSentenceCompleted,
  onLocationChanged,
  onAddAnotherLocation,
  showAddAnotherLocationButton,
  onRemoveLocation,
  stepNumber,
  locationIndex,
  showRemoteOptions,
}: LocationAutocompleteProps):JSX.Element => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isInputFocused, setInputFocused] = useState<boolean>(false);
  const dispatch = useDispatch();
  const debouncedValue = useDebounce(inputValue.toLowerCase(), 500);
  const {
    locations: storedLocations,
    remotePosition: isRemotePosition,
  } = useOnboardingLetsBegin();

  const storedLocation = storedLocations[locationIndex];
  const isStoredLocationEmpty = isEmpty(storedLocation);

  const {
    isLoading,
    locationObject,
  } = useLocationsAutocomplete({
    query: debouncedValue,
    showRemoteOptions,
    country: storedLocations.find((location) => !!location.country)?.country,
  });
  const { t: translate } = useTranslation();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const locations = useMemo(() => [...(locationObject.regions || []), ...(locationObject.exactLocations || [])], [locationObject.exactLocations, locationObject.regions]);

  const handleComplete = (selectedLocation: LocationDto) => {
    const trackEventPayload: TrackEventPayload = {
      eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_INSERT_PARAMETER_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.BASIC_INFO,
      componentName: COMPONENT_NAME.LOCATION,
      componentElementType: ELEMENT_TYPE.AUTOCOMPLETE,
      componentLocation: { ...selectedLocation },
      componentMetadata: '',
      stepNumber,
    };

    if (selectedLocation.country === REMOTE_POSITION.country) {
      trackEventPayload.componentMetadata = ONBOARDING_LOCATION.REMOTE;
      trackEventPayload.componentLocation = {
        city: ONBOARDING_LOCATION.REMOTE,
        state: ONBOARDING_LOCATION.REMOTE,
        country: ONBOARDING_LOCATION.REMOTE,
      };
      dispatch(setPositionRemoteLocation());
    } else {
      trackEventPayload.componentMetadata = ONBOARDING_LOCATION.SPECIFIC_LOCATION;
      dispatch(updatePositionLocation({ data: selectedLocation, index: locationIndex }));
    }
    analyticsTrackEvent(trackEventPayload);
    onSentenceCompleted();
    setInputFocused(false);
  };

  const itemClickedHandler = (value: string, index?: number) => {
    const locationItem = index ? locations[index] : undefined;

    if (locationItem) {
      handleComplete(locationItem);
      setInputValue(formatLocation(locationItem));
    } else {
      setInputValue(value);
      dispatch(resetPositionLocation(locationIndex));
      onLocationChanged?.();
    }
  };

  const onSelect = (selectedLocation: LocationDto) => {
    setInputValue(formatLocation(selectedLocation));
    handleComplete(selectedLocation);
  };

  const StartAdornment = (
    ((isInputFocused || isStoredLocationEmpty) && (
      <span>📍</span>
    )) || undefined);

  const EndAdornment = useMemo(() => {
    if (!isStoredLocationEmpty && !isInputFocused && showAddAnotherLocationButton) {
      return (
        <StyledIconButton
          type="button"
          variant={ IconButtonVariants.Fill }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          onClick={ onAddAnotherLocation }
          StartIcon={ AddButtonIcon }
        />
      );
    }

    if (isInputFocused && onRemoveLocation) {
      return (
        <StyledIconButton
          type="button"
          variant={ IconButtonVariants.Fill }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          onClick={ onRemoveLocation }
          StartIcon={ RemoveButtonIcon }
        />
      );
    }

    return undefined;
  }, [isInputFocused, isStoredLocationEmpty, onAddAnotherLocation, onRemoveLocation, showAddAnotherLocationButton]);

  const renderAutocomplete = () => {
    return (
      <Autocomplete
        inputElementId={ inputElementId }
        InstructionsMessage={ <TypeAndSelectMessage /> }
        optionsTitle=""
        isError={ isStoredLocationEmpty && !isInputFocused && !isRemotePosition }
        errorMessage={ translate(STRING_KEYS.ONBOARDING.LOCATION_SELECTION_ERROR) }
        isLoading={ isLoading }
        placeholder={ STRING_KEYS.ONBOARDING.ONBOARDING_LOCATION_PLACEHOLDER }
        inputValue={ inputValue }
        onValueChange={ itemClickedHandler }
        isInputFocused={ isInputFocused }
        setInputFocused={ setInputFocused }
        StartAdornment={ StartAdornment }
        EndAdornment={ EndAdornment }
        inputStyle={ InputStyle.Onboarding }
      >
        {locations.map((location: LocationDto) => {
          return (
            <LocationMenuItem
              key={ formatLocation(location) }
              data={ location }
              isSelected={ isEqual(location, storedLocation) }
              clickHandler={ () => onSelect(location) }
              size={ LocationMenuItemSize.Large }
              direction={ LocationMenuItemDirection.Row }
            />
          );
        })}
      </Autocomplete>
    );
  };

  return (
    <Container>
      { renderAutocomplete() }
    </Container>
  );
};

LocationAutocomplete.defaultProps = {
  onLocationChanged: undefined,
  onAddAnotherLocation: undefined,
  onRemoveLocation: undefined,
  showAddAnotherLocationButton: false,
};
