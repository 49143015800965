import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { Body2Font, CaptionFont, Subtitle1Font } from '../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../utils/colors';

export const Container = styled.div`
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.MEDIUM} ${THEME.SPACING.BASE};
`;

export const StartContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.MEDIUM} 0 ${THEME.SPACING.MEDIUM};
  align-self: start;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const Title = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const DescriptionContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINY};
  align-items: center;
`;

export const InfoText = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.PRIMARY_DARK};
  opacity: ${THEME.OPACITY.MED};
`;

export const Description = styled.div`
  ${Body2Font};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;

export const Badge = styled.div`
  ${Body2Font};
  color: ${THEME.COLORS.PRIMARY_DARK};
  background-color: ${hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.GHOST)};
  padding: ${THEME.SPACING.MICRO} ${THEME.SPACING.TINY};
  border-radius: ${THEME.RADIUS.BASE};
  width: fit-content;
  text-transform: capitalize;
`;

export const EndContainer = styled.div`
  align-self: start;
`;
