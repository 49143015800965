import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/base-query';
import { REDUCER_PATH } from './onboarding.consts';
import {
  getAllowedCompaniesQuery,
  loadOnboardingQuery,
  updateOnboardingProgressQuery,
} from './onboarding.toolkit-queries';
import { OnboardingResponse } from './dto/response/onboarding-response.dto';
import { UpdateOnboardingProgressRequest } from './dto/request/update-onboarding-progress.request';

export const onboardingQuery = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    loadOnboarding: builder.mutation<OnboardingResponse, void>({
      query: loadOnboardingQuery,
    }),
    getAllowedCompanies: builder.query<OnboardingAllowedCompanyResponse, void>({
      query: getAllowedCompaniesQuery,
    }),
    updateOnboardingProgress: builder.mutation<OnboardingResponse, UpdateOnboardingProgressRequest>({
      query: updateOnboardingProgressQuery,
    }),
  }),
});
