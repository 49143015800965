import { useActivateAccountState } from '@frontegg/react-hooks';
import { useTranslation } from 'react-i18next';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useAuthActions, useSocialLoginState } from '@frontegg/react';
import log from 'loglevel';
import { IndeterminateLinearLoader } from '../Common/Loaders/IndeterminateLinearLoader';
import STRING_KEYS from '../../language/keys';
import { AuthError } from '../Common/AuthError';
import { AppLoader } from '../Common/Loaders/AppLoader';
import PrimaryButton from '../Common/Buttons/PrimaryButton';
import { PageHeader } from '../Common/headers/page-header/page-header.component';
import { getGoogleSocialLoginUrl } from '../../utils/frontegg';
import {
  isLengthValid,
  hasDigit,
  hasUpperOrLowerCase,
} from '../../utils/passwords';
import { PAGE_TITLES } from '../../consts';
import { PRIVACY_CLASS } from '../../consts/fullstory';
import { AppRouting } from '../../services/appRoutingResolver';
import {
  PageContainer,
  Title,
  SubTitle,
  ContinueWithGoogleContainer,
  PageContentsWrapper,
  Container,
  DescriptionTitle,
  StyledForm,
  WorkEmailInput,
  PasswordInput,
  LetsGoButtonWrapper,
  ValidationError,
  LoaderWrapper,
  EmailIcon,
  PasswordIcon,
} from './styled';
import { PasswordRules } from './PasswordRules';

const logger = log.getLogger('ACCOUNT_ACTIVATION_SCREEN');
const SOCIAL_LOGIN_OPTION = 'google';

// to do move Login Form into a new component after merge with OB registration page
function AccountActivationScreen(): JSX.Element {
  // This effect runs once, after the first render
  useEffect(() => {
    document.title = PAGE_TITLES.ACTIVATION;
  }, []);

  const [passwordInput, setPasswordInput] = useState<string>('');

  const [passwordError, setPasswordError] = useState<string>('');

  const [isShowPasswordRules, setIsShowPasswordRules] = useState(false);
  // const [termsOfUseModalVisible, setTermsOfUseModalVisible] = useState<boolean>(false);
  const [initLoading, setInitLoading] = useState(true);

  const { t: translate } = useTranslation();

  const { getActivateAccountStrategy, activateAccount } = useAuthActions();
  const activateState = useActivateAccountState();
  const { activationStrategy } = activateState;
  const { socialLoginsConfigV2 } = useSocialLoginState();
  const { loading: isLoading, error: activationError } = activateState;

  const params = new URLSearchParams(window.location.search);
  const userId = params.get('userId') || '';
  const token = params.get('token') || '';
  const userEmail = params.get('email') || '';

  if (!userId || !token) {
    AppRouting.navigateToUrl(AppRouting.buildGlobalLoginUrl(window.location));
  }

  const resetErrors = () => {
    setPasswordError('');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      // we need this to prevent showing activation form
      // before getActivateAccountStrategy was called.
      setInitLoading(false);
    }, 500);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  });

  useEffect(() => {
    getActivateAccountStrategy({ userId, token });
  }, [getActivateAccountStrategy, userId, token]);

  if (initLoading || activationStrategy.loading) {
    return <AppLoader title="Account activation" />;
  }

  // if activation was done with SSO,
  // the user may still be able to set password and no error will be received
  if (activationStrategy.error) {
    logger.warn(
      'failed to activate account due to Frontegg error:',
      activationStrategy.error,
    );

    return <AuthError errorMessage={ translate(STRING_KEYS.INVALID_LINK) } />;
  }

  const handleContinueWithGoogle = () => {
    const googleConfiguration =
    socialLoginsConfigV2?.find((config) => config.type === SOCIAL_LOGIN_OPTION);

    const redirectUrl = AppRouting.buildSocialLoginSuccessUrl(window.location);

    logger.debug('navigating to google login with params', {
      clientId: googleConfiguration?.clientId,
      redirectUrl,
    });

    window.location.href = getGoogleSocialLoginUrl(
      googleConfiguration?.clientId as string,
      redirectUrl,
    );
  };

  const handleSignUpClick = async (event: SyntheticEvent) => {
    // preventing the refresh of the browser upon form completion default behavior
    event.preventDefault();

    resetErrors();

    if (!isLengthValid(passwordInput)) {
      setPasswordError(translate(STRING_KEYS.PASSWORD_LENGTH_VALIDATION_ERROR));

      return;
    }

    if (!hasUpperOrLowerCase(passwordInput)) {
      const concatString = `${translate(STRING_KEYS.PASSWORD_SHOULD)} ${
        translate(STRING_KEYS.AT_LEAST_ONE_UPPER_OR_LOWER_LETTER)}`;

      setPasswordError(concatString);

      return;
    }

    if (!hasDigit(passwordInput)) {
      const concatString = `${translate(STRING_KEYS.PASSWORD_SHOULD)} ${
        translate(STRING_KEYS.CONTAINS_NUMBER_OR_LETTER)}`;

      setPasswordError(concatString);

      return;
    }

    activateAccount({
      userId,
      token,
      password: passwordInput,
    });
  };

  const handlePasswordInputChange = (e: SyntheticEvent) => {
    const element = e.target as HTMLInputElement;

    if (isLengthValid(element.value)) {
      setPasswordError('');
    }

    setPasswordInput(element.value);
  };

  // const handleOpenTermsOfUseModal = () => {
  //   setTermsOfUseModalVisible(true);
  // };

  // const handleCloseTermsOfUseModal = () => {
  //   setTermsOfUseModalVisible(false);
  // };

  const defineErrors = () => {
    if (passwordError) {
      return (
        <ValidationError>
          { passwordError }
        </ValidationError>
      );
    }

    if (activationError) {
      return (
        <ValidationError>
          { activationError }
        </ValidationError>
      );
    }

    return null;
  };

  const handleShowPasswordRules = () => {
    setIsShowPasswordRules(true);
  };
  const handleHidePasswordRules = () => {
    setIsShowPasswordRules(false);
  };

  return (
    <PageContainer>

      {isLoading && (
        <LoaderWrapper>
          <IndeterminateLinearLoader />
        </LoaderWrapper>
      )}

      <PageHeader />
      {/* {
        termsOfUseModalVisible && (
          <TermsOfUseModal onModalClose={ handleCloseTermsOfUseModal } />
        )
      } */}
      <Container>
        <Title>
          { translate(STRING_KEYS.ACCOUNT_ACTIVATION.TITLE) }
        </Title>

        <SubTitle >
          { translate(STRING_KEYS.ACCOUNT_ACTIVATION.SUBTITLE) }
        </SubTitle>

        <PageContentsWrapper>

          <ContinueWithGoogleContainer onClick={ () => handleContinueWithGoogle() }>
            { translate(STRING_KEYS.CONTINUE_WITH_GOOGLE_ACCOUNT) }
          </ContinueWithGoogleContainer>

          <DescriptionTitle>
            { translate(STRING_KEYS.START_EXPERIENCE_WITH_EMAIL) }
          </DescriptionTitle>

          <StyledForm
            onSubmit={ handleSignUpClick }
            autoComplete="new-password"
          >
            <EmailIcon />
            <WorkEmailInput
              placeholder={ userEmail }
              autoComplete="new-password"
            />
            <PasswordIcon />
            <PasswordInput
              type="password"
              autoComplete="new-password"
              onChange={ handlePasswordInputChange }
              className={ PRIVACY_CLASS.EXCLUDE }
              onFocus={ handleShowPasswordRules }
              onBlur={ handleHidePasswordRules }
              placeholder={
                translate(STRING_KEYS.CHOOSE_YOUR_PASSWORD)
              }
              isError={ !!passwordError }
            />

            {isShowPasswordRules && <PasswordRules passwordInput={ passwordInput } /> }
            { defineErrors() }
            <LetsGoButtonWrapper
              isLoading={ isLoading }
            >
              <PrimaryButton
                title={ translate(STRING_KEYS.CONTINUE) }
                type="submit"
                // PrimaryButton component has to have onClick prop
                onClick={ () => null }
              />
            </LetsGoButtonWrapper>
          </StyledForm>

          {/* <TermsOfUse onClick={ handleOpenTermsOfUseModal }>
            <Trans
              defaults={ STRING_KEYS.SIGN_UP_TERMS_OF_USE }
              components={ { underline: <u /> } }
            />
          </TermsOfUse> */}

        </PageContentsWrapper>

      </Container>
    </PageContainer>
  );
}

export default AccountActivationScreen;
