import styled from 'styled-components';

export const Container = styled.div<{
  width?: string,
  withBorder?: boolean,
  }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  ${({ width }) => width ? `width: ${width}` : ''};
`;
