import styled from 'styled-components';
import { THEME } from '../../../themes';
import { hexToRgbA } from '../../../utils/colors';
import { OverlineFont } from '../../../themes/fonts';
import { scoreColorByLevel } from '../base-card/base-card.consts';
import { ConnectionScoreEnum } from '../../../enums/connection-score.enum';

export const TalentNetworkConnectionScoreContainer = styled.div` 
  color: currentColor;
`;

export const TitleContainer = styled.div` 
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
`;

export const ScoreContainer = styled.div` 
  display: flex;
  gap: 2px;
`;

export const ScoreTitle = styled.div<{ level: ConnectionScoreEnum}>`
  ${OverlineFont};
  font-weight: 600;
  color: ${({ level }) => scoreColorByLevel[level]};
`;

export const Score = styled.div<{ level: ConnectionScoreEnum, active: boolean}>`
  width: 8px;
  height: 12px;
  border-radius: ${THEME.RADIUS.TIDY};
  background-color: ${({ level, active }) => {
    const alpha = active ? 1 : THEME.OPACITY.DIVIDER;
    
    return hexToRgbA(scoreColorByLevel[level], alpha);
  }};
`;
