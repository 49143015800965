import { useTranslation } from 'react-i18next';
import StyledAvatar from '../styled-avatar/styled-avatar.component';
import STRING_KEYS from '../../../language/keys';
import PerfectCheckbox from '../perfect-checkbox/perfect-checkbox.component';
import { UserMenuItemProps } from './user-menu-item';
import {
  Container,
  MenuItemAvatarContainer,
  MenuItemTextContainer,
  MenuItemSubtitle,
  MenuItemTitle,
  UserNotVerifiedIcon,
  UserEmailNotConfiguredIcon,
} from './user-menu-item.styled';
import {
  STYLED_AVATAR_BORDER_WIDTH_PX,
  STYLED_AVATAR_INITIALS_FONT_SIZE_PX,
  STYLED_AVATAR_OUTLINE_WIDTH_PX,
} from './user-menu-item.consts';

const UserMenuItem = ({
  user,
  showCheckbox,
  checked,
  isCurrentUser,
  clickHandler,
  subtitleOptions,
}: UserMenuItemProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const renderSubtitle = () => {
    let subtitle;
    let Icon;
    if (!user.verified && subtitleOptions?.showUserVerifiedIndication) {
      subtitle = translate(STRING_KEYS.USER_MENU_ITEM.INVITE_PENDING);
      Icon = <UserNotVerifiedIcon />;
    } else if (!user.emailConfigurationInfo?.isConfigured && subtitleOptions?.showEmailConfigurationIndication) {
      subtitle = translate(STRING_KEYS.USER_MENU_ITEM.EMAIL_NOT_CONFIGURED);
      Icon = <UserEmailNotConfiguredIcon />;
    } else {
      subtitle = user.emailConfigurationInfo?.connectedEmailAddress || user.email;
    }

    return (
      <MenuItemSubtitle>{Icon}{ subtitle }</MenuItemSubtitle>
    );
  };

  return (
    <Container key={ user.id } onClick={ () => clickHandler?.() }>
      <>{ showCheckbox && (<PerfectCheckbox checked={ checked } />) }</>
      <MenuItemAvatarContainer>
        <StyledAvatar
          name={ user.fullName || user.email }
          image={ user.profilePictureUrl }
          initialsFontSizePx={ STYLED_AVATAR_INITIALS_FONT_SIZE_PX }
          borderWidthPx={ STYLED_AVATAR_BORDER_WIDTH_PX }
          outlineWidthPx={ STYLED_AVATAR_OUTLINE_WIDTH_PX }
          borderRadiusPx={ 4 }
          height={ 32 }
          width={ 32 }
        />
      </MenuItemAvatarContainer>
      <MenuItemTextContainer>
        <MenuItemTitle>{ isCurrentUser ? translate(STRING_KEYS.USER_MENU_ITEM.ME) : (user.fullName || user.email) }</MenuItemTitle>
        <MenuItemSubtitle>{ renderSubtitle() }</MenuItemSubtitle>
      </MenuItemTextContainer>
    </Container>
  );
};

export default UserMenuItem;
