import { useTranslation } from 'react-i18next';
import Dialog from '../base-dialog/base-dialog.component';
import { DialogWidthSize } from '../base-dialog/base-dialog.enums';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
} from '../../Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../language/keys';
import {
  ActionButton,
  BodyContainer,
  ButtonsContainer,
  Description,
  HeaderContainer,
  HeaderIcon, Headline,
  TextContainer,
  Title,
} from './outreach-email-connected-successfully-dialog.styled';

export const OutreachEmailConnectedSuccessfullyDialog = ({
  closeDialogHandler,
  isDialogOpen,
}: OutreachEmailConnectedSuccessfullyDialogProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Dialog
      isOpen={ isDialogOpen }
      onClose={ closeDialogHandler }
      widthSize={ DialogWidthSize.Medium }
      portalUniqueId="OUTREACH_EMAIL_CONNECTED_SUCCESSFULLY_DIALOG"
    >
      <HeaderContainer>
        <HeaderIcon />
      </HeaderContainer>
      <BodyContainer>
        <TextContainer>
          <Headline>{ translate(STRING_KEYS.OUTREACH_EMAIL_CONNECTED_SUCCESSFULLY_DIALOG.HEADLINE) }</Headline>
          <Title>{ translate(STRING_KEYS.OUTREACH_EMAIL_CONNECTED_SUCCESSFULLY_DIALOG.TITLE) }</Title>
          <Description>
            { translate(STRING_KEYS.OUTREACH_EMAIL_CONNECTED_SUCCESSFULLY_DIALOG.DESCRIPTION) }
          </Description>
        </TextContainer>
        <ButtonsContainer>
          <ActionButton
            hoverVariant={ PerfectButtonHoverVariants.Grow }
            onClick={ closeDialogHandler }
            size={ PerfectButtonSize.Large }
          >
            { translate(STRING_KEYS.OUTREACH_EMAIL_CONNECTED_SUCCESSFULLY_DIALOG.ACTION_BUTTON_TEXT) }
          </ActionButton>
        </ButtonsContainer>
      </BodyContainer>
    </Dialog>
  );
};
