import styled from 'styled-components';
import { THEME } from '../../../themes';
import { HeadlineFont } from '../../../themes/fonts';
import { ASSETS } from '../../../assets';
import { TextEllipsis } from '../../../themes/text';
import { hexToRgbA } from '../../../utils/colors';

export const Container = styled.div<{ required: boolean }>`
  height: 48px;
  display: flex;
  align-items: center;
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  background-color: ${THEME.COLORS.PURE_WHITE};
  color: ${({ required }) => (required ? THEME.COLORS.SECONDARY : THEME.COLORS.CONTENT_A)};
  cursor: pointer;
  gap: ${THEME.SPACING.TINO};
  outline: solid 1px ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};

  :hover {
    outline-width: 2px;
  };
`;

export const Title = styled.span`
  ${HeadlineFont};
  font-family: ${THEME.FONT_FAMILY.MERRIWEATHER};
  ${TextEllipsis};
  line-height: 40px; 
  max-width: 30ch;
`;

export const RequiredChipIcon = styled(ASSETS.ASTERISK)`
  width: 18px;
  height: 18px;
  fill: currentColor;
`;

export const CloseIconWrapper = styled.div`
  display: flex;
`;

export const CloseChipIcon = styled(ASSETS.X_SIGN_ROUNDISH)<{ required: boolean }>`
  width: 20px;
  height: 20px;
  opacity: ${({ required }) => (required ? THEME.OPACITY.HIGHEST : THEME.OPACITY.LOW)};
`;
