/* eslint-disable react/no-array-index-key,react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import StarIcon from '@mui/icons-material/Star';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { ExactLocationDto, LocationDto, LocationType } from '../../../../../../../models/dto/location.dto';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
} from '../../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { DEFAULT_MILE_RADIUS_VALUE, hasRemoteOption } from '../../../../../../../utils/location';
import { REMOTE_POSITION } from '../../../../../../../hooks/location-autocomplete-hook/location-autocomplete-hook.consts';
import STRING_KEYS from '../../../../../../../language/keys';
import LocationItem from './location-item/location-item.component';
import {
  AddLocationButton,
  AddLocationButtonIcon,
  LocationAutocompleteLabelWrapper,
  LocationsContainer,
} from './location-step-body.styled';
import { LocationStepBodyProps } from './location-step-body';

export const LocationStepBody = ({
  locations,
  remotePosition,
  changeHandler,
  remotePositionChangeHandler,
  ...otherProps
}: LocationStepBodyProps): JSX.Element => {
  const { t: translate } = useTranslation();

  // Set default locationMileRadius when missing
  useEffect(() => {
    const updatedLocations = locations.map((location) => {
      if (location.type === LocationType.ExactLocation) {
        if ((location as ExactLocationDto).locationMileRadius === null) {
          return {
            ...location,
            locationMileRadius: DEFAULT_MILE_RADIUS_VALUE,
          };
        }
      }

      return location;
    });
    changeHandler(updatedLocations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedCountry = useMemo(() => {
    if (locations.length > 1) {
      return locations.find((location) => !!location.country)?.country;
    }

    return undefined;
  }, [locations]);

  const isRemotePosition = useMemo(() => remotePosition || hasRemoteOption(locations), [locations, remotePosition]);
  const locationsAmount = useMemo(() => locations.length || 1, [locations.length]);

  const onAddLocationButtonClick = () => {
    const slicedLocations = locations.slice();
    slicedLocations.push({} as ExactLocationDto);
    changeHandler(slicedLocations);
  };

  const onRemoveLocationItemButtonClick = (index: number) => {
    const slicedLocations = locations.slice();
    slicedLocations.splice(index, 1);
    changeHandler(slicedLocations);
  };

  const onLocationItemChange = (newLocation: LocationDto | undefined, index: number) => {
    if (!newLocation) {
      return;
    }

    const selectedRemotePosition = newLocation.country === REMOTE_POSITION.country;
    remotePositionChangeHandler(selectedRemotePosition);

    const slicedLocations = locations.slice();
    slicedLocations[index] = newLocation;
    changeHandler(slicedLocations);
  };

  const onLocationInputChange = (index: number) => {
    if (remotePosition) {
      remotePositionChangeHandler(false);
    }

    if (!locations[index]) {
      return;
    }

    const slicedLocations = locations.slice();
    slicedLocations[index] = {} as ExactLocationDto;
    changeHandler(slicedLocations);
  };

  const renderLocationItem = (index: number) => {
    const location = locations[index] || {} as ExactLocationDto;
    const isPrimaryLocation = index === 0;

    const placeholderKey = isPrimaryLocation ?
      STRING_KEYS.MATCH_TUNE.LOCATIONS_STEP.PRIMARY_LOCATION_PLACEHOLDER :
      STRING_KEYS.MATCH_TUNE.LOCATIONS_STEP.SECONDARY_LOCATION_PLACEHOLDER;

    const labelKey = isPrimaryLocation ?
      STRING_KEYS.MATCH_TUNE.LOCATIONS_STEP.PRIMARY_LOCATION_LABEL :
      STRING_KEYS.MATCH_TUNE.LOCATIONS_STEP.SECONDARY_LOCATION_LABEL;

    const LabelIcon = isPrimaryLocation ? StarIcon : AddBoxIcon;

    return (
      <LocationItem
        key={ `${location?.country}_${index}` }
        location={ location }
        changeHandler={ (newLocation) => onLocationItemChange(newLocation, index) }
        inputChangeHandler={ () => onLocationInputChange(index) }
        removeButtonClickHandler={ () => onRemoveLocationItemButtonClick(index) }
        disableRemoveButton={ index === 0 }
        selectedCountry={ selectedCountry }
        showRemoteOptions={ locations?.length <= 1 }
        locationAutocompletePlaceholder={ translate(placeholderKey) }
        locationAutocompleteLabel={ (
          <LocationAutocompleteLabelWrapper>
            <LabelIcon fontSize="small" />
            { translate(labelKey) }
          </LocationAutocompleteLabelWrapper>
        ) }
      />
    );
  };

  return (
    <div { ...otherProps }>
      <LocationsContainer>
        { Array.from(Array(locationsAmount).keys()).map(renderLocationItem) }
      </LocationsContainer>
      <AddLocationButton
        hoverVariant={ PerfectButtonHoverVariants.Grow }
        size={ PerfectButtonSize.Small }
        onClick={ onAddLocationButtonClick }
        StartIcon={ AddLocationButtonIcon }
        disabled={ isRemotePosition }
      >
        { translate(STRING_KEYS.MATCH_TUNE.LOCATIONS_STEP.ADD_ANOTHER_LOCATION_BUTTON_TEXT) }
      </AddLocationButton>
    </div>
  );
};
