import { convertToHTML } from 'draft-convert';
import React from 'react';
import {
  ContentBlock, ContentState, EditorState, Modifier, RawDraftInlineStyleRange, SelectionState,
} from 'draft-js';
import { stringToCamelCase } from '../../../utils';
import { PerfectTextEditorBlockAlignment } from './perfect-text-editor.enums';
import { DECORATORS_NAMES } from './decorators/decorators.consts';

interface InlineStyleRange extends Omit<RawDraftInlineStyleRange, 'style'> {
  style: string;
}

const replaceTemplateToRegularText = (type: string, originalText: string) => {
  if (type === DECORATORS_NAMES.MENTION) {
    return `{{${stringToCamelCase(originalText)}}}`.replace(/<[^>]*>?/gm, '');
  }

  return `${originalText}`;
};

export const contentToHtml = (content: ContentState, convertMentions = false): string => {
  return convertToHTML({
    entityToHTML: (entity, originalText) => {
      if (convertMentions) {
        return replaceTemplateToRegularText(entity.type, originalText);
      }

      return originalText;
    },
    // eslint-disable-next-line consistent-return
    blockToHTML: (block) => {
      let toReturn;
      if (block.text.length === 0) {
        return React.createElement('br', null);
      }

      block.inlineStyleRanges.forEach(({ style }: InlineStyleRange) => {
        if (style === PerfectTextEditorBlockAlignment.Right) {
          toReturn = React.createElement('p', { style: { 'text-align': 'right' } });
        } else if (style === PerfectTextEditorBlockAlignment.Left) {
          toReturn = React.createElement('p', { style: { 'text-align': 'left' } });
        } else if (style === PerfectTextEditorBlockAlignment.Center) {
          toReturn = React.createElement('p', { style: { 'text-align': 'center' } });
        }
      });

      if (toReturn) {
        return toReturn;
      }
    },
  })(content);
};

export const convertMentionsToTokens = (content: ContentState, responseAsPlanText = false): string => {
  const html = convertToHTML({
    entityToHTML: (entity, originalText) => {
      return replaceTemplateToRegularText(entity.type, originalText);
    },
  })(content);

  if (responseAsPlanText) {
    const match = html.match(/<p>(.*?)<\/p>/);

    if (match) {
      return match[1];
    }
  }

  return html;
};

export const newEntityLocationStrategy = (type: string) => {
  return (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
  ): void => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();

      return (
        entityKey !== null &&
        contentState.getEntity(entityKey)
          .getType() === type
      );
    }, callback);
  };
};

export const getCaretPosition = (editorState: EditorState): number => {
  return editorState.getSelection().getAnchorOffset();
};

export const getCurrentBlock = (editorState: EditorState): ContentBlock | null => {
  if (editorState.getSelection) {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    return contentState.getBlockForKey(selectionState.getStartKey());
  }

  return  null;
};

export const getText = (editorState: EditorState, start: number, end: number): string => {
  const block = getCurrentBlock(editorState);
  const blockText = block?.getText();

  return blockText?.substring(start, end) || '';
};

export const parseSavedMentions = (editorState: EditorState): EditorState => {
  let contentState = editorState.getCurrentContent();
  const contentBlocks = contentState.getBlocksAsArray();

  contentBlocks.forEach((contentBlock, index) => {
    if (contentBlock) {
      let text = contentBlock.getText();
      let match = /{{(.*?)}}/gm.exec(text);
      const blockKey = contentBlock.getKey();
      while (match) {
        const matchStartIndex = match.index;
        const matchEndIndex = matchStartIndex + match[0].length;
        const wordsList = match[1].replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
        const mentionLabel = wordsList?.reduce((acc: string, word: string) => {
          return  `${acc + word.charAt(0).toUpperCase() + word.slice(1)}`;
        }, '');

        if (mentionLabel) {
          const contentStateWithEntity = contentState.createEntity(
            DECORATORS_NAMES.MENTION,
            'IMMUTABLE',
            mentionLabel,
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
          contentState = Modifier.replaceText(
            contentStateWithEntity,
            new SelectionState({
              anchorKey: blockKey,
              anchorOffset: matchStartIndex,
              focusKey: blockKey,
              focusOffset: matchEndIndex,
              isBackward: false,
              hasFocus: true,
            }),
            mentionLabel,
            undefined,
            entityKey,
          );

          editorState = EditorState.push(editorState, contentState, 'apply-entity');
          text = editorState.getCurrentContent().getBlocksAsArray()[index].getText();
          match = /{{(.*?)}}/gm.exec(text);
        }
      }
    }
  });

  return editorState;
};
