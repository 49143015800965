import {
  ActiveStep, CompletedStep, Container, EmptyStep, VSignIcon,
} from './steps.styled';

const OutreachSteps = ({
  totalSteps,
  completedSteps,
  activeStepColor,
  completedStepsColor,
}: StepsProps): JSX.Element => {
  const renderStep = (stepIndex: number) => {
    if (stepIndex < completedSteps) {
      return (
        <CompletedStep
          key={ stepIndex }
          color={ completedStepsColor }
        >
          <VSignIcon />
        </CompletedStep>
      );
    }

    if (stepIndex === completedSteps) {
      return <ActiveStep key={ stepIndex } color={ activeStepColor } />;
    }

    return <EmptyStep key={ stepIndex } />;
  };

  return (
    <Container>
      { Array.from(Array(totalSteps)).map((_, index) => renderStep(index)) }
    </Container>
  );
};

export default OutreachSteps;
