import { Avatar } from '@mui/material';
import { ASSETS } from '../../../assets';
import { Icons, useStyles } from './icon-list.styled';

type IconListProps = {
    iconsUrls: string[]
}

export const IconList = ({
  iconsUrls,
}: IconListProps):
JSX.Element => {
  const classes = useStyles();

  return (
    <Icons max={ 3 } classes={ { avatar: classes.avatar } }>
      {iconsUrls && iconsUrls.map((iconUrl: string) => {
        return (
          <Avatar
            key={ iconUrl }
            src={ iconUrl }
          >
            <img src={ ASSETS.EXPERIENCE_FALLBACK } alt="Company" />
          </Avatar>
        );
      })}
    </Icons>
  );
};
