import { CaseReducer, AnyAction } from '@reduxjs/toolkit';
import { AppState } from '../app-state.initial-state';
import { removeFromArray } from '../../../../utils';
import { FATAL_ERRORS_CODE, INITIAL_LOADING } from '../app-state.consts';

export const getPositionsFulfilledExtraReducer: CaseReducer<AppState, AnyAction> = (state) => {
  state.initialLoadings = removeFromArray(state.initialLoadings, INITIAL_LOADING.LOAD_POSITIONS);
};

export const getPositionsRejectExtraReducer: CaseReducer<AppState, AnyAction> = (state, { payload }) => {
  state.initialLoadings = removeFromArray(state.initialLoadings, INITIAL_LOADING.LOAD_POSITIONS);
  const { serverError } = payload.error;
  if (serverError.statusCode >= 500) {
    state.fatalErrorCode = FATAL_ERRORS_CODE.POSITIONS_FROM_SERVER_FAILED;
    state.fatalErrorMessage = serverError.message;
    state.fatalErrorInfo = serverError;
  }
};
