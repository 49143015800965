import {
  BackdropComponent, SidePanelComponent,
} from './side-panel.styled';
import { SidePanelProps } from './side-panel';
import { SidePanelDirection, SidePanelVariant } from './side-panel.enums';

const SidePanel = ({
  isOpen, onClose, children, hideBackdrop, direction = SidePanelDirection.Right, variant = SidePanelVariant.Temporary,
}: SidePanelProps): JSX.Element => {
  return (
    <SidePanelComponent
      hideBackdrop={ hideBackdrop }
      anchor={ direction }
      variant={ variant }
      open={ isOpen }
      onClose={ onClose }
      BackdropComponent={ BackdropComponent }
    >
      { children }
    </SidePanelComponent>
  );
};

export default SidePanel;
