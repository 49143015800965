import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { ASSETS } from '../../../../../../assets';

export const Icon = styled(ASSETS.QUOTE)`
  width: 16px;
`;

export const QuoteText = styled.div`
  font-family: ${THEME.FONT_FAMILY.MERRIWEATHER};
  font-weight: 300;
  
  strong {
    font-family: ${THEME.FONT_FAMILY.MERRIWEATHER};
  }

  :before {
    content: '“';
  }

  :after {
    content: '”';
  }
`;
