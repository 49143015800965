import { accountQuery } from '../account.toolkit-api';
import { useAppSelector } from '../../../../selectors';
import { AccountInfoResponse } from '../dto/response/account-info.response';
import { useMyUserSelector } from '../../user/selectors/get-my-user.selector';

export const accountByIdSelector = accountQuery.endpoints.getAccountById.select;

export const useGetMyAccountSelector = (): AppSelectorType<AccountInfoResponse> => {
  const { data: { accountId } } = useMyUserSelector();
  const {
    data: account, isLoading, isError, isSuccess, error,
  } = useAppSelector(accountByIdSelector(accountId));

  return {
    data: account || {} as AccountInfoResponse,
    error,
    isLoading,
    isError,
    isSuccess,
  };
};
