import styled from 'styled-components';
import { THEME } from '../../../themes';
import { Title1Font } from '../../../themes/fonts';
import { circleGrowUpAnimation } from '../../../animations';

export const FullSizeScreen = styled.div`
  ${Title1Font};
  min-width: 100vw;
  min-height: 100vh;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${THEME.COLORS.PURE_WHITE};
  line-height: 1.64;
  font-weight: 300;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Circle = styled.div`
  width: 20px;
  height: 20px;
  background: ${THEME.COLORS.ACCENT};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  animation: ${circleGrowUpAnimation({
    animationDuration: 200,
    animationDelay: 0,
  })};
  position: fixed;
  z-index: 1;
`;
