import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import StepContainer from '../step-container/step-container.component';
import STRING_KEYS from '../../../../../../language/keys';
import {
  EditPositionAutocomplete,
} from '../../../../../create-position/components/autocompletes/position-autocomplete/edit-position-autocomplete/edit-position-autocomplete.component';
import { useGetSimilarTitlesQuery } from '../../../../../../store/rtk-query/apis/position/hooks/get-similar-titles.query-hook';
import { SimilarTitle } from '../../../../../../store/rtk-query/apis/position/dto/response/position.response';
import { IndeterminateCircularLoader } from '../../../../../../components/Common/Loaders/IndeterminateCircularLoader';
import { InputStyle } from '../../../../../onboarding/components/input/input.component';
import useDebounce from '../../../../../../hooks/debounce';
import { PressEnterMessage } from '../../../../../create-position/components/press-enter-message/press-enter-message.component';
import { ANALYTICS_EVENTS } from '../../../../../../consts/analytics';
import {
  Card,
  Container,
  Description,
  InputContainer,
  InputIcon,
  SimilarItemsContainer,
  SimilarTitlesLoadingWrapper,
  StyledAutocomplete,
  Title,
} from './job-title-step.styled';
import SimilarTitleItem from './similar-title-item/similar-title-item.component';
import { SimilarTitleStatus } from './similar-title-item/similar-title-item.enums';
import { JobTitleStepProps } from './job-title-step';

const createSimilarTitleMap = (titles: SimilarTitle[]) => new Map(titles?.map((title) => [title.title, title]) || []);

const JobTitleStep = ({
  jobTitle,
  positionId,
  prioritizedTitles,
  excludedTitles,
  jobTitleChangeHandler,
  prioritizedTitlesChangeHandler,
  excludedTitlesChangeHandler,
  backButtonClickHandler,
}: JobTitleStepProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [internalJobTitle, setInternalJobTitle] = useState(jobTitle);
  const debouncedInternalJobTitle = useDebounce(internalJobTitle, 500);
  const [internalPrioritizedTitles, setInternalPrioritizedTitles] = useState(createSimilarTitleMap(prioritizedTitles));
  const [internalExcludedTitles, setInternalExcludedTitles] = useState(createSimilarTitleMap(excludedTitles));
  const {
    data: similarTitles,
    isLoading: isSimilarTitlesLoading,
    isFetching,
    isSuccess: isSimilarTitlesSuccess,
  } = useGetSimilarTitlesQuery({ title: debouncedInternalJobTitle, positionId });
  const [userInput, setUserInput] = useState('');
  const [userInputSimilarTitles, setUserInputSimilarTitles] = useState<SimilarTitle[]>([]);
  const [isInputFocused, setInputFocused] = useState<boolean>(false);

  const isDataChanged = useMemo(
    () => jobTitle !== internalJobTitle ||
      !isEqual(prioritizedTitles, Array.from(internalPrioritizedTitles.values())) ||
      !isEqual(excludedTitles, Array.from(internalExcludedTitles.values())),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [internalJobTitle, internalPrioritizedTitles, internalExcludedTitles]);

  // Add similar titles from user input
  useEffect(() => {
    if (isSimilarTitlesSuccess) {
      const titlesSet = new Set(similarTitles?.map((title) => title.title));

      const missingSimilarTitles = [
        ...prioritizedTitles.filter((title) => !titlesSet.has(title.title)),
        ...excludedTitles.filter((title) => !titlesSet.has(title.title)),
      ];

      setUserInputSimilarTitles(missingSimilarTitles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSimilarTitlesSuccess]);

  const onJobTitleChange = (changedValue: string) => {
    setInternalJobTitle(changedValue);

    if (internalPrioritizedTitles.size) {
      setInternalPrioritizedTitles(new Map());
    }

    if (internalExcludedTitles.size) {
      setInternalExcludedTitles(new Map());
    }

    if (userInputSimilarTitles.length) {
      setUserInputSimilarTitles([]);
    }
  };

  const onAcceptButtonClick = (title: SimilarTitle) => {
    const updatedPrioritizedJobTitles = new Map(internalPrioritizedTitles);
    const updatedExcludedJobTitles = new Map(internalExcludedTitles);

    if (updatedPrioritizedJobTitles.has(title.title)) {
      updatedPrioritizedJobTitles.delete((title.title));
    } else {
      updatedPrioritizedJobTitles.set(title.title, title);
    }

    updatedExcludedJobTitles.delete(title.title);
    setInternalPrioritizedTitles(updatedPrioritizedJobTitles);
    setInternalExcludedTitles(updatedExcludedJobTitles);
  };

  const onRejectButtonClick = (title: SimilarTitle) => {
    const updatedPrioritizedJobTitles = new Map(internalPrioritizedTitles);
    const updatedExcludedJobTitles = new Map(internalExcludedTitles);

    if (updatedExcludedJobTitles.has(title.title)) {
      updatedExcludedJobTitles.delete(title.title);
    } else {
      updatedExcludedJobTitles.set(title.title, title);
    }

    updatedPrioritizedJobTitles.delete(title.title);
    setInternalPrioritizedTitles(updatedPrioritizedJobTitles);
    setInternalExcludedTitles(updatedExcludedJobTitles);
  };

  const getSimilarTitleStatus = (title: string) => {
    if (internalPrioritizedTitles.has(title)) {
      return SimilarTitleStatus.Accepted;
    }
    if (internalExcludedTitles.has(title)) {
      return SimilarTitleStatus.Rejected;
    }

    return undefined;
  };

  const renderSimilarTitles = () => {
    return [...(similarTitles || []), ...userInputSimilarTitles].map((title) => (
      <SimilarTitleItem
        key={ `${title.title}_${title.normalizedTitle}` }
        text={ title.title }
        status={ getSimilarTitleStatus(title.title) }
        acceptHandler={ () => onAcceptButtonClick(title) }
        rejectHandler={ () => onRejectButtonClick(title) }
      />
    ));
  };

  const onContinueButtonClick = () => {
    jobTitleChangeHandler(internalJobTitle);
    prioritizedTitlesChangeHandler(Array.from(internalPrioritizedTitles.values()));
    excludedTitlesChangeHandler(Array.from(internalExcludedTitles.values()));
  };

  const addUserInputSimilarTitle = () => {
    if (!userInput) {
      return;
    }

    const slicedUserInputSimilarTitles = userInputSimilarTitles.slice();
    slicedUserInputSimilarTitles.push({
      title: userInput,
    });
    setUserInputSimilarTitles(slicedUserInputSimilarTitles);
    setUserInput('');
  };

  const renderSimilarTitlesContainer = () => {
    if (isSimilarTitlesLoading || isFetching) {
      return (
        <SimilarItemsContainer>
          <SimilarTitlesLoadingWrapper>
            <IndeterminateCircularLoader />
            { translate(STRING_KEYS.MATCH_TUNE.JOB_TITLE_STEP.SIMILAR_TITLES_LOADING_TEXT) }
          </SimilarTitlesLoadingWrapper>
        </SimilarItemsContainer>
      );
    }

    return (
      <SimilarItemsContainer>
        { renderSimilarTitles() }
        <InputContainer>
          <InputIcon />
          <StyledAutocomplete
            InstructionsMessage={ <PressEnterMessage translationKey={ STRING_KEYS.PRESS_ENTER } /> }
            optionsTitle=""
            autoFocus={ false }
            isLoading={ false }
            placeholder={ translate(STRING_KEYS.MATCH_TUNE.JOB_TITLE_STEP.JOB_TITLE_INPUT_PLACEHOLDER) }
            inputValue={ userInput }
            onValueChange={ (value) => setUserInput(value) }
            isInputFocused={ isInputFocused }
            setInputFocused={ setInputFocused }
            isDisableOptions
            inputStyle={ InputStyle.Onboarding }
            onEnterClick={ addUserInputSimilarTitle }
          />
        </InputContainer>
      </SimilarItemsContainer>
    );
  };

  return (
    <StepContainer
      title={ translate(STRING_KEYS.MATCH_TUNE.JOB_TITLE_STEP.TITLE) }
      subtitle={ translate(STRING_KEYS.MATCH_TUNE.JOB_TITLE_STEP.SUBTITLE) }
      backButtonHandler={ backButtonClickHandler }
      continueButtonHandler={ onContinueButtonClick }
      isContinueButtonDisabled={ !isDataChanged }
      eventName={ ANALYTICS_EVENTS.MATCH_TUNE_JOB_TITLE_STEP_VIEWED }
    >
      <Container>
        <Card>
          <Title>{ translate(STRING_KEYS.MATCH_TUNE.JOB_TITLE_STEP.JOB_TITLE_INPUT_TITLE) }</Title>
          <EditPositionAutocomplete
            jobTitle={ internalJobTitle }
            changeHandler={ onJobTitleChange }
            completeHandler={ onJobTitleChange }
          />
        </Card>
        <Card>
          <Title>{ translate(STRING_KEYS.MATCH_TUNE.JOB_TITLE_STEP.SIMILAR_TITLES_TITLE) }</Title>
          <Description>{ translate(STRING_KEYS.MATCH_TUNE.JOB_TITLE_STEP.SIMILAR_TITLES_DESCRIPTION) }</Description>
          { renderSimilarTitlesContainer() }
        </Card>
      </Container>
    </StepContainer>
  );
};

export default JobTitleStep;
