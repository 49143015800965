import styled, { css } from 'styled-components';
import { CircularProgress } from '@mui/material';
import { THEME } from '../../../../themes';
import { ButtonFont, CaptionFont } from '../../../../themes/fonts';
import { PerfectButtonHoverVariants, PerfectButtonSize, PerfectButtonVariants } from './perfect-base-button.enums';

const buttonSizeMap: {[key: string]: string} = {
  [PerfectButtonSize.Small]: '32px',
  [PerfectButtonSize.Medium]: '40px',
  [PerfectButtonSize.Large]: '48px',
  [PerfectButtonSize.ExtraLarge]: '56px',
};

const DarkenedCss = css`
  background-blend-mode: darken;
  background-image: linear-gradient(rgb(0 0 0/10%) 0 0);
`;

export const GrowCss = css`
  transform: scale(1.05);
`;

export const UnderlineCss = css`
  text-decoration: underline;
`;

const HoverOutline = css`
  &:hover {
    background-color: currentColor;
  }
  &:hover span {
    color: white;
  }
`;

const getHoverCss = (hoverVariant = PerfectButtonHoverVariants.Darkened, variant: PerfectButtonVariants) => {
  if (hoverVariant === PerfectButtonHoverVariants.Grow) {
    return GrowCss;
  }

  if (hoverVariant === PerfectButtonHoverVariants.Underline) {
    return UnderlineCss;
  }

  if (hoverVariant === PerfectButtonHoverVariants.Darkened && variant === PerfectButtonVariants.Fill) {
    return DarkenedCss;
  }

  if (variant === PerfectButtonVariants.Outline || variant === PerfectButtonVariants.Ghost) {
    return HoverOutline;
  }

  return undefined;
};

const ripple = (hoverVariant = PerfectButtonHoverVariants.Darkened, variant: PerfectButtonVariants) => {
  const HoverCss = getHoverCss(hoverVariant, variant);

  return css`
    &:not([disabled]):not(.disabled) {
      overflow: hidden;
      transition: transform 0.1s;
      
      &:hover {
        ${HoverCss};
        transition: all 0.25s ease;
      }

      &:active {
        transform: scale(0.9);
      }
    }
  `;
};

const BaseButtonStyle = css`
  padding: 0 ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.CIRCLE};
`;

const LinkButtonCss = css`
  background-color: inherit;
  color: ${THEME.COLORS.PRIMARY};
  fill: currentColor;
  ${CaptionFont};
`;

const GhostButtonCss = css`
  ${BaseButtonStyle};
  background-color: inherit;
  color: ${THEME.COLORS.PRIMARY};
  fill: currentColor;
`;

const OutlineButtonCss = css`
  ${BaseButtonStyle};
  background-color: ${THEME.COLORS.PURE_WHITE};
  color: ${THEME.COLORS.PRIMARY};
  border: 2px solid currentcolor;
  fill: currentColor;
`;

const FillButtonCss = css`
  ${BaseButtonStyle};
  color: ${THEME.COLORS.PURE_WHITE};
  background-color: ${THEME.COLORS.PRIMARY};
  fill: currentColor;
`;

const getFont = (variant: PerfectButtonVariants) => {
  if (variant === PerfectButtonVariants.Link) {
    return CaptionFont;
  }

  return ButtonFont;
};

const getVariantButtonCss = (variant: PerfectButtonVariants) => {
  if (variant === PerfectButtonVariants.Fill) {
    return FillButtonCss;
  }

  if (variant === PerfectButtonVariants.Outline) {
    return OutlineButtonCss;
  }

  if (variant === PerfectButtonVariants.Ghost) {
    return GhostButtonCss;
  }

  if (variant === PerfectButtonVariants.Link) {
    return LinkButtonCss;
  }

  return FillButtonCss;
};

const getSizeButtonCss = (variant: PerfectButtonVariants, size: PerfectButtonSize) => {
  if (variant === PerfectButtonVariants.Link) {
    return undefined;
  }

  const height = buttonSizeMap[size];

  return css`
    height: ${height};
  `;
};

export const ButtonContainer = styled.button<{ size: PerfectButtonSize, variant: PerfectButtonVariants, hoverVariant?: PerfectButtonHoverVariants}>`
  cursor: pointer;
  outline: none;
  position: relative;
  border: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  background-blend-mode: lighten;
  flex: 0 0 auto;
  ${({ hoverVariant, variant }) => {
    return ripple(hoverVariant, variant);
  }}
  ${({ variant }) => {
    return getFont(variant);
  }}
  ${({ variant, size }) => {
    return getSizeButtonCss(variant, size);
  }}
  ${({ variant }) => {
    return getVariantButtonCss(variant);
  }}
  &:disabled {
    opacity: ${THEME.OPACITY.LOW};
    cursor: default;
  }
`;

export const StartIconContainer = styled.span` 
  display: flex;
  margin-left: calc(${THEME.SPACING.MICRO} * -1);
  margin-right: ${THEME.SPACING.TINY};
`;

export const EndIconContainer = styled.span` 
  display: flex;
  margin-left: ${THEME.SPACING.TINY};
`;

export const TextContainer = styled.span` 
  color: currentColor;
`;

export const Loader = styled(CircularProgress)`
  ${ButtonFont};
`;
