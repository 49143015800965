import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import {
  FormControl, FormControlLabel, RadioGroup,
} from '@mui/material';
import SelectionPopup from '../selection-popup/selection-popup.container';
import STRING_KEYS from '../../../../../../../language/keys';
import {
  CUSTOM_MODE_NEXT_BUSINESS_DAY_VALUE,
  CUSTOM_MODE_SOON_AS_POSSIBLE_VALUE,
} from '../../campaign-custom-mode.consts';
import PerfectRadio from '../../../../../../../components/Common/perfect-radio/perfect-radio.component';
import {
  Container,
  StyledFormControlLabel,
} from './select-first-message-day-popup.styled';

const SelectFirstMessageDayPopup = ({ time, confirmButtonClickHandler }: SelectFirstMessageTimePopupProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [value, setValue] = useState<string>(time ? CUSTOM_MODE_NEXT_BUSINESS_DAY_VALUE : CUSTOM_MODE_SOON_AS_POSSIBLE_VALUE);

  const handleRadioGroupChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <SelectionPopup
      title={ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TIME.TITLE) }
      withFooterAction
      actionHandler={ () => confirmButtonClickHandler(value) }
    >
      <Container>
        <FormControl>
          <RadioGroup
            defaultValue={ value }
            onChange={ handleRadioGroupChange }
          >
            <FormControlLabel
              value={ CUSTOM_MODE_SOON_AS_POSSIBLE_VALUE }
              control={ <PerfectRadio /> }
              label={ (
                <StyledFormControlLabel>
                  { translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.FIRST_STEP_MESSAGE_AS_SOON_AS_POSSIBLE) }
                </StyledFormControlLabel>
              ) }
            />
            <FormControlLabel
              value={ CUSTOM_MODE_NEXT_BUSINESS_DAY_VALUE }
              control={ <PerfectRadio /> }
              label={ (
                <StyledFormControlLabel>
                  { translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.FIRST_STEP_MESSAGE_NEXT_BUSINESS_DAY) }
                </StyledFormControlLabel>
              ) }
            />
          </RadioGroup>
        </FormControl>
      </Container>
    </SelectionPopup>
  );
};

export default SelectFirstMessageDayPopup;
