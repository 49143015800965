import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { ButtonFont } from '../../../../themes/fonts';

const {
  SPACING, COLORS, RADIUS, OPACITY,
} = THEME;

// used to add "effect" to the button when hover/active/disabled etc.
// this way, we don't need to define a new color (in the theme) with opacity.
const overlayerStyle = `
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: ${THEME.COLORS.PURE_WHITE};
  content: '';
`;

export const StyledPrimaryButton = styled.button<{
  disabled?: boolean,
  disableMargin?: boolean;
}>`
  ${ButtonFont};
  width: 100%;
  ${({ disableMargin }) => disableMargin ? '' : `
    margin: ${THEME.SPACING.BASE} 0 ${THEME.SPACING.LOOSE} 0;`}
  padding-top: ${SPACING.BASE};
  padding-bottom: ${SPACING.BASE};
  border-radius: ${RADIUS.CIRCLE};
  background-color: ${COLORS.ACCENT};
  border: none;
  outline: none;
  text-align: center;
  color: ${COLORS.PURE_WHITE};
  cursor: pointer;
  position: relative;
  transition: all 0.2s;
  ${({ disabled }) => disabled ? `
    pointer-events: none;
    opacity: ${THEME.OPACITY.LOW};
    ` : ''}
  &:hover {
    &::after {
      ${overlayerStyle};
      opacity: ${OPACITY.DIVIDER};
    }
  }
  &:active {
    &::after {
      ${overlayerStyle};
      opacity: 0.24;
    }
  }
`;
