import {
  setOnboardingContinueButtonDisable,
  setOnboardingContinueButtonEnable,
} from '../../../../store/slices/onboarding-state/onboarding-state.toolkit-slice';
import CreatePositionBackgroundStep
  from '../../../create-position/components/create-position-background-step/create-position-background-step.component';

export const OnboardingBackgroundPage = (): JSX.Element => {
  return (
    <CreatePositionBackgroundStep
      setContinueButtonEnable={ setOnboardingContinueButtonEnable }
      setContinueButtonDisable={ setOnboardingContinueButtonDisable }
      isOnboarding
    />
  );
};
