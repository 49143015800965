import { forwardRef, ReactElement, Ref } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { DialogBody } from './base-dialog-body.styled';

const BaseDialogBody = forwardRef((
  props: TransitionProps & {
    children: ReactElement | ReactElement[] | string;
  },
  ref: Ref<HTMLDivElement>,
) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DialogBody { ...props } ref={ ref }>
      { props.children }
    </DialogBody>
  );
});

BaseDialogBody.displayName = 'BaseDialogBody';

export default BaseDialogBody;
