import styled from 'styled-components';
import  CountUp  from 'react-countup';
import { THEME } from '../../../../../themes';
import { CaptionFont, HeadlineFont } from '../../../../../themes/fonts';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const  ExperienceTitle = styled.div`
  ${CaptionFont};
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  margin-bottom: ${THEME.SPACING.MICRO};
`;
const Title = styled.div`
 ${HeadlineFont};
 color:${THEME.COLORS.CONTENT_PRIMARY};
`;
export const  SkillsCounter = styled(CountUp)`
 ${HeadlineFont};
  color:${THEME.COLORS.CONTENT_PRIMARY};
  font-weight: bold;
  color: ${THEME.COLORS.ACCENT};
`;
export const  SkillsTitle = styled(Title)`
  font-weight: bold;
`;
export const  Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${THEME.SPACING.MICRO};
  margin-bottom: ${THEME.SPACING.BASE};
`;

export const Collapse = styled.div<{isOpen:boolean}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: ${({ isOpen }) => isOpen ? '100%' : '0'};
  overflow: hidden;
  margin-top: -${THEME.SPACING.BASE};
  position: relative;
  transition: max-height 0.5s ease;
`;
