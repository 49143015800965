import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import { CompanyAutocomplete } from '../../autocompletes/company-autocomplete/company-autocomplete.component';
import { Sentence } from '../../../../../components/Common/sentence-component/sentence.component';

type CompanySentenceProps = {
  onSentenceCompleted: () => void,
  onCompanyChange: () => void,
  onOpenCompanyDialog: () => void,
}

export const CompanySentence = ({
  onSentenceCompleted,
  onCompanyChange,
  onOpenCompanyDialog,
}: CompanySentenceProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Sentence
      text={ translate(STRING_KEYS.AT) }
      Item={ (
        <CompanyAutocomplete
          onSentenceCompleted={ onSentenceCompleted }
          onCompanyChange={ onCompanyChange }
          onOpenCompanyDialog={ onOpenCompanyDialog }
        />
      ) }
      shouldAddDot
    />
  );
};
