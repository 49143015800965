import { HEADER_HEIGHT } from '../../../../../components/Common/headers/header/header.styled';
import { LARGE_BANNER_HEIGHT_PX } from '../outreach-banner/outreach-banner.styled';
import { THEME } from '../../../../../themes';

export const calculateOutreachPageHeight = (
  { isBannerVisible }: { isBannerVisible: boolean },
): string => {
  const additionalHeightSize = isBannerVisible ? LARGE_BANNER_HEIGHT_PX : 0;
  const additionalPaddingSize = isBannerVisible ? THEME.SPACING.BASE : 0;

  return `calc(100vh - ${HEADER_HEIGHT + additionalHeightSize}px ${additionalPaddingSize ? `- ${additionalPaddingSize}` : ''} - ${THEME.SPACING.TINY})`;
};
