import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import BasePositionAssistant from '../base-position-assistant/base-position-assistant.component';
import ProgressBar from './progress-bar/progress-bar.component';

const BUILD_STRONGER_CANDIDATE_PIPELINE_RATE = 200;
const MAX_CANDIDATE = 50;

const BuildStrongerCandidatePipeline = ({
  actionHandler, progress,
}: BuildStrongerCandidatePipelineProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BasePositionAssistant
      title={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.BUILD_STRONGER_CANDIDATE_PIPELINE.TITLE, {
        count: MAX_CANDIDATE - progress,
      }) }
      description={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.BUILD_STRONGER_CANDIDATE_PIPELINE.DESCRIPTION) }
      Icon={ () => <ProgressBar progress={ progress * 2 } /> }
      rate={ BUILD_STRONGER_CANDIDATE_PIPELINE_RATE }
      action={ {
        title: translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.BUILD_STRONGER_CANDIDATE_PIPELINE.ACTION_TITLE),
        handler: actionHandler,
      } }
    />
  );
};

export default BuildStrongerCandidatePipeline;
