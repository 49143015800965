import styled from 'styled-components';
import { DividerSection } from '../../../../../components/Common/Divider/styled';
import { THEME } from '../../../../../themes';

export const Container = styled.div`
  padding-bottom: ${THEME.SPACING.LOOSE};
`;

export const Divider = styled(DividerSection)`
  margin-bottom: ${THEME.SPACING.LOOSE};
`;
