import { ApiError } from '../../../../../consts/api';
import { positionQuery } from '../position.toolkit-api';
import { LinkPositionToAtsQueryArguments } from '../dto/query-arguments/link-position-to-ats.query-arguments';
import { LinkedAtsJobResponse } from '../dto/response/position.response';

type UseLinkPositionToAtsMutationType = [
  ({
    positionId, atsJobId, atsJobTitle, atsIntegrationId, syncAutomatically,
  }: LinkPositionToAtsQueryArguments) => void,
  AppSelectorType<LinkedAtsJobResponse, ApiError>
];

export const useLinkPositionToAtsMutation = (): UseLinkPositionToAtsMutationType => {
  const [
    linkPositionToAts,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = positionQuery.useLinkPositionToAtsMutation();

  const meta = {
    isLoading, isError, error: error as ApiError, isSuccess, data, resetState: reset,
  };

  return [
    linkPositionToAts,
    { ...meta },
  ];
};
