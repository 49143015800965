import { useTranslation } from 'react-i18next';
import InsightsCard from '../base-insights-card/insights-card.component';
import { InsightsCardDescription, InsightsCardTitle } from '../base-insights-card/insights-card.styled';
import { THEME } from '../../../../../../themes';
import STRING_KEYS from '../../../../../../language/keys';
import {
  TimerIcon,
} from './time-saved-insight-card.styled';
import { SAVED_TIME_SUFFIX } from './time-saved-insight-card.consts';

const TimeSavedInsightCard = ({ time }: TimeSavedInsightCardProps):JSX.Element => {
  const { t: translate } = useTranslation();

  const InsightsTitle: JSX.Element = (
    <>
      <TimerIcon />
      <InsightsCardTitle
        color={ THEME.COLORS.SECONDARY }
      >
        { translate(STRING_KEYS.DAILY_LIMIT.INSIGHTS.TIME_SAVED_TITLE) }
      </InsightsCardTitle>
    </>
  );

  const InsightsDescription: JSX.Element = (
    <InsightsCardDescription>
      { translate(STRING_KEYS.DAILY_LIMIT.INSIGHTS.TIME_SAVED_DESCRIPTION) }
    </InsightsCardDescription>
  );

  return (
    <InsightsCard
      title={ InsightsTitle }
      insightValueBold={ time }
      insightValue={ SAVED_TIME_SUFFIX }
    >
      { InsightsDescription }
    </InsightsCard>
  );
};

export default TimeSavedInsightCard;
