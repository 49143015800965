import styled, { css } from 'styled-components';
import { THEME } from '../../../../../../themes';
import { scaleUpCenterAnimation } from '../../../../../../animations';
import { MeterBarStyleEnum } from './meter.enums';

const STEP_WIDTH_HORIZONTAL = '56px';
const STEP_WIDTH_VERTICAL = '7px';
const STEP_HEIGHT_VERTICAL_ACTIVE = '16px';
const STEP_HEIGHT_VERTICAL_INACTIVE = '12px';
const STEP_HEIGHT_VERTICAL_INACTIVE_FIRST = '14px';
const STEP_HEIGHT_HORIZONTAL_ACTIVE = '10px';
const STEP_HEIGHT_HORIZONTAL_INACTIVE = '4px';
const STEP_HEIGHT_HORIZONTAL_INACTIVE_FIRST = '8px';

const getBarHeight = (barStyle: MeterBarStyleEnum, isActive: boolean, isFirst?: boolean) => {
  if (barStyle === MeterBarStyleEnum.Vertical) {
    if (isActive) {
      return STEP_HEIGHT_VERTICAL_ACTIVE;
    }

    if (isFirst) {
      return STEP_HEIGHT_VERTICAL_INACTIVE_FIRST;
    }

    return STEP_HEIGHT_VERTICAL_INACTIVE;
  }

  if (isActive) {
    return STEP_HEIGHT_HORIZONTAL_ACTIVE;
  }

  if (isFirst) {
    return STEP_HEIGHT_HORIZONTAL_INACTIVE_FIRST;
  }

  return STEP_HEIGHT_HORIZONTAL_INACTIVE;
};

const getBarWidth = (barStyle: MeterBarStyleEnum) => {
  if (barStyle === MeterBarStyleEnum.Vertical) {
    return STEP_WIDTH_VERTICAL;
  }

  return STEP_WIDTH_HORIZONTAL;
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
  justify-content: space-between;
`;

export const EmptyLevel = styled.div<{
  color: string,
  index: number,
  isFirst: boolean,
  isEnableAnimation: boolean,
  barStyle: MeterBarStyleEnum,
}>`
  height: ${({ barStyle, isFirst }) => getBarHeight(barStyle, false, isFirst)};
  width:  ${({ barStyle }) => getBarWidth(barStyle)};
  opacity: ${THEME.OPACITY.DIVIDER};
  border-radius:  ${THEME.RADIUS.CIRCLE};
  background-color: ${({ color }) => color};
  animation: ${({ isEnableAnimation, index }) => {
    return isEnableAnimation ?
      css`${scaleUpCenterAnimation} .4s cubic-bezier(0.175, 0.885, 0.320, 1.275)
      ${(index + 1) * 50}ms both` : 'none';
  }};
`;

export const ActiveLevel = styled.div<{
  color: string,
  index: number,
  isEnableAnimation: boolean,
  barStyle: MeterBarStyleEnum,
}>`
  height: ${({ barStyle }) => getBarHeight(barStyle, true)};
  width:  ${({ barStyle }) => getBarWidth(barStyle)};
  border-radius:  ${THEME.RADIUS.CIRCLE};
  background-color: ${({ color }) => color};
  transition: background-color .4s cubic-bezier(0.85, 0, 0.15, 1);
  animation: ${({ isEnableAnimation, index }) => {
    return isEnableAnimation ?
      css`${scaleUpCenterAnimation} .8s cubic-bezier(0.175, 0.885, 0.320, 1.275)
      ${(index + 1) * 50}ms both` : 'none';
  }};
`;
