import { Trans } from 'react-i18next';
import { useState } from 'react';
import STRING_KEYS from '../../../../../../language/keys';
import { OutreachInitializationDialogs } from '../../../../../../components/dialogs/outreach-initialization-dialogs/outreach-initialization-dialogs.component';
import {
  Container, EnablePermissionsButton, Icon, Text,
} from './enable-read-permission-banner.styled';

const EnableReadPermissionBanner = ({ positionId }: EnableReadPermissionBannerProps): JSX.Element => {
  const [isOutreachInitializationDialogOpen, setIsOutreachInitializationDialogOpen]  = useState(false);

  return (
    <>
      <Container>
        <Icon />
        <Text>
          <Trans
            i18nKey={ STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.INCLUDE_EMAIL_SIGNATURE.ENABLE_READ_PERMISSIONS_BANNER_TEXT }
          >
            <EnablePermissionsButton onClick={ () => setIsOutreachInitializationDialogOpen(true) } />
          </Trans>
        </Text>
      </Container>
      <OutreachInitializationDialogs
        isOpen={ isOutreachInitializationDialogOpen }
        closeDialogHandler={ () => setIsOutreachInitializationDialogOpen(false) }
        disableSuccessDialog
        positionId={ positionId }
      />
    </>
  );
};

export default EnableReadPermissionBanner;
