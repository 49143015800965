import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from '../../reportWebVitals';
import '../../language';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { MicrosoftEmailOAuthFrame } from '../../components/oauth-frames/microsoft-email-oauth-frame/microsoft-email-oauth-frame.component';

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <MicrosoftEmailOAuthFrame />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
