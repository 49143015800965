export const ANALYTICS_EVENTS = {
  MATCH_ENGAGE_BUTTON_CLICKED: 'engage_button_clicked',
  MATCH_DECLINE_BUTTON_CLICKED: 'decline_button_clicked',
  MATCH_ENGAGEMENT_UNDO_BUTTON_CLICKED: 'match_engagement_undo',
  CLICKED_ON_OPEN_MATCH_MODAL: 'clicked_on_open_match_modal',
  CLICKED_ON_REACH_MATCH: 'clicked_on_reach_match',
  MATCH_AUTO_ENGAGE_ACTION_PERFORMED: 'clicked_on_auto_engage',
  MATCH_CONTACT_INFO_SHOWN: 'contact_info_shown',
  USER_LOGGED_IN: 'user_logged_in',
  MATCH_PAGE_VIEWED: 'match_page_viewed',
  MATCH_TUNE_SAVE_BUTTON_CLICKED: 'match_tune_save_button_clicked',
  HOME_PAGE_VIEWED: 'home_page_viewed',
  USER_LOGOUT_CLICKED: 'clicked_on_log_out',
  LOGIN_PAGE_VIEWED: 'login_page_viewed',
  POSITION_ITEM_CLICKED: 'clicked_on_position_card',
  ACCOUNT_KEEP_USING_REQUESTED: 'clicked_on_trial_widget',
  POSITION_CLOSE_CLICK: 'clicked_on_close_position',
  MATCH_DECLINE_REASON_CLICKED: 'clicked_on_decline_reason',
  MATCHES_PAGE_VIEWED: 'matches_page_viewed',
  MATCH_TUNE_PAGE_VIEWED: 'match_tune_page_viewed',
  POSITION_WAITING_MATCHES_PAGE_VIEWED: 'waiting_page_position_page_viewed',
  POSITION_INSIGHTS_PAGE_VIEWED: 'insights_page_viewed',
  POSITION_INSIGHTS_WIDGET_VIEWED: 'insights_widget_viewed',
  POSITION_PROGRESS_BAR_VIEWED: 'match_making_progress_viewed',
  MATCH_CLICK_ON_SOCIAL: 'clicked_on_social',
  MATCH_COMPANY_INSIGHTS_CLICKED: 'clicked_on_company_info',
  MATCH_ELEMENT_VIEWED: 'match_element_viewed',
  COMPONENT_VIEWED: 'component_viewed',
  COMPONENT_EXPANDED: 'component_expanded',
  MATCH_STATUS_CLICKED: 'clicked_on_match_status',
  MATCH_ENGAGE_MODAL_CLICK_COPY_MESSAGE: 'clicked_on_copy_message',
  MATCH_ENGAGE_MODAL_CLICK_COPY_SUBJECT: 'clicked_on_copy_subject',
  MATCH_ENGAGE_MODAL_CLICK_MESSAGE_CHANNEL: 'clicked_on_message_channel',
  MATCH_ENGAGE_MODAL_CLICK_LEARN_MORE: 'clicked_on_learn_more',
  MATCH_ENGAGE_MODAL_CLICK_GO_PRO: 'clicked_on_go_pro',
  MATCH_ENGAGE_PANEL_SHOWN: 'engagement_panel_shown',
  CLICKED_ON_CONTACT_INFO: 'clicked_on_contact_info',
  MATCH_ENGAGE_MODAL_CLICK_COPY_MESSAGE_MENU: 'clicked_on_copy_message_menu',
  ATS_PROMO_MODAL_SHOWN: 'ats_promo_modal_shown',
  CLICKED_ON_ATS_PROMO: 'clicked_on_ats_promo',
  SYNC_POSITION_WITH_ATS_SHOWN: 'sync_position_with_ats_shown',
  CLICKED_MODAL_SYNC_POSITION_WITH_ATS: 'clicked_modal_sync_position_with_ats',
  CLICKED_ON_ADD_TALENT_TO_ATS: 'clicked_on_add_talent_to_ats',
  CLICKED_ON_AUTOMATIC_ADD_TO_ATS: 'clicked_on_automatic_add_to_ats',
  MATCH_DAILY_LIMIT_VIEWED: 'match_daily_limit_page_viewed',
  CLICKED_ON_REACH_YOUR_MATCHES: 'clicked_on_reach_your_matches',
  CLICKED_ON_LOCKED_MATCH: 'clicked_on_locked_match',
  CLICKED_ON_PREMIUM_MODAL: 'clicked_on_premium_modal',
  PREMIUM_MODAL_SHOWN: 'premium_modal_shown',
  CLICKED_ON_GET_UNLIMITED_MATCHES: 'clicked_on_get_unlimited_matches',
  NOTIFICATION_SHOWN: 'notification_shown',
  CLICKED_ON_NOTIFICATION: 'clicked_on_notification',
  // MATCH TUNE COMPONENTS
  MATCH_TUNE_LOCATIONS_STEP_VIEWED: 'match_tune_locations_step_viewed',
  MATCH_TUNE_LEVELS_OF_EXPERIENCE_STEP_VIEWED: 'match_tune_levels_of_experience_step_viewed',
  MATCH_TUNE_EDUCATION_STEP_VIEWED: 'match_tune_education_step_viewed',
  MATCH_TUNE_INDUSTRY_EXPERIENCE_STEP_VIEWED: 'match_tune_industry_experience_step_viewed',
  MATCH_TUNE_COMPANY_SIZE_STEP_VIEWED: 'match_tune_company_size_step_viewed',
  MATCH_TUNE_SKILLS_STEP_VIEWED: 'match_tune_skills_step_viewed',
  MATCH_TUNE_JOB_TITLE_STEP_VIEWED: 'match_tune_job_title_step_viewed',
  MATCH_TUNE_POSITION_FEEDBACK_STEP_VIEWED: 'match_tune_position_feedback_step_viewed',
  // DEDICATED INTERCOM EVENTS
  INTERCOM_TRIGGER_COMPANY_VALIDATION_FAILED: 'intercom_trigger_company_validation_failed',
  INTERCOM_TRIGGER_EMAIL_VERIFICATION_FAILED: 'intercom_trigger_email_verification_failed',
  INTERCOM_TRIGGER_ONBOARDING_COMPLETED: 'intercom_trigger_onboarding_completed',
  INTERCOM_TRIGGER_ATS_LINK_POSITION_HELP: 'intercom_trigger_ats_link_position_help',
  INTERCOM_TRIGGER_ATS_GET_API_KEY_HELP: 'intercom_trigger_ats_get_api_key_help',
  OUTREACH_PAGE_VIEW: 'outreach_page_view',
  OUTREACH_EMPTY_PAGE_VIEW: 'outreach_empty_page_view',
  CLICKED_ON_CONNECT_MAIL_OUTREACH: 'clicked_on_connect_mail_outreach',
  TALENT_NETWORK_INFO_SHOWN: 'talent_network_info_shown',
  ENGAGEMENT_PANEL_TALENT_NETWORK_LOADED: 'engagement_panel_talent_network_loaded',
  ENGAGEMENT_PANEL_TALENT_NETWORK_SEE_MORE: 'engagement_panel_talent_network_see_more',
  MATCH_PAGE_ELEMENTS_LOADED: 'match_page_elements_loaded',
  MATCH_PAGE_WIDGET_VIEWED: 'match_page_widget_viewed',
  MATCH_CARD_WIDGET_VIEWED: 'match_card_widget_viewed',
  MATCH_PAGE_BADGE_VIEWED: 'match_page_badge_viewed',
  CLICKED_ON_INVITE_TEAMMATE: 'clicked_on_invite_teammate',
  INVITE_TEAMMATE_MODAL_SHOWN: 'invite_teammate_modal_shown',
  CLICKED_ON_INVITE_TEAMMATE_MODAL: 'clicked_on_invite_teammate_modal',
  SIGN_UP_DETAILS_PAGE_VIEWED: 'sign_up_details_page_viewed',
  MATCH_PAGE_TOO_LONG_STATE_VIEWED: 'match_page_too_long_state_viewed',
};

export const APP_USER_TYPE = 'APP_USER';

export const APP_USER_PERMISSION_TYPE = {
  REGULAR: 'regular',
  SUPER_USER: 'super_user',
};

export const EVENT_TYPE = {
  USER_ACTION: 'user_action',
  INTERCOM_TRIGGER: 'intercom_trigger',
};

export const PRODUCT_AREA = {
  ADD_POSITION: 'add_position',
  POSITION_PAGE: 'position_page',
  ONBOARDING_PAGE: 'onboarding',
  HOMEPAGE: 'homepage',
  MATCH_PAGE: 'match_page',
  TALENT_OUTREACH: 'talent_outreach',
  POSITION_OVERVIEW_PAGE: 'position_overview_page',
};

export const ACTION_SOURCE_LOCATION = {
  ENGAGEMENT_MODAL: 'engagement_modal',
  POSITION_CARD: 'position_card',
};

export const EVENT_GROUP = {
  CLICK: 'click',
  IMP: 'imp',
  PAGE_VIEW: 'page_view',
};

export const POSITION_CARD_COMPONENT_ELEMENT_TYPE = {
  TO_REVIEW: 'to_review',
  TO_REACHOUT: 'to_reachout',
  TO_REPLY: 'to_reply',
  TO_MEET: 'to_meet',
  REGULAR: 'regular',
};

export const COMPONENT_NAME = {
  SIGN_IN: 'sign_in',
  COMPANY: 'company',
  LOCATION: 'location',
  NAME: 'name',
  EMPLOYEE_TYPE: 'employee_type',
  JOB_TITLE: 'job_title',
  EXPERIENCE_LEVEL: 'experience_level',
  BACKGROUND: 'background',
  FIELD_OF_STUDY: 'education',
  SKILL: 'skills',
  ADD_POSITION_PRO: 'add_position_pro',
  ADD_POSITION_LITE: 'add_position_lite',
  LITE_POSITION_LIMIT: 'lite_position_limit',
  LITE_MATCHES_LIMIT: 'lite_matches_limit',
  PRO_POSITION_LIMIT: 'pro_position_limit',
  ENGAGEMENT_FAB: 'engagement_fab',
  UPGRADED_SUCCESSFULLY: 'upgraded_successfully',
  GO_TO_MY_POSITIONS: 'go_to_my_positions',
  MATCHES_ALMOST_GONE: 'matches_almost_gone',
  END_OF_PREMIUM_INDICATION: 'end_of_premium_indication',
  ENGAGEMENT_POP_UP: 'engagement_pop_up',
  LITE_TALENT_NETWORK: 'lite_talent_network',
};

export const ELEMENT_TYPE = {
  AUTOCOMPLETE: 'autocomplete',
  MANUAL: 'manual',
  SUGGESTIONS: 'suggestions',
  FILE: 'file',
  LINK: 'link',
  GO_PRO: 'go_pro',
  CLOSE: 'close',
};
