import { useTranslation } from 'react-i18next';
import { ItemsWrapper, TimelineLabel } from '../timeline.styled';
import STRING_KEYS from '../../../../../../language/keys';
import EducationContainerItem from './education-container-item/education-container-item.component';

const EducationContainer = ({ items }: EducationContainerProps): JSX.Element | null => {
  const { t: translate } = useTranslation();

  if (!items?.length) {
    return null;
  }

  return (
    <div>
      <TimelineLabel>
        { translate(STRING_KEYS.MATCH_CARD.EDUCATION_LABEL) }
      </TimelineLabel>
      <ItemsWrapper>
        { items.map((item) => <EducationContainerItem key={ item.educationId } educationItem={ item } />) }
      </ItemsWrapper>
    </div>
  );
};

export default EducationContainer;
