import { INTERNAL_SERVER_ERROR_CODES } from '../../../consts/api';

/**
 * check if value match url .
 * @param value string
 * @returns indicator (boolean) if value match url
 */
export const isLinkedInCompanyFormat = (value: string): boolean => {
  if (value && value.length > 0) {
    return !!value.match('linkedin.com/company/*');
  }

  return false;
};

/**
 * Check if a string consists of only letters(all languages).
 * @param stringToCheck the value to check
 * @returns true if the string contains only letters(all languages), false otherwise.
 */
export const isValidName = (stringToCheck: string): boolean => {
  const onlyLettersRegEx = /^[\p{Letter}\p{Mark} `']+$/u;

  return onlyLettersRegEx.test(stringToCheck);
};

/**
 * Checks if personal email error
 * @param errorCode axios error
 * @returns indicator boolean if mail is personal
 */
export const isPersonalEmailError = (errorCode: number):boolean => {
  return errorCode === INTERNAL_SERVER_ERROR_CODES.PERSONAL_EMAIL_ERROR_CODE;
};
