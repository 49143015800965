import { useTranslation } from 'react-i18next';
import BaseFunnelTag from '../base-tag/base-funnel-tag.component';
import STRING_KEYS from '../../../../../../language/keys';
import { FUNNEL_TAG_MIN_OPTIMAL_MATCHES_COUNT } from '../../position-performance-funnel.consts';
import {
  FireIcon, HeardIcon, LightIcon, RocketIcon, SeedlingIcon,
} from './liked-tags.styled';

export const OptimalResult = ({ liked = 0 }: LikedTagProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseFunnelTag
      emoji={ <LightIcon /> }
      text={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.LIKED.TAGS.OPTIMAL_RESULT, {
        count: FUNNEL_TAG_MIN_OPTIMAL_MATCHES_COUNT - liked,
      }) }
    />
  );
};

export const KeepItUp = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseFunnelTag
      emoji={ <RocketIcon /> }
      text={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.LIKED.TAGS.KEEP_IT_OUT) }
    />
  );
};

export const GreatStart = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseFunnelTag
      emoji={ <SeedlingIcon /> }
      text={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.LIKED.TAGS.GREAT_START) }
    />
  );
};

export const ExcellentPipeline = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseFunnelTag
      emoji={ <FireIcon /> }
      text={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.LIKED.TAGS.EXCELLENT_PIPELINE) }
    />
  );
};

export const Amazing = ({ acceptanceRate = 0 }: LikedTagProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseFunnelTag
      emoji={ <HeardIcon /> }
      text={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.LIKED.TAGS.AMAZING, {
        rate: Math.trunc(acceptanceRate * 100),
      }) }
    />
  );
};
