import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { BodyContainer } from '../../../create-position/components/create-position-container/create-position-container.styled';

export const BodyWrapper = styled(BodyContainer)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 ${THEME.SPACING.TINY};
`;

export const Error = styled.div`
  display: flex;
  margin-top: ${THEME.SPACING.BASE};
  color: ${THEME.COLORS.ALERT};
`;
