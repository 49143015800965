import { THEME } from '../../../../themes';

export const WIDGET_COLORS = [
  THEME.COLORS.ACCENT,
  THEME.COLORS.PRIMARY,
  THEME.COLORS.SECONDARY,
  THEME.COLORS.DECOR,
  '#8a054c',
  '#5040c9',
  '#049f6e',
  '#b39c05',
  '#fcb0d9',
  '#c5bfed',
  '#affde4',
  '#fdf6c3',
];

// TODO: create enum
export const MOVE_METER_TYPES = {
  SOMEWHAT_LIKELY_LOW: 'SOMEWHAT_LIKELY_LOW',
  SOMEWHAT_LIKELY_HIGH: 'SOMEWHAT_LIKELY_HIGH',
  LIKELY_TO_MOVE_LOW: 'LIKELY_TO_MOVE_LOW',
  LIKELY_TO_MOVE_HIGH: 'LIKELY_TO_MOVE_HIGH',
  VERY_LIKELY_LOW: 'VERY_LIKELY_LOW',
  VERY_LIKELY_HIGH: 'VERY_LIKELY_HIGH',
};
