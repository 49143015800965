import { sequenceQuery } from '../sequence.toolkit-api';
import { CreateOrUpdatePositionSequencePreferencesQueryArguments } from '../dto/query-arguments/create-or-update-position-sequence-preferences.query-arguments';
import { PositionSequencePreferencesResponseDto } from '../dto/response/position-sequence-preferences.response';
import { ApiError } from '../../../../../consts/api';

type UseCreatePositionSequencePreferencesMutationType = [
  (positionSequencePreferences: CreateOrUpdatePositionSequencePreferencesQueryArguments) => void,
  AppSelectorType<PositionSequencePreferencesResponseDto | undefined, ApiError>
];

export const useCreatePositionSequencePreferencesMutation = (): UseCreatePositionSequencePreferencesMutationType => {
  const [
    createPositionSequencePreferences,
    {
      data, isLoading, isError, error: apiError, isSuccess, reset,
    }] = sequenceQuery.useCreatePositionSequencePreferencesMutation();

  const meta = {
    isLoading, isError, error: apiError as ApiError, isSuccess, data, resetState: reset,
  };

  return [
    createPositionSequencePreferences,
    { ...meta },
  ];
};
