export const DECORATORS_NAMES = {
  MENTION: 'MENTION',
  MENTION_PLACEHOLDER: 'MENTION_PLACEHOLDER',
};

export const OPEN_MENTION_CHAR = '{';

export const DOT = '. ';

export const SINGLE_SPACE = ' ';
