export const ROUTES = {
  USERS: '/users',
  USER_INFO: '/users/user-info',
  USER_INVITE: '/users/invite',
  USER_INVITE_RESEND: '/users/invite/resend',
};

export const REDUCER_PATH = 'userApi';

export const FIXED_CACHE_KEYS = {
  RESEND_INVITE_TO_USER: 'RESEND_INVITE_TO_USER',
};
