import { useTranslation } from 'react-i18next';
import OutreachChip from '../../../../modules/application/components/outreach-chip/outreach-chip.component';
import { OutreachChipTypeEnum } from '../../../../modules/application/components/outreach-chip/outreach-chip.enums';
import STRING_KEYS from '../../../../language/keys';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
} from '../../Buttons/perfect-base-button/perfect-base-button.enums';
import { SkeletonChipWrapper, WarningChipButton } from './text-editor-skeleton.styled';
import MessageSkeleton from './message-skeleton/message-skeleton.component';

const TextEditorSkeleton = ({
  showAnimation,
  hasError,
  errorChipButtonClickHandler,
  isErrorActionLoading,
  loadingText,
}: TextEditorSkeletonProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <div>
      <MessageSkeleton showAnimation={ showAnimation } />
      <SkeletonChipWrapper>
        {
          hasError ?
            (
              <OutreachChip
                type={ OutreachChipTypeEnum.Warning }
                text={ translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.GENERATE_MESSAGE_ERROR_CHIP_TEXT) }
                isLoading={ isErrorActionLoading }
                EndAdornment={ (
                  <WarningChipButton
                    onClick={ errorChipButtonClickHandler }
                    hoverVariant={ PerfectButtonHoverVariants.Grow }
                    size={ PerfectButtonSize.Small }
                    disabled={ isErrorActionLoading }
                  >
                    { translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.GENERATE_MESSAGE_ERROR_CHIP_BUTTON_TEXT) }
                  </WarningChipButton>
                ) }
              />
            ) :
            loadingText && (<OutreachChip isLoading text={ loadingText } />)
        }
      </SkeletonChipWrapper>
    </div>
  );
};

export default TextEditorSkeleton;
