import { css } from 'styled-components';
import { hexToRgbA } from '../utils/colors';
import { THEME } from './index';

export const Scrollbar = css<{ showOnHover?: boolean, scrollColor?: string }>`
  overflow-y: ${({ showOnHover }) => showOnHover ? 'hidden' : 'scroll'};
  ${({ showOnHover }) => {
    if (showOnHover) {
      return `
        :hover {
          overflow-y: scroll;
        }
      `;
    }
    
    return '';
  }};
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-right: none;
    border-left: none;
    border-radius: ${THEME.RADIUS.CURVY};
    background-color: ${({ scrollColor }) => hexToRgbA(scrollColor || THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW)};
  }
`;
