import { useState } from 'react';
import {
  CheckedIcon,
  Slider,
  SliderButton,
  StyledSwitch,
  UncheckedIcon,
} from './switch.styled';

export const Switch = ({ disabled, checked, onChangeHandler }: SwitchProps):JSX.Element => {
  const [internalChecked, setInternalChecked] = useState<boolean>(checked || false);

  const onChange = () => {
    if (onChangeHandler) {
      onChangeHandler(!internalChecked);
    }

    setInternalChecked(!internalChecked);
  };

  return (
    <StyledSwitch disabled={ disabled } >
      <input
        type="checkbox"
        disabled={ disabled }
        checked={ internalChecked }
        onChange={ onChange }
      />
      <Slider>
        <SliderButton>
          { internalChecked ? <CheckedIcon /> : <UncheckedIcon /> }
        </SliderButton>
      </Slider>
    </StyledSwitch>
  );
};

Switch.defaultProps = {
  disabled: false,
  checked: false,
};
