import { useRef } from 'react';
import { useIntersection } from '../../../../../hooks/useIntersection';
import { Wrapper } from './widget-wrapper.styled';

type WidgetWrapperProps = {
  children: JSX.Element,
  width: string,
  height: string,
  padding: string,
  backgroundColor: string,
  intersectionHandler?: () => void,
}

export const WidgetWrapper = ({
  children,
  width,
  height,
  padding,
  backgroundColor,
  intersectionHandler,
}:WidgetWrapperProps): JSX.Element => {
  const componentRef = useRef(null);
  useIntersection({
    element: componentRef,
    callback: () => intersectionHandler?.(),
  });

  return (
    <Wrapper
      width={ width }
      height={ height }
      padding={ padding }
      backgroundColor={ backgroundColor }
      ref={ componentRef }
    >
      {children}
    </Wrapper>
  );
};

WidgetWrapper.defaultProps = {
  intersectionHandler: undefined,
};
