import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import {
  Mentions,
} from '../../../../../../components/Common/perfect-text-editor/decorators/mention-decorator/mention-list/mention-list';
import STRING_KEYS from '../../../../../../language/keys';
import { useMyUserSelector } from '../../../../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { useGetMyAccountSelector } from '../../../../../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';

export const useCustomModeMentionsOptions = (): Mentions[] | undefined => {
  const { t: translate } = useTranslation();
  const { data: user } = useMyUserSelector();
  const { data: account } = useGetMyAccountSelector();

  return useMemo<Mentions[] | undefined>(() => {
    const talent = {
      key: STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS_TITLE.TALENT,
      mentionInfo: [
        {
          displayValue: translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.TALENT_FIRST_NAME),
          value: translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.TALENT_FIRST_NAME),
        },
        {
          displayValue: translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.TALENT_LAST_NAME),
          value: translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.TALENT_LAST_NAME),
        },
        {
          displayValue: translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.TALENT_CURRENT_COMPANY_NAME),
          value: translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.TALENT_CURRENT_COMPANY_NAME),
        },
        {
          displayValue: translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.TALENT_YEARS_EXPERIENCE),
          value: translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.TALENT_YEARS_EXPERIENCE),
        },
      ],
    };

    const senderName = `${translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS_TITLE.SENDER)} 
            ${translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.SENDER_NAME)}`;

    const sender = {
      key: STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS_TITLE.SENDER,
      mentionInfo: [
        {
          displayValue: senderName,
          value:
          senderName,
        },
      ],
    };

    return [
      talent,
      sender,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, account]);
};
