import { useSelector } from 'react-redux';
import { RootReducerState } from '../../../index';
import { getUserInputsJobRequirement } from '../../../../modules/create-position/utils/create-position-job-requerments.util';
import { CompleteStepPayload } from '../../../../modules/onboarding/@types/onboarding-step-complete';

export const useCreateOnboardingBackgroundSelector = (): Partial<CompleteStepPayload> => {
  return useSelector<RootReducerState, Partial<CompleteStepPayload>>(({ createPosition }: RootReducerState) => {
    const companyBackground = getUserInputsJobRequirement(createPosition.companyBackground);
    const skills = getUserInputsJobRequirement(createPosition.skills);

    return {
      companyBackground,
      skills,
    };
  });
};
