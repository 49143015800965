import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';

export const CustomDismissButton = styled.div<{backgroundColor?: string}>`
  width: 32px;
  height: 32px;
  border-radius: ${THEME.RADIUS.CIRCLE};
  background-color:${(props) => props.backgroundColor};
  display: inline-flex;
  position: relative;
  cursor: pointer;
`;

export const CircularContainer = styled(CircularProgress)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: initial;
  width: 32px;
  height: 32px;
  svg: {
    width: 32px;
    height: 32px;
  };
`;
export const CloseContainer = styled.div`
  position: absolute;
  display: inline-flex;
  left:50%;
  top: 50%;
  transform: translate(-50%, -50%);
  svg {
    height: 14px;
    width: 14px;
    fill: ${THEME.COLORS.ACCENT};
    line-height: 1.1;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
  }
`;

export const CloseIcon = styled(ASSETS.CLOSE)<{color?: string, size: string}>`
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  color: ${(props) => props.color};
  fill: ${(props) => props.color};
`;
