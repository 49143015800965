import styled, { keyframes } from 'styled-components';
import { THEME } from '../../../../../themes';
import {
  HeadlineFont,
  Body1Font,
  Subtitle1Font,
} from '../../../../../themes/fonts';

const slideInFwd = keyframes`
0% {
  -webkit-transform: translateZ(-1400px);
          transform: translateZ(-1400px);
  opacity: 0;
}
100% {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  opacity: 1;
}
`;

// animation property to show education items with slide in effect
export const EducationItemSection = styled.div`
  width: 100%;
  border-radius: ${THEME.RADIUS.BASE};
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: ${THEME.SPACING.GRAND};
  animation: ${slideInFwd} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
`;

export const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  margin: 0 ${THEME.SPACING.MEDIUM} 0 0;
  border-radius: ${THEME.RADIUS.CIRCLE};
  display: inline-block;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  :after {
    content: '';
    border-left: 4px solid  ${THEME.COLORS.LIGHT_GREY};
    height: 100px;
    display: block;
    margin-left: 30px;
  }
`;

export const DescriptionSection = styled.div`
  flex: 1;
  max-width: 600px;
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  justify-content: center;
`;

export const EducationItemDescription = styled.div`
  ${Subtitle1Font};
  font-weight: normal;
  line-height: normal;
  color: ${THEME.COLORS.PRIMARY_DARK};
  margin-top: ${THEME.SPACING.BASE};
`;

export const InstitutionName = styled.div`
  ${HeadlineFont};
  line-height: normal;
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const YearsSection = styled.div`
  ${Body1Font};
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.PRIMARY_DARK};
  margin-top: ${THEME.SPACING.TINO};
`;
