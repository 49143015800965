import { Container } from './progress-bar.styled';
import ProgressBarStep from './progress-bar-step/progress-bar-step.component';
import { ProgressBarProps } from './progress-bar';

const ProgressBar = ({ steps }: ProgressBarProps): JSX.Element => {
  const renderSteps = steps.map(({ title, type }, index) => (
    <ProgressBarStep
      key={ title }
      title={ title }
      step={ type }
      isLastStep={ index === steps.length - 1 }
    />
  ));

  return (
    <Container>
      { renderSteps }
    </Container>
  );
};

export default ProgressBar;
