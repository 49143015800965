export const POSITION_ID_PATH_PARAM = ':positionId';
export const CONVERSATION_ID_PATH_PARAM = ':conversationId';
export const MESSAGE_ID_PATH_PARAM = ':messageId';
export const TASK_ID_PATH_PARAM = ':taskId';

export const ROUTES = {
  POSITION_SEQUENCE_PREFERENCES: `/positions/${POSITION_ID_PATH_PARAM}/sequences/preferences`,
  POSITION_CUSTOM_STEPS_PREFERENCES: `/positions/${POSITION_ID_PATH_PARAM}/sequences/preferences/custom/steps`,
  NEXT_SCHEDULED_MESSAGE: `/conversations/${CONVERSATION_ID_PATH_PARAM}/sequences/next-scheduled-message`,
  CONVERSATION_SEQUENCE: `/conversations/${CONVERSATION_ID_PATH_PARAM}/sequences`,
  REGENERATE_CONVERSATION_SEQUENCE: `/conversations/${CONVERSATION_ID_PATH_PARAM}/sequences/regenerate`,
  MESSAGE_PREVIEW: `/positions/${POSITION_ID_PATH_PARAM}/sequences/preferences/content/message-preview`,
  SCHEDULED_MESSAGE: `/sequences/scheduled-messages/${MESSAGE_ID_PATH_PARAM}`,
  CANCEL_CAMPAIGN: `/conversations/${CONVERSATION_ID_PATH_PARAM}/sequences/cancel`,
  LINKEDIN_TASK_BY_ID: `/sequences/linkedin-task/${TASK_ID_PATH_PARAM}`,
};

export const REDUCER_PATH = 'sequenceApi';
