export enum OutreachBannerTypeEnum {
  UpgradeToPro = 'UPGRADE_TO_PRO',
  ConnectEmail = 'CONNECT_EMAIL',
  ReconnectEmail = 'RECONNECT_EMAIL',
  SetupGenAi = 'SETUP_GEN_AI',
  CampaignOnHoldUserEmailConnection = 'CAMPAIGN_ON_HOLD_USER_EMAIL_CONNECTION',
  CampaignOnHoldEmailConnection = 'CAMPAIGN_ON_HOLD_EMAIL_CONNECTION',
  CampaignOnHoldInternalError = 'CAMPAIGN_ON_HOLD_INTERNAL_ERROR',
}

export enum OutreachBannerSizeEnum {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',
}
