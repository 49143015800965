import styled from 'styled-components';
import { THEME } from '../../../../../../../themes';

export const SocialIcon = styled.img`
  display: inline-block;
  width: 32px;
  height: 32px;
  transition: all 0.2s;
  :hover {
    opacity: 0.8;
  }
`;

export const SocialIconWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${THEME.SPACING.TINY};
  margin-bottom: ${THEME.SPACING.TINY};
  border-radius: ${THEME.RADIUS.CURVY};
  box-shadow: 0 0 4px 0 ${THEME.COLORS_RGB.BLACK_DIVIDER};
  border: solid 1px rgba(0, 0, 0, 0);
  background-color: ${THEME.COLORS.PURE_WHITE};
}
`;
