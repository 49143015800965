import styled from 'styled-components';
import { THEME } from '../../../../../../../themes';
import { TextEllipsis } from '../../../../../../../themes/text';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
  padding: ${THEME.SPACING.MICRO} 0;
`;

export const Bullet = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  
  :before, :after {
    background-color: ${THEME.COLORS.MID_GREY};
  }
  
  :before {
    content: '';
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
  }
  
  :after {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: ${THEME.RADIUS.CIRCLE};
    border: 3px solid white;
    z-index: 1;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  
  :last-child ${Bullet}:before {
    height: 50%;
  }
`;

export const EducationRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: ${THEME.SPACING.NANO};
`;

export const EducationTitleContainer = styled.div`
  width: 500px;
  ${TextEllipsis};
`;
