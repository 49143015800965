import log from 'loglevel';
import { MatchResponse } from '../dto/response/match.response';
import { ContactDetailsLookupCompletedSocketDto } from '../dto/socket/contact-details-lookup-completed.socket';

const logger = log.getLogger('CONTACT_DETAILS_LOOKUP_COMPLETED_MATCH_WEBSOCKET_HANDLER');

export const contactDetailsLookupCompletedMatchWebsocketHandler = (
  { contactDetails }: ContactDetailsLookupCompletedSocketDto,
  updateCachedData: (args: (match: MatchResponse) => void) => void,
): void => {
  updateCachedData((match: MatchResponse) => {
    if (contactDetails && contactDetails.matchId === match.id) {
      logger.debug('Updated match contact details from ContactDetailsLookupCompleted socket message', { matchId: contactDetails.matchId });
      match.contactDetails = contactDetails;
    }
  });
};
