import styled, { keyframes } from 'styled-components';
import Typed from 'react-typed';
import { THEME } from '../../../themes';
import { Title3Font } from '../../../themes/fonts';

const blink = keyframes`
 50% {
    opacity: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: ${THEME.SPACING.GRAND};
  font-family: ${THEME.FONT_FAMILY.MERRIWEATHER};
  gap: ${THEME.SPACING.TINY};

  @media (max-height: ${THEME.BREAKPOINTS.HEIGHT.SMALL}px) {
    min-height: ${THEME.SPACING.LARGE};
  }
`;

export const TypingWrapper = styled(Typed)`
display: flex;
align-items: center;
font-size: 32px;
  > * {
      &:first-child {
        font-family: ${THEME.FONT_FAMILY.MERRIWEATHER};
        @media (max-height: ${THEME.BREAKPOINTS.HEIGHT.SMALL}px) {
          ${Title3Font};
        }
      }
      &:nth-child(2) {
        font-family: ${THEME.FONT_FAMILY.MERRIWEATHER};
        color: ${THEME.COLORS.ACCENT};
        font-size: 40px;
        animation: ${blink} 1s step-start infinite;
        @media (max-height: ${THEME.BREAKPOINTS.HEIGHT.SMALL}px) {
          ${Title3Font};
        }
      }
    }
`;

export const Dot = styled.div`
  font-size: 32px;
  font-family: ${THEME.FONT_FAMILY.MERRIWEATHER};
  :before {
    content: '.';
  }
`;

export const Comma = styled.div`
  font-size: 32px;
  font-family: ${THEME.FONT_FAMILY.MERRIWEATHER};
  margin-left: -${THEME.SPACING.MICRO};
  :before {
    content: ',';
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;
