import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { AppState } from '../app-state.initial-state';
import { UserIdentifyMethod } from '../app-state.enums';

export const setUserIdentifyMethodReducer: CaseReducer<AppState, PayloadAction<UserIdentifyMethod>> = (state, { payload }) => {
  state.identifyMethod = payload;
};

export const setUserJustSignedUpReducer: CaseReducer<AppState, PayloadAction<undefined>> = (state) => {
  state.isNewSignup = true;
};
