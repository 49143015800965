import { Container, Text } from './match-tune-card.styled';

const MatchTuneCard = ({
  text,
  Icon,
  isSelected,
  clickHandler,
  disabled,
}: MatchTuneCardProps): JSX.Element => {
  return (
    <Container
      isSelected={ isSelected }
      onClick={ () => !disabled && clickHandler() }
      disabled={ disabled }
    >
      <Icon />
      <Text>{ text }</Text>
    </Container>
  );
};

export default MatchTuneCard;
