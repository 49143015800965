import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { CaptionFont } from '../../../../../../themes/fonts';

export const ListContainer = styled.div`
  display:flex;
  flex-direction: column;
`;
export const Container = styled.div<{ disablePadding?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ disablePadding }) => disablePadding ? 0 : `${THEME.SPACING.MICRO} 0px`};
`;
export const RoleCircle = styled.div<{color: string}>`
  width: 8px;
  height: 8px;
  border-radius: ${THEME.RADIUS.BASE};
  background-color: ${({ color }) => color};
  margin-right: ${THEME.SPACING.TINO};
`;
export const RoleName = styled.div`
  opacity: ${THEME.OPACITY.MED};
  ${CaptionFont};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  flex: 1;
  margin-right: 5px;
`;
export const ExperienceValue = styled.div`
  opacity: ${THEME.OPACITY.LOW};
  ${CaptionFont};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  margin-left: auto;
`;
