import { isMobile } from 'react-device-detect';
import { MobileMessage } from '../../Common/MobileMessage';

type WithMobileCheckProps = {
    children: JSX.Element,
}

export const WithMobileCheck = ({ children }:WithMobileCheckProps) :
  JSX.Element => {
  if (isMobile) {
    return <MobileMessage />;
  }
  
  return children;
};
