import { THEME } from '../../../../themes';
import { CustomButton } from './styled';

export type ButtonProps = {
  children?: React.ReactNode,
  onClick: () => void,
  isDisabled?: boolean,

  // styline
  fontColor?: string,
  backgroundColor?: string,
  border?: string,
  overlayColor?: string,
  padding?: string,
  top?: string,
  left?: string,
  buttonWidth?: string,
  buttonHeight?: string,
  iconWidth?: string,
  iconHeight?: string,
};

// todo: create story-book

export const Button = ({
  children,
  onClick,
  isDisabled,
  fontColor,
  backgroundColor,
  border,
  overlayColor,
  padding,
  buttonWidth,
  buttonHeight,
  iconWidth,
  iconHeight,
}: ButtonProps): JSX.Element => {
  return (
    <CustomButton
      type="button"
      onClick={ onClick }
      disabled={ isDisabled }
      backgroundColor={ backgroundColor }
      overlayColor={ overlayColor }
      fontColor={ fontColor }
      border={ border }
      padding={ padding }
      buttonWidth={ buttonWidth }
      buttonHeight={ buttonHeight }
      iconWidth={ iconWidth }
      iconHeight={ iconHeight }
    >
      {children}
    </CustomButton>
  );
};

Button.defaultProps = {
  isDisabled: false,
  fontColor: THEME.COLORS.CONTENT_INVERSE,
  backgroundColor: THEME.COLORS.PRIMARY,
  border: 'none',
  overlayColor: THEME.COLORS.PURE_WHITE,
  children: null,
  padding: '',
  top: '',
  left: '',
  width: '',
  height: '',
  buttonWidth: '',
  buttonHeight: '',
  iconWidth: '',
  iconHeight: '',
};
