import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { CaptionFont, HeadlineFont } from '../../../../../themes/fonts';

export const Reference = styled.div`
  opacity: ${THEME.OPACITY.MED};
  ${CaptionFont};
  color:${THEME.COLORS.CONTENT_PRIMARY};
  margin-bottom: ${THEME.SPACING.MICRO};
`;

export const Title = styled.span`
  color:${THEME.COLORS.PRIMARY_DARK};
  ${HeadlineFont};
  font-size: 18px;
  font-weight: normal;
`;

export const Counter = styled(Title)`
  font-weight: 600;
  color: ${THEME.COLORS.ACCENT};
  margin-left: ${THEME.SPACING.MICRO};
  margin-right: ${THEME.SPACING.MICRO};
`;

export const Company = styled(Title)`
  font-weight: 700;
  margin-left: ${THEME.SPACING.MICRO};
`;

export const WidgetContainer = styled.div`
  width: 100%;
  height: 100%;
`;
