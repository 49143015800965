import { DecoratorElementProps } from '../../decorators';
import {
  Brackets, MentionContainer,
} from './mention.styled';

export const Mention = ({ children }: DecoratorElementProps): JSX.Element => (
  <MentionContainer>
    <Brackets />
    {children}
  </MentionContainer>
);
