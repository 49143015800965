import styled from 'styled-components';
import { THEME } from '../../../themes';
import BaseButton from '../../Common/Buttons/perfect-base-button/perfect-base-button.component';
import { Subtitle2Font } from '../../../themes/fonts';
import { ASSETS } from '../../../assets';

export const AtsUploadedContainer = styled.div`
  color: ${THEME.COLORS.PRIMARY_DARK};
  margin-top: ${THEME.SPACING.TINY};
  display: flex;
  gap: ${THEME.SPACING.MICRO};
  align-items: center;
`;

export const AtsIntegrationButton = styled(BaseButton)<{customColor: string }>`
  color: ${({ customColor }) => customColor};
  text-transform: capitalize;
  background-color: inherit;
  margin-top: ${THEME.SPACING.TINY};
`;

export const AtsIntegrationSynced = styled.span`
  ${Subtitle2Font};
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const AtsName = styled.span`
  ${Subtitle2Font};
  color: ${THEME.COLORS.ACCENT};
`;

export const InfinityIcon = styled(ASSETS.INFINITY_ARROW_ICON)`
  height: 12px;
`;

export const ExportMatchesIcon = styled(ASSETS.DOWNLOAD_FILLED)`
  height: 22px;
  width: 22px;
  margin-left: 3px;
  fill: ${THEME.COLORS.PRIMARY_DARK};
`;
