import { createTheme, LinearProgress, ThemeProvider  } from '@mui/material';
import { THEME } from '../../../../themes';

type IndeterminateLinearLoaderProps = {
  loaderHeight?: string
}

export const IndeterminateLinearLoader = ({
  loaderHeight = '8px',
} : IndeterminateLinearLoaderProps):JSX.Element => {
  const theme = createTheme({
    components: {
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            height: loaderHeight,
            backgroundColor: 'rgba(236,9,130,0.34)',
          },
          bar2Indeterminate: {
            backgroundColor: `${THEME.COLORS.ACCENT}`,
          },
          bar1Indeterminate: {
            backgroundColor: `${THEME.COLORS.ACCENT}`,
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={ theme }>
      <LinearProgress />
    </ThemeProvider>
  );
};

IndeterminateLinearLoader.defaultProps = {
  loaderHeight: '8px',
};
