import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import BasePositionAssistant from '../base-position-assistant/base-position-assistant.component';
import { PositionAssistantProps } from '../base-position-assistant/base-position-assistant';
import { LinkIcon } from './ats-integration.styled';

const AtsIntegrationAssistant = ({
  actionHandler,
}: PositionAssistantProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BasePositionAssistant
      title={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ATS_INTEGRATION.TITLE) }
      description={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ATS_INTEGRATION.DESCRIPTION) }
      Icon={ LinkIcon }
      action={ {
        title: translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ATS_INTEGRATION.ACTION_TITLE),
        handler: actionHandler,
      } }
    />
  );
};

export default AtsIntegrationAssistant;
