import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';

const getCurrentStep = (pathname: string): unknown => {
  const currentStep = pathname
    .substring(1)
    .split('-')
    .join('_')
    .toUpperCase();

  if (currentStep === 'COMPLETED') {
    return `ONBOARDING_${currentStep}`;
  }

  return currentStep;
};

export const useGetCurrentStep = <T>(): T => {
  const history = useHistory();

  return useMemo(() => getCurrentStep(history.location.pathname), [history.location.pathname]) as T;
};
