import styled from 'styled-components';
import { ASSETS } from '../../../../../assets';
import { THEME } from '../../../../../themes';

export const Container = styled.div`
  padding: ${THEME.SPACING.TINY} 0;
`;

export const Icon = styled(ASSETS.ARCHIVE_FILLED)`
  height: 24px;
`;
