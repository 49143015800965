import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { DEFAULT_MILE_RADIUS_VALUE, getMileRadius } from '../../../../../../../../../utils/location';
import SelectItem from '../../../../../../../../../components/Common/select/select-item/select-item.component';
import { ExactLocationDto, LocationType } from '../../../../../../../../../models/dto/location.dto';
import { REMOTE_POSITION } from '../../../../../../../../../hooks/location-autocomplete-hook/location-autocomplete-hook.consts';
import STRING_KEYS from '../../../../../../../../../language/keys';
import { MileRadiusSelectProps } from './mile-readius-select';
import { MilesRadiusSelectLabelIcon, MilesRadiusSelectLabelWrapper, StyledSelect } from './mile-readius-select.styled';

const MILE_RADIUS_VALUES = [25, 50, 100, 150];

const MileRadiusSelect = ({ location, changeHandler }: MileRadiusSelectProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const isMileRadiusDisabled = useMemo(
    () => location?.type === LocationType.Region || location?.country === REMOTE_POSITION.country || !(location as ExactLocationDto).city,
    [location],
  );

  const onMileRadiusChange = (newValue: string) => {
    changeHandler(parseInt(newValue));
  };

  return (
    <StyledSelect
      value={ `${getMileRadius(location)}` }
      onChangeHandler={ onMileRadiusChange }
      disabled={ isMileRadiusDisabled }
      label={ (
        <MilesRadiusSelectLabelWrapper>
          <MilesRadiusSelectLabelIcon />
          { translate(STRING_KEYS.MATCH_TUNE.LOCATIONS_STEP.MILE_RADIUS_SELECT_LABEL) }
        </MilesRadiusSelectLabelWrapper>
      ) }
      formatDisplayValue={
        (element) => translate(STRING_KEYS.MATCH_TUNE.LOCATIONS_STEP.MILE_RADIUS_SELECT_VALUE, { mileRadius: parseInt(element.value) })
      }
    >
      { MILE_RADIUS_VALUES.map((mileRadius) => {
        return (
          <SelectItem
            key={ mileRadius }
            value={ String(mileRadius) }
          >
            <div>
              { translate(STRING_KEYS.MATCH_TUNE.LOCATIONS_STEP.MILE_RADIUS_SELECT_VALUE, { mileRadius }) }
              { mileRadius === DEFAULT_MILE_RADIUS_VALUE ? ` (${translate(STRING_KEYS.RECOMMENDED)})` : '' }
            </div>
          </SelectItem>
        );
      }) }
    </StyledSelect>
  );
};

export default MileRadiusSelect;
