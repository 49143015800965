import styled, {
  css, SimpleInterpolation,
} from 'styled-components';
import BaseButton from '../perfect-base-button/perfect-base-button.component';
import { THEME } from '../../../../themes';
import { IconButtonVariants } from './icon-button.enums';

const GhostCss = css`
  border-radius: ${THEME.RADIUS.CIRCLE};
`;

const getVariantButtonCss = (variant?: string): ReadonlyArray<SimpleInterpolation> | undefined => {
  if (variant === IconButtonVariants.Ghost) {
    return GhostCss;
  }

  return undefined;
};

export const ButtonContainer = styled(BaseButton)<{ variant?: string }>`
  border-radius: ${THEME.RADIUS.BASE};
  height: 100%;
  padding: ${THEME.SPACING.TINY};
  & span {
    margin: 0;
  }

  & svg {
    fill: currentColor;
  }
  
  ${({ variant }) => {
    return getVariantButtonCss(variant);
  }}
`;
