import styled from 'styled-components';
import { THEME } from '../../../../themes';

export const Container = styled.span`
  text-decoration: underline;
  &:hover {
    color: ${THEME.COLORS.ACCENT};
    cursor: default;
  }
`;
