import styled from 'styled-components';
import { Subtitle2Font } from '../../../../themes/fonts';
import { THEME } from '../../../../themes';

export const ButtonContainer = styled.button<{
    textColor?: string,
    hoveredBackgroundColor?: string,
    backgroundColor?: string,
    activeBackgroundColor?: string,
    activeTextColor?: string,
    hoveredTextColor?: string,
    border?: string,
    uppercase?: boolean,
}>`
  ${Subtitle2Font};
  font-weight: 600;
  height: fit-content;
  border: ${({ border }) => border || 'none'};
  color: ${({ textColor }) => textColor};
  fill: ${({ textColor }) => textColor};
  text-transform: ${({ uppercase }) => uppercase ? 'uppercase' : ''};
  border-radius: ${THEME.RADIUS.CIRCLE};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.BASE};
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  transition: all 0.3s ease;
  outline: none;

  : disabled {
    opacity: ${THEME.OPACITY.LOW};
    pointer-events: none;
  }
  : hover {
    color: ${({ hoveredTextColor }) => hoveredTextColor};
    fill: ${({ hoveredTextColor }) => hoveredTextColor};
    background-color: ${({ hoveredBackgroundColor }) => hoveredBackgroundColor};
  }
  : active {
    color: ${({ activeTextColor }) => activeTextColor};
    fill: ${({ activeTextColor }) => activeTextColor};
    background-color: ${({ activeBackgroundColor }) => activeBackgroundColor};
  }
`;

export const TextWrapper = styled.span`
    ${Subtitle2Font};
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.34px;
`;

export const IconWrapper = styled.span<{ fill?: string, hoverFill?: string, activeFill?: string }>`
    display: flex;
    margin-right: ${THEME.SPACING.TINY};
`;
