import styled from 'styled-components';
import { FLUENT_UI_EMOJIS } from '../../../../../assets';
import { THEME } from '../../../../../themes';

export const TrialEndedIcon = styled(FLUENT_UI_EMOJIS.RAINBOW)`
  width: 88px;
  height: 88px;
`;

export const DaysToEnd = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: ${THEME.RADIUS.TIDY};
  background-color: ${THEME.COLORS.PURE_WHITE};
  color: ${THEME.COLORS.TRUE_BLACK};
`;
