import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { THEME } from '../../../../../themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex:1;
  padding: 0;
`;

export const LabelSkeleton = styled(Skeleton)`
  width: 150px;
  height: 15px;
  display: flex;
`;

export const LineContainer = styled.div`
  margin-top: ${THEME.SPACING.XLOOSE};
  display:flex;
  flex-direction: row;
`;

export const FirstLineTitle = styled(LabelSkeleton)`
    width: 230px;
    height: 20px;
`;

export const StatusContainer = styled(Skeleton)`
  width: 100px;
  height: 30px;
  display: flex;
  border-radius: ${THEME.RADIUS.ROUND};
  padding: 0 ${THEME.SPACING.BASE};
  margin: 0 ${THEME.SPACING.BASE};
`;

export const SocialIcon = styled(Skeleton)`
  width: auto;
  height: 30px;
  display: flex;
  border-radius: ${THEME.RADIUS.CIRCLE};
  padding: 0 ${THEME.SPACING.BASE};
  margin: 0 ${THEME.SPACING.TINO};
`;

export const SecondLine = styled(LabelSkeleton)`
    width: 360px;
    margin-bottom: -15px;
`;

export const ThirdLine = styled(LabelSkeleton)`
    width: 60px;
    margin-bottom: -15px;
`;

export const FourthLine = styled(LabelSkeleton)`
    width: 120px;
    margin-bottom: -15px;
`;

export const Title = styled(LabelSkeleton)`
    width: 100px;
    margin-bottom: -15px;
    height: 30px;
`;

export const IconSkeleton = styled(Skeleton)`
    width: 64px;
    height: 64px;
    margin: 0 ${THEME.SPACING.MEDIUM} 0 0;
    border-radius: ${THEME.RADIUS.CIRCLE};
    display: flex;
`;

export const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100px;
`;
