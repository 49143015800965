import styled, { keyframes } from 'styled-components';
import { THEME } from '../../../../themes';
import { Body2Font } from '../../../../themes/fonts';
import { hexToRgbA } from '../../../../utils/colors';

export const MENU_Y_OFFSET_PX = 24;

const scale = keyframes`
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
  }
`;

export const MenuWrapper = styled.div`
  z-index: 2;
  width: 100%;
  border-radius: 0 0 ${THEME.RADIUS.ROUNDISH} ${THEME.RADIUS.ROUNDISH};
  animation: ${scale} 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;

export const Wrapper = styled.div`
  display: block;
  width: 100%;
  border-radius: ${THEME.RADIUS.ROUNDISH};
  perspective: 1px;
  box-shadow: 0 12px 24px 0 ${THEME.COLORS_RGB.BLACK_DIVIDER};
`;

export const List = styled.div`
  max-height: 300px;
  overflow: auto;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: white;
  overflow-x: hidden;
  border-radius: ${THEME.RADIUS.ROUNDISH};
  background-color: ${THEME.COLORS.PURE_WHITE};
  ::-webkit-scrollbar {
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
    border-radius: 100px;
    background-color: rgba(236, 9, 130, ${THEME.OPACITY.LOW});
    background-clip: content-box;
  }
`;

export const OptionsTitle = styled.div`
  ${Body2Font};
  margin-left: ${THEME.SPACING.BASE};
  margin-bottom: ${THEME.SPACING.MICRO};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.25px;
  margin-bottom: ${THEME.SPACING.TINY};
`;

export const RowItem = styled.div<{
  isActive: boolean,
  isLastItem: boolean,
  }>`
  position: relative;
  border-bottom: ${({ isLastItem }) => isLastItem ? 'unset' :
    `1px solid ${THEME.COLORS_RGB.PRIMARY_DARK_GHOST}`};
  background-color: ${THEME.COLORS.PURE_WHITE};
  transition: all ease-in .2s;
  background-color: ${({ isActive }) => isActive ? hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.FOCUS) : undefined}
`;
