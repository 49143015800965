import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { Body2Font } from '../../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../../utils/colors';
import { FLUENT_UI_EMOJIS } from '../../../../../../assets';

export const Container = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINY};
  background-color: ${hexToRgbA(THEME.COLORS.DECOR, THEME.OPACITY.DIVIDER)};
  border: 1px solid ${hexToRgbA(THEME.COLORS.DECOR, THEME.OPACITY.DIVIDER)};
  border-radius: ${THEME.RADIUS.ROUND};
  padding: ${THEME.SPACING.BASE};
  align-items: center;
`;

export const Icon = styled(FLUENT_UI_EMOJIS.PERSON_RAISING_HAND)`
  width: 24px;
`;

export const Text = styled.div`
  ${Body2Font};
  color: ${THEME.COLORS.PRIMARY_DARK};
  white-space: pre-wrap;
`;

export const EnablePermissionsButton = styled.div`
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
`;
