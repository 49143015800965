import styled from 'styled-components';
import { THEME } from '../../../themes';
import { hexToRgbA } from '../../../utils/colors';

export const Bar = styled.div<{ progress: number }>`
  width: ${({ progress }) => progress}%;
  height: 100%;
  border-radius: ${THEME.RADIUS.TIDY};
`;

export const Container = styled.div<{ color: string }>`
  outline: 2px solid ${({ color }) => color};
  border-radius: ${THEME.RADIUS.TIDY};
  background-color: ${({ color }) => hexToRgbA(color, THEME.OPACITY.GHOST)};
  padding: 2px;
  
  & ${Bar} {
    background-color: ${({ color }) => color};
  }
`;
