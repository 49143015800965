import DotsLoader from '../../../../components/Common/Loaders/dots-loader/dots-loader.component';
import { DotsLoaderVariation } from '../../../../components/Common/Loaders/dots-loader/dots-loader.enums';
import { Container } from './page-loader.styled';

const PageLoader = (): JSX.Element => {
  return (
    <Container>
      <DotsLoader variation={ DotsLoaderVariation.Section } />
    </Container>
  );
};

export default PageLoader;
