import styled from 'styled-components';
import { OverlineFont } from '../../../../../themes/fonts';
import { THEME } from '../../../../../themes';

export const Container = styled.div<{
  color: string,
  icon?: string,
}>`
  ${OverlineFont};
  color: ${({ color }) => color};
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  :after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 17px;
    width: 17px;
    margin-left:  ${THEME.SPACING.MICRO};
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-image: url(${({ icon }) => icon});
  }
`;
