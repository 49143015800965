import styled from 'styled-components';
import { THEME } from '../../../../../../themes';

export const SkeletonHeader = styled.div`
  display: flex;
  gap: ${THEME.SPACING.BASE};
  align-items: center;
`;

export const SkeletonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${THEME.SPACING.MICRO};
`;
