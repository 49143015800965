export enum PerfectTextEditorBlockAlignment {
  Left = 'LEFT',
  Center = 'CENTER',
  Right = 'RIGHT',
}

export enum PerfectTextEditorStyleControl {
  Bold = 'BOLD',
  Italic = 'ITALIC',
  Underline = 'UNDERLINE',
}
