import { useTranslation } from 'react-i18next';
import {
  RegularTag,
  VerifiedIcon,
} from '../explainability-info-menu.styled';
import STRING_KEYS from '../../../../../language/keys';
import { COMPANY_SIZE_ICON_MAP } from '../../match-card/timeline/experience-container/experience-item-tags/experience-item-tags.component';
import HeaderSection from '../sections/header-section/header-section.component';
import BaseSection from '../sections/base-section/base-section.component';
import { Container } from './company-size-info-menu.styled';
import { CompanySizeInfoMenuProps } from './company-size-info-menu';

const CompanySizeInfoMenu = ({
  companySize,
  companyName,
}: CompanySizeInfoMenuProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const Icon = COMPANY_SIZE_ICON_MAP[companySize];

  return (
    <Container>
      <>
        <HeaderSection
          title={ translate(STRING_KEYS.COMPANY_SIZE_LABEL) }
          Icon={ <Icon /> }
        >
          <RegularTag text={ translate(STRING_KEYS.COMPANY_SIZE[companySize]) } />
        </HeaderSection>
        <BaseSection
          title={ translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY.INFO_MENU.VERIFICATION_SOURCE_SECTION_TITLE) }
          Icon={ <VerifiedIcon /> }
        >
          { translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY.VERIFIED_FROM_COMPANY_PROFILE_TEXT, { companyName }) }
        </BaseSection>
      </>
    </Container>
  );
};

export default CompanySizeInfoMenu;
