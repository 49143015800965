import { positionQuery } from '../position.toolkit-api';
import { PositionRegionsResponse } from '../dto/response/position-regions.response';

export const useGetPositionRegionsQuery = (): AppSelectorType<PositionRegionsResponse> => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch,
  } = positionQuery.useGetPositionRegionsQuery(undefined);

  return {
    data: data || {} as PositionRegionsResponse,
    error,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
