import styled, { css } from 'styled-components';
import { CaptionFont } from '../../../themes/fonts';
import { THEME } from '../../../themes';
import { TextEllipsis } from '../../../themes/text';
import { PerfectChipVariants } from './perfect-chip.enums';

const fillVariant = css`
  background-color: ${THEME.COLORS.PRIMARY_DARK};
  color: ${THEME.COLORS.PURE_WHITE};
`;

const outlineVariant = css`
  background-color: ${THEME.COLORS.PURE_WHITE};
  color: ${THEME.COLORS.PRIMARY_DARK};
  outline: 1px solid currentcolor;
  fill: currentColor;
`;

export const ChipContainer = styled.div<{ variant: PerfectChipVariants }>`
  border-radius: ${THEME.RADIUS.CURVY};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: center;
  height: 32px;
  width: fit-content;
  gap: ${THEME.SPACING.TINY};
  ${CaptionFont};
  padding-left: ${THEME.SPACING.TINO};
  padding-right: ${THEME.SPACING.TINO};
  word-break: break-all;
  ${({ variant }) => {
    if (variant === PerfectChipVariants.Outline) {
      return outlineVariant;
    }
    
    return fillVariant;
  }}
`;

export const TextWrapper = styled.span`
  ${TextEllipsis};
  max-width: 150px;
`;
