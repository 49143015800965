import { Dictionary, groupBy } from 'lodash';
import {
  getCurrentYearMonthObj,
  getDiffOfDateObjects,
  getSortedEmploymentByEndDate,
  getSortedEmployment as getSortedEmploymentByStartDate,
} from '../../../utils';
import { MatchEmploymentHistoryRecord } from '../../../@types/api';
import { ExperienceItem } from '../../../@types/match';

const ONE_MONTH = 60 * 60 * 24 * 32 * 1000;// Take into account timezone changes

/**
 * get grouped By companyName and companyId object returns grouped By companyName and companyId aggregated object
 * @param companyRoles: Array<MatchEmploymentHistoryRecord> | undefined
 * @returns Dictionary<[MatchEmploymentHistoryRecord, ...MatchEmploymentHistoryRecord[]]>
 */
export const aggregateData = (
  companyRoles: Array<MatchEmploymentHistoryRecord>,
): ExperienceItem[] => {
  let prevRole: MatchEmploymentHistoryRecord | null = null;
  const companyChapters : ExperienceItem[] = [];

  // roles should be sorted ASC
  companyRoles.forEach((role: MatchEmploymentHistoryRecord) => {
    let shouldAggregate = false;

    // if there is a previous role
    if (prevRole) {
      const diff = getDiffOfDateObjects(
        prevRole.endDateObject || getCurrentYearMonthObj(),
        role.startDateObject,
      );

      shouldAggregate = diff <= ONE_MONTH;
    }

    if (shouldAggregate && companyChapters.length > 0) {
      const previousChapter = companyChapters[companyChapters.length - 1];
      if (!previousChapter.keyInsights) {
        previousChapter.keyInsights = role.keyInsights;
      }
      // new role for previous company chapter
      previousChapter.data.push({
        endDateObject: role.endDateObject,
        startDateObject: role.startDateObject,
        title: role.title,
        seniorityLevel: role.seniorityLevel,
        experienceId: role.experienceId,
        generatedSummary: role.generatedSummary,
        explainability: role.explainability,
        companySize: role.companySizeTier,
      });
    } else {
      companyChapters.push({
        companyId: role.companyId,
        companyDescription: role.companyDescription,
        companyLogo: role.companyLogo,
        companyName: role.companyName,
        companyEnrichedData: role.companyEnrichedData,
        industries: role.industries,
        companySizeTier: role.companySizeTier,
        talentSkills: role.skills,
        isCompanyEnriched: role.isCompanyEnriched,
        keyInsights: role.keyInsights,
        data: [
          {
            endDateObject: role.endDateObject,
            startDateObject: role.startDateObject,
            title: role.title,
            seniorityLevel: role.seniorityLevel,
            experienceId: role.experienceId,
            generatedSummary: role.generatedSummary,
            explainability: role.explainability,
            companySize: role.companySizeTier,
          },
        ],
      });
    }

    // set new company for next iteration
    prevRole = role;
  });

  return companyChapters;
};

/**
 * get employment history list returns grouped by company name and company id object
 * @param data: Array<MatchEmploymentHistoryRecord> | undefined
 * @returns Dictionary<[MatchEmploymentHistoryRecord, ...MatchEmploymentHistoryRecord[]]>
 */
const groupedCompaniesByName = (
  data: Array<MatchEmploymentHistoryRecord>,
) => {
  return groupBy(data, (company) => `${company.companyId}-${company.companyName}`);
};

/**
 * get grouped By companyName and companyId object returns grouped By companyName and companyId and aggregated object
 * @returns Dictionary<[string,MatchEmploymentHistoryRecord[]]>
 * @param sortedCompanies
 */
const aggregatedCompanies = (
  sortedCompanies: Dictionary<MatchEmploymentHistoryRecord[]>,
) => {
  let aggregatedCompaniesObject = {};
  Object.keys(sortedCompanies).forEach((key) => {
    aggregatedCompaniesObject = {
      ...aggregatedCompaniesObject,
      [key]: aggregateData(sortedCompanies[key]),
    };
  });

  return aggregatedCompaniesObject;
};

/**
 * get grouped By companyName and companyId object returns list of companies
 * @returns Dictionary<[MatchEmploymentHistoryRecord, ...MatchEmploymentHistoryRecord[]]>
 * @param aggregatedList
 */
const getCompaniesList = (aggregatedList: Dictionary<[ExperienceItem, ...ExperienceItem[]]>) => {
  const temp: Array<ExperienceItem> = [];
  Object.keys(aggregatedList).forEach((key: string) => {
    return aggregatedList[key].forEach((item) => {
      temp.push(item);
    });
  });

  return temp;
};

export const experienceAggregation = (data: Array<MatchEmploymentHistoryRecord> | undefined):
  Array<ExperienceItem> => {
  if (data) {
    // sort company list by start date - DESC (latest role [by start-date] is first)
    const sortedCompanies = getSortedEmploymentByStartDate(data);
    const groupedByCompanies: Dictionary<MatchEmploymentHistoryRecord[]> = groupedCompaniesByName(sortedCompanies); // grouped companies by company name and company id
    const aggregatedCompaniesList = aggregatedCompanies(groupedByCompanies); // aggregate companies by diff between months less or equal 1
    const companiesList = getCompaniesList(aggregatedCompaniesList); // destruct aggregated grouped object to list

    return getSortedEmploymentByEndDate(companiesList); // sort companies list by end date
  }

  return [];
};

export const getFormattedWorkedWithData = (workers: WorkedWithWorker[]): FormattedWorkedWith[] => {
  if (workers) {
    return workers.map((worker: WorkedWithWorker) => {
      return {
        companyName: worker.overlappingExperience[0].companyName,
        endYear: worker.overlappingExperience[0].endYear,
        startYear: worker.overlappingExperience[0].startYear,
        fullName: worker.fullName,
        iconUrl: worker.profileImage,
      };
    });
  }

  return [];
};
