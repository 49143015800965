import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { OnboardingState } from '../onboarding-state.initial-state';

export const setOnboardingLoaderReducer: CaseReducer<OnboardingState, PayloadAction<boolean>> = (state, { payload }) => {
  state.isOnboardingLoading = payload;
};

export const onboardingResetFieldsReducer: CaseReducer<OnboardingState, PayloadAction<undefined>> = (state) => {
  return {
    ...state,
    shouldRetryFetchAllowedCompanies: true,
    userName: '',
    roleName: '',
    selectedCompany: {
      linkedInUrl: '',
      companyName: '',
    },
    selectedJobRequirements: [],
    isOnboardingLoading: false,
    onboardingLoadingError: '',
  };
};
