import styled  from 'styled-components';
import { THEME } from '../../../../../../themes';
import { Subtitle2Font } from '../../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../../utils/colors';

export const MentionPlaceholderContainer = styled.span`
  ${Subtitle2Font};
  padding: ${THEME.SPACING.MICRO} ${THEME.SPACING.TINO};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  background-color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.GHOST)};
  color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.LOW)};
  display: inline-flex;
  gap: ${THEME.SPACING.MICRO};
  align-items: center;
`;
