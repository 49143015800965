import styled from 'styled-components';
import { CaptionFont, OverlineFont } from '../../../../../themes/fonts';
import { THEME } from '../../../../../themes';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import Progress from '../../../../../components/Common/progress/progress.component';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MICRO};
`;

export const Label = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const StyledProgressBar = styled(Progress)`
  width: 140px;
  height: 8px;
`;

export const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PositionsCountText = styled.div`
  ${OverlineFont};
  font-weight: 600;
`;

export const LinkButton = styled(BaseButton)`
  color: ${THEME.COLORS.ACCENT};
  font-weight: bold;
`;
