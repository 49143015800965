import styled from 'styled-components';
import { Avatar as MuiAvatar } from '@mui/material';
import { AvatarProps } from '@mui/material/Avatar/Avatar';
import { THEME } from '../../../themes';

const DEFAULT_AVATAR_SIZE = 56;

export const Avatar = styled(MuiAvatar)<AvatarProps & { size?: number }>`
  width: ${({ size }) => `${size || DEFAULT_AVATAR_SIZE}px !important`};
  height: ${({ size }) => `${size || DEFAULT_AVATAR_SIZE}px !important`};
`;

export const BadgeAvatar = styled(MuiAvatar)`
  width: 24px !important;
  height: 24px !important;
  border-radius: ${THEME.RADIUS.BASE} !important;
  border: 2px solid ${THEME.COLORS.PURE_WHITE} !important;
`;
