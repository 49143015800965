import { useAppSelector } from '../../../../selectors';
import { matchQuery } from '../match.toolkit-api';
import { MatchResponse } from '../dto/response/match.response';

export const matchByIdSelector = matchQuery.endpoints.getMatchById.select;

export const useGetMatchByIdSelector = (positionId = '', matchId = ''): AppSelectorType<MatchResponse> => {
  const {
    data, isLoading, isError, isSuccess, error,
  } = useAppSelector(matchByIdSelector({ matchId, positionId }));

  return {
    data: data || {} as MatchResponse,
    error,
    isLoading,
    isError,
    isSuccess,
  };
};
