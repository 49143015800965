import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import SelectionPopup from '../selection-popup/selection-popup.container';
import STRING_KEYS from '../../../../../../../language/keys';
import TimePicker from '../../../../../../../components/time-picker/time-picker.component';
import {
  Container, Label,
} from './select-time-popup.styled';

const SelectTimesPopup = ({ time, confirmButtonClickHandler }: SelectTimePopupProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [inputValue, setInputValue] = useState<string | undefined>(time);

  const onTimeChange = (value: string) => {
    setInputValue(value);
  };

  return (
    <SelectionPopup
      title={ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TIME.TITLE) }
      description={ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TIME.DESCRIPTION) }
      withFooterAction
      actionHandler={ () => {
        if (inputValue) {
          confirmButtonClickHandler(inputValue);
        }
      } }
    >
      <Container>
        <TimePicker
          time={ inputValue }
          changeHandler={ onTimeChange }
        />
        <Label>{ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TIME.LABEL) }</Label>
      </Container>
    </SelectionPopup>
  );
};

export default SelectTimesPopup;
