import {
  BarStepContainer,
  BarStepTitle,
  StepCompetedIcon,
  StepCurrentIcon,
  StepNextIcon,
  StepsDividerIcon,
} from './progress-bar-step.styled';
import { ProgressBarProps } from './progress-bar-step';
import { ProgressStepTypes } from './progress-bar-step.enum';

const ProgressBarStep = ({ title, step, isLastStep }: ProgressBarProps): JSX.Element => {
  const getIconByStep = () => {
    if (step === ProgressStepTypes.Finished) {
      return <StepCompetedIcon />;
    }

    if (step === ProgressStepTypes.Waiting) {
      return <StepNextIcon />;
    }

    return <StepCurrentIcon />;
  };

  return (
    <BarStepContainer>
      { getIconByStep() }
      <BarStepTitle step={ step }>
        {title}
      </BarStepTitle>
      { !isLastStep && <StepsDividerIcon />}
    </BarStepContainer>
  );
};

ProgressBarStep.defaultProps = {
  isLastStep: undefined,
};

export default ProgressBarStep;
