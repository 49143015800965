import { SequenceStatusEnum } from '../../sequence/enums/sequence-status.enum';
import { PositionSequencePreferencesResponseDto } from '../../sequence/dto/response/position-sequence-preferences.response';

export const createEmailSenderUpdateSequencePreferencesOptimisticHandler = (preferences: PositionSequencePreferencesResponseDto, currentUserId: string): void => {
  if (preferences.sequencePreferences && preferences.sequencePreferences.status !== SequenceStatusEnum.Running) {
    const isCurrentUserPrimarySender = preferences.sequencePreferences.senders?.find((sender) => sender.user?.id === currentUserId && sender.isPrimarySender);
    if (isCurrentUserPrimarySender) {
      preferences.sequencePreferences.status = SequenceStatusEnum.Running;
    }
  }
};
