import { RootLogger } from 'loglevel';
import remote from 'loglevel-plugin-remote';
import { loglevelPluginLogToRemoteServerLog } from '../utils/log-formatter';

const remoteLoggingConfig = {
  url: process.env.REACT_APP_SERVER_LOGS_ENDPOINT,
  method: 'POST',
  headers: {

  },
  token: '',
  timeout: 0,
  interval: Number(process.env.REACT_APP_SERVER_LOGS_SEND_INTERVAL) || 1000,
  level: process.env.REACT_APP_REMOTE_LOG_LEVEL || 'error',
  backoff: {
    multiplier: 2,
    jitter: 0.1,
    limit: 30000,
  },
  capacity: process.env.REACT_APP_REMOTE_CAPACITY || 500,
  stacktrace: {
    levels: ['trace', 'warn', 'error'],
    depth: 3,
    excess: 0,
  },
  timestamp: (): string => new Date().toISOString(),
  format: loglevelPluginLogToRemoteServerLog,
};

const remoteLoggingMiddleware = (logger: RootLogger): void => {
  remote.apply(logger, remoteLoggingConfig);
};

export default remoteLoggingMiddleware;
