import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { UpgradePlanDialog } from '../../../components/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import { UpgradePlanDialogVariation } from '../../../components/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.enums';
import { removeInitialLoadings } from '../../../store/slices/app-state/app-state.toolkit-slice';
import { Container } from './plan-expired.styled';

const PlanExpired = (): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(removeInitialLoadings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      <UpgradePlanDialog isDialogOpen onCloseDialog={ () => {} } variation={ UpgradePlanDialogVariation.TrialEnded } />
    </Container>
  );
};

export default PlanExpired;
