import styled from 'styled-components';
import { Subtitle2Font } from '../../../themes/fonts';
import { THEME } from '../../../themes';

export const TagContainer = styled.div<{ isClickable: boolean }>`
  ${Subtitle2Font};
  line-height: normal;
  padding: ${THEME.SPACING.MICRO} ${THEME.SPACING.TINY} ${THEME.SPACING.MICRO} ${THEME.SPACING.MICRO};
  border-radius: ${THEME.RADIUS.TIDY};
  display: inline-flex;
  width: fit-content;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
  min-height: 24px;
  cursor: ${({ isClickable }) => isClickable ? 'pointer' : undefined};
  
  & svg {
    width: 16px;
    height: 16px;
  }
`;

export const TextWrapper = styled.div``;
