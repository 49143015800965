import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { CaptionFont, OverlineFont, Title2Font } from '../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../utils/colors';
import { ASSETS } from '../../../../../assets';

export const Container = styled.div`
  position: relative;
  flex: 50%;
  display: flex;
  flex-direction: column;
  height: 176px;
  background-color: ${THEME.COLORS.PURE_WHITE};
  border-radius: ${THEME.RADIUS.CURVY};
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.MEDIUM} ${THEME.SPACING.BASE} ${THEME.SPACING.MEDIUM};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: fit-content;
  align-items: center;
`;

export const LabelContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINY};
`;

export const QuestionIcon = styled(ASSETS.QUESTION_OUTLINE)`
  width: 20px;
  height: 20px;
  fill: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
`;

export const Label = styled.span`
  ${OverlineFont};
  font-weight: 600;
  color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW)};
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

export const Title = styled.div`
  ${Title2Font};
  font-weight: 300;
  line-height: 2.25;
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const Description = styled.div`
  ${CaptionFont};
  font-weight: 500;
  line-height: 1.33;
  color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.MED)};
`;

export const TooltipWrapper = styled.div`
  display: flex;
`;
