import styled from 'styled-components';
import { THEME } from '../../../../themes';

export const Container  = styled.div<{ borderBottomDisable: boolean, isSticky: boolean }>`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  padding-left: ${THEME.SPACING.MEDIUM};
  padding-right: ${THEME.SPACING.MEDIUM};
  border-bottom: ${({ borderBottomDisable }) => borderBottomDisable ? undefined : `1px solid ${THEME.COLORS_RGB.PRIMARY_DARK_GHOST}`};;
  background-color: ${({ isSticky }) => isSticky ? `${THEME.COLORS.PURE_WHITE}` : undefined};;
  position: ${({ isSticky }) => isSticky ? 'sticky' : undefined};;
  top: ${({ isSticky }) => isSticky ? 0 : undefined};;
  z-index: ${({ isSticky }) => isSticky ? 999 : undefined};;
`;
