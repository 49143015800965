import styled from 'styled-components';
import { THEME } from '../../../themes';
import { CaptionFont } from '../../../themes/fonts';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: ${THEME.SPACING.XLOOSE};
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  width: 90%;
  max-width: 400px;
  position: relative;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill::first-line,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${THEME.COLORS_RGB.GHOST_GRAY} inset !important;
    -webkit-text-fill-color: ${THEME.COLORS_RGB.CONTENT_PRIMARY_MED} !important;
}
`;

export const ValidationError = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  width: 100%;
  color: ${THEME.COLORS.ALERT};
  margin-top: ${THEME.SPACING.TINY};
`;

export const SubmitButtonWrapper = styled.div<{isLoading: boolean}>`
  width: 100%;
  margin-top: ${THEME.SPACING.MEDIUM};
  opacity: ${({ isLoading }) => isLoading ? THEME.OPACITY.LOW : THEME.OPACITY.HIGHEST};
  pointer-events: ${({ isLoading }) => isLoading ? 'none' : ''};
`;

export const Container = styled.div`
  width: 95%;
`;
