import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { MatchInteractionTypeEnum } from '../../../../enums/match-interaction-type.enum';
import { MatchResponse } from '../../../../store/rtk-query/apis/match/dto/response/match.response';
import { MatchesCarouselProps } from './matches-carousel';
import {
  Carousel, MatchCardContainer,
} from './matches-carousel.styled';
import { addEngagedMatchesIdSet, groupMatches, sortMatches } from './matches-carousel.utils';

const initialAnimationState = {
  liked: false,
  pass: false,
};

const ACTIVE_MATCHES_BATCH = 2;

const MatchesCarousel = ({
  matches,
  matchEngagementHandler,
  disableActions,
  anonymizeTalentDetails,
}: MatchesCarouselProps): JSX.Element => {
  const [progressCounter, setProgressCounter] = useState(0);
  const [activeMatchIndex, setActiveMatchIndex] = useState(0);
  const [matchesBatch, setMatchesBatch] = useState<MatchResponse[]>([]);
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isAnimationRunning, setIsAnimationRunning] = useState(false);
  const [showMatchCardFader, setShowMatchCardFader] = useState(false);
  const [engagedMatchesIdSet, setEngagedMatchesIdSet] = useState<Set<string>>(new Set());
  const [animationAfterAction, setAnimationAfterAction] = useState(initialAnimationState);
  const { toReviewMatches } = useMemo(() => groupMatches(matches), [matches]);
  const sortedMatches = useMemo(() => sortMatches(toReviewMatches), [toReviewMatches]);

  useEffect(() => {
    setMatchesBatch(sortedMatches.slice(activeMatchIndex, ACTIVE_MATCHES_BATCH));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeMatchIndex > 0) {
      setMatchesBatch((prevState) => {
        const slicedMatches = sortedMatches
          .filter((match) => !engagedMatchesIdSet.has(match.id))
          .slice(0, ACTIVE_MATCHES_BATCH);
        const lastSeenMatch = prevState[1];

        return [lastSeenMatch, ...slicedMatches.filter(({ id }) => id !== lastSeenMatch.id)];
      });
      setActiveMatchIndex(0);
    }
  }, [activeMatchIndex, engagedMatchesIdSet, sortedMatches]);

  const goToNextMatch = useCallback(() => {
    setActiveMatchIndex((currentMatchIndex) => {
      const nextIndex = currentMatchIndex + 1;
      if (nextIndex > matchesBatch.length - 1) {
        return currentMatchIndex;
      }

      return nextIndex;
    });
    setProgressCounter((prev) => prev + 1);
  }, [matchesBatch]);

  const onEngageButtonClick = (match: MatchResponse) => {
    carouselRef?.current?.scrollTo(({ top: 0, behavior: 'smooth' }));
    setIsAnimationRunning(true);
    const isLastMatch = match.id === sortedMatches[sortedMatches.length - 1].id;
    matchEngagementHandler(MatchInteractionTypeEnum.Engage, match, isLastMatch);
    setEngagedMatchesIdSet((prevSet) => addEngagedMatchesIdSet(prevSet, match.id));
    setAnimationAfterAction((prevState) => {
      return {
        ...prevState,
        liked: true,
      };
    });
  };

  const onDeclineButtonClick = (match: MatchResponse) => {
    carouselRef?.current?.scrollTo(({ top: 0, behavior: 'smooth' }));
    setIsAnimationRunning(true);
    const isLastMatch = match.id === sortedMatches[sortedMatches.length - 1].id;
    matchEngagementHandler(MatchInteractionTypeEnum.Decline, match, isLastMatch);
    setEngagedMatchesIdSet((prevSet) => addEngagedMatchesIdSet(prevSet, match.id));
    setAnimationAfterAction((prevState) => {
      return {
        ...prevState,
        pass: true,
      };
    });
  };

  const scrollChangeHandler = useCallback((scrollHeight: number, scrollTop: number, clientHeight: number) => {
    const bottom = scrollHeight - scrollTop <= clientHeight + 42;

    if (bottom) {
      setShowMatchCardFader(false);
    } else if (!showMatchCardFader) {
      setShowMatchCardFader(true);
    }
  }, [showMatchCardFader]);

  const viewChangeHandler = useCallback(() => {
    if (!carouselRef?.current) {
      return;
    }

    scrollChangeHandler(carouselRef?.current?.scrollHeight, carouselRef?.current?.scrollTop, carouselRef?.current?.clientHeight);
  }, [scrollChangeHandler]);

  useEffect(() => {
    window.addEventListener('resize', viewChangeHandler);

    return () => window.removeEventListener('resize', viewChangeHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAnimationRunning) {
      setShowMatchCardFader(false);
    }

    if (!carouselRef?.current) {
      return;
    }

    scrollChangeHandler(carouselRef?.current?.scrollHeight, carouselRef?.current?.scrollTop, carouselRef?.current?.clientHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnimationRunning]);

  const MatchCards = useMemo(() => {
    return matchesBatch.map((match, index) => {
      return (
        <MatchCardContainer
          key={ match.id }
          $matchCardIndex={ index }
          $matchCardActiveIndex={ activeMatchIndex }
          engagedMatchCount={ progressCounter }
          $playLikeAnimation={ animationAfterAction.liked && index === activeMatchIndex }
          $playPassAnimation={ animationAfterAction.pass && index === activeMatchIndex }
          $isAnimationRunning={ isAnimationRunning }
          match={ match }
          anonymizeTalentDetails={ anonymizeTalentDetails }
          matchesLength={ matches.length }
          isActive={ index === activeMatchIndex }
          engageHandler={ () => onEngageButtonClick(match) }
          declineHandler={ () => onDeclineButtonClick(match) }
          viewChangeHandler={ () => setTimeout(viewChangeHandler, 200) }
          disableActions={ disableActions }
          showBottomFader={ index === activeMatchIndex && showMatchCardFader }
          onAnimationEnd={ () => {
            setIsAnimationRunning(false);
            goToNextMatch();
          } }
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    matches.length,
    matchesBatch,
    activeMatchIndex,
    animationAfterAction.liked,
    animationAfterAction.pass,
    isAnimationRunning,
    disableActions,
    showMatchCardFader,
    viewChangeHandler,
    goToNextMatch,
  ]);

  return (
    <Carousel
      ref={ carouselRef }
      onAnimationEnd={ () => setAnimationAfterAction(initialAnimationState) }
      onScroll={ viewChangeHandler }
    >
      { MatchCards }
    </Carousel>
  );
};

export default MatchesCarousel;
