import BaseSwitch from '../../../../../components/Common/base-switch/base-switch.component';
import { CheckedIcon, UnCheckedIcon } from './secondary-switch.styled';

const SecondarySwitch = ({ checked, disabled, changeHandler }: SecondarySwitchProps): JSX.Element => {
  return (
    <BaseSwitch
      checked={ checked }
      switchChangeHandler={ (value) => changeHandler(value) }
      CheckedIcon={ <CheckedIcon /> }
      UnCheckedIcon={ <UnCheckedIcon /> }
      disabled={ disabled }
    />
  );
};

export default SecondarySwitch;
