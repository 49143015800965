import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { PerfectLogo } from '../../PerfectLogo';
import { AppRouting } from '../../../../services/appRoutingResolver';
import {
  Container,
  LogoContainer,
  Title,
  ContentContainer,
  HomePageLink,
  ImageWrapperEffect,
  ErrorImage,
  ErrorCode,
} from './styled';

type ErrorPageProps = {
  title: React.ReactNode,
  content: React.ReactNode,
  errorCode?: string,
  image?: string,
  imageAlt?: string,
}

export const ErrorPage = ({
  title,
  content,
  errorCode,
  image,
  imageAlt,
}: ErrorPageProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <LogoContainer>
        <PerfectLogo />
      </LogoContainer>

      <Title>{title}</Title>

      <ContentContainer>
        {content}
      </ContentContainer>

      <HomePageLink href={ AppRouting.ROUTES.HOME }>
        {translate(STRING_KEYS.GO_BACK_TO_HOMEPAGE)}
      </HomePageLink>

      <ImageWrapperEffect>
        <ErrorImage src={ image } alt={ imageAlt } />
      </ImageWrapperEffect>

      {!!errorCode && (
        <ErrorCode>
          {errorCode}
        </ErrorCode>
      )}
    </Container>
  );
};

ErrorPage.defaultProps = {
  image: '',
  imageAlt: '',
  errorCode: undefined,
};
