import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import { OutreachInitializationButton } from '../../dialogs/outreach-initialization-dialog/outreach-initialization-dialog.styled';
import { THEME } from '../../../themes';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Iframe = styled.iframe`
  border: none;
  height: 56px;
  width: 100%;
`;
export const DummyButton = styled(OutreachInitializationButton)`
  z-index: -1;
  position: absolute;
  top: 0;
`;

export const Loader = styled(CircularProgress)`
  margin-left: ${THEME.SPACING.BASE};
`;
