import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import BasePositionAssistant from '../base-position-assistant/base-position-assistant.component';
import { ANIMATED_FLUENT_UI_EMOJIS } from '../../../../../assets';

const SlothImg = () => (
  <img
    src={ ANIMATED_FLUENT_UI_EMOJIS.SLOTH }
    alt="Sloth"
    width="112"
    height="112"
  />
);

const EmptyAssistant = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BasePositionAssistant
      title={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.EMPTY_ASSISTANT.TITLE) }
      description={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.EMPTY_ASSISTANT.DESCRIPTION) }
      Icon={ SlothImg }
      isDark
    />
  );
};

export default EmptyAssistant;
