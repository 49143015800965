/* eslint-disable react-hooks/exhaustive-deps */
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useState } from 'react';
import { MatchAtsSyncStatusEnum } from '../../../enums/match-ats-sync-status.enum';
import STRING_KEYS from '../../../language/keys';
import { addMatchToAts } from '../../../api/match';
import {
  ACTION_SOURCE_LOCATION, ANALYTICS_EVENTS, EVENT_GROUP, PRODUCT_AREA,
} from '../../../consts/analytics';
import { useAnalyticsContext } from '../../../contexts/analytics/useAnalyticsContext';
import useInterval from '../../../hooks/interval.hook';
import { useMatchQuery } from '../../../hooks/queries';
import { REFETCH_MATCH_MS } from '../../../modules/application/components/outreach/match-side-panel/match-side-panel.consts';
import { useGetPositionByIdQuery } from '../../../store/rtk-query/apis/position/hooks/get-position-by-id.query-hook';
import { useGetMyAccountSelector } from '../../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import { THEME } from '../../../themes';
import { useShouldLockFeature } from '../../../hooks/should-lock-feature.hook';
import AtsInitializationDialogs from '../../dialogs/ats-initialization-dialogs/ats-initialization-dialogs.component';
import { UpgradePlanDialog } from '../../dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import { PerfectButtonVariants } from '../../Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { AtsButtonProps } from './ats-button';
import {
  AtsIntegrationButton,
  AtsIntegrationSynced,
  AtsName,
  AtsUploadedContainer,
  InfinityIcon,
} from './ats-button.styled';

const AtsButton = ({
  matchId, positionId, size, color = THEME.COLORS.PRIMARY, errorHandler,
}: AtsButtonProps): JSX.Element => {
  const [isAtsInitializationDialogsOpen, setIsAtsInitializationDialogsOpen] = useState(false);
  const [isAccountUpgradeDialogOpen, setIsAccountUpgradeDialogOpen] = useState(false);
  const { data: currentAccount } = useGetMyAccountSelector();
  const { t: translate } = useTranslation();
  const isLocked = useShouldLockFeature();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const { data: currentPosition } = useGetPositionByIdQuery(positionId);
  const {
    data: match,
    refetch: refetchMatch,
  } = useMatchQuery(positionId, matchId);

  useInterval(refetchMatch,
    match?.ats?.syncStatus?.status === MatchAtsSyncStatusEnum.InProgress || match?.ats?.syncStatus?.status === MatchAtsSyncStatusEnum.Pending,
    REFETCH_MATCH_MS);

  const onAddTalentToAtsError = () => {
    errorHandler?.();
  };

  const {
    mutate: addTalentToAtsMutation,
    isLoading: isAddTalentToAtsLoading,
  } = useMutation(
    ['add_match_to_ats', positionId, matchId],
    () => addMatchToAts(positionId, matchId),
    {
      onSuccess: () => refetchMatch(),
      onError: onAddTalentToAtsError,
    },
  );

  const sendAtsEvent = (eventName: string, additionalPayloadData: Record<string, string | boolean> = {}) => {
    const payload = {
      eventName,
      eventGroup: EVENT_GROUP.CLICK,
      productArea: PRODUCT_AREA.MATCH_PAGE,
      pagePositionId: positionId,
      pagePositionName: currentPosition.jobTitle,
      pagePositionStatus: currentPosition.status,
      pagePositionAtsName: currentPosition.linkedAtsJob?.jobTitle,
      pagePositionAtsIsAutomatic: currentPosition.linkedAtsJob?.syncAutomatically,
      actionSourceLocation: ACTION_SOURCE_LOCATION.ENGAGEMENT_MODAL,
      ...additionalPayloadData,
    };
    analyticsTrackEvent(payload);
  };

  const onAddTalentToAtsButtonClick = () => {
    if (isLocked) {
      setIsAccountUpgradeDialogOpen(true);

      return;
    }

    if (!currentAccount.atsIntegration || !currentPosition.linkedAtsJob) {
      setIsAtsInitializationDialogsOpen(true);

      return;
    }

    addTalentToAtsMutation();
    sendAtsEvent(ANALYTICS_EVENTS.CLICKED_ON_ADD_TALENT_TO_ATS);
  };

  const loadingState = isAddTalentToAtsLoading ||
    match?.ats?.syncStatus?.status === MatchAtsSyncStatusEnum.InProgress ||
    match?.ats?.syncStatus?.status === MatchAtsSyncStatusEnum.Pending;

  const title = loadingState ? translate(STRING_KEYS.ENGAGE_PANEL.UPLOAD_ATS_BUTTON.UPLOADING, {
    name: currentAccount.atsIntegration?.atsName,
  }) : translate(STRING_KEYS.ENGAGE_PANEL.UPLOAD_ATS_BUTTON.UPLOAD);

  const renderAtsStateComponent = (): JSX.Element => {
    if (match?.ats?.linkedCandidate || match?.ats?.syncStatus?.status === MatchAtsSyncStatusEnum.Finished) {
      return (
        <AtsUploadedContainer>
          <InfinityIcon />
          <Trans i18nKey={ STRING_KEYS.ENGAGE_PANEL.UPLOAD_ATS_BUTTON.UPLOADED }>
            <AtsIntegrationSynced />
            <AtsName />
            {{ name: currentAccount.atsIntegration?.atsName }}
          </Trans>
        </AtsUploadedContainer>
      );
    }

    return (
      <>
        <AtsIntegrationButton
          customColor={ color }
          onClick={ onAddTalentToAtsButtonClick }
          variant={ PerfectButtonVariants.Link }
          StartIcon={ InfinityIcon }
          isLoading={ loadingState }
          size={ size }
        >
          { title }
        </AtsIntegrationButton>
      </>
    );
  };

  return (
    <>
      { renderAtsStateComponent() }
      { isAtsInitializationDialogsOpen && (
        <AtsInitializationDialogs
          positionId={ positionId }
          isOpen={ isAtsInitializationDialogsOpen }
          closeHandler={ () => setIsAtsInitializationDialogsOpen(false) }
          productArea={ PRODUCT_AREA.MATCH_PAGE }
        />
      ) }
      { isAccountUpgradeDialogOpen && (
        <UpgradePlanDialog
          isDialogOpen={ isAccountUpgradeDialogOpen }
          onCloseDialog={ () => setIsAccountUpgradeDialogOpen(false) }
        />
      )}
    </>
  );
};

export default AtsButton;
