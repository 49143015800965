import {
  SequenceMessageTypeEnum,
} from '../../../../../../store/rtk-query/apis/sequence/enums/sequence-message-type.enum';

export const MAX_CUSTOM_STEPS = 10;
export const OUTREACH_CUSTOM_STEP_DEFAULT_TIME = '10:30';

export const EMPTY_STEP = {
  days: '3',
  time: OUTREACH_CUSTOM_STEP_DEFAULT_TIME,
  type: SequenceMessageTypeEnum.Email,
  sendAsReply: true,
  isNotSavedStep: true,
  isWorkDays: true,
  initSubject: '',
  initMessage: '',
};

export const FIRST_TIME_DAYS = '0';
