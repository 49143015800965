import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import STRING_KEYS from '../../../../language/keys';
import { useUpdatePositionMutation } from '../../../../store/rtk-query/apis/position/hooks/update-position.mutation-hook';
import { positionQuery } from '../../../../store/rtk-query/apis/position/position.toolkit-api';
import { AlertSnackBar } from '../../../../components/Common/CustomSnackBar/AlertSnackBar';
import {
  Container,
  SubTitle,
  Title,
  TitleContainer,
} from './position-adjustments.styled';
import RecommendedAdjustments from './recommended-adjustments/recommended-adjustments.component';
import AdjustmentsContainer from './adjustments-container/adjustments-container.component';
import { PositionAdjustmentsProps } from './position-adjustments';

const PositionAdjustments = ({ position, adjustments, editPositionPreferencesButtonClickHandler }: PositionAdjustmentsProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [updatePosition, { isLoading: isEditPositionLoading, isSuccess: isEditPositionSuccess, isError: isEditPositionError }] = useUpdatePositionMutation();
  const [getPosition] = positionQuery.useLazyGetPositionByIdQuery();
  const [getPositions] = positionQuery.useLazyGetOpenPositionsQuery();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);

  // todo: combine with the same AdjustmentsContainer logic
  const onEditPositionSuccess = async () => {
    await Promise.all([getPosition(position.id), getPositions()]);
  };

  useEffect(() => {
    if (isEditPositionSuccess) {
      onEditPositionSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditPositionSuccess]);

  useEffect(() => {
    if (isEditPositionError) {
      setIsSnackbarOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditPositionError]);

  const renderBody = () => {
    if (adjustments) {
      return (
        <RecommendedAdjustments
          position={ position }
          adjustments={ adjustments }
          confirmHandler={ updatePosition }
          isEditPositionLoading={ isEditPositionLoading }
        />
      );
    }

    return (<AdjustmentsContainer position={ position } editPositionPreferencesButtonClickHandler={ editPositionPreferencesButtonClickHandler } />);
  };

  return (
    <Container>
      <div>
        <TitleContainer>
          <SubTitle>{ translate(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.SUBTITLE) }</SubTitle>
          <Title>{ translate(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.TITLE) }</Title>
        </TitleContainer>
        { renderBody() }
        <AlertSnackBar
          title={ translate(STRING_KEYS.ALERT) }
          anchor="top-center"
          description={ translate(STRING_KEYS.GLOBAL_ERROR_MESSAGE) }
          isOpen={ isSnackbarOpen }
          shouldAutoDismiss
          onClose={ () => setIsSnackbarOpen(false) }
        />
      </div>
    </Container>
  );
};

export default PositionAdjustments;
