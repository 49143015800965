import {
  Wrapper,
  ErrorIcon,
  ErrorContent,
} from './error-message.styled';

type ErrorMessageProps = {
  errorMessage: string,
};

export const ErrorMessage = ({
  errorMessage,
}:ErrorMessageProps): JSX.Element => {
  return (
    <Wrapper>
      <ErrorIcon>
        !
      </ErrorIcon>
      <ErrorContent>
        {errorMessage}
      </ErrorContent>
    </Wrapper>
  );
};
