import { Trans, useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import STRING_KEYS from '../../../language/keys';
import NotificationDialog from '../notification-dialog/notification-dialog.component';
import { IntercomManager } from '../../../services/intercom-manager';
import { useMyUserSelector } from '../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { useDeleteAccountAcknowledgementById } from '../../../store/rtk-query/apis/user/hooks/delete-account-aknowledgement.hook';
import { useSeeAcknowledgements } from '../../../hooks/acknowledgements-react-qury.hooks';
import { AcknowledgementType } from '../../../enums/acknowledgement-type.enum';
import { useGetPositionSequencePreferencesQuery } from '../../../store/rtk-query/apis/sequence/hooks/get-position-sequence-preferences.query-hook';
import { useGetPositionByIdFromPositionsQuerySelector } from '../../../store/rtk-query/apis/position/selectors/get-positions.selector';
import { OutreachInitializationDialogs } from '../outreach-initialization-dialogs/outreach-initialization-dialogs.component';
import {
  ActionIcon,
  ContactSupportButton,
  Container,
  Description,
  Icon,
  Title,
} from './email-connection-error-notification.styled';

const EmailConnectionErrorNotification = (): JSX.Element | null => {
  const { t: translate } = useTranslation();
  const { data: { acknowledgements, id: userId } } = useMyUserSelector();
  const deleteAccountAcknowledgementById = useDeleteAccountAcknowledgementById();
  const { mutate: seeAcknowledgementMutate } = useSeeAcknowledgements();
  const [isNotificationOpen, setIsNotificationOpen] = useState(true);
  const [isOutreachInitializationDialogOpen, setIsOutreachInitializationDialogOpen]  = useState(false);

  const getEmailConnectionErrorAcknowledgement = () => {
    const emailConnectionErrorAcknowledgements = acknowledgements?.filter((ack) => ack.type === AcknowledgementType.EmailConnectionError);

    return emailConnectionErrorAcknowledgements?.find((ack) => ack.metadata.senderId === userId) || emailConnectionErrorAcknowledgements?.[0];
  };

  const emailConnectionErrorAcknowledgement = useMemo(getEmailConnectionErrorAcknowledgement, [acknowledgements, userId]);

  const isNotificationRelatedToCurrentUser = emailConnectionErrorAcknowledgement?.metadata.senderId === userId;

  const {
    data: preferences,
    isLoading: isPreferencesLoading,
  } = useGetPositionSequencePreferencesQuery(emailConnectionErrorAcknowledgement?.positionId, !emailConnectionErrorAcknowledgement || isNotificationRelatedToCurrentUser);
  const { data: relatedPosition } = useGetPositionByIdFromPositionsQuerySelector(emailConnectionErrorAcknowledgement?.positionId);

  const senderName = useMemo(
    () => preferences.sequencePreferences?.senders?.find((sender) => sender.user.id === emailConnectionErrorAcknowledgement.metadata.senderId)?.user?.fullName,
    [preferences.sequencePreferences?.senders, emailConnectionErrorAcknowledgement?.metadata?.senderId]);

  const getCurrentUserDescription = () => {
    if (relatedPosition.jobTitle) {
      return STRING_KEYS.CONNECT_EMAIL_NOTIFICATION.CURRENT_USER_DESCRIPTION;
    }

    return STRING_KEYS.CONNECT_EMAIL_NOTIFICATION.CURRENT_USER_DESCRIPTION_WITHOUT_POSITION;
  };

  const getOtherUserDescription = () => {
    if (!senderName && !relatedPosition.jobTitle) {
      return STRING_KEYS.CONNECT_EMAIL_NOTIFICATION.DESCRIPTION_WITHOUT_POSITION_AND_USERNAME;
    }
    if (!senderName) {
      return STRING_KEYS.CONNECT_EMAIL_NOTIFICATION.DESCRIPTION_WITHOUT_USERNAME;
    }
    if (!relatedPosition.jobTitle) {
      return STRING_KEYS.CONNECT_EMAIL_NOTIFICATION.DESCRIPTION_WITHOUT_POSITION;
    }

    return STRING_KEYS.CONNECT_EMAIL_NOTIFICATION.DESCRIPTION;
  };

  const getDescriptionKey = () => {
    if (isNotificationRelatedToCurrentUser) {
      return getCurrentUserDescription();
    }

    return getOtherUserDescription();
  };

  const DescriptionComponent = useMemo(() => {
    return (
      <Description>
        <Trans
          i18nKey={ getDescriptionKey() }
          values={ { userName: senderName, positionName: relatedPosition.jobTitle } }
        >
          <ContactSupportButton onClick={ IntercomManager.showChat } />
        </Trans>
      </Description>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedPosition.jobTitle, senderName, isNotificationRelatedToCurrentUser]);

  const dismissAcknowledgementAndCloseNotification = () => {
    setIsNotificationOpen(false);
    seeAcknowledgementMutate(emailConnectionErrorAcknowledgement.id);
    deleteAccountAcknowledgementById(emailConnectionErrorAcknowledgement.id);
  };

  const onActionButtonClick = () => {
    dismissAcknowledgementAndCloseNotification();
    setIsOutreachInitializationDialogOpen(true);
  };

  const showNotificationActionButton = isNotificationRelatedToCurrentUser && relatedPosition.id;

  if ((!emailConnectionErrorAcknowledgement || isPreferencesLoading) && !isOutreachInitializationDialogOpen) {
    return null;
  }

  return (
    <>
      <NotificationDialog
        isOpen={ isNotificationOpen }
        onClose={ dismissAcknowledgementAndCloseNotification }
        closeTitle={ translate(STRING_KEYS.CONNECT_EMAIL_NOTIFICATION.CLOSE_BUTTON_TEXT) }
        actionTitle={ translate(STRING_KEYS.CONNECT_EMAIL_NOTIFICATION.ACTION_BUTTON_TEXT) }
        ActionIcon={ ActionIcon }
        onAction={ showNotificationActionButton ? onActionButtonClick : undefined }
        showDivider
      >
        <Container>
          <Icon />
          <Title>{ translate(STRING_KEYS.CONNECT_EMAIL_NOTIFICATION.TITLE) }</Title>
          { DescriptionComponent }
        </Container>
      </NotificationDialog>
      <OutreachInitializationDialogs
        isOpen={ isOutreachInitializationDialogOpen }
        closeDialogHandler={ () => setIsOutreachInitializationDialogOpen(false) }
        positionId={ relatedPosition.id }
        disableSuccessDialog
      />
    </>
  );
};

export default EmailConnectionErrorNotification;
