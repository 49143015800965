import { PositionResponse, PositionUserRole } from './dto/response/position.response';

export const updatePositionUserRoles = (position: PositionResponse, recruiters?: PositionUserRole[], hiringManagers?: PositionUserRole[]): PositionResponse => {
  if (position) {
    if (recruiters) {
      position.recruiters = recruiters;
    }
    if (hiringManagers) {
      position.hiringManagers = hiringManagers;
    }
  }

  return position;
};
