import { ToneOfVoiceEnum } from '../../../../store/rtk-query/apis/sequence/enums/tone-of-voice.enum';

export const TONE_OF_VOICE_TO_EMOJI_MAP = {
  [ToneOfVoiceEnum.Auto]: '✨',
  [ToneOfVoiceEnum.ProfessionalAndFormal]: '👔',
  [ToneOfVoiceEnum.FriendlyAndConversational]: '😊',
  [ToneOfVoiceEnum.EnthusiasticAndEnergetic]: '⚡',
  [ToneOfVoiceEnum.InspirationalAndAspirational]: '🌟',
  [ToneOfVoiceEnum.DirectAndConcise]: '🕰',
  [ToneOfVoiceEnum.EmpatheticAndUnderstanding]: '💙',
  [ToneOfVoiceEnum.InformativeAndDetailed]: '🔍',
  [ToneOfVoiceEnum.LightheartedAndHumorous]: '😄',
  [ToneOfVoiceEnum.ProfessionalPolished]: '🖋️',
};
