import { useTranslation } from 'react-i18next';
import { OutreachInitializationButton } from '../../../dialogs/outreach-initialization-dialog/outreach-initialization-dialog.styled';
import STRING_KEYS from '../../../../language/keys';
import { CONFIG } from '../../../../config';
import { MicrosoftLogo } from './microsoft-login.styled';

export const MicrosoftLogin = ({ successHandler, errorHandler, readPermissionsApproved }: MicrosoftLoginProps): JSX.Element | null => {
  const { t: translate } = useTranslation();

  const clientId = CONFIG.OUTREACH_MICROSOFT_CLIENT_ID;
  const redirectUri = CONFIG.MICROSOFT_EMAIL_OAUTH_URL; // Replace with your app's redirect URI
  const scope = 'openid profile User.Read email offline_access https://graph.microsoft.com/Mail.Send'; // Replace with the scopes you need
  const scopeWithReadPermissions = `${scope} https://graph.microsoft.com/Mail.ReadWrite`;

  const loginUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${readPermissionsApproved ? scopeWithReadPermissions : scope}&prompt=select_account`;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.onSuccess = (value) => {
    successHandler(value);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.onError = (error, errorDescription) => {
    errorHandler(error, errorDescription);
  };

  const onLoginClick = () => {
    const width = 500;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    window.open(loginUrl, 'Microsoft Login', `width=${width},height=${height},left=${left},top=${top}`);
  };

  return (
    <OutreachInitializationButton onClick={ onLoginClick }>
      <MicrosoftLogo />
      {translate(STRING_KEYS.CONTINUE_WITH_MICROSOFT)}
    </OutreachInitializationButton>
  );
};
