import {
  MutableRefObject, useEffect, useRef, useState,
} from 'react';

export const useResizeObserver = (ref: MutableRefObject<HTMLElement | null>): DOMRect => {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [rect, setRect] = useState<DOMRect>({} as DOMRect);
  const observer = useRef<ResizeObserver | null>(null);

  const cleanObserver = () => {
    if (observer.current) {
      observer.current.disconnect();
    }
  };

  useEffect(() => {
    setElement(ref.current);
  }, [ref]);

  useEffect(() => {
    if (!element) {
      return undefined;
    }

    cleanObserver();

    observer.current = new ResizeObserver(([entry]) => {
      setRect(entry.target.getBoundingClientRect());
    });

    observer.current.observe(element);

    return () => {
      cleanObserver();
    };
  }, [element]);

  return rect;
};
