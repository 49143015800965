import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootReducerState } from '../index';
import { INITIAL_LOADING } from '../slices/app-state/app-state.consts';

const appState = (state: RootReducerState) => state.appReducer;

const selectLoadRudderstackState = createSelector(
  [appState],
  ({ initialLoadings }) => !initialLoadings.includes(INITIAL_LOADING.LOAD_RUDDERSTACK),
);

export const useLoadRudderstackState = (): boolean => {
  return useSelector((state: RootReducerState) => {
    return selectLoadRudderstackState(state);
  });
};
