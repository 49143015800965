/* eslint-disable react/jsx-props-no-spreading */
import { useMemo, useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { THEME } from '../../../../../../themes';
import { ActiveLevel, Container, EmptyLevel } from './meter.styled';
import { MeterProps } from './meter';

const Meter = (props: MeterProps): JSX.Element => {
  const {
    level,
    color,
    totalBarSize,
    barStyle,
    ...otherProps
  } = props;

  const containerRef = useRef(null);
  const {
    inViewport,
    enterCount,
  } = useInViewport(
    containerRef,
    {},
    {},
  );

  const [activeLevels, emptyLevels] = useMemo(() => {
    const active = Array.from({
      length: level > totalBarSize ?
        totalBarSize : level,
    }, (v, i) => i);
    const empty = Array.from({ length: totalBarSize - level }, (v, i) => i);

    return [
      active.map((item, index) => (
        <ActiveLevel
          color={ color }
          key={ `active-level-${level}-${item}` }
          index={ index }
          isEnableAnimation={ inViewport && enterCount === 1 }
          barStyle={ barStyle }
          onAnimationEnd={ (e) => e.stopPropagation() }
        />
      )),
      empty.map((item, index) => (
        <EmptyLevel
          key={ `empty-level-${level}-${item}` }
          isEnableAnimation={ inViewport && enterCount === 1 }
          color={ index === 0 ? color : THEME.COLORS.PRIMARY }
          isFirst={ index === 0 }
          index={ active.length - 1 + (index + 1) }
          barStyle={ barStyle }
          onAnimationEnd={ (e) => e.stopPropagation() }
        />
      )),
    ];
  }, [barStyle, color, enterCount, inViewport, level, totalBarSize]);

  return (
    <Container
      ref={ containerRef }
      { ...otherProps }
    >
      { activeLevels }
      { emptyLevels }
    </Container>
  );
};

export default Meter;
