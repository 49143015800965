export enum OutreachContentPreferencesFormikKeys {
  ToneOfVoice = 'toneOfVoice',
  InsiderNetwork = 'insiderNetwork',
  LikelyhoodToMove = 'likelyhoodToMove',
  RelevantSkills = 'relevantSkills',
  RelevantEducation = 'relevantEducation',
  SimilarCompanies = 'similarCompanies',
  AboutTheCompany = 'aboutTheCompany',
  PreferredCompanyName = 'preferredCompanyName',
  PreferredJobTitle = 'preferredJobTitle',
  PreferredPositionLocation = 'preferredPositionLocation',
  AboutTheRole = 'aboutTheRole',
  Extras = 'extras',
  AgencyName = 'agencyName',
  AboutAgency = 'aboutAgency',
  AgencyModeEnabled = 'agencyModeEnabled',
  HiringCompanyNameConfidential = 'hiringCompanyNameConfidential',
  MessageCharactersLength = 'messageCharactersLength',
  IncludeEmailSignature = 'includeEmailSignature',
  IncludeEmailTracking = 'includeEmailTracking',
}
