import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/base-query';
import {
  fillUserInfoQuery,
  getAccountUsersQuery,
  getMyUserQuery,
  inviteColleagueQuery,
  resendInviteColleagueQuery,
} from './user.toolkit-queries';
import { REDUCER_PATH } from './user.consts';
import { UserInfoResponse } from './dto/response/user-info.response';
import { errorObjectToStringTransformer } from './user.toolkit-error-transformers';
import { UpdateUserQueryArguments } from './dto/query-arguments/update-user.query-arguments';
import { UserResponse } from './dto/response/invited-users.response';
import { ResendInviteColleagueQueryArguments } from './dto/query-arguments/resend-invite-colleague.query-arguments';

export const userQuery = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getMyUser: builder.query<UserInfoResponse, void>({
      query: getMyUserQuery,
    }),
    getAccountUsers: builder.query<UserResponse[], void>({
      query: getAccountUsersQuery,
      transformErrorResponse: errorObjectToStringTransformer,
    }),
    inviteColleague: builder.mutation<UserResponse[], string>({
      query: inviteColleagueQuery,
      transformErrorResponse: errorObjectToStringTransformer,
    }),
    resendInviteColleague: builder.mutation<UserResponse[], ResendInviteColleagueQueryArguments>({
      query: resendInviteColleagueQuery,
    }),
    updateUser: builder.mutation<UserResponse[], UpdateUserQueryArguments>({
      query: fillUserInfoQuery,
      transformErrorResponse: errorObjectToStringTransformer,
      async onQueryStarted(
        { requestedCustomOutreachCampaign }, { dispatch, queryFulfilled },
      ) {
        let patchResult;
        if (requestedCustomOutreachCampaign !== undefined) {
          patchResult = dispatch(
            userQuery.util.updateQueryData('getMyUser', undefined, (draft) => {
              draft.requestedCustomOutreachCampaign = requestedCustomOutreachCampaign;
            }),
          );
        }
        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),
  }),
});
