import styled from 'styled-components';
import Dialog from '../../base-dialog/base-dialog.component';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';
import {
  CaptionFont,
  HeadlineFont,
  Subtitle1Font,
  Title2Font,
} from '../../../../themes/fonts';
import BaseButton from '../../../Common/Buttons/perfect-base-button/perfect-base-button.component';

export const DialogBody = styled(Dialog.Body)`
  display: flex;
  flex-direction: column;
`;

export const DialogFooter = styled(Dialog.Footer)<{ hasText: boolean }>`
  display: flex;
  justify-content: ${({ hasText }) => hasText ? 'space-between' : 'end'};
  align-items: flex-end;
`;

export const DialogFooterTitle = styled.div`
  ${HeadlineFont};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const DialogFooterSubTitle = styled.div`
  ${CaptionFont};
  font-size: 10px;
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
  margin-top: ${THEME.SPACING.MICRO};
`;

export const DialogTitle = styled.div`
  ${Title2Font};
  font-weight: 600;
  line-height: 1.19;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const DialogSubTitle = styled.div`
  ${Subtitle1Font};
  font-weight: 400;
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
  margin-top: ${THEME.SPACING.LOOSE};
`;

export const DialogContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

export const DialogLeftImage = styled.div`
  width: 298px;
  background-image: url(${ASSETS.SLOTH_ON_ROCKET});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const BodyContainer = styled.div`
  ${Subtitle1Font};
  font-weight: 400;
  line-height: 1.5;
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
  margin-top: ${THEME.SPACING.MEDIUM};
  margin-bottom: ${THEME.SPACING.XLARGE};
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MEDIUM};
`;

export const ActionButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.SECONDARY};
`;

export const ActionGhostButton = styled(BaseButton)`
  color: ${THEME.COLORS.SECONDARY};
`;
