import styled from 'styled-components';
import { THEME } from '../../../themes';

export const MatchEngineProgressBarContainer = styled.div`
  background-color: ${THEME.COLORS_RGB.PRIMARY_DARK_GHOST};
  border-radius:  ${THEME.RADIUS.ROUND};
  position: relative;
  height: 32px;
`;

export const MatchEngineProgressBarRank = styled.div<{ progress: number }>`
  height: 40px;
  width: ${({ progress }) => `${progress}%`};
  
  background-image: linear-gradient(
          135deg,
          ${THEME.COLORS_RGB.WHITE_DIVIDER} 25%,
          transparent 25%,
          transparent 50%,
          ${THEME.COLORS_RGB.WHITE_DIVIDER} 50%,
          ${THEME.COLORS_RGB.WHITE_DIVIDER} 75%,
          transparent 75%, transparent
  );
  background-size: 15px 15px;
  background-color: ${THEME.COLORS.ACCENT};
  animation: animate-stripes 3s linear infinite;
  will-change: transform;
  transition: all 0.5s;
  border-radius: ${THEME.RADIUS.CURVY};
  position: absolute;
  top: -3px;
  left: 0;
  z-index: 9;


  @keyframes animate-stripes {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 60px 0;
    }
  }
`;

export const MatchEngineProgressBarBullet = styled.div<{ left: number, active?: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: ${THEME.RADIUS.BASE};
  background-color: ${({ active }) => !active ? THEME.COLORS_RGB.PRIMARY_DARK_DIVIDER : undefined};
  border: ${({ active }) => active ? `solid 2px ${THEME.COLORS.ACCENT}` : undefined};
  position: absolute;
  left: ${({ left }) => `${left}%`};
  top: 33%;
`;

export const MatchEngineProgressBarText = styled.div<{ left: number, active?: boolean }>`
  position: absolute;
  left: ${({ left }) => `${left}%`};
  top: ${THEME.SPACING.GRAND};
`;
