import { QueryMethods } from '../../enums/query-methods.enums';
import { PostQuery, GetQuery, PutQuery } from '../../@types/rtk-query';
import { ROUTES } from './user.consts';
import { InviteColleagueRequest } from './dto/request/invite-colleaque.request';
import { ResendInviteColleagueQueryArguments } from './dto/query-arguments/resend-invite-colleague.query-arguments';
import { FillUserInfoRequest } from './dto/request/fill-user-info.request';
import { UpdateUserQueryArguments } from './dto/query-arguments/update-user.query-arguments';

export const getMyUserQuery = (): GetQuery => ({
  url: ROUTES.USER_INFO,
  method: QueryMethods.Get,
});

export const getAccountUsersQuery = (): GetQuery => ({
  url: ROUTES.USERS,
  method: QueryMethods.Get,
});

export const inviteColleagueQuery = (email: string): PostQuery<InviteColleagueRequest> => ({
  url: ROUTES.USER_INVITE,
  method: QueryMethods.Post,
  data: {
    email,
  },
});

export const resendInviteColleagueQuery = ({ userId, email }: ResendInviteColleagueQueryArguments): PostQuery<ResendInviteColleagueQueryArguments> => ({
  url: ROUTES.USER_INVITE_RESEND,
  method: QueryMethods.Post,
  data: {
    userId,
    email,
  },
});

export const fillUserInfoQuery = ({
  firstName,
  lastName,
  currentRole,
  requestedCustomOutreachCampaign,
}: UpdateUserQueryArguments): PutQuery<FillUserInfoRequest> => ({
  url: ROUTES.USERS,
  method: QueryMethods.Put,
  data: {
    firstName,
    lastName,
    role: currentRole,
    requestedCustomOutreachCampaign,
  },
});
