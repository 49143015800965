export enum ToneOfVoiceEnum {
  Auto = 'AUTO',
  ProfessionalAndFormal = 'PROFESSIONAL_AND_FORMAL',
  FriendlyAndConversational = 'FRIENDLY_AND_CONVERSATIONAL',
  EnthusiasticAndEnergetic = 'ENTHUSIASTIC_AND_ENERGETIC',
  InspirationalAndAspirational = 'INSPIRATIONAL_AND_ASPIRATIONAL',
  DirectAndConcise = 'DIRECT_AND_CONCISE',
  EmpatheticAndUnderstanding = 'EMPATHETIC_AND_UNDERSTANDING',
  InformativeAndDetailed = 'INFORMATIVE_AND_DETAILED',
  LightheartedAndHumorous = 'LIGHTHEARTED_AND_HUMOROUS',
  ProfessionalPolished = 'PROFESSIONAL_POLISHED',
}
