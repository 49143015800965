import { useMemo } from 'react';
import { diffInHours } from '../utils/dates';
import { useGetAccountByIdQuery } from '../store/rtk-query/apis/account/hooks/get-account-by-id.query-hook';
import { useGetMyUserQuery } from '../store/rtk-query/apis/user/hooks/get-my-user.query-hook';

export const useDaysLeftPlan = (): number => {
  const {
    data: { accountId },
  } = useGetMyUserQuery();

  const { data: { plan } } = useGetAccountByIdQuery(accountId);

  return useMemo(() => {
    return Math.ceil(diffInHours(new Date(plan?.endDate), new Date()) / 24);
  }, [plan?.endDate]);
};
