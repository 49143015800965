import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../language/keys';
import { TransitionScreenWrapper } from '../transition-screen-wrapper/transition-screen-wrapper.component';
import { SlideInSentence } from '../slide-in-sentence/slide-in-sentence.component';
import DotsLoader from '../Loaders/dots-loader/dots-loader.component';
import { DotsLoaderVariation } from '../Loaders/dots-loader/dots-loader.enums';
import { THEME } from '../../../themes';
import { BodyWrapper, LoaderWrapper } from './matching-making-transition.styled';

export const MatchingMakingTransitionScreen = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <TransitionScreenWrapper>
      <BodyWrapper>
        <SlideInSentence
          content={ translate(STRING_KEYS.ONBOARDING.DONE_SCREEN_MATCHING_PROGRESS) }
          delay={ 400 }
        />
        <LoaderWrapper>
          <DotsLoader variation={ DotsLoaderVariation.Section } color={ THEME.COLORS.PURE_WHITE } />
        </LoaderWrapper>
      </BodyWrapper>
    </TransitionScreenWrapper>
  );
};
