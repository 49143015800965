import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { UnregisterCallback } from 'history';

export const useConfirmPageNavigation = (message: string, shouldConfirm?: boolean): void => {
  const history = useHistory();
  const unblockRef = useRef<UnregisterCallback>();

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    unblockRef.current = history.block((newHistory) => {
      // In case there is change only in query param, we need to pass the confirmation
      const isTheSamePath = history.location.pathname === newHistory.pathname;

      // eslint-disable-next-line no-alert
      if (!isTheSamePath && shouldConfirm && !window.confirm(message)) { // notify and wait to confirm a user when navigating away from a page
        return false;
      }
    });

    return () => {
      unblockRef.current?.();
    };
  }, [history, shouldConfirm, message]);
};
