import { useMemo } from 'react';
import ExplainabilityWidgetItem from '../explainability-widget-item.component';
import { THEME } from '../../../../../../../../themes';
import { ExplainabilityWidgetAttributesProps } from './explainability-widget-attributes';
import {
  Container,
} from './explainability-widget-attributes.styled';
import ExactAttributeRow from './exact-attribute-row/exact-attribute-row.component';
import SimilarAttributesContainer from './similar-attributes-container/similar-attributes-container.component';

const ExplainabilityWidgetAttributes = ({
  label,
  exactAttributes,
  similarAttributes,
  Icon,
  explainabilityType,
}: ExplainabilityWidgetAttributesProps): JSX.Element | null => {
  const ExactAttributes = useMemo(() => (
    exactAttributes?.map((attribute) => (
      <ExactAttributeRow
        key={ `explainability_widget_exact_attribute_${attribute.value}` }
        type={ explainabilityType }
        attribute={ attribute }
      />
    ))
  ), [exactAttributes, explainabilityType]);

  if (!exactAttributes?.length && !similarAttributes?.length) {
    return null;
  }

  return (
    <ExplainabilityWidgetItem
      label={ label }
      Icon={ Icon }
      borderColor={ !similarAttributes?.length ? THEME.COLORS.SUCCESS : THEME.COLORS.SECONDARY }
    >
      <Container>
        { ExactAttributes }
        { similarAttributes && <SimilarAttributesContainer type={ explainabilityType } similarAttributes={ similarAttributes } /> }
      </Container>
    </ExplainabilityWidgetItem>
  );
};

export default ExplainabilityWidgetAttributes;
