import styled from 'styled-components';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { THEME } from '../../../../../themes';

export const FilledButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.ACCENT};
`;

export const GhostButton = styled(BaseButton)`
  color: ${THEME.COLORS.ACCENT};
  background-color: ${THEME.COLORS.TRANSPARENT};
`;
