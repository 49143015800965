import styled from 'styled-components';
import { THEME } from '../../../themes';

export const BodyWrapper = styled.div`
  z-index: 2;
  position: relative;
`;

export const LoaderWrapper =  styled.div`
  z-index: 2;
  bottom: -${THEME.SPACING.XGIANT};
  text-align: center;
  margin: 0 auto;
  right: 0;
  left: 0;
  position: absolute;
  width: fit-content;
`;
