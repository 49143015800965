import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import STRING_KEYS from '../../../../../language/keys';
import OutreachContentPreferencesSection
  from '../../../pages/outreach-content-preferences/outreach-content-preferences-section/outreach-content-preferences-section.component';
import MessageItem from '../input-items/message-item/message-item.component';
import TextInputItem from '../input-items/text-input-item/text-input-item.component';
import { OutreachContentPreferencesFormikKeys } from '../../../pages/outreach-content-preferences/outreach-content-preferences.enums';
import TextInputWithCheckboxItem
  from '../input-items/text-input-with-checkbox-item/text-input-with-checkbox-item.component';
import SecondarySwitch from '../../outreach/secondary-switch/secondary-switch.component';
import { useGetAccountByIdQuery } from '../../../../../store/rtk-query/apis/account/hooks/get-account-by-id.query-hook';
import { useGetMyUserQuery } from '../../../../../store/rtk-query/apis/user/hooks/get-my-user.query-hook';
import { getCompanyByIdQuery } from '../../../../../store/rtk-query/apis/company/hooks/get-company-by-id.query-hook';
import {
  AiIcon,
  Section,
  SectionsContainer,
  TitleAdornmentContainer,
} from './agency-preferences.styled';
import { AgencyPreferencesProps } from './agency-preferences';

const AgencyPreferences = ({
  agencyName,
  aboutAgency,
  agencyModeEnabled,
  hiringCompanyName,
  hiringCompanyNameConfidential,
  aboutHiringCompany,
  onChangeHandler,
}: AgencyPreferencesProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { data: { accountId } } = useGetMyUserQuery();
  const { data: { companyId } } = useGetAccountByIdQuery(accountId);
  const { data: company } = getCompanyByIdQuery(companyId);

  const showAboutCompanyAutoFillButton = !!company?.id;

  const onAgencyNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.AgencyName, event.currentTarget.value);
  };

  const onAboutAgencyChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.AboutAgency, event.target.value);
  };

  const onHiringCompanyNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.PreferredCompanyName, event.currentTarget.value);
  };

  const onHiringCompanyNameConfidentialChange = (confidential: boolean) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.HiringCompanyNameConfidential, confidential);
  };

  const onAboutHiringCompanyChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.AboutTheCompany, event.target.value);
  };

  const onAgencyModeEnabledChange = (enabled: boolean) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.AgencyModeEnabled, enabled);
  };

  const onAboutCompanyAutoFillClick = (formikKey: OutreachContentPreferencesFormikKeys) => {
    if (company?.fullDescription) {
      onChangeHandler(formikKey, company.fullDescription);
    }
  };

  const renderAgencyFields = () => {
    return (
      <Section>
        <TextInputItem
          value={ agencyName }
          changeHandler={ onAgencyNameChange }
          title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.AGENCY_NAME.TITLE) }
          description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.AGENCY_NAME.DESCRIPTION) }
          placeholder={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.AGENCY_NAME.PLACEHOLDER) }
        />
        <MessageItem
          message={ aboutAgency }
          messageChangeHandler={ onAboutAgencyChange }
          title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.ABOUT_AGENCY.TITLE) }
          titleAdornment={ <AiIcon /> }
          description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.ABOUT_AGENCY.DESCRIPTION) }
          autoFillClickHandler={ () => onAboutCompanyAutoFillClick(OutreachContentPreferencesFormikKeys.AboutAgency) }
        />
      </Section>
    );
  };

  return (
    <OutreachContentPreferencesSection
      title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.TITLE) }
      TitleAdornment={ (
        <TitleAdornmentContainer>
          { translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.AGENCY_MODE_TOGGLE.TITLE) }
          <SecondarySwitch
            checked={ agencyModeEnabled }
            changeHandler={ onAgencyModeEnabledChange }
          />
        </TitleAdornmentContainer>
      ) }
    >
      <SectionsContainer>
        { agencyModeEnabled && renderAgencyFields() }
        <Section>
          <TextInputWithCheckboxItem
            inputValue={ hiringCompanyName }
            inputChangeHandler={ onHiringCompanyNameChange }
            inputTitle={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.HIRING_COMPANY_NAME.TITLE) }
            inputDescription={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.HIRING_COMPANY_NAME.DESCRIPTION) }
            inputPlaceholder={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.HIRING_COMPANY_NAME.PLACEHOLDER) }
            checkboxTitle={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.HIRING_COMPANY_NAME_CONFIDENTIAL.TITLE) }
            checkboxValue={ hiringCompanyNameConfidential }
            checkboxDescription={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.HIRING_COMPANY_NAME_CONFIDENTIAL.DESCRIPTION) }
            checkboxChangeHandler={ onHiringCompanyNameConfidentialChange }
          />
          <MessageItem
            message={ aboutHiringCompany }
            messageChangeHandler={ onAboutHiringCompanyChange }
            title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.ABOUT_HIRING_COMPANY.TITLE) }
            titleAdornment={ <AiIcon /> }
            description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.ABOUT_HIRING_COMPANY.DESCRIPTION) }
            autoFillClickHandler={ () => onAboutCompanyAutoFillClick(OutreachContentPreferencesFormikKeys.AboutTheCompany) }
            showAutoFillButton={ showAboutCompanyAutoFillButton && !agencyModeEnabled }
          />
        </Section>
      </SectionsContainer>
    </OutreachContentPreferencesSection>
  );
};

export default AgencyPreferences;
