import styled, { css } from 'styled-components';
import { THEME } from '../../../../themes';

export const Body = styled.div`
  min-height: 192px;
`;

const GrayedOutContainerStyle = css`
  opacity: ${THEME.OPACITY.LOW};
  pointer-events: none;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const GrayedOutContainer = styled.div<{ isGrayedOut: boolean }>`
  ${({ isGrayedOut }) => isGrayedOut ? GrayedOutContainerStyle : undefined};
`;
