import styled  from 'styled-components';
import { THEME } from '../../../../../themes';
import { ASSETS } from '../../../../../assets';
import { SIDE_PANEL_WIDTH_EXTRA_SMALL, SIDE_PANEL_WIDTH_MEDIUM, SIDE_PANEL_WIDTH_SMALL } from '../match-side-panel/match-side-panel.styled';
import { calculateOutreachPageHeight } from '../outreach-layout/outreach.utils';

export const CONVERSATION_SIDE_PANEL_HEADER_HEIGHT = 56;
export const PREFERENCES_SECTION_HEIGHT = 104;

export const SidePanelSectionLayout = styled.div<{ isAutoMode: boolean, isStepSelected: boolean, isLoading?: boolean, isBannerVisible: boolean }>`
  height: ${({ isBannerVisible }) => calculateOutreachPageHeight({ isBannerVisible })};
  display: grid;
  max-width: ${({ isAutoMode }) => {
    if (!isAutoMode) {
      return `${SIDE_PANEL_WIDTH_MEDIUM}px`;
    }
    
    return `${SIDE_PANEL_WIDTH_EXTRA_SMALL}px`;
  }};
  grid-template-columns: ${({ isAutoMode, isStepSelected, isLoading }) => {
    if (!isAutoMode || isLoading) {
      return '1fr';
    }
    
    return isStepSelected ? `${SIDE_PANEL_WIDTH_EXTRA_SMALL}px ${SIDE_PANEL_WIDTH_SMALL}px` : '1fr';
  }};
  grid-gap: ${THEME.SPACING.TINY};
  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MEDIUM}px) {
    max-width: ${({ isAutoMode }) => {
    if (!isAutoMode) {
      return `${SIDE_PANEL_WIDTH_SMALL}px`;
    }
    
    return undefined;
  }};
  };
`;

export const ItemsContainer = styled.div<{ isStepSelected: boolean, isAutoMode: boolean, isLoading?: boolean, isBannerVisible: boolean }>`
  display: ${({ isStepSelected, isAutoMode, isLoading }) => isStepSelected && isAutoMode && !isLoading ? 'block' : 'none'};
  height: ${({ isBannerVisible }) => calculateOutreachPageHeight({ isBannerVisible })};
`;

export const SidePanelSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: ${THEME.SPACING.TINY};
`;

export const PreferencesSection = styled.div`
  background-color: ${THEME.COLORS.PURE_WHITE};
  border-radius: ${THEME.RADIUS.CURVY};
  overflow: hidden;
`;

export const ConversationsSection = styled.div`
  background-color: ${THEME.COLORS.PURE_WHITE};
  border-radius: ${THEME.RADIUS.CURVY};
  height: calc(100% - ${PREFERENCES_SECTION_HEIGHT}px);
  overflow: hidden;
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: ${THEME.SPACING.BASE};
  padding-left: ${THEME.SPACING.MEDIUM};
  padding-right: ${THEME.SPACING.TINO};
`;

export const RepliedIcon = styled(ASSETS.REPLAY_ARROW)`
  width: 16px;
  height: 14px;
`;

export const ToOutreachIcon = styled(ASSETS.HEART)`
  width: 18px;
  height: 16px;
`;

export const SentIcon = styled(ASSETS.REPLAY_ARROW)`
  width: 16px;
  height: 14px;
`;

export const ToFollowupIcon = styled(ASSETS.ALARM)`
  width: 17px;
  height: 16px;
`;

export const ArchivedIcon = styled(ASSETS.DREAM)`
  width: 13px;
  height: 18px;
`;

export const EmailIcon = styled(ASSETS.EMAIL)`
  height: 13px;
`;

export const CompletedIcon = styled(ASSETS.CHECKLIST_FILL)`
  height: 12px;
`;
