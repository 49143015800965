/* eslint-disable react/no-array-index-key */
import {
  Container, Label, ProgressBarContainer, ProgressBarStyled, RulerContainer, Badge, RulerItemContainer,
} from './progress-bar.styled';

const RULER_ITEMS: (number | null)[] = [0, null, null, null, 25, null, null, null, 50];
const MIN_PROGRESS_VALUE = 7;

const ProgressBar = ({
  progress,
}: ProgressBarProps): JSX.Element => {
  return (
    <Container>
      <ProgressBarContainer>
        <ProgressBarStyled progress={ Math.max(progress, MIN_PROGRESS_VALUE) } />
      </ProgressBarContainer>
      <RulerContainer>
        {
          RULER_ITEMS.map((value, i) => (
            <RulerItemContainer key={ `ruler-item-${i}` }>
              <Badge />
              {
                Number.isInteger(value) && (
                  <Label key={ `ruler-item-${value}` }>{ value }</Label>
                )
              }
            </RulerItemContainer>
          ))
        }
      </RulerContainer>
    </Container>
  );
};

export default ProgressBar;
