import axiosInstance from '../../../api';

export const sendVerificationEmail = async (): Promise<void> => {
  // TODO: change to rtk
  await axiosInstance.post('/v1/onboarding/send-verification-email');
};

// to do changed unknown return type
export const verifyEmail = async (code: string): Promise<unknown> => {
  // TODO: change to rtk
  const url = `/v1/onboarding/verify-email/${code}/`;

  const response = await axiosInstance.post(url);

  return response.data;
};

export const validateUserEmailDomain = async (email: string): Promise<unknown> => {
  // TODO: change to rtk
  const url = `/v1/users/validate-email-domain/${email}`;

  const response = await axiosInstance.get(url);

  return response.data;
};
