import styled from 'styled-components';
import { FLUENT_UI_EMOJIS } from '../../../../../../assets';

export const Container = styled.div`
  width: 100%;
`;

export const CardBody = styled.div`
  display: flex;
  align-items: center;
`;

export const ChartWrapper = styled.div`
  width: 133px;
  margin: -28px;
`;

export const RolesWrapper = styled.div`
  flex-grow: 1;
`;

export const Icon = styled(FLUENT_UI_EMOJIS.BAR_CHART)``;
