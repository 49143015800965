import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../../../../../language/keys';
import ExplainabilityWidgetAttributes from '../explainability-widget-attributes.component';
import { ExplainabilityTypeEnum } from '../../../../../../../../../enums/explainability-type.enum';
import { ExplainabilityWidgetSkillsProps } from './explainability-widget-skills';
import { Icon } from './explainability-widget-skills.styled';

const ExplainabilityWidgetSkills = ({ explainability }: ExplainabilityWidgetSkillsProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <ExplainabilityWidgetAttributes
      label={ translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY_WIDGET.ITEMS_LABELS.SKILLS) }
      exactAttributes={ explainability?.relevantSkills }
      similarAttributes={ explainability?.similarSkills }
      Icon={ Icon }
      explainabilityType={ ExplainabilityTypeEnum.Skill }
    />
  );
};

export default ExplainabilityWidgetSkills;
