import { useEffect, useState } from 'react';
import { EditorState, Modifier, SelectionState } from 'draft-js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DraftOffsetKey from 'draft-js/lib/DraftOffsetKey';
import { useTranslation } from 'react-i18next';
import { getCaretPosition, getCurrentBlock } from '../../perfect-text-editor.utils';
import { DECORATORS_NAMES } from '../decorators.consts';
import STRING_KEYS from '../../../../../language/keys';
import { MentionHookProps, MentionHookResponse, MentionState } from './mention-decorator';

export const useMention = ({
  editorState, setEditorState, editorContainerId,
}: MentionHookProps): MentionHookResponse => {
  const { t: translate } = useTranslation();
  const [mention, setMention] = useState<MentionState>({
    selectedIndex: 0,
    offset: getCaretPosition(editorState),
    isOpen: false,
  });

  const [mentionReference, setMentionReference] = useState<HTMLElement | null>(
    null,
  );

  const createMentionPlaceholder = () => {
    const data = translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS_PLACEHOLDER.TITLE);
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      DECORATORS_NAMES.MENTION_PLACEHOLDER,
      'IMMUTABLE',
      data,
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const block = getCurrentBlock(editorState);
    const blockKey = block?.getKey();
    const contentStateWithReplacedText = Modifier.replaceText(
      contentStateWithEntity,
      new SelectionState({
        anchorKey: blockKey,
        anchorOffset: getCaretPosition(editorState),
        focusKey: blockKey,
        focusOffset: getCaretPosition(editorState),
        isBackward: false,
        hasFocus: true,
      }),
      data,
      undefined,
      entityKey,
    );

    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithReplacedText,
      selection: new SelectionState({
        anchorKey: blockKey,
        anchorOffset: getCaretPosition(editorState) + data.length,
        focusKey: blockKey,
        focusOffset: getCaretPosition(editorState) + data.length,
        isBackward: false,
        hasFocus: true,
      }),
    });

    setEditorState(newEditorState);
  };

  const confirmMention = (mentionValue: string) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      DECORATORS_NAMES.MENTION,
      'IMMUTABLE',
      mentionValue,
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const block = getCurrentBlock(editorState);
    const blockKey = block?.getKey();
    const contentStateWithReplacedText = Modifier.replaceText(
      contentStateWithEntity,
      new SelectionState({
        anchorKey: blockKey,
        anchorOffset: mention.offset,
        focusKey: blockKey,
        focusOffset: getCaretPosition(editorState),
        isBackward: false,
        hasFocus: true,
      }),
      mentionValue,
      undefined,
      entityKey,
    );
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithReplacedText,
      selection: new SelectionState({
        anchorKey: blockKey,
        anchorOffset: mention.offset + mentionValue.length,
        focusKey: blockKey,
        focusOffset: mention.offset + mentionValue.length,
        isBackward: false,
        hasFocus: true,
      }),
    });

    setMention((prevMentionState) => ({
      ...prevMentionState,
      selectedIndex: 0,
      offset: getCaretPosition(newEditorState),
      isOpen: false,
    }));
    setEditorState(newEditorState);
  };

  const closeMention = (state: EditorState) => {
    if (mention.isOpen) {
      setMention((prevState) => ({
        ...prevState,
        offset: getCaretPosition(state),
        isOpen: false,
      }));
    }
  };

  useEffect(() => {
    if (editorState) {
      const block = getCurrentBlock(editorState);
      if (block) {
        const offsetKey = DraftOffsetKey.encode(block.getKey(), 0, 0);
        setTimeout(() => {
          const node = document.querySelectorAll<HTMLDivElement>(
            `#${editorContainerId} [data-offset-key="${offsetKey}"]`,
          );
          setMentionReference((node[node.length - 1]?.children?.[0] as HTMLElement));
        }, 0);
      }
    }
  }, [editorState, editorContainerId]);

  const openMention = (newEditorState: EditorState) => {
    setMention((prevState) => ({
      ...prevState,
      isOpen: true,
      offset: getCaretPosition(newEditorState),
    }));
    createMentionPlaceholder();
  };

  return {
    openMention,
    closeMention,
    mention,
    setMention,
    confirmMention,
    mentionReference,
  };
};
