import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useUpdatePositionMutation } from '../../../../../store/rtk-query/apis/position/hooks/update-position.mutation-hook';
import { useEditPositionPreferencesFormik } from '../../../../../components/dialogs/edit-position-dialog/edit-position-dialog.formik';
import {
  ActionContainer,
  AdjustmentItemsContainer,
  AdjustmentItemsContainerHint,
  AdjustmentItemsContainerTopBar,
  AdjustmentItemsList,
  ConfirmButton, EditPositionButton,
  SmallAdjustmentWrapper,
} from '../position-adjustments.styled';
import STRING_KEYS from '../../../../../language/keys';
import LocationAdjustment from '../adjustment-item/location-adjustment/location-adjustment.component';
import { EditPositionPreferencesFormikKeys } from '../../../../../components/dialogs/edit-position-dialog/edit-position-dialog.enums';
import PositionTitleAdjustment from '../adjustment-item/position-title-adjustment/position-title-adjustment.component';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
  PerfectButtonVariants,
} from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { PositionRunTriggerTypeEnum } from '../../../../../enums/position-run-trigger-type.enum';
import { positionQuery } from '../../../../../store/rtk-query/apis/position/position.toolkit-api';
import { AlertSnackBar } from '../../../../../components/Common/CustomSnackBar/AlertSnackBar';
import { ASSETS } from '../../../../../assets';
import { AdjustmentsContainerProps } from './adjustments-container';

const AdjustmentsContainer = ({ position, editPositionPreferencesButtonClickHandler }: AdjustmentsContainerProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [updatePosition, { isLoading: isEditPositionLoading, isSuccess: isEditPositionSuccess, isError: isEditPositionError }] = useUpdatePositionMutation();
  const [getPosition] = positionQuery.useLazyGetPositionByIdQuery();
  const [getPositions] = positionQuery.useLazyGetOpenPositionsQuery();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);

  const {
    jobTitle,
    locations,
    remotePosition,
    setValueByKey,
    save,
    dirty,
    isValid,
  } = useEditPositionPreferencesFormik({
    submitHandler: updatePosition, position, enableReinitialize: true, triggerType: PositionRunTriggerTypeEnum.DeadEndRecommendation,
  });

  const onConfirmButtonClick = async () => {
    await save();
  };

  const onEditPositionSuccess = async () => {
    await Promise.all([getPosition(position.id), getPositions()]);
  };

  useEffect(() => {
    if (isEditPositionSuccess) {
      onEditPositionSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditPositionSuccess]);

  useEffect(() => {
    if (isEditPositionError) {
      setIsSnackbarOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditPositionError]);

  // TODO: fix min-width after fixing sizes

  return (
    <div>
      <AdjustmentItemsContainer>
        <AdjustmentItemsContainerTopBar>
          <AdjustmentItemsContainerHint>{ translate(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.ITEMS_CONTAINER_HINT) }</AdjustmentItemsContainerHint>
        </AdjustmentItemsContainerTopBar>
        <AdjustmentItemsList>
          <SmallAdjustmentWrapper>
            <PositionTitleAdjustment
              jobTitle={ jobTitle }
              changeHandler={ (changedData) => setValueByKey(EditPositionPreferencesFormikKeys.JobTitle, changedData) }
            />
          </SmallAdjustmentWrapper>
          <SmallAdjustmentWrapper>
            <LocationAdjustment
              locations={ locations }
              isRemote={ remotePosition }
              changeHandler={ (changedData) => setValueByKey(EditPositionPreferencesFormikKeys.Locations, changedData) }
              remotePositionChangeHandler={ (changedData) => setValueByKey(EditPositionPreferencesFormikKeys.RemotePosition, changedData) }
            />
          </SmallAdjustmentWrapper>
        </AdjustmentItemsList>
      </AdjustmentItemsContainer>
      <ActionContainer>
        <ConfirmButton
          onClick={ onConfirmButtonClick }
          size={ PerfectButtonSize.Medium }
          variant={ PerfectButtonVariants.Outline }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          isLoading={ isEditPositionLoading }
          disabled={ !dirty || !isValid }
        >
          { translate(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.CONFIRM_BUTTON_TEXT) }
        </ConfirmButton>
        <EditPositionButton
          StartIcon={ ASSETS.SETTINGS }
          onClick={ editPositionPreferencesButtonClickHandler }
          size={ PerfectButtonSize.Medium }
          variant={ PerfectButtonVariants.Ghost }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
        >
          { translate(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.REDIRECT_TO_EDIT_POSITION) }
        </EditPositionButton>
      </ActionContainer>
      <AlertSnackBar
        title={ translate(STRING_KEYS.ALERT) }
        anchor="top-center"
        description={ translate(STRING_KEYS.GLOBAL_ERROR_MESSAGE) }
        isOpen={ isSnackbarOpen }
        shouldAutoDismiss
        onClose={ () => setIsSnackbarOpen(false) }
      />
    </div>
  );
};

export default AdjustmentsContainer;
