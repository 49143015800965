import styled, { css } from 'styled-components';
import { ASSETS } from '../../../../assets';
import { Body2Font, CaptionFont, OverlineFont } from '../../../../themes/fonts';
import { THEME } from '../../../../themes';
import Tag from '../../../../components/Common/tag/tag.component';
import { Scrollbar } from '../../../../themes/scrollbar';

export const VerifiedIcon = styled(ASSETS.CHECK_STAR)`
  width: 12px;
`;

export const MenuContainer = styled.div`
  ${Scrollbar};
  width: 400px;
  max-height: 320px;
  overflow-y: auto;
  padding: ${THEME.SPACING.BASE};
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MICRO};
`;

export const SectionText = styled.div`
  ${CaptionFont};
  opacity: ${THEME.OPACITY.MED};
`;

export const TitleStyle = css`
  ${OverlineFont};
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
  font-weight: 600;
`;

export const SectionTitle = styled.div`
  ${TitleStyle};
  opacity: ${THEME.OPACITY.LOW};
`;

export const SourceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MICRO};
`;

export const ExactTag = styled(Tag)`
  background-color: ${THEME.COLORS.SUCCESS};
  color: ${THEME.COLORS.PURE_WHITE};
  padding: ${THEME.SPACING.MICRO} ${THEME.SPACING.TINY};
  font-weight: 600;
`;

export const TagStyle = css`
  padding: ${THEME.SPACING.MICRO} ${THEME.SPACING.TINY};
  font-weight: 600;
  max-width: 40%;
`;

export const SimilarTag = styled(Tag)`
  ${TagStyle};
  background-color: ${THEME.COLORS.SECONDARY};
  color: ${THEME.COLORS.PURE_WHITE};
`;

export const RegularTag = styled(Tag)`
  ${TagStyle};
  background-color: ${THEME.COLORS.GREY};
  color: ${THEME.COLORS.TRUE_BLACK};
`;

export const TagRow = styled.div`
  ${Body2Font};
  font-weight: 600;
  display: flex;
  gap: ${THEME.SPACING.TINY};
  align-items: center;
`;
