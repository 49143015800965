import styled, { css } from 'styled-components';
import { THEME } from '../../../../../themes';
import {
  Body1Font, Body2Font, HeadlineFont, OverlineFont,
} from '../../../../../themes/fonts';
import { AvatarWithFallback } from '../../../../../components/Common/avatar-with-fallback/avatar-with-fallback.component';
import { hexToRgbA } from '../../../../../utils/colors';

const defineItemsContainerHeight = (minimized: boolean, minimizedHeight: number, fullHeight?: number) => {
  if (minimized) {
    return minimizedHeight !== -1 ? `${minimizedHeight}px` : 'unset';
  }

  return (fullHeight === -1 || !fullHeight) ? 'unset' : `${fullHeight}px`;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINO};
`;

// Container related components
// TODO: delete and use the new container
export const ItemsContainer = styled.div<{ minimized: boolean, minimizedHeight: number, fullHeight?: number }>`
  overflow: hidden;
  transition: height 300ms;

  height: ${({ minimized, minimizedHeight, fullHeight }) => defineItemsContainerHeight(minimized, minimizedHeight, fullHeight)};
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
  margin-top: ${THEME.SPACING.BASE};
`;

// Container items related components
export const TimelineContainerItemLogo = styled(AvatarWithFallback).attrs({
  size: 48,
})`
  margin-right: ${THEME.SPACING.TINO};
`;

export const TimelineContainerItemLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: inherit;
  gap: ${THEME.SPACING.TINY};
  min-width: 56px;
`;

export const ExperienceLogoDivider = styled.div`
  background-color: ${hexToRgbA(THEME.COLORS.PRIMARY, THEME.OPACITY.DIVIDER)};
  height: calc(100% - 3px);
  width: 3px;
  border-radius: 3px;
`;

export const RoleDecorator = styled.div<{ isPromoted?: boolean }>`
  width: 24px;
  height: 24px;
  min-height: 24px;
  border-radius: ${THEME.RADIUS.CIRCLE};
  border: solid 6px ${THEME.COLORS.PURE_WHITE};
  outline: ${({ isPromoted }) => `solid 3px ${isPromoted ? THEME.COLORS.WARNING : hexToRgbA(THEME.COLORS.PRIMARY, THEME.OPACITY.DIVIDER)}`};
  margin-top: ${THEME.SPACING.NANO};
  background-color: ${({ isPromoted }) => isPromoted ? THEME.COLORS.WARNING : hexToRgbA(THEME.COLORS.PRIMARY, THEME.OPACITY.DIVIDER)};
`;

export const TimelineLabel = styled.div`
  ${Body2Font};
  letter-spacing: 0.13px;
  line-height: normal;
  text-transform: capitalize;
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;

export const TimelineBoldText = styled.span<{ isSmall?: boolean }>`
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_A};
  line-height: 1.1;
`;

const RegularText = css`
  ${Body1Font};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const TimelineRegularText = styled.span`
  ${RegularText};
`;

export const TimelineDurationText = styled.span`
  ${OverlineFont};
  opacity: ${THEME.OPACITY.MED};
`;

export const TimeLineCompanyContainer = styled.div<{ showBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: ${THEME.SPACING.BASE};
  border-bottom: ${({ showBorder }) => showBorder && `1px solid ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.GHOST)}`};
`;

export const TimelineExperienceRangeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TimelineExperienceRangeText = styled.span`
  ${OverlineFont};
  line-height: 1;
  letter-spacing: 1.5px;
  opacity: ${THEME.OPACITY.MED};
`;

export const ExperienceTitle = styled.div`
  ${HeadlineFont};
  font-weight: normal;
  margin-top: ${THEME.SPACING.TINY};
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO} ${THEME.SPACING.TINY};
  flex-wrap: wrap;
  letter-spacing: 0.15px;
`;

export const InstitutionText = styled.span`
  display: flex;
  gap: ${THEME.SPACING.MICRO};
  align-items: center;
`;

export const AtText = styled.span`
  opacity: ${THEME.OPACITY.MED};
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const MultipleExperienceTitle = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${THEME.SPACING.TINO};
`;
