import React, {
  useEffect,
  useRef,
  useState,
  FC,
  ComponentType,
} from 'react';

export function withShowHide<T>(
  Component: ComponentType<T>,
):FC<T> {
  const ShowHide = (props: T) => {
    const containerRef = useRef<HTMLHeadingElement>(null);
    const [filterMenuOpen, setShowFilterMenu] = useState<boolean>(false);

    const handleClick = (event:React.MouseEvent | MouseEvent) => {
      event.stopPropagation();
      if (containerRef?.current?.contains(event.target as Node)) {
        setShowFilterMenu((prevIsShowFilterMenu) => !prevIsShowFilterMenu);

        return;
      }
      setShowFilterMenu(false);
    };

    useEffect(() => {
      document.addEventListener('click', handleClick);

      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, []);

    return (
      <div
        ref={ containerRef }
        onClick={ (event) => { handleClick(event); } }
      >
        <Component
          // eslint-disable-next-line react/jsx-props-no-spreading
          { ...(props as T) }
          filterMenuOpen={ filterMenuOpen }
          setFilterMenuOpen={ () => {
            setShowFilterMenu(true);
          } }
          setFilterMenuClose={ () => {
            setShowFilterMenu(false);
          } }
        />
      </div>
    );
  };

  return ShowHide;
}
