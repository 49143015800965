import styled from 'styled-components';
import Chip from '../../../../../../components/Common/perfect-chip/perfect-chip.component';
import { ASSETS } from '../../../../../../assets';

export const OutreachIcon = styled(ASSETS.BALL_BOUNCE)<{ color: string }>`
  width: 8px;
  height: 8px;
  fill: ${({ color }) => color};
`;

export const StepChip = styled(Chip)<{ color: string, backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
`;
