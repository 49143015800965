export const LEVEL_OF_EXPERIENCE_YEARS = {
  ENTRY_LEVEL: {
    from: '3',
    to: '12',
  },
  STARTING_OUT: {
    from: '1',
    to: '3',
  },
  SOMEWHAT_EXPERIENCED: {
    from: '3',
    to: '5',
  },
  HIGHLY_EXPERIENCED: {
    from: '5',
    to: '10',
  },
  INCREDIBLY_EXPERIENCED: '10+',
};
