import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { ASSETS } from '../../../../../../assets';

export const LikeIcon = styled(ASSETS.FILLED_LIKE)`
  fill: ${THEME.COLORS.ACCENT};
  width: 17.3px;
  height: 16px;
`;

export const ExternalLinkIcon = styled(ASSETS.ETERNAL_LINK)`
  color: ${THEME.COLORS.PRIMARY_DARK};
  opacity: ${THEME.OPACITY.MED};
  width: 12px;
  height: 12px;
`;
