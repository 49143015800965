/* eslint-disable react/jsx-props-no-spreading */
import Draft, {
  ContentBlock,
  ContentState,
  DraftHandleValue,
  Editor,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js';
import {
  MouseEvent, useCallback, useEffect, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import htmlToDraft from 'html-to-draftjs';
import STRING_KEYS from '../../../language/keys';
import { KEYBOARD_KEYS } from '../../../consts/keyboard_keys';
import {
  BodyEditorContainer,
  Divider,
  EditorActionsContainer,
  EditorWrapper,
  FooterContainer,
  InfoComponentWrapper,
  SkeletonContainer,
  StandardAction,
  SubjectEditorContainer,
} from './perfect-text-editor.styled';
import InlineStyleControlsComponent from './text-editor-controls/inline-style-controls/inline-style-controls.component';
import TextAlignmentControlsComponent
  from './text-editor-controls/text-alignment-controls/text-alignment-controls.component';
import { PerfectTextEditorProps, PerfectTextEditorSkeletonProps } from './perfect-text-editor';
import { PerfectTextEditorBlockAlignment } from './perfect-text-editor.enums';
import {
  DRAFT_ALIGNMENT_CLASS_NAME,
  DRAFT_ALIGNMENT_STYLE_LIST,
  DRAFT_CHANGE_TYPE,
  DRAFT_HANDLE_VALUE,
  DRAFT_NOT_HANDLE_VALUE,
  EDITOR_COMMAND,
} from './perfect-text-editor.const';
import { useMention } from './decorators/mention-decorator/mention-decorator.hook';
import {
  DECORATORS_NAMES, DOT, OPEN_MENTION_CHAR, SINGLE_SPACE,
} from './decorators/decorators.consts';
import TokensControl from './text-editor-controls/custom-editor-controls/mentions-control/mentions-control.component';
import { MentionsList } from './decorators/mention-decorator/mention-list/mention-list.component';
import { MentionState } from './decorators/mention-decorator/mention-decorator';
import TextEditorSkeleton from './text-editor-skeleton/text-editor-skeleton.component';
import useTextEditor from './context/text-editor.hook';
import { decorators } from './context/text-editor.context';
import { parseSavedMentions } from './perfect-text-editor.utils';

const PerfectTextEditor = ({
  showSubject,
  errorChipButtonClickHandler,
  subject,
  message,
  resetKey,
  mentions,
  InfoComponent,
  ActionButton,
  editorKeyboardEventHandler,
  skeleton = {} as PerfectTextEditorSkeletonProps,
  onSubjectBlurHandler,
  onBodyBlurHandler,
  subjectContainerId,
  bodyContainerId,
  ActionControlsAdornment,
  ...otherProps
}: PerfectTextEditorProps): JSX.Element => {
  const {
    visible: showSkeleton, isErrorActionLoading, hasError, showAnimation: showSkeletonAnimation, loadingText: skeletonLoadingText,
  } = skeleton;
  const { t: translate } = useTranslation();
  const {
    editorBodyState,
    editorSubjectState,
    touched,
    showSubjectEditor,
    setTouched,
    setEditorBodyState,
    setEditorSubjectState,
    setEditorInFocus,
    setSubjectEditorInFocus,
    subjectEditorInFocus,
    setShowSubjectEditor,
    editorInFocus,
    showTextEditorHeader,
    subjectReadOnly,
  } = useTextEditor();

  const {
    mention: bodyMentionState,
    confirmMention: confirmBodyMention,
    mentionReference: bodyMentionReference,
    closeMention: closeBodyMention,
    openMention: openBodyMention,
  } = useMention({
    editorState: editorBodyState,
    setEditorState: setEditorBodyState,
    editorContainerId: bodyContainerId,
  });

  const {
    mention: subjectMentionState,
    confirmMention: confirmSubjectMention,
    mentionReference: subjectMentionReference,
    closeMention: closeSubjectMention,
    openMention: openSubjectMention,
  } = useMention({
    editorState: editorSubjectState,
    setEditorState: setEditorSubjectState,
    editorContainerId: subjectContainerId,
  });

  useEffect(() => {
    if (resetKey) {
      setEditorBodyState(EditorState.createEmpty(decorators));
      setEditorSubjectState(EditorState.createEmpty(decorators));
      setEditorInFocus(false);
    }
  }, [resetKey, setEditorBodyState, setEditorInFocus, setEditorSubjectState]);

  useEffect(() => {
    if (message) {
      const blocksFromHtml = htmlToDraft(message);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState, decorators);
      setEditorBodyState(parseSavedMentions(editorState));
      setEditorInFocus(true);
    }
  }, [message, setEditorBodyState, setEditorInFocus]);

  useEffect(() => {
    if (subject) {
      const editorState = EditorState.createWithContent(ContentState.createFromText(subject), decorators);
      setEditorSubjectState(parseSavedMentions(editorState));
    }
  }, [setEditorSubjectState, subject]);

  const refBodyEditor = useRef<Editor>(null);
  const refSubjectEditor = useRef<Editor>(null);

  useEffect(() => {
    if (document.activeElement === refSubjectEditor.current?.editor) {
      setSubjectEditorInFocus(true);

      return;
    }

    if (document.activeElement === refBodyEditor.current?.editor && subjectEditorInFocus) {
      setSubjectEditorInFocus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.activeElement]);

  useEffect(() => {
    if (showSubject) {
      setShowSubjectEditor(editorInFocus);
    }

    if (!editorInFocus) {
      setShowSubjectEditor(false);
    }
  }, [showSubject, editorInFocus, setShowSubjectEditor]);

  const applyInlineStyle = (inlineStyle: string) => {
    const newState = RichUtils.toggleInlineStyle(editorBodyState, inlineStyle);
    setEditorBodyState(newState);
  };

  const applyAlignmentStyle = (newStyle: string) => {
    const oldStyle = DRAFT_ALIGNMENT_STYLE_LIST.filter((style) => style !== newStyle);
    const currentContent = editorBodyState.getCurrentContent();
    const selection = editorBodyState.getSelection();
    const focusBlock = currentContent.getBlockForKey(selection.getFocusKey());
    const anchorBlock = currentContent.getBlockForKey(selection.getAnchorKey());
    const isBackward = selection.getIsBackward();

    const selectionMerge = {
      anchorOffset: 0,
      focusOffset: focusBlock.getLength(),
    };

    if (isBackward) {
      selectionMerge.anchorOffset = anchorBlock.getLength();
    }
    const finalSelection = selection.merge(selectionMerge);
    const finalContent = oldStyle.reduce((content, style) => Modifier.removeInlineStyle(content, finalSelection, style), currentContent);
    const modifiedContent = Modifier.applyInlineStyle(finalContent, finalSelection, newStyle);
    const nextEditorState = EditorState.push(editorBodyState, modifiedContent, DRAFT_CHANGE_TYPE);
    setEditorBodyState(nextEditorState);
  };

  const handleKeyCommand = (
    command: string,
    state: EditorState,
    mentionState: MentionState,
    closeMention: (state: EditorState) => void,
    setEditorState: (state: EditorState) => void,
  ) => {
    if (command === EDITOR_COMMAND.SPLIT_BLOCK && mentionState.isOpen) {
      return DRAFT_HANDLE_VALUE;
    }
    if (command === EDITOR_COMMAND.BACKSPACE) {
      closeMention(state);
    }
    const newState = RichUtils.handleKeyCommand(state, command);

    if (newState) {
      setEditorState(newState);

      return DRAFT_HANDLE_VALUE;
    }

    return DRAFT_NOT_HANDLE_VALUE;
  };

  const handleKeyCommandForBody = (command: string, state: EditorState) => {
    return handleKeyCommand(command, state, bodyMentionState, closeBodyMention, setEditorBodyState);
  };

  const handleKeyCommandForSubject = (command: string, state: EditorState) => {
    return handleKeyCommand(command, state, subjectMentionState, closeSubjectMention, setEditorSubjectState);
  };

  const blockStyleFn = (block: ContentBlock) => {
    let alignment = PerfectTextEditorBlockAlignment.Left;
    block.findStyleRanges((e) => {
      if (e.hasStyle(PerfectTextEditorBlockAlignment.Center)) {
        alignment = PerfectTextEditorBlockAlignment.Center;
      }
      if (e.hasStyle(PerfectTextEditorBlockAlignment.Right)) {
        alignment = PerfectTextEditorBlockAlignment.Right;
      }

      return true;
    }, () => '');

    return `${DRAFT_ALIGNMENT_CLASS_NAME}-${alignment.toLowerCase()}`;
  };

  const onBodyStateChange = (editorState: EditorState) => {
    if (!touched && editorState.getCurrentContent().getPlainText() !== editorBodyState.getCurrentContent().getPlainText()) {
      setTouched(true);
    }
    setEditorBodyState(editorState);
  };

  const onSubjectStateChange = (editorState: EditorState) => {
    setEditorSubjectState(editorState);
  };

  const editorFocus = () => {
    refBodyEditor?.current?.focus();
  };

  const onClickEditorContainerHandler = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    editorFocus();
    setEditorInFocus(true);
  };

  const handleBeforeInput = (
    chars: string,
    editorState: EditorState,
    openMention: (editorState: EditorState) => void,
    setEditorState: (editorState: EditorState) => void,
  ) => {
    // Fixed Draft js bug, issue still open in github
    // https://github.com/facebookarchive/draft-js/issues/2422
    if (chars === DOT) {
      const currentSelection = editorState.getSelection();
      setEditorState(EditorState.set(
        editorState,
        {
          currentContent: Modifier.replaceText(
            editorState.getCurrentContent(),
            currentSelection,
            SINGLE_SPACE,
          ),
        },
      ));

      return DRAFT_HANDLE_VALUE as DraftHandleValue;
    }

    if (chars === OPEN_MENTION_CHAR && mentions) {
      openMention(editorState);

      return DRAFT_HANDLE_VALUE as DraftHandleValue;
    }

    return DRAFT_NOT_HANDLE_VALUE as DraftHandleValue;
  };

  const handleTokenControl = useCallback(() => {
    if (!editorInFocus) {
      return;
    }
    if (subjectEditorInFocus) {
      openSubjectMention(editorSubjectState);
    } else {
      setEditorInFocus(true);
      editorFocus();
      openBodyMention(editorBodyState);
    }
  }, [editorInFocus, subjectEditorInFocus, openSubjectMention, editorSubjectState, setEditorInFocus, openBodyMention, editorBodyState]);

  const onCloseMentionsMenuHandler = (
    editorState: EditorState,
    setEditorState: (editorState: EditorState) => void,
    closeMention: (editorState: EditorState) => void,
  ) => {
    const currentContentState = editorState.getCurrentContent();
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const startKey = selectionState.getStartKey();
    const contentBlock = contentState.getBlockForKey(startKey);

    let entitySelection: SelectionState | null = null;
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();

        return (
          entityKey !== null &&
          currentContentState.getEntity(entityKey)
            .getType() === DECORATORS_NAMES.MENTION_PLACEHOLDER
        );
      },
      (start, end) => {
        entitySelection = selectionState.merge({
          anchorOffset: start,
          focusOffset: end,
        });
      },
    );

    if (entitySelection) {
      const newContentState = Modifier.removeRange(
        contentState,
        entitySelection,
        'backward',
      );

      const newEditorState = EditorState.push(
        editorBodyState,
        newContentState,
        'remove-range',
      );

      setEditorState(newEditorState);
      closeMention(newEditorState);
    }
  };

  const onCopyEditorText = async () => {
    if (navigator?.clipboard) {
      const copiedText = await navigator.clipboard.readText();
      await navigator.clipboard.writeText(copiedText);
    }
  };

  const renderSkeleton = () => {
    return (
      <SkeletonContainer>
        <TextEditorSkeleton
          showAnimation={ showSkeletonAnimation }
          hasError={ !!hasError }
          errorChipButtonClickHandler={ errorChipButtonClickHandler }
          isErrorActionLoading={ !!isErrorActionLoading }
          loadingText={ skeletonLoadingText }
        />
      </SkeletonContainer>
    );
  };

  const renderEditor = () => {
    if (showSkeleton) {
      return renderSkeleton();
    }

    return (
      <div>
        <SubjectEditorContainer
          id={ subjectContainerId }
          isVisible={ showSubjectEditor }
          onClick={ (e) => e.stopPropagation() }
        >
          <Editor
            readOnly={ subjectReadOnly }
            ref={ refSubjectEditor }
            editorState={ editorSubjectState }
            onChange={ onSubjectStateChange }
            handleKeyCommand={ handleKeyCommandForSubject }
            keyBindingFn={ (e) => {
              if (!e.metaKey && e.code === KEYBOARD_KEYS.ENTER) {
                return null;
              }

              return Draft.getDefaultKeyBinding(e);
            } }
            handleBeforeInput={ (chars: string, editorState: EditorState) => {
              return handleBeforeInput(chars, editorState, openSubjectMention, setEditorSubjectState);
            } }
            onCopy={ onCopyEditorText }
            placeholder={ translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.SUBJECT.PLACEHOLDER) }
            onBlur={ onSubjectBlurHandler }
          />
          <Divider />
        </SubjectEditorContainer>
        <BodyEditorContainer id={ bodyContainerId }>
          <Editor
            ref={ refBodyEditor }
            keyBindingFn={ editorKeyboardEventHandler }
            blockStyleFn={ blockStyleFn }
            editorState={ editorBodyState }
            handleKeyCommand={ handleKeyCommandForBody }
            onChange={ onBodyStateChange }
            onCopy={ onCopyEditorText }
            handleBeforeInput={ (chars: string, editorState: EditorState) => {
              return handleBeforeInput(chars, editorState, openBodyMention, setEditorBodyState);
            } }
            placeholder={ translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MESSAGE.PLACEHOLDER) }
            onBlur={ onBodyBlurHandler }
          />
        </BodyEditorContainer>
      </div>
    );
  };

  const renderInfoComponent = () => {
    if (InfoComponent && !showSkeleton) {
      return <InfoComponentWrapper>{ InfoComponent }</InfoComponentWrapper>;
    }

    return <Divider />;
  };

  return (
    <EditorWrapper
      inFocus={ editorInFocus }
      showTopBorderRadius={ !showTextEditorHeader }
      onClick={ onClickEditorContainerHandler }
      { ...otherProps }
    >
      { renderEditor() }
      <FooterContainer>
        { renderInfoComponent() }
        <EditorActionsContainer>
          <StandardAction>
            <StandardAction>
              <InlineStyleControlsComponent
                toggleHandler={ applyInlineStyle }
                currentInlineStyle={ editorBodyState.getCurrentInlineStyle() }
              />
              <TextAlignmentControlsComponent
                toggleHandler={ applyAlignmentStyle }
                currentInlineStyle={ editorBodyState.getCurrentInlineStyle() }
              />
            </StandardAction>
            { mentions && (
              <StandardAction>
                <TokensControl
                  disabled={ !editorInFocus || !!showSkeleton }
                  toggleHandler={ handleTokenControl }
                />
              </StandardAction>
            )}
            { ActionControlsAdornment }
          </StandardAction>
          { ActionButton }
        </EditorActionsContainer>
      </FooterContainer>
      { mentions && (
        <MentionsList
          mentions={ mentions }
          mentionState={ bodyMentionState }
          mentionReference={ bodyMentionReference }
          confirmMentionHandler={ confirmBodyMention }
          mentionsMenuCloseHandler={ () => onCloseMentionsMenuHandler(editorBodyState, setEditorBodyState, closeBodyMention) }
        />
      )}
      { mentions && (
        <MentionsList
          mentions={ mentions }
          mentionState={ subjectMentionState }
          mentionReference={ subjectMentionReference }
          confirmMentionHandler={ confirmSubjectMention }
          mentionsMenuCloseHandler={ () => onCloseMentionsMenuHandler(editorSubjectState, setEditorSubjectState, closeSubjectMention) }
        />
      )}
    </EditorWrapper>
  );
};

export default PerfectTextEditor;

PerfectTextEditor.defualtProps = {
  disableSendButton: false,
  emailsInput: [],
};
