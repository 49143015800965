import log from 'loglevel';
import { getAppInformation, getAppMode } from './utils/environment';
import { initAppLogger } from './logging';
import { AppModeEnum } from './enums/app-mode.enum';

// Init App logger
initAppLogger(log);
const logger = log.getLogger('Index');

// Special variables for console
Object.defineProperty(window, 'app_version', { get: getAppInformation });

Object.defineProperty(window, 'intercomSettings', {
  get() {
    return {
      ...this.data,
      hide_default_launcher: true,
    };
  },
  set(newData) {
    this.data = { ...(this.data || {}), ...newData };
  },
});

const appMode = getAppMode();

logger.debug('the app was started in mode:', appMode, { appEnvInformation: getAppInformation() });

switch (appMode) {
  case AppModeEnum.MatchPreview: {
    require('./entrypoints/match_preview');
    break;
  }
  case AppModeEnum.GoogleEmailOauth: {
    require('./entrypoints/google-email-oauth');
    break;
  }
  case AppModeEnum.MicrosoftEmailOauth: {
    require('./entrypoints/microsoft-email-oauth');
    break;
  }
  default:
  case AppModeEnum.Default: {
    require('./entrypoints/app');
    break;
  }
}
