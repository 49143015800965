import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import StepContainer from '../step-container/step-container.component';
import STRING_KEYS from '../../../../../../language/keys';
import { CompanySizeTierEnum } from '../../../../../../enums/company-size-tier.enum';
import CompanySizeCheckboxContainer
  from '../../../../../../components/company-size-checkbox-container/company-size-checkbox-container.component';
import { ANALYTICS_EVENTS } from '../../../../../../consts/analytics';
import { CompanySizeStepProps } from './company-size-step';

const CompanySizeStep = ({ selectedCompanySizes, changeHandler, backButtonClickHandler }: CompanySizeStepProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [selectedCompanySizesSet, setSelectedCompanySizes] = useState(new Set<CompanySizeTierEnum>(selectedCompanySizes));

  const isContinueButtonDisabled = useMemo(
    () => isEqual(Array.from(selectedCompanySizesSet), selectedCompanySizes),
    [selectedCompanySizes, selectedCompanySizesSet],
  );

  return (
    <StepContainer
      title={ translate(STRING_KEYS.MATCH_TUNE.COMPANY_SIZE_STEP.TITLE) }
      subtitle={ translate(STRING_KEYS.MATCH_TUNE.COMPANY_SIZE_STEP.SUBTITLE) }
      backButtonHandler={ backButtonClickHandler }
      continueButtonHandler={ () => changeHandler(Array.from(selectedCompanySizesSet)) }
      isContinueButtonDisabled={ isContinueButtonDisabled }
      eventName={ ANALYTICS_EVENTS.MATCH_TUNE_COMPANY_SIZE_STEP_VIEWED }
    >
      <CompanySizeCheckboxContainer
        selectedItems={ selectedCompanySizesSet }
        changeHandler={ setSelectedCompanySizes }
      />
    </StepContainer>
  );
};

export default CompanySizeStep;
