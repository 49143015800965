import {
  MouseEvent, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInCalendarDays } from 'date-fns';
import Steps from '../../../../../../components/Common/outreach-steps/outreach-steps.component';
import { IconButtonVariants } from '../../../../../../components/Common/Buttons/icon-button/icon-button.enums';
import { PerfectButtonHoverVariants } from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import BaseMenu from '../../../../../../components/Common/perfect-base-menu/perfect-base-menu.component';
import MenuItem from '../../../../../../components/Common/perfect-base-menu/menu-item/menu-item.component';
import STRING_KEYS from '../../../../../../language/keys';
import {
  AutoModeEditorHeader,
  Icon,
  EndAdornment,
  MenuButton,
  MenuButtonIcon,
} from './auto-mode-text-editor-header.styled';

const AutoModeTextEditorHeader = ({
  totalSteps,
  completedSteps,
  cancelCampaignClickHandler,
  scheduledMessageTime,
}: AutoModeTextEditorHeaderProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const anchorElementRef = useRef<HTMLDivElement>(null);
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);

  const closeMenu = () => {
    setAnchorElement(null);
  };

  const onMenuButtonClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    anchorElement ? closeMenu() : setAnchorElement(anchorElementRef?.current);
  };

  const onCancelConversationSequenceMenuItemClick = () => {
    closeMenu();
    cancelCampaignClickHandler();
  };

  const renderMenu = () => {
    return (
      <BaseMenu
        isOpen={ !!anchorElement }
        onClose={ () => setAnchorElement(null) }
        anchorElement={ anchorElement || undefined }
        placement="bottom-start"
      >
        <MenuItem onClick={ onCancelConversationSequenceMenuItemClick }>
          { translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.AUTO_MODE_HEADER.MENU_OPTIONS.CANCEL_CAMPAIGN) }
        </MenuItem>
      </BaseMenu>
    );
  };

  const renderAutoModeHeaderAdornment = () => {
    return (
      <EndAdornment>
        <div>
          { translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.AUTO_MODE_HEADER.STEPS_TEXT, {
            currentStep: Math.min(completedSteps + 1, totalSteps),
            totalSteps,
          }) }
        </div>
        <Steps totalSteps={ totalSteps } completedSteps={ completedSteps } />
        <div
          ref={ anchorElementRef }
          onClick={ (event) => onMenuButtonClick(event) }
        >
          <MenuButton
            type="button"
            variant={ IconButtonVariants.Ghost }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
            StartIcon={ MenuButtonIcon }
          />
          { renderMenu() }
        </div>
      </EndAdornment>
    );
  };

  const scheduledMessageSendTimeDifferenceInDays = useMemo(
    () => scheduledMessageTime ? differenceInCalendarDays(new Date(scheduledMessageTime), new Date()) : undefined,
    [scheduledMessageTime]);

  const infoText = useMemo(() => {
    if (scheduledMessageSendTimeDifferenceInDays === undefined || scheduledMessageSendTimeDifferenceInDays < 0) {
      return translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.AUTO_MODE_HEADER.INFO_TEXT_NO_TIMESTAMP);
    }

    if (scheduledMessageSendTimeDifferenceInDays > 1) {
      return translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.AUTO_MODE_HEADER.INFO_TEXT_PLURAL, { daysCount: scheduledMessageSendTimeDifferenceInDays });
    }
    if (scheduledMessageSendTimeDifferenceInDays === 1) {
      return translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.AUTO_MODE_HEADER.INFO_TEXT_SINGULAR, { daysCount: scheduledMessageSendTimeDifferenceInDays });
    }

    return translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.AUTO_MODE_HEADER.INFO_TEXT_TODAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledMessageTime, scheduledMessageSendTimeDifferenceInDays]);

  return (
    <AutoModeEditorHeader
      label={ translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.AUTO_MODE_HEADER.LABEL) }
      infoText={ infoText }
      Icon={ <Icon /> }
      EndAdornment={ renderAutoModeHeaderAdornment() }
    />
  );
};

export default AutoModeTextEditorHeader;
