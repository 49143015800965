import { UpdateUserQueryArguments } from '../dto/query-arguments/update-user.query-arguments';
import { userQuery } from '../user.toolkit-api';
import { UserResponse } from '../dto/response/invited-users.response';

type useUpdateUserResponseType = [
  (data: UpdateUserQueryArguments) => void,
  AppSelectorType<UserResponse[]>
];

export const useUpdateUserMutation = (): useUpdateUserResponseType => {
  const [
    updateUser,
    {
      data, isLoading, isError, error, isSuccess,
    }] = userQuery.useUpdateUserMutation();

  const meta = {
    isLoading, isError, error, isSuccess, data: data || [] as UserResponse[],
  };

  return [
    updateUser,
    { ...meta },
  ];
};
