import { CaseReducer, AnyAction } from '@reduxjs/toolkit';
import { CreatePositionState, initialCreatePositionState } from '../create-position-state.initial-state';

export const createPositionsPendingExtraReducer: CaseReducer<CreatePositionState, AnyAction> = (state) => {
  state.loading = true;
};

export const createPositionsRejectedExtraReducer: CaseReducer<CreatePositionState, AnyAction> = (state, action) => {
  return {
    ...initialCreatePositionState,
    error: action.payload.error.serverError?.message,
    errorCode: action.payload.error.serverError?.internalErrorCode,
  };
};

export const createPositionsFulfilledExtraReducer: CaseReducer<CreatePositionState, AnyAction> = (state, { payload }) => {
  return {
    ...initialCreatePositionState,
    createdId: payload.id,
  };
};
