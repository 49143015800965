import styled, { css } from 'styled-components';
import { THEME } from '../../../../../../../../themes';
import { hexToRgbA } from '../../../../../../../../utils/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINO};
`;

export const TextUnderlineStyle = css`
  text-underline-offset: ${THEME.SPACING.MICRO};

  :hover {
    text-decoration: underline solid ${hexToRgbA(THEME.COLORS.PRIMARY, THEME.OPACITY.DIVIDER)};
  }
`;
