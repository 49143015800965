/* eslint-disable react/no-array-index-key */
import { useTranslation } from 'react-i18next';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useLocation, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useFilterAndGroupAutoConversations } from '../../../../hooks/filter-and-group-auto-conversations.hook';
import STRING_KEYS from '../../../../../../language/keys';
import { ConversationGroupsEnum } from '../../../../../../enums/outreach/conversation-groups.enum';
import { usePageFilters } from '../../../../../../hooks/use-page-filters.hooks';
import { AppRouting } from '../../../../../../services/appRoutingResolver';
import Portal from '../../../../../../components/Common/Portal/portal.commponent';
import { getGroupIcon } from '../conversation-group.util';
import { PageName } from '../../../../../../enums/page-name.enum';
import { ErrorIcon } from '../conversation-group.styled';
import {
  ConversationGroupsContainer,
  ConversationNumber,
  GroupContainer,
  GroupsContainer,
  GroupTitleContainer,
  HeaderContainer,
  HeaderTitle,
  ProgressContainer,
} from './conversation-group-auto-mode.styled';
import { ConversationsGroupProps } from './conversation-group-auto-mode';
import ConversationItemsAutoMode from './conversation-items/conversation-items.component';

export const CONVERSATION_ITEMS_ID = 'CONVERSATION_ITEMS';

const ConversationGroupAutoMode = ({ conversations, sequenceLength }: ConversationsGroupProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { positionId } = useParams<MatchUrlParams>();
  const [selectedConversationIds, setSelectedConversationIds] = useState<string[] | null>(null);
  const {
    getFiltersByKey,
    isFilterExists,
    setFilterByKey,
    deleteFiltersByKey,
  } = usePageFilters();

  const selectedConversationGroupName = useMemo<string>(() => {
    return getFiltersByKey(AppRouting.URL_PARAMS.OUTREACH.CONVERSATION_GROUP)[0];
  }, [getFiltersByKey]);

  const onSelectGroupClick = useCallback((step: ConversationGroupsEnum, itemDisabled: boolean) => {
    if (!itemDisabled) {
      const pageName = AppRouting.getPageName(location.pathname);
      if (pageName !== PageName.Conversation && pageName !== PageName.ConversationsEmpty) {
        history.push(AppRouting.buildPositionConversationPageUrl(positionId, step));
      } else if (!isFilterExists(AppRouting.URL_PARAMS.OUTREACH.CONVERSATION_GROUP, step)) {
        setFilterByKey(AppRouting.URL_PARAMS.OUTREACH.CONVERSATION_GROUP, step, false);
      } else {
        deleteFiltersByKey(AppRouting.URL_PARAMS.OUTREACH.CONVERSATION_GROUP);
      }
    }
  }, [location.pathname, isFilterExists, history, positionId, setFilterByKey, deleteFiltersByKey]);

  const groupedConversations = useFilterAndGroupAutoConversations(conversations, sequenceLength || 0);

  useEffect(() => {
    if (selectedConversationGroupName && groupedConversations.has(selectedConversationGroupName)) {
      setSelectedConversationIds(groupedConversations.get(selectedConversationGroupName)?.conversationIds || null);
    }
  }, [groupedConversations, selectedConversationGroupName]);

  const Groups = useMemo(() => Array.from(groupedConversations.entries()).map(([groupNameKey, conversationsGrouped]) => {
    const allConversationsLength =  groupedConversations.get(ConversationGroupsEnum.All)?.conversationIds?.length || 0;
    const conversationsLength =  conversationsGrouped?.conversationIds?.length || 0;
    const widthPercentage = groupNameKey === ConversationGroupsEnum.All ? 100 : (conversationsLength / allConversationsLength) * 100;
    const itemDisabled = !conversationsLength;
    const getTranslatedGroupName = () => {
      if (groupNameKey.includes(ConversationGroupsEnum.Step)) {
        const [, stepNumber] = groupNameKey.split('_');

        return translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.GROUP_NAME.STEP, { stepNumber });
      }

      return translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.GROUP_NAME[groupNameKey]);
    };

    return (
      <GroupContainer
        active={ groupNameKey === selectedConversationGroupName }
        key={ groupNameKey }
        onClick={ () => onSelectGroupClick(groupNameKey as ConversationGroupsEnum, itemDisabled) }
        disabled={ itemDisabled }
      >
        <ProgressContainer active={ groupNameKey === selectedConversationGroupName } widthPercentage={ widthPercentage } />
        <GroupTitleContainer>
          { getGroupIcon(groupNameKey) }
          { getTranslatedGroupName() }
        </GroupTitleContainer>
        {
          groupNameKey !== ConversationGroupsEnum.All && conversationsGrouped.bounceExists ?
            <ErrorIcon /> :
            <ConversationNumber>{ conversationsLength }</ConversationNumber>
        }
      </GroupContainer>
    );
  }), [groupedConversations, selectedConversationGroupName, translate, onSelectGroupClick]);

  return (
    <div>
      <ConversationGroupsContainer>
        <HeaderContainer>
          <HeaderTitle>{ translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.SECTION_TITLE) }</HeaderTitle>
        </HeaderContainer>
        <GroupsContainer>
          { Groups }
        </GroupsContainer>
      </ConversationGroupsContainer>
      <Portal containerId={ CONVERSATION_ITEMS_ID } removeAfterUnmount={ false }>
        <ConversationItemsAutoMode
          selectedConversationIds={ selectedConversationIds }
          conversations={ conversations }
        />
      </Portal>
    </div>
  );
};

export default ConversationGroupAutoMode;
