import { CONFIG } from '../config';
import { POSITION_STATUS } from '../consts';
import { PositionResponse } from '../store/rtk-query/apis/position/dto/response/position.response';
import { LevelOfExperience } from '../enums/level-of-experience.enum';

export const sortPositionsByState =
 (positionsToSort: PositionResponse[]):
 PositionResponse[] => {
   // sorting position array to have opened first and closed second
   return positionsToSort
     .sort((a: PositionResponse, b: PositionResponse) => {
       if (a.state === b.state) {
         return 0;
       }
       if (a.state === POSITION_STATUS.CLOSED) {
         return 1;
       }

       return -1;
     });
 };

export const isFewDaysLeft = (endDate: Date): boolean => {
  const ONE_DAY = 24 * 60 * 60 * 1000;
  const now = new Date();
  const daysInMilliseconds = CONFIG.TOTAL_DAYS_WARNING_BEFORE_TRIAL_END * ONE_DAY;
  const timeDiffInMs = endDate.getTime() - daysInMilliseconds;

  return now.getTime() > timeDiffInMs;
};

export const getMatchingOrderedLevelsOfExperience = (levelsOfExperience: LevelOfExperience[]): LevelOfExperience[] => {
  if (levelsOfExperience.length <= 1) {
    return levelsOfExperience;
  }

  const enumKeys = Object.values(LevelOfExperience);
  const sortedRange = levelsOfExperience.slice().sort((a, b) => enumKeys.indexOf(a) - enumKeys.indexOf(b));
  const rangeIndexes = sortedRange.map((enumValue) => enumKeys.indexOf(enumValue));

  let endIndex = 0;
  for (let i = 0; i < rangeIndexes.length; i++) {
    if (rangeIndexes[i + 1] !== undefined) {
      const enumIndex = rangeIndexes[i];
      if (enumIndex + 1 === rangeIndexes[i + 1]) {
        endIndex = i + 1;
      } else {
        break;
      }
    }
  }

  return sortedRange.slice(0, endIndex + 1);
};
