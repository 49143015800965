import { Trans } from 'react-i18next';
import { ASSETS } from '../../../../../assets';
import STRING_KEYS from '../../../../../language/keys';
import { THEME } from '../../../../../themes';
import { InstructionsMessage } from '../../../../onboarding/components/autocompletes/instructions-message/instructions-message.component';

export const TypeAndSelectMessage = ():JSX.Element => {
  return (
    <InstructionsMessage
      color={ THEME.COLORS.CONTENT_PRIMARY }
      icon={ ASSETS.LIST }
      message={ (
        <Trans
          i18nKey={ STRING_KEYS.TYPE_AND_SELECT }
          components={ { bold: <strong /> } }
        />
      ) }
    />
  );
};
