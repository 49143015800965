import styled, { css, keyframes } from 'styled-components';
import { Body1Font, Body2Font, HeadlineFont } from '../../../../themes/fonts';
import { ASSETS } from '../../../../assets';
import { THEME } from '../../../../themes';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';

export const ModeOptionsContainer = styled.div`
  max-width: 960px;
`;

export const Title = styled.span`
  ${HeadlineFont};
`;

export const TitleIcon = styled(ASSETS.FOLDER_LIGHTNING)`
  height: 24px;
`;

export const SaveButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.ACCENT};
  width: 88px;
`;

export const ModesContainer = styled.div`

`;

export const DescriptionText = styled.div`
  ${Body2Font};
  opacity: ${THEME.OPACITY.MED};
  max-width: 640px;
  margin-top: ${THEME.SPACING.TINO};
`;

export const AutoModeOptionsTitle = styled.div`
  ${Body1Font};
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_A};
  margin-bottom: ${THEME.SPACING.TINY};
`;

export const OptionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
`;

export const ModesButtonsContainer = styled.div`
  display: flex;
  margin-top: ${THEME.SPACING.MEDIUM};
  gap: ${THEME.SPACING.BASE};
`;

export const ModeButtonItemContainer = styled.div`
`;

export const ManualButtonIcon = styled(ASSETS.CHAT)``;
export const CustomButtonIcon = styled(ASSETS.CHAT_V_SIGN)``;
export const AutoButtonIcon = styled(ASSETS.FOLDER_LIGHTNING)``;
export const StarIcon = styled(ASSETS.BADGE_STAR)``;
export const LightningIcon = styled(ASSETS.LIGHTNING)`
  height: 14px;
`;

export const SignatureIcon = styled(ASSETS.SIGNATURE)`
  height: 14px;
`;

export const LeftArrowIcon = styled(ASSETS.ARROW_BACK_COMPONENT)`
  width: 14px;
  transform: rotate(180deg);
  margin-left: ${THEME.SPACING.MICRO};
`;

const WobbleRight = keyframes`
  0%,
  100% {
    transform: translateY(0) rotate(0);
    transform-origin: 50% 50%;
  }
  15% {
    transform: translateY(-30px) rotate(6deg);
  }
  30% {
    transform: translateY(15px) rotate(-6deg);
  }
  45% {
    transform: translateY(-15px) rotate(3.6deg);
  }
  60% {
    transform: translateY(9px) rotate(-2.4deg);
  }
  75% {
    transform: translateY(-6px) rotate(1.2deg);
  }
`;

export const WobbleRightAnimationContainer = styled.div<{ showAnimation: boolean }>`
  animation: ${({ showAnimation }) => showAnimation ? css`${WobbleRight} 0.8s both` : undefined}; 
`;
