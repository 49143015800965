import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { Subtitle1Font } from '../../../../themes/fonts';
import { hexToRgbA } from '../../../../utils/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINO};
  margin-top: ${THEME.SPACING.TINY};
`;

export const Quote = styled.div`
  ${Subtitle1Font};
  background-color: ${THEME.COLORS.LIGHT_GREY};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Merriweather;
  width: fit-content;
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.BASE};
  color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.MED)};
`;
