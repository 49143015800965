import styled from 'styled-components';
import { THEME } from '../../../../themes';

export const OnboardingPageFooterContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.BASE};
  width: 80vw;
  margin: 0 0 35px ${THEME.SPACING.GRAND};
  align-items: center;
  left: 0;
  bottom: 0;
`;

export const OnboardingPageContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
`;
