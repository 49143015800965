import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { menuElevation } from '../../../../themes/elevations';
import { Body2Font, Subtitle1Font } from '../../../../themes/fonts';
import { TextEllipsis } from '../../../../themes/text';

const MAX_HEIGHT = 200;
const ITEM_HEIGHT = 56;

export const MenuContainer = styled.div<{ menuWidthPx?: number }>`
  ${menuElevation};
  width: ${({ menuWidthPx }) => menuWidthPx ? `${menuWidthPx}px` : '100%'};
  display: block;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  border-radius: ${THEME.RADIUS.ROUND};
  background-color: ${THEME.COLORS.SURFACE_PRIMARY};
  z-index: 8;
  cursor: auto;
  max-height: ${MAX_HEIGHT}px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ListItemContainer = styled.div<{ selected?: boolean }>`
  transition: background-color 100ms ease-in;
  padding: 0 ${THEME.SPACING.MEDIUM};
  cursor: ${({ selected }) => selected ? 'unset' : 'pointer'};
  color: ${({ selected }) => selected ? THEME.COLORS.ACCENT : THEME.COLORS.CONTENT_A};
  height: ${ITEM_HEIGHT}px;
  display: flex;
  align-items: center;

  ${({ selected }) => {
    if (!selected) {
      return (`
        &:hover {
          background-color: ${THEME.COLORS_RGB.ACCENT_DIVIDER};
         }`
      );
    }

    return '';
  }};
`;

export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
`;

export const ItemImage = styled.img`
  width: 24px;
  border-radius: ${THEME.RADIUS.CIRCLE};
  margin-right: ${THEME.SPACING.TINO};
`;

export const Title = styled.div`
  ${Subtitle1Font}
`;

export const Subtitle = styled.div<{ menuWidthPx?: number }>`
  ${({ menuWidthPx }) => menuWidthPx ? TextEllipsis : undefined};
  max-width: ${({ menuWidthPx }) => menuWidthPx ? `calc(${menuWidthPx}px - ${THEME.SPACING.MEDIUM} - ${THEME.SPACING.MEDIUM})` : undefined};
  ${Body2Font};
  opacity: 0.6;
`;

export const Divider = styled.div`
  background-color: ${THEME.COLORS.NEUTRAL};
  height: 1px;
`;
