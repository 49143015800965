import BaseInputItem from '../base-input-item.component';
import { BaseInputItemDirection } from '../text-input-item.enums';
import { Input, InputsContainer } from './text-input-item.styled';
import { TextInputItemProps } from './text-input-item';

const TextInputItem = ({
  title,
  value,
  description,
  placeholder,
  changeHandler,
  direction = BaseInputItemDirection.COLUMN,
  fullWidth = false,
  children,
}: TextInputItemProps): JSX.Element => {
  return (
    <BaseInputItem
      title={ title }
      description={ description }
      direction={ direction }
    >
      <InputsContainer>
        <Input
          placeholder={ placeholder }
          onChange={ changeHandler }
          value={ value }
          fullWidth={ fullWidth }
        />
        { children }
      </InputsContainer>
    </BaseInputItem>
  );
};

export default TextInputItem;
