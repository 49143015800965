import styled from 'styled-components';
import { hexToRgbA } from '../../../../utils/colors';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';
import Spinner from '../../../../components/Common/spinner/spinner.component';

export const TabContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${THEME.SPACING.MICRO}
`;

export const MatchCounter = styled.div<{ $isActive: boolean }>`
  width: 25px;
  height: 22px;
  border-radius: ${THEME.RADIUS.TIDY};
  background-color: ${({ $isActive }) => $isActive ?  hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.GHOST) : hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.GHOST)};
  text-align: center;
`;

export const OutreachNotification = styled.div`
  position: absolute;
  top: 0;
  right: -7px;
  width: 8px;
  height: 8px;
  border-radius: ${THEME.RADIUS.CIRCLE};
  background-color: ${THEME.COLORS.ACCENT};
`;

export const HourglassIcon = styled(ASSETS.HOURGLASS)`
  width: 8px;
  height: 12px;
  fill: currentColor;
`;

export const SpinnerContainer = styled.div`
  margin: 0 auto;
  width: 11px;
  height: 20px;
`;

export const StyledSpinner = styled(Spinner)`
  height: 12px;
  width: 12px;
`;
