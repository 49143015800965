/* eslint-disable react/no-array-index-key */
import { SocialLinksType } from './social-links.enums';
import { SocialLinksProps } from './social-links';
import {
  Container, FacebookIcon, GithubIcon, LinkContainer, LinkedinIcon, MediumIcon, OtherIcon, StackOverflowIcon, TwitterIcon,
} from './social-links.styled';

const SocialLinks = ({ socialLinks, onSocialLinkClick }: SocialLinksProps):JSX.Element => {
  const getSocialIconByType = (type: SocialLinksType) => {
    switch (type) {
      case SocialLinksType.GitHub:
        return <GithubIcon />;
      case SocialLinksType.Medium:
        return <MediumIcon />;
      case SocialLinksType.Facebook:
        return <FacebookIcon />;
      case SocialLinksType.LinkedIn:
        return <LinkedinIcon />;
      case SocialLinksType.Twitter:
        return <TwitterIcon />;
      case SocialLinksType.StackOverflow:
        return <StackOverflowIcon />;
      case SocialLinksType.Other:
      default: {
        return <OtherIcon />;
      }
    }
  };

  const renderIcon = () => {
    return socialLinks.map((socialLink, index) => (
      <LinkContainer
        key={ `${socialLink.type}_${index}` }
        href={ socialLink.url }
        target="_blank"
        rel="noreferrer"
        onClick={ () => onSocialLinkClick(socialLink.type) }
      >
        { getSocialIconByType(socialLink.type) }
      </LinkContainer>
    ));
  };

  return (
    <Container>
      { renderIcon() }
    </Container>
  );
};

export default SocialLinks;
