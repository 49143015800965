export const countDecimals = (number: number): number => {
  if (Math.floor(number) === number) {
    return 0;
  }

  return number.toString().split('.')[1].length || 0;
};

export const numberWithCommas = (number: number): string => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getRandomIntBetweenRange = (min:number, max:number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
};
