import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { hexToRgbA } from '../../../../utils/colors';

export const PageContainer = styled.div``;

export const ChildrenWrapper = styled.div`
  height: 100%;
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to right, ${THEME.COLORS.LIGHT_GREY}, ${THEME.COLORS.LIGHT_GREY});
  display: flex;
  flex-grow: 1;
  z-index: -1;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    border-radius: 100%;
    height: 0;
    filter: blur(80vw);
    opacity: 0.5;
    background: linear-gradient(132deg, ${hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.LOW)} 0%, ${hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.LOW)} 100%);
    width: 34%;
    padding-top: 34%;
    left: 50%;
    top: 100%;
    transform: translateX(-50%) translateY(-50%);
  }
`;
