import { useEffect } from 'react';
import {
  useOnboardingStoreMetadataSelector,
} from '../../../../store/slices/onboarding-state/selectors/onboarding-selectors.hooks';
import { FatalErrorPage } from '../../../../components/Common/Errors/FatalErrorPage';
import LoginLogger from '../../../../components/Common/Loaders/login-loader/login-loader.component';
import { ONBOARDING_PAGE_TITLES } from '../../consts/onboarding.const';
import { useGetCurrentStep } from '../../hooks/onboarding-current-step.hooks';
import { FATAL_ERRORS_CODE } from '../../../../store/slices/app-state/app-state.consts';
import { PageContainer } from './onboarding-layout.styled';

const OnboardingLayout = ({ children }: LayoutProps): JSX.Element => {
  const {
    isOnboardingLoading,
    onboardingLoadingError,
  } = useOnboardingStoreMetadataSelector();
  const currentStep = useGetCurrentStep();

  useEffect(() => {
    const title = ONBOARDING_PAGE_TITLES[currentStep as keyof typeof ONBOARDING_PAGE_TITLES];
    if (title) {
      document.title = title;
    }
  }, [currentStep]);

  if (onboardingLoadingError) {
    return <FatalErrorPage errorCode={ FATAL_ERRORS_CODE.GET_ONBOARDING_PROGRESS_FROM_SERVER_FAILED } />;
  }

  return (
    <PageContainer>
      <LoginLogger isLoading={ isOnboardingLoading } />
      { children }
    </PageContainer>
  );
};

export default OnboardingLayout;
