import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { MatchState } from '../match-state.initial-state';

export type TalentNetworkDialogData = {
  isOpen: boolean,
  selectedExperienceIds?: Set<number>,
  selectedEducationIds?: Set<number>,
}

export const talentNetworkDialogDataReducer: CaseReducer<MatchState, PayloadAction<TalentNetworkDialogData>> = (state, { payload }) => {
  state.talentsNetworkDialogData = payload;
};

export const closeTalentNetworkDialogReducer: CaseReducer<MatchState, PayloadAction> = (state) => {
  state.talentsNetworkDialogData = {
    isOpen: false,
    selectedEducationIds: new Set(),
    selectedExperienceIds: new Set(),
  };
};
