import { Container, SelectedIcon, StyledMenuItem } from './select-item.styled';

const SelectItem = ({
  selected,
  value,
  children,
  onClickHandler,
  ...otherProps
}: SelectItemProps): JSX.Element => {
  return (
    <Container selected={ selected }>
      <StyledMenuItem
        onClick={ () => onClickHandler?.(value) }
        // eslint-disable-next-line react/jsx-props-no-spreading
        { ...otherProps }
      >
        <>
          { children }
          { selected && <SelectedIcon /> }
        </>
      </StyledMenuItem>
    </Container>
  );
};

export default SelectItem;
