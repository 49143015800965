import styled from 'styled-components';
import { THEME } from '../../../themes';
import { Body1Font } from '../../../themes/fonts';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${THEME.SPACING.TINY};
`;

export const AccountLogoImage = styled.img`
  max-height: 48px;
  max-width: 500px;
`;

export const AccountName = styled.div`
  ${Body1Font};
  color: ${THEME.COLORS.PRIMARY_DARK};
`;
