import { ConversationMinimizedResponseDto } from '../dto/response/conversation-minimized.response';
import { ConversationResponseDto } from '../dto/response/conversation.response';

const archiveConversation = (conversation: ConversationMinimizedResponseDto | ConversationResponseDto): void => {
  conversation.isArchived = true;
  conversation.nextScheduledMessage = undefined;
  conversation.nextOpenLinkedinTask = undefined;
};

export const archiveConversationsByIdOptimisticHandler = (
  conversations: ConversationMinimizedResponseDto[],
  conversationId: string,
): void => {
  const foundConversation = conversations.find((conversation) => conversation.id === conversationId);
  if (foundConversation) {
    archiveConversation(foundConversation);
  }
};

export const archiveConversationOptimisticHandler = (
  conversation: ConversationResponseDto,
): void => {
  archiveConversation(conversation);
};
