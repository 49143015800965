export const COLORS_RGB = {
  TRUE_BLACK_012: 'rgb(1, 10, 20, 0.12)',
  GREEN_012: 'rgb(51, 204, 102, 0.12)',
  GREEN_024: 'rgb(51, 204, 102, 0.24)',
  CONTENT_A_034: 'rgba(2, 29, 61, 0.34)',
  LIGHT_RED: 'rgb(255, 0, 49, 0.12)',
  WHITE_DIVIDER: 'rgba(255, 255, 255, 0.12)',
  CONTENT_PRIMARY_GHOST: 'rgba(0, 0, 31, 0.08)',
  CONTENT_PRIMARY_MED: 'rgba(0, 0, 31, 0.6)',
  CONTENT_PRIMARY_034: 'rgba(0, 0, 31, 0.34)',
  CONTENT_PRIMARY_LOW: 'rgba(0, 0, 31, 0.24)',
  CONTENT_PRIMARY_012: 'rgba(0, 0, 31, 0.12)',
  BLACK_DIVIDER: 'rgba(0, 0, 0, 0.08)',
  GREY_MED: 'rgba(245, 245, 247, 0.6)',
  ACCENT_005: 'rgba(255, 6, 96, 0.05)',
  ACCENT_024: 'rgba(255, 6, 96, 0.24)',
  ACCENT_012: 'rgba(255, 6, 96, 0.12)',
  ACCENT_06: 'rgba(255, 6, 96, 0.6)',
  ACCENT_08: 'rgba(255, 6, 96, 0.08)',
  PRIMARY_DARK_GHOST: 'rgba(0, 0, 64, 0.08)',
  PRIMARY_DARK_DIVIDER: 'rgba(0, 0, 64, 0.12)',
  PRIMARY_DARK_MED: 'rgba(0, 0, 64, 0.6)',
  PRIMARY_DARK_LOW: 'rgba(0, 0, 64, 0.34)',
  PRIMARY_DARK_012: 'rgba(19, 15, 53, 0.12)',
  LIGHT_PURPLE: 'rgba(0, 0, 114, 0.12)',
  PRIMARY_GHOSTLY: 'rgba(0, 0, 114, 0.05)',
  PRIMARY_GHOST: 'rgba(0, 0, 114, 0.08)',
  ALERT_045: 'rgba(255, 0, 49, 0.45)',
  ALERT_008: 'rgba(255, 0, 49, 0.08)',
  WARNING_LOW: 'rgba(241, 196, 15, 0.24)',
  WARNING: 'rgba(241, 196, 15, 0.08)',
  YELLOW_012: 'rgba(241, 196, 15, 0.12)',
  YELLOW_064: 'rgba(241, 196, 15, 0.64)',
  LIGHT_GRAY: 'rgb(243,241,243)',
  GRAY_GHOST: 'rgb(249,249,250)',
  LOW_GRAY: 'rgba(0, 0, 64, 0.34)',
  GHOST_GRAY: 'rgba(19, 15, 53, 0.08)',
  SECONDARY_012: 'rgba(126, 39, 228, 0.12)',
  SECONDARY_024: 'rgba(126, 39, 228, 0.24)',
  SECONDARY_064: 'rgba(126, 39, 228, 0.64)',
  SECONDARY_005: 'rgba(126, 39, 228, 0.05)',
};
