import { useCallback } from 'react';
import { MATCH_ID_PATH_PARAM, POSITION_ID_PATH_PARAM, ROUTES } from '../../../store/rtk-query/apis/match/match.consts';
import axiosInstance from '../../../api';

// TODO Should move to RTK query
async function exportCsvByRoute(route: string, fileName: string): Promise<void> {
  // TODO: change to rtk
  const response = await axiosInstance.get(`/v1${route}`, {
    responseType: 'blob',
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}.csv`;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export const useExportEngagedMatchesCSV = (positionId: string, positionName: string): () => Promise<void> => {
  return useCallback(async () => {
    const route = ROUTES.EXPORT_ENGAGED_MATCHES_CSV.replace(POSITION_ID_PATH_PARAM, positionId);
    await exportCsvByRoute(route, `Perfect_${positionName}_Matches_Data`);
  }, [positionId, positionName]);
};

export const useExportMatchCSV = (positionId: string, matchId: string, talentName: string): () => Promise<void> => {
  return useCallback(async () => {
    const route = ROUTES.EXPORT_MATCH_CSV
      .replace(POSITION_ID_PATH_PARAM, positionId)
      .replace(MATCH_ID_PATH_PARAM, matchId);
    await exportCsvByRoute(route, talentName);
  }, [positionId, matchId, talentName]);
};
