import { Trans } from 'react-i18next';
import parse from 'html-react-parser';
import STRING_KEYS from '../../../../../language/keys';
import { getExperienceSentenceData, prepareMatchSummaryText } from './talent-info-sentence.utils';
import { useGetCurrentJobExperienceWithPostfix, useGetCurrentJobTitlePrefix } from './talent-info-sentence.hooks';

export const TalentInfoSentence = ({
  firstName,
  currentJobTitle,
  currentJobExperienceInMonths,
  currentCompanyName,
  matchSummary,
  highlightWords,
}: TalentInfoSentenceProps): JSX.Element => {
  const jobTitlePrefix = useGetCurrentJobTitlePrefix(currentJobTitle);
  const currentJobExperienceWithPostfix = useGetCurrentJobExperienceWithPostfix(currentJobExperienceInMonths);

  const renderExperienceSentenceComponent = () => {
    const { translationKey, options } = getExperienceSentenceData({
      firstName,
      currentJobTitle,
      currentJobExperienceInMonths,
      currentCompanyName,
      currentJobExperienceWithPostfix,
      jobTitlePrefix,
    }) || {};

    if (!translationKey) {
      return undefined;
    }

    return (
      <Trans
        i18nKey={ translationKey }
        values={ options }
      />
    );
  };

  const renderIntroduceSentenceComponent = () => {
    if (!firstName || !currentJobTitle) {
      return '';
    }

    return (
      <Trans
        i18nKey={ STRING_KEYS.NEW_MATCH_TALENT_BIO_SENTENCE_1_1 }
        values={ {
          firstName,
          currentJobTitle,
          currentJobTitlePrefix: jobTitlePrefix,
        } }
      />
    );
  };

  if (matchSummary) {
    return <>{parse(prepareMatchSummaryText(matchSummary, highlightWords, currentJobTitle))}</>;
  }

  return (
    <>
      { renderIntroduceSentenceComponent() }
      { renderExperienceSentenceComponent() }
    </>
  );
};

TalentInfoSentence.defaultProps = {
  currentCompanyName: undefined,
  currentJobExperienceInMonths: undefined,
};
