import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/base-query';
import {
  getAccountQuery,
  getMyAccountQuery,
} from './account.toolkit-queries';
import { REDUCER_PATH } from './account.consts';
import { AccountBasicInfoResponse } from './dto/response/account-basic-info.response';
import { AccountInfoResponse } from './dto/response/account-info.response';

export const accountQuery = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAccountBySubdomain: builder.query<AccountBasicInfoResponse, string>({
      query: getMyAccountQuery,
    }),
    getAccountById: builder.query<AccountInfoResponse, string>({
      query: getAccountQuery,
    }),
  }),
});
