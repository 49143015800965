import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { CreatePositionState } from '../create-position-state.initial-state';
import { ExactLocationDto, LocationDto } from '../../../../models/dto/location.dto';

export const setPositionRemoteLocationReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  state.isRemote = true;
};

export const addPositionLocationReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  state.selectedLocations = [...state.selectedLocations, {} as ExactLocationDto];
};

export const updatePositionLocationReducer:
  CaseReducer<CreatePositionState, PayloadAction<{ data: LocationDto, index: number }>> = (state, { payload }) => {
    state.selectedLocations[payload.index] = payload.data;
    state.isRemote = false;
  };

export const removePositionLocationReducer: CaseReducer<CreatePositionState, PayloadAction<number>> = (state, { payload }) => {
  state.selectedLocations.splice(payload, 1);
};

export const resetPositionLocationReducer: CaseReducer<CreatePositionState, PayloadAction<number | undefined>> = (state, { payload }) => {
  if (payload === undefined) {
    state.selectedLocations = [];
  } else {
    state.selectedLocations[payload] = {} as ExactLocationDto;
  }

  state.isRemote = false;
};

export const setPositionJobTitleReducer: CaseReducer<CreatePositionState, PayloadAction<string>> = (state, { payload }) => {
  state.selectedJobTitle = {
    name: payload,
  };
};

export const resetPositionJobTitleReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  state.selectedJobTitle = {
    name: '',
  };
};
