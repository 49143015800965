import styled from 'styled-components';
import Chip from '../../../../../components/Common/perfect-chip/perfect-chip.component';
import { Subtitle2Font } from '../../../../../themes/fonts';
import { THEME } from '../../../../../themes';
import { ASSETS } from '../../../../../assets';
import { TextWrapper } from '../../../../../components/Common/perfect-chip/perfect-chip.styled';

export const ArchivedConversation = styled(Chip)`
  ${Subtitle2Font};
  border-radius: ${THEME.RADIUS.BASE};
  background-color: ${THEME.COLORS.LIGHT_GREY};
  color: ${THEME.COLORS.TRUE_BLACK};
  cursor: default;
  ${TextWrapper} {
    max-width: unset;
  }
`;

export const ArchiveIcon = styled(ASSETS.ARCHIVE_FILLED)`
  width: 22px;
  height: 22px;
  fill: ${THEME.COLORS.CONTENT_A};
`;

export const Underline = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${THEME.COLORS.ACCENT};
`;
