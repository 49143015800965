import { useTranslation } from 'react-i18next';
import {
  PerfectButtonHoverVariants,
  PerfectButtonVariants,
} from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../../../language/keys';
import BaseInputItem from '../base-input-item.component';
import { BaseInputItemDirection } from '../text-input-item.enums';
import {
  AutoFillButton,
  ButtonIcon,
  Message,
  MessageContainer,
} from './message-item.styled';
import { MessageItemProps } from './message-item';

const MessageItem = ({
  message, messageChangeHandler, title, titleAdornment, description, placeholder, autoFillClickHandler, isAutoFillButtonLoading, showAutoFillButton,
}: MessageItemProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseInputItem
      title={ title }
      titleAdornment={ titleAdornment }
      description={ description }
      direction={ BaseInputItemDirection.COLUMN }
    >
      <MessageContainer>
        <Message
          placeholder={ placeholder || translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_CONTENT.PLACEHOLDER) }
          value={ message }
          onChange={ messageChangeHandler }
        />
        {showAutoFillButton && (
          <AutoFillButton
            isLoading={ isAutoFillButtonLoading }
            variant={ PerfectButtonVariants.Ghost }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
            StartIcon={ ButtonIcon }
            onClick={ () => autoFillClickHandler?.() }
          >
            { translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_CONTENT.AUTO_FILL_BUTTON) }
          </AutoFillButton>
        )}
      </MessageContainer>
    </BaseInputItem>
  );
};
export default MessageItem;
