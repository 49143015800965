import { Trans, useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import STRING_KEYS from '../../../../../language/keys';
import CampaignPageBaseOption from '../campaign-base-option/campaign-base-option.component';
import SelectionGroupSwitch
  from '../../../../../components/Common/selection-group-switch/selection-group-switch.component';
import { OutreachSequencePreferencesFormikKeys } from '../../../pages/campaign/campaign.enums';
import { SelectionGroupSwitchState } from '../../../../../components/Common/selection-group-switch/selection-group-switch.enums';
import { CampaignAutoModeFasterFollowUpsSectionProps } from './campaign-auto-mode-faster-follow-ups-section';
import { Container, Icon } from './campaign-auto-mode-faster-follow-ups-section.styled';

const CampaignAutoModeFasterFollowUpsSection = ({ active, changeHandler }: CampaignAutoModeFasterFollowUpsSectionProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const selectedValue = useMemo(() => active ? SelectionGroupSwitchState.Active : SelectionGroupSwitchState.Inactive, [active]);

  const onChangeSelection = (value: string) => {
    changeHandler(OutreachSequencePreferencesFormikKeys.FasterFollowups, value === SelectionGroupSwitchState.Active);
  };

  const Description = useMemo(() => (
    <Trans
      defaults={ STRING_KEYS.CAMPAIGN_PAGE.FASTER_FOLLOWUPS.DESCRIPTION }
      components={ { Underline: <u /> } }
    />
  ), []);

  return (
    <Container>
      <CampaignPageBaseOption
        title={ translate(STRING_KEYS.CAMPAIGN_PAGE.FASTER_FOLLOWUPS.TITLE) }
        description={ Description }
        Icon={ Icon }
        EndAdornment={ (
          <SelectionGroupSwitch
            changeHandler={ onChangeSelection }
            option1={ { id: SelectionGroupSwitchState.Inactive, title: translate(STRING_KEYS.CAMPAIGN_PAGE.FASTER_FOLLOWUPS.SWITCH.INACTIVE) } }
            option2={ { id: SelectionGroupSwitchState.Active, title: translate(STRING_KEYS.CAMPAIGN_PAGE.FASTER_FOLLOWUPS.SWITCH.ACTIVE) } }
            selectedValueId={ selectedValue }
          />
        ) }
      />
    </Container>
  );
};

export default CampaignAutoModeFasterFollowUpsSection;
