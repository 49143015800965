import styled from 'styled-components';
import CountUp from 'react-countup';
import { ASSETS } from '../../../../assets';
import { THEME } from '../../../../themes';
import { CaptionFont, HeadlineFont, Title2Font } from '../../../../themes/fonts';

export const TextContainer = styled.div`
  margin-left: ${THEME.SPACING.TINO};
`;

export const Title = styled.div`
  ${HeadlineFont};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  margin-bottom: ${THEME.SPACING.MICRO};
`;

export const Subtitle = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  opacity: ${THEME.OPACITY.MED};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${THEME.SPACING.TINY};
  width: 100%;
  height: 100%;
  border-radius: ${THEME.RADIUS.ROUND};
  word-break: break-all;
`;

export const YearsDecoration = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${THEME.SPACING.MICRO};
  &:before {
    content: '';
    width: 22px;
    height: 55px;
    background: url(${ASSETS.LEAF_LEFT}) no-repeat left center;
    background-size: contain;
  }
  &:after {
    content: '';
    width: 22px;
    height: 55px;
    background: url(${ASSETS.LEAF_RIGHT}) no-repeat left center;
    background-size: contain;
  }
`;

export const YearsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const YearsTitle = styled.div`
  ${CaptionFont};
  text-transform: uppercase;
  color: ${THEME.COLORS.ACCENT};
  font-weight: 900;
  line-height: 1;
`;

// min width is required to prevent jumping of the badge size,
// while the numbers are counting
export const Years = styled(CountUp)`
  ${Title2Font};
  font-weight: bold;
  color: ${THEME.COLORS.ACCENT};
  min-width: 65px;
  text-align: center;
`;
