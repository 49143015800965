import { PerfectButtonVariants } from '../../../Common/Buttons/perfect-base-button/perfect-base-button.enums';
import {
  ActionButton,
  DialogBody,
  DialogContainer,
  DialogFooter,
  DialogFooterSubTitle,
  DialogFooterTitle,
  DialogLeftImage,
  DialogSubTitle,
  DialogTitle,
  ActionGhostButton,
} from './time-to-pay-dialog.styled';
import { TimeToPayDialogButton, TimeToPayDialogContainerProps } from './time-to-pay-dialog';
import { TimeToPayDialogActionVariant } from './time-to-pay-dialog.enums';

const TimeToPayDialogContainer = ({
  children,
  title,
  subTitle,
  footerTitle,
  footerSubTitle,
  buttons,
}: TimeToPayDialogContainerProps): JSX.Element => {
  const hasFooterText = !!footerTitle || !!footerSubTitle;

  const renderSubTitle = () => {
    if (subTitle) {
      return (
        <DialogSubTitle>
          { subTitle }
        </DialogSubTitle>
      );
    }

    return <></>;
  };

  const renderFooterText = (): JSX.Element => {
    if (!hasFooterText) {
      return <></>;
    }

    return (
      <div>
        { footerTitle && (
          <DialogFooterTitle>
            { footerTitle }
          </DialogFooterTitle>
        ) }
        {
          footerSubTitle && (
            <DialogFooterSubTitle>
              { footerSubTitle }
            </DialogFooterSubTitle>
          )
        }
      </div>
    );
  };

  const renderButton = (button: TimeToPayDialogButton): JSX.Element => {
    switch (button.variant) {
      case TimeToPayDialogActionVariant.Text:
        return (
          <ActionGhostButton
            onClick={ (e) => {
              e.stopPropagation();
              button.clickHandler();
            } }
            isLoading={ button.isLoading }
            variant={ PerfectButtonVariants.Ghost }
            key={ button.title }
          >
            { button.title }
          </ActionGhostButton>
        );
      case TimeToPayDialogActionVariant.Button:
      default:
        return (
          <ActionButton
            onClick={ (e) => {
              e.stopPropagation();
              button.clickHandler();
            } }
            isLoading={ button.isLoading }
            key={ button.title }
          >
            { button.title }
          </ActionButton>
        );
    }
  };

  return (
    <DialogContainer>
      <DialogLeftImage />
      <DialogBody>
        <DialogTitle>
          { title }
        </DialogTitle>
        { renderSubTitle() }
        <> { children } </>
        <DialogFooter hasText={ hasFooterText }>
          { renderFooterText() }
          <>{ buttons?.map(renderButton) }</>
        </DialogFooter>
      </DialogBody>
    </DialogContainer>
  );
};

export default TimeToPayDialogContainer;
