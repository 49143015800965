import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { CreatePositionState } from '../create-position-state.initial-state';

export const setJobDescriptionTempUrlReducer: CaseReducer<CreatePositionState, PayloadAction<string>> = (state, { payload }) => {
  state.jobDescriptionTempUrls = payload;
};

export const setJobDescriptionS3TempUrlReducer: CaseReducer<CreatePositionState, PayloadAction<string>> = (state, { payload }) => {
  state.jobDescriptionS3TempUrls = payload;
};

export const resetJobDescriptionFieldsReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  state.jobDescriptionTempUrls = '';
  state.jobDescriptionS3TempUrls = '';
};
