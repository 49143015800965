import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { InfoDialogBase } from '../../info-dialogs/info-dialog-base.component';
import { THEME } from '../../../../themes';
import { FLUENT_UI_EMOJIS } from '../../../../assets';

export const PositionAddedDialog = ({
  closeHandler,
  isDialogOpen,
  createPositionButtonHandler,
}: ActivePositionPurchaseDialogProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <InfoDialogBase
      portalUniqueId="PositionAddedDialog"
      title={ translate(STRING_KEYS.ACTIVE_POSITION_PURCHASE_DIALOG.TITLE) }
      description={ translate(STRING_KEYS.ACTIVE_POSITION_PURCHASE_DIALOG.DESCRIPTION) }
      isDialogOpen={ isDialogOpen }
      onCloseDialog={ closeHandler }
      primaryButton={ {
        text: translate(STRING_KEYS.ACTIVE_POSITION_PURCHASE_DIALOG.PRIMARY_BUTTON_TITLE),
        handler: createPositionButtonHandler,
      } }
      secondaryButton={ {
        text: translate(STRING_KEYS.ACTIVE_POSITION_PURCHASE_DIALOG.SECONDARY_BUTTON_TITLE),
        handler: closeHandler,
      } }
      buttonsColor={ THEME.COLORS.ACCENT }
      Icon={ FLUENT_UI_EMOJIS.WOMAN_RAISING_HAND }
    />
  );
};
