import styled from 'styled-components';
import { ASSETS } from '../../../../../assets';
import Select from '../../../../../components/Common/select/select.component';

export const Container = styled.div`
  
`;

export const Icon = styled(ASSETS.TWO)`
  height: 24px;
`;

export const StyledSelect = styled(Select)`
  width: 96px;
`;
