import styled, { css } from 'styled-components';
import { Body2Font, CaptionFont } from '../../../../../../themes/fonts';
import { THEME } from '../../../../../../themes';
import { ASSETS } from '../../../../../../assets';
import { hexToRgbA } from '../../../../../../utils/colors';

const ROW_SIZE = 40;

const Hidden = css`
  opacity: 0;
  transition: opacity 0.2s, height 0.5s ease-in-out 0.1s;
  height: 0;
`;

const getShowCss = (rows: number) => {
  return css`
    height: ${rows * ROW_SIZE}px;
    opacity: ${THEME.OPACITY.HIGHEST};
    transition: opacity 1s ease-in-out 0.2s, height 0.5s;
  `;
};

export const Content = styled.div<{ isLocked: boolean }>`
  transition: all 0.2s;
  padding: ${THEME.SPACING.MICRO} ${THEME.SPACING.TINY};
  border-radius: ${THEME.RADIUS.TIDY};
  display: flex;
  align-items: center;
  width: 260px;
  cursor: pointer;
  filter: ${({ isLocked }) => isLocked ? 'blur(5px)' : undefined};
  &:hover svg {
    display: inline;;
  };
`;

export const Container = styled.div`
  display: block;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  gap: 10px;
`;

export const Text = styled.div`
  ${Body2Font};
  font-weight: 500;
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  padding-right: ${THEME.SPACING.MEDIUM};
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconWrapper = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmailIcon = styled(ASSETS.MAIL_COMPONENT)`
  width: 24px;
  height: 24px;
  fill: ${THEME.COLORS.PRIMARY_DARK}
`;

export const PhoneIcon = styled(ASSETS.NUMBER_COMPONENT)`
  width: 15px;
  height: 20px;
  fill: ${THEME.COLORS.PRIMARY_DARK}
`;

export const CopyIcon = styled(ASSETS.COPY_TEXT)`
  display: none;
  width: 16px;
  height: 16px;
  fill: ${THEME.COLORS.ACCENT};
`;

export const AllButtonContainer = styled.div`
  margin-top: ${THEME.SPACING.BASE};
`;

export const AllContactsHideContainer = styled.div<{isDisplayShow: boolean, rows: number}>`
  ${({ isDisplayShow, rows }) => {
    if (isDisplayShow) {
      return getShowCss(rows);
    }
    
    return Hidden;
  }}
`;

export const CopiedChip = styled.div`
  ${CaptionFont};
  padding: ${THEME.SPACING.MICRO};
  color: ${THEME.COLORS.ACCENT};
  border-radius: ${THEME.RADIUS.CIRCLE};
  background-color: ${hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.GHOST)};
`;
