import { useMemo } from 'react';
import { PositionSelectionGroupItemType } from '../../../modules/application/components/position-selection-group/position-selection-group.enums';
import {
  SelectionGroup,
  SelectionGroupItemContent,
  SelectionGroupWrapper,
  StyledSelectionGroupItem,
} from './selection-group-switch.styled';

const SelectionGroupSwitch = ({
  option1,
  option2,
  changeHandler,
  selectedValueId,
}: SelectionGroupSwitchProps): JSX.Element => {
  const selectedIndex = useMemo(() => {
    return option1.id === selectedValueId ? 0 : 1;
  }, [option1.id, selectedValueId]);

  const toggleSelectedIndex = () => {
    const selectedValue = option1.id === selectedValueId ? option2 : option1;
    changeHandler(selectedValue.id);
  };

  return (
    <SelectionGroupWrapper onClick={ toggleSelectedIndex }>
      <SelectionGroup defaultSelectedIndex={ selectedIndex }>
        <StyledSelectionGroupItem
          selectedIndex={ selectedIndex }
          value={ PositionSelectionGroupItemType.Overview }
        >
          <SelectionGroupItemContent>
            { option1.icon && <option1.icon /> }
            <div>{ option1.title }</div>
          </SelectionGroupItemContent>
        </StyledSelectionGroupItem>
        <StyledSelectionGroupItem
          selectedIndex={ selectedIndex }
          value={ PositionSelectionGroupItemType.Matches }
        >
          <SelectionGroupItemContent>
            { option2.icon && <option2.icon /> }
            <div>{ option2.title }</div>
          </SelectionGroupItemContent>
        </StyledSelectionGroupItem>
      </SelectionGroup>
    </SelectionGroupWrapper>
  );
};

export default SelectionGroupSwitch;
