import { ApiError } from '../../../../../consts/api';
import { positionQuery } from '../position.toolkit-api';

type UseSkipMatchTuneMutationType = [
  (positionId: string) => void,
  AppSelectorType<void, ApiError>
];

export const useSkipMatchTuneMutation = (): UseSkipMatchTuneMutationType => {
  const [
    skipMatchTune,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = positionQuery.useSkipMatchTuneMutation();

  const meta = {
    isLoading, isError, error: error as ApiError, isSuccess, data, resetState: reset,
  };

  return [
    skipMatchTune,
    { ...meta },
  ];
};
