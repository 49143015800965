import styled, { css } from 'styled-components';
import { THEME } from '../../../../themes';
import {
  Body2Font,
  Title3Font,
  CaptionFont,
} from '../../../../themes/fonts';
import { ASSETS } from '../../../../assets';

const getDropFileOuterWrapper = (
  fileUploadedSuccessfully:boolean,
  isDragOver:boolean,
  isDragReject: boolean,
) => {
  if (fileUploadedSuccessfully) {
    return `2px solid ${THEME.COLORS.ACCENT}`;
  }

  if (isDragOver && !isDragReject) {
    return `2px dashed ${THEME.COLORS_RGB.PRIMARY_DARK_LOW}`;
  }

  if (isDragReject) {
    return `2px dashed ${THEME.COLORS.ALERT}`;
  }

  return `2px dashed ${THEME.COLORS_RGB.PRIMARY_DARK_DIVIDER}`;
};

export const DropFileOuterWrapper = styled.div<{
  fileUploadedSuccessfully: boolean,
  isDragOver: boolean,
  isDragReject: boolean,
  }>`
  height: 112px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${THEME.RADIUS.CURVY};
  background-color: ${THEME.COLORS.PURE_WHITE};
  margin-top: ${THEME.SPACING.BASE};
  outline: ${({
    fileUploadedSuccessfully,
    isDragOver,
    isDragReject,
  }) => getDropFileOuterWrapper(
    fileUploadedSuccessfully,
    isDragOver,
    isDragReject,
  )};
`;

export const DragDropTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  text-align: left;
  flex: 1;
  margin-right: ${THEME.SPACING.GIANT};
`;

export const DragDropFileTitleText = styled.div`
  ${Title3Font};
  font-weight: 500;
  line-height: 1.5;
`;

export const DragDropFileDescriptionText = styled.div`
  ${Body2Font};
  opacity: ${THEME.OPACITY.MED};
`;

export const UploadIconContainer = styled.div`
  height: 52px;
  width: 52px;
  color: ${THEME.COLORS.ACCENT};
  font-size: 24px;
  line-height: 24px;
  border-radius: 50%;
  background-color: ${THEME.COLORS.PURE_WHITE};
  float: left;
  margin-left: ${THEME.SPACING.BASE};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DropFileInnerWrapper = styled.div<{fileUploadedSuccessfully: boolean}>`
  height: 95px;
  width: 624px;
  border-radius: ${THEME.RADIUS.ROUND};
  background-color: ${({ fileUploadedSuccessfully }) => fileUploadedSuccessfully ?
    THEME.COLORS_RGB.ACCENT_DIVIDER : THEME.COLORS.LIGHT_GREY};
  position: absolute;
  bottom: ${THEME.SPACING.TINY};
  left: ${THEME.SPACING.TINY};
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.TINO};
`;

export const Remove = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.ACCENT};
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
`;

export const ErrorDescription = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.ALERT};
  text-align: left;
  position: absolute;
  top: 118px;
  left: 5px;
`;

const IconStyle = css`
  width: 22px;
  fill: ${THEME.COLORS.ACCENT};
  position: absolute;
`;

export const UploadIcon = styled(ASSETS.ARROW_BACK_COMPONENT)`
  ${IconStyle};
  transform: rotate(-90deg);
`;

export const UploadedIcon = styled(ASSETS.V_SIGN)`
  ${IconStyle};
`;
