import styled, { css, keyframes } from 'styled-components';
import { ASSETS } from '../../../../assets';
import { THEME } from '../../../../themes';
import {
  Subtitle1Font,
  CaptionFont,
  ButtonFont,
} from '../../../../themes/fonts';

const shakeHorizontal = keyframes`
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
`;

export const LetsGoButtonWrapper = styled.div<{isLoading: boolean}>`
  margin-top: ${THEME.SPACING.TINY};
  opacity: ${({ isLoading }) => isLoading ? THEME.OPACITY.LOW : THEME.OPACITY.HIGHEST};
  pointer-events: ${({ isLoading }) => isLoading ? 'none' : ''};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  min-height: 500px;
`;

export const Background = styled.div`
  background-image: url(${ASSETS.REGISTRATION_PAGE_BACKGROUND});
  background-position: 50% -40px;
  background-size: 100vw;
  background-repeat: no-repeat;
  filter: blur(13px);
  mix-blend-mode: normal;
  position: absolute;
  width: 100vw;
  height: calc(100vh - 80px);
  z-index: -1;
  opacity: ${THEME.OPACITY.MED};
`;

export const ContinueWithGoogleContainer = styled.div`
  ${Subtitle1Font};
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  padding: 20px;
  border-radius: ${THEME.RADIUS.LOOPY};
  box-shadow: 0 12px 24px 0 ${THEME.COLORS_RGB.BLACK_DIVIDER};
  position: relative;
  background-color: ${THEME.COLORS.PURE_WHITE};
  :before {
    position: absolute;
    left: 14px;
    top: calc(50% - 14px);
    content: '';
    background: url(${ASSETS.GOOGLE_LOGO}) no-repeat center center;
    background-size: contain;
    width: 28px;
    height: 28px;
  }
`;

export const PageContentsWrapper = styled.div`
  margin-top: ${THEME.SPACING.GRAND};
  width: 400px;
`;

export const EmailIcon = styled.div`
  position: absolute;
  top: 33px;
  left: 0;
  width: 53px;
  height: 53px;
  border-radius: ${THEME.RADIUS.ROUNDISH};
  background:url(${ASSETS.MAIL_ALT}) no-repeat ${THEME.SPACING.MEDIUM};
  background-size: 24px;
  z-index: 1;
`;

export const PasswordIcon = styled.div`
  position: absolute;
  top: 9px;
  left: 0;
  width: 53px;
  height: 53px;
  border-radius: ${THEME.RADIUS.ROUNDISH};
  background:url(${ASSETS.SECRET_ICON}) no-repeat ${THEME.SPACING.MEDIUM};
  background-size: 24px;
  z-index: 1;
`;

export const InputWrapper = styled.div`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill::first-line,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${THEME.COLORS_RGB.GHOST_GRAY} inset !important;
    -webkit-text-fill-color: ${THEME.COLORS_RGB.CONTENT_PRIMARY_MED} !important;
  }
`;

export const WorkEmailInput = styled.input<{isError: boolean}>`
  ${Subtitle1Font};
  font-weight: normal;
  color: ${THEME.COLORS_RGB.CONTENT_PRIMARY_MED};
  width: 100%;
  margin-top: ${THEME.SPACING.LOOSE};
  background-color: ${THEME.COLORS_RGB.GHOST_GRAY};
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.BASE} ${THEME.SPACING.BASE} ${THEME.SPACING.GRAND};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  border: solid 1px ${({ isError }) => isError ? THEME.COLORS.ALERT : THEME.COLORS_RGB.PRIMARY_DIVIDER};
  caret-color: ${THEME.COLORS.ACCENT};
  position: relative;
  :focus {
    outline: none;
  }
  animation: ${({ isError }) => (isError ?
    css`${shakeHorizontal} 0.7s cubic-bezier(0.175, 0.885, 0.320, 1.275) both` : '')} ;
`;

export const PasswordInputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const PasswordInput = styled.input<{isError: boolean}>`
  ${Subtitle1Font};
  font-weight: normal;
  width: 100%;
  margin-top: ${THEME.SPACING.TINY};
  background-color: ${THEME.COLORS_RGB.GHOST_GRAY};
  color: ${THEME.COLORS_RGB.CONTENT_PRIMARY_MED};
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.BASE} ${THEME.SPACING.BASE} ${THEME.SPACING.GRAND};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  border: solid 1px ${({ isError }) => isError ? THEME.COLORS.ALERT : THEME.COLORS_RGB.PRIMARY_DIVIDER};
  caret-color: ${THEME.COLORS.ACCENT};
  :focus {
    outline: none;
  }
  :before {

  }
  animation: ${({ isError }) => (isError ?
    css`${shakeHorizontal} 0.7s cubic-bezier(0.175, 0.885, 0.320, 1.275) both` : '')} ;
`;

export const TermsOfUse = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  margin-top: -${THEME.SPACING.BASE};
  opacity: ${THEME.OPACITY.MED};
  text-align: center;
`;

export const ValidationError = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  color: ${THEME.COLORS.ALERT};
  margin-top: ${THEME.SPACING.TINY};
`;

export const Wrapper = styled.div`
  text-align: center;
  flex-direction: column;
`;

export const AlreadyHaveAccountText = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const LoginButton = styled.div`
  ${ButtonFont};
  width: 48px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  justify-self: center;
  cursor: pointer;
  margin-top: ${THEME.SPACING.TINY};
  color: ${THEME.COLORS.ACCENT};
`;
