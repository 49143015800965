import styled from 'styled-components';
import { THEME } from '../../../themes';
import { ASSETS } from '../../../assets';

export const HamburgerIcon = styled(ASSETS.FILTER_HAMBURGER_ICON)`
  width: 12px;
  height: 10px;
  fill: ${THEME.COLORS.PRIMARY};
  margin-right: ${THEME.SPACING.TINY};
  transition: fill 0.2s ease;
`;

const ContainerHoverActiveStyle = `
    box-shadow: 0 8px 16px 0 rgba(217, 49, 128, 0.08);
    outline: solid 3px rgba(48, 37, 133, 0.12);
    background-color: ${THEME.COLORS.SURFACE_PRIMARY};
    
    & ${HamburgerIcon} {
    fill: ${THEME.COLORS.ACCENT};
`;

export const Container = styled.div<{isShowUserMenu: boolean}>`
  display: flex;
  width: 76px;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: ${THEME.SPACING.TINY} ${THEME.SPACING.TINY} ${THEME.SPACING.TINY} ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.LOOPY};
  outline: solid 1px rgba(1, 10, 20, 0.08);
  background-color: ${THEME.COLORS.PURE_WHITE};
  transition: outline 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease;
  ${(props) => props.isShowUserMenu ? ContainerHoverActiveStyle : null}
  cursor: pointer;
  :hover{
    ${ContainerHoverActiveStyle};
  }
`;
