import { DecoratorElementProps } from '../../decorators';
import { Brackets } from '../mention/mention.styled';
import { MentionPlaceholderContainer } from './mention-placeholder.styled';

export const MentionPlaceholder = ({ children }: DecoratorElementProps): JSX.Element => (
  <MentionPlaceholderContainer>
    <Brackets />
    {children}
  </MentionPlaceholderContainer>
);
