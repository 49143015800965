import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useCreatePositionJobDescription } from '../../../../store/slices/create-position-state/selectors/create-position-job-description.selector';
import { useOnboardingStoreMetadataSelector } from '../../../../store/slices/onboarding-state/selectors/onboarding-selectors.hooks';
import { isValidUrl } from '../../../../utils';
import STRING_KEYS from '../../../../language/keys';
import { PageTitleBold } from '../create-position-container/create-position-container.styled';
import { CreatePositionContainer } from '../create-position-container/create-position-container.component';
import { UploadFileContent } from '../upload-file-content/upload-file-content.component';
import { setJobDescriptionS3TempUrl, setJobDescriptionTempUrl } from '../../../../store/slices/create-position-state/create-position-state.toolkit-slice';
import { useGetStepInfoComponent } from '../../hooks/create-position-actions.hooks';
import { CREATE_POSITION_INPUT_ID } from '../../consts/input-ids.consts';
import {
  ErrorDescription,
  JobDescriptionUrlInput, OrChipWrapper,
  UploadJobDescriptionWrapper,
} from './create-position-description-step.styled';
import { JobDescriptionStepProps } from './create-position-description-step';

export const JobDescriptionStep = ({
  setContinueButtonEnable,
  setContinueButtonDisable,
  isOnboarding,
}: JobDescriptionStepProps): JSX.Element => {
  const { jobDescriptionTempUrls, jobDescriptionS3TempUrls } = useCreatePositionJobDescription();
  const [isLoading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const InfoComponent = useGetStepInfoComponent();

  const { t: translate } = useTranslation();
  const { isContinueButtonEnabled } = useOnboardingStoreMetadataSelector();

  const isJobDescriptionUrlError = !!jobDescriptionTempUrls && !isValidUrl(jobDescriptionTempUrls);
  const useCanNotContinue = (!jobDescriptionS3TempUrls && !jobDescriptionTempUrls) || isJobDescriptionUrlError;

  useEffect(() => {
    if (!useCanNotContinue && !isContinueButtonEnabled) {
      dispatch(setContinueButtonEnable());
    }

    if (useCanNotContinue && isContinueButtonEnabled) {
      dispatch(setContinueButtonDisable());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCanNotContinue]);

  const handleUploadedUrlChange = (newUrl: string) => {
    dispatch(setJobDescriptionS3TempUrl(newUrl));
  };

  const handleUpdateLoadingState = (newState: boolean) => {
    setLoading(newState);
  };

  const defineError = (): JSX.Element => {
    if (isJobDescriptionUrlError) {
      return (
        <ErrorDescription>
          {translate(STRING_KEYS.ONBOARDING.LINKEDIN_WRONG_FORMAT)}
        </ErrorDescription>
      );
    }

    return <></>;
  };

  const getPageTitle = () => (
    <Trans
      i18nKey={ STRING_KEYS.ONBOARDING.ONBOARDING_JOB_DESCRIPTION_TITLE }
    >
      <PageTitleBold />
    </Trans>
  );

  return (
    <>
      <CreatePositionContainer
        title={ getPageTitle() }
        info={ InfoComponent }
        isOnboarding={ isOnboarding }
      >
        <UploadJobDescriptionWrapper>
          <JobDescriptionUrlInput
            id={ CREATE_POSITION_INPUT_ID.JOB_DESCRIPTION }
            showIcon={ !jobDescriptionTempUrls }
            placeholder={
              translate(STRING_KEYS.ONBOARDING.ONBOARDING_UPLOAD_JOB_DESCRIPTION_PASTE_URL)
            }
            value={ jobDescriptionTempUrls }
            onChange={ (e) => dispatch(setJobDescriptionTempUrl(e.target.value)) }
            isError={ isJobDescriptionUrlError }
          />
          { defineError() }
          <OrChipWrapper>
            {translate(STRING_KEYS.OR)}:
          </OrChipWrapper>

          <UploadFileContent
            isLoading={ isLoading }
            onUpdateLoadingState={ handleUpdateLoadingState }
            onUploadedUrlChange={ handleUploadedUrlChange }
          />
        </UploadJobDescriptionWrapper>
      </CreatePositionContainer>
    </>
  );
};
