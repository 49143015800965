import { Container } from './role-item.styled';

type RoleItemProps = {
    role:RoleItem,
    onSelect: (value: RoleItem) => void,
    isSelected: boolean,
}

export const RoleItem = ({
  role,
  onSelect,
  isSelected,
}: RoleItemProps): JSX.Element => {
  return (
    <Container onClick={ () => {
      onSelect(role);
    } }
    isSelected={ isSelected }
    >
      {role.name}
    </Container>
  );
};
