import styled from 'styled-components';

export const Container = styled.div<{
  flex?: number,
  width?: string,
  }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: ${({ flex }) => flex ?? 'auto'};
  ${({ width }) => width ? `width: ${width}` : ''}
`;
