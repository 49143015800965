import {
  SyntheticEvent,
  useState,
  useEffect,
  KeyboardEvent,
  useRef,
  RefObject,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import STRING_KEYS from '../../../../language/keys';
import { useSendVerificationEmail, useVerifyEmail } from '../../hooks/onboarding-react-query.hooks';
import { CONFIG } from '../../../../config';
import { ANALYTICS_EVENTS, EVENT_GROUP, EVENT_TYPE } from '../../../../consts/analytics';
import { PAGE_NAMES } from '../../../../consts/pages';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { useAppState } from '../../../../store/selectors';
import VerificationInput from '../../components/verification-input/verification-input.component';
import { RudderStackDestination } from '../../../../services/rudderStackAnalyticsAgent';
import { TrackEventPayload } from '../../../../@types/analytics/eventPayload';
import { useOnboardingStoreMetadataSelector } from '../../../../store/slices/onboarding-state/selectors/onboarding-selectors.hooks';
import { CREATE_POSITION_ANALYTICS_EVENTS } from '../../../create-position/consts/position-analytics.consts';
import {
  Container,
  Wrapper,
  PageTitle,
} from '../../../create-position/components/create-position-container/create-position-container.styled';
import {
  setOnboardingContinueButtonHide, setOnboardingLoaderButtonHide,
  setOnboardingLoaderButtonShow,
  setOnboardingSkipButtonHide,
} from '../../../../store/slices/onboarding-state/onboarding-state.toolkit-slice';
import {
  PageDescription,
  CodeEmailedTitle,
  VerificationRectContainer,
  ErrorText,
  LinkToMail,
  NoLuckText,
  HumanHelpTextWrapper,
  SendingCodeText,
  TransitionWrapper,
  ResendCode, HelperTextWrapper,
} from './email-verification.page.styled';

const SUPPORT_BUTTON_COMPONENT_NAME = 'support';

type EmailVerificationScreenProps = {
  onScreenCompleted: () => void,
}

const EmailVerificationScreen = ({
  onScreenCompleted,
}:EmailVerificationScreenProps): JSX.Element => {
  const { showButtonLoader } = useOnboardingStoreMetadataSelector();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setOnboardingSkipButtonHide());
    dispatch(setOnboardingContinueButtonHide());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [verificationInputs, setVerificationInputs] = useState([
    {
      key: 'ver1',
      value: '',
      ref: useRef<HTMLInputElement>(null),
    },
    {
      key: 'ver2',
      value: '',
      ref: useRef<HTMLInputElement>(null),
    },
    {
      key: 'ver3',
      value: '',
      ref: useRef<HTMLInputElement>(null),
    },
    {
      key: 'ver4',
      value: '',
      ref: useRef<HTMLInputElement>(null),
    },
    {
      key: 'ver5',
      value: '',
      ref: useRef<HTMLInputElement>(null),
    },
  ]);

  const [isCodeResendRequested, setIsCodeResendRequested] = useState<boolean>(false);
  const { t: translate } = useTranslation();
  const {
    isLoading: isVerificationLoading,
    isError: isVerificationError,
    mutate: verifyEmailCode,
  } = useVerifyEmail(onScreenCompleted);

  const {
    mutate: sendVerificationEmail,
  } = useSendVerificationEmail();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const { currentPageId, referrerPageId } = useAppState();

  useEffect(() => {
    if (isVerificationLoading && !showButtonLoader) {
      dispatch(setOnboardingLoaderButtonShow());
    }

    if (!isVerificationLoading && showButtonLoader) {
      dispatch(setOnboardingLoaderButtonHide());
    }
  }, [dispatch, isVerificationLoading, showButtonLoader]);

  useEffect(() => {
    if (isVerificationError) {
      const intercomTrackEventPayload = {
        eventName: ANALYTICS_EVENTS.INTERCOM_TRIGGER_EMAIL_VERIFICATION_FAILED,
        event_type: EVENT_TYPE.INTERCOM_TRIGGER,
        pageName: PAGE_NAMES.EMAIL_VERIFICATION_ONBOARDING,
        pageViewId: currentPageId,
        pageReferrerId: referrerPageId,
      };
      // send only to the INTERCOM integration
      analyticsTrackEvent(intercomTrackEventPayload, [RudderStackDestination.INTERCOM]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerificationError]);

  const handleInputChange = (event: SyntheticEvent, index: number) => {
    const { value } = event.target as HTMLInputElement;

    if (index < verificationInputs.length - 1 && value !== '') {
      const nextInput = verificationInputs[index + 1].ref.current as HTMLInputElement;

      nextInput.focus();
    }

    setVerificationInputs(verificationInputs.map((verInput, verInputIndex) => {
      if (verInputIndex === index) {
        return {
          ...verInput,
          value,
        };
      }

      return verInput;
    }));

    const currentCode =
     `${(verificationInputs).map((verInput) => verInput.value).join('')}${value}`;

    if (value && currentCode.length === verificationInputs.length) {
      verificationInputs[verificationInputs.length - 1].ref.current?.blur();

      verifyEmailCode({ code: currentCode });
    }
  };

  useEffect(() => {
    sendVerificationEmail();
  }, [sendVerificationEmail]);

  const handleKeyPress = (event: KeyboardEvent, index: number) => {
    if (event.key === 'Backspace') {
      if (index > 0 && verificationInputs[index].value === '') {
        const prevInput = verificationInputs[index - 1].ref.current as HTMLInputElement;

        prevInput.focus();
      }
    }
  };

  const handleResendCodeRequest = () => {
    setIsCodeResendRequested(true);

    sendVerificationEmail();
  };

  const handleRequestSupportClicked = () => {
    const trackEventPayload = {
      eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_SUPPORT_BUTTON_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.EMAIL_VERIFICATION_ONBOARDING,
      pageViewId: currentPageId,
      pageReferrerId: referrerPageId,
      componentName: SUPPORT_BUTTON_COMPONENT_NAME,
    } as TrackEventPayload;

    analyticsTrackEvent(trackEventPayload);
  };

  const defineHelperText = () => {
    if (isCodeResendRequested) {
      return (
        <>
          <SendingCodeText>
            {
              translate(STRING_KEYS.ONBOARDING.ONBOARDING_VERIFICATION_CODE_SENT_AGAIN)
            }
          </SendingCodeText>
          <HumanHelpTextWrapper>
            <NoLuckText>
              {
                translate(STRING_KEYS.ONBOARDING.ONBOARDING_VERIFICATION_CODE_NO_LUCK)
              }
            </NoLuckText>
            <LinkToMail
              rel="noopener noreferrer"
              target="_blank"
              onClick={ handleRequestSupportClicked }
              href={
                `mailto:${CONFIG.SUPPORT_MAIL_ADDRESS}`
              }
            >
              {
                translate(STRING_KEYS.ONBOARDING.ONBOARDING_VERIFICATION_CODE_ASK_HUMAN_HELP)
              }
            </LinkToMail>
          </HumanHelpTextWrapper>
        </>
      );
    }

    return (
      <ResendCode onClick={ handleResendCodeRequest }>
        {translate(STRING_KEYS.ONBOARDING.ONBOARDING_VERIFICATION_CODE_NOT_RECEIVED)}
      </ResendCode>
    );
  };

  const defineContent = () => {
    return (
      <HelperTextWrapper>
        {isVerificationError && (
          <ErrorText>
            {translate(STRING_KEYS.ONBOARDING.ONBOARDING_VERIFICATION_CODE_VERIFICATION_FAILED)}
          </ErrorText>
        )}
        {defineHelperText()}
      </HelperTextWrapper>
    );
  };

  const handlePasteEvent = (pastedData: string, inputRef: RefObject<HTMLInputElement>) => {
    const pasted = pastedData.split('').slice(0, verificationInputs.length);

    setVerificationInputs(verificationInputs.map((verInput, verInputIndex) => {
      return {
        ...verInput,
        value: pasted[verInputIndex],
      };
    }));

    if (pasted.length === verificationInputs.length) {
      inputRef.current?.blur();

      verifyEmailCode({ code: pasted.join('') });
    }
  };

  return (
    <Container topPadding>
      <Wrapper>
        <PageTitle>
          { translate(STRING_KEYS.ONBOARDING.ONBOARDING_VERIFICATION_TITLE) }
        </PageTitle>
        <TransitionWrapper>
          <PageDescription>
            { translate(STRING_KEYS.ONBOARDING.ONBOARDING_VERIFICATION_DESCRIPTION) }
          </PageDescription>
          <CodeEmailedTitle>
            { translate(STRING_KEYS.ONBOARDING.ONBOARDING_VERIFICATION_CODE_EMAILED) }
          </CodeEmailedTitle>
          <VerificationRectContainer animationToggle={ isVerificationError }>
            {
              verificationInputs.map(({ key, ref, value }, index) => (
                <VerificationInput
                  key={ key }
                  inputRef={ ref }
                  onPaste={ (event) => {
                    handlePasteEvent(event.clipboardData?.getData('text/plain'), ref);
                  } }
                  value={ value }
                  onChange={ (event: SyntheticEvent) => handleInputChange(event, index) }
                  onKeyDown={ (event: KeyboardEvent) => handleKeyPress(event, index) }
                />
              ))
            }
          </VerificationRectContainer>
          { defineContent() }
        </TransitionWrapper>
      </Wrapper>
    </Container>
  );
};

export default EmailVerificationScreen;
