import { useDispatch } from 'react-redux';
import { userQuery } from '../user.toolkit-api';

export const useDeleteAccountAcknowledgementById = (): (acknowledgementId: string) => void => {
  const dispatch = useDispatch();

  return (acknowledgementId) => {
    dispatch(
      userQuery.util.updateQueryData('getMyUser', undefined, (draft) => {
        const acknowledgements = draft.acknowledgements.filter((acknowledgement) => acknowledgement.id !== acknowledgementId);
        Object.assign(draft, { acknowledgements });
      }),
    );
  };
};
