import { useEffect } from 'react';
import log from 'loglevel';
import { useLocation } from 'react-router';
import { useAppState } from '../store/selectors';
import { positionQuery } from '../store/rtk-query/apis/position/position.toolkit-api';
import { matchQuery } from '../store/rtk-query/apis/match/match.toolkit-api';
import { parseUrlValueByKey } from '../utils';
import { AppRouting } from '../services/appRoutingResolver';

const logger = log.getLogger('USE_SOCKET_CONNECTOR_LISTENER');

export const useSocketConnectorListener = (): void => {
  const location = useLocation();
  const { connectedSocket } = useAppState();
  const [getPositions, { error: getPositionsError, isError: isGetPositionsError }] = positionQuery.useLazyGetOpenPositionsQuery();
  const [getPosition, { error: getPositionError, isError: isGetPositionError }] = positionQuery.useLazyGetPositionByIdQuery();
  const [
    getPendingReviewMatches,
    { error: getPendingReviewMatchesError, isError: isGetPendingReviewMatchesError },
  ] = matchQuery.useLazyGetPendingReviewMatchesQuery();

  useEffect(() => {
    if (connectedSocket.talentAcquisition) {
      logger.log('Fetch positions again because talent acquisition socket was connected', { connectedSocket });
      getPositions();
      const positionId = parseUrlValueByKey(location.pathname, AppRouting.POSITIONS_PATH_PARAM_KEY);
      if (positionId) {
        getPosition(positionId);
        getPendingReviewMatches({ positionId });
        logger.log('Fetch position data again because talent acquisition socket was connected', { connectedSocket });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedSocket.talentAcquisition]);

  useEffect(() => {
    if (isGetPositionsError) {
      logger.error('Error while fetching user positions!', getPositionsError, { connectedSocket });
    }
    if (isGetPositionError) {
      logger.error('Error while fetching user position!', getPositionError, { connectedSocket });
    }
    if (isGetPendingReviewMatchesError) {
      logger.error('Error while fetching user pending review matches!', getPendingReviewMatchesError, { connectedSocket });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetPositionsError]);
};
