import { Trans, useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import BasePositionAssistant from '../base-position-assistant/base-position-assistant.component';
import { CONFIG } from '../../../../../config';
import { PositionAssistantProps } from '../base-position-assistant/base-position-assistant';
import { IncreaseReplayRateIcon, LearnHowLink } from './improve-outreach-engagement.styled';

const IMPROVE_OUTREACH_ENGAGEMENT_RATE = 200;

const ImproveOutreachEngagement = ({
  actionHandler,
}: PositionAssistantProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const Description = (
    <Trans
      i18nKey={ STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.IMPROVE_OUTREACH_ENGAGEMENT.DESCRIPTION }
    >
      <LearnHowLink
        href={ CONFIG.HOW_TO_INCREASE_REPLAY_RATE_URL }
        target="_blank"
      />
    </Trans>
  );

  return (
    <BasePositionAssistant
      title={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.IMPROVE_OUTREACH_ENGAGEMENT.TITLE) }
      description={ Description }
      Icon={ IncreaseReplayRateIcon }
      rate={ IMPROVE_OUTREACH_ENGAGEMENT_RATE }
      action={ {
        title: translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.IMPROVE_OUTREACH_ENGAGEMENT.ACTION_TITLE),
        handler: actionHandler,
      } }
    />
  );
};

export default ImproveOutreachEngagement;
