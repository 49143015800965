import { NODE_ENVIRONMENTS } from '../consts/environment';
import { AppModeEnum } from '../enums/app-mode.enum';
import { getSubDomain } from './url';
/**
 * check if we are in a production environment
 * @returns bool
 */
export const isProduction = (): boolean => {
  return process.env.NODE_ENV === NODE_ENVIRONMENTS.PRODUCTION;
};

/**
 * Get the minimum number of parts in the domain
 * @returns number - minimum size of domain with its subdomains
 */
export const getSubDomainMinimumLength = (): number => {
  if (process.env.REACT_APP_SUB_DOMAIN_MIN_LENGTH) {
    return parseInt(process.env.REACT_APP_SUB_DOMAIN_MIN_LENGTH);
  }

  return 3;
};

/**
 * @returns obj with app information (to print in console)
 */
export const getAppInformation = (): AppInformation => {
  return {
    ENVIRONMENT: `${process.env.REACT_APP_VERSION} ${process.env.NODE_ENV}`,
    BASE_URL: process.env.REACT_APP_BASE_URL,
  };
};

/**
 * get current app-mode
 * @returns current app-mode
 */
export const getAppMode = (): AppModeEnum => {
  if (getSubDomain(window.location.hostname) === process.env.REACT_APP_GOOGLE_EMAIL_OAUTH_SUBDOMAIN) {
    return AppModeEnum.GoogleEmailOauth;
  }

  if (getSubDomain(window.location.hostname) === process.env.REACT_APP_MICROSOFT_EMAIL_OAUTH_SUBDOMAIN) {
    return AppModeEnum.MicrosoftEmailOauth;
  }

  return (process.env.REACT_APP_MODE || AppModeEnum.Default) as AppModeEnum;
};
