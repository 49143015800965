import Tooltip from '../../../../../../components/tooltip/tooltip.component';
import {
  Container, QuestionIcon, Title, TooltipWrapper,
} from './base-funnel-tag.styled';

const BaseFunnelTag = ({ emoji, text, infoText }: BaseFunnelTagProps): JSX.Element => {
  return (
    <Container>
      { emoji }
      <Title>{ text }</Title>
      { infoText && (
        <Tooltip
          content={ infoText }
        >
          <TooltipWrapper>
            <QuestionIcon />
          </TooltipWrapper>
        </Tooltip>
      )}
    </Container>
  );
};

export default BaseFunnelTag;
