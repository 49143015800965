export enum MatchTuneStep {
  Locations = 'LOCATIONS',
  LevelsOfExperience = 'LEVELS_OF_EXPERIENCE',
  IndustryExperience = 'INDUSTRY_EXPERIENCE',
  CompanySize = 'COMPANY_SIZE',
  Skills = 'SKILLS',
  JobTitle = 'JOB_TITLE',
  Education = 'EDUCATION',
  PositionFeedback = 'POSITION_FEEDBACK',
}
