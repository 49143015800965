import styled from 'styled-components';
import CheckboxButtonGroupContainer from '../Common/checkbox-button-group/checkbox-button-group.component';
import { Container as CheckboxItemContainer } from '../Common/checkbox-button-group/checkbox-item/checkbox-item.styled';
import { THEME } from '../../themes';

export const StyledCheckboxButtonGroupContainer = styled(CheckboxButtonGroupContainer)`
  & ${CheckboxItemContainer} {
    background-color: ${THEME.COLORS.PURE_WHITE};
  }
` as typeof CheckboxButtonGroupContainer;
