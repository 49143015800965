import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';
import {
  Subtitle2Font,
} from '../../../../themes/fonts';

export const SuggestionsContainer = styled.div`
  margin-top: ${THEME.SPACING.BASE};
  display: flex;
  flex-direction: column;
  position: relative;
  gap: ${THEME.SPACING.TINY};
  margin-bottom: ${THEME.SPACING.GIANT};
`;

export const CheckedIcon = styled(ASSETS.CHECKED_GHOST)`
  fill: ${THEME.COLORS.SUCCESS};
  width: 13.5px;
  height: 9.8px;
  margin: 0 10px 0 0;
`;

export const Suggestion = styled.span`
  ${Subtitle2Font};
  line-height: 1.71;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const SuggestionsItem = styled.span`
  display: flex;
  align-items: center;
`;
