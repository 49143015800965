import { useEffect, useState } from 'react';

export default function useInterval(callback: () => void, isRunning: boolean, intervalMs: number): void {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  if (intervalId && !isRunning) {
    clearInterval(intervalId);
    setIntervalId(null);
  }

  useEffect(
    () => {
      if (intervalId || !isRunning) {
        return undefined;
      }

      const id = setInterval(callback, intervalMs);
      setIntervalId(id);

      return () => {
        if (id) {
          clearInterval(id);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRunning]);
}
