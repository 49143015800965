import styled, { css } from 'styled-components';
import { THEME } from '../../../../../../themes';
import {
  Body2Font, CaptionFont, HeadlineFont, Subtitle2Font,
} from '../../../../../../themes/fonts';
import { ASSETS } from '../../../../../../assets';
import { LocationMenuItemDirection, LocationMenuItemSize } from './location-menu-item.enums';

export const Container = styled.div<{isSelected: boolean, size: LocationMenuItemSize}>`
  ${({ size }) => size === LocationMenuItemSize.Small ? Subtitle2Font : HeadlineFont};
  height: ${({ size }) => size === LocationMenuItemSize.Small ? 48 : 64}px;
  padding-left: ${THEME.SPACING.TINO};
  padding-right: ${THEME.SPACING.TINO};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  color: ${({ color, isSelected }) => isSelected ? THEME.COLORS.ACCENT : color};
  display: flex;
  gap: ${THEME.SPACING.TINO};
  align-items: center;
  transition: all ease-in .2s;
  cursor: pointer;
  flex-shrink: 0;
  ${({ isSelected }) => {
    if (isSelected) {
      return {
        pointerEvents: 'none',
      };
    }

    return {};
  }}
`;

export const Description = styled.div<{size: LocationMenuItemSize}>`
  opacity: 0.6;
  ${({ size }) => size === LocationMenuItemSize.Large ? Body2Font : CaptionFont};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.25px;
`;

export const Label = styled.div``;

export const Wrapper = styled.div<{ direction: LocationMenuItemDirection }>`
  display: flex;
  align-items: ${({ direction }) => direction === LocationMenuItemDirection.Row ? 'center' : 'start'};
  flex-direction: ${({ direction }) => direction === LocationMenuItemDirection.Row ? 'row' : 'column'};
  gap: ${({ direction }) => direction === LocationMenuItemDirection.Row ? THEME.SPACING.TINY : THEME.SPACING.MICRO};
`;

export const InfoWrapper = styled.div<{ direction: LocationMenuItemDirection }>`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
`;

export const Icon = styled.img`
  width: 32px;
  height: auto;
`;

const IconStyle = css<{size: LocationMenuItemSize}>`
  width: ${({ size }) => size === LocationMenuItemSize.Small ? 10 : 12}px;
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;

export const RegionIcon = styled(ASSETS.MAP)<{size: LocationMenuItemSize}>`
  ${IconStyle};
`;

export const ExactLocationIcon = styled(ASSETS.LOCATION_PIN)<{size: LocationMenuItemSize}>`
  ${IconStyle};
`;
