import { useTranslation } from 'react-i18next';
import InsightsCard from '../base-insights-card/insights-card.component';
import { InsightsCardTitle } from '../base-insights-card/insights-card.styled';
import { AppRouting } from '../../../../../../services/appRoutingResolver';
import { CONFIG } from '../../../../../../config';
import STRING_KEYS from '../../../../../../language/keys';
import {
  ExternalLinkIcon,
  LikeIcon,
} from './acceptance-rate-insight-card.styled';

const AcceptanceRateInsightCard = ({ acceptanceRate }: AcceptanceRateInsightCardProps):JSX.Element => {
  const { t: translate } = useTranslation();

  const InsightsTitle: JSX.Element = (
    <>
      <LikeIcon />
      <InsightsCardTitle>{ translate(STRING_KEYS.DAILY_LIMIT.INSIGHTS.ACCEPTANCE_RATE_MATCHES_TITLE) }</InsightsCardTitle>
    </>
  );

  const onClickHowToImprove = () => {
    if (CONFIG.HOW_TO_GETTING_MORE_MATCHES_URL) {
      AppRouting.openInNewTab(CONFIG.HOW_TO_GETTING_MORE_MATCHES_URL);
    }
  };

  return (
    <InsightsCard
      title={ InsightsTitle }
      insightValueBold={ `${acceptanceRate}%` }
      actionTitle={ translate(STRING_KEYS.DAILY_LIMIT.INSIGHTS.ACCEPTANCE_RATE_ACTION_TITLE) }
      ActionIcon={ <ExternalLinkIcon /> }
      actionHandler={ onClickHowToImprove }
    />
  );
};

export default AcceptanceRateInsightCard;
