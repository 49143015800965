import styled from 'styled-components';
import BaseSelectionGroup from '../base-selection-group/base-selection-group.component';
import { THEME } from '../../../themes';
import SelectionGroupItem from '../base-selection-group/selection-group-item/selection-group-item.component';

export const SelectionGroupWrapper = styled.div`
  width: fit-content;
  cursor: pointer;
`;

export const SelectionGroup = styled(BaseSelectionGroup)`
  height: 48px;
  background-color: ${THEME.COLORS.LIGHT_GREY};
  width: fit-content;
  box-shadow: none;
`;

export const StyledSelectionGroupItem = styled(SelectionGroupItem)<{ selectedIndex?: number }>`
  width: 88px;
  color: ${THEME.COLORS.PURE_WHITE};

  &:first-child::before {
    background-color: ${({ selectedIndex }) => selectedIndex === 0 ? THEME.COLORS.PRIMARY : THEME.COLORS.SECONDARY};
  }
`;

export const SelectionGroupItemContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${THEME.SPACING.MICRO}
`;
