import styled, { css } from 'styled-components';
import { THEME } from '../../../../../themes';
import {
  ButtonFont, CaptionFont, OverlineFont, Subtitle1Font,
} from '../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../utils/colors';
import { ASSETS } from '../../../../../assets';

const ActiveContainerStyle = css`
  outline: 2px solid ${hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.DIVIDER)};
  background-color: ${hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.GHOST)};
`;

const ContainerStyle = css<{ disabled: boolean }>`
  outline: 1px solid ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.GHOST)};
  background-color: ${THEME.COLORS.LIGHT_GREY};

  ${({ disabled }) => disabled ? '' : `
    :hover {
      outline: 2px solid ${THEME.COLORS.SECONDARY};
    }`}
`;

const DisabledStyle = css<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => disabled ? THEME.OPACITY.LOW :  undefined};
`;

export const Container = styled.div<{ isSelected: boolean, disabled: boolean }>`
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.MEDIUM};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 308px;
  min-width: 240px;
  height: 204px;
  border-radius: ${THEME.RADIUS.ROUNDISH};
  gap: ${THEME.SPACING.MEDIUM};
  ${({ isSelected }) => isSelected ? ActiveContainerStyle : ContainerStyle};
  cursor: ${({ isSelected, disabled }) => isSelected || disabled ? undefined : 'pointer'};
  position: relative;
`;

export const IconContainer = styled.div<{ disabled?: boolean }>`
  align-items: center;
  display: flex;
  ${DisabledStyle};
  
  & svg {
    height: 24px;
  }
`;

export const InfoTextIconContainer = styled.div`
  align-items: center;
  display: flex;
  
  & svg {
    height: 12px;
    fill: ${THEME.COLORS.SECONDARY};
  }
`;

export const TopContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const BottomContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 40px;
`;

export const Title = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
  ${DisabledStyle};
`;

export const Subtitle = styled.div`
  ${CaptionFont};
  margin-top: ${THEME.SPACING.TINY};
  ${DisabledStyle};
`;

const BadgeStyle = css`
  color: ${THEME.COLORS.SECONDARY};
  background-color: ${hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.DIVIDER)};
`;

const DisabledBadgeStyle = css`
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  background-color: ${hexToRgbA(THEME.COLORS.CONTENT_PRIMARY, THEME.OPACITY.DIVIDER)};
`;

export const Badge = styled.div<{ disabled?: boolean }>`
  ${OverlineFont};
  position: absolute;
  top: ${THEME.SPACING.BASE};
  right: ${THEME.SPACING.BASE};
  padding: ${THEME.SPACING.NANO} ${THEME.SPACING.MICRO};
  ${({ disabled }) => disabled ? DisabledBadgeStyle : BadgeStyle};
  border-radius: ${THEME.RADIUS.TIDY};
  display: flex;
  gap: ${THEME.SPACING.MICRO};
`;

export const BadgeIconWrapper = styled.div<{ disabled?: boolean }>`
  height: fit-content;

  & svg {
    width: 8px;
    height: 8px;
  }
`;

export const ActiveIndicationContainer = styled.div`
  ${ButtonFont};
  line-height: 1.71;
  letter-spacing: 0.1px;
  text-transform: capitalize;
  display: flex;
  gap: ${THEME.SPACING.TINY};
  align-items: center;
  color: ${THEME.COLORS.SECONDARY};
`;

export const ActiveIndicationIcon = styled(ASSETS.V_SIGN)``;
