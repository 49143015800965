import { TextField } from '@mui/material';
import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { Subtitle2Font } from '../../../../../themes/fonts';

export const sx = {
  input: {
    backgroundColor: THEME.COLORS_RGB.GREY_MED,
    borderColor: THEME.COLORS_RGB.ACCENT_MED,
    borderRadius: THEME.RADIUS.ROUNDISH,
    fontFamily: 'Merriweather',
    fontSize: '16px',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.25',
    letterSpacing: 'normal',
    color: THEME.COLORS.PRIMARY_DARK,
  } as const,
  inputAdornment: {
    width: '24px',
    height: '24px',
  } as const,
  li: {
    transition: 'all .2s ease-in',
    '&:hover': {
      backgroundColor: `${THEME.COLORS.LIGHT_GREY} !important`,
    },
  } as const,
} as const;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 552px;
  position: relative;
`;

export const Input = styled(TextField)`
  background-color: ${THEME.COLORS_RGB.GREY_MED};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 2px solid ${THEME.COLORS_RGB.ACCENT_MED};
    border-radius: 12px;
  }, 
  & .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
  & .Mui-error .MuiOutlinedInput-notchedOutline {
    opacity: 0.6;
    border: 2px solid ${THEME.COLORS.ALERT};
    border-radius: 12px;
  },
`;

export const Error = styled.div`
  ${Subtitle2Font};
  position: absolute;
  top: 50px;
  left: 0;
  display: flex;
  width: 100%;
  text-align: left;
  color: ${THEME.COLORS.ALERT};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.25px;
  margin-top: ${THEME.SPACING.TINO};
`;
