import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';
import { OverlineFont } from '../../../../themes/fonts';

export const Container = styled.div`
  ${OverlineFont};
  color: ${THEME.COLORS.CONTENT_A};
  display: flex;
  gap: ${THEME.SPACING.TINY};
  cursor: pointer;
`;

export const GuidanceMessage = styled.div`
  opacity: ${THEME.OPACITY.MED};
  border-bottom: 1px dashed currentColor;
`;

export const GuidanceMessageIcon = styled(ASSETS.QUESTION_FILLED)`
  opacity: ${THEME.OPACITY.LOW};
  fill: currentColor;
`;
