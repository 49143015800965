import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { CaptionFont } from '../../../../../../themes/fonts';
import { ASSETS } from '../../../../../../assets';

export const Button = styled.div`
  cursor: pointer;
  display: flex;
  gap: 2px;
  padding: 4px;
  justify-content: start;
  align-items: center;
  text-align: left;
  ${CaptionFont};
  font-weight: bold;
  opacity: ${THEME.OPACITY.LOW};
  color: ${THEME.COLORS.PRIMARY_DARK};
  fill: ${THEME.COLORS.PRIMARY_DARK};
  width: fit-content;
`;

export const IconWrapper = styled.div`
  display: flex;
  position: relative;
  margin-right: ${THEME.SPACING.MICRO};
`;

export const ArrowIcon = styled(ASSETS.ARROW_DOWN)<{isOpen: boolean}>`
  width: 20px;
  ${({ isOpen }) => isOpen ? 'transform: rotate(180deg)' : null};
  transform-origin: center;
  transition: transform 0.1s ease;
`;
