import styled from 'styled-components';
import { THEME } from '../../../themes';

export const Container = styled.div`
  padding: ${THEME.SPACING.TINO};
`;

export const RuleContainer = styled.div`
  color: ${THEME.COLORS.SUCCESS};
`;
