import { LocationType, RegionDto } from '../../models/dto/location.dto';
import {
  FRANCE_COUNTRY_CODE, FRANCE_COUNTRY_NAME,
  GERMANY_COUNTRY_CODE,
  GERMANY_COUNTRY_NAME,
  ISRAEL_COUNTRY_CODE, ISRAEL_COUNTRY_NAME,
  UNITED_KINGDOM_COUNTRY_CODE,
  UNITED_KINGDOM_COUNTRY_NAME,
  UNITED_STATES_COUNTRY_CODE,
  UNITED_STATES_COUNTRY_NAME,
} from '../../consts/countries';

export const GOOGLE_REGIONS_TYPE = '(regions)';
export const GOOGLE_UNITED_KINGDOM_COUNTRY_CODE = 'GB';
export const GOOGLE_CITY = 'locality';
export const GOOGLE_STATE = 'administrative_area_level_1';
export const GOOGLE_COUNTRY = 'country';
export type GOOGLE_TYPES = 'locality' | 'administrative_area_level_1' | 'country';

export const AUTOCOMPLETE_ALLOWED_COUNTRIES = [
  UNITED_STATES_COUNTRY_CODE,
  GOOGLE_UNITED_KINGDOM_COUNTRY_CODE,
  FRANCE_COUNTRY_CODE,
  GERMANY_COUNTRY_CODE,
  ISRAEL_COUNTRY_CODE,
];

export const MAX_AUTOCOMPLETE_VALUES_NUMBER = 10;

export const INITIAL_LOCATION_OBJECT = {
  exactLocations: [],
  regions: [],
};

// TODO: translation (need to create some id before doing it, so all placed that are using this object still work
export const REMOTE_POSITION = {
  type: LocationType.ExactLocation,
  country: 'Remote',
  city: 'Anywhere',
};

export const REMOTE_COUNTRIES: RegionDto[] = [{
  type: LocationType.Region,
  country: UNITED_STATES_COUNTRY_CODE,
  region: UNITED_STATES_COUNTRY_CODE,
  countryLong: UNITED_STATES_COUNTRY_NAME,
}, {
  type: LocationType.Region,
  country: UNITED_KINGDOM_COUNTRY_CODE,
  region: UNITED_KINGDOM_COUNTRY_CODE,
  countryLong: UNITED_KINGDOM_COUNTRY_NAME,
}, {
  type: LocationType.Region,
  country: GERMANY_COUNTRY_CODE,
  region: GERMANY_COUNTRY_CODE,
  countryLong: GERMANY_COUNTRY_NAME,
},
{
  type: LocationType.Region,
  country: FRANCE_COUNTRY_CODE,
  region: FRANCE_COUNTRY_CODE,
  countryLong: FRANCE_COUNTRY_NAME,
}, {
  type: LocationType.Region,
  country: ISRAEL_COUNTRY_CODE,
  region: ISRAEL_COUNTRY_CODE,
  countryLong: ISRAEL_COUNTRY_NAME,
},
];
