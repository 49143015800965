import styled from 'styled-components';
import { THEME } from '../../themes';
import { CaptionFont, HeadlineFont } from '../../themes/fonts';

export const Title = styled.div`
  ${HeadlineFont};
  font-weight: 600;
  margin-bottom: ${THEME.SPACING.MICRO};
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  display: flex;
`;

export const Description = styled.div`
  opacity:${THEME.OPACITY.MED};
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${THEME.SPACING.TINY};
  width: 100%;
  height: 100%;
  border-radius: ${THEME.RADIUS.ROUND};
  word-break: break-all;
`;

export const BadgeContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
