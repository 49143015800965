import { outreachQuery } from '../outreach.toolkit-api';
import { EmailSenderResponseDto } from '../dto/response/email-sender.response';
import { useAppSelector } from '../../../../selectors';

export const emailSenderSelector = outreachQuery.endpoints.getEmailSender.select;

export const useGetEmailSenderSelector = (): AppSelectorType<EmailSenderResponseDto | undefined> => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useAppSelector(emailSenderSelector());

  return {
    data,
    error,
    isLoading,
    isError,
    isSuccess,
  };
};
