import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import { RoleAutocomplete } from '../../autocompletes/role-autocomplete/role-autocomplete.component';
import { Sentence } from '../../../../../components/Common/sentence-component/sentence.component';
import { ROLES } from '../../../consts/onboarding.const';

type MyRoleSentenceProps = {
  onSentenceCompleted: () => void,
}

export const MyRoleSentence = ({
  onSentenceCompleted,
}: MyRoleSentenceProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const roles = [{
    id: 1,
    name: translate(STRING_KEYS.RECRUITER_TITLE),
    roleEnum: ROLES.RECRUITER,
  },
  {
    id: 2,
    name: translate(STRING_KEYS.MANAGER_TITLE),
    roleEnum: ROLES.MANAGER,
  },
  ];

  return (
    <Sentence
      text={ translate(STRING_KEYS.ONBOARDING.ONBOARDING_I_AM) }
      Item={
        <RoleAutocomplete onSentenceCompleted={ onSentenceCompleted } roles={ roles } />
      }
    />
  );
};
