import styled, { css, keyframes } from 'styled-components';
import { ASSETS } from '../../../../assets';
import { THEME } from '../../../../themes';
import { CaptionFont, OverlineFont, Subtitle1Font } from '../../../../themes/fonts';

const shakeHorizontal = keyframes`
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
`;

export const Input = styled.input<{isError: boolean}>`
  ${Subtitle1Font};
  font-weight: normal;
  width: 100%;
  margin-top: ${THEME.SPACING.TINY};
  background-color: ${THEME.COLORS_RGB.GHOST_GRAY};
  color: ${THEME.COLORS_RGB.CONTENT_PRIMARY_MED};
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.BASE} ${THEME.SPACING.BASE} ${THEME.SPACING.GRAND};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  border: solid 1px ${({ isError }) => isError ? THEME.COLORS.ALERT : THEME.COLORS_RGB.PRIMARY_GHOST};
  caret-color: ${THEME.COLORS.ACCENT};
  ::placeholder{
    opacity: ${THEME.OPACITY.LOW};
  }
  :focus {
    outline: none;
  }
  animation: ${({ isError }) => (isError ?
    css`${shakeHorizontal} 0.7s cubic-bezier(0.175, 0.885, 0.320, 1.275) both` : '')} ;
`;

export const Icon = styled.div`
  position: absolute;
  top: 9px;
  left: 0;
  width: 53px;
  height: 53px;
  border-radius: ${THEME.RADIUS.ROUNDISH};
  background:url(${ASSETS.SECRET_ICON}) no-repeat ${THEME.SPACING.MEDIUM};
  background-size: 24px;
  z-index: 1;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ShowPassword = styled.div`
  position: absolute;
  text-transform: uppercase;
  top: 29px;
  right: 14px;
  color: ${THEME.COLORS.ACCENT};
  ${OverlineFont};
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;

export const ValidationError = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  width: 100%;
  color: ${THEME.COLORS.ALERT};
  margin-top: ${THEME.SPACING.TINY};
`;
