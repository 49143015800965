import { Avatar as MuiAvatar } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { THEME } from '../../../themes';

const StyledAvatarBase  = styled(MuiAvatar)`
  border: 2px solid ${THEME.COLORS.PURE_WHITE};
  transition: box-shadow 0.1s ease;
  background-color: ${THEME.COLORS.PRIMARY};
  width: 100%;
  height: auto;
  :hover {
    box-shadow: 0 4px 8px 0 rgba(217, 49, 128, 0.1);
  }
`;

export const StyledAvatar = withStyles({
  colorDefault: {
    fonWeight: 'bold',
    lineHeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: '0.14px',
    color: THEME.COLORS.PRIMARY,
    width: '100%',
    height: '100%',
    backgroundColor: `${THEME.COLORS.LIGHT_GREY} !important`,
  },
})(StyledAvatarBase);
