import { Switch, Route, Redirect } from 'react-router-dom';
// import log from 'loglevel';
import ResetPasswordScreen from '../ResetPasswordScreen';
import ForgotPasswordScreen from '../ForgotPasswordScreen';
import AccountSocialSuccessScreen from '../AccountSocialSuccessScreen';
import { AppRouting } from '../../services/appRoutingResolver';
import LoginScreen from '../login/login.component';
import { useAppState } from '../../store/selectors';
import AccountActivationScreen from '../AccountActivationScreen';
import AcceptTenantInviteScreen from '../AcceptTenantInviteScreen';

// TODO [refactor] implement
// const logger = log.getLogger('AUTH_ROUTER');

const AuthRouter = (): JSX.Element | null => {
  const {
    showSplashScreen,
  } = useAppState();

  if (showSplashScreen) {
    return null;
  }

  return (
    <>
      <Switch>
        <Route path={ AppRouting.ROUTES.ACCEPT_TENANT_INVITE } exact>
          <AcceptTenantInviteScreen />
        </Route>
        <Route path={ AppRouting.ROUTES.ACTIVATE_ACCOUNT } exact>
          <AccountActivationScreen />
        </Route>
        <Route path={ AppRouting.ROUTES.GLOBAL_LOGIN } exact>
          <LoginScreen />
        </Route>
        <Route path={ AppRouting.ROUTES.RESET_PASSWORD } exact>
          <ResetPasswordScreen />
        </Route>
        <Route path={ AppRouting.ROUTES.FORGOT_PASSWORD } exact>
          <ForgotPasswordScreen />
        </Route>
        <Route path={ AppRouting.ROUTES.SUCCESS_SOCIAL_LOGIN } exact>
          <AccountSocialSuccessScreen />
        </Route>
        <Route path="*">
          <Redirect to={ AppRouting.ROUTES.GLOBAL_LOGIN } />
        </Route>
      </Switch>
    </>
  );
};

export default AuthRouter;
