import styled from 'styled-components';
import { THEME } from '../../../../../../../themes';
import { hexToRgbA } from '../../../../../../../utils/colors';
import { Subtitle2Font } from '../../../../../../../themes/fonts';
import { ASSETS } from '../../../../../../../assets';
import { ValidationBannerEnum } from './validation-banner.enum';

const getBackgroundColor = (type: ValidationBannerEnum): string => {
  if (type === ValidationBannerEnum.Warning) {
    return hexToRgbA(THEME.COLORS.WARNING, THEME.OPACITY.DIVIDER);
  }

  return hexToRgbA(THEME.COLORS.ALERT, THEME.OPACITY.DIVIDER);
};
export const BannerContainer = styled.div<{ type: ValidationBannerEnum }>`
    background-color: ${({ type }) => getBackgroundColor(type)};
    padding-left: ${THEME.SPACING.XLOOSE};
    display: flex;
    align-items: center;
    gap: ${THEME.SPACING.TINO};
    height: 56px;
`;

export const ContextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${THEME.SPACING.MICRO};
`;

export const Title = styled.div`
    ${Subtitle2Font};
    font-weight: 600;
`;

export const Description = styled.div`
    ${Subtitle2Font};
`;

export const WarningIcon = styled(ASSETS.QUESTION_FILLED)`
    width: 24px;
    height: 24px;
    fill: ${THEME.COLORS.WARNING}
`;

export const ErrorIcon = styled(ASSETS.ALERT)`
    width: 24px;
    height: 24px;
    fill: ${THEME.COLORS.ALERT}
`;
