import styled  from 'styled-components';
import {
  CaptionFont, OverlineFont, Subtitle1Font,
} from '../../../../../themes/fonts';
import { THEME } from '../../../../../themes';
import { ASSETS } from '../../../../../assets';
import { hexToRgbA } from '../../../../../utils/colors';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';

export const BannerContainer = styled.div`
  width: 100%;
  height: 176px;
  border-radius: ${THEME.RADIUS.ROUND};
  backdrop-filter: blur(4px);
  background-color:  ${hexToRgbA(THEME.COLORS.PURE_WHITE, THEME.OPACITY.GHOSTLY)};;
  position: relative;
  display: flex;
  align-items: center;
`;

export const Background = styled.div<{ gradientBackgroundColor: string }>`
  width: 100%;
  height: 176px;
  position: absolute;
  top: 0;
  z-index: -1;
  border-radius: ${THEME.RADIUS.ROUND};
  mix-blend-mode: hard-light;
  opacity: ${THEME.OPACITY.MED};
  background-image: ${({ gradientBackgroundColor }) => `radial-gradient(circle closest-corner at 0%, ${gradientBackgroundColor}, rgba(126,39,228,.05))`};
`;

export const IconContainer = styled.div`
  flex-basis: 30%;
  display: flex;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  padding-top: ${THEME.SPACING.MEDIUM};
  flex-basis: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINO};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const Label = styled.div`
  ${OverlineFont};
  opacity: ${THEME.OPACITY.MED};
  font-weight: 600;
  line-height: 1.5;
`;

export const Title = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
  letter-spacing: 0.14px;
`;

export const Description = styled.div`
  ${CaptionFont};
  opacity: ${THEME.OPACITY.MED};
  letter-spacing: 0.4px;
`;

export const UpgradeButtonIcon = styled(ASSETS.LIGHTNING)`
  height: 24px;
  fill: ${THEME.COLORS.WARNING};
`;

export const ActionButton = styled(BaseButton)`
  background-color: transparent;
  color: ${THEME.COLORS.WARNING};
  padding: 0 ${THEME.SPACING.MICRO};
  width: fit-content;
  &:hover {
    background-color: transparent !important;
  }
`;
