import { outreachQuery } from '../outreach.toolkit-api';
import { EmailSenderResponseDto } from '../dto/response/email-sender.response';
import { ApiError } from '../../../../../consts/api';
import { CreateEmailSenderQueryArguments } from '../dto/query-arguments/create-email-sender.query-arguments';

type UseCreateMicrosoftEmailSenderMutationType = [
  (request: CreateEmailSenderQueryArguments) => void,
  AppSelectorType<EmailSenderResponseDto | undefined, ApiError>
];

export const useCreateMicrosoftEmailSenderMutation = (): UseCreateMicrosoftEmailSenderMutationType => {
  const [
    createMicrosoftEmailSender,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = outreachQuery.useCreateMicrosoftEmailSenderMutation();

  const meta = {
    isLoading, isError, error: error as ApiError, isSuccess, data, resetState: reset,
  };

  return [
    createMicrosoftEmailSender,
    { ...meta },
  ];
};
