import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../../language/keys';
import BaseSection from '../base-section/base-section.component';
import { Icon } from './relevance-explanation-section.styled';

const RelevanceExplanationSection = ({ relevanceExplanation }: RelevanceExplanationSectionProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseSection
      title={ translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY.INFO_MENU.RELEVANCE_EXPLANATION_SECTION_TITLE) }
      Icon={ <Icon /> }
    >
      <>{ relevanceExplanation }</>
    </BaseSection>
  );
};

export default RelevanceExplanationSection;
