import {
  GetQuery, PatchQuery, PostQuery, PutQuery,
} from '../../@types/rtk-query';
import { QueryMethods } from '../../enums/query-methods.enums';
import { GetPositionSequencePreferencesQueryArguments } from './dto/query-arguments/get-position-sequence-preferences.query-arguments';
import {
  CONVERSATION_ID_PATH_PARAM,
  MESSAGE_ID_PATH_PARAM,
  POSITION_ID_PATH_PARAM,
  ROUTES,
  TASK_ID_PATH_PARAM,
} from './sequence.consts';
import { CreateContentPreferencesDto, CreatePositionSequencePreferencesRequest } from './dto/request/create-position-sequence-preferences.request';
import { CreateOrUpdatePositionSequencePreferencesQueryArguments } from './dto/query-arguments/create-or-update-position-sequence-preferences.query-arguments';
import { CreateMessagePreviewQueryArguments } from './dto/query-arguments/create-message-preview.query-arguments';
import { UpdateScheduledMessageQueryArguments } from './dto/query-arguments/update-scheduled-message.query-arguments';
import { UpdateScheduledMessageRequest } from './dto/request/update-scheduled-message.request';
import { CancelConversationSequenceQueryArguments } from './dto/query-arguments/cancel-conversation-sequence.query-arguments';
import { CancelConversationSequenceRequest } from './dto/request/cancel-conversation-sequence.request';
import { UpdateLinkedinTaskQueryArguments } from './dto/query-arguments/update-linkedin-task.query-arguments';
import { UpdateLinkedinTaskRequest } from './dto/request/update-linkedin-task.request';
import { CreateConversationSequenceQueryArguments } from './dto/query-arguments/create-conversation-sequence.query-arguments';
import { GetConversationNextScheduledMessageQueryArguments } from './dto/query-arguments/get-conversation-next-scheduled-message.query-arguments';
import { UpdateCustomStepsQueryArguments } from './dto/query-arguments/update-custom-steps.query-arguments';
import { UpdateCustomStepsRequest } from './dto/request/update-custom-steps.request';

const CREATE_MESSAGE_PREVIEW_TIMEOUT_MS = 60000;

export const getPositionSequencePreferencesQuery = ({ positionId }: GetPositionSequencePreferencesQueryArguments): GetQuery => ({
  url: ROUTES.POSITION_SEQUENCE_PREFERENCES.replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Get,
});

export const getConversationNextScheduledMessageQuery = ({ conversationId }: GetConversationNextScheduledMessageQueryArguments): GetQuery => ({
  url: ROUTES.NEXT_SCHEDULED_MESSAGE.replace(CONVERSATION_ID_PATH_PARAM, conversationId),
  method: QueryMethods.Get,
});

export const createPositionSequencePreferencesQuery = (
  { positionId, data }: CreateOrUpdatePositionSequencePreferencesQueryArguments,
): PostQuery<CreatePositionSequencePreferencesRequest> => ({
  url: ROUTES.POSITION_SEQUENCE_PREFERENCES.replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Post,
  data,
});

export const createConversationSequenceQuery = (
  { conversationId }: CreateConversationSequenceQueryArguments,
): PostQuery<CreatePositionSequencePreferencesRequest> => ({
  url: ROUTES.CONVERSATION_SEQUENCE.replace(CONVERSATION_ID_PATH_PARAM, conversationId),
  method: QueryMethods.Post,
});

export const regenerateConversationSequenceQuery = (
  { conversationId }: CreateConversationSequenceQueryArguments,
): PostQuery<CreatePositionSequencePreferencesRequest> => ({
  url: ROUTES.REGENERATE_CONVERSATION_SEQUENCE.replace(CONVERSATION_ID_PATH_PARAM, conversationId),
  method: QueryMethods.Post,
});

export const updateScheduledMessageQuery = (data: UpdateScheduledMessageQueryArguments): PatchQuery<UpdateScheduledMessageRequest> => ({
  url: ROUTES.SCHEDULED_MESSAGE.replace(MESSAGE_ID_PATH_PARAM, data.messageId),
  method: QueryMethods.Patch,
  data,
});

export const cancelConversationSequenceQuery = (data: CancelConversationSequenceQueryArguments): PostQuery<CancelConversationSequenceRequest> => ({
  url: ROUTES.CANCEL_CAMPAIGN.replace(CONVERSATION_ID_PATH_PARAM, data.conversationId),
  method: QueryMethods.Post,
});

export const updatePositionSequencePreferencesQuery = (
  { positionId, data }: CreateOrUpdatePositionSequencePreferencesQueryArguments,
): PatchQuery<CreatePositionSequencePreferencesRequest> => ({
  url: ROUTES.POSITION_SEQUENCE_PREFERENCES.replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Patch,
  data,
});

export const createMessagePreviewQuery = (
  { positionId, data }: CreateMessagePreviewQueryArguments,
): PostQuery<CreateContentPreferencesDto> => ({
  url: ROUTES.MESSAGE_PREVIEW.replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Post,
  data,
  timeout: CREATE_MESSAGE_PREVIEW_TIMEOUT_MS,
});

export const updateLinkedinTaskQuery = (
  { task, status }: UpdateLinkedinTaskQueryArguments,
): PatchQuery<UpdateLinkedinTaskRequest> => ({
  url: ROUTES.LINKEDIN_TASK_BY_ID.replace(TASK_ID_PATH_PARAM, task.id),
  method: QueryMethods.Patch,
  data: { status },
});

export const updateCustomStepsQuery = ({
  positionId, steps,
}: UpdateCustomStepsQueryArguments): PutQuery<UpdateCustomStepsRequest> => {
  const data = { data: steps };

  return {
    url: ROUTES.POSITION_CUSTOM_STEPS_PREFERENCES.replace(POSITION_ID_PATH_PARAM, positionId),
    method: QueryMethods.Put,
    data,
  };
};
