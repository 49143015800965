import { IconList } from './icon-list/icon-list.component';
import {
  Container,
  Description,
  Title,
  BadgeContent,
} from './badge.styled';

export type BadgeProps = {
    title: string,
    description?: string,
    iconsUrls?: string[] | null,
    Icon?: JSX.Element | null,
}

export const Badge = ({
  title,
  description,
  iconsUrls,
  Icon,
}: BadgeProps): JSX.Element => {
  const defineIcon = () => {
    if (iconsUrls) {
      return <IconList iconsUrls={ iconsUrls } />;
    }

    return Icon;
  };

  return (
    <Container>
      {defineIcon()}
      <BadgeContent>
        <Title>
          {title}
        </Title>
        {description && (
          <Description title={ description }>
            {description}
          </Description>
        )}
      </BadgeContent>
    </Container>
  );
};

Badge.defaultProps = {
  description: '',
  iconsUrls: null,
  Icon: null,
};
