import styled, { createGlobalStyle } from 'styled-components';

export const AppWrapper = styled.div<{
  isLoading: boolean,
}>`
  ${({ isLoading }) => isLoading && `
    opacity: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    overflow: hidden;
  `}
`;

export const GlobalStyle = createGlobalStyle`
  body {
    position: fixed;
    overflow: hidden;
    width: 100%;
  }
`;
