// TODO @Israel Should add design for error state
// TODO @Israel Should add design for variants
export enum PerfectInputVariants {
  Fill = 'FILL',
  Outline = 'OUTLINE',
  Ghost = 'GHOST',
}

// TODO @Israel Should add design for hover
export enum PerfectInputHoverVariants {
  Darkened = 'DARKENED',
  Grow = 'GROW',
}

// TODO @Israel Should add design for size
export enum PerfectInputSize {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',
}
