import { Dispatch } from 'react';
import { MatchCardActionType } from './match-card.enums';
import { MatchCardAction, MatchCardActionsReturnType } from './match-card';

export const actions = (dispatch: Dispatch<MatchCardAction>): MatchCardActionsReturnType => ({
  setIsExpanded: (state: boolean) => {
    dispatch({
      type: MatchCardActionType.SetIsExpanded,
      payload: state,
    });
  },
});
