import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { CaptionFont } from '../../../../../themes/fonts';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: ${THEME.SPACING.BASE};
`;

export const SocialProfilesSubTitle = styled.div`
  opacity:${THEME.OPACITY.MED};
  ${CaptionFont};
  margin-bottom: ${THEME.SPACING.MICRO};
  color: #021d3d;
`;
