import React, { useEffect, useState } from 'react';
import { Location, LocationKey } from 'history';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

export const useUrlQuery = (): URLSearchParams => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

type useBrowserNavigateButtonsType = {
  onForward?: () => void,
  onBack?: (pathName: string) => void,
}
export const useBrowserNavigateButtons = ({
  onForward,
  onBack,
}:useBrowserNavigateButtonsType): void => {
  const [locationKeys, setLocationKeys] = useState<LocationKey[]>([]);
  const history = useHistory();

  useEffect(() => {
    return history.listen((location: Location<unknown>) => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key || '']);
        if (onForward) {
          onForward();
        }
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          setLocationKeys((keys) => [location.key || '', ...keys]);
          if (onBack)  {
            onBack(location.pathname);
          }
        }
      }
    });
  }, [
    locationKeys,
    history,
    onBack,
    onForward,
  ]);
};
