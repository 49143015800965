import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { ASSETS } from '../../../../../assets';
import { OverlineFont } from '../../../../../themes/fonts';

export const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.XLOOSE};
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MEDIUM};
`;

export const AiIcon = styled(ASSETS.AI)`
  width: 16px;
`;

export const TitleAdornmentContainer = styled.div`
  ${OverlineFont};
  color: ${THEME.COLORS.TRUE_BLACK};
  display: flex;
  gap: ${THEME.SPACING.TINY};
  align-items: center;
  font-weight: 500;
  line-height: normal;
`;
