import { AnyAction, CaseReducer } from '@reduxjs/toolkit';
import { AppState } from '../app-state.initial-state';
import { removeFromArray } from '../../../../utils';
import { INITIAL_LOADING } from '../app-state.consts';

export const getAccountByIdRejectExtraReducer: CaseReducer<AppState, AnyAction> = (state) => {
  state.initialLoadings = removeFromArray(state.initialLoadings, INITIAL_LOADING.LOAD_ACCOUNT_DATA);
  // TODO [refactor] added global errors
};

export const getAccountByIdFulfilledExtraReducer: CaseReducer<AppState, AnyAction> = (state) => {
  state.initialLoadings = removeFromArray(state.initialLoadings, INITIAL_LOADING.LOAD_ACCOUNT_DATA);
};
