import { SequenceModeEnum } from '../../rtk-query/apis/sequence/enums/sequence-mode.enum';

export type OutreachState = {
  shouldSortConversations: boolean,
  sequenceMode: SequenceModeEnum | undefined,
}

export const initialOutreachState: OutreachState = {
  shouldSortConversations: true,
  sequenceMode: undefined,
};
