import styled from 'styled-components';
import { ASSETS } from '../../../../../assets';
import { CaptionFont, HeadlineFont } from '../../../../../themes/fonts';
import { THEME } from '../../../../../themes';
import SelectItem from '../../../../../components/Common/select/select-item/select-item.component';
import { UserMenuItemStyle } from '../../../../../components/Common/user-menu-item/user-menu-item.styled';
import { TextEllipsis } from '../../../../../themes/text';
import Select from '../../../../../components/Common/select/select.component';
import { SelectProps } from '../../../../../components/Common/select/select';

export const Container = styled.div`
  
`;

export const Icon = styled(ASSETS.USER_AVATAR)`
  height: 24px;
`;

export const MenuTitleContainer = styled.div`
  color: ${THEME.COLORS.CONTENT_A};
  display: flex;
  flex-direction: column;
  padding: ${THEME.SPACING.TINO} 0;
`;

export const MenuTitle = styled.div`
  ${HeadlineFont};
`;

export const MenuSubtitle = styled.div`
  ${CaptionFont};
  opacity: ${THEME.OPACITY.MED};
  width: 272px;
`;

export const StyledSelectItem = styled(SelectItem)`
  ${UserMenuItemStyle};
`;

export const UserAvatarWrapper = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

export const SelectItemValue = styled.div`
  width: 100px;
  ${TextEllipsis};
`;

export const StyledSelect = styled(Select)<SelectProps & { isValid?: boolean }>`
  width: 232px;
  height: 56px;
  border: ${({ isValid }) => isValid ? undefined : `1px solid ${THEME.COLORS.ALERT}`};
`;
