import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { ASSETS } from '../../../../../assets';

const TOOLBAR_HEIGHT = 48;

export const Container = styled.div<{ isActive?: boolean }>`
  height: ${TOOLBAR_HEIGHT}px;
  display: flex;
  position: relative;
  align-items: center;
  min-width: 400px;
  max-width: 600px;
  width: fit-content;
  gap: ${THEME.SPACING.TINO};
`;

export const DividerIcon = styled(ASSETS.ARROW_RIGHT)`
  width: 9px;
  height: 15px;
  opacity: ${THEME.OPACITY.GHOST};
  fill: ${THEME.COLORS.PRIMARY_DARK};
`;

export const HomeIcon = styled(ASSETS.HOME_ICON)`
  width: 18px;
  height: 12px;
  fill: ${THEME.COLORS.PRIMARY_DARK};
  &:hover {
    fill: ${THEME.COLORS.ACCENT}
  }
`;
