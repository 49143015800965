/* eslint-disable eqeqeq */
import styled from 'styled-components';

export const Container = styled.div<{ height: number, transitionDurationMs?: number }>`
  transition: height ${({ transitionDurationMs }) => transitionDurationMs != undefined ? transitionDurationMs : 50}ms ease;
  will-change: height;
  height: ${({ height }) => height}px;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  width: fit-content;
  height: fit-content;
`;
