import { v4 } from 'uuid';
import { ConversationMinimizedResponseDto } from '../../outreach/dto/response/conversation-minimized.response';
import { ConversationStatusEnum } from '../../outreach/enums/conversation-status.enum';
import { MessageDirectionEnum } from '../../outreach/enums/message-direction.enum';
import { MessageResponseDto } from '../../outreach/dto/response/message.response';
import { LinkedinTaskResponseDto } from '../../outreach/dto/response/conversation-base.response';
import { MessageTypeEnum } from '../../outreach/enums/message-type.enum';
import { MessageTriggerTypeEnum } from '../../outreach/enums/message-trigger-type.enum';
import { ConversationResponseDto } from '../../outreach/dto/response/conversation.response';
import { UserInfoResponse } from '../../user/dto/response/user-info.response';
import { createFullName } from '../../../../../utils';

const linkedinTaskToMessageDto = (
  linkedinTaskResponseDto: LinkedinTaskResponseDto,
  currentUser: UserInfoResponse,
) => {
  const {
    subject, html, text,
  } = linkedinTaskResponseDto.payload;

  return {
    id: v4(), // This is temporary id, after refresh data should be changed to original id
    type: MessageTypeEnum.LinkedinManual,
    triggerType: MessageTriggerTypeEnum.LinkedinTask,
    messageDirection: MessageDirectionEnum.Outbound,
    seen: false,
    payload: {
      subject: subject || '',
      text,
      html,
      sanitizedBodyHtml: html,
      sanitizedBodyText: text,
    },
    opened: false,
    actionTime: new Date().toISOString(),
    from: currentUser.email,
    userInfo: {
      id: currentUser.id,
      email: currentUser.email,
      fullName: createFullName(currentUser.firstName, currentUser.lastName),
      profileImage: currentUser.profileImage,
    },
  } as MessageResponseDto;
};

export const addLinkedinTaskInConversationsByConversationIdOptimisticHandler = (
  linkedinTask: LinkedinTaskResponseDto,
  conversationId: string,
  currentUser: UserInfoResponse,
  conversations: ConversationMinimizedResponseDto[],
): void => {
  const foundConversation = conversations.find((conversation) => conversation.id === conversationId);
  if (foundConversation) {
    foundConversation.status = ConversationStatusEnum.Sent;
    foundConversation.isError = false;
    foundConversation.latestMessage = linkedinTaskToMessageDto(linkedinTask, currentUser);
    foundConversation.lastActionAt = new Date();
  }
};

export const addLinkedinTaskInConversationByConversationIdOptimisticHandler = (
  linkedinTask: LinkedinTaskResponseDto,
  conversation: ConversationResponseDto,
  currentUser: UserInfoResponse,
): void => {
  conversation.status = ConversationStatusEnum.Sent;
  conversation.isError = false;
  conversation.messageHistory = (conversation.messageHistory || []);
  conversation.messageHistory.push(linkedinTaskToMessageDto(linkedinTask, currentUser));
  conversation.lastActionAt = new Date();
};
