import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import { DisconnectEmailProps } from './disconnect-email';
import {
  Container, DisconnectLink, Underline,
} from './disconnect-email.styled';

const DisconnectEmail = ({ emailAddress, onClickDisconnectEmail }: DisconnectEmailProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <Trans
        i18nKey={ STRING_KEYS.CAMPAIGN_PAGE.DISCONNECT_EMAIL.DESCRIPTION }
        values={ { userEmailAddress: emailAddress } }
      />
      <DisconnectLink onClick={ onClickDisconnectEmail }> <Underline>{ translate(STRING_KEYS.CAMPAIGN_PAGE.DISCONNECT_EMAIL.LINK) }</Underline></DisconnectLink>
    </Container>
  );
};

export default DisconnectEmail;
