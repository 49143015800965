import styled from 'styled-components';
import { THEME } from '../../../themes';
import { Body1Font, Title2Font, Title3Font } from '../../../themes/fonts';
import { ASSETS } from '../../../assets';
import Dialog from '../base-dialog/base-dialog.component';
import { HorizontalDivider } from '../../Common/horizontal-divider/horizontal-divider.component';
import { hexToRgbA } from '../../../utils/colors';

export const HeaderContainer = styled(Dialog.Header)`
  padding-top: ${THEME.SPACING.MEDIUM};
  padding-left: ${THEME.SPACING.LOOSE};
`;

export const HeaderIcon = styled(ASSETS.INFINITY_ARROW_ICON)`
  width: 48px;
  color: ${THEME.COLORS.ACCENT};
`;

export const BodyContainer = styled(Dialog.Body)`
  display: flex;
  flex-direction: row;
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.LOOSE} ${THEME.SPACING.XLOOSE};
  overflow: hidden;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
`;

export const Title = styled.div`
  ${Title2Font};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_A};
  white-space: pre-line;
`;

export const Description = styled.div`
  ${Body1Font};
  color: ${THEME.COLORS_RGB.PRIMARY_DARK_MED};
  white-space: pre-line;
`;

export const StyledDivider = styled(HorizontalDivider)`
  height: 2px;
`;

export const Subtitle = styled.div`
  ${Title3Font};
  color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.MED)};
`;

export const LoaderWrapper = styled.div`
  border-radius: 32px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
`;
