import React, { useEffect, useState } from 'react';
import { useGetMyAccountSelector } from '../../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import LinkPositionToAtsModal from '../link-position-to-ats/link-position-to-ats.component';
import { AtsInitializationDialog } from '../ats-initialization/ats-initialization.component';

const AtsInitializationDialogs = ({
  positionId,
  isOpen,
  closeHandler,
  productArea,
}: AtsInitializationDialogsProps): JSX.Element | null => {
  const { data: { atsIntegration } } = useGetMyAccountSelector();
  const [isOpenAtsInitializationDialog, setIsOpenAtsInitializationDialog] = useState(false);
  const [isOpenLinkPositionToAtsDialog, setIsOpenLinkPositionToAtsDialog] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (isOpenAtsInitializationDialog || isOpenLinkPositionToAtsDialog) {
        return;
      }

      if (atsIntegration) {
        setIsOpenLinkPositionToAtsDialog(true);

        return;
      }

      setIsOpenAtsInitializationDialog(true);
    } else {
      setIsOpenLinkPositionToAtsDialog(false);
      setIsOpenAtsInitializationDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, atsIntegration]);

  const closeModals = () => {
    setIsOpenLinkPositionToAtsDialog(false);
    setIsOpenAtsInitializationDialog(false);
    closeHandler();
  };

  return (
    <div>
      <LinkPositionToAtsModal
        isDialogOpen={ isOpenLinkPositionToAtsDialog }
        positionId={ positionId }
        closeHandler={ closeModals }
        productArea={ productArea }
      />
      <AtsInitializationDialog
        isDialogOpen={ isOpenAtsInitializationDialog }
        closeDialogHandler={ closeModals }
        productArea={ productArea }
      />
    </div>
  );
};

export default AtsInitializationDialogs;
