import { Avatar as MuiAvatar } from '@mui/material';
import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { Title3Font, Subtitle2Font } from '../../../../themes/fonts';
import { ASSETS, FLUENT_UI_EMOJIS } from '../../../../assets';

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${THEME.SPACING.BASE};
`;

export const CompanyBaseDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const CompanyNameAndLocationWrapper = styled.div`
  display: block;
`;

export const CompanyNameLabel = styled.div`
  ${Title3Font};
  font-weight: bold;
  display: inline-block;
  margin-right: ${THEME.SPACING.BASE};
`;

export const CompanyLocationLabel = styled.div`
  ${Subtitle2Font};
  display: inline-block;
  opacity: ${THEME.OPACITY.MED};
  line-height: 1.57;
  letter-spacing: 0.25px;
  margin-top: ${THEME.SPACING.MICRO};
  text-transform: capitalize;
`;

// TO DO Use divider from common
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-radius: ${THEME.RADIUS.ROUND};
  background-color: ${THEME.COLORS.PURE_WHITE};
  border: solid 1px rgba(34, 26, 93, 0.12);
`;

export const CompanyOtherDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CompanyDescription = styled.div`
  ${Subtitle2Font};
  opacity: ${THEME.OPACITY.MED};
  line-height: 1.57;
  margin-left: ${THEME.SPACING.TINY};
  flex: 1;
  max-height: 250px;
  overflow: auto;
`;

export const CompanyLogoImage = styled(MuiAvatar).attrs({
  sx: {
    height: '64px',
    width: '64px',
    backgroundColor: 'unset',
    borderRadius: THEME.RADIUS.BASE,
  },
})`
`;

export const LogoFallback = styled.div`
  width: 64px;
  height: 64px;
  padding: 20px;
  position: relative;
  background: url(${ASSETS.EXPERIENCE_FALLBACK}), ${THEME.COLORS.PRIMARY};
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center;
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${THEME.SPACING.MICRO};
`;

export const TagIcon = styled(FLUENT_UI_EMOJIS.OFFICE_BUILDING)``;
