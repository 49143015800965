import { Avatar } from '@mui/material';
import { withShowHide } from '../../HOC/WithShowHide';
import { PRIVACY_CLASS } from '../../../consts/fullstory';
import { useMyUserSelector } from '../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import UserProfileMenu from './user-profile-menu/user-profile-menu.component';
import {
  Container,
  HamburgerIcon,
} from './user-profile.styled';
import { UserProfileProps } from './user-profile';

const UserProfile = ({ filterMenuOpen }: UserProfileProps): JSX.Element => {
  const { data: { firstName, profileImage } } = useMyUserSelector();

  return (
    <Container
      isShowUserMenu={ !!filterMenuOpen }
    >
      <HamburgerIcon />
      <Avatar
        className={ PRIVACY_CLASS.EXCLUDE }
        alt={ `${firstName}` }
        src={ `${profileImage}` }
        sx={ { width: 32, height: 32 } }
      />
      {filterMenuOpen && (
        <UserProfileMenu />
      )}
    </Container>
  );
};

export default withShowHide(UserProfile);
