import styled from 'styled-components';
import { THEME } from '../../../../../../../themes';
import { Body2Font } from '../../../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../../../utils/colors';

export const Container = styled.div`
  display: flex;
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.BASE};
  align-items: center;
  gap: ${THEME.SPACING.BASE};
`;

export const Label = styled.div`
  ${Body2Font};
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.MED)};
`;
