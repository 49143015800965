import { sortPositionsByState } from '../../../../../utils/positions';
import { PositionResponse } from '../dto/response/position.response';
import { MATCH_STATUSES } from '../../../../../consts';
import { MatchingStatus } from '../../../../../enums/matching-status.enum';
import { PositionRunStatusEnum } from '../../../../../enums/position-run-status.enum';

export const getMatchingStatus = (position: PositionResponse): MatchingStatus => {
  const toReviewCount = position.statistics?.engagementStatusStatistics?.find((statistics) => statistics.name === MATCH_STATUSES.TO_REVIEW)?.amount || 0;

  if (!toReviewCount && position.hasReachedMaxDailyMatches) {
    return MatchingStatus.DailyLimit;
  }

  if ((!toReviewCount && position.latestRun?.status === PositionRunStatusEnum.Completed) || !position.latestRun) {
    return MatchingStatus.MatchingInTheMaking;
  }

  if (!toReviewCount && position.latestRun?.status === PositionRunStatusEnum.InProgress) {
    return MatchingStatus.MatchingInTheMaking;
  }

  if (
    !toReviewCount &&
    !position.hasReachedMaxDailyMatches &&
    (position.latestRun?.status === PositionRunStatusEnum.Incomplete || position.latestRun?.status === PositionRunStatusEnum.Failed)
  ) {
    return MatchingStatus.Failed;
  }

  return MatchingStatus.Active;
};

export const getPositionsResponseTransformer = (response: PositionResponse[]): PositionResponse[] => {
  const positions = response.map((position) => {
    position.matchingStatus = getMatchingStatus(position);

    return position;
  });

  return sortPositionsByState(positions);
};
