import styled from 'styled-components';
import { THEME } from '../../../themes';
import {
  Body1Font, CaptionFont, Subtitle1Font, Title2Font,
} from '../../../themes/fonts';
import { ASSETS } from '../../../assets';
import Dialog from '../base-dialog/base-dialog.component';

export const HeaderContainer = styled(Dialog.Header)`
  margin-top: ${THEME.SPACING.LARGE};
  display: flex;
  justify-content: center;
  height: fit-content;
`;

export const HeaderIcon = styled(ASSETS.OUTREACH_ICON)`
  width: 56px;
  height: 32px;
  fill: ${THEME.COLORS.ACCENT};
`;

export const BodyContainer = styled(Dialog.Body)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.LOOSE} ${THEME.SPACING.XLOOSE};
  overflow: hidden;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  ${Title2Font};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const Description = styled.div`
  ${Body1Font};
  margin-top: ${THEME.SPACING.LOOSE};
  color: ${THEME.COLORS_RGB.PRIMARY_DARK_MED};
`;

export const ButtonsContainer = styled.div`
  margin-top: ${THEME.SPACING.LARGE};
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINO};
  width: 400px;
  align-items: center;
`;

// TODO: talk with israel about create a function for this button style
export const OutreachInitializationButton = styled.div<{ disabled?: boolean }>`
  ${Subtitle1Font};
  position: relative;
  font-weight: 600;
  padding: 0 ${THEME.SPACING.MEDIUM};
  height: 56px;
  text-align: center;
  background-color: ${THEME.COLORS.LIGHT_GREY};
  border: 1px solid ${THEME.COLORS_RGB.PRIMARY_GHOST};
  border-radius: ${THEME.RADIUS.LOOPY};
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ disabled }) => disabled ? THEME.OPACITY.LOW : THEME.OPACITY.HIGHEST};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};

  ${({ disabled }) => {
    if (!disabled) {
      return `
      :hover {
        background-blend-mode: darken;
        background-image: linear-gradient(rgb(0 0 0/10%) 0 0);
      }
      `;
    }
    
    return '';
  }};
`;

export const ReadPermissionsContainer = styled.div`
  margin-top: ${THEME.SPACING.BASE};
  display: flex;
  gap: ${THEME.SPACING.TINY};
  align-items: center;
  justify-content: center;
`;

export const ReadPermissionsInfoText = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;

export const CheckedIcon = styled(ASSETS.CHECKED_GHOST)`
  width: 6px;
  fill: ${THEME.COLORS.ACCENT};
`;

export const UnCheckedIcon = styled(ASSETS.CHECKED_GHOST)`
  width: 6px;
  fill: ${THEME.COLORS.GREY};
`;
