import {
  Container, Title, Description, TitleContainer, TitleWrapper,
} from './base-input-item.styled';
import { BaseInputItemProps } from './base-input-item';

const BaseInputItem = ({
  title,
  titleAdornment,
  description,
  direction,
  children,
}: BaseInputItemProps): JSX.Element => {
  return (
    <Container direction={ direction }>
      <TitleContainer>
        <TitleWrapper>
          <Title>{ title }</Title>
          { titleAdornment }
        </TitleWrapper>
        <Description>{ description }</Description>
      </TitleContainer>
      { children }
    </Container>
  );
};

export default BaseInputItem;
