import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import {
  Body2Font,
} from '../../../../../../themes/fonts';

export const BadgeContent = styled.div`
    ${Body2Font};
    padding: ${THEME.SPACING.MICRO} ${THEME.SPACING.TINY};
    border-radius: ${THEME.RADIUS.TIDY};
    border: solid 1px rgba(5, 4, 13, ${THEME.OPACITY.GHOST});
    position: relative;
    display: flex;
    align-items: center;
`;

export const Container = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINO};
  flex-direction: column;
  margin-top: ${THEME.SPACING.TINY};
  align-items: flex-start;
`;

export const Content = styled.div`
  margin-left: ${THEME.SPACING.MICRO};
  text-overflow: ellipsis;
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
`;
