import styled from 'styled-components';

export const InputContainer = styled.div`
  text-align: center;
  font-size: 24px;
  
  & input {
    text-align: center;
  }
`;
