import { createElement, useEffect } from 'react';
import { Container } from './stripe-pricing-table.styled';

const StripePricingTable = ({ userId, pricingTableId, publishableKey }: StripePricingTableProps): JSX.Element => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Container>
      {
        createElement('stripe-pricing-table', {
          'pricing-table-id': pricingTableId,
          'publishable-key': publishableKey,
          'client-reference-id': userId,
        })
      }
    </Container>
  );
};

export default StripePricingTable;
