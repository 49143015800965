import { onboardingQuery } from '../onboarding.toolkit-api';

export const useGetAllowedCompaniesQuery = (skip = false): AppSelectorType<OnboardingAllowedCompanyResponse> => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
  } = onboardingQuery.useGetAllowedCompaniesQuery(
    undefined,
    {
      skip,
    },
  );

  return {
    data: data || {} as OnboardingAllowedCompanyResponse,
    error,
    isLoading,
    isError,
    isSuccess,
  };
};
