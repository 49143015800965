import { v4 } from 'uuid';
import { ConversationMinimizedResponseDto } from '../dto/response/conversation-minimized.response';
import { ConversationStatusEnum } from '../enums/conversation-status.enum';
import { MessageDirectionEnum } from '../enums/message-direction.enum';
import { MessageResponseDto } from '../dto/response/message.response';
import { SendMessageQueryArguments } from '../dto/query-arguments/send-message-query.arguments';
import { ConversationResponseDto } from '../dto/response/conversation.response';

export const addMessageInConversationsByConversationIdOptimisticHandler = (
  newMessage: SendMessageQueryArguments,
  conversations: ConversationMinimizedResponseDto[],
): void => {
  const {
    recipient, subject, html, text, conversationId, type, triggerType,
  } = newMessage;
  const foundConversation = conversations.find((conversation) => conversation.id === conversationId);
  if (foundConversation) {
    foundConversation.status = ConversationStatusEnum.Sent;
    foundConversation.isError = false;
    foundConversation.latestMessage = {
      id: v4(), // This is temporary id, after refresh data should be changed to original id
      recipient,
      type,
      triggerType,
      messageDirection: MessageDirectionEnum.Outbound,
      seen: false,
      payload: {
        subject: subject || '',
        text,
        html,
        sanitizedBodyHtml: html,
        sanitizedBodyText: text,
      },
      opened: false,
      actionTime: new Date().toISOString(),
    } as MessageResponseDto;
    foundConversation.lastActionAt = new Date();
  }
};

export const updateMessageInConversationsByMatchIdOptimisticHandler = (
  matchId: string,
  updatedConversation: MessageResponseDto,
  conversations: ConversationMinimizedResponseDto[],
): void => {
  const foundConversation = conversations.find((conversation) => conversation.matchId === matchId);
  if (foundConversation) {
    foundConversation.latestMessage = {
      ...updatedConversation,
    } as MessageResponseDto;
  }
};

export const updateMessageAsErrorInConversationsByMatchIdOptimisticHandler = (
  matchId: string,
  conversations: ConversationMinimizedResponseDto[],
): void => {
  const foundConversation = conversations.find((conversation) => conversation.matchId === matchId);
  if (foundConversation) {
    foundConversation.isError = true;
  }
};

export const addMessageToConversationOptimisticHandler = (
  tempMessageId: string,
  newMessage: SendMessageQueryArguments,
  conversation: ConversationResponseDto,
): void => {
  const {
    recipient, subject = '', html, text, userInfo, type, triggerType,
  } = newMessage;
  const originSubject = subject || conversation.messageHistory
    ?.slice()
    ?.reverse()
    ?.find(({ payload }: MessageResponseDto) => payload.subject)?.payload?.subject || '';
  conversation.messageHistory[conversation?.messageHistory?.length || 0] = {
    actionTime: new Date().toISOString(),
    from: '',
    id: tempMessageId,
    messageDirection: MessageDirectionEnum.Outbound,
    opened: false,
    payload: {
      subject: originSubject,
      text,
      html,
      sanitizedBodyText: text,
      sanitizedBodyHtml: html,
    },
    recipient,
    seen: false,
    senderUserId: '',
    shouldBlur: false,
    type,
    triggerType,
    userInfo,
  };
};

export const updateMessageInConversationOptimisticHandler = (
  tempMessageId: string,
  updatedConversation: MessageResponseDto,
  conversation: ConversationResponseDto,
): void => {
  const foundMessageIndex = conversation.messageHistory.findIndex(({ id }) => id === tempMessageId);

  if (foundMessageIndex > -1) {
    conversation.messageHistory[foundMessageIndex] = {
      ...updatedConversation,
    } as MessageResponseDto;
  }
};

export const updateMessageAsErrorInConversationOptimisticHandler = (
  tempMessageId: string,
  conversation: ConversationResponseDto,
): void => {
  const foundMessageIndex = conversation.messageHistory.findIndex(({ id }) => id === tempMessageId);
  if (foundMessageIndex) {
    conversation.messageHistory[foundMessageIndex].isError = true;
  }
};
