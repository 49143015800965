import { PositionRunStatusEnum } from '../../../../../enums/position-run-status.enum';
import { PositionResponse } from '../dto/response/position.response';

export const getPositionByIdResponseTransformer = (response: PositionResponse): PositionResponse => {
  response.latestRun = {
    triggeredAt: response.latestRun?.triggeredAt || response?.lastEngagedAt ||  response.createdAt,
    status: response.latestRun?.status || PositionRunStatusEnum.InProgress,
    noResults: response.latestRun?.noResults || false,
    deadEndRecommendations: response.latestRun?.deadEndRecommendations,
    triggerType: response.latestRun?.triggerType,
  };

  return response;
};
