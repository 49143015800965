import styled, { keyframes } from 'styled-components';

const confettiSlow = keyframes`
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  100% { transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg); }
`;

const confettiMedium = keyframes`
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  100% { transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg); }
`;

const confettiFast = keyframes`
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  100% { transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg); }
`;

export const Container = styled.div`
  perspective: 700px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  & .confetti {
    position: absolute;
    z-index: 1;
    top: -10px;
    border-radius: 0;

    &--animation-slow {
        animation: ${confettiSlow} 2.25s linear 1 forwards;
    }

    &--animation-medium {
        animation: ${confettiMedium} 1.75s linear 1 forwards;
    }

    &--animation-fast {
        animation: ${confettiFast} 1.25s linear 1 forwards;
    }
  }
`;
