import { FATAL_ERRORS } from '../../../../consts/errors';
import { ErrorPage } from '../ErrorPage';
import { FATAL_ERRORS_CODE } from '../../../../store/slices/app-state/app-state.consts';

// selecting error is outside the component to prevent changing on each render
const randomErrorIndex = Math.floor(Math.random() * FATAL_ERRORS.length);
const currentError = FATAL_ERRORS[randomErrorIndex];

type FatalErrorPageProps = {
  errorCode?: string,
}

export const FatalErrorPage = ({
  errorCode,
}: FatalErrorPageProps): JSX.Element => {
  return (
    <ErrorPage
      title={ currentError.title }
      content={ currentError.content }
      errorCode={ errorCode }
      image={ currentError.image }
      imageAlt={ currentError.alt }
    />
  );
};

FatalErrorPage.defaultProps = {
  errorCode: FATAL_ERRORS_CODE.UNDEFINED,
};
