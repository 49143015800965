import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import STRING_KEYS from '../../../../../language/keys';
import { Autocomplete } from '../../../../../components/Common/create-position-autocomplete/autocomplete.component';
import {
  useOnboardingCompanyName,
  useOnboardingLetsBegin,
} from '../../../../../store/slices/onboarding-state/selectors/onboarding-selectors.hooks';
import { useGetAllowedCompaniesSelector } from '../../../../../store/rtk-query/apis/onboarding/selectors/get-allowed-companies.selector';
import { setCompany } from '../../../../../store/slices/onboarding-state/onboarding-state.toolkit-slice';
import { InputStyle } from '../../input/input.component';

// todo onboarding: autocomplete case insensitive
// todo onboarding: handle undefined values in company object (in render)
type RoleAutocompleteProps = {
  onSentenceCompleted: () => void,
  onCompanyChange: () => void,
  onOpenCompanyDialog: () => void,
}

export const CompanyAutocomplete = ({
  onSentenceCompleted,
  onCompanyChange,
  onOpenCompanyDialog,
}: RoleAutocompleteProps):JSX.Element => {
  const [isInputFocused, setInputFocused] = useState<boolean>(false);
  const { companyName } = useOnboardingCompanyName();
  const [companyInputValue, setCompanyInputValue] = useState<string>('');
  const { data: { allowedCompanies } } = useGetAllowedCompaniesSelector();
  const dispatch = useDispatch();
  const { companyLinkedinUrl: storedCompany } = useOnboardingLetsBegin();
  const { t: translate } = useTranslation();

  const isOneAllowedCompany = allowedCompanies && allowedCompanies.length === 1;

  useEffect(() => {
    if (companyName) {
      setInputFocused(false);
    }
  }, [companyName]);

  useEffect(() => {
    if (isInputFocused) {
      onOpenCompanyDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInputFocused]);

  const companyChangedHandler = (value: string) => {
    setCompanyInputValue(value);
    onCompanyChange();
  };

  useEffect(() => {
    if (isOneAllowedCompany) {
      companyChangedHandler(allowedCompanies[0].name || '');
      onSentenceCompleted();
      dispatch(setCompany({
        companyLinkedInUrl: allowedCompanies[0].sanitizedLinkedinUrl || '',
        name: allowedCompanies[0].name || '',
      },
      ));
      setInputFocused(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      isError={ !storedCompany && !isInputFocused }
      errorMessage={ translate(STRING_KEYS.ONBOARDING.COMPANY_SELECTION_ERROR) }
      isLoading={ false }
      placeholder={ STRING_KEYS.ONBOARDING.ONBOARDING_COMPANY_PLACEHOLDER }
      inputValue={ companyName || companyInputValue }
      onValueChange={ companyChangedHandler }
      isInputFocused={ isInputFocused }
      setInputFocused={ setInputFocused }
      autoFocus={ !(isOneAllowedCompany && !isInputFocused) }
      inputStyle={ InputStyle.Onboarding }
    />
  );
};
