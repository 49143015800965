import styled, { css } from 'styled-components';
import { THEME } from '../../../../../themes';
import {
  Subtitle1Font, Subtitle2Font, Title2Font,
} from '../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../utils/colors';

export const Container = styled.div`
  width: 300px;
  height: 274px;
  position: relative;
  background-color: ${THEME.COLORS.PURE_WHITE};
`;

export const Wrapper = styled.div`
  height: 100%;
  padding-top: ${THEME.SPACING.MEDIUM};
  padding-left: ${THEME.SPACING.BASE};
  padding-bottom: ${THEME.SPACING.BASE};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 15;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
`;

export const Title = styled.div`
  ${Subtitle1Font};
`;

export const Subtitle = styled.div`
  ${Subtitle2Font};
  margin-top: ${THEME.SPACING.TINY};
`;

export const IconContainer = styled.div`
  width: 16px;
  height: 16px;
`;

export const CountContainer = styled.div`
  ${Title2Font};
  font-weight: 600;
  margin-top: ${THEME.SPACING.TINO};
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MICRO};
`;

const PolygonBase = css`
  background: ${hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.DIVIDER)};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
`;

type PolygonProps = {
  level: number,
};

export const Polygon = styled.div<PolygonProps>`
  ${PolygonBase};
  top: ${({ level }) => `${level}%`};
`;

export const OptimalLine = styled.div<PolygonProps & { nextLevel?: number }>`
  ${PolygonBase};
  background: unset;
  border-top: 2px dashed ${hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.LOW)};
  border-right: 2px dashed ${hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.LOW)};
  top: ${({ level }) => `${level}%`};
  right: -2px;
  height: ${({ level, nextLevel }) => `calc(${nextLevel}% - ${level}%)`};
`;
