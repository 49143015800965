import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { Badge } from '../../../badge/badge.component';
import { Progression } from './progression-badge.styled';

export const ProgressionBadge = ():JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Badge
      title={ translate(STRING_KEYS.CAREER_UPGRADE) }
      description={ translate(STRING_KEYS.CAREER_UPGRADE_DESCRIPTION) }
      Icon={ <Progression /> }
    />
  );
};
