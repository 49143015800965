import { v4 } from 'uuid';
import Cookies from 'js-cookie';
import log from 'loglevel';
import { AppRouting } from './appRoutingResolver';

class TrackingIdManager {
  private  logger = log.getLogger(TrackingIdManager.name);

  readonly COOKIE_NAME = 'perfect_t_uid';
  
  readonly SESSION_STORAGE_KEY = 'perfect_in_id';

  // Represent a long-time id that is being saved in cookies/local storage.
  // this allows us to identify the same user from multiple instances of the app,
  // for example when he closes & opens the app multiple times.
  browserId: null | string = null;
  
  // Represent an unique id of current app-instance.
  // this allows us to identify every instance of the app,
  // for example if it's opened in multiple tabs.
  // this instanceId will be the same while the tab is open, and even after refresh.
  tabSessionId: null | string = null;

  constructor() {
    // load current ids if exists
    this.browserId = this.loadBrowserSessionId();
    this.tabSessionId = sessionStorage.getItem(this.SESSION_STORAGE_KEY) || null;

    // make sure that the cookie won't expire soon
    this.extendCookieTime();
  }

  /**
   * Recreate the cookie to extend its life-time.
   * it prevents experation of the cookie after a predefined amount of time,
   * so the cookie will be expired only if the user won't visit the app again
   * within the defined cookie life-time
   */
  private extendCookieTime = (): void => {
    if (this.browserId) {
      const _browserId = this.browserId;
      // fix access lexical declaration exception
      setTimeout(() => {
        this.saveBrowserSessionId(_browserId);
      }, 100);
    }
  }

  /**
   * Get current tracking-id.
   * if it doesn't set yet, it will generate a new tracking-id.
   * @returns string tracking-id
   */
  getId = (): string => {
    const browserId = this.browserId || this.regenerateBrowserSessionId();
    const tabSessionId = this.tabSessionId || this.regenerateTabSessionId();

    return `${browserId}:${tabSessionId}`;
  }

  /**
   * Refresh the tracking id,
   * can be used for example after a user logged out
   * @returns string new tracking-id
   */
  regenerateTrackingId = (): string => {
    this.regenerateBrowserSessionId();
    this.regenerateTabSessionId();

    return this.getId();
  }

  /**
   * Regenerate a new browser-id, and save it in cookies.
   * @returns string new browser-id
   */
  private regenerateBrowserSessionId = (): string => {
    const newId = v4();

    this.saveBrowserSessionId(newId);
    this.browserId = newId;

    return this.browserId;
  }

  private loadBrowserSessionId = () => {
    const idFromLocalStorage = localStorage.getItem(this.COOKIE_NAME);

    if (idFromLocalStorage) {
      this.logger.debug('browser-id loaded from local-storage', {
        browserId: idFromLocalStorage,
      });

      return idFromLocalStorage;
    }

    const idFromCookie = Cookies.get(this.COOKIE_NAME);

    if (idFromCookie) {
      this.logger.debug('browser-id loaded from cookies', {
        browserId: idFromCookie,
      });

      return idFromCookie;
    }

    this.logger.debug('browser-id was not found');

    return null;
  };

  /**
   * Save browser-id to cookie&local storage
   * @param newId browser-id to save
   */
  private saveBrowserSessionId = (newId: string): void => {
    const rootOrigin = `.${AppRouting.getRootOrigin()}`;

    // we want to keep the newId also in cookie storage for backward support,
    // but even more important - to support cross-subdomain id
    // (which cannot be achieved with localstorage alone)
    Cookies.set(this.COOKIE_NAME, newId, {
      domain: rootOrigin,
      expires: Number(process.env.REACT_APP_TRACKING_COOKIE_LIFETIME_DAYS) || 30,
      sameSite: 'Lax',
    });

    localStorage.setItem(this.COOKIE_NAME, newId);
  }

  /**
   * Regenerate a new tab-session-id, and save it in Session storage.
   * @returns string new tab-session-id
   */
  private regenerateTabSessionId = (): string => {
    const newId = this.generateSimpleId(8);

    sessionStorage.setItem(this.SESSION_STORAGE_KEY, newId);
    this.tabSessionId = newId;

    return this.tabSessionId;
  }

  /**
   * Generate a simple *NON-SECURED* random string
   * @param size number of characters in result
   * @returns string of random characters in length of selected size
   */
  private generateSimpleId = (size: number): string => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const totalCharacters = characters.length;
    for (let i = 0; i < size; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * totalCharacters),
      );
    }

    return result;
  }
}

export default new TrackingIdManager();
