import { useTranslation } from 'react-i18next';
import { BaseButton } from '../../../../components/Common/Buttons/base-button/base-button.component';
import { THEME } from '../../../../themes';
import STRING_KEYS from '../../../../language/keys';

type CreatePositionDialogExitButtonProps = {
  onClick: () => void,
}
export const CreatePositionDialogExitButton = ({ onClick }: CreatePositionDialogExitButtonProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseButton
      onClick={ onClick }
      buttonText={ translate(STRING_KEYS.EXIT) }
      style={ {
        textColor: THEME.COLORS.CONTENT_A,
        backgroundColor: THEME.COLORS_RGB.PRIMARY_DARK_GHOST,
        uppercase: true,
      } }
    />
  );
};
