import styled from 'styled-components';
import TextEditorHeader from '../text-editor-header.component';
import { hexToRgbA } from '../../../../../../utils/colors';
import { THEME } from '../../../../../../themes';
import { ASSETS } from '../../../../../../assets';
import BaseButton from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import IconButton from '../../../../../../components/Common/Buttons/icon-button/icon-button.component';

export const Container = styled(TextEditorHeader)<TextEditorHeaderProps & { backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => hexToRgbA(backgroundColor || THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER)};
`;

export const AlertActionButton = styled(BaseButton)`
  background-color: transparent;
  color: ${THEME.COLORS.ACCENT};
  font-weight: 600;
  align-self: center;
`;

export const AlertActionIconButton = styled(IconButton)`
  height: 24px;
  width: 24px;
  background-color: ${THEME.COLORS.ACCENT};
  border-radius: ${THEME.RADIUS.CIRCLE};
  align-self: center;
`;

export const LinkedinIcon = styled(ASSETS.LINKEDIN_COMPONENT)`
  width: 16px;
  height: 16px;
  
  & rect {
    fill: ${THEME.COLORS.ACCENT};
  }
`;

export const MissingEmailAlertIcon = styled(ASSETS.QUESTION_FILLED)`
  fill: ${THEME.COLORS.ACCENT};
  width: 24px;
  height: 24px;
`;
