import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import STRING_KEYS from '../../../../../language/keys';
import { useGetPositionAnalytics } from '../../../../../hooks/queries';
import { PositionAnalytics } from '../../../../../models/dto/response/position/position-analytics-response.dto';
import AcceptanceRateInsightCard from './acceptance-rate-insight-card/acceptance-rate-insight-card.component';
import ScreeningRateInsightCard from './screening-rate-insight-card/screening-rate-insight-card.component';
import TimeSavedInsightCard from './time-saved-insight-card/time-saved-insight-card.component';
import { InsightsAnimatedWrapper, InsightsCardsContainer, InsightsTitle } from './insights-cards.styled';
import EngagedInsightCard from './engaged-insight-card/engaged-insight-card.component';

const InsightsCards = ({ positionId }: InsightsCardsProps):JSX.Element => {
  const { t: translate } = useTranslation();
  const { data = {} as PositionAnalytics, isLoading } = useGetPositionAnalytics(positionId);
  const {
    engagedMatches = [],
    acceptanceRate,
    matchesRarity,
    matchesTimeSavedInMinutes,
  } = data;

  const engagedMatchesIcons = useMemo(() => (
    engagedMatches.map((match) => {
      return {
        profileImage: match.talent.profileImage,
        userName: `${match.talent.firstName} ${match.talent.lastName}`,
      };
    })
  ), [engagedMatches]);

  const renderInsights = useCallback(() => {
    if (isLoading) {
      return null;
    }
    let insightsIndex = 0;

    return (
      <>
        { !!(engagedMatchesIcons.length) && (
          <InsightsAnimatedWrapper
            index={ insightsIndex++ }
          >
            <EngagedInsightCard
              positionId={ positionId }
              icons={ engagedMatchesIcons }
            />
          </InsightsAnimatedWrapper>
        )}
        { acceptanceRate && (
          <InsightsAnimatedWrapper
            index={ insightsIndex++ }
          >
            <AcceptanceRateInsightCard
              positionId={ positionId }
              acceptanceRate={ acceptanceRate }
            />
          </InsightsAnimatedWrapper>
        )}
        {
          matchesRarity && (
            <InsightsAnimatedWrapper
              index={ insightsIndex++ }
            >
              <ScreeningRateInsightCard
                positionId={ positionId }
                numerator={ matchesRarity.numerator }
                denominator={ matchesRarity.denominator }
              />
            </InsightsAnimatedWrapper>
          )
        }
        { matchesTimeSavedInMinutes && (
          <InsightsAnimatedWrapper
            index={ insightsIndex++ }
          >
            <TimeSavedInsightCard
              positionId={ positionId }
              time={ matchesTimeSavedInMinutes }
            />
          </InsightsAnimatedWrapper>
        )}
      </>
    );
  }, [isLoading, engagedMatchesIcons, positionId, acceptanceRate, matchesRarity, matchesTimeSavedInMinutes]);

  return (
    <>
      <InsightsTitle>{ translate(STRING_KEYS.DAILY_LIMIT.INSIGHTS.TITLE) }</InsightsTitle>
      <>
        <InsightsCardsContainer>
          { renderInsights() }
        </InsightsCardsContainer>
      </>
    </>
  );
};

export default InsightsCards;
