import { ApiError } from '../../../../../consts/api';
import { CreateCustomAtsIntegrationRequest } from '../dto/request/create-custom-ats-integration.request';
import { atsIntegrationQuery } from '../ats-integration.toolkit-api';
import { AtsIntegrationResponse } from '../dto/response/ats-integration.response';

type UseCreateCustomAtsIntegrationResponseType = [
  (requestArguments: CreateCustomAtsIntegrationRequest) => void,
  AppSelectorType<AtsIntegrationResponse, ApiError>
];

export const useCreateCustomAtsIntegrationMutation = (): UseCreateCustomAtsIntegrationResponseType => {
  const [
    createCustomAtsIntegration,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = atsIntegrationQuery.useCreateCustomAtsIntegrationMutation();

  const meta = {
    isLoading, isError, error: error as ApiError, isSuccess, data: data || {} as AtsIntegrationResponse, resetState: reset,
  };

  return [
    createCustomAtsIntegration,
    { ...meta },
  ];
};
