import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import SelectionPopup from '../selection-popup/selection-popup.container';
import STRING_KEYS from '../../../../../../../language/keys';
import ToggleButton from '../../toggle-button/toggle-button.component';
import {
  Container, InputContainer, Label, ToggleContainer,
} from './select-days-popup.styled';

const SelectDaysPopup = ({ onConfirmClickHandler, days, isWorkDays }: SelectDaysPopupProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [inputValue, setInputValue] = useState<string>(days || '3');
  const [workDaysToggle, setWorkDaysToggle] = useState<boolean>(isWorkDays);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onConfirmClick = () => {
    onConfirmClickHandler(inputValue, workDaysToggle);
  };

  return (
    <SelectionPopup
      title={ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_DAYS.TITLE) }
      description={ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_DAYS.DESCRIPTION) }
      withFooterAction
      actionHandler={ onConfirmClick }
    >
      <Container>
        <InputContainer
          type="number"
          min="1"
          max="60"
          value={ inputValue }
          onChange={ onInputChange }
          EndAdornment={ <Label>{ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_DAYS.INPUT_LABEL) }</Label> }
        />
        <ToggleContainer>
          <ToggleButton
            checked={ workDaysToggle }
            setChecked={ setWorkDaysToggle }
            label={ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_DAYS.TOGGLE_LABEL) }
          />
        </ToggleContainer>
      </Container>
    </SelectionPopup>
  );
};

export default SelectDaysPopup;
