import { MatchResponse } from '../../../../store/rtk-query/apis/match/dto/response/match.response';
import { MATCH_STATUSES } from '../../../../consts';

type GroupedMatches = {
  engagedMatches: MatchResponse[],
  toReviewMatches: MatchResponse[],
}

export const sortMatches = (matches: MatchResponse[]): MatchResponse[] => {
  return matches.sort((m1, m2) => {
    const exactAttributesSumM1 = (m1.explainability?.relevantSkills?.length || 0) + (m1.explainability?.companyInsights?.length || 0);
    const exactAttributesSumM2 = (m2.explainability?.relevantSkills?.length || 0) + (m2.explainability?.companyInsights?.length || 0);

    if (exactAttributesSumM1 !== exactAttributesSumM2) {
      return exactAttributesSumM2 - exactAttributesSumM1;
    }

    const similarAttributesSumM1 = (m1.explainability?.similarSkills?.length || 0) + (m1.explainability?.similarCompanyInsights?.length || 0);
    const similarAttributesSumM2 = (m2.explainability?.similarSkills?.length || 0) + (m2.explainability?.similarCompanyInsights?.length || 0);

    return similarAttributesSumM2 - similarAttributesSumM1;
  });
};

export const groupMatches = (matches: MatchResponse[]): GroupedMatches => {
  return matches.reduce<{
    engagedMatches: MatchResponse[],
    toReviewMatches: MatchResponse[],
  }>((acc, match) => {
    if (match.engagement?.status !== MATCH_STATUSES.TO_REVIEW) {
      acc.engagedMatches.push(match);
    } else {
      acc.toReviewMatches.push(match);
    }

    return acc;
  }, {
    engagedMatches: [],
    toReviewMatches: [],
  });
};

export const addEngagedMatchesIdSet = (engagedMatchesIdSet: Set<string>, matchId: string): Set<string> => {
  const setClone = new Set(engagedMatchesIdSet);
  setClone.add(matchId);

  return setClone;
};
