import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import STRING_KEYS from '../../../../../language/keys';
import {
  DiamondIcon, LabelSoonPosition, SoonContainer, SoonLabel, StyledBaseRealizationMetrics,
} from './talent-rarity.styled';

const TalentRarity = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <SoonContainer>
      <LabelSoonPosition>
        <SoonLabel>
          { translate(STRING_KEYS.COMING_SOON) }
        </SoonLabel>
      </LabelSoonPosition>
      <StyledBaseRealizationMetrics
        label={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.TALENT_RARITY.LABEL) }
        LabelIcon={ DiamondIcon }
        title={ (
          <Trans
            i18nKey={ STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.TALENT_RARITY.TITLE }
          >
            <strong />
          </Trans>
        ) }
        description={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.TALENT_RARITY.DESCRIPTION) }
      />
    </SoonContainer>
  );
};

export default TalentRarity;
