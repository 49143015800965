import { useMemo } from 'react';
import { experienceAggregation } from '../../../../../components/match/match-content/match-content.utils';
import ExperienceContainer from './experience-container/experience-container.component';
import EducationContainer from './education-container/education-container.component';
import { Container } from './timeline.styled';

const Timeline = ({ talent }: TimelineProps): JSX.Element => {
  const experience = useMemo(() => experienceAggregation(talent.employmentHistory).reverse() || [], [talent.employmentHistory]);

  return (
    <Container>
      <ExperienceContainer items={ experience } />
      <EducationContainer items={ talent.educationHistory || [] } />
    </Container>
  );
};

export default Timeline;
