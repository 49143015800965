import { MatchResponse } from '../../store/rtk-query/apis/match/dto/response/match.response';
import { getCurrentYearMonthObj } from '../../utils';
import { MatchEmploymentHistoryRecord } from '../../@types/api';

const employmentFormatter = (employmentHistory: MatchEmploymentHistoryRecord[]):
MatchEmploymentHistoryRecord[] => {
  return employmentHistory.map((item) => {
    return {
      ...item,
      startDateObject: item?.startDateObject || getCurrentYearMonthObj(),
    };
  });
};

// todo: in the future, may use immer
export const dataFormatter = (data: MatchResponse): MatchResponse => {
  const formattedEmploymentHistory = employmentFormatter(data.talent.employmentHistory || []);

  return {
    ...data,
    talent: {
      ...data.talent,
      employmentHistory: formattedEmploymentHistory,
    },
  };
};
