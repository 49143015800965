import { Container } from './styled';

type FooterProps = {
  children: JSX.Element | JSX.Element[],
  width?:string,
}

const ModalFooter = ({
  children,
  width,
}: FooterProps): JSX.Element => {
  return (
    <Container width={ width }>
      { children }
    </Container>
  );
};

ModalFooter.defaultProps = {
  width: undefined,
};

export default ModalFooter;
