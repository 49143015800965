import Tooltip from '../../../../../components/tooltip/tooltip.component';
import {
  BodyContainer,
  Container,
  Description,
  HeaderContainer,
  Label, LabelContainer,
  QuestionIcon,
  Title, TooltipWrapper,
} from './base-component.styled';
import { BaseRealizationMetricsProps } from './base-component.d';

const BaseRealizationMetrics = ({
  label, LabelIcon, title, description, tooltipText, ...otherProps
}: BaseRealizationMetricsProps): JSX.Element => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Container { ...otherProps }>
      <HeaderContainer>
        <LabelContainer>
          <LabelIcon />
          <Label>{ label }</Label>
        </LabelContainer>
        { tooltipText && (
          <Tooltip
            content={ tooltipText }
          >
            <TooltipWrapper>
              <QuestionIcon />
            </TooltipWrapper>
          </Tooltip>
        )}
      </HeaderContainer>
      <BodyContainer>
        <Title>
          { title }
        </Title>
        <Description>
          { description }
        </Description>
      </BodyContainer>
    </Container>
  );
};

export default BaseRealizationMetrics;
