import { useQuery, UseQueryResult } from 'react-query';
import log from 'loglevel';
import { searchJobTitles } from '../services/create-position.network-requests';

const logger = log.getLogger('CREATE_POSITION_QUERIES');
const USE_GET_JOB_TITLES = 'USE_GET_JOB_TITLES';

export const useGetJobTitles = (jobTitleSearchTerm: string):
  UseQueryResult<PositionTitle[]> => {
  return useQuery([USE_GET_JOB_TITLES, jobTitleSearchTerm], async () => {
    if (jobTitleSearchTerm && jobTitleSearchTerm.length > 2) {
      return searchJobTitles(jobTitleSearchTerm);
    }

    return [] as PositionTitle[];
  }, {
    onSuccess: () => {
      logger.debug('loaded job titles successfully', { jobTitleSearchTerm });
    },
    onError: (error) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const errorStatusCode = error?.response?.status || 999;
      const isInternalServerError = errorStatusCode > 499;
      if (isInternalServerError) {
        logger.error('Failed to load job titles. status: ', errorStatusCode, error, { jobTitleSearchTerm, isInternalServerError });
      } else {
        logger.warn('Failed to load job titles. status: ', errorStatusCode, error, { jobTitleSearchTerm, isInternalServerError });
      }
    },
    retry: 0,
  });
};
