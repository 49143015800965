import { PreferencesContentSectionProps } from './preferences-content-section';
import { Container, Divider } from './preferences-content-section.styled';

const PreferencesContentSection = ({
  disableDivider,
  children,
  ...otherProps
}: PreferencesContentSectionProps): JSX.Element => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Container { ...otherProps } >
      { !disableDivider &&  <Divider />}
      { children }
    </Container>
  );
};
export default PreferencesContentSection;
