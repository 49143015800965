import {
  Container, Description, IconWrapper, StyledCheckbox, TextContainer, Title,
} from './checkbox-item.styled';
import { CheckboxItemProps } from './checkbox-item';

const CheckboxItem = <T, >({
  id,
  Icon,
  title,
  description,
  onClick,
  selected,
  size,
}: CheckboxItemProps<T>): JSX.Element => {
  return (
    <Container
      onClick={ () => onClick(id) }
      isActive={ selected }
      size={ size }
    >
      <StyledCheckbox checkboxSize={ size } checked={ selected } />
      <TextContainer>
        { Icon && (
          <IconWrapper>
            { Icon }
          </IconWrapper>
        ) }
        <div>
          <Title>{ title }</Title>
          { description && <Description>{ description }</Description> }
        </div>
      </TextContainer>
    </Container>
  );
};

export default CheckboxItem;
