import styled, { css } from 'styled-components';
import { inputLabelClasses, TextField } from '@mui/material';
import { THEME } from '../../../../themes';
import { Body1Font, Title3Font } from '../../../../themes/fonts';
import { hexToRgbA } from '../../../../utils/colors';
import { InputSize } from './input.component';

const typingStyle = css`
  min-width: 400px;
  color: ${THEME.COLORS.PRIMARY_DARK};
  font-weight: 300;
  border-bottom: 1px dashed transparent;
  @media (max-height: ${THEME.BREAKPOINTS.HEIGHT.SMALL}px) {
    min-width: 200px;
  }
`;

const preFilledStyle = css`
  color: ${THEME.COLORS_RGB.PRIMARY_DARK_DIVIDER};
  font-weight: normal;
  border-bottom: 1px dashed transparent;
`;

const hoveredStyle = css`
  color: ${THEME.COLORS_RGB.PRIMARY_DARK_LOW};
  font-weight: normal;
  border-bottom: 1px dashed ${THEME.COLORS.GREY};
`;

const activeStyle = css`
  color: ${THEME.COLORS_RGB.PRIMARY_DARK_LOW};
  font-weight: 300;
  border-bottom: 1px dashed transparent;
`;

const errorInputStyle = css`
  color: ${THEME.COLORS_RGB.ALERT_045};
  font-weight: normal;
  border-bottom: 1px dashed ${THEME.COLORS.ALERT};
`;

const inputFilledStyle = css`
  color: ${THEME.COLORS.ACCENT};
  font-weight: normal;
  border-bottom: 1px dashed transparent;

`;

const getInputStyle = (
  isError: boolean,
  isPreFilled: boolean,
  emptyActive: boolean,
  typingState: boolean,
) => {
  if (isError) {
    return errorInputStyle;
  }
  if (isPreFilled) {
    return preFilledStyle;
  }
  if (emptyActive) {
    return activeStyle;
  }
  if (typingState) {
    return typingStyle;
  }

  return inputFilledStyle;
};

export const getHoveredPlaceholderColor = (
  isInputFocused: boolean,
  isError: boolean,
): string => {
  if (isError) {
    return THEME.COLORS_RGB.ALERT_045;
  }
  if (!isInputFocused) {
    return THEME.COLORS_RGB.PRIMARY_DARK_LOW;
  }

  return THEME.COLORS_RGB.PRIMARY_DARK_DIVIDER;
};

export const OnboardingInput = styled.input<{
    width: number,
    isDisableTyping: boolean,
    isInputFocused: boolean,
    isListOpened: boolean,
    isError: boolean,
    isPreFilled: boolean,
    isHovered: boolean
    emptyActive: boolean,
    typingState: boolean,
}>`
  caret-color: ${({ isDisableTyping }) => isDisableTyping ?
    'transparent' : THEME.COLORS.ACCENT};
  font-family: Merriweather;
  font-size: inherit;
  height: 45px;
  border: none;
  position: relative;
  background-color: transparent;
  transition: all 100ms ease-in;
  transition-delay: 100ms;
  width: ${({ width }) => `${width}px`};
  min-width: ${({ isListOpened }) => isListOpened ? '400px' : 0};
  box-sizing: content-box;
  left: 2px;
  ${({
    isError,
    isPreFilled,
    emptyActive,
    typingState,
  }) => getInputStyle(
    isError,
    isPreFilled,
    emptyActive,
    typingState,
  )};

  :focus {
    min-width: 400px;
    outline: none;
    @media (max-height: ${THEME.BREAKPOINTS.HEIGHT.SMALL}px) {
      min-width: 200px;
    }
  }
  ::placeholder {
    color:  ${({ isError }) => isError ?
    THEME.COLORS_RGB.ALERT_045 :
    THEME.COLORS_RGB.PRIMARY_DARK_DIVIDER};
    transition: all 100ms ease-in;
  }
  
  &:focus::-webkit-input-placeholder{
    color: ${THEME.COLORS_RGB.PRIMARY_DARK_DIVIDER};
   }
   
  :hover {
    ${({ isHovered }) => isHovered ? hoveredStyle : ''}; 
    ::placeholder {
    color: ${({ isInputFocused, isError }) => getHoveredPlaceholderColor(isInputFocused, isError)}
  }
  }
  @media (max-height: ${THEME.BREAKPOINTS.HEIGHT.SMALL}px) {
    ${Title3Font};
  }
`;

export const DefaultTextFieldWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const DefaultTextField = styled(TextField)<{ isError: boolean, inputSize?: InputSize, hasLabel?: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: ${THEME.RADIUS.ROUNDISH};

  & .MuiFilledInput-root {
    font-family: 'Gilroy', serif;
    height: ${({ inputSize }) => inputSize === InputSize.Small ? 48 : 56}px;
    ${Body1Font};
    border-radius: ${THEME.RADIUS.ROUNDISH};
    background-color: ${THEME.COLORS.PURE_WHITE};
    border: 1px solid ${({ isError }) => isError ? THEME.COLORS.ALERT : hexToRgbA(THEME.COLORS.PRIMARY, THEME.OPACITY.DIVIDER)};
    color: ${THEME.COLORS.CONTENT_A};

    &:hover {
      background-color: ${THEME.COLORS.PURE_WHITE};
      border-color: ${({ isError }) => isError ? THEME.COLORS.ALERT : THEME.COLORS.ACCENT};
    },
  }

  & .MuiFilledInput-root.Mui-focused {
    background-color: ${THEME.COLORS.PURE_WHITE};
    border-color: ${({ isError }) => isError ? THEME.COLORS.ALERT : THEME.COLORS.ACCENT};
  }
  
  & .MuiInputBase-input:not([value=""]) {
    font-weight: 600;
  }
  
  & .MuiInputBase-input {
    padding-bottom: ${({ hasLabel }) => hasLabel ? undefined : 0};
    padding-top: ${({ hasLabel }) => hasLabel ? undefined : 0};
  }
  
  & .MuiInputLabel-root {
    color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.MED)};
    
    &.${inputLabelClasses.shrink} {
      color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.MED)};
  }
}`;

export const StartAdornmentContainer = styled.span`
  display: flex;
  align-items: center;
  padding-right: ${THEME.SPACING.TINY};
`;

export const EndAdornmentContainer = styled.span`
  padding-left: 16px;
`;
