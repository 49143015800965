import { Trans, useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import {
  PerfectButtonHoverVariants,
  PerfectButtonVariants,
} from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../../../language/keys';
import { TimeAgoItem } from '../../../../../../components/Common/TimeAgoItem';
import { ANIMATED_FLUENT_UI_EMOJIS } from '../../../../../../assets';
import {
  ActionButton,
  ActionButtonIcon,
  ManualModeEditorHeader,
} from './manual-mode-text-editor-header.styled';

const HeaderImg = () => (
  <img
    src={ ANIMATED_FLUENT_UI_EMOJIS.ANIMATED_ALARM }
    alt="Alarm"
    width="30"
    height="30"
  />
);

const ManualModeTextEditorHeader = ({ lastMessageSendTime, automateFollowupsButtonClickHandler }: ManualModeTextEditorHeaderProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const isConversationEmpty = useMemo(() => !lastMessageSendTime, [lastMessageSendTime]);

  const renderManualModeHeaderAdornment = () => {
    return (
      <ActionButton
        isConversationEmpty={ isConversationEmpty }
        variant={ PerfectButtonVariants.Ghost }
        StartIcon={ ActionButtonIcon }
        hoverVariant={ PerfectButtonHoverVariants.Grow }
        onClick={ () => automateFollowupsButtonClickHandler?.() }
      >
        { translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MANUAL_MODE_HEADER.ACTION_BUTTON_TEXT) }
      </ActionButton>
    );
  };

  const Icon = useMemo(() => {
    if (isConversationEmpty) {
      return undefined;
    }

    return HeaderImg();
  }, [isConversationEmpty]);

  const label = useMemo(() => {
    if (isConversationEmpty || !lastMessageSendTime) {
      return undefined;
    }

    return (
      <Trans i18nKey={ STRING_KEYS.OUTREACH_PAGE.EDITOR.MANUAL_MODE_HEADER.LABEL }>
        <TimeAgoItem date={ lastMessageSendTime } />
      </Trans>
    );
  }, [isConversationEmpty, lastMessageSendTime]);

  const infoText = useMemo(() => {
    if (isConversationEmpty) {
      return translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MANUAL_MODE_HEADER.INFO_TEXT_EMPTY_CONVERSATION);
    }

    return translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MANUAL_MODE_HEADER.INFO_TEXT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConversationEmpty]);

  return (
    <ManualModeEditorHeader
      isConversationEmpty={ isConversationEmpty }
      label={ label }
      infoText={ infoText }
      Icon={ Icon }
      EndAdornment={ renderManualModeHeaderAdornment() }
    />
  );
};

export default ManualModeTextEditorHeader;
