import { useParams } from 'react-router';
import { useFormik } from 'formik';
import {
  AgencyPreferences,
  ContentPreferencesDto,
} from '../../../../store/rtk-query/apis/sequence/dto/content-preferences.dto';
import { CreateMessageContentDto, CreateSpicesDto } from '../../../../store/rtk-query/apis/sequence/dto/request/create-position-sequence-preferences.request';
import { ToneOfVoiceEnum } from '../../../../store/rtk-query/apis/sequence/enums/tone-of-voice.enum';
import {
  CreateOrUpdatePositionSequencePreferencesQueryArguments,
} from '../../../../store/rtk-query/apis/sequence/dto/query-arguments/create-or-update-position-sequence-preferences.query-arguments';
import { isEmptyObject } from '../../../../utils';
import { OutreachContentPreferencesFormikKeys } from './outreach-content-preferences.enums';

export type OutreachContentPreferencesFormikValues = {
  [OutreachContentPreferencesFormikKeys.InsiderNetwork]: boolean,
  [OutreachContentPreferencesFormikKeys.LikelyhoodToMove]: boolean,
  [OutreachContentPreferencesFormikKeys.RelevantSkills]: boolean,
  [OutreachContentPreferencesFormikKeys.RelevantEducation]: boolean,
  [OutreachContentPreferencesFormikKeys.SimilarCompanies]: boolean,
  [OutreachContentPreferencesFormikKeys.ToneOfVoice]: ToneOfVoiceEnum | undefined,
  [OutreachContentPreferencesFormikKeys.AboutTheCompany]: string,
  [OutreachContentPreferencesFormikKeys.PreferredCompanyName]: string,
  [OutreachContentPreferencesFormikKeys.PreferredPositionLocation]: string,
  [OutreachContentPreferencesFormikKeys.PreferredJobTitle]: string,
  [OutreachContentPreferencesFormikKeys.AboutTheRole]: string,
  [OutreachContentPreferencesFormikKeys.Extras]: string,
  [OutreachContentPreferencesFormikKeys.AgencyName]: string,
  [OutreachContentPreferencesFormikKeys.AboutAgency]: string,
  [OutreachContentPreferencesFormikKeys.AgencyModeEnabled]: boolean,
  [OutreachContentPreferencesFormikKeys.HiringCompanyNameConfidential]: boolean,
  [OutreachContentPreferencesFormikKeys.MessageCharactersLength]: number | undefined,
  [OutreachContentPreferencesFormikKeys.IncludeEmailSignature]: boolean,
  [OutreachContentPreferencesFormikKeys.IncludeEmailTracking]: boolean,
};

export type OutreachContentPreferencesFormik = OutreachContentPreferencesFormikValues & {
  setValueByKey: (key: OutreachContentPreferencesFormikKeys, value: unknown) => void,
  save: () => Promise<void>;
  dirty: boolean,
};

const generateInitialValue = (params: {preferences?: ContentPreferencesDto; accountName?: string; jobTitle?: string;}): OutreachContentPreferencesFormikValues => {
  const defaultValue = {
    [OutreachContentPreferencesFormikKeys.ToneOfVoice]: params.preferences?.toneOfVoice || ToneOfVoiceEnum.Auto,
    [OutreachContentPreferencesFormikKeys.AboutTheCompany]: params.preferences?.messageContent?.aboutTheCompany || '',
    [OutreachContentPreferencesFormikKeys.PreferredCompanyName]: params.preferences?.messageContent?.preferredCompanyName || params.accountName || '',
    [OutreachContentPreferencesFormikKeys.PreferredJobTitle]: params.preferences?.messageContent?.preferredJobTitle || params.jobTitle || '',
    [OutreachContentPreferencesFormikKeys.PreferredPositionLocation]: params.preferences?.messageContent?.preferredPositionLocation || '',
    [OutreachContentPreferencesFormikKeys.AboutTheRole]: params.preferences?.messageContent?.aboutTheRole || '',
    [OutreachContentPreferencesFormikKeys.Extras]: params.preferences?.messageContent?.extras || '',
    [OutreachContentPreferencesFormikKeys.AgencyName]: params.preferences?.messageContent?.agencyPreferences?.agencyName || '',
    [OutreachContentPreferencesFormikKeys.AboutAgency]: params.preferences?.messageContent?.agencyPreferences?.aboutAgency || '',
    [OutreachContentPreferencesFormikKeys.AgencyModeEnabled]: params.preferences?.messageContent?.agencyPreferences?.agencyModeEnabled || false,
    [OutreachContentPreferencesFormikKeys.HiringCompanyNameConfidential]: params.preferences?.messageContent?.hiringCompanyNameConfidential || false,
    [OutreachContentPreferencesFormikKeys.MessageCharactersLength]: params.preferences?.messageContent?.messageCharactersLength,
    [OutreachContentPreferencesFormikKeys.IncludeEmailSignature]: params.preferences?.messageContent?.includeEmailSignature || false,
    [OutreachContentPreferencesFormikKeys.IncludeEmailTracking]: params.preferences?.messageContent?.includeEmailTracking || false,
  };

  if (params.preferences?.spices) {
    return {
      ...defaultValue,
      [OutreachContentPreferencesFormikKeys.InsiderNetwork]: params.preferences?.spices.insiderNetwork,
      [OutreachContentPreferencesFormikKeys.LikelyhoodToMove]: params.preferences?.spices.likelyhoodToMove,
      [OutreachContentPreferencesFormikKeys.RelevantSkills]: params.preferences?.spices?.relevantSkills,
      [OutreachContentPreferencesFormikKeys.RelevantEducation]: params.preferences?.spices?.relevantEducation,
      [OutreachContentPreferencesFormikKeys.SimilarCompanies]: params.preferences?.spices?.similarCompanies,
    };
  }

  return {
    ...defaultValue,
    [OutreachContentPreferencesFormikKeys.InsiderNetwork]: true,
    [OutreachContentPreferencesFormikKeys.LikelyhoodToMove]: true,
    [OutreachContentPreferencesFormikKeys.RelevantSkills]: true,
    [OutreachContentPreferencesFormikKeys.RelevantEducation]: true,
    [OutreachContentPreferencesFormikKeys.SimilarCompanies]: true,
  };
};

export const getMessageSpices = (values: Partial<OutreachContentPreferencesFormikValues>): CreateSpicesDto | undefined => {
  return {
    insiderNetwork: values[OutreachContentPreferencesFormikKeys.InsiderNetwork] || false,
    likelyhoodToMove: values[OutreachContentPreferencesFormikKeys.LikelyhoodToMove] || false,
    relevantSkills: values[OutreachContentPreferencesFormikKeys.RelevantSkills] || false,
    relevantEducation: values[OutreachContentPreferencesFormikKeys.RelevantEducation] || false,
    similarCompanies: values[OutreachContentPreferencesFormikKeys.SimilarCompanies] || false,
  };
};

export const getAgencyPreferences = (values: Partial<OutreachContentPreferencesFormikValues>): AgencyPreferences | undefined => {
  const agencyPreferences: AgencyPreferences = {} as AgencyPreferences;

  agencyPreferences.agencyModeEnabled = values[OutreachContentPreferencesFormikKeys.AgencyModeEnabled] || false;

  if (values[OutreachContentPreferencesFormikKeys.AgencyName]) {
    agencyPreferences.agencyName = values[OutreachContentPreferencesFormikKeys.AgencyName];
  }

  if (values[OutreachContentPreferencesFormikKeys.AboutAgency]) {
    agencyPreferences.aboutAgency = values[OutreachContentPreferencesFormikKeys.AboutAgency];
  }

  if (isEmptyObject(agencyPreferences)) {
    return undefined;
  }

  return agencyPreferences;
};

export const getMessageContent = (values: Partial<OutreachContentPreferencesFormikValues>): CreateMessageContentDto | undefined => {
  const messageContent: CreateMessageContentDto = {};
  if (values[OutreachContentPreferencesFormikKeys.AboutTheCompany]) {
    messageContent.aboutTheCompany = values[OutreachContentPreferencesFormikKeys.AboutTheCompany];
  }

  if (values[OutreachContentPreferencesFormikKeys.PreferredCompanyName]) {
    messageContent.preferredCompanyName = values[OutreachContentPreferencesFormikKeys.PreferredCompanyName];
  }

  if (values[OutreachContentPreferencesFormikKeys.PreferredJobTitle]) {
    messageContent.preferredJobTitle = values[OutreachContentPreferencesFormikKeys.PreferredJobTitle];
  }

  if (values[OutreachContentPreferencesFormikKeys.PreferredPositionLocation]) {
    messageContent.preferredPositionLocation = values[OutreachContentPreferencesFormikKeys.PreferredPositionLocation];
  }

  if (values[OutreachContentPreferencesFormikKeys.AboutTheRole]) {
    messageContent.aboutTheRole = values[OutreachContentPreferencesFormikKeys.AboutTheRole];
  }

  if (values[OutreachContentPreferencesFormikKeys.Extras]) {
    messageContent.extras = values[OutreachContentPreferencesFormikKeys.Extras];
  }

  if (values[OutreachContentPreferencesFormikKeys.IncludeEmailSignature]) {
    messageContent.includeEmailSignature = values[OutreachContentPreferencesFormikKeys.IncludeEmailSignature];
  }

  if (values[OutreachContentPreferencesFormikKeys.IncludeEmailTracking]) {
    messageContent.includeEmailTracking = values[OutreachContentPreferencesFormikKeys.IncludeEmailTracking];
  }

  if (values[OutreachContentPreferencesFormikKeys.HiringCompanyNameConfidential]) {
    messageContent.hiringCompanyNameConfidential = values[OutreachContentPreferencesFormikKeys.HiringCompanyNameConfidential];
  }

  if (values[OutreachContentPreferencesFormikKeys.MessageCharactersLength]) {
    messageContent.messageCharactersLength = values[OutreachContentPreferencesFormikKeys.MessageCharactersLength];
  }

  messageContent.agencyPreferences = getAgencyPreferences(values);

  return messageContent;
};

export const useOutreachContentPreferencesFormik = (
  params: {
    onSubmitHandler: (positionSequencePreferences: CreateOrUpdatePositionSequencePreferencesQueryArguments) => void;
    contentPreferences?: ContentPreferencesDto;
    accountName?: string;
    jobTitle?: string;
  },
): OutreachContentPreferencesFormik => {
  const { positionId } = useParams<MatchUrlParams>();
  const initialValues = generateInitialValue({ preferences: params.contentPreferences, accountName: params.accountName, jobTitle: params.jobTitle });
  const formik = useFormik<OutreachContentPreferencesFormikValues>({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values: OutreachContentPreferencesFormikValues) => {
      const responseArguments = {
        positionId,
        data: {
          contentPreferences: {
            toneOfVoice: values.toneOfVoice || undefined,
            messageContent: getMessageContent(values),
            spices: getMessageSpices(values),
          },
        },
      };

      return params.onSubmitHandler(responseArguments);
    },
  });

  return {
    [OutreachContentPreferencesFormikKeys.InsiderNetwork]: formik.values[OutreachContentPreferencesFormikKeys.InsiderNetwork],
    [OutreachContentPreferencesFormikKeys.LikelyhoodToMove]: formik.values[OutreachContentPreferencesFormikKeys.LikelyhoodToMove],
    [OutreachContentPreferencesFormikKeys.RelevantSkills]: formik.values[OutreachContentPreferencesFormikKeys.RelevantSkills],
    [OutreachContentPreferencesFormikKeys.RelevantEducation]: formik.values[OutreachContentPreferencesFormikKeys.RelevantEducation],
    [OutreachContentPreferencesFormikKeys.SimilarCompanies]: formik.values[OutreachContentPreferencesFormikKeys.SimilarCompanies],
    [OutreachContentPreferencesFormikKeys.ToneOfVoice]: formik.values[OutreachContentPreferencesFormikKeys.ToneOfVoice],
    [OutreachContentPreferencesFormikKeys.AboutTheCompany]: formik.values[OutreachContentPreferencesFormikKeys.AboutTheCompany],
    [OutreachContentPreferencesFormikKeys.PreferredCompanyName]: formik.values[OutreachContentPreferencesFormikKeys.PreferredCompanyName],
    [OutreachContentPreferencesFormikKeys.PreferredJobTitle]: formik.values[OutreachContentPreferencesFormikKeys.PreferredJobTitle],
    [OutreachContentPreferencesFormikKeys.PreferredPositionLocation]: formik.values[OutreachContentPreferencesFormikKeys.PreferredPositionLocation],
    [OutreachContentPreferencesFormikKeys.AboutTheRole]: formik.values[OutreachContentPreferencesFormikKeys.AboutTheRole],
    [OutreachContentPreferencesFormikKeys.Extras]: formik.values[OutreachContentPreferencesFormikKeys.Extras],
    [OutreachContentPreferencesFormikKeys.AgencyName]: formik.values[OutreachContentPreferencesFormikKeys.AgencyName],
    [OutreachContentPreferencesFormikKeys.AboutAgency]: formik.values[OutreachContentPreferencesFormikKeys.AboutAgency],
    [OutreachContentPreferencesFormikKeys.AgencyModeEnabled]: formik.values[OutreachContentPreferencesFormikKeys.AgencyModeEnabled],
    [OutreachContentPreferencesFormikKeys.HiringCompanyNameConfidential]: formik.values[OutreachContentPreferencesFormikKeys.HiringCompanyNameConfidential],
    [OutreachContentPreferencesFormikKeys.MessageCharactersLength]: formik.values[OutreachContentPreferencesFormikKeys.MessageCharactersLength],
    [OutreachContentPreferencesFormikKeys.IncludeEmailSignature]: formik.values[OutreachContentPreferencesFormikKeys.IncludeEmailSignature],
    [OutreachContentPreferencesFormikKeys.IncludeEmailTracking]: formik.values[OutreachContentPreferencesFormikKeys.IncludeEmailTracking],
    setValueByKey: (key, value) => {
      formik.setFieldValue(key, value);
    },
    save: formik.submitForm,
    dirty: formik.dirty,
  };
};
