import { ApiError } from '../../../../../consts/api';
import { positionQuery } from '../position.toolkit-api';
import { SkipPositionAssistantQueryArguments } from '../dto/query-arguments/skip-position-assistant.query-arguments';

type UseSkipMatchTuneMutationType = [
  (args: SkipPositionAssistantQueryArguments) => void,
  AppSelectorType<void, ApiError>
];

export const useSkipPositionAssistantMutation = (): UseSkipMatchTuneMutationType => {
  const [
    skipPositionAssistant,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = positionQuery.useSkipPositionAssistantMutation();

  const meta = {
    isLoading, isError, error: error as ApiError, isSuccess, data, resetState: reset,
  };

  return [
    skipPositionAssistant,
    { ...meta },
  ];
};
