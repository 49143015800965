import styled, { css } from 'styled-components';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { THEME } from '../../../../../themes';
import { ASSETS, FLUENT_UI_EMOJIS } from '../../../../../assets';

export const Button = styled(BaseButton)`
  background-color: ${THEME.COLORS.ACCENT};
`;

export const GhostButton = styled(BaseButton)`
  color: ${THEME.COLORS.ACCENT};
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINO};
`;

const CardIconStyle = css`
  width: 56px;
  height: 56px;
`;

export const LocationIcon = styled(FLUENT_UI_EMOJIS.ROUND_PUSHPIN)`
  ${CardIconStyle};
`;

export const LevelOfExperienceIcon = styled(FLUENT_UI_EMOJIS.MILITARY_MEDAL)`
  ${CardIconStyle};
`;

export const IndustryExperienceIcon = styled(FLUENT_UI_EMOJIS.OFFICE_BUILDING)`
  ${CardIconStyle};
`;

export const CompanySizeIcon = styled(FLUENT_UI_EMOJIS.DECIDUOUS_TREE)`
  ${CardIconStyle};
`;

export const SkillsIcon = styled(FLUENT_UI_EMOJIS.MAN_JUGGLING)`
  ${CardIconStyle};
`;

export const JobTitleIcon = styled(FLUENT_UI_EMOJIS.MAGNIFYING_GLASS_TILTED_LEFT)`
  ${CardIconStyle};
`;

export const EducationIcon = styled(FLUENT_UI_EMOJIS.GRADUATION_CAP)`
  ${CardIconStyle};
`;

export const PositionFeedbackIcon = styled(FLUENT_UI_EMOJIS.POSTBOX)`
  ${CardIconStyle};
`;

export const ConfirmButtonIcon = styled(ASSETS.V_SIGN)`
  width: 14px;
  height: 11px;
`;

export const SkipButtonIcon = styled(ASSETS.FORWARD)`
  height: 12px;
`;
