import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../components/tooltip/tooltip.component';
import STRING_KEYS from '../../../../language/keys';
import { translationsConst } from '../../../../store/rtk-query/consts/translations.const';
import {
  Container, ShapesIcon, Text, TextWrapper, Title,
} from './anonymize-talent-tooltip.styled';

export const AnonymizeTalentTooltipComponent = ({ children, anonymizeTalentDetails }: AnonymizeTalentTooltipProps) : JSX.Element => {
  const { t: translate } = useTranslation();
  const toolTip = () => {
    return (
      <Tooltip
        customTooltipStyle={ {
          maxWidth: '600px',
        } }
        showTooltipDelay={ 1000 }
        content={ (
          <Container>
            <ShapesIcon />
            <TextWrapper>
              <Title>
                {translate(STRING_KEYS.MATCH_TUNE.BYEBIAS_TOOLTIP_TITLE, { ByeBias: translationsConst.byeBias })}
              </Title>
              <Text>
                {translate(STRING_KEYS.MATCH_TUNE.BYEBIAS_TOOLTIP_DESCRIPTION, { ByeBias: translationsConst.byeBias })}
              </Text>
            </TextWrapper>
          </Container>
        ) }
      >
        {children}
      </Tooltip>
    );
  };
  
  return anonymizeTalentDetails ? toolTip() : children;
};
