import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { PerfectButtonHoverVariants, PerfectButtonSize } from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../../language/keys';
import Tooltip from '../../../../../components/tooltip/tooltip.component';
import { THEME } from '../../../../../themes';
import { hexToRgbA } from '../../../../../utils/colors';
import { useGetPositionSequencePreferencesSelector } from '../../../../../store/rtk-query/apis/sequence/selectors/position-sequence-preferences.selector';
import { useUpdateCustomStepsMutation } from '../../../../../store/rtk-query/apis/sequence/hooks/update-custom-steps.mutation-hook';
import EditDialog from './campaign-custom-mode-dialog/campaign-custom-mode-dialog.component';
import {
  Body,
  Container,
  Description,
  EditStepsButton,
  Header,
  InfoIcon,
  ManualLabel,
  ManualLabelContainer,
  SignatureIcon,
  StepCardContainer,
  StepText,
  Title,
  TitleContainer,
} from './campaign-custom-mode.styled';
import CustomModeStepChip from './step-chip/custom-mode-step-chip.component';
import { CampaignCustomModeProps, Step } from './campaign-custom-mode';
import { EMPTY_STEP } from './campaign-custom-mode-dialog/campaign-custom-mode-dialog.consts';
import { useCampaignCustomModeFormik } from './campaign-custom-mode.formik';
import { CUSTOM_MODE_NEXT_BUSINESS_DAY_VALUE } from './campaign-custom-mode.consts';

const CampaignCustomMode = ({
  customSteps, onCloseDialogHandler,
}: CampaignCustomModeProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const { positionId } = useParams<MatchUrlParams>();
  const [showErrors, setShowErrors] = useState(false);

  const {
    data: preferences,
  } = useGetPositionSequencePreferencesSelector(positionId);

  const [updateCustomSteps, {
    isError: isUpdateCustomStepsError,
    isLoading: isUpdateCustomStepsLoading,
    isSuccess: isUpdateCustomStepsSuccess,
    resetState,
  }] = useUpdateCustomStepsMutation();

  const {
    steps,
    touched,
    errors,
    setValueByKey,
    resetForm,
    save,
  } = useCampaignCustomModeFormik(updateCustomSteps, positionId, customSteps);

  useEffect(() => {
    setShowErrors(false);
  }, [steps]);

  useEffect(() => {
    if (isUpdateCustomStepsSuccess) {
      const updatedSteps = steps.map((step) => ({
        ...step,
        isNotSavedStep: false,
      }));
      setValueByKey(updatedSteps);
      setEditDialogOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCustomStepsSuccess]);

  const saveStepsHandler = async () => {
    if (touched && !errors?.steps?.length) {
      await save();
    } else {
      resetState?.();
      setShowErrors(true);
    }
  };

  const setEmptyStep = () => {
    const cloneSteps = [...steps];
    cloneSteps.push(EMPTY_STEP);
    setValueByKey(cloneSteps);
  };

  const changeStep = (stepIndex: number, step: Step) => {
    const cloneSteps = [...steps];
    cloneSteps[stepIndex] = step;
    setValueByKey(cloneSteps);
  };

  const removeStepHandler = (stepIndex: number) => {
    setValueByKey(steps.filter((_, index)  => index !== stepIndex));
  };

  const onCloseDialog = () => {
    if (!preferences?.sequencePreferences?.customSteps?.length) {
      resetForm();
      onCloseDialogHandler();
    }
    setEditDialogOpen(false);
  };

  const renderSteps = () => {
    if (steps[0].isNotSavedStep && !editDialogOpen) {
      setEditDialogOpen(true);

      return null;
    }

    const onlySavedSteps = steps.filter(({ isNotSavedStep }) => !isNotSavedStep);

    return onlySavedSteps.map(({
      type, days, time, isManual,
    }, index) => {
      let i18nKey;
      if (index === 0) {
        if (days === CUSTOM_MODE_NEXT_BUSINESS_DAY_VALUE) {
          i18nKey = STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.FIRST_STEP_NEXT_BUSINESS_DAY;
        } else {
          i18nKey = STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.FIRST_STEP_AS_SOON_AS_POSSIBLE;
        }
      } else {
        i18nKey = STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.N_STEP;
      }

      const transValues = {
        prevStepType: index > 0 ? onlySavedSteps[index - 1].type?.toLowerCase() : '',
        type: type?.toLowerCase(),
        days,
        time,
      };

      return (
        // eslint-disable-next-line react/no-array-index-key
        <StepCardContainer key={ index }>
          <CustomModeStepChip
            step={ index + 1 }
            color={ THEME.COLORS.CONTENT_PRIMARY }
            backgroundColor={ hexToRgbA(THEME.COLORS.CONTENT_PRIMARY, THEME.OPACITY.DIVIDER) }
          />
          <StepText>
            <Trans
              i18nKey={ i18nKey }
              values={ transValues }
            />
          </StepText>
          {isManual && (
            <ManualLabelContainer>
              <ManualLabel>{translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.MANUEL_LABEL)}</ManualLabel>
              <div>
                <Tooltip
                  content={ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.MANUEL_LABEL_INFO) }
                >
                  <InfoIcon />
                </Tooltip>
              </div>
            </ManualLabelContainer>
          )}
        </StepCardContainer>
      );
    });
  };

  return (
    <Container>
      <Header>
        <TitleContainer>
          <Title>{translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.TITLE)}</Title>
          <Description>{translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.DESCRIPTION)}</Description>
        </TitleContainer>
        <EditStepsButton
          StartIcon={ SignatureIcon }
          onClick={ () => setEditDialogOpen(true) }
          size={ PerfectButtonSize.Medium }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
        >
          {translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.EDIT_BUTTON)}
        </EditStepsButton>
      </Header>
      <Body>
        { renderSteps() }
      </Body>
      <EditDialog
        isDialogOpen={ editDialogOpen }
        closeDialogHandler={ onCloseDialog }
        steps={ steps }
        setEmptyStepHandler={ setEmptyStep }
        changeStepHandler={ changeStep }
        removeStepHandler={ removeStepHandler }
        saveStepsHandler={ saveStepsHandler }
        errors={ showErrors ? errors.steps : undefined }
        isServerError={ isUpdateCustomStepsError }
        isServerLoading={ isUpdateCustomStepsLoading }
        isSaveButtonDisabled={ !touched }
      />
    </Container>
  );
};

export default CampaignCustomMode;
