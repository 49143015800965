import styled from 'styled-components';
import { HeadlineFont } from '../../../../../themes/fonts';

export const TALENT_INFO_LINE_HEIGHT = 37;

export const TalentInfoContainer = styled.div`
  position: relative;
  font-size: ${HeadlineFont};
  font-weight: 300;
  line-height: ${TALENT_INFO_LINE_HEIGHT}px;
  word-break: break-word;
  white-space: pre-line;
`;
