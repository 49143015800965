import { sequenceQuery } from '../sequence.toolkit-api';
import { ApiError } from '../../../../../consts/api';
import { CreateConversationSequenceQueryArguments } from '../dto/query-arguments/create-conversation-sequence.query-arguments';
import { ConversationResponseDto } from '../../outreach/dto/response/conversation.response';

type UseCreateConversationSequenceMutationType = [
  (regenerateConversationSequence: CreateConversationSequenceQueryArguments) => void,
  AppSelectorType<ConversationResponseDto | undefined, ApiError>
];

export const useRegenerateConversationSequence = (): UseCreateConversationSequenceMutationType => {
  const [
    regenerateConversationSequence,
    {
      data, isLoading, isError, error: apiError, isSuccess, reset,
    }] = sequenceQuery.useRegenerateConversationSequenceMutation();

  const meta = {
    isLoading, isError, error: apiError as ApiError, isSuccess, data, resetState: reset,
  };

  return [
    regenerateConversationSequence,
    { ...meta },
  ];
};
