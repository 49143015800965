import styled from 'styled-components';
import { THEME } from '../../../themes';
import { CaptionFont, HeadlineFont, Title1Font } from '../../../themes/fonts';
import { ASSETS } from '../../../assets';
import { hexToRgbA } from '../../../utils/colors';
import { scale } from '../../../animations';

export const Container =  styled.div<{ $isActive: boolean, backgroundColor?: string }>`
  width: 192px;
  height: 176px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ $isActive, backgroundColor }) => {
    return $isActive ? hexToRgbA(backgroundColor || THEME.COLORS.PURE_WHITE, THEME.OPACITY.DIVIDER) : THEME.COLORS.PURE_WHITE;
  }};
  padding: ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.CURVY};
  border: ${({ $isActive }) => {
    return !$isActive ? `solid 1px ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.GHOST)}` : undefined;
  }};
  color: ${({ $isActive, backgroundColor }) => {
    return $isActive && backgroundColor ? backgroundColor : THEME.COLORS.PRIMARY_DARK;
  }};
  cursor: pointer;
  ${scale};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
`;

export const Title = styled.div`
  ${HeadlineFont};
  font-weight: 600;
`;

export const TitleIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 24px;
    height: auto;
    fill: ${THEME.COLORS.PRIMARY_DARK};
  }
`;

export const Body = styled.div`
  display: flex;
  margin-top: ${THEME.SPACING.XLOOSE};
`;

export const BodyIconWrapper = styled.div`
  height: 32px;
  
  & svg {
    height: 100%;
    fill: ${THEME.COLORS.PRIMARY_DARK};
  }
`;

export const Count = styled.div<{ $isActive: boolean }>`
  ${Title1Font};
  line-height: 0.8;
  font-weight: ${({ $isActive }) => $isActive ? 900 : 400};
`;

export const Footer = styled.div`
  display: flex;
  margin-top: ${THEME.SPACING.BASE};
  justify-content: space-between;
  align-items: center;
`;

export const InfoText = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.LOW};
`;

export const ActionButton = styled.div`
  ${CaptionFont};
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.15px;
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
  width: 100%;
`;

export const ActionIconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

export const ActionIcon = styled(ASSETS.ARROW_BACK_COMPONENT)<{ $isActive: boolean, color?: string }>`
  width: 24px;
  height: 24px;
  fill: ${({ $isActive, color }) => $isActive ? color || undefined : undefined};
  padding: ${THEME.SPACING.MICRO};
`;
