import { ExactLocationDto, LocationType } from '../../models/dto/location.dto';
import { ISRAEL_COUNTRY_CODE, UNITED_KINGDOM_COUNTRY_CODE } from '../../consts/countries';
import {
  GOOGLE_CITY,
  GOOGLE_COUNTRY,
  GOOGLE_STATE,
  GOOGLE_TYPES,
  GOOGLE_UNITED_KINGDOM_COUNTRY_CODE,
} from './location-autocomplete-hook.consts';

const isCity = (types: GOOGLE_TYPES[]) => types.some((type: string) => type === GOOGLE_CITY);
const isState = (types: GOOGLE_TYPES[]) => types.some((type: string) => type === GOOGLE_COUNTRY);
const isCountry = (types: GOOGLE_TYPES[]) => types.some((type: string) => type === GOOGLE_STATE);

const mapCountryName = (country: string) => {
  if (country === GOOGLE_UNITED_KINGDOM_COUNTRY_CODE) {
    return UNITED_KINGDOM_COUNTRY_CODE;
  }

  return country;
};

export const shouldShowLocationSuggestion = (location: ExactLocationDto): boolean => {
  if (location.country === ISRAEL_COUNTRY_CODE) {
    return !!location.city;
  }
  
  return true;
};

export const getFormattedLocationFromPlacePredictions = (
  place: google.maps.places.PlaceResult,
): ExactLocationDto => {
  const addressComponents = place.address_components || [];
  const addressGeometry = place.geometry;

  const onboardingLocation: ExactLocationDto = addressComponents
    .reduce((item, obj: google.maps.GeocoderAddressComponent) => {
      const typedItem = {
        ...item,
        type: LocationType.ExactLocation,
      };

      if (isCity(obj.types as GOOGLE_TYPES[])) {
        return {
          ...typedItem,
          city: obj.long_name,
        };
      }

      if (isState(obj.types as GOOGLE_TYPES[])) {
        return {
          ...typedItem,
          country: mapCountryName(obj.short_name),
          countryLong: mapCountryName(obj.long_name),
        };
      }

      if (isCountry(obj.types as GOOGLE_TYPES[])) {
        return {
          ...typedItem,
          state: obj.short_name,
          stateLong: obj.long_name,
        };
      }

      return typedItem;
    }, {} as ExactLocationDto);
  if (addressGeometry?.location) {
    onboardingLocation.geometry = {
      lat: addressGeometry.location.lat(),
      lng: addressGeometry.location.lng(),
    };
  }

  return onboardingLocation;
};
