import styled  from 'styled-components';
import { ASSETS } from '../../../../../assets';
import { iconCss } from '../base-text-editor-controls/text-alignment-controls.styled';

export const BoldIcon = styled(ASSETS.BOLD)`
  ${iconCss}
`;

export const ItalicIcon = styled(ASSETS.ITALIC)`
  ${iconCss}
`;

export const UnderlineIcon = styled(ASSETS.UNDERLINE)`
  ${iconCss}
`;
