import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { TransitionScreenWrapper } from '../../../../components/Common/transition-screen-wrapper/transition-screen-wrapper.component';
import { SlideInSentence } from '../../../../components/Common/slide-in-sentence/slide-in-sentence.component';
import {
  WELCOME_SCREEN_FIRST_SENTENCE_DELAY,
  WELCOME_SCREEN_SECOND_SENTENCE_DELAY,
  WELCOME_SCREEN_THIRD_SENTENCE_DELAY,
} from '../../../../consts/delays';
import { validateEmail } from '../../../../utils';
import { EVENT_GROUP } from '../../../../consts/analytics';
import { PAGE_NAMES } from '../../../../consts/pages';
import { CREATE_POSITION_ANALYTICS_EVENTS } from '../../../create-position/consts/position-analytics.consts';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { useMyUserSelector } from '../../../../store/rtk-query/apis/user/selectors/get-my-user.selector';

export const WelcomeUserTransitionScreen = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const { data: { firstName } } = useMyUserSelector();
  const { analyticsPageEvent } = useAnalyticsContext();
  const { data: { accountId } } = useMyUserSelector();

  const isFirstNameEmail = validateEmail(firstName || '');
  const userName = !isFirstNameEmail ? firstName : '';

  useEffect(() => {
    if (accountId) {
      analyticsPageEvent({
        eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_START_PAGE_VIEWED,
        eventGroup: EVENT_GROUP.PAGE_VIEW,
        pageName: PAGE_NAMES.START_ONBOARDING,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  return (
    <TransitionScreenWrapper>
      <SlideInSentence
        content={ translate(STRING_KEYS.ONBOARDING.WELCOME_SCREEN_HELLO,
          { name: userName }) }
        delay={ WELCOME_SCREEN_FIRST_SENTENCE_DELAY }
      />
      <SlideInSentence
        content={ translate(STRING_KEYS.ONBOARDING.WELCOME_SCREEN_STARTING_TODAY) }
        delay={ WELCOME_SCREEN_SECOND_SENTENCE_DELAY }
      />
      <SlideInSentence
        content={ translate(STRING_KEYS.ONBOARDING.WELCOME_SCREEN_EASIEST_JOB) }
        delay={ WELCOME_SCREEN_THIRD_SENTENCE_DELAY }
      />
    </TransitionScreenWrapper>
  );
};
