import { createSlice } from '@reduxjs/toolkit';
import { initialOutreachState } from './outreach-state.initial-state';
import { conversationsSortedReducers, resetConversationsSortReducers } from './reducers/conversations-sort.reducers';
import { setSequenceModeReducers } from './reducers/set-sequence-mode.reducers';

const OutreachStateSlice = createSlice({
  name: 'outreach-state',
  initialState: initialOutreachState,
  reducers: {
    conversationsSorted: conversationsSortedReducers,
    resetConversationsSort: resetConversationsSortReducers,
    setSequenceMode: setSequenceModeReducers,
  },
});

export const {
  conversationsSorted,
  resetConversationsSort,
  setSequenceMode,
} = OutreachStateSlice.actions;

export default OutreachStateSlice.reducer;
