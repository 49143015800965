import log from 'loglevel';
import STRING_KEYS from '../../../../../language/keys';
import { escapeRegExp } from '../../../../../utils';

const logger = log.getLogger('TALENT_INFO_SENTENCE_UTILS');

const prepareMatchSummaryTextHandler = (
  matchSummary: string,
  highlightWords: string[],
  currentJobTitle: string,
): string => {
  let replacedMatchSummaryText = matchSummary.replaceAll(new RegExp(`\\b${escapeRegExp(currentJobTitle)}\\b`, 'ig'), '<strong>$&</strong>');

  highlightWords?.forEach((word) => {
    replacedMatchSummaryText = replacedMatchSummaryText.replaceAll(new RegExp(`\\b${escapeRegExp(word)}\\b`, 'ig'), '<strong>$&</strong>');
  });

  return replacedMatchSummaryText;
};

export const prepareMatchSummaryText = (
  matchSummary: string,
  highlightWords: string[],
  currentJobTitle: string,
): string => {
  try {
    return prepareMatchSummaryTextHandler(matchSummary, highlightWords, currentJobTitle);
  } catch (error) {
    logger.error("Couldn't prepare match summary text", error);

    return matchSummary;
  }
};

type GetExperienceSentenceDataArgs = {
  currentJobTitle: string,
  firstName: string,
  jobTitlePrefix: string,
  currentJobExperienceInMonths?: number,
  currentCompanyName?: string,
  currentJobExperienceWithPostfix?: string,
}

type GetExperienceSentenceDataResult = {
  translationKey: string,
  options: {
    firstName: string,
    currentRole: string,
    currentRolePeriod?: string,
    currentEmployer?: string,
    currentJobTitlePrefix: string,
  },
}

export const getExperienceSentenceData = ({
  firstName,
  currentJobTitle,
  currentJobExperienceInMonths,
  currentCompanyName,
  currentJobExperienceWithPostfix,
  jobTitlePrefix,
}: GetExperienceSentenceDataArgs): GetExperienceSentenceDataResult | undefined => {
  let translationKey;
  if (currentJobExperienceInMonths && currentJobTitle && currentCompanyName) {
    translationKey = STRING_KEYS.NEW_MATCH_TALENT_BIO_SENTENCE_2_1;
  } else if (currentJobExperienceInMonths && currentJobTitle) {
    translationKey = STRING_KEYS.NEW_MATCH_TALENT_BIO_SENTENCE_2_2;
  } else if (currentJobExperienceInMonths && currentCompanyName) {
    translationKey = STRING_KEYS.NEW_MATCH_TALENT_BIO_SENTENCE_2_3;
  } else if (currentJobTitle && currentCompanyName) {
    translationKey = STRING_KEYS.NEW_MATCH_TALENT_BIO_SENTENCE_2_4;
  }

  if (!translationKey) {
    return undefined;
  }

  const options = {
    firstName,
    currentRole: currentJobTitle,
    currentRolePeriod: currentJobExperienceWithPostfix,
    currentEmployer: currentCompanyName,
    currentJobTitlePrefix: jobTitlePrefix,
  };

  return {
    translationKey,
    options,
  };
};
