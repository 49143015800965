import { isEmpty } from 'lodash';
import i18n from 'i18next';
import {
  ExactLocationDto, LocationDto, Locations, LocationType, RegionDto,
} from '../models/dto/location.dto';
import STRING_KEYS from '../language/keys';
import {
  REMOTE_POSITION,
} from '../hooks/location-autocomplete-hook/location-autocomplete-hook.consts';
import {
  FRANCE_COUNTRY_CODE, GERMANY_COUNTRY_CODE, ISRAEL_COUNTRY_CODE, UNITED_KINGDOM_COUNTRY_CODE, UNITED_STATES_COUNTRY_CODE,
} from '../consts/countries';
import { joinStrings } from './index';

export const DEFAULT_MILE_RADIUS_VALUE = 100;
export const ISRAEL_DEFAULT_MILE_RADIUS_VALUE = 25;

// TODO MULTI: use a locations object after refactor
export const hasRemoteOption = (locations: LocationDto[]): boolean => (
  locations.some((location) => {
    if (location.type === LocationType.Region) {
      return (location as RegionDto).region === location.country;
    }

    return location.country === REMOTE_POSITION.country;
  })
);

const areExactLocationsEqual = (location1: ExactLocationDto, location2: ExactLocationDto): boolean => {
  return location1.country === location2.country &&
    location1.state === location2.state &&
    location1.city === location2.city;
};

const areRegionsEqual = (region1: RegionDto, region2: RegionDto): boolean => {
  return region1.country === region2.country && region1.region === region2.region;
};

export const areLocationsEqual = (location1: LocationDto, location2: LocationDto): boolean => {
  if (location1.type !== location2.type) {
    return false;
  }

  if (location1.type === LocationType.ExactLocation) {
    return areExactLocationsEqual(location1, location2);
  }

  return areRegionsEqual(location1 as RegionDto, location2 as RegionDto);
};

export const locationsObjectToArray = (locations: Locations): LocationDto[] => {
  return [
    ...(locations?.exactLocations?.map((location) => ({ ...location, type: LocationType.ExactLocation })) || []),
    ...(locations?.regions?.map((location) => ({ ...location, type: LocationType.Region })) || []),
  ];
};

export const createLocationObject = (locations: LocationDto[]): Locations => {
  return locations?.reduce((acc: Locations, location: LocationDto) => {
    if (isEmpty(location)) {
      return acc;
    }

    if (location.type === LocationType.ExactLocation) {
      const arr = acc.exactLocations || [];
      arr.push(location as ExactLocationDto);
      acc.exactLocations = arr;
    } else {
      const arr = acc.regions || [];
      arr.push(location as RegionDto);
      acc.regions = arr;
    }

    return acc;
  }, {} as Locations) || {};
};

export const setDefaultMileRadius = (exactLocations?: ExactLocationDto[]): ExactLocationDto[] | undefined => {
  return exactLocations?.map((exactLocation) => {
    if (!exactLocation.city) {
      return exactLocation;
    }

    return {
      ...exactLocation,
      locationMileRadius: exactLocation.country === ISRAEL_COUNTRY_CODE ? ISRAEL_DEFAULT_MILE_RADIUS_VALUE : DEFAULT_MILE_RADIUS_VALUE,
    };
  }) || undefined;
};

export const formatExactLocation = (location: ExactLocationDto): string => {
  if (location?.country === ISRAEL_COUNTRY_CODE) {
    return joinStrings(location?.city, location?.countryLong);
  }

  return joinStrings(location?.city, location?.stateLong || location?.state, location?.countryLong || location?.country);
};

export const formatRegion = (location: RegionDto): string => {
  if (location.country === location.region) {
    let translationKey = STRING_KEYS.SPECIFIC_COUNTRY_REMOTE;
    if (location.country === UNITED_STATES_COUNTRY_CODE || location.country === UNITED_KINGDOM_COUNTRY_CODE) {
      translationKey = STRING_KEYS.US_UK_COUNTRY_REMOTE;
    }
    
    return i18n.t(translationKey, { countryCode: location.countryLong || location.country });
  }

  return `${location?.region}, ${location?.countryLong || location?.country}`;
};

export const formatLocations = (locationObject: Locations): string[] => {
  return [
    ...(locationObject?.exactLocations || []).map(formatExactLocation),
    ...(locationObject?.regions || []).map(formatRegion),
  ];
};

export const getFlagByCountry = (country?: string): string => {
  switch (country) {
    case ISRAEL_COUNTRY_CODE:
      return '🇮🇱';
    case UNITED_STATES_COUNTRY_CODE:
      return '🇺🇸';
    case UNITED_KINGDOM_COUNTRY_CODE:
      return '🇬🇧';
    case GERMANY_COUNTRY_CODE:
      return '🇩🇪';
    case FRANCE_COUNTRY_CODE:
      return '🇫🇷';
    default:
      return '';
  }
};

export const formatLocation = (location: LocationDto): string => {
  switch (location.type) {
    case LocationType.Region: {
      return formatRegion(location as RegionDto);
    }
    case LocationType.ExactLocation: {
      return formatExactLocation(location as ExactLocationDto);
    }
    default:
      return '';
  }
};

export const getMileRadius = (location: LocationDto): number => {
  if (location.type === LocationType.ExactLocation) {
    return (location as ExactLocationDto).locationMileRadius || DEFAULT_MILE_RADIUS_VALUE;
  }

  return DEFAULT_MILE_RADIUS_VALUE;
};
