import { useMemo } from 'react';
import { BaseTextEditorControlsProps, ButtonsInlineStyles } from '../base-text-editor-controls/base-text-editor-controls';
import BaseTextEditorControls from '../base-text-editor-controls/base-text-editor-controls.component';
import { PerfectTextEditorBlockAlignment } from '../../perfect-text-editor.enums';
import { AlignCenterIcon, AlignLeftIcon, AlignRightIcon } from './text-alignment-controls.styled';

const TextAlignmentControlsComponent = ({ currentInlineStyle, toggleHandler }: BaseTextEditorControlsProps): JSX.Element => {
  const inlineStyles = useMemo<ButtonsInlineStyles[]>(() => (
    [
      { icon: AlignLeftIcon, style: PerfectTextEditorBlockAlignment.Left },
      { icon: AlignCenterIcon, style: PerfectTextEditorBlockAlignment.Center },
      { icon: AlignRightIcon, style: PerfectTextEditorBlockAlignment.Right },
    ]
  ), []);

  return (
    <BaseTextEditorControls
      currentInlineStyle={ currentInlineStyle }
      toggleHandler={ toggleHandler }
      buttonsInlineStyles={ inlineStyles }
    />
  );
};

export default TextAlignmentControlsComponent;
