import {
  StyledSvgIcon,
} from './styled';

type SIZES = 'small' | 'medium' | 'large' | 'micro' | 'xl';

const SIZE = {
  micro: '16px',
  small: '24px',
  medium: '30px',
  large: '54px',
  xl: '64px',
};

type SvgIconProps = {
  Icon: React.FC,
  size: SIZES,
  fill?: string,
}

// TODO: delete when not in use in deleted components
function SvgIcon({ Icon, size, fill }:SvgIconProps):JSX.Element {
  return (
    <StyledSvgIcon size={ SIZE[size] } fill={ fill } >
      <Icon />
    </StyledSvgIcon>
  );
}

SvgIcon.defaultProps = {
  fill: undefined,
};

export default SvgIcon;
