import { Container } from './job-title-item.styled';

type JobTitleProps = {
    jobTitle:PositionTitle,
    onSelect: (value: PositionTitle) => void,
    isSelected: boolean,
}

export const JobTitleItem = ({
  jobTitle,
  onSelect,
  isSelected,
}: JobTitleProps): JSX.Element => {
  return (
    <Container onClick={ () => {
      onSelect(jobTitle);
    } }
    isSelected={ isSelected }
    >
      {jobTitle.jobTitle}
    </Container>
  );
};
