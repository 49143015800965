import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import {
  GuidanceMessage,
  Container,
  GuidanceMessageIcon,
} from './open-guidance-modal-button.styled';

const OpenGuidanceModalButton = ({ clickHandler }: OpenGuidanceModalButtonProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container onClick={ clickHandler }>
      <GuidanceMessage>
        { translate(STRING_KEYS.GUIDANCE_MODAL.OPEN_GUIDANCE_MODAL_BUTTON_TEXT) }
      </GuidanceMessage>
      <GuidanceMessageIcon />
    </Container>
  );
};

export default OpenGuidanceModalButton;
