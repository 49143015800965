/* eslint-disable react/jsx-props-no-spreading */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getMileRadius } from '../../../../../../../../utils/location';
import { ExactLocationDto, LocationDto } from '../../../../../../../../models/dto/location.dto';
import { PerfectButtonHoverVariants } from '../../../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { IconButtonVariants } from '../../../../../../../../components/Common/Buttons/icon-button/icon-button.enums';
import STRING_KEYS from '../../../../../../../../language/keys';
import LocationAutocomplete from './location-autocomplete/location-autocomplete.component';
import MileRadiusSelect from './mile-readius-select/mile-readius-select.component';
import { LocationItemProps } from './location-item';
import {
  Container,
  StyledIconButton,
  RemoveButtonIcon,
} from './location-item.styled';

const LocationItem = ({
  location,
  changeHandler,
  inputChangeHandler,
  removeButtonClickHandler,
  disableRemoveButton,
  selectedCountry,
  showRemoteOptions,
  locationAutocompleteLabel,
  locationAutocompletePlaceholder,
}: LocationItemProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const onMileRadiusChange = (newValue: number) => {
    changeHandler({
      ...location,
      locationMileRadius: newValue,
    });
  };

  const setLocationHandler = (newLocation: LocationDto | undefined) => {
    changeHandler({
      ...newLocation || {} as ExactLocationDto,
      locationMileRadius: getMileRadius(location),
    });
  };

  const LocationAutocompleteComponent = useMemo(() => (
    <LocationAutocomplete
      location={ location }
      changeHandler={ setLocationHandler }
      inputChangeHandler={ inputChangeHandler }
      selectedCountry={ selectedCountry }
      showRemoteOptions={ showRemoteOptions }
      locationAutocompletePlaceholder={ locationAutocompletePlaceholder }
      locationAutocompleteLabel={ locationAutocompleteLabel }
    />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [
    inputChangeHandler,
    location,
    locationAutocompleteLabel,
    locationAutocompletePlaceholder,
    selectedCountry,
    showRemoteOptions,
  ]);

  const RemoveButton = (
    <StyledIconButton
      type="button"
      hoverVariant={ PerfectButtonHoverVariants.Grow }
      variant={ IconButtonVariants.Ghost }
      StartIcon={ RemoveButtonIcon }
      onClick={ removeButtonClickHandler }
      disabled={ disableRemoveButton }
    />
  );

  return (
    <Container>
      { LocationAutocompleteComponent }
      { translate(STRING_KEYS.WITHIN) }
      <MileRadiusSelect location={ location } changeHandler={ onMileRadiusChange } />
      { RemoveButton }
    </Container>
  );
};

export default LocationItem;
