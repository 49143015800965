import styled from 'styled-components';
import { THEME } from '../../../../themes';

export const PageHeaderContent = styled.div`
  display: flex;
  margin-left: ${THEME.SPACING.XLOOSE};
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
