import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import BaseMenu from '../../../../components/Common/perfect-base-menu/perfect-base-menu.component';
import {
  PerfectButtonSize,
  PerfectButtonVariants,
} from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { ASSETS } from '../../../../assets';
import STRING_KEYS from '../../../../language/keys';
import { ByeBiasButton } from '../../pages/matches/matches.styled';
import BaseSwitch from '../../../../components/Common/base-switch/base-switch.component';
import { THEME } from '../../../../themes';
import { CONFIG } from '../../../../config';
import { Divider } from '../../../../components/Common/Divider';
import { translationsConst } from '../../../../store/rtk-query/consts/translations.const';
import {
  Description, ShapesIcon, Container, Title, ALink, Label, SwitchAndLabel,
} from './byebias-menu.styled';

export const ByeBiasMenu = ({ toggleHandler, isByeBiasEnabled }: ByeBiasMenuProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [anchorElement, setAnchorElement] = useState(null);
  const ref = useRef(null);
  const handleButtonClick = () => anchorElement ? setAnchorElement(null) : setAnchorElement(ref.current);
  const handleMenuClose = () => setAnchorElement(null);

  const onToggleChange = (checked: boolean) => {
    setAnchorElement(null);
    toggleHandler(checked);
  };

  return (
    <>
      <ByeBiasButton
        ref={ ref }
        size={ PerfectButtonSize.Large }
        id="byebias-button"
        onClick={ handleButtonClick }
        type="button"
        StartIcon={ ASSETS.SHAPES }
        variant={ PerfectButtonVariants.Fill }
      >
        {translate(STRING_KEYS.MATCH_TUNE.BYEBIAS_BUTTON_TEXT, {
          ByeBias: 'ByeBias',
        })}
      </ByeBiasButton>
      <BaseMenu
        isOpen={ !!anchorElement }
        onClose={ handleMenuClose }
        anchorElement={ anchorElement || undefined }
        placement="bottom-start"
      >
        <Container>
          <ShapesIcon />
          <Title>{translate(STRING_KEYS.MATCH_TUNE.BYEBIAS_POPUP_TITLE)}</Title>
          <Description>
            {translate(STRING_KEYS.MATCH_TUNE.BYEBIAS_POPUP_DESCRIPTION)}
            <ALink target="_blank" href={ CONFIG.BYEBIAS_LEARN_MORE_URL }>{translate(STRING_KEYS.MATCH_TUNE.BYEBIAS_POPUP_LEARN_MORE)}</ALink>
          </Description>
          <Divider />
          <SwitchAndLabel>
            <BaseSwitch checked={ isByeBiasEnabled } switchChangeHandler={ onToggleChange } checkedColor={ THEME.COLORS.ACCENT } />
            <Label>
              {
                translate(isByeBiasEnabled ?
                  STRING_KEYS.MATCH_TUNE.BYEBIAS_POPUP_ACTIVATED : STRING_KEYS.MATCH_TUNE.BYEBIAS_POPUP_DISABLED, { ByeBias: translationsConst.byeBias },
                )
              }
            </Label>
          </SwitchAndLabel>
        </Container>
      </BaseMenu>
    </>
  );
};
