import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PerfectTooltip } from '../../../../../../../../../components/Common/perfect-tooltip';
import STRING_KEYS from '../../../../../../../../../language/keys';
import { ExplainabilityTypeEnum } from '../../../../../../../../../enums/explainability-type.enum';
import SimilarAttributeInfoMenu from '../../../../../../explainability-info-menu/similar-attribute-info-menu/similar-attribute-info-menu.component';
import Popper from '../../../../../../../../../components/Common/popper/popper.component';
import { SimilarAttributeExplainabilityTextProps } from './similar-attribute-explainability-text';
import { BoldText, SimilarAttributeOrigin } from './similar-attribute-explainability-text.styled';

const SimilarAttributeExplainabilityText = ({ type, similarAttribute }: SimilarAttributeExplainabilityTextProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const ref =  useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const tooltipText = useMemo(() => (
    translate(
      type === ExplainabilityTypeEnum.Skill ?
        STRING_KEYS.MATCH_CARD.EXPLAINABILITY.SIMILAR_SKILL_MORE_INFO_TOOLTIP_TEXT :
        STRING_KEYS.MATCH_CARD.EXPLAINABILITY.SIMILAR_INDUSTRY_MORE_INFO_TOOLTIP_TEXT,
    )
  ), [translate, type]);

  return (
    <>
      <PerfectTooltip
        title={ <>{ tooltipText }</> }
        placement="bottom-start"
        enterDelay={ 700 }
        enterNextDelay={ 700 }
      >
        <SimilarAttributeOrigin
          ref={ ref }
          onClick={ () => setIsMenuOpen(true) }
        >
          <BoldText>{ similarAttribute.value }</BoldText> { similarAttribute.jobRelatedExplanation }
        </SimilarAttributeOrigin>
      </PerfectTooltip>
      <Popper
        isOpen={ isMenuOpen }
        anchorElement={ ref?.current || undefined }
        placement="top-start"
        closeHandler={ () => setIsMenuOpen(false) }
      >
        <SimilarAttributeInfoMenu
          type={ type }
          value={ similarAttribute.value }
          originalValue={ similarAttribute.originalValue }
          similarExplanation={ similarAttribute.similarityExplanation }
          relevanceExplanation={ similarAttribute.relevanceExplanation }
          origins={ similarAttribute.origins }
        />
      </Popper>
    </>
  );
};

export default SimilarAttributeExplainabilityText;
