import { userQuery } from '../user.toolkit-api';
import { UserResponse } from '../dto/response/invited-users.response';
import { ResendInviteColleagueQueryArguments } from '../dto/query-arguments/resend-invite-colleague.query-arguments';
import { FIXED_CACHE_KEYS } from '../user.consts';

type UseFillUserInfoResponseType = [
  (args: ResendInviteColleagueQueryArguments) => void,
  AppSelectorType<UserResponse[], string>
];

export const useResendInviteUserMutation = (): UseFillUserInfoResponseType => {
  const [
    inviteUser,
    {
      data, isLoading, isError, error, isSuccess,
    }] = userQuery.useResendInviteColleagueMutation({
    fixedCacheKey: FIXED_CACHE_KEYS.RESEND_INVITE_TO_USER,
  });

  const meta = {
    isLoading, isError, error: error as string, isSuccess, data: data || [] as UserResponse[],
  };

  return [
    inviteUser,
    { ...meta },
  ];
};
