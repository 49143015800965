import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { Title3Font } from '../../../../themes/fonts';
import { ASSETS } from '../../../../assets';

export const Container = styled.div`
  display: flex;
  flex: 1.33;
  flex-direction: column;
  margin-right: ${THEME.SPACING.LOOSE};
  div:last-child > div:after { display: none; }
`;

export const Header = styled.div`
  display: flex;
  opacity: ${THEME.OPACITY.LOW};
  ${Title3Font};
  font-weight: 500;
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  margin-bottom: ${THEME.SPACING.XLOOSE};
  :before {
    content: url(${ASSETS.EDUCATION_HAT});
    margin-right: ${THEME.SPACING.TINY};
  }
`;
