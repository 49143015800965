import { useTranslation } from 'react-i18next';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
  PerfectButtonVariants,
} from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../../language/keys';
import {
  ActionButton,
  ActionButtonIcon,
  Container,
  Description,
  IconWrapper,
  TextContainer,
  Title,
  Wrapper,
} from './adjustment-item.styled';

const AdjustmentItem = ({
  icon,
  title,
  description,
  isSelected = false,
  actionButtonClickHandler,
  showActionButton = true,
  children,
}: AdjustmentItemProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <IconWrapper>{ icon }</IconWrapper>
        <TextContainer>
          <Title>{ title }</Title>
          <Description>{ description }</Description>
        </TextContainer>
        { showActionButton && (
          <ActionButton
            onClick={ actionButtonClickHandler }
            StartIcon={ ActionButtonIcon }
            size={ PerfectButtonSize.Small }
            variant={ PerfectButtonVariants.Fill }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
            isSelected={ isSelected }
          >
            { translate(
              isSelected ?
                STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.ADJUSTMENT_ITEM_ACTION_BUTTON_TEXT :
                STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.ADJUSTMENT_ITEM_ACTION_BUTTON_SELECTED_TEXT,
            )}
          </ActionButton>
        ) }
      </Wrapper>
      { children }
    </Container>
  );
};

export default AdjustmentItem;
