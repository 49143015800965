import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { hexToRgbA } from '../../../../utils/colors';
import { ASSETS } from '../../../../assets';
import { OverlineFont } from '../../../../themes/fonts';
import FunnelBar from './funnel-bar/funnel-bar.component';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${THEME.COLORS.PURE_WHITE};
  border-radius: ${THEME.RADIUS.CURVY};
  width: fit-content;
  overflow: hidden;
  border: ${THEME.SPACING.TINY} solid ${THEME.COLORS.PURE_WHITE};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: ${THEME.SPACING.BASE};
  padding-right: ${THEME.SPACING.BASE};
  padding-top: ${THEME.SPACING.TINY};
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINY};
  align-items: center;
`;

export const HeaderInfoContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.BASE};
  align-items: center;
`;

export const InfoLabelContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINY};
  align-items: center;
`;

export const InfoLabelLine = styled.div<{isDashedLine?: boolean}>`
  height: 1px;
  width: 40px;
  margin-top: -2px;
  border-bottom: ${({ isDashedLine }) => {
    const line = isDashedLine ? 'dashed' : 'solid';
    
    return `2px ${line} ${THEME.COLORS.SECONDARY}`;
  }};
`;

export const InfoLabel = styled.div`
  ${OverlineFont};
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const FunnelIcon = styled(ASSETS.FILLED_FUNNEL)`
  width: 19px;
  height: 19px;
  fill: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW)};
`;

export const HeaderTitle = styled.div`
  ${OverlineFont};
  font-weight: 600;
  color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW)};
`;

export const FunnelContainer = styled.div`
  display: flex;
`;

export const StyledFunnelBar = styled(FunnelBar)`
  &:not(:last-child) {
    border-right: ${THEME.SPACING.NANO} solid ${hexToRgbA(THEME.COLORS.PRIMARY, THEME.OPACITY.DIVIDER)};
  }
`;

export const PeopleMatchedBarIcon = styled(ASSETS.STAR)``;
export const LikedBarIcon = styled(ASSETS.HEART)``;
export const ContactedBarIcon = styled(ASSETS.BUBBLE_CHAT)``;
export const RepliedBarIcon = styled(ASSETS.REPLAY_ARROW)`
  width: 17px;
`;
