import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { OnboardingState } from '../onboarding-state.initial-state';

type CompanyPayload = {
  companyLinkedInUrl: string,
  name: string,
};

export const setUserNameReducer: CaseReducer<OnboardingState, PayloadAction<string>> = (state, { payload }) => {
  state.userName = payload;
};

export const setRoleReducer: CaseReducer<OnboardingState, PayloadAction<string>> = (state, { payload }) => {
  state.roleName = payload;
};

export const setPromoReducer: CaseReducer<OnboardingState, PayloadAction<string>> = (state, { payload }) => {
  state.promo = payload;
};

export const setCompanyReducer: CaseReducer<OnboardingState, PayloadAction<CompanyPayload>> = (state, { payload }) => {
  state.selectedCompany = {
    linkedInUrl: payload.companyLinkedInUrl,
    companyName: payload.name,
  };
};

export const resetCompanyReducer: CaseReducer<OnboardingState, PayloadAction<undefined>> = (state) => {
  state.selectedCompany = {
    linkedInUrl: '',
    companyName: '',
  };
};
