import { ChangeEvent } from 'react';
import BaseInputItem from '../base-input-item.component';
import { InputContainer, InputLabel } from './number-input-item.styled';

const NumberInputItem = ({
  title,
  description,
  placeholder,
  value,
  inputLabel,
  min,
  max,
  changeHandler,
}: NumberInputItemProps): JSX.Element => {
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);

    if ((max !== undefined && newValue > max) || (min !== undefined && newValue < min)) {
      return;
    }

    changeHandler(newValue);
  };

  const EndAdornment = inputLabel ? <InputLabel>{ inputLabel }</InputLabel> : undefined;

  return (
    <BaseInputItem
      title={ title }
      description={ description }
    >
      <InputContainer
        type="number"
        value={ value }
        onChange={ onInputChange }
        EndAdornment={ EndAdornment }
        placeholder={ placeholder }
      />
    </BaseInputItem>
  );
};

export default NumberInputItem;
