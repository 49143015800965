import React from 'react';
import { useTranslation } from 'react-i18next';
import AdjustmentItem from '../adjustment-item.component';
import STRING_KEYS from '../../../../../../language/keys';
import { JobTitleIcon } from '../adjustment-item.styled';
import { Container, PositionTitleAutocomplete } from './position-title-adjustment.styled';

const PositionTitleAdjustment = ({ jobTitle, changeHandler }: PositionTitleAdjustment): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <AdjustmentItem
        icon={ (<JobTitleIcon />) }
        title={ translate(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.TITLE_ADJUSTMENT.TITLE) }
        description={ translate(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.TITLE_ADJUSTMENT.DESCRIPTION) }
        showActionButton={ false }
      >
        <PositionTitleAutocomplete
          jobTitle={ jobTitle }
          changeHandler={ changeHandler }
          completeHandler={ changeHandler }
        />
      </AdjustmentItem>
    </Container>
  );
};

export default PositionTitleAdjustment;
