import { Trans } from 'react-i18next';
import { ASSETS } from '../../../../assets';
import { THEME } from '../../../../themes';
import { InstructionsMessage } from '../../../onboarding/components/autocompletes/instructions-message/instructions-message.component';
import { PressEnterMessageProps } from './press-enter-message';

export const PressEnterMessage = ({ translationKey }: PressEnterMessageProps):JSX.Element  => {
  return (
    <InstructionsMessage
      color={ THEME.COLORS_RGB.CONTENT_PRIMARY_MED }
      icon={ ASSETS.ENTER_KEYBOARD }
      message={ (
        <Trans
          i18nKey={ translationKey }
          components={ { bold: <strong /> } }
        />
      ) }
    />
  );
};
