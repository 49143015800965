import { Trans, useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import STRING_KEYS from '../../../../../language/keys';
import OutreachContentPreferencesSection
  from '../../../pages/outreach-content-preferences/outreach-content-preferences-section/outreach-content-preferences-section.component';
import MessageItem from '../input-items/message-item/message-item.component';
import { OutreachContentPreferencesFormikKeys } from '../../../pages/outreach-content-preferences/outreach-content-preferences.enums';
import QuotesGuidanceModal
  from '../../../../../components/dialogs/guidance-modal/quotes-guidance-modal/quotes-guidance-modal.component';
import { OUTREACH_AI_INSTRUCTIONS_QUOTES } from '../../../../../components/dialogs/guidance-modal/quotes-guidance-modal/quotes-guidance-modal.consts';
import { AiInstructionsProps } from './ai-instructions';
import { AiIcon, SeeExamplesButton } from './ai-instructions.styled';

const AiInstructions = ({
  aiInstructions,
  onChangeHandler,
}: AiInstructionsProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [isGuidanceModalOpen, setIsGuidanceModalOpen] = useState<boolean>(false);

  const onAiInstructionsChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.Extras, e.target.value);
  };

  const InputDescription = (
    <Trans
      i18nKey={ STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AI_INSTRUCTIONS.INPUT_DESCRIPTION }
    >
      <SeeExamplesButton onClick={ () => setIsGuidanceModalOpen(true) } />
    </Trans>
  );

  return (
    <>
      <OutreachContentPreferencesSection
        title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AI_INSTRUCTIONS.TITLE) }
      >
        <MessageItem
          message={ aiInstructions }
          placeholder={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AI_INSTRUCTIONS.INPUT_PLACEHOLDER) }
          messageChangeHandler={ onAiInstructionsChange }
          title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AI_INSTRUCTIONS.INPUT_TITLE) }
          titleAdornment={ <AiIcon /> }
          description={ InputDescription }
        />
      </OutreachContentPreferencesSection>
      { isGuidanceModalOpen && (
        <QuotesGuidanceModal
          isOpen={ isGuidanceModalOpen }
          closeHandler={ () => setIsGuidanceModalOpen(false) }
          title={ translate(STRING_KEYS.GUIDANCE_MODAL.OUTREACH_AI_INSTRUCTIONS_MODAL_TITLE) }
          data={ OUTREACH_AI_INSTRUCTIONS_QUOTES }
        />
      ) }
    </>
  );
};

export default AiInstructions;
