import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { DialogWidthSize } from '../base-dialog/base-dialog.enums';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
} from '../../Common/Buttons/perfect-base-button/perfect-base-button.enums';
import Dialog from '../base-dialog/base-dialog.component';
import { THEME } from '../../../themes';
import STRING_KEYS from '../../../language/keys';
import { LoaderWrapper } from '../ats-initialization/ats-initialization.styled';
import { IndeterminateLinearLoader } from '../../Common/Loaders/IndeterminateLinearLoader';
import { useDeleteEmailSenderQuery } from '../../../store/rtk-query/apis/outreach/hooks/delete-email-sender.query-hook';
import {
  ButtonsContainer,
  Container,
  Description,
  PrimaryButton,
  SecondaryButton,
  TextContainer,
  Title,
} from './disconnect-email-sender-dialog.styled';
import { DisconnectEmailSenderDialogProps } from './disconnect-email-sender-dialog';

export const DisconnectEmailSenderDialog = ({
  emailSender,
  onCloseDialog,
  isOpen,
  onCancel,
  onError,
}: DisconnectEmailSenderDialogProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [deleteEmailSender, { isLoading, isSuccess, isError }] = useDeleteEmailSenderQuery();

  const handleEmailSenderDisconnect = () => {
    deleteEmailSender({ accountId: emailSender.accountId, userId: emailSender.userId });
  };

  useEffect(() => {
    if (isSuccess) {
      onCloseDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      onCloseDialog();
      onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <Dialog
      isOpen={ isOpen }
      onClose={ onCloseDialog }
      widthSize={ DialogWidthSize.Small }
    >
      <Container>
        <>
          { isLoading && (
            <LoaderWrapper>
              <IndeterminateLinearLoader loaderHeight="4px" />
            </LoaderWrapper>
          )}
        </>
        <TextContainer>
          <Title>
            <Trans
              i18nKey={ STRING_KEYS.CAMPAIGN_PAGE.DISCONNECT_EMAIL.DIALOG.TITLE }
              values={ { emailAddress: emailSender.senderEmail } }
            />
          </Title>
          <Description>
            {translate(STRING_KEYS.CAMPAIGN_PAGE.DISCONNECT_EMAIL.DIALOG.DESCRIPTION)}
          </Description>
        </TextContainer>
        <ButtonsContainer>
          <PrimaryButton
            size={ PerfectButtonSize.Large }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
            onClick={ handleEmailSenderDisconnect }
            color={ THEME.COLORS.ACCENT }
            disabled={ isLoading }
          >
            {translate(STRING_KEYS.CAMPAIGN_PAGE.DISCONNECT_EMAIL.DIALOG.CONFIRM_DISCONNECT)}
          </PrimaryButton>
          <SecondaryButton
            size={ PerfectButtonSize.Large }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
            onClick={ onCancel }
            color={ THEME.COLORS.ACCENT }
            disabled={ isLoading }
          >
            {translate(STRING_KEYS.CAMPAIGN_PAGE.DISCONNECT_EMAIL.DIALOG.KEEP_CONNECTED)}
          </SecondaryButton>
        </ButtonsContainer>
      </Container>
    </Dialog>
  );
};
