import { EVENT_GROUP } from '../../../consts/analytics';
import { TrackEventPayload } from '../../../@types/analytics/eventPayload';
import { generateDataForBIEventsByState } from '../utils/onboarding-bi-events.utls';
import { useCreatePositionProgressSteps } from '../../create-position/hooks/create-position-progress-steps.hooks';
import { CreatePositionStepsEnums } from '../../create-position/enums/create-position-steps.enums';
import { CREATE_POSITION_ANALYTICS_EVENTS } from '../../create-position/consts/position-analytics.consts';
import { useAnalyticsContext } from '../../../contexts/analytics/useAnalyticsContext';
import { useGetCurrentStep } from './onboarding-current-step.hooks';

export const useOnboardingJobRequirementEvent = (): (componentType: string, componentMetadata: string) => void => {
  const { analyticsTrackEvent } = useAnalyticsContext();
  const currentStep = useGetCurrentStep<CreatePositionStepsEnums>();
  const { steps } = useCreatePositionProgressSteps(currentStep);

  return (componentType, componentMetadata) => {
    if (steps) {
      const dynamicData = (generateDataForBIEventsByState(currentStep, steps)) as TrackEventPayload;
      const staticsData = {
        eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_INSERT_PARAMETER_CLICKED,
        eventGroup: EVENT_GROUP.CLICK,
      };
      if (dynamicData) {
        analyticsTrackEvent({
          ...staticsData,
          stepNumber: dynamicData.stepNumber,
          pageName: dynamicData.pageName,
          componentName: dynamicData.componentName,
          componentElementType: componentType,
          componentMetadata,
        });
      }
    }
  };
};
