/* eslint-disable max-len */
export const loremIpsum = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit. In porta urna at erat lobortis, quis posuere est ultrices. Donec eu aliquet ipsum. Praesent tincidunt, elit vitae laoreet tempus, felis sapien pharetra augue, in vehicula est nulla eu turpis. Etiam eget nunc tempor, venenatis orci a, ultrices eros. Ut consequat, orci sed sagittis iaculis, quam massa fringilla augue, eu vehicula ligula mi in risus. Cras nisl nisi, consequat vel ornare id, porttitor eu ante. In erat nisi, finibus et ultrices sit amet, posuere ac diam. Vestibulum egestas tortor ut metus gravida finibus. Cras id ligula quis dui dignissim consequat.

Curabitur venenatis purus sit amet pellentesque vulputate. Fusce ultrices sodales nunc, vitae pretium felis dapibus vel. Donec id fermentum tellus. Fusce id nulla arcu. Phasellus nec condimentum eros, quis cursus enim. Etiam porttitor pulvinar suscipit. Proin sed massa in nisi cursus ullamcorper.

Sed nec luctus neque, a facilisis enim. Proin ut vehicula nunc, quis porta libero. Praesent vitae libero pulvinar, elementum lectus et, commodo ligula. Donec maximus velit a purus vulputate, quis varius nibh semper. Nam odio neque, consequat iaculis maximus vitae, sodales a sapien. Aenean dapibus, risus vel elementum auctor, lacus nunc faucibus sapien, eu tristique lectus metus eget mauris. Duis scelerisque egestas quam ac euismod. Duis laoreet elit risus, sed mattis elit lacinia id. Etiam ac neque id magna dapibus iaculis.

Morbi tincidunt, mi eget volutpat congue, nisi lorem bibendum augue, a tristique ante mauris ut enim. Phasellus ut leo ante. Ut erat nibh, pretium et malesuada eu, mollis quis nibh. Praesent sit amet congue arcu, sit amet commodo nisi. Vestibulum nec dignissim libero, eu molestie erat. Integer lorem massa, consectetur sit amet vehicula in, efficitur nec lacus. Maecenas et dictum ante. Integer malesuada tortor urna, ut sodales quam sagittis et. Nullam sollicitudin dolor a imperdiet accumsan. Vestibulum condimentum sem condimentum, fermentum ipsum scelerisque, imperdiet neque. Integer maximus dolor id turpis facilisis dapibus. Duis dignissim tellus eu orci semper ullamcorper. Nam vel quam ut tortor venenatis vulputate ac facilisis turpis. Nam finibus, nisi in laoreet suscipit, mi ante fringilla mauris, sit amet vulputate lorem orci sit amet mi.

Donec mollis ligula a leo euismod, nec maximus diam semper. Suspendisse nec metus metus. Aliquam erat volutpat. Vivamus mollis imperdiet feugiat. Aliquam euismod ornare dapibus. Integer dictum scelerisque massa, quis pharetra turpis placerat a. Suspendisse potenti. Sed nec magna quam. Nullam ut varius dolor. Nunc eu luctus est. Donec vitae vehicula ligula, et commodo dolor. Mauris sit amet lectus vel dui scelerisque aliquet ut at elit. Donec aliquet, ligula ut pharetra hendrerit, orci nisl accumsan enim, non posuere orci dolor vitae odio. Mauris tincidunt bibendum felis sed dapibus.

Sed eleifend tempor ipsum quis maximus. Curabitur sit amet nisi ut sem vehicula egestas. Maecenas non arcu non ipsum laoreet auctor ornare ut neque. Ut ut ullamcorper leo. Cras bibendum nulla a commodo laoreet. Vivamus tempus semper lacinia. Morbi at neque porta, consequat eros eu, mollis quam.

Maecenas at iaculis ante. Duis ac ipsum justo. Quisque accumsan justo ac quam tempus, non eleifend quam interdum. Maecenas imperdiet facilisis risus. Sed ut hendrerit erat, id venenatis lectus. Suspendisse at tortor ut velit vestibulum vulputate. Sed vestibulum id dui ac consequat. Duis non nibh quam. Pellentesque dui felis, aliquam et vehicula et, feugiat vel ante.

Phasellus a enim id tellus luctus fermentum venenatis eget sem. Quisque accumsan iaculis nibh, et aliquam ligula faucibus quis. Praesent lacinia orci mattis, hendrerit purus nec, tempor nisi. Mauris at malesuada enim, in condimentum erat. Cras semper consectetur vulputate. Nam lobortis turpis ac mauris dapibus tempus. Sed ac arcu ac enim lobortis laoreet. Donec ac ante ac nisl mattis aliquam. Mauris tincidunt justo justo, id pretium ante egestas sollicitudin.

Aliquam euismod non turpis a commodo. In quis pretium magna. Phasellus ultrices turpis sit amet volutpat pulvinar. Phasellus sit amet viverra turpis, ut efficitur mi. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec accumsan auctor porttitor. Suspendisse ex nunc, gravida ac diam sed, ullamcorper tincidunt augue. Maecenas varius tellus quam. Donec eget lorem ac ipsum dapibus consectetur vel id quam. Pellentesque blandit elit mattis, molestie urna et, gravida urna. Aliquam sed velit ac lectus interdum aliquet id quis lorem. Aenean nec ipsum lobortis, dapibus diam non, ultricies orci.

Nulla posuere sapien non eros blandit porttitor ac quis enim. Vivamus fringilla tellus et porta rutrum. Quisque egestas aliquam ipsum eu commodo. Ut a malesuada magna. Aenean vehicula volutpat lobortis. Sed dui leo, lobortis eget sollicitudin vel, placerat sed purus. Proin non tincidunt erat. Cras sem leo, ultrices at imperdiet vitae, facilisis nec urna. Ut nec cursus lorem. Nulla a dolor vel neque rutrum semper. Maecenas ullamcorper arcu felis, et blandit urna cursus sed. Phasellus eleifend augue placerat fringilla consectetur.
`;
