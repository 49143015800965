import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as React from 'react';
import Autocomplete from '../../Common/autocomplete/autocomplete.component';
import { Switch } from '../../Common/inputs/switch/switch.component';
import STRING_KEYS from '../../../language/keys';
import { AlertSnackBar } from '../../Common/CustomSnackBar/AlertSnackBar';
import {
  ACTION_SOURCE_LOCATION,
  ANALYTICS_EVENTS,
  EVENT_GROUP,
  EVENT_TYPE,
  PRODUCT_AREA,
} from '../../../consts/analytics';
import { PAGE_NAMES } from '../../../consts/pages';
import { useAnalyticsContext } from '../../../contexts/analytics/useAnalyticsContext';
import { RudderStackDestination } from '../../../services/rudderStackAnalyticsAgent';
import { LoaderWrapper } from '../ats-initialization/ats-initialization.styled';
import { IndeterminateLinearLoader } from '../../Common/Loaders/IndeterminateLinearLoader';
import Dialog from '../base-dialog/base-dialog.component';
import { DialogWidthSize } from '../base-dialog/base-dialog.enums';
import { useGetPositionByIdQuery } from '../../../store/rtk-query/apis/position/hooks/get-position-by-id.query-hook';
import { useGetMyAccountSelector } from '../../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import { useLinkPositionToAtsMutation } from '../../../store/rtk-query/apis/position/hooks/link-position-to-ats.mutation-hook';
import { positionQuery } from '../../../store/rtk-query/apis/position/position.toolkit-api';
import { useUpdatePositionAtsIntegrationMutation } from '../../../store/rtk-query/apis/position/hooks/update-position-ats-integration.mutation-hook';
import { LinkedAtsJobResponse } from '../../../store/rtk-query/apis/position/dto/response/position.response';
import { useGetAccountAtsJobsQuery } from '../../../store/rtk-query/apis/ats-integration/hooks/get-account-ats-jobs.query-hook';
import { AtsJobResponse } from '../../../store/rtk-query/apis/ats-integration/dto/response/ats-job.response';
import { formatLocations } from '../../../utils/location';
import {
  AutoSyncContainer,
  AutoSyncInfoText,
  AutoSyncTextContainer,
  AutoSyncTitle,
  BodyContainer,
  Description,
  DialogContainer,
  FooterContainer,
  HeaderContainer,
  HelpButton,
  HelpContainer,
  ModalContentWrapper,
  ModalIcon,
  StyledButton,
  Title,
} from './link-position-to-ats.styled';

type LinkPositionToAtsModalProps = {
  positionId: string;
  closeHandler: () => void;
  isDialogOpen: boolean;
  productArea: string;
}

const COMPONENT_ELEMENT_TYPE = {
  CLOSE: 'close',
  TOGGLE_ON: 'toggle_on',
  TOGGLE_OFF: 'toggle_off',
  SAVE: 'save',
  HELP: 'help',
};

const eventPayloadAdditionalProperties = {
  [PRODUCT_AREA.HOMEPAGE]: {
    pageName: PAGE_NAMES.HOME_PAGE,
    actionSourceLocation: ACTION_SOURCE_LOCATION.POSITION_CARD,
  },
  [PRODUCT_AREA.MATCH_PAGE]: {
    pageName: PAGE_NAMES.MATCH_PAGE,
    actionSourceLocation: ACTION_SOURCE_LOCATION.ENGAGEMENT_MODAL,
  },
};

const LinkPositionToAtsModal = ({
  positionId,
  closeHandler,
  isDialogOpen,
  productArea,
}: LinkPositionToAtsModalProps): JSX.Element => {
  const divRef = useRef<HTMLDivElement>(null);
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const { data: position } = useGetPositionByIdQuery(positionId);
  const { data: atsJobs, isLoading: isGetAtsJobsLoading } = useGetAccountAtsJobsQuery(!isDialogOpen);
  const { data: { atsIntegration } } = useGetMyAccountSelector();
  const syncAutomatically = useRef<boolean>(position?.linkedAtsJob ? position?.linkedAtsJob.syncAutomatically : true);
  const { analyticsTrackEvent } = useAnalyticsContext();
  const [linkPositionToAts, {
    isLoading: isLinkPositionToAtsLoading, isError: isLinkPositionToAtsError, isSuccess: isLinkPositionToAtsSuccess, data: linkedAtsJob,
  }] = useLinkPositionToAtsMutation();
  const [updatePositionAtsIntegration, {
    isLoading: isUpdatePositionAtsIntegrationLoading, isError: isUpdatePositionAtsIntegrationError, isSuccess: isUpdatePositionAtsIntegrationSuccess, data: updatedAtsJob,
  }] = useUpdatePositionAtsIntegrationMutation();

  const [selectedJob, setSelectedJob] = useState<AtsJobResponse | undefined>(undefined);

  useEffect(() => {
    if (atsJobs?.jobs && position?.linkedAtsJob && !selectedJob) {
      const linkedJob = atsJobs.jobs.find((job: AtsJobResponse) => job.id === position.linkedAtsJob?.jobId);
      if (linkedJob) {
        setSelectedJob(linkedJob);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atsJobs, position]);

  // TODO: check if it's a good practice, maybe move it to another place
  const updatePositionAtsIntegrationInStorage = (atsJob: LinkedAtsJobResponse) => {
    if (!atsJob) {
      return;
    }

    dispatch(
      positionQuery.util.updateQueryData('getOpenPositions', undefined, (positions) => {
        const foundPosition = positions.find((positionItem) => positionItem.id === positionId);
        if (foundPosition) {
          foundPosition.linkedAtsJob = atsJob;
        }
      }),
    );

    dispatch(
      positionQuery.util.updateQueryData('getPositionById', positionId, (positionDraft) => {
        if (positionDraft) {
          positionDraft.linkedAtsJob = atsJob;
        }
      }),
    );
  };

  useEffect(() => {
    if (isLinkPositionToAtsSuccess) {
      updatePositionAtsIntegrationInStorage(linkedAtsJob);
      closeHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLinkPositionToAtsSuccess]);

  useEffect(() => {
    if (isUpdatePositionAtsIntegrationSuccess) {
      updatePositionAtsIntegrationInStorage(updatedAtsJob);
      closeHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatePositionAtsIntegrationSuccess]);

  const sendAnalyticsEvent = (
    eventName: string,
    eventGroup: string = EVENT_GROUP.IMP,
    elementType?: string,
    componentMetadata?: Record<string, string | boolean>,
  ) => {
    const payload = {
      eventName,
      eventGroup,
      productArea,
      ...(eventPayloadAdditionalProperties[productArea] || {}),
      componentMetadataType: 'modal_values',
      componentElementType: elementType,
      componentMetadataStruct: componentMetadata || {
        atsName: atsIntegration?.atsName,
        isAutomaticAdd: position?.linkedAtsJob ? position?.linkedAtsJob.syncAutomatically : true,
        atsPositionName: position?.linkedAtsJob?.jobTitle,
      },
    };
    analyticsTrackEvent(payload);
  };

  const onCloseDialogHandler = () => {
    closeHandler();
    sendAnalyticsEvent(
      ANALYTICS_EVENTS.CLICKED_MODAL_SYNC_POSITION_WITH_ATS,
      EVENT_GROUP.CLICK,
      COMPONENT_ELEMENT_TYPE.CLOSE,
    );
  };

  useEffect(() => {
    if (isDialogOpen) {
      sendAnalyticsEvent(ANALYTICS_EVENTS.SYNC_POSITION_WITH_ATS_SHOWN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const onLinkPositionToAtsButtonClick = () => {
    if (selectedJob && atsIntegration) {
      if (position?.linkedAtsJob) {
        updatePositionAtsIntegration({
          positionId, jobId: selectedJob.id, jobTitle: selectedJob.name, syncAutomatically: syncAutomatically.current,
        });
      } else {
        linkPositionToAts({
          positionId, atsJobId: selectedJob.id, atsJobTitle: selectedJob.name, atsIntegrationId: atsIntegration.id, syncAutomatically: syncAutomatically.current,
        });
      }

      sendAnalyticsEvent(
        ANALYTICS_EVENTS.CLICKED_MODAL_SYNC_POSITION_WITH_ATS,
        EVENT_GROUP.CLICK,
        COMPONENT_ELEMENT_TYPE.SAVE,
        {
          atsName: atsIntegration.atsName,
          isAutomaticAdd: syncAutomatically.current,
          atsPositionName: selectedJob.name,
        });
    }
  };

  const onItemSelect = (id: string) => {
    const selectedItem = atsJobs?.jobs.find((job: AtsJobResponse) => job.id === id);
    setSelectedJob(selectedItem);
  };

  const createMenuItems = (): DropdownMenuItem[] => {
    return atsJobs?.jobs?.map((job: AtsJobResponse) => {
      return {
        id: job.id,
        title: job.name,
        subtitle: job.location,
        image: atsIntegration?.atsImage,
      };
    }) || [];
  };

  const autoSyncChangeHandler = (autoSync: boolean) => {
    syncAutomatically.current = autoSync;
    const componentElementType = autoSync ? COMPONENT_ELEMENT_TYPE.TOGGLE_ON : COMPONENT_ELEMENT_TYPE.TOGGLE_OFF;
    sendAnalyticsEvent(
      ANALYTICS_EVENTS.CLICKED_MODAL_SYNC_POSITION_WITH_ATS,
      EVENT_GROUP.CLICK,
      componentElementType,
    );
  };

  const onHelpButtonClick = () => {
    sendAnalyticsEvent(
      ANALYTICS_EVENTS.CLICKED_MODAL_SYNC_POSITION_WITH_ATS,
      EVENT_GROUP.CLICK,
      COMPONENT_ELEMENT_TYPE.HELP,
    );

    const payload = {
      eventName: ANALYTICS_EVENTS.INTERCOM_TRIGGER_ATS_LINK_POSITION_HELP,
      event_type: EVENT_TYPE.INTERCOM_TRIGGER,
    };
    analyticsTrackEvent(payload, [RudderStackDestination.INTERCOM]);
  };

  const renderHelpText = () => {
    return (
      <HelpContainer>
        <Trans i18nKey={ STRING_KEYS.ATS_LINK_POSITION_HELP_TEXT }>
          <HelpButton onClick={ onHelpButtonClick } />
        </Trans>
      </HelpContainer>
    );
  };

  const isLoading = isGetAtsJobsLoading || isLinkPositionToAtsLoading;

  return (
    <Dialog isOpen={ isDialogOpen } onClose={ onCloseDialogHandler } widthSize={ DialogWidthSize.Medium }>
      <DialogContainer>
        <>
          { isLoading && (
            <LoaderWrapper>
              <IndeterminateLinearLoader loaderHeight="4px" />
            </LoaderWrapper>
          )}
        </>
        <HeaderContainer>
          <div>
            <ModalIcon />
          </div>
          <Title ref={ divRef }>{ translate(STRING_KEYS.ATS_LINK_POSITION_MODAL_TITLE) }</Title>
          <Description>{ translate(STRING_KEYS.ATS_LINK_POSITION_MODAL_DESCRIPTION) }</Description>
        </HeaderContainer>

        <BodyContainer>
          <ModalContentWrapper>
            { position && (
              <Autocomplete
                disabled
                items={ [{
                  id: position.id,
                  title: position.jobTitle,
                  subtitle: position.remotePosition ? translate(STRING_KEYS.REMOTE) : formatLocations(position.locations).join(' • '),
                }] }
                selectedItemId={ position.id }
              />
            ) }
            <Autocomplete
              disabled={ isGetAtsJobsLoading }
              image={ atsIntegration?.atsImage }
              items={ createMenuItems() }
              selectedItemId={ selectedJob?.id }
              itemSelectHandler={ onItemSelect }
              placeholder={ translate(STRING_KEYS.ATS_LINK_POSITION_JOB_AUTOCOMPLETE_PLACEHOLDER) }
            />
            <AutoSyncContainer>
              <Switch
                checked={ syncAutomatically.current }
                onChangeHandler={ autoSyncChangeHandler }
              />
              <AutoSyncTextContainer>
                <AutoSyncTitle>
                  { translate(STRING_KEYS.ATS_AUTO_SYNC_TITLE, { atsName: atsIntegration?.atsName || translate(STRING_KEYS.YOUR_ATS) }) }
                </AutoSyncTitle>
                <AutoSyncInfoText>{ translate(STRING_KEYS.ATS_AUTO_INFO_TEXT) }</AutoSyncInfoText>
              </AutoSyncTextContainer>
            </AutoSyncContainer>
          </ModalContentWrapper>
        </BodyContainer>
        <FooterContainer>
          <StyledButton
            onClick={ onLinkPositionToAtsButtonClick }
            isDisabled={ !selectedJob || isLinkPositionToAtsLoading || isUpdatePositionAtsIntegrationLoading }
          >
            { translate(STRING_KEYS.SAVE_AND_SYNC) }
          </StyledButton>
          { renderHelpText() }
        </FooterContainer>
        <AlertSnackBar
          title={ translate(STRING_KEYS.ALERT) }
          description={ translate(STRING_KEYS.ATS_LINK_POSITION_ERROR_MESSAGE) }
          isOpen={ isLinkPositionToAtsError || isUpdatePositionAtsIntegrationError }
          shouldAutoDismiss
        />
      </DialogContainer>
    </Dialog>
  );
};

export default LinkPositionToAtsModal;
