import React, { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useInViewport } from 'react-in-viewport';
import { TENURE_TITLE_STATUS } from '../../../../../consts';
import STRING_KEYS from '../../../../../language/keys';
import { METER_TYPE } from './tenure-meter-time-widget.conts';
import {
  Container, Header, MeterContainer, TenureMeterComparisonContainer,
  TenureMeterComparisonRow, Title, VerticalDash, VerticalDashTitle,
} from './tenure-meter-time-widget.styled';
import { TenureMeterItem } from './tenure-meter-item/tenure-meter-item.component';
import { calcMeterWidth, getYearAbb, makeAxis } from './tenure-meter-time-widget.utils';

type TenureMeterTimeWidgetProps = {
  average: number,
  experience: number,
  talentName: string,
  type: string,
  currentCompanyName: string,
}

export const TenureMeterTimeWidget = ({
  average,
  experience,
  talentName,
  type,
  currentCompanyName,
}:TenureMeterTimeWidgetProps):JSX.Element => {
  const { t: translate } = useTranslation();
  const containerRef = useRef(null);
  const {
    inViewport,
    enterCount,
  } = useInViewport(
    containerRef,
    {},
    {},
  );
  const biggestValue = average > experience ? average : experience;
  const yearMax = Math.ceil(biggestValue);
  const axises = makeAxis(yearMax);
  const widthAverage = calcMeterWidth(average, yearMax);
  const widthExperience = calcMeterWidth(experience, yearMax);
  const diffInNumber = Math.abs(average - experience);
  const widthShowDiff = calcMeterWidth(diffInNumber, yearMax);

  const renderTitle = () => {
    if (type === TENURE_TITLE_STATUS.EXCEEDING_TENURE) {
      return STRING_KEYS.STATUS_TENURE_COMPARISON_TITLE_EXCEEDING;
    }

    return STRING_KEYS.STATUS_TENURE_COMPARISON_TITLE_APPROACHING;
  };

  return (
    <Container>
      <Header>{translate(STRING_KEYS.PERSONAL_TENURE_COMPARISON)}</Header>
      <Title>
        <Trans
          i18nKey={ renderTitle() }
          values={ {
            userName: talentName,
          } }
        />
      </Title>
      <MeterContainer>
        {axises.map((axis) => {
          return (
            <VerticalDash
              key={ axis.id }
            >
              <VerticalDashTitle>
                {axis.title}
              </VerticalDashTitle>
            </VerticalDash>
          );
        })}
        <TenureMeterComparisonContainer>
          <TenureMeterComparisonRow ref={ containerRef }>
            <TenureMeterItem
              title={ translate(STRING_KEYS.AVG_TENURE_TIME) }
              years={ getYearAbb(average) }
              width={ widthAverage }
              isEnableAnimation={ inViewport && enterCount === 1 }
              isBiggestValue={ average > experience }
              widthShowDiff={ widthShowDiff }
              type={ type }
              meter={ METER_TYPE.AVERAGE }
              isEqual={ average === experience }
            />
            <TenureMeterItem
              title={ translate(STRING_KEYS.TIME_AT_COMPANY, { currentCompanyName }) }
              years={ getYearAbb(experience) }
              width={ widthExperience }
              isEnableAnimation={ inViewport && enterCount === 1 }
              isBiggestValue={ experience > average }
              widthShowDiff={ widthShowDiff }
              type={ type }
              meter={ METER_TYPE.EXPERIENCE }
              isEqual={ average === experience }
            />
          </TenureMeterComparisonRow>
        </TenureMeterComparisonContainer>
      </MeterContainer>
    </Container>
  );
};
