/* eslint-disable react/jsx-props-no-spreading */
import { Bar, Container } from './progress.styled';

const Progress = ({ progressPercentage, color, ...otherProps }: ProgressProps): JSX.Element => {
  return (
    <Container color={ color } { ...otherProps }>
      <Bar progress={ Math.min(progressPercentage, 100) } />
    </Container>
  );
};

export default Progress;
