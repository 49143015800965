import { useTranslation } from 'react-i18next';
import TimeAgo from 'react-timeago';
import { isBefore } from 'date-fns';
import STRING_KEYS from '../../../language/keys';

type TimeAgoItemProps = {
    date: string | Date,
}

const SECOND = 'second';
const MIN_PERIOD = 60;

export const TimeAgoItem = ({
  date,
}:TimeAgoItemProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const timeFormatter = (value: number, unit: string, suffix:string) => {
    if (unit === SECOND) return translate(STRING_KEYS.JUST_NOW);
    const now = new Date();
    if (isBefore(now, new Date(date))) {
      return `${value} ${unit}${value > 1 ? 's' : ''}`;
    }

    return `${value} ${unit}${value > 1 ? 's' : ''} ${suffix}`;
  };

  return (
    <TimeAgo
      date={ date }
      minPeriod={ MIN_PERIOD }
      formatter={ timeFormatter }
    />
  );
};
