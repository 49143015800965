import { HorizontalDivider } from '../../../../../../components/Common/horizontal-divider/horizontal-divider.component';
import {
  ActionContainer,
  BodyContainer,
  Container,
  TitleContainer,
  InsightsValueContainer,
  ActionIconContainer,
} from './insights-card.styled';

const InsightsCard = ({
  title,
  insightValueBold,
  insightValue,
  children,
  actionTitle,
  ActionIcon,
  actionHandler,
}: InsightsCard):JSX.Element => {
  return (
    <Container>
      <BodyContainer>
        <TitleContainer>
          { title }
        </TitleContainer>
        <InsightsValueContainer>
          <span>{ insightValueBold }</span>{insightValue}
        </InsightsValueContainer>
        { children }
      </BodyContainer>
      { actionTitle && <HorizontalDivider /> }
      { actionTitle && (
        <ActionContainer onClick={ actionHandler }>
          { actionTitle }
          { ActionIcon && <ActionIconContainer>{ ActionIcon }</ActionIconContainer> }
        </ActionContainer>
      )}
    </Container>
  );
};

export default InsightsCard;
