import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../../utils/base-query';
import { accountQuery } from '../account/account.toolkit-api';
import { REDUCER_PATH } from './ats-integration.consts';
import {
  createAtsIntegrationQuery,
  createCustomIntegrationQuery,
  getAccountAtsJobsQuery,
  getMergeFrontendLinkTokenQuery,
} from './ats-integration.toolkit-queries';
import { AtsIntegrationResponse } from './dto/response/ats-integration.response';
import { MergeFrontendLinkTokenResponse } from './dto/response/merge-frontend-link-token.response';
import { CreateAtsIntegrationRequest } from './dto/request/create-ats-integration.request';
import { AtsJobsResponse } from './dto/response/ats-job.response';
import { CreateCustomAtsIntegrationRequest } from './dto/request/create-custom-ats-integration.request';

export const atsIntegrationQuery = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    createAtsIntegration: builder.mutation<AtsIntegrationResponse, CreateAtsIntegrationRequest>({
      query: createAtsIntegrationQuery,
      async onQueryStarted(
        { accountId }, { dispatch, queryFulfilled },
      ) {
        const { data: atsIntegration } = await queryFulfilled;
        dispatch(
          accountQuery.util.updateQueryData('getAccountById', accountId, (account) => {
            account.atsIntegration = atsIntegration;
          }),
        );
      },
    }),
    createCustomAtsIntegration: builder.mutation<AtsIntegrationResponse, CreateCustomAtsIntegrationRequest>({
      query: createCustomIntegrationQuery,
      async onQueryStarted(
        { accountId }, { dispatch, queryFulfilled },
      ) {
        const { data: atsIntegration } = await queryFulfilled;
        dispatch(
          accountQuery.util.updateQueryData('getAccountById', accountId, (account) => {
            account.atsIntegration = atsIntegration;
          }),
        );
      },
    }),
    getMergeFrontendLinkToken: builder.query<MergeFrontendLinkTokenResponse, void>({
      query: getMergeFrontendLinkTokenQuery,
    }),
    getAccountAtsJobs: builder.query<AtsJobsResponse, void>({
      query: getAccountAtsJobsQuery,
    }),
  }),
});
