import React from 'react';
import { Circle, FullSizeScreen } from './transition-screen-wrapper.styled';

type TransitionScreenWrapperProps = {
  children: React.ReactNode,
}

export const TransitionScreenWrapper = ({
  children,
}: TransitionScreenWrapperProps): JSX.Element => {
  return (
    <>
      <FullSizeScreen>
        {children}
      </FullSizeScreen>
      <Circle />
    </>
  );
};
