import styled from 'styled-components';
import { ASSETS } from '../../../../../assets';
import { hexToRgbA } from '../../../../../utils/colors';
import { THEME } from '../../../../../themes';
import { Body2Font, Title3Font } from '../../../../../themes/fonts';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';

export const ContainerBorder = styled.div`
  border-radius: ${THEME.SPACING.BASE};
  border: ${THEME.SPACING.TINY} solid ${THEME.COLORS.PURE_WHITE};
`;

export const Container = styled.div`
  background-color: ${hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.DIVIDER)};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${THEME.SPACING.XLARGE} ${THEME.SPACING.GRAND};
  border-radius: ${THEME.SPACING.BASE};
  color: ${THEME.COLORS.CONTENT_A};
  text-align: center;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: ${THEME.SPACING.BASE};
`;

export const BannerIcon = styled(ASSETS.STARS_BUBBLE_FILL)`
  width: 48px;
  height: 48px;
  margin-bottom: ${THEME.SPACING.MEDIUM};
`;

export const Title = styled.div`
  ${Title3Font};
  font-weight: 600;
  margin-bottom: ${THEME.SPACING.BASE};
`;

export const Description = styled.div`
  ${Body2Font};
  opacity: ${THEME.OPACITY.MED};
`;

export const DividerWrapper = styled.div`
  margin: ${THEME.SPACING.MEDIUM} 0;
  width: 100%;
`;

export const ActionButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.SECONDARY};
  width: 146px;
`;
