import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { Divider } from '../../../Common/Divider';
import TimeToPayDialog from '../time-to-pay-dialog/time-to-pay-dialog.component';
import { usePaymentInteraction } from '../../../../hooks/payment-react-qury.hooks';
import {
  CheckedIcon,
  Suggestion,
  SuggestionsContainer,
  SuggestionsItem,
} from './pro-downgraded-dialog.styled';

const suggestions = [
  STRING_KEYS.PRO_DOWNGRADED_DIALOG.SUGGESTIONS.UNLIMITED_DAILY_MATCHES,
  STRING_KEYS.PRO_DOWNGRADED_DIALOG.SUGGESTIONS.UNLIMITED_POSITIONS,
  STRING_KEYS.PRO_DOWNGRADED_DIALOG.SUGGESTIONS.ACCESS_ALL_FEATURES,
];

const ProDowngradedDialog = ({
  actionClickHandler,
  isOpen,
  onClose,
}: ProPositionLimitDialogProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { mutate: createCheckoutSession, isLoading: creatingCheckoutSession } = usePaymentInteraction();

  const renderSuggestions = () => suggestions.map((key) => (
    <SuggestionsItem key={ key } >
      <CheckedIcon />
      <Suggestion>
        { translate(key) }
      </Suggestion>
    </SuggestionsItem>
  ));

  const onClickAction = () => {
    actionClickHandler?.();
    const successUrl = new URL(window.location.href);
    createCheckoutSession(successUrl.toString());
  };

  return (
    <TimeToPayDialog
      title={ translate(STRING_KEYS.PRO_DOWNGRADED_DIALOG.HEADER_TITLE) }
      subTitle={ translate(STRING_KEYS.PRO_DOWNGRADED_DIALOG.HEADER_SUB_TITLE) }
      footerTitle={ translate(STRING_KEYS.PRO_DOWNGRADED_DIALOG.FOOTER_TITLE) }
      footerSubTitle={ translate(STRING_KEYS.PRO_DOWNGRADED_DIALOG.FOOTER_SUB_TITLE) }
      isOpen={ isOpen }
      onClose={ onClose }
      buttons={ [{
        title: translate(STRING_KEYS.PRO_DOWNGRADED_DIALOG.BUTTON_TITLE),
        clickHandler: onClickAction,
        isLoading: creatingCheckoutSession,
      }] }
    >
      <SuggestionsContainer>
        { renderSuggestions() }
      </SuggestionsContainer>
      <Divider />
    </TimeToPayDialog>
  );
};

export default ProDowngradedDialog;
