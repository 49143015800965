import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useEffect, useMemo, useState } from 'react';
import { CONFIG } from '../../config';
import { Locations, LocationType, RegionDto } from '../../models/dto/location.dto';
import { useGetPositionRegionsQuery } from '../../store/rtk-query/apis/position/hooks/get-position-regions.query-hook';
import {
  AUTOCOMPLETE_ALLOWED_COUNTRIES,
  GOOGLE_CITY,
  GOOGLE_REGIONS_TYPE,
  GOOGLE_STATE,
  INITIAL_LOCATION_OBJECT,
  MAX_AUTOCOMPLETE_VALUES_NUMBER, REMOTE_COUNTRIES,
  REMOTE_POSITION,
} from './location-autocomplete-hook.consts';
import { getFormattedLocationFromPlacePredictions, shouldShowLocationSuggestion } from './location-autocomplete-hook.utils';
import { LocationsAutocompleteResponse, UseLocationsAutocompleteArgs } from './location-autocomplete-hook';

export const useLocationsAutocomplete = ({
  query,
  skip = false,
  country,
  showRemoteOptions = true,
}: UseLocationsAutocompleteArgs): LocationsAutocompleteResponse => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({ apiKey: CONFIG.GOOGLE_API_KEY, language: 'en' });
  const [locationList, setLocationList] = useState<Locations>(INITIAL_LOCATION_OBJECT);
  const { data: positionRegions } = useGetPositionRegionsQuery();

  const isGoogleAutocompletePredictionType = (types?: string[]) => {
    return !!types?.some((type) => type === GOOGLE_CITY || type === GOOGLE_STATE);
  };
  
  const isValidSuggestion = (placeDetails: google.maps.places.PlaceResult | null): boolean => {
    if (!placeDetails) {
      return false;
    }

    if (!placeDetails.address_components || !placeDetails.types) {
      return false;
    }

    return isGoogleAutocompletePredictionType(placeDetails.types);
  };

  const regionList: RegionDto[] = useMemo(
    () => Array.from(positionRegions.regions || [])
      .sort((region1, region2) => region1.country === region1.name && region2.country !== region2.name ? -1 : 1)
      .map((region) => ({
        type: LocationType.Region, country: region.country, region: region.name, countryLong: region.countryLong,
      })),
    [positionRegions.regions]);

  useEffect(() => {
    if (skip) {
      return;
    }

    setLocationList(INITIAL_LOCATION_OBJECT);

    if (query.trim().length > 0) {
      // First get regions. If reached some max value, no need to search from google api
      const lowerCaseQuery = query.toLowerCase();
      const matchedRegions = regionList.filter(
        (region) => region.countryLong?.toLowerCase()?.includes(lowerCaseQuery) || region.region.toLowerCase().includes(lowerCaseQuery),
      );

      setLocationList({ regions: matchedRegions });

      if (matchedRegions.length >= MAX_AUTOCOMPLETE_VALUES_NUMBER) {
        return;
      }

      getPlacePredictions({
        input: query,
        componentRestrictions: {
          country: country ? [country] : AUTOCOMPLETE_ALLOWED_COUNTRIES,
        },
        types: [GOOGLE_REGIONS_TYPE],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (country) {
      if (locationList.exactLocations?.length || locationList.regions?.length) {
        setLocationList((prevLocationObject: Locations) => ({
          exactLocations: prevLocationObject?.exactLocations?.filter((location) => location.country === country),
          regions: prevLocationObject?.regions?.filter((location) => location.country === country),
        }));
      }
    }
  }, [locationList.exactLocations?.length, locationList.regions?.length, country]);

  useEffect(() => {
    if (placePredictions && placePredictions.length >= 0) {
      placePredictions.forEach((place) => {
        placesService?.getDetails({ placeId: place.place_id },
          (placeDetails) => {
            if (isValidSuggestion(placeDetails)) {
              const location = getFormattedLocationFromPlacePredictions(placeDetails as google.maps.places.PlaceResult);
              if (shouldShowLocationSuggestion(location)) {
                setLocationList((prevLocationObject: Locations) => {
                  return {
                    regions: prevLocationObject.regions,
                    exactLocations: [
                      ...(prevLocationObject.exactLocations || []),
                      { ...location },
                    ],
                  };
                },
                );
              }
            }
          },
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placePredictions]);

  const locationObjectExactLocations = [...(locationList.exactLocations || [])];
  const locationObjectRegions = [...(locationList.regions || [])];
  if (!query && showRemoteOptions) {
    locationObjectExactLocations.push(REMOTE_POSITION, ...REMOTE_COUNTRIES);
  }

  return {
    isLoading: isPlacePredictionsLoading,
    locationObject: {
      exactLocations: locationObjectExactLocations,
      regions: locationObjectRegions,
    },
  };
};
