import styled from 'styled-components';
import Tag from '../../../../../../../../../components/Common/tag/tag.component';
import { THEME } from '../../../../../../../../../themes';
import { scale } from '../../../../../../../../../animations';
import { TextWrapper } from '../../../../../../../../../components/Common/tag/tag.styled';
import { TextEllipsis } from '../../../../../../../../../themes/text';
import { ASSETS } from '../../../../../../../../../assets';

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${THEME.SPACING.TINY};
  align-items: center;
`;

export const SimilarAttributeRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const SimilarAttributesOriginsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINO};
`;

export const RightArrow = styled(ASSETS.ARROW_BACK_COMPONENT)`
  width: 12px;
  height: fit-content;
  color: ${THEME.COLORS.PRIMARY_DARK};
  opacity: ${THEME.OPACITY.LOW};
`;

export const OriginalAttribute = styled(Tag)`
  background-color: transparent;
  color: ${THEME.COLORS.PRIMARY_DARK};
  font-weight: bold;
  padding: 0;
`;

export const SimilarAttribute = styled(Tag)`
  background-color: ${THEME.COLORS.SECONDARY};
  color: ${THEME.COLORS.PURE_WHITE};
  font-weight: bold;
  padding: ${THEME.SPACING.MICRO} ${THEME.SPACING.TINY};
  ${scale};
  
  ${TextWrapper} {
    ${TextEllipsis};
    max-width: 32ch;
  }
`;
