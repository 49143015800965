import styled, { css } from 'styled-components';
import { hexToRgbA } from '../../../utils/colors';
import { THEME } from '../../../themes';
import { CaptionFont, Subtitle1Font } from '../../../themes/fonts';
import { TextEllipsis } from '../../../themes/text';
import { ASSETS } from '../../../assets';

export const UserMenuItemStyle = css`
  &:hover {
    background-color: ${hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER)};
    color: ${THEME.COLORS.CONTENT_A};
  }

  &:focus {
    background-color: ${hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.FOCUS)};
    color: ${THEME.COLORS.CONTENT_A};
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuItemAvatarContainer = styled.div`
  width: 32px;
  height: 32px;
`;

export const MenuItemTextContainer = styled.div`
  color: ${THEME.COLORS.CONTENT_A};
  margin-left: ${THEME.SPACING.TINO};
`;

const MenuItemTextBaseStyle = css`
  max-width: 170px;
  ${TextEllipsis};
`;

export const MenuItemTitle = styled.div`
  ${Subtitle1Font};
  ${MenuItemTextBaseStyle};
`;

export const MenuItemSubtitle = styled.div`
  ${CaptionFont};
  opacity: ${THEME.OPACITY.MED};
  ${MenuItemTextBaseStyle};
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
`;

export const UserNotVerifiedIcon = styled(ASSETS.MESSAGE_NOTIFICATION)`
  width: 15px;
  height: 13px;
`;

export const UserEmailNotConfiguredIcon = styled(ASSETS.ERROR_RECTANGLE)`
  width: 12px;
  height: 12px;
`;
