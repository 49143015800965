import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { THEME } from '../../../../../themes';
import { PerfectButtonVariants } from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { useGetOpenPositionsSelector } from '../../../../../store/rtk-query/apis/position/selectors/get-positions.selector';
import { useGetMyAccountSelector } from '../../../../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import STRING_KEYS from '../../../../../language/keys';
import {
  BottomContainer,
  Container,
  Label,
  LinkButton,
  PositionsCountText,
  StyledProgressBar,
} from './active-positions-widget.styled';

const ActivePositionsWidget = ({ upgradeButtonClickHandler }: ActivePositionsWidgetProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { data: openPositions } = useGetOpenPositionsSelector();
  const { data: { plan: { maximumOpenPositions = 0 } } } = useGetMyAccountSelector();
  const progressPercentage = useMemo(() => (openPositions.length / maximumOpenPositions) * 100, [openPositions, maximumOpenPositions]);

  return (
    <>
      <Container>
        <Label>{ translate(STRING_KEYS.HOMEPAGE.ACTIVE_POSITIONS_WIDGET.LABEL) }</Label>
        <StyledProgressBar progressPercentage={ progressPercentage } color={ THEME.COLORS.ACCENT } />
        <BottomContainer>
          <PositionsCountText>
            { translate(STRING_KEYS.HOMEPAGE.ACTIVE_POSITIONS_WIDGET.POSITIONS_COUNT_TEXT, {
              openPositionsCount: openPositions.length,
              maximumOpenPositionsCount: Math.max(openPositions.length, maximumOpenPositions),
            }) }
          </PositionsCountText>
          <LinkButton
            variant={ PerfectButtonVariants.Link }
            onClick={ upgradeButtonClickHandler }
          >
            { translate(STRING_KEYS.UPGRADE) }
          </LinkButton>
        </BottomContainer>
      </Container>
    </>
  );
};

export default ActivePositionsWidget;
