import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';
import { Subtitle2Font } from '../../../../themes/fonts';
import { hexToRgbA } from '../../../../utils/colors';

export const CardContainer = styled.div`
  width: 580px;
  height: 294px;
  border-radius: ${THEME.RADIUS.CURVY};
  outline: 2px dashed ${THEME.COLORS.PURE_WHITE};
  outline-offset: ${THEME.SPACING.TINY};
  margin: ${THEME.SPACING.BASE};
  background-color: ${hexToRgbA(THEME.COLORS.PURE_WHITE, THEME.OPACITY.LOW)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${THEME.SPACING.TINO};
  cursor: pointer;
  transition: all 0.25s ease;
  &:hover {
    transform: scale(1.01);
  }
  &:active {
    transform: scale(0.99);
  }
`;

export const Title = styled.div`
  ${Subtitle2Font};
  font-weight: 600;
  line-height: 1.36;
  letter-spacing: 1.34px;
  color: ${THEME.COLORS.TRUE_BLACK};
  text-transform: uppercase;
`;

export const AddIcon = styled(ASSETS.FILLED_PLUS)`
  height: 24px;
  fill: ${THEME.COLORS.TRUE_BLACK};
`;
