import {
  useRef, useState,
} from 'react';
import BaseMenu from '../../../../../../components/Common/perfect-base-menu/perfect-base-menu.component';
import { SequenceMessageTypeEnum } from '../../../../../../store/rtk-query/apis/sequence/enums/sequence-message-type.enum';
import { SelectionButtonProps } from './selection-button';
import { Container, Label, MenuContainer } from './selection-button.styled';
import { SelectionButtonEnum } from './selection-button.enum';
import SelectTypePopup from './select-type-popup/select-type-popup.component';
import SelectDaysPopup from './select-days-popup/select-days-popup.component';
import SelectTimesPopup from './select-time-popup/select-time-popup.component';
import SelectFirstMessageDayPopup from './select-first-message-day-popup/select-first-message-day-popup.component';

const SelectionButton = ({
  children, Icon, type, selected, step, onTimeChangeHandler, onDaysChangeHandler, onFirstStepDaysChangeHandler, messageTypeChangeHandler,
}: SelectionButtonProps): JSX.Element => {
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const handleButtonClick = () => anchorElement ? setAnchorElement(null) : setAnchorElement(ref?.current);
  const handleMenuClose = () => setAnchorElement(null);

  const onSelectTypeClick = (selectedType: SequenceMessageTypeEnum) => {
    messageTypeChangeHandler?.(selectedType);
    handleMenuClose();
  };

  const onConfirmDaysClick = (selectedDays: string, isWorkDays: boolean) => {
    onDaysChangeHandler?.(selectedDays, isWorkDays);
    handleMenuClose();
  };

  const onConfirmFirstStepDaysClick = (selectedDays: string) => {
    onFirstStepDaysChangeHandler?.(selectedDays);
    handleMenuClose();
  };

  const onConfirmTimeClick = (selectedTime: string) => {
    onTimeChangeHandler?.(selectedTime);
    handleMenuClose();
  };

  const renderPopupByType = () => {
    switch (type) {
      case SelectionButtonEnum.SenderTypes: {
        return <SelectTypePopup onItemClickHandler={ onSelectTypeClick } />;
      }
      case SelectionButtonEnum.Days: {
        return <SelectDaysPopup days={ step?.days } onConfirmClickHandler={ onConfirmDaysClick } isWorkDays={ !!step?.isWorkDays } />;
      }
      case SelectionButtonEnum.FirstMessageDay: {
        return <SelectFirstMessageDayPopup time={ step?.time } confirmButtonClickHandler={ onConfirmFirstStepDaysClick } />;
      }
      case SelectionButtonEnum.Time: {
        return (
          <SelectTimesPopup
            time={ step.time }
            confirmButtonClickHandler={ onConfirmTimeClick }
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <>
      <Container onClick={ handleButtonClick } ref={ ref }>
        <Icon />
        <Label selected={ selected }>{ children }</Label>
      </Container>
      <BaseMenu
        disabledArrowNavigation
        isOpen={ !!anchorElement }
        onClose={ handleMenuClose }
        anchorElement={ anchorElement || undefined }
        placement="bottom-start"
      >
        <MenuContainer>{ renderPopupByType() }</MenuContainer>
      </BaseMenu>
    </>
  );
};

export default SelectionButton;
