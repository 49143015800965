import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { OverlineFont } from '../../../../themes/fonts';
import { ASSETS } from '../../../../assets';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
`;

export const Section = styled.div<{ disableGap?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ disableGap }) => disableGap ? undefined : THEME.SPACING.BASE};
`;

export const SectionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
  opacity: ${THEME.OPACITY.MED};
`;

export const SectionTitle = styled.div`
  ${OverlineFont};
  font-weight: bold;
`;

export const IconWrapper = styled.div`
  width: 24px;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const DegreesIcon = styled(ASSETS.LIST_COMPONENT)`
  fill: currentColor;
  width: 16px;
`;

export const FieldsOfStudyIcon = styled(ASSETS.THREE_DOTS)`
  fill: currentColor;
  width: 11px;
`;
