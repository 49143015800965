import styled  from 'styled-components';
import { THEME } from '../../../themes';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: ${THEME.SPACING.LOOSE};
`;

export const StyledForm = styled.form<{isDisabled: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  width: 90%;
  max-width: 400px;
  opacity: ${(props) => props.isDisabled ? THEME.OPACITY.LOW : THEME.OPACITY.HIGHEST};
  pointer-events: ${(props) => props.isDisabled ? 'none' : 'all'};
`;

export const SubmitButtonWrapper = styled.div`
  width: 100%;
  margin-top: ${THEME.SPACING.MEDIUM};
`;

export const ErrorContainer = styled.div`
  width: 464px;
  margin-top: ${THEME.SPACING.TINY};
`;
