import styled from 'styled-components';
import { Scrollbar } from '../../../../../../themes/scrollbar';
import { THEME } from '../../../../../../themes';
import { Subtitle1Font, Subtitle2Font } from '../../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../../utils/colors';

export const ConversationGroupsContainer = styled.div`
  ${Scrollbar};
  height: 100%;
  padding-top: ${THEME.SPACING.BASE};
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
`;

export const HeaderContainer = styled.div`
  padding-left: ${THEME.SPACING.MEDIUM};
`;

export const HeaderTitle = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  opacity: ${THEME.OPACITY.MED};
`;

export const GroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
  padding: 0 ${THEME.SPACING.TINO};
`;

export const GroupContainer = styled.div<{ active: boolean, disabled: boolean }>`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor:  ${({ disabled }) => disabled ? undefined : 'pointer'};
  opacity: ${({ disabled }) => disabled ? THEME.OPACITY.LOW : undefined};
  position: relative;
  z-index: 10;
  border-radius: 8px;
  padding-right: ${THEME.SPACING.TINY};
  background-color: ${({ active }) => active ? hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER) : undefined};
`;

export const ProgressContainer = styled.div<{ active: boolean, widthPercentage: number }>`
  width: ${({ widthPercentage }) => `${widthPercentage}%`};
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: ${THEME.RADIUS.BASE};
  background-color: ${({ active }) => active ?  hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER) : THEME.COLORS.LIGHT_GREY};
  z-index: 9;
`;

export const GroupTitleContainer = styled.div`
  margin-left: ${THEME.SPACING.BASE};
  z-index: 11;
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
`;

export const ConversationNumber = styled.div`
  ${Subtitle2Font};
  font-weight: 600;
  line-height: 1.71;
  z-index: 11;
  opacity: ${THEME.OPACITY.LOW};
  margin-right: ${THEME.SPACING.TINO};
`;
