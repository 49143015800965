import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { CaptionFont, Title3Font } from '../../../../../themes/fonts';
import { ASSETS } from '../../../../../assets';
import { hexToRgbA } from '../../../../../utils/colors';

export const Container =  styled.div<{ $isActive: boolean, backgroundColor?: string }>`
  width: 168px;
  height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: ${THEME.OPACITY.HIGH};
  background-color: ${({ $isActive, backgroundColor }) => $isActive ? backgroundColor || THEME.COLORS.PURE_WHITE : THEME.COLORS.GREY};
  padding: ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.BASE};
  color: ${({ $isActive, backgroundColor }) => $isActive && backgroundColor ? THEME.COLORS.PURE_WHITE : hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.LOW)};
  cursor: pointer;
  transition: transform 200ms;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
`;

export const TitleIconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;

export const Count = styled.div<{ $isActive: boolean }>`
  ${Title3Font};
  font-weight: ${({ $isActive }) => $isActive ? 600 : 400};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div<{ $isActive: boolean, isDark?: boolean }>`
  ${CaptionFont};
  line-height: 1.17;
  letter-spacing: 0.21px;
  color:  ${({ $isActive, isDark }) => {
    if (isDark) {
      return THEME.COLORS.PRIMARY_DARK;
    }
    
    return $isActive ? THEME.COLORS.PURE_WHITE : hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.LOW);
  }};
  max-width: 85px;
`;

export const ActionIcon = styled(ASSETS.ARROW_BACK_COMPONENT)<{ $isActive: boolean, color?: string }>`
  width: 24px;
  height: 24px;
  fill: ${({ $isActive, color }) => $isActive ? color || undefined : undefined};
  padding: ${THEME.SPACING.MICRO};
  background-color: ${THEME.COLORS.PURE_WHITE};
  border-radius: ${THEME.RADIUS.CIRCLE};
`;

export const BodyIconWrapper = styled.div`
  height: 24px;
  
  & svg {
    height: 100%;
    fill: ${THEME.COLORS.PRIMARY_DARK};
  }
`;
