import { Container } from './slide-in-sentence.styled';

type SlideInSentenceProps = {
  content: string,
  delay: number,
}
export const SlideInSentence = ({
  content,
  delay,
}:SlideInSentenceProps): JSX.Element => {
  return (
    <Container delay={ delay }>
      {content}
    </Container>
  );
};
