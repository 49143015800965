import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { PerfectButtonHoverVariants } from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../language/keys';
import { getRandomIntBetweenRange } from '../../../../utils/number';
import { EngagementFeedbackContainer, UndoButton } from './engagement-feedback.styled';

const FEEDBACK_KEYS = [
  STRING_KEYS.ENGAGEMENT_FEEDBACK.THANKS,
  STRING_KEYS.ENGAGEMENT_FEEDBACK.GOT_YOU,
  STRING_KEYS.ENGAGEMENT_FEEDBACK.CHEERS,
  STRING_KEYS.ENGAGEMENT_FEEDBACK.SUPER,
  STRING_KEYS.ENGAGEMENT_FEEDBACK.AWESOME,
  STRING_KEYS.ENGAGEMENT_FEEDBACK.LOVE_IT,
];

const EngagementFeedback = ({ undoButtonLoading, onUndoClickHandler, matchId }: EngagementFeedbackProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [translationKey, setTranslationKey] = useState(STRING_KEYS.ENGAGEMENT_FEEDBACK.THANKS);

  useEffect(() => {
    if (matchId) {
      const randomIndex = getRandomIntBetweenRange(0, FEEDBACK_KEYS.length - 1);

      setTranslationKey(FEEDBACK_KEYS[randomIndex]);
    }
  }, [matchId]);

  return (
    <EngagementFeedbackContainer>
      { translate(translationKey) }
      <UndoButton
        hoverVariant={ PerfectButtonHoverVariants.Grow }
        onClick={ onUndoClickHandler }
        isLoading={ undoButtonLoading }
      >
        { translate(STRING_KEYS.ACTION_UNDO) }
      </UndoButton>

    </EngagementFeedbackContainer>
  );
};

export default EngagementFeedback;
