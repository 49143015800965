import styled from 'styled-components';
import { THEME } from '../../../../../../themes';

export const ContactDetailsSkeletonRow = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINO};
  align-items: center;
`;

export const ContactDetailsSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MEDIUM};
`;
