import { Badge } from '@mui/material';
import { InitialsAvatar } from '../InitialsAvatar';
import { getInitials } from '../../../utils';
import { Avatar, BadgeAvatar } from './avatar-with-badge.styled';

export const AvatarWithBadge = ({
  avatarImage,
  name,
  badgeImage,
  badgeAlt,
  size,
}: AvatarWithBadgeProps): JSX.Element => {
  const renderAvatar = () => {
    return (
      <Avatar alt={ name } src={ avatarImage } size={ size }>
        <InitialsAvatar
          alt={ name }
          message={ getInitials(name) }
          fontSize="16px"
        />
      </Avatar>
    );
  };

  if (!badgeImage) {
    return renderAvatar();
  }

  return (
    <Badge
      overlap="circular"
      anchorOrigin={ { vertical: 'bottom', horizontal: 'right' } }
      badgeContent={
        <BadgeAvatar alt={ badgeAlt } src={ badgeImage } />
      }
    >
      { renderAvatar() }
    </Badge>
  );
};
