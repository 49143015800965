import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { HeadlineFont } from '../../../../../../themes/fonts';
import { HorizontalDivider } from '../../../../../../components/Common/horizontal-divider/horizontal-divider.component';
import { DividerContainer as EditJobRequirementDivider } from '../../edit-job-requirement/edit-job-requirement.styled';

export const StyledDivider = styled(HorizontalDivider)`
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${THEME.SPACING.MEDIUM};
  
  ${EditJobRequirementDivider} {
    width: 50%;
  }
`;

export const Title = styled.div`
  ${HeadlineFont};
  font-weight: normal;
  color: ${THEME.COLORS.CONTENT_A};
`;
