import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { CaptionFont } from '../../../../../themes/fonts';
import { TimeAgoItem } from '../../../../../components/Common/TimeAgoItem';

export const MiddleDot = styled.span`
  margin-left: ${THEME.SPACING.MICRO};
  margin-right: ${THEME.SPACING.MICRO};
  font-weight: 700;
`;

export const PositionCreated = styled.span``;

export const LastUpdated = styled.span`
  margin-bottom: ${THEME.SPACING.TINO};
  ${CaptionFont};
`;

export const Label = styled.span`
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_A};
  margin-right: ${THEME.SPACING.TINY};
`;

export const TimeAgoItemWrapper = styled(TimeAgoItem)`
`;
