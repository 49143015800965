import styled from 'styled-components';
import { THEME } from '../../../themes';
import Dialog from '../base-dialog/base-dialog.component';
import { HeadlineFont, Title3Font } from '../../../themes/fonts';

export const StyledDialog = styled(Dialog)`
  padding: ${THEME.SPACING.MEDIUM} ${THEME.SPACING.LOOSE};
`;

export const HeaderContainer = styled(Dialog.Header)`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MICRO};
  color: ${THEME.COLORS.CONTENT_A};
  width: fit-content;
`;

export const Title = styled.div`
  ${Title3Font};
  font-weight: 600;
`;

export const SubTitle = styled.div`
  ${HeadlineFont};
  opacity: ${THEME.OPACITY.LOW};
  font-weight: 500;
`;
