import { Avatar } from '@mui/material';
import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import {
  CaptionFont,
} from '../../../../../themes/fonts';

export const Container = styled.div`
  position: relative;
  display: block;
  padding: ${THEME.SPACING.MICRO} ${THEME.SPACING.TINY};
  border-radius: ${THEME.RADIUS.CIRCLE};
  :hover {
    background-color: ${THEME.COLORS_RGB.ACCENT_GHOST};
  }
`;

export const StyledAvatar = styled(Avatar).attrs({
  style: {
    width: '32px',
    height: '32px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
})`
`;

export const ResendInvite = styled.div`
  ${CaptionFont};
  font-weight: bold;
  line-height: 1.58;
  letter-spacing: 1.15px;
  text-transform: uppercase;
  color: ${THEME.COLORS.ACCENT};
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: calc(50% - 9px);
`;
