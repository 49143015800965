export const getGoogleSocialLoginUrl = (clientId: string, redirectUri: string): string => {
  const searchParams: URLSearchParams = new URLSearchParams({
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: 'code',
    include_granted_scopes: 'true',
    scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
    state: '',
  });

  const url: URL = new URL('https://accounts.google.com/o/oauth2/v2/auth');
  url.search = searchParams.toString();

  return url.toString();
};

export const passwordValidator = (password: string): boolean => {
  return password.length >= 8;
};
