import { accountQuery } from '../account.toolkit-api';
import { AccountBasicInfoResponse } from '../dto/response/account-basic-info.response';

export const useGetAccountBySubDomainQuery = (subDomain: string, skip = false): AppSelectorType<AccountBasicInfoResponse> => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
  } = accountQuery.useGetAccountBySubdomainQuery(
    subDomain,
    {
      skip: !subDomain || skip,
    },
  );

  return {
    data: data || {} as AccountBasicInfoResponse,
    error,
    isLoading,
    isError,
    isSuccess,
  };
};
