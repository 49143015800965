import { Redirect, Route, Switch } from 'react-router-dom';
import { AppRouting } from '../../services/appRoutingResolver';
import PlanExpired from './pages/plan-expired.page';

const PlanExpiredRouter = (): JSX.Element | null => {
  return (
    <Switch>
      <Route path={ AppRouting.ROUTES.HOME } exact>
        <PlanExpired />
      </Route>
      <Route path="*">
        <Redirect to={ { pathname: AppRouting.ROUTES.HOME } } push />
      </Route>
    </Switch>
  );
};

export default PlanExpiredRouter;
