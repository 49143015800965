import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';

export const CloseButton = styled.div`
  width: 32px;
  height: 32px;
  font-size: 0;
  border-radius: ${THEME.RADIUS.CIRCLE};
  background: ${THEME.COLORS.LIGHT_GREY} url(${ASSETS.CLOSE_DIALOG}) no-repeat center center;
  cursor: pointer;
  transition: all 0.2s;
  z-index:2;
  :hover {
    opacity: ${THEME.OPACITY.MED};
  }
`;
