/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  ANALYTICS_EVENTS,
  COMPONENT_NAME,
  ELEMENT_TYPE,
  EVENT_GROUP,
  PRODUCT_AREA,
} from '../../../../../consts/analytics';
import { useAnalyticsContext } from '../../../../../contexts/analytics/useAnalyticsContext';
import { AppRouting } from '../../../../../services/appRoutingResolver';
import MatchLimitDailyBase from '../match-daily-limit-base/match-daily-limit.component';
import { PricingPlanEnum } from '../../../../../enums/user-plan.enum';
import STRING_KEYS from '../../../../../language/keys';
import { CREATE_POSITION_ANALYTICS_EVENTS } from '../../../../create-position/consts/position-analytics.consts';
import { PAGE_NAMES } from '../../../../../consts/pages';
import { useGetMyAccountSelector } from '../../../../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
} from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { UpgradePlanDialog } from '../../../../../components/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import { UpgradePlanDialogVariation } from '../../../../../components/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.enums';
import { useGetPositionSelector } from '../../../../../store/rtk-query/apis/position/selectors/get-position.selector';
import {
  LianaIcon,
  SlothIcon,
  FilledButton,
  GhostButton,
} from './match-daily-limit-free.styled';

const MatchLimitDailyFree = ():JSX.Element | null => {
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
  const { positionId } = useParams<MatchUrlParams>();
  const browserHistory = useHistory();
  const { t: translate } = useTranslation();
  const { data: { plan } } = useGetMyAccountSelector();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const { data: { dailyLimitSize } } = useGetPositionSelector(positionId);

  useEffect(() => {
    if (isUpgradeDialogOpen) {
      const eventData = {
        eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_PREMIUM_MODAL_SHOWN,
        eventGroup: EVENT_GROUP.IMP,
        pageName: PRODUCT_AREA.MATCH_PAGE,
        productArea: `${PRODUCT_AREA.MATCH_PAGE}`,
        componentName: plan?.pricingPlan !== PricingPlanEnum.LITE ? COMPONENT_NAME.ADD_POSITION_PRO : COMPONENT_NAME.ADD_POSITION_LITE,
        pagePositionId: positionId,
      };

      analyticsTrackEvent(eventData);
    }
  }, [isUpgradeDialogOpen]);

  const onClickUpgrade = () => {
    const eventData = {
      eventName: ANALYTICS_EVENTS.CLICKED_ON_GET_UNLIMITED_MATCHES,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.MATCH_LIMIT,
      productArea: PRODUCT_AREA.MATCH_PAGE,
      pagePositionId: positionId,
    };

    analyticsTrackEvent(eventData);
    setIsUpgradeDialogOpen(true);
  };

  const onClickGoToPositionReachout = () => {
    const eventData = {
      eventName: ANALYTICS_EVENTS.CLICKED_ON_REACH_YOUR_MATCHES,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.MATCH_LIMIT,
      productArea: PRODUCT_AREA.MATCH_PAGE,
      pagePositionId: positionId,
    };

    analyticsTrackEvent(eventData);
    browserHistory.push(
      AppRouting.buildPositionConversationsPageUrl(positionId),
    );
  };

  const onDialogClickBIEvent = (type: string) => {
    const clickOnDialogEventData = {
      eventName: ANALYTICS_EVENTS.CLICKED_ON_PREMIUM_MODAL,
      eventGroup: EVENT_GROUP.CLICK,
      productArea: PRODUCT_AREA.MATCH_PAGE,
      pageName: PAGE_NAMES.MATCH_LIMIT,
      componentElementType: type,
      componentName: plan?.pricingPlan !== PricingPlanEnum.LITE ? COMPONENT_NAME.PRO_POSITION_LIMIT : COMPONENT_NAME.LITE_POSITION_LIMIT,
    };

    analyticsTrackEvent(clickOnDialogEventData);
  };

  const onDialogCloseHandler = () => {
    setIsUpgradeDialogOpen(false);
    onDialogClickBIEvent(ELEMENT_TYPE.CLOSE);
  };

  const ViewUpgradeOptionsButton: JSX.Element = (
    <FilledButton
      size={ PerfectButtonSize.Large }
      onClick={ onClickUpgrade }
      hoverVariant={ PerfectButtonHoverVariants.Grow }
    >
      { translate(STRING_KEYS.DAILY_LIMIT.PRO_USER.PRIMARY_BUTTON) }
    </FilledButton>
  );

  const ReachoutMatchesButton: JSX.Element = (
    <GhostButton
      size={ PerfectButtonSize.Large }
      onClick={ onClickGoToPositionReachout }
      hoverVariant={ PerfectButtonHoverVariants.Grow }
    >
      { translate(STRING_KEYS.DAILY_LIMIT.PRO_USER.SECONDARY_BUTTON) }
    </GhostButton>
  );

  return (
    <>
      <MatchLimitDailyBase
        positionId={ positionId }
        Background={ <LianaIcon /> }
        Icon={ <SlothIcon /> }
        title={ translate(STRING_KEYS.DAILY_LIMIT.FREE_USER.TITLE) }
        description={ translate(STRING_KEYS.DAILY_LIMIT.FREE_USER.DESCRIPTION, { planName: translate(STRING_KEYS.PRICE_PLAN[plan.pricingPlan]), dailyLimitSize }) }
        PrimaryButton={ ViewUpgradeOptionsButton }
        SecondaryButton={ ReachoutMatchesButton }
      />
      { isUpgradeDialogOpen && (
        <UpgradePlanDialog
          isDialogOpen={ isUpgradeDialogOpen }
          onCloseDialog={ onDialogCloseHandler }
          variation={ UpgradePlanDialogVariation.ExceedPositionLimit }
        />
      ) }
    </>
  );
};

export default MatchLimitDailyFree;
