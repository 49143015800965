const createId = (inputName: string) => {
  return `create_position_${inputName}_input`;
};

export const CREATE_POSITION_INPUT_ID = {
  JOB_TITLE: createId('job_title'),
  SKILL: createId('skill'),
  COMPANY_BACKGROUND: createId('company_background'),
  FIELD_OF_STUDY: createId('field_of_study'),
  EDUCATION: createId('education'),
  LOCATION: createId('location'),
  JOB_DESCRIPTION: createId('job_description'),
};
