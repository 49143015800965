import {
  MatchPreviousFamiliarity,
  NetworkedTalentResponseDto,
} from '../../store/rtk-query/apis/match/dto/response/match.response';
import { TalentNetworkExperienceDto } from '../../modules/application/components/talent-network/mutual-experience-sentence/mutual-experience-sentence';
import { TalentNetworkEducationDto } from '../../modules/application/components/talent-network/mutual-education-sentence/mutual-education-sentence';
import { NO_OVERLAP_ID } from './match-content/talent-network-timeline-base/talent-network-timeline-base.consts';

export const createTalentNetworkCompanyExperienceMap = (networkedTalents?: NetworkedTalentResponseDto[]): Map<string, Map<number, TalentNetworkExperienceDto[]>> => {
  const companyExperienceMap = new Map<string, Map<number, TalentNetworkExperienceDto[]>>();

  networkedTalents?.forEach((networkedTalent) => {
    networkedTalent?.employmentConnections.forEach((employmentConnection) => {
      const experienceMap = companyExperienceMap.get(employmentConnection.companyId) || new Map();
      const experienceId = employmentConnection.overlapInfo.overlapping ? employmentConnection.experienceId : NO_OVERLAP_ID;
      const experienceArray: TalentNetworkExperienceDto[] = experienceMap.get(experienceId) || [];
      experienceArray.push({ talent: networkedTalent.talent, experience: employmentConnection });
      experienceMap.set(experienceId, experienceArray);
      companyExperienceMap.set(employmentConnection.companyId, experienceMap);
    });
  });

  return companyExperienceMap;
};

export const createTalentNetworkCompanyEducationMap = (networkedTalents?: NetworkedTalentResponseDto[]): Map<string, Map<number, TalentNetworkEducationDto[]>> => {
  const companyEducationMap = new Map<string, Map<number, TalentNetworkEducationDto[]>>();

  networkedTalents?.forEach((networkedTalent) => {
    networkedTalent?.educationConnections.forEach((educationConnection) => {
      const educationMap = companyEducationMap.get(educationConnection.institutionId) || new Map();
      const educationArray: TalentNetworkEducationDto[] = educationMap.get(educationConnection.educationId) || [];
      educationArray.push({ talent: networkedTalent.talent, education: educationConnection });
      educationMap.set(educationConnection.educationId, educationArray);
      companyEducationMap.set(educationConnection.institutionId, educationMap);
    });
  });

  return companyEducationMap;
};

export const isPreviousFamiliarityValid = (previousFamiliarity?: MatchPreviousFamiliarity): boolean => {
  return !!(previousFamiliarity?.previouslyInteractedInAts || previousFamiliarity?.candidateInAts || previousFamiliarity?.engagedInDifferentPosition);
};
