import {
  ChangeEvent, SyntheticEvent, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { IndeterminateLinearLoader } from '../../Common/Loaders/IndeterminateLinearLoader';
import STRING_KEYS from '../../../language/keys';
import { useClosePositionMutation } from '../../../store/rtk-query/apis/position/hooks/close-position.mutation-hook';
import {
  ClosePositionModalContainer,
  BackButton,
  CloseButton,
  Title,
  TopNavWrapper,
  LoaderWrapper,
  ModalBackdrop,
} from './close-position-modal.styled';
import SomethingElseReason from './SomethingElseReason';
import ReasonButtons from './ReasonButtons';

function ClosePositionModal({
  positionId,
  onClose,
}: ClosePositionModalProps): JSX.Element {
  const [otherReasonSelected, setOtherReasonSelected] = useState<boolean>(false);
  const [freeText, setFreeText] = useState<string>('');
  const { t: translate } = useTranslation();

  const [
    closePosition,
    { isLoading: isClosePositionLoading, isSuccess: isClosePositionSuccess, resetState: resetClosePositionState },
  ] = useClosePositionMutation();

  useEffect(() => {
    if (isClosePositionSuccess) {
      resetClosePositionState?.();
      onClose({ isPositionClosedSuccessfully: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClosePositionSuccess]);

  const handleModalWrapperClick  = (e: SyntheticEvent) => {
    e.preventDefault();
  };

  const handleSubmitPositionClose = (reasonId: string) => {
    closePosition({ positionId, closureReason: reasonId, freeText });
  };

  const handleFreeTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFreeText(e.target.value);
  };

  const handleGoBack = () => {
    setFreeText('');
    setOtherReasonSelected(false);
  };

  const defineModalContent = () => {
    if (otherReasonSelected) {
      return (
        <SomethingElseReason
          freeText={ freeText }
          onFreeTextChange={ handleFreeTextChange }
          onSubmitPositionClose={ handleSubmitPositionClose }
        />

      );
    }

    return (
      <ReasonButtons
        setOtherReasonSelected={ setOtherReasonSelected }
        onSubmitPositionClose={ handleSubmitPositionClose }
      />
    );
  };

  return (
    <>
      <ModalBackdrop
        onClick={ (event: SyntheticEvent) => {
          event.stopPropagation();
          onClose();
        } }
      />
      <ClosePositionModalContainer onClick={ handleModalWrapperClick  }>

        {isClosePositionLoading && (
          <LoaderWrapper>
            <IndeterminateLinearLoader loaderHeight="4px" />
          </LoaderWrapper>
        )}

        <TopNavWrapper>
          <CloseButton onClick={ () => onClose() } />
          {otherReasonSelected &&
          <BackButton onClick={ handleGoBack } />}
        </TopNavWrapper>

        <Title>
          {translate(STRING_KEYS.CLOSE_POSITION.TITLE)}
        </Title>

        { defineModalContent() }

      </ClosePositionModalContainer>
    </>

  );
}

export default ClosePositionModal;
