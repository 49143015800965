import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import parse from 'html-react-parser';
import { ConversationPageSizeEnum, useConversationPageSize } from '../../hooks/conversations-page-size.hook';
import SidePanel from '../../../../components/Common/side-panel/side-panel.component';
import { SidePanelVariant } from '../../../../components/Common/side-panel/side-panel.enums';
import { TextTyping } from '../../../../components/Common/text-typing/text-typing.component';
import { PerfectButtonHoverVariants } from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../language/keys';
import { getRandomIntBetweenRange } from '../../../../utils/number';
import OutreachChip from '../outreach-chip/outreach-chip.component';
import {
  ActionContainer,
  Banner,
  BodyContainer,
  Header,
  MessageContentIcon, PreviewContainer,
  RegenerateButton,
  RegenerationIcon,
  SidePanelContainer,
  SidePanelHeaderContainer,
  TypingTextContainer,
} from './message-preview-side-panel.styled';
import MessagePreviewSkeleton from './message-preview-skeleton/message-preview-skeleton.component';

const MessagePreviewSidePanel = ({
  isOpen, onClose, isPreferencesLoading, isCreateMessagePreviewLoading, message, subject, generateMessageHandler, shouldType,
}: MessagePreviewSidePanelProps):JSX.Element => {
  const { t: translate } = useTranslation();
  const pageSize = useConversationPageSize();
  const randomNumber = useMemo(() => {
    if (isCreateMessagePreviewLoading) {
      return getRandomIntBetweenRange(0, STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.LOADING_TEXT.length - 1);
    }

    return 0;
  }, [isCreateMessagePreviewLoading]);
  const isSmallScreen = pageSize === ConversationPageSizeEnum.Small;

  const formatText = useMemo(() =>  {
    let text = '';
    if (subject) {
      text = `<b>${subject}</b>`;
    }

    if (message) {
      text = `${text}\n\n ${message}`;
    }

    return text;
  }, [message, subject]);

  const renderPreview = useCallback(() => {
    if (isCreateMessagePreviewLoading || isPreferencesLoading) {
      return (
        <MessagePreviewSkeleton />
      );
    }
    if (!formatText && !isPreferencesLoading) {
      return (
        <MessagePreviewSkeleton animation={ false } />
      );
    }

    return (
      <div>
        <Banner>
          {translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.BANNER_TEXT)}
        </Banner>
        <PreviewContainer>
          <TypingTextContainer>
            {
              shouldType ? (
                <TextTyping
                  shouldStartTyping
                  typingText={ formatText || '' }
                  ghostText={ formatText || '' }
                />
              ) : (
                <>
                  { parse(formatText) }
                </>
              )
            }
          </TypingTextContainer>
        </PreviewContainer>
      </div>
    );
  }, [formatText, isPreferencesLoading, shouldType, translate, isCreateMessagePreviewLoading]);

  const ButtonIcon = useMemo(() => {
    if (!isPreferencesLoading && !message) {
      return (MessageContentIcon);
    }

    if (!isPreferencesLoading && message) {
      return (RegenerationIcon);
    }

    return undefined;
  }, [isPreferencesLoading, message]);

  const generateButtonText = useMemo(() => {
    let translationKey;
    if (isCreateMessagePreviewLoading) {
      translationKey = STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.GENERATE_BUTTON_LOADING;
    } else {
      translationKey = message ?
        translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.REGENERATE_BUTTON) :
        translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.GENERATE_BUTTON);
    }

    return translate(translationKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateMessagePreviewLoading, message]);

  return (
    <>
      <SidePanel
        isOpen={ isOpen }
        onClose={ onClose }
        variant={ isSmallScreen ? SidePanelVariant.Temporary : SidePanelVariant.Persistent }
      >
        <SidePanelContainer>
          <SidePanelHeaderContainer
            closeButtonClickHandler={ isSmallScreen ? onClose : undefined }
          >
            <Header>
              {translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.TITLE)}
            </Header>
          </SidePanelHeaderContainer>
          <BodyContainer>
            { renderPreview() }
            <ActionContainer>
              { isCreateMessagePreviewLoading && (
                <OutreachChip text={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.LOADING_TEXT[randomNumber]) } />
              )}
              <RegenerateButton
                hoverVariant={ PerfectButtonHoverVariants.Grow }
                StartIcon={ ButtonIcon }
                isLoading={ isCreateMessagePreviewLoading }
                disabled={ isPreferencesLoading }
                onClick={ generateMessageHandler }
                isMessageLoading={ isCreateMessagePreviewLoading }
              >
                { generateButtonText }
              </RegenerateButton>
            </ActionContainer>
          </BodyContainer>
        </SidePanelContainer>
      </SidePanel>
    </>
  );
};

export default MessagePreviewSidePanel;
