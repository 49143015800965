/* eslint-disable react/jsx-props-no-spreading */
import { Link } from 'react-router-dom';
import UserProfile from '../../user-profile/user-profile.component';
import { AppRouting } from '../../../../services/appRoutingResolver';
import {
  HeaderContainer,
  LeftContainer,
  PerfectIcon,
  RightContainer,
} from './header.styled';
import { HeaderProps } from './header';

const Header = ({ children, ...otherProps }: HeaderProps): JSX.Element => {
  return (
    <HeaderContainer { ...otherProps }>
      <LeftContainer>
        <Link to={ { pathname: AppRouting.ROUTES.HOME } }>
          <PerfectIcon />
        </Link>
      </LeftContainer>
      { children }
      <RightContainer>
        <UserProfile />
      </RightContainer>
    </HeaderContainer>
  );
};

export default Header;
