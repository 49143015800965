import { useMyUserSelector } from '../../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { AcknowledgementType } from '../../../../enums/acknowledgement-type.enum';
import {
  Avatar, AvatarsGroup, Container, LockIcon,
} from './talent-network-timeline-base.styled';
import { TalentNetworkTimelineBaseProps } from './talent-network-timeline-base';
import { MAX_AVATARS_IN_COMPONENT } from './talent-network-timeline-base.consts';
import { TalentNetworkFirstTimeExperience } from './talent-network-first-time-experience/talent-network-first-time-experience.component';

export const TalentNetworkTimelineBase = ({
  children,
  talentsInfo,
  isLocked,
  onClickHandler,
}: TalentNetworkTimelineBaseProps): JSX.Element | null => {
  const { data: { acknowledgements } } = useMyUserSelector();

  const isFirstTimeExperience = acknowledgements?.find((ack) => ack.type === AcknowledgementType.InsiderNetworkFirstTimeExperience);

  if (!talentsInfo?.length) {
    return null;
  }

  return (
    <div>
      <Container onClick={ onClickHandler }>
        <AvatarsGroup>
          { talentsInfo
            ?.map((talentInfo) => talentInfo.profileImage)
            .filter(Boolean)
            .slice(0, MAX_AVATARS_IN_COMPONENT)
            .map((profileImage) => <Avatar key={ profileImage } src={ profileImage } />)}
        </AvatarsGroup>
        { children }
        { isLocked && <LockIcon /> }
      </Container>
      { isFirstTimeExperience && <TalentNetworkFirstTimeExperience /> }
    </div>
  );
};
