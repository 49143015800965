import { Button } from './back-button.styled';

const BackButton = ({
  onClick,
}:BackButtonProps):JSX.Element => {
  return (
    <Button onClick={ onClick } />
  );
};

export default BackButton;
