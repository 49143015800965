import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import STRING_KEYS from '../../../../language/keys';
import {
  EmailSenderIntegrationStatusEnum,
  useGetEmailSenderIntegrationStatus,
} from '../../../../store/rtk-query/apis/outreach/hooks/get-email-sender.query-hook';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
} from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { OutreachInitializationDialogs } from '../../../../components/dialogs/outreach-initialization-dialogs/outreach-initialization-dialogs.component';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { ANALYTICS_EVENTS, EVENT_GROUP, PRODUCT_AREA } from '../../../../consts/analytics';
import { PAGE_NAMES } from '../../../../consts/pages';
import { useGetPositionSequencePreferencesSelector } from '../../../../store/rtk-query/apis/sequence/selectors/position-sequence-preferences.selector';
import { AppRouting } from '../../../../services/appRoutingResolver';
import {
  AnimatedContainer,
  ConnectEmailButton,
  ConnectEmailButtonContainer,
  ConnectEmailInfoText,
  ConversationsEmptyPage,
  OutreachDescription,
  OutreachIcon,
  OutreachTitle,
} from './conversation-empty.styled';

const ConversationEmpty = (): JSX.Element | null => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const { analyticsPageEvent, analyticsTrackEvent } = useAnalyticsContext();
  const emailSenderIntegrationStatus = useGetEmailSenderIntegrationStatus({ skipFetch: true });
  const [isOutreachInitializationDialogOpen, setIsOutreachInitializationDialogOpen]  = useState(false);
  const { positionId } = useParams<MatchUrlParams>();

  const {
    data: preferences,
    isSuccess: isGetPreferencesSuccess,
  } = useGetPositionSequencePreferencesSelector(positionId);

  useEffect(() => {
    if (isGetPreferencesSuccess && !preferences?.contentPreferences) {
      history.push(AppRouting.buildPositionContentPreferencesPageUrl(positionId));
    }
  }, [history, isGetPreferencesSuccess, positionId, preferences]);

  useEffect(() => {
    analyticsPageEvent({
      eventName: ANALYTICS_EVENTS.OUTREACH_EMPTY_PAGE_VIEW,
      pageName: PAGE_NAMES.TALENT_OUTREACH_EMPTY,
      eventGroup: EVENT_GROUP.PAGE_VIEW,
      productArea: PRODUCT_AREA.TALENT_OUTREACH,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTitle = () => {
    if (emailSenderIntegrationStatus === EmailSenderIntegrationStatusEnum.Active) {
      return (
        <>
          <span>{translate(STRING_KEYS.OUTREACH_PAGE.EMPTY_CONVERSATION.TITLE_LABEL)}</span>
          <span>{translate(STRING_KEYS.OUTREACH_PAGE.EMPTY_CONVERSATION.TITLE)}</span>
        </>
      );
    }

    return (
      <>
        <span>{translate(STRING_KEYS.OUTREACH_PAGE.EMPTY_CONVERSATION_NO_INTEGRATION.TITLE_LABEL)}</span>
        <span>{translate(STRING_KEYS.OUTREACH_PAGE.EMPTY_CONVERSATION_NO_INTEGRATION.TITLE)}</span>
      </>
    );
  };

  const renderDescription = () => {
    if (emailSenderIntegrationStatus === EmailSenderIntegrationStatusEnum.Active) {
      return translate(STRING_KEYS.OUTREACH_PAGE.EMPTY_CONVERSATION.DESCRIPTION);
    }

    return translate(STRING_KEYS.OUTREACH_PAGE.EMPTY_CONVERSATION_NO_INTEGRATION.DESCRIPTION);
  };

  const onClickOutreachInitialization = () => {
    setIsOutreachInitializationDialogOpen(true);
    analyticsTrackEvent({
      eventName: ANALYTICS_EVENTS.CLICKED_ON_CONNECT_MAIL_OUTREACH,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.TALENT_OUTREACH_EMPTY,
      productArea: PRODUCT_AREA.TALENT_OUTREACH,

    });
  };

  const renderAdditionalConnectEmail = (): JSX.Element | null => {
    if (emailSenderIntegrationStatus === EmailSenderIntegrationStatusEnum.Active) {
      return null;
    }

    return (
      <ConnectEmailButtonContainer>
        <ConnectEmailButton
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          size={ PerfectButtonSize.Large }
          onClick={ onClickOutreachInitialization  }
        >
          {translate(STRING_KEYS.OUTREACH_PAGE.EMPTY_CONVERSATION_NO_INTEGRATION.CONNECT_EMAIL_BUTTON)}
        </ConnectEmailButton>
        <ConnectEmailInfoText>
          {translate(STRING_KEYS.OUTREACH_PAGE.EMPTY_CONVERSATION_NO_INTEGRATION.CONNECT_EMAIL_INFO_TEXT)}
        </ConnectEmailInfoText>
      </ConnectEmailButtonContainer>
    );
  };

  return (
    <ConversationsEmptyPage>
      <>
        { emailSenderIntegrationStatus && (
          <AnimatedContainer>
            <OutreachIcon />
            <OutreachTitle>{ renderTitle() }</OutreachTitle>
            <OutreachDescription>{ renderDescription() }</OutreachDescription>
            { renderAdditionalConnectEmail() }
          </AnimatedContainer>
        ) }
      </>
      <OutreachInitializationDialogs
        isOpen={ isOutreachInitializationDialogOpen }
        closeDialogHandler={ () => setIsOutreachInitializationDialogOpen(false) }
        positionId={ positionId }
      />
    </ConversationsEmptyPage>
  );
};

export default ConversationEmpty;
