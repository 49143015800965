import { GetQuery, PostQuery } from '../../@types/rtk-query';
import { QueryMethods } from '../../enums/query-methods.enums';
import { ROUTES } from './payment.consts';

export const getPositionPriceQuery = (): GetQuery => ({
  url: ROUTES.POSITION_PRICE,
  method: QueryMethods.Get,
});

export const buyPositionQuery = (): PostQuery => ({
  url: ROUTES.BUY_POSITION,
  method: QueryMethods.Post,
});
