import log from 'loglevel';
import { useEffect, useState } from 'react';
import { MatchBody } from '../match/match-content/match-content.component';
import { MatchResponse } from '../../store/rtk-query/apis/match/dto/response/match.response';

const logger = log.getLogger('MATCH_PREVIEW_FRAME');

// TODO [refactor]: delete
// this component is used within iframe!
export const MatchPreviewFrame = (): JSX.Element => {
  const [data, setData] = useState<MatchResponse | null>(null);

  const handleNewMessage = (message: MessageEvent) => {
    if (message.type !== 'message') {
      logger.debug('skipping new message of type', message.type);

      return;
    }

    // todo: skip messages by origin (when servers are ready)
    if (!message.data.talent && !message.data.matchReasons) {
      logger.debug('skipping message without expected data', { message });

      return;
    }

    logger.debug('got new message with data', { message });
    setData(message.data);
  };

  useEffect(() => {
    window.addEventListener('message', handleNewMessage);

    return () => {
      window.removeEventListener('message', handleNewMessage);
    };
  }, []);

  if (!data) {
    // todo: change
    logger.debug('waiting for match-preview data...');

    return <div>Waiting for data...</div>;
  }

  return (
    <MatchBody
      data={ data }
      isLoading={ false }
    />
  );
};
