import { atsIntegrationQuery } from '../ats-integration.toolkit-api';
import { AtsJobsResponse } from '../dto/response/ats-job.response';

export const useGetAccountAtsJobsQuery = (skip: boolean): AppSelectorType<AtsJobsResponse> => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
  } = atsIntegrationQuery.useGetAccountAtsJobsQuery(
    undefined,
    { skip, refetchOnMountOrArgChange: true },
  );

  return {
    data: data || {} as AtsJobsResponse,
    error,
    isLoading,
    isError,
    isSuccess,
  };
};
