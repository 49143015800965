import { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { IconButtonVariants } from '../../../../../../components/Common/Buttons/icon-button/icon-button.enums';
import { PerfectButtonHoverVariants } from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { AppRouting } from '../../../../../../services/appRoutingResolver';
import STRING_KEYS from '../../../../../../language/keys';
import {
  BoldText,
  ButtonIcon,
  ExternalLinkIcon,
  LinkedinButton,
  LinkedinIcon,
  MarkAsDoneIcon,
  MenuDescription,
  MenuHeaderContainer,
  MenuTitle,
  StyledMenu,
  StyledMenuItem,
} from './send-via-linkedin-menu.styled';

const SendViaLinkedinMenu = ({
  talentName,
  talentLinkedinUrl,
  disabled = false,
  menuButtonClickHandler,
  markMessageAsSentButtonClickHandler,
}: SendViaLinkedinMenuProps): JSX.Element => {
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);
  const anchorElementRef = useRef<HTMLDivElement>(null);

  const onMarkMessageAsSentMenuItemClick = () => {
    setAnchorElement(null);
    markMessageAsSentButtonClickHandler();
  };

  const onOpenLinkedinMenuItemClick = () => {
    setAnchorElement(null);

    if (talentLinkedinUrl) {
      AppRouting.openInNewTab(talentLinkedinUrl);
    }
  };

  const onMenuButtonClick = () => {
    anchorElement ? setAnchorElement(null) : setAnchorElement(anchorElementRef?.current);
    menuButtonClickHandler?.();
  };

  return (
    <div
      ref={ anchorElementRef }
    >
      <LinkedinButton
        variant={ IconButtonVariants.Fill }
        isActive={ !!anchorElement }
        StartIcon={ ButtonIcon }
        hoverVariant={ PerfectButtonHoverVariants.Grow }
        onClick={ onMenuButtonClick }
        disabled={ disabled }
      />
      <StyledMenu
        isOpen={ !!anchorElement }
        anchorElement={ anchorElement || undefined }
        placement="top-end"
        onClose={ () => setAnchorElement(null) }
        Title={ (
          <MenuHeaderContainer>
            <MenuTitle>
              <Trans i18nKey={ STRING_KEYS.OUTREACH_PAGE.EDITOR.SEND_VIA_LINKEDIN_MENU.TITLE } />
            </MenuTitle>
            <MenuDescription>
              <Trans
                i18nKey={ STRING_KEYS.OUTREACH_PAGE.EDITOR.SEND_VIA_LINKEDIN_MENU.DESCRIPTION }
                values={ { talentName } }
              >
                <BoldText />
              </Trans>
            </MenuDescription>
          </MenuHeaderContainer>
        ) }
      >
        <StyledMenuItem onClick={ onOpenLinkedinMenuItemClick }>
          <LinkedinIcon />
          <Trans i18nKey={ STRING_KEYS.OUTREACH_PAGE.EDITOR.SEND_VIA_LINKEDIN_MENU.ITEMS.OPEN_LINKEDIN } values={ { talentName } } />
          <ExternalLinkIcon />
        </StyledMenuItem>
        <StyledMenuItem onClick={ onMarkMessageAsSentMenuItemClick }>
          <MarkAsDoneIcon />
          <Trans i18nKey={ STRING_KEYS.OUTREACH_PAGE.EDITOR.SEND_VIA_LINKEDIN_MENU.ITEMS.MARK_MESSAGE_AS_SENT } />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};

export default SendViaLinkedinMenu;
