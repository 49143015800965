/* eslint-disable react/jsx-props-no-spreading */
import {
  ChildrenContainer,
  Container,
  IconWrapper,
  Title,
  WidgetHeader,
  WidgetHeaderLeftContainer,
} from './base-widget.styled';

const BaseWidget = ({
  title, Icon, titleColor, children, EndAdornment, ...otherProps
}: BaseWidgetProps): JSX.Element => {
  const renderHeader = () => {
    if (!title && !Icon) {
      return null;
    }

    return (
      <WidgetHeader>
        <WidgetHeaderLeftContainer titleColor={ titleColor }>
          { Icon && <IconWrapper><Icon /></IconWrapper>  }
          { title && <Title>{title}</Title>}
        </WidgetHeaderLeftContainer>
        { EndAdornment }
      </WidgetHeader>
    );
  };

  return (
    <Container { ...otherProps }>
      { renderHeader() }
      <ChildrenContainer>
        { children }
      </ChildrenContainer>
    </Container>
  );
};

export default BaseWidget;
