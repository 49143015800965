import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { HeadlineFont } from '../../../../../../themes/fonts';

export const Container = styled.div<{isSelected: boolean}>`
  ${HeadlineFont};
  height: 64px;
  padding-left: ${THEME.SPACING.TINO};
  padding-right: ${THEME.SPACING.TINO};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  color: ${({ isSelected }) => isSelected ? THEME.COLORS.ACCENT : THEME.COLORS.CONTENT_PRIMARY};
  display: flex;
  align-items: center;
  transition: all ease-in .2s;
  cursor: pointer;
  :hover {
    background-color: ${THEME.COLORS_RGB.ACCENT_DIVIDER};
  };
  ${({ isSelected }) => {
    if (isSelected) {
      return {
        pointerEvents: 'none',
      };
    }

    return {};
  }}
`;
