import styled from 'styled-components';
import { OverlineFont } from '../../../../../../themes/fonts';
import { THEME } from '../../../../../../themes';
import { DividerSection } from '../../../../Divider/styled';

export const ListLabel = styled.div`
  ${OverlineFont};
  padding-left: ${THEME.SPACING.BASE};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;

export const Divider = styled(DividerSection)`
  width: 100%;
  align-self: center;
  margin-bottom: ${THEME.SPACING.TINY};
`;
