export const MATCH_ID_PATH_PARAM = ':matchId';
export const POSITION_ID_PATH_PARAM = ':positionId';
export const ENGAGEMENT_TYPE_PATH_PARAM = ':engagementType';

export const ROUTES = {
  PENDING_REVIEW_MATCHES: `/positions/${POSITION_ID_PATH_PARAM}/matches/pending-review`,
  MATCH_BY_ID: `/positions/${POSITION_ID_PATH_PARAM}/matches/${MATCH_ID_PATH_PARAM}`,
  EXPORT_ENGAGED_MATCHES_CSV: `/positions/${POSITION_ID_PATH_PARAM}/matches/engaged/export-csv`,
  EXPORT_MATCH_CSV: `/positions/${POSITION_ID_PATH_PARAM}/matches/${MATCH_ID_PATH_PARAM}/export-csv`,
  PERFORM_MATCH_INTERACTION: `/positions/${POSITION_ID_PATH_PARAM}/matches/${MATCH_ID_PATH_PARAM}/engage/${ENGAGEMENT_TYPE_PATH_PARAM}`,
  VIEW_MATCH: `/positions/${POSITION_ID_PATH_PARAM}/matches/${MATCH_ID_PATH_PARAM}/view`,
};

export const REDUCER_PATH = 'matchApi';
