import { useSelector } from 'react-redux';
import { RootReducerState } from '../../../index';
import { CreatePositionStoreMetadataSelector } from '../../../../modules/create-position/@types/create-position-selectors';

export const useCreatePositionStoreMetadataSelector = (): CreatePositionStoreMetadataSelector => {
  return useSelector(({ createPosition }: RootReducerState) => {
    return {
      createdId: createPosition.createdId,
      showButtonLoader: createPosition.showButtonLoaderShow,
      showSkipButton: createPosition.showSkipButtonShow,
      isContinueButtonEnabled: createPosition.isContinueButtonEnabled,
      showContinueButton: createPosition.showContinueButtonShow,
      isLoading: createPosition.loading,
      error: createPosition.error,
      errorCode: createPosition.errorCode,
    };
  });
};
