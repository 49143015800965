import styled from 'styled-components';
import { ASSETS } from '../../../../../../assets';
import { THEME } from '../../../../../../themes';
import { popoutElevation } from '../../../../../../themes/elevations';
import { Body1Font, Title3Font } from '../../../../../../themes/fonts';

export const LearnWhyContainer = styled.div<{modalPosition: 'top' | 'bottom'}>`
  ${popoutElevation};
  width: 480px;
  border-radius: ${THEME.RADIUS.LOOPY};
  border: solid 1px ${THEME.COLORS_RGB.PRIMARY_DIVIDER};
  background-color: ${THEME.COLORS.SURFACE_PRIMARY};
  padding: ${THEME.SPACING.LOOSE};
  position: absolute;
  right: -26px;
  ${({ modalPosition }) => {
    if (modalPosition === 'top') {
      return 'top: -240px';
    }

    return 'bottom: -210px';
  }};
  height: 200px;
  z-index: 6;
  
  animation: show 0.3s;
  animation-fill-mode: forwards;

  @keyframes show{
    0% { opacity: 0 }
    100% { opacity: 1 }
  }
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.div`
  width: 32px;
  height: 32px;
  font-size: 0;
  border-radius: ${THEME.RADIUS.CIRCLE};
  background: ${THEME.COLORS.LIGHT_GREY} url(${ASSETS.CLOSE_DIALOG}) no-repeat center center;
  right: 0;
  top: 0;
  position: absolute;
  cursor: pointer;
  transition: all 0.2s;
  z-index:6;
  margin-top: ${THEME.SPACING.BASE};
  margin-right: ${THEME.SPACING.BASE};
  :hover {
    opacity: ${THEME.OPACITY.MED};
  }
`;

export const Title = styled.div`
  display: block;
  width: 100%;
  ${Title3Font};
  color: ${THEME.COLORS.CONTENT_A};
  font-weight: bold;
  line-height: 1.5;
`;

export const Description = styled.div`
  ${Body1Font};
  margin-top: ${THEME.SPACING.TINY};
  line-height: 1.5;
  color: ${THEME.COLORS.CONTENT_A};
  opacity: 0.6;
`;

export const Backdrop = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 5;
  top: 0;
  left: 0;
`;
