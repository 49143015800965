import { BannerType, Container, ErrorCaption } from './styled';

type BannerProps = {
  message: string,
  caption?: string,
  type: BannerType,
}

export const Banner = ({
  caption,
  message,
  type,
}: BannerProps): JSX.Element => {
  return (
    <Container type={ type }>
      {!!caption && <ErrorCaption>{caption}</ErrorCaption>}
      {message}
    </Container>
  );
};

Banner.defaultProps = {
  caption: '',
};
