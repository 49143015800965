import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PRIVACY_CLASS } from '../../../../../../../consts/fullstory';
import STRING_KEYS from '../../../../../../../language/keys';
import { getInitials } from '../../../../../../../utils';
import { InitialsAvatar } from '../../../../../../Common/InitialsAvatar';

import {
  Container,
  Description,
  Name,
  Wrapper,
} from './worked-with-item.styled';

type WorkedWithItemProps = {
  workedWith: FormattedWorkedWith
}

export const WorkedWithItem = ({
  workedWith,
}:WorkedWithItemProps):JSX.Element => {
  const { t: translate } = useTranslation();

  const getYearString = (startYear: number, endYear: number) => {
    if (endYear && startYear !== endYear) {
      return `${startYear} - ${endYear} `;
    }

    return `${startYear} `;
  };

  return (
    <Container key={ workedWith.iconUrl }>
      <Avatar
        className={ PRIVACY_CLASS.EXCLUDE }
        src={ workedWith.iconUrl }
        style={ {
          width: '32px',
          height: '32px',
        } }
      >
        <InitialsAvatar
          alt={ workedWith?.fullName || '' }
          message={ getInitials(workedWith?.fullName) }
          fontSize="14px"
        />
      </Avatar>
      <Wrapper>
        <Name className={ PRIVACY_CLASS.EXCLUDE }>{workedWith.fullName}</Name>
        <Description>
          {getYearString(workedWith.startYear, workedWith.endYear)}
          {translate(STRING_KEYS.AT_COMPANY, {
            companyName: workedWith.companyName,
          })}
        </Description>
      </Wrapper>
    </Container>
  );
};
