import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { Body2Font } from '../../../../../themes/fonts';
import { ASSETS } from '../../../../../assets';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import IconButton from '../../../../../components/Common/Buttons/icon-button/icon-button.component';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
  max-width: 960px;
  padding: 0 ${THEME.SPACING.TINY};
  margin-bottom: ${THEME.SPACING.TINY};
  width: 100%;
`;

export const EmailContainer = styled.div<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => isVisible ? THEME.OPACITY.HIGHEST : THEME.OPACITY.TRANSPARENT};
  height: ${({ isVisible }) => isVisible ? 'fit-content' : 0};
  min-height: ${({ isVisible }) => isVisible ? '50px' : 0};
  transition: all 0.2s ease-in-out;
`;

export const FutureLinkedinTaskActionButtonPlaceholder = styled.div`
  ${Body2Font};
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
`;

export const FutureLinkedinTaskActionButtonPlaceholderIcon = styled(ASSETS.SCHEDULE_MEETING)`
  width: 22px;
`;

export const HeaderWrapper = styled.div`
  border-top-right-radius: ${THEME.RADIUS.ROUND};
  border-top-left-radius: ${THEME.RADIUS.ROUND};
  overflow: hidden;
`;

export const MarkAsDoneButtonIcon = styled(ASSETS.CHAT_V_SIGN)`
  height: 16px;
`;

export const MarkAsDoneButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.PRIMARY};
  width: 170px;
`;

export const SaveChangesButton = styled(BaseButton)`
  background-color: transparent;
  color: ${THEME.COLORS.ACCENT};
`;

export const SaveChangesButtonIcon = styled(ASSETS.V_SIGN)`
  width: 20px;
  color: ${THEME.COLORS.PURE_WHITE};
  background: ${THEME.COLORS.ACCENT};
  padding: ${THEME.SPACING.MICRO};
  border-radius: ${THEME.RADIUS.CIRCLE};
`;

export const SendIcon = styled(ASSETS.ARROW_BACK_COMPONENT)`
  height: 14px;
  width: 14px;
`;

export const SendMessageButton = styled(IconButton)`
  height: 24px;
  width: 24px;
  background-color: ${THEME.COLORS.ACCENT};
  border-radius: ${THEME.RADIUS.CIRCLE};
  padding: ${THEME.SPACING.MICRO};
`;

export const ManualModeSendButtonContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.BASE};
`;

export const FutureLinkedinTaskActionButtonContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.MEDIUM};
`;

export const SendMessageNowButton = styled(BaseButton)<{ color?: string, backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
`;

export const RegenerateMessageButton = styled(BaseButton)`
  background-color: transparent;
  color: ${THEME.COLORS.SECONDARY};
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.1px;
  padding: 0;
`;

export const RegenerateMessageButtonIcon = styled(ASSETS.STARS_FILLED)`
  width: 14px;
`;
