import styled from 'styled-components';
import { Body1Font, HeadlineFont } from '../../../themes/fonts';
import { THEME } from '../../../themes';
import Dialog from '../base-dialog/base-dialog.component';
import BaseButton from '../../Common/Buttons/perfect-base-button/perfect-base-button.component';

export const Container = styled(Dialog.Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
  padding: ${THEME.SPACING.LOOSE}  ${THEME.SPACING.MEDIUM};
`;

export const ActionsContainer = styled(Dialog.Footer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: unset;
  gap: ${THEME.SPACING.TINO};
  padding-bottom: ${THEME.SPACING.MEDIUM};
`;

export const Title = styled.div`
  ${HeadlineFont};
`;

export const Description = styled.div`
  ${Body1Font};
  line-height: 1.5;
  opacity: ${THEME.OPACITY.MED};
  text-align: center;
`;

export const ConfirmButton = styled(BaseButton)`
  height: 48px;
  background-color: ${THEME.COLORS.ACCENT};
`;

export const CancelButton = styled(BaseButton)`
  color: ${THEME.COLORS.ACCENT};
`;
