import i18n from 'i18next';
import { getYearsAndMonthsDiffString } from '../../../../../utils/dates';
import STRING_KEYS from '../../../../../language/keys';
import { DateObject } from '../../../../../@types/match';

export enum YearsRangeType {
  Range = 'RANGE',
  Sum = 'SUM',
  RangeAndSum = 'RANGE_AND_SUM'
}

export const createTimelineYearsRange = (startDate: DateObject, endDate: DateObject | undefined, type: YearsRangeType): string | null => {
  const startDateString = `${startDate ? `${startDate.year}` : ''}`;

  if (!startDateString) {
    return null;
  }

  const yearsSum = getYearsAndMonthsDiffString(startDate, endDate);

  const endDateString = `${endDate ?
    `${endDate.year}` :
    `${i18n.t(STRING_KEYS.PRESENT)}`}`;

  return (
    `${(yearsSum && type !== YearsRangeType.Range) ? `${yearsSum}` : ''}
    ${type === YearsRangeType.RangeAndSum ? ' • ' : ''}
    ${(yearsSum && type !== YearsRangeType.Sum) ? `${startDateString} - ${endDateString}` : ''}`
  );
};

export const createExperienceTimelineRange =
  (startDate: DateObject, endDate: DateObject | undefined): { yearsSum: string, startDateString: string, endDateString: string } | null => {
    if (!startDate) {
      return null;
    }

    const startDateString = `${startDate.year}`;

    const yearsSum = getYearsAndMonthsDiffString(startDate, endDate);

    const endDateString = `${endDate ?
      `${endDate.year}` :
      `${i18n.t(STRING_KEYS.PRESENT)}`}`;

    return {
      yearsSum,
      startDateString,
      endDateString,
    };
  };
