import { PostQuery } from '../../@types/rtk-query';
import { QueryMethods } from '../../enums/query-methods.enums';
import { ROUTES } from './tracking.consts';
import { TrackPageVisitsRequest } from './dto/requests/track-page-visits.request';
import { TrackPageVisitQueryArguments } from './dto/query-arguments/track-page-visit-query.arguments';

export const trackPageVisitsQuery = (queryArguments: TrackPageVisitQueryArguments[]): PostQuery<TrackPageVisitsRequest> => ({
  url: ROUTES.TRACK_PAGE_VISITS,
  method: QueryMethods.Post,
  data: {
    visits: queryArguments,
  },
});
