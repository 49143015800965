import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { CreatePositionState } from '../create-position-state.initial-state';
import { LevelOfExperience } from '../../../../enums/level-of-experience.enum';

export const setLevelsOfExperienceReducer: CaseReducer<CreatePositionState, PayloadAction<LevelOfExperience[]>> = (state, { payload }) => {
  state.selectedLevelsOfExperience = payload;
};

export const resetExperienceLevelsReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  state.selectedLevelsOfExperience = [];
};
