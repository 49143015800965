import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../../language/keys';
import { ASSETS } from '../../../../../../assets';
import {
  Title,
  Description,
  TextWrapper,
  InvitePendingWrapper,
  UnreadEmailIcon,
} from './styled';

type ItemContentProps = {
  email: string,
  fullName: string,
  invitePending: boolean,
}

const ItemContent = ({
  email,
  fullName,
  invitePending,
}: ItemContentProps): JSX.Element => {
  const { t: translate } = useTranslation();

  if (invitePending) {
    return (
      <TextWrapper>
        <Title title={ email }>
          { email }
        </Title>

        <InvitePendingWrapper>
          <UnreadEmailIcon>
            <ASSETS.UNREAD_EMAIL />
          </UnreadEmailIcon>

          <Description>
            { translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.INVITE_PENDING) }
          </Description>
        </InvitePendingWrapper>
      </TextWrapper>
    );
  }

  return (
    <TextWrapper>
      <Title>
        { fullName }
      </Title>
      <Description>
        { email }
      </Description>
    </TextWrapper>
  );
};

export default ItemContent;
