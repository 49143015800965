import styled from 'styled-components';
import { FLUENT_UI_EMOJIS } from '../../../../../../assets';
import { IconCss } from '../base-tag/base-funnel-tag.styled';

export const BuildingIcon = styled(FLUENT_UI_EMOJIS.OFFICE_BUILDING)`
  ${IconCss};
`;

export const SparklesIcon = styled(FLUENT_UI_EMOJIS.SPARKLES)`
  ${IconCss};
`;
