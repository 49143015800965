/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import {
  Comma, Dot, ItemWrapper, TypingWrapper, Wrapper,
} from './sentence.styled';

type SentenceProps = {
  text: string,
  Item?: JSX.Element,
  startDelay?: number,
  shouldAddComma?: boolean,
  shouldAddDot?: boolean,
  completeHandler?: () => void;
};

export const Sentence = ({
  text,
  Item,
  startDelay = 500,
  shouldAddComma,
  shouldAddDot,
  completeHandler,
  ...otherProps
}: SentenceProps): JSX.Element => {
  const [isShowItem, setShowItem] = useState(!text);

  const defineSentenceSuffix = () => {
    if (shouldAddComma) {
      return <Comma />;
    }
    if (shouldAddDot) {
      return <Dot />;
    }

    return null;
  };

  return (
    <Wrapper { ...otherProps }>
      {
        !!text && (
          <TypingWrapper
            strings={ [text] }
            typeSpeed={ 25 }
            backSpeed={ 25 }
            startDelay={ startDelay }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onComplete={ (self: any) => {
              self.cursor.hidden = true;
              setShowItem(true);
              completeHandler?.();
            } }
          />
        )
      }
      { Item && isShowItem && (
        <ItemWrapper>
          {Item}
          {defineSentenceSuffix()}
        </ItemWrapper>
      ) }
    </Wrapper>
  );
};

Sentence.defaultProps = {
  startDelay: 500,
  shouldAddComma: false,
  shouldAddDot: false,
  completeHandler: undefined,
  Item: undefined,
};
