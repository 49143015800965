import { ConversationBaseResponseDto } from '../dto/response/conversation-base.response';
import { ConversationResponseDto } from '../dto/response/conversation.response';
import { ConversationsMinimizedResponseDto } from '../dto/response/conversation-minimized.response';

export const talentRepliedGetConversationByIdWebsocketListener = (
  socketPayload: ConversationResponseDto,
  cacheConversationId: string,
  updateCachedData: (args: (conversation: ConversationBaseResponseDto) => void) => void,
): void => {
  updateCachedData((conversation: ConversationBaseResponseDto) => {
    if (cacheConversationId === socketPayload.id) {
      Object.assign(conversation, socketPayload);
    }
  });
};

export const talentRepliedGetConversationsWebsocketListener = (
  socketPayload: ConversationResponseDto,
  updateCachedData: (args: (cacheData: ConversationsMinimizedResponseDto) => void) => void,
): void => {
  updateCachedData((cacheData: ConversationsMinimizedResponseDto) => {
    const foundConversation = cacheData?.conversations?.find((conversation) => conversation.id === socketPayload.id);

    if (foundConversation) {
      Object.assign(foundConversation, socketPayload);
      foundConversation.latestMessage = socketPayload.messageHistory[socketPayload.messageHistory.length - 1];
    }
  });
};
