import styled from 'styled-components';
import { ASSETS } from '../../../../assets';
import { THEME } from '../../../../themes';

const BaseIcon = `
  margin-right: ${THEME.SPACING.BASE};
`;

export const Trilingual = styled(ASSETS.TRILINGUAL)`
  ${BaseIcon}
`;

export const Bilingual = styled(ASSETS.BILLINGUAL)`
  ${BaseIcon}
`;

export const Multilingual = styled(ASSETS.MULTILINGUAL)`
  ${BaseIcon}
`;
