import {
  CompletedStep, Container, EmptyStep,
} from './carousel-stepper.styled';

const CarouselStepper = ({
  totalSteps,
  activeStep,
}: CarouselStepsProps): JSX.Element => {
  const renderStep = (stepIndex: number) => {
    if (stepIndex <= activeStep) {
      return (
        <CompletedStep
          key={ stepIndex }
        />
      );
    }

    return <EmptyStep key={ stepIndex } />;
  };

  return (
    <Container>
      { Array.from(Array(totalSteps)).map((_, index) => renderStep(index)) }
    </Container>
  );
};

export default CarouselStepper;
