import { useTranslation } from 'react-i18next';
import HeaderBanner from '../header-banner/header-banner.component';
import { THEME } from '../../../../../themes';
import STRING_KEYS from '../../../../../language/keys';
import { TrialEndedIcon } from './trial-ended-header-banner.styled';

const TrialEndedHeaderBanner = ({ actionButtonClickHandler }: TrialEndedHeaderBannerProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <HeaderBanner
      label={ translate(STRING_KEYS.HOMEPAGE.HEADER.TRIAL_BANNER.LABEL) }
      title={ translate(STRING_KEYS.HOMEPAGE.HEADER.TRIAL_BANNER.ENDED.TITLE) }
      description={ translate(STRING_KEYS.HOMEPAGE.HEADER.TRIAL_BANNER.ENDED.DESCRIPTION) }
      gradientBackgroundColor={ THEME.COLORS.ACCENT }
      Icon={ <TrialEndedIcon /> }
      actionButtonClickHandler={ actionButtonClickHandler }
      actionButtonText={ translate(STRING_KEYS.HOMEPAGE.HEADER.TRIAL_BANNER.ENDED.ACTION_BUTTON_TEXT) }
    />
  );
};

export default TrialEndedHeaderBanner;
