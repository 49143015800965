import DotsLoader from '../../../../../../components/Common/Loaders/dots-loader/dots-loader.component';
import { DotsLoaderVariation } from '../../../../../../components/Common/Loaders/dots-loader/dots-loader.enums';
import { SidePanelLoaderContainer } from './match-side-panel-loader.styled';

const MatchSidePanelLoader = ():JSX.Element => {
  return (
    <SidePanelLoaderContainer>
      <DotsLoader variation={ DotsLoaderVariation.Section } />
    </SidePanelLoaderContainer>
  );
};

export default MatchSidePanelLoader;
