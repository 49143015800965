import styled from 'styled-components';
import { CREATE_POSITION_CONTAINER_HEIGHT } from '../create-position-container/create-position-container.styled';
import { HEADER_HEIGHT } from '../../../../components/Common/headers/header/header.styled';

export const SlideBody = styled.div`
  position: absolute;
  top: ${HEADER_HEIGHT}px;
  height: ${CREATE_POSITION_CONTAINER_HEIGHT};
  display: flex;
  left: 0;
  width: 100vw;
`;
