import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { Body2Font } from '../../../../themes/fonts';
import { hexToRgbA } from '../../../../utils/colors';
import { ASSETS } from '../../../../assets';

export const Container = styled.div`
  ${Body2Font};
  background-color: ${THEME.COLORS.LIGHT_GREY};
  border: 1px solid ${THEME.COLORS_RGB.PRIMARY_DARK_GHOST};
  border-radius: ${THEME.RADIUS.CURVY};
  display: flex;
  align-items: center;
  padding: ${THEME.SPACING.MICRO};
  padding-right: ${THEME.SPACING.BASE};
  color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.MED)};
  width: fit-content;
  transition: 100ms background-color;
  cursor: pointer;
  
  &:hover {
    background-color: ${hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER)};;
  }
`;

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: ${THEME.RADIUS.ROUND};
  border: 2px solid ${THEME.COLORS.LIGHT_GREY};
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const AvatarsGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${THEME.SPACING.TINY};
  
  & img:not(:first-child) {
    margin-left: -5px;
  }
`;

export const LockIcon = styled(ASSETS.LOCK_COMPONENT)`
  font-weight: bold;
  fill: ${THEME.COLORS.SECONDARY};
  width: 7px;
  margin-left: ${THEME.SPACING.TINY};
`;

export const NetworkedTalentName = styled.span<{ isLocked: boolean }>`
  ${({ isLocked }) => isLocked ? 'filter: blur(3px)' : ''};
  font-weight: bold;
`;
