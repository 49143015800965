import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import {
  BackgroundBanner,
  DescriptionContainer,
  InfoLabel,
  NotificationDialogContainer,
  StyledNotificationDialog,
  TitleContainer,
} from './requirement-tip-notification.styled';

const RequirementTipNotification = ({
  isOpen,
  onClose,
}: MatchesWereAlmostGoneDialogProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <StyledNotificationDialog
      isOpen={ isOpen }
      onClose={ onClose }
      closeTitle={ translate(STRING_KEYS.MATCH_TUNE.REQUIREMENT_CONTAINER.NOTIFICATION_TIP.BUTTON_TEXT) }
      showDivider={ false }
    >
      <NotificationDialogContainer>
        <InfoLabel>{ translate(STRING_KEYS.MATCH_TUNE.REQUIREMENT_CONTAINER.NOTIFICATION_TIP.INFO_LABEL) }</InfoLabel>
        <TitleContainer>{ translate(STRING_KEYS.MATCH_TUNE.REQUIREMENT_CONTAINER.NOTIFICATION_TIP.TITLE) }</TitleContainer>
        <DescriptionContainer>{ translate(STRING_KEYS.MATCH_TUNE.REQUIREMENT_CONTAINER.NOTIFICATION_TIP.DESCRIPTION) }</DescriptionContainer>
        <BackgroundBanner />
      </NotificationDialogContainer>
    </StyledNotificationDialog>
  );
};

export default RequirementTipNotification;
