import { positionQuery } from '../position.toolkit-api';
import { useAppSelector } from '../../../../selectors';
import { PositionResponse } from '../dto/response/position.response';
import { POSITION_STATUS } from '../../../../../consts';
import { outreachQuery } from '../../outreach/outreach.toolkit-api';

export const positionsSelector = positionQuery.endpoints.getOpenPositions.select;
export const positionSelector = positionQuery.endpoints.getPositionById.select;
export const conversationsSelector = outreachQuery.endpoints.getConversations.select;

export const useGetPositionsSelector = (): AppSelectorType<PositionResponse[]> => {
  const {
    data: positions, isLoading, isError, isSuccess, error,
  } = useAppSelector(positionsSelector());

  return {
    data: positions || [] as PositionResponse[],
    error,
    isLoading,
    isError,
    isSuccess,
  };
};

export const useGetOpenPositionsSelector = (): AppSelectorType<PositionResponse[]> => {
  const {
    data: positions, isLoading, isError, isSuccess, error,
  } = useAppSelector(positionsSelector());

  return {
    data: positions?.filter((position) => position.state === POSITION_STATUS.OPEN) || [] as PositionResponse[],
    error,
    isLoading,
    isError,
    isSuccess,
  };
};

export const useGetPositionByIdFromPositionsQuerySelector = (positionId: string): AppSelectorType<PositionResponse> => {
  const {
    data: positions, isLoading, isError, isSuccess, error,
  } = useAppSelector(positionsSelector());

  return {
    data: positions?.find((position) => position.id === positionId) || {} as PositionResponse,
    error,
    isLoading,
    isError,
    isSuccess,
  };
};
