import {
  useEffect, useState,
} from 'react';
import { Fade } from '@mui/material';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
  PerfectButtonVariants,
} from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import CarouselStepper from '../../../../components/Common/carousel-stepper/carousel-stepper.component';
import { PositionAssistantTaskEnum } from '../../../../enums/position-assistant-task.enum';
import { PositionAssistantTypeEnum } from '../../../../enums/position-assistant-type.enum';
import { PositionAssistantMilestoneEnum } from '../../../../enums/position-assistant-milestone.enum';
import STRING_KEYS from '../../../../language/keys';
import { PositionAssistantsProps } from './position-assistants';
import MilestoneCelebrations from './milestone-celebrations/milestone-celebrations.component';
import {
  Container, DismissButton, DismissIcon, Footer, NextButton, NextIcon,
} from './position-assistants.styled';
import EmptyAssistant from './empty-assistant/empty-assistant.component';
import { useTaskAssistantState } from './position-assistant.hook';

const PositionAssistants = ({
  tasks, milestones, connectToATSHandler, positionStatistics, skipPositionAssistantHandler,
}: PositionAssistantsProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { positionId } = useParams<MatchUrlParams>();
  const [skippedAssistant, setSkippedAssistant] = useState(false);
  const [activeAssistant, setActiveAssistant] = useState(-1);
  const [transitionRun, setTransitionRun] = useState(true);
  const { TasksAssistantsComponents, setTasksAssistantsComponents } = useTaskAssistantState(tasks, connectToATSHandler, positionStatistics);

  const [milestonesAssistants, setMilestonesAssistants] = useState<JSX.Element[]>(() => {
    return milestones.map(({ name }) => (
      <MilestoneCelebrations variant={ name } key={ name } data-type={ PositionAssistantTypeEnum.Milestone } />
    ));
  });

  const assistantLength = TasksAssistantsComponents.length + milestonesAssistants.length;
  const showEmptyState = !assistantLength;

  const shuffleAssistants = [...TasksAssistantsComponents, ...milestonesAssistants];

  const type = shuffleAssistants[activeAssistant]?.props['data-type'];

  useEffect(() => {
    if (transitionRun) {
      setActiveAssistant((prevState) => {
        if (prevState >= assistantLength - 1) {
          return 0;
        }

        return prevState + 1;
      });
    }
  }, [assistantLength, transitionRun]);

  const goToNextAssistant = () => {
    setTransitionRun(true);
    if (skippedAssistant) {
      if (type === PositionAssistantTypeEnum.Task) {
        setTasksAssistantsComponents((prevStateTasksAssistants: JSX.Element[]) => {
          return prevStateTasksAssistants.filter(({ key }) => shuffleAssistants[activeAssistant].key !== key);
        });
      } else {
        setMilestonesAssistants((prevStateMilestoneAssistants) => {
          return prevStateMilestoneAssistants.filter(({ key }) => shuffleAssistants[activeAssistant].key !== key);
        });
      }
      setSkippedAssistant(false);
    }
  };

  const skipPosition = () => {
    setSkippedAssistant(true);
    skipPositionAssistantHandler({
      positionId,
      type,
      name: shuffleAssistants[activeAssistant].key as PositionAssistantTaskEnum | PositionAssistantMilestoneEnum,
    });
  };

  const onNextClick = () => {
    if (type === PositionAssistantTypeEnum.Milestone) {
      skipPosition();
    }
    setTransitionRun(false);
  };

  const onDismissClick = () => {
    skipPosition();
    setTransitionRun(false);
  };

  const showNextButton = () => {
    if (type === PositionAssistantTypeEnum.Milestone) {
      return true;
    }

    return assistantLength > 1;
  };

  const renderAssistant  = () => {
    if (showEmptyState) {
      return (
        <Fade
          in={ transitionRun }
          onExited={ goToNextAssistant }
        >
          <div>
            <EmptyAssistant />
          </div>
        </Fade>
      );
    }
    if (!showEmptyState && shuffleAssistants[activeAssistant]) {
      return (
        <Fade
          in={ transitionRun }
          onExited={ goToNextAssistant }
        >
          <div>{ shuffleAssistants[activeAssistant] }</div>
        </Fade>
      );
    }

    return null;
  };

  return (
    <Container showEmptyState={ showEmptyState }>
      { renderAssistant() }
      {
        !showEmptyState && (
          <Footer>
            <DismissButton
              isVisible={ type === PositionAssistantTypeEnum.Task }
              size={ PerfectButtonSize.Small }
              variant={ PerfectButtonVariants.Ghost }
              hoverVariant={ PerfectButtonHoverVariants.Grow }
              StartIcon={ DismissIcon }
              onClick={ onDismissClick }
            >
              { translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.DISMISS_BUTTON) }
            </DismissButton>
            <CarouselStepper totalSteps={ assistantLength } activeStep={ activeAssistant } />
            <NextButton
              isVisible={ showNextButton() }
              size={ PerfectButtonSize.Small }
              variant={ PerfectButtonVariants.Ghost }
              hoverVariant={ PerfectButtonHoverVariants.Grow }
              StartIcon={ NextIcon }
              onClick={ onNextClick }
            >
              { translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.NEXT_BUTTON) }
            </NextButton>
          </Footer>
        )
      }
    </Container>
  );
};

export default PositionAssistants;
