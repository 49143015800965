import STRING_KEYS from '../../../../../../../language/keys';
import { SequenceMessageTypeEnum } from '../../../../../../../store/rtk-query/apis/sequence/enums/sequence-message-type.enum';
import { SelectionItem } from './select-type-popup';
import {
  EmailIcon, LinkedInIcon, PhoneIcon, SmsIcon,
} from './select-type-popup.styled';

export const SELECTION_AUTOMATIC_ITEMS = [{
  translationKey: STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.EMAIL,
  disabled: false,
  type: SequenceMessageTypeEnum.Email,
  Icon: EmailIcon,
}, {
  translationKey: STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.SMS,
  disabled: true,
  type: SequenceMessageTypeEnum.Sms,
  Icon: SmsIcon,
}] as SelectionItem[];

export const SELECTION_MANUAL_ITEMS = [{
  translationKey: STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.LINKEDIN_MANUAL,
  disabled: false,
  type: SequenceMessageTypeEnum.LinkedinManual,
  Icon: LinkedInIcon,
}, {
  translationKey: STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.PHONE,
  disabled: true,
  type: SequenceMessageTypeEnum.Phone,
  Icon: PhoneIcon,
}] as SelectionItem[];
