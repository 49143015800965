/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, Ref } from 'react';
import { BaseButtonProps } from './perfect-base-button';
import {
  ButtonContainer, StartIconContainer, Loader, TextContainer, EndIconContainer,
} from './perfect-base-button.styled';
import { PerfectButtonSize, PerfectButtonVariants } from './perfect-base-button.enums';

const BaseButton = forwardRef((
  props: BaseButtonProps,
  ref: Ref<HTMLButtonElement>,
) => {
  const {
    size, variant, StartIcon, disabled,
    children, hoverVariant, isLoading,  EndIcon,
    ...otherProps
  } = props;

  return (
    <ButtonContainer
      size={ size || PerfectButtonSize.Medium }
      variant={ variant || PerfectButtonVariants.Fill }
      disabled={ disabled || isLoading }
      hoverVariant={ hoverVariant }
      { ...otherProps }
      ref={ ref }
    >
      { isLoading && (
        <StartIconContainer>
          <Loader
            color="inherit"
            size="16px" // TODO should be changed by provided PerfectButtonSize
          />
        </StartIconContainer>
      )}
      { !!(!isLoading && StartIcon) && <StartIconContainer><StartIcon /></StartIconContainer> }
      <TextContainer>{ children }</TextContainer>
      { !!(EndIcon) && <EndIconContainer><EndIcon /></EndIconContainer> }
    </ButtonContainer>
  );
});

BaseButton.displayName = 'BaseButton';

export default BaseButton;
