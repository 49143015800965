import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import BasePositionAssistant from '../base-position-assistant/base-position-assistant.component';
import { ANIMATED_FLUENT_UI_EMOJIS } from '../../../../../assets';

const PROMPT_FOLLOW_UP_RATE = 12;

const WavingHandImg = () => (
  <img
    src={ ANIMATED_FLUENT_UI_EMOJIS.WAVING_HAND }
    alt="Rocket"
    width="112"
    height="112"
  />
);

const PromptFollowUp = ({
  actionHandler, followUpsConversations,
}: PromptFollowUpProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BasePositionAssistant
      title={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.PROMPT_FOLLOW_UP.TITLE, {
        count: followUpsConversations,
      }) }
      description={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.PROMPT_FOLLOW_UP.DESCRIPTION) }
      Icon={ WavingHandImg }
      rate={ PROMPT_FOLLOW_UP_RATE }
      action={ {
        title: translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.PROMPT_FOLLOW_UP.ACTION_TITLE),
        handler: actionHandler,
      } }
    />
  );
};

export default PromptFollowUp;
