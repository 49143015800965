import styled from 'styled-components';
import {
  EditPositionAutocomplete,
} from '../../../../../create-position/components/autocompletes/position-autocomplete/edit-position-autocomplete/edit-position-autocomplete.component';
import { Title2Font } from '../../../../../../themes/fonts';

export const Container = styled.div`
  
`;

export const PositionTitleAutocomplete = styled(EditPositionAutocomplete)`
  ${Title2Font};
`;
