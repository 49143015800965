import { PageHeader } from '../page-header/page-header.component';
import ProgressBar
  from './progress-bar/progress-bar.component';
import { PageHeaderContent } from './progress-bar-header.styled';
import { ProgressStep } from './progress-bar/progress-bar';

export const StepsPageHeader = ({
  steps,
  children,
  logoSize,
  borderBottomDisable,
  isSticky,
}: PageHeaderProps & { steps: ProgressStep[] }):JSX.Element => {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <PageHeader
      logoSize={ logoSize }
      borderBottomDisable={ borderBottomDisable }
      isSticky={ isSticky }
    >
      <PageHeaderContent>
        <ProgressBar
          steps={ steps }
        />
        {children}
      </PageHeaderContent>
    </PageHeader>
  );
};
