import {
  Section,
  SectionText,
  SectionTitle,
} from '../../explainability-info-menu.styled';
import { StyledDivider } from './base-section.styled';

const BaseSection = ({ title, Icon, children }: BaseSectionProps): JSX.Element => {
  return (
    <>
      <StyledDivider />
      <Section>
        <SectionTitle>
          { Icon } { title }
        </SectionTitle>
        <SectionText>
          { children }
        </SectionText>
      </Section>
    </>
  );
};

export default BaseSection;
