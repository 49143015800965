import { useSelector } from 'react-redux';
import { RootReducerState } from '../index';
import { SequenceModeEnum } from '../rtk-query/apis/sequence/enums/sequence-mode.enum';

type OutreachStateSelector = {
  shouldSortConversations: boolean,
  sequenceMode: SequenceModeEnum | undefined,
}

export const useOutreachState = (): OutreachStateSelector => {
  return useSelector(({ outreachReducer }: RootReducerState) => {
    return {
      shouldSortConversations: outreachReducer.shouldSortConversations,
      sequenceMode: outreachReducer.sequenceMode,
    };
  });
};
