import { useSelector } from 'react-redux';
import { RootReducerState } from '../../../index';
import { CreatePositionEducationSelector } from '../../../../modules/create-position/@types/create-position-selectors';

export const useCreatePositionEducationSelector = (): CreatePositionEducationSelector => {
  return useSelector(({ createPosition } : RootReducerState) => {
    return {
      degrees: createPosition.degrees,
      userInputFieldsOfStudy: createPosition.userInputFieldsOfStudy,
    };
  });
};
