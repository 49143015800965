import styled from 'styled-components';
import { THEME } from '../../../themes';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: ${THEME.SPACING.NANO};
  > * {
    flex-grow: 1;
  }
`;

export const ActiveIndicator = styled.div<{ width: number }>`
  width: ${({ width }) => `${width}px`};
  height: 4px;
  border-radius: ${THEME.RADIUS.NANO};
  background-color: ${THEME.COLORS.ACCENT};
`;
