import styled  from 'styled-components';
import { THEME } from '../../../../../../themes';

export const  Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${THEME.SPACING.MICRO};
  margin-bottom: ${THEME.SPACING.BASE};
`;
