import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { ASSETS } from '../../../../../assets';
import { hexToRgbA } from '../../../../../utils/colors';

export const SmileIcon = styled(ASSETS.SMILING_FACE_WITH_TEAR)`
  width: 14px;
  height: 16px;
  fill: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW)};
`;

export const HoursSaved = styled.div<{shouldShowData: boolean}>`
  color: ${hexToRgbA(THEME.COLORS.SUCCESS)};
  display: ${(props) => props.shouldShowData ? 'inline-flex' : 'none'};;
  align-items: center;
  justify-content: center;
`;

export const CalculatingTitle = styled.div`
  opacity: ${THEME.OPACITY.LOW};
`;

export const Underline = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${THEME.COLORS.ACCENT};
`;
