import log from 'loglevel';
import Cookies from 'js-cookie';
import { AppRouting } from './appRoutingResolver';

const logger = log.getLogger('UTM_TRACKER');
const DEFAULT_COOKIE_TTL = 10368000000;   // 120 days in ms
// The UTM parameters you want to track
const UTM_CAMPAIGN_ID = 'utm_id';
const UTM_CAMPAIGN_SOURCE = 'utm_source';
const UTM_CAMPAIGN_MEDIUM = 'utm_medium';
const UTM_CAMPAIGN = 'utm_campaign';
const UTM_CAMPAIGN_TERM = 'utm_term';
const UTM_CAMPAIGN_CONTENT = 'utm_content';
export const UTM_PARAMS = [
  UTM_CAMPAIGN_ID,
  UTM_CAMPAIGN_SOURCE,
  UTM_CAMPAIGN_MEDIUM,
  UTM_CAMPAIGN,
  UTM_CAMPAIGN_TERM,
  UTM_CAMPAIGN_CONTENT,
];

/**
 * http://www.brennon.me/tracking-utm-params/
 */
/* eslint-disable camelcase */
class UtmTrackingService {
  /**
   * Private helper function to handle
   * setting the new UTM parameter's value
   * @param {String} utmParam
   * @param {String} utmValue
   */
  private setNewUTMCookie(utmParam: string, utmValue: string) {
    if (utmParam && utmValue) {
      const now = new Date();
      // save as cookie
      const rootOrigin = `.${AppRouting.getRootOrigin()}`;
      Cookies.set(`perfect_${utmParam}`, utmValue, {
        domain: rootOrigin,
        expires: now.getTime() + DEFAULT_COOKIE_TTL,
        sameSite: 'Lax',
      });
    }
    
    return true;
  }

  /**
   * Entry function to handle how
   * to store the UTM parameter found in the URL
   * @param utmParam
   * @param utmValue
   */
  private storeUTMParam(utmParam: string, utmValue: string) {
    if (utmParam && utmValue) {
      // create a new one with expiration for 120 days
      this.setNewUTMCookie(utmParam, utmValue);
    }
  }

  /**
   * Search the array of parameters for the specified UTM parameter
   * @param {String} utmParam
   * @param {Array} urlParams
   */
  // eslint-disable-next-line consistent-return
  private static getUTMParamFromURL(utmParam: string,
    urlParams:Array<string> = []): string | undefined {
    if (utmParam) {
      for (let i = 0; i < urlParams.length; i++) {
        const param = urlParams[i];
        const keyValue = param.split('=');
        if (keyValue[0] === utmParam && keyValue[1]) {
          return decodeURIComponent(keyValue[1]);
        }
      }
    }
  }

  /**
   * Trigger to check the current URL for the UTM params
   */
  public checkForUTMParams() {
    const URLParams: Array<string> = window.location.search.substr(1).split('&');
    UTM_PARAMS.forEach((utmParam: string) => {
      const utmValue = UtmTrackingService.getUTMParamFromURL(utmParam, URLParams);
      logger.debug('Found utm param ', {
        utmParam,
        utmValue,
      });
      if (utmValue) {
        this.storeUTMParam(utmParam, utmValue);
      }
    });
  }

  /**
   * If the given parameter is valid, attempt to
   * retrieve the UTM value from cookie
   * @param {String} param
   */
  public getUTMParamFromCookie(param: string): string | undefined {
    if (param && UTM_PARAMS.includes(param)) {
      return Cookies.get(param);
    }

    return undefined;
  }

  public getAllUTMParamFromCookies(): {
    utm_id?: string;
    utm_campaign?: string;
    utm_content?: string;
    utm_medium?: string;
    utm_source?: string;
    utm_term?: string;
    } {
    const obj: {
      utm_id?: string;
      utm_campaign?: string;
      utm_content?: string;
      utm_medium?: string;
      utm_source?: string;
      utm_term?: string;
    } = {};
    UTM_PARAMS.forEach((utmParam: string) => {
      const value = Cookies.get(`perfect_${utmParam}`);
      if (value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        obj[utmParam] = value;
      }
    });

    return obj;
  }
}

export default new UtmTrackingService();
