import {
  Container,
  IconWrapper,
  Label,
  TextContainer,
  Value,
  Wrapper,
} from './explainability-widget-item.styled';
import { ExplainabilityWidgetItemProps } from './explainability-widget-item';

const ExplainabilityWidgetItem = ({
  label,
  value,
  borderColor,
  Icon,
  children,
}: ExplainabilityWidgetItemProps): JSX.Element => {
  return (
    <Container>
      <Wrapper borderColor={ borderColor }>
        { Icon && <IconWrapper><Icon /></IconWrapper> }
        <div>
          <TextContainer>
            <Label>{ label }</Label>
            { value && <Value>{ value }</Value> }
          </TextContainer>
          { children }
        </div>
      </Wrapper>
    </Container>
  );
};

export default ExplainabilityWidgetItem;
