import { Trans } from 'react-i18next';
import { useShouldLockFeature } from '../../../../../hooks/should-lock-feature.hook';
import STRING_KEYS from '../../../../../language/keys';
import { NetworkedTalentName } from '../mutual-sentences/mutual-sentences.styled';
import { MutualExperienceSentenceProps } from './mutual-experience-sentence';

export const MutualExperienceSentence = ({ companyName, networkedTalents }: MutualExperienceSentenceProps): JSX.Element | null => {
  const isLocked = useShouldLockFeature();

  if (!networkedTalents?.length) {
    return null;
  }

  const translateMultiple = () => {
    if (networkedTalents.length === 1) {
      return null;
    }

    return (
      <Trans i18nKey={ STRING_KEYS.TALENT_NETWORK.TIMELINE.MULTIPLE }>
        <strong />
        {{ talentsCount: networkedTalents.length - 1 }}
      </Trans>
    );
  };

  const translateSingle = () => {
    const networkedTalent = networkedTalents[0];
    let translationKey;

    // TODO: update translation keys
    if (networkedTalent.experience.sameSite) {
      translationKey = STRING_KEYS.TALENT_NETWORK.TIMELINE.EXPERIENCE.SAME_COMPANY_SITE;
    } else if (networkedTalent.experience.sameRole) {
      translationKey = STRING_KEYS.TALENT_NETWORK.TIMELINE.EXPERIENCE.SAME_ROLE;
    } else if (networkedTalent.experience.similarRole) {
      translationKey = STRING_KEYS.TALENT_NETWORK.TIMELINE.EXPERIENCE.SIMILAR_ROLE;
    } else {
      translationKey = STRING_KEYS.TALENT_NETWORK.TIMELINE.EXPERIENCE.WORKED_WITH;
    }

    const firstLastNameLetter = networkedTalent?.talent?.lastName?.[0];
    const nameString = networkedTalent?.talent?.firstName + (firstLastNameLetter ? ` ${firstLastNameLetter}.` : '');

    return (
      <Trans i18nKey={ translationKey }>
        <NetworkedTalentName isLocked={ isLocked } />
        {{ name: nameString }}
        {{ companyName }}
      </Trans>
    );
  };

  return (
    <>
      { translateSingle() }
      { translateMultiple() }
    </>
  );
};
