import styled from 'styled-components';
import { FLUENT_UI_EMOJIS } from '../../assets';

export const EntryLevelIconIcon = styled(FLUENT_UI_EMOJIS.JAPANESE_SYMBOL_FOR_BEGINNER)``;

export const StartingOutIcon = styled(FLUENT_UI_EMOJIS.FIRST_PLACE_MEDAL)``;

export const SomewhatExperiencedIcon = styled(FLUENT_UI_EMOJIS.SPORTS_MEDAL)``;

export const HighlyExperiencedIcon = styled(FLUENT_UI_EMOJIS.TROPHY)``;

export const IncrediblyExperiencedIcon = styled(FLUENT_UI_EMOJIS.CROWN)``;
