export const UNITED_STATES_COUNTRY_CODE = 'US';
export const UNITED_KINGDOM_COUNTRY_CODE = 'UK';
export const FRANCE_COUNTRY_CODE = 'FR';
export const GERMANY_COUNTRY_CODE = 'DE';
export const ISRAEL_COUNTRY_CODE = 'IL';
export const UNITED_STATES_COUNTRY_NAME = 'United States';
export const UNITED_KINGDOM_COUNTRY_NAME = 'United Kingdom';
export const GERMANY_COUNTRY_NAME = 'Germany';
export const FRANCE_COUNTRY_NAME = 'France';
export const ISRAEL_COUNTRY_NAME = 'Israel';
