import { Trans, useTranslation } from 'react-i18next';
import {
  Description,
  StyledDivider,
  Subtitle,
  TextContainer,
  Title,
} from '../ats-initialization.styled';
import STRING_KEYS from '../../../../language/keys';
import { ASSETS } from '../../../../assets';
import BaseSwitch from '../../../Common/base-switch/base-switch.component';
import { THEME } from '../../../../themes';
import { BaseSwitchSize } from '../../../Common/base-switch/base-switch.enums';
import { CustomAtsIntegrationType } from '../ats-initialization.enums';
import {
  ArrowIcon,
  AtsAshbyLogoImage,
  AtsComeetLogoImage,
  AtsGreenhouseLogoImage,
  AtsLogosImage,
  Button,
  ButtonsContainer,
  ButtonsRow,
  ButtonTextAndImageWrapper,
  CheckedIcon,
  Container,
  TwoWayAtsContainer,
  TwoWayAtsInfoText,
  UnCheckedIcon,
} from './ats-intialization-main-screen.styled';
import { MainScreenProps } from './ats-intialization-main-screen';

const AtsInitializationMainScreen = ({
  twoWaySyncApproved,
  isMergeReady,
  continueWithOtherButtonClickHandler,
  twoWaySyncApprovedChangeHandler,
  createCustomAtsIntegrationHandler,
}: MainScreenProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <TextContainer>
        <Title>{ translate(STRING_KEYS.ATS_INITIALIZATION_DIALOG.TITLE) }</Title>
        <Description>
          { translate(STRING_KEYS.ATS_INITIALIZATION_DIALOG.DESCRIPTION) }
        </Description>
        <StyledDivider />
        <Subtitle>{ translate(STRING_KEYS.ATS_INITIALIZATION_DIALOG.CHOOSE_ATS) }</Subtitle>
      </TextContainer>
      <ButtonsContainer>
        <ButtonsRow>
          <Button
            onClick={ () => createCustomAtsIntegrationHandler(CustomAtsIntegrationType.Greenhouse) }
            centerItems
          >
            <ButtonTextAndImageWrapper>
              <AtsGreenhouseLogoImage src={ ASSETS.ATS_GREENHOUSE_LOGO } />
            </ButtonTextAndImageWrapper>
          </Button>
          <Button
            onClick={ () => createCustomAtsIntegrationHandler(CustomAtsIntegrationType.Comeet) }
            centerItems
          >
            <ButtonTextAndImageWrapper>
              <AtsComeetLogoImage src={ ASSETS.ATS_COMEET_LOGO } />
            </ButtonTextAndImageWrapper>
          </Button>
          <Button
            onClick={ () => createCustomAtsIntegrationHandler(CustomAtsIntegrationType.Ashby) }
            centerItems
          >
            <ButtonTextAndImageWrapper>
              <AtsAshbyLogoImage src={ ASSETS.ATS_ASHBY_LOGO } />
            </ButtonTextAndImageWrapper>
          </Button>
        </ButtonsRow>
        <ButtonsRow>
          <Button disabled={ !isMergeReady } onClick={ continueWithOtherButtonClickHandler }>
            <ButtonTextAndImageWrapper>
              { translate(STRING_KEYS.ATS_INITIALIZATION_DIALOG.CONTINUE_WITH) }
              <AtsLogosImage src={ ASSETS.ATS_LOGOS } />
            </ButtonTextAndImageWrapper>
            <ArrowIcon />
          </Button>
        </ButtonsRow>
      </ButtonsContainer>
      <TwoWayAtsContainer>
        <BaseSwitch
          checked={ twoWaySyncApproved }
          checkedColor={ THEME.COLORS.ACCENT }
          switchChangeHandler={ twoWaySyncApprovedChangeHandler }
          size={ BaseSwitchSize.Small }
          CheckedIcon={ <CheckedIcon /> }
          UnCheckedIcon={ <UnCheckedIcon /> }
        />
        <TwoWayAtsInfoText>
          <Trans i18nKey={ STRING_KEYS.ATS_INITIALIZATION_DIALOG.TWO_WAY_ATS_INFO_TEXT } />
        </TwoWayAtsInfoText>
      </TwoWayAtsContainer>
    </Container>
  );
};

export default AtsInitializationMainScreen;
