import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../language/keys';
import { useAppState } from '../../../store/selectors';
import { SubDomainType } from '../../../store/slices/app-state/app-state.enums';
import { getSubDomain } from '../../../utils/url';
import { useGetAccountBySubDomainQuery } from '../../../store/rtk-query/apis/account/hooks/get-account-by-sub-domain.query-hook';
import {
  AccountLogoImage,
  Container,
  AccountName,
} from './account-logo.styled';

function AccountLogo():JSX.Element {
  const { t: translate } = useTranslation();
  const currentSubDomain = getSubDomain(window.location.hostname);
  const { subDomainType } = useAppState();
  const isGlobalLogin = subDomainType === SubDomainType.GlobalLogin;
  const { data: accountData } = useGetAccountBySubDomainQuery(currentSubDomain, isGlobalLogin);

  const defineAccountLogo = (): JSX.Element | null => {
    if (accountData.logo) {
      return (
        <AccountLogoImage
          src={ accountData.logo }
          alt={ translate(STRING_KEYS.LOGO_ALT, { name: accountData.displayName }) }
        />
      );
    }

    if (accountData.displayName) {
      return <AccountName>{accountData.displayName}</AccountName>;
    }

    return null;
  };

  return (
    <Container>
      {!isGlobalLogin && defineAccountLogo()}
    </Container>
  );
}

export default AccountLogo;
