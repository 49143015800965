import React from 'react';

type StepScreenWrapperProps = {
  children: React.ReactNode,
}

export const StepScreenWrapper = ({
  children,
}: StepScreenWrapperProps): JSX.Element => {
  return (
    <>
      {children}
    </>
  );
};
