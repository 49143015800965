import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../../language/keys';
import { EducationLogoImage, IconFallback } from './education-item-logo.styled';

type EducationLogoProps = {
  logoUrl: string,
  institutionName: string
}

export const EducationLogo = ({
  logoUrl,
  institutionName,
}: EducationLogoProps) : JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <EducationLogoImage
      src={ logoUrl }
      alt={ translate(
        STRING_KEYS.LOGO_ALT,
        { name: translate(institutionName) },
      ) }
    >
      <IconFallback />
    </EducationLogoImage>
  );
};
