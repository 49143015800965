import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import { getInitials } from '../../../../../utils';
import { InitialsAvatar } from '../../../../Common/InitialsAvatar';
import { useResendInviteUserMutation } from '../../../../../store/rtk-query/apis/user/hooks/resend-invite-user.mutation-hook';
import {
  Container,
  StyledAvatar,
  ResendInvite,
} from './styled';
import ItemContent from './ItemContent';

type InvitedColleaguesListProps = {
  fullName: string,
  email: string,
  profileImage: string,
  id: string,
  invitePending: boolean,
  onResetError: () => void,
}

const InvitedColleaguesListItem = ({
  fullName,
  email,
  profileImage,
  invitePending,
  id,
  onResetError,
}: InvitedColleaguesListProps): JSX.Element => {
  const [isContainerHovered, setIsContainerHovered] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { t: translate } = useTranslation();
  const [resendInviteColleagueAction] = useResendInviteUserMutation();
  const handleResendInviteClick = () => {
    onResetError();
    resendInviteColleagueAction({ userId: id, email });
  };

  return (
    <Container
      ref={ containerRef }
      onMouseOver={ () => setIsContainerHovered(true) }
      onMouseLeave={ () => setIsContainerHovered(false) }
    >
      <StyledAvatar
        alt={ fullName }
        src={ profileImage }
      >
        <InitialsAvatar
          alt={ fullName }
          message={ getInitials(fullName) }
          fontSize="14px"
        />
      </StyledAvatar>

      <ItemContent
        email={ email }
        fullName={ fullName }
        invitePending={ invitePending }
      />

      {isContainerHovered && invitePending && (
        <ResendInvite onClick={ handleResendInviteClick }>
          { translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.RESEND_INVITE) }
        </ResendInvite>
      )}
    </Container>
  );
};

export default InvitedColleaguesListItem;
