import styled from 'styled-components';
import { Subtitle1Font } from '../../../../../themes/fonts';
import { THEME } from '../../../../../themes';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { ASSETS } from '../../../../../assets';

export const EditPreferences = styled(BaseButton)`
  ${Subtitle1Font};
  color: ${THEME.COLORS.ACCENT};
  background-color: transparent;
  height: fit-content;
  text-transform: unset;
`;

export const PreferencesIcon = styled(ASSETS.MATCHTUNE)`
  width: 24px;
`;
