import { IndeterminateLinearLoader } from '../IndeterminateLinearLoader';
import {
  LoaderWrapper,
} from './login-loader.styled';

type OnboardingLoaderProps = {
  isLoading: boolean,
}

const LoginLogger = ({
  isLoading,
}: OnboardingLoaderProps): JSX.Element | null => {
  if (!isLoading) {
    return null;
  }

  return (
    <LoaderWrapper>
      <IndeterminateLinearLoader />
    </LoaderWrapper>
  );
};

export default LoginLogger;
