import { useEffect } from 'react';
import { generateDataForBIEventsByState } from '../../onboarding/utils/onboarding-bi-events.utls';
import { CREATE_POSITION_ANALYTICS_EVENTS } from '../consts/position-analytics.consts';
import { ELEMENT_TYPE, EVENT_GROUP } from '../../../consts/analytics';
import { useAnalyticsContext } from '../../../contexts/analytics/useAnalyticsContext';
import { CreatePositionStepsEnums } from '../enums/create-position-steps.enums';
import { ProgressStep } from '../../../components/Common/headers/progress-bar-header/progress-bar/progress-bar';
import { TrackEventPayload } from '../../../@types/analytics/eventPayload';
import { OnboardingStep } from '../../onboarding/onboarding.enums';
import { ONBOARDING_ANALYTICS_EVENTS } from '../../onboarding/consts/onboarding-analytics.const';
import { useGetMyAccountSelector } from '../../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import { useCreatePositionJobDescription } from '../../../store/slices/create-position-state/selectors/create-position-job-description.selector';

export const usePageShowEvent = (currentStep: CreatePositionStepsEnums, steps: ProgressStep[], enabled = true): void => {
  const { analyticsPageEvent } = useAnalyticsContext();
  const { data: { id: accountId } } = useGetMyAccountSelector();

  useEffect(() => {
    if (enabled && accountId && steps) {
      const dynamicData = generateDataForBIEventsByState(currentStep, steps);
      if (dynamicData) {
        analyticsPageEvent({
          eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_STEP_PAGE_VIEWED,
          eventGroup: EVENT_GROUP.PAGE_VIEW,
          stepNumber: dynamicData.stepNumber,
          pageName: dynamicData.pageName,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, enabled]);
};

export const useBackClickEvent = (currentStep: OnboardingStep | CreatePositionStepsEnums, steps: ProgressStep[]): () => void => {
  const { analyticsTrackEvent } = useAnalyticsContext();
  const staticsData = {
    eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_BACK_CLICKED,
    eventGroup: EVENT_GROUP.CLICK,
  };

  return () => {
    if (steps) {
      const dynamicData = generateDataForBIEventsByState(currentStep, steps);
      if (dynamicData) {
        analyticsTrackEvent({
          ...staticsData,
          stepNumber: dynamicData.stepNumber,
          pageName: dynamicData.pageName,
        });
      }
    }
  };
};

export const useContinueClickEvent = (currentStep: OnboardingStep | CreatePositionStepsEnums, steps: ProgressStep[]): () => void => {
  const { analyticsTrackEvent } = useAnalyticsContext();
  const { jobDescriptionTempUrls, jobDescriptionS3TempUrls } = useCreatePositionJobDescription();
  const staticsData = {
    eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_CLICKED_ON_NEXT_STEP,
    eventGroup: EVENT_GROUP.CLICK,
  };

  const generateJobDescriptionData = (dynamicData: TrackEventPayload | null): TrackEventPayload | null => {
    if (jobDescriptionTempUrls) {
      return {
        stepNumber: dynamicData?.stepNumber,
        pageName: dynamicData?.pageName,
        componentMetadata: jobDescriptionTempUrls,
        eventName: ONBOARDING_ANALYTICS_EVENTS.ONBOARDING_ADD_LINK_JOB_DESCRIPTION_CLICKED,
      };
    }

    if (jobDescriptionS3TempUrls) {
      return {
        stepNumber: dynamicData?.stepNumber,
        pageName: dynamicData?.pageName,
        eventName: ONBOARDING_ANALYTICS_EVENTS.ONBOARDING_ADD_FILE_JOB_DESCRIPTION_CLICKED,
        componentElementType: ELEMENT_TYPE.FILE,
        componentMetadata: jobDescriptionS3TempUrls,
      };
    }

    return dynamicData;
  };

  return () => {
    if (steps) {
      const dynamicData = generateDataForBIEventsByState(currentStep, steps);
      if (currentStep as string === OnboardingStep.JobDescription) {
        analyticsTrackEvent({
          ...staticsData,
          ...generateJobDescriptionData(dynamicData),
        });
      }
      if (dynamicData) {
        analyticsTrackEvent({
          ...staticsData,
          stepNumber: dynamicData.stepNumber,
          pageName: dynamicData.pageName,
        });
      }
    }
  };
};

export const useSkipClickEvent = (currentStep: OnboardingStep | CreatePositionStepsEnums, steps: ProgressStep[]): () => void => {
  const { analyticsTrackEvent } = useAnalyticsContext();
  const staticsData = {
    eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_CLICKED_ON_SKIP_STEP,
    eventGroup: EVENT_GROUP.CLICK,
  };

  return () => {
    if   (steps) {
      const dynamicData = generateDataForBIEventsByState(currentStep, steps);
      if (dynamicData) {
        analyticsTrackEvent({
          ...staticsData,
          stepNumber: dynamicData.stepNumber,
          pageName: dynamicData.pageName,
        });
      }
    }
  };
};
