import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { ASSETS } from '../../../../../../assets';
import { Body2Font } from '../../../../../../themes/fonts';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
  user-select: none;
`;

export const CheckedIcon = styled(ASSETS.CHECKED_GHOST)`
  width: 8px;
  height: 8px;
  fill: ${THEME.COLORS.ACCENT};
`;

export const SwitchLabel = styled.div`
  ${Body2Font};
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.25px;
`;

export const UnCheckedIcon = styled(ASSETS.CHECKED_GHOST)`
  width: 8px;
  height: 8px;
  fill: ${THEME.COLORS.GREY};
`;
