import React, { useState } from 'react';
import { useAuthActions } from '@frontegg/react';
import { useTranslation } from 'react-i18next';
import { ISocialLoginProviderConfigurationV2 } from '@frontegg/rest-api';
import log from 'loglevel';
import PrimaryButton from '../../Common/Buttons/PrimaryButton';
import STRING_KEYS from '../../../language/keys';
import { validateEmail } from '../../../utils';
import { getGoogleSocialLoginUrl } from '../../../utils/frontegg';
import { AppRouting } from '../../../services/appRoutingResolver';
import DescriptionTitleWithDividers
  from '../../Common/discription-title-with-dividers/description-title-with-dividers.componet';
import {
  FormContainer,
  StyledForm,
  LetsGoButtonWrapper,
  Container,
  ValidationError,
  ForgotPassword,
} from './login-form.styled';
import { SocialLoginOptions } from './social-login/social-login.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { EmailInput } from './email-input/email-input.component';

const logger = log.getLogger('LOGIN_FORM');

type LoginFormProps = {
  errorMessage: string,
};

function LoginForm({ errorMessage }: LoginFormProps):JSX.Element {
  const { t: translate } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  const actions = useAuthActions();

  const resetErrors = () => {
    setEmailError('');
    setPasswordError('');
  };

  const submitForm = () => {
    resetErrors();

    let hasErrors = false;

    if (!email) {
      setEmailError(
        translate(
          STRING_KEYS.PLEASE_ENTER_INPUT_VALUE,
          { inputName: translate(STRING_KEYS.EMAIL) },
        ),
      );
      hasErrors = true;
    } else if (!validateEmail(email)) {
      setEmailError(
        translate(
          STRING_KEYS.PLEASE_ENTER_VALID_VALUE,
          { valueName: translate(STRING_KEYS.EMAIL_ADDRESS) },
        ),
      );
      hasErrors = true;
    }

    if (!password) {
      setPasswordError(
        translate(STRING_KEYS.PLEASE_ENTER_INPUT_VALUE,
          { inputName: translate(STRING_KEYS.PASSWORD) },
        ),
      );
      hasErrors = true;
    }

    if (!hasErrors) {
      actions.login({ email, password });
    }
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submitForm();
  };

  // todo: check if values of email/password should be passed to their components

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    actions.resetLoginState();
    setEmail(event.currentTarget.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordError('');
    actions.resetLoginState();
    setPassword(event.currentTarget.value);
  };

  const handleGoogleLoginClick = (configuration: ISocialLoginProviderConfigurationV2) => {
    const redirectUrl = AppRouting.buildSocialLoginSuccessUrl(window.location);

    logger.debug('navigating to google login with params', {
      clientId: configuration.clientId,
      redirectUrl,
    });

    window.location.href = getGoogleSocialLoginUrl(
      configuration.clientId as string,
      redirectUrl,
    );
  };

  return (
    <FormContainer>
      <StyledForm onSubmit={ handleFormSubmit }>
        <Container>
          <SocialLoginOptions
            onGoogleLoginClick={ handleGoogleLoginClick }
          />
          <DescriptionTitleWithDividers
            text={ translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_DESCRIPTION) }
          />
        </Container>
        <EmailInput
          onChange={ handleEmailChange }
          isError={ !!emailError || !!errorMessage }
          error={ emailError }
        />
        <PasswordInputComponent
          onChange={ handlePasswordChange }
          isError={ !!passwordError || !!errorMessage }
          error={ passwordError }
        />
        {errorMessage && (
          <ValidationError>
            { errorMessage }
          </ValidationError>
        )}

        <ForgotPassword
          onClick={
            () => AppRouting.navigateToUrl(
              AppRouting.buildForgotPasswordPagePath(),
            )
          }
        >
          {translate(STRING_KEYS.FORGOT_PASSWORD)}
        </ForgotPassword>

        <LetsGoButtonWrapper
          isLoading={ false }
        >
          <PrimaryButton
            title={ translate(STRING_KEYS.LETS_GO) }
            type="submit"
          />
        </LetsGoButtonWrapper>
      </StyledForm>
    </FormContainer>
  );
}

export default LoginForm;
