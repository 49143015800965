import styled, { keyframes } from 'styled-components';
import { THEME } from '../../../themes';
import { ASSETS } from '../../../assets';

const fadeIn = keyframes`
  from {
   opacity: 0;
  }
  to {
   opacity: 1;
  }
`;

export const Container = styled.div<{
  width?: number,
  height?: number,
  absolutePosition?: boolean,
}>`
  position: ${({ absolutePosition }) => absolutePosition ? 'absolute' : 'fixed'};
  top: ${({ height }) => height ? `calc(50% - ${height / 2}px)` :
    'calc(50% - 256px})'};
  left: ${({ width }) => width ? `calc(50% - ${width / 2}px)` :
    'calc(50% - 350px})'};
  background-color: ${THEME.COLORS.PURE_WHITE};
  z-index: 1201;
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  max-height: 576px;
  border: solid 1px ${THEME.COLORS_RGB.PRIMARY_DARK_DIVIDER};
  border-radius: ${THEME.RADIUS.LOOPY};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: ${THEME.SPACING.LOOSE};
  padding-top: ${THEME.SPACING.LARGE};
  padding-bottom: ${THEME.SPACING.LOOSE};
  -webkit-clip-path: inset(0 0 0 0 round 32px);
  clip-path: inset(0 0 0 0 round 32px);
  animation: ${fadeIn} .5s forwards;
`;

export const CloseButton = styled.div`
  width: 32px;
  height: 32px;
  font-size: 0;
  border-radius: ${THEME.RADIUS.CIRCLE};
  background: ${THEME.COLORS.LIGHT_GREY} url(${ASSETS.CLOSE_DIALOG}) no-repeat center center;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  transition: all 0.2s;
  z-index:2;
  margin-top: ${THEME.SPACING.BASE};
  margin-right: ${THEME.SPACING.BASE};
  :hover {
    opacity: ${THEME.OPACITY.MED};
  }
`;

export const ModalBackdrop = styled.div<{
  backgroundBlur: boolean,
  originalBackgroundColor: boolean,
  width?: number,
  height?: number,
  }>`
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 998;
  background-color: ${({ originalBackgroundColor }) => originalBackgroundColor ?
    'unset' : 'rgba(255, 255, 255, .7)'} ;
  backdrop-filter: ${({ backgroundBlur }) => backgroundBlur ? 'blur(16px)' : 'none'} ;
  :after {
    content:"";
    border-radius: ${THEME.RADIUS.LOOPY};
    position: fixed;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    left: ${({ width }) =>  width && `calc(50% - ${width / 2}px)`};;
    top: ${({ height }) =>  height && `calc(50% - ${height / 2}px)`};
    box-shadow: 0 4px 24px 0 ${THEME.COLORS_RGB.PRIMARY_DARK_GHOST};
  }
`;

export const LoaderWrapper = styled.div`
  border-radius: 32px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
`;
