import styled from 'styled-components';
import { CaptionFont } from '../../../../../themes/fonts';
import { THEME } from '../../../../../themes';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { DividerSection } from '../../../../../components/Common/Divider/styled';
import { ASSETS } from '../../../../../assets';

export const SeeMoreButton = styled(BaseButton)`
  ${CaptionFont};
  color: ${THEME.COLORS.PRIMARY_DARK};
  opacity: ${THEME.OPACITY.MED};
`;

export const Divider = styled(DividerSection)`
  width: 100%;
  background-color: ${THEME.COLORS.CONTENT_PRIMARY};
  opacity: ${THEME.OPACITY.GHOST};
  align-self: center;
`;

export const ErrorIcon = styled(ASSETS.ALERT)`
  width: 32px;
  height: 32px;
  fill: ${THEME.COLORS.ALERT};
`;
