import { useMemo } from 'react';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import { WidgetDivider } from '../widgets-container.styled';
import TalentNetworkConnectionScore
  from '../../../../../../components/talent-network-card/talent-network-connection-score/talent-network-connection-score.component';
import { createFullName } from '../../../../../../utils';
import { MutualExperienceSentence } from '../../../talent-network/mutual-experience-sentence/mutual-experience-sentence.component';
import { MutualEducationSentence } from '../../../talent-network/mutual-education-sentence/mutual-education-sentence.component';
import { AvatarWithBadge } from '../../../../../../components/Common/avatar-with-badge/avatar-with-badge.component';
import { NetworkedTalentResponseDto } from '../../../../../../store/rtk-query/apis/match/dto/response/match.response';
import { connectionScoreRank } from '../../../../../../enums/connection-score.enum';
import BaseWidget from '../base-widget/base-widget.component';
import STRING_KEYS from '../../../../../../language/keys';
import { TalentNetworkWidgetProps } from './talent-network-widget';
import {
  Text, TextContainer, Container, Icon,
} from './talent-network-widget.styled';

const logger = log.getLogger('TALENT_NETWORK_WIDGET');

enum MutualConnectionType {
  Employment = 'EMPLOYMENT',
  Education = 'EDUCATION',
}
const getMutualConnectionType = (networkedTalent: NetworkedTalentResponseDto): MutualConnectionType => {
  const empConnectionScore = networkedTalent?.employmentConnections?.[0]?.connectionScore;
  const eduConnectionScore = networkedTalent?.educationConnections?.[0]?.connectionScore;

  if (empConnectionScore && eduConnectionScore) {
    if ((connectionScoreRank.get(empConnectionScore) || 0) >= (connectionScoreRank.get(eduConnectionScore) || 0)) {
      return MutualConnectionType.Employment;
    }

    return MutualConnectionType.Education;
  }

  return empConnectionScore ? MutualConnectionType.Employment : MutualConnectionType.Education;
};

const TalentNetworkWidget = ({ networkedTalent }: TalentNetworkWidgetProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const talentFullName = createFullName(networkedTalent?.talent?.firstName, networkedTalent?.talent?.lastName);

  const [mutualConnectionType, mutualPlaceName, mutualPlaceLogo] = useMemo(() => {
    // todo: use both education and employment when exist
    const connectionType = getMutualConnectionType(networkedTalent);
    let placeName;
    let placeLogo;

    if (connectionType === MutualConnectionType.Employment) {
      const item = networkedTalent.employmentConnections[0];
      placeLogo = item.companyLogo;
      placeName = item.companyName;
    } else {
      const item = networkedTalent.educationConnections[0];
      placeLogo = item.institutionLogo;
      placeName = item.institutionName;
    }

    return [
      connectionType,
      placeName,
      placeLogo,
    ];
  }, [networkedTalent]);

  const renderAvatar = () => {
    return (
      <AvatarWithBadge
        avatarImage={ networkedTalent?.talent?.profileImage }
        name={ talentFullName }
        badgeImage={ mutualPlaceLogo }
        badgeAlt={ mutualPlaceName }
        size={ 80 }
      />
    );
  };

  const renderSentence = () => {
    let sentence = null;

    switch (mutualConnectionType) {
      case MutualConnectionType.Employment:
        sentence = (
          <MutualExperienceSentence
            companyName={ mutualPlaceName || '' }
            networkedTalents={ [{ talent: networkedTalent.talent, experience: networkedTalent.employmentConnections[0] }] }
          />
        );
        break;
      case MutualConnectionType.Education:
        sentence = (
          <MutualEducationSentence
            institutionName={ mutualPlaceName || '' }
            networkedTalents={ [{ talent: networkedTalent.talent, education: networkedTalent.educationConnections[0] }] }
          />
        );
        break;
      default:
        logger.error('Unknown mutual connection type', mutualConnectionType);
        break;
    }

    return (<Text>{ sentence }</Text>);
  };

  return (
    <BaseWidget
      title={ translate(STRING_KEYS.MATCH_WIDGETS.TALENT_NETWORK.TITLE) }
      Icon={ Icon }
    >
      <Container>
        { renderAvatar() }
        <WidgetDivider isBig />
        <TextContainer>
          <TalentNetworkConnectionScore level={ networkedTalent.connectionCompoundScore } />
          { renderSentence() }
        </TextContainer>
      </Container>
    </BaseWidget>
  );
};

export default TalentNetworkWidget;
