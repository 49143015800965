import styled from 'styled-components';
import { THEME } from '../../../../themes';

export const Title = styled.div`
  display: flex;
  font-size: 24px; // Need to check how to manage all new fonts properties
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${THEME.UNKNOWN_COLORS.NAVY};
`;

export const ButtonWrapper = styled.div<{
  isDisabled: boolean}>`
  display: flex;
  width: 160px;
  ${({ isDisabled }) => isDisabled ? `
  opacity: ${THEME.OPACITY.LOW};
  pointer-events: none;
` : ''}
`;

export const InputWrapper = styled.input`
  display: flex;
`;
