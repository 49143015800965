import { useSelector } from 'react-redux';
import { RootReducerState } from '../../../index';
import { getUserInputsJobRequirement } from '../../../../modules/create-position/utils/create-position-job-requerments.util';
import { CompleteStepPayload } from '../../../../modules/onboarding/@types/onboarding-step-complete';

export const useCreateOnboardingEducationSelector = (): Partial<CompleteStepPayload> => {
  return useSelector<RootReducerState, Partial<CompleteStepPayload>>(({ createPosition }: RootReducerState) => {
    return {
      degrees: createPosition.degrees || undefined,
      fieldOfStudies: getUserInputsJobRequirement(createPosition.userInputFieldsOfStudy),
    };
  });
};
