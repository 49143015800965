import { DialogWidthSize } from '../base-dialog/base-dialog.enums';
import CompanyModalContents from '../../Common/CompanyInsightsModal/ModalContents';
import { CompanyDialogProps } from './company-dialog';
import { StyledDialog } from './company-dialog.styled';

const CompanyDialog = ({
  isOpen,
  isLoading,
  closeHandler,
  companyData,
}: CompanyDialogProps): JSX.Element => {
  return (
    <StyledDialog
      isOpen={ isOpen }
      onClose={ closeHandler }
      widthSize={ DialogWidthSize.Large }
    >
      <CompanyModalContents
        isLoading={ !!isLoading }
        companyInsightData={ companyData }
      />
    </StyledDialog>
  );
};

export default CompanyDialog;
