import axiosInstance from '../../../api';

export const searchJobTitles = async (text: string): Promise<RoleItem[]> => {
  // TODO: change to rtk
  const url = '/v1/positions/job-titles/search';

  // TODO: change to rtk
  const response = await axiosInstance.get(url, {
    params: {
      text,
    },
  });

  return response.data;
};

export const uploadJobDescription = async (file: FormData):
  Promise<PositionDescription> => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };
  // TODO: change to rtk
  const response = await axiosInstance.post('/v1/positions/job-description', file, config);

  return response.data;
};
