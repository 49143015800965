import styled from 'styled-components';
import { THEME } from '../../../../themes';
import {
  Title1Font,
  Title2Font,
  CaptionFont,
  Title3Font,
} from '../../../../themes/fonts';
import {
  JOB_DESCRIPTION_SUBTITLE_DELAY,
  JOB_DESCRIPTION_CONTENT_DELAY,
} from '../../../../consts/delays';
import { ELEMENTS_DURATION } from '../../../../consts/durations';
import { fadeInAnimation } from '../../../../animations';
import { ASSETS } from '../../../../assets';

export const Title = styled.div`
  ${Title1Font};
  font-weight: 300;
  color: ${THEME.COLORS_RGB.CONTENT_PRIMARY_MED};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  animation: ${fadeInAnimation({
    animationDuration: ELEMENTS_DURATION.JOB_DESCRIPTION_TITLE_DURATION,
    animationDelay: 0,
  })
};
`;

export const SubTitle = styled.div`
  ${Title3Font};
  font-weight: 500;
  color: ${THEME.COLORS_RGB.CONTENT_PRIMARY_MED};
  line-height: 1.38;

  animation: ${fadeInAnimation({
    animationDuration: ELEMENTS_DURATION.JOB_DESCRIPTION_SUBTITLE_DURATION,
    animationDelay: JOB_DESCRIPTION_SUBTITLE_DELAY,
  })
};
`;

export const Content = styled.div`
  ${Title2Font};
  color: ${THEME.COLORS.PRIMARY_DARK};
  line-height: 1.13;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin-top: 140px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  opacity: 0;
  animation: ${fadeInAnimation({
    animationDuration: ELEMENTS_DURATION.JOB_DESCRIPTION_CONTENT_DURATION,
    animationDelay: JOB_DESCRIPTION_CONTENT_DELAY,
  })
};
`;

export const UploadJobDescriptionWrapper = styled.div`
  position: relative;
  width: 640px;
`;

export const JobDescriptionUrlInput = styled.input<{
  showIcon: boolean,
  isError?:boolean
  }>`
  ${Title2Font};
  line-height: 1.13;
  font-weight: 300;
  width: 100%;
  opacity: ${THEME.OPACITY.MED};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  padding: 16px 12px;
  ${({ showIcon }) => showIcon ? `text-indent: ${THEME.SPACING.LARGE}` : ''};
  caret-color: ${THEME.COLORS.ACCENT};

  border: ${({ isError }) => isError ?
    `solid 1px ${THEME.COLORS.ALERT}` :
    `solid 1px ${THEME.COLORS_RGB.PRIMARY_DARK_GHOST}`};

  :focus {
    outline: none;
  }

  background: ${({ showIcon }) => showIcon ? `url(${ASSETS.ADD_LINK_ALT})` : ''}
    no-repeat ${THEME.SPACING.MEDIUM} ${THEME.COLORS.LIGHT_GREY};
  background-size: 32px;
  @media (max-height: ${THEME.BREAKPOINTS.HEIGHT.SMALL}px) {
    ${Title3Font};
  }
`;

export const OrChipWrapper = styled.div`
  ${Title3Font};
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: normal;
  margin-top: ${THEME.SPACING.MEDIUM};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
  background-color: ${THEME.COLORS.PURE_WHITE};
  text-align: left;
  text-transform: uppercase;
`;

export const ContinueButtonWrapper = styled.div`
  margin-top: 105px;
  width: 229px;
  display: inline-block;
`;

export const ErrorDescription = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.ALERT};
  text-align: left;
  position: absolute;
  top: 72px;
  left: 5px;
`;

export const Error = styled.div`
  display: inline-block;
  margin-left: ${THEME.SPACING.BASE};
  word-break: break-all;
  color: ${THEME.COLORS.ALERT};
`;
