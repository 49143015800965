import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { Body2Font } from '../../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../../utils/colors';

export const Container = styled.span`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
  cursor: pointer;
`;

export const Label = styled.span<{ selected?: boolean }>`
  ${Body2Font};
  font-weight: 600;
  line-height: 1.71;
  letter-spacing: 0.1px;
  color: ${({ selected }) => selected ? THEME.COLORS.ACCENT : hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.LOW)};
  display:inline-block;
  :first-letter {
    text-transform: uppercase; 
  }
`;

export const MenuContainer = styled.div`
  width: 320px;
  display: flex;
`;
