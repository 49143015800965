import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import STRING_KEYS from '../../../../language/keys';
import { MatchingProgressLoader } from '../../../../pages/matching-progress-page/matching-progress-loader/matching-progress-loader.component';
import { useGetPositionByIdQuery } from '../../../../store/rtk-query/apis/position/hooks/get-position-by-id.query-hook';
import { PositionRunTriggerTypeEnum } from '../../../../enums/position-run-trigger-type.enum';
import {
  PROGRESS_STEP,
} from '../../../../pages/matching-progress-page/matching-progress-loader/matching-progress-loader.consts';
import { PositionRunStatusEnum } from '../../../../enums/position-run-status.enum';
import { IntercomManager } from '../../../../services/intercom-manager';
import { ANALYTICS_EVENTS, EVENT_GROUP } from '../../../../consts/analytics';
import { PAGE_NAMES } from '../../../../consts/pages';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import {
  Container,
  DescriptionIcon,
  HeaderContainer,
  HeaderDescription,
  HeaderTextContainer,
  HeaderTitle,
  FooterText,
  SupportText,
  Description, DescriptionSubtitle, ChatLink,
} from './matching-progress.styled';

const MatchingProgress = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const { analyticsPageEvent } = useAnalyticsContext();
  const { positionId } = useParams<MatchUrlParams>();
  const { data: position } = useGetPositionByIdQuery(positionId);
  const [matchFooterText, setMatchFooterText] = useState<string>(translate(STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.FINDING));
  const [matchProgressTitle, setMatchProgressTitle] = useState<string>(translate(STRING_KEYS.MATCHING_PROGRESS_PAGE.TITLE.MAKING));
  const [matchProgressTitleDescription, setMatchProgressTitleDescription] = useState<string>(translate(STRING_KEYS.MATCHING_PROGRESS_PAGE.SUBTITLE.ANALYZING));
  const [isSupportRowVisible, setIsSupportRowVisible] = useState(false);
  const latestRunStatus = position.latestRun?.status;

  useEffect(() => {
    const isAdjustmentRun = position?.latestRun?.triggerType === PositionRunTriggerTypeEnum.DeadEndRecommendation;

    if (isAdjustmentRun) {
      setMatchProgressTitle(translate(STRING_KEYS.MATCHING_PROGRESS_PAGE.TITLE.ADJUSTMENT));
      setMatchProgressTitleDescription(translate(STRING_KEYS.MATCHING_PROGRESS_PAGE.DESCRIPTION.ADJUSTMENT));
    }
  }, [position?.latestRun?.triggerType, translate]);

  const updateProgressHandler = (step: number) => {
    if (step === PROGRESS_STEP.EXTENDED_WAIT_TIME) {
      setMatchFooterText(translate(STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.THANKS));
      setMatchProgressTitle(translate(STRING_KEYS.MATCHING_PROGRESS_PAGE.TITLE.STILL_WORKING));
      setMatchProgressTitleDescription(translate(STRING_KEYS.MATCHING_PROGRESS_PAGE.DESCRIPTION.MAKING_SURE));
    } else {
      setMatchProgressTitle(translate(STRING_KEYS.MATCHING_PROGRESS_PAGE.TITLE.LONGER_THAN_EXPECTED));
      setMatchFooterText(translate(STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.TOO_LONG.NOTIFY));
      setMatchProgressTitleDescription(translate(STRING_KEYS.MATCHING_PROGRESS_PAGE.DESCRIPTION.CLOSER_LOOK));
      setIsSupportRowVisible(true);
    }
  };

  const updateFooterTextHandler = (translationKey: string) => {
    setMatchFooterText(translate(translationKey));
  };

  const renderText = () => {
    return (
      <HeaderTextContainer>
        <HeaderTitle>
          { matchProgressTitle }
        </HeaderTitle>
        <HeaderDescription>
          { matchProgressTitleDescription }
        </HeaderDescription>
      </HeaderTextContainer>
    );
  };

  useEffect(() => {
    if (latestRunStatus === PositionRunStatusEnum.Failed) {
      updateProgressHandler(PROGRESS_STEP.ERROR_OR_TOO_LONG);
      analyticsPageEvent({
        eventName: ANALYTICS_EVENTS.MATCH_PAGE_TOO_LONG_STATE_VIEWED,
        eventGroup: EVENT_GROUP.PAGE_VIEW,
        pageName: PAGE_NAMES.MATCH_PAGE,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestRunStatus]);

  return (
    <Container>
      <HeaderContainer>
        { renderText() }
        <MatchingProgressLoader
          positionId={ position.id }
          positionTriggeredAt={ position?.latestRun?.triggeredAt }
          onUpdateProgress={ updateProgressHandler }
          onUpdateFooter={ updateFooterTextHandler }
        />
        <FooterText>
          <Description isSupportRowVisible={ isSupportRowVisible }>
            { isSupportRowVisible && <DescriptionIcon disableMargin /> }
            { matchFooterText }
          </Description>
          { isSupportRowVisible && (
            <DescriptionSubtitle>
              <SupportText>
                <Trans i18nKey={ STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.TOO_LONG.NEED_HELP }>
                  <ChatLink onClick={ IntercomManager.showChat } />
                </Trans>
              </SupportText>
            </DescriptionSubtitle>
          )}
        </FooterText>
      </HeaderContainer>
    </Container>
  );
};

export default MatchingProgress;
