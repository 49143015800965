import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import STRING_KEYS from '../../../../../language/keys';
import {
  DailyStreakIconContainer, FifthDailyStreakIcon, FirstDailyStreakIcon, FourthDailyStreakIcon,
  Label, SecondDailyStreakIcon, SeventhDailyStreakIcon, SixthDailyStreakIcon, ThirdDailyStreakIcon,
  Title,
} from './daily-streak.styled';

const DailyStreakIcons = [
  <FirstDailyStreakIcon key="daily-streak-0" />,
  <SecondDailyStreakIcon key="daily-streak-1" />,
  <ThirdDailyStreakIcon key="daily-streak-2" />,
  <FourthDailyStreakIcon key="daily-streak-3" />,
  <FifthDailyStreakIcon key="daily-streak-4" />,
  <SixthDailyStreakIcon key="daily-streak-5" />,
  <SeventhDailyStreakIcon key="daily-streak-6" />,
];

const MAXIMUM_DAILY_STREAK = 21;

const DailyStreak = ({ dailyStreak = 0 }: DailyStreakProps): JSX.Element | null => {
  const { t: translate } = useTranslation();

  const dailyStreakIndex = useMemo(() => {
    if (dailyStreak < 1) {
      return -1;
    }

    if (dailyStreak > 0 && dailyStreak <= MAXIMUM_DAILY_STREAK) {
      return dailyStreak - 1;
    }

    if (dailyStreak > MAXIMUM_DAILY_STREAK) {
      return MAXIMUM_DAILY_STREAK - 1;
    }

    return 0;
  }, [dailyStreak]);

  const showLatsIcon = useMemo(() => dailyStreakIndex >= DailyStreakIcons.length - 1, [dailyStreakIndex]);

  const getIcon = () => {
    if (showLatsIcon) {
      return DailyStreakIcons[DailyStreakIcons.length - 1];
    }

    return DailyStreakIcons[dailyStreakIndex];
  };

  if (dailyStreakIndex < 0) {
    return null;
  }

  return (
    <>
      <div>
        <Label>{ translate(STRING_KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[dailyStreakIndex][0]) }</Label>
        <Title>
          <span>
            { translate(STRING_KEYS.HOMEPAGE.HEADER.DAILY_STREAK.COUNTER, {
              count: dailyStreak,
            })}
          </span>
          <span>
            { translate(STRING_KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[dailyStreakIndex][1], {
              count: dailyStreak,
            })}
          </span>
        </Title>
      </div>
      <DailyStreakIconContainer showLatsIcon={ showLatsIcon }>
        { getIcon() }
      </DailyStreakIconContainer>
    </>
  );
};
export default DailyStreak;
