import { positionQuery } from '../position.toolkit-api';
import { GetSimilarTitlesQueryArguments } from '../dto/query-arguments/get-similar-titles.query-arguments';
import { SimilarTitle } from '../dto/response/position.response';

export const useGetSimilarTitlesQuery = ({ title, positionId }: GetSimilarTitlesQueryArguments): AppSelectorType<SimilarTitle[]> => {
  const {
    data,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
    refetch,
  } = positionQuery.useGetSimilarTitlesQuery({ title, positionId });

  return {
    data: data?.titles || [] as SimilarTitle[],
    error,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    refetch,
  };
};
