import styled from 'styled-components';
import { ASSETS } from '../../../../assets';
import { THEME } from '../../../../themes';

export const Button = styled.button`
  width: 56px;
  height: 56px;
  cursor: pointer;
  padding: ${THEME.SPACING.TINO};
  border-radius: ${THEME.RADIUS.CIRCLE};
  border: 1px solid ${THEME.COLORS.LIGHT_GREY};
  background: rgba(255,255,255,.12) url(${ASSETS.ARROW_BACK}) no-repeat center center;
  background-size: 20px;
  position: relative;
  transform: rotate(180deg);
  :disabled {
    opacity: 0.2;
    pointer-events: none;
  }
  :hover {
    opacity: 0.8;
  }
  :active{
    opacity: 0.5;
  }
`;
