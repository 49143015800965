import { OnboardingResponse } from '../../../../../modules/onboarding/@types/onboarding';
import { onboardingQuery } from '../onboarding.toolkit-api';
import { UpdateOnboardingProgressQueryArguments } from '../dto/query-arguments/update-onboarding-progress.query-arguments';

type UseLoadOnboardingResponseType = [
  ({ step, dynamicUpdateParams }:UpdateOnboardingProgressQueryArguments) => void,
  AppSelectorType<OnboardingResponse | undefined, unknown>
];

export const useUpdateOnboardingProgress = (): UseLoadOnboardingResponseType => {
  const [
    updateOnboardingProgress,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = onboardingQuery.useUpdateOnboardingProgressMutation();

  const meta = {
    isLoading, isError, error, isSuccess, data, resetState: reset,
  };

  return [
    updateOnboardingProgress,
    { ...meta },
  ];
};
