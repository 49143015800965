import { useMemo } from 'react';
import {
  ActionIcon,
  ActionIconWrapper,
  Body,
  Container,
  Count,
  Footer,
  Header,
  TitleIconWrapper,
  InfoText,
  Title,
  BodyIconWrapper,
  ActionButton,
} from './statistic-card.styled';

const StatisticCard = ({
  title,
  TitleIcon,
  count,
  activeColor,
  infoText,
  BodyIcon,
  actionButtonText,
  clickHandler,
}: StatisticCardProps): JSX.Element => {
  const isActive = useMemo(() => count > 0, [count]);

  const renderActionButton = () => {
    return (
      <ActionButton>
        <ActionIconWrapper><ActionIcon $isActive={ isActive } color={ activeColor } /></ActionIconWrapper>
        <div>{ actionButtonText }</div>
      </ActionButton>
    );
  };

  const renderBodyIcon = () => {
    return (
      <BodyIconWrapper>
        <BodyIcon />
      </BodyIconWrapper>
    );
  };

  const renderFooterContent = () => {
    if (isActive) {
      return renderActionButton();
    }

    return <InfoText>{ infoText }</InfoText>;
  };

  const renderBodyContent = () => {
    if (BodyIcon && !count) {
      return renderBodyIcon();
    }

    return <Count $isActive={ isActive }>{ count > 0 ? '+' : undefined}{ count }</Count>;
  };

  return (
    <Container
      $isActive={ isActive }
      backgroundColor={ activeColor }
      onClick={ () => clickHandler?.() }
    >
      <Header>
        <TitleIconWrapper><TitleIcon /></TitleIconWrapper>
        <Title>{ title }</Title>
      </Header>
      <Body>
        { renderBodyContent() }
      </Body>
      <Footer>
        { renderFooterContent() }
      </Footer>
    </Container>
  );
};

export default StatisticCard;
