import styled from 'styled-components';
import { Avatar as MuiAvatar } from '@mui/material';
import { ASSETS } from '../../../../assets';
import { THEME } from '../../../../themes';
import {
  Body1Font, Title1Font, Subtitle2Font, HeadlineFont,
} from '../../../../themes/fonts';
import { pageContentWidth } from '../match-content.styled';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${THEME.COLORS.LIGHT_GREY};
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 250px;
  flex-direction: column;
  align-items: center;
  background-color: ${THEME.COLORS.LIGHT_GREY};
  padding-top: ${THEME.SPACING.LARGE};
  padding-bottom: ${THEME.SPACING.LARGE};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin: auto;
  width: ${pageContentWidth};
  align-items: center;
`;

export const StyledAvatar  = styled(MuiAvatar)<{ color: string }>`
  outline: 4px solid ${({ color }) => color};
  border: 8px solid ${THEME.COLORS.PURE_WHITE};
  transition: all 0.8s ease-in;
`;

export const AvatarBadge  = styled.div<{ backgroundColor: string, textColor: string }>`
  ${Subtitle2Font};
  height: 32px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  position: absolute;
  bottom: -12px;
  white-space: nowrap;
  padding: ${THEME.SPACING.TINY} ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.ROUND};
  outline: 4px solid ${THEME.COLORS.LIGHT_GREY};
  letter-spacing: 0.84px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  transition: all 0.8s ease-in;
`;

export const AvatarContainer = styled.div`
  display: flex;
  width: 133px;
  height: 133px;
  justify-content: center;
  align-items: center;
  margin-right: ${THEME.SPACING.LOOSE};
  position: relative;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: ${THEME.SPACING.LOOSE};
  border-left: 2px solid ${THEME.COLORS.NEUTRAL};
  word-break: break-all;
`;

export const InfoRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const TalentInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TalentName = styled.div`
  display: inline-block;
  ${Title1Font};
  font-weight: 600;
  vertical-align: middle;
  line-height: 1.27;
  color: ${THEME.COLORS.PRIMARY_DARK};
  margin-right: ${THEME.SPACING.BASE};
`;

export const TalentLocation = styled.span`
   ${HeadlineFont};
  font-weight: normal;
  color: ${THEME.COLORS_RGB.PRIMARY_DARK_MED};
  display: flex;
  align-items: center;
`;

export const LocationPinIcon = styled(ASSETS.LOCATION_PIN)`
  margin-right: ${THEME.SPACING.TINY};
`;

export const MetaDataRow = styled.div`
  margin-top: ${THEME.SPACING.TINY};
  display: block;
`;

export const ToReviewIcon = styled(ASSETS.STARS_FILLED)`
  width: 20px;
`;

export const EngagedIcon = styled(ASSETS.HEART)`
  width: 20px;
`;

export const DeclinedIcon = styled(ASSETS.REJECT)`
  width: 20px;
`;

export const MetaDataItem = styled.div`
  ${Body1Font};
  display: inline-block;
  vertical-align: center;
  margin-right: ${THEME.SPACING.BASE};

  :before {
    content: '';
    float: left;
    margin-right: ${THEME.SPACING.TINO};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
`;

export const Location = styled(MetaDataItem)`
  :before {
    width: 20px;
    height: 20px;
    background-image: url(${ASSETS.LOCATION_ICON});
    margin-top: 2px;
  }
`;
