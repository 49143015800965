import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { OutreachContentPreferencesFormikKeys } from '../../pages/outreach-content-preferences/outreach-content-preferences.enums';
import OutreachContentPreferencesSection
  from '../../pages/outreach-content-preferences/outreach-content-preferences-section/outreach-content-preferences-section.component';
import {
  Container, Description, MessagesContainer, TitleIcon,
} from './message-spices.styled';
import { MessageSpicesProps } from './message-spices';
import MessageSpiceItem from './message-spice-item/message-spice-item.component';

const MessageSpices = (props: MessageSpicesProps): JSX.Element => {
  const {
    insiderNetwork,
    likelyhoodToMove,
    relevantSkills,
    relevantEducation,
    similarCompanies,
    onChangeHandler,
  } = props;
  const { t: translate } = useTranslation();

  const renderMessageItems = () => {
    return (
      <MessagesContainer>
        <MessageSpiceItem
          checked={ insiderNetwork }
          checkedHandler={ (value) => {
            onChangeHandler(OutreachContentPreferencesFormikKeys.InsiderNetwork, value);
          } }
          title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_TITLE.INSIDER_NETWORK) }
          description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_DESCRIPTION.INSIDER_NETWORK) }
          message={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_MESSAGES.INSIDER_NETWORK) }
        />
        <MessageSpiceItem
          checked={ likelyhoodToMove }
          checkedHandler={ (value) => {
            onChangeHandler(OutreachContentPreferencesFormikKeys.LikelyhoodToMove, value);
          } }
          title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_TITLE.LIKELYHOOD_TO_MOVE) }
          description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_DESCRIPTION.LIKELYHOOD_TO_MOVE) }
          message={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_MESSAGES.LIKELYHOOD_TO_MOVE) }
        />
        <MessageSpiceItem
          checked={ relevantSkills }
          checkedHandler={ (value) => {
            onChangeHandler(OutreachContentPreferencesFormikKeys.RelevantSkills, value);
          } }
          title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_TITLE.RELEVANT_SKILLS) }
          description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_DESCRIPTION.RELEVANT_SKILLS) }
          message={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_MESSAGES.RELEVANT_SKILLS) }
        />
        <MessageSpiceItem
          checked={ relevantEducation }
          checkedHandler={ (value) => {
            onChangeHandler(OutreachContentPreferencesFormikKeys.RelevantEducation, value);
          } }
          title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_TITLE.RELEVANT_EDUCATION) }
          description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_DESCRIPTION.RELEVANT_EDUCATION) }
          message={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_MESSAGES.RELEVANT_EDUCATION) }
        />
        <MessageSpiceItem
          checked={ similarCompanies }
          checkedHandler={ (value) => {
            onChangeHandler(OutreachContentPreferencesFormikKeys.SimilarCompanies, value);
          } }
          title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_TITLE.SIMILAR_COMPANIES) }
          description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_DESCRIPTION.SIMILAR_COMPANIES) }
          message={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_MESSAGES.SIMILAR_COMPANIES) }
        />
      </MessagesContainer>
    );
  };

  return (
    <OutreachContentPreferencesSection
      title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.TITLE) }
      Icon={ <TitleIcon /> }
    >
      <Container>
        <Description>
          { translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.DESCRIPTION) }
        </Description>
        { renderMessageItems() }
      </Container>
    </OutreachContentPreferencesSection>
  );
};
export default MessageSpices;
