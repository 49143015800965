import styled from 'styled-components';
import { HeadlineFont, OverlineFont } from '../../../../../themes/fonts';
import { THEME } from '../../../../../themes';
import { DAILY_STREAK } from '../../../../../assets';

export const Label = styled.div`
  ${OverlineFont};
  opacity: ${THEME.OPACITY.MED};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

export const Title = styled.div`
  ${HeadlineFont};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  display: flex;
  gap: ${THEME.SPACING.TINY};
  align-items: center;
`;

export const DailyStreakIconContainer = styled.div<{ showLatsIcon: boolean }>`
  mask-image: ${({ showLatsIcon }) => showLatsIcon ? 'linear-gradient(to right, rgba(0, 0, 64, 0.2), rgb(0, 0, 64) 20%)' : undefined};
`;

export const FirstDailyStreakIcon = styled(DAILY_STREAK[0])``;
export const SecondDailyStreakIcon = styled(DAILY_STREAK[1])``;
export const ThirdDailyStreakIcon = styled(DAILY_STREAK[2])``;
export const FourthDailyStreakIcon = styled(DAILY_STREAK[3])``;
export const FifthDailyStreakIcon = styled(DAILY_STREAK[4])``;
export const SixthDailyStreakIcon = styled(DAILY_STREAK[5])``;
export const SeventhDailyStreakIcon = styled(DAILY_STREAK[6])``;
