import { Container } from './instructions-message.styled';

type InstructionsMessageProps = {
  message: JSX.Element,
  icon?: string,
  color: string,
}

export const InstructionsMessage = ({
  message,
  icon = '',
  color,
}:InstructionsMessageProps): JSX.Element => {
  return (
    <Container
      icon={ icon }
      color={ color }
    >
      {message}
    </Container>
  );
};

InstructionsMessage.defaultProps = {
  icon: '',
};
