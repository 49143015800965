import { Trans, useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import {
  FirstTimeExperience,
  Header,
  TalentNetworkIcon,
  TalentNetworkIconWrapper,
  TextContainer,
} from './talent-network-first-time-experience.styled';

export const TalentNetworkFirstTimeExperience = (): JSX.Element | null => {
  const { t: translate } = useTranslation();

  const getText = () => {
    return (
      <Trans i18nKey={ STRING_KEYS.TALENT_NETWORK.TIMELINE.FIRST_TIME_EXPERIENCE.TEXT }>
        <strong />
      </Trans>
    );
  };

  return (
    <FirstTimeExperience>
      <Header>
        <TalentNetworkIconWrapper>
          <TalentNetworkIcon />
        </TalentNetworkIconWrapper>
        <div>{translate(STRING_KEYS.TALENT_NETWORK.TIMELINE.FIRST_TIME_EXPERIENCE.TITLE)}</div>
      </Header>
      <TextContainer>{ getText() }</TextContainer>
    </FirstTimeExperience>
  );
};
