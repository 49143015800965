import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { SKILLS_TO_SHOW } from '../skills-widget.component';
import { SkillItem } from './skill/skill.component';
import { Wrapper } from './skills-list.styled';

type SkillsListProps = {
    skills: SkillData[],
    isExpanded: boolean,
}

const SkillsList = ({
  skills,
  isExpanded,
}: SkillsListProps):JSX.Element => {
  const containerRef = useRef(null);
  const {
    inViewport,
    enterCount,
  } = useInViewport(
    containerRef,
    {},
    {},
  );
  const skillsList = !isExpanded ? skills.slice(0, SKILLS_TO_SHOW) : skills;

  return  (
    <Wrapper ref={ containerRef }>
      { skillsList.map(({ askedByUser, skillName }, index) => {
        return (
          <SkillItem
            key={ skillName }
            index={ index }
            skill={ skillName }
            isEnableAnimation={ inViewport && enterCount === 1 }
            askedByUser={ !!askedByUser }
          />
        );
      }) }
    </Wrapper>
  );
};

export default SkillsList;
