import styled, { css } from 'styled-components';
import { THEME } from '../../../../themes';
import { HeadlineFont, Title1Font, Title2Font } from '../../../../themes/fonts';
import { fadeInAnimation } from '../../../../animations';
import { ELEMENTS_DURATION } from '../../../../consts/durations';
import {
  PAGE_CONTENT_DELAY,
  PAGE_CONTENT_DELAY_WITH_WELCOME_DELAY,
  PAGE_TITLE_DELAY,
} from '../../../../consts/delays';
import TitleWithTooltip from '../../../application/components/title-with-tooltip/title-with-tooltip.component';

export const CREATE_POSITION_CONTAINER_HEIGHT = '64vh';

export const Container = styled.div<{ topPadding?: boolean }>`
  display: flex;
  width: 100vw;
  padding-top: ${({ topPadding }) => topPadding && THEME.SPACING.LARGE};
  padding-bottom: ${THEME.SPACING.TINO};
  padding-left: calc(${THEME.SPACING.XGIANT} + ${THEME.SPACING.XGIANT});
  position: relative;
  margin: 0 auto;
  height: ${CREATE_POSITION_CONTAINER_HEIGHT};
  overflow-y: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  flex-grow: 1;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
`;

export const BodyContainer = styled.div<{
  includeWelcomeScreenDelay?: boolean
  marginTop?: string
  marginBottom?: string
}>`
  ${Title2Font};
  color: ${THEME.COLORS.PRIMARY_DARK};
  width: 100%;
  opacity: 0;
  z-index: 10;
  margin-top: ${THEME.SPACING.LARGE};
  animation: ${({ includeWelcomeScreenDelay }) => fadeInAnimation({
    animationDuration: ELEMENTS_DURATION.PAGE_TITLE_DURATION,
    animationDelay: includeWelcomeScreenDelay ?
      PAGE_CONTENT_DELAY_WITH_WELCOME_DELAY :
      PAGE_CONTENT_DELAY,
  })
};
  @media (max-height: ${THEME.BREAKPOINTS.HEIGHT.SMALL}px) {
    margin-top: ${THEME.SPACING.BASE};
  }
`;

export const PositionInfo = styled.span`
  ${HeadlineFont};
  font-weight: normal;
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.PRIMARY_DARK};
  
  strong {
    font-weight: 500;
  }
`;

export const PageTitle = styled.span<{
  includeAnimationDelay?: boolean,
  maxWidth?: number,
}>`
  ${Title1Font};
  font-weight: 300;
  color: ${THEME.COLORS.CONTENT_A};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '680px')};
  animation: ${({ includeAnimationDelay }) => {
    return fadeInAnimation({
      animationDuration: ELEMENTS_DURATION.PAGE_TITLE_DURATION,
      animationDelay: includeAnimationDelay ? PAGE_TITLE_DELAY : 0,
    });
  }
};
  @media (max-height: ${THEME.BREAKPOINTS.HEIGHT.SMALL}px) {
    ${Title2Font};
  }
`;

const TitleCss = css`
    ${Title1Font};
    font-weight: 600;
    color: ${THEME.COLORS.PRIMARY_DARK};
    @media (max-height: ${THEME.BREAKPOINTS.HEIGHT.SMALL}px) {
        ${Title2Font};
        font-weight: 600;
    }
`;

export const PageTitleBold = styled.span`
  ${TitleCss};
`;

export const PageTitleBoldTooltip = styled(TitleWithTooltip)`
  ${TitleCss};
`;
