import styled from 'styled-components';
import { THEME } from '../../../themes';
import {
  Title3Font,
  Body1Font,
  Body2Font,
} from '../../../themes/fonts';

export const Title = styled.div`
  ${Title3Font};
  font-weight: bold;
  line-height: 1.5;
`;

export const SubTitle = styled.div`
  ${Body1Font};
  line-height: 1.5;
  opacity: ${THEME.OPACITY.MED};
  margin-bottom: ${THEME.SPACING.MEDIUM};
`;

export const ChapterBody = styled.div`
  ${Body2Font};
  margin-top: ${THEME.SPACING.BASE};
  line-height: 1.57;
  height: 280px;
  overflow-y: scroll;
  word-break: break-word;
  text-align: left;
  margin-bottom: ${THEME.SPACING.MEDIUM};

  ::-webkit-scrollbar {
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
    border-radius: 100px;
    background-color: rgba(236, 9, 130, ${THEME.OPACITY.LOW});
    background-clip: content-box;
  }
`;

export const CloseButtonWrapper = styled.div`
  width: 160px;
  margin-top: -${THEME.SPACING.BASE};
  margin-bottom: -${THEME.SPACING.LOOSE};
`;

export const ContentWrapper = styled.div`
  width: 90%;
  margin: -${THEME.SPACING.XLOOSE} auto 0 auto;
`;
