import styled from 'styled-components';

export const StyledSvgIcon = styled.svg<{size:string, fill?: string}>`  
  width:${({ size }) => size};
  height: ${({ size }) => size};
  
  & svg {
    fill:${({ fill }) => fill};
    width:${({ size }) => size};
    height: ${({ size }) => size};
  }
`;
