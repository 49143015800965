import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { Badge, BadgeProps as BadgeType } from '../../../badge/badge.component';
import { Language } from '../../../../@types/smartBadges';
import {
  Bilingual,
  Trilingual,
  Multilingual,
} from './multilingual-badge.styled';

const BILINGUAL_SIZE = 2;
const TRILINGUAL_SIZE = 3;

type MultilingualBadgeProps = {
  languagesList: Language[]
}

export const MultilingualBadge = ({
  languagesList,
}:MultilingualBadgeProps):
JSX.Element | null => {
  const { t: translate } = useTranslation();
  const languages = languagesList.map((language) => language.name);

  const getBadgeData = (): BadgeType | null => {
    if (languagesList.length === BILINGUAL_SIZE) {
      return {
        title: translate(STRING_KEYS.BILINGUAL),
        description: translate(STRING_KEYS.BILINGUAL_DESCRIPTION, {
          languages: languages.slice(0, BILINGUAL_SIZE - 1).join(', '),
          lastLanguage: languages[BILINGUAL_SIZE - 1],
        }),
        Icon: <Bilingual />,
      };
    }
    if (languagesList.length === TRILINGUAL_SIZE) {
      return {
        title: translate(STRING_KEYS.TRILINGUAL),
        description: translate(STRING_KEYS.TRILINGUAL_DESCRIPTION, {
          languages: languages.slice(0, TRILINGUAL_SIZE - 1).join(', '),
          lastLanguage: languages[TRILINGUAL_SIZE - 1],
        }),
        Icon: <Trilingual />,
      };
    }
    if (languagesList.length > TRILINGUAL_SIZE) {
      return {
        title: translate(STRING_KEYS.MULTILINGUAL),
        description: translate(STRING_KEYS.MULTILINGUAL_DESCRIPTION, {
          languages: languages.join(', '),
        }),
        Icon: <Multilingual />,
      };
    }

    return null;
  };

  const { title, description, Icon } = getBadgeData() || {};

  if (!title || !description || !Icon) {
    return null;
  }

  return (
    <Badge
      title={ title }
      description={ description }
      Icon={ Icon }
    />
  );
};
