/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, Ref } from 'react';
import { BaseSelectionGroupVariant } from '../base-selection-group.enums';
import { Container, Content, Wrapper } from './selection-group-item.styled';
import { SelectionGroupItemProps } from './selection-group-item';

const SelectionGroupItem = forwardRef((
  props: SelectionGroupItemProps,
  ref: Ref<HTMLDivElement>,
) => {
  const {
    variant = BaseSelectionGroupVariant.Round,
    disabled = false,
    onClickHandler,
    value,
    children,
    activeElementIndex = 0,
    active = false,
    Adornment,
    ...otherProps
  } = props;

  return (
    <Container
      ref={ ref }
      variant={ variant }
      active={ active }
      disabled={ disabled }
      activeElementIndex={ activeElementIndex }
      onClick={ () => !disabled && onClickHandler?.(value) }
      { ...otherProps }
    >
      <Wrapper active={ active }>
        <Content>
          { children }
          { Adornment && (<>{ Adornment }</>)}
        </Content>
      </Wrapper>
    </Container>
  );
});

SelectionGroupItem.displayName = 'SelectionGroupItem';

export default SelectionGroupItem;
