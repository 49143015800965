import styled from 'styled-components';
import { ASSETS } from '../../../../../../../../assets';
import { THEME } from '../../../../../../../../themes';
import IconButton from '../../../../../../../../components/Common/Buttons/icon-button/icon-button.component';

export const Container = styled.div`
  height: 70px;
  background-color: ${THEME.COLORS.PURE_WHITE};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  display: flex;
  align-items: center;
  padding: 0 ${THEME.SPACING.TINY};
  gap: ${THEME.SPACING.TINO};
`;

export const StyledIconButton = styled(IconButton)`
  color: ${THEME.COLORS.PRIMARY_DARK};
  height: fit-content;
  width: fit-content;
`;

export const RemoveButtonIcon = styled(ASSETS.X_SIGN_FILLED)`
  width: 20px;
  height: 20px;
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.PRIMARY_DARK};
`;
