import styled, { css } from 'styled-components';
import { THEME } from '../../../../../../themes';
import { hexToRgbA } from '../../../../../../utils/colors';
import { CaptionFont } from '../../../../../../themes/fonts';
import { ASSETS } from '../../../../../../assets';

export const IconCss = css`
  height: 20px;
  width: 20px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
  padding: ${THEME.SPACING.TINY} 0;
  width: fit-content;
`;

export const Title = styled.div`
  ${CaptionFont};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const TooltipWrapper = styled.div`
  display: flex;
`;

export const QuestionIcon = styled(ASSETS.QUESTION_OUTLINE)`
  width: 16px;
  height: 16px;
  fill: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
`;
