import { JobRequirement } from '../../../store/rtk-query/apis/position/dto/response/position.response';

export const SKIP_ITEM_ID = 'SKIP_ID';

export const getUserInputsJobRequirement = (jobRequirements: PositionJobRequirementState[]): JobRequirement[] => (
  jobRequirements
    ?.filter((jobRequirement) => jobRequirement.id !== SKIP_ITEM_ID && !!jobRequirement.title)
    .map((jobRequirement: PositionJobRequirementState) => ({
      id: jobRequirement.id,
      value: jobRequirement.title,
    })) || []
);
