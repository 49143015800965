import { DescriptionTitle } from './description-title-with-dividers.styled';

type DescriptionTitleWithDividersProps = {
  text: string
}

function DescriptionTitleWithDividers({ text }: DescriptionTitleWithDividersProps): JSX.Element {
  return (
    <DescriptionTitle>
      <span>{ text }</span>
    </DescriptionTitle>
  );
}

export default DescriptionTitleWithDividers;
