import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import { CONFIG } from '../../../../../config';
import {
  Container,
  Description,
  Label,
  LearnMoreLink,
  Title,
} from './gen-ai-activation-banner.styled';

const GenAiActivationBanner = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <Label>{ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.GEN_AI_ACTIVATION_BANNER.LABEL) }</Label>
      <Title>{ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.GEN_AI_ACTIVATION_BANNER.TITLE) }</Title>
      <Description>{ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.GEN_AI_ACTIVATION_BANNER.DESCRIPTION) }</Description>
      <LearnMoreLink
        target="_blank"
        rel="noopener noreferrer"
        href={ CONFIG.REACT_APP_GEN_AI_PREFERENCES_GUIDE_URL }
      >
        { translate(STRING_KEYS.LEARN_MORE) }
      </LearnMoreLink>
    </Container>
  );
};

export default GenAiActivationBanner;
