import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';

export const Wrapper = styled.div<{
  width: string,
  height: string,
  padding: string,
  backgroundColor: string,
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  border-radius: ${THEME.RADIUS.ROUND};
  border: solid 1px rgba(0, 0, 0, 0);
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: relative;
`;

export const InfoButton = styled(ASSETS.INFO)`
  position: absolute;
  right: ${THEME.SPACING.TINY};
  top: ${THEME.SPACING.TINY};
  cursor: pointer;
`;
