import styled, { keyframes, css } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@mui/material';
import { TENURE_TITLE_STATUS } from '../../../../../../consts';
import { THEME } from '../../../../../../themes';
import { Body1Font } from '../../../../../../themes/fonts';
import { METER_TYPE } from '../tenure-meter-time-widget.conts';

const getGrowToRightAnimation = (width: number) => {
  return keyframes`
      from {
          width: 0;
      }
      to {
        width: ${width}%;
      }
      `;
};

type MeterProps = {
  width: number,
  isEnableAnimation: boolean,
  isBiggestValue: boolean,
  isEqual: boolean,
  meter: string,
 }

export const Meter = styled.div<MeterProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ width }) => width || 100}%;
  background-color: ${({ meter }) => meter === METER_TYPE.EXPERIENCE ?
    THEME.COLORS.ACCENT : THEME.COLORS.PRIMARY};
  border-radius: ${({ isBiggestValue, isEqual }) => !isBiggestValue && !isEqual ?
    `${THEME.SPACING.TINY} 0px 0px ${THEME.SPACING.TINY}` : THEME.SPACING.TINY};
  padding: 7.5px 9px 8.5px 16px;
  color: ${THEME.COLORS.PURE_WHITE};
  line-height: 1.83;
  height: 34px;
  animation: ${({ width, isEnableAnimation }) => {
    return isEnableAnimation ? css`${getGrowToRightAnimation(width)} 1200ms 
    cubic-bezier(0.85, 0, 0.15, 1)` : 'none';
  }};
`;

export const MeterTitle = styled.div`
  color: ${THEME.COLORS.PURE_WHITE};
  font-size: 12px;
  font-weight: 400;
  line-height: 1.83;
  letter-spacing: 0.21px;
  width: max-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const MeterTitleExperience = styled.div`
  ${Body1Font};
  color: ${THEME.COLORS.PURE_WHITE};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  letter-spacing: 0.29px;
  min-width: 60px;
  text-align: center;
  white-space: nowrap;
`;

// to do change repeating-linear-gradient color from design
const getColorBackground = (type: string) => {
  return type === TENURE_TITLE_STATUS.EXCEEDING_TENURE ?
    'rgb(191, 186, 210)' : 'rgba(235, 9, 129, 0.32)';
};

const setGradientRed = css`
  background: repeating-linear-gradient(
    -45deg, rgba(235, 9, 129, 0.32), rgba(235, 9, 129, 0.32) 2px, #fde2f0 1px, #fde2f0 6px);`;

const setGradientBlue = css`
  background: repeating-linear-gradient(
    -45deg, rgb(191, 186, 210), rgb(191, 186, 210) 2px, #E6E4EE 1px, #E6E4EE 6px);
`;

export const DifferenceStripedBlock = styled.div<{ width: number, type: string }>`
  height: 34px;
  width: ${({ width }) => width}%;
  border: solid 1px ${({ type }) => getColorBackground(type)} ;
  ${({ type }) => type === TENURE_TITLE_STATUS.EXCEEDING_TENURE ?
    setGradientBlue : setGradientRed};
  border-top-right-radius: ${THEME.SPACING.TINY};
  border-bottom-right-radius: ${THEME.SPACING.TINY};
`;

export const TooltipBubble = withStyles({
  tooltip: {
    display: 'flex',
    color: `${THEME.COLORS.PRIMARY_DARK}`,
    backgroundColor: `${THEME.COLORS.LIGHT_GREY}`,
    borderRadius: '24px 24px 5px 24px',
    width: 'auto !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'center',
    height: 'auto',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: 'normal',
    letterSpacing: '0.4px',
    border: `solid 2px ${THEME.COLORS.PURE_WHITE}`,
    position: 'absolute',
    top: '-10px',
    right: '-6px',
  } as const,
})(Tooltip);

export const MeterRow = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;
