/** getYearAbb return or single or multiple Abb ('YR' or 'YRS), related to number
 * example number = 1, it will (return 1 YR,) 
 * example 2, number 11, it will (return 11 YRS)
 * 
 * @param number 
 * @returns string 
 */

export const getYearAbb = (number: number): string => {
  const yearsText = number <= 1 ? 'YR' : 'YRS';

  return `${number} ${yearsText}`;
};

/** makeAxis return list of objects { title: string, id: number } every 0.5 times
 * if the value has dot, we don't need to display title.
 * example yearMax = 3; arr will be [{ title: '', id: 0 }, { title: 'YR', id: 1 } { title: 'YRS', id: 2 }] e.t.c
 * @param yearMax  
 * @returns  list of objects { title: string, id: number } 
 */

export const makeAxis = (yearMax: number): { title: string, id: number }[] => {
  const listAxis = [];
  for (let i = 0; i <= yearMax; i += (0.5)) {
    if (i % 1 !== 0) {
      listAxis.push({
        title: '',
        id: i,
      });
    } else {
      listAxis.push({
        title: `${getYearAbb(i)}`,
        id: i,
      });
    }
  }

  return listAxis;
};

/** calcMeterWidth return diff of number (meaning precented from maxVal)
 * example value = 5 , maxVal = 10 return val 50... (5 * 100) / 10 = 50;
 * 
 * @param number  
 * @param maxVal  
 * @returns number 
 */

export const calcMeterWidth = (value: number, maxVal: number):number => {
  return (value * 100) / maxVal;
};
