import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import InsightsCard from '../base-insights-card/insights-card.component';
import { InsightsCardTitle } from '../base-insights-card/insights-card.styled';
import { AppRouting } from '../../../../../../services/appRoutingResolver';
import STRING_KEYS from '../../../../../../language/keys';
import AvatarsList from '../../../../../../components/Common/AvatarsList';
import { MAX_NUMBER_OF_ICONS_IN_AVATAR_GROUP } from './engaged-insight-card.consts';
import { HeartIcon } from './engaged-insight-card.styled';

const EngagedInsightCard = ({
  positionId,
  icons,
}: EngagedInsightCardProps):JSX.Element => {
  const browserHistory = useHistory();
  const { t: translate } = useTranslation();

  const onClickGoToOutreach = () => {
    browserHistory.push(
      AppRouting.buildPositionConversationsPageUrl(positionId),
    );
  };

  const InsightsTitle: JSX.Element = (
    <>
      <HeartIcon />
      <InsightsCardTitle>{ translate(STRING_KEYS.DAILY_LIMIT.INSIGHTS.ENGAGED_MATCHES_TITLE) }</InsightsCardTitle>
    </>
  );

  const InsightBody: JSX.Element = (
    <AvatarsList
      icons={ icons }
      maxNumberInGroup={ MAX_NUMBER_OF_ICONS_IN_AVATAR_GROUP }
    />
  );

  return (
    <InsightsCard
      title={ InsightsTitle }
      insightValueBold={ icons.length }
      actionTitle={ translate(STRING_KEYS.DAILY_LIMIT.INSIGHTS.ENGAGED_MATCHES_ACTION_TITLE) }
      actionHandler={ onClickGoToOutreach }
    >
      { InsightBody }
    </InsightsCard>
  );
};

export default EngagedInsightCard;
