import { Trans } from 'react-i18next';
import { CONFIG } from '../../../../../config';
import { THEME } from '../../../../../themes';
import STRING_KEYS from '../../../../../language/keys';
import { ContactSupportLink } from './outreach-banner.styled';
import { BannerConfiguration } from './outreach-banner';
import { OutreachBannerTypeEnum } from './outreach-banner-type.enum';

const CampaignOnHoldTransComponent = (
  <ContactSupportLink
    rel="noopener noreferrer"
    target="_blank"
    href={ `mailto:${CONFIG.SUPPORT_MAIL_ADDRESS}` }
  />
);

export const getBannerConfiguration = (type: OutreachBannerTypeEnum, additionalInfo?: Record<string, string>): BannerConfiguration | null => {
  switch (type) {
    case OutreachBannerTypeEnum.ConnectEmail:
      return ({
        color: THEME.COLORS.ACCENT,
        title: STRING_KEYS.OUTREACH_PAGE.CONNECT_EMAIL_BANNER.TITLE,
        description: STRING_KEYS.OUTREACH_PAGE.CONNECT_EMAIL_BANNER.DESCRIPTION,
        actionButtonText: STRING_KEYS.OUTREACH_PAGE.CONNECT_EMAIL_BANNER.BUTTON_TEXT,
      });
    case OutreachBannerTypeEnum.ReconnectEmail:
      return ({
        color: THEME.COLORS.ACCENT,
        title: STRING_KEYS.OUTREACH_PAGE.RECONNECT_EMAIL_BANNER.TITLE,
        description: STRING_KEYS.OUTREACH_PAGE.RECONNECT_EMAIL_BANNER.DESCRIPTION,
        actionButtonText: STRING_KEYS.OUTREACH_PAGE.RECONNECT_EMAIL_BANNER.BUTTON_TEXT,
      });
    case OutreachBannerTypeEnum.UpgradeToPro:
      return ({
        color: THEME.COLORS.SECONDARY,
        title: STRING_KEYS.OUTREACH_PAGE.UPGRADE_TO_PRO_BANNER.TITLE,
        description: STRING_KEYS.OUTREACH_PAGE.UPGRADE_TO_PRO_BANNER.DESCRIPTION,
        actionButtonText: STRING_KEYS.OUTREACH_PAGE.UPGRADE_TO_PRO_BANNER.BUTTON_TEXT,
      });
    case OutreachBannerTypeEnum.SetupGenAi:
      return ({
        color: THEME.COLORS.WARNING,
        title: STRING_KEYS.OUTREACH_PAGE.SETUP_GEN_AI_BANNER.TITLE,
        actionButtonText: STRING_KEYS.OUTREACH_PAGE.SETUP_GEN_AI_BANNER.BUTTON_TEXT,
        actionButtonColor: THEME.COLORS.ACCENT,
      });
    case OutreachBannerTypeEnum.CampaignOnHoldUserEmailConnection:
      return ({
        color: THEME.COLORS.ALERT,
        title: STRING_KEYS.OUTREACH_PAGE.CAMPAIGN_ON_HOLD.TITLE,
        description: (
          <Trans i18nKey={ STRING_KEYS.OUTREACH_PAGE.CAMPAIGN_ON_HOLD.DESCRIPTION_USER_EMAIL_NOT_CONNECTED }>
            { CampaignOnHoldTransComponent }
          </Trans>
        ),
        actionButtonText: STRING_KEYS.OUTREACH_PAGE.CAMPAIGN_ON_HOLD.BUTTON_TEXT,
      });
    case OutreachBannerTypeEnum.CampaignOnHoldEmailConnection:
      return ({
        color: THEME.COLORS.ALERT,
        title: STRING_KEYS.OUTREACH_PAGE.CAMPAIGN_ON_HOLD.TITLE,
        description: (
          <Trans
            i18nKey={ STRING_KEYS.OUTREACH_PAGE.CAMPAIGN_ON_HOLD.DESCRIPTION_EMAIL_NOT_CONNECTED }
            values={ additionalInfo }
          >
            { CampaignOnHoldTransComponent }
          </Trans>
        ),
      });
    case OutreachBannerTypeEnum.CampaignOnHoldInternalError:
      return ({
        color: THEME.COLORS.ALERT,
        title: STRING_KEYS.OUTREACH_PAGE.CAMPAIGN_ON_HOLD.TITLE,
        description: (
          <Trans i18nKey={ STRING_KEYS.OUTREACH_PAGE.CAMPAIGN_ON_HOLD.DESCRIPTION_INTERNAL_ERROR }>
            { CampaignOnHoldTransComponent }
          </Trans>
        ),
      });
    default:
      return null;
  }
};
