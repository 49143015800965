import { Trans } from 'react-i18next';
import { useShouldLockFeature } from '../../../../../hooks/should-lock-feature.hook';
import STRING_KEYS from '../../../../../language/keys';
import { NetworkedTalentName } from '../mutual-sentences/mutual-sentences.styled';
import { MutualEducationSentenceProps } from './mutual-education-sentence';

export const MutualEducationSentence = ({ institutionName, networkedTalents }: MutualEducationSentenceProps): JSX.Element | null => {
  const isLocked = useShouldLockFeature();

  if (!networkedTalents?.length) {
    return null;
  }

  const translateMultiple = () => {
    if (networkedTalents.length === 1) {
      return null;
    }

    return (
      <Trans i18nKey={ STRING_KEYS.TALENT_NETWORK.TIMELINE.MULTIPLE }>
        <strong />
        {{ talentsCount: networkedTalents.length - 1 }}
      </Trans>
    );
  };

  const translateSingle = () => {
    const networkedTalent = networkedTalents[0];
    let translationKey;

    if (networkedTalent.education.sameClass) {
      translationKey = STRING_KEYS.TALENT_NETWORK.TIMELINE.EDUCATION.SAME_CLASS;
    } else if (networkedTalent.education.sameMajor) {
      translationKey = STRING_KEYS.TALENT_NETWORK.TIMELINE.EDUCATION.SAME_MAJOR;
    } else if (networkedTalent.education.overlapInfo.overlapping) {
      translationKey = STRING_KEYS.TALENT_NETWORK.TIMELINE.EDUCATION.SAME_TIME;
    } else {
      translationKey = STRING_KEYS.TALENT_NETWORK.TIMELINE.EDUCATION.SAME_UNIVERSITY;
    }

    const firstLastNameLetter = networkedTalent?.talent?.lastName?.[0];
    const nameString = networkedTalent?.talent?.firstName + (firstLastNameLetter ? ` ${firstLastNameLetter}.` : '');

    return (
      <Trans i18nKey={ translationKey }>
        <NetworkedTalentName isLocked={ isLocked } />
        {{ name: nameString }}
        {{ institutionName }}
      </Trans>
    );
  };

  return (
    <>
      { translateSingle() }
      { translateMultiple() }
    </>
  );
};
