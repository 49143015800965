import { useTranslation } from 'react-i18next';
import { CONFIG } from '../../../config';
import { ASSETS } from '../../../assets';
import STRING_KEYS from '../../../language/keys';
import { StyledPerfectLogo } from './styled';

const SIZES = {
  SMALL: '28px',
  MEDIUM: '48px',
  LARGE: '64px',
};

type PerfectLogoProps = {
  size?: 'medium' | 'large',
}

export const PerfectLogo = ({ size }: PerfectLogoProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const getLogoSize = () => {
    switch (size) {
      case 'large':
        return SIZES.LARGE;
      case 'medium':
        return SIZES.MEDIUM;
      default:
        return SIZES.SMALL;
    }
  };

  return (
    <StyledPerfectLogo
      alt={ translate(STRING_KEYS.LOGO_ALT, { name: CONFIG.OUR_NAME }) }
      src={ ASSETS.PERFECT_LOGO }
      height={ getLogoSize() }
    />
  );
};

PerfectLogo.defaultProps = {
  size: undefined,
};
