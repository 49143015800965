import styled from 'styled-components';
import { ASSETS } from '../../../../../../../../../assets';
import { THEME } from '../../../../../../../../../themes';
import { Body2Font, CaptionFont } from '../../../../../../../../../themes/fonts';
import { TextUnderlineStyle } from '../explainability-widget-attributes.styled';

export const VerifiedIcon = styled(ASSETS.CHECK_STAR)`
  fill: ${THEME.COLORS.SUCCESS};
  width: 12px;
  margin-left: ${THEME.SPACING.NANO};
  margin-right: ${THEME.SPACING.TINY};
`;

export const Container = styled.div`
  width: fit-content;
  ${TextUnderlineStyle};
  cursor: pointer;
`;

export const ExactAttributeName = styled.span`
  ${Body2Font};
  font-weight: 600;
`;

export const OriginSourceText = styled.span`
  ${CaptionFont};
  opacity: ${THEME.OPACITY.MED};
  
  strong {
    font-weight: 600;
  }
`;
