import styled from 'styled-components';
import { ASSETS } from '../../../../assets';
import { THEME } from '../../../../themes';
import { Body2Font, Subtitle1Font, Title3Font } from '../../../../themes/fonts';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { fadeInAnimation } from '../../../../animations';
import PreferencesPageContentComponent from '../../components/outreach/preferences-page-content/preferences-page-content.component';

export const ConversationsEmptyPage = styled(PreferencesPageContentComponent)`
  justify-content: center;
  align-items: center;
`;

export const AnimatedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${THEME.SPACING.MEDIUM};
  animation: ${fadeInAnimation({
    animationDuration: 500,
    animationDelay: 0,
  })};
`;

export const OutreachIcon = styled(ASSETS.OUTREACH_ICON)`
  width: 112px;
  height: 64px;
  object-fit: contain;
  fill: ${THEME.COLORS.ACCENT};
`;

export const OutreachTitle = styled.div`
  ${Title3Font};
  font-weight: 400;
  line-height: 1.17;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${THEME.COLORS.CONTENT_A};
  & :last-child {
    font-weight: 600;
  }
`;

export const OutreachDescription = styled.div`
  ${Subtitle1Font};
  width: 512px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
  font-weight: normal;
  line-height: 1.25;
`;

export const ConnectEmailButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ConnectEmailButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.ACCENT};
  width: 240px;
`;

export const ConnectEmailInfoText = styled.div`
  ${Body2Font};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
  margin-top: ${THEME.SPACING.TINO};
`;
