import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import {
  SourceList,
  VerifiedIcon,
} from '../../explainability-info-menu.styled';
import STRING_KEYS from '../../../../../../language/keys';
import { ExplainabilityOrigin } from '../../../../../../store/rtk-query/apis/match/dto/response/match.response';
import ExplainabilityOriginSentence
  from '../../../explainability-origin-sentence/explainability-origin-sentence.component';
import BaseSection from '../base-section/base-section.component';
import { VerificationSourceSectionProps } from './verification-source-section';

const VerificationSourceSection = ({ type, value, origins }: VerificationSourceSectionProps): JSX.Element | null => {
  const { t: translate } = useTranslation();

  const text = useMemo(() => {
    const originMapByCompanyName = new Map<string, ExplainabilityOrigin>();
    origins?.forEach((origin) => originMapByCompanyName.set(origin.companyName, origin));
    const uniqueOrigins = Array.from(originMapByCompanyName.values());

    return uniqueOrigins?.map((origin) => {
      return (
        <div key={ `exact_attribute_verification_source_${origin.source}` }>
          <ExplainabilityOriginSentence
            type={ type }
            origin={ origin }
            value={ value }
          />
        </div>
      );
    }).filter(Boolean);
  }, [origins, type, value]);

  if (!text?.length) {
    return null;
  }

  return (
    <BaseSection
      title={ translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY.INFO_MENU.VERIFICATION_SOURCE_SECTION_TITLE) }
      Icon={ <VerifiedIcon /> }
    >
      <SourceList>
        { text }
      </SourceList>
    </BaseSection>
  );
};

export default VerificationSourceSection;
