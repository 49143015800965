import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { drawerElevation } from '../../../../themes/elevations';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';

export const EngagementFeedbackContainer = styled.div`
  background-color: ${THEME.COLORS.PURE_WHITE};
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.ROUNDISH} ${THEME.RADIUS.ROUNDISH} ${THEME.RADIUS.ROUNDISH} 0;
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
  ${drawerElevation};
`;

export const UndoButton = styled(BaseButton)`
  color: ${THEME.COLORS.ACCENT};
  background-color: transparent;
  padding: 0;
  height: fit-content;
`;
