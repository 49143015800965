import { useCallback, useEffect, useState } from 'react';
import { THEME } from '../../../../themes';
import { CONFIG } from '../../../../config';
import {
  CircularContainer, CloseContainer, CustomDismissButton, CloseIcon,
} from './styled';

type DismissButtonProps = {
  color: string | undefined,
  backgroundColor: string | undefined,
  onClick: () => void,
  onCounterDone: () => void,
  counterEnabled: boolean | undefined,
  counter: number | undefined,
}
const numberOfSteps = 10;
const intervaleCounter = 1000 / numberOfSteps;
const addValue = 100 / (CONFIG.SNACKBAR_AUTO_DISMISS_DURATION / 1000) / numberOfSteps;
const SNACK_BAR_IMAGE_SIZE = '14px';

export const DismissButton = ({
  color,
  backgroundColor,
  onClick,
  onCounterDone,
  counter,
  counterEnabled,
}: DismissButtonProps):JSX.Element => {
  const [value, setValue] = useState(0);
  const resetValue = () => {
    setValue(0);
  };

  const handleClose = useCallback(() => {
    onClick();
    resetValue();
  }, [onClick]);

  useEffect(() => {
    if (typeof counter === 'number' && value >= 100) {
      onCounterDone();
      resetValue();
    }
  }, [value, onCounterDone, counter]);

  useEffect(() => {
    if (counterEnabled && typeof counter === 'number') {
      const timer = setInterval(() => {
        setValue((prevProgress) => {
          return prevProgress + addValue;
        });
      }, intervaleCounter);

      return () => {
        clearInterval(timer);
      };
    }

    return undefined;
  }, [counter, counterEnabled]);

  return (
    <CustomDismissButton
      color={ color }
      backgroundColor={ backgroundColor }
      onClick={ handleClose }
    >
      <CircularContainer
        variant="determinate"
        value={ value }
        color="secondary"
        size={ 32 }
      />
      <CloseContainer>
        <CloseIcon
          color={ THEME.COLORS.ACCENT }
          size={ SNACK_BAR_IMAGE_SIZE }
        />
      </CloseContainer>
    </CustomDismissButton>
  );
};
