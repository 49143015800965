import { PositionAssistantTaskEnum } from '../../../../enums/position-assistant-task.enum';
import ActivatedAutomatedOutreachAssistant from './activate-automated-outreach/activate-automated-outreach.component';
import BuildStrongerCandidatePipeline
  from './build-stronger-candidate-pipeline/build-stronger-candidate-pipeline.component';
import ImproveOutreachEngagement from './improve-outreach-engagement/improve-outreach-engagement.component';
import AtsIntegrationAssistant from './ats-integration/ats-integration.component';
import PromptFollowUp from './prompt-follow-up/prompt-follow-up.component';
import EncourageTimelyReplies from './encourage-timely-replies/encourage-timely-replies.component';
import OutreachToLikeMatches from './outreach-to-like-matches/outreach-to-like-matches.component';

export const ASSISTANT_TASKS_MAP = {
  [PositionAssistantTaskEnum.ActivateAutomatedOutreach]: ActivatedAutomatedOutreachAssistant,
  [PositionAssistantTaskEnum.BuildStrongCandidatePipeline]: BuildStrongerCandidatePipeline,
  [PositionAssistantTaskEnum.ImproveOutreachEngagement]: ImproveOutreachEngagement,
  [PositionAssistantTaskEnum.ATSIntegration]: AtsIntegrationAssistant,
  [PositionAssistantTaskEnum.PromptFollowup]: PromptFollowUp,
  [PositionAssistantTaskEnum.EncourageTimelyReplies]: EncourageTimelyReplies,
  [PositionAssistantTaskEnum.OutreachToLikedMatches]: OutreachToLikeMatches,
};
