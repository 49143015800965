import { useTranslation } from 'react-i18next';
import { Avatar as MuiAvatar } from '@mui/material';
import { THEME } from '../../../themes';
import STRING_KEYS from '../../../language/keys';
import { hexToRgbA } from '../../../utils/colors';
import {
  IconFallback,
  Container,
} from './avatar-with-fallback.styled';

export const AvatarWithFallback = ({
  logo,
  fallbackImage,
  title,
  size,
  variant,
}: AvatarWithFallbackProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <MuiAvatar
        variant={ variant }
        src={ logo }
        alt={ translate(
          STRING_KEYS.LOGO_ALT,
          { name: translate(title) },
        ) }
        sx={ {
          height: `${size}px`,
          width: `${size}px`,
          backgroundColor: 'unset',
          borderRadius: !variant ? THEME.RADIUS.TIDY : undefined,
          border: `1px solid ${THEME.COLORS.PURE_WHITE}`,
          outline: `3px solid ${hexToRgbA(THEME.COLORS.PRIMARY, THEME.OPACITY.DIVIDER)}`,
          boxSizing: 'content-box',
        } }
      >
        <IconFallback fallbackImage={ fallbackImage } />
      </MuiAvatar>
    </Container>
  );
};

AvatarWithFallback.defaultProps = {
  logo: undefined,
};
