import { userQuery } from '../user.toolkit-api';
import { UserResponse } from '../dto/response/invited-users.response';

export const useGetAccountUsersQuery = (skip = false): AppSelectorType<UserResponse[], string> => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch,
  } = userQuery.useGetAccountUsersQuery(
    undefined,
    {
      skip,
    },
  );

  return {
    data: data || [] as UserResponse[],
    isLoading,
    isError,
    isSuccess,
    error: error as string,
    refetch,
  };
};
