import Tooltip from '../../../../components/tooltip/tooltip.component';
import { TitleWithTooltipProps } from './title-with-tooltip';
import { Container } from './title-with-tooltip.styled';

const TitleWithTooltip = ({ children, tooltipTitle, ...otherProps }: TitleWithTooltipProps): JSX.Element => {
  return (
    <Tooltip
      content={ tooltipTitle }
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Container { ...otherProps } >
        { children }
      </Container>
    </Tooltip>
  );
};
export default TitleWithTooltip;
