import { useTranslation } from 'react-i18next';
import { PerfectLogo } from '../PerfectLogo';
import STRING_KEYS from '../../../language/keys';
import {
  LogoWrapper,
  MessageSection,
  MessageWrapper,
  Text1,
  Text2,
  Text3,
} from './styled';

export const MobileMessage = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <MessageWrapper>
      <MessageSection>
        <LogoWrapper>
          <PerfectLogo size="medium" />
        </LogoWrapper>
        <Text1>
          { translate(STRING_KEYS.MOBILE_MESSAGE_NOT_US) }
        </Text1>
        <Text2 >
          { translate(STRING_KEYS.MOBILE_MESSAGE_NOT_SUPPORTED) }
        </Text2>
        <Text3>
          { translate(STRING_KEYS.MOBILE_MESSAGE_SUGGESTION) }
        </Text3>
      </MessageSection>
    </MessageWrapper>
  );
};
