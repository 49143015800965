import Dialog from '../../base-dialog/base-dialog.component';
import { DialogWidthSize } from '../../base-dialog/base-dialog.enums';
import { TimeToPayDialogProps } from './time-to-pay-dialog';
import TimeToPayDialogContainer from './time-to-pay-dialog-container.component';

const TimeToPayDialog = ({
  isOpen,
  onClose,
  children,
  title,
  subTitle,
  footerTitle,
  footerSubTitle,
  buttons,
}: TimeToPayDialogProps): JSX.Element => {
  return (
    <Dialog
      isOpen={ isOpen }
      onClose={ onClose }
      widthSize={ DialogWidthSize.XLarge }
    >
      <TimeToPayDialogContainer
        title={ title }
        subTitle={ subTitle }
        footerTitle={ footerTitle }
        footerSubTitle={ footerSubTitle }
        buttons={ buttons }
      >
        { children }
      </TimeToPayDialogContainer>
    </Dialog>
  );
};

export default TimeToPayDialog;
