import styled from 'styled-components';
import { THEME } from '../../../themes';
import { Body2Font } from '../../../themes/fonts';
import { hexToRgbA } from '../../../utils/colors';
import { PerfectInputSize } from './perfect-base-input.enums';

const inputHeightMap: {[key: string]: string} = {
  [PerfectInputSize.Small]: '32px',
  [PerfectInputSize.Medium]: '40px',
  [PerfectInputSize.Large]: '48px',
};

export const InputContainer = styled.div<{ size: PerfectInputSize, disabled: boolean, fullWidth: boolean }>`
  height: ${({ size }) => inputHeightMap[size]};
  width: ${({ fullWidth }) => fullWidth ? '100%' : '25ch'};
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
  padding-top: ${THEME.SPACING.MICRO};
  padding-bottom: ${THEME.SPACING.MICRO};
  padding-left: ${THEME.SPACING.BASE};
  padding-right: ${THEME.SPACING.TINY};
  border-radius: 20px;
  border: solid 1px ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.GHOST)};
  background-color: ${THEME.COLORS.PURE_WHITE};
  opacity: ${({ disabled }) => disabled ? THEME.OPACITY.LOW : THEME.OPACITY.HIGHEST};
  :focus-within {
    border: solid 1px ${THEME.COLORS.ACCENT};
  }
`;

export const AdornmentContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 90;
`;

export const Input = styled.input`
  ${Body2Font};
  border: none;
  width: 100%;
  color: ${THEME.COLORS.CONTENT_A};
  :focus {
    outline :none;
  }
  &::placeholder {
    opacity: ${THEME.OPACITY.LOW};
  }
`;
