/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export type Filters = {
  [key: string]: string,
};

type PageFiltersParams = {
  filters: Filters,
  getFiltersByKey: (key: string) => string[],
  setFilterByKey: (key: string, value: string, multiple?: boolean) => void,
  isFilterExists: (key: string, value: string) => boolean,
  deleteFiltersByKey: (key: string | string[], value?: string) => void,
};

const SEPARATOR = ',';

export const usePageFilters = (): PageFiltersParams => {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const setFilterByKey = useCallback((name: string, value: string, multiple?: boolean) => {
    if (searchParams.has(name) && multiple) {
      const params = searchParams.get(name);
      const listOfParams = params?.split(SEPARATOR) || [];
      if (!listOfParams.includes(value)) {
        searchParams.set(name, `${params},${value}`);
      }
    } else {
      searchParams.set(name, value);
    }
    history.push({ search: searchParams.toString() });
  }, [searchParams]);

  const isFilterExists = useCallback((name: string, value: string) => {
    if (!searchParams.has(name)) {
      return false;
    }
    const params = searchParams.get(name);
    const listOfParams = params?.split(SEPARATOR) || [];

    return listOfParams.includes(value);
  }, [searchParams]);

  const getFiltersByKey = useCallback((name: string): string[] => {
    const params = searchParams.get(name);
    if (!params) {
      return [];
    }

    return params.split(SEPARATOR);
  }, [searchParams]);

  const deleteFiltersByKey = useCallback((names: string | string[], value?: string) => {
    if (Array.isArray(names)) {
      names.forEach((name) => searchParams.delete(name));
      history.push({ search: searchParams.toString() });

      return;
    }
    if (value) {
      const params = searchParams.get(names);
      const listOfParams = params?.split(SEPARATOR) || [];
      const filteredSearchValue = listOfParams.filter((filterValue) => filterValue !== value);
      if (filteredSearchValue.length) {
        searchParams.set(names, filteredSearchValue.join(SEPARATOR));
        history.push({ search: searchParams.toString() });

        return;
      }
    }

    searchParams.delete(names);
    history.push({ search: searchParams.toString() });
  }, [searchParams]);

  return {
    filters: useMemo(() => Object.fromEntries(searchParams), [searchParams]),
    getFiltersByKey,
    setFilterByKey,
    isFilterExists,
    deleteFiltersByKey,
  };
};
