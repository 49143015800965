import { createContext } from 'react';
import { CompositeDecorator, EditorState } from 'draft-js';
import { newEntityLocationStrategy } from '../perfect-text-editor.utils';
import { DECORATORS_NAMES } from '../decorators/decorators.consts';
import { Mention } from '../decorators/mention-decorator/mention/mention.component';
import { MentionPlaceholder } from '../decorators/mention-decorator/mention-placeholder/mention-placeholder.component';
import { TextEditorContext, TextEditorState } from './text-editor';

export const decorators = new CompositeDecorator([
  {
    strategy: newEntityLocationStrategy(DECORATORS_NAMES.MENTION),
    component: Mention,
  },
  {
    strategy: newEntityLocationStrategy(DECORATORS_NAMES.MENTION_PLACEHOLDER),
    component: MentionPlaceholder,
  },
]);

export const initialState: TextEditorState = {
  editorBodyState: EditorState.createEmpty(decorators),
  editorSubjectState: EditorState.createEmpty(decorators),
  showSubject: true,
  touched: false,
  showSubjectEditor: false,
  editorInFocus: false,
  subjectEditorInFocus: false,
  showTextEditorHeader: false,
  subjectReadOnly: false,
};

export default createContext<TextEditorContext>({ state: {}, actions: {} } as TextEditorContext);
