import { useTranslation } from 'react-i18next';
import {
  getHostnameAndDomainFromWebsite,
  extractYearFromDate,
} from '../../../../utils';
import { ASSETS } from '../../../../assets';
import STRING_KEYS from '../../../../language/keys';
import {
  CompanyParametersWrapper,
  CompanyParameterLabel,
  StyledLink,
  CompanyIpoStatusLabel,
} from './styled';

type CompanyPropertiesProps = {
  size: string | undefined,
  foundedAt: string | undefined,
  website: string | undefined,
  linkedinUrl: string | undefined,
  ipoStatus: string | undefined,
  lastFundingType: string | undefined,
  name: string,
}

function CompanyProperties({
  size,
  foundedAt,
  website,
  linkedinUrl,
  name,
  ipoStatus,
  lastFundingType,
} : CompanyPropertiesProps): JSX.Element {
  const { t: translate } = useTranslation();

  return (
    <CompanyParametersWrapper>
      {size && (
        <CompanyParameterLabel
          labelIcon={ ASSETS.PEOPLE }
          title={ size }
        >
          { size }
        </CompanyParameterLabel>
      )}

      {foundedAt && (
        <CompanyParameterLabel
          labelIcon={ ASSETS.LEAF }
          title={ foundedAt }
        >
          {
            translate(STRING_KEYS.FROM_DATE, { date: extractYearFromDate(foundedAt) })
          }
        </CompanyParameterLabel>
      )}

      {lastFundingType && (
        <CompanyParameterLabel
          labelIcon={ ASSETS.DOLLAR_CIRCLE }
          title={ lastFundingType }
        >
          { lastFundingType }
        </CompanyParameterLabel>
      )}

      {ipoStatus && (
        <CompanyIpoStatusLabel
          labelIcon={ ASSETS.FLAG }
          title={ ipoStatus }
        >
          { ipoStatus }
        </CompanyIpoStatusLabel>
      )}

      {website && (
        <CompanyParameterLabel
          labelIcon={ ASSETS.LINK }
        >
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            href={ website }
            title={ website }
          >
            { getHostnameAndDomainFromWebsite(website) }
          </StyledLink>

        </CompanyParameterLabel>
      )}

      {linkedinUrl && (
        <CompanyParameterLabel
          labelIcon={ ASSETS.LINKEDIN }
        >
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            href={ linkedinUrl }
            title={ name }
          >
            { name }
          </StyledLink>
        </CompanyParameterLabel>
      )}
    </CompanyParametersWrapper>
  );
}

export default CompanyProperties;
