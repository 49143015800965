import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { AppState } from '../app-state.initial-state';
import { removeFromArray } from '../../../../utils';
import { getSubDomain } from '../../../../utils/url';
import { CONFIG } from '../../../../config';
import { INITIAL_LOADING } from '../app-state.consts';
import { SubDomainType } from '../app-state.enums';

export const setVerifiedUserSubdomainTypeReducer: CaseReducer<AppState> = (state) => {
  state.subDomainType = SubDomainType.Account;
};

export const setSubdomainTypeReducer: CaseReducer<AppState, PayloadAction<SubDomainType>> = (state, { payload }) => {
  state.subDomainType = payload;
  state.initialLoadings = removeFromArray(state.initialLoadings, INITIAL_LOADING.PREPARE_SUB_DOMAIN);
};

export const prepareSubDomainReducer: CaseReducer<AppState> = (state) => {
  const currentSubDomain = getSubDomain(window.location.hostname);
  let subDomainType: SubDomainType;
  switch (currentSubDomain) {
    case CONFIG.GLOBAL_LOGIN_SUBDOMAIN: {
      subDomainType = SubDomainType.GlobalLogin;
      break;
    }
    case CONFIG.GLOBAL_ACTIVATION_SUBDOMAIN: {
      subDomainType = SubDomainType.GlobalActivation;
      break;
    }
    case CONFIG.ONBOARDING_SUBDOMAIN: {
      subDomainType = SubDomainType.Onboarding;
      break;
    }
    case CONFIG.GOOGLE_EMAIL_OAUTH: {
      subDomainType = SubDomainType.GoogleEmailOAuth;
      break;
    }
    case null: {
      subDomainType = SubDomainType.None;
      break;
    }
    default: {
      subDomainType = SubDomainType.Account;
    }
  }
  state.subDomainType = subDomainType;
  state.initialLoadings = removeFromArray(state.initialLoadings, INITIAL_LOADING.PREPARE_SUB_DOMAIN);
};
