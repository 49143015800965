import { SmartWidgetItemSkeleton } from './smart-widget-item-skeleton/smart-widget-item-skeleton.component';

export const SmartWidgetsSkeleton = ():JSX.Element => {
  return (
    <>
      <SmartWidgetItemSkeleton
        wrapperHeight="315px"
        contentHeight="171px"
      />
      <SmartWidgetItemSkeleton
        wrapperHeight="137px"
        contentHeight="1px"
      />
      <SmartWidgetItemSkeleton
        wrapperHeight="315px"
        contentHeight="171px"
      />
      <SmartWidgetItemSkeleton
        wrapperHeight="137px"
        contentHeight="1px"
      />
    </>
  );
};
