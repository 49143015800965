export const LOADER_DURATION_MS = 60 * 1000 * 3;
export const LOADER_INTERVAL_TIME_MS = 1000;
export const INITIAL_TITLE = '5:00';
export const INITIAL_STATIC_TITLE = '1-3';
export const END_TITLE = '00:00';
export const STILL_WORKING_TITLE = '...';
export const COUNTER_TIME_FORMAT = 'mm:ss';
const MINUTE = 60 * 1000;

export const STEP_DESCRIPTION_TIMES_MS = {
  STEP1: MINUTE * 3,
  STEP2: MINUTE * 2.5,
  STEP3: MINUTE * 2.0,
  STEP4: MINUTE * 1.5,
  STEP5: MINUTE * 0.93,
  STEP6: 0,
  STEP7: -MINUTE * 15,
};

export enum PROGRESS_STEP {
  RUNNING,
  EXTENDED_WAIT_TIME,
  ERROR_OR_TOO_LONG,
}
