import { BrowserRouter } from 'react-router-dom';
import { FronteggProvider } from '@frontegg/react';
import { useDispatch } from 'react-redux';
import { useAppState } from '../../store/selectors';
import { SplashScreen } from '../SplashScreen';
import MainRouter from '../routers/main.router';
import { AnalyticsProvider } from '../../contexts/analytics';
import { deleteSnackbar } from '../../store/slices/app-state/app-state.toolkit-slice';
import { AppSnackbar } from '../Common/CustomSnackBar/app-snackbar/app-snackbar.component';
import useFlashingFavicon from '../../modules/application/hooks/flashing-favicon';
import { GlobalStyle } from './styled';

const contextOptions = {
  baseUrl: process.env.REACT_APP_FRONT_EGG_BASE_URL || '',
};

export const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    showSplashScreen,
    snackbar,
  } = useAppState();
  useFlashingFavicon();

  return (
    <>
      <GlobalStyle />
      { showSplashScreen && <SplashScreen /> }
      <FronteggProvider customLoginBox contextOptions={ contextOptions }>
        <AnalyticsProvider>
          <BrowserRouter>
            <MainRouter />
            { snackbar && (
              <AppSnackbar
                title={ snackbar.title }
                description={ snackbar.description }
                isOpen={ !!snackbar }
                shouldAutoDismiss
                onClose={ () => dispatch(deleteSnackbar()) }
                anchor={ snackbar.anchor }
                variant={ snackbar.variant }
              />
            ) }
          </BrowserRouter>
        </AnalyticsProvider>
      </FronteggProvider>
    </>
  );
};
