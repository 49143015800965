import { useAuth } from '@frontegg/react';

export const useSkipSuperUser = (callback: () => void): (() => void) | undefined => {
  const { user } = useAuth();
  const isSuperUser = !!user?.superUser;
  if (!isSuperUser) {
    return callback;
  }

  return undefined;
};

export const useIsSuperUser = (): boolean => {
  const { user } = useAuth();

  return !!user?.superUser;
};
