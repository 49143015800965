export enum LocationMenuItemSize {
  Small = 'SMALL',
  Large = 'LARGE',
}

export enum LocationMenuItemType {
  Area = 'AREA',
  City = 'CITY',
  State = 'STATE',
}

export enum LocationMenuItemDirection {
  Row = 'ROW',
  Column = 'COLUMN',
}
