import styled from 'styled-components';
import { hexToRgbA } from '../../../../utils/colors';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';

export const Container = styled.div<{ backgroundColor?: string }>`
  height: 40px;
  align-self: center;
  background-color: ${({ backgroundColor }) => hexToRgbA(backgroundColor || THEME.COLORS.WARNING, THEME.OPACITY.GHOST)};
  color: ${THEME.COLORS.CONTENT_A};
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
`;

export const AtsIndicationIcon = styled(ASSETS.DUPLICATE)`
  width: 24px;
  color: ${THEME.COLORS.CONTENT_A};
  flex-shrink: 0;
`;
