export const ELEMENTS_DURATION = {
  WELCOME_SCREEN: 200,
  WELCOME_SCREEN_SENTENCE_DURATION: 500,
  SCREEN_ANIMATED_DURATION: 500,
  LETS_BEGIN_TITLE_DURATION: 500,
  PAGE_TITLE_DURATION: 500,
  LETS_BEGIN_CONTENT_DURATION: 500,
  TELL_US_MORE_TITLE_DURATION: 500,
  TELL_US_MORE_CONTENT_DURATION: 500,
  JOB_DESCRIPTION_TITLE_DURATION: 500,
  JOB_DESCRIPTION_SUBTITLE_DURATION: 500,
  JOB_DESCRIPTION_CONTENT_DURATION: 500,
  JOB_DESCRIPTION_CONTINUE_BUTTON_DURATION: 500,
  VERIFICATION_SCREEN_TITLE_DURATION: 500,
  VERIFICATION_SCREEN_CONTENT_DURATION: 500,
};
