/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, Ref } from 'react';
import {
  Container, ScrollContainer,
} from './preferences-page-content.styled';

const PreferencesPageContentComponent = forwardRef((
  props: PreferencesPageContentProps,
  ref: Ref<HTMLDivElement>,
) => {
  const {
    children, ...otherProps
  } = props;

  return (
    <Container>
      <ScrollContainer
        ref={ ref }
        { ...otherProps }
      >
        { children }
      </ScrollContainer>
    </Container>
  );
});

PreferencesPageContentComponent.displayName = 'ProgressButton';

export default PreferencesPageContentComponent;
