import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import log from 'loglevel';
import { HTTP_CODES } from '../../consts/api';
import { logout } from '../slices/app-state/app-state.toolkit-slice';

const logger = log.getLogger('RTK_QUERY_ERROR_MIDDLEWARE');

export const errorMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (action.payload?.isApiError && action.payload?.error?.serverError?.statusCode === HTTP_CODES.UNAUTHORIZED) {
        logger.debug('detected an "unauthorized" response from server. sending logout action');
        api.dispatch(logout());
      }
    }

    return next(action);
  };
