import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';
import { USER_AVATAR_SIZE_PX } from '../../pages/home/home.styled';
import { CaptionFont, Title3Font } from '../../../../themes/fonts';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { hexToRgbA } from '../../../../utils/colors';

export const Container = styled.div`
  display: flex;
  margin-top: ${THEME.SPACING.LOOSE};
  margin-bottom: ${THEME.SPACING.XLOOSE};
  justify-content: space-between;
  align-items: center;
`;

export const AddUserIconWrapper = styled.div`
  background-color: ${THEME.COLORS.PRIMARY_DARK};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${USER_AVATAR_SIZE_PX}px;
  height: ${USER_AVATAR_SIZE_PX}px;
  border-radius: ${THEME.RADIUS.CIRCLE};
`;

export const AddUserIcon = styled(ASSETS.ADD_USER)`
  width: 20px;
  fill: ${THEME.COLORS.PURE_WHITE};
`;

export const BodyHeaderRightContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.BASE};
`;

export const BodyTitle = styled.div`
  ${Title3Font};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const CreatePositionButton = styled(BaseButton)`
  align-self: end;
  background-color: ${THEME.COLORS.ACCENT};
`;

export const CreatePositionButtonIcon = styled(ASSETS.FILLED_PLUS)`
  fill: ${THEME.COLORS.PURE_WHITE};
  height: 24px;
  width: 24px;
`;

export const UsersAvatarListContainer = styled.div`
  min-width: 160px;
`;

export const UsersAvatarListWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
  cursor: pointer;
`;

export const UsersAvatarListLabel = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
  margin-bottom: ${THEME.SPACING.NANO};
`;

export const VerticalDivider = styled.div`
  width: 1px;
  background-color: ${hexToRgbA(THEME.COLORS.CONTENT_PRIMARY, THEME.OPACITY.GHOST)};
`;

export const ActivePositionsWidgetWrapper = styled.div`
  align-self: end;
`;
