import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { Title3Font } from '../../../../../themes/fonts';
import { ASSETS } from '../../../../../assets';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
  max-width: 960px;
`;

export const SectionIcon = styled(ASSETS.SKELETON_WITH_STAR)``;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleStartWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.BASE};
`;

export const Title = styled.div`
  ${Title3Font};
  font-weight: 500;
  width: fit-content;
`;

export const IconWrapper = styled.div`
  display: flex;

  svg {
    width: 32px;
    height: 32px;
  }
`;
