import { MouseEvent, useCallback } from 'react';
import { IconButtonVariants } from '../../../Buttons/icon-button/icon-button.enums';
import { PerfectButtonHoverVariants } from '../../../Buttons/perfect-base-button/perfect-base-button.enums';
import {
  Button, InlineStyleContainer,
} from './text-alignment-controls.styled';
import { BaseTextEditorControlsProps, ButtonsInlineStyles } from './base-text-editor-controls';

const BaseTextEditorControls = (
  { buttonsInlineStyles, currentInlineStyle, toggleHandler }: BaseTextEditorControlsProps & { buttonsInlineStyles: ButtonsInlineStyles[] },
): JSX.Element => {
  const onMouseDown = useCallback((e: MouseEvent<HTMLButtonElement>, style: string) => {
    e.preventDefault();
    toggleHandler(style);
  }, [toggleHandler]);

  const renderButtons = () => (
    buttonsInlineStyles.map((type) => {
      return (
        <Button
          active={ currentInlineStyle.has(type.style) }
          type="button"
          key={ type.style }
          hoverVariant={ PerfectButtonHoverVariants.Darkened }
          onMouseDown={ (e: MouseEvent<HTMLButtonElement>) => onMouseDown(e, type.style) }
          variant={ IconButtonVariants.Ghost }
          StartIcon={ type.icon }
        />
      );
    })
  );

  return (
    <InlineStyleContainer>
      {renderButtons()}
    </InlineStyleContainer>
  );
};

export default BaseTextEditorControls;
