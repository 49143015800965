import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { shakeAnimation } from '../../../../animations';
import { OverlineFont } from '../../../../themes/fonts';

export const Wrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 54px;
  display: flex;
  gap: ${THEME.SPACING.MICRO};
  animation: ${shakeAnimation({
    animationDuration: 200,
    animationDelay: 0,
  })
};
`;

export const ErrorIcon = styled.div`
  font-size: 16px;
  color: ${THEME.COLORS.PURE_WHITE};
  background-color: ${THEME.COLORS.ALERT};
  border-radius: ${THEME.RADIUS.TIDY};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
`;

export const ErrorContent = styled.div`
  ${OverlineFont};
  font-weight: bold;
  color: ${THEME.COLORS.ALERT};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  height: 18px;
  text-transform: uppercase;
`;
