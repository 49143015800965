import { forwardRef, Ref } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import IconButton from '../../Buttons/icon-button/icon-button.component';
import { IconButtonVariants } from '../../Buttons/icon-button/icon-button.enums';
import { PerfectButtonHoverVariants } from '../../Buttons/perfect-base-button/perfect-base-button.enums';
import { CloseIcon, HeaderContainer } from './side-panel-header.styled';

const SidePanelHeader = forwardRef((
  props: TransitionProps & SidePanelHeaderProps,
  ref: Ref<HTMLDivElement>,
) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <HeaderContainer { ...props } ref={ ref } >
      { props.children }
      { props.closeButtonClickHandler && (
        <IconButton
          onClick={ props.closeButtonClickHandler }
          StartIcon={ CloseIcon }
          variant={ IconButtonVariants.Ghost }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
        />
      )}
    </HeaderContainer>
  );
});

SidePanelHeader.displayName = 'SidePanelHeader';

export default SidePanelHeader;
