import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { CaptionFont } from '../../../../../../themes/fonts';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const CheckboxTitle = styled.div`
  font-weight: bold;
`;

export const CheckboxDescription = styled.div`
  opacity: ${THEME.OPACITY.MED};
`;
