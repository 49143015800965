import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../../../../../language/keys';
import ExplainabilityWidgetAttributes from '../explainability-widget-attributes.component';
import { ExplainabilityTypeEnum } from '../../../../../../../../../enums/explainability-type.enum';
import { Icon } from './explainability-widget-company-background.styled';
import { ExplainabilityWidgetCompanyBackgroundProps } from './explainability-widget-company-background';

const ExplainabilityWidgetCompanyBackground = ({ explainability }: ExplainabilityWidgetCompanyBackgroundProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <ExplainabilityWidgetAttributes
      label={ translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY_WIDGET.ITEMS_LABELS.COMPANY_BACKGROUND) }
      exactAttributes={ explainability?.companyInsights }
      similarAttributes={ explainability?.similarCompanyInsights }
      Icon={ Icon }
      explainabilityType={ ExplainabilityTypeEnum.Industry }
    />
  );
};

export default ExplainabilityWidgetCompanyBackground;
