import { makeStyles } from '@material-ui/core/styles';
import { AvatarGroup } from '@mui/material';
import styled from 'styled-components';
import { THEME } from '../../../themes';

export const useStyles = makeStyles(() => ({
  avatar: {
    border: `solid 4px ${THEME.COLORS.LIGHT_GREY} !important`,
    backgroundColor: THEME.COLORS.PRIMARY,
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    textAlign: 'left',
    color: THEME.COLORS.PURE_WHITE,
  },
}));

export const Icons = styled(AvatarGroup)`
  margin-right: ${THEME.SPACING.BASE};
`;
