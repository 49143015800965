import { useSelector } from 'react-redux';
import { RootReducerState } from '../../../index';
import { CreatePositionLetsBeginSelector } from '../../../../modules/create-position/@types/create-position-selectors';

export const useOnboardingProgress = (): OnboardingProgressSelector => {
  return useSelector((state: RootReducerState) => {
    return {
      id: state.onboardingReducer.id,
      userId: state.onboardingReducer.userId,
      status: state.onboardingReducer.status,
      isOnboardingLoading: state.onboardingReducer.isOnboardingLoading,
      onboardingLoadingError: state.onboardingReducer.onboardingLoadingError,
      showOnboardingSplashScreen: state.onboardingReducer.showOnboardingSplashScreen,
      step: state.onboardingReducer.step || '',
    };
  });
};

export const useOnboardingLetsBegin = (): CreatePositionLetsBeginSelector => {
  return useSelector((state: RootReducerState) => {
    return {
      userRole: state.onboardingReducer.roleName,
      userName: state.onboardingReducer.userName,
      promo: state.onboardingReducer.promo,
      companyLinkedinUrl: state.onboardingReducer.selectedCompany.linkedInUrl,
      jobTitleName: state.createPosition.selectedJobTitle.name,
      locations: state.createPosition.selectedLocations,
      remotePosition: state.createPosition.isRemote,
    };
  });
};

export const useOnboardingCompanyName = (): {companyName: string} => {
  return useSelector((state: RootReducerState) => {
    return {
      companyName: state.onboardingReducer.selectedCompany.companyName,
    };
  });
};

export const useOnboardingStoreMetadataSelector = (): OnboardingStoreMetadataSelector => {
  return useSelector((state: RootReducerState) => {
    return {
      showButtonLoader: state.onboardingReducer.showButtonLoaderShow,
      showSkipButton: state.onboardingReducer.showSkipButtonShow,
      isContinueButtonEnabled: state.onboardingReducer.isContinueButtonEnabled,
      showContinueButton: state.onboardingReducer.showContinueButtonShow,
      isOnboardingLoading: state.onboardingReducer.isOnboardingLoading,
      onboardingLoadingError: state.onboardingReducer.onboardingLoadingError,
      showOnboardingSplashScreen: state.onboardingReducer.showOnboardingSplashScreen,
    };
  });
};
