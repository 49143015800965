import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import {
  ExplainabilitySimilarAttribute,
} from '../../../../../../../../../store/rtk-query/apis/match/dto/response/match.response';
import STRING_KEYS from '../../../../../../../../../language/keys';
import SimilarAttributeInfoMenu
  from '../../../../../../explainability-info-menu/similar-attribute-info-menu/similar-attribute-info-menu.component';
import SimilarAttributeExplainabilityText
  from '../similar-attribute-explainability-text/similar-attribute-explainability-text.component';
import { ExplainabilityTypeEnum } from '../../../../../../../../../enums/explainability-type.enum';
import {
  OriginalAttribute,
  RightArrow,
  SimilarAttribute,
  SimilarAttributeRow,
  SimilarAttributesOriginsList,
  TagsContainer,
} from './similar-attributes-container.styled';
import { SimilarAttributesContainerProps } from './similar-attributes-container';

const SimilarAttributesContainer = ({ type, similarAttributes }: SimilarAttributesContainerProps): JSX.Element | null => {
  const { t: translate } = useTranslation();

  const renderSimilarAttributesTags = (originalAttribute: string, relatedSimilarAttributes: ExplainabilitySimilarAttribute[]) => {
    return (
      relatedSimilarAttributes.map((attribute) => (
        <SimilarAttribute
          key={ `${originalAttribute}_similar_attribute_${attribute.value}` }
          text={ attribute.value }
          tagInfo={ translate(
            type === ExplainabilityTypeEnum.Skill ?
              STRING_KEYS.MATCH_CARD.EXPLAINABILITY.SIMILAR_SKILL_MORE_INFO_TOOLTIP_TEXT :
              STRING_KEYS.MATCH_CARD.EXPLAINABILITY.SIMILAR_INDUSTRY_MORE_INFO_TOOLTIP_TEXT,
          ) }
          menu={ (
            <SimilarAttributeInfoMenu
              type={ type }
              value={ attribute.value }
              originalValue={ attribute.originalValue }
              similarExplanation={ attribute.similarityExplanation }
              relevanceExplanation={ attribute.relevanceExplanation }
              origins={ attribute.origins }
            />
          ) }
        />
      ))
    );
  };

  const renderSimilarAttributesRow = (originalAttribute: string, relatedSimilarAttributes: ExplainabilitySimilarAttribute[]) => {
    return (
      <SimilarAttributeRow>
        <TagsContainer>
          <OriginalAttribute text={ originalAttribute } />
          <RightArrow />
          { renderSimilarAttributesTags(originalAttribute, relatedSimilarAttributes) }
        </TagsContainer>
        <SimilarAttributesOriginsList>
          { relatedSimilarAttributes.map((attribute) => (
            <SimilarAttributeExplainabilityText
              key={ `explainability_widget_similar_attribute_explainability_${attribute.value}` }
              type={ type }
              similarAttribute={ attribute }
            />
          )) }
        </SimilarAttributesOriginsList>
      </SimilarAttributeRow>
    );
  };

  const SimilarAttributeRows = useMemo(() => {
    const components: JSX.Element[] = [];

    similarAttributes?.reduce((acc, attribute) => {
      const relatedSimilarAttributes = acc.get(attribute.originalValue) || [];
      relatedSimilarAttributes.push(attribute);
      acc.set(attribute.originalValue, relatedSimilarAttributes);

      return acc;
    }, new Map())?.forEach((relatedSimilarAttributes: ExplainabilitySimilarAttribute[], originalAttribute: string) => {
      components.push(renderSimilarAttributesRow(originalAttribute, relatedSimilarAttributes));
    });

    return components;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [similarAttributes]);

  if (!similarAttributes?.length) {
    return null;
  }

  return (
    <>{ SimilarAttributeRows }</>
  );
};

export default SimilarAttributesContainer;
