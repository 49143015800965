/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FullScreenDialog from '../../../../components/dialogs/full-screen-dialog/full-screen-dialog.component';
import { StepsPageHeader } from '../../../../components/Common/headers/progress-bar-header/progress-bar-header.component';
import {
  useCreatePositionDialogSlides,
  useCreatePositionProgressSteps,
} from '../../hooks/create-position-progress-steps.hooks';
import { CreatePositionDialogExitButton } from '../create-position-dialog-exit-button/create-position-dialog-exit-button.component';
import ActionsFooterComponent from '../actions-footer/actions-footer.component';
import PositionSlider from '../create-position-slider/create-position-slider.component';
import { CreatePositionStepsEnums } from '../../enums/create-position-steps.enums';
import {
  useCreatePositionStoreMetadataSelector,
} from '../../../../store/slices/create-position-state/selectors/create-position.selector';
import {
  useCreatePositionContinueWatcherButton,
  useCreatePositionSkipWatcherButton,
  useResetStoreByStep,
} from '../../hooks/create-position-actions.hooks';
import { MatchingMakingTransitionScreen } from '../../../../components/Common/matching-making-transition/matching-making-transition.component';
import STRING_KEYS from '../../../../language/keys';
import { CREATE_POSITION_ANALYTICS_EVENTS } from '../../consts/position-analytics.consts';
import { EVENT_GROUP, PRODUCT_AREA } from '../../../../consts/analytics';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import {
  useBackClickEvent,
  useContinueClickEvent,
  usePageShowEvent,
  useSkipClickEvent,
} from '../../hooks/create-position-actions-bi-evnts.hooks';
import { useGetMyAccountSelector } from '../../../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import { resetState } from '../../../../store/slices/create-position-state/create-position-state.toolkit-slice';
import { useCreatePositionMutation } from '../../../../store/rtk-query/apis/position/hooks/create-position.mutation-hook';
import { useCreatePositionBuildPayload } from '../../hooks/create-position-build-payload.hooks';
import { AppRouting } from '../../../../services/appRoutingResolver';
import { useGetOpenPositionsQuery } from '../../../../store/rtk-query/apis/position/hooks/get-open-positions.query-hook';
import { FullScreenBody, FullScreenFooter, FullScreenHeader } from './create-position-dialog.styled';

type CreatePositionDialogProps = {
  isOpen: boolean,
  closeHandler: () => void;
}

const BUTTON_TYPES = {
  CONTINUE: 'continue',
  SKIP: 'skip',
};

function CreatePositionDialog({ isOpen, closeHandler }: CreatePositionDialogProps): JSX.Element {
  const [shouldRenderBody, setShouldRenderBody] = useState(false);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const history = useHistory();
  const { data: { plan: { maximumOpenPositions = 0 } } } = useGetMyAccountSelector();
  const { analyticsTrackEvent, analyticsPageEvent } = useAnalyticsContext();
  const [createPosition, { isSuccess: isPositionCreateSuccess }] = useCreatePositionMutation();
  // TODO [refactor]: fix.. this is used in order to load updated positions
  const { refetch: refetchPositions } = useGetOpenPositionsQuery();
  const {
    slidesState, nextSlide, prevSlide, setDefaults, activeSlide,
  } = useCreatePositionDialogSlides({
    onLastClickHandler: () => closeHandler(),
  });
  const { steps } = useCreatePositionProgressSteps(activeSlide.key);
  const { reset: resetStore, resetActionButtons } = useResetStoreByStep();
  const {
    showButtonLoader, showSkipButton, isContinueButtonEnabled, showContinueButton, isLoading, createdId,
  } = useCreatePositionStoreMetadataSelector();
  useCreatePositionContinueWatcherButton(activeSlide.key);
  useCreatePositionSkipWatcherButton(activeSlide.key);
  usePageShowEvent(activeSlide.key, steps, isOpen);
  const writeBackClickEvent = useBackClickEvent(activeSlide.key, steps);
  const writeContinueClickEvent = useContinueClickEvent(activeSlide.key, steps);
  const writeSkipClickEvent = useSkipClickEvent(activeSlide.key, steps);
  const createPositionRequest = useCreatePositionBuildPayload();

  // TODO Vova [refactor]: replaced this code with the one at to bottom because it still listening after the dialog is closed
  // useEffect(() => {
  //   console.log('in effect');
  //   if (createdId && isOpen) {
  //     history.push(AppRouting.buildMatchingProgressPagePath(createdId));
  //   }
  // }, [createdId]);

  useEffect(() => {
    if (isPositionCreateSuccess) {
      refetchPositions?.();
      history.push(AppRouting.buildMatchesPageUrl(createdId));
    }
  }, [isPositionCreateSuccess]);

  const handleOnContinueClick = (type: string) => {
    if (activeSlide.key === CreatePositionStepsEnums.JobDescription) {
      const eventData = {
        eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_FINISHED_SUCCESSFULLY,
        eventGroup: EVENT_GROUP.CLICK,
        pageName: PRODUCT_AREA.HOMEPAGE,
        productArea: PRODUCT_AREA.ADD_POSITION,
        positionQuota: maximumOpenPositions,
      };

      createPosition(createPositionRequest);
      analyticsTrackEvent(eventData);
    }
    if (type === BUTTON_TYPES.CONTINUE) {
      writeContinueClickEvent();
    } else {
      writeSkipClickEvent();
    }
    resetActionButtons();
    nextSlide();
  };

  const handleOnBackClick = () => {
    writeBackClickEvent();
    resetStore(activeSlide.key);
    resetActionButtons();
    prevSlide();
  };

  const handlerOnCloseDialog = () => {
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm(translate(STRING_KEYS.CREATE_POSITION.CREATE_POSITION_CONFIRM_MESSAGE));
    const eventData = {
      eventName: CREATE_POSITION_ANALYTICS_EVENTS.EXIT_NEW_POSITION_CLICKED_FROM_HOME_PAGE,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PRODUCT_AREA.HOMEPAGE,
      positionQuota: maximumOpenPositions,
    };
    if (confirmed) {
      resetActionButtons();
      closeHandler();
      setShouldRenderBody(false);
      analyticsTrackEvent(eventData);
      dispatch(resetState());
      setDefaults();
    }
  };

  const renderBody = (): JSX.Element => {
    if (shouldRenderBody) {
      return (
        <FullScreenBody>
          <PositionSlider
            slides={ slidesState }
          />
        </FullScreenBody>
      );
    }

    return <></>;
  };

  const isSkipButtonRender = (): boolean => {
    return showSkipButton && !(
      activeSlide.key === CreatePositionStepsEnums.LetsBegin ||
      activeSlide.key === CreatePositionStepsEnums.Experience
    );
  };

  if (isLoading) {
    analyticsPageEvent({
      eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_FINISH_PAGE_VIEWED,
      eventGroup: EVENT_GROUP.PAGE_VIEW,
      pageName: PRODUCT_AREA.HOMEPAGE,

    });

    return (
      <MatchingMakingTransitionScreen />
    );
  }

  return (
    <FullScreenDialog
      isOpen={ isOpen }
      closeHandler={ handlerOnCloseDialog }
      onOpened={ () => setShouldRenderBody(true) }
    >
      <FullScreenHeader>
        <StepsPageHeader
          steps={ steps }
          borderBottomDisable={ false }
        >
          <CreatePositionDialogExitButton
            onClick={ handlerOnCloseDialog }
          />
        </StepsPageHeader>
      </FullScreenHeader>
      { renderBody() }
      <FullScreenFooter>
        <ActionsFooterComponent
          handleBackButton={ handleOnBackClick }
          handleOnContinueClick={ () => handleOnContinueClick(BUTTON_TYPES.CONTINUE) }
          handleOnSkipClick={ () => handleOnContinueClick(BUTTON_TYPES.SKIP) }
          showButtonLoader={ showButtonLoader }
          showContinueButton={ showContinueButton }
          isContinueButtonAnimation={ isContinueButtonEnabled }
          isContinueButtonEnabled={ isContinueButtonEnabled }
          showSkipButton={ isSkipButtonRender() }
          isSkipButtonDisabled={ isContinueButtonEnabled }
        />
      </FullScreenFooter>
    </FullScreenDialog>
  );
}

export default CreatePositionDialog;
