import { useEffect, useRef } from 'react';

/**
 * Store value for comparing in state changes.
 * NOTE: First returned value is `undefined`, because no previous value
 * https://stackoverflow.com/a/57706747
 * @param value to save
 * @returns last saved value
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  
  return ref.current;
};
