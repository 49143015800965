import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import Chip from '../../../../components/Common/perfect-chip/perfect-chip.component';
import { THEME } from '../../../../themes';
import { hexToRgbA } from '../../../../utils/colors';
import { ButtonFont } from '../../../../themes/fonts';
import { ChipProps } from '../../../../components/Common/perfect-chip/perfect-chip';
import { ASSETS } from '../../../../assets';
import { OutreachChipTypeEnum } from './outreach-chip.enums';

const getChipBackgroundColor = (type: OutreachChipTypeEnum) => {
  let color;
  switch (type) {
    case OutreachChipTypeEnum.Warning:
      color = THEME.COLORS.WARNING;
      break;
    case OutreachChipTypeEnum.Regular:
    default:
      color = THEME.COLORS.SECONDARY;
      break;
  }

  return hexToRgbA(color, THEME.OPACITY.GHOST);
};

export const StyledChip = styled(Chip)<ChipProps & { type: OutreachChipTypeEnum }>`
  border-radius: ${THEME.RADIUS.ROUND};
  background-color: ${({ type }) => getChipBackgroundColor(type)};
  color: ${THEME.COLORS.PRIMARY};
  & span {
    max-width: 100%;
  }
`;

export const Loader = styled(CircularProgress).attrs({
  style: {
    color: THEME.COLORS.SECONDARY,
  },
})`
  ${ButtonFont};
`;

export const WarningIcon = styled(ASSETS.WARNING_FILLED)`
  height: 19px;
`;
