import { useTranslation } from 'react-i18next';
import { InfoDialogBase } from '../info-dialog-base.component';
import { THEME } from '../../../../themes';
import { FLUENT_UI_EMOJIS } from '../../../../assets';
import STRING_KEYS from '../../../../language/keys';
import { CONFIG } from '../../../../config';
import { AppRouting } from '../../../../services/appRoutingResolver';

const UpgradeToScaleDialog = ({ isDialogOpen, closeButtonHandler }: UpgradeToScaleDialogProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const openScheduleCustomPlanConsultation = () => {
    if (CONFIG.REACT_APP_SCHEDULE_CUSTOM_PLAN_CONSULTATION_URL) {
      AppRouting.openInNewTab(CONFIG.REACT_APP_SCHEDULE_CUSTOM_PLAN_CONSULTATION_URL);
    }
    closeButtonHandler();
  };

  return (
    <InfoDialogBase
      title={ translate(STRING_KEYS.UPGRADE_TO_SCALE_DIALOG.TITLE) }
      description={ translate(STRING_KEYS.UPGRADE_TO_SCALE_DIALOG.DESCRIPTION) }
      isDialogOpen={ isDialogOpen }
      onCloseDialog={ closeButtonHandler }
      primaryButton={ {
        text: translate(STRING_KEYS.UPGRADE_TO_SCALE_DIALOG.PRIMARY_BUTTON_TITLE),
        handler: openScheduleCustomPlanConsultation,
      } }
      secondaryButton={ {
        text: translate(STRING_KEYS.UPGRADE_TO_SCALE_DIALOG.SECONDARY_BUTTON_TITLE),
        handler: closeButtonHandler,
      } }
      buttonsColor={ THEME.COLORS.SECONDARY }
      Icon={ FLUENT_UI_EMOJIS.ROCKET }
    />
  );
};

export default UpgradeToScaleDialog;
