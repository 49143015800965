/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { TalentNetworkConnectionScoreProps } from './talent-network-connection-score';
import {
  Score, ScoreContainer, ScoreTitle, TalentNetworkConnectionScoreContainer, TitleContainer,
} from './talent-network-connection-score.styled';
import { SCORE_BY_LEVEL } from './talent-network-connection-score.consts';

const TalentNetworkConnectionScore = forwardRef((
  props: TalentNetworkConnectionScoreProps,
  ref: Ref<HTMLDivElement>,
) => {
  const { t: translate } = useTranslation();
  const { level } = props;
  const config = SCORE_BY_LEVEL[level];
  const renderScoreLevel = () => {
    const scoreItems = new Array(3).fill(undefined);

    return scoreItems.map((value, i) => {
      const active = i < config.levelNumber;

      return (
        // eslint-disable-next-line react/no-array-index-key
        <Score key={ `${level}_${i}` } level={ level } active={ active } />
      );
    });
  };

  return (
    <TalentNetworkConnectionScoreContainer ref={ ref } >
      <TitleContainer>
        <ScoreContainer>
          { renderScoreLevel() }
        </ScoreContainer>
        <ScoreTitle level={ level } >
          { translate(config.translationKey) }
        </ScoreTitle>
      </TitleContainer>
    </TalentNetworkConnectionScoreContainer>
  );
});

TalentNetworkConnectionScore.displayName = 'TalentNetworkConnectionScore';

export default TalentNetworkConnectionScore;
