import log from 'loglevel';
import { MOVE_METER_TYPES } from '../consts';
import { THEME } from '../../../../../themes';
import STRING_KEYS from '../../../../../language/keys';

const logger = log.getLogger('MOVE_METER_WIDGET_UTILS');

export const getMeterProperties = (type: string): { color: string, titleTranslationKey: string } => {
  // TODO: remove color after using the new widget also in match page
  const {
    SOMEWHAT_LIKELY_LOW,
    SOMEWHAT_LIKELY_HIGH,
    LIKELY_TO_MOVE_LOW,
    LIKELY_TO_MOVE_HIGH,
    VERY_LIKELY_LOW,
    VERY_LIKELY_HIGH,
  } = MOVE_METER_TYPES;
  switch (type) {
    case SOMEWHAT_LIKELY_LOW:
    case SOMEWHAT_LIKELY_HIGH:
      return {
        color: THEME.COLORS.WARNING,
        titleTranslationKey: STRING_KEYS.SOMEWHAT_LIKELY,
      };
    case LIKELY_TO_MOVE_LOW:
    case LIKELY_TO_MOVE_HIGH:
      return {
        color: THEME.COLORS.ACCENT,
        titleTranslationKey: STRING_KEYS.LIKELY_TO_MOVE,
      };
    case VERY_LIKELY_LOW:
    case VERY_LIKELY_HIGH:
      return {
        color: THEME.COLORS.SECONDARY,
        titleTranslationKey: STRING_KEYS.VERY_LIKELY,
      };
    default:
      logger.warn('Move Meter widget type is unknown', {
        type,
      });

      return {
        color: '',
        titleTranslationKey: '',
      };
  }
};

export const calculateLevel = (score: number, scoreMaxValue: number, meterMinValue: number, meterMaxValue: number): number => {
  return Math.floor(score *
    ((meterMaxValue - meterMinValue) / scoreMaxValue) + meterMinValue);
};
