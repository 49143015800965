import { useCallback, useEffect } from 'react';

const useClickOutsideListener = (
  onClickOutside: (event: MouseEvent) => void,
  node: HTMLElement | undefined,
): void => {
  const onClickOutsideHandler = useCallback(
    (event: MouseEvent) => {
      if (
        !node?.contains?.(event.target as Node)
      ) {
        onClickOutside(event);
      }
    },
    [onClickOutside, node],
  );
  useEffect(() => {
    document.addEventListener('mouseup', onClickOutsideHandler);

    return () => document.removeEventListener('mouseup', onClickOutsideHandler);
  }, [onClickOutsideHandler]);
};

export default useClickOutsideListener;
