import { EmailSenderResponseDto } from '../dto/response/email-sender.response';
import { outreachQuery } from '../outreach.toolkit-api';
import { EmailSenderStatusEnum } from '../../../../../enums/email-sender-status.enum';
import { EmailCredentialsStatusEnum } from '../../../../../enums/email-credentials-status.enum';
import { useGetEmailSenderSelector } from '../selectors/get-email-sender.selector';

export const useGetEmailSenderQuery = (skip = false): AppSelectorType<EmailSenderResponseDto> => {
  const {
    data,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
    refetch,
  } = outreachQuery.useGetEmailSenderQuery(
    undefined,
    { skip },
  );

  return {
    data: data || {} as EmailSenderResponseDto,
    error,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    refetch,
  };
};

// TODO: put enum in another place?
export enum EmailSenderIntegrationStatusEnum {
  NotConnected = 'NOT_CONNECTED',
  Invalid = 'INVALID',
  Active = 'ACTIVE',
}

type UseGetEmailSenderIntegrationStatusArgs = {
  skipFetch?: boolean;
};

export const useGetEmailSenderIntegrationStatus = (args?: UseGetEmailSenderIntegrationStatusArgs): EmailSenderIntegrationStatusEnum | undefined => {
  useGetEmailSenderQuery(!!args?.skipFetch);
  const { data: emailSender, isSuccess: isGetEmailSenderSuccess, isError: isGetEmailSenderError } = useGetEmailSenderSelector();

  if (isGetEmailSenderError) {
    return EmailSenderIntegrationStatusEnum.NotConnected;
  }

  if (isGetEmailSenderSuccess) {
    if (emailSender?.status !== EmailSenderStatusEnum.Active || emailSender?.credentials.status !== EmailCredentialsStatusEnum.Valid) {
      return EmailSenderIntegrationStatusEnum.Invalid;
    }

    return EmailSenderIntegrationStatusEnum.Active;
  }

  return undefined;
};
