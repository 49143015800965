import styled, { css } from 'styled-components';
import { THEME } from '../../../../../themes';
import { Body2Font, CaptionFont, Subtitle1Font } from '../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../utils/colors';
import { BaseInputItemDirection } from './text-input-item.enums';

export const Container = styled.div<{ direction?: BaseInputItemDirection }>`
  display: flex;
  justify-content: space-between;
  gap: ${THEME.SPACING.TINO};
  flex-direction: ${({ direction }) => direction};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const Description = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;

export const Title = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
`;

export const TextInputCommonStyle = css`
  ${Body2Font};
  padding: ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  border: solid 1px ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
  background-color: ${hexToRgbA(THEME.COLORS.LIGHT_GREY)};
  resize: none;
  ::placeholder {
    opacity: ${THEME.OPACITY.MED};
    line-height: 1.57;
    letter-spacing: 0.25px;
    color: ${THEME.COLORS.CONTENT_A};
  };
  :focus {
    outline: none;
  }
`;
