/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState } from 'react';
import { PerfectTooltip } from '../perfect-tooltip';
import Popper from '../popper/popper.component';
import { TagProps } from './tag';
import { TagContainer, TextWrapper } from './tag.styled';

// TODO: create separate component for tag & menu
const Tag = (props: TagProps): JSX.Element => {
  const ref =  useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const {
    text, Icon, tagInfo, menu, clickHandler, ...otherProps
  } = props;

  const tooltipDisabled = !tagInfo;
  const className = isMenuOpen ? `${otherProps.className || ''} active` : otherProps.className;

  const onClick = () => {
    if (menu) {
      setIsMenuOpen(true);
    }

    clickHandler?.();
  };

  const tagContainer = (
    <TagContainer
      ref={ ref }
      { ...otherProps }
      className={ className }
      onClick={ onClick }
      isClickable={ !!menu || !!clickHandler }
    >
      { Icon && <Icon /> }
      <TextWrapper>{text}</TextWrapper>
    </TagContainer>
  );

  if (tooltipDisabled) {
    return tagContainer;
  }

  return (
    <>
      <PerfectTooltip
        title={ tagInfo }
        placement="bottom-start"
        enterNextDelay={ 700 }
        enterDelay={ 700 }
      >
        { tagContainer }
      </PerfectTooltip>
      { !!menu && (
        <Popper
          isOpen={ isMenuOpen }
          anchorElement={ ref?.current || undefined }
          placement="top-start"
          closeHandler={ () => setIsMenuOpen(false) }
        >
          <div>
            { props.menu }
          </div>
        </Popper>
      ) }
    </>
  );
};

Tag.displayName = 'Tag';

export default Tag;
