import { MouseEvent } from 'react';
import {
  Container, Title, CloseChipIcon, RequiredChipIcon, CloseIconWrapper,
} from './requirement-chip.styled';

const RequirementChip = ({
  removeButtonClickHandler,
  clickHandler,
  title,
  required,
}: RequirementChipProps): JSX.Element => {
  const onRemoveButtonClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    removeButtonClickHandler();
  };

  return (
    <Container
      title={ title }
      onClick={ clickHandler }
      required={ !!required }
    >
      { required && <RequiredChipIcon /> }
      <Title> { title } </Title>
      <CloseIconWrapper onClick={ onRemoveButtonClick }>
        <CloseChipIcon
          required={ !!required }
        />
      </CloseIconWrapper>
    </Container>
  );
};

export default RequirementChip;
