import styled, { css } from 'styled-components';
import { hexToRgbA } from '../../../utils/colors';
import { THEME } from '../../../themes';
import { ASSETS } from '../../../assets';

export const Container = styled.div`
  display: flex;
  gap: ${THEME.SPACING.NANO};
`;

const StepBase = css`
  width: 8px;
  height: 8px;
  border-radius: ${THEME.RADIUS.NANO};
`;

export const EmptyStep = styled.div`
  ${StepBase};
  border: 1px solid ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW)};
  background-color: ${THEME.COLORS.PURE_WHITE};
`;

export const ActiveStep = styled.div<{ color?: string }>`
  ${StepBase};
  background-color: ${({ color }) => color || THEME.COLORS.SECONDARY};
`;

export const CompletedStep = styled.div<{ color?: string }>`
  ${StepBase};
  background-color: ${({ color }) => color || THEME.COLORS.PRIMARY_DARK};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VSignIcon = styled(ASSETS.V_SIGN)`
  width: 4px;
  color: ${THEME.COLORS.PURE_WHITE};
`;
