import styled from 'styled-components';
import { TitleStyle } from '../../explainability-info-menu.styled';

export const HeaderTitle = styled.div`
  ${TitleStyle};
  
  & svg {
    width: 16px;
    height: 16px;
  }
`;
