import { useTranslation } from 'react-i18next';
import { Divider } from '../../../../../components/Common/Divider';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
} from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../../language/keys';
import {
  BannerIcon,
  Container,
  ContainerBorder,
  Description,
  DividerWrapper,
  ActionButton,
  Title,
  Wrapper,
} from './setup-gen-ai-banner.styled';

const SetupGenAiBanner = ({ clickHandler }: SetupGenAiBannerProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <ContainerBorder>
      <Container>
        <Wrapper>
          <BannerIcon />
          <Title>{ translate(STRING_KEYS.OUTREACH_PAGE.SETUP_GEN_AI_CONVERSATION_BANNER.TITLE) }</Title>
          <Description>{ translate(STRING_KEYS.OUTREACH_PAGE.SETUP_GEN_AI_CONVERSATION_BANNER.DESCRIPTION) }</Description>
          <DividerWrapper>
            <Divider />
          </DividerWrapper>
          <ActionButton
            onClick={ clickHandler }
            size={ PerfectButtonSize.Medium }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
          >
            { translate(STRING_KEYS.OUTREACH_PAGE.SETUP_GEN_AI_CONVERSATION_BANNER.BUTTON_TEXT) }
          </ActionButton>
        </Wrapper>
      </Container>
    </ContainerBorder>
  );
};

export default SetupGenAiBanner;
