/* eslint-disable react/jsx-props-no-spreading */
import { MenuContainer } from './explainability-info-menu.styled';

const ExplainabilityInfoMenu = ({ children, ...otherProps }: ExperienceInfoMenuProps): JSX.Element => {
  return (
    <MenuContainer { ...otherProps }>
      { children }
    </MenuContainer>
  );
};

export default ExplainabilityInfoMenu;
