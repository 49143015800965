import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { CaptionFont } from '../../../../../themes/fonts';

const getColor = (isActive: boolean) => {
  return isActive ? THEME.COLORS.SUCCESS : THEME.COLORS.DECOR;
};

export const Container = styled.div<{ isActive: boolean }>`
  ${CaptionFont};
  font-weight: 600;
  color: ${({ isActive }) => getColor(isActive)};
  display: flex;
  gap: ${THEME.SPACING.TINY};
  align-items: center;
  
  :before {
    width: 8px;
    height: 8px;
    content: '';
    display: block;
    border-radius: ${THEME.RADIUS.CIRCLE};
    background-color: ${({ isActive }) => getColor(isActive)};
  }
`;
