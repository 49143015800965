import { OnboardingStep } from '../../../modules/onboarding/onboarding.enums';

export type OnboardingState = {
  id: string | null,
  userId: string | null,
  status: string | null,
  step: OnboardingStep | null,
  showOnboardingSplashScreen: boolean,
  shouldRetryFetchAllowedCompanies: boolean,
  userName: string,
  roleName: string,
  promo: string,
  selectedCompany: {
    linkedInUrl: string,
    companyName: string,
  },
  selectedJobRequirements:  PositionRequirement[],
  jobDescriptionTempUrls: string[],
  onboardingLoadingError: string,
  isOnboardingLoading: boolean,
  isContinueButtonEnabled: boolean,
  showContinueButtonShow: boolean,
  showSkipButtonShow: boolean,
  showButtonLoaderShow: boolean,
};

export const initialOnboardingState: OnboardingState = {
  id: null,
  userId: null,
  status: null,
  step: null,
  showOnboardingSplashScreen: true,
  shouldRetryFetchAllowedCompanies: true,
  userName: '',
  roleName: '',
  promo: '',
  selectedCompany: {
    linkedInUrl: '',
    companyName: '',
  },
  selectedJobRequirements: [],
  jobDescriptionTempUrls: [],
  onboardingLoadingError: '',
  isOnboardingLoading: false,
  isContinueButtonEnabled: false,
  showContinueButtonShow: false,
  showSkipButtonShow: false,
  showButtonLoaderShow: false,
};
