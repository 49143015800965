import { useAppSelector } from '../../../../selectors';
import { sequenceQuery } from '../sequence.toolkit-api';
import { PositionSequencePreferencesResponseDto } from '../dto/response/position-sequence-preferences.response';

export const positionSequencePreferences = sequenceQuery.endpoints.getPositionSequencePreferences.select;

export const useGetPositionSequencePreferencesSelector = (positionId: string): AppSelectorType<PositionSequencePreferencesResponseDto> => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useAppSelector(positionSequencePreferences({ positionId }));

  return {
    data: data || {} as PositionSequencePreferencesResponseDto,
    error,
    isLoading,
    isError,
    isSuccess,
  };
};
