import styled from 'styled-components';
import { THEME } from '../../../themes';
import { Body1Font, ButtonFont, Title2Font } from '../../../themes/fonts';
import BaseButton from '../../Common/Buttons/perfect-base-button/perfect-base-button.component';

export const Container = styled.div`
  display: flex;
  gap: ${THEME.SPACING.LOOSE};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.MEDIUM};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
  align-items: center;
  justify-content: center;
`;

export const DialogHeaderTitle = styled.div`
  ${ButtonFont};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const IconWrapper = styled.div`
  & svg {
    width: 96px;
    height: 96px; 
  }
`;

export const Title = styled.div`
  ${Title2Font};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const Description = styled.div`
  ${Body1Font};
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_A};
  text-align: center;
  line-height: 1.5;
  white-space: pre-line;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const PrimaryButton = styled(BaseButton)<{ color?: string }>`
  background-color: ${({ color }) => color || THEME.COLORS.PRIMARY};
`;

export const SecondaryButton = styled(BaseButton)<{ color?: string }>`
  background-color: ${THEME.COLORS.TRANSPARENT};
  color: ${({ color }) => color || THEME.COLORS.PRIMARY};
`;
