import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { HeadlineFont } from '../../../../../themes/fonts';

export const PREFERENCES_BODY_HEADER_HEIGHT_PX = 96;

export const Container = styled.div`
  height: ${PREFERENCES_BODY_HEADER_HEIGHT_PX}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  z-index: 110;
  background-color: ${THEME.COLORS.PURE_WHITE};
  padding: 0 ${THEME.SPACING.LOOSE};
`;

export const LeftContainer = styled.div<{ hasTitleAdornment: boolean }>`
  display: flex;
  gap: ${THEME.SPACING.BASE};
  color: ${THEME.COLORS.CONTENT_A};
  align-items: ${({ hasTitleAdornment }) => hasTitleAdornment ? 'start' : 'center'}
`;

export const IconContainer = styled.div`
  & svg {
    height: 24px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MICRO};
`;

export const Title = styled.span`
  ${HeadlineFont};
`;

export const ActionButtonsContainer = styled.div``;
