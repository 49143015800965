import styled from 'styled-components';
import { ASSETS } from '../../../../../assets';
import { THEME } from '../../../../../themes';
import { Body1Font, OverlineFont } from '../../../../../themes/fonts';

export const FirstTimeExperience = styled.div`
  margin-top: ${THEME.SPACING.TINO};
  background-color: ${THEME.COLORS_RGB.SECONDARY_005};
  border-radius: ${THEME.RADIUS.ROUND};
  padding: ${THEME.SPACING.BASE};
`;

export const Header = styled.div`
  ${OverlineFont};
  display: flex;
  font-weight: bold;
  align-items: center;
  color: ${THEME.COLORS.SECONDARY};
`;

export const TalentNetworkIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${THEME.COLORS.PURE_WHITE};
  width: fit-content;
  height: fit-content;
  padding: 4px;
  border-radius: ${THEME.RADIUS.CIRCLE};
  margin-right: ${THEME.SPACING.TINO};
`;

export const TalentNetworkIcon = styled(ASSETS.TALENT_NETWORK_ICON)`
  color: ${THEME.COLORS.SECONDARY};
  width: 16px;
  height: 16px;
`;

export const TextContainer = styled.div`
  ${Body1Font};
  margin-top: ${THEME.SPACING.TINO};
`;
