import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { AppState } from '../app-state.initial-state';

type PayloadType = {
  currentPageId: string,
  referrerPageId: string,
}
export const setReferrerAndViewIdReducer: CaseReducer<AppState, PayloadAction<PayloadType>> = (state, { payload }) => {
  state.currentPageId = payload.currentPageId;
  state.referrerPageId = payload.referrerPageId;
};
