import Cookies from 'js-cookie';
import log from 'loglevel';
import { v4 } from 'uuid';
import { AppRouting } from './appRoutingResolver';

const logger = log.getLogger('ANALYTICS_ID_MANAGER');

type AnalyticsSession = {
  id: string,
  expirationTime: Date,
};

class AnalyticsIdManager {
  private readonly COOKIE_NAME = 'perfect_analytics_id';

  private readonly LIFETIME = 1;  // in hours

  /**
   * Get existing analytics-id or create a new one (if doesn't exist)
   * @returns string current analytics-id
   */
  public getId = () => {
    // TODO: build a mechanism so we dont always have to get it from storage...
    // we should always get it from storage, to be synced with other tabs
    // for example - if the user was logged out
    const sessionData = this.getFromStorage();

    if (!sessionData || !this.isSessionValid(sessionData)) {
      logger.debug("analytics-id isn't valid anymore, creating a new analytics-id");
      const newSession = this.create();

      return newSession.id;
    }

    return sessionData.id;
  }

  /**
   * Mark an active analytics session,
   * by extending current analytics-id time,
   * or by creating a new id if doesn't exist
   */
  public markAsActive = () => {
    logger.debug('marking analytics-id as active');

    const sessionData = this.getFromStorage();

    if (sessionData && this.isSessionValid(sessionData)) {
      this.extend(sessionData.id);
      
      return;
    }

    this.create();
  }

  /**
   * Remove current analytics-id.
   * This is useful for cases where we want to anonymize the user.
   * This method will NOT create a new analytics-id
   */
  public remove = () => {
    logger.debug('removing analytics-id');
    this.removeFromStorage();
  }

  // ---------------------------------------
  // Session Existence
  // ---------------------------------------

  private create = (): AnalyticsSession => {
    const newData = {
      id: v4(),
      expirationTime: this.prepareExpirationTime(),
    };

    this.saveToStorage(
      newData.id,
      newData.expirationTime,
    );

    logger.debug('created new anlaytics-id:', newData.id);

    return newData;
  }

  private extend = (analyticsId: string) => {
    const newExpirationTime = this.prepareExpirationTime();

    logger.trace('extended anlaytics-id time', {
      analyticsId,
      newExpirationTime,
    });

    this.saveToStorage(analyticsId, newExpirationTime);
  }

  // ---------------------------------------
  // Storage Management
  // ---------------------------------------

  private getFromStorage = (): AnalyticsSession | null => {
    const cookieValue = Cookies.get(this.COOKIE_NAME);

    if (!cookieValue) {
      // logger.trace("analytics-id cookie doesn't exist", {
      //   cookieName: this.COOKIE_NAME,
      // });

      return null;
    }

    const cookieData = JSON.parse(cookieValue) as AnalyticsSession;

    return {
      id: cookieData.id,
      expirationTime: new Date(cookieData.expirationTime),
    };
  }

  private saveToStorage = (id: string, expirationTime: Date) => {
    const rootOrigin = `.${AppRouting.getRootOrigin()}`;

    const cookieValue = JSON.stringify({
      id,
      expirationTime,
    });

    Cookies.set(this.COOKIE_NAME, cookieValue, {
      domain: rootOrigin,
      expires: expirationTime,
      sameSite: 'Lax',
    });

    // logger.trace('saved new cookie value', {
    //   cookieName: this.COOKIE_NAME,
    //   value: cookieValue,
    // });
  }

  private removeFromStorage = () => {
    const rootOrigin = `.${AppRouting.getRootOrigin()}`;

    Cookies.remove(this.COOKIE_NAME, {
      domain: rootOrigin,
    });

    // logger.debug('removed analytics-id cookie', {
    //   cookieName: this.COOKIE_NAME,
    // });
  }

  // ---------------------------------------
  // Helpers
  // ---------------------------------------

  private isSessionValid = (sessionData: AnalyticsSession) => {
    const now = new Date();

    const { id, expirationTime } = sessionData;

    return (id && expirationTime && now < expirationTime);
  }

  private prepareExpirationTime = (): Date => {
    const date = new Date();
    date.setHours(date.getHours() + this.LIFETIME);

    return date;
  }
}

export default new AnalyticsIdManager();
