import { useMemo } from 'react';
import { Container, TypingLoader, SectionLoader } from './dots-loader.styled';
import { DotsLoaderProps } from './dots-loader';
import { DotsLoaderVariation } from './dots-loader.enums';

const DotsLoader = ({ variation, color, ...otherProps }: DotsLoaderProps): JSX.Element => {
  const Loader = useMemo(() => {
    switch (variation) {
      case DotsLoaderVariation.Typing:
      default:
        return <TypingLoader />;
      case DotsLoaderVariation.Section:
        return <SectionLoader color={ color } />;
    }
  }, [variation, color]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Container { ...otherProps }>
      { Loader }
    </Container>
  );
};

export default DotsLoader;
