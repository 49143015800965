import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import InsightsCards from '../insights-cards/insights-cards.component';
import STRING_KEYS from '../../../../../language/keys';
import { useGetPositionSelector } from '../../../../../store/rtk-query/apis/position/selectors/get-position.selector';
import { todayTomorrowOrFormatDate } from '../../../../../utils/dates';
import {
  Container,
  LeftColumn,
  Title,
  Description,
  ActionContainer,
  ColumnRight,
  DescriptionWrapper,
  BoldText,
} from './match-daily-limit.styled';

const NEXT_DAILY_BATCH_TIME_FORMAT = 'HH:mm';

const MatchLimitDailyBase = ({
  positionId,
  Background,
  Icon,
  title,
  description,
  PrimaryButton,
  SecondaryButton,
}: MatchDailyLimitProps):JSX.Element => {
  const { t: translate } = useTranslation();
  const { data: { nextDailyPublishMatchesTime } } = useGetPositionSelector(positionId);

  const renderNextDailyBatchTimeText = () => {
    if (!nextDailyPublishMatchesTime) {
      return null;
    }

    const nextDailyPublishMatchesTimeDate = new Date(nextDailyPublishMatchesTime);

    const day = todayTomorrowOrFormatDate(nextDailyPublishMatchesTimeDate);
    const hour = format(nextDailyPublishMatchesTimeDate, NEXT_DAILY_BATCH_TIME_FORMAT);

    return (
      <BoldText>
        { translate(STRING_KEYS.DAILY_LIMIT.NEXT_MATCHES_BATCH_TIME, { day, hour }) }
      </BoldText>
    );
  };

  return (
    <Container>
      { !!Background && Background }
      <LeftColumn>
        { Icon }
        <Title>
          { title }
        </Title>
        <Description>
          <DescriptionWrapper>
            { description }
            { renderNextDailyBatchTimeText() }
          </DescriptionWrapper>
        </Description>
        <ActionContainer>
          { PrimaryButton }
          { !!SecondaryButton && SecondaryButton }
        </ActionContainer>
      </LeftColumn>
      <ColumnRight>
        <InsightsCards positionId={ positionId } />
      </ColumnRight>
    </Container>
  );
};

export default MatchLimitDailyBase;
