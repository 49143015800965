import styled, { css } from 'styled-components';
import { BaseSelectionGroupVariant } from '../base-selection-group.enums';
import { THEME } from '../../../../themes';
import { ButtonFont } from '../../../../themes/fonts';

const getBeforeForLastChildByVariant = (variant: BaseSelectionGroupVariant, activeElementIndex: number) => {
  if (variant === BaseSelectionGroupVariant.Round || variant === BaseSelectionGroupVariant.Sharp) {
    return css`
      position: relative;
      &:first-child::before {
        pointer-events: none;
        transition: 0.3s all;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: ${() => THEME.COLORS.LIGHT_PINK};
        transform: ${`translateX(calc(${activeElementIndex} * 100%))`};
      };
    `;
  }

  return '';
};

const getVariantRadiusCss = (variant: BaseSelectionGroupVariant) => {
  if (variant === BaseSelectionGroupVariant.Round) {
    return css`
      border-radius: ${THEME.RADIUS.CURVY};
      &::before {
        border-radius: ${THEME.RADIUS.CURVY};
      }
    `;
  }

  if (variant === BaseSelectionGroupVariant.Sharp) {
    return css`
      border-radius: ${THEME.RADIUS.SHARP};
      &::before {
        border-radius: ${THEME.RADIUS.SHARP};
      }
    `;
  }

  return '';
};

export const Container = styled.div<{ variant: BaseSelectionGroupVariant, active: boolean, disabled: boolean, activeElementIndex: number }>`
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${THEME.COLORS.ACCENT};
  transition: color 0.3s, fill 0.3s, background-color 0.3s;
  cursor: pointer;
  fill: ${({ active }) => active ? THEME.COLORS.ACCENT : undefined};
  ${({ variant }) => getVariantRadiusCss(variant)};
  ${({ variant, activeElementIndex }) => getBeforeForLastChildByVariant(variant, activeElementIndex)};
`;

export const Wrapper = styled.div<{ active: boolean }>`
  z-index: 1;
  color: ${({ active }) => active ? 'inherit' : THEME.COLORS.CONTENT_A};
  transition: color 400ms;
`;

export const Content = styled.div`
  ${ButtonFont};
  text-transform: capitalize;
  line-height: 1.71;
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
  z-index: 1;
`;
