import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { POSITION_CLOSING_REASONS } from '../../../../consts';
import STRING_KEYS from '../../../../language/keys';
import {
  ClosingReasonsWrapper,
  CloseReason,
} from './styled';

type ReasonButtonsProps = {
  setOtherReasonSelected: Dispatch<SetStateAction<boolean>>,
  onSubmitPositionClose: (selectedReason: string) => void
}

function ReasonButtons({
  setOtherReasonSelected,
  onSubmitPositionClose,
}: ReasonButtonsProps): JSX.Element {
  const { t: translate } = useTranslation();

  const onClick = (reasonId: string) => {
    if (reasonId === POSITION_CLOSING_REASONS.OTHER) {
      setOtherReasonSelected(true);

      return;
    }

    onSubmitPositionClose(reasonId);
  };

  return (
    <ClosingReasonsWrapper>
      {
        Object.entries(POSITION_CLOSING_REASONS).map((reason) => (
          <CloseReason
            key={ reason[0] }
            onClick={ () => onClick(reason[0]) }
          >
            {translate(STRING_KEYS.CLOSE_POSITION[reason[1]])}
          </CloseReason>
        ))
      }

    </ClosingReasonsWrapper>
  );
}

export default ReasonButtons;
