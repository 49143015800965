import { PerfectButtonHoverVariants, PerfectButtonSize } from '../../Common/Buttons/perfect-base-button/perfect-base-button.enums';
import Dialog from '../base-dialog/base-dialog.component';
import { DialogWidthSize } from '../base-dialog/base-dialog.enums';
import {
  Description,
  Title,
  SecondaryButton,
  IconWrapper,
  TextContainer,
  Container,
  ButtonsContainer,
  PrimaryButton,
} from './info-dialog-base.styled';

export const InfoDialogBase = ({
  portalUniqueId,
  onCloseDialog,
  isDialogOpen,
  primaryButton,
  secondaryButton,
  buttonsColor,
  title,
  description,
  Icon,
}: InfoDialogBaseProps): JSX.Element => {
  return (
    <Dialog
      isOpen={ isDialogOpen }
      onClose={ onCloseDialog }
      widthSize={ DialogWidthSize.Medium }
      portalUniqueId={ portalUniqueId }
    >
      <Container>
        <IconWrapper>
          <Icon />
        </IconWrapper>
        <TextContainer>
          <Title>{ title }</Title>
          <Description>{ description }</Description>
        </TextContainer>
        <ButtonsContainer>
          <PrimaryButton
            size={ PerfectButtonSize.Large }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
            onClick={ primaryButton.handler }
            color={ buttonsColor }
            isLoading={ primaryButton.isLoading }
          >
            { primaryButton.text }
          </PrimaryButton>
          { secondaryButton && (
            <SecondaryButton
              size={ PerfectButtonSize.Large }
              hoverVariant={ PerfectButtonHoverVariants.Grow }
              onClick={ secondaryButton.handler }
              color={ buttonsColor }
              isLoading={ secondaryButton.isLoading }
            >
              { secondaryButton.text }
            </SecondaryButton>
          ) }
        </ButtonsContainer>
      </Container>
    </Dialog>
  );
};
