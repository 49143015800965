import { UserInfoResponse } from '../dto/response/user-info.response';
import { useAppSelector } from '../../../../selectors';
import { userQuery } from '../user.toolkit-api';
import { AcknowledgementType } from '../../../../../enums/acknowledgement-type.enum';
import { AcknowledgementResponse } from '../../../../../@types/api';

const getMyUserSelectorSelector = userQuery.endpoints.getMyUser.select();

export const useMyUserSelector = (): AppSelectorType<UserInfoResponse> => {
  const {
    data: user, isLoading, isError, isSuccess, error,
  } = useAppSelector(getMyUserSelectorSelector);

  return {
    data: user || {} as UserInfoResponse,
    error,
    isLoading,
    isError,
    isSuccess,
  };
};

export const useGetAcknowledgmentSelector = (positionId: string, type: AcknowledgementType): AppSelectorType<AcknowledgementResponse | undefined> => {
  const {
    data: user, isLoading, isError, isSuccess, error,
  } = useAppSelector(getMyUserSelectorSelector);

  return {
    data: user?.acknowledgements?.find((ack) => ack.positionId === positionId && ack.type === type),
    error,
    isLoading,
    isError,
    isSuccess,
  };
};
