import { LANGUAGES } from './language/languages';

export const APP_ENV = {
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
};

export const CONFIG = {
  OUR_NAME: 'Perfect',
  LOCALE: LANGUAGES.ENGLISH,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  WEBSOCKET_BASE_URL: `${process.env.REACT_APP_WEBSOCKET_BASE_URL}`,
  WEBSOCKET_PATH: `${process.env.REACT_APP_WEBSOCKET_PATH}`,
  WEBSOCKET_SECURED: process.env.REACT_APP_WEBSOCKET_SECURED === 'true',
  HTTP_TIMEOUT_SECONDS: 60,
  SUPPORT_MAIL_ADDRESS: process.env.REACT_APP_SUPPORT_MAIL_ADDRESS || '',
  SUPPORT_MAIL_SUBJECT_ACTIVATE: "I'm having trouble setting my account",
  SUPPORT_MAIL_SUBJECT_RESET_PASSWORD: "I'm having trouble resetting my password",
  SUPPORT_MAIL_SUBJECT_INTERESTED_PRICING: 'is interested in pricing - end of trial',
  SUPPORT_MAIL_BODY_INTERESTED_PRICING: 'sent a request to learn more about pricing on',
  RESERVED_SUBDOMAINS: [null, 'app'], // null meaning there is no sub-domain
  GLOBAL_LOGIN_SUBDOMAIN: 'auth',
  GLOBAL_ACTIVATION_SUBDOMAIN: 'activation',
  ONBOARDING_SUBDOMAIN: 'onboarding',
  GOOGLE_EMAIL_OAUTH: 'google-email-oauth',
  TIME_PERIOD: {
    MORNING: {
      START: 5,
      END: 12,
    },
    AFTERNOON: {
      START: 12,
      END: 17,
    },
    EVENING: {
      START: 17,
      END: 20,
    },
  },
  TOTAL_DAYS_WARNING_BEFORE_TRIAL_END: 3,
  SNACKBAR_AUTO_DISMISS_DURATION: 7000,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY || '',
  ACTIVITY_DEBOUNCE_TIME: 15, // seconds
  RUDDERSTACK_LOADING_TIMEOUT: 10, // seconds
  BILLING_URL: process.env.REACT_APP_BILLING_URL,
  URL_DOMAIN: process.env.REACT_APP_URL_DOMAIN,
  GOOGLE_EMAIL_OAUTH_SUBDOMAIN: process.env.REACT_APP_GOOGLE_EMAIL_OAUTH_SUBDOMAIN,
  GOOGLE_EMAIL_OAUTH_URL: process.env.REACT_APP_GOOGLE_EMAIL_OAUTH_URL,
  MICROSOFT_EMAIL_OAUTH_SUBDOMAIN: process.env.REACT_APP_MICROSOFT_EMAIL_OAUTH_SUBDOMAIN,
  MICROSOFT_EMAIL_OAUTH_URL: process.env.REACT_APP_MICROSOFT_EMAIL_OAUTH_URL,
  REACT_APP_SCHEDULE_CUSTOM_PLAN_CONSULTATION_URL: process.env.REACT_APP_SCHEDULE_CUSTOM_PLAN_CONSULTATION_URL,
  REACT_APP_GEN_AI_PREFERENCES_GUIDE_URL: process.env.REACT_APP_GEN_AI_PREFERENCES_GUIDE_URL,
  HOW_TO_GETTING_MORE_MATCHES_URL: process.env.REACT_APP_HOW_TO_GETTING_MORE_MATCHES_URL,
  SCHEDULE_MEETING_URL_SALES: process.env.REACT_APP_SCHEDULE_MEETING_URL_SALES,
  TALENT_NETWORK_INFO_URL: process.env.REACT_APP_TALENT_NETWORK_INFO_URL,
  HOW_TO_INCREASE_REPLAY_RATE_URL: process.env.REACT_APP_HOW_TO_INCREASE_REPLAY_RATE_URL,
  BYEBIAS_LEARN_MORE_URL: process.env.BYEBIAS_LEARN_MORE_URL,
  OUTREACH_GOOGLE_CLIENT_ID: process.env.REACT_APP_OUTREACH_GOOGLE_CLIENT_ID,
  OUTREACH_MICROSOFT_CLIENT_ID: process.env.REACT_APP_OUTREACH_MICROSOFT_OAUTH_CLIENT_ID,
  USERFLOW_TOKEN: process.env.REACT_APP_USERFLOW_TOKEN || '',
  RUDDERSTACK_WRITE_KEY: process.env.REACT_APP_RUDDERSTACK_WRITE_KEY || '',
  RUDDERSTACK_DATA_PLANE_URL: process.env.REACT_APP_RUDDERSTACK_DATA_PLANE_URL || '',
  RUDDERSTACK_API_URL: process.env.REACT_APP_RUDDERSTACK_API_URL || '',
  STRIPE_PRICING_TABLE_PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PRICING_TABLE_PUBLISHABLE_KEY || '',
  STRIPE_PRICING_TABLE_ID: process.env.REACT_APP_STRIPE_PRICING_TABLE_ID || '',
  INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_APP_ID || '',
  APP_VERSION: process.env.REACT_APP_VERSION || '',
  APP_ENV: process.env.REACT_APP_ENV || '',
  SHOULD_CHECK_APP_VERSION: [APP_ENV.PRODUCTION, APP_ENV.DEVELOPMENT].includes(process.env.REACT_APP_ENV || ''),
  TALENT_AVATAR_URL: process.env.REACT_APP_TALENT_AVATAR_URL || '',
  LANGUAGE_CACHE: process.env.LANGUAGE_CACHE || true,
};
