import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import BasePositionAssistant from '../base-position-assistant/base-position-assistant.component';
import { PositionAssistantProps } from '../base-position-assistant/base-position-assistant';
import { ANIMATED_FLUENT_UI_EMOJIS } from '../../../../../assets';

const ACTIVATED_AUTOMATED_OUTREACH_RATE = 50;

const RocketImg = () => (
  <img
    src={ ANIMATED_FLUENT_UI_EMOJIS.ROCKET }
    alt="Rocket"
    width="112"
    height="112"
  />
);

const ActivatedAutomatedOutreachAssistant = ({
  actionHandler,
}: PositionAssistantProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BasePositionAssistant
      title={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ACTIVATED_AUTOMATED_OUTREACH.TITLE) }
      description={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ACTIVATED_AUTOMATED_OUTREACH.DESCRIPTION) }
      Icon={ RocketImg }
      rate={ ACTIVATED_AUTOMATED_OUTREACH_RATE }
      action={ {
        title: translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ACTIVATED_AUTOMATED_OUTREACH.ACTION_TITLE),
        handler: actionHandler,
      } }
    />
  );
};

export default ActivatedAutomatedOutreachAssistant;
