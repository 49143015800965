import { PricingPlanEnum } from '../../../../enums/user-plan.enum';
import { useGetMyAccountSelector } from '../../../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import MatchLimitDailyPro from './match-daily-limit-pro/match-daily-limit-pro.component';
import MatchLimitDailyFree from './match-daily-limit-free/match-daily-limit-free.component';

const PositionStatistics = ():JSX.Element => {
  const { data: { plan } } = useGetMyAccountSelector();

  if (plan?.pricingPlan !== PricingPlanEnum.TRIAL) {
    return <MatchLimitDailyPro />;
  }

  return <MatchLimitDailyFree />;
};

export default PositionStatistics;
