import { ELEMENTS_DURATION } from './durations';

const {
  WELCOME_SCREEN,
  WELCOME_SCREEN_SENTENCE_DURATION,
  SCREEN_ANIMATED_DURATION,
  PAGE_TITLE_DURATION,
  LETS_BEGIN_CONTENT_DURATION,
  TELL_US_MORE_TITLE_DURATION,
  TELL_US_MORE_CONTENT_DURATION,
  JOB_DESCRIPTION_TITLE_DURATION,
  VERIFICATION_SCREEN_TITLE_DURATION,
  VERIFICATION_SCREEN_CONTENT_DURATION,
} = ELEMENTS_DURATION;

export const LETS_BEGIN_BASIC_DELAY = 2000;
export const BASIC_DELAY = 500;

export const WELCOME_SCREEN_BASIC_DELAY = BASIC_DELAY + WELCOME_SCREEN_SENTENCE_DURATION;

export const WELCOME_SCREEN_FIRST_SENTENCE_DELAY = WELCOME_SCREEN + WELCOME_SCREEN_BASIC_DELAY;
export const WELCOME_SCREEN_SECOND_SENTENCE_DELAY = WELCOME_SCREEN_FIRST_SENTENCE_DELAY + WELCOME_SCREEN_BASIC_DELAY;
export const WELCOME_SCREEN_THIRD_SENTENCE_DELAY = WELCOME_SCREEN_SECOND_SENTENCE_DELAY + WELCOME_SCREEN_BASIC_DELAY;
export const SCREEN_ANIMATED_DELAY = WELCOME_SCREEN_THIRD_SENTENCE_DELAY +
WELCOME_SCREEN_BASIC_DELAY + LETS_BEGIN_BASIC_DELAY;

export const PAGE_TITLE_DELAY = SCREEN_ANIMATED_DELAY +
  SCREEN_ANIMATED_DURATION;

export const PAGE_CONTENT_DELAY = BASIC_DELAY;

export const PAGE_CONTENT_DELAY_WITH_WELCOME_DELAY = PAGE_TITLE_DELAY + PAGE_TITLE_DURATION;

export const LETS_BEGIN_TYPING_NAME_SENTENCE_DELAY = PAGE_CONTENT_DELAY_WITH_WELCOME_DELAY +
LETS_BEGIN_CONTENT_DURATION;

export const TELL_US_MORE_LEVEL_OF_EXPERIENCE_DELAY = TELL_US_MORE_TITLE_DURATION +
TELL_US_MORE_CONTENT_DURATION + BASIC_DELAY;
export const JOB_DESCRIPTION_SUBTITLE_DELAY = JOB_DESCRIPTION_TITLE_DURATION;
export const JOB_DESCRIPTION_CONTENT_DELAY = JOB_DESCRIPTION_SUBTITLE_DELAY + BASIC_DELAY;
export const VERIFICATION_SCREEN_DELAY = VERIFICATION_SCREEN_TITLE_DURATION +
VERIFICATION_SCREEN_CONTENT_DURATION;
