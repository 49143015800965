import { MatchResponse } from '../store/rtk-query/apis/match/dto/response/match.response';
import { AtsCandidateDto } from '../@types/api';
import { dataFormatter } from './formatters';
import axiosInstance from '.';

export const getMatch = async (positionId: string, matchId: string): Promise<MatchResponse> => {
  // TODO: change to rtk
  const response = await axiosInstance.get(`/v1/positions/${positionId}/matches/${matchId}`);
  const formattedData = dataFormatter(response.data);

  return formattedData as MatchResponse;
};

export const setMatchAsViewed = async (positionId: string, matchId: string): Promise<null> => {
  // TODO: change to rtk
  const response = await axiosInstance.put(`/v1/positions/${positionId}/matches/${matchId}/view`);

  return response.data;
};

export const addMatchToAts = async (positionId: string, matchId: string):Promise<AtsCandidateDto>  => {
  // TODO: change to rtk
  const url = `/v1/positions/${positionId}/matches/${matchId}/ats`;
  const response = await axiosInstance.post(url);

  return response.data;
};
