import { Skeleton } from '@mui/material';
import { THEME } from '../../../../../themes';
import { Body, Container, Header } from './message-preview-skeleton.styled';
import { MessagePreviewSkeletonProps } from './message-preview-skeleton';

// TODO should check with Israel
const MessagePreviewSkeleton = ({ animation }: MessagePreviewSkeletonProps): JSX.Element => {
  return (
    <Container>
      <Header>
        <Skeleton animation={ animation } variant="rectangular" width={ 41 } sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width={ 41 } sx={ { fontSize: '12px', borderRadius: THEME.RADIUS.CIRCLE } } />
      </Header>
      <Body>
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '12px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '12px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
      </Body>
    </Container>
  );
};

export default MessagePreviewSkeleton;
