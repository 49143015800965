import React, { useEffect, useRef } from 'react';
import log from 'loglevel';
import { getOriginFromUrl } from '../../../utils';
import {
  Container, DummyButton, Iframe, Loader,
} from './oauth-login-iframe-container.styled';
import { OAuthLoginIframeContainerProps } from './oauth-login-iframe-container';

const logger = log.getLogger('OAUTH_IFRAME_CONTAINER');

export const OAuthLoginIframeContainer = ({
  successHandler, errorHandler, iframeSrc, buttonIcon, buttonText, readPermissionsApproved,
}: OAuthLoginIframeContainerProps): JSX.Element | null => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleNewMessage = (message: MessageEvent) => {
    if (message.type !== 'message') {
      logger.debug('skipping new message of type', message.type);

      return;
    }

    if (message.origin !== iframeSrc) {
      logger.debug(`ignoring messages of unknown origins. senderOrigin: ${message.origin}`, { senderOrigin: message.origin });

      return;
    }

    if (message.data.code) {
      successHandler(message.data.code);

      return;
    }

    if (message.data.error) {
      logger.warn('oauth iframe on message error!', { error: message.data.error });
      errorHandler?.(message.data.error);
      
      return;
    }

    logger.debug('skipping message without expected data', { message });
  };

  useEffect(() => {
    window.addEventListener('message', handleNewMessage);

    return () => {
      window.removeEventListener('message', handleNewMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!iframeSrc) {
    logger.error('iframeSrc is undefined!', { iframeSrc });
    
    return null;
  }

  return (
    <Container>
      <Iframe onLoad={ () => {
        logger.debug('iframe loaded!', { iframeSrc });
        iframeRef?.current?.contentWindow?.postMessage(
          { parentOrigin: window.location.origin },
          getOriginFromUrl(iframeSrc),
        );
      } }
      ref={ iframeRef }
      title="Email oauth"
      src={ `${iframeSrc}?readPermissionsApproved=${readPermissionsApproved}` }
      />
      <DummyButton disabled>
        { buttonIcon }
        { buttonText }
        <Loader size="24px"  />
      </DummyButton>
    </Container>
  );
};
