export enum SubDomainType {
  None,
  GlobalLogin,
  GlobalActivation,
  Account,
  Onboarding,
  GoogleEmailOAuth,
}

export enum UserIdentifyMethod  {
  Local = 'Local',
  Google = 'Google',
}
