import { FormikErrors, useFormik } from 'formik';
import { isEmpty, isUndefined, omitBy } from 'lodash';
import { FieldHelperProps, FieldMetaProps } from 'formik/dist/types';
import {
  JobRequirement,
  PositionResponse,
  PositionSkillResponse,
  SimilarTitle,
} from '../../../store/rtk-query/apis/position/dto/response/position.response';
import { UpdatePositionQueryArguments } from '../../../store/rtk-query/apis/position/dto/query-arguments/update-position.query-arguments';
import { CompanySizeTierEnum } from '../../../enums/company-size-tier.enum';
import { ExactLocationDto, LocationDto } from '../../../models/dto/location.dto';
import { createLocationObject, locationsObjectToArray } from '../../../utils/location';
import { REMOTE_POSITION } from '../../../hooks/location-autocomplete-hook/location-autocomplete-hook.consts';
import { PositionRunTriggerTypeEnum } from '../../../enums/position-run-trigger-type.enum';
import { LevelOfExperience } from '../../../enums/level-of-experience.enum';
import { getDiffBetweenTwoObjects } from '../../../utils';
import { EditPositionPreferencesFormikKeys } from './edit-position-dialog.enums';

const createJobRequirementRequestDto = (requirements?: PositionJobRequirementState[]): JobRequirement[] | undefined => {
  return requirements
    ?.filter((requirement) => requirement.selected)
    .map((requirement) => ({ id: requirement.id, value: requirement.title, required: requirement.required }));
};

const mapStringArrayToPositionJobRequirementState = (array?: string[]): PositionJobRequirementState[] => {
  return array?.map((text: string) => ({ title: text, selected: true })) || [];
};

const mapJobRequirementToPositionJobRequirementState = (jobRequirement?: JobRequirement[]): PositionJobRequirementState[] => {
  return jobRequirement?.map(({ id, value, required }) => ({
    ...(id !== undefined && { id }),
    title: value,
    ...(required !== undefined && { required }),
    selected: true,
  })) || [];
};

const mapSkillsToPositionJobRequirementState = (skills?: PositionSkillResponse[]): PositionJobRequirementState[] => {
  return skills?.map((skill: PositionSkillResponse) => (
    {
      title: skill.name,
      selected: true,
      ...(skill.required !== undefined && { required: skill.required }),
    })) || [];
};

export type EditPositionPreferencesFormikValues = {
  [EditPositionPreferencesFormikKeys.JobTitle]: string,
  [EditPositionPreferencesFormikKeys.PrioritizedTitles]: SimilarTitle[],
  [EditPositionPreferencesFormikKeys.ExcludedTitles]: SimilarTitle[],
  [EditPositionPreferencesFormikKeys.Locations]: ExactLocationDto[],
  [EditPositionPreferencesFormikKeys.RemotePosition]: boolean,
  [EditPositionPreferencesFormikKeys.LevelsOfExperience]: LevelOfExperience[] | null,
  [EditPositionPreferencesFormikKeys.OverallLevelsOfExperience]: LevelOfExperience[] | null,
  [EditPositionPreferencesFormikKeys.CompanyBackground]: PositionJobRequirementState[],
  [EditPositionPreferencesFormikKeys.CompanySizes]: CompanySizeTierEnum[],
  [EditPositionPreferencesFormikKeys.Degrees]: PositionJobRequirementState[],
  [EditPositionPreferencesFormikKeys.FieldsOfStudy]: PositionJobRequirementState[],
  [EditPositionPreferencesFormikKeys.Skills]: PositionJobRequirementState[],
};

export type EditPositionPreferencesFormikErrors = {
  [EditPositionPreferencesFormikKeys.JobTitle]: boolean,
  [EditPositionPreferencesFormikKeys.PrioritizedTitles]: boolean,
  [EditPositionPreferencesFormikKeys.ExcludedTitles]: boolean,
  [EditPositionPreferencesFormikKeys.Locations]: boolean,
  [EditPositionPreferencesFormikKeys.RemotePosition]: boolean,
  [EditPositionPreferencesFormikKeys.LevelsOfExperience]: boolean,
  [EditPositionPreferencesFormikKeys.OverallLevelsOfExperience]: boolean,
  [EditPositionPreferencesFormikKeys.CompanyBackground]: boolean,
  [EditPositionPreferencesFormikKeys.CompanySizes]: boolean,
  [EditPositionPreferencesFormikKeys.Degrees]: boolean,
  [EditPositionPreferencesFormikKeys.FieldsOfStudy]: boolean,
  [EditPositionPreferencesFormikKeys.Skills]: boolean,
};

export type EditPositionPreferencesPreferencesFormik = EditPositionPreferencesFormikValues & {
  setValueByKey: (key: EditPositionPreferencesFormikKeys, value: unknown) => void,
  save: () => Promise<void>;
  dirty: boolean,
  isValid: boolean,
  errors: FormikErrors<EditPositionPreferencesFormikValues>,
  getFieldMeta: (name: string) => FieldMetaProps<unknown>,
  getFieldHelpers: (name: string) => FieldHelperProps<unknown>,
};

const generateInitialPositionLocation = (position?: PositionResponse): LocationDto[] => {
  if (!position) {
    return [];
  }

  if (position.remotePosition) {
    return [REMOTE_POSITION];
  }

  return locationsObjectToArray(position.locations);
};

const generateInitialValue = (position?: PositionResponse): EditPositionPreferencesFormikValues => {
  return {
    [EditPositionPreferencesFormikKeys.JobTitle]: position?.jobTitle || '',
    [EditPositionPreferencesFormikKeys.PrioritizedTitles]: position?.prioritizedTitles || [],
    [EditPositionPreferencesFormikKeys.ExcludedTitles]: position?.excludedTitles || [],
    [EditPositionPreferencesFormikKeys.Locations]: generateInitialPositionLocation(position),
    [EditPositionPreferencesFormikKeys.RemotePosition]: !!position?.remotePosition,
    [EditPositionPreferencesFormikKeys.LevelsOfExperience]: position?.levelsOfExperience || null,
    [EditPositionPreferencesFormikKeys.OverallLevelsOfExperience]: position?.overallLevelsOfExperience || null,
    [EditPositionPreferencesFormikKeys.CompanyBackground]: mapJobRequirementToPositionJobRequirementState(position?.companyBackground),
    [EditPositionPreferencesFormikKeys.CompanySizes]: position?.companySizes || [],
    [EditPositionPreferencesFormikKeys.Degrees]: mapStringArrayToPositionJobRequirementState(position?.educationDegrees),
    [EditPositionPreferencesFormikKeys.FieldsOfStudy]: mapJobRequirementToPositionJobRequirementState(position?.educationFieldOfStudies),
    [EditPositionPreferencesFormikKeys.Skills]: mapSkillsToPositionJobRequirementState(position?.skills),
  };
};

export const useEditPositionPreferencesFormik = (
  props: {
    submitHandler: (positionSequencePreferences: UpdatePositionQueryArguments) => void,
    position?: PositionResponse,
    enableReinitialize?: boolean,
    triggerType: PositionRunTriggerTypeEnum,
  },
): EditPositionPreferencesPreferencesFormik => {
  const {
    submitHandler, position, enableReinitialize, triggerType,
  } = props;
  const initialValues = generateInitialValue(position);
  const formik = useFormik<EditPositionPreferencesFormikValues>({
    initialValues,
    enableReinitialize,
    onSubmit: (values: EditPositionPreferencesFormikValues) => {
      if (!position) {
        return undefined;
      }

      const changedData = getDiffBetweenTwoObjects<EditPositionPreferencesFormikValues>(initialValues, values);
      const locations = changedData.locations?.length ? createLocationObject(changedData.locations) : undefined;

      const requestArguments = {
        positionId: position?.id,
        locations: changedData.remotePosition ? undefined : locations,
        remotePosition: changedData.remotePosition,
        jobTitle: changedData.jobTitle,
        prioritizedTitles: changedData.prioritizedTitles,
        excludedTitles: changedData.excludedTitles,
        levelsOfExperience: changedData.levelsOfExperience,
        overallLevelsOfExperience: changedData.overallLevelsOfExperience,
        degrees: changedData.degrees?.map((item) => item.title),
        companySizes: changedData.companySizes,
        fieldOfStudies: createJobRequirementRequestDto(changedData.fieldsOfStudy),
        companyBackground: createJobRequirementRequestDto(changedData.companyBackground),
        skills: createJobRequirementRequestDto(changedData.skills),
        triggerType,
      };

      return submitHandler(omitBy(requestArguments, isUndefined) as UpdatePositionQueryArguments);
    },
    validate: (values: EditPositionPreferencesFormikValues) => {
      const errors: EditPositionPreferencesFormikErrors = {} as EditPositionPreferencesFormikErrors;
      if (!values[EditPositionPreferencesFormikKeys.JobTitle]) {
        errors[EditPositionPreferencesFormikKeys.JobTitle] = true;
      }

      const locations = values[EditPositionPreferencesFormikKeys.Locations];
      const isEachLocationValid = locations.length && locations.every((location) => !isEmpty(location));
      if (!isEachLocationValid) {
        errors[EditPositionPreferencesFormikKeys.Locations] = true;
      }

      const hasLevelsOfExperience =
        values[EditPositionPreferencesFormikKeys.LevelsOfExperience]?.length || values[EditPositionPreferencesFormikKeys.OverallLevelsOfExperience]?.length;
      if (!hasLevelsOfExperience) {
        errors[EditPositionPreferencesFormikKeys.LevelsOfExperience] = true;
        errors[EditPositionPreferencesFormikKeys.OverallLevelsOfExperience] = true;
      }

      return errors;
    },
  });

  return {
    [EditPositionPreferencesFormikKeys.JobTitle]: formik.values[EditPositionPreferencesFormikKeys.JobTitle],
    [EditPositionPreferencesFormikKeys.PrioritizedTitles]: formik.values[EditPositionPreferencesFormikKeys.PrioritizedTitles],
    [EditPositionPreferencesFormikKeys.ExcludedTitles]: formik.values[EditPositionPreferencesFormikKeys.ExcludedTitles],
    [EditPositionPreferencesFormikKeys.Locations]: formik.values[EditPositionPreferencesFormikKeys.Locations],
    [EditPositionPreferencesFormikKeys.RemotePosition]: formik.values[EditPositionPreferencesFormikKeys.RemotePosition],
    [EditPositionPreferencesFormikKeys.LevelsOfExperience]: formik.values[EditPositionPreferencesFormikKeys.LevelsOfExperience],
    [EditPositionPreferencesFormikKeys.OverallLevelsOfExperience]: formik.values[EditPositionPreferencesFormikKeys.OverallLevelsOfExperience],
    [EditPositionPreferencesFormikKeys.CompanyBackground]: formik.values[EditPositionPreferencesFormikKeys.CompanyBackground],
    [EditPositionPreferencesFormikKeys.CompanySizes]: formik.values[EditPositionPreferencesFormikKeys.CompanySizes],
    [EditPositionPreferencesFormikKeys.Degrees]: formik.values[EditPositionPreferencesFormikKeys.Degrees],
    [EditPositionPreferencesFormikKeys.FieldsOfStudy]: formik.values[EditPositionPreferencesFormikKeys.FieldsOfStudy],
    [EditPositionPreferencesFormikKeys.Skills]: formik.values[EditPositionPreferencesFormikKeys.Skills],
    setValueByKey: (key, value) => {
      formik.setFieldValue(key, value);
    },
    save: formik.submitForm,
    dirty: formik.dirty,
    errors: formik.errors,
    isValid: formik.isValid,
    getFieldMeta: formik.getFieldMeta,
    getFieldHelpers: formik.getFieldHelpers,
  };
};
