import styled from 'styled-components';
import {
  MatchEngineProgressBarRank,
} from '../../../../../../components/position-insights/match-engine-progress-bar/match-engine-progress-bar.styled';
import { THEME } from '../../../../../../themes';
import { hexToRgbA } from '../../../../../../utils/colors';
import { CaptionFont } from '../../../../../../themes/fonts';

export const Container = styled.div`
  width: 360px;
  min-height: 112px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: ${THEME.SPACING.MICRO};
`;

export const ProgressBarContainer = styled.div`
  padding: ${THEME.SPACING.NANO};
  border-radius: ${THEME.RADIUS.ROUND};
  border: solid 2px ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.GHOST)};
  background-color: ${THEME.COLORS.PURE_WHITE};
`;

export const ProgressBarStyled = styled(MatchEngineProgressBarRank)`
  height: 26px;
  position: unset;
  animation: unset;
`;

export const RulerContainer = styled.div`
  width: 100%;
  padding: 0 ${THEME.SPACING.TINY};
  display: flex;
  justify-content: space-between;
`;

export const RulerItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Badge = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
  border-radius: ${THEME.RADIUS.CIRCLE};
`;

export const Label = styled.div`
  ${CaptionFont};
  font-weight: 600;
  color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
`;
