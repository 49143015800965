import { useEffect, useRef, useState } from 'react';
import { getRandomIntBetweenRange } from '../../../utils/number';
import {
  Blinker,
  Container,
  GhostText,
  TypingContainer,
} from './text-typing.styled';

const TAG_START_CHAR = '<';
const TAG_END_CHAR = '>';
const LETTER_TYPING_IN_MS = 10;
const LETTER_PAUSE_IN_MS = 150;
const WORDS_PAUSE_MIN_RANGE = 3;
const WORDS_PAUSE_MAX_RANGE = 8;

export const TextTyping = ({
  typingText,
  ghostText,
  shouldStartTyping,
  hideCaretOnFinish,
}: TextTypingProps): JSX.Element | null => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [showCaret, setShowCaret] = useState<boolean>(true);

  // Typing variables
  let letterIndex = 0;
  let isTag: boolean;
  let spaceCount = 0;
  let typingTimeoutId: NodeJS.Timeout;
  let wordsPauseLength = getRandomIntBetweenRange(WORDS_PAUSE_MIN_RANGE, WORDS_PAUSE_MAX_RANGE);

  const typeText = (): void => {
    if (!textRef.current || !typingText || letterIndex === typingText.length) {
      if (hideCaretOnFinish) {
        setShowCaret(false);
      }

      return;
    }

    const text = typingText.slice(0, ++letterIndex);
    textRef.current.innerHTML = `${text}`;
    const char = text.slice(-1);
    if (char === TAG_START_CHAR) {
      isTag = true;
    }

    if (char === TAG_END_CHAR) {
      isTag = false;
    }

    if (char === ' ') {
      spaceCount++;
    }

    if (isTag) {
      typeText();
    } else {
      const longTimeout = char === ' ' && (spaceCount % wordsPauseLength === 0);

      if (longTimeout) {
        spaceCount = 0;
        wordsPauseLength = getRandomIntBetweenRange(WORDS_PAUSE_MIN_RANGE, WORDS_PAUSE_MAX_RANGE);
      }

      typingTimeoutId = setTimeout(typeText, longTimeout ? LETTER_PAUSE_IN_MS : LETTER_TYPING_IN_MS);
    }
  };

  useEffect(() => {
    if (textRef.current && shouldStartTyping) {
      typeText();
    }

    return () => {
      if (typingTimeoutId) {
        clearTimeout(typingTimeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textRef.current, shouldStartTyping]);

  return (
    <Container>
      <TypingContainer>
        <span ref={ textRef } />
        { showCaret && <Blinker />}
      </TypingContainer>
      <GhostText>{ ghostText }</GhostText>
    </Container>
  );
};
