import styled from 'styled-components';
import { ASSETS } from '../../../../assets';
import { THEME } from '../../../../themes';

const BaseIcon = `
  margin-right: ${THEME.SPACING.BASE};
`;

export const HighScoreIcon = styled(ASSETS.TALENT_NETWORK_HIGH_SCORE)`
  ${BaseIcon}
`;

export const MedLowIcon = styled(ASSETS.TALENT_NETWORK_MED_LOW_SCORE)`
  ${BaseIcon}
`;
