import {
  Divider,
  IconSkeleton,
  CompanyNameLine,
  ColumnContainer,
  TitlesContainer,
  CompanyLocationLine,
  RowContainer,
  IndustriesSkeleton,
  CompanyParamSkeleton,
  CompanyDescriptionLine,
} from './styled';

export const CompanyModalSkeleton = (): JSX.Element => {
  return (
    <ColumnContainer>
      <RowContainer>
        <IconSkeleton />
        <ColumnContainer>
          <TitlesContainer>
            <CompanyNameLine />
            <CompanyLocationLine />
          </TitlesContainer>
          <RowContainer>
            <IndustriesSkeleton />
            <IndustriesSkeleton />
            <IndustriesSkeleton />
          </RowContainer>
        </ColumnContainer>

      </RowContainer>

      <Divider />

      <RowContainer >
        <ColumnContainer>
          <CompanyParamSkeleton />
          <CompanyParamSkeleton />
          <CompanyParamSkeleton />
          <CompanyParamSkeleton />
        </ColumnContainer>

        <ColumnContainer>
          <CompanyDescriptionLine />
          <CompanyDescriptionLine />
          <CompanyDescriptionLine />
          <CompanyDescriptionLine />
        </ColumnContainer>

      </RowContainer>

    </ColumnContainer>
  );
};
