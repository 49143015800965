/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ASSETS } from '../../../../../assets';
import STRING_KEYS from '../../../../../language/keys';
import {
  Wrapper,
  sx,
  Error,
  Input,
} from './company-input.style';

type AutocompleteCompanyProps = {
  onInputValueChange: (event:React.SyntheticEvent, newInputValue:string) => void,
  disabled?: boolean,
  isError?: boolean,
  errorMessage: string,
}
const CompanyInput = ({
  onInputValueChange,
  isError,
  errorMessage,
}: AutocompleteCompanyProps):JSX.Element => {
  const { t: translate } = useTranslation();

  const renderInput = () => {
    const extendedParams = {
      InputProps: {
        autoFocus: true,
        placeholder: translate(STRING_KEYS.ONBOARDING.ONBOARDING_COMPANY_MODAL_PLACEHOLDER),
        sx: sx.input,
        error: isError,
        startAdornment: (
          <InputAdornment sx={ sx.inputAdornment } position="start">
            <ASSETS.SOCIAL_LINKEDIN_COMPONENT />
          </InputAdornment>
        ),
      },
    };

    return (
      <Input
        { ...extendedParams }
        onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
          onInputValueChange(e, e.target.value);
        } }
      />
    );
  };

  return (
    <Wrapper>
      { renderInput() }
      {isError && <Error>{ errorMessage }</Error>}
    </Wrapper>
  );
};

CompanyInput.defaultProps = {
  disabled: false,
  isError: false,
};

export default CompanyInput;
