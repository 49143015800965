import styled from 'styled-components';
import { THEME } from '../../../../themes';
import {
  Body1Font,
  ButtonFont,
  CaptionFont,
  Title2Font,
} from '../../../../themes/fonts';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 760px;
  margin: 0 auto;
  padding-bottom: ${THEME.SPACING.TINY};
`;

export const LogoContainer = styled.div`
  display: block;
  margin-top: ${THEME.SPACING.TINO};
  margin-bottom: ${THEME.SPACING.GRAND};
`;

export const Title = styled.div`
  ${Title2Font}
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  margin-bottom: ${THEME.SPACING.MEDIUM};
  text-align: center;
  line-height: normal;
`;

export const ContentContainer = styled.div`
  ${Body1Font}
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  opacity: ${THEME.OPACITY.MED};
  margin-bottom: ${THEME.SPACING.MEDIUM};
  text-align: center;

  & a:hover {
    text-decoration: underline;
  }
`;

export const HomePageLink = styled.a`
  ${ButtonFont}
  color: ${THEME.COLORS.ACCENT};
  margin-bottom: ${THEME.SPACING.XLOOSE};
  text-align: center;

  :hover {
    opacity: 0.8;
  }
`;

// todo: color from theme
export const ImageWrapperEffect = styled.div`
  width: 480px;
  max-width: 98%;
  position: relative;
  background-color: rgba(236, 9, 130, 0.7);
`;

export const ErrorImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
  mix-blend-mode: screen;
`;

export const ErrorCode = styled.div`
  ${CaptionFont}
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  opacity: ${THEME.OPACITY.MED};
  margin-top: ${THEME.SPACING.MEDIUM};
`;
