import { Skeleton } from '@mui/material';
import {
  SkeletonContainer, SkeletonDetailsContainer, SkeletonSummeryContainer,
} from './conversation-group-skeleton.styled';
import { ConversationsGroupSkeletonProps } from './conversation-group-skeleton';

const ConversationGroupSkeleton = ({ isAutoMode }: ConversationsGroupSkeletonProps): JSX.Element => {
  return (
    <SkeletonContainer isAutoMode={ isAutoMode }>
      <SkeletonSummeryContainer>
        <Skeleton variant="rectangular" animation="wave" width={ 210 } height={ 25 } />
      </SkeletonSummeryContainer>
      <SkeletonDetailsContainer>
        <Skeleton variant="rectangular" animation="wave" width={ 180 } height={ 40 } />
        <Skeleton variant="rectangular" animation="wave" width={ 180 } height={ 40 } />
        <Skeleton variant="rectangular" animation="wave" width={ 180 } height={ 40 } />
      </SkeletonDetailsContainer>
    </SkeletonContainer>
  );
};

export default ConversationGroupSkeleton;
