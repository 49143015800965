/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SpinnerCircle, SpinnerSvg } from './spinner.styled';

const Spinner = ({ ...otherProps }): JSX.Element => {
  return (
    <SpinnerSvg
      viewBox="25 25 50 50"
      { ...otherProps }
    >
      <SpinnerCircle cx="50" cy="50" r="20" />
    </SpinnerSvg>
  );
};

export default Spinner;
