import styled from 'styled-components';
import BaseMenu from '../perfect-base-menu/perfect-base-menu.component';
import { Menu } from '../perfect-base-menu/perfect-base-menu.styled';
import IconButton from '../Buttons/icon-button/icon-button.component';
import { THEME } from '../../../themes';
import { ASSETS } from '../../../assets';
import { hexToRgbA } from '../../../utils/colors';

export const Container = styled(BaseMenu)`
  overflow: hidden;
  
  ${Menu} {
    padding: 0;
  }
`;

export const CloseButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  border-radius: ${THEME.RADIUS.CIRCLE};
  position: absolute;
  top: ${THEME.SPACING.TINY};
  right: ${THEME.SPACING.TINY};
  background-color: ${THEME.COLORS.PURE_WHITE};
  z-index: 1;
`;

export const CloseButtonIcon = styled(ASSETS.X_SIGN)`
  width: 12px;
  height: 12px;
  color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW)};
`;
