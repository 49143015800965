import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import StepContainer from '../step-container/step-container.component';
import STRING_KEYS from '../../../../../../language/keys';
import { ANALYTICS_EVENTS } from '../../../../../../consts/analytics';
import { LocationStepBody } from './location-step-body/location-step-body.component';
import { LocationsStepProps } from './locations-step';

const LocationsStep = ({
  locations,
  remotePosition,
  changeHandler,
  remotePositionChangeHandler,
  backButtonClickHandler,
}: LocationsStepProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [internalLocations, setInternalLocations] = useState(locations);
  const [internalRemotePosition, setInternalRemotePosition] = useState(remotePosition);

  const hasEmptyLocation = useMemo(() => internalLocations.some(isEmpty), [internalLocations]);
  const isLocationsDataEqual = useMemo(
    () => isEqual(locations, internalLocations) && remotePosition === internalRemotePosition,
    [internalLocations, internalRemotePosition, locations, remotePosition],
  );

  const continueButtonHandler = () => {
    changeHandler(internalLocations);
    if (remotePosition !== internalRemotePosition) {
      remotePositionChangeHandler(internalRemotePosition);
    }
  };

  return (
    <StepContainer
      title={ translate(STRING_KEYS.MATCH_TUNE.LOCATIONS_STEP.TITLE) }
      subtitle={ translate(STRING_KEYS.MATCH_TUNE.LOCATIONS_STEP.SUBTITLE) }
      backButtonHandler={ backButtonClickHandler }
      continueButtonHandler={ continueButtonHandler }
      isContinueButtonDisabled={ isLocationsDataEqual || hasEmptyLocation }
      eventName={ ANALYTICS_EVENTS.MATCH_TUNE_LOCATIONS_STEP_VIEWED }
    >
      <LocationStepBody
        locations={ internalLocations }
        remotePosition={ internalRemotePosition }
        changeHandler={ (changedData) => setInternalLocations(changedData) }
        remotePositionChangeHandler={ (changedData) => setInternalRemotePosition(changedData) }
      />
    </StepContainer>
  );
};

export default LocationsStep;
