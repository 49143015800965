import { IndeterminateLinearLoader } from '../Loaders/IndeterminateLinearLoader';
import {
  Container,
  CloseButton,
  ModalBackdrop,
  LoaderWrapper,
} from './styled';

type ModalProps = {
  children: JSX.Element | JSX.Element[],
  onClose?: () => void,
  isLoading: boolean,
  width?: number,
  height?: number,
  backgroundBlur?: boolean,
  originalBackgroundColor?: boolean,
  absolutePosition?: boolean,
}

const Modal = ({
  children,
  onClose,
  isLoading,
  width,
  height,
  backgroundBlur = false,
  originalBackgroundColor = false,
  absolutePosition = false,
}: ModalProps): JSX.Element => {
  return (
    <>
      <ModalBackdrop
        onClick={ onClose }
        width={ width }
        height={ height }
        backgroundBlur={ backgroundBlur }
        originalBackgroundColor={ originalBackgroundColor }
      />
      <Container
        width={ width }
        height={ height }
        absolutePosition={ absolutePosition }
      >
        {isLoading && (
          <LoaderWrapper>
            <IndeterminateLinearLoader loaderHeight="4px" />
          </LoaderWrapper>
        )}
        <CloseButton onClick={ onClose } />
        { children }
      </Container>
    </>
  );
};

Modal.defaultProps = {
  width: 640,
  height: 256,
  backgroundBlur: undefined,
  originalBackgroundColor: undefined,
  absolutePosition: undefined,
  onClose: undefined,
};

export default Modal;
