import styled from 'styled-components';
import { FLUENT_UI_EMOJIS } from '../../../../../../assets';
import { IconCss } from '../base-tag/base-funnel-tag.styled';

export const LightIcon = styled(FLUENT_UI_EMOJIS.LIGHT_BUILD)`
  ${IconCss};
`;

export const RocketIcon = styled(FLUENT_UI_EMOJIS.ROCKET)`
  ${IconCss};
`;

export const SeedlingIcon = styled(FLUENT_UI_EMOJIS.SEEDLING)`
  ${IconCss};
`;

export const FireIcon = styled(FLUENT_UI_EMOJIS.FIRE)`
  ${IconCss};
`;

export const HeardIcon = styled(FLUENT_UI_EMOJIS.SPARKLING_HEART)`
  ${IconCss};
`;
