import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import BasePositionAssistant from '../base-position-assistant/base-position-assistant.component';
import { ANIMATED_FLUENT_UI_EMOJIS } from '../../../../../assets';
import { PositionAssistantProps } from '../base-position-assistant/base-position-assistant';

const LoveLetterImg = () => (
  <img
    src={ ANIMATED_FLUENT_UI_EMOJIS.LOVE_LETTER }
    alt="Love Letter"
    width="112"
    height="112"
  />
);

const OutreachToLikeMatches = ({
  actionHandler,
}: PositionAssistantProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BasePositionAssistant
      title={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.OUTREACH_TO_LIKED_MATCHES.TITLE) }
      description={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.OUTREACH_TO_LIKED_MATCHES.DESCRIPTION) }
      Icon={ LoveLetterImg }
      action={ {
        title: translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.OUTREACH_TO_LIKED_MATCHES.ACTION_TITLE),
        handler: actionHandler,
      } }
    />
  );
};

export default OutreachToLikeMatches;
