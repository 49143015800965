export const OUTREACH_AI_INSTRUCTIONS_QUOTES = [
  'Highlight our remote work policy.',
  'Include a Calendly link for scheduling: calendly.com/your-link',
  'Emphasize international travel opportunities.',
  'Highlight our recent ‘Best Workplace’ award.',
  'Mention our quick hiring process.',
  'Use humor—maybe add a lighthearted joke.',
  'Drop in a Star Wars reference.',
  'Highlight support for working parents.',
  'Reference our annual company retreat.',
];
