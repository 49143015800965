import log from 'loglevel';
import { BroadcastChannel } from 'broadcast-channel';
import {
  BROADCAST_CHANNEL_MESSAGE_TYPE,
  BROADCAST_CHANNEL_NAME,
} from '../consts/broadcast_channel';

type SimpleCallback = () => void;

/**
 * This class allows sending broadcast-channel message between different browser tabs.
 * This allows sending events to other tabs and listen to incoming messages.
 */
class BroadcastChannelManager {
  private logger = log.getLogger(BroadcastChannelManager.name);

  private channel: BroadcastChannel | null = null;

  constructor(channelName: string) {
    this.logger.debug('creating new broadcast channel called', channelName);
    this.channel = new BroadcastChannel(channelName);

    this.channel.onmessage = this.onMessageReceived;
  }

  // ---------------------------------------
  // Public Methods
  // ---------------------------------------

  public sendLogoutMessage = () => {
    this.sendMessage(BROADCAST_CHANNEL_MESSAGE_TYPE.LOGOUT);
  }

  // ---------------------------------------
  // Handle Listeners
  // ---------------------------------------

  public addMessageListener = (
    messageType: BROADCAST_CHANNEL_MESSAGE_TYPE,
    callback: SimpleCallback,
  ) => {
    document.addEventListener(messageType, callback);
  }

  public removeMessageListener = (
    messageType: BROADCAST_CHANNEL_MESSAGE_TYPE,
    registeredCallback: SimpleCallback,
  ) => {
    document.removeEventListener(messageType, registeredCallback);
  }

  // ---------------------------------------
  // Inner Logic
  // ---------------------------------------

  private sendMessage = (type: BROADCAST_CHANNEL_MESSAGE_TYPE, payload?: unknown) => {
    this.logger.debug('sending broadcast channel message', {
      type,
      payload,
    });

    this.channel?.postMessage({
      type,
      payload,
    });
  }

  private onMessageReceived = (message: MessageEvent) => {
    this.logger.debug('broadcast channel received new message', JSON.stringify(message.data));

    if (!Object.values(BROADCAST_CHANNEL_MESSAGE_TYPE).includes(message.data?.type)) {
      this.logger.warn('received unknown message in broadcast channel', {
        messageType: message.data?.type,
      });

      return;
    }

    const eventType = message.data.type as BROADCAST_CHANNEL_MESSAGE_TYPE;

    this.logger.debug('detected known event type from broadcast channel message', {
      eventType,
    });

    document.dispatchEvent(new CustomEvent(eventType));
  }
}

export default new BroadcastChannelManager(BROADCAST_CHANNEL_NAME);
