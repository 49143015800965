export const CREATE_POSITION_ANALYTICS_EVENTS = {
  NEW_POSITION_FAILED: 'new_position_failed',
  NEW_POSITION_FINISHED_SUCCESSFULLY: 'new_position_finished_successfully',
  EDIT_POSITION_CLICKED_FROM_HOME_PAGE: 'clicked_on_edit_position',
  EXIT_NEW_POSITION_CLICKED_FROM_HOME_PAGE: 'clicked_on_exit_new_position',
  NEW_POSITION_CLICKED_FROM_HOME_PAGE: 'clicked_on_add_position',
  NEW_POSITION_LETS_GO_CLICKED: 'clicked_on_lets_go_new_position',
  NEW_POSITION_CONTINUE_WITH_GOOGLE_CLICKED: 'clicked_on_continue_with_google_new_position',
  NEW_POSITION_START_PAGE_VIEWED: 'start_new_position_page_viewed',
  NEW_POSITION_INSERT_PARAMETER_CLICKED: 'clicked_on_insert_parameter_new_position',
  NEW_POSITION_STEP_PAGE_VIEWED: 'new_position_steps_page_view',
  NEW_POSITION_CLICKED_ON_NEXT_STEP: 'clicked_on_new_position_next',
  NEW_POSITION_CLICKED_ON_SKIP_STEP: 'clicked_on_new_position_skip',
  NEW_POSITION_EMAIL_VERIFICATION_PAGE_VIEWED: 'email_verification_new_position_page_viewed',
  NEW_POSITION_SUPPORT_BUTTON_CLICKED: 'clicked_on_support_new_position',
  NEW_POSITION_FINISH_PAGE_VIEWED: 'finished_new_position_page_viewed',
  NEW_POSITION_BACK_CLICKED: 'clicked_on_new_position_back',
  NEW_POSITION_PREMIUM_MODAL_SHOWN: 'premium_modal_shown',
};

export const PAGE_NAMES = {
  BASIC_INFO: 'basic_info',
  INDUSTRY_PAGE_VIEWED: 'industry',
  EDUCATION_PAGE_VIEWED: 'education',
  SKILLS_PAGE_VIEWED: 'skills',
  EXPERIENCE_PAGE_VIEWED: 'experince',
  FINISHED_ONBOARDING: 'finished_onboarding',
};
