import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';

const TRANSITION_TIME = 0.2;

export const Slider = styled.span`
  position: absolute;
  cursor: inherit;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${THEME.COLORS_RGB.CONTENT_PRIMARY_LOW};
  transition: ${TRANSITION_TIME}s;
  border-radius: 34px;
`;

export const SliderButton = styled.span`
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: ${TRANSITION_TIME}s;
  border-radius: 50%;
`;

export const StyledSwitch = styled.label<{ disabled?: boolean }>`
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  opacity: ${({ disabled }) => disabled ? 0.2 : 1};
  cursor: ${({ disabled }) => disabled ? 'auto' : 'pointer'};

  :disabled {
    width: 80px;
    pointer-events: none;
  }
  
  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  & input:checked + ${Slider} {
    background-color: ${THEME.COLORS.ACCENT};
    
    & ${SliderButton} {
      transform: translateX(16px);
    }
  }
`;

export const CheckedIcon = styled(ASSETS.V_SIGN)`
  width: 10px;
  height: 10px;
  color: ${THEME.COLORS.ACCENT};
`;

export const UncheckedIcon = styled(ASSETS.X_SIGN)`
  width: 10px;
  height: 10px;
  fill: ${THEME.COLORS_RGB.CONTENT_PRIMARY_LOW};
`;
