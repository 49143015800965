/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { differenceInHours } from 'date-fns';
import { ConversationMinimizedResponseDto } from '../../../store/rtk-query/apis/outreach/dto/response/conversation-minimized.response';
import { ConversationStatusEnum } from '../../../store/rtk-query/apis/outreach/enums/conversation-status.enum';
import { useOutreachState } from '../../../store/selectors/outreach.selectors';
import { conversationsSorted, resetConversationsSort } from '../../../store/slices/outreach-state/outreach-state.toolkit-slice';
import { usePageFilters } from '../../../hooks/use-page-filters.hooks';
import { AppRouting } from '../../../services/appRoutingResolver';
import { ConversationGroupsEnum } from '../../../enums/outreach/conversation-groups.enum';
import { sortMinimizedConversationByLastAction } from '../components/outreach/conversation-group/conversation-group.util';

const generateDefaultSortedConversations = () => new Map(
  [
    [ConversationGroupsEnum.Replied, []],
    [ConversationGroupsEnum.ToOutreach, []],
    [ConversationGroupsEnum.ToFollowup, []],
    [ConversationGroupsEnum.Sent, []],
    [ConversationGroupsEnum.Archived, []],
  ],
);

export const useFilterAndGroupManualConversations = (
  conversations: ConversationMinimizedResponseDto[],
): Map<ConversationGroupsEnum, string[]> => {
  const dispatch = useDispatch();
  const {
    filters,
    getFiltersByKey,
  } = usePageFilters();
  const search = getFiltersByKey(AppRouting.URL_PARAMS.OUTREACH.SEARCH)[0];
  // const statusesFilters = getFiltersByKey(AppRouting.URL_PARAMS.OUTREACH.FILTER_BY_STATUS);
  // const likedUsersFilters = getFiltersByKey(AppRouting.URL_PARAMS.OUTREACH.FILTER_BY_LIKED_USER);
  const [sortedConversations, setSortedConversations] = useState<Map<ConversationGroupsEnum, string[]>>(generateDefaultSortedConversations());
  const { shouldSortConversations } = useOutreachState();

  const filteredConversations = useMemo(() =>  {
    let tempConversations = conversations;
    if (search) {
      tempConversations = conversations?.filter((conversation: ConversationMinimizedResponseDto) => {
        const { match: { talent: { firstName, lastName } } } = conversation;

        return `${firstName} ${lastName}`.toLowerCase().includes(search?.toLowerCase() || '');
      }) || [];
    }

    // Filters are currently not supported from conversations side panel menu
    // if (statusesFilters.length) {
    //   tempConversations = tempConversations?.filter((conversation: ConversationMinimizedResponseDto) => {
    //     const { status } = conversation;
    //
    //     return statusesFilters?.includes(status);
    //   }) || [];
    // }
    // if (likedUsersFilters.length) {
    //   tempConversations = tempConversations?.filter((conversation: ConversationMinimizedResponseDto) => {
    //     const { engagedUserInfo = { id: undefined } } = conversation;
    //
    //     return likedUsersFilters?.includes(engagedUserInfo.id || '');
    //   }) || [];
    // }

    return tempConversations;
  }, [conversations, filters]);

  useEffect(() => {
    dispatch(resetConversationsSort());
  }, [dispatch, filters, conversations.length]);

  useEffect(() => {
    if (shouldSortConversations && filteredConversations) {
      const conversationsMap = filteredConversations.reduce<Map<ConversationGroupsEnum, ConversationMinimizedResponseDto[]>>((acc, conversation) => {
        if (conversation.isArchived) {
          acc.get(ConversationGroupsEnum.Archived)?.push(conversation);

          return acc;
        }

        if (conversation.status === ConversationStatusEnum.Replied) {
          acc.get(ConversationGroupsEnum.Replied)?.push(conversation);

          return acc;
        }

        if (conversation.status === ConversationStatusEnum.New) {
          acc.get(ConversationGroupsEnum.ToOutreach)?.push(conversation);

          return acc;
        }

        const nextSentAt = conversation?.nextOpenLinkedinTask?.sendAt || conversation?.nextScheduledMessage?.sendAt;
        if (nextSentAt && differenceInHours(new Date(nextSentAt), new Date()) < 24) {
          acc.get(ConversationGroupsEnum.ToFollowup)?.push(conversation);
        } else {
          acc.get(ConversationGroupsEnum.Sent)?.push(conversation);
        }

        return acc;
      }, generateDefaultSortedConversations());

      conversationsMap.forEach((groupedConversations) => {
        groupedConversations.sort(sortMinimizedConversationByLastAction);
      });

      const clonedConversationMap = new Map<ConversationGroupsEnum, string[]>(generateDefaultSortedConversations());

      if (conversationsMap.size) {
        conversationsMap.forEach((groupedConversations, key) => {
          const conversationsIds = groupedConversations.map((conversation) => conversation.id);
          clonedConversationMap.set(key, conversationsIds);
        });
      }
      setSortedConversations(clonedConversationMap);
      dispatch(conversationsSorted());
    }
  }, [filteredConversations, shouldSortConversations]);

  return sortedConversations;
};
