import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import STRING_KEYS from '../../../../../language/keys';
import { Container } from './outreach-content-preferences-status.styled';

const OutreachContentPreferencesStatus = ({ isActive }: OutreachContentPreferencesStatusProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const text = useMemo(() => translate(isActive ? STRING_KEYS.ACTIVE : STRING_KEYS.NOT_ACTIVATED), [isActive, translate]);

  return (
    <Container isActive={ isActive }>{ text }</Container>
  );
};

export default OutreachContentPreferencesStatus;
