import { sequenceQuery } from '../sequence.toolkit-api';
import { ApiError } from '../../../../../consts/api';
import { UpdateScheduledMessageQueryArguments } from '../dto/query-arguments/update-scheduled-message.query-arguments';

type UseUpdateScheduledMessageMutationType = [
  (updateScheduledMessage: UpdateScheduledMessageQueryArguments) => void,
  AppSelectorType<void, ApiError>
];

export const useUpdateScheduledMessageMutation = (): UseUpdateScheduledMessageMutationType => {
  const [
    updateScheduledMessage,
    {
      data, isLoading, isError, error: apiError, isSuccess, reset,
    }] = sequenceQuery.useUpdateScheduledMessageMutation();

  const meta = {
    isLoading, isError, error: apiError as ApiError, isSuccess, data, resetState: reset,
  };

  return [
    updateScheduledMessage,
    { ...meta },
  ];
};
