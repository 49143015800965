import log from 'loglevel';
import { useEffect, useState } from 'react';
import { CONFIG } from '../../../config';
import { extractDomainFromUrl } from '../../../utils';
import { MicrosoftLogin } from './microsoft-login/microsoft-login.component';

const logger = log.getLogger('MICROSOFT_EMAIL_OAUTH_FRAME');

// this component is used within iframe!
export const MicrosoftEmailOAuthFrame = (): JSX.Element | null => {
  const [parentOrigin, setParentOrigin] = useState<string | null>(null);
  const searchParams = new URLSearchParams(window.location.search);
  const readPermissionsApproved = searchParams.get('readPermissionsApproved') || true;

  const handleNewMessage = (message: MessageEvent) => {
    if (message.type !== 'message') {
      logger.debug('skipping new message of type', message.type);

      return;
    }

    const senderOrigin = extractDomainFromUrl(message.origin);

    if (senderOrigin !== CONFIG.URL_DOMAIN) {
      logger.debug(`ignoring messages of unknown origins. senderOrigin: ${senderOrigin}`);

      return;
    }

    // todo: skip messages by origin (when servers are ready)
    if (!message.data.parentOrigin) {
      logger.debug('skipping message without expected data', { message });

      return;
    }

    logger.debug('got new message with data', { message });

    setParentOrigin(message.data.parentOrigin);
  };

  useEffect(() => {
    window.addEventListener('message', handleNewMessage);

    return () => {
      window.removeEventListener('message', handleNewMessage);
    };
  }, []);

  useEffect(() => {
    // Check if we have an authorization code in the query string
    const params = new URLSearchParams(window.location.search);
    const parentWindow = window.opener;
    const code = params.get('code');

    if (code) {
      parentWindow.onSuccess(code);
      window.close();
    }

    const error = params.get('error');
    if (error) {
      const errorDescription = params.get('error_description');
      parentWindow.onError(error, errorDescription);
      window.close();
    }
  }, []);

  if (!CONFIG.OUTREACH_MICROSOFT_CLIENT_ID) {
    return null;
  }

  if (!parentOrigin) {
    return null;
  }

  const onMicrosoftLoginSuccess = (code: string) => {
    if (parentOrigin) {
      window.parent.postMessage({ code }, parentOrigin);
    }
  };

  const onMicrosoftLoginError = (error: string, errorDescription: string) => {
    logger.error('Error on microsoft login', error, errorDescription);

    if (parentOrigin) {
      window.parent.postMessage({ error: errorDescription }, parentOrigin);
    }
  };

  return (
    <MicrosoftLogin successHandler={ onMicrosoftLoginSuccess } errorHandler={ onMicrosoftLoginError } readPermissionsApproved={ readPermissionsApproved !== 'false' } />
  );
};
