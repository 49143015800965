import styled, { css } from 'styled-components';
import { THEME } from '../../../../../../themes';
import { Subtitle1Font } from '../../../../../../themes/fonts';
import { ASSETS } from '../../../../../../assets';

export const TalentNetworkContainer = styled.div`
  height: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINY};
  align-items: center;
`;

const Hidden = css`
  opacity: 0;
  transition: opacity 0.2s, height 0.5s ease-in-out 0.1s;
  height: 0;
`;

const getShowCss = (rows: number) => {
  return css`
    height: ${rows * 250}px;
    opacity: ${THEME.OPACITY.HIGHEST};
    transition: opacity 1s ease-in-out 0.2s, height 0.5s;
  `;
};

export const Title = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const HeaderIcon = styled(ASSETS.TALENT_NETWORK_ICON)`
  width: 24px;
  height: 24px;
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const TalentNetworkCardWrapper = styled.div`
  margin-bottom: ${THEME.SPACING.TINO};
  margin-top: ${THEME.SPACING.TINO};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${THEME.SPACING.TINO};
`;

export const HiddenTalentCardsContainer = styled.div<{isDisplayShow: boolean, rows: number}>`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINO};
  overflow: hidden;
  ${({ isDisplayShow, rows }) => {
    if (isDisplayShow) {
      return getShowCss(rows);
    }

    return Hidden;
  }}
`;

export const SeeMoreButtonContainer = styled.div`
  margin-top: ${THEME.SPACING.BASE};
`;
