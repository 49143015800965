import { MessageDirectionEnum } from '../../../../../../store/rtk-query/apis/outreach/enums/message-direction.enum';
import ConversationMessage from '../../conversation-message/conversation-message.component';
import { ConversationMessageStatusEnum } from '../../../../../../enums/outreach/conversation-message-status.enum';
import ConversationMessageError from '../../conversation-message-error/conversation-message-error.component';
import { useReSendMessageMutation } from '../../../../../../store/rtk-query/apis/outreach/hooks/re-send-message.query-hook';
import { ConversationMessageWrapper } from './conversation-massage-item.styled';
import { ConversationMessageItemProps } from './conversation-massage-item';

const ConversationMessageItem = ({ conversationId, message }: ConversationMessageItemProps): JSX.Element => {
  const isNewMessage = !message.seen && message.messageDirection === MessageDirectionEnum.Inbound;
  const [
    reSendMessage,
    {
      isError: resendError, isLoading,
    },
  ] = useReSendMessageMutation(conversationId, message.id);

  return (
    <ConversationMessageWrapper>
      <ConversationMessage
        date={ new Date(message.actionTime) }
        status={ message.opened ? ConversationMessageStatusEnum.Seen : ConversationMessageStatusEnum.Sent }
        isSelfMessage={ message.messageDirection === MessageDirectionEnum.Outbound }
        platformType={ message.type }
        senderName={ message.userInfo?.fullName || message.from || '' }
        senderAvatar={ message.userInfo?.profileImage }
        messageBody={ message.payload.sanitizedBodyHtml }
        messageTitle={ message.payload.subject }
        triggerType={ message.triggerType }
        messageAttachments={ message.payload.attachments }
        isNew={ isNewMessage }
        isLocked={ message.shouldBlur }
        isError={ message.isError }
      />
      {(message.wasBounced || message.isError || resendError) && (
        <ConversationMessageError
          isLoading={ isLoading }
          actionButtonHandler={ reSendMessage }
          wasBounced={ message.wasBounced }
        />
      )}
    </ConversationMessageWrapper>
  );
};

export default ConversationMessageItem;
