import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import * as DOMPurify from 'dompurify';
import { ConversationMessageStatusEnum } from '../../../../../enums/outreach/conversation-message-status.enum';
import STRING_KEYS from '../../../../../language/keys';
import { getInitials } from '../../../../../utils';
import { InitialsAvatar } from '../../../../../components/Common/InitialsAvatar';
import { ConversationSeenMessage, ConversationSentMessage } from '../conversation-message-state/conversation-message-state';
import { MessageTriggerTypeEnum } from '../../../../../store/rtk-query/apis/outreach/enums/message-trigger-type.enum';
import { MessageTypeEnum } from '../../../../../store/rtk-query/apis/outreach/enums/message-type.enum';
import { PerfectButtonHoverVariants, PerfectButtonVariants } from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { useResizeObserver } from '../../../../../hooks/resize-observer.hook';
import { AppRouting } from '../../../../../services/appRoutingResolver';
import {
  AlarmIcon,
  AutomatedMessageLabel,
  Container,
  DownloadAttachmentButton,
  DownloadAttachmentIcon,
  EmailIcon,
  LightningIcon,
  LinkedinIcon,
  MessageBody,
  MessageBodyFade,
  MessageHeader,
  MessageHeaderEndContainer,
  MessageHeaderStartContainer,
  MessageMetadata,
  MessageTitle,
  NewLabel,
  SeeMoreButton,
  SenderAvatar,
  SenderName,
  SentTime,
  TaskIcon,
} from './conversation-message.styled';
import { ConversationMessageProps } from './conversation-message.d';

const SENT_TIME_FORMAT = 'h:mma';

const ConversationMessage = ({
  date, isNew, status, isSelfMessage, platformType, triggerType, senderName, senderAvatar, messageBody, messageTitle, messageAttachments, isLocked, isError = false,
}: ConversationMessageProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [isSeeMoreVisible, setIsSeeMoreVisible] = useState<boolean>(false);
  const [messageBodyMaxHeight, setMessageBodyMaxHeight] = useState<number | null>(null);

  const messageBodyRef = useRef<HTMLDivElement | null>(null);
  const messageBodyWrapperRef = useRef<HTMLDivElement | null>(null);

  const sanitizedMessageBody = useMemo(() => DOMPurify.sanitize(messageBody, { USE_PROFILES: { html: true } }), [messageBody]);

  const bodyWrapper = useResizeObserver(messageBodyWrapperRef);
  useEffect(() => {
    if (messageBodyRef.current && messageBodyWrapperRef.current) {
      if (bodyWrapper.height > messageBodyRef.current.clientHeight) {
        setIsSeeMoreVisible(true);
      }
    }
  }, [messageBodyRef, messageBodyWrapperRef, bodyWrapper.height]);

  const renderTriggerType = (): JSX.Element | null => {
    switch (triggerType) {
      case MessageTriggerTypeEnum.LinkedinTask:
        return (
          <MessageMetadata>
            <TaskIcon />{ translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATION_MESSAGE.TRIGGER_TYPE.LINKEDIN_TASK) }
          </MessageMetadata>
        );
      case MessageTriggerTypeEnum.Automated:
        return (
          <AutomatedMessageLabel>
            <MessageMetadata>
              <LightningIcon />{ translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATION_MESSAGE.TRIGGER_TYPE.AUTOMATED) }
            </MessageMetadata>
          </AutomatedMessageLabel>
        );
      case MessageTriggerTypeEnum.SuggestedFollowup:
        return <MessageMetadata><AlarmIcon />{ translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATION_MESSAGE.TRIGGER_TYPE.SUGGESTED_FOLLOWUP) }</MessageMetadata>;
      default:
        return null;
    }
  };

  const renderMessagePlatformType = (): JSX.Element | null => {
    switch (platformType) {
      case MessageTypeEnum.Email:
        return <MessageMetadata><EmailIcon />{ translate(STRING_KEYS.EMAIL) }</MessageMetadata>;
      case MessageTypeEnum.LinkedinManual:
        return <MessageMetadata><LinkedinIcon />{ translate(STRING_KEYS.LINKEDIN) }</MessageMetadata>;
      default:
        return null;
    }
  };

  const renderStatus = (): JSX.Element | null => {
    let translationKey;
    let icon;

    switch (status) {
      case ConversationMessageStatusEnum.Sent:
        translationKey = STRING_KEYS.SENT;
        icon = <ConversationSentMessage />;
        break;
      case ConversationMessageStatusEnum.Seen:
        translationKey = STRING_KEYS.SEEN;
        icon = <ConversationSeenMessage />;
        break;
      default:
        return null;
    }

    return (
      <MessageMetadata>
        { icon }
        { translate(translationKey) }
      </MessageMetadata>
    );
  };

  const onSeeMoreButtonClick = () => {
    if (messageBodyWrapperRef.current) {
      setMessageBodyMaxHeight(messageBodyWrapperRef.current.clientHeight);
      setIsSeeMoreVisible(false);
    }
  };
  
  const renderAttachmentLinks = () => {
    if (!messageAttachments?.length) {
      return undefined;
    }
    
    return messageAttachments.map((attachment) => {
      return (
        <DownloadAttachmentButton
          key={ attachment.attachmentUrl }
          variant={ PerfectButtonVariants.Link }
          onClick={ () => {
            AppRouting.openInNewTab(attachment.attachmentUrl);
          } }
          StartIcon={ DownloadAttachmentIcon }
          hoverVariant={ PerfectButtonHoverVariants.Underline }
        >
          {attachment.attachmentName}
        </DownloadAttachmentButton>
      );
    });
  };

  return (
    <Container isSelfMessage={ isSelfMessage } isNewMessage={ isNew } isError={ isError }>
      <MessageHeader>
        <MessageHeaderStartContainer>
          <SenderAvatar alt={ senderName } src={ senderAvatar }>
            <InitialsAvatar
              alt={ senderName }
              message={ getInitials(senderName) }
              fontSize="16px"
            />
          </SenderAvatar>
          <SenderName>{ senderName }</SenderName>
          <SentTime>{ format(date, SENT_TIME_FORMAT) }</SentTime>
        </MessageHeaderStartContainer>
        <MessageHeaderEndContainer>
          { renderTriggerType() }
          { renderMessagePlatformType() }
          { (isSelfMessage && !isError) && renderStatus() }
          { isNew && <NewLabel /> }
        </MessageHeaderEndContainer>
      </MessageHeader>
      <MessageTitle>{ messageTitle }</MessageTitle>
      <MessageBody
        ref={ messageBodyRef }
        maxHeight={ messageBodyMaxHeight }
        isLocked={ isLocked }
      >
        <div ref={ messageBodyWrapperRef }>
          { parse(sanitizedMessageBody) }
          { renderAttachmentLinks() }
        </div>
        <MessageBodyFade
          isVisible={ isSeeMoreVisible }
          isNewMessage={ isNew }
          isSelfMessage={ isSelfMessage }
          onClick={ onSeeMoreButtonClick }
        >
          <SeeMoreButton>{ translate(STRING_KEYS.SEE_MORE) }</SeeMoreButton>
        </MessageBodyFade>
      </MessageBody>
    </Container>
  );
};

export default ConversationMessage;

ConversationMessage.defaultProps = {
  senderAvatar: undefined,
  isNew: false,
};
