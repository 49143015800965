import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PRIVACY_CLASS } from '../../../../consts/fullstory';
import STRING_KEYS from '../../../../language/keys';
import {
  Icon,
  Input,
  ShowPassword,
  Wrapper,
  ValidationError,
} from './password-input.styled';

type PasswordInputProps = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    isError: boolean
    error: string,
}

export const PasswordInputComponent = ({
  onChange,
  isError,
  error,
}: PasswordInputProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [isShowPassword, setShowPassword] = useState<boolean>(false);

  const togglePasswordState = () => {
    setShowPassword((prevState) => !prevState);
  };

  const definePasswordErrors = () => {
    if (error) {
      return (
        <ValidationError>
          { error }
        </ValidationError>
      );
    }

    return null;
  };

  return (
    <Wrapper>
      <Icon />
      <Input
        className={ PRIVACY_CLASS.EXCLUDE }
        type={ isShowPassword ? 'text' : 'password' }
        onChange={ onChange }
        placeholder={ translate(STRING_KEYS.YOUR_PASSWORD) }
        isError={ isError }
      />
      <ShowPassword onClick={ togglePasswordState }>
        { isShowPassword ? translate(STRING_KEYS.HIDE) : translate(STRING_KEYS.SHOW) }
      </ShowPassword>
      {definePasswordErrors()}
    </Wrapper>
  );
};
