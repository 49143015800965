import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import { CreatePositionStepsEnums } from '../enums/create-position-steps.enums';
import { OnboardingStep } from '../../onboarding/onboarding.enums';
import { useCreatePositionStoreMetadataSelector } from '../../../store/slices/create-position-state/selectors/create-position.selector';
import {
  resetEducationFields,
  resetCompanyBackground,
  resetJobDescriptionFields,
  resetPositionJobTitle,
  resetPositionLocation,
  setContinueButtonDisable,
  setContinueButtonHide,
  setContinueButtonShow,
  setSkipButtonHide,
  setSkipButtonShow,
  resetSkills,
  resetExperienceLevels,
} from '../../../store/slices/create-position-state/create-position-state.toolkit-slice';
import { useCreatePositionBasicInfoSelector } from '../../../store/slices/create-position-state/selectors/create-position-basic-info.selector';
import PositionLocation from '../../../components/Common/position-location/position-location.component';
import { createLocationObject } from '../../../utils/location';
import STRING_KEYS from '../../../language/keys';
import { useGetUserOnboardingSelector } from '../../../store/rtk-query/apis/onboarding/selectors/user-onboarding.selector';

export const useCreatePositionContinueWatcherButton = (currentStep: string): void => {
  const dispatch = useDispatch();
  const { showContinueButton } = useCreatePositionStoreMetadataSelector();
  useEffect(() => {
    if (
      !showContinueButton &&
      currentStep !== OnboardingStep.LetsBegin &&
      currentStep !== OnboardingStep.Experience
    ) {
      dispatch(setContinueButtonShow());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContinueButton, currentStep]);
};

export const useCreatePositionSkipWatcherButton = (currentStep: string): void => {
  const dispatch = useDispatch();
  const { showSkipButton } = useCreatePositionStoreMetadataSelector();
  useEffect(() => {
    if (
      !showSkipButton &&
      currentStep !== OnboardingStep.LetsBegin &&
      currentStep !== OnboardingStep.Experience
    ) {
      dispatch(setSkipButtonShow());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSkipButton, currentStep]);
};

type ResetStoreByStep = {
  reset: (step: string) => void;
  resetActionButtons: () => void;
}

export const useGetStepInfoComponent = (): JSX.Element => {
  const basicInfo = useCreatePositionBasicInfoSelector();
  const onboardingData = useGetUserOnboardingSelector();

  const jobTitle = basicInfo.jobTitle || onboardingData.data?.position?.jobTitle;
  const locations = basicInfo.locations.length ? createLocationObject(basicInfo.locations) : onboardingData.data?.position?.locations;
  const isRemote = basicInfo.isRemote || onboardingData.data?.position?.remotePosition;

  const LocationComponent = (
    <PositionLocation
      remotePosition={ isRemote }
      locations={ locations }
    />
  );

  return (<Trans i18nKey={ STRING_KEYS.ONBOARDING.CONTAINER_POSITION_INFO } values={ { jobTitle } }>{ LocationComponent }</Trans>);
};

export const useResetStoreByStep = (): ResetStoreByStep => {
  const dispatch = useDispatch();

  const resetActionButtons = () => {
    dispatch(setContinueButtonHide());
    dispatch(setContinueButtonDisable());
    dispatch(setSkipButtonHide());
  };

  const reset = (step: string) => {
    switch (step) {
      case CreatePositionStepsEnums.LetsBegin:
        break;
      case CreatePositionStepsEnums.Experience:
        dispatch(resetPositionLocation());
        dispatch(resetPositionJobTitle());
        dispatch(resetExperienceLevels());
        break;
      case CreatePositionStepsEnums.Background:
        dispatch(resetExperienceLevels());
        dispatch(resetCompanyBackground());
        dispatch(resetSkills());
        break;
      case CreatePositionStepsEnums.Education:
        dispatch(resetCompanyBackground());
        dispatch(resetSkills());
        dispatch(resetEducationFields());
        break;
      case CreatePositionStepsEnums.JobDescription:
        dispatch(resetJobDescriptionFields());
        break;
      default:
    }
  };

  return {
    reset,
    resetActionButtons,
  };
};
