import { Trans, useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import STRING_KEYS from '../../../language/keys';
import { formatLocations, getFlagByCountry } from '../../../utils/location';
import Tooltip from '../../../components/tooltip/tooltip.component';
import { PositionLocationProps } from './position-location';
import { UnderlineText } from './position-location.styled';

const PositionLocation = ({ remotePosition, locations }: PositionLocationProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return useMemo(() => {
    if (remotePosition) {
      return <>{translate(STRING_KEYS.REMOTE)}</>;
    }

    const locationStrings = formatLocations(locations);
    const country = locations?.exactLocations?.[0]?.country || locations?.regions?.[0]?.country;
    const primaryLocation = `${getFlagByCountry(country)} ${locationStrings[0]}`;
    const otherLocations = locationStrings.slice(1);

    let moreLocationsLabel;
    if (otherLocations.length) {
      moreLocationsLabel = (
        <Tooltip
          content={ (
            <div>
              {otherLocations.map((location) => (
                <div key={ location }>{location}</div>
              ))}
            </div>
          ) }
        >
          <Trans
            i18nKey={ otherLocations.length === 1 ? STRING_KEYS.HOMEPAGE.POSITION_CARD.ONE_MORE_LOCATION : STRING_KEYS.HOMEPAGE.POSITION_CARD.X_MORE_LOCATIONS }
            values={ { locationsAmount: otherLocations.length } }
          >
            <UnderlineText />
          </Trans>
        </Tooltip>
      );
    }

    return (
      <span>{primaryLocation} {moreLocationsLabel}</span>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, remotePosition]);
};

export default PositionLocation;
