import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { CaptionFont, HeadlineFont } from '../../../../../themes/fonts';

export const Container = styled.div`

`;
export const Header = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.TRUE_BLACK};
`;
export const Title = styled.div`
  ${HeadlineFont};
  line-height: 1.2;
  font-weight: 600;
  color: ${THEME.COLORS.PRIMARY_DARK};
  margin: ${THEME.SPACING.BASE} 0px;
  strong {
    color: ${THEME.COLORS.ACCENT};
    text-transform: lowercase;
  }
  display: -webkit-box;
  text-overflow: ellipsis;
  width: 295px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-wrap: initial;
  overflow: hidden;
`;
export const MeterContainer = styled.div`
  height: 90px;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const VerticalDash = styled.div`
  height: 100%;
  color: ${THEME.COLORS_RGB.PRIMARY_DIVIDER};
  border-left: 2px dashed;
  position: relative;
`;

export const VerticalDashTitle = styled.div`
  position: absolute;
  bottom: -25px;
  left: -8px;
  font-size: ${THEME.SPACING.TINY};
  color: ${THEME.COLORS.PRIMARY_DARK};
  font-weight: 500;
  letter-spacing: 0.26px;
  opacity: ${THEME.OPACITY.MED};
  width: max-content;
`;

export const TenureMeterComparisonContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;
export const TenureMeterComparisonRow = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  gap: 10px;
  padding-bottom: ${THEME.SPACING.TINY};
`;
