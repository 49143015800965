import styled from 'styled-components';
import { Accordion, AccordionDetails as AccordionDetailsMui, AccordionSummary } from '@mui/material';
import { THEME } from '../../../../../../themes';
import { Subtitle2Font } from '../../../../../../themes/fonts';
import { Scrollbar } from '../../../../../../themes/scrollbar';
import { CONVERSATION_SIDE_PANEL_HEADER_HEIGHT } from '../../conversation-side-panel/conversation-side-panel.styled';
import { hexToRgbA } from '../../../../../../utils/colors';
import { ASSETS } from '../../../../../../assets';

export const ConversationGroupsContainer = styled.div`
  ${Scrollbar};
  height: calc(100% - ${CONVERSATION_SIDE_PANEL_HEADER_HEIGHT}px);
`;

export const AccordionComponent = styled(Accordion)`
  box-shadow: unset !important;
  border-top: 1px solid ${hexToRgbA(THEME.COLORS.CONTENT_PRIMARY, THEME.OPACITY.GHOST)};
  border-bottom: 1px solid ${hexToRgbA(THEME.COLORS.CONTENT_PRIMARY, THEME.OPACITY.GHOST)};
  border-radius: 0 !important;
  &:not(:last-child) {
    border-bottom: 0;
  };
  &:before {
    display: none
  };
  &.Mui-expanded {
    margin: 0 !important;
  }
  &.Mui-disabled {
    background-color: ${THEME.COLORS.PURE_WHITE} !important;
  }
`;

export const AccordionSummaryComponent = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    display: flex;
    padding-left: ${THEME.SPACING.MEDIUM};
    padding-right: ${THEME.SPACING.TINO};
    &.Mui-disabled {
      opacity: ${THEME.OPACITY.LOW} !important;
    }
  }
`;

export const AccordionSummaryContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY}
`;

export const AccordionSummaryTitle = styled.div`
  ${Subtitle2Font};
  font-weight: 600;
  line-height: unset;
`;

export const AccordionSummaryCounter = styled.div`
  ${Subtitle2Font};
  font-weight: 600;
  line-height: unset;
  opacity: ${THEME.OPACITY.LOW};
`;

export const AccordionDetails = styled(AccordionDetailsMui)`
  &.MuiAccordionDetails-root {
    padding: 0;
  }
`;

export const AccordionDetailsComponent = styled.div`
  display: flex;
  flex-direction: column;
  &:last-child {
    border-bottom: 0;
    margin-bottom: ${THEME.SPACING.MICRO};
  };
`;

export const ExpandIcon = styled(ASSETS.ARROW_DOWN)`
  width: 28px;
  height: 28px;
  fill: ${THEME.COLORS.TRUE_BLACK};
  opacity: ${THEME.OPACITY.LOW};
`;
