import log from 'loglevel';
import { Manager as SocketManger, Manager } from 'socket.io-client/build/esm/manager';
import { Socket, SocketOptions } from 'socket.io-client/build/esm/socket';
import { CONFIG } from '../config';
import { InternalServerErrorCode } from '../enums/internal-server-error-code.enum';
import { SocketNamespace } from '../enums/socket-namespace.enum';
import { API_HEADERS } from '../consts/api';
import TrackingIdManager from './trackingIdManager';
import AnalyticsIdManager from './analyticsIdManager';

type GetSocketArguments = {
  namespace: SocketNamespace,
  opts?: Partial<SocketOptions>,
  onSocketConnected?: (socketId: string, namespace: string) => void,
  onSocketDisconnected?: (socketId: string) => void,
}

class SocketIoManager {
  private readonly logger = log.getLogger('SOCKET_IO_MANAGER');

  private readonly manager: Manager;

  private token: string | undefined;

  private sockets: Socket[] = [];

  private transports = ['websocket', 'polling'];

  constructor() {
    this.manager = new SocketManger(CONFIG.WEBSOCKET_BASE_URL, {
      reconnection: true,
      secure: CONFIG.WEBSOCKET_SECURED,
      transports: this.transports,
      path: CONFIG.WEBSOCKET_PATH,
    });
  }

  public setToken(token: string, reconnect?: boolean) {
    this.token = `Bearer ${token}`;
    if (reconnect) {
      let appMetadataToken = 'unknown';
      const appMetadata = {
        appVersion: process.env.REACT_APP_VERSION,
      };
      try {
        appMetadataToken = Buffer.from(JSON.stringify(appMetadata)).toString('base64');
        // eslint-disable-next-line no-empty
      } catch (e) {
        this.logger.debug('Error generating appMetadataToken!', {
          appMetadata,
        });
      }

      this.sockets.forEach((socket) => {
        socket.auth = {
          token: this.token,
        };

        socket.io.opts.extraHeaders = {
          [API_HEADERS.SESSION_TRACKING_ID]: TrackingIdManager.getId(),
          [API_HEADERS.ANALYTICS_ID]: AnalyticsIdManager.getId(),
          [API_HEADERS.APP_METADATA_TOKEN]: appMetadataToken,
        };

        socket.connect();
      });
    }
  }

  public disconnectAllSockets() {
    this.sockets.forEach((socket) => {
      socket.disconnect();
    });
  }

  public getSocket({
    namespace,
    opts,
    onSocketConnected,
    onSocketDisconnected,
  }: GetSocketArguments): Socket | null {
    const socket = this.manager.socket(`/${namespace}`, {
      auth: {
        token: this.token,
      },
      ...opts,
    });

    socket.on('connect', () => {
      this.sockets.push(socket);
      onSocketConnected?.(socket.id, namespace);
      this.logger.info('Socket connected', {
        namespace,
        ...opts,
      });
    });

    socket.on('connect_error', (err) => {
      this.logger.warn('Socket got connection error, revert to classic upgrade(polling, websocket) and reconnect', err);
      socket.io.opts.transports = this.transports.reverse();
      socket.connect();
    });

    socket.on('error', (err) => {
      this.logger.warn('Socket got general error', err);
      const { response } = err;
      if (response?.internalErrorCode === InternalServerErrorCode.NotAuthenticated) {
        socket.disconnect();
      }
    });

    socket.on('disconnect', (reason, description) => {
      this.logger.warn('Socket disconnected, socket will try to reconnect', {
        reason,
        description,
      });
      onSocketDisconnected?.(socket.id);
      this.sockets = this.sockets.filter((localSocket) => localSocket.id !== socket.id);
      socket.connect();
    });

    return socket;
  }
}

export default new SocketIoManager();
