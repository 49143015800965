import styled from 'styled-components';
import { THEME } from '../../../../../../../themes';
import {
  ButtonFont,
  CaptionFont,
  HeadlineFont,
} from '../../../../../../../themes/fonts';

export const Reference = styled.div`
  opacity: ${THEME.OPACITY.MED};
  ${CaptionFont};
  color:${THEME.COLORS.CONTENT_PRIMARY};
  margin-bottom: ${THEME.SPACING.TINO};
`;

export const Title = styled.span`
  color:${THEME.COLORS.PRIMARY_DARK};
  ${HeadlineFont};
  font-weight: normal;
`;

export const Counter = styled(Title)`
  font-weight: 600;
  color: ${THEME.COLORS.ACCENT};
  margin-left: ${THEME.SPACING.MICRO};
  margin-right: ${THEME.SPACING.MICRO};
`;

export const Company = styled(Title)`
  font-weight: 700;
  margin-left: ${THEME.SPACING.MICRO};
`;

export const  Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Name = styled.div`
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  ${ButtonFont};
  line-height: 1.2;
  letter-spacing: 0.1px;
  text-transform: capitalize;
  font-weight: bold;
`;
export const Description = styled.div`
  opacity: ${THEME.OPACITY.MED};
  ${CaptionFont};
  color:${THEME.COLORS.CONTENT_PRIMARY};
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
  margin-top: ${THEME.SPACING.BASE};
`;

export const WidgetContainer = styled.div`
  width: 100%;
  height: 100%;
`;
