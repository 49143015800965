import { createSlice } from '@reduxjs/toolkit';
import { onboardingQuery } from '../../rtk-query/apis/onboarding/onboarding.toolkit-api';
import { initialOnboardingState } from './onboarding-state.initial-state';
import { hideOnboardingSplashScreenReducer } from './reducers/obarding-splash-screen.reducer';
import { onboardingResetFieldsReducer, setOnboardingLoaderReducer } from './reducers/obarding-loading.reducer';
import { loadOnboardingFulfilledExtraReducer, loadOnboardingRejectedExtraReducer, loadingOnboardingPendingExtraReducer } from './extra-reducers/onboarding.reducers';
import {
  resetCompanyReducer, setCompanyReducer, setPromoReducer, setRoleReducer, setUserNameReducer,
} from './reducers/obarding-user-data.reducer';
import {
  setOnboardingContinueButtonDisableReducer,
  setOnboardingContinueButtonEnableReducer,
  setOnboardingContinueButtonHideReducer,
  setOnboardingContinueButtonShowReducer,
  setOnboardingLoaderButtonHideReducer, setOnboardingLoaderButtonShowReducer,
  setOnboardingSkipButtonHideReducer,
  setOnboardingSkipButtonShowReducer,
} from './reducers/obarding-action.reducer';
import { resetOnboardingStateReducer } from './reducers/obarding-logout.reducer';

export const OnboardingStateSlice = createSlice({
  name: 'onboarding-state',
  initialState: initialOnboardingState,
  reducers: {
    resetOnboardingState: resetOnboardingStateReducer,
    hideOnboardingSplashScreen: hideOnboardingSplashScreenReducer,
    setOnboardingLoader: setOnboardingLoaderReducer,
    setUserName: setUserNameReducer,
    setRole: setRoleReducer,
    setPromo: setPromoReducer,
    setCompany: setCompanyReducer,
    onboardingResetFields: onboardingResetFieldsReducer,
    resetCompany: resetCompanyReducer,
    setOnboardingContinueButtonEnable: setOnboardingContinueButtonEnableReducer,
    setOnboardingContinueButtonDisable: setOnboardingContinueButtonDisableReducer,
    setOnboardingContinueButtonHide: setOnboardingContinueButtonHideReducer,
    setOnboardingContinueButtonShow: setOnboardingContinueButtonShowReducer,
    setOnboardingSkipButtonHide: setOnboardingSkipButtonHideReducer,
    setOnboardingSkipButtonShow: setOnboardingSkipButtonShowReducer,
    setOnboardingLoaderButtonHide: setOnboardingLoaderButtonHideReducer,
    setOnboardingLoaderButtonShow: setOnboardingLoaderButtonShowReducer,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(onboardingQuery.endpoints.loadOnboarding.matchPending, loadingOnboardingPendingExtraReducer)
      .addMatcher(onboardingQuery.endpoints.loadOnboarding.matchFulfilled, loadOnboardingFulfilledExtraReducer)
      .addMatcher(onboardingQuery.endpoints.loadOnboarding.matchRejected, loadOnboardingRejectedExtraReducer);
  },
});

export const {
  resetOnboardingState,
  setOnboardingLoaderButtonShow,
  setOnboardingLoaderButtonHide,
  setOnboardingSkipButtonShow,
  setOnboardingSkipButtonHide,
  setOnboardingContinueButtonShow,
  setOnboardingContinueButtonHide,
  setOnboardingContinueButtonDisable,
  setOnboardingContinueButtonEnable,
  resetCompany,
  onboardingResetFields,
  setUserName,
  setCompany,
  setRole,
  setPromo,
  hideOnboardingSplashScreen,
  setOnboardingLoader,
} = OnboardingStateSlice.actions;

export default OnboardingStateSlice.reducer;
