import Cookies from 'js-cookie';
import log from 'loglevel';
import { AppRouting } from './appRoutingResolver';

class UserLoggingInfoManger {
  private readonly logger = log.getLogger(UserLoggingInfoManger.name);

  private readonly COOKIE_NAME = 'perfect_uli_data';

  private userLoggingData = {};

  constructor() {
    // load current ids if exists
    this.userLoggingData = this.loadUserLoggingInfoData();
  }

  /**
   * Get current user logging data.
   * @returns object containing user data
   */
  getUserInfoLoggingData = () => {
    return this.userLoggingData;
  }

  private loadUserLoggingInfoData = () => {
    const dataFromCookie = Cookies.get(this.COOKIE_NAME);

    if (dataFromCookie) {
      return JSON.parse(dataFromCookie);
    }

    return {};
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  public saveUserLoggingInfoData = (data: object): void => {
    const rootOrigin = `.${AppRouting.getRootOrigin()}`;

    // we want to keep the data also in cookie storage for backward support,
    // but even more important - to support cross-subdomain id
    // (which cannot be achieved with localstorage alone)
    Cookies.set(this.COOKIE_NAME, JSON.stringify(data), {
      domain: rootOrigin,
      expires: Number(process.env.REACT_APP_TRACKING_COOKIE_LIFETIME_DAYS) || 30,
      sameSite: 'Lax',
    });
    this.userLoggingData = data;
  }

  public clear = (): void => {
    const rootOrigin = `.${AppRouting.getRootOrigin()}`;

    Cookies.remove(this.COOKIE_NAME, {
      domain: rootOrigin,
    });
    this.userLoggingData = {};
  }
}

export default new UserLoggingInfoManger();
