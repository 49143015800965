import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import {
  Badge,
  BadgeIconWrapper,
  Container,
  IconContainer,
  Subtitle,
  TopContainer,
  Title,
  BottomContainer,
  ActiveIndicationContainer,
  ActiveIndicationIcon,
  InfoTextIconContainer,
} from './campaign-mode-button.styled';

const CampaignModeButton = ({
  title,
  subtitle,
  Icon,
  badgeText,
  BadgeIcon,
  isActive,
  isSelected,
  disabled,
  infoText,
  InfoTextIcon,
  clickHandler,
}: CampaignModeButtonProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const renderBadge = () => {
    if (!badgeText && !BadgeIcon) {
      return null;
    }

    return (
      <Badge disabled={ disabled }>
        { BadgeIcon && <BadgeIconWrapper disabled={ disabled }><BadgeIcon /></BadgeIconWrapper> }
        { badgeText }
      </Badge>
    );
  };

  const renderBottomContainerContent = () => {
    const BottomIcon = isActive ? ActiveIndicationIcon : InfoTextIcon;
    const bottomText = isActive ? translate(STRING_KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.ACTIVE_BUTTON_INDICATION) : infoText;

    return (
      <ActiveIndicationContainer>
        <InfoTextIconContainer><BottomIcon /></InfoTextIconContainer>
        { bottomText }
      </ActiveIndicationContainer>
    );
  };

  return (
    <Container
      isSelected={ !!isSelected }
      disabled={ !!disabled }
      onClick={ () => clickHandler?.() }
    >
      <TopContainer>
        <IconContainer disabled={ disabled }><Icon /></IconContainer>
        <div>
          <Title disabled={ disabled }>{ title }</Title>
          <Subtitle disabled={ disabled }>{ subtitle }</Subtitle>
        </div>
      </TopContainer>
      <BottomContainer>
        { renderBottomContainerContent() }
      </BottomContainer>
      { renderBadge() }
    </Container>
  );
};

export default CampaignModeButton;
