import { userQuery } from '../user.toolkit-api';
import { UserResponse } from '../dto/response/invited-users.response';

type UseFillUserInfoResponseType = [
  (email: string) => void,
  AppSelectorType<UserResponse[], string>
];

export const useInviteUserMutation = (): UseFillUserInfoResponseType => {
  const [
    inviteUser,
    {
      data, isLoading, isError, error, isSuccess,
    }] = userQuery.useInviteColleagueMutation();

  const meta = {
    isLoading, isError, error: error as string, isSuccess, data: data || [] as UserResponse[],
  };

  return [
    inviteUser,
    { ...meta },
  ];
};
