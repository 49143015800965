import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import { SocialLinksList } from './social-links/social-links.component';
import {
  Container,
  SocialProfilesSubTitle,
} from './social-links-widget.styled';

type SocialLinksWidgetProps =  {
  socialLinks: SocialLinkData[],
  trackSocialLinkClick?: (socialChannel: string) => void,
}

export const SocialLinksWidget = ({
  socialLinks,
  trackSocialLinkClick,
}:SocialLinksWidgetProps): JSX.Element | null => {
  const { t: translate } = useTranslation();

  if (!socialLinks) {
    return null;
  }

  return (
    <Container>
      <SocialProfilesSubTitle>
        {translate(STRING_KEYS.SOCIAL_PROFILES)}
      </SocialProfilesSubTitle>
      <SocialLinksList
        socialLinks={ socialLinks }
        trackSocialLinkClick={ trackSocialLinkClick }
      />
    </Container>
  );
};

SocialLinksWidget.defaultProps = {
  trackSocialLinkClick: undefined,
};
