import styled, { css } from 'styled-components';
import { hexToRgbA } from '../../../../../utils/colors';
import { THEME } from '../../../../../themes';
import { Body2Font, HeadlineFont } from '../../../../../themes/fonts';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { ASSETS, FLUENT_UI_EMOJIS } from '../../../../../assets';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 112px;
  gap: ${THEME.SPACING.MEDIUM};
  background-color: ${THEME.COLORS.PURE_WHITE};
  border-radius: ${THEME.RADIUS.CURVY};
  padding: ${THEME.SPACING.MEDIUM} ${THEME.SPACING.MEDIUM};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.BASE};
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background-color: ${hexToRgbA(THEME.COLORS.PRIMARY, THEME.OPACITY.GHOSTLY)};
  border-radius: ${THEME.RADIUS.LOOPY};
  font-size: 32px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const Title = styled.div`
  ${HeadlineFont};
`;

export const Description = styled.div`
  ${Body2Font};
  opacity: ${THEME.OPACITY.MED};
  max-width: 590px;
`;

export const ActionButtonStyle = css`
  color: ${THEME.COLORS.PURE_WHITE};
  background-color: ${THEME.COLORS.ACCENT};
`;

export const SelectedActionButtonStyle = css`
  background-color: transparent;
  color: ${THEME.COLORS.ACCENT};
  border: 1px solid ${THEME.COLORS.ACCENT};
`;

export const ActionButton = styled(BaseButton)<{ isSelected: boolean }>`
  ${({ isSelected }) => isSelected ? SelectedActionButtonStyle : ActionButtonStyle};
  margin-left: auto;
  height: 40px;
`;

export const ActionButtonIcon = styled(ASSETS.V_SIGN)`
  width: 12px;
`;

const IconStyle = css`
  width: 40px;
  height: 40px;
`;

export const LocationIcon = styled(FLUENT_UI_EMOJIS.ROUND_PUSHPIN)`
  ${IconStyle};
`;

export const LevelOfExperienceIcon = styled(FLUENT_UI_EMOJIS.OFFICE_BUILDING)`
  ${IconStyle};
`;

export const CompanySizesIcon = styled(FLUENT_UI_EMOJIS.DECIDUOUS_TREE)`
  ${IconStyle};
`;

export const MustHavesIcon = styled(FLUENT_UI_EMOJIS.EIGHT_SPOKED_ASTERISK)`
  ${IconStyle};
`;

export const JobTitleIcon = styled(FLUENT_UI_EMOJIS.BRIEFCASE)`
  ${IconStyle};
`;
