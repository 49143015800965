import styled  from 'styled-components';
import { THEME } from '../../../../../../themes';
import { hexToRgbA } from '../../../../../../utils/colors';
import { ASSETS } from '../../../../../../assets';
import { CaptionFont, Subtitle2Font } from '../../../../../../themes/fonts';
import Chip from '../../../../../../components/Common/perfect-chip/perfect-chip.component';

const PADDING = `${THEME.SPACING.TINO} ${THEME.SPACING.MEDIUM}`;

export const Container = styled.div<{ isAlert: boolean }>`
  border: ${({ isAlert }) => {
    let color: string = THEME.COLORS.PRIMARY_DARK;
    if (isAlert) {
      color = THEME.COLORS.ALERT;
    }

    return `solid 1px ${hexToRgbA(color, THEME.OPACITY.DIVIDER)}`;
  }};
  border-radius: ${THEME.RADIUS.ROUND};
  overflow: hidden;
  margin-top: ${THEME.SPACING.TINY};
`;

export const EmailsContainer = styled.div`
  align-items: start;
  ${Subtitle2Font};
  width: 100%;
  min-height: 48px;
  max-height: 150px;
  overflow: auto;
  display: flex;
  gap: ${THEME.SPACING.TINY};
  padding: ${PADDING};
`;

export const Label = styled.span`
  color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.LOW)};
  padding-top: ${THEME.SPACING.MICRO};
`;

export const Me = styled.span`
  padding-top: ${THEME.SPACING.MICRO};
`;

export const EmailsInputContainer = styled.span<{ noWrap?: boolean }>`
  display: flex;
  align-items: start;
  gap: ${THEME.SPACING.TINY};
  flex-wrap: ${({ noWrap }) => noWrap ? 'inherit' : 'wrap'};
  width: 100%;
`;
export const Input = styled.input`
  border: none;
  color: ${THEME.COLORS.CONTENT_A};
  width: fit-content;
  height: 22px;
  :focus {
    outline :none;
  }
`;

export const EmailChip = styled(Chip)<{ selected?: boolean }>`
  color: ${({ selected }) => selected ? THEME.COLORS.ACCENT : THEME.COLORS.CONTENT_A};
  background-color: ${({ selected }) => selected ? undefined : THEME.COLORS.GREY};
  outline: ${({ selected }) => selected ? undefined : 'unset'};
  ${Subtitle2Font};
  font-weight: 600;
  padding: ${THEME.SPACING.MICRO} ${THEME.SPACING.TINO};
  height: 22px;
  cursor: pointer;
`;

export const CheckedChipIcon = styled(ASSETS.CHECKED_GHOST)`
  width: 9px;
  height: 7px;
`;

export const FromUserIcon = styled(ASSETS.FROM_USER)`
  width: 24px;
  height: 24px;
  fill: ${THEME.COLORS.CONTENT_A};
`;

export const EndAdornmentContainer = styled.div`
  display: flex;
  color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW)};
  gap: ${THEME.SPACING.TINY};
  align-items: center;
  cursor: pointer;
  :hover {
    color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.HIGHEST)};
  };
`;

export const EndAdornmentLabel = styled.span`
  ${CaptionFont};
  width: max-content;
`;

export const EndAdornmentIcon = styled(ASSETS.QUESTION_OUTLINE)`
  width: 16px;
  height: 16px;
  fill: currentColor;
`;

export const Underline = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${THEME.COLORS.ACCENT};
`;
