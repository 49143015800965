import { GIFS } from '../assets';
import { SupportLink } from '../components/Common/SimpleSupportLink';

export const FATAL_ERRORS = [
  {
    title: 'Whoops. That didn’t go as planned. Forgive us?',
    content: (
      <span>
        {'Give us a minute to recoup. If this problem continues please let us know: '}
        <SupportLink />
      </span>
    ),
    image: GIFS.SPONGEBOB,
    alt: 'Embarrassed Spongebob Squarepants',
  },

  {
    title: (
      <>
        <div>We’re really sorry.</div>
        <div>Something broke and we’re fixing it.</div>
      </>
    ),
    content: (
      <span>
        {'If this problem continues please let us know: '}
        <SupportLink />
      </span>
    ),
    image: GIFS.DOG_PEOPLE,
    alt: 'Dog People',
  },
];
