import { createApi, retry } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../../utils/base-query';
import { REDUCER_PATH } from './tracking.consts';
import { trackPageVisitsQuery } from './tracking.toolkit-queries';
import { TrackPageVisitQueryArguments } from './dto/query-arguments/track-page-visit-query.arguments';

export const trackingQuery = createApi({
  reducerPath: REDUCER_PATH,
  // All this api calls will be with retry. Can disable specific request with { maxRetries: 0 }
  baseQuery: retry(axiosBaseQuery(), { maxRetries: 3 }),
  endpoints: (builder) => ({
    trackVisit: builder.mutation<void, TrackPageVisitQueryArguments[]>({
      query: trackPageVisitsQuery,
    }),
  }),
});
