import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CampaignPageBaseOption from '../campaign-base-option/campaign-base-option.component';
import SelectionGroupSwitch
  from '../../../../../components/Common/selection-group-switch/selection-group-switch.component';
import STRING_KEYS from '../../../../../language/keys';
import { AutoModeIcon, Container } from './campaign-auto-mode-switch-option.styled';

export const AUTO_MODE_ID = 'auto_id';
export const CUSTOM_MODE_ID = 'custom_id';

const CampaignPageAutoModeSwitchOption = ({
  title,
  description,
  infoText,
  Icon,
  selectedValue,
  ActionComponent,
  changeHandler,
}: CampaignPageAutoModeSwitchOptionProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const isAutoMode = useMemo(() => selectedValue === AUTO_MODE_ID, [selectedValue]);

  return (
    <Container isAutoMode={ isAutoMode }>
      <CampaignPageBaseOption
        title={ title }
        description={ isAutoMode ? undefined : description }
        infoText={ isAutoMode ? infoText : undefined }
        badgeText={ isAutoMode ? 'automated' : undefined }
        Icon={ Icon }
        EndAdornment={ (
          <SelectionGroupSwitch
            changeHandler={ (value) => changeHandler(value) }
            option1={ { id: CUSTOM_MODE_ID, title: translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.SWITCH.CUSTOM) } }
            option2={ { id: AUTO_MODE_ID, title: translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.SWITCH.AUTO), icon: AutoModeIcon } }
            selectedValueId={ selectedValue }
          />
        ) }
      >
        { isAutoMode ? undefined : ActionComponent }
      </CampaignPageBaseOption>
    </Container>
  );
};

export default CampaignPageAutoModeSwitchOption;
