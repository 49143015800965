import { Trans } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { ExplainabilityOriginSourceEnum } from '../../../../enums/explainability-origin-source.enum';
import { ExplainabilityTypeEnum } from '../../../../enums/explainability-type.enum';
import { ExplainabilityOriginSentenceProps } from './explainability-origin-sentence';

const ExplainabilityOriginSentence = ({
  type,
  value,
  origin,
}: ExplainabilityOriginSentenceProps): JSX.Element | null => {
  const getIndustrySentence = () => {
    let translationKey;
    switch (origin.source) {
      case ExplainabilityOriginSourceEnum.SpecificRoleText:
        translationKey = STRING_KEYS.MATCH_CARD.EXPLAINABILITY.VERIFIED_FROM_COMPANY_DESCRIPTION;
        break;
      case ExplainabilityOriginSourceEnum.SpecificRoleAttribute:
        translationKey = STRING_KEYS.MATCH_CARD.EXPLAINABILITY.VERIFIED_FROM_COMPANY_INDUSTRIES;
        break;
      default:
        return null;
    }

    return (
      <Trans
        i18nKey={ translationKey }
        values={ {
          companyName: origin.companyName,
          industryName: value,
        } }
      />
    );
  };

  const getSkillSentence = () => {
    let translationKey;
    switch (origin.source) {
      case ExplainabilityOriginSourceEnum.SpecificRoleText:
        translationKey = STRING_KEYS.MATCH_CARD.EXPLAINABILITY.VERIFIED_FROM_TALENT_SPECIFIC_ROLE_DESCRIPTION_TEXT;
        break;
      case ExplainabilityOriginSourceEnum.SpecificRoleAttribute:
        translationKey = STRING_KEYS.MATCH_CARD.EXPLAINABILITY.VERIFIED_FROM_TALENT_SPECIFIC_ROLE_SKILLS_TEXT;
        break;
      case ExplainabilityOriginSourceEnum.GeneralProfileText:
        translationKey = STRING_KEYS.MATCH_CARD.EXPLAINABILITY.VERIFIED_FROM_TALENT_GENERAL_PROFILE_DESCRIPTION_TEXT;
        break;
      case ExplainabilityOriginSourceEnum.GeneralProfileAttribute:
        translationKey = STRING_KEYS.MATCH_CARD.EXPLAINABILITY.VERIFIED_FROM_TALENT_GENERAL_PROFILE_SKILLS_TEXT;
        break;
      default:
        return null;
    }

    return (
      <Trans
        i18nKey={ translationKey }
        values={ {
          companyName: origin.companyName,
          jobTitle: origin.jobTitle,
        } }
      />
    );
  };

  if (!origin) {
    return null;
  }

  switch (type) {
    case ExplainabilityTypeEnum.Skill:
      return getSkillSentence();
    case ExplainabilityTypeEnum.Industry:
      return getIndustrySentence();
    default:
      return null;
  }
};

export default ExplainabilityOriginSentence;
