import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
`;
