import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import STRING_KEYS from '../../../../../../../../language/keys';
import ExplainabilityWidgetItem from '../explainability-widget-item.component';
import { CompanySizeTierEnum } from '../../../../../../../../enums/company-size-tier.enum';
import { Icon } from './explainability-widget-company-size.styled';
import { ExplainabilityWidgetCompanySizeProps } from './explainability-widget-company-size';

const ExplainabilityWidgetCompanySize = ({ employmentHistory }: ExplainabilityWidgetCompanySizeProps): JSX.Element | null => {
  const { t: translate } = useTranslation();

  const askedCompanySizes = useMemo(() => (
    employmentHistory?.reduce((acc, history) => {
      if (history.companySizeTier?.askedByUser) {
        acc.add(history.companySizeTier.value);
      }

      return acc;
    }, new Set<CompanySizeTierEnum>())
  ), [employmentHistory]);

  if (!askedCompanySizes?.size) {
    return null;
  }

  const joinedAskedSizes = Array.from(askedCompanySizes).map((size) => translate(STRING_KEYS.COMPANY_SIZE[size])).join(', ');

  return (
    <ExplainabilityWidgetItem
      key="ExplainabilityWidgetCompanySize"
      label={ translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY_WIDGET.ITEMS_LABELS.COMPANY_SIZE) }
      value={ joinedAskedSizes }
      Icon={ Icon }
    />
  );
};

export default ExplainabilityWidgetCompanySize;
