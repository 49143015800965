/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import log from 'loglevel';
import STRING_KEYS from '../../../../language/keys';
import { uploadJobDescription } from '../../services/create-position.network-requests';
import { IndeterminateCircularLoader } from '../../../../components/Common/Loaders/IndeterminateCircularLoader';
import {
  DropFileOuterWrapper,
  DragDropTextContainer,
  DragDropFileTitleText,
  DragDropFileDescriptionText,
  Remove,
  UploadIconContainer,
  DropFileInnerWrapper,
  ErrorDescription,
  UploadIcon,
  UploadedIcon,
} from './upload-file-content.styled';

const logger = log.getLogger('UPLOAD_FILE_CONTENT');

const MAX_FILE_SIZE = 5242880;
const FILE_TOO_LARGE_CODE = 'file-too-large';

type UploadFileContentProps = {
  isLoading: boolean,
  onUploadedUrlChange: (newUrl: string) => void,
  onUpdateLoadingState: (newState: boolean) => void,
}

export const UploadFileContent = ({
  isLoading,
  onUploadedUrlChange,
  onUpdateLoadingState,
} :UploadFileContentProps): JSX.Element => {
  const [isError, setError] = useState<boolean>(false);
  const [fileUploadedSuccessfully, setFileUploadedSuccessfully] = useState<boolean>(false);
  const [isDragOver, setDragOver] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();
      reader.onprogress = () => onUpdateLoadingState(true);
      reader.onabort = () => setError(true);
      reader.onerror = () => setError(true);
      reader.onload = () => {
        const formData = new FormData();
        formData.append('file', file);
        onUpdateLoadingState(true);
        uploadJobDescription(formData)
          .then(({ fileUrl }) => {
            setError(false);
            onUploadedUrlChange(fileUrl);
            setFileUploadedSuccessfully(true);
          }).catch((error) => {
            logger.error('Failed to upload job description PDF file', error, {
              fileName: file.name,
              fileSize: file.size,
            });

            setError(true);
          }).finally(() => {
            onUpdateLoadingState(false);
          });
        onUpdateLoadingState(true);
        setFileName(file.name);
      };
      reader.readAsArrayBuffer(file);
    });
  }, [onUpdateLoadingState, onUploadedUrlChange]);

  const { t: translate } = useTranslation();

  const {
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    maxSize: MAX_FILE_SIZE,
    accept: {
      'application/pdf': ['.pdf'],
    },
  });

  const removeAll = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    onUploadedUrlChange('');
    setFileUploadedSuccessfully(false);
    setDragOver(false);
  };

  const defineError = (): JSX.Element => {
    const isFileTooLarge = fileRejections.length > 0 &&
      fileRejections[0].file.size > MAX_FILE_SIZE &&
      fileRejections[0].errors[0].code === FILE_TOO_LARGE_CODE;

    if (isFileTooLarge) {
      return (
        <ErrorDescription>
          {translate(STRING_KEYS.ONBOARDING.ONBOARDING_FILE_TOO_LARGE_ERROR)}
        </ErrorDescription>
      );
    }

    if (isDragReject || isError) {
      return (
        <ErrorDescription>
          {translate(STRING_KEYS.ONBOARDING.ONBOARDING_FILE_TYPE_ERROR)}
        </ErrorDescription>
      );
    }

    return <></>;
  };

  const renderUploadIcon = () => {
    let Icon;
    if (fileUploadedSuccessfully && !isLoading) {
      Icon = <UploadedIcon />;
    } else {
      Icon = <UploadIcon />;
    }

    return (
      <UploadIconContainer>
        { isLoading && <IndeterminateCircularLoader />}
        { Icon }
      </UploadIconContainer>
    );
  };

  return (
    <DropFileOuterWrapper { ...getRootProps() }
      fileUploadedSuccessfully={ fileUploadedSuccessfully }
      isDragOver={ isDragOver }
      onDragOver={ () => setDragOver(true) }
      onDragLeave={ () => setDragOver(false) }
      isDragReject={ isDragReject }
    >
      <input
        type="file"
        { ...getInputProps() }
      />
      <DropFileInnerWrapper
        fileUploadedSuccessfully={ fileUploadedSuccessfully }
      >
        { renderUploadIcon() }
        <DragDropTextContainer>
          <DragDropFileTitleText>
            {
              fileUploadedSuccessfully ?
                fileName :
                translate(STRING_KEYS.ONBOARDING
                  .ONBOARDING_UPLOAD_JOB_DESCRIPTION_DRAG_DROP)
            }
          </DragDropFileTitleText>

          <DragDropFileDescriptionText>
            {
              fileUploadedSuccessfully ?
                translate(STRING_KEYS.ONBOARDING.ONBOARDING_UPLOAD_JOB_PUT_IN_CLOUD) :
                translate(STRING_KEYS.ONBOARDING.ONBOARDING_UPLOAD_JOB_DESCRIPTION_PDF)
            }
          </DragDropFileDescriptionText>
          {fileUploadedSuccessfully && (
            <Remove onClick={ removeAll }>
              { translate(STRING_KEYS.ONBOARDING.ONBOARDING_REMOVE_FILE) }
            </Remove>
          )}
        </DragDropTextContainer>
      </DropFileInnerWrapper>
      {defineError()}
    </DropFileOuterWrapper>
  );
};
