import BaseSwitch from '../../../../../../components/Common/base-switch/base-switch.component';
import { THEME } from '../../../../../../themes';
import {
  CheckedIcon, Container, SwitchLabel, UnCheckedIcon,
} from './toggle-button.styled';

const ToggleButton = ({ label, checked, setChecked }: ToggleButtonProps): JSX.Element => {
  return (
    <Container>
      <BaseSwitch
        checked={ checked }
        switchChangeHandler={ (value) => setChecked(value) }
        CheckedIcon={ <CheckedIcon /> }
        UnCheckedIcon={ <UnCheckedIcon /> }
        checkedColor={ THEME.COLORS.ACCENT }
      />
      <SwitchLabel>
        { label }
      </SwitchLabel>
    </Container>
  );
};

export default ToggleButton;
