import React from 'react';
import { PageHeader } from '../Common/headers/page-header/page-header.component';
import { WarningSnackBar } from '../Common/CustomSnackBar/WarningSnackBar';
import LoginLogger from '../Common/Loaders/login-loader/login-loader.component';
import TermsOfUseModal from '../Common/terms-of-use-modal/terms-of-use-modal.component';
import { Container, SubTitle, Title } from './login-and-registration.styled';

type LoginAndRegistrationComponent = {
  children: JSX.Element | JSX.Element[],
  accountLogo?: JSX.Element,
  isLoading?: boolean,
  headerSticky?: boolean,
  title: string | JSX.Element,
  subTitle?: string,
  error?: string,
  termsOfUseModalVisible?: boolean,
  handleCloseTermsOfUseModal?: () => void,
}

function LoginAndRegistrationComponent({
  children,
  title,
  subTitle,
  headerSticky,
  error,
  isLoading,
  termsOfUseModalVisible,
  handleCloseTermsOfUseModal,
  accountLogo,
}: LoginAndRegistrationComponent): JSX.Element {
  return (
    <>
      <PageHeader isSticky={ headerSticky } />
      {error && (
        <WarningSnackBar
          title={ error }
          isOpen
          shouldAutoDismiss
        />
      )}

      <LoginLogger isLoading={ !!isLoading } />

      {
        termsOfUseModalVisible && (
          <TermsOfUseModal onModalClose={ handleCloseTermsOfUseModal } />
        )
      }
      <Container>
        {accountLogo}
        <Title>
          { title }
        </Title>

        {
          subTitle && (
            <SubTitle>
              { subTitle }
            </SubTitle>
          )
        }
        { children }
      </Container>
    </>
  );
}

LoginAndRegistrationComponent.defaultProps = {
  accountLogo: undefined,
  isLoading: false,
  headerSticky: false,
  termsOfUseModalVisible: false,
  handleCloseTermsOfUseModal: undefined,
  subTitle: undefined,
  error: '',
};

export default LoginAndRegistrationComponent;
