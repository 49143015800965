import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { CaptionFont, Subtitle2Font, Title2Font } from '../../../../../../themes/fonts';

export const EmptyContainer = styled.div`
  padding: ${THEME.SPACING.MEDIUM} ${THEME.SPACING.MEDIUM} 0;
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
`;

export const IconContainer = styled.div`
  ${Title2Font};
  font-weight: 600;
`;

export const TitleContainer = styled.div`
  ${Subtitle2Font};
`;

export const DescriptionContainer = styled.div`
  ${CaptionFont};
  opacity: 0.6;
`;
