import { GetQuery, PostQuery } from '../../@types/rtk-query';
import { QueryMethods } from '../../enums/query-methods.enums';
import { ROUTES } from './ats-integration.consts';
import { CreateAtsIntegrationQueryArguments } from './dto/query-arguments/create-ats-integration.query-arguments';
import { CreateAtsIntegrationRequest } from './dto/request/create-ats-integration.request';
import { CreateCustomAtsIntegrationRequest } from './dto/request/create-custom-ats-integration.request';
import { CreateCustomAtsIntegrationQueryArguments } from './dto/query-arguments/create-custom-ats-integration.query-arguments';

export const createAtsIntegrationQuery = ({ accountToken, twoWaySyncApproved }: CreateAtsIntegrationRequest): PostQuery<CreateAtsIntegrationQueryArguments> => ({
  url: ROUTES.ATS_INTEGRATIONS,
  method: QueryMethods.Post,
  data: {
    accountToken,
    twoWaySyncApproved,
  },
});

export const createCustomIntegrationQuery = (
  { integrationType, token, twoWaySyncApproved }: CreateCustomAtsIntegrationRequest,
): PostQuery<CreateCustomAtsIntegrationQueryArguments> => ({
  url: ROUTES.CUSTOM_ATS_INTEGRATION,
  method: QueryMethods.Post,
  data: {
    integrationType,
    token,
    twoWaySyncApproved,
  },
});

export const getMergeFrontendLinkTokenQuery = (): GetQuery => ({
  url: ROUTES.GET_MERGE_FRONTEND_LINK_TOKEN,
  method: QueryMethods.Get,
});

export const getAccountAtsJobsQuery = (): GetQuery => ({
  url: ROUTES.GET_ATS_ACCOUNT_JOBS,
  method: QueryMethods.Get,
});
