import styled, { css } from 'styled-components';
import { THEME } from '../../../../themes';
import { CaptionFont, HeadlineFont, Subtitle1Font } from '../../../../themes/fonts';
import PerfectCheckbox from '../../perfect-checkbox/perfect-checkbox.component';
import { CheckboxItemSize } from './checkbox-item.enums';

export const Title = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
  height: 16px;
  color: ${THEME.COLORS.CONTENT_A};
`;

const ActiveStyle = css`
  outline: solid 2px ${THEME.COLORS.ACCENT};
  background-color: ${THEME.COLORS_RGB.ACCENT_012} !important;
  cursor: pointer;
`;

const SmallStyle = css`
  height: 48px;
`;

const MediumStyle = css`
  height: 56px;
  width: 394px;
  
  ${Title} {
    margin-left: ${THEME.SPACING.BASE};
  }
`;

export const Container = styled.div<{ isActive: boolean, size?: CheckboxItemSize }>`
  ${({ size }) =>  size === CheckboxItemSize.Small ? SmallStyle : MediumStyle};
  border-radius: 12px;
  outline: solid 1px rgba(19, 15, 53, 0.12);
  background-color: ${THEME.COLORS.LIGHT_GREY};
  padding-right: ${THEME.SPACING.MEDIUM};
  display: flex;
  ${({ isActive }) => isActive ? ActiveStyle : undefined};

  :hover {
    ${ActiveStyle};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  ${HeadlineFont};
  
  & svg {
    width: 32px;
    height: 32px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Description = styled.div`
  ${CaptionFont};
  height: 12px;
  margin-left: ${THEME.SPACING.BASE};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;

export const StyledCheckbox = styled(PerfectCheckbox).attrs<{ checkboxSize?: CheckboxItemSize }>(({ checkboxSize }) => ({
  sx: {
    padding: THEME.SPACING.BASE,
    paddingRight: checkboxSize === CheckboxItemSize.Small ? THEME.SPACING.TINO : undefined,
  },
}))<{ checkboxSize?: CheckboxItemSize }>``;
