import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import BasePositionAssistant from '../base-position-assistant/base-position-assistant.component';
import { EnvelopeIcon } from './encourage-timely-replies.styled';

const EncourageTimelyReplies = ({
  actionHandler, waitingToReply,
}: EncourageTimelyRepliesProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BasePositionAssistant
      title={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ENCOURAGE_TIMELY_REPLIES.TITLE, {
        count: waitingToReply,
      }) }
      description={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ENCOURAGE_TIMELY_REPLIES.DESCRIPTION) }
      Icon={ EnvelopeIcon }
      action={ {
        title: translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ENCOURAGE_TIMELY_REPLIES.ACTION_TITLE),
        handler: actionHandler,
      } }
    />
  );
};

export default EncourageTimelyReplies;
