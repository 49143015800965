import { sequenceQuery } from '../sequence.toolkit-api';
import { ApiError } from '../../../../../consts/api';
import { CancelConversationSequenceQueryArguments } from '../dto/query-arguments/cancel-conversation-sequence.query-arguments';

type UseCancelConversationSequenceMutationType = [
  (cancelConversationSequence: CancelConversationSequenceQueryArguments) => void,
  AppSelectorType<void, ApiError>
];

export const useCancelConversationSequenceMutation = (): UseCancelConversationSequenceMutationType => {
  const [
    cancelConversationSequence,
    {
      data, isLoading, isError, error: apiError, isSuccess, reset,
    }] = sequenceQuery.useCancelConversationSequenceMutation();

  const meta = {
    isLoading, isError, error: apiError as ApiError, isSuccess, data, resetState: reset,
  };

  return [
    cancelConversationSequence,
    { ...meta },
  ];
};
