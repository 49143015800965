import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useCreateGoogleEmailSenderMutation } from '../../../store/rtk-query/apis/outreach/hooks/create-google-email-sender.mutation-hook';
import { OutreachInitializationDialog } from '../outreach-initialization-dialog/outreach-initialization-dialog.component';
import { OutreachEmailConnectedSuccessfullyDialog } from '../outreach-email-connected-successfully-dialog/outreach-email-connected-successfully-dialog.component';
import { AlertSnackBar } from '../../Common/CustomSnackBar/AlertSnackBar';
import STRING_KEYS from '../../../language/keys';
import { useGetEmailSenderQuery } from '../../../store/rtk-query/apis/outreach/hooks/get-email-sender.query-hook';
import { useCreateMicrosoftEmailSenderMutation } from '../../../store/rtk-query/apis/outreach/hooks/create-microsoft-email-sender.mutation-hook';
import { useMyUserSelector } from '../../../store/rtk-query/apis/user/selectors/get-my-user.selector';

const MISSING_GOOGLE_SCOPE_ERROR_CODE = 12017;
const MISSING_MICROSOFT_SCOPE_ERROR_CODE = 12020;
const MICROSOFT_MISSING_SCOPES_ERROR_MESSAGE = 'The user has denied access to the scope requested by the client application.';
const MICROSOFT_DECLINED_TO_CONSENT_TO_ACCESS_APP_ERROR_MESSAGE = 'AADSTS65004: User declined to consent to access the app.';

export const OutreachInitializationDialogs = ({
  isOpen,
  closeDialogHandler,
  positionId,
  disableSuccessDialog,
}: OutreachInitializationDialogsProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [isOutreachInitializationDialogOpen, setIsOutreachInitializationDialogOpen]  = useState(false);
  const [isOutreachEmailConnectedSuccessfullyDialogOpen, setIsOutreachEmailConnectedSuccessfullyDialogOpen]  = useState(false);
  const [isErrorSnackbarOpen, setIsErrorSnackbarOpen]  = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage]  = useState<string | undefined>();
  const { data: currentUser } = useMyUserSelector();

  const [
    createGoogleEmailSender,
    {
      isSuccess: isCreateGoogleEmailSenderSuccess,
      isError: isCreateGoogleEmailSenderError,
      isLoading: isCreateGoogleEmailSenderLoading,
      error: createGoogleEmailSenderError,
    },
  ] = useCreateGoogleEmailSenderMutation();

  const [
    createMicrosoftEmailSender,
    {
      isSuccess: isCreateMicrosoftEmailSenderSuccess,
      isError: isCreateMicrosoftEmailSenderError,
      isLoading: isCreateMicrosoftEmailSenderLoading,
      error: createMicrosoftEmailSenderError,
    },
  ] = useCreateMicrosoftEmailSenderMutation();

  useGetEmailSenderQuery(!isCreateGoogleEmailSenderSuccess && !isCreateMicrosoftEmailSenderSuccess);

  useEffect(() => {
    if (isCreateGoogleEmailSenderSuccess || isCreateMicrosoftEmailSenderSuccess) {
      setIsOutreachInitializationDialogOpen(false);

      if (disableSuccessDialog) {
        closeDialogHandler();
      } else {
        setIsOutreachEmailConnectedSuccessfullyDialogOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableSuccessDialog, isCreateGoogleEmailSenderSuccess, isCreateMicrosoftEmailSenderSuccess]);

  useEffect(() => {
    if (isCreateGoogleEmailSenderError || isCreateMicrosoftEmailSenderError) {
      setIsOutreachInitializationDialogOpen(false);
      closeDialogHandler();
      setIsErrorSnackbarOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateGoogleEmailSenderError, isCreateMicrosoftEmailSenderError]);

  useEffect(() => {
    if (isOpen) {
      setIsErrorSnackbarOpen(false);
      setIsOutreachInitializationDialogOpen(true);
    }
  }, [isOpen]);

  const onGoogleLoginSuccess = (authCode: string) => {
    createGoogleEmailSender({ authCode, positionId, currentUserId: currentUser.id });
  };

  const onMicrosoftLoginSuccess = (authCode: string) => {
    createMicrosoftEmailSender({ authCode, positionId, currentUserId: currentUser.id });
  };

  const onMicrosoftLoginError = (error: string) => {
    if (error === MICROSOFT_MISSING_SCOPES_ERROR_MESSAGE || error === MICROSOFT_DECLINED_TO_CONSENT_TO_ACCESS_APP_ERROR_MESSAGE) {
      setErrorSnackbarMessage(translate(STRING_KEYS.OUTREACH_INITIALIZATION_DIALOG.PERMISSIONS_ERROR_MESSAGE));
    } else {
      setErrorSnackbarMessage(translate(STRING_KEYS.OUTREACH_INITIALIZATION_DIALOG.GENERAL_ERROR_MESSAGE));
    }
    setIsErrorSnackbarOpen(true);
    setIsOutreachInitializationDialogOpen(false);
    closeDialogHandler();
  };

  const onErrorSnackbarActionClick = () => {
    setIsOutreachInitializationDialogOpen(true);
    setIsErrorSnackbarOpen(false);
  };

  const getErrorSnackbarMessage = () => {
    if (errorSnackbarMessage) {
      return errorSnackbarMessage;
    }

    if (
      createGoogleEmailSenderError?.error?.serverError.internalErrorCode === MISSING_GOOGLE_SCOPE_ERROR_CODE ||
      createMicrosoftEmailSenderError?.error?.serverError.internalErrorCode === MISSING_MICROSOFT_SCOPE_ERROR_CODE) {
      return translate(STRING_KEYS.OUTREACH_INITIALIZATION_DIALOG.PERMISSIONS_ERROR_MESSAGE);
    }

    return translate(STRING_KEYS.OUTREACH_INITIALIZATION_DIALOG.GENERAL_ERROR_MESSAGE);
  };

  return (
    <>
      <OutreachInitializationDialog
        isDialogOpen={ isOutreachInitializationDialogOpen }
        googleSuccessHandler={ onGoogleLoginSuccess }
        microsoftSuccessHandler={ onMicrosoftLoginSuccess }
        microsoftErrorHandler={ onMicrosoftLoginError }
        isCreateEmailSenderLoading={ isCreateGoogleEmailSenderLoading || isCreateMicrosoftEmailSenderLoading }
        closeDialogHandler={ () => {
          setIsOutreachInitializationDialogOpen(false);
          closeDialogHandler();
        } }
      />
      <OutreachEmailConnectedSuccessfullyDialog
        isDialogOpen={ isOutreachEmailConnectedSuccessfullyDialogOpen }
        closeDialogHandler={ () => {
          setIsOutreachEmailConnectedSuccessfullyDialogOpen(false);
          closeDialogHandler();
        } }
      />
      <AlertSnackBar
        title={ translate(STRING_KEYS.OOPSIE) }
        description={ getErrorSnackbarMessage() }
        isOpen={ isErrorSnackbarOpen }
        shouldAutoDismiss
        onClose={ () => setIsErrorSnackbarOpen(false) }
        actionTitle={ translate(STRING_KEYS.TRY_AGAIN).toUpperCase() }
        onActionClick={ onErrorSnackbarActionClick }
      />
    </>
  );
};
