import { paymentQuery } from '../payment.toolkit-api';
import { useAppSelector } from '../../../../selectors';
import { PositionPriceResponse } from '../dto/response/position-price.response';

export const paymentSelector = paymentQuery.endpoints.getPositionPrice.select;

export const useGetPositionPriceSelector = (): AppSelectorType<PositionPriceResponse> => {
  const {
    data, isLoading, isError, isSuccess, error,
  } = useAppSelector(paymentSelector());

  return {
    data: data || {} as PositionPriceResponse,
    error,
    isLoading,
    isError,
    isSuccess,
  };
};
