import styled from 'styled-components';
import { ASSETS, FLUENT_UI_EMOJIS } from '../../../assets';
import { Body2Font, Title3Font } from '../../../themes/fonts';
import { THEME } from '../../../themes';

export const Container = styled.div`
  color: ${THEME.COLORS.CONTENT_A};
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
`;

export const Icon = styled(FLUENT_UI_EMOJIS.ELECTRIC_PLUG)`
  width: 64px;
  height: 64px;
`;

export const ActionIcon = styled(ASSETS.ELECTRIC_PLUG)``;

export const Title = styled.div`
  ${Title3Font};
  font-weight: bold;
`;

export const Description = styled.div`
  ${Body2Font};
  opacity: ${THEME.OPACITY.MED};
`;

export const ContactSupportButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
