/* eslint-disable react/no-array-index-key */
import { useTranslation } from 'react-i18next';
import {
  Fragment, SyntheticEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import STRING_KEYS from '../../../../../../language/keys';
import ConversationGroupEmpty from '../conversation-group-empty/conversation-group-empty.component';
import { ConversationItemProps } from '../../conversation-item/conversation-item';
import ConversationItem from '../../conversation-item/conversation-item.component';
import { useFilterAndGroupManualConversations } from '../../../../hooks/filter-and-group-manual-conversations.hook';
import { useMapConversationsById } from '../../../../hooks/conversations-map-by-id.hook';
import { ConversationGroupProps } from '../conversation-group';
import { ErrorIcon } from '../conversation-group.styled';
import { transformConversationResponseToComponentPropsGroupedByPosition } from './conversation-group-manual-mode.utils';
import { ConversationsGroupProps } from './conversation-group-manual-mode';
import {
  AccordionComponent, AccordionDetails, AccordionDetailsComponent, AccordionSummaryComponent, AccordionSummaryContainer, AccordionSummaryCounter, AccordionSummaryTitle,
  ConversationGroupsContainer, ExpandIcon,
} from './conversation-group-manual-mode.styled';

const ConversationGroupManualMode = ({ conversations }: ConversationsGroupProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [expanded, setExpanded] = useState<string>('');

  const groupedConversations = useFilterAndGroupManualConversations(conversations);

  const conversationByIdMap = useMapConversationsById(conversations);

  const conversationList = useMemo<ConversationGroupProps[]>(() => {
    return transformConversationResponseToComponentPropsGroupedByPosition(conversationByIdMap, groupedConversations);
  }, [conversationByIdMap, groupedConversations]);

  useEffect(() => {
    if (conversationList) {
      const firstActiveConversation = conversationList.find(({ items }) => items.length);
      if (firstActiveConversation) {
        setExpanded(firstActiveConversation.groupNameKey);
      }
    }
  }, [conversationList]);

  const onConversationItemsOpen =
    (key: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? key : '');
    };

  const renderConversationItems = useCallback((items: ConversationItemProps[]) => {
    return items.map(({
      conversationId,
      type,
      conversationMessage,
      url,
      fullName,
      positionId,
    }, index) => (
      <ConversationItem
        positionId={ positionId }
        key={ `${conversationId}_${index}` }
        conversationId={ conversationId }
        type={ type }
        url={ url }
        fullName={ fullName }
        conversationMessage={ conversationMessage }
      />
    ));
  }, []);

  const renderConversations = useCallback(() => {
    if (!conversationList.length) {
      return <ConversationGroupEmpty />;
    }

    return conversationList.map(({ groupNameKey, GroupIcon, items }) => {
      const bounceExists = items?.some((item) => item.conversationMessage?.wasBounced);

      return (
        <Fragment key={ `${groupNameKey}_${items.length}` }>
          <AccordionComponent
            onChange={ onConversationItemsOpen(groupNameKey) }
            expanded={ expanded === groupNameKey }
            disabled={ items.length === 0 }
          >
            <AccordionSummaryComponent
              expandIcon={ <ExpandIcon /> }
            >
              <AccordionSummaryContainer>
                { GroupIcon }
                <AccordionSummaryTitle>{ translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.GROUP_NAME[groupNameKey]) }</AccordionSummaryTitle>
                { bounceExists ? <ErrorIcon /> : <AccordionSummaryCounter>{ items.length }</AccordionSummaryCounter> }
              </AccordionSummaryContainer>
            </AccordionSummaryComponent>
            <AccordionDetails>
              <AccordionDetailsComponent>
                { renderConversationItems(items) }
              </AccordionDetailsComponent>
            </AccordionDetails>
          </AccordionComponent>
        </Fragment>
      );
    });
  }, [conversationList, expanded, translate, renderConversationItems]);

  return (
    <ConversationGroupsContainer>
      {renderConversations()}
    </ConversationGroupsContainer>
  );
};

export default ConversationGroupManualMode;
