import styled from 'styled-components';
import { THEME } from '../../../../../themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINO};
`;

export const WidgetDivider = styled.div<{ isBig?: boolean }>`
  width: 1px;
  height: ${({ isBig }) => isBig ? 83 : 64}px;
  background-color: ${THEME.COLORS.PRIMARY_DARK};
  opacity: ${THEME.OPACITY.GHOST};
  margin: 0 ${THEME.SPACING.BASE};
`;
