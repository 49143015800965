import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { setReferrerAndViewId } from '../store/slices/app-state/app-state.toolkit-slice';
import { useAppState } from '../store/selectors';

/**
 * Track changes in the displayed page
 * each url location change will trigger a new page-id
 * (used for Rudderstack Analytics)
 * note: changes in query params will also trigger the change
 * (for example, for different matches that being handled with ?matchId & ?positionId params)
 */
const usePageView = (): void => {
  const { currentPageId } = useAppState();

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setReferrerAndViewId({
      currentPageId: v4(),
      referrerPageId: currentPageId,
    }));

  // don't add currentPageId to dependencies, to prevent infinite loop
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
};

export default usePageView;
