import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { CreatePositionState } from '../create-position-state.initial-state';
import { LevelOfDegreeEnum } from '../../../../enums/level-of-degree';

export const setDegreesReducer: CaseReducer<CreatePositionState, PayloadAction<LevelOfDegreeEnum[]>> = (state, { payload }) => {
  state.degrees = payload;
};

export const setUserInputFieldOfStudyReducer : CaseReducer<CreatePositionState, PayloadAction<PositionJobRequirementState[]>> = (state, { payload }) => {
  state.userInputFieldsOfStudy = payload;
};

export const resetEducationFieldsReducer : CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  state.userInputFieldsOfStudy = [];
  state.degrees = [];
};
