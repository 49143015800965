import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetLinkedInProfile } from '../../../../hooks/queries';
import STRING_KEYS from '../../../../language/keys';
import PrimaryButton from '../../../../components/Common/Buttons/PrimaryButton';
import Modal from '../../../../components/Common/Modal';
import ModalContent from '../../../../components/Common/Modal/ModalContent';
import ModalFooter from '../../../../components/Common/Modal/ModalFooter';
import ModalHeader from '../../../../components/Common/Modal/ModalHeader';
import {
  COMPONENT_NAME, ELEMENT_TYPE, EVENT_GROUP,
} from '../../../../consts/analytics';
import { PAGE_NAMES } from '../../../../consts/pages';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { ONBOARDING_ANALYTICS_EVENTS } from '../../consts/onboarding-analytics.const';
import {
  Title,
  ButtonWrapper,
} from './company-dialog.styled';
import CompanyInput from './company-input/company-input.component';

type CompanyDialogProps = {
  onClose: () => void,
  onDone: (company:OnboardingCompanyResponse | undefined) => void,
}

const MIN_INPUT_LENGTH = 3;

export const CompanyDialog = ({
  onClose,
  onDone,
}:CompanyDialogProps): JSX.Element => {
  const { t: translate }  = useTranslation();
  const [companyInputValue, setCompanyInputValue] = useState<string>('');
  const {
    isError,
    isLoading,
    data,
    isLinkedInFormatError,
  } = useGetLinkedInProfile(companyInputValue);
  const { analyticsTrackEvent } = useAnalyticsContext();

  const handleOnContinueButton = () => {
    if (data?.[0]) {
      const trackEventPayload = {
        eventName: ONBOARDING_ANALYTICS_EVENTS.ONBOARDING_CONTINUE_BTN_COMPANY_POP_UP_CLICKED,
        eventGroup: EVENT_GROUP.CLICK,
        pageName: PAGE_NAMES.BASIC_INFO,
        componentElementType: ELEMENT_TYPE.LINK,
        componentName: COMPONENT_NAME.COMPANY,
        componentCompany: data[0],
      };

      analyticsTrackEvent(trackEventPayload);
      onDone(data[0]);
      onClose();
    }
  };

  const valueChangeHandler = (event: React.SyntheticEvent<Element, Event>,
    newInputValue: string) => {
    setCompanyInputValue(newInputValue);
  };

  const defineErrorMessage = () => {
    if (isLinkedInFormatError) {
      return translate(STRING_KEYS.ONBOARDING.LINKEDIN_WRONG_FORMAT);
    }

    return translate(STRING_KEYS.ONBOARDING.ONBOARDING_NOT_FOUND_COMPANY);
  };

  const defineError = () => {
    if (companyInputValue.length >= MIN_INPUT_LENGTH) {
      return isError || isLinkedInFormatError;
    }

    return false;
  };

  return (
    <Modal
      onClose={ onClose }
      isLoading={ isLoading }
      height={ 320 }
      width={ 640 }
      backgroundBlur
    >
      <ModalHeader>
        <Title>
          { translate(STRING_KEYS.ONBOARDING.ONBOARDING_COMPANY_LINKEDIN_PROFILE) }
        </Title>
      </ModalHeader>
      <ModalContent>
        <CompanyInput
          onInputValueChange={ valueChangeHandler }
          disabled={ false }
          isError={ defineError() }
          errorMessage={ defineErrorMessage() }
        />
      </ModalContent>
      <ModalFooter>
        <ButtonWrapper
          isDisabled={
            isLoading || defineError() || !companyInputValue || companyInputValue.length < MIN_INPUT_LENGTH
          }
        >
          <PrimaryButton
            onClick={ handleOnContinueButton }
            title={ translate(STRING_KEYS.CONTINUE) }
            type="button"
          />
        </ButtonWrapper>
      </ModalFooter>
    </Modal>
  );
};
