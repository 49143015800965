import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import MenuItem from '../../../../perfect-base-menu/menu-item/menu-item.component';
import BaseMenu from '../../../../perfect-base-menu/perfect-base-menu.component';
import { Divider, ListLabel } from './mention-list.styled';
import { MentionsListProps } from './mention-list';

export const MentionsList = ({
  mentions, mentionState, mentionReference, confirmMentionHandler, mentionsMenuCloseHandler,
}: MentionsListProps): JSX.Element | null => {
  const { t: translate } = useTranslation();

  const menuItems = mentions.map(({ key, mentionInfo }, index) => (
    <Fragment key={ key } >
      { (index > 0) && <Divider /> }
      <ListLabel>{ translate(key) }</ListLabel>
      {
        mentionInfo.map(({ displayValue, value }) => (
          <MenuItem
            key={ displayValue }
            onClick={ () => confirmMentionHandler(value) }
          >
            { displayValue }
          </MenuItem>
        ))
      }
    </Fragment>
  ));

  return (
    <BaseMenu
      isOpen={ mentionState.isOpen }
      onClose={ mentionsMenuCloseHandler }
      anchorElement={ mentionReference || undefined }
      placement="auto"
    >
      { menuItems }
    </BaseMenu>
  );
};
