import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ExactTag,
  TagRow,
} from '../explainability-info-menu.styled';
import { IndustryTagIcon } from '../../match-card/timeline/experience-container/experience-item-tags/experience-item-tags.styled';
import { FLUENT_UI_EMOJIS } from '../../../../../assets';
import { ExplainabilityTypeEnum } from '../../../../../enums/explainability-type.enum';
import { createSkillIcon } from '../../match-card/timeline/experience-container/experience-item-tags/experience-item-tags.component';
import STRING_KEYS from '../../../../../language/keys';
import SelfEvidenceSection from '../sections/self-evidence-section/self-evidence-section.component';
import VerificationSourceSection from '../sections/verification-source-section/verification-source-section.component';
import HeaderSection from '../sections/header-section/header-section.component';
import { ExactAttributeInfoMenuProps } from './exact-attribute-info-menu';
import { Container } from './exact-attribute-info-menu.styled';

const ExactAttributeInfoMenu = ({
  type,
  value,
  origins,
}: ExactAttributeInfoMenuProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const [title, Icon] = useMemo(() => {
    if (type === ExplainabilityTypeEnum.Skill) {
      return [translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY.EXACT_SKILL), createSkillIcon(FLUENT_UI_EMOJIS.HIGH_VOLTAGE_GREEN)];
    }

    return [translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY.EXACT_INDUSTRY), IndustryTagIcon];
  }, [translate, type]);

  return (
    <Container>
      <>
        <HeaderSection
          title={ title }
          Icon={ <Icon /> }
        >
          <TagRow>
            <ExactTag text={ value } />
            { translate(
              type === ExplainabilityTypeEnum.Skill ?
                STRING_KEYS.MATCH_CARD.EXPLAINABILITY.INFO_MENU.SKILL_VERIFIED :
                STRING_KEYS.MATCH_CARD.EXPLAINABILITY.INFO_MENU.INDUSTRY_VERIFIED,
            ) }
          </TagRow>
        </HeaderSection>
        { origins && <VerificationSourceSection type={ type } value={ value } origins={ origins } /> }
        { origins && <SelfEvidenceSection origins={ origins } /> }
      </>
    </Container>
  );
};

export default ExactAttributeInfoMenu;
