import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';

import {
  Container,
  Subtitle,
  TextContainer,
  Title,
  Years,
  YearsDecoration,
  YearsTitle,
  YearsWrapper,
} from './experience-badge.styled';

type ExperienceBadgeProps = {
  totalExperienceYears: number,
  totalRelevantExperienceYears: number,
}

export const ExperienceBadge = ({
  totalExperienceYears,
  totalRelevantExperienceYears,
}: ExperienceBadgeProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <YearsDecoration>
        <YearsWrapper>
          <Years
            end={ totalRelevantExperienceYears }
            duration={ 2 }
            decimal="."
            decimals={ 1 }
            useEasing
          />
          <YearsTitle>{ translate(STRING_KEYS.YEARS) }</YearsTitle>
        </YearsWrapper>
      </YearsDecoration>
      <TextContainer>
        <Title>
          {translate(STRING_KEYS.YEARS_EXPERIENCE_TITLE)}
        </Title>
        <Subtitle>
          {translate(STRING_KEYS.YEARS_EXPERIENCE_SUBTITLE, { totalYears: totalExperienceYears })}
        </Subtitle>
      </TextContainer>
    </Container>
  );
};
