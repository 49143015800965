import styled from 'styled-components';
import { FLUENT_UI_EMOJIS } from '../../../../../../assets';
import { IconCss } from '../base-tag/base-funnel-tag.styled';

export const ArrowIcon = styled(FLUENT_UI_EMOJIS.RIGHT_ARROW_CURVING_LEFT)`
  ${IconCss};
`;

export const HandsIcon = styled(FLUENT_UI_EMOJIS.CLAPPING_HANDS)`
  ${IconCss};
`;

export const MailboxIcon = styled(FLUENT_UI_EMOJIS.CLOSED_MAILBOX_WITH_RAISED_FLAG)`
  ${IconCss};
`;

export const HighVoltageIcon = styled(FLUENT_UI_EMOJIS.HIGH_VOLTAGE)`
  ${IconCss};
`;

export const SpeechBalloonIcon = styled(FLUENT_UI_EMOJIS.SPEECH_BALLOON)`
  ${IconCss};
`;
