import styled  from 'styled-components';
import { THEME } from '../../../../../../themes';
import { CaptionFont, Subtitle1Font } from '../../../../../../themes/fonts';
import { FLUENT_UI_EMOJIS } from '../../../../../../assets';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${THEME.SPACING.BASE};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MICRO};
`;

export const Title = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_PRIMARY};
`;

export const Description = styled.div`
  ${CaptionFont};
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const TitleIcon = styled(FLUENT_UI_EMOJIS.GLOWING_STAR)``;
