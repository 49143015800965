import { useTranslation } from 'react-i18next';
import BaseFunnelTag from '../base-tag/base-funnel-tag.component';
import STRING_KEYS from '../../../../../../language/keys';
import { LetterIcon, StarStruckIcon, TrophyIcon } from './replied-tags.styled';

const TOOLTIP_KEY = STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.REPLIED.INFO_TEXT;

export const ReplayRateLow = ({ replayRate }: ReplayRateTagProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseFunnelTag
      emoji={ <LetterIcon /> }
      text={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.REPLIED.TAGS.LOW, {
        rate: Math.trunc(replayRate * 100),
      }) }
      infoText={ translate(TOOLTIP_KEY) }
    />
  );
};

export const ReplayRateMed = ({ replayRate }: ReplayRateTagProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseFunnelTag
      emoji={ <StarStruckIcon /> }
      text={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.REPLIED.TAGS.MED, {
        rate: Math.trunc(replayRate * 100),
      }) }
      infoText={ translate(TOOLTIP_KEY) }
    />
  );
};

export const ReplayRateHigh = ({ replayRate }: ReplayRateTagProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseFunnelTag
      emoji={ <TrophyIcon /> }
      text={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.REPLIED.TAGS.HIGH, {
        rate: Math.trunc(replayRate * 100),
      }) }
      infoText={ translate(TOOLTIP_KEY) }
    />
  );
};
