import styled, { css } from 'styled-components';
import { ASSETS } from '../../../../assets';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { THEME } from '../../../../themes';
import { pulsateAnimation } from '../../../../animations';

export const TitleIcon = styled(ASSETS.STARS_GROUP_FILL)`
  height: 24px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
`;

export const SaveButtonActivateAnimation = css`
  ${pulsateAnimation} 1.5s ease-in-out infinite both;
`;

export const SaveButton = styled(BaseButton)<{ isActivated?: boolean }>`
  background-color: ${THEME.COLORS.ACCENT};
  animation: ${({ isActivated }) => isActivated ? undefined : SaveButtonActivateAnimation};
`;

export const PreviewButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.PURE_WHITE};
  color: ${THEME.COLORS.ACCENT};
`;
