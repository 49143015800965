/* eslint-disable react/no-array-index-key */
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanySizeTierEnum } from '../../../../../../../enums/company-size-tier.enum';
import STRING_KEYS from '../../../../../../../language/keys';
import { FLUENT_UI_EMOJIS } from '../../../../../../../assets';
import {
  ExplainabilitySimilarAttribute,
  ExplainabilityAttribute,
} from '../../../../../../../store/rtk-query/apis/match/dto/response/match.response';
import { ExplainabilityTypeEnum } from '../../../../../../../enums/explainability-type.enum';
import CompanySizeInfoMenu
  from '../../../../explainability-info-menu/company-size-info-menu/company-size-info-menu.component';
import ExactAttributeInfoMenu from '../../../../explainability-info-menu/exact-attribute-info-menu/exact-attribute-info-menu.component';
import SimilarAttributeInfoMenu from '../../../../explainability-info-menu/similar-attribute-info-menu/similar-attribute-info-menu.component';
import { ExperienceTagsProps } from './experience-item-tags';
import {
  CompanySizeTag,
  Container,
  IndustryTagIcon,
  LargeCompanyIcon,
  MediumCompanyIcon,
  MoreTags,
  SkillTagAskedByUserIcon,
  SkillTagAskedByUserIconWrapper,
  SkillTagIcon,
  SmallCompanyIcon,
  VeryLargeCompanyIcon,
  ExactAttributeTag,
  SimilarAttributeTag,
  AdditionalTag,
} from './experience-item-tags.styled';

export const COMPANY_SIZE_ICON_MAP = {
  [CompanySizeTierEnum.Small]: SmallCompanyIcon,
  [CompanySizeTierEnum.Medium]: MediumCompanyIcon,
  [CompanySizeTierEnum.Large]: LargeCompanyIcon,
  [CompanySizeTierEnum.VeryLarge]: VeryLargeCompanyIcon,
};

export const createSkillIcon = (src: string) => {
  // eslint-disable-next-line react/display-name
  return (): JSX.Element => (
    <SkillTagAskedByUserIconWrapper>
      <SkillTagAskedByUserIcon src={ src } />
    </SkillTagAskedByUserIconWrapper>
  );
};

function ExperienceTags({
  role,
  companyName,
  isExpanded,
  expandHandler,
}: ExperienceTagsProps): JSX.Element | null {
  const { t: translate } = useTranslation();

  const renderExactAttributeInfoMenu = (type: ExplainabilityTypeEnum, attribute: ExplainabilityAttribute) => {
    return (
      <ExactAttributeInfoMenu
        type={ type }
        value={ attribute.value }
        companyName={ [companyName] }
        origins={ attribute.origins }
      />
    );
  };

  const renderSimilarAttributeInfoMenu = (type: ExplainabilityTypeEnum, attribute: ExplainabilitySimilarAttribute) => {
    return (
      <SimilarAttributeInfoMenu
        type={ type }
        value={ attribute.value }
        originalValue={ attribute.originalValue }
        similarExplanation={ attribute.similarityExplanation }
        relevanceExplanation={ attribute.relevanceExplanation }
        origins={ attribute.origins }
      />
    );
  };

  const renderExactSkillsTags = () => {
    return role.explainability?.relevantSkills?.map((skill, index) => (
      <ExactAttributeTag
        key={ `experience_${role.experienceId}_exact_skill_tag_${skill.value}_${index}` }
        className="exact-attribute-tag"
        text={ skill.value }
        Icon={ createSkillIcon(FLUENT_UI_EMOJIS.HIGH_VOLTAGE_GREEN) }
        tagInfo={ translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY.EXACT_SKILL_MORE_INFO_TOOLTIP_TEXT) }
        menu={ renderExactAttributeInfoMenu(ExplainabilityTypeEnum.Skill, skill) }
      />
    ));
  };

  const renderSimilarSkillsTags = () => {
    return role.explainability?.similarSkills?.map((skill, index) => (
      <SimilarAttributeTag
        key={ `experience_${role.experienceId}_similar_skill_tag_${skill.value}_${index}` }
        className="similar-attribute-tag"
        text={ skill.value }
        Icon={ createSkillIcon(FLUENT_UI_EMOJIS.HIGH_VOLTAGE_PURPLE) }
        tagInfo={ translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY.SIMILAR_SKILL_MORE_INFO_TOOLTIP_TEXT) }
        menu={ renderSimilarAttributeInfoMenu(ExplainabilityTypeEnum.Skill, skill) }
      />
    ));
  };

  const renderExactIndustriesTags = () => {
    return role.explainability?.companyInsights?.map((industry, index) => (
      <ExactAttributeTag
        key={ `experience_${role.experienceId}_exact_industry_tag_${industry.value}_${index}` }
        className="exact-attribute-tag"
        text={ industry.value }
        Icon={ IndustryTagIcon }
        tagInfo={ translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY.EXACT_INDUSTRY_MORE_INFO_TOOLTIP_TEXT) }
        menu={ renderExactAttributeInfoMenu(ExplainabilityTypeEnum.Industry, industry) }
      />
    ));
  };

  const renderSimilarIndustriesTags = () => {
    return role.explainability?.similarCompanyInsights?.map((industry, index) => (
      <SimilarAttributeTag
        key={ `experience_${role.experienceId}_similar_industry_tag_${industry.value}_${index}` }
        className="similar-attribute-tag"
        text={ industry.value }
        Icon={ createSkillIcon(FLUENT_UI_EMOJIS.HIGH_VOLTAGE_PURPLE) }
        tagInfo={ translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY.SIMILAR_INDUSTRY_MORE_INFO_TOOLTIP_TEXT) }
        menu={ renderSimilarAttributeInfoMenu(ExplainabilityTypeEnum.Industry, industry) }
      />
    ));
  };

  const renderCompanySizeTag = () => {
    if (!role.companySize?.value) {
      return undefined;
    }

    return (
      <CompanySizeTag
        key={ `experience_${role.experienceId}_company_size_tag` }
        text={ translate(STRING_KEYS.COMPANY_SIZE[role.companySize.value]) }
        Icon={ COMPANY_SIZE_ICON_MAP[role.companySize.value] }
        tagInfo={ translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY.COMPANY_SIZE_MORE_INFO_TOOLTIP_TEXT) }
        askedByUser={ role.companySize?.askedByUser }
        menu={ (
          <CompanySizeInfoMenu
            companySize={ role.companySize.value }
            companyName={ companyName }
          />
        ) }
      />
    );
  };

  const renderAdditionalAttributesTags = (attributes: ExplainabilityAttribute[] | undefined, Icon: FC) => {
    return attributes?.map((attribute, index) => (
      <AdditionalTag
        key={ `experience_${role.experienceId}_additional_attribute_tag_${attribute.value}_${index}` }
        text={ attribute.value }
        Icon={ Icon }
        disableHover
      />
    )) || [];
  };

  const renderRelevantTags = () => {
    const tags = [
      ...(renderExactSkillsTags() || []),
      ...(renderExactIndustriesTags() || []),
      ...(renderSimilarSkillsTags() || []),
      ...(renderSimilarIndustriesTags() || []),
    ];

    const companySizeTag = renderCompanySizeTag();
    if (companySizeTag) {
      tags.unshift(companySizeTag);
    }

    return tags;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const RelevantTags = useMemo(() => renderRelevantTags(), [role]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const OtherTags = useMemo(() => (
    [
      ...renderAdditionalAttributesTags(role.explainability?.additionalSkills, SkillTagIcon),
      ...renderAdditionalAttributesTags(role.explainability?.additionalCompanyInsights, IndustryTagIcon),
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [role.explainability?.additionalCompanyInsights, role.explainability?.additionalSkills]);

  if (!RelevantTags?.length && !OtherTags?.length) {
    return null;
  }

  return (
    <Container>
      { RelevantTags }
      { (!!OtherTags?.length && !isExpanded) && (
        <MoreTags
          tagInfo={ translate(STRING_KEYS.MATCH_CARD.EXPERIENCE.MORE_TAGS_TOOLTIP) }
          text={ `+${OtherTags.length}` }
          clickHandler={ () => expandHandler?.() }
        />
      ) }
      { isExpanded && OtherTags }
    </Container>
  );
}

export default ExperienceTags;
