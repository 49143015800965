import { forwardRef, ReactElement, Ref } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { FooterContainer } from './full-screen-dialog-footer.styled';

const FullScreenDialogFooter = forwardRef((
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<HTMLDivElement>,
) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FooterContainer { ...props } ref={ ref } >
      { props.children }
    </FooterContainer>
  );
});

FullScreenDialogFooter.displayName = 'FullScreenDialogFooter';

export default FullScreenDialogFooter;
