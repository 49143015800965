import { Trans } from 'react-i18next';
import { useMemo } from 'react';
import { WidgetDivider } from '../widgets-container.styled';
import { RolesList } from '../../../../../../components/match/match-content/smart-widgets/experience-by-role-widget/roles-list/roles-list.component';
import STRING_KEYS from '../../../../../../language/keys';
import { ExperiencePieChart }
  from '../../../../../../components/match/match-content/smart-widgets/experience-by-role-widget/experience-pie-chart/experience-pie-chart.component';
import BaseWidget from '../base-widget/base-widget.component';
import {
  Container, ChartWrapper, CardBody, RolesWrapper, Icon,
} from './experience-by-industry-widget.styled';

const ExperienceByIndustryWidget = ({ experienceByIndustries }: ExperienceByIndustryWidgetProps): JSX.Element => {
  const chartData = useMemo(() => experienceByIndustries.map((industry) => ({ title: industry.industry, durationInMonths: industry.tenureInMonths })),
    [experienceByIndustries]);

  return (
    <BaseWidget
      title={ <Trans i18nKey={ STRING_KEYS.MATCH_WIDGETS.EXPERIENCE_BY_INDUSTRY.TITLE } /> }
      Icon={ Icon }
    >
      <Container>
        <CardBody>
          <ChartWrapper>
            <ExperiencePieChart data={ chartData } isSmall />
          </ChartWrapper>
          <WidgetDivider isBig />
          <RolesWrapper>
            <RolesList data={ chartData } disablePadding />
          </RolesWrapper>
        </CardBody>
      </Container>
    </BaseWidget>
  );
};

export default ExperienceByIndustryWidget;
