import { LogLevelNumbers, RootLogger } from 'loglevel';
import * as FullStory from '@fullstory/browser';
import { LogMessage } from '../models/log-message.interface';
import { logMessageToFullStoryLog } from '../utils/log-formatter';

/** Middleware that prints application logs to FullStory */
const  fullStoryLoggingMiddleware = (logger: RootLogger): void => {
  const originalFactory = logger.methodFactory;
  logger.methodFactory =
    (methodName: string, logLevel: LogLevelNumbers, loggerName: string | symbol) => {
      const rawMethod = originalFactory(methodName, logLevel, loggerName);

      return (...message: unknown[]) => {
        try {
          let msg;
          let msgLogLevel;
          try {
            const firstElem = message[0] as string;
            const parsedMessage: LogMessage = JSON.parse(firstElem);

            const logObject = logMessageToFullStoryLog(parsedMessage);
            msg = JSON.stringify(logObject);
            msgLogLevel = logObject.level;
          } catch (e) {
            // couldn't parse
            msg = message.join(' ');
            msgLogLevel = methodName;
          }

          FullStory.log(msgLogLevel as FullStory.LogLevel, msg);
        } catch (error) {
          // FullStory probably not working or wasn't initiated yet,
          // so we need to skip it
        }
        rawMethod(...message);
      };
    };
  logger.setLevel(logger.getLevel());
};

export default fullStoryLoggingMiddleware;
