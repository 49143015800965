import { useTranslation } from 'react-i18next';
import { useMemo, useRef } from 'react';
import { DialogWidthSize } from '../../../../../../components/dialogs/base-dialog/base-dialog.enums';
import STRING_KEYS from '../../../../../../language/keys';
import CustomModeStepChip from '../step-chip/custom-mode-step-chip.component';
import { THEME } from '../../../../../../themes';
import StepEditor from '../step-editor/step-editor.component';
import SelectionButton from '../selection-button/selection-button.component';
import { SelectionButtonEnum } from '../selection-button/selection-button.enum';
import { PerfectButtonHoverVariants, PerfectButtonVariants } from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { Step } from '../campaign-custom-mode';
import {
  SequenceMessageTypeEnum,
} from '../../../../../../store/rtk-query/apis/sequence/enums/sequence-message-type.enum';
import {
  CUSTOM_MODE_NEXT_BUSINESS_DAY_VALUE,
  CUSTOM_MODE_SOON_AS_POSSIBLE_VALUE,
} from '../campaign-custom-mode.consts';
import {
  AddStepButton,
  AddStepIcon,
  CalendarIcon,
  CustomModeDialogBodyContainer,
  CustomModeDialogContainer,
  CustomModeDialogFooterContainer,
  CustomModeDialogHeaderContainer,
  Description,
  Divider,
  RemoveStepButton,
  SaveStepsButton,
  SenderIcon,
  StepContainer,
  HeaderContainer,
  HeaderTextContainer,
  TimerIcon,
  Title,
  RemoveButtonContainer,
  CustomModeStepsContainer,
  RemoveStepIcon,
  AlertContainer,
  HeaderDescriptionContainer,
} from './campaign-custom-mode-dialog.styled';
import { MAX_CUSTOM_STEPS, OUTREACH_CUSTOM_STEP_DEFAULT_TIME } from './campaign-custom-mode-dialog.consts';
import { CampaignCustomModeDialogProps } from './campaign-custom-mode-dialog';
import Banner from './validation-banner/validation-banner.component';
import { ValidationBannerEnum } from './validation-banner/validation-banner.enum';

const CampaignCustomModeDialog = ({
  isDialogOpen, closeDialogHandler, steps, changeStepHandler, removeStepHandler,
  setEmptyStepHandler, saveStepsHandler, errors, isServerError, isServerLoading, isSaveButtonDisabled,
}: CampaignCustomModeDialogProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const stepsContainerRef = useRef<HTMLDivElement>(null);
  const showAddStepButton = useMemo(() => {
    return steps.length < MAX_CUSTOM_STEPS;
  }, [steps.length]);

  const onSendAsReplyToggleChange = (step: Step, stepIndex: number, value: boolean) => {
    const changedStep = {
      ...step,
      sendAsReply: value,
    };
    changeStepHandler(stepIndex, changedStep);
  };

  const onSubjectChange = (step: Step, stepIndex: number, changedSubject: string) => {
    const changedStep = {
      ...step,
      subject: changedSubject,
    };
    changeStepHandler(stepIndex, changedStep);
  };

  const onMessageChange = (step: Step, stepIndex: number, changedMessage: string) => {
    const changedStep = {
      ...step,
      message: changedMessage,
    };
    changeStepHandler(stepIndex, changedStep);
  };

  const onMessageTypeChange = (step: Step, stepIndex: number, messageType: SequenceMessageTypeEnum) => {
    const changedStep = {
      ...step,
      type: messageType,
    };
    changeStepHandler(stepIndex, changedStep);
  };

  const onDaysChange = (step: Step, stepIndex: number, days: string, isWorkDays: boolean) => {
    const changedStep = {
      ...step,
      days,
      isWorkDays,
    };
    changeStepHandler(stepIndex, changedStep);
  };

  const onFirstStepDaysChange = (step: Step, stepIndex: number, days: string) => {
    const time = days === CUSTOM_MODE_SOON_AS_POSSIBLE_VALUE ? undefined : OUTREACH_CUSTOM_STEP_DEFAULT_TIME;
    const changedStep = {
      ...step,
      time,
    };
    changeStepHandler(stepIndex, changedStep);
  };

  const onTimeChange = (step: Step, stepIndex: number, time: string) => {
    const changedStep = {
      ...step,
      time,
    };
    changeStepHandler(stepIndex, changedStep);
  };

  const renderTypeSelectionButton = (step: Step, index: number) => {
    const isFirstStep = index === 0;
    const descriptionTranslationKey = isFirstStep ?
      STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.FIRST_DESCRIPTION : STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.FOLLOW_UP_DESCRIPTION;

    const selectedType = step.type ?
      translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECT_METHOD[step.type]) :
      translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECT_METHOD.NEW);

    return (
      <>
        <HeaderTextContainer>
          { translate(descriptionTranslationKey) }
        </HeaderTextContainer>
        <SelectionButton
          messageTypeChangeHandler={ (newStepType) => onMessageTypeChange(step, index, newStepType) }
          Icon={ SenderIcon }
          type={ SelectionButtonEnum.SenderTypes }
          selected={ !!step.type }
          step={ step }
        >
          { selectedType }
        </SelectionButton>
      </>
    );
  };

  const renderFirstStepDaysSelectionButton = (step: Step, index: number) => {
    const value = !step.time ?
      STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.FIRST_STEP_MESSAGE_AS_SOON_AS_POSSIBLE :
      STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.FIRST_STEP_MESSAGE_NEXT_BUSINESS_DAY;

    const label = step.days === CUSTOM_MODE_NEXT_BUSINESS_DAY_VALUE ? translate(STRING_KEYS.IN) : undefined;

    return (
      <>
        { !!label && (
          <HeaderTextContainer>
            { label }
          </HeaderTextContainer>
        ) }
        <SelectionButton
          onFirstStepDaysChangeHandler={ (newDays) => onFirstStepDaysChange(step, index, newDays) }
          Icon={ CalendarIcon }
          type={ SelectionButtonEnum.FirstMessageDay }
          selected={ !!step.days }
          step={ step }
        >
          { translate(value) }
        </SelectionButton>
      </>
    );
  };

  const renderOtherStepDaysSelectionButton = (step: Step, index: number) => {
    const isFirstStep = index === 0;
    const descriptionTranslationKey = isFirstStep ?
      STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.FIRST_DESCRIPTION : STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.FOLLOW_UP_DESCRIPTION;

    const daysWithLabel = step.days ?
      `${step.days} ${translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECT_DAYS_PLACEHOLDER)}` :
      translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECT_DAYS_PLACEHOLDER);

    return (
      <>
        <HeaderTextContainer>
          { translate(descriptionTranslationKey[1]) }
        </HeaderTextContainer>
        <SelectionButton
          onDaysChangeHandler={ (newDays, isWorkDays) => onDaysChange(step, index, newDays, isWorkDays) }
          Icon={ CalendarIcon }
          type={ SelectionButtonEnum.Days }
          selected={ !!step.days }
          step={ step }
        >
          { daysWithLabel }
        </SelectionButton>
      </>
    );
  };

  const renderTimeSelectionButton = (step: Step, index: number): JSX.Element | null => {
    const isFirstStep = index === 0;
    if (isFirstStep && !step.time) {
      return null;
    }

    const descriptionTranslationKey = isFirstStep ?
      STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.FIRST_DESCRIPTION : STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.FOLLOW_UP_DESCRIPTION;

    return (
      <>
        <HeaderTextContainer>
          { translate(descriptionTranslationKey[2]) }
        </HeaderTextContainer>
        <SelectionButton
          onTimeChangeHandler={ (newTime) => onTimeChange(step, index, newTime) }
          Icon={ TimerIcon }
          type={ SelectionButtonEnum.Time }
          selected={ !!step.time }
          step={ step }
        >
          { step.time || translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECT_TIME_PLACEHOLDER) }
        </SelectionButton>
      </>
    );
  };

  const renderSteps = () => {
    return steps?.map((step, index) => {
      const isFirstStep = index === 0;
      const { sendAsReply, initMessage, initSubject } = step;

      return (
        // eslint-disable-next-line react/no-array-index-key
        <StepContainer key={ `campaign_custom_mode_${index}` } warning={ !!(errors?.[index]) }>
          <HeaderContainer>
            <RemoveButtonContainer>
              <CustomModeStepChip
                step={ index + 1 }
                color={ THEME.COLORS.SURFACE_PRIMARY }
                backgroundColor={ THEME.COLORS.PRIMARY_DARK }
              />
              {
                index > 0 && (
                  <RemoveStepButton
                    type="button"
                    variant={ PerfectButtonVariants.Ghost }
                    hoverVariant={ PerfectButtonHoverVariants.Darkened }
                    StartIcon={ RemoveStepIcon }
                    onClick={ () => removeStepHandler(index) }
                  >
                    { translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.REMOVE_STEP_BUTTON) }
                  </RemoveStepButton>
                )
              }
            </RemoveButtonContainer>
            <div>
              <HeaderTextContainer>{ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.TITLE) }</HeaderTextContainer>
              <HeaderDescriptionContainer>
                { renderTypeSelectionButton(step, index) }
                { isFirstStep && (renderFirstStepDaysSelectionButton(step, index)) }
                { !isFirstStep && (renderOtherStepDaysSelectionButton(step, index)) }
                { renderTimeSelectionButton(step, index) }
              </HeaderDescriptionContainer>
            </div>
          </HeaderContainer>
          <StepEditor
            step={ index + 1 }
            message={ initMessage }
            subject={ initSubject }
            sendAsReply={ sendAsReply }
            sandAsReplyHandler={ (value) => onSendAsReplyToggleChange(step, index, value) }
            changeSubjectHandler={ (changedSubject) => onSubjectChange(step, index, changedSubject) }
            changeMessageHandler={ (changedMessage) => onMessageChange(step, index, changedMessage) }
          />
        </StepContainer>
      );
    });
  };

  const onAddStateButtonClick = () => {
    setEmptyStepHandler();
    setTimeout(() => {
      stepsContainerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    });
  };

  return (
    <>
      <CustomModeDialogContainer
        isOpen={ isDialogOpen }
        onClose={ closeDialogHandler }
        widthSize={ DialogWidthSize.Large }
      >
        <CustomModeDialogHeaderContainer>
          <Title>{ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.TITLE) }</Title>
          <Description>{ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.DESCRIPTION) }</Description>
        </CustomModeDialogHeaderContainer>
        <CustomModeDialogBodyContainer>
          <CustomModeStepsContainer ref={ stepsContainerRef } >
            { renderSteps() }
            { showAddStepButton && (
              <AddStepButton
                type="button"
                StartIcon={ AddStepIcon }
                onClick={ onAddStateButtonClick }
              >
                { translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.ADD_STEP_BUTTON) }
              </AddStepButton>
            )}
          </CustomModeStepsContainer>
        </CustomModeDialogBodyContainer>
        <div>
          <>{ (!!(errors?.length) || isServerError) && (
            <AlertContainer>
              <Banner
                type={ isServerError ? ValidationBannerEnum.Error : ValidationBannerEnum.Warning }
              />
            </AlertContainer>
          )}
          </>
          <Divider />
        </div>
        <CustomModeDialogFooterContainer>
          <SaveStepsButton
            type="button"
            onClick={ saveStepsHandler }
            isLoading={ isServerLoading }
            disabled={ isSaveButtonDisabled }
          >
            { translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.SAVE_STEPS_BUTTON) }
          </SaveStepsButton>
        </CustomModeDialogFooterContainer>
      </CustomModeDialogContainer>
    </>
  );
};

export default CampaignCustomModeDialog;
