import { OnboardingStep } from '../onboarding.enums';
import { PageEventPayload, TrackEventPayload } from '../../../@types/analytics/eventPayload';
import { PAGE_NAMES } from '../../../consts/pages';
import { COMPONENT_NAME } from '../../../consts/analytics';
import { ProgressStep } from '../../../components/Common/headers/progress-bar-header/progress-bar/progress-bar';

/**
 * Get step number for BI events
 * @returns number
 */
export const getStepIndex = (currentPage: string, steps: ProgressStep[]): number => {
  if (!steps) {
    return -1;
  }

  return steps.findIndex(({ step }) => currentPage === step);
};

/**
 * Get BI event data by onboarding step
 * @returns number
 */
export const generateDataForBIEventsByState =
  (currentPage: string, steps: ProgressStep[]): PageEventPayload | TrackEventPayload | null => {
    switch (currentPage) {
      case OnboardingStep.LetsBegin:
        return {
          pageName: PAGE_NAMES.BASIC_INFO,
          stepNumber: getStepIndex(currentPage, steps),
        };
      case OnboardingStep.Experience:
        return {
          pageName: PAGE_NAMES.EXPERIENCE_PAGE_VIEWED,
          stepNumber: getStepIndex(currentPage, steps),
          componentName: COMPONENT_NAME.EXPERIENCE_LEVEL,
        };
      case OnboardingStep.Background:
        return {
          pageName: PAGE_NAMES.INDUSTRY_PAGE_VIEWED,
          stepNumber: getStepIndex(currentPage, steps),
          componentName: COMPONENT_NAME.BACKGROUND,
        };
      case OnboardingStep.Education:
        return {
          pageName: PAGE_NAMES.EDUCATION_PAGE_VIEWED,
          stepNumber: getStepIndex(currentPage, steps),
          componentName: COMPONENT_NAME.FIELD_OF_STUDY,
        };
      case OnboardingStep.JobDescription:
        return {
          pageName: PAGE_NAMES.JOB_DESCRIPTION_ONBOARDING,
          stepNumber: getStepIndex(currentPage, steps),
        };
      case OnboardingStep.EmailVerification:
        return {
          pageName: PAGE_NAMES.EMAIL_VERIFICATION_ONBOARDING,
          stepNumber: getStepIndex(currentPage, steps),
        };
      default:
        return null;
    }
  };

export const buildJobRequirementsComponentMetadata =
  (jobRequirements: PositionJobRequirementState[], valueKey: string): string[] => jobRequirements.reduce((acc, jobRequirement) => {
    if (jobRequirement && jobRequirement.selected) {
      acc.push((jobRequirement?.[valueKey as keyof PositionJobRequirementState] as string).toLowerCase());
    }

    return acc;
  }, [] as string[]);
