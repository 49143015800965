import styled, { css } from 'styled-components';
import { THEME } from '../../../themes';
import { hexToRgbA } from '../../../utils/colors';
import { ghostElevation } from '../../../themes/elevations';
import { BaseSelectionGroupVariant } from './base-selection-group.enums';

const getVariantRadiusCss = (variant: BaseSelectionGroupVariant) => {
  if (variant === BaseSelectionGroupVariant.Round) {
    return css`
      border-radius: ${THEME.RADIUS.CURVY};
    `;
  }

  if (variant === BaseSelectionGroupVariant.Sharp) {
    return css`
      border-radius: ${THEME.RADIUS.SHARP};
    `;
  }

  return '';
};

export const Container = styled.div<{ variant: BaseSelectionGroupVariant}>`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  height: 48px;
  width: fit-content;
  padding: ${THEME.SPACING.MICRO};
  background-color: ${THEME.COLORS.PURE_WHITE};
  ${ghostElevation};
  border: solid 1px ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.GHOST)};
  ${({ variant }) => getVariantRadiusCss(variant)}
`;
