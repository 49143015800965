import { useTranslation } from 'react-i18next';
import BaseFunnelTag from '../base-tag/base-funnel-tag.component';
import STRING_KEYS from '../../../../../../language/keys';
import {
  ArrowIcon, HandsIcon, HighVoltageIcon, MailboxIcon, SpeechBalloonIcon,
} from './contacted-tags.styled';

export const Followups = ({ followups }: ContactedTagProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseFunnelTag
      emoji={ <ArrowIcon /> }
      text={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.CONTACTED.TAGS.FOLLOWUPS, {
        count: followups,
      }) }
    />
  );
};

export const WellDone = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseFunnelTag
      emoji={ <HandsIcon /> }
      text={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.CONTACTED.TAGS.WELL_DONE) }
    />
  );
};

export const HighOpenRate = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseFunnelTag
      emoji={ <HighVoltageIcon /> }
      text={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.CONTACTED.TAGS.HIGH_OPEN_RATE) }
    />
  );
};

export const OpenRate = ({ openRate = 0 }: ContactedTagProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseFunnelTag
      emoji={ <MailboxIcon /> }
      text={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.CONTACTED.TAGS.OPEN_RATE, {
        rate: openRate * 100,
      }) }
    />
  );
};

export const Sent = ({ sentMessages }: ContactedTagProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseFunnelTag
      emoji={ <SpeechBalloonIcon /> }
      text={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.CONTACTED.TAGS.SENT, { count: sentMessages }) }
    />
  );
};
