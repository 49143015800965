import { useTranslation } from 'react-i18next';
import BaseWidget from '../base-widget/base-widget.component';
import STRING_KEYS from '../../../../../../language/keys';
import {
  Container, Description, TextContainer, Title, TitleIcon,
} from './badge-widget.styled';

const BadgeWidget = ({
  title,
  description,
  Icon,
}: BadgeWidgetProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseWidget
      title={ translate(STRING_KEYS.MATCH_WIDGETS.BADGE_TITLE) }
      Icon={ TitleIcon }
    >
      <Container>
        <div>{ Icon }</div>
        <TextContainer>
          <Title>{ title }</Title>
          <Description>{ description }</Description>
        </TextContainer>
      </Container>
    </BaseWidget>
  );
};

export default BadgeWidget;
