/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAuthActions } from '@frontegg/react';
import { OnboardingStep } from '../onboarding.enums';
import { buildJobRequirementsComponentMetadata } from '../utils/onboarding-bi-events.utls';
import { ELEMENT_TYPE } from '../../../consts/analytics';
import { usePositionBackgroundSelector } from '../../../store/slices/create-position-state/selectors/create-position-background.selector';
import { useCreatePositionEducationSelector } from '../../../store/slices/create-position-state/selectors/create-position-education.selector';
import {
  useBackClickEvent,
  useContinueClickEvent,
  useSkipClickEvent,
} from '../../create-position/hooks/create-position-actions-bi-evnts.hooks';
import { ProgressStep } from '../../../components/Common/headers/progress-bar-header/progress-bar/progress-bar';
import { logout, setIsOnboardingLogout } from '../../../store/slices/app-state/app-state.toolkit-slice';
import {
  resetEducationFields,
  resetExperienceLevels,
  resetCompanyBackground,
  resetJobDescriptionFields,
  resetSkills,
} from '../../../store/slices/create-position-state/create-position-state.toolkit-slice';
import {
  onboardingResetFields,
  resetOnboardingState,
  setOnboardingContinueButtonDisable,
  setOnboardingContinueButtonHide,
  setOnboardingContinueButtonShow,
  setOnboardingLoader,
  setOnboardingSkipButtonHide,
  setOnboardingSkipButtonShow,
} from '../../../store/slices/onboarding-state/onboarding-state.toolkit-slice';
import { prepareOnboardingStepForNavigating } from '../services/onboarding.routing-resolver';
import { useOnboardingStoreMetadataSelector } from '../../../store/slices/onboarding-state/selectors/onboarding-selectors.hooks';
import { useCreateOnboardingLetsBeginSelector } from '../../../store/slices/onboarding-state/selectors/create-onboarding-lets-begin.selector';
import { useUpdateOnboardingProgress } from '../../../store/rtk-query/apis/onboarding/hooks/update-onboarding-progress.mutation-hook';
import { useCreateOnboardingBackgroundSelector } from '../../../store/slices/onboarding-state/selectors/create-onboarding-background.selector';
import { useCreateOnboardingEducationSelector } from '../../../store/slices/onboarding-state/selectors/create-onboarding-education.selector';
import { useCreateOnboardingJobDescriptionSelector } from '../../../store/slices/onboarding-state/selectors/create-onboarding-job-description.selector';
import { onboardingQuery } from '../../../store/rtk-query/apis/onboarding/onboarding.toolkit-api';
import { CompleteStepPayload } from '../@types/onboarding-step-complete';
import { useCreateOnboardingLevelOfExperienceSelector } from '../../../store/slices/onboarding-state/selectors/create-onboarding-level-of-experience.selector';
import { useGetCurrentStep } from './onboarding-current-step.hooks';
import { useOnboardingJobRequirementEvent } from './onboarding-actions-bi-evnts.hooks';

export const useOnboardingContinueWatcherButton = (currentStep: string): void => {
  const dispatch = useDispatch();
  const { showContinueButton, showSkipButton } = useOnboardingStoreMetadataSelector();
  useEffect(() => {
    if (
      !showContinueButton &&
      currentStep !== OnboardingStep.LetsBegin &&
      currentStep !== OnboardingStep.Experience
    ) {
      dispatch(setOnboardingContinueButtonShow());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContinueButton, currentStep, showSkipButton]);
};

export const useOnboardingSkipWatcherButton = (): void => {
  const dispatch = useDispatch();
  const { showSkipButton } = useOnboardingStoreMetadataSelector();
  useEffect(() => {
    if (!showSkipButton) {
      dispatch(setOnboardingSkipButtonShow());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSkipButton]);
};

type ResetUserFilledFieldsResponse = {
  reset: (step: string) => void;
}

export const useResetUserFilledFields = (): ResetUserFilledFieldsResponse => {
  const dispatch = useDispatch();
  const reset = (step: string) => {
    switch (step) {
      case OnboardingStep.LetsBegin:
        break;
      case OnboardingStep.Experience:
        dispatch(resetExperienceLevels());
        dispatch(onboardingResetFields());
        break;
      case OnboardingStep.Background:
        dispatch(resetExperienceLevels());
        dispatch(resetCompanyBackground());
        dispatch(resetSkills());
        dispatch(onboardingResetFields());
        break;
      case OnboardingStep.Education:
        dispatch(resetSkills());
        dispatch(resetCompanyBackground());
        dispatch(resetEducationFields());
        break;
      case OnboardingStep.JobDescription:
        dispatch(resetJobDescriptionFields());
        break;
      case OnboardingStep.EmailVerification:
        dispatch(onboardingResetFields());
        break;
      default:
    }
  };

  return {
    reset,
  };
};

type OnboardingContinueButtonResponse = {
  handleOnContinueClick: (skipSate?: boolean) => void,
  handleOnSkipClick: () => void,
  handleBackButtonClicked: () => void,
}

export const useOnboardingActionButtons = (steps: ProgressStep[]): OnboardingContinueButtonResponse => {
  const dispatch = useDispatch();
  const actions = useAuthActions();
  const history = useHistory();
  const currentStep = useGetCurrentStep<OnboardingStep>();
  const { reset: resetUserFilledFields } = useResetUserFilledFields();
  const { companyBackground, skills } = usePositionBackgroundSelector();
  const { degrees, userInputFieldsOfStudy } = useCreatePositionEducationSelector();
  // TODO [refactor]: maybe use another approach instead of listening to all data
  const createLetsBeginData = useCreateOnboardingLetsBeginSelector();
  const createExperienceData = useCreateOnboardingLevelOfExperienceSelector();
  const createBackgroundData = useCreateOnboardingBackgroundSelector();
  const createEducationData = useCreateOnboardingEducationSelector();
  const createJobDescriptionData = useCreateOnboardingJobDescriptionSelector();
  const writeBackClickEvent = useBackClickEvent(currentStep, steps);
  const writeContinueClickEvent = useContinueClickEvent(currentStep, steps);
  const writeSkipClickEvent = useSkipClickEvent(currentStep, steps);
  const writeJobRequirementEvent = useOnboardingJobRequirementEvent();
  const [updateOnboardingProgress, { isLoading: isUpdateOnboardingProgressLoading, data: onboardingData }] = useUpdateOnboardingProgress();

  useEffect(() => {
    dispatch(setOnboardingLoader(isUpdateOnboardingProgressLoading));
  }, [isUpdateOnboardingProgressLoading]);

  useEffect(() => {
    if (onboardingData?.step) {
      history.replace(
        prepareOnboardingStepForNavigating(onboardingData.step),
      );
    }
  }, [onboardingData?.step]);

  const getMetadataByStep = (): {payload: string, type: string} | null => {
    const types = [];
    switch (currentStep) {
      case OnboardingStep.Background:
        return {
          payload: JSON.stringify({
            companyBackground: buildJobRequirementsComponentMetadata(companyBackground, 'title'),
            skills: buildJobRequirementsComponentMetadata(skills, 'title'),
          }),
          type: ELEMENT_TYPE.MANUAL,
        };
      case OnboardingStep.Education:
        if (degrees.length) {
          types.push(ELEMENT_TYPE.SUGGESTIONS);
        }
        if (userInputFieldsOfStudy.length) {
          types.push(ELEMENT_TYPE.AUTOCOMPLETE);
        }

        return {
          payload: JSON.stringify([
            ...degrees,
            ...buildJobRequirementsComponentMetadata(userInputFieldsOfStudy, 'title'),
          ]),
          type: types.join(','),
        };
      default:
        return null;
    }
  };

  const getActionByStep = (): {d: string, data: Partial<CompleteStepPayload>} | null => {
    switch (currentStep) {
      case OnboardingStep.LetsBegin:
        return { d: OnboardingStep.LetsBegin, data: createLetsBeginData };
      case OnboardingStep.Experience:
        return { d: OnboardingStep.Experience, data: createExperienceData };
      case OnboardingStep.Background:
        return { d: OnboardingStep.Background, data: createBackgroundData };
      case OnboardingStep.Education:
        return { d: OnboardingStep.Education, data: createEducationData };
      case OnboardingStep.JobDescription:
        return { d: OnboardingStep.JobDescription, data: createJobDescriptionData };
      default:
        return null;
    }
  };

  const handleOnContinueClick = (skipSate?: boolean) => {
    const action = getActionByStep();
    if (action) {
      if (!skipSate) {
        const metadata = getMetadataByStep();
        if (metadata) {
          writeJobRequirementEvent(metadata.type, metadata.payload);
        }
        writeContinueClickEvent();
      }

      updateOnboardingProgress({ step: action.d, dynamicUpdateParams: action.data });
    }
  };

  const handleOnSkipClick = () => {
    writeSkipClickEvent();
    handleOnContinueClick(true);
  };

  const logoutOnboardingHandler = () => {
    actions.logout();
    dispatch(onboardingQuery.util.resetApiState());
    dispatch(setIsOnboardingLogout());
    dispatch(logout());
    dispatch(resetOnboardingState());
  };

  const handleBackButtonClicked = () => {
    resetUserFilledFields(currentStep);
    dispatch(setOnboardingContinueButtonHide());
    dispatch(setOnboardingContinueButtonDisable());
    dispatch(setOnboardingSkipButtonHide());
    writeBackClickEvent();
    switch (currentStep) {
      case OnboardingStep.LetsBegin:
        logoutOnboardingHandler();
        history.push(
          prepareOnboardingStepForNavigating(OnboardingStep.Registration),
        );
        break;
      case OnboardingStep.Experience:
        history.push(
          prepareOnboardingStepForNavigating(OnboardingStep.LetsBegin),
        );
        break;
      case OnboardingStep.Background:
        history.push(
          prepareOnboardingStepForNavigating(OnboardingStep.Experience),
        );
        break;
      case OnboardingStep.Education:
        history.push(
          prepareOnboardingStepForNavigating(OnboardingStep.Background),
        );
        break;
      case OnboardingStep.JobDescription:
        history.push(
          prepareOnboardingStepForNavigating(OnboardingStep.Education),
        );
        break;
      case OnboardingStep.EmailVerification:
        history.push(
          prepareOnboardingStepForNavigating(OnboardingStep.JobDescription),
        );
        break;
      default:
    }
  };

  return {
    handleOnContinueClick,
    handleOnSkipClick,
    handleBackButtonClicked,
  };
};
