import { Redirect, useLocation } from 'react-router-dom';
import {
  useOnboardingStoreMetadataSelector,
} from '../../../../store/slices/onboarding-state/selectors/onboarding-selectors.hooks';
import {
  OnboardingPageContainer,
  OnboardingPageFooterContainer,
} from '../../containers/onboarding-page-footer-container/onboarding-page-footer-container.styled';
import { Error } from '../../pages/onboarding-lets-begin-page/onboarding-lets-begin.page.styled';
import { useCreatePositionProgressSteps } from '../../../create-position/hooks/create-position-progress-steps.hooks';
import { useBrowserNavigateButtons } from '../../../../hooks/route';
import { ONBOARDING_ROUTES } from '../../consts/onboarding.const';
import {
  useOnboardingActionButtons,
} from '../../hooks/onboarding-actions.hooks';
import { OnboardingStep } from '../../onboarding.enums';
import { WelcomeUserTransitionScreen } from '../../pages/welcome-user-transition-page/welcome-user-transition.page';
import { StepsPageHeader } from '../../../../components/Common/headers/progress-bar-header/progress-bar-header.component';
import { useGetCurrentStep } from '../../hooks/onboarding-current-step.hooks';
import { CreatePositionStepsEnums } from '../../../create-position/enums/create-position-steps.enums';
import ActionsFooterComponent from '../../../create-position/components/actions-footer/actions-footer.component';
import { usePageShowEvent } from '../../../create-position/hooks/create-position-actions-bi-evnts.hooks';
import { LOGIN_STATE } from '../../../../store/slices/app-state/app-state.consts';
import { useAppState } from '../../../../store/selectors';
import { StepScreenAnimatedWrapper } from './step-screen-animated-wrapper/step-screen-animated-wrapper.component';
import { StepScreenWrapper } from './step-screen-wrapper/step-screen-wrapper.component';

const OnboardingStepsLayout = ({
  children,
  disabledAnimation,
  setDisableWelcomeScreen,
}: OnboardingLayoutProps): JSX.Element => {
  const { search: queryParams } = useLocation();
  const currentStep = useGetCurrentStep<CreatePositionStepsEnums>();
  const { steps } = useCreatePositionProgressSteps(currentStep);
  const {
    showContinueButton, onboardingLoadingError, isOnboardingLoading, isContinueButtonEnabled, showSkipButton, showButtonLoader,
  } = useOnboardingStoreMetadataSelector();
  const { loginState } = useAppState();
  const { handleOnContinueClick, handleOnSkipClick, handleBackButtonClicked } = useOnboardingActionButtons(steps);
  usePageShowEvent(currentStep, steps);

  const handleBackButton = () => {
    setDisableWelcomeScreen(true);
    handleBackButtonClicked();
  };

  useBrowserNavigateButtons({
    onBack: (pathname: string) => {
      if (pathname === ONBOARDING_ROUTES.REGISTRATION) {
        handleBackButton();
      }
    },
  });

  const defineContent = () => (
    <OnboardingPageContainer>
      {
        currentStep as string !== OnboardingStep.EmailVerification && (
          <StepsPageHeader
            borderBottomDisable
            isSticky
            steps={ steps }
          />
        )
      }
      { children }
      <OnboardingPageFooterContainer>
        <ActionsFooterComponent
          handleBackButton={ handleBackButton }
          handleOnContinueClick={ handleOnContinueClick }
          handleOnSkipClick={ handleOnSkipClick }
          showButtonLoader={ showButtonLoader }
          showContinueButton={ currentStep as string !== OnboardingStep.EmailVerification && showContinueButton }
          isContinueButtonAnimation={ isContinueButtonEnabled }
          isContinueButtonEnabled={ isContinueButtonEnabled && !isOnboardingLoading }
          showSkipButton={ currentStep as string !== OnboardingStep.EmailVerification && showSkipButton }
          isSkipButtonDisabled={ isContinueButtonEnabled }
        />
        <Error>{ onboardingLoadingError }</Error>
      </OnboardingPageFooterContainer>
    </OnboardingPageContainer>
  );

  const AnimatedWrapper = () => {
    return (
      <StepScreenAnimatedWrapper>
        { defineContent() }
      </StepScreenAnimatedWrapper>
    );
  };

  const StepWrapper = () => {
    return (
      <StepScreenWrapper>
        { defineContent() }
      </StepScreenWrapper>
    );
  };

  if (loginState === LOGIN_STATE.LOGGED_OUT) {
    return (<Redirect to={ `${ONBOARDING_ROUTES.REGISTRATION}${queryParams}` } />);
  }

  if (currentStep as string === OnboardingStep.OnboardingCompleted) {
    return (<>{children}</>);
  }

  return (
    <>
      {!disabledAnimation && <WelcomeUserTransitionScreen />}
      { disabledAnimation ? StepWrapper() : AnimatedWrapper()}
    </>
  );
};

export default OnboardingStepsLayout;
