import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../../../../hooks/debounce';
import { Autocomplete } from '../../../../../components/Common/create-position-autocomplete/autocomplete.component';
import STRING_KEYS from '../../../../../language/keys';
import {
  COMPONENT_NAME, ELEMENT_TYPE, EVENT_GROUP,
} from '../../../../../consts/analytics';
import { PAGE_NAMES } from '../../../../../consts/pages';
import { PressEnterMessage } from '../../press-enter-message/press-enter-message.component';
import { useGetJobTitles } from '../../../hooks/create-position-react-query.hooks';
import { CREATE_POSITION_ANALYTICS_EVENTS } from '../../../consts/position-analytics.consts';
import { useAnalyticsContext } from '../../../../../contexts/analytics/useAnalyticsContext';
import { setPositionJobTitle } from '../../../../../store/slices/create-position-state/create-position-state.toolkit-slice';
import { InputStyle } from '../../../../onboarding/components/input/input.component';
import { CREATE_POSITION_INPUT_ID } from '../../../consts/input-ids.consts';
import { JobTitleItem } from './job-title-item/job-title-item.component';

type PositionAutocompleteProps = {
  onSentenceCompleted: () => void,
  onPositionChange: () => void,
  stepNumber: number,
}

export const PositionAutocomplete = ({
  onSentenceCompleted,
  onPositionChange,
  stepNumber,
}: PositionAutocompleteProps):JSX.Element => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isInputFocused, setInputFocused] = useState<boolean>(false);
  const debouncedValue = useDebounce(inputValue.toLowerCase(), 500);
  const {
    data: jobtitles = [],
    isLoading,
  } = useGetJobTitles(debouncedValue);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { analyticsTrackEvent } = useAnalyticsContext();

  const handleEnterClick = (selectedJobTitle:PositionTitle) => {
    setInputValue(selectedJobTitle.jobTitle);
  };

  const itemClickedHandler = (value: string, index?:number) => {
    const isEnterClicked = index !== undefined && index >= 0;
    if (isEnterClicked) {
      handleEnterClick(jobtitles[index]);
    } else {
      setInputValue(value);
    }
    onPositionChange();
  };

  useEffect(() => {
    if (!isInputFocused && inputValue.trim() !== '') {
      dispatch(setPositionJobTitle(inputValue));
      onSentenceCompleted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInputFocused]);

  const handleComplete = (selectedJobTitle: PositionTitle) => {
    const trackEventPayload = {
      eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_INSERT_PARAMETER_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.BASIC_INFO,
      componentName: COMPONENT_NAME.JOB_TITLE,
      componentElementType: ELEMENT_TYPE.AUTOCOMPLETE,
      componentMetadata: selectedJobTitle.jobTitle,
      stepNumber,
    };
    onSentenceCompleted();
    setInputFocused(false);
    analyticsTrackEvent(trackEventPayload);
    dispatch(setPositionJobTitle(selectedJobTitle?.jobTitle || ''));
    setInputValue(selectedJobTitle?.jobTitle || '');
  };

  return (
    <>
      <Autocomplete
        inputElementId={ CREATE_POSITION_INPUT_ID.JOB_TITLE }
        InstructionsMessage={ <PressEnterMessage translationKey={ STRING_KEYS.SELECT_OR_PRESS_ENTER } /> }
        optionsTitle=""
        isLoading={ isLoading }
        placeholder={ translate(STRING_KEYS.ONBOARDING.JOB_TITLE_PLACEHOLDER) }
        inputValue={ inputValue }
        onValueChange={ itemClickedHandler }
        isInputFocused={ isInputFocused }
        setInputFocused={ setInputFocused }
        inputStyle={ InputStyle.Onboarding }
      >
        {[...jobtitles.map((jobItem: PositionTitle) => {
          return (
            <JobTitleItem
              isSelected={ inputValue === jobItem.jobTitle }
              onSelect={ (job) => handleComplete(job) }
              key={ jobItem.id }
              jobTitle={ jobItem }
            />
          );
        }),
        ]}
      </Autocomplete>
    </>
  );
};
