import { useRef } from 'react';
import { useResizeObserver } from '../../../hooks/resize-observer.hook';
import { Container, Wrapper } from './dynamic-height-container.styled';

const DynamicHeightContainer = ({ transitionDurationMs, children }: DynamicHeightContainerProps): JSX.Element => {
  const content = useRef(null);
  const rect = useResizeObserver(content);

  return (
    <Container height={ rect.height } transitionDurationMs={ transitionDurationMs }>
      <Wrapper ref={ content }>
        { children }
      </Wrapper>
    </Container>
  );
};

export default DynamicHeightContainer;
