export enum PageName {
  Insights = 'INSIGHTS',
  MatchingProgress = 'MATCHING_PROGRESS',
  ScheduleMeeting = 'SCHEDULE_MEETING',
  PositionIntroduction = 'POSITION_INTRODUCTION',
  NoMatchesFound = 'NO_MATCHES_FOUND',
  Match = 'MATCH',
  MatchTune = 'MATCH_TUNE',
  Overview = 'OVERVIEW',
  ConversationsEmpty = 'CONVERSATIONS_EMPTY',
  Conversation = 'CONVERSATION',
  OutreachContentPreferences = 'OUTREACH_CONTENT_PREFERENCES',
  OutreachCampaignPreferences = 'OUTREACH_CAMPAIGN_PREFERENCES',
  HomePage = 'HOME_PAGE',
}
