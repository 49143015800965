import { Trans, useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import {
  Description, StyledDivider, Subtitle, TextContainer, Title,
} from '../ats-initialization.styled';
import { Input, InputSize, InputStyle } from '../../../../modules/onboarding/components/input/input.component';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
  PerfectButtonVariants,
} from '../../../Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../language/keys';
import { IntercomManager } from '../../../../services/intercom-manager';
import {
  ButtonsContainer,
  Container,
  BackButton,
  BackButtonIcon,
  ConfirmButton,
  InputWrapper,
  ContactCsLink,
} from './enter-api-key.styled';
import { EnterApiKeyProps } from './enter-api-key';

const EnterApiKey = ({
  integrationType,
  isCreateCustomAtsIntegrationLoading,
  continueButtonClickHandler,
  backButtonClickHandler,
}: EnterApiKeyProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [apiKey, setApiKey] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const translatedIntegrationName = useMemo(() => translate(STRING_KEYS.ATS_INITIALIZATION_DIALOG.CUSTOM_INTEGRATION_NAMES[integrationType]), [integrationType]);

  const onInputEnter = () => {
    if (apiKey) {
      continueButtonClickHandler(apiKey);
    }
  };

  const onContactCsButtonClick = () => {
    IntercomManager.showChat();
  };

  return (
    <Container>
      <TextContainer>
        <Title>{ translate(STRING_KEYS.ATS_INITIALIZATION_DIALOG.ENTER_API_KEY_SCREEN.TITLE, { atsName: translatedIntegrationName }) }</Title>
        <Description>
          <Trans i18nKey={ STRING_KEYS.ATS_INITIALIZATION_DIALOG.ENTER_API_KEY_SCREEN.DESCRIPTION } values={ { atsName: translatedIntegrationName } }>
            <ContactCsLink onClick={ onContactCsButtonClick } />
          </Trans>
        </Description>
        <StyledDivider />
        <Subtitle>{ translate(STRING_KEYS.ATS_INITIALIZATION_DIALOG.ENTER_API_KEY_SCREEN.INPUT_TITLE, { atsName: translatedIntegrationName }) }</Subtitle>
      </TextContainer>
      <InputWrapper>
        <Input
          placeholder={ translate(STRING_KEYS.ATS_INITIALIZATION_DIALOG.ENTER_API_KEY_SCREEN.INPUT_PLACEHOLDER) }
          value={ apiKey }
          onValueChange={ setApiKey }
          autoFocus
          style={ InputStyle.Regular }
          inputSize={ InputSize.Small }
          onEnter={ onInputEnter }
        />
      </InputWrapper>
      <ButtonsContainer>
        <BackButton
          StartIcon={ BackButtonIcon }
          variant={ PerfectButtonVariants.Outline }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          size={ PerfectButtonSize.Large }
          onClick={ backButtonClickHandler }
          disabled={ isCreateCustomAtsIntegrationLoading }
        />
        <ConfirmButton
          onClick={ () => continueButtonClickHandler(apiKey) }
          size={ PerfectButtonSize.Large }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          disabled={ !apiKey || isCreateCustomAtsIntegrationLoading }
          isLoading={ isCreateCustomAtsIntegrationLoading }
        >
          { translate(STRING_KEYS.CONTINUE) }
        </ConfirmButton>
      </ButtonsContainer>
    </Container>
  );
};

export default EnterApiKey;
