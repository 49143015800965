import styled from 'styled-components';
import { ASSETS } from '../../../assets';
import { THEME } from '../../../themes';
import { Body2Font, Subtitle1Font } from '../../../themes/fonts';

export type BannerType = 'info' | 'success' | 'warning' | 'alert';

const getBackgroundColor = (type: BannerType) => {
  switch (type) {
    case 'alert': {
      return THEME.COLORS.ALERT;
    }
    case 'warning': {
      return THEME.COLORS.WARNING;
    }
    case 'success': {
      return THEME.COLORS.SUCCESS;
    }
    default:
    case 'info': {
      return THEME.COLORS.PRIMARY;
    }
  }
};

const getTextColor = (type: BannerType) => {
  switch (type) {
    case 'warning':
    case 'success': {
      return THEME.COLORS.CONTENT_PRIMARY;
    }
    default:
    case 'info':
    case 'alert': {
      return THEME.COLORS.CONTENT_INVERSE;
    }
  }
};

const getIcon = (type: BannerType) => {
  switch (type) {
    case 'alert': {
      return ASSETS.ERROR_CIRCLE;
    }
    case 'warning': {
      return ASSETS.WARNING;
    }
    case 'success': {
      return ASSETS.CHECKED_CIRCLE;
    }
    default:
    case 'info': {
      // todo: change this asset (doesn't exist in zeplin)
      return ASSETS.ERROR_CIRCLE;
    }
  }
};

export const Container = styled.div<{
  type: BannerType,
}>`
  ${Body2Font};
  display: inline-block;
  background-color: ${(props) => getBackgroundColor(props.type)};
  padding-top: ${THEME.SPACING.TINO};
  padding-bottom: ${THEME.SPACING.TINO};
  padding-right: ${THEME.SPACING.MEDIUM};
  padding-left: ${THEME.SPACING.MEDIUM};
  color: ${(props) => getTextColor(props.type)};
  margin-top: ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.BASE};
  transition: all 0.2s;

  &:before {
    content: '';
    width: 28px;
    height: 28px;
    display: block;
    background: url(${(props) => getIcon(props.type)}) no-repeat left center;
    background-size: contain;
    float: left;
    margin-top: -4px;
    margin-right: ${THEME.SPACING.TINY};
  }
`;

export const ErrorCaption = styled.span`
  ${Subtitle1Font};
  margin-right: ${THEME.SPACING.TINY}
`;
