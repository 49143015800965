import { Trans, useTranslation } from 'react-i18next';
import HeaderBanner from '../header-banner/header-banner.component';
import { THEME } from '../../../../../themes';
import STRING_KEYS from '../../../../../language/keys';
import { DaysToEnd } from '../trial-ended-header-banner/trial-ended-header-banner.styled';
import { TrialWillEndIcon } from './trial-will-end-header-banner.styled';

const TrialWillEndHeaderBanner = ({ actionButtonClickHandler, daysLeft }: TrialWillEndHeaderBannerProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <HeaderBanner
      label={ translate(STRING_KEYS.HOMEPAGE.HEADER.TRIAL_BANNER.LABEL) }
      title={ (
        <Trans
          defaults={ STRING_KEYS.HOMEPAGE.HEADER.TRIAL_BANNER.WILL_END.TITLE }
          components={ { days: <DaysToEnd /> } }
          values={ { count: daysLeft } }
        />
      ) }
      description={ translate(STRING_KEYS.HOMEPAGE.HEADER.TRIAL_BANNER.WILL_END.DESCRIPTION) }
      gradientBackgroundColor={ THEME.COLORS.SECONDARY }
      Icon={ <TrialWillEndIcon /> }
      actionButtonClickHandler={ actionButtonClickHandler }
      actionButtonText={ translate(STRING_KEYS.HOMEPAGE.HEADER.TRIAL_BANNER.WILL_END.ACTION_BUTTON_TEXT) }
    />
  );
};

export default TrialWillEndHeaderBanner;
