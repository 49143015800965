import { useSelector } from 'react-redux';
import { RootReducerState } from '../../../index';
import { CompleteStepPayload } from '../../../../modules/onboarding/@types/onboarding-step-complete';

export const useCreateOnboardingJobDescriptionSelector = (): Partial<CompleteStepPayload> => {
  return useSelector<RootReducerState, Partial<CompleteStepPayload>>(({ createPosition }: RootReducerState) => {
    const temUrls =
      [createPosition.jobDescriptionTempUrls, createPosition.jobDescriptionS3TempUrls]
        .filter((jobItem) => jobItem !== '');

    return {
      jobDescriptionTempUrls: temUrls,
    };
  });
};
