import styled from 'styled-components';
import FullScreenDialog from '../../../../components/dialogs/full-screen-dialog/full-screen-dialog.component';
import { THEME } from '../../../../themes';
import { Title2Font } from '../../../../themes/fonts';

export const FullScreenHeader = styled(FullScreenDialog.Header)`
  width: 100%; 
  display: flex; 
  justify-content: flex-end;
`;

export const FullScreenBody = styled(FullScreenDialog.Body)`
  ${Title2Font};
  color: ${THEME.COLORS.PRIMARY_DARK};
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  min-width: 960px;
  min-height: 63vh;
  box-sizing: content-box;
  margin: 0 auto;
`;

export const FullScreenFooter = styled(FullScreenDialog.Footer)`
  position: relative;
  z-index: 10;
  display: flex;
  gap: ${THEME.SPACING.BASE};
  width: 80vw;
  align-items: center;
  left: 117px;
  bottom: ${THEME.SPACING.XLARGE};
  height: fit-content;
  
  @media (max-height: ${THEME.BREAKPOINTS.HEIGHT.SMALL}px) and (min-height: ${THEME.BREAKPOINTS.HEIGHT.XSMALL}px) {
    bottom: ${THEME.SPACING.MEDIUM};
  }

  @media (max-height: ${THEME.BREAKPOINTS.HEIGHT.XSMALL}px) {
    bottom: ${THEME.SPACING.TINY};
  }
`;
