import { outreachQuery } from '../outreach.toolkit-api';
import { MessageResponseDto } from '../dto/response/message.response';
import { useAppSelector } from '../../../../selectors';
import { MessageTypeEnum } from '../enums/message-type.enum';
import { useMyUserSelector } from '../../user/selectors/get-my-user.selector';

type UseReSendMessageMutationType = [
  () => void,
  AppSelectorType<MessageResponseDto | undefined>
];

export const conversationSelector = outreachQuery.endpoints.getConversationById.select;

export const useReSendMessageMutation = (conversationId: string, messageId: string): UseReSendMessageMutationType => {
  const {
    data: conversation,
  } = useAppSelector(conversationSelector({ conversationId }));
  const { data: user } = useMyUserSelector();
  const message = conversation?.messageHistory.find(({ id }) => id === messageId);

  const [
    resSendMessage,
    {
      data, isLoading, isError, error, isSuccess,
    }] = outreachQuery.useReSendMessageMutation();

  return [
    () => {
      const replyToMessage = conversation?.messageHistory
        ?.slice()
        ?.reverse()
        ?.find(({ isError: messageWasSentWithError }: MessageResponseDto) => !messageWasSentWithError);

      if (conversation && message) {
        const requestArguments = {
          messageId,
          conversationId,
          positionId: conversation.positionId,
          matchId: conversation.matchId,
          senderUserId: user.id,
          recipient: message.recipient,
          subject: message.payload.subject || undefined,
          html: message.payload.sanitizedBodyHtml,
          text: message.payload.sanitizedBodyText,
          inReplyToPrimaryId: replyToMessage?.id,
          type: MessageTypeEnum.Email,
          userInfo: {
            id: user.id,
            fullName: `${user.firstName} ${user.lastName}`,
            profileImage: user.profileImage,
          },
        };

        resSendMessage(requestArguments);
      }
    },
    {
      isLoading,
      isError,
      error,
      isSuccess,
      data,
    },
  ];
};
