import styled  from 'styled-components';
import { THEME } from '../../../../../themes';
import { hexToRgbA } from '../../../../../utils/colors';
import { ASSETS } from '../../../../../assets';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { CaptionFont, Subtitle2Font } from '../../../../../themes/fonts';

export const Container = styled.div`
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.BASE} ${THEME.SPACING.BASE} ${THEME.SPACING.MEDIUM};
  border-radius: ${THEME.RADIUS.LOOPY};
  background-color: ${hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER)};
  display: flex;
  justify-content: space-between;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
`;

export const ErrorIcon = styled(ASSETS.ALERT)`
  width: 32px;
  height: 32px;
  fill: ${THEME.COLORS.ALERT};
`;

export const ActionButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.PURE_WHITE};
  color: ${THEME.COLORS.ALERT};
  font-weight: 600;
`;

export const Title = styled.div`
  ${Subtitle2Font};
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: ${THEME.COLORS.TRUE_BLACK};
`;

export const Description = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: ${THEME.COLORS.CONTENT_A};
`;
