/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../../../../../hooks/debounce';
import STRING_KEYS from '../../../../../../language/keys';
import { useGetJobTitles } from '../../../../hooks/create-position-react-query.hooks';
import { JobTitleItem } from '../job-title-item/job-title-item.component';
import { InputStyle } from '../../../../../onboarding/components/input/input.component';
import { CustomAutocomplete, InputIcon } from './edit-position-autocomplete.styled';

type PositionAutocompleteProps = {
  jobTitle: string,
  completeHandler: (value: string) => void,
  changeHandler: (value: string) => void,
}

export const EditPositionAutocomplete = ({
  jobTitle,
  completeHandler,
  changeHandler,
  ...otherProps
}: PositionAutocompleteProps):JSX.Element => {
  const [inputValue, setInputValue] = useState<string>(jobTitle);
  const [isInputFocused, setInputFocused] = useState<boolean>(false);
  const debouncedValue = useDebounce(inputValue?.toLowerCase(), 500);
  const { data: jobtitles = [], isLoading } = useGetJobTitles(debouncedValue);
  const { t: translate } = useTranslation();

  useEffect(() => {
    setInputValue(jobTitle);
  }, [jobTitle]);

  const handleEnterClick = (selectedJobTitle: PositionTitle) => {
    setInputValue(selectedJobTitle.jobTitle);
    completeHandler(selectedJobTitle.jobTitle);
  };

  const itemClickedHandler = (value: string, index?:number) => {
    const isEnterClicked = index !== undefined && index >= 0;
    if (isEnterClicked) {
      handleEnterClick(jobtitles[index]);

      return;
    }

    setInputValue(value);
    changeHandler(value);
  };

  const handleComplete = (selectedJobTitle: PositionTitle) => {
    setInputFocused(false);
    completeHandler(selectedJobTitle?.jobTitle);
    setInputValue(selectedJobTitle?.jobTitle || '');
  };

  return (
    <CustomAutocomplete
      optionsTitle=""
      autoFocus={ false }
      isLoading={ isLoading }
      placeholder={ translate(STRING_KEYS.ONBOARDING.JOB_TITLE_PLACEHOLDER) }
      inputValue={ inputValue }
      onValueChange={ itemClickedHandler }
      isInputFocused={ isInputFocused }
      setInputFocused={ setInputFocused }
      inputStyle={ InputStyle.Onboarding }
      StartAdornment={ <InputIcon /> }
      { ...otherProps }
    >
      {[...jobtitles.map((jobItem: PositionTitle) => {
        return (
          <JobTitleItem
            isSelected={ inputValue === jobItem.jobTitle }
            onSelect={ (job) => handleComplete(job) }
            key={ jobItem.id }
            jobTitle={ jobItem }
          />
        );
      }),
      ]}
    </CustomAutocomplete>
  );
};
