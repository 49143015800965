import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { CreatePositionState, initialCreatePositionState } from '../create-position-state.initial-state';

export const resetErrorReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  state.error = '';
  state.errorCode = 0;
};

export const setContinueButtonEnableReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  state.isContinueButtonEnabled = true;
};

export const setContinueButtonDisableReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  state.isContinueButtonEnabled = false;
};

export const setContinueButtonHideReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  state.showContinueButtonShow = false;
};

export const setContinueButtonShowReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  state.showContinueButtonShow = true;
};

export const setSkipButtonShowReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  state.showSkipButtonShow = true;
};

export const setSkipButtonHideReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  state.showSkipButtonShow = false;
};

export const resetStateReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = () => {
  return initialCreatePositionState;
};
