import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../../../language/keys';
import BadgeWidget from '../badge-widget.component';
import { Icon } from './multilingual-badge.styled';
import { MultilingualBadgeProps } from './multilingual-badge';

const BILINGUAL_SIZE = 2;
const TRILINGUAL_SIZE = 3;

export const MultilingualBadge = ({ languages }: MultilingualBadgeProps):
  JSX.Element | null => {
  const { t: translate } = useTranslation();
  const languagesNames = languages.map((language) => language.name);

  const getBadgeData = (): Partial<BadgeWidgetProps> | null => {
    if (languages.length === BILINGUAL_SIZE) {
      return {
        title: translate(STRING_KEYS.BILINGUAL),
        description: translate(STRING_KEYS.BILINGUAL_DESCRIPTION, {
          languages: languagesNames.slice(0, BILINGUAL_SIZE - 1).join(', '),
          lastLanguage: languagesNames[BILINGUAL_SIZE - 1],
        }),
      };
    }
    if (languages.length === TRILINGUAL_SIZE) {
      return {
        title: translate(STRING_KEYS.TRILINGUAL),
        description: translate(STRING_KEYS.TRILINGUAL_DESCRIPTION, {
          languages: languagesNames.slice(0, TRILINGUAL_SIZE - 1).join(', '),
          lastLanguage: languagesNames[TRILINGUAL_SIZE - 1],
        }),
      };
    }
    if (languages.length > TRILINGUAL_SIZE) {
      return {
        title: translate(STRING_KEYS.MULTILINGUAL),
        description: translate(STRING_KEYS.MULTILINGUAL_DESCRIPTION, {
          languages: languagesNames.join(', '),
        }),
      };
    }

    return null;
  };

  const { title, description } = getBadgeData() || {};

  if (!title || !description) {
    return null;
  }

  return (
    <BadgeWidget
      title={ title }
      description={ description }
      Icon={ <Icon /> }
    />
  );
};
