import styled, { css } from 'styled-components';
import { THEME } from '../../../../themes';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { CaptionFont } from '../../../../themes/fonts';
import { hexToRgbA } from '../../../../utils/colors';
import { ASSETS } from '../../../../assets';

const getButtonStyle = (opacity: number) => {
  return css`
    ${CaptionFont};
    font-weight: bold;
    color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, opacity)};
  `;
};

export const Container = styled.div<{ showEmptyState: boolean }>`
  border-radius: ${THEME.RADIUS.CURVY};
  background-color: ${THEME.COLORS.PURE_WHITE};
  padding: ${THEME.SPACING.MEDIUM};
  padding-bottom: ${THEME.SPACING.TINO};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  height: ${({ showEmptyState }) => (showEmptyState ? undefined : '448px')};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DismissButton = styled(BaseButton)<{ isVisible: boolean}>`
  ${getButtonStyle(THEME.OPACITY.LOW)};
  visibility: ${({ isVisible }) => (isVisible ? undefined : 'hidden')};
  width: 100px;
`;

export const NextButton = styled(BaseButton)<{ isVisible: boolean}>`
  ${getButtonStyle(THEME.OPACITY.MED)};
  visibility: ${({ isVisible }) => (isVisible ? undefined : 'hidden')};
  width: 100px;
`;

export const NextIcon = styled(ASSETS.FILL_ARROW_BACK_COMPONENT)`
  height: 12px;
  width: 12px;
`;

export const DismissIcon = styled(ASSETS.CLOSE_CHIP)`
  height: 12px;
  width: 12px;
`;
