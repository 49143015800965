import styled from 'styled-components';
import { THEME } from '../../../themes';
import { Title3Font } from '../../../themes/fonts';

export const pageContentWidth = '1240px';

export const WhyContainer = styled.div`
  margin-top: ${THEME.SPACING.LOOSE};
`;

// todo: remove

// export const LoaderContainer = styled.div`
//   display: flex;
//   flex: 1;
//   justify-content: center;
//   align-items: center;
// `;

// todo: remove all mock and change elements

export const MockFullWidthImage = styled.img`
  display: block;
  width: 100%;
`;

export const MockImage = styled.img`
  display: inline-block;
  max-width: 81%;
  height: auto;
`;

export const MatchDescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 auto;
`;

// todo: change top/left - responsive
export const MatchExplanationSection = styled.div`
  flex: 1;
  padding-top: ${THEME.SPACING.XLOOSE};
  position: relative;

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.SMALL}px) {
    padding-top: ${THEME.SPACING.MEDIUM};
  }
`;

// todo: change width to match page layout
export const MatchExplanationSectionContent = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
  margin: 0 auto;
  align-items: flex-start;
`;

export const ExperienceSection = styled.div`
  display: block;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const PropertiesSection = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0;
  margin-bottom: 32px;
  position: relative;
`;

export const WebSection = styled.div`
  display: block;
  width: 100%;
  background-color: ${THEME.COLORS.LIGHT_GREY};
  text-align: center;
  padding: 40px 0;
  margin-bottom: 42px;
  position: relative;
`;

export const DividerContainer = styled.div`
  margin-top: ${THEME.SPACING.LOOSE};
  margin-bottom: ${THEME.SPACING.LOOSE};
`;

export const Title = styled.div`
  opacity: 0.6;
  ${Title3Font};
  font-weight: 500;
  text-align: left;
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  width: 80%;
  margin: 0 auto;
`;
