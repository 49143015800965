import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import log from 'loglevel';
import { markAcknowledgementAsSeen } from '../api/acknowledgements';

const logger = log.getLogger('ACKNOWLEDGMENTS_REACT_QUERIES');

export const useSeeAcknowledgements = (): UseMutationResult<void, AxiosError, string> => {
  return useMutation(
    (id: string) => {
      return markAcknowledgementAsSeen(id);
    }, {
      onError: (error: AxiosError, id) => {
        logger.error(
          'Failed to mark acknowledgement has seen',
          error,
          { acknowledgementId: id },
        );
      },
    },
  );
};
