import { positionQuery } from '../position.toolkit-api';
import { ApiError } from '../../../../../consts/api';
import { ClosePositionQueryArguments } from '../dto/query-arguments/close-position.query-arguments';
import { FIXED_CACHE_KEYS } from '../position.consts';

type UseClosePositionMutationType = [
  ({ positionId, closureReason, freeText }: ClosePositionQueryArguments) => void,
  AppSelectorType<void, ApiError>
];

export const useClosePositionMutation = (): UseClosePositionMutationType => {
  const [
    closePosition,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = positionQuery.useClosePositionMutation({
    fixedCacheKey: FIXED_CACHE_KEYS.CLOSE_POSITION,
  });

  const meta = {
    isLoading, isError, error: error as ApiError, isSuccess, data, resetState: reset,
  };

  return [
    closePosition,
    { ...meta },
  ];
};
