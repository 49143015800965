import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { IndeterminateCircularLoader } from '../../../Common/Loaders/IndeterminateCircularLoader';
import { UserResponse } from '../../../../store/rtk-query/apis/user/dto/response/invited-users.response';
import {
  Container,
  MyTeammates,
  ColleaguesListWrapper,
  WrapperLoader,
} from './styled';
import InvitedColleaguesListItem from './InvitedColleagueListItem';

type InvitedColleaguesListProps = {
  inviteColleagueUsers: UserResponse[],
  isLoadingColleagueUsers: boolean,
  isLoadingSendInviteColleague: boolean,
  isLoadingResendInviteColleague: boolean,
  onResetError: () => void,
}

const InvitedColleaguesList = ({
  inviteColleagueUsers,
  isLoadingColleagueUsers,
  isLoadingSendInviteColleague,
  isLoadingResendInviteColleague,
  onResetError,
}: InvitedColleaguesListProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const renderColleagueUsers = () => {
    if (isLoadingColleagueUsers || isLoadingSendInviteColleague ||
      isLoadingResendInviteColleague
    ) {
      return (
        <WrapperLoader>
          <IndeterminateCircularLoader />
        </WrapperLoader>
      );
    }

    return inviteColleagueUsers?.map(({
      fullName, profilePictureUrl, verified, email, id,
    }) => (
      <InvitedColleaguesListItem
        key={ fullName }
        fullName={ fullName || '' }
        email={ email }
        profileImage={ profilePictureUrl || '' }
        invitePending={ !verified }
        id={ id }
        onResetError={ onResetError }
      />
    ));
  };

  return (
    <Container>
      <MyTeammates>
        {
          translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.MY_TEAMMATES_TITLE,
            { counter: inviteColleagueUsers?.length })
        }
      </MyTeammates>
      <ColleaguesListWrapper showOnHover >
        {renderColleagueUsers()}
      </ColleaguesListWrapper>
    </Container>
  );
};

export default InvitedColleaguesList;
