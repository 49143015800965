import styled, { css } from 'styled-components';
import {
  CaptionFont, HeadlineFont, Subtitle2Font,
} from '../../../themes/fonts';
import { THEME } from '../../../themes';
import MenuItem from '../perfect-base-menu/menu-item/menu-item.component';
import { hexToRgbA } from '../../../utils/colors';
import { ASSETS } from '../../../assets';
import { TextEllipsis } from '../../../themes/text';
import BaseMenu from '../perfect-base-menu/perfect-base-menu.component';
import { UserMenuItemStyle } from '../user-menu-item/user-menu-item.styled';

export const AVATAR_SIZE_PX = 24;

export const Container = styled.div`
  min-width: 108px;
  max-width: 230px;
  height: fit-content;
  cursor: pointer;
`;

export const Label = styled.div`
  ${CaptionFont};
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_A};
  margin-bottom: ${THEME.SPACING.MICRO};
`;

const BaseContainerStyle = css<{ isActive: boolean }>`
  ${Subtitle2Font};
  display: flex;
  align-items: center;
  color: ${({ isActive }) => isActive ? THEME.COLORS.ACCENT : THEME.COLORS.CONTENT_A};
`;

export const EmptyStateContainer = styled.div`
  ${BaseContainerStyle};
  gap: ${THEME.SPACING.MICRO};
`;

export const AssignIcon = styled(ASSETS.INVITE_GHOST)``;

export const SingularUserContainer = styled.div`
  ${BaseContainerStyle};
  gap: ${THEME.SPACING.TINY};
`;

export const SingularUserName = styled.div`
  max-width: 76px;
  ${TextEllipsis};
  line-height: 1.2;
`;

export const SingularAvatarContainer = styled.div`
  width: ${AVATAR_SIZE_PX}px;
  height: ${AVATAR_SIZE_PX}px;
`;

export const UsersMenu = styled(BaseMenu)`
  max-height: 500px;
  overflow-y: auto;
`;

export const MenuTitle = styled.div`
  ${HeadlineFont};
  color: ${THEME.COLORS.CONTENT_A};
  font-weight: 600;
  height: 48px;
  display: flex;
  align-items: center;
`;

export const StyledMenuItem = styled(MenuItem)`
  ${UserMenuItemStyle};
`;

export const InviteTeammateMenuItem = styled(MenuItem)`
  border-top: 1px solid ${hexToRgbA(THEME.COLORS.CONTENT_PRIMARY, THEME.OPACITY.GHOSTLY)};
  ${UserMenuItemStyle};
  display: flex;
  gap: ${THEME.SPACING.TINY};
`;

export const InviteTeammateMenuItemIcon = styled(ASSETS.PLUS_CIRCLE_OUTLINE)`
  margin-left: ${THEME.SPACING.TINY};
`;

export const AddUserIconWrapper = styled.div`
  background-color: ${THEME.COLORS.PRIMARY_DARK};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${AVATAR_SIZE_PX}px;
  height: ${AVATAR_SIZE_PX}px;
  border-radius: ${THEME.RADIUS.CIRCLE};
`;

export const AddUserIcon = styled(ASSETS.ADD_USER)`
  width: 12px;
  fill: ${THEME.COLORS.PURE_WHITE};
`;

export const MultipleUsersContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.MICRO};
`;
