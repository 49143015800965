import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthActions } from '@frontegg/react';
import { ErrorMessage } from '../../Common/ErrorMessage';
import PrimaryButton from '../../Common/Buttons/PrimaryButton';
import STRING_KEYS from '../../../language/keys';
import {
  PasswordIcon,
  PasswordInput,
  PasswordInputWrapper,
  ValidationError,
} from '../../../modules/onboarding/pages/onboarding-registration-page/onboarding-registration.page.styled';
import { PRIVACY_CLASS } from '../../../consts/fullstory';
import { passwordValidator } from '../../../utils/frontegg';
import { PasswordRules } from '../../Common/PasswordRules';
import { AppRouting } from '../../../services/appRoutingResolver';
import {
  FormContainer,
  StyledForm,
  ErrorContainer,
  SubmitButtonWrapper,
} from './styled';

type ResetPasswordFormProps = {
  isDisabled: boolean,
  errorMessage: string,
};

function ResetPasswordForm({ isDisabled, errorMessage }: ResetPasswordFormProps):JSX.Element {
  const { t: translate } = useTranslation();

  const [showPasswordRules, setPasswordRulesVisibility] = useState<boolean>(false);
  const [localPasswordError, setLocalPasswordError] = useState<boolean>(false);
  const [passwordInput, setPasswordInput] = useState<string>('');

  const actions = useAuthActions();

  const params = new URLSearchParams(window.location.search);
  const userId = params.get('userId') || '';
  const token = params.get('token') || '';

  if (!userId || !token) {
    AppRouting.navigateToUrl(AppRouting.buildGlobalLoginUrl(window.location));
  }

  const resetErrors = () => {
    setLocalPasswordError(false);
  };

  const submitForm = () => {
    resetErrors();

    const hasErrors = false;

    if (!hasErrors) {
      actions.resetPassword({
        userId,
        token,
        password: passwordInput,
      });
    }
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submitForm();
  };

  const handlePasswordInputChange = (e: SyntheticEvent) => {
    const element = e.target as HTMLInputElement;

    actions.resetSignUpState();

    if (passwordValidator(element.value)) {
      setLocalPasswordError(false);
    }

    setPasswordInput(element.value);
  };

  const definePasswordErrors = () => {
    if (localPasswordError) {
      return (
        <ValidationError>
          { translate(STRING_KEYS.PASSWORD_LENGTH_VALIDATION_ERROR) }
        </ValidationError>
      );
    }

    return null;
  };

  return (
    <FormContainer>
      <StyledForm isDisabled={ isDisabled } onSubmit={ handleFormSubmit }>
        <PasswordInputWrapper>
          <PasswordIcon />
          <PasswordInput
            className={ PRIVACY_CLASS.EXCLUDE }
            type="password"
            onChange={ handlePasswordInputChange }
            onFocus={ () => {
              setPasswordRulesVisibility(true);
            } }
            onBlur={ () => {
              setPasswordRulesVisibility(false);
            } }
            placeholder={
              translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_CHOOSE_YOUR_PASSWORD)
            }
            isError={ localPasswordError }
          />
        </PasswordInputWrapper>
        { definePasswordErrors() }

        <PasswordRules
          isShowPasswordRules={ showPasswordRules }
          passwordInput={ passwordInput }
        />
        <SubmitButtonWrapper>
          <PrimaryButton
            title={ translate(STRING_KEYS.CONFIRM) }
            type="submit"
            disabled={ localPasswordError }
            disableMargin
          />
        </SubmitButtonWrapper>
        {errorMessage && (
          <ErrorContainer>
            <ErrorMessage message={ errorMessage } />
          </ErrorContainer>
        )}
      </StyledForm>
    </FormContainer>
  );
}

export default ResetPasswordForm;
