import GuidanceModal from '../guidance-modal.component';
import { DialogWidthSize } from '../../base-dialog/base-dialog.enums';
import { Container, Quote } from './quotes-guidance-modal.styled';

const QuotesGuidanceModal = ({
  isOpen,
  closeHandler,
  title,
  data,
}: QuotesGuidanceModalProps): JSX.Element => {
  return (
    <GuidanceModal
      isOpen={ isOpen }
      closeHandler={ closeHandler }
      widthSize={ DialogWidthSize.Medium }
      title={ title }
    >
      <Container>
        { data.map((quote) => (
          <Quote key={ quote }>
            “{ quote }”
          </Quote>
        )) }
      </Container>
    </GuidanceModal>
  );
};

export default QuotesGuidanceModal;
