class NotificationService {
  public playNotificationSound = () => {
    const audio = new Audio('/assets/new-notification.mp3');

    audio.play().catch(() => {
      // I don't want to handle this, just want the app not to throw an error and crash the app
    });
  }
}

export default new NotificationService();
