import { LogLevelDesc, RootLogger } from 'loglevel';
import { LOG_LEVELS } from '../consts/environment';
import { getAppMode } from '../utils/environment';
import { AppModeEnum } from '../enums/app-mode.enum';
import fullStoryLoggingMiddleware from './middlewares/full-story-logging.middleware';
import consoleLoggingMiddleware from './middlewares/console-logging.middleware';
import remoteLoggingMiddleware from './middlewares/remote-logging.middleware';
import messageFormatterMiddleware from './middlewares/message-formatter.middleware';

export const initAppLogger = (logger: RootLogger): void => {
  if (process.env.REACT_APP_LOG_LEVEL && LOG_LEVELS.includes(process.env.REACT_APP_LOG_LEVEL)) {
    logger.setLevel(process.env.REACT_APP_LOG_LEVEL as LogLevelDesc);
  } else {
    logger.setLevel('error');
  }

  consoleLoggingMiddleware(logger);
  if (getAppMode() === AppModeEnum.Default) {
    /**
     * Add middlewares. The ORDER OF MIDDLEWARES ARE IMPORTANT!
     * middlewares are executing from last to first.
     * (latest middleware applied will be the first to run on logging)
     */
    if (process.env.REACT_APP_SERVER_LOGS_ENDPOINT) {
      remoteLoggingMiddleware(logger);
    }
    fullStoryLoggingMiddleware(logger);
    messageFormatterMiddleware(logger);
  }
};
