import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { Subtitle2Font } from '../../../../themes/fonts';

export const CompanyParametersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINO};
  margin-top: ${THEME.SPACING.TINY};
  width: 240px;
`;

export const CompanyParameterLabel = styled.div<{labelIcon: string}>`
  ${Subtitle2Font};
  line-height: 1.57;
  letter-spacing: 0.25px;
  overflow:hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
  margin-right: ${THEME.SPACING.BASE};
  :before {
    display: inline-block;
    width: 19px;
    text-align: center;
    content: url(${({ labelIcon }) => labelIcon});
    vertical-align: middle;
    margin-right: ${THEME.SPACING.TINO};
    margin-top: ${THEME.SPACING.MICRO};
  }
`;

export const CompanyIpoStatusLabel = styled(CompanyParameterLabel)`
  text-transform: capitalize;
`;

export const StyledLink = styled.a`
  :hover {
    text-decoration: underline;
  }
`;
