import { useAppSelector } from '../../../../selectors';
import { onboardingQuery } from '../onboarding.toolkit-api';
import { OnboardingResponse } from '../dto/response/onboarding-response.dto';
import { FIXED_CACHE_KEYS } from '../onboarding.consts';

export const onboardingSelector = onboardingQuery.endpoints.loadOnboarding.select;

export const useGetUserOnboardingSelector = (): AppSelectorType<OnboardingResponse> => {
  const {
    data, isLoading, isError, isSuccess, error,
  } = useAppSelector(onboardingSelector({ fixedCacheKey: FIXED_CACHE_KEYS.LOAD_ONBOARDING, requestId: undefined }));

  return {
    data: data || {} as OnboardingResponse,
    error,
    isLoading,
    isError,
    isSuccess,
  };
};
