import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { AppRouting } from '../services/appRoutingResolver';
import { UpgradePlanDialog } from '../components/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import { useUrlQuery } from './route';

export const useUpgradeModalFromQueryParam = (): JSX.Element | null => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const pageQuery = useUrlQuery();
  const history = useHistory();
  const showUpgradeModal = pageQuery.get(AppRouting.URL_PARAMS.SHOW_UPGRADE_MODAL);

  if (showUpgradeModal) {
    pageQuery.delete(AppRouting.URL_PARAMS.SHOW_UPGRADE_MODAL);
    setIsDialogOpen(true);
    history.replace({
      search: pageQuery.toString(),
    });
  }

  if (!isDialogOpen) {
    return null;
  }

  return (
    <UpgradePlanDialog
      isDialogOpen={ isDialogOpen }
      onCloseDialog={ () => setIsDialogOpen(false) }
    />
  );
};
