export const ROUTES = {
  ONBOARDING: '/onboarding',
  ALLOWED_COMPANIES: '/onboarding/allowed-companies',
};

export const REDUCER_PATH = 'onboardingApi';

export const FIXED_CACHE_KEYS = {
  LOAD_ONBOARDING: 'LOAD_ONBOARDING',
};
