/* eslint-disable react/jsx-props-no-spreading */
import {
  Children, ForwardedRef, forwardRef, RefObject, useState,
} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { usePopper } from 'react-popper';
import Portal from '../../Portal/portal.commponent';
import {
  List,
  MENU_Y_OFFSET_PX,
  MenuWrapper,
  OptionsTitle,
  RowItem,
  Wrapper,
} from './option-list.styled';

type OptionListProps = {
  children: JSX.Element | JSX.Element[] | undefined,
  onClick: () => void,
  activeIndex: number | null,
  onMouseEnter: (index:number) => void,
  onMouseLeave: () => void,
  optionsTitle: string,
  closeHandler: () => void;
  inputRef: RefObject<HTMLInputElement>,
  menuOffset?: [number, number];
}

const PORTAL_CONTAINER_ID = 'AUTOCOMPLETE_OPTION_LIST';

export const OptionList = forwardRef(({
  children,
  onClick,
  activeIndex,
  onMouseEnter,
  onMouseLeave,
  optionsTitle,
  closeHandler,
  inputRef,
  menuOffset,
  ...otherProps
}: OptionListProps,
ref:  ForwardedRef<HTMLDivElement>) => {
  const childrenLength = Children.count(children);
  const [optionListRef, setOptionListRef] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(inputRef.current, optionListRef, {
    placement: 'bottom-start',
    modifiers: [
      { name: 'offset', options: { offset: menuOffset || [0, MENU_Y_OFFSET_PX] } },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['right-start'],
        },
      },
    ],
  });

  if (!children || childrenLength <= 0) {
    return null;
  }

  return (
    <Portal containerId={ PORTAL_CONTAINER_ID } isGlobalPortal removeAfterUnmount={ false }>
      <div
        ref={ setOptionListRef }
        style={ {
          ...styles.popper,
          zIndex: 2000,
        } }
        { ...attributes.popper }
      >
        <OutsideClickHandler onOutsideClick={ (e) => {
          if (!inputRef.current?.contains(e.target as Node)) {
            closeHandler();
          }
        } }
        >
          <MenuWrapper
            ref={ ref }
            { ...otherProps }
          >
            <Wrapper>
              {!!optionsTitle && (
                <OptionsTitle>
                  { optionsTitle }
                </OptionsTitle>
              )}
              <List>
                {
                  Children.map(children,
                    (optionItem:JSX.Element, index) => (
                      <RowItem
                        id={ `${index}` }
                        key={ optionItem.key }
                        onClick={ onClick }
                        isActive={ index === activeIndex }
                        isLastItem={ index === childrenLength - 1 }
                        onMouseEnter={ () => onMouseEnter(index) }
                        onMouseLeave={ onMouseLeave }
                      >
                        {optionItem}
                      </RowItem>
                    ))
                }
              </List>
            </Wrapper>
          </MenuWrapper>
        </OutsideClickHandler>
      </div>
    </Portal>

  );
});

OptionList.defaultProps = {
  menuOffset: undefined,
};

OptionList.displayName = 'OptionList';
