import { useTranslation } from 'react-i18next';
import NotificationDialog from '../notification-dialog/notification-dialog.component';
import STRING_KEYS from '../../../language/keys';
import {
  DescriptionContainer,
  EmojiContainer,
  NotificationDialogContainer,
  TitleContainer,
} from './matches-were-almost-gone.styled';

const MatchesWereAlmostGoneDialog = ({
  isOpen,
  onClose,
}: MatchesWereAlmostGoneDialogProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <NotificationDialog
      isOpen={ isOpen }
      onClose={ onClose }
      closeTitle={ translate(STRING_KEYS.MATCHES_WERE_ALMOST_GONE_DIALOG.CLOSE_TITLE) }
    >
      <NotificationDialogContainer>
        <EmojiContainer>{translate(STRING_KEYS.MATCHES_WERE_ALMOST_GONE_DIALOG.HEADER_EMOJI)}</EmojiContainer>
        <TitleContainer>{translate(STRING_KEYS.MATCHES_WERE_ALMOST_GONE_DIALOG.TITLE)}</TitleContainer>
        <DescriptionContainer>{translate(STRING_KEYS.MATCHES_WERE_ALMOST_GONE_DIALOG.DESCRIPTION)}</DescriptionContainer>
      </NotificationDialogContainer>
    </NotificationDialog>
  );
};

export default MatchesWereAlmostGoneDialog;
