import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxButtonGroupDirection } from '../Common/checkbox-button-group/checkbox-button-group.enums';
import { CheckboxButtonGroupItem } from '../Common/checkbox-button-group/checkbox-button-group';
import { LevelOfDegreeEnum } from '../../enums/level-of-degree';
import STRING_KEYS from '../../language/keys';
import { StyledCheckboxButtonGroupContainer } from './degrees-container.styled';
import { DegreesContainerProps } from './degrees-container';

const DegreesContainer = ({ selectedDegrees, changeHandler }: DegreesContainerProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const degreesItems: CheckboxButtonGroupItem<string>[] = useMemo(() => {
    return Object.keys(LevelOfDegreeEnum).map((degree) => ({
      id: degree,
      title: translate(STRING_KEYS.DEGREES[degree]),
    }));
  }, [translate]);

  return (
    <StyledCheckboxButtonGroupContainer
      items={ degreesItems }
      selectedItems={ new Set(selectedDegrees) }
      changeHandler={ changeHandler }
      direction={ CheckboxButtonGroupDirection.Row }
    />
  );
};

export default DegreesContainer;
