import styled from 'styled-components';
import { LocationStepBody } from '../../../match-tune/steps/locations-step/location-step-body/location-step-body.component';
import { THEME } from '../../../../../../themes';
import { hexToRgbA } from '../../../../../../utils/colors';
import {
  Container as LocationItemContainer,
} from '../../../match-tune/steps/locations-step/location-step-body/location-item/location-item.styled';

export const LocationContainer = styled.div`
  background-color: ${THEME.COLORS.LIGHT_GREY};
  border: solid 1px ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
`;

export const LocationSection = styled(LocationStepBody)`
  max-width: 660px;
  ${LocationItemContainer} {
    background-color: ${THEME.COLORS.LIGHT_GREY};
    border: solid 1px ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
  }
`;
