import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../../../../language/keys';
import {
  PromotedBadgeContainer,
  PromotionLabel,
} from './promotion-badge.styled';

export const PromotionBadge = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <PromotedBadgeContainer>
      <PromotionLabel>
        {translate(STRING_KEYS.PROMOTION)}
      </PromotionLabel>
    </PromotedBadgeContainer>
  );
};
