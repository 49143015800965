import styled from 'styled-components';
import { THEME } from '../../../../../themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
  flex-wrap: wrap;
`;
