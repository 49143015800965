import {
  Dispatch, FC, SetStateAction, useCallback, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { AssistantOption } from '../../../../store/rtk-query/apis/position/dto/response/position-assistant.response';
import { PositionAssistantTaskEnum } from '../../../../enums/position-assistant-task.enum';
import { MATCH_STATUSES } from '../../../../consts';
import { PositionAssistantTypeEnum } from '../../../../enums/position-assistant-type.enum';
import { AppRouting } from '../../../../services/appRoutingResolver';
import {
  PositionStatisticsResponse,
} from '../../../../store/rtk-query/apis/position/dto/response/position-statistics.response';
import { PositionAssistantProps } from './base-position-assistant/base-position-assistant';
import { ASSISTANT_TASKS_MAP } from './position-assistant.consts';
import BuildStrongerCandidatePipeline
  from './build-stronger-candidate-pipeline/build-stronger-candidate-pipeline.component';
import PromptFollowUp from './prompt-follow-up/prompt-follow-up.component';
import EncourageTimelyReplies from './encourage-timely-replies/encourage-timely-replies.component';

type UseTaskAssistant = {
  TasksAssistantsComponents: JSX.Element[];
  setTasksAssistantsComponents: Dispatch<SetStateAction<JSX.Element[]>>;
}

export const useTaskAssistantState = (
  taskAssistants: AssistantOption<PositionAssistantTaskEnum>[],
  connectToATSHandler: () => void,
  positionStatistics?: PositionStatisticsResponse,
): UseTaskAssistant => {
  const history = useHistory();
  const { positionId } = useParams<MatchUrlParams>();
  const shuffleTasks = [...taskAssistants].sort(() => Math.random() - 0.5);

  const getActionHandler = useCallback((name: PositionAssistantTaskEnum): () => void => {
    switch (name) {
      case PositionAssistantTaskEnum.BuildStrongCandidatePipeline:
        return () => history.push(AppRouting.buildMatchesPageUrl(positionId));
      case PositionAssistantTaskEnum.ATSIntegration:
        return connectToATSHandler;
      case PositionAssistantTaskEnum.PromptFollowup:
      case PositionAssistantTaskEnum.EncourageTimelyReplies:
      case PositionAssistantTaskEnum.OutreachToLikedMatches:
        return () => history.push(AppRouting.buildPositionConversationsPageUrl(positionId));
      case PositionAssistantTaskEnum.ActivateAutomatedOutreach:
      case PositionAssistantTaskEnum.ImproveOutreachEngagement:
      default:
        return () => history.push(AppRouting.buildCampaignsPreferencesPageUrl(positionId));
    }
  }, [connectToATSHandler, history, positionId]);

  const getBuildStrongerCandidatePipelineComponent = () => {
    const actionHandler = getActionHandler(PositionAssistantTaskEnum.BuildStrongCandidatePipeline);
    const progress = positionStatistics?.engagementStatusStatistics?.find(({ name: engagementStatus }) => {
      return engagementStatus === MATCH_STATUSES.ENGAGED;
    })?.amount || 0;

    return (
      <BuildStrongerCandidatePipeline
        key={ PositionAssistantTaskEnum.BuildStrongCandidatePipeline }
        data-type={ PositionAssistantTypeEnum.Task }
        actionHandler={ actionHandler }
        progress={ progress }
      />
    );
  };

  const getPromptFollowupComponent = () => {
    const actionHandler = getActionHandler(PositionAssistantTaskEnum.PromptFollowup);
    const followUpsConversations = positionStatistics?.followupsMessagesCount || 0;

    return (
      <PromptFollowUp
        key={ PositionAssistantTaskEnum.PromptFollowup }
        data-type={ PositionAssistantTypeEnum.Task }
        actionHandler={ actionHandler }
        followUpsConversations={ followUpsConversations }
      />
    );
  };

  const getEncourageTimelyRepliesComponent = () => {
    const actionHandler = getActionHandler(PositionAssistantTaskEnum.EncourageTimelyReplies);
    const newRepliedConversations = positionStatistics?.newRepliedConversations || 0;

    return (
      <EncourageTimelyReplies
        key={ PositionAssistantTaskEnum.EncourageTimelyReplies }
        data-type={ PositionAssistantTypeEnum.Task }
        actionHandler={ actionHandler }
        waitingToReply={ newRepliedConversations }
      />
    );
  };

  const [TasksAssistantsComponents, setTasksAssistantsComponents] = useState<JSX.Element[]>(() => {
    return shuffleTasks.map(({ name }) => {
      if (name === PositionAssistantTaskEnum.BuildStrongCandidatePipeline) {
        return getBuildStrongerCandidatePipelineComponent();
      }

      if (name === PositionAssistantTaskEnum.PromptFollowup) {
        return getPromptFollowupComponent();
      }

      if (name === PositionAssistantTaskEnum.EncourageTimelyReplies) {
        return getEncourageTimelyRepliesComponent();
      }

      const actionHandler = getActionHandler(name);
      const Component = ASSISTANT_TASKS_MAP[name] as FC<PositionAssistantProps>;

      return <Component key={ name } data-type={ PositionAssistantTypeEnum.Task } actionHandler={ actionHandler } />;
    });
  });

  return {
    TasksAssistantsComponents,
    setTasksAssistantsComponents,
  };
};
