import { MATCH_PAGE_CONTENT_WIDTH } from '../../../modules/application/components/match-card/match-card.styled';
import { ActiveIndicator, Container } from './navigation-indication.styled';

const createNavigationIndicatorKey = (index: number) => {
  return `NAVIGATION_INDICATION_${index}`;
};

const NavigationIndication = ({ count, activeIndex }: NavigationIndicationProps): JSX.Element => {
  const indicatorWidth = MATCH_PAGE_CONTENT_WIDTH / count;

  return (
    <Container>
      { Array.from(Array(count).keys()).map((_, index) => {
        if (index <= activeIndex) {
          return <ActiveIndicator key={ createNavigationIndicatorKey(index) } width={ indicatorWidth } />;
        }

        return null;
      }) }
    </Container>
  );
};

export default NavigationIndication;
