import { useTranslation } from 'react-i18next';
import { LevelOfExperience } from '../../enums/level-of-experience.enum';
import STRING_KEYS from '../../language/keys';
import CheckboxButtonGroupContainer from '../Common/checkbox-button-group/checkbox-button-group.component';
import { CheckboxButtonGroupItem } from '../Common/checkbox-button-group/checkbox-button-group';
import { getMatchingOrderedLevelsOfExperience } from '../../utils/positions';
import { getEnumValuesInRange } from '../../utils';
import { LevelOfExperienceContainerProps } from './level-of-experience-container';
import { LEVEL_OF_EXPERIENCE_YEARS } from './level-of-experience-container.consts';
import {
  EntryLevelIconIcon,
  HighlyExperiencedIcon,
  IncrediblyExperiencedIcon,
  SomewhatExperiencedIcon,
  StartingOutIcon,
} from './level-of-experience-container.styled';

const LevelOfExperienceContainer = ({ selectedItems, clickHandler, changeHandler }: LevelOfExperienceContainerProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const experienceItems: CheckboxButtonGroupItem<LevelOfExperience>[] = [
    {
      id: LevelOfExperience.EntryLevel,
      title: translate(STRING_KEYS.LEVEL_OF_EXPERIENCE[LevelOfExperience.EntryLevel]),
      description: translate(
        STRING_KEYS.ONBOARDING.ONBOARDING_EXPERIENCE_TOTAL_YEARS_ENTRY_LEVEL,
        LEVEL_OF_EXPERIENCE_YEARS.ENTRY_LEVEL,
      ),
      Icon: <EntryLevelIconIcon />,
    },
    {
      id: LevelOfExperience.StartingOut,
      title: translate(STRING_KEYS.LEVEL_OF_EXPERIENCE[LevelOfExperience.StartingOut]),
      description: translate(
        STRING_KEYS.ONBOARDING.ONBOARDING_EXPERIENCE_TOTAL_YEARS,
        LEVEL_OF_EXPERIENCE_YEARS.STARTING_OUT,
      ),
      Icon: <StartingOutIcon />,
    },
    {
      id: LevelOfExperience.SomewhatExperienced,
      title: translate(STRING_KEYS.LEVEL_OF_EXPERIENCE[LevelOfExperience.SomewhatExperienced]),
      description: translate(
        STRING_KEYS.ONBOARDING.ONBOARDING_EXPERIENCE_TOTAL_YEARS,
        LEVEL_OF_EXPERIENCE_YEARS.SOMEWHAT_EXPERIENCED,
      ),
      Icon: <SomewhatExperiencedIcon />,
    },
    {
      id: LevelOfExperience.HighlyExperienced,
      title: translate(STRING_KEYS.LEVEL_OF_EXPERIENCE[LevelOfExperience.HighlyExperienced]),
      description: translate(
        STRING_KEYS.ONBOARDING.ONBOARDING_EXPERIENCE_TOTAL_YEARS,
        LEVEL_OF_EXPERIENCE_YEARS.HIGHLY_EXPERIENCED,
      ),
      Icon: <HighlyExperiencedIcon />,
    },
    {
      id: LevelOfExperience.IncrediblyExperienced,
      title: translate(STRING_KEYS.LEVEL_OF_EXPERIENCE[LevelOfExperience.IncrediblyExperienced]),
      description: `${LEVEL_OF_EXPERIENCE_YEARS.INCREDIBLY_EXPERIENCED}
      ${translate(STRING_KEYS.YEARS)}`,
      Icon: <IncrediblyExperiencedIcon />,
    },
  ];

  const onLevelOfExperienceChange = (levelOfExperienceChangedData: Set<LevelOfExperience>) => {
    let newValues;
    if (levelOfExperienceChangedData.size < selectedItems.size) {
      newValues = getMatchingOrderedLevelsOfExperience(Array.from(levelOfExperienceChangedData));
    } else {
      newValues = getEnumValuesInRange(LevelOfExperience, Array.from(levelOfExperienceChangedData));
    }

    changeHandler(new Set(newValues));
  };

  return (
    <CheckboxButtonGroupContainer
      items={ experienceItems }
      selectedItems={ selectedItems }
      changeHandler={ onLevelOfExperienceChange }
      clickHandler={ clickHandler }
    />
  );
};

export default LevelOfExperienceContainer;
