export enum ConversationGroupsEnum {
  Replied = 'REPLIED',
  ToOutreach = 'TO_OUTREACH',
  ToFollowup = 'TO_FOLLOWUP',
  Sent = 'SENT',
  Archived = 'ARCHIVED',
  All = 'ALL',
  Completed = 'COMPLETED',
  Step = 'STEP',
}
