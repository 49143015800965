type HexToRgbAType = {
  hex?: string,
  alpha?: number,
}

export const hexToRgb = (hex: string): number[] => {
  return hex.match(/\w\w/g)?.map((x) => parseInt(x, 16)) || [0, 0, 0];
};

export const hexToRgbA = (hex: string, alpha = 1): string => {
  if (!hex) {
    return hex;
  }
  const [r, g, b] = hexToRgb(hex);

  return `rgba(${r},${g},${b},${alpha})`;
};

export const hexToHslA = ({ hex = '', alpha = 1 }: HexToRgbAType): string => {
  if (!hex) {
    return hex;
  }

  const [rHex, gHex, bHex] = hexToRgb(hex);

  const r = rHex / 255;
  const g = gHex / 255;
  const b = bHex / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = (max + min) / 2;
  let s = h;
  let l = h;

  if (max === min) {
    // Achromatic
    return `hsla(${0},${0}%,${l * 100}%,${alpha})`;
  }

  const d = max - min;
  s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

  switch (max) {
    case r:
      h = (g - b) / d + (g < b ? 6 : 0);
      break;
    case g:
      h = (b - r) / d + 2;
      break;
    default:
    case b:
      h = (r - g) / d + 4;
      break;
  }
  h /= 6;

  s *= 100;
  s = Math.round(s);
  l *= 100;
  l = Math.round(l);
  h = Math.round(360 * h);

  return `hsla(${h},${s}%,${l}%,${alpha})`;
};
