import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import {
  Body2Font,
  CaptionFont,
  HeadlineFont,
  Subtitle2Font,
} from '../../../../../themes/fonts';
import { ASSETS } from '../../../../../assets';
import { DoDontCardType } from './do-dont-card.enums';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
  position: relative;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const Chip = styled.div<{ cardType: DoDontCardType }>`
  display: flex;
  align-items: center;
  ${Body2Font};
  padding: 0 ${THEME.SPACING.TINO};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  background-color: ${({ cardType }) => cardType === DoDontCardType.Do ? THEME.COLORS.SUCCESS : THEME.COLORS.ALERT};
  width: fit-content;
  color: ${THEME.COLORS.PURE_WHITE};
  position: absolute;
  top: -${THEME.SPACING.TINY};
  font-weight: 500;
  gap: ${THEME.SPACING.TINY};
`;

export const VIcon = styled(ASSETS.V_SIGN)`
  width: 12px;
`;

export const XIcon = styled(ASSETS.X_SIGN)`
  width: 12px;
  fill: currentColor;
`;

export const QuoteContainer = styled.div`
  background-color: ${THEME.COLORS.LIGHT_GREY};
  height: 88px;
  border-radius: ${THEME.RADIUS.ROUNDISH};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Quote = styled.div`
  ${HeadlineFont};
  font-family: Merriweather;
  opacity: ${THEME.OPACITY.MED};
  font-weight: 600;
`;

export const Title = styled.div`
  ${Subtitle2Font};
  font-weight: 600;
`;

export const Text = styled.div`
  ${CaptionFont};
`;
