import { useSelector } from 'react-redux';
import { RootReducerState } from '../../../index';
import { CreatePositionJobDescriptionSelector } from '../../../../modules/create-position/@types/create-position-selectors';

export const useCreatePositionJobDescription = (): CreatePositionJobDescriptionSelector => {
  return useSelector(({ createPosition  }: RootReducerState) => {
    return {
      jobDescriptionTempUrls: createPosition.jobDescriptionTempUrls,
      jobDescriptionS3TempUrls: createPosition.jobDescriptionS3TempUrls,
    };
  });
};
