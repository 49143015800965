import { useTranslation } from 'react-i18next';
import AdjustmentItem from '../adjustment-item.component';
import STRING_KEYS from '../../../../../../language/keys';
import { LocationIcon } from '../adjustment-item.styled';
import { LocationSection } from './location-adjustment.styled';
import { LocationAdjustmentProps } from './location-adjustment';

const LocationAdjustment = ({
  locations,
  isRemote,
  changeHandler,
  remotePositionChangeHandler,
}: LocationAdjustmentProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <div>
      <AdjustmentItem
        icon={ <LocationIcon /> }
        title={ translate(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.LOCATION_ADJUSTMENT.TITLE) }
        description={ translate(STRING_KEYS.POSITION_ADJUSTMENTS_PAGE.LOCATION_ADJUSTMENT.DESCRIPTION) }
        showActionButton={ false }
      >
        <LocationSection
          locations={ locations }
          remotePosition={ isRemote }
          changeHandler={ changeHandler }
          remotePositionChangeHandler={ remotePositionChangeHandler }
        />
      </AdjustmentItem>
    </div>
  );
};

export default LocationAdjustment;
