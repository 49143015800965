/* eslint-disable react/jsx-props-no-spreading */
import {
  Container,
  CountContainer,
  IconContainer,
  TagsContainer,
  OptimalLine,
  Polygon,
  Subtitle,
  Title,
  TitleContainer,
  TopContainer,
  Wrapper,
} from './funnel-bar.styled';

const FunnelBar = ({
  title,
  subtitle,
  Icon,
  count,
  Tags,
  level,
  optimalLevel,
  nextOptimalLevel,
  ...otherProps
}: FunnelBarProps): JSX.Element => {
  const funnelLevel = level * 100;
  const funnelOptimalLevel = optimalLevel * 100;
  const funnelNextOptimalLevel = (nextOptimalLevel || 0) * 100;

  return (
    <Container { ...otherProps }>
      <Wrapper>
        <TopContainer>
          <TitleContainer>
            <IconContainer><Icon /></IconContainer>
            <Title>{ title }</Title>
          </TitleContainer>
          <CountContainer>{ count }</CountContainer>
          { subtitle && (
            <Subtitle>{ subtitle }</Subtitle>
          ) }
        </TopContainer>
        { !!Tags && (
          <TagsContainer>{ Tags }</TagsContainer>
        ) }
      </Wrapper>
      <Polygon level={ funnelLevel } />
      <OptimalLine level={ funnelOptimalLevel } nextLevel={ funnelNextOptimalLevel } />
    </Container>
  );
};

export default FunnelBar;
