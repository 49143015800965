import styled from 'styled-components';
import { Subtitle1Font, Title1Font, Title2Font } from '../../../../themes/fonts';
import { THEME } from '../../../../themes';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { ASSETS } from '../../../../assets';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${THEME.SPACING.XLOOSE};
  padding-bottom: ${THEME.SPACING.GRAND};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const Title = styled.div`
  ${Title1Font};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const SubTitle = styled.div`
  ${Title2Font};
  color: ${THEME.COLORS.PRIMARY_DARK};
  opacity: ${THEME.OPACITY.MED};
`;

export const AdjustmentItemsContainerTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AdjustmentItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
  margin-top: ${THEME.SPACING.LARGE};
  min-width: 985px;
`;

export const AdjustmentItemsContainerHint = styled.div`
  ${Subtitle1Font};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
  font-weight: 700;
`;

export const AdjustmentItemsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const SelectAllButton = styled(BaseButton)`
  color: ${THEME.COLORS.ACCENT};
  background-color: transparent;
  height: fit-content;
`;

export const SelectAllButtonIcon = styled(ASSETS.DOUBLE_V_SIGN)`
  width: 24px;
`;

export const ActionContainer = styled.div`
  display: flex;
  margin-top: ${THEME.SPACING.BASE};
  align-items: center;
  gap: ${THEME.SPACING.TINO};
`;

export const ConfirmButton = styled(BaseButton)`
  color: ${THEME.COLORS.PURE_WHITE};
  background-color: ${THEME.COLORS.ACCENT};
  height: 48px;
  text-transform: unset;
`;

export const EditPositionButton = styled(BaseButton)`
  color: ${THEME.COLORS.ACCENT};
  text-transform: unset;
`;

export const SmallAdjustmentWrapper = styled.div`
  max-width: 800px;
`;
