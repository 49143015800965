import { Skeleton } from '@mui/material';
import React from 'react';
import { THEME } from '../../../../../../themes';
import { ContactDetailsSkeletonContainer, ContactDetailsSkeletonRow } from './contact-details-skeleton.styled';

const ContactDetailsSkeleton = (): JSX.Element => {
  const renderRow = (width: number) => {
    return (
      <ContactDetailsSkeletonRow>
        <Skeleton
          variant="circular"
          width={ 12 }
          height={ 12 }
          sx={ {
            borderRadius: THEME.RADIUS.BASE,
          } }
        />
        <Skeleton
          variant="rectangular"
          width={ width }
          height={ 4 }
          sx={ {
            borderRadius: THEME.RADIUS.BASE,
          } }
        />
      </ContactDetailsSkeletonRow>
    );
  };

  return (
    <ContactDetailsSkeletonContainer>
      { renderRow(43) }
      { renderRow(146) }
      { renderRow(81) }
    </ContactDetailsSkeletonContainer>
  );
};

export default ContactDetailsSkeleton;
