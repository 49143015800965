import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import KEYS from '../../../language/keys';
import { ProgressStepTypes } from '../../../components/Common/headers/progress-bar-header/progress-bar/progress-bar-step/progress-bar-step.enum';
import { ProgressStep } from '../../../components/Common/headers/progress-bar-header/progress-bar/progress-bar';
import { CreatePositionStepsEnums } from '../enums/create-position-steps.enums';
import { CreatePositionLetsBeginStep } from '../components/create-position-lets-begin-step/create-position-lets-begin-step.component';
import { CreatePositionExperienceStep } from '../components/create-position-experience-step/create-position-experience-step.componet';
import { SlideProps } from '../components/create-position-slider/create-position-slider';
import { getStepIndex } from '../../onboarding/utils/onboarding-bi-events.utls';
import { SliderDirectionEnums } from '../../../enums/slider-direction.enums';
import { CreatePositionEducationStep } from '../components/create-position-education-step/create-position-education-step.componet';
import { JobDescriptionStep } from '../components/create-position-job-description-step/create-position-description-step.componet';
import {
  setContinueButtonDisable,
  setContinueButtonEnable,
  setContinueButtonShow,
} from '../../../store/slices/create-position-state/create-position-state.toolkit-slice';
import CreatePositionBackgroundStep
  from '../components/create-position-background-step/create-position-background-step.component';
import { useGetCurrentStep } from '../../onboarding/hooks/onboarding-current-step.hooks';

type UseCreatePositionProgressStepsType = {
  steps: ProgressStep[],
}

export const useCreatePositionProgressSteps = (currentStep: CreatePositionStepsEnums): UseCreatePositionProgressStepsType => {
  const { t: translate } = useTranslation();
  const getStepType = (step: CreatePositionStepsEnums): ProgressStepTypes => {
    const indexOfCurrentStep = Object.values(CreatePositionStepsEnums).indexOf(step);
    const indexOfStepFromServer = Object.values(CreatePositionStepsEnums).indexOf(currentStep);

    if (indexOfCurrentStep === indexOfStepFromServer) {
      return ProgressStepTypes.InProgress;
    }

    return indexOfCurrentStep < indexOfStepFromServer ? ProgressStepTypes.Finished : ProgressStepTypes.Waiting;
  };

  return useMemo<UseCreatePositionProgressStepsType>(() => {
    return {
      steps: [{
        title: translate(KEYS.ONBOARDING.PROGRESS_BAR_STEPS.BASIC_INFO),
        type: getStepType(CreatePositionStepsEnums.LetsBegin),
        step: CreatePositionStepsEnums.LetsBegin,
      }, {
        title: translate(KEYS.ONBOARDING.PROGRESS_BAR_STEPS.EXPERIENCE),
        type: getStepType(CreatePositionStepsEnums.Experience),
        step: CreatePositionStepsEnums.Experience,
      }, {
        title: translate(KEYS.ONBOARDING.PROGRESS_BAR_STEPS.BACKGROUND),
        type: getStepType(CreatePositionStepsEnums.Background),
        step: CreatePositionStepsEnums.Background,
      }, {
        title: translate(KEYS.ONBOARDING.PROGRESS_BAR_STEPS.EDUCATION),
        type: getStepType(CreatePositionStepsEnums.Education),
        step: CreatePositionStepsEnums.Education,
      },
      {
        title: translate(KEYS.ONBOARDING.PROGRESS_BAR_STEPS.JOB_DESCRIPTION),
        type: getStepType(CreatePositionStepsEnums.JobDescription),
        step: CreatePositionStepsEnums.JobDescription,
      }],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);
};

type UseCreatePositionDialogSlidesProps = {
  onLastClickHandler?: () => void,
}

type UseCreatePositionDialogSlidesResponse = {
  slidesState: SlideProps[],
  nextSlide: () => void,
  prevSlide: () => void,
  setDefaults: () => void,
  activeSlide: SlideProps,
}

const reCreateSlides = (
  prev: SlideProps[],
  slidesDefault: SlideProps[],
  isNextSlide: boolean,
  onLastClickHandler?: () => void,
): SlideProps[] => {
  const activeIndex = prev.findIndex(({ active }) => active);
  const nextStep = isNextSlide ? prev[activeIndex].nextStep : prev[activeIndex].prevStep;
  const newState = [...prev];
  if (nextStep) {
    const newActiveIndex = isNextSlide ? activeIndex + 1 : activeIndex - 1;
    newState[activeIndex] = {
      ...newState[activeIndex],
      active: false,
      direction: isNextSlide ? SliderDirectionEnums.Right : SliderDirectionEnums.Left,
    };
    newState[newActiveIndex] = {
      ...newState[newActiveIndex],
      active: true,
      direction: isNextSlide ? SliderDirectionEnums.Left : SliderDirectionEnums.Right,
    };

    return newState;
  }
  onLastClickHandler?.();

  return slidesDefault;
};

export const useCreatePositionDialogSlides = ({ onLastClickHandler }: UseCreatePositionDialogSlidesProps): UseCreatePositionDialogSlidesResponse => {
  const currentStep = useGetCurrentStep<CreatePositionStepsEnums>();
  const { steps } = useCreatePositionProgressSteps(currentStep);
  const stepNumber = getStepIndex(currentStep, steps);

  const slidesDefault = [{
    key: CreatePositionStepsEnums.LetsBegin,
    nextStep: CreatePositionStepsEnums.Experience,
    active: true,
    direction: SliderDirectionEnums.Left,
    component: (
      <CreatePositionLetsBeginStep
        stepNumber={ stepNumber }
        setContinueButtonEnable={ setContinueButtonEnable }
        setContinueButtonDisable={ setContinueButtonDisable }
        setContinueButtonShow={ setContinueButtonShow }
      />
    ),
  }, {
    key: CreatePositionStepsEnums.Experience,
    nextStep: CreatePositionStepsEnums.Background,
    prevStep: CreatePositionStepsEnums.LetsBegin,
    active: false,
    direction: SliderDirectionEnums.Left,
    component: (
      <CreatePositionExperienceStep
        stepNumber={ stepNumber }
        setContinueButtonEnable={ setContinueButtonEnable }
        setContinueButtonDisable={ setContinueButtonDisable  }
        setContinueButtonShow={ setContinueButtonShow }
      />),
  }, {
    key: CreatePositionStepsEnums.Background,
    nextStep: CreatePositionStepsEnums.Education,
    prevStep: CreatePositionStepsEnums.Experience,
    active: false,
    direction: SliderDirectionEnums.Left,
    component: (
      <CreatePositionBackgroundStep
        setContinueButtonEnable={ setContinueButtonEnable }
        setContinueButtonDisable={ setContinueButtonDisable }
      />
    ),
  }, {
    key: CreatePositionStepsEnums.Education,
    nextStep: CreatePositionStepsEnums.JobDescription,
    prevStep: CreatePositionStepsEnums.Background,
    active: false,
    direction: SliderDirectionEnums.Left,
    component: (
      <CreatePositionEducationStep
        setContinueButtonEnable={ setContinueButtonEnable }
        setContinueButtonDisable={ setContinueButtonDisable }
      />
    ),
  },
  {
    key: CreatePositionStepsEnums.JobDescription,
    prevStep: CreatePositionStepsEnums.Education,
    active: false,
    direction: SliderDirectionEnums.Left,
    component: (
      <JobDescriptionStep
        setContinueButtonEnable={ setContinueButtonEnable }
        setContinueButtonDisable={ setContinueButtonDisable }
      />
    ),
  }] as SlideProps[];
  const [slidesState, setSlidesState] = useState(slidesDefault);

  const nextSlide = () => {
    setSlidesState((prev) => {
      return reCreateSlides(prev, slidesDefault, true, onLastClickHandler);
    });
  };

  const prevSlide = () => {
    setSlidesState((prev) => {
      return reCreateSlides(prev, slidesDefault, false, onLastClickHandler);
    });
  };

  const setDefaults = () => {
    setSlidesState(slidesDefault);
  };

  return {
    slidesState: useMemo(() => slidesState, [slidesState]),
    nextSlide,
    prevSlide,
    setDefaults,
    activeSlide: slidesState.filter((slide) => slide.active)[0],
  };
};
