import {
  DifferenceStripedBlock,
  Meter, MeterTitle, MeterTitleExperience, TooltipBubble, MeterRow,
} from './tenure-meter-item.styled';

type TenureMeterItemProps = {
  title: string,
  width: number,
  years: string,
  isEnableAnimation: boolean,
  isBiggestValue: boolean,
  widthShowDiff: number,
  type: string,
  meter: string,
  isEqual: boolean,
}
export const TenureMeterItem = ({
  title,
  width,
  years,
  isEnableAnimation,
  isBiggestValue,
  widthShowDiff,
  type,
  meter,
  isEqual,
}: TenureMeterItemProps):JSX.Element => {
  return (
    <TooltipBubble
      title={ title }
      placement="top"
    >
      <MeterRow>
        <Meter
          isBiggestValue={ isBiggestValue }
          width={ width }
          isEnableAnimation={ isEnableAnimation }
          meter={ meter }
          isEqual={ isEqual }
        >
          <MeterTitle>
            {title}
          </MeterTitle>
          <MeterTitleExperience title={ title }>
            {years}
          </MeterTitleExperience>
        </Meter>
        {!isBiggestValue && !isEqual && (
          <DifferenceStripedBlock
            type={ type }
            width={ widthShowDiff }
          />
        )}
      </MeterRow>
    </TooltipBubble>
  );
};
