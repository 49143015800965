import { sequenceQuery } from '../sequence.toolkit-api';
import { PositionSequencePreferencesResponseDto } from '../dto/response/position-sequence-preferences.response';

export const useGetPositionSequencePreferencesQuery = (positionId: string, skip = false): AppSelectorType<PositionSequencePreferencesResponseDto> => {
  const {
    data,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
    refetch,
  } = sequenceQuery.useGetPositionSequencePreferencesQuery(
    { positionId },
    { skip },
  );

  return {
    data: data || {} as PositionSequencePreferencesResponseDto,
    error,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    refetch,
  };
};
