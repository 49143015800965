import { CaseReducer } from '@reduxjs/toolkit';
import { AppState } from '../app-state.initial-state';

export const enableFlashingFaviconReducer: CaseReducer<AppState> = (state) => {
  state.isFaviconFlashing = true;
};

export const disableFlashingFaviconReducer: CaseReducer<AppState> = (state) => {
  state.isFaviconFlashing = false;
};
