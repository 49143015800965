import {
  forwardRef, Ref, ReactElement, RefObject,
} from 'react';
import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import FullScreenDialogHeader from './full-screen-dialog-header/full-screen-dialog-header.component';
import FullScreenDialogBody from './full-screen-dialog-body/full-screen-dialog-body.component';
import FullScreenDialogFooter from './full-screen-dialog-footer/full-screen-dialog-footer.component';

type FullScreenDialogProps = {
  children: JSX.Element | JSX.Element[],
  isOpen: boolean;
  closeHandler?: () => void;
  onOpened?: () => void;
  ref?: RefObject<HTMLDivElement>,
};

const Transition = forwardRef((
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ ref } { ...props } timeout={ 500 } />;
});

Transition.displayName = 'Transition';

const FullScreenDialog = ({
  isOpen, closeHandler, onOpened, children, ref,
}: FullScreenDialogProps): JSX.Element => {
  return (
    <>
      <Dialog
        fullScreen
        ref={ ref }
        open={ isOpen }
        onClose={ () => closeHandler?.() }
        TransitionComponent={ Transition }
        TransitionProps={
          { onEntered: () => onOpened?.() }
        }
      >
        { children }
      </Dialog>
    </>
  );
};

FullScreenDialog.defaultProps = {

};

FullScreenDialog.Header = FullScreenDialogHeader;
FullScreenDialog.Body = FullScreenDialogBody;
FullScreenDialog.Footer = FullScreenDialogFooter;

FullScreenDialog.defaultProps = {
  ref: undefined,
};

export default FullScreenDialog;

FullScreenDialog.defaultProps = {
  onOpened: undefined,
  closeHandler: undefined,
};
