import { PerfectButtonVariants } from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import {
  ActionButton,
  ContentContainer,
  Description,
  IconContainer,
  Label,
  Title, TitleContainer,
  BannerContainer,
  Background,
  UpgradeButtonIcon,
} from './header-banner.styled';

const HeaderBanner = ({
  label, actionButtonText, actionButtonClickHandler, title, description, gradientBackgroundColor, Icon,
}: HeaderBannerProps): JSX.Element => {
  return (
    <BannerContainer>
      <Background gradientBackgroundColor={ gradientBackgroundColor } />
      <IconContainer>
        { Icon }
      </IconContainer>
      <ContentContainer>
        <TitleContainer>
          <Label>
            { label }
          </Label>
          <Title>
            { title }
          </Title>
        </TitleContainer>
        <Description>
          { description }
        </Description>
        <ActionButton
          variant={ PerfectButtonVariants.Ghost }
          StartIcon={ UpgradeButtonIcon }
          onClick={ actionButtonClickHandler }
        >
          { actionButtonText }
        </ActionButton>
      </ContentContainer>
    </BannerContainer>
  );
};
export default HeaderBanner;
