import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../language/keys';
import {
  HeaderContainer,
  StyledDialog,
  SubTitle,
  Title,
} from './guidance-modal.styled';
import { GuidanceModalProps } from './guidance-modal';

const GuidanceModal = ({
  isOpen,
  closeHandler,
  widthSize,
  title,
  children,
}: GuidanceModalProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <StyledDialog
      isOpen={ isOpen }
      onClose={ closeHandler }
      widthSize={ widthSize }
    >
      <HeaderContainer>
        <SubTitle>{ translate(STRING_KEYS.GUIDANCE_MODAL.TITLE) }</SubTitle>
        <Title>{ title }</Title>
      </HeaderContainer>
      { children }
    </StyledDialog>
  );
};

export default GuidanceModal;
