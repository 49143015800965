import { useTranslation } from 'react-i18next';
import { useMemo, useRef, useState } from 'react';
import { PerfectTooltip } from '../../../../../../../../../components/Common/perfect-tooltip';
import { ExplainabilityOrigin } from '../../../../../../../../../store/rtk-query/apis/match/dto/response/match.response';
import STRING_KEYS from '../../../../../../../../../language/keys';
import ExactAttributeInfoMenu
  from '../../../../../../explainability-info-menu/exact-attribute-info-menu/exact-attribute-info-menu.component';
import Popper from '../../../../../../../../../components/Common/popper/popper.component';
import { ExplainabilityTypeEnum } from '../../../../../../../../../enums/explainability-type.enum';
import ExplainabilityOriginSentence
  from '../../../../../../explainability-origin-sentence/explainability-origin-sentence.component';
import {
  Container,
  ExactAttributeName,
  OriginSourceText,
  VerifiedIcon,
} from './exact-attribute-row.styled';
import { ExactAttributeRowProps } from './exact-attribute-row';

const ExactAttributeRow = ({ type, attribute }: ExactAttributeRowProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const ref =  useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getOriginSourceText = (origins?: ExplainabilityOrigin[]): JSX.Element | string | undefined => {
    if (!origins?.length) {
      return undefined;
    }

    const originMapByCompanyName = new Map();
    origins.forEach((origin) => originMapByCompanyName.set(origin.companyName, origin));
    const uniqueOrigins = Array.from(originMapByCompanyName.values());

    const OriginText = (
      <ExplainabilityOriginSentence
        type={ type }
        origin={ origins[0] }
        value={ attribute.value }
      />
    );

    if (uniqueOrigins.length === 1) {
      return OriginText;
    }

    return (
      <span>
        { OriginText } { translate(STRING_KEYS.AND_X_MORE, { count: uniqueOrigins.length - 1 }) }
      </span>
    );
  };

  const tooltipText = useMemo(() => (
    translate(
      type === ExplainabilityTypeEnum.Skill ?
        STRING_KEYS.MATCH_CARD.EXPLAINABILITY.EXACT_SKILL_MORE_INFO_TOOLTIP_TEXT :
        STRING_KEYS.MATCH_CARD.EXPLAINABILITY.EXACT_INDUSTRY_MORE_INFO_TOOLTIP_TEXT,
    )
  ), [translate, type]);

  return (
    <>
      <PerfectTooltip
        title={ tooltipText }
        placement="bottom-start"
        enterDelay={ 700 }
        enterNextDelay={ 700 }
      >
        <Container ref={ ref } onClick={ () => setIsMenuOpen(true) }>
          <ExactAttributeName>{ attribute.value }</ExactAttributeName>
          <VerifiedIcon />
          <OriginSourceText>{ getOriginSourceText(attribute.origins) }</OriginSourceText>
        </Container>
      </PerfectTooltip>
      <Popper
        isOpen={ isMenuOpen }
        anchorElement={ ref?.current || undefined }
        placement="top-start"
        closeHandler={ () => setIsMenuOpen(false) }
      >
        <ExactAttributeInfoMenu
          type={ type }
          value={ attribute.value }
          origins={ attribute.origins }
        />
      </Popper>
    </>
  );
};

export default ExactAttributeRow;
