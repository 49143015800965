import { companyQuery } from '../company.toolkit-api';
import { CompanyInfoResponse } from '../dto/response/company-info.response';

export const getCompanyByIdQuery = (companyId = '', shouldFetch = true): AppSelectorType<CompanyInfoResponse> => {
  const {
    data,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
    refetch,
  } = companyQuery.useGetCompanyByIdQuery(
    { companyId },
    {
      skip: !shouldFetch,
    },
  );

  return {
    data: data || {} as CompanyInfoResponse,
    error,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    refetch,
  };
};
