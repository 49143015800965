import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import ExplainabilityWidgetItem from '../explainability-widget-item.component';
import STRING_KEYS from '../../../../../../../../language/keys';
import { LevelOfDegreeEnum } from '../../../../../../../../enums/level-of-degree';
import { ExplainabilityWidgetEducationProps } from './explainability-widget-education';
import { Icon } from './explainability-widget-education.styled';

const ExplainabilityWidgetEducation = ({ match }: ExplainabilityWidgetEducationProps): JSX.Element | null => {
  const { t: translate } = useTranslation();

  const mappedEducationList = useMemo(() => (
    match.explainability?.education?.filter(Boolean)?.map((education) => {
      const { value } = education;
      if (value in LevelOfDegreeEnum) {
        return translate(STRING_KEYS.DEGREES[value]);
      }

      return value;
    })?.filter(Boolean)?.join(', ')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [match?.id]);

  if (!mappedEducationList) {
    return null;
  }

  return (
    <ExplainabilityWidgetItem
      key="ExplainabilityWidgetEducation"
      label={ translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY_WIDGET.ITEMS_LABELS.EDUCATION) }
      value={ mappedEducationList }
      Icon={ Icon }
    />
  );
};

export default ExplainabilityWidgetEducation;
