import styled, { css, keyframes } from 'styled-components';
import { HeadlineFont } from '../../../../../themes/fonts';
import { THEME } from '../../../../../themes';

const scaleInForward = keyframes`
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
`;

export const InsightsCardsContainer = styled.div`
  margin-top: ${THEME.SPACING.MEDIUM};
  display: grid;
  grid-template-columns: 210px 210px;
  grid-gap: 10px;
  align-items: start;
`;

export const InsightsTitle = styled.div`
  ${HeadlineFont};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const InsightsAnimatedWrapper = styled.div<{
  index: number,
}>`
  animation: ${({ index }) => {
    return css`${scaleInForward} .4s cubic-bezier(0.250, 0.460, 0.450, 0.940)
    ${(index + 1) * 100}ms both`;
  }};
`;
