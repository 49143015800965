import React from 'react';
import ReactDOM from 'react-dom';
import './app.css';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import log from 'loglevel';
import reportWebVitals from '../../reportWebVitals';
import '../../language';
import store from '../../store';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { App } from '../../components/App';
import { WithMobileCheck } from '../../components/HOC/WithMobileCheck';
import UtmTrackingService from '../../services/utmTracker';
import { AppRouting } from '../../services/appRoutingResolver';

const logger = log.getLogger('ENTRY_APP_INDEX');

UtmTrackingService.checkForUTMParams();

logger.info('App information', {
  appInformation: {
    appVersion: process.env.REACT_APP_VERSION,
    currentDomain: window.location.origin,
    userAgent: navigator.userAgent,
  },
});

// check if we need to redirect the user to a global-login url
if (!AppRouting.redirectToGlobalLogin()) {
  // we only render the app if we aren't redirecting the user -
  // to prevent "flickering" in the UI of the first url that is trying to be loaded

  const queryClient = new QueryClient();

  // Main render
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Provider store={ store }>
          <QueryClientProvider client={ queryClient }>
            <WithMobileCheck>
              <App />
            </WithMobileCheck>
          </QueryClientProvider>
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(log.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
