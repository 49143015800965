import { sequenceQuery } from '../sequence.toolkit-api';
import { ApiError } from '../../../../../consts/api';
import { CreateMessagePreviewQueryArguments } from '../dto/query-arguments/create-message-preview.query-arguments';
import { MessagePreviewResponseDto } from '../dto/response/message-preview.response';

type UseCreateMessagePreviewsMutationType = [
  (positionSequencePreferences: CreateMessagePreviewQueryArguments) => void,
  AppSelectorType<MessagePreviewResponseDto | undefined, ApiError>
];

export const useCreateMessagePreviewMutation = (): UseCreateMessagePreviewsMutationType => {
  const [
    createMessagePreview,
    {
      data, isLoading, isError, error: apiError, isSuccess, reset,
    }] = sequenceQuery.useCreateMessagePreviewMutation();

  const meta = {
    isLoading, isError, error: apiError as ApiError, isSuccess, data, resetState: reset,
  };

  return [
    createMessagePreview,
    { ...meta },
  ];
};
