import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import {
  Icon,
  Input,
  Wrapper,
  ValidationError,
} from './email-input.styled';

type EmailInputType = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  isError: boolean,
  error: string,
}

export const EmailInput = ({
  onChange,
  isError,
  error,
}: EmailInputType): JSX.Element => {
  const { t: translate } = useTranslation();

  const defineEmailErrors = (): JSX.Element | null => {
    if (error) {
      return (
        <ValidationError>
          { error }
        </ValidationError>
      );
    }

    return null;
  };

  return (
    <Wrapper>
      <Icon />
      <Input
        placeholder={ translate(STRING_KEYS.WORK_EMAIL) }
        onChange={ onChange }
        isError={ isError }
      />
      { defineEmailErrors() }
    </Wrapper>
  );
};
