import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { ASSETS } from '../../../../../assets';
import { hexToRgbA } from '../../../../../utils/colors';
import { OverlineFont } from '../../../../../themes/fonts';
import BaseRealizationMetrics from '../base-component/base-component.component';

export const DiamondIcon = styled(ASSETS.FILLED_DIAMOND)`
  width: 14px;
  height: 16px;
  fill: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW)};
`;

export const SoonContainer = styled.div`
  flex: 50%;
  position: relative;
`;

export const LabelSoonPosition = styled.div`
  width: 100px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
`;

export const SoonLabel = styled.div`
  ${OverlineFont};
  text-align: center;
  transform: rotate(45deg);
  position: relative;
  padding: ${THEME.SPACING.TINY} 0;
  top: 20px;
  left: -10px;
  width: 155px;
  background-color: ${THEME.COLORS.PURE_WHITE};
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const StyledBaseRealizationMetrics = styled(BaseRealizationMetrics)`
  opacity: ${THEME.OPACITY.LOW};
`;
