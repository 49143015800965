import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AppRouting } from '../services/appRoutingResolver';
import { PaymentSuccessDialog } from '../components/dialogs/payment-success-dialog/payment-success-dialog.component';
import { PaymentSuccessDialogReferrer } from '../components/dialogs/payment-success-dialog/payment-success-dialog.enums';
import { useAnalyticsContext } from '../contexts/analytics/useAnalyticsContext';
import {
  ANALYTICS_EVENTS, COMPONENT_NAME, EVENT_GROUP, PRODUCT_AREA,
} from '../consts/analytics';
import { PAGE_NAMES } from '../consts/pages';
import { useUrlQuery } from './route';

export const useOnPaymentSuccess = (): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const pageQuery = useUrlQuery();
  const history = useHistory();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const paymentSessionId = pageQuery.get(AppRouting.URL_PARAMS.PAYMENT_SESSION_ID);
  const referrer = pageQuery.get(AppRouting.URL_PARAMS.REFERRER);

  useEffect(() => {
    if (isDialogOpen) {
      const eventData = {
        eventName: ANALYTICS_EVENTS.PREMIUM_MODAL_SHOWN,
        eventGroup: EVENT_GROUP.IMP,
        pageName: referrer === PaymentSuccessDialogReferrer.MatchDailyStatistics ? PAGE_NAMES.MATCH_LIMIT : PAGE_NAMES.HOME_PAGE,
        productArea: referrer === PaymentSuccessDialogReferrer.MatchDailyStatistics ? PRODUCT_AREA.MATCH_PAGE : PRODUCT_AREA.HOMEPAGE,
        componentName: COMPONENT_NAME.UPGRADED_SUCCESSFULLY,
      };
      analyticsTrackEvent(eventData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const goToPositions = () => {
    setIsDialogOpen(false);
    const eventData = {
      eventName: ANALYTICS_EVENTS.CLICKED_ON_PREMIUM_MODAL,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: referrer === PaymentSuccessDialogReferrer.MatchDailyStatistics ? PAGE_NAMES.MATCH_LIMIT : PAGE_NAMES.HOME_PAGE,
      productArea: referrer === PaymentSuccessDialogReferrer.MatchDailyStatistics ? PRODUCT_AREA.MATCH_PAGE : PRODUCT_AREA.HOMEPAGE,
      componentName: COMPONENT_NAME.GO_TO_MY_POSITIONS,
    };
    analyticsTrackEvent(eventData);
    history.push(AppRouting.buildHomePagePath());
  };

  if (paymentSessionId) {
    pageQuery.delete(AppRouting.URL_PARAMS.PAYMENT_SESSION_ID);
    setIsDialogOpen(true);
    history.replace({
      search: pageQuery.toString(),
    });
  }

  return (
    <PaymentSuccessDialog
      isDialogOpen={ isDialogOpen }
      onCloseDialog={ () => setIsDialogOpen(false) }
      goToHomepage={ goToPositions }
    />
  );
};
