export enum ConnectionScoreEnum {
  LowConnection = 'LOW_CONNECTION',
  MediumConnection = 'MEDIUM_CONNECTION',
  StrongConnection = 'STRONG_CONNECTION',
}

export const connectionScoreRank = new Map<ConnectionScoreEnum, number>([
  [ConnectionScoreEnum.LowConnection, 1],
  [ConnectionScoreEnum.MediumConnection, 2],
  [ConnectionScoreEnum.StrongConnection, 3],
]);
