import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../../../language/keys';
import {
  BannerContainer, ContextContainer, Description, ErrorIcon, Title, WarningIcon,
} from './validation-banner.styled';
import { BannerProps } from './validation-banner';
import { ValidationBannerEnum } from './validation-banner.enum';

const Banner = ({
  type,
}: BannerProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { title, description, Icon } = useMemo(() => {
    if (type === ValidationBannerEnum.Warning) {
      return {
        description: translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.VALIDATION_ERROR.TITLE),
        title: translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.VALIDATION_ERROR.DESCRIPTION),
        Icon: WarningIcon,
      };
    }

    return {
      description: translate(STRING_KEYS.TRY_AGAIN),
      title: translate(STRING_KEYS.GLOBAL_ERROR_MESSAGE),
      Icon: ErrorIcon,
    };
  }, [translate, type]);

  return (
    <BannerContainer type={ type }>
      <Icon />
      <ContextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </ContextContainer>
    </BannerContainer>
  );
};

export default Banner;
