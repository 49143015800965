import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import STRING_KEYS from '../../language/keys';
import PrimaryButton from '../Common/Buttons/PrimaryButton';
import { IndeterminateLinearLoader } from '../Common/Loaders/IndeterminateLinearLoader';
import { PageHeader } from '../Common/headers/page-header/page-header.component';
import { AppRouting } from '../../services/appRoutingResolver';
import { useMyUserSelector } from '../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { useUpdateUserMutation } from '../../store/rtk-query/apis/user/hooks/update-user.mutation-hook';
import { useGetMyAccountSelector } from '../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import { useAnalyticsContext } from '../../contexts/analytics/useAnalyticsContext';
import { ANALYTICS_EVENTS, EVENT_GROUP } from '../../consts/analytics';
import {
  PageContainer,
  Title,
  SubTitle,
  InputsWrapper,
  CustomInput,
  StartUsingPerfectWrapper,
  ValidationError,
  InputAndErrorWrapper,
  LoaderWrapper,
} from './styled';

const AccountActivationUserInfo = (): JSX.Element => {
  const history = useHistory();
  const { analyticsTrackEvent } = useAnalyticsContext();

  useEffect(() => {
    const payload = {
      eventName: ANALYTICS_EVENTS.SIGN_UP_DETAILS_PAGE_VIEWED,
      eventGroup: EVENT_GROUP.PAGE_VIEW,
    };
    analyticsTrackEvent(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: {
      firstName: firstNameFromServer,
      lastName,
    },
  } = useMyUserSelector();

  const [
    updateUser,
    {
      isLoading: isUpdateUserLoading, isError: isUpdateUserError, error: updateUserError, isSuccess: isUpdateUserSuccess,
    }] = useUpdateUserMutation();

  const firstName = firstNameFromServer?.includes('@') ? '' : firstNameFromServer;

  const [firstNameInput, setFirstNameInput] = useState<string>(firstName ?? '');
  const [lastNameInput, setLastNameInput] = useState<string>(lastName ?? '');
  const [userRoleInput, setUserRoleInput] = useState<string>('');

  const [localFirstNameError, setLocalFirstNameError] = useState<boolean>(false);
  const [localLastNameError, setLocalLastNameError] = useState<boolean>(false);
  const [localUserRoleError, setLocalUserRoleError] = useState<boolean>(false);

  const { data: { displayName: accountName } } = useGetMyAccountSelector();

  const { t: translate } = useTranslation();

  useEffect(() => {
    if (isUpdateUserSuccess) {
      AppRouting.navigateToUrl(AppRouting.buildGlobalLoginUrl(window.location));
    }
  }, [history, isUpdateUserSuccess]);

  const handleOnClick = async () => {
    let hasError = false;

    if (!firstNameInput) {
      setLocalFirstNameError(true);
      hasError = true;
    }

    if (!lastNameInput) {
      setLocalLastNameError(true);
      hasError = true;
    }

    if (!userRoleInput) {
      setLocalUserRoleError(true);
      hasError = true;
    }

    if (!hasError) {
      updateUser({ firstName: firstNameInput, lastName: lastNameInput, currentRole: userRoleInput });
    }
  };

  const handleFirstNameInputChange = (event: SyntheticEvent) => {
    const element = event.target as HTMLInputElement;

    // to do add validations
    if (element.value) {
      setLocalFirstNameError(false);
    }

    setFirstNameInput(element.value);
  };

  const handleLastNameInputChange = (event: SyntheticEvent) => {
    const element = event.target as HTMLInputElement;

    // to do add validations
    if (element.value) {
      setLocalLastNameError(false);
    }

    setLastNameInput(element.value);
  };

  const handleUserRoleInputChange = (event: SyntheticEvent) => {
    const element = event.target as HTMLInputElement;

    // to do add validations
    if (element.value) {
      setLocalUserRoleError(false);
    }

    setUserRoleInput(element.value);
  };

  return (
    <>
      {isUpdateUserLoading && (
        <LoaderWrapper>
          <IndeterminateLinearLoader />
        </LoaderWrapper>
      )}

      <PageHeader />

      <PageContainer>
        <Title>
          { translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.SIGN_UP_TITLE) }
        </Title>

        <SubTitle >
          { translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.SIGN_UP_SUBTITLE) }
        </SubTitle>

        <InputsWrapper>
          <InputAndErrorWrapper>
            <CustomInput
              value={ firstNameInput }
              onChange={ handleFirstNameInputChange }
              placeholder={ translate(STRING_KEYS.FIRST_NAME) }
              isError={ localFirstNameError }
            />
            {localFirstNameError && (
              <ValidationError>
                { translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.NAME_ERROR) }
              </ValidationError>
            )}
          </InputAndErrorWrapper>

          <InputAndErrorWrapper>
            <CustomInput
              value={ lastNameInput }
              onChange={ handleLastNameInputChange }
              placeholder={ translate(STRING_KEYS.LAST_NAME) }
              isError={ localLastNameError }
            />
            {localLastNameError && (
              <ValidationError>
                { translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.NAME_ERROR) }
              </ValidationError>
            )}
          </InputAndErrorWrapper>

          <InputAndErrorWrapper>
            <CustomInput
              onChange={ handleUserRoleInputChange }
              placeholder={
                translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.CURRENT_ROLE, {
                  companyName: accountName,
                })
              }
              isError={ localUserRoleError }
            />
            {(localUserRoleError) && (
              <ValidationError>
                { translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.ACCOUNT_NAME_ERROR) }
              </ValidationError>
            )}

            {isUpdateUserError && (
              <ValidationError>
                {/* TODO [refactor] check the error */}
                { updateUserError as string }
              </ValidationError>
            )}
          </InputAndErrorWrapper>

          <StartUsingPerfectWrapper>
            <PrimaryButton
              title={ translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.START_USING_PERFECT) }
              onClick={ handleOnClick }
              type="button"
            />
          </StartUsingPerfectWrapper>

        </InputsWrapper>

      </PageContainer>
    </>
  );
};

export default AccountActivationUserInfo;
