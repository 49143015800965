import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { StyledHeader } from '../../components/homepage-header/homepage-header.styled';
import { hexToRgbA } from '../../../../utils/colors';

export const USER_AVATAR_SIZE_PX = 40;

export const Container = styled.div`
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: ${THEME.SPACING.XGIANT};
`;

export const HeaderContainer = styled.div<{ isHeaderSticky?: boolean }>`
  & ${StyledHeader} {
    background-color: ${({ isHeaderSticky }) => isHeaderSticky ? hexToRgbA(THEME.COLORS.PURE_WHITE, THEME.OPACITY.MED) : undefined};
    backdrop-filter: ${({ isHeaderSticky }) => isHeaderSticky ? 'blur(20px)saturate(180%)' : undefined};
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PositionsContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: ${THEME.SPACING.MEDIUM};
`;

export const BodyWrapper = styled.div`
  width: ${THEME.BREAKPOINTS.WIDTH.SMALL}px;
`;
