import { ChangeEvent, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { THEME } from '../../../../themes';
import { POSITION_CLOSING_REASONS } from '../../../../consts';
import STRING_KEYS from '../../../../language/keys';
import { CustomButton } from '../../../Common/Buttons/Button/styled';
import { ASSETS } from '../../../../assets';
import {
  SomethingElseWrapper,
  SomethingElseReasonButton,
  ClosePositionButtonWrapper,
  ButtonIconStyle,
} from './styled';

type SomethingElseReasonProps = {
  freeText: string,
  onFreeTextChange: (e: ChangeEvent<HTMLInputElement>) => void,
  onSubmitPositionClose: (reasonId: string) => void,
}

function SomethingElseReason({
  freeText,
  onFreeTextChange,
  onSubmitPositionClose,
}: SomethingElseReasonProps): JSX.Element {
  const { t: translate } = useTranslation();

  const isClosePositionButtonDisabled = !freeText.trim();

  const onClick = (event: SyntheticEvent) => {
    event.stopPropagation();

    onSubmitPositionClose(POSITION_CLOSING_REASONS.OTHER);
  };

  return (
    <SomethingElseWrapper>
      <SomethingElseReasonButton
        autoFocus
        multiline
        variant="standard"
        maxRows={ 3 }
        placeholder={ translate(STRING_KEYS.CLOSE_POSITION.SOMETHING_ELSE_PLACEHOLDER) }
        value={ freeText }
        onChange={ onFreeTextChange }
      />
      <ClosePositionButtonWrapper>
        <CustomButton
          backgroundColor={ THEME.COLORS.ALERT }
          onClick={ onClick }
          disabled={ isClosePositionButtonDisabled }
        >
          <ASSETS.CLOSE_BASKET_COMPONENT style={ ButtonIconStyle } />
          {translate(STRING_KEYS.CLOSE_POSITION.BUTTON)}
        </CustomButton>
      </ClosePositionButtonWrapper>
    </SomethingElseWrapper>
  );
}

export default SomethingElseReason;
