import {
  IconSkeleton,
  Container,
  TitlesContainer,
  FirstLineTitle,
  SecondLine,
  ThirdLine,
  FourthLine,
  LineContainer,
  Title,
} from './education-information-skeleton.styled';

const EducationInformationSkeleton = (): JSX.Element => {
  return  (
    <Container>
      <Title />
      <LineContainer>
        <IconSkeleton />
        <TitlesContainer>
          <FirstLineTitle />
          <SecondLine />
          <ThirdLine />
          <FourthLine />
        </TitlesContainer>
      </LineContainer>
      <LineContainer>
        <IconSkeleton />
        <TitlesContainer>
          <FirstLineTitle />
          <SecondLine />
          <ThirdLine />
          <FourthLine />
        </TitlesContainer>
      </LineContainer>
    </Container>
  );
};

export default EducationInformationSkeleton;
