import { THEME } from '../../../themes';

type SIZES = 'small' | 'medium' | 'large' | 'micro';

const SIZE = {
  micro: '16px',
  small: '24px',
  medium: '30px',
  large: '54px',
};

type IconProps = {
    src: string,
    iconColor?: string,
    size: SIZES,
}

export const Icon =  ({ src, iconColor, size }: IconProps): JSX.Element => {
  return (
    <object
      type="image/svg+xml"
      data={ src }
      width={ SIZE[size] }
      height={ SIZE[size] }
      style={ { filter: iconColor } }
    >
      <img src={ src  } alt={ src } />
    </object>
  );
};

Icon.defaultProps = {
  iconColor: THEME.FILTERS.BLACK_FILTER,
};
