import styled from 'styled-components';
import { CaptionFont } from '../../../../../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../../../../../utils/colors';
import { THEME } from '../../../../../../../../../themes';
import { TextUnderlineStyle } from '../explainability-widget-attributes.styled';

export const SimilarAttributeOrigin = styled.span`
  ${CaptionFont};
  color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.MED)};
  ${TextUnderlineStyle};
  margin-right: ${THEME.SPACING.MICRO};
  cursor: pointer;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;
