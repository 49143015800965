import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useOnboardingLetsBegin } from '../../../../../store/slices/onboarding-state/selectors/onboarding-selectors.hooks';
import STRING_KEYS from '../../../../../language/keys';
import {
  addPositionLocation,
  removePositionLocation,
} from '../../../../../store/slices/create-position-state/create-position-state.toolkit-slice';
import { hasRemoteOption } from '../../../../../utils/location';
import { LocationsContainer } from './location-sentence-container.styled';
import { LocationSentence } from './location-sentence.component';

type LocationSentenceContainerProps = {
  stepNumber: number,
  onSentenceCompleted: () => void,
  onSentenceInProgress: () => void,
};

const LocationSentenceContainer = ({
  stepNumber,
  onSentenceCompleted,
  onSentenceInProgress,
}: LocationSentenceContainerProps): JSX.Element => {
  const dispatch = useDispatch();

  const {
    locations: positionLocations,
  } = useOnboardingLetsBegin();

  useEffect(() => {
    const isEachLocationValid = positionLocations.length && positionLocations.every((location) => !isEmpty(location));

    if (!isEachLocationValid) {
      onSentenceInProgress();
    } else if (positionLocations.length) {
      onSentenceCompleted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionLocations]);

  const onAddAnotherLocationButtonClick = () => {
    dispatch(addPositionLocation());
  };

  const onRemoveLocationButtonClick = (locationIndex: number) => {
    dispatch(removePositionLocation(locationIndex));
  };

  const isRemote = useMemo(() => hasRemoteOption(positionLocations), [positionLocations]);

  return (
    <LocationsContainer>
      { Array.from(Array(positionLocations.length || 1).keys()).map((index) => (
        <LocationSentence
          key={ `position_location_${index}` }
          textKey={ index === 0 ? STRING_KEYS.IN : STRING_KEYS.OR }
          stepNumber={ stepNumber }
          onSentenceCompleted={ onSentenceCompleted }
          onAddAnotherLocation={ onAddAnotherLocationButtonClick }
          showAddAnotherLocationButton={ !isRemote && (index + 1 === positionLocations.length)  }
          onRemoveLocation={ positionLocations.length > 1 ? () => onRemoveLocationButtonClick(index) : undefined }
          locationIndex={ index }
          showRemoteOptions={ positionLocations.length <= 1 }
        />
      )) }
    </LocationsContainer>
  );
};

export default LocationSentenceContainer;
