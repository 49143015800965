import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { THEME } from '../../../../themes';

export const LabelSkeleton = styled(Skeleton)`
  width: 150px;
  height: 15px;
  display: flex;
`;

export const RowContainer = styled.div`
  display:flex;
  flex-direction: row;
`;

export const ColumnContainer = styled.div`
  display:flex;
  flex-direction: column;
`;

export const CompanyNameLine = styled(LabelSkeleton)`
  width: 74px;
  height: 24px;
  margin-bottom: -10px;
  border-radius: ${THEME.RADIUS.CIRCLE};
`;

export const CompanyLocationLine = styled(LabelSkeleton)`
  width: 262px;
  height: 14px;
  margin-top: ${THEME.SPACING.TINY};
  border-radius: ${THEME.RADIUS.CIRCLE};
`;

export const CompanyDescriptionLine = styled(LabelSkeleton)`
  width: 470px;
  height: 14px;
  margin-left: ${THEME.SPACING.GIANT};
  border-radius: ${THEME.RADIUS.CIRCLE};
  margin-bottom: -${THEME.SPACING.MICRO};
`;

export const IconSkeleton = styled(Skeleton)`
  width: 64px;
  height: 64px;
  margin: 0 ${THEME.SPACING.MEDIUM} 0 0;
  border-radius: ${THEME.RADIUS.BASE};
  display: flex;
`;
export const TitlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${THEME.SPACING.BASE};
`;

export const IndustriesSkeleton = styled(Skeleton)`
  width: 100px;
  height: 24px;
  margin-right: ${THEME.SPACING.BASE};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-radius: ${THEME.RADIUS.ROUND};
  background-color: ${THEME.COLORS.PURE_WHITE};
  border: solid 1px rgba(34, 26, 93, 0.12);
  margin-bottom: ${THEME.SPACING.BASE};
`;

export const CompanyParamSkeleton = styled(Skeleton)`
  width: 100px;
  height: 16px;
  margin-bottom: ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.CIRCLE};
`;
