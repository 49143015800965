import { PositionState } from '../../../../enums/position-state.enum';
import { PositionCardProps } from './position-card';
import OpenPositionCard from './open-position-card/open-position-card.component';
import ClosedPositionCard from './closed-position-card/closed-position-card.component';

const PositionCard = ({
  position,
  closePositionHandler,
  atsInitializationHandler,
  positionIndex,
}: PositionCardProps): JSX.Element => {
  if (position.state === PositionState.Open) {
    return (
      <OpenPositionCard
        position={ position }
        closePositionHandler={ closePositionHandler }
        atsInitializationHandler={ atsInitializationHandler }
        positionIndex={ positionIndex }
      />
    );
  }

  return <ClosedPositionCard position={ position } />;
};
export default PositionCard;
