import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { Scrollbar } from '../../../../../themes/scrollbar';

export const Container = styled.div`
  background-color: ${THEME.COLORS.PURE_WHITE};
  border-radius: ${THEME.RADIUS.CURVY};
  overflow: hidden;
`;

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${Scrollbar};
`;
