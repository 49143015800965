import { useSelector } from 'react-redux';
import { CreatePositionLevelOfExperience } from '../../../../modules/create-position/@types/create-position-selectors';
import { RootReducerState } from '../../../index';

export const useCreatePositionLevelsOfExperienceSelector = (): CreatePositionLevelOfExperience => {
  return useSelector(({ createPosition }: RootReducerState) => {
    return {
      selectedLevelsOfExperience: createPosition.selectedLevelsOfExperience,
    };
  });
};
