import GuidanceModal from '../guidance-modal.component';
import { DoDontGuidanceModalProps } from './do-dont-guidance-modal';
import { Container } from './do-dont-guidance-modal.styled';
import DoDontCard from './do-dont-card/do-dont-card.component';

const DoDontGuidanceModal = ({
  isOpen,
  closeHandler,
  title,
  data,
}: DoDontGuidanceModalProps): JSX.Element => {
  return (
    <GuidanceModal
      isOpen={ isOpen }
      closeHandler={ closeHandler }
      title={ title }
      widthSize="800px"
    >
      <Container>
        { data.map((cardProps) => (
          <DoDontCard
            key={ cardProps.title }
            type={ cardProps.type }
            quote={ cardProps.quote }
            title={ cardProps.title }
            text={ cardProps.text }
          />
        )) }
      </Container>
    </GuidanceModal>
  );
};

export default DoDontGuidanceModal;
