import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppLoader } from '../Common/Loaders/AppLoader';
import { prepareSubDomainData } from '../../store/slices/app-state/app-state.toolkit-slice';

export const SplashScreen = ():JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(prepareSubDomainData());
  }, [dispatch]);

  return <AppLoader title="Splash" />;
};
