import { useTranslation } from 'react-i18next';
import { MouseEvent, useRef, useState } from 'react';
import BaseMenu from '../../../../components/Common/perfect-base-menu/perfect-base-menu.component';
import STRING_KEYS from '../../../../language/keys';
import { ASSETS } from '../../../../assets';
import { PositionCardSettingsProps } from './position-card-settings';
import {
  ActionButtonWrapper, AtsConnectedText, AtsMenuIcon, ExportMatchesIcon, FilterMenuItem,
} from './position-card-settings.styled';
import PositionSettingsItem from './position-settings-item/position-settings-item.component';

const PositionCardSettings = ({
  ActionButton,
  closePositionHandler,
  atsInitializationHandler,
  isAtsIntegrated,
  exportMatchesHandler,
}: PositionCardSettingsProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const anchorElementRef = useRef<HTMLDivElement>(null);
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);

  const onMenuButtonClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    anchorElement ? setAnchorElement(null) : setAnchorElement(anchorElementRef?.current);
  };

  const onMenuClose = () => setAnchorElement(null);

  const onAtsInitializationButtonClick = () => {
    atsInitializationHandler();
    onMenuClose();
  };

  const onClickClosePosition = () => {
    closePositionHandler();
    onMenuClose();
  };

  const onClickExportMatches = () => {
    exportMatchesHandler();
    onMenuClose();
  };

  return (
    <>
      <ActionButtonWrapper
        ref={ anchorElementRef }
        onClick={ (event) => onMenuButtonClick(event) }
      >
        { ActionButton }
      </ActionButtonWrapper>
      <BaseMenu
        isOpen={ !!anchorElement }
        onClose={ onMenuClose }
        anchorElement={ anchorElement || undefined }
        placement="bottom-start"
      >
        <FilterMenuItem onClick={ onAtsInitializationButtonClick }>
          <AtsMenuIcon />
          <>
            {
              isAtsIntegrated ?
                <>{ translate(STRING_KEYS.ATS_MANAGE_POSITION_LINK_MENU_ITEM) }<AtsConnectedText>{ translate(STRING_KEYS.CONNECTED) }</AtsConnectedText></> :
                translate(STRING_KEYS.ATS_LINK_POSITION_MENU_ITEM)
            }
          </>
        </FilterMenuItem>
        <PositionSettingsItem
          onClick={ onClickExportMatches }
          icon={ <ExportMatchesIcon /> }
          text={ translate(STRING_KEYS.EXPORT_MATCHES.ACTION) }
        />
        <PositionSettingsItem
          onClick={ onClickClosePosition }
          icon={ <ASSETS.CLOSE_BASKET_COMPONENT /> }
          text={ translate(STRING_KEYS.CLOSE_POSITION.ACTION) }
        />
      </BaseMenu>
    </>
  );
};
export default PositionCardSettings;
