export const KEYBOARD_KEYS = {
  ENTER: 'Enter',
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
  BACKSPACE: 'Backspace',
};

export enum Keymap {
  ArrowLeft = 37,
  ArrowDown = 40,
  ArrowUp = 38,
  Escape = 27,
}
