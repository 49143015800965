import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';
import { Body2Font, CaptionFont, HeadlineFont } from '../../../../themes/fonts';

export const Container = styled.div`
  width: 320px;
  height: 221px;
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINO};
  padding: ${THEME.SPACING.BASE};
`;

export const Title = styled.div`
  ${HeadlineFont};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const Description = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;

export const ShapesIcon = styled(ASSETS.SHAPES)`
  height: 32px;
  width: 32px;
`;

export const ALink = styled.a`
  margin-left: 4px;
  text-decoration-line: underline;
`;

export const Label = styled.div`
  color: ${THEME.COLORS.ACCENT};
  ${Body2Font};
  font-weight: 600;
  padding-left: 12.6px;
  padding-top: 2px;
`;

export const SwitchAndLabel = styled.div`
  display: flex;
  flex-direction: row;
`;
