/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import KEYS from '../../language/keys';
import { EVENT_GROUP, PRODUCT_AREA } from '../../consts/analytics';
import { useAnalyticsContext } from '../../contexts/analytics/useAnalyticsContext';
import { AnalyticsProvider } from '../../contexts/analytics';
import { AppRouting } from '../../services/appRoutingResolver';
import { CONFIG } from '../../config';
import { INTERNAL_SERVER_ERROR_CODES } from '../../consts/api';
import { PAGE_NAMES } from '../../consts/pages';
import { AlertSnackBar } from '../../components/Common/CustomSnackBar/AlertSnackBar';
import { useGetMyAccountSelector } from '../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import { useCreatePositionStoreMetadataSelector } from '../../store/slices/create-position-state/selectors/create-position.selector';
import { resetError } from '../../store/slices/create-position-state/create-position-state.toolkit-slice';
import { CREATE_POSITION_ANALYTICS_EVENTS } from './consts/position-analytics.consts';
import CreatePositionDialog from './components/create-position-dialog/create-position-dialog.component';

type CreatePositionsProps = {
  isCreatePositionDialogOpen: boolean,
  closeHandler: () => void;
}

function CreatePosition({
  isCreatePositionDialogOpen,
  closeHandler,
}: CreatePositionsProps): JSX.Element {
  const { t: translate } = useTranslation();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const dispatch = useDispatch();
  const { error, errorCode } = useCreatePositionStoreMetadataSelector();
  const { data: { plan } } = useGetMyAccountSelector();

  useEffect(() => {
    if (error) {
      const eventData = {
        eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_FAILED,
        eventGroup: EVENT_GROUP.CLICK,
        pageName: PAGE_NAMES.HOME_PAGE,
        productArea: `${PRODUCT_AREA.ADD_POSITION}/${PRODUCT_AREA.HOMEPAGE}`,
        positionQuota: plan.maximumOpenPositions || 0,
        failReason: error || 'position quota',
      };

      analyticsTrackEvent(eventData);
    }
  }, [error]);

  const onSnackBarActionClick = () => {
    dispatch(resetError());
    if (CONFIG.BILLING_URL) {
      AppRouting.openInNewTab(CONFIG.BILLING_URL);
    }
  };

  const getSnackTitle = (): string => {
    if (errorCode === INTERNAL_SERVER_ERROR_CODES.POSITION_INTERNAL_ERROR_CODE_QUOTA) {
      return translate(KEYS.CREATE_POSITION.ERRORS.TITLE_QUOTA);
    }

    if (errorCode === INTERNAL_SERVER_ERROR_CODES.ACCOUNT_EXPIRED_ERROR_CODE) {
      return translate(KEYS.CREATE_POSITION.ERRORS.TITLE_EXPIRED);
    }

    return translate(KEYS.CREATE_POSITION.ERRORS.TITLE);
  };

  const getSnackActionTitle = (): string | undefined => {
    if (errorCode === INTERNAL_SERVER_ERROR_CODES.POSITION_INTERNAL_ERROR_CODE_QUOTA) {
      return translate(KEYS.CREATE_POSITION.ERRORS.ACTION_TITLE);
    }

    return undefined;
  };

  const getSnackActionDescription = (): string | undefined => {
    if (errorCode === INTERNAL_SERVER_ERROR_CODES.POSITION_INTERNAL_ERROR_CODE_QUOTA) {
      return translate(KEYS.CREATE_POSITION.ERRORS.DESCRIPTION);
    }

    return undefined;
  };

  const onAlertClose = () => {
    dispatch(resetError());
  };

  return (
    <>
      <AnalyticsProvider productArea={ PRODUCT_AREA.ADD_POSITION }>
        <CreatePositionDialog
          isOpen={ isCreatePositionDialogOpen }
          closeHandler={ closeHandler }
        />
        <AlertSnackBar
          shouldAutoDismiss
          isOpen={ !!error }
          title={ getSnackTitle() }
          actionTitle={ getSnackActionTitle() }
          description={  getSnackActionDescription() }
          onActionClick={ onSnackBarActionClick }
          onClose={ onAlertClose }
        />
      </AnalyticsProvider>
    </>
  );
}

export default CreatePosition;
