import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { Title2Font } from '../../../../themes/fonts';

export const VerificationRect = styled.input.attrs({
  maxLength: 1,
})`
  ${Title2Font};
  line-height: 1.38;
  font-weight: bold;
  color: ${THEME.COLORS.ACCENT};
  width: 64px;
  height: 64px;
  border-radius: ${THEME.RADIUS.ROUNDISH};
  background-color: ${THEME.COLORS.LIGHT_GREY};
  border: 1px solid ${THEME.COLORS_RGB.PRIMARY_GHOST};
  margin-right: ${THEME.SPACING.TINY};
  caret-color: ${THEME.COLORS.ACCENT};
  text-align: center;

  :focus {
    outline: none;
  }
`;
