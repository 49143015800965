import styled from 'styled-components';
import { ASSETS } from '../../../../../../../assets';
import { THEME } from '../../../../../../../themes';
import BaseButton
  from '../../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { StartIconContainer } from '../../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.styled';

export const LocationsContainer = styled.div`
  width: 650px;
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const AddLocationButton = styled(BaseButton)`
  background-color: transparent;
  color: ${THEME.COLORS.PRIMARY_DARK};
  padding-left: 0;
  margin-top: ${THEME.SPACING.BASE};

  & ${StartIconContainer} {
    margin-left: 0;
  }
`;

export const AddLocationButtonIcon = styled(ASSETS.FILLED_PLUS)`
  color: ${THEME.COLORS.ACCENT};
  height: 25px;
`;

export const LocationAutocompleteLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
`;
