import { PRIVACY_CLASS } from '../../../consts/fullstory';
import { StyledAvatar } from './styled';

export const InitialsAvatar = ({
  message,
  alt,
  fontSize,
}: {
    alt: string,
    message: string,
    fontSize: string,
  }): JSX.Element => {
  return (
    <StyledAvatar
      className={ PRIVACY_CLASS.EXCLUDE }
      alt={ alt }
      title={ alt }
      style={ { fontSize } }
    >
      { message }
    </StyledAvatar>
  );
};
