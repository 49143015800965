import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { Badge } from '../../../badge/badge.component';
import { PastExperienceCompanyItem } from '../../../../@types/smartBadges';

const MAX_COMPANIES_TO_SHOW = 3;

type CompaniesBadgeProps = {
  companiesList: PastExperienceCompanyItem[],
}

export const CompaniesBadge = ({
  companiesList,
}:CompaniesBadgeProps):JSX.Element => {
  const { t: translate } = useTranslation();
  const getCompaniesDescription = () => {
    if (companiesList.length > MAX_COMPANIES_TO_SHOW) {
      return translate(STRING_KEYS.WORKED_AT_MULTI, {
        companies: companiesList
          .slice(0, MAX_COMPANIES_TO_SHOW)
          .map((companyItem) => companyItem.companyName)
          .join(', '),
      });
    }

    return translate(STRING_KEYS.WORKED_AT, {
      companies: companiesList.map((companyItem) => companyItem.companyName)
        .join(', '),
    });
  };

  return (
    <Badge
      title={ translate(STRING_KEYS.EXPERIENCE_BADGE_TITLE) }
      description={ getCompaniesDescription() }
      iconsUrls={ companiesList.map((companyItem) => companyItem.companyLogo) }
    />
  );
};
