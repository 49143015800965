import styled  from 'styled-components';
import { THEME } from '../../../../../themes';
import { CaptionFont, HeadlineFont, OverlineFont } from '../../../../../themes/fonts';
import Meter from './meter/meter.component';

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

export const StyledMeter = styled(Meter)`
  margin-top: ${THEME.SPACING.BASE};
  margin-bottom: ${THEME.SPACING.TINY};
`;

export const Desc = styled.div`
  ${CaptionFont};
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  margin-bottom: ${THEME.SPACING.MICRO};
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const LearnWhyButton = styled.button`
  all: unset;
  ${OverlineFont};
  line-height: 1.6;
  letter-spacing: 0.33px;
  font-weight: 600;
  color: ${THEME.COLORS.PRIMARY};
  display: flex;
  gap: 5px;
  fill: ${THEME.COLORS.PRIMARY};
  cursor: pointer;
`;

const Title = styled.div`
 ${HeadlineFont};
 color:${THEME.COLORS.CONTENT_PRIMARY};
`;

export const  SkillsTitle = styled(Title)`
  font-weight: bold;
`;

export const  Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.div`
  opacity: ${THEME.OPACITY.LOW};
  ${OverlineFont};
  color: ${THEME.COLORS.CONTENT_PRIMARY};
`;
