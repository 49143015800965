import styled, { css } from 'styled-components';
import Chip from '../../../../../../Common/perfect-chip/perfect-chip.component';
import { THEME } from '../../../../../../../themes';
import { scaleUpCenterAnimation } from '../../../../../../../animations';

export const Skill = styled.div<{
    index: number,
    isEnableAnimation: boolean,
  }>`
    animation: ${({ isEnableAnimation, index }) => {
    return isEnableAnimation ?
      css`${scaleUpCenterAnimation} .4s cubic-bezier(0.175, 0.885, 0.320, 1.275) 
    ${(index + 1) * 10}ms both` : 'none';
  }};
`;

export const SkillChip = styled(Chip)<{ askedByUser: boolean }>`
  background-color: ${({ askedByUser }) => askedByUser ? THEME.COLORS.ACCENT : THEME.COLORS.PRIMARY_DARK};
`;
