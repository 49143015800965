import { Trans } from 'react-i18next';
import STRING_KEYS from '../../../../../../../language/keys';
import { ChildWidget } from '../child-widget/child-widget.component';
import { WidgetIcon } from './company-layoff-talent.styled';

type CompanyLayoffTalentProps = {
  companyName: string;
}

export const CompanyLayoffTalent = ({ companyName }:CompanyLayoffTalentProps):JSX.Element => {
  return (
    <ChildWidget
      description={ <Trans i18nKey={ STRING_KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.CHILD_WIDGETS.LAYOFF_TALENT.DESCRIPTION } values={ { companyName } } /> }
      icon={ <WidgetIcon /> }
    />
  );
};
