import { AnyAction, CaseReducer } from '@reduxjs/toolkit';
import { OnboardingState } from '../onboarding-state.initial-state';

export const loadingOnboardingPendingExtraReducer: CaseReducer<OnboardingState, AnyAction> = (state) => {
  state.isOnboardingLoading = true;
};

export const loadOnboardingRejectedExtraReducer: CaseReducer<OnboardingState, AnyAction> = (state, { payload: { error } }) => {
  state.isOnboardingLoading = false;
  state.showOnboardingSplashScreen = false;
  state.onboardingLoadingError = error?.error?.serverError?.message;
};

export const loadOnboardingFulfilledExtraReducer: CaseReducer<OnboardingState, AnyAction> = (state, { payload }) => {
  state.isOnboardingLoading = false;
  state.step = payload.step;
};
