import styled from 'styled-components';
import { THEME } from '../../../../../../themes';

export const WidgetWrapper = styled.div`
  &:not(:first-child) {
    :before {
      content: '';
      display: block;
      width: 100%;
      opacity: ${THEME.OPACITY.GHOSTLY};
      background-color: ${THEME.COLORS.PRIMARY};
      margin: ${THEME.SPACING.BASE} 0px;
      height: 1px;
    }
  }
`;
