/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import log from 'loglevel';
import STRING_KEYS from '../../../language/keys';
import { CircularProgress } from '../../../components/Common/circular-loader/circular-progress.component';
import { THEME } from '../../../themes';
import {
  INITIAL_STATIC_TITLE,
  LOADER_DURATION_MS,
  LOADER_INTERVAL_TIME_MS,
  PROGRESS_STEP,
  STEP_DESCRIPTION_TIMES_MS,
  STILL_WORKING_TITLE,
} from './matching-progress-loader.consts';
import { Icon } from './matching-progress-loader.styled';

const logger = log.getLogger('MATCHING_PROGRESS_LOADER');

export const MatchingProgressLoader = ({
  positionId, positionTriggeredAt, onUpdateProgress, onUpdateFooter,
}: MatchingProgressLoaderProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const progressRef = useRef(STEP_DESCRIPTION_TIMES_MS.STEP1);
  const [progress, setProgress] = useState(STEP_DESCRIPTION_TIMES_MS.STEP1);
  const [progressStep, setProgressStep] = useState(PROGRESS_STEP.RUNNING);

  const [title, setTitle] = useState<string | JSX.Element>(INITIAL_STATIC_TITLE);
  const [descriptionTranslationKey, setDescriptionTranslationKey] = useState(STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_PROGRESS.TIME_LEFT);
  const [footerTextTranslationKey, setFooterTextTranslationKey] = useState(STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.ANALYZING);

  useEffect(() => {
    let currentTimeDescriptionTranslationKey = STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_PROGRESS.TIME_LEFT;
    let currentFooterTextTranslationKey = STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.ANALYZING;
    let currentProgressStep = PROGRESS_STEP.RUNNING;

    if (progressRef.current <= STEP_DESCRIPTION_TIMES_MS.STEP1 && progressRef.current > STEP_DESCRIPTION_TIMES_MS.STEP2) {
      currentFooterTextTranslationKey = STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.ANALYZING;
    } else if (progressRef.current < STEP_DESCRIPTION_TIMES_MS.STEP2 && progressRef.current > STEP_DESCRIPTION_TIMES_MS.STEP3) {
      currentFooterTextTranslationKey = STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.LEARNING;
    } else if (progressRef.current < STEP_DESCRIPTION_TIMES_MS.STEP3 && progressRef.current > STEP_DESCRIPTION_TIMES_MS.STEP4) {
      currentFooterTextTranslationKey = STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.EXTRACTING;
    } else if (progressRef.current < STEP_DESCRIPTION_TIMES_MS.STEP4 && progressRef.current > STEP_DESCRIPTION_TIMES_MS.STEP6) {
      currentFooterTextTranslationKey = STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.CHERRY_PICKING;
    } else if (progressRef.current < STEP_DESCRIPTION_TIMES_MS.STEP6 && progressRef.current > STEP_DESCRIPTION_TIMES_MS.STEP7) {
      currentFooterTextTranslationKey = STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.ALMOST_THERE;
      currentTimeDescriptionTranslationKey = STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_PROGRESS.FINALIZING;
      currentProgressStep = PROGRESS_STEP.EXTENDED_WAIT_TIME;
    } else if (progressRef.current < STEP_DESCRIPTION_TIMES_MS.STEP7) {
      currentProgressStep = PROGRESS_STEP.ERROR_OR_TOO_LONG;
      setTitle(<Icon />);
      currentTimeDescriptionTranslationKey = STRING_KEYS.MATCHING_PROGRESS_PAGE.MATCHING_PROGRESS.CHECK_LATER;
    }

    if (descriptionTranslationKey !== currentTimeDescriptionTranslationKey) {
      setDescriptionTranslationKey(currentTimeDescriptionTranslationKey);
    }

    if (footerTextTranslationKey !== currentFooterTextTranslationKey) {
      setFooterTextTranslationKey(currentFooterTextTranslationKey);
      onUpdateFooter(currentFooterTextTranslationKey);
    }

    if (currentProgressStep !== progressStep) {
      setProgressStep(currentProgressStep);
      onUpdateProgress(currentProgressStep);
    }
  }, [progress]);

  useEffect(() => {
    if (!positionTriggeredAt) {
      return undefined;
    }

    const initialTime = new Date(positionTriggeredAt || '').getTime();
    const timeNow = new Date().getTime();
    const progressDiff = LOADER_DURATION_MS - (timeNow - initialTime);
    const progressDiffMin = Math.min(progressDiff, LOADER_DURATION_MS);
    progressRef.current = progressDiffMin;

    if (progressDiffMin > LOADER_DURATION_MS) {
      logger.warn('Current matching progress loader is above max loader duration', {
        positionId, progressDiffMin, LOADER_DURATION_MS, initialTime, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    }

    if (progressRef?.current <= 0) {
      setTitle(STILL_WORKING_TITLE);
      setProgress(0);

      return undefined;
    }

    setProgress(progressRef?.current);

    const intervalId = setInterval(() => {
      progressRef.current -= LOADER_INTERVAL_TIME_MS;

      if (progressRef?.current <= 0) {
        clearInterval(intervalId);
        setTitle(STILL_WORKING_TITLE);
        setProgress(0);

        return;
      }

      setProgress(progressRef?.current);
    }, LOADER_INTERVAL_TIME_MS);

    return () => {
      clearInterval(intervalId);
    };
  }, [positionTriggeredAt]);

  const calculateProgress = () => {
    if (!positionTriggeredAt) {
      return 0;
    }

    const progressValue = 100 - (progress / LOADER_DURATION_MS) * 100;

    return Math.min(progressValue, 93);
  };

  const translatedDescription = useMemo(() => {
    return translate(descriptionTranslationKey);
  }, [descriptionTranslationKey]);

  return (
    <CircularProgress
      progress={ calculateProgress() }
      title={ title }
      description={ translatedDescription }
      color={ progressStep === PROGRESS_STEP.ERROR_OR_TOO_LONG ? THEME.COLORS.PRIMARY_DARK : undefined }
    />
  );
};
