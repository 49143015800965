/* eslint-disable react-hooks/exhaustive-deps,@typescript-eslint/no-unused-vars */
// TODO [refactor] eslint-disable for temporary devs deployments
import { Switch, Route, Redirect } from 'react-router-dom';
import log from 'loglevel';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { AppRouting } from '../../services/appRoutingResolver';
import { setPositionsLoadingCompleted } from '../../store/slices/app-state/app-state.toolkit-slice';
import AccountActivationUserInfo from '../AccountActivationUserInfo';
import { AppLoader } from '../Common/Loaders/AppLoader';
import { useAppState } from '../../store/selectors';
import { INITIAL_LOADING } from '../../store/slices/app-state/app-state.consts';

// TODO [refactor] implement
// const logger = log.getLogger('COMPLETE_USER_ACTIVATION_ROUTER');

const CompleteUserActivationRouter = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { initialLoadings, showSplashScreen } = useAppState();

  useEffect(() => {
    if (initialLoadings.includes(INITIAL_LOADING.LOAD_POSITIONS)) {
      dispatch(setPositionsLoadingCompleted());
    }
  }, []);

  if (showSplashScreen) {
    return null;
  }

  return (
    <Switch>
      <Route path={ AppRouting.ROUTES.USER_INFO } exact>
        <AccountActivationUserInfo />
      </Route>
      <Route path={ AppRouting.ROUTES.ACTIVATION_COMPLETED } exact>
        {/* special route to navigate from activation flow to home screen */}
        <AppLoader title="Activation Completed" />
      </Route>
      <Route path="*">
        <Redirect to={ { pathname: AppRouting.ROUTES.USER_INFO } } push />
      </Route>
    </Switch>
  );
};

export default CompleteUserActivationRouter;
