import styled from 'styled-components';
import { Autocomplete } from '../../../../../../components/Common/create-position-autocomplete/autocomplete.component';
import { ASSETS } from '../../../../../../assets';
import { THEME } from '../../../../../../themes';

export const CustomAutocomplete = styled(Autocomplete)`
  font-size: 24px;
  
  & .input-wrapper {
    height: 32px;
  }
`;

export const InputIcon = styled(ASSETS.PENCIL)`
  width: 24px;
  height: 24px;
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  opacity: ${THEME.OPACITY.LOW};
`;
