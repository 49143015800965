import styled from 'styled-components';
import IconButton from '../../../../Buttons/icon-button/icon-button.component';
import { THEME } from '../../../../../../themes';
import { ASSETS } from '../../../../../../assets';

export const TokensIcon = styled(ASSETS.TOKENS)`
  width: 20px;
  height: 20px;
`;
export const Button = styled(IconButton)`
  color: ${THEME.COLORS.PRIMARY_DARK};
`;
