import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdatePositionUserRolesMutation } from '../../../../store/rtk-query/apis/position/hooks/update-position-user-roles.mutation-hook';
import UserPicker from '../../../../components/Common/user-picker/user-picker.component';
import STRING_KEYS from '../../../../language/keys';
import { UserPickersContainerProps } from './user-pickers-container';
import { Container } from './user-pickers-container.styled';

const UserPickersContainer = ({
  position,
  openInviteUsersModalHandler,
  showAddUserIcon,
  maxDisplayAvatars,
}: UserPickersContainerProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [updatePositionUserRoles] = useUpdatePositionUserRolesMutation();

  const onRecruitersChange = (selectedIds: string[]) => {
    updatePositionUserRoles({ positionId: position.id, recruiters: selectedIds.map((userId) => ({ userId })) });
  };

  const onHiringManagersChange = (selectedIds: string[]) => {
    updatePositionUserRoles({ positionId: position.id, hiringManagers: selectedIds.map((userId) => ({ userId })) });
  };

  const userPickerRecruiters = useMemo(() => position?.recruiters?.map((user) => user.userId) || [], [position?.recruiters]);
  const userPickerHiringManagers = useMemo(() => position?.hiringManagers?.map((user) => user.userId) || [], [position?.hiringManagers]);

  return (
    <Container>
      <UserPicker
        label={ translate(STRING_KEYS.USER_PICKER.RECRUITER) }
        menuTitle={ translate(STRING_KEYS.USER_PICKER.RECRUITER_MENU_TITLE) }
        selectedUsers={ userPickerRecruiters }
        changeHandler={ onRecruitersChange }
        openInviteUsersModalHandler={ openInviteUsersModalHandler }
        showAddUserIcon={ showAddUserIcon }
        maxDisplayAvatars={ maxDisplayAvatars }
      />
      <UserPicker
        label={ translate(STRING_KEYS.USER_PICKER.HIRING_MANAGER) }
        menuTitle={ translate(STRING_KEYS.USER_PICKER.HIRING_MANAGER_MENU_TITLE) }
        selectedUsers={ userPickerHiringManagers }
        changeHandler={ onHiringManagersChange }
        openInviteUsersModalHandler={ openInviteUsersModalHandler }
        showAddUserIcon={ showAddUserIcon }
        maxDisplayAvatars={ maxDisplayAvatars }
      />
    </Container>
  );
};

export default UserPickersContainer;
