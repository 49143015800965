import { css } from 'styled-components';

export const drawerElevation = css`
  box-shadow: 0 4px 24px 0 rgb(46 37 128 / 8%);
`;

export const surfaceBaseElevation = css`
  box-shadow: none;
`;

export const raisedElevation = css`
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.08);
`;

export const overlayElevation = css`
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
`;

export const lowElevation = css`
  box-shadow: 0 4px 12px 0 rgba(0, 0, 64, 0.12);
`;

export const stickyElevation = css`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
`;

export const selectionElevation = css`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
`;

export const temporaryElevation = css`
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
`;

export const popoutElevation = css`
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.08);
`;

export const menuElevation = css`
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
`;

export const ghostElevation = css`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
`;
