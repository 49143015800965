import styled, { css, keyframes } from 'styled-components';
import { THEME } from '../../../../themes';
import { fadeInAnimation } from '../../../../animations';
import {
  HeadlineFont,
  Body1Font,
  Subtitle1Font,
} from '../../../../themes/fonts';
import { VERIFICATION_SCREEN_DELAY } from '../../../../consts/delays';
import { ELEMENTS_DURATION } from '../../../../consts/durations';

const shakeHorizontal = keyframes`
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
`;

export const PageDescription = styled.div`
  ${HeadlineFont};
  font-weight: 500;
  white-space: pre-wrap;
  line-height: 1.5;
  opacity: ${THEME.OPACITY.MED};
  margin-top: 50px;
`;

export const CodeEmailedTitle = styled.div`
  ${HeadlineFont};
  font-weight: 600;

  margin-top: ${THEME.SPACING.XLOOSE};
`;

export const VerificationRectContainer = styled.div<{animationToggle: boolean}>`
  margin-top: ${THEME.SPACING.XLOOSE};

  animation: ${({ animationToggle }) => (animationToggle ?
    css`${shakeHorizontal} 0.7s cubic-bezier(0.175, 0.885, 0.320, 1.275) both` : '')} ;

  ${({ animationToggle }) => animationToggle ? `
  input {
    border: 2px solid ${THEME.COLORS.ALERT};
  }
  ` : null}
`;

export const HelperTextWrapper = styled.div`
  margin-top: ${THEME.SPACING.TINY};
  min-height: 100px;
`;

export const ErrorText = styled.div`
  ${Body1Font};
  font-weight: 500;
  color: ${THEME.COLORS.ALERT};
`;

export const HumanHelpTextWrapper = styled.div`
  display: block;
  margin-top: ${THEME.SPACING.MICRO};
`;

export const ResendCode = styled.a`
  ${Subtitle1Font};
  text-decoration: underline;
  cursor: pointer;
  color: ${THEME.COLORS.ACCENT};
  display: inline-block;
  margin-top: ${THEME.SPACING.XLOOSE};
`;

export const LinkToMail = styled.a`
  ${Subtitle1Font};
  text-decoration: underline;
  cursor: pointer;
  color: ${THEME.COLORS.ACCENT};
  display: inline-block;
`;

export const NoLuckText = styled.div`
  ${Subtitle1Font};
  margin-right: ${THEME.SPACING.TINO};
  opacity: ${THEME.OPACITY.MED};
  display: inline-block;
`;

export const SendingCodeText = styled.div`
  ${Subtitle1Font};
  line-height: 1.5;
  margin-top: ${THEME.SPACING.XLOOSE};
  margin-right: ${THEME.SPACING.TINY};
  white-space: pre-line;
`;

export const BottomContentWrapper = styled.div`
  position: relative;
`;

export const TransitionWrapper = styled.div`
   animation: ${fadeInAnimation({
    animationDuration: ELEMENTS_DURATION.VERIFICATION_SCREEN_CONTENT_DURATION,
    animationDelay: VERIFICATION_SCREEN_DELAY,
  })};`;

export const BackButtonWrapper = styled.div`
  width: 56px;
  height: 56px;
  position: absolute;
  top: 28px;
  left: 117px;
`;
