import { useCreatePositionBasicInfoSelector } from '../../../store/slices/create-position-state/selectors/create-position-basic-info.selector';
import { useCreatePositionEducationSelector } from '../../../store/slices/create-position-state/selectors/create-position-education.selector';
import { usePositionBackgroundSelector } from '../../../store/slices/create-position-state/selectors/create-position-background.selector';
import { useCreatePositionJobDescription } from '../../../store/slices/create-position-state/selectors/create-position-job-description.selector';
import { getUserInputsJobRequirement } from '../utils/create-position-job-requerments.util';
import {
  useCreatePositionLevelsOfExperienceSelector,
} from '../../../store/slices/create-position-state/selectors/create-position-level-experience.selector';
import { CreatePositionRequest } from '../../../store/rtk-query/apis/position/dto/request/create-position.request';
import { createLocationObject, setDefaultMileRadius } from '../../../utils/location';

export const useCreatePositionBuildPayload = (): CreatePositionRequest => {
  const basicInfo = useCreatePositionBasicInfoSelector();
  const education = useCreatePositionEducationSelector();
  const { companyBackground, skills } = usePositionBackgroundSelector();
  const jobDescription = useCreatePositionJobDescription();
  const { selectedLevelsOfExperience } = useCreatePositionLevelsOfExperienceSelector();

  const degrees = education.degrees || [];
  const fieldsOfStudy = getUserInputsJobRequirement(education.userInputFieldsOfStudy) || [];
  const customCompanyBackground = getUserInputsJobRequirement(companyBackground) || [];
  const userInputSkills = getUserInputsJobRequirement(skills) || [];
  const locations = createLocationObject(basicInfo.locations);
  locations.exactLocations = setDefaultMileRadius(locations.exactLocations);

  return {
    jobTitle: basicInfo.jobTitle,
    locations: basicInfo.isRemote ? undefined : locations,
    remotePosition: basicInfo.isRemote,
    jobDescriptionTempUrls: [jobDescription.jobDescriptionTempUrls, jobDescription.jobDescriptionS3TempUrls],
    degrees: degrees || undefined,
    fieldOfStudies: fieldsOfStudy,
    levelsOfExperience: selectedLevelsOfExperience,
    companyBackground: customCompanyBackground,
    skills: userInputSkills,
  };
};
