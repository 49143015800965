import { useEffect, useState } from 'react';
import BuyPositionDialog from './buy-position-dialog/buy-position-dialog.coponent';
import { PositionAddedDialog } from './position-added-dialog/position-added-dialog.component';

const BuyPositionDialogs = ({ isOpen, closeHandler, createPositionHandler }: BuyPositionDialogsProps): JSX.Element => {
  const [isBuyPositionDialogOpen, setIsBuyPositionDialogOpen] = useState(false);
  const [isPositionAddedSuccessfullyDialogOpen, setIsPositionAddedSuccessfullyDialogOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (isPositionAddedSuccessfullyDialogOpen || isBuyPositionDialogOpen) {
        return;
      }

      setIsBuyPositionDialogOpen(true);
    } else {
      setIsBuyPositionDialogOpen(false);
      setIsPositionAddedSuccessfullyDialogOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const buyPositionSuccessHandler = () => {
    setIsBuyPositionDialogOpen(false);
    setIsPositionAddedSuccessfullyDialogOpen(true);
  };

  const closeDialogs = () => {
    setIsBuyPositionDialogOpen(false);
    setIsPositionAddedSuccessfullyDialogOpen(false);
    closeHandler();
  };

  const createPositionButtonClickHandler = () => {
    createPositionHandler();
  };

  return (
    <>
      {
        isBuyPositionDialogOpen && (
          <BuyPositionDialog
            isDialogOpen={ isBuyPositionDialogOpen }
            closeButtonHandler={ closeDialogs }
            buyPositionSuccessHandler={ buyPositionSuccessHandler }
          />
        )
      }
      {
        isPositionAddedSuccessfullyDialogOpen && (
          <PositionAddedDialog
            isDialogOpen={ isPositionAddedSuccessfullyDialogOpen }
            closeHandler={ closeDialogs }
            createPositionButtonHandler={ createPositionButtonClickHandler }
          />
        )
      }
    </>
  );
};

export default BuyPositionDialogs;
