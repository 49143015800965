import { useTranslation } from 'react-i18next';
import InsightsCard from '../base-insights-card/insights-card.component';
import { InsightsCardDescription, InsightsCardTitle } from '../base-insights-card/insights-card.styled';
import { THEME } from '../../../../../../themes';
import STRING_KEYS from '../../../../../../language/keys';
import {
  DiamondIcon,
} from './screening-rate-insight-card.styled';

const ScreeningRateInsightCard = ({
  numerator,
  denominator,
}: ScreeningRateInsightCardProps):JSX.Element => {
  const { t: translate } = useTranslation();

  const InsightsTitle: JSX.Element = (
    <>
      <DiamondIcon />
      <InsightsCardTitle
        color={ THEME.COLORS.SECONDARY }
      >
        { translate(STRING_KEYS.DAILY_LIMIT.INSIGHTS.SCREENING_RATE_TITLE) }
      </InsightsCardTitle>
    </>
  );

  const InsightsDescription: JSX.Element = (
    <InsightsCardDescription>
      { translate(STRING_KEYS.DAILY_LIMIT.INSIGHTS.SCREENING_RATE_DESCRIPTION) }
    </InsightsCardDescription>
  );
  const formattedDenominator = denominator >= 10000 ? '>10K' : denominator;

  return (
    <InsightsCard
      title={ InsightsTitle }
      insightValueBold={ numerator }
      insightValue={ `/${formattedDenominator}` }
    >
      { InsightsDescription }
    </InsightsCard>
  );
};

export default ScreeningRateInsightCard;
