import styled from 'styled-components';
import ExplainabilityInfoMenu from '../explainability-info-menu.component';
import { THEME } from '../../../../../themes';
import { SectionText } from '../explainability-info-menu.styled';
import { Body2Font } from '../../../../../themes/fonts';

export const Container = styled(ExplainabilityInfoMenu)`
  background-image: linear-gradient(to bottom, rgba(126, 39, 228, 0.05), rgba(126, 39, 228, 0));
`;

export const SimilarExplanation = styled(SectionText)`
  ${Body2Font};
  line-height: normal;
  margin-top: ${THEME.SPACING.BASE};
`;
