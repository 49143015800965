import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import STRING_KEYS from '../../../../../language/keys';
import BasePositionAssistant from '../base-position-assistant/base-position-assistant.component';
import { PositionAssistantMilestoneEnum } from '../../../../../enums/position-assistant-milestone.enum';
import {
  PerfectButtonVariants,
} from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { ANIMATED_FLUENT_UI_EMOJIS } from '../../../../../assets';
import Confetti from '../../../../../components/Common/confetti/confetti.component';
import { ConfettiWrapper, MilestoneCelebrationsContainer } from './milestone-celebrations.styled';
import { MilestoneCelebrationsProps } from './milestone-celebrations';

const PartyPopperImg = () => (
  <img
    src={ ANIMATED_FLUENT_UI_EMOJIS.PARTY_POPPER }
    alt="Party Popper"
    width="112"
    height="112"
  />
);

const MilestoneCelebrations = ({
  variant,
}: MilestoneCelebrationsProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [celebrate, setCelebrate] = useState(false);
  const { titleTranslationKey, descriptionTranslationKey } = useMemo(() => {
    let title;
    let description;
    switch (variant) {
      case PositionAssistantMilestoneEnum.ReviewedCandidateMilestone: {
        title = STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.MILESTONE_CELEBRATION.REVIEWED_100_MATCHES.TITLE;
        description = STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.MILESTONE_CELEBRATION.REVIEWED_100_MATCHES.DESCRIPTION;
        break;
      }
      case PositionAssistantMilestoneEnum.AddedCandidateMilestone: {
        title = STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.MILESTONE_CELEBRATION.ADDED_50_TO_OUTREACH.TITLE;
        description = STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.MILESTONE_CELEBRATION.ADDED_50_TO_OUTREACH.DESCRIPTION;
        break;
      }
      case PositionAssistantMilestoneEnum.FirstReply: {
        title = STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.MILESTONE_CELEBRATION.FIRST_REPLAY.TITLE;
        description = STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.MILESTONE_CELEBRATION.FIRST_REPLAY.DESCRIPTION;
        break;
      }
      default: {
        title = STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.MILESTONE_CELEBRATION.FIRST_REPLAY.TITLE;
        description = STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.MILESTONE_CELEBRATION.FIRST_REPLAY.DESCRIPTION;
      }
    }

    return {
      titleTranslationKey: title,
      descriptionTranslationKey: description,
    };
  }, [variant]);

  const onCelebrateButtonClick = () => {
    if (celebrate) {
      return;
    }

    setCelebrate(true);
    setTimeout(() => setCelebrate(false), 3000);
  };

  return (
    <MilestoneCelebrationsContainer>
      <BasePositionAssistant
        title={ translate(titleTranslationKey) }
        description={ translate(descriptionTranslationKey) }
        Icon={ PartyPopperImg }
        action={ {
          title: translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.MILESTONE_CELEBRATION.ACTION_TITLE),
          handler: onCelebrateButtonClick,
          showIcon: false,
          variant: PerfectButtonVariants.Outline,
        } }
      />
      <ConfettiWrapper isVisible={ celebrate }>
        <Confetti />
      </ConfettiWrapper>
    </MilestoneCelebrationsContainer>
  );
};

export default MilestoneCelebrations;
