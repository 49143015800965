import React from 'react';
import { Wrapper } from './step-screen-animated-wrapper.styled';

type StepScreenAnimatedWrapperProps = {
  children: React.ReactNode,
}

export const StepScreenAnimatedWrapper = ({
  children,
}: StepScreenAnimatedWrapperProps): JSX.Element => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
};
