import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { CaptionFont, HeadlineFont, Subtitle2Font } from '../../../../themes/fonts';
import { hexToRgbA } from '../../../../utils/colors';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { HEADER_HEIGHT } from '../../../../components/Common/headers/header/header.styled';
import { Scrollbar } from '../../../../themes/scrollbar';
import SidePanelHeader from '../../../../components/Common/side-panel/side-panel-header/side-panel-header.component';
import { CONVERSATION_HEADER_HEIGHT } from '../outreach/conversation-header/conversation-header.styled';
import { PREFERENCES_BODY_HEADER_HEIGHT_PX } from '../outreach/preferences-body-header/preferences-body-header.styled';
import { ASSETS } from '../../../../assets';
import { BaseButtonProps } from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button';

export const SIDE_PANEL_WIDTH_MEDIUM = 360;
export const SIDE_PANEL_BANNER_HEIGHT = 56;

export const SidePanelContainer = styled.div`
  overflow: hidden;
  width: ${SIDE_PANEL_WIDTH_MEDIUM}px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const SidePanelHeaderContainer = styled(SidePanelHeader)`
  padding: ${THEME.SPACING.LOOSE} ${THEME.SPACING.MEDIUM};
  height: ${CONVERSATION_HEADER_HEIGHT};
  border-bottom: solid 1px ${THEME.COLORS_RGB.PRIMARY_GHOST};
  margin-bottom: 0;
`;

export const Header = styled.div`
  ${HeadlineFont};
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const PreviewContainer = styled.div`
  padding-top: ${THEME.SPACING.TINO};
  ${Scrollbar};
  height: calc(100vh - ${HEADER_HEIGHT}px - ${PREFERENCES_BODY_HEADER_HEIGHT_PX}px - ${SIDE_PANEL_BANNER_HEIGHT}px - ${THEME.SPACING.XGIANT});
`;

export const Banner = styled.div`
  ${CaptionFont};
  height: ${SIDE_PANEL_BANNER_HEIGHT}px;
  width: auto;
  color: ${THEME.COLORS.CONTENT_A};
  background-color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.GHOST)};
  display: flex;
  align-items: center;
  padding: 0 ${THEME.SPACING.MEDIUM};
  flex-shrink: 0;
`;

export const TypingTextContainer = styled.div`
  ${Subtitle2Font};
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: ${THEME.COLORS.PRIMARY_DARK};
  padding: 0 ${THEME.SPACING.MEDIUM};
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding-bottom: ${THEME.SPACING.BASE};
  height: calc(100vh - ${HEADER_HEIGHT}px - ${PREFERENCES_BODY_HEADER_HEIGHT_PX}px - ${SIDE_PANEL_BANNER_HEIGHT}px - ${THEME.SPACING.TINY});
`;

export const RegenerateButton = styled(BaseButton)<BaseButtonProps & { isMessageLoading?: boolean }>`
  height: 48px;
  background-color: ${hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.DIVIDER)};;
  background-color: ${({ isMessageLoading }) => isMessageLoading ? 'transparent' : hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.DIVIDER)};
  font-weight: 600;
  color: ${THEME.COLORS.SECONDARY};
  margin-top: ${THEME.SPACING.TINY};
`;

export const MessageContentIcon = styled(ASSETS.MESSAGE_CONTENT)`
  width: 18px;
  height: 16px;
  fill: ${THEME.COLORS.SECONDARY};
`;

export const RegenerationIcon = styled(ASSETS.REGENERATION)`
  width: 21px;
  height: 18px;
  fill: ${THEME.COLORS.SECONDARY};
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
