export enum PerfectButtonVariants {
  Fill = 'FILL',
  Outline = 'OUTLINE',
  Ghost = 'GHOST',
  Link = 'LINK',
}

export enum PerfectButtonHoverVariants {
  Darkened = 'DARKENED',
  Grow = 'GROW',
  Underline = 'UNDERLINE',
  NONE = 'NONE',
}

export enum PerfectButtonSize {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',
  ExtraLarge = 'EXTRA_LARGE',
}
