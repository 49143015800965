import { CaseReducer } from '@reduxjs/toolkit';
import { AppState } from '../app-state.initial-state';
import { removeFromArray } from '../../../../utils';
import { INITIAL_LOADING } from '../app-state.consts';

export const removeInitialLoadingReducer: CaseReducer<AppState> = (state) => {
  state.initialLoadings = [];
};

export const setRudderstackLoadingCompletedReducer: CaseReducer<AppState> = (state) => {
  state.initialLoadings = removeFromArray(state.initialLoadings, INITIAL_LOADING.LOAD_RUDDERSTACK);
};

export const setFronteggLoadingCompletedReducer: CaseReducer<AppState> = (state) => {
  state.initialLoadings = removeFromArray(state.initialLoadings, INITIAL_LOADING.LOAD_FRONTEGG);
};

export const setPositionsLoadingCompletedReducer: CaseReducer<AppState> = (state) => {
  state.initialLoadings = removeFromArray(state.initialLoadings, INITIAL_LOADING.LOAD_POSITIONS);
};
