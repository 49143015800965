import styled from 'styled-components';
import { Scrollbar } from '../../../../../../../themes/scrollbar';
import { THEME } from '../../../../../../../themes';
import { CaptionFont } from '../../../../../../../themes/fonts';
import ConversationItem from '../../../conversation-item/conversation-item.component';

export const ConversationItemsContainer = styled.div`
  overflow: hidden;
  padding-top: ${THEME.SPACING.MEDIUM};
  background-color: ${THEME.COLORS.PURE_WHITE};
  border-radius: ${THEME.RADIUS.CURVY};
  height: inherit;
`;

export const ScrollWrapper = styled.div`
  ${Scrollbar};
  height: calc(100% - ${THEME.SPACING.MEDIUM} - 20px);
`;

export const GroupContainer = styled.div`
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.MEDIUM};
  overflow: hidden;
`;

export const GroupTitle = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  margin-bottom: ${THEME.SPACING.TINO};
`;

export const StyleItem = styled(ConversationItem)`
  margin-left: -${THEME.SPACING.TINY};
  margin-right: -${THEME.SPACING.TINO};
  padding-left: ${THEME.SPACING.TINO};
`;

export const TitleWrapper = styled.div`
  padding-left: ${THEME.SPACING.MEDIUM};
  padding-right: ${THEME.SPACING.TINY};
`;
