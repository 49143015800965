import styled from 'styled-components';
import { THEME } from '../../../../themes';
import {
  Subtitle1Font,
} from '../../../../themes/fonts';
import { Scrollbar } from '../../../../themes/scrollbar';

export const Container = styled.div`
  display: block;
  text-align: left;
  position: relative;
  margin-top: ${THEME.SPACING.MEDIUM};
`;

export const ColleaguesListWrapper = styled.div<{ showOnHover?: boolean }>`
  margin-top: ${THEME.SPACING.TINY};
  height: 210px;
  ${Scrollbar};
`;

export const MyTeammates = styled.div`
  ${Subtitle1Font};
  opacity: ${THEME.OPACITY.MED};
  text-align: left;
  margin-bottom: ${THEME.SPACING.MICRO};
`;

export const WrapperLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
