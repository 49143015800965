import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootReducerState } from '../index';
import { SubDomainType, UserIdentifyMethod } from '../slices/app-state/app-state.enums';
import { AppSnackbarDto } from '../slices/app-state/dto/app-snackbar.dto';
/**
 * App State
 */

export const useAppSelector: TypedUseSelectorHook<RootReducerState> = useSelector;

type AppStateSelector = {
  showSplashScreen: boolean,
  subDomainType: SubDomainType,
  fatalErrorCode: string,
  fatalErrorMessage: string,
  fatalErrorInfo: unknown,
  loginState: LOGIN_STATE,
  isLoggingOutFromRemoteTab: boolean,
  isOnboardingLogout: boolean,
  initialLoadings: Array<string>,
  userIdentifyMethod: UserIdentifyMethod,
  currentPageId: string,
  referrerPageId: string,
  connectedSocket: {
    talentAcquisition: boolean,
  },
  snackbar: AppSnackbarDto | undefined,
  latestAppVersion: string,
  isFaviconFlashing: boolean,
}

export const useAppState = (): AppStateSelector => {
  return useSelector(({ appReducer }: RootReducerState) => {
    return {
      showSplashScreen: appReducer.initialLoadings.length > 0,
      initialLoadings: appReducer.initialLoadings,
      subDomainType: appReducer.subDomainType,
      fatalErrorCode: appReducer.fatalErrorCode,
      fatalErrorMessage: appReducer.fatalErrorMessage,
      fatalErrorInfo: appReducer.fatalErrorInfo,
      loginState: appReducer.loginState,
      isLoggingOutFromRemoteTab: appReducer.isLoggingOutFromRemoteTab,
      isOnboardingLogout: appReducer.isOnboardingLogout,
      userIdentifyMethod: appReducer.identifyMethod,
      currentPageId: appReducer.currentPageId,
      referrerPageId: appReducer.referrerPageId,
      connectedSocket: {
        talentAcquisition: appReducer.sockets.talentAcquisition.connected,
      },
      snackbar: appReducer.snackbar,
      latestAppVersion: appReducer.latestAppVersion,
      isFaviconFlashing: appReducer.isFaviconFlashing,
    };
  });
};

export const useShouldRetryFetchAllowedCompanies = (): boolean => {
  return useSelector((state: RootReducerState) => {
    return state.onboardingReducer.shouldRetryFetchAllowedCompanies;
  });
};
