import { Trans, useTranslation } from 'react-i18next';
import { ChangeEvent, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import STRING_KEYS from '../../../../../language/keys';
import OutreachContentPreferencesSection
  from '../../../pages/outreach-content-preferences/outreach-content-preferences-section/outreach-content-preferences-section.component';
import MessageItem from '../input-items/message-item/message-item.component';
import TextInputItem from '../input-items/text-input-item/text-input-item.component';
import { OutreachContentPreferencesFormikKeys } from '../../../pages/outreach-content-preferences/outreach-content-preferences.enums';
import PositionLocation from '../../../../../components/Common/position-location/position-location.component';
import { useGetPositionByIdQuery } from '../../../../../store/rtk-query/apis/position/hooks/get-position-by-id.query-hook';
import { Section, SectionsContainer } from './role-section.styled';
import { RoleSectionProps } from './role-section';

const RoleSection = ({
  preferredJobTitle,
  preferredPositionLocation,
  aboutTheRole,
  onChangeHandler,
}: RoleSectionProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { positionId } = useParams<MatchUrlParams>();
  const { data: position } = useGetPositionByIdQuery(positionId);

  const onRoleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.PreferredJobTitle, e.currentTarget.value);
  };

  const onAboutRoleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.AboutTheRole, e.target.value);
  };

  const onRoleLocationChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.PreferredPositionLocation, e.currentTarget.value);
  };

  const aboutTheRoleTitle = useMemo(() => (
    <Trans
      i18nKey={ STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.INPUTS.ABOUT_ROLE.TITLE }
      values={ { positionName: position.jobTitle } }
    >
      <PositionLocation remotePosition={ !!position.remotePosition } locations={ position.locations } />
    </Trans>
  ), [position]);

  return (
    <OutreachContentPreferencesSection
      title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.TITLE) }
    >
      <SectionsContainer>
        <Section>
          <TextInputItem
            value={ preferredJobTitle }
            changeHandler={ onRoleNameChange }
            title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.INPUTS.ROLE_NAME.TITLE) }
            description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.INPUTS.ROLE_NAME.DESCRIPTION) }
            placeholder={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.INPUTS.ROLE_NAME.PLACEHOLDER) }
            fullWidth
          />
          <MessageItem
            message={ aboutTheRole }
            messageChangeHandler={ onAboutRoleChange }
            title={ aboutTheRoleTitle }
            description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.INPUTS.ABOUT_ROLE.DESCRIPTION) }
          />
          <TextInputItem
            value={ preferredPositionLocation }
            changeHandler={ onRoleLocationChange }
            title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.INPUTS.ROLE_LOCATION.TITLE) }
            description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.INPUTS.ROLE_LOCATION.DESCRIPTION) }
            placeholder={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.INPUTS.ROLE_LOCATION.PLACEHOLDER) }
            fullWidth
          />
        </Section>
      </SectionsContainer>
    </OutreachContentPreferencesSection>
  );
};

export default RoleSection;
