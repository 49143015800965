import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import STRING_KEYS from '../../../../language/keys';
import { PageTitleBold } from '../create-position-container/create-position-container.styled';
import { CreatePositionContainer } from '../create-position-container/create-position-container.component';
import { useCreatePositionEducationSelector } from '../../../../store/slices/create-position-state/selectors/create-position-education.selector';
import { useGetStepInfoComponent } from '../../hooks/create-position-actions.hooks';
import { setDegrees, setUserInputFieldOfStudy } from '../../../../store/slices/create-position-state/create-position-state.toolkit-slice';
import { CREATE_POSITION_INPUT_ID } from '../../consts/input-ids.consts';
import DegreesContainer from '../../../../components/degrees-container/degrees-container.component';
import { LevelOfDegreeEnum } from '../../../../enums/level-of-degree';
import MultiSentenceInputsContainer from '../multi-sentence-inputs/multi-sentence-inputs-container.component';
import {
  Container,
  DegreesIcon,
  FieldsOfStudyIcon,
  IconWrapper,
  Section,
  SectionTitle,
  SectionTitleContainer,
  SectionTitleWrapper,
} from './create-position-education-step.styled';

export const CreatePositionEducationStep = ({
  setContinueButtonEnable,
  setContinueButtonDisable,
  isOnboarding,
}: CreatePositionEducationProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const InfoComponent = useGetStepInfoComponent();
  const { degrees = [], userInputFieldsOfStudy = [] } = useCreatePositionEducationSelector();

  useEffect(() => {
    const hasFieldOfStudy = userInputFieldsOfStudy.some((fieldOfStudy) => !!fieldOfStudy.title);

    if (hasFieldOfStudy || degrees.length) {
      dispatch(setContinueButtonEnable());
    } else {
      dispatch(setContinueButtonDisable());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInputFieldsOfStudy, degrees]);

  const onDegreesChange = (degreesChangeData: Set<LevelOfDegreeEnum>) => {
    if (degreesChangeData) {
      dispatch(setDegrees(Array.from(degreesChangeData)));
    }
  };

  const setUserInputFieldsOfStudy = (fieldsOfStudy: PositionJobRequirementState[]) => {
    dispatch(setUserInputFieldOfStudy(fieldsOfStudy));
  };

  const getPageTitle = () => (
    <Trans
      i18nKey={ STRING_KEYS.ONBOARDING.ONBOARDING_EDUCATION_PAGE_TITLE }
    >
      <PageTitleBold />
    </Trans>
  );

  const onAddFieldOfStudyClick = () => {
    dispatch(setUserInputFieldOfStudy([...userInputFieldsOfStudy, { title: '' }]));
  };

  return (
    <>
      <CreatePositionContainer
        title={ getPageTitle() }
        info={ InfoComponent }
        isOnboarding={ isOnboarding }
      >
        <Container>
          <Section>
            <SectionTitleContainer>
              <SectionTitleWrapper>
                <IconWrapper>
                  <DegreesIcon />
                </IconWrapper>
                <SectionTitle>{ translate(STRING_KEYS.ONBOARDING.EDUCATION_STEP.DEGREE_TITLE) }</SectionTitle>
              </SectionTitleWrapper>
            </SectionTitleContainer>
            <DegreesContainer
              selectedDegrees={ new Set(degrees) }
              changeHandler={ onDegreesChange }
            />
          </Section>
          <Section disableGap>
            <SectionTitleContainer>
              <SectionTitleWrapper>
                <IconWrapper>
                  <FieldsOfStudyIcon />
                </IconWrapper>
                <SectionTitle>{ translate(STRING_KEYS.ONBOARDING.EDUCATION_STEP.FIELDS_OF_STUDY_TITLE) }</SectionTitle>
              </SectionTitleWrapper>
            </SectionTitleContainer>
            <div>
              <MultiSentenceInputsContainer
                inputElementId={ CREATE_POSITION_INPUT_ID.FIELD_OF_STUDY }
                values={ userInputFieldsOfStudy }
                placeholder={ translate(STRING_KEYS.ONBOARDING.EDUCATION_STEP.FIELDS_OF_STUDY_INPUT_PLACEHOLDER) }
                addHandler={ onAddFieldOfStudyClick }
                changeHandler={ setUserInputFieldsOfStudy }
                showInstructionMessage={ false }
                autoFocus
                skipItemTitle={ translate(STRING_KEYS.ONBOARDING.BACKGROUND_STEP.COMPANY_BACKGROUND_SECTION.BLANK_INPUT_TEXT) }
              />
            </div>
          </Section>
        </Container>
      </CreatePositionContainer>
    </>
  );
};
