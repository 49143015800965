import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import { PositionAutocomplete } from '../../autocompletes/position-autocomplete/position-autocomplete.component';
import { Sentence } from '../../../../../components/Common/sentence-component/sentence.component';

type LookingRoleSentenceProps = {
  onSentenceCompleted: () => void,
  onPositionChange: () => void,
  stepNumber: number,
}

export const LookingRoleSentence = ({
  onSentenceCompleted,
  onPositionChange,
  stepNumber,
}: LookingRoleSentenceProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Sentence
      text={ translate(STRING_KEYS.ONBOARDING.ONBOARDING_JOBTITLE_SENTENCE) }
      Item={ (
        <PositionAutocomplete
          stepNumber={ stepNumber }
          onSentenceCompleted={ onSentenceCompleted }
          onPositionChange={ onPositionChange }
        />
      ) }
    />
  );
};
