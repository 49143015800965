import { Skeleton } from '@mui/material';
import { SkeletonHeader, SkeletonTextContainer } from './conversation-header-skeleton.styled';

const ConversationHeaderSkeleton = (): JSX.Element => {
  return (
    <SkeletonHeader>
      <Skeleton animation="wave" variant="circular" width={ 40 } height={ 40 } />
      <SkeletonTextContainer>
        <Skeleton animation="wave" width={ 210 } height={ 30 } />
        <Skeleton animation="wave" width={ 210 } />
      </SkeletonTextContainer>
    </SkeletonHeader>
  );
};

export default ConversationHeaderSkeleton;
