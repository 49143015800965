import styled from 'styled-components';
import { ASSETS } from '../../../../../assets';
import { THEME } from '../../../../../themes';
import Select from '../../../../../components/Common/select/select.component';

export const Container = styled.div`
  
`;

export const Icon = styled(ASSETS.SCHEDULE_MEETING)`
  height: 24px;
`;

export const ActionComponent = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINY};
`;

export const DayTypeSelect = styled(Select)`
  width: 170px;
`;
