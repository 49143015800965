import styled from 'styled-components';
import { ASSETS } from '../../../assets';
import { THEME } from '../../../themes';
import { Body1Font, HeadlineFont } from '../../../themes/fonts';

export const MessageWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0; 
  left: 0; 
  position: fixed;
  background-image: url(${ASSETS.BACKGROUND_IMAGE_TABLET_LAND});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media only screen and (max-width: 830px){
    background-image: url(${ASSETS.BACKGROUND_IMAGE_TABLET});
  }
  @media only screen and (max-width: 480px){
    background-image: url(${ASSETS.BACKGROUND_IMAGE_MOBILE});
  }
`;

export const MessageSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: ${THEME.SPACING.GIANT};
  padding-top: ${THEME.SPACING.GIANT};
  @media only screen and (max-width: 480px){
    padding-left: ${THEME.SPACING.MEDIUM};
    padding-top: ${THEME.SPACING.LOOSE};
  }
`;

export const LogoWrapper = styled.div`
  width: auto;
  margin-bottom: ${THEME.SPACING.XLOOSE};
`;

export const Text1 = styled.div`
  ${Body1Font};
  color:${THEME.COLORS.PURE_WHITE};
`;

export const Text2 = styled.div`
  ${HeadlineFont};
  margin-top: ${THEME.SPACING.TINY};
  color:${THEME.COLORS.PURE_WHITE};
`;

export const Text3 = styled(Text1)`
  padding-right: ${THEME.SPACING.TINY};
  margin-top: ${THEME.SPACING.XLOOSE};
`;
