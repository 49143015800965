import { CONFIG } from '../../../config';

export const SupportLink = (): JSX.Element => {
  return (
    <a
      href={ `mailto:${CONFIG.SUPPORT_MAIL_ADDRESS}` }
      target="_blank"
      rel="noopener noreferrer"
    >
      {CONFIG.SUPPORT_MAIL_ADDRESS}
    </a>
  );
};
