import { useTranslation } from 'react-i18next';
import { ASSETS } from '../../../../../../../assets';
import {
  TimelineBoldText,
  TimelineContainerItemLogo,
  TimelineContainerItemLogoWrapper,
  TimelineDurationText,
  TimelineRegularText,
} from '../../timeline.styled';
import { createTimelineYearsRange, YearsRangeType } from '../../timeline.utils';
import { AvatarVariants } from '../../../../../../../components/Common/avatar-with-fallback/avatar-variant.enum';
import STRING_KEYS from '../../../../../../../language/keys';
import {
  Container, EducationRowContainer, EducationTitleContainer,
} from './education-container-item.styled';

const EducationContainerItem = ({ educationItem }: EducationContainerItemProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const hasOnlyInstitutionName = !educationItem.title && !educationItem.fieldOfStudy;

  const renderLogo = () => {
    return (
      <TimelineContainerItemLogoWrapper>
        <TimelineContainerItemLogo
          logo={ educationItem.institutionLogo }
          title={ educationItem.institutionName }
          fallbackImage={ ASSETS.EDUCATION_FALLBACK }
          variant={ AvatarVariants.Circular }
        />
      </TimelineContainerItemLogoWrapper>
    );
  };

  const renderTitleAndFieldOfStudy = () => {
    if (hasOnlyInstitutionName) {
      return null;
    }

    if (!educationItem.degree) {
      return <TimelineRegularText>{ educationItem.fieldOfStudy }</TimelineRegularText>;
    }

    if (!educationItem.fieldOfStudy) {
      return <TimelineBoldText>{ educationItem.degree }</TimelineBoldText>;
    }

    return <TimelineBoldText>{ educationItem.degree }, <TimelineRegularText>{ educationItem.fieldOfStudy }</TimelineRegularText></TimelineBoldText>;
  };

  const renderInstitution = () => {
    return (
      <TimelineBoldText isSmall={ !hasOnlyInstitutionName }>
        { !hasOnlyInstitutionName ? translate(STRING_KEYS.AT) : '' } { educationItem.institutionName }
      </TimelineBoldText>
    );
  };

  const renderText = () => {
    return (
      <EducationRowContainer>
        <TimelineDurationText>
          { createTimelineYearsRange(educationItem.startDateObject, educationItem.endDateObject, YearsRangeType.Range) }
        </TimelineDurationText>
        <EducationTitleContainer>{ renderTitleAndFieldOfStudy() }</EducationTitleContainer>
        { renderInstitution() }
      </EducationRowContainer>
    );
  };

  return (
    <Container>
      { renderLogo() }
      { renderText() }
    </Container>
  );
};

export default EducationContainerItem;
