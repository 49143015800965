import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { hexToRgbA } from '../../../../../utils/colors';
import { Body2Font, CaptionFont, Subtitle1Font } from '../../../../../themes/fonts';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
  background-color: ${hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.GHOST)};
  border: 1px solid ${hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.DIVIDER)};
  border-radius: ${THEME.SPACING.TINO};
  padding: ${THEME.SPACING.BASE};
`;

export const Label = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.SECONDARY};
  font-weight: 600;
`;

export const Title = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const Description = styled.div`
  ${Body2Font};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const LearnMoreLink = styled.a`
  ${Body2Font};
  text-decoration: underline;
  color: ${THEME.COLORS.CONTENT_A};
  line-height: normal;
  cursor: pointer;
  width: fit-content;
`;
