import styled from 'styled-components';
import IconButton from '../../../../../../components/Common/Buttons/icon-button/icon-button.component';
import { THEME } from '../../../../../../themes';
import { ASSETS, SOCIAL_ICONS } from '../../../../../../assets';
import BaseMenu from '../../../../../../components/Common/perfect-base-menu/perfect-base-menu.component';
import { ButtonFont, CaptionFont, HeadlineFont } from '../../../../../../themes/fonts';
import MenuItem from '../../../../../../components/Common/perfect-base-menu/menu-item/menu-item.component';
import { IconButtonProps } from '../../../../../../components/Common/Buttons/icon-button/icon-button';

export const StyledMenu = styled(BaseMenu)`
  max-width: 380px;
`;

export const StyledMenuItem = styled(MenuItem)`
  ${ButtonFont};
  display: flex;
  color: ${THEME.COLORS.ACCENT};
  align-items: center;
  max-width: unset;
  gap: ${THEME.SPACING.TINY};
`;

export const MenuHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${THEME.COLORS.CONTENT_A};
  gap: ${THEME.SPACING.TINO};
  padding: ${THEME.SPACING.TINO} 0;
`;

export const MenuTitle = styled.div`
  ${HeadlineFont};
`;

export const MenuDescription = styled.div`
  ${CaptionFont};
  opacity: ${THEME.OPACITY.MED};
`;

export const LinkedinButton = styled(IconButton)<IconButtonProps & { isActive?: boolean }>`
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: ${THEME.RADIUS.CIRCLE};
  padding: ${THEME.SPACING.MICRO};
  color: ${({ isActive }) => isActive ? THEME.COLORS.ACCENT : THEME.COLORS.DECOR};
`;

export const ButtonIcon = styled(SOCIAL_ICONS.LINKED_IN)<{ isActive: boolean }>`
  height: 24px;
`;

export const LinkedinIcon = styled(SOCIAL_ICONS.LINKED_IN)`
  height: 24px;
`;

export const MarkAsDoneIcon = styled(ASSETS.CHAT_V_SIGN)`
  height: 24px;
`;

export const ExternalLinkIcon = styled(ASSETS.ETERNAL_LINK)`
  opacity: ${THEME.OPACITY.MED};
  width: 12px;
  height: 12px;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;
