import { Dispatch } from 'react';
import { EditorState } from 'draft-js';
import { TextEditorActionType } from './text-editor.enums';

export type TextEditorAction = {
  type: TextEditorActionType,
  payload: EditorState | boolean | void,
};

export type TextEditorActionReturnType = {
  setEditorStateInitialized: (state: boolean) => void,
  setSubjectReadOnly: (state: boolean) => void,
  setTouched: (state: boolean) => void,
  setShowSubjectEditor: (state: boolean) => void,
  setEditorInFocus: (state: boolean) => void,
  setSubjectEditorInFocus: (state: boolean) => void,
  setEditorBodyState: (state: EditorState) => void,
  setEditorSubjectState: (state: EditorState) => void,
  resetEditor: () => void;
  setShowTextEditorHeader: (state: boolean) => void,
};

export const actions = (dispatch:  Dispatch<TextEditorAction>): TextEditorActionReturnType => ({
  setEditorStateInitialized: (state: boolean) => {
    dispatch({
      type: TextEditorActionType.SetEditorStateInitialized,
      payload: state,
    });
  },
  setSubjectReadOnly: (state: boolean) => {
    dispatch({
      type: TextEditorActionType.SetSubjectReadOnly,
      payload: state,
    });
  },
  setTouched: (state: boolean) => {
    dispatch({
      type: TextEditorActionType.SetTouched,
      payload: state,
    });
  },
  setShowSubjectEditor: (state: boolean) => {
    dispatch({
      type: TextEditorActionType.SetShowSubjectEditor,
      payload: state,
    });
  },
  setEditorInFocus: (state: boolean) => {
    dispatch({
      type: TextEditorActionType.SetEditorInFocus,
      payload: state,
    });
  },
  setSubjectEditorInFocus: (state: boolean) => {
    dispatch({
      type: TextEditorActionType.SetSubjectEditorInFocus,
      payload: state,
    });
  },
  setEditorBodyState: (state: EditorState) => {
    dispatch({
      type: TextEditorActionType.SetEditorBody,
      payload: state,
    });
  },
  setEditorSubjectState: (state: EditorState) => {
    dispatch({
      type: TextEditorActionType.SetEditorSubject,
      payload: state,
    });
  },
  resetEditor: () => {
    dispatch({
      type: TextEditorActionType.ResetEditor,
      payload: undefined,
    });
  },
  setShowTextEditorHeader: (state: boolean) => {
    dispatch({
      type: TextEditorActionType.SetShowTextEditorHeader,
      payload: state,
    });
  },
});
