import styled  from 'styled-components';
import { THEME } from '../../../../themes';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { ghostElevation } from '../../../../themes/elevations';
import { hexToRgbA } from '../../../../utils/colors';

export const Container = styled.div`
  overflow: auto;
  height: 100vh;
`;

export const PositionIntroductionScheduleMeetingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const MatcherFab = styled.div`
  display: flex;
  align-items: start;
  position: fixed;
  bottom: 32px;
  z-index: 101;
  left: 36px;
  gap: ${THEME.SPACING.TINY};
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINO};
  flex-direction: row;
`;

export const EditPositionButton = styled(BaseButton)`
  ${ghostElevation};
  background-color: ${THEME.COLORS.PURE_WHITE};
  color: ${THEME.COLORS.SECONDARY};
  text-transform: capitalize;
  letter-spacing: 0.1px;
  border: solid 1px ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.GHOST)};
`;

export const ByeBiasButton = styled(BaseButton)`
  ${ghostElevation};
  background-color: ${THEME.COLORS.PURE_WHITE};
  color: ${THEME.COLORS.TRUE_BLACK};
  text-transform: capitalize;
  letter-spacing: 0.1px;
  border: solid 1px ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.GHOST)};
`;
