import styled from 'styled-components';
import { THEME } from '../../../../themes';
import HOMEPAGE_BACKGROUND_IMAGE from '../../../../assets/homepage-header-background.svg';
import { Body2Font, Title1Font } from '../../../../themes/fonts';
import { ASSETS } from '../../../../assets';
import Header from '../../../../components/Common/headers/header/header.component';

export const HEADER_CONTAINER_HEIGHT_PX = 320;

export const StyledHeader = styled(Header)``;

export const HeaderContainer = styled.div`
  height: ${HEADER_CONTAINER_HEIGHT_PX}px;
  background-color: ${THEME.COLORS.PRIMARY_DARK};
  background-image: url(${HOMEPAGE_BACKGROUND_IMAGE});
  background-position: 50%;
  background-size: 100%;
  display: flex;
  justify-content: center;
  padding: ${THEME.SPACING.MEDIUM};
  position: relative;
  z-index: 1;
`;

export const ContainerBackground = styled.div`
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle at 30%,#130f35 19%,rgba(19,15,53,0));
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

export const HeaderContent = styled.div`
  width: ${THEME.BREAKPOINTS.WIDTH.SMALL}px;
  color: ${THEME.COLORS.PURE_WHITE};
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContentContainer  = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINO};
  flex: 0 0 50%;
`;

export const Title = styled.div`
  ${Title1Font};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
`;

export const Description = styled.div`
  ${Body2Font};
`;

export const TimeOfDayContainer = styled.span`
  position: relative;
`;

export const MorningIcon = styled(ASSETS.SUNSHINE)`
  position: absolute;
  top: -5px;
  right: -15px;
  fill: ${THEME.COLORS.WARNING};
`;

export const AfternoonIcon = styled(ASSETS.STEAM)`
  fill: ${THEME.COLORS.ACCENT};
  position: absolute;
  top: -5px;
  right: 0;
`;

export const EveningIcon = styled(ASSETS.STARS_FILLED)`
  position: absolute;
  top: -5px;
  right: 0;
  width: 16px;
  height: 20px;
  color: ${THEME.COLORS.DECOR};
`;
