import styled  from 'styled-components';
import { THEME } from '../../../themes';
import { CaptionFont } from '../../../themes/fonts';

export const Message = styled.div`
  width: 100%;
  ${CaptionFont}
  text-align: center;
  color: ${THEME.COLORS.ALERT};
`;
