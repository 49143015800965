import { AnyAction, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { setLatestAppVersion } from '../slices/app-state/app-state.toolkit-slice';
import { API_HEADERS } from '../../consts/api';

const isHttpResponse = (action: AnyAction): boolean => {
  return action.type.endsWith('/fulfilled') || action.type.endsWith('/rejected');
};

export const appVersionMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (!isHttpResponse(action)) {
      return next(action);
    }

    const response = next(action);
    const headers = response?.meta?.baseQueryMeta?.headers || {};

    const latestAppVersion = headers[API_HEADERS.APP_VERSION];

    if (latestAppVersion) {
      api.dispatch(setLatestAppVersion(latestAppVersion));
    }

    return response;
  };
