import styled from 'styled-components';
import { ASSETS, FLUENT_UI_EMOJIS } from '../../../../../assets';
import { THEME } from '../../../../../themes';
import { pageContentWidth } from '../../match-content.styled';

export const Badges =  styled.div`
  display: flex;
  flex-direction: row;
  width: ${pageContentWidth};
  flex-wrap: wrap;
  gap: ${THEME.SPACING.BASE};
  margin: ${THEME.SPACING.XLOOSE} auto auto auto;
`;

export const Crunchbase = styled(ASSETS.COMPANY_RANK)`
  width: 48.5px;
  height: 56px;
  margin-right: 16px;
`;

export const ActivelyLookingBadgeIcon = styled(ASSETS.ACTIVELY_LOOKING)`
  width: 56px;
  height: 56px;
  margin-right: 16px;
`;

export const AcademicExcellenceBadgeIcon = styled(FLUENT_UI_EMOJIS.CLASSICAL_BUILDING)`
  width: 56px;
  height: 56px;
  margin-right: 16px;
`;

export const TenurePeriodBadgeIcon = styled(ASSETS.STOPPER)`
  width: 56px;
  height: 56px;
  margin-right: 16px;
`;
