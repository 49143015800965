import { CustomSnackBar } from '..';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';
import { AppSnackbarVariant } from './app-snackbar.enums';
import { AppSnackbarProps } from './app-snackbar';

export const AppSnackbar = ({
  title,
  description,
  actionTitle,
  shouldAutoDismiss,
  onStartClosing,
  onActionClick,
  isOpen,
  anchor = 'top-center',
  variant,
  onClose,
}: AppSnackbarProps):JSX.Element => {
  const getIcon = () => {
    switch (variant) {
      case AppSnackbarVariant.Error:
        return ASSETS.ALERT;
      case AppSnackbarVariant.Success:
      default:
        return ASSETS.CHECKED_CIRCLE_FILLED_SVG;
    }
  };

  const getTitleAndIconColor = () => {
    switch (variant) {
      case AppSnackbarVariant.Error:
        return THEME.COLORS.ALERT;
      case AppSnackbarVariant.Success:
      default:
        return THEME.COLORS.SUCCESS;
    }
  };

  return (
    <CustomSnackBar
      isOpen={ isOpen }
      anchor={ anchor }
      icon={ getIcon() }
      iconColor={ getTitleAndIconColor() }
      title={ title }
      titleColor={ getTitleAndIconColor() }
      description={ description }
      actionTitle={ actionTitle }
      onActionClick={ onActionClick }
      actionColor={ THEME.COLORS.ACCENT }
      onStartClosing={ onStartClosing }
      shouldAutoDismiss={ shouldAutoDismiss }
      onClose={ onClose }
    />
  );
};
