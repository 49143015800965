class FaviconService {
  public setFavicon = (path: string): void => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement | null;

    if (favicon) {
      favicon.href = path;
    }
  }
}

export default new FaviconService();
