import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { ButtonFont } from '../../../../themes/fonts';

const {
  SPACING, COLORS, RADIUS, OPACITY,
} = THEME;

// the :after is an overlay used to add "effect" to the button when hover/active/disabled etc.
// this way, we don't need to define a new color (in the theme) with opacity.
export const CustomButton = styled.button<{
  fontColor?: string,
  backgroundColor?: string,
  border?: string,
  disabled?: boolean
  overlayColor?: string,
  padding?: string,
  buttonWidth?: string,
  buttonHeight?: string,
  iconWidth?: string,
  iconHeight?: string,
}>`
  ${ButtonFont};
  color: ${({ fontColor }) => fontColor ?? COLORS.CONTENT_INVERSE};
  padding: ${({ padding }) => padding ||
          `${SPACING.TINO} ${SPACING.MEDIUM}`};
  border-radius: ${RADIUS.CIRCLE};
  background-color: ${({ backgroundColor }) => backgroundColor ?? COLORS.PRIMARY};
  border: ${({ border }) => border ?? 'none'};
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  transition: all 0.2s;
  position: relative;
  overflow: hidden;
  min-height: 45px;
  width: ${({ buttonWidth }) => buttonWidth ?? 'auto'};
  height: ${({ buttonHeight }) => buttonHeight ?? 'auto'};

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ overlayColor }) => overlayColor ?? THEME.COLORS.PURE_WHITE};
    content: '';
    opacity: 0;
    border-radius: ${THEME.RADIUS.CIRCLE};
  }

  ${({ disabled }) => disabled && (`
    &:after {
      background-color: ${THEME.COLORS.PURE_WHITE};
      opacity: ${OPACITY.LOW} !important;
    }
  `)}
  &:hover {
    &:after {
      opacity: ${OPACITY.DIVIDER};
    }
  }

  &:active {
    &:after {
      opacity: 0.24;
    }
  }
`;
