import styled from 'styled-components';
import  CountUp  from 'react-countup';
import { THEME } from '../../../../../../themes';
import { Body2Font, Title2Font } from '../../../../../../themes/fonts';

const SMALL_CHART_SIZE_PX = 133;
const BIG_CHART_SIZE_PX = 250;

export const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const ChartStyle = (isSmall: boolean): Record<string, string> => ({
  display: 'flex',
  width: `${isSmall ? SMALL_CHART_SIZE_PX : BIG_CHART_SIZE_PX}px`,
  height: `${isSmall ? SMALL_CHART_SIZE_PX : BIG_CHART_SIZE_PX}px`,
});

export const Experience = styled(CountUp)`
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  ${Title2Font};
  font-weight: bold;
`;

export const Years = styled.div`
  opacity: ${THEME.OPACITY.LOW};
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  ${Body2Font};
  font-weight: bold;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 100px;
`;
