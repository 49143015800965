import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import StepContainer from '../step-container/step-container.component';
import STRING_KEYS from '../../../../../../language/keys';
import { ANALYTICS_EVENTS } from '../../../../../../consts/analytics';
import { EditJobRequirement } from '../../edit-job-requirement/edit-job-requirement.component';
import { LevelOfDegreeEnum } from '../../../../../../enums/level-of-degree';
import DegreesContainer from '../../../../../../components/degrees-container/degrees-container.component';
import {
  Container,
  StyledDivider,
  Title,
} from './education-step.styled';

const EducationStep = ({
  selectedDegrees,
  selectedFieldsOfStudies,
  degreesChangeHandler,
  fieldsOfStudiesChangeHandler,
  backButtonClickHandler,
}: EducationStepProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [selectedDegreesSet, setSelectedDegreesSet] = useState(new Set(selectedDegrees.map((degree) => degree.title as LevelOfDegreeEnum)));
  const [selectedFieldsOfStudiesInternal, setSelectedFieldsOfStudiesInternal] = useState(JSON.parse(JSON.stringify(selectedFieldsOfStudies)));

  const isContinueButtonDisabled = useMemo(() => {
    return selectedDegreesSet.size === selectedDegrees.length &&
      isEqual(selectedFieldsOfStudiesInternal, selectedFieldsOfStudies);
  }, [selectedDegrees.length, selectedDegreesSet.size, selectedFieldsOfStudies, selectedFieldsOfStudiesInternal]);

  const onContinueButtonClick = () => {
    degreesChangeHandler(Array.from(selectedDegreesSet).map((degree) => ({ title: degree })));
    fieldsOfStudiesChangeHandler(selectedFieldsOfStudiesInternal);
  };

  return (
    <StepContainer
      title={ translate(STRING_KEYS.MATCH_TUNE.EDUCATION_STEP.TITLE) }
      subtitle={ translate(STRING_KEYS.MATCH_TUNE.EDUCATION_STEP.SUBTITLE) }
      backButtonHandler={ backButtonClickHandler }
      continueButtonHandler={ onContinueButtonClick }
      isContinueButtonDisabled={ isContinueButtonDisabled }
      eventName={ ANALYTICS_EVENTS.MATCH_TUNE_EDUCATION_STEP_VIEWED }
    >
      <Container>
        <Title>{ translate(STRING_KEYS.MATCH_TUNE.EDUCATION_STEP.DEGREE_TITLE) }</Title>
        <DegreesContainer
          selectedDegrees={ selectedDegreesSet }
          changeHandler={ setSelectedDegreesSet }
        />
        <StyledDivider />
        <Title>{ translate(STRING_KEYS.MATCH_TUNE.EDUCATION_STEP.FIELD_OF_STUDY_TITLE) }</Title>
        <EditJobRequirement
          selectedJobRequirements={ selectedFieldsOfStudiesInternal }
          setSelectedJobRequirementsHandler={ (updatedData) => setSelectedFieldsOfStudiesInternal(updatedData) }
          enableRequirement
          emptyStateText={ translate(STRING_KEYS.MATCH_TUNE.EDUCATION_STEP.FIELD_OF_STUDY_EMPTY_STATE_TEXT) }
        />
      </Container>
    </StepContainer>
  );
};

export default EducationStep;
