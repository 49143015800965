import { Section } from '../../explainability-info-menu.styled';
import { HeaderTitle } from './header-section.styled';

const HeaderSection = ({ title, Icon, children }: HeaderSectionProps): JSX.Element => {
  return (
    <Section>
      <HeaderTitle>
        { Icon }
        { title }
      </HeaderTitle>
      { children }
    </Section>
  );
};

export default HeaderSection;
