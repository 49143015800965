export interface Locations {
  exactLocations?: ExactLocationDto[];
  regions: RegionDto[];
}

export enum LocationType {
  ExactLocation = 'ExactLocation',
  Region = 'Region',
}

export interface LocationBaseDto {
  country: string;
  countryLong?: string;
  type: LocationType;
}

export interface ExactLocationDto extends LocationBaseDto {
  city?: string;
  state?: string;
  stateLong?: string;
  geometry?: {
    lat: number;
    lng: number;
  }
  stateAbbreviation?: string;
  locationMileRadius?: number;
}

export interface RegionDto extends LocationBaseDto {
  region: string;
}

export type LocationDto = ExactLocationDto | RegionDto;
