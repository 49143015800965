import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ASSETS } from '../../../../../assets';
import STRING_KEYS from '../../../../../language/keys';
import SvgIcon from '../../../../Common/SvgIcon';
import { MATCH_PAGE_HEADER_HEIGHT } from '../../../../../pages/match-page/match.page.styled';
import { LearnWhyPopUp } from './learn-why-pop-up/learn-why-pop-up.component';
import {
  CardHeader, Container, Desc, Label, LearnWhyButton, SkillsTitle, StyledMeter, Wrapper,
} from './move-meter-widget.styled';
import { calculateLevel, getMeterProperties } from './move-meter-widgets.utils';
import { MeterBarStyleEnum } from './meter/meter.enums';
import MoveMeterChildWidgetsContainer
  from './move-meter-child-widgets-container/move-meter-child-widgets-container.component';

type MoveMeterProps = {
  data: MoveMeterData,
  childWidgets: ChildWidget[],
}

const LEARN_MORE_MODAL_SPACING = 50;
const MAX_SIZE = 25;
const MIN_SIZE = 12;
const SERVER_RANGE = 10;

export const MoveMeterWidget = ({
  data,
  childWidgets,
}:MoveMeterProps)
: JSX.Element | null => {
  const { t: translate } = useTranslation();
  const { randomizedViewOnlyScore, type } = data as MoveMeterData;
  const [isOpenLearnWhy, setIsOpenLearnWhy] = useState(false);

  const [shouldModalOpenBelow, setShouldModalOpenBelow] = useState<boolean | null>(null);
  const learnMoreModalRef = useRef<HTMLDivElement>(null);
  const learnMoreOpeningButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (learnMoreModalRef.current && learnMoreOpeningButtonRef.current) {
      const topPosition = learnMoreOpeningButtonRef.current.getBoundingClientRect().top;
      const modalHeight = learnMoreModalRef.current.getBoundingClientRect().height;

      if (topPosition - MATCH_PAGE_HEADER_HEIGHT < (modalHeight + LEARN_MORE_MODAL_SPACING)) {
        // no enough sapce above the button, open the modal below
        setShouldModalOpenBelow(true);
      } else {
        // there is enough space above the button, open the modal above
        setShouldModalOpenBelow(false);
      }
    }
  }, [isOpenLearnWhy]);

  const level = calculateLevel(randomizedViewOnlyScore, SERVER_RANGE, MIN_SIZE, MAX_SIZE);
  const { color, titleTranslationKey } = getMeterProperties(type);

  if (!color && !titleTranslationKey) {
    return null;
  }

  const handleOpenLearnWhy = () => {
    setIsOpenLearnWhy(true);
  };
  const handleCloseLearnWhy = () => {
    setIsOpenLearnWhy(false);
  };

  return (
    <Container>
      <CardHeader>
        <Desc>{translate(STRING_KEYS.LIKELIHOOD)}</Desc>
        <LearnWhyButton onClick={ handleOpenLearnWhy } ref={ learnMoreOpeningButtonRef }>
          {translate(STRING_KEYS.LEARN_MORE)}
          <SvgIcon Icon={ ASSETS.QUESTION_FILLED } size="micro" fill="#302585" />
        </LearnWhyButton>

        { isOpenLearnWhy && (
          <LearnWhyPopUp
            modalRef={ learnMoreModalRef }
            position={ shouldModalOpenBelow ? 'bottom' : 'top' }
            onCloseLearnWhyClick={ handleCloseLearnWhy }
          />
        )}
      </CardHeader>
      <SkillsTitle>
        {translate(titleTranslationKey)}
      </SkillsTitle>
      <StyledMeter
        level={ level }
        color={ color }
        totalBarSize={ MAX_SIZE }
        barStyle={ MeterBarStyleEnum.Vertical }
      />
      <Wrapper>
        <Label>{translate(STRING_KEYS.UNLIKELY)}</Label>
        <Label>{translate(STRING_KEYS.SOMEWHAT_LIKELY)}</Label>
        <Label>{translate(STRING_KEYS.VERY_LIKELY)}</Label>
      </Wrapper>
      <MoveMeterChildWidgetsContainer childWidgets={ childWidgets } />
    </Container>
  );
};
