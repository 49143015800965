import styled  from 'styled-components';
import { THEME } from '../../../themes';
import { hexToRgbA } from '../../../utils/colors';
import { DividerSection } from '../Divider/styled';
import { Body2Font, CaptionFont } from '../../../themes/fonts';
import BaseButton from '../Buttons/perfect-base-button/perfect-base-button.component';

export const EditorWrapper = styled.div<{ inFocus: boolean, showTopBorderRadius?: boolean }>`
  ${Body2Font};
  min-height: ${({ inFocus }) => inFocus ? '225px' : '125px'};
  transition: all 0.3s ease-in;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${THEME.SPACING.BASE};
  border: solid 1px ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
  border-bottom-left-radius: ${THEME.RADIUS.ROUND};
  border-bottom-right-radius: ${THEME.RADIUS.ROUND};
  background-color: ${THEME.COLORS.PURE_WHITE};
  ${({ showTopBorderRadius }) => showTopBorderRadius ? `
      border-top-left-radius: ${THEME.RADIUS.ROUND};
      border-top-right-radius: ${THEME.RADIUS.ROUND};
  ` : undefined};
`;

export const SubjectEditorContainer = styled.div<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => isVisible ? THEME.OPACITY.HIGHEST : 0};
  height: ${({ isVisible }) => isVisible ? '52px' : 0};
  overflow: auto;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
  & .DraftEditor-root {
    width: 100%;
    position: relative;
    padding-left:  ${THEME.SPACING.TINO};
    padding-top: ${THEME.SPACING.TINO};
    & .DraftEditor-editorContainer {
      position: relative;
      z-index: 1;
    }
    & .public-DraftEditorPlaceholder-root {
      opacity: ${THEME.OPACITY.LOW};
      position: absolute;
    }
`;

export const BodyEditorContainer = styled.div` 
  & .DraftEditor-root {
    position: relative;
    padding-left: ${THEME.SPACING.TINO};
    padding-top: ${THEME.SPACING.TINO};
    max-height: 150px;
    overflow-y: auto;
    line-height: 1.7;
    & .DraftEditor-editorContainer {
      position: relative;
      z-index: 1;
    }
    & .public-DraftEditorPlaceholder-root {
      opacity: ${THEME.OPACITY.LOW};
      position: absolute;
    }
  & .editor-alignment-right {
    text-align: right;
  }
  & .editor-alignment-center {
    text-align: center;
  }
  & .editor-alignment-left {
    text-align: left;
  }
  & .span {
    height: 100%;
  }
}
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
  padding: 0 ${THEME.SPACING.MICRO};
  overflow: hidden;
`;

export const StandardAction = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.BASE};
`;

export const EditorActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Divider = styled(DividerSection)`
  width: 99%;
  align-self: center;
`;

export const InfoComponentWrapper = styled.div`
  margin-top: ${THEME.SPACING.TINY};
  display: flex;
`;

export const SkeletonContainer = styled.div`
  padding-bottom: ${THEME.SPACING.BASE};
`;

export const SkeletonChipWrapper = styled.div`
  margin-top: ${THEME.SPACING.BASE};
`;

export const WarningChipButton = styled(BaseButton)`
  ${CaptionFont};
  letter-spacing: 1.15px;
  font-weight: bold;
  background-color: transparent;
  color: ${THEME.COLORS.SECONDARY};
`;
