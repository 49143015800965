import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { hexToRgbA } from '../../../../../utils/colors';
import { ASSETS } from '../../../../../assets';
import { Body2Font, OverlineFont, Title3Font } from '../../../../../themes/fonts';
import Chip from '../../../../../components/Common/perfect-chip/perfect-chip.component';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { popoutElevation } from '../../../../../themes/elevations';
import {
  PerfectButtonVariants,
} from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

export const Background = styled.div<{ isDark: boolean }>`
  width: 100%;
  height: 270px;
  position: absolute;
  top: 26px;
  z-index: 0;
  left: 0;
  background-image: radial-gradient(ellipse at 50% 50%, ${THEME.COLORS.LIGHT_PINK}, rgba(255, 229, 239, 0) 70%);
  background-image: ${({ isDark }) => {
    if (isDark) {
      return `radial-gradient(ellipse at 50% 50%, ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.DIVIDER)}, rgba(255, 229, 239, 0) 70%)`;
    }

    return `radial-gradient(ellipse at 50% 50%, ${THEME.COLORS.LIGHT_PINK}, rgba(255, 229, 239, 0) 70%)`;
  }};
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const HeaderIconContainer = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${THEME.RADIUS.CIRCLE};
`;

export const HeaderIcon = styled(ASSETS.LIGHTNING)`
  width: 10px;
  height: 10px;
  color: ${THEME.COLORS.PURE_WHITE};
`;

export const HeaderLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
`;

export const HeaderLabel = styled.div`
  ${OverlineFont};
  font-weight: 600;
  color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW)};
`;

export const RateChip = styled(Chip)`
  ${popoutElevation};
  display: flex;
  align-items: center;
  background-color: ${hexToRgbA(THEME.COLORS.SUCCESS, THEME.OPACITY.DIVIDER)};
  color: ${THEME.COLORS.SUCCESS};
  outline: solid 4px ${hexToRgbA(THEME.COLORS.PURE_WHITE, THEME.OPACITY.MED)};
`;

export const ArrowIcon = styled(ASSETS.REPLAY_ARROW)`
  width: 16px;
  height: 14px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: relative;
`;

export const BodyTitle = styled.div<{ isDark: boolean }>`
  ${Title3Font};
  margin-top: ${THEME.SPACING.LOOSE};
  font-weight: 600;
  line-height: 1.33;
  color: ${({ isDark }) => {
    if (isDark) {
      return hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.LOW);
    }
    
    return THEME.COLORS.CONTENT_A;
  }};
  text-align: center;
  white-space: pre-line;
`;

export const BodyDescription = styled.div<{ isDark: boolean }>`
  ${Body2Font};
  margin-top: ${THEME.SPACING.TINO};
  margin-bottom: ${THEME.SPACING.MEDIUM};
  line-height: 1.57;
  white-space: pre-line;
  color: ${({ isDark }) => {
    if (isDark) {
      return hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.LOW);
    }

    return THEME.COLORS.CONTENT_A;
  }};
  text-align: center;
`;

export const ActionButton = styled(BaseButton)< { variant: PerfectButtonVariants.Fill | PerfectButtonVariants.Outline } >`
  background-color: ${({ variant }) => {
    if (variant === PerfectButtonVariants.Outline) {
      return THEME.COLORS.PURE_WHITE;
    }
    
    return hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER);
  }};
  border: ${({ variant }) => {
    if (variant === PerfectButtonVariants.Outline) {
      return `solid 1px ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.GHOST)}`;
    }

    return undefined;
  }};
  color: ${THEME.COLORS.ACCENT};
  font-size: ${THEME.FONT_SIZE.TINO};
`;

export const ButtonIcon = styled(ASSETS.FILL_ARROW_BACK_COMPONENT)`
  height: 24px;
  fill: ${hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER)};
`;
