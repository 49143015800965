import styled from 'styled-components';
import { THEME } from '../../../../themes';
import BaseButton from '../../Buttons/perfect-base-button/perfect-base-button.component';
import { CaptionFont } from '../../../../themes/fonts';

export const SkeletonChipWrapper = styled.div`
  margin-top: ${THEME.SPACING.BASE};
`;

export const WarningChipButton = styled(BaseButton)`
  ${CaptionFont};
  letter-spacing: 1.15px;
  font-weight: bold;
  background-color: transparent;
  color: ${THEME.COLORS.SECONDARY};
`;
