import styled from 'styled-components';
import TextEditorHeader from '../text-editor-header.component';
import { hexToRgbA } from '../../../../../../utils/colors';
import { THEME } from '../../../../../../themes';
import { OverlineFont } from '../../../../../../themes/fonts';
import { ASSETS } from '../../../../../../assets';
import BaseButton from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';

const getBackgroundColor = (isConversationEmpty: boolean) => {
  if (isConversationEmpty) {
    return THEME.COLORS.PRIMARY_DARK;
  }

  return hexToRgbA(THEME.COLORS.DECOR, THEME.OPACITY.DIVIDER);
};

export const ManualModeEditorHeader = styled(TextEditorHeader)<{ isConversationEmpty: boolean }>`
  background-color: ${({ isConversationEmpty }) => getBackgroundColor(isConversationEmpty)};
  color: ${({ isConversationEmpty }) => isConversationEmpty ? THEME.COLORS.PURE_WHITE : undefined};
`;

export const EndAdornment = styled.div`
  display: flex;
  align-items: center;
  ${OverlineFont};
  letter-spacing: 0.33px;
  text-transform: capitalize;
  gap: ${THEME.SPACING.BASE};
`;

export const ActionButton = styled(BaseButton)<{ isConversationEmpty: boolean }>`
  background-color: transparent;
  color: ${({ isConversationEmpty }) => isConversationEmpty ? THEME.COLORS.ACCENT : THEME.COLORS.DECOR};
  font-weight: 600;
  align-self: center;
`;

export const ActionButtonIcon = styled(ASSETS.TOGGLE_OFF)`
  width: 21px;
`;
