import { UserInfoResponse } from '../dto/response/user-info.response';
import { userQuery } from '../user.toolkit-api';

type UseGetMyUserQuery = {
  pollingIntervalInMs?: number,
  skip?: boolean,
};

export const useGetMyUserQuery = (args?: UseGetMyUserQuery): AppSelectorType<UserInfoResponse> => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch,
  } = userQuery.useGetMyUserQuery(
    undefined,
    {
      skip: args?.skip || false,
      pollingInterval: args?.pollingIntervalInMs || undefined,
    },
  );

  return {
    data: data || {} as UserInfoResponse,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch,
  };
};
