import { useRef } from 'react';
import { useIntersection } from '../../../hooks/useIntersection';

export const IntersectionWrapper = ({ intersectionHandler, children }: IntersectionWrapperProps): JSX.Element => {
  const componentRef = useRef(null);
  useIntersection({
    element: componentRef,
    callback: () => intersectionHandler?.(),
  });

  return (
    <div ref={ componentRef }>
      { children }
    </div>
  );
};
