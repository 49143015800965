import styled from 'styled-components';
import {
  Body2Font, CaptionFont, HeadlineFont, Subtitle1Font,
} from '../../../../../themes/fonts';
import { THEME } from '../../../../../themes';
import { ASSETS } from '../../../../../assets';
import SidePanelHeader from '../../../../../components/Common/side-panel/side-panel-header/side-panel-header.component';
import { hexToHslA, hexToRgbA } from '../../../../../utils/colors';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { PerfectButtonVariants } from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { CONVERSATION_HEADER_HEIGHT } from '../conversation-header/conversation-header.styled';
import { fadeInAnimation } from '../../../../../animations';
import { Scrollbar } from '../../../../../themes/scrollbar';
import TalentAtsIndication from '../../talent-ats-indication/talent-ats-indication.component';

export const SIDE_PANEL_WIDTH_EXTRA_SMALL = 240;
export const SIDE_PANEL_WIDTH_SMALL = 310;
export const SIDE_PANEL_WIDTH_MEDIUM = 360;

export const SidePanelContainer = styled.div`
  ${Scrollbar};
  overflow-x: hidden;
  width: ${SIDE_PANEL_WIDTH_MEDIUM}px;
`;

export const MatchEngagementSidePanelContainer = styled.div`
  background-color: ${THEME.COLORS.PURE_WHITE};
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.LOOSE};
`;

export const SidePanelHeaderContainer = styled(SidePanelHeader)`
  backdrop-filter: saturate(180%) blur(20px);
  background-color: ${hexToHslA({ hex: THEME.COLORS.PURE_WHITE, alpha: THEME.OPACITY.MED })};
  padding: ${THEME.SPACING.LOOSE} ${THEME.SPACING.MEDIUM};
  height: ${CONVERSATION_HEADER_HEIGHT};
  border-bottom: solid 1px ${THEME.COLORS_RGB.PRIMARY_GHOST};
`;

export const AboutSentenceContainer = styled.div`
  ${Body2Font};
  line-height: 1.71;
  letter-spacing: normal;
  margin-bottom: ${THEME.SPACING.BASE};
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
`;

export const AboutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AboutTitle = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
`;

export const GoToMatchButton = styled(BaseButton)`
  color: ${THEME.COLORS.PURE_WHITE};
  line-height: 1.57;
  letter-spacing: 0.25px;
  text-transform: capitalize;
  width: 100%;
`;

export const ProfileIcon = styled(ASSETS.FULL_PROFILE)`
  width: 16px;
  height: 16px;
  fill: ${THEME.COLORS.PURE_WHITE};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
`;

export const HeaderTitle = styled.div`
  ${HeadlineFont};
  font-weight: 600;
`;

export const ContactDetailsContainer = styled.div`
  margin-top: ${THEME.SPACING.BASE};
  margin-bottom: ${THEME.SPACING.BASE};
  backdrop-filter: saturate(180%) blur(20px);
  background-color: hsla(0, 0%, 100%, 0.7);
`;

export const ContactDetailsColumnContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
`;

export const ContactHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  button {
    padding-left: ${THEME.SPACING.MICRO};
  }
`;

export const ContactDetailsInfoContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const ContactProBadge = styled.div`
  ${CaptionFont};
  width: fit-content;
  position: absolute;
  top: ${THEME.SPACING.MICRO};
  right: ${THEME.SPACING.TINO};
  padding: ${THEME.SPACING.MICRO} ${THEME.SPACING.TINY};
  background-color: ${THEME.COLORS_RGB.SECONDARY_005};
  color: ${THEME.COLORS.SECONDARY};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  display: flex;
  gap: 6px;
  cursor: pointer;
`;

export const ContactProIcon = styled(ASSETS.ROCKET_LAUNCH)`
  fill: ${THEME.COLORS.SECONDARY};
  width: 12px;
`;

export const ContactTitle = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
  color: ${THEME.COLORS.PRIMARY_DARK};
  white-space: nowrap;
`;

export const SeeMoreButton = styled(BaseButton)`
  ${CaptionFont};
  font-weight: 600;
  color: ${THEME.COLORS.PRIMARY_DARK};
  opacity: ${THEME.OPACITY.MED};
  background-color: ${({ variant }) => variant === PerfectButtonVariants.Fill ? hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER) : undefined};
  outline: 1px solid  ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
`;

export const TalentNetworkContainer = styled.div`
  margin-top: ${THEME.SPACING.BASE};
`;

export const MissingContacts = styled.div`
  ${CaptionFont};
  letter-spacing: 0.09px;
  font-weight: 600;
  opacity: ${THEME.OPACITY.MED};
  span {
    font-weight: normal;
  }
`;

export const DividerWrapper = styled.div`
  margin: 0 -${THEME.SPACING.LOOSE};
`;

export const AnimationWrapper = styled.div`
  animation: ${fadeInAnimation({
    animationDuration: 800,
    animationDelay: 0,
  })};
`;

export const StyledTalentAtsIndication = styled(TalentAtsIndication)`
  ${Body2Font};
  padding: ${THEME.SPACING.BASE};
  height: unset;
  flex-grow: 1;
  border-radius: ${THEME.RADIUS.ROUNDISH};
`;

export const ExportCSVButton = styled(BaseButton)`
  color: ${THEME.COLORS.PRIMARY_DARK};
  text-transform: capitalize;
  background-color: inherit;
  padding: 0;
  height: 24px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.25px;
  justify-content: left;
  border-radius: 0;
`;
