import { outreachQuery } from '../outreach.toolkit-api';
import { SendMessageQueryArguments } from '../dto/query-arguments/send-message-query.arguments';
import { MessageResponseDto } from '../dto/response/message.response';

type UseMarkMessageAsSentMutationType = [
  (request: SendMessageQueryArguments) => void,
  AppSelectorType<MessageResponseDto | undefined>
];

export const useMarkMessageAsSentMutation = (): UseMarkMessageAsSentMutationType => {
  const [
    markMessageAsSent,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = outreachQuery.useMarkMessageAsSentMutation();

  const meta = {
    isLoading, isError, error, isSuccess, data, resetState: reset,
  };

  return [
    markMessageAsSent,
    { ...meta },
  ];
};
