import { Container } from './styled';

type HeaderProps = {
  children: JSX.Element | JSX.Element[],
  width?: string,
}

const ModalHeader = ({
  children,
  width,
}: HeaderProps): JSX.Element => {
  return (
    <Container width={ width }>
      { children }
    </Container>
  );
};

ModalHeader.defaultProps = {
  width: undefined,
};

export default ModalHeader;
