/* eslint-disable react/jsx-props-no-spreading */
import {
  Divider,
  MenuContainer,
  ListItemContainer,
  Subtitle,
  Title,
  ItemImage,
  ListItemWrapper,
} from './dropdown-menu.styled';

function DropdownMenu({
  items,
  selectedItemId,
  onItemSelect,
  menuWidthPx,
  ...otherProps
}: DropdownMenuProps): JSX.Element {
  const onItemClick = (event: React.MouseEvent, id: string) => {
    if (!!onItemSelect && id !== selectedItemId) {
      onItemSelect(id);
    } else {
      event.stopPropagation();
    }
  };

  const renderItems = () => {
    return items?.map((item: DropdownMenuItem, index: number) => (
      <ListItemContainer
        key={ item.id }
        selected={ selectedItemId === item.id }
        onClick={ (event) => onItemClick(event, item.id) }
      >
        { index !== 0 && <Divider /> }
        <ListItemWrapper>
          { item.image && <ItemImage src={ item.image } /> }
          <div>
            <Title>{ item.title }</Title>
            { item.subtitle && <Subtitle menuWidthPx={ menuWidthPx }>{ item.subtitle }</Subtitle> }
          </div>
        </ListItemWrapper>
      </ListItemContainer>
    ));
  };

  return (
    <MenuContainer
      menuWidthPx={ menuWidthPx }
      { ...otherProps }
    >
      { renderItems() }
    </MenuContainer>
  );
}
export default DropdownMenu;

DropdownMenu.defaultProps = {
  selectedItem: undefined,
};
