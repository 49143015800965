import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PerfectButtonSize,
  PerfectButtonVariants,
} from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../../../language/keys';
import { SeeMoreButton } from '../match-side-panel.styled';
import { Contact, ContactsDetailsProps } from './contact-details-list';
import {
  AllButtonContainer,
  AllContactsHideContainer,
  Container,
  Content,
  ContentContainer,
  CopiedChip,
  CopyIcon,
  EmailIcon,
  IconWrapper,
  PhoneIcon,
  Text,
} from './contact-details-list.styled';
import { ContactType } from './contact-details-list.enums';

const ContactsDetails = ({ contacts, isLocked, onClick }: ContactsDetailsProps):JSX.Element => {
  const [showMoreContacts, setShowMoreContacts] = useState(false);
  const [isContactCopied, setIsContactCopied] = useState<string|null>(null);
  const { t: translate } = useTranslation();
  const orderedContacts = contacts.reduce((acc, contact) => {
    const { type, value } = contact;
    if (!acc.main.has(type)) {
      acc.main.set(type, { type, value });

      return acc;
    }

    acc.all.push({ type, value });

    return acc;
  }, {
    main: new Map<string, Contact>(),
    all: [] as Contact[],
  });

  const onContactClick = (value: string, key: string) => {
    onClick?.();

    if (!isLocked) {
      navigator?.clipboard?.writeText(value);
      setIsContactCopied(key);
    }
  };

  useEffect(() => {
    if (isContactCopied) {
      setTimeout(() => setIsContactCopied(null), 3000);
    }
  }, [isContactCopied]);

  const renderCopyButton = (contactKey: string) => {
    if (isLocked) {
      return null;
    }

    return (isContactCopied === contactKey ? <CopiedChip>{ translate(STRING_KEYS.COPIED) }</CopiedChip> : <CopyIcon />);
  };

  const renderContacts = (items: Contact[], contactIndex: string) => {
    return items.map(({ value, type }, index) => {
      const contactKey = `${contactIndex}_${index}`;
      const Icon = type === ContactType.Email ? EmailIcon : PhoneIcon;

      return (
        <ContentContainer key={ value } onClick={ () => onContactClick(value, contactKey) }>
          <IconWrapper>
            <Icon />
          </IconWrapper>
          <Content isLocked={ isLocked } >
            <Text>{ value }</Text>
            { renderCopyButton(contactKey) }
          </Content>
        </ContentContainer>
      );
    });
  };

  const renderAllContacts = () => {
    if (!orderedContacts.all.length) {
      return null;
    }

    return (
      <>
        <AllContactsHideContainer
          isDisplayShow={ showMoreContacts }
          rows={ orderedContacts.all.length }
        >
          {renderContacts(orderedContacts.all, 'all')}
        </AllContactsHideContainer>
        <AllButtonContainer>
          <SeeMoreButton
            size={ PerfectButtonSize.Small }
            variant={ showMoreContacts ? PerfectButtonVariants.Fill : PerfectButtonVariants.Outline }
            onClick={ () => {
              setShowMoreContacts((prevState) => !prevState);
            } }
          >
            { showMoreContacts ? translate(STRING_KEYS.ENGAGE_PANEL.SEE_MORE_BUTTON.HIDE) : translate(STRING_KEYS.ENGAGE_PANEL.SEE_MORE_BUTTON.SHOW) }
          </SeeMoreButton>
        </AllButtonContainer>
      </>
    );
  };

  return (
    <Container>
      { renderContacts(Array.from(orderedContacts.main.values()), 'main') }
      { renderAllContacts() }
    </Container>
  );
};

export default ContactsDetails;
