import styled from 'styled-components';
import { THEME } from '../../themes';
import {
  ButtonFont,
  CaptionFont,
  Subtitle1Font,
  Title2Font,
} from '../../themes/fonts';
import { HEADER_HEIGHT } from '../Common/headers/header/header.styled';

export const PageContainer = styled.div`
  text-align: center;
  overflow-y: scroll;
  height:  calc(100vh - ${HEADER_HEIGHT}px);
`;

export const Title = styled.div`
  ${Title2Font};
  line-height: 1.38;
  margin-top: ${THEME.SPACING.GRAND};
`;

export const SubTitle = styled.div`
  ${Title2Font};
  font-weight: bold;
  line-height: 1.38;
`;

export const InputsWrapper = styled.div`
  width: 400px;
  margin: 20px auto 0;
`;

export const CustomInput = styled.input<{isError: boolean}>`
  ${Subtitle1Font};
  font-weight: normal;
  color: ${THEME.COLORS_RGB.CONTENT_PRIMARY_MED};
  width: 100%;
  margin-top: ${THEME.SPACING.LOOSE};
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.MEDIUM};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  border: solid 1px ${({ isError }) => isError ?
    THEME.COLORS.ALERT : THEME.COLORS_RGB.PRIMARY_DARK_GHOST};
  caret-color: ${THEME.COLORS.ACCENT};
  background-color: ${THEME.COLORS.LIGHT_GREY};

  :focus {
    outline: none;
  }
`;

export const StartUsingPerfectWrapper = styled.div`
  margin-top: ${THEME.SPACING.XLOOSE};
  button {
    ${ButtonFont};
    line-height: 1.14;
  }
`;

export const ValidationError = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  position: absolute;
  bottom: -20px;
  left: ${THEME.SPACING.MICRO};
  color: ${THEME.COLORS.ALERT};
`;

export const InputAndErrorWrapper = styled.div`
  position: relative;
`;

export const LoaderWrapper = styled.div`
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;
