import styled from 'styled-components';
import { OverlineFont } from '../../../../../themes/fonts';
import { THEME } from '../../../../../themes';
import { ASSETS } from '../../../../../assets';

export const InstructionsMessageContainer = styled.div`
  ${OverlineFont};
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SkipMessage = styled.div`
  display: flex;
  cursor: pointer;
  gap: ${THEME.SPACING.MICRO};
  align-items: center;
`;

export const SkipButton = styled.div`
  color: ${THEME.COLORS.ACCENT};
  font-weight: 600;
`;

export const SkipButtonIcon = styled(ASSETS.FORWARD)`
  width: 16px;
  color: ${THEME.COLORS.ACCENT};
`;
