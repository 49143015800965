import { forwardRef, ReactElement, Ref } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { HeaderContainer } from './full-screen-dialog-header.styled';

const FullScreenDialogHeader = forwardRef((
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<HTMLDivElement>,
) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <HeaderContainer { ...props } ref={ ref } >
      { props.children }
    </HeaderContainer>
  );
});

FullScreenDialogHeader.displayName = 'FullScreenDialogHeader';

export default FullScreenDialogHeader;
