import SecondarySwitch from '../../outreach/secondary-switch/secondary-switch.component';
import {
  LeftContainer, Description, Header, Message, MessageContainer, MessageIcon, Title, TitleContainer, Container,
} from './message-spice-item.styled';
import { MessageSpiceItemProps } from './message-spice-item';

const MessageSpiceItem = ({
  title, description, message, checked, checkedHandler,
}: MessageSpiceItemProps): JSX.Element => {
  return (
    <Container>
      <LeftContainer>
        <Header>
          <TitleContainer>
            <Title>{ title }</Title>
            <Description>{ description }</Description>
          </TitleContainer>
        </Header>
        <MessageContainer>
          <MessageIcon />
          <Message>...{ message }...</Message>
        </MessageContainer>
      </LeftContainer>
      <SecondarySwitch
        checked={ checked }
        changeHandler={ (value) => checkedHandler(value) }
      />
    </Container>
  );
};
export default MessageSpiceItem;
