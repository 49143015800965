import { useState } from 'react';
import { isEmpty } from 'lodash';
import {
  LocationMenuItemDirection,
  LocationMenuItemSize,
} from '../../../../../../../../onboarding/components/autocompletes/location-autocomplete/location-menu-item/location-menu-item.enums';
import { areLocationsEqual, formatLocation, getMileRadius } from '../../../../../../../../../utils/location';
import useDebounce from '../../../../../../../../../hooks/debounce';
import { LocationMenuItem } from '../../../../../../../../onboarding/components/autocompletes/location-autocomplete/location-menu-item/location-menu-item.component';
import { ExactLocationDto, LocationDto } from '../../../../../../../../../models/dto/location.dto';
import { useLocationsAutocomplete } from '../../../../../../../../../hooks/location-autocomplete-hook/location-autocomplete.hook';
import { Autocomplete } from '../../../../../../../../../components/Common/create-position-autocomplete/autocomplete.component';
import { LocationAutocompleteProps } from './location-autocomplete';
import { LocationAutocompleteWrapper, MENU_Y_OFFSET_PX } from './location-autocomplete.styled';

const LocationAutocomplete = ({
  location,
  changeHandler,
  inputChangeHandler,
  selectedCountry,
  showRemoteOptions,
  locationAutocompletePlaceholder,
  locationAutocompleteLabel,
}: LocationAutocompleteProps): JSX.Element => {
  const isLocationAutocompleteAutofocus = isEmpty(location);
  const [isInputFocused, setInputFocused] = useState<boolean>(isLocationAutocompleteAutofocus);
  const [inputValue, setInputValue] = useState(formatLocation(location));
  const debouncedValue = useDebounce(inputValue.toLowerCase(), 500);

  const {
    isLoading,
    locationObject,
  } = useLocationsAutocomplete({
    query: debouncedValue, country: selectedCountry, showRemoteOptions,
  });

  const locations = [...locationObject.regions || [], ...locationObject.exactLocations || []];

  const setLocationHandler = (newLocation: LocationDto | undefined) => {
    changeHandler({
      ...newLocation || {} as ExactLocationDto,
      locationMileRadius: getMileRadius(location),
    });
  };

  const onAutocompleteInputChange = (value: string, index?: number) => {
    const isEnterClicked = index !== undefined && index >= 0;
    let newInputValue;
    if (isEnterClicked) {
      const selectedLocation = locations[index];
      setLocationHandler(selectedLocation);
      newInputValue = formatLocation(selectedLocation);
      setInputFocused(false);
    } else {
      newInputValue = value;
    }
    setInputValue(newInputValue);
    inputChangeHandler(newInputValue);
  };

  const onSelect = (selectedLocation: LocationDto) => {
    setInputValue(formatLocation(selectedLocation));
    setLocationHandler(selectedLocation);
    setInputFocused(false);
  };

  return (
    <LocationAutocompleteWrapper>
      <Autocomplete
        autoFocus={ isLocationAutocompleteAutofocus }
        isError={ !isInputFocused && isEmpty(location) }
        isLoading={ isLoading }
        showLoadingIndication={ false }
        placeholder={ locationAutocompletePlaceholder }
        label={ locationAutocompleteLabel }
        inputValue={ inputValue }
        onValueChange={ onAutocompleteInputChange }
        isInputFocused={ isInputFocused }
        setInputFocused={ setInputFocused }
        menuOffset={ [0, MENU_Y_OFFSET_PX] }
      >
        { locations.map((locationData: LocationDto) => {
          return (
            <LocationMenuItem
              key={ formatLocation(locationData) }
              data={ locationData }
              isSelected={ areLocationsEqual(locationData, location) }
              clickHandler={ () => onSelect(locationData) }
              size={ LocationMenuItemSize.Small }
              direction={ LocationMenuItemDirection.Row }
            />
          );
        }) }
      </Autocomplete>
    </LocationAutocompleteWrapper>
  );
};

export default LocationAutocomplete;
