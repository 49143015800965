import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import STRING_KEYS from '../../../../language/keys';
import BaseSelectionGroup from '../../../../components/Common/base-selection-group/base-selection-group.component';
import SelectionGroupItem
  from '../../../../components/Common/base-selection-group/selection-group-item/selection-group-item.component';
import { AppRouting } from '../../../../services/appRoutingResolver';
import { PageName } from '../../../../enums/page-name.enum';
import { useGetPositionStatisticsQuerySelector } from '../../../../store/rtk-query/apis/position/selectors/get-position.selector';
import { PositionSelectionGroupItemType } from './position-selection-group.enums';
import {
  HourglassIcon,
  MatchCounter,
  OutreachNotification,
  SpinnerContainer,
  StyledSpinner,
  TabContainer,
} from './position-selection-group.styled';

const calculateDefaultIndex = (pageName: string | null) => {
  const enumKeys = Object.keys(PositionSelectionGroupItemType).map((key) => key.toUpperCase());
  if (pageName === PageName.Match || pageName === PageName.MatchTune) {
    return enumKeys.indexOf(PositionSelectionGroupItemType.Matches);
  }
  if (
    pageName === PageName.Conversation ||
    pageName === PageName.ConversationsEmpty ||
    pageName === PageName.OutreachContentPreferences ||
    pageName === PageName.OutreachCampaignPreferences
  ) {
    return enumKeys.indexOf(PositionSelectionGroupItemType.Outreach);
  }

  return enumKeys.indexOf(PositionSelectionGroupItemType.Overview);
};

const PositionSelectionGroup = ({ positionId }: PositionSelectionGroupProps): JSX.Element => {
  const location = useLocation();
  const pageName = useMemo(() => AppRouting.getPageName(location.pathname), [location.pathname]);

  const { data: { repliedConversationsCount, ...positionStatistics } } = useGetPositionStatisticsQuerySelector(positionId);

  const [defaultSelectedIndex, setDefaultState] = useState(calculateDefaultIndex(pageName));

  useEffect(() => {
    const defaultIndex = calculateDefaultIndex(pageName);
    setDefaultState(defaultIndex);
  }, [location.pathname, pageName]);

  const { t: translate } = useTranslation();
  const history = useHistory();

  const onTagClick = useCallback((value: string) => {
    switch (value) {
      case PositionSelectionGroupItemType.Overview:
        history.push(AppRouting.buildPositionOverviewPageUrl(positionId));
        break;
      case PositionSelectionGroupItemType.Matches:
        history.push(AppRouting.buildMatchesPageUrl(positionId));
        break;
      case PositionSelectionGroupItemType.Outreach:
        history.push(AppRouting.buildPositionConversationPageUrl(positionId));
        break;
      default:
        break;
    }
  }, [history, positionId]);

  const MatchTabIcon = useMemo(() => {
    if (positionStatistics.isDailyLimit) {
      return (
        <HourglassIcon />
      );
    }

    if (positionStatistics.matchingProgress) {
      return (
        <SpinnerContainer>
          <StyledSpinner />
        </SpinnerContainer>
      );
    }

    if (positionStatistics.toReviewMatches) {
      return (
        <MatchCounter
          $isActive={ pageName === PageName.Match || pageName === PageName.MatchTune }
        >
          { positionStatistics.toReviewMatches }
        </MatchCounter>
      );
    }

    return null;
  }, [pageName, positionStatistics.isDailyLimit, positionStatistics.matchingProgress, positionStatistics.toReviewMatches]);

  return (
    <BaseSelectionGroup
      clickHandler={ onTagClick }
      defaultSelectedIndex={ defaultSelectedIndex }
    >
      <SelectionGroupItem
        value={ PositionSelectionGroupItemType.Overview }
      >
        { translate(STRING_KEYS.POSITION_SELECTION_GROUP.OVERVIEW_TITLE) }
      </SelectionGroupItem>
      <SelectionGroupItem
        value={ PositionSelectionGroupItemType.Matches }
      >
        <TabContainer>
          <div>{ translate(STRING_KEYS.POSITION_SELECTION_GROUP.MATCH_TITLE) }</div>
          { MatchTabIcon }
        </TabContainer>
      </SelectionGroupItem>
      <SelectionGroupItem
        value={ PositionSelectionGroupItemType.Outreach }
      >
        <TabContainer>
          <div>{ translate(STRING_KEYS.POSITION_SELECTION_GROUP.OUTREACH_TITLE) }</div>
          { !!repliedConversationsCount && <OutreachNotification /> }
        </TabContainer>
      </SelectionGroupItem>
    </BaseSelectionGroup>
  );
};

export default PositionSelectionGroup;
