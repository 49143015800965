import styled from 'styled-components';
import { THEME } from '../../../../../../../themes';
import { CaptionFont, HeadlineFont } from '../../../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../../../utils/colors';
import { DividerSection } from '../../../../../../../components/Common/Divider/styled';
import BaseButton from '../../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';

export const PopupMainContainer = styled.div<{ showSpaceBetween: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ showSpaceBetween }) => showSpaceBetween ? 'space-between' : undefined};
`;

export const PopupSharedContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINO};
  flex-direction: column;
  align-items: flex-start;
  padding: 0 ${THEME.SPACING.BASE};
`;

export const PopupTitle = styled.div`
  ${HeadlineFont};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const PopupDescription = styled.div`
  ${CaptionFont};
  color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.MED)};
`;

export const PopupDivider = styled(DividerSection)`
  width: calc(100% + ${THEME.SPACING.BASE} + ${THEME.SPACING.BASE});
  margin-left: -${THEME.SPACING.BASE};
`;

export const RemoveStepButton = styled(BaseButton)`
  width: 287px;
  height: 40px;
  background-color: ${THEME.COLORS.PRIMARY_DARK};
`;
