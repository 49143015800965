import styled, { css } from 'styled-components';
import MatchCard from '../match-card/match-card.component';
import { passMatch, likeMatch } from './matches-carousel.animation';

type MatchCardContainerProps = {
  $playLikeAnimation?: boolean,
  $playPassAnimation?: boolean,
  $matchCardIndex: number,
  $matchCardActiveIndex: number,
  $isAnimationRunning: boolean,
  onAnimationEnd: () => void,
};

const getAnimation = (playLakeAnimation: boolean, playPassAnimation: boolean) => {
  if (playLakeAnimation) {
    return css`
      animation: ${likeMatch} 0.4s ease-in;
    `;
  }

  if (playPassAnimation) {
    return css`
      animation: ${passMatch} 0.4s ease-in;
    `;
  }

  return css``;
};

export const Carousel = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 80px);
  overflow: auto;
  position: relative;
`;

export const MatchCardContainer = styled(MatchCard)<MatchCardContainerProps>`
  justify-content: center;
  z-index: ${({ $matchCardIndex }) => 50 - $matchCardIndex};
  display: ${({ $matchCardActiveIndex, $matchCardIndex }) => $matchCardIndex < $matchCardActiveIndex ? 'none' : 'flex'};
  transition: opacity 1s;
  opacity: ${({ $matchCardActiveIndex, $matchCardIndex, $isAnimationRunning }) => {
    if ($isAnimationRunning && $matchCardIndex === $matchCardActiveIndex + 1) {
      return 1;
    }

    return $matchCardIndex === $matchCardActiveIndex ? 1 : 0;
  }};
  height:${({ $matchCardActiveIndex, $matchCardIndex, $isAnimationRunning }) => {
    if ($matchCardIndex !== $matchCardActiveIndex) {
      if ($isAnimationRunning && ($matchCardIndex === $matchCardActiveIndex + 1 || $matchCardIndex === $matchCardActiveIndex - 1)) {
        return undefined;
      }

      return '1px';
    }
    
    return undefined;
  }};
  ${({ $playLikeAnimation, $playPassAnimation }) =>  getAnimation(!!$playLikeAnimation, !!$playPassAnimation)};
  position: absolute;
  top: 0;
  overflow-x: hidden;
`;
