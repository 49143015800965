import styled from 'styled-components';
import { ASSETS } from '../../../../../assets';
import Select from '../../../../../components/Common/select/select.component';
import { THEME } from '../../../../../themes';
import { CaptionFont, HeadlineFont, OverlineFont } from '../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../utils/colors';

export const Icon = styled(ASSETS.FILLED_SEND)`
  height: 24px;
  width: 24px;
`;

export const StyledSelect = styled(Select)`
  width: 230px;
  height: 56px;
`;

export const MenuTitleContainer = styled.div`
  color: ${THEME.COLORS.CONTENT_A};
  display: flex;
  flex-direction: column;
  padding: ${THEME.SPACING.TINO} 0;
  gap: ${THEME.SPACING.TINO};
  border-bottom: 1px solid ${hexToRgbA(THEME.COLORS.CONTENT_PRIMARY, THEME.OPACITY.GHOSTLY)};
`;

export const MenuTitle = styled.div`
  ${HeadlineFont};
`;

export const MenuSubtitle = styled.div`
  ${CaptionFont};
  opacity: ${THEME.OPACITY.MED};
`;

export const MenuLabel = styled.div`
  ${OverlineFont};
  color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.MED)};
  text-transform: uppercase;
  padding: 0 ${THEME.SPACING.BASE};
  margin-top: ${THEME.SPACING.TINO};
`;
