import log from 'loglevel';
import { MatchFlowFailedWebsocketDto } from '../../match/dto/socket/match-flow-failed.socket';
import { PositionResponse } from '../dto/response/position.response';
import { PositionRunStatusEnum } from '../../../../../enums/position-run-status.enum';
import { getMatchingStatus } from '../transformers/position-response.transformer';

const logger = log.getLogger('POSITION_TOOLKIT_API_MATCH_FLOW_FAILED_WEBSOCKET_LISTENERS');

export const matchFlowFailedPositionCacheWebsocketListeners = (
  socketPayload: MatchFlowFailedWebsocketDto,
  updateCachedData: (args: (position: PositionResponse) => void) => void,
): void => {
  logger.log('Got MatchFlowFailed event at getPositionById request', { socketPayload });
  updateCachedData((position: PositionResponse) => {
    const { positionId, noResults, deadEndRecommendations } = socketPayload;
    if (positionId === position.id) {
      position.latestRun = {
        noResults: !!noResults,
        status: PositionRunStatusEnum.Failed,
        triggeredAt: position.latestRun?.triggeredAt || new Date().toISOString(),
        deadEndRecommendations,
      };
    }
  });
};

export const matchFlowFailedPositionsCacheWebsocketListeners = (
  socketPayload: MatchFlowFailedWebsocketDto,
  updateCachedData: (args: (positions: PositionResponse[]) => void) => void,
): void => {
  logger.log('Got MatchFlowFailed event at getPositions request', { socketPayload });
  updateCachedData((positions: PositionResponse[]) => {
    const { positionId, noResults, deadEndRecommendations } = socketPayload;
    const position = positions.find(({ id }) => id === positionId);
    if (positionId === position?.id) {
      position.latestRun = {
        noResults: !!noResults,
        status: PositionRunStatusEnum.Failed,
        triggeredAt: position.latestRun?.triggeredAt || new Date().toISOString(),
        deadEndRecommendations,
      };
      position.matchingStatus = getMatchingStatus(position);
    }
  });
};
