import styled  from 'styled-components';
import { THEME } from '../../../../../themes';
import { hexToRgbA } from '../../../../../utils/colors';
import { OverlineFont, Subtitle1Font } from '../../../../../themes/fonts';
import { ASSETS } from '../../../../../assets';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PreferencesItem = styled.div`
  height: 48px;
  padding: 0 ${THEME.SPACING.MEDIUM};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  
  :hover {
    background-color: ${hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.GHOSTLY)};
  }
`;

export const PreferencesItemTitle = styled.div<{ isActive: boolean }>`
  color: ${({ isActive }) => isActive ? THEME.COLORS.ACCENT : hexToRgbA(THEME.COLORS.CONTENT_PRIMARY, THEME.OPACITY.MED)};
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: ${THEME.SPACING.TINY};
  ${Subtitle1Font};
  font-weight: 600;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: ${THEME.SPACING.TINY};
`;

export const Label = styled.span`
  ${OverlineFont};
  opacity: ${THEME.OPACITY.MED};
  font-weight: 600;
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const CampaignIcon = styled(ASSETS.FOLDER_LIGHTNING)`
  width: 15px;
  height: 16px;
  fill: currentColor;
`;

export const GenAiIcon = styled(ASSETS.STARS_BUBBLE_FILL)`
  width: 16px;
  height: 16px;
  fill: currentColor;
`;

export const AutoIcon = styled(ASSETS.TOGGLE_ON)`
  width: 29px;
  height: 16px;
  fill: ${hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.LOW)};
`;

export const ManualIcon = styled(ASSETS.TOGGLE_OFF)`
  width: 29px;
  height: 16px;
  fill: ${hexToRgbA(THEME.COLORS.GREY, THEME.OPACITY.LOW)};
`;
