import { useMemo } from 'react';
import { WIDGET_TYPE } from '../../../../../../pages/match-page/match.page.const';
import { CompanyLayoffTalent } from './company-layoff-talent/company-layoff-talent.component';
import { PersonalTenureComparison } from './personal-tenure-comparison/personal-tenure-comparison.component';
import { WidgetWrapper } from './move-meter-sub-widgets-container.styled';

const MoveMeterChildWidgetsContainer = ({ childWidgets }: MoveMeterSubWidgetsContainerProps): JSX.Element => {
  const renderCompanyLayoffTalent = (widgetData: CompanyInfoData) => {
    return (
      <CompanyLayoffTalent companyName={ widgetData?.companyName } />
    );
  };

  const renderPersonalTenureComparison = () => {
    return <PersonalTenureComparison />;
  };

  const renderSubWidget = (widget: ChildWidget) => {
    let widgetComponent;
    switch (widget.type) {
      case WIDGET_TYPE.WTM_COMPANY_LAYOFFS:
      case WIDGET_TYPE.WTM_COMPANY_LAYOFF_TALENT: {
        if (!widget.data) {
          return null;
        }

        widgetComponent = renderCompanyLayoffTalent(widget.data);
        break;
      }
      case WIDGET_TYPE.PERSONAL_TENURE_COMPARISON: {
        widgetComponent = renderPersonalTenureComparison();
        break;
      }
      default: {
        return null;
      }
    }

    return (
      <WidgetWrapper>{ widgetComponent }</WidgetWrapper>
    );
  };

  const renderedChildWidgets = useMemo(() => (
    childWidgets.map((widget) => (
      <div key={ `MOVE_METER_CHILD_WIDGET_${widget.type}` }>{ renderSubWidget(widget) }</div>
    ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [childWidgets]);

  return (
    <div>{ renderedChildWidgets }</div>
  );
};

export default MoveMeterChildWidgetsContainer;
