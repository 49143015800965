import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { AcknowledgementType } from '../enums/acknowledgement-type.enum';
import ProDowngradedDialog
  from '../components/dialogs/time-to-pay-dialogs/pro-downgraded-dialog/pro-downgraded-dialog.component';
import MatchesWereAlmostGoneDialog
  from '../components/dialogs/matches-were-almost-gone-dialog/matches-were-almost-gone.component';
import { useAnalyticsContext } from '../contexts/analytics/useAnalyticsContext';
import {
  ANALYTICS_EVENTS, COMPONENT_NAME, ELEMENT_TYPE, EVENT_GROUP,
} from '../consts/analytics';
import { AppRouting } from '../services/appRoutingResolver';
import { parseUrlValueByKey } from '../utils';
import { useMyUserSelector } from '../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { useDeleteAccountAcknowledgementById } from '../store/rtk-query/apis/user/hooks/delete-account-aknowledgement.hook';
import { AcknowledgementResponse } from '../@types/api';
import { useSeeAcknowledgements } from './acknowledgements-react-qury.hooks';
import { useShouldLockFeature } from './should-lock-feature.hook';

const getAcknowledgementsMap = (acknowledgements: AcknowledgementResponse[], positionId?: string): Record<string, AcknowledgementResponse | undefined> => {
  const endOfPremiumAcknowledgement = acknowledgements.find((ack) => {
    return ack.type === AcknowledgementType.EndOfPremium;
  });
  const matchesWereAlmostGoneAcknowledgement = acknowledgements.find((ack) => {
    if (ack.positionId === positionId) {
      return ack.type === AcknowledgementType.MatchesWereAlmostGone;
    }

    return false;
  });

  const firstTimeExperienceAcknowledgement = acknowledgements.find((ack) => {
    if (ack.positionId && ack.positionId === positionId) {
      return ack.type === AcknowledgementType.FirstTimeExperience;
    }

    return false;
  });

  return { endOfPremiumAcknowledgement, matchesWereAlmostGoneAcknowledgement, firstTimeExperienceAcknowledgement };
};

export const useAcknowledgementsDialogs = (
): Record<string, JSX.Element> => {
  const location = useLocation();
  const { data: { acknowledgements } } = useMyUserSelector();
  const isLocked = useShouldLockFeature();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const deleteAccountAcknowledgementById = useDeleteAccountAcknowledgementById();
  const { mutate: seeAcknowledgement } = useSeeAcknowledgements();
  const [endOfPremiumDialogAckIdOpen, setEndOfPremiumDialogAckIdOpen] = useState<string>('');
  const [matchesWereAlmostGoneAckIdOpen, setMatchesWereAlmostGoneAckIdOpen] = useState<string>('');
  const positionId = parseUrlValueByKey(location.pathname, AppRouting.POSITIONS_PATH_PARAM_KEY);

  useEffect(() => {
    if (!positionId && matchesWereAlmostGoneAckIdOpen) {
      deleteAccountAcknowledgementById(matchesWereAlmostGoneAckIdOpen);
      setMatchesWereAlmostGoneAckIdOpen('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionId]);

  useEffect(() => {
    if (matchesWereAlmostGoneAckIdOpen) {
      const eventData = {
        eventName: ANALYTICS_EVENTS.NOTIFICATION_SHOWN,
        eventGroup: EVENT_GROUP.IMP,
        componentName: COMPONENT_NAME.MATCHES_ALMOST_GONE,
      };
      analyticsTrackEvent(eventData);
    }
    if (endOfPremiumDialogAckIdOpen) {
      const eventData = {
        eventName: ANALYTICS_EVENTS.PREMIUM_MODAL_SHOWN,
        eventGroup: EVENT_GROUP.IMP,
        componentName: COMPONENT_NAME.END_OF_PREMIUM_INDICATION,
      };
      analyticsTrackEvent(eventData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },  [endOfPremiumDialogAckIdOpen, matchesWereAlmostGoneAckIdOpen]);

  useEffect(() => {
    if (acknowledgements?.length) {
      const { endOfPremiumAcknowledgement, matchesWereAlmostGoneAcknowledgement } = getAcknowledgementsMap(acknowledgements, positionId);
      if (endOfPremiumAcknowledgement) {
        setEndOfPremiumDialogAckIdOpen(endOfPremiumAcknowledgement.id);

        seeAcknowledgement(endOfPremiumAcknowledgement.id);
      }
      if (matchesWereAlmostGoneAcknowledgement) {
        setMatchesWereAlmostGoneAckIdOpen(matchesWereAlmostGoneAcknowledgement.id);

        seeAcknowledgement(matchesWereAlmostGoneAcknowledgement.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acknowledgements, positionId]);

  const onCloseMatchesWereAlmostGoneDialog = () => {
    setMatchesWereAlmostGoneAckIdOpen('');
    const eventData = {
      eventName: ANALYTICS_EVENTS.CLICKED_ON_NOTIFICATION,
      eventGroup: EVENT_GROUP.CLICK,
      componentName: COMPONENT_NAME.MATCHES_ALMOST_GONE,
      componentElementType: ELEMENT_TYPE.CLOSE,
    };
    analyticsTrackEvent(eventData);
    deleteAccountAcknowledgementById(matchesWereAlmostGoneAckIdOpen);
  };

  const onCloseProDowngradedDialog = () => {
    setEndOfPremiumDialogAckIdOpen('');
    const eventData = {
      eventName: ANALYTICS_EVENTS.CLICKED_ON_PREMIUM_MODAL,
      eventGroup: EVENT_GROUP.IMP,
      componentName: COMPONENT_NAME.END_OF_PREMIUM_INDICATION,
      componentElementType: ELEMENT_TYPE.CLOSE,
    };
    analyticsTrackEvent(eventData);
    deleteAccountAcknowledgementById(endOfPremiumDialogAckIdOpen);
  };

  const onActionProDowngradedDialog = () => {
    const eventData = {
      eventName: ANALYTICS_EVENTS.CLICKED_ON_PREMIUM_MODAL,
      eventGroup: EVENT_GROUP.IMP,
      componentName: COMPONENT_NAME.END_OF_PREMIUM_INDICATION,
      componentElementType: ELEMENT_TYPE.GO_PRO,
    };
    analyticsTrackEvent(eventData);
  };

  let dialogs = {};

  if (isLocked) {
    dialogs = {
      EndOfPremiumDialog: (
        <ProDowngradedDialog
          isOpen={ !!endOfPremiumDialogAckIdOpen }
          onClose={ onCloseProDowngradedDialog }
          actionClickHandler={ onActionProDowngradedDialog }
        />
      ),
    };
  }

  return {
    ...dialogs,
    MatchesWereAlmostDialog: (
      <MatchesWereAlmostGoneDialog
        isOpen={ !!matchesWereAlmostGoneAckIdOpen }
        onClose={ onCloseMatchesWereAlmostGoneDialog }
      />
    ),
  };
};

export const useRenderAcknowledgementsDialogs = (AcknowledgementsDialogs: Record<string, JSX.Element>): JSX.Element[] => Object.keys(AcknowledgementsDialogs)
  .map((dialogKey) => (
    <Fragment key={ dialogKey }>
      { AcknowledgementsDialogs[dialogKey] }
    </Fragment>
  ));
