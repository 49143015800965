import { accountQuery } from '../account.toolkit-api';
import { AccountInfoResponse } from '../dto/response/account-info.response';

export const useGetAccountByIdQuery = (accountId = '', skip = false): AppSelectorType<AccountInfoResponse> => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch,
  } = accountQuery.useGetAccountByIdQuery(
    accountId,
    {
      skip: !accountId || skip,
    },
  );

  return {
    data: data || {} as AccountInfoResponse,
    error,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
