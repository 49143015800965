import styled from 'styled-components';
import { THEME } from '../../../../../../../themes';
import { CaptionFont } from '../../../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../../../utils/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${THEME.SPACING.TINY};
  color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.MED)};
`;

export const ImageWrapper = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  flex: 1;
`;

export const Description = styled.div`
  ${CaptionFont};
  line-height: 16px;
  font-weight: 300;
`;

export const CompanyTrendContainer = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
`;
