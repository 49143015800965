/* eslint-disable react/jsx-props-no-spreading */
import Dialog from '../base-dialog/base-dialog.component';
import { PerfectButtonHoverVariants, PerfectButtonVariants } from '../../Common/Buttons/perfect-base-button/perfect-base-button.enums';
import {
  ActionsContainer, ConfirmButton, Container, Description, CancelButton, Title,
} from './confirmation-dialog.styled';

const ConfirmationDialog = ({
  isOpen,
  title,
  description,
  confirmHandler,
  cancelHandler,
  closeHandler,
  confirmButtonTitle,
  cancelButtonTitle,
  ...otherProps
}: ConfirmationDialogProps): JSX.Element => {
  return (
    <Dialog
      isOpen={ isOpen }
      onClose={ closeHandler }
      { ...otherProps }
    >
      <Container>
        <Title>
          { title }
        </Title>
        <Description>
          { description }
        </Description>
      </Container>
      <ActionsContainer>
        <ConfirmButton
          variant={ PerfectButtonVariants.Fill }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          onClick={ () => {
            closeHandler();
            confirmHandler();
          } }
        >
          { confirmButtonTitle }
        </ConfirmButton>
        <CancelButton
          variant={ PerfectButtonVariants.Ghost }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          onClick={ () => {
            closeHandler();
            cancelHandler?.();
          } }
        >
          { cancelButtonTitle }
        </CancelButton>
      </ActionsContainer>
    </Dialog>
  );
};

export default ConfirmationDialog;
