import { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SelectionPopup from '../selection-popup/selection-popup.container';
import STRING_KEYS from '../../../../../../../language/keys';
import MenuItem from '../../../../../../../components/Common/perfect-base-menu/menu-item/menu-item.component';
import { SelectionItem, SelectTypePopupProps } from './select-type-popup';
import { SELECTION_AUTOMATIC_ITEMS, SELECTION_MANUAL_ITEMS } from './select-type-popup.consts';
import {
  ItemsContainer, Label, ItemContainer, SoonIcon,
} from './select-type-popup.styled';

const SelectTypePopup = ({ onItemClickHandler }: SelectTypePopupProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const renderItems = (items: SelectionItem[]) => items.map(({
    translationKey, disabled, Icon, type,
  }) => (
    <Fragment key={ translationKey }>
      <MenuItem
        key={ translationKey }
        disabled={ disabled }
        onClick={ () => onItemClickHandler(type) }
      >
        <ItemContainer>
          <Icon />
          <Trans i18nKey={ translationKey } />
          { disabled && <SoonIcon /> }
        </ItemContainer>
      </MenuItem>
    </Fragment>
  ));

  return (
    <SelectionPopup
      title={ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.TITLE) }
      description={ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.DESCRIPTION) }
    >
      <ItemsContainer>
        <Label>{ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.AUTOMATIC_LABEL) }</Label>
        {renderItems(SELECTION_AUTOMATIC_ITEMS) }
      </ItemsContainer>
      <ItemsContainer>
        <Label>{ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.MANUAL_LABEL) }</Label>
        { renderItems(SELECTION_MANUAL_ITEMS) }
      </ItemsContainer>
    </SelectionPopup>
  );
};

export default SelectTypePopup;
