import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { CaptionFont, Subtitle1Font } from '../../../../themes/fonts';
import { ASSETS } from '../../../../assets';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  margin-top: ${THEME.SPACING.MEDIUM};
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINO};
`;

export const ButtonsRow = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINO};
`;

export const Button = styled.div<{ disabled?: boolean, centerItems?: boolean }>`
  ${Subtitle1Font};
  font-weight: 600;
  padding: 0 ${THEME.SPACING.MEDIUM};
  height: 64px;
  background-color: ${THEME.COLORS.LIGHT_GREY};
  border: 1px solid ${THEME.COLORS_RGB.PRIMARY_GHOST};
  border-radius: ${THEME.RADIUS.ROUND};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ centerItems }) => centerItems ? 'center' : 'space-between'};
  opacity: ${({ disabled }) => disabled ? THEME.OPACITY.LOW : 1};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  
  :hover {
    background-blend-mode: darken;
    background-image: linear-gradient(rgb(0 0 0/10%) 0 0);
  }
`;

export const ButtonTextAndImageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
`;

export const AtsComeetLogoImage = styled.img`
  width: 101px;
`;

export const AtsGreenhouseLogoImage = styled.img`
  width: 108px;
`;

export const AtsAshbyLogoImage = styled.img`
  width: 73px;
`;

export const AtsLogosImage = styled.img`
   width: 360px;
`;

export const ArrowIcon = styled(ASSETS.ARROW_RIGHT)`
  width: 7px;
  height: 14px;
  fill: ${THEME.COLORS.ACCENT};
`;

export const TwoWayAtsContainer = styled.div`
  margin-top: ${THEME.SPACING.BASE};
  display: flex;
  gap: ${THEME.SPACING.TINY};
`;

export const TwoWayAtsInfoText = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;

export const CheckedIcon = styled(ASSETS.CHECKED_GHOST)`
  width: 6px;
  fill: ${THEME.COLORS.ACCENT};
`;

export const UnCheckedIcon = styled(ASSETS.CHECKED_GHOST)`
  width: 6px;
  fill: ${THEME.COLORS.GREY};
`;
