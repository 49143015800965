import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../language/keys';
import CheckboxButtonGroupContainer from '../Common/checkbox-button-group/checkbox-button-group.component';
import { CompanySizeTierEnum } from '../../enums/company-size-tier.enum';
import { CheckboxButtonGroupItem } from '../Common/checkbox-button-group/checkbox-button-group';
import { CompanySizeCheckboxContainerProps } from './company-size-checkbox-container';
import {
  LargeCompanySizeIcon,
  MediumCompanySizeIcon,
  SmallCompanySizeIcon,
  VeryLargeCompanySizeIcon,
} from './company-size-checkbox-container.styled';

const CompanySizeCheckboxContainer = ({ selectedItems, changeHandler }: CompanySizeCheckboxContainerProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const experienceItems: CheckboxButtonGroupItem<CompanySizeTierEnum>[] = [
    {
      id: CompanySizeTierEnum.Small,
      title: translate(STRING_KEYS.COMPANY_SIZE[CompanySizeTierEnum.Small]),
      Icon: <SmallCompanySizeIcon />,
    },
    {
      id: CompanySizeTierEnum.Medium,
      title: translate(STRING_KEYS.COMPANY_SIZE[CompanySizeTierEnum.Medium]),
      Icon: <MediumCompanySizeIcon />,
    },
    {
      id: CompanySizeTierEnum.Large,
      title: translate(STRING_KEYS.COMPANY_SIZE[CompanySizeTierEnum.Large]),
      Icon: <LargeCompanySizeIcon />,
    },
    {
      id: CompanySizeTierEnum.VeryLarge,
      title: translate(STRING_KEYS.COMPANY_SIZE[CompanySizeTierEnum.VeryLarge]),
      Icon: <VeryLargeCompanySizeIcon />,
    },
  ];

  return (
    <CheckboxButtonGroupContainer
      items={ experienceItems }
      selectedItems={ selectedItems }
      changeHandler={ changeHandler }
    />
  );
};

export default CompanySizeCheckboxContainer;
