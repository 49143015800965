import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { ASSETS } from '../../../../../assets';

export const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.XLOOSE};
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MEDIUM};
`;

export const AiIcon = styled(ASSETS.AI)`
  width: 16px;
`;
