import styled from 'styled-components';
import { THEME } from '../../../themes';
import { ASSETS } from '../../../assets';
import { Body1Font, Title3Font } from '../../../themes/fonts';

export const ClosePositionModalContainer = styled.div`
  position: absolute;
  top: calc(50% - 219px);
  left: calc(50% - 240px);
  width: 480px;
  height: 438px;
  background-color: ${THEME.COLORS.PURE_WHITE};
  padding: ${THEME.SPACING.TINY} 100px ${THEME.SPACING.MEDIUM} 100px;
  border-radius: ${THEME.RADIUS.CURVY};
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  cursor: default;
  z-index: 101;
  -webkit-clip-path: inset(0 0 0 0 round 32px);
  clip-path: inset(0 0 0 0 round 32px);
`;

export const TopNavWrapper = styled.div`
  display: block;
  margin-bottom: ${THEME.SPACING.LOOSE};
`;

export const CloseButton = styled.div`
  display: inline-block;
  position: absolute;
  right: ${THEME.SPACING.BASE};
  top: ${THEME.SPACING.MICRO};
  width: 32px;
  height: 32px;
  margin-top: ${THEME.SPACING.TINY};
  border-radius: ${THEME.RADIUS.CIRCLE};
  background: ${THEME.COLORS.LIGHT_GREY} url(${ASSETS.CLOSE_DIALOG}) no-repeat center center;
  cursor: pointer;
  transition: all 0.2s;

  :hover {
    opacity: 0.8;
  }
`;

export const BackButton = styled.div`
  display: inline-block;
  position: absolute;
  left: ${THEME.SPACING.BASE};
  top: ${THEME.SPACING.MICRO};
  width: 32px;
  height: 32px;
  margin-top: ${THEME.SPACING.TINY};
  float: left;
  border-radius: ${THEME.RADIUS.CIRCLE};
  background: ${THEME.COLORS.LIGHT_GREY} url(${ASSETS.BUTTON_ARROW_LEFT}) no-repeat center center;
  cursor: pointer;
  transition: all 0.2s;
  transform: rotate(180deg);

  :hover {
    opacity: 0.8;
  }
`;

export const Title = styled.div`
  ${Title3Font};
  font-weight: bold;
  white-space: pre-line;
  text-align: center;
  line-height: 1.5;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 15px;
  width: 450px;
  border-radius: 32px;
`;

export const ErrorWrapper = styled.div`
  ${Body1Font};
  color: ${THEME.COLORS.ALERT};
  position: absolute;
  bottom: ${THEME.SPACING.BASE};
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: ${THEME.SPACING.BASE};
  padding-right: ${THEME.SPACING.BASE};
`;

export const ModalBackdrop = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  cursor: default;
  background-color: rgba(0,0,0,0.4);
`;
