import { Tooltip } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import { THEME } from '../../../themes';

export const PerfectTooltip = withStyles({
  arrow: {
    color: `${THEME.COLORS.PRIMARY_DARK}`,
    backgroundColor: `${THEME.COLORS.PRIMARY_DARK}`,
    borderRadius: `${THEME.RADIUS.CIRCLE}`,
    position: 'absolute',
    top: '100%',
    height: '10px',
    width: '10px',
    marginTop: '-7px',
  },
  tooltip: {
    color: `${THEME.COLORS.SURFACE_PRIMARY}`,
    backgroundColor: `${THEME.COLORS.PRIMARY_DARK}`,
    padding: `${THEME.SPACING.TINY}`,
    textAlign: 'center',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: 'normal',
    letterSpacing: '0.4px',
    position: 'relative',
    top: '-10px',
  },
})(Tooltip);
