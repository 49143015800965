import {
  SyntheticEvent,
  KeyboardEvent,
  RefObject,
  ClipboardEvent,
} from 'react';
import { VerificationRect } from './verification-input.styled';

type VerificationInputProps = {
  inputRef: RefObject<HTMLInputElement>,
  value: string,
  onPaste: (event: ClipboardEvent<HTMLInputElement>) => void,
  onChange: (event: SyntheticEvent) => void,
  onKeyDown: (event: KeyboardEvent) => void,
}

const VerificationInput = ({
  inputRef,
  value,
  onPaste,
  onChange,
  onKeyDown,
}: VerificationInputProps): JSX.Element => {
  return (
    <VerificationRect
      ref={ inputRef }
      value={ value }
      onPaste={ onPaste }
      onChange={ onChange }
      onKeyDown={ onKeyDown }
    />
  );
};

export default VerificationInput;
