export const COLORS_FILTERS = {
  DARK_PURPLE:
  'invert(19%) sepia(49%) saturate(2868%) hue-rotate(234deg) brightness(81%) contrast(104%)',
  LIGHT_GREEN:
  'invert(60%) sepia(17%) saturate(1533%) hue-rotate(110deg) brightness(90%) contrast(94%)',
  BLACK:
  'invert(0%) sepia(100%) saturate(0%) hue-rotate(227deg) brightness(96%) contrast(106%)',
  DARK_CONTENT:
  'filter: invert(7%) sepia(11%) saturate(1428%) hue-rotate(213deg) brightness(97%) contrast(92%)',
};
