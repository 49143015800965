/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import log from 'loglevel';
import { timeoutPromise } from '../utils';
import rudderstackAnalyticsAgent, { IdentifyUserPayloadType } from '../services/rudderStackAnalyticsAgent';
import { CONFIG } from '../config';
import { setRudderstackLoadingCompleted } from '../store/slices/app-state/app-state.toolkit-slice';
import { useGetMyAccountSelector } from '../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import { useMyUserSelector } from '../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { useAppState } from '../store/selectors';
import {
  useGetEmailSenderIntegrationStatus,
} from '../store/rtk-query/apis/outreach/hooks/get-email-sender.query-hook';

const logger = log.getLogger('INITIALIZE_RUDDER_STACK_HOOK');

export const useInitializeRudderStack = (skipInitialization: boolean): void => {
  const dispatch = useDispatch();
  const [rudderstackInitialized, setRudderstackInitialized] = useState(false);

  const { userIdentifyMethod } = useAppState();
  const { data: account, isSuccess: isAccountSuccess } = useGetMyAccountSelector();
  const { data: user, isSuccess: isUserSuccess } = useMyUserSelector();
  const emailSenderIntegrationStatus = useGetEmailSenderIntegrationStatus();

  const userIdentifyPayload = useMemo<IdentifyUserPayloadType>(() => ({
    userId: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    avatar: user.profileImage,
    accountId: user.accountId,
    accountName: account.displayName,
    accountPlan: account.plan?.pricingPlan,
    method: userIdentifyMethod,
    firstLogin: false,
    intercomUserHash: user.intercomUserHash,
    emailConnectionStatus: emailSenderIntegrationStatus,
    accountAtsConnected: !!account.atsIntegration?.isActive,
  }), [isAccountSuccess, isUserSuccess, emailSenderIntegrationStatus]);

  useEffect(() => {
    if (skipInitialization) {
      dispatch(setRudderstackLoadingCompleted());
    }
  }, [skipInitialization]);

  useEffect(() => {
    const isUserDataLoaded = (isAccountSuccess || user.isOnboarding) && isUserSuccess && emailSenderIntegrationStatus;
    if (rudderstackInitialized && isUserDataLoaded) {
      logger.info('Call to identify user', { userIdentifyPayload });
      rudderstackAnalyticsAgent
        .identify(userIdentifyPayload)
        .then(() => {
          logger.debug('rudderstack-agent identify process was finished successfully');
        });
    }
  }, [rudderstackInitialized]);

  useEffect(() => {
    // wait for rudderstack agent loading, to prevent sending events when it is not ready.
    // if rudderstack won't load within {RUDDERSTACK_LOADING_TIMEOUT} seconds,
    // we'll continue anyway
    // the rudderstack agent can skip initialization if the hook get skip flag, we used is for super users
    if (!skipInitialization && !rudderstackAnalyticsAgent.isInitialized()) {
      timeoutPromise(
        rudderstackAnalyticsAgent.init(
          CONFIG.RUDDERSTACK_WRITE_KEY,
          CONFIG.RUDDERSTACK_DATA_PLANE_URL,
          CONFIG.RUDDERSTACK_API_URL,
        ),
        CONFIG.RUDDERSTACK_LOADING_TIMEOUT * 1000,
      )
        .then(() => {
          // rudderstack agent was loaded and ready to use
          logger.debug('rudderstack-agent loaded successfully and ready for use');
          setRudderstackInitialized(true);
        })
        .catch((error) => {
          // rudderstack agent failed to load
          // OR timeout was exceeded
          const errorMessage = (error as Error).message;
          logger.warn('rudderstack-agent loading failed.', errorMessage);
        })
        .finally(() => {
          logger.debug('setting rudderstack-agent loading as completed');
          // in each case (success/error), we continue
          dispatch(setRudderstackLoadingCompleted());
        });
    }
  }, [dispatch, skipInitialization, isUserSuccess, isAccountSuccess]);
};
