/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import {
  Chip,
  Container,
  Quote,
  QuoteContainer,
  Text,
  Title,
  VIcon,
  XIcon,
} from './do-dont-card.styled';
import { DoDontCardProps } from './do-dont-card';
import { DoDontCardType } from './do-dont-card.enums';

const DoDontCard = ({
  type,
  quote,
  title,
  text,
  ...otherProps
}: DoDontCardProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const renderChip = () => {
    const Icon = type === DoDontCardType.Do ? <VIcon /> : <XIcon />;
    const translationKey = type === DoDontCardType.Do ? STRING_KEYS.DO : STRING_KEYS.DONT;

    return (
      <Chip cardType={ type }>
        { Icon }{ translate(translationKey) }
      </Chip>
    );
  };

  return (
    <Container { ...otherProps } >
      { renderChip() }
      <QuoteContainer>
        <Quote>“{ quote }”</Quote>
      </QuoteContainer>
      <Title>{ title }</Title>
      <Text>{ text }</Text>
    </Container>
  );
};

export default DoDontCard;
