import styled from 'styled-components';
import { THEME } from '../../../../themes';
import MenuItem from '../../../../components/Common/perfect-base-menu/menu-item/menu-item.component';
import { ASSETS } from '../../../../assets';
import { Body1Font, OverlineFont } from '../../../../themes/fonts';

export const ActionButtonWrapper = styled.div`
  width: fit-content;
  height: fit-content;
`;

export const FilterMenuItem = styled(MenuItem)`
  ${Body1Font};
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
  line-height: 18px;
  :hover,
  :focus {
    background-color: ${THEME.COLORS.ACCENT};
  }
`;

export const AtsMenuIcon = styled(ASSETS.INFINITY_ARROW_ICON)`
    width: 21px;
`;

export const ExportMatchesIcon = styled(ASSETS.DOWNLOAD)`
  width: 15px;
  height: 19px;
`;

export const AtsConnectedText = styled.span`
  ${OverlineFont};
  margin-left: ${THEME.SPACING.XLOOSE};
  color: ${THEME.COLORS.SUCCESS};
`;
