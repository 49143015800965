import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { Body1Font } from '../../../../../themes/fonts';

// Using fill property in parent for entire SVG color
export const StyledUserMenuRow = styled.div`
  width: 100%;
  display: flex;
  padding: 13px 61px 11px 16px;
  cursor: pointer;
  fill: ${THEME.COLORS.PRIMARY_DARK};
  color: ${THEME.COLORS.PRIMARY_DARK};
  :hover{
    fill: ${THEME.COLORS.PURE_WHITE};
    background-color: ${THEME.COLORS.ACCENT};
    color: ${THEME.COLORS.PURE_WHITE};
  }
`;

export const StyledUserMenuTitle = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${Body1Font};
  margin-left: ${THEME.SPACING.TINY};
`;
