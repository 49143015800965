import { SKILLS_TO_SHOW } from '../../skills-widget.component';
import { Skill, SkillChip } from './skill.styled';

type SkillItemProps = {
  skill: string,
  index: number,
  isEnableAnimation: boolean,
  askedByUser: boolean,
}

export const SkillItem = ({
  skill,
  index,
  isEnableAnimation,
  askedByUser,
}: SkillItemProps): JSX.Element => {
  return (
    <Skill
      index={ index }
      title={ skill }
      isEnableAnimation={ isEnableAnimation ||  index + 1 > SKILLS_TO_SHOW }
    >
      <SkillChip askedByUser={ askedByUser }>{skill}</SkillChip>
    </Skill>
  );
};
