import styled from 'styled-components';
import { Body1Font } from '../../../../../themes/fonts';
import MenuItem from '../../../../../components/Common/perfect-base-menu/menu-item/menu-item.component';
import { THEME } from '../../../../../themes';

export const FilterMenuItem = styled(MenuItem)`
  ${Body1Font};
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
  line-height: 18px;
  :hover,
  :focus {
    background-color: ${THEME.COLORS.ACCENT};
  }
`;

export const IconContainer = styled.div`
  width: 22px;
  text-align:center;
`;
