import styled  from 'styled-components';
import { Avatar as MuiAvatar } from '@mui/material';
import { THEME } from '../../../../../themes';
import {
  Body2Font, CaptionFont, OverlineFont, Subtitle2Font,
} from '../../../../../themes/fonts';
import { ASSETS, SOCIAL_ICONS } from '../../../../../assets';
import { hexToRgbA } from '../../../../../utils/colors';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { StartIconContainer } from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.styled';

const MAX_MESSAGE_BODY_HEIGHT_PX = 150;

const getBackgroundColor = (isSelfMessage: boolean, isNewMessage: boolean) => {
  if (isSelfMessage) {
    return THEME.COLORS.LIGHT_GREY;
  }

  if (isNewMessage) {
    return THEME.UNKNOWN_COLORS.ACCENT_005_HEX;
  }

  return THEME.UNKNOWN_COLORS.PRIMARY_005_HEX;
};

const createMessageBodyFade = (isSelfMessage: boolean, isNewMessage: boolean) => {
  const color = getBackgroundColor(isSelfMessage, isNewMessage);

  return `linear-gradient(to bottom, ${hexToRgbA(color, 0)}, ${hexToRgbA(color, 0.75)} 39%, ${color} 78%)`;
};

export const Container = styled.div<{ isSelfMessage: boolean, isNewMessage: boolean, isError: boolean }>`
  padding: ${THEME.SPACING.BASE};
  background-color: ${({ isSelfMessage, isNewMessage }) => getBackgroundColor(isSelfMessage, isNewMessage)};
  border: ${({ isError }) => isError ? `solid 1px ${hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER)}` : undefined};
  border-radius: ${THEME.RADIUS.ROUND};
  color: ${THEME.COLORS.CONTENT_A};
  position: relative;
`;

export const MessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MessageHeaderStartContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SenderAvatar = styled(MuiAvatar)`
  width: 32px !important;
  height: 32px !important;
  border-radius: ${THEME.RADIUS.CIRCLE};
  margin-right: ${THEME.SPACING.TINO};
`;

export const SenderName = styled.div`
  ${Subtitle2Font};
  font-weight: 600;
  margin-right: ${THEME.SPACING.TINY};
`;

export const SentTime = styled.div`
  ${CaptionFont};
`;

export const MessageHeaderEndContainer = styled.div`
  ${CaptionFont};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
  color: ${THEME.COLORS_RGB.PRIMARY_DARK_LOW};
`;

export const NewLabel = styled(ASSETS.NEW_LABEL)`
  fill: ${THEME.COLORS.ACCENT};
`;

export const MessageTitle = styled.div`
  ${Subtitle2Font};
  font-weight: 600;
  margin-top: ${THEME.SPACING.BASE};
`;

export const MessageBody = styled.div<{ maxHeight?: number | null, isLocked?: boolean }>`
  ${Body2Font};
  font-weight: 300;
  margin-top: ${THEME.SPACING.BASE};
  max-height: ${({ maxHeight }) => `${(maxHeight || MAX_MESSAGE_BODY_HEIGHT_PX)}px`};
  filter: ${({ isLocked }) => isLocked ? 'blur(3px)' : undefined};
  transition: all 200ms;
  overflow: hidden;
  position: relative;
`;

export const MessageBodyFade = styled.div<{ isSelfMessage: boolean, isVisible: boolean, isNewMessage: boolean }>`
  width: 100%;
  position: absolute;
  height: 69px;
  bottom: 0;
  background-image: ${({ isSelfMessage, isNewMessage }) => createMessageBodyFade(isSelfMessage, isNewMessage)};
  opacity: ${({ isVisible }) => isVisible ? THEME.OPACITY.HIGHEST : THEME.OPACITY.TRANSPARENT};
  z-index : ${({ isVisible }) => isVisible ? undefined : -1};
  transition: all 100ms;
  cursor: pointer;
`;

export const EmailIcon = styled(ASSETS.EMAIL)`
  width: 12.3px;
  height: 10px;
`;

export const LinkedinIcon = styled(SOCIAL_ICONS.LINKED_IN)`
  width: 16px;
  height: 16px;
  color: ${THEME.COLORS_RGB.PRIMARY_DARK_LOW};
`;

export const LightningIcon = styled(ASSETS.LIGHTNING)`
  width: 8px;
  height: 12px;
`;

export const TaskIcon = styled(ASSETS.TASK)`
  width: 12px;
  height: 12px;
`;

export const AlarmIcon = styled(ASSETS.ALARM)`
  width: 15px;
  height: 14px;
`;

export const MessageMetadata = styled.span<{ isBold?: boolean }>`
  ${CaptionFont};
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
  text-transform: capitalize;
`;

export const SeeMoreButton = styled.div`
  ${OverlineFont};
  letter-spacing: 0.33px;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  left: 0;
  color: ${THEME.COLORS.CONTENT_A};
  text-transform: capitalize;
`;

export const AutomatedMessageLabel = styled.div`
  color: ${THEME.COLORS.SECONDARY};
`;

export const DownloadAttachmentButton = styled(BaseButton)`
    color: ${THEME.COLORS.ACCENT};
    font-weight: 600;
    background-color: inherit;
    padding-top: ${THEME.SPACING.BASE};

    ${StartIconContainer} {
        margin-left: 0;
    }
`;

export const DownloadAttachmentIcon = styled(ASSETS.DOWNLOAD_FILLED)`
    height: 12px;
    width: 10px;
`;
