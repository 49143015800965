import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { CaptionFont } from '../../../../themes/fonts';

export const RuleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap:10px;
  height: 19px;
`;
export const RuleTitle = styled.div`
  ${CaptionFont}
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
`;

export const IconWrapper = styled.div`
  svg {
    color: ${THEME.COLORS.SUCCESS};
    fill: ${THEME.COLORS.SUCCESS};
    width: 12px;
    height: 12px;
  }
`;

export const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: ${THEME.RADIUS.CIRCLE};
  border: 2px solid ${THEME.COLORS.ACCENT};
`;
