/* eslint-disable react/jsx-props-no-spreading */
import {
  forwardRef, Ref, KeyboardEvent, MouseEvent,
} from 'react';
import { KEYBOARD_KEYS } from '../../../../consts/keyboard_keys';
import { ITEM_ROLE } from '../perfect-base-menu.consts';
import { MenuItemContainer, SelectedIcon } from './menu-item.styled';
import { MenuItemProps } from './menu-item';
import { MenuItemSelectedVariant } from './menu-item.enum';

const MenuItem = forwardRef((
  props: MenuItemProps,
  ref: Ref<HTMLLIElement>,
) => {
  const {
    onClick,
    disabled = false,
    isSubMenu = false,
    children,
    selected,
    selectedVariant = MenuItemSelectedVariant.BackgroundColor,
    ...otherProps
  } = props;

  const handleOnClick = (event: MouseEvent<HTMLLIElement> | KeyboardEvent<HTMLLIElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (disabled) {
      return;
    }
    onClick?.();
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLLIElement>) => {
    if (disabled) {
      return;
    }
    if (event.key === KEYBOARD_KEYS.ENTER) {
      handleOnClick(event);
    }
  };

  return (
    <MenuItemContainer
      role={ !disabled ? ITEM_ROLE : undefined }
      tabIndex={ !disabled ? 0 : undefined }
      onClick={ handleOnClick }
      onKeyDown={ handleOnKeyDown }
      ref={ ref }
      disabled={ disabled }
      isSubMenu={ isSubMenu }
      selectedVariant={ selectedVariant }
      { ...otherProps }
    >
      { selected && <SelectedIcon /> }
      { children }
    </MenuItemContainer>
  );
});

MenuItem.displayName = 'MenuItem';

export default MenuItem;
