import styled from 'styled-components';

export const MilestoneCelebrationsContainer = styled.div`
  display: flex;
  position: relative;
`;

export const ConfettiWrapper = styled.div<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => isVisible ? 1 : 0};
  transition: opacity 800ms;
`;
