import log from 'loglevel';
import { CONFIG } from '../config';
import { getSubDomainMinimumLength } from './environment';
import { getRandomIntBetweenRange } from './number';

const logger = log.getLogger('UTILS');

/**
 * Get the subdomain part of the url (always lower-cased)
 * for example:
 *    url: devalore.perfectmatch.com
 *    output: devalore
 * @param hostName string of the hostname part in the url
 * @returns string | null if no subdomain found
 */
export const getSubDomain = (hostName: string): string => {
  const hostNameParts = hostName.split('.');

  if (hostNameParts.length < getSubDomainMinimumLength()) {
    return '';
  }

  return hostNameParts[0].toLowerCase();
};

/**
 * Check if the user should be redirected to a global login page.
 * This should happen if there is no sub-domain or its a reserved sub-domain.
 * (unknown url with trailing path should be handled as a not-found page)
 * @param location window.location object
 * @returns boolean true - if user should be redirected to Global Login url
 */
export const shouldRedirectToGlobalLogin = (location: Location): boolean => {
  const currentSubDomain = getSubDomain(location.hostname);

  logger.debug('Current subdomain:', currentSubDomain);

  return CONFIG.RESERVED_SUBDOMAINS.includes(currentSubDomain);
};

/**
 * Add a sub-domain part to a url.
 * for example:
 *    perfectmatch.com/match/page -> auth.perfectmatch.com/match/page
 *    dev.perfectmatch.co.il/match/page -> auth.dev.perfectmatch.co.il/match/page
 * @param url relevant string for output url
 * @param host host part of the url (window.location.host)
 * @param subDomain string to be added to host name
 * @returns string with the url including the new sub domain
 */
export const addSubDomainToUrl = (url: string, host: string, subDomain: string): string => {
  return url.replace(host, `${subDomain}.${host}`);
};

/**
 * Add a sub-domain part to the url.
 * for example:
 *    perfectmatch.com -> auth.perfectmatch.com
 *    dev.perfectmatch.co.il -> auth.dev.perfectmatch.co.il
 * @param location window.location object
 * @param subDomain string to be added to the host name
 * @returns string with the full url including the new sub domain
 */
export const addSubDomain = (location: Location, subDomain: string): string => {
  return addSubDomainToUrl(location.href, location.host, subDomain);
};

/**
 * Replace the first part of the host-name
 * for example: login.perfectmatch.com -> auth.perfectmatch.com
 * @param url
 * @param oldSubDomain to be replaced
 * @param newSubDomain to take place in result
 * @returns string - url with the newSubDomain
 */
export const replaceSubDomain = (
  url: string, oldSubDomain: string, newSubDomain: string,
): string => {
  return url.replace(`//${oldSubDomain}.`, `//${newSubDomain}.`);
};

export const getRandomCensoredAvatarUrl = (): string => {
  const randomNumber = getRandomIntBetweenRange(1, 11);

  return `${CONFIG.TALENT_AVATAR_URL}/${randomNumber}`;
};
