import { UsePositionPerformance } from '../position-performance-funnel.hook';
import {
  FUNNEL_GRAPH_OPTIMAL_LIKED_PEOPLE,
  FUNNEL_TAG_HIGH_OPEN_RATE,
  FUNNEL_TAG_MAX_GREAT_START,
  FUNNEL_TAG_MAX_KEEP_IT_UP,
  FUNNEL_TAG_MAX_REPLAY_RATE_MED,
  FUNNEL_TAG_MIN_GREAT_START,
  FUNNEL_TAG_MIN_KEEP_IT_UP,
  FUNNEL_TAG_MIN_REPLAY_RATE_MED,
  FUNNEL_TAG_MIN_TALENTS_COMPANIES_CARDINALITY,
  FUNNEL_TAG_REPLAY_RATE_LOW,
  FUNNEL_TAG_TALENTS_COMPANIES_CARDINALITY_RATE,
  FUNNEL_TAG_WELL_DONE,
  FUNNEL_TAG_MIN_OPTIMAL_OR_EXCELLENT,
} from '../position-performance-funnel.consts';
import { FunnelTagsTypeEnum } from './funnel-tags.enum';
import { GreatMix, OutOfCompanies } from './people-matched-tags/people-matched-tags.component';
import {
  Amazing,
  ExcellentPipeline, GreatStart, KeepItUp, OptimalResult,
} from './liked-tags/liked-tags.component';
import {
  Followups, HighOpenRate, OpenRate, Sent, WellDone,
} from './contacted-tags/contacted-tags.component';
import { ReplayRateHigh, ReplayRateLow, ReplayRateMed } from './replied-tags/replied-tags.component';

const generatePeopleMatchedTags = (reviewedMatchesCount: number, matchesTalentsCompaniesCardinality: number): JSX.Element[] => {
  const Tags: JSX.Element[] = [];
  if (matchesTalentsCompaniesCardinality > FUNNEL_TAG_MIN_TALENTS_COMPANIES_CARDINALITY) {
    Tags.push(
      <OutOfCompanies
        key={ `${FunnelTagsTypeEnum.PeopleMatched}_OutOfCompanies` }
        companyCount={ matchesTalentsCompaniesCardinality }
      />,
    );
  }

  if (reviewedMatchesCount / matchesTalentsCompaniesCardinality >= FUNNEL_TAG_TALENTS_COMPANIES_CARDINALITY_RATE) {
    Tags.push(
      <GreatMix
        key={ `${FunnelTagsTypeEnum.PeopleMatched}_GreatMix` }
        companyCount={ matchesTalentsCompaniesCardinality }
      />,
    );
  }

  return Tags;
};

const generateLikedTags = (engagedMatchesCount: number, engagedMatchesRatio: number): JSX.Element[] => {
  const Tags: JSX.Element[] = [];

  if (engagedMatchesCount < FUNNEL_TAG_MIN_OPTIMAL_OR_EXCELLENT) {
    Tags.push(
      <OptimalResult
        key={ `${FunnelTagsTypeEnum.Liked}_OptimalResult` }
        liked={ engagedMatchesCount }
      />,
    );
  }

  if (engagedMatchesCount >= FUNNEL_TAG_MIN_KEEP_IT_UP && engagedMatchesCount <= FUNNEL_TAG_MAX_KEEP_IT_UP) {
    Tags.push(
      <KeepItUp
        key={ `${FunnelTagsTypeEnum.Liked}_KeepItUp` }
      />,
    );
  }

  if (engagedMatchesCount >= FUNNEL_TAG_MIN_GREAT_START && engagedMatchesCount <= FUNNEL_TAG_MAX_GREAT_START) {
    Tags.push(
      <GreatStart
        key={ `${FunnelTagsTypeEnum.Liked}_GreatStart` }
      />,
    );
  }

  if (engagedMatchesCount >= FUNNEL_TAG_MIN_OPTIMAL_OR_EXCELLENT) {
    Tags.push(
      <ExcellentPipeline
        key={ `${FunnelTagsTypeEnum.Liked}_ExcellentPipeline` }
      />,
    );
  }

  if (engagedMatchesRatio >= FUNNEL_GRAPH_OPTIMAL_LIKED_PEOPLE) {
    Tags.push(
      <Amazing
        key={ `${FunnelTagsTypeEnum.Liked}_Amazing` }
        acceptanceRate={ engagedMatchesRatio }
      />,
    );
  }

  return Tags;
};

const generateContactedTags = (
  followupsMessagesCount: number,
  contactedMatchesRatio: number,
  messagesOpenRate: number,
  sentMessagesCount: number,
): JSX.Element[] => {
  const Tags: JSX.Element[] = [];
  if (followupsMessagesCount) {
    Tags.push(
      <Followups
        key={ `${FunnelTagsTypeEnum.Contacted}_Followups` }
        followups={ followupsMessagesCount }
      />,
    );
  }

  if (contactedMatchesRatio * 100 >= FUNNEL_TAG_WELL_DONE) {
    Tags.push(
      <WellDone
        key={ `${FunnelTagsTypeEnum.Contacted}_WellDone` }
      />,
    );
  }

  if (messagesOpenRate * 100 >= FUNNEL_TAG_HIGH_OPEN_RATE) {
    Tags.push(
      <HighOpenRate
        key={ `${FunnelTagsTypeEnum.Contacted}_HighOpenRate` }
      />,
    );
  } else {
    Tags.push(
      <OpenRate
        key={ `${FunnelTagsTypeEnum.Contacted}_HighOpenRate` }
        openRate={ messagesOpenRate }
      />,
    );
  }

  if (sentMessagesCount > 0) {
    Tags.push(
      <Sent
        key={ `${FunnelTagsTypeEnum.Contacted}_Sent` }
        sentMessages={ sentMessagesCount }
      />,
    );
  }

  return Tags;
};

const generateRepliedTags = (
  repliedConversationsRatio: number,
): JSX.Element[] => {
  const Tags: JSX.Element[] = [];
  const repliedRate = repliedConversationsRatio * 100;
  if (repliedRate < FUNNEL_TAG_REPLAY_RATE_LOW) {
    Tags.push(
      <ReplayRateLow
        key={ `${FunnelTagsTypeEnum.Replied}_ReplayRateLow` }
        replayRate={ repliedConversationsRatio }
      />,
    );
  } else if (repliedRate >= FUNNEL_TAG_MIN_REPLAY_RATE_MED && repliedRate <= FUNNEL_TAG_MAX_REPLAY_RATE_MED) {
    Tags.push(
      <ReplayRateMed
        key={ `${FunnelTagsTypeEnum.Replied}_ReplayRateMed` }
        replayRate={ repliedConversationsRatio }
      />,
    );
  } else {
    Tags.push(
      <ReplayRateHigh
        key={ `${FunnelTagsTypeEnum.Replied}_ReplayRateHigh` }
        replayRate={ repliedConversationsRatio }
      />,
    );
  }

  return Tags;
};

export const useFunnelTags = (positionStatistics: UsePositionPerformance): Map<FunnelTagsTypeEnum, JSX.Element[]> => {
  const tagsMap = new Map<FunnelTagsTypeEnum, JSX.Element[]>();
  const PeopleMatchedTags = generatePeopleMatchedTags(positionStatistics.reviewedMatchesCount, positionStatistics.matchesTalentsCompaniesCardinality);
  const LikedTags = generateLikedTags(positionStatistics.engagedMatchesCount, positionStatistics.engagedMatchesRatio);
  const ContactedTags = generateContactedTags(
    positionStatistics.followupsMessagesCount,
    positionStatistics.contactedMatchesRatio,
    positionStatistics.messagesOpenRate,
    positionStatistics.sentMessagesCount,
  );
  const RepliedTags = generateRepliedTags(positionStatistics.messagesReplyRate);

  if (PeopleMatchedTags.length) {
    tagsMap.set(FunnelTagsTypeEnum.PeopleMatched, PeopleMatchedTags);
  }

  if (LikedTags.length) {
    tagsMap.set(FunnelTagsTypeEnum.Liked, LikedTags);
  }

  if (ContactedTags.length) {
    tagsMap.set(FunnelTagsTypeEnum.Contacted, ContactedTags);
  }

  if (RepliedTags.length) {
    tagsMap.set(FunnelTagsTypeEnum.Replied, RepliedTags);
  }

  return tagsMap;
};
