import { ApiError } from '../../../../../consts/api';
import { positionQuery } from '../position.toolkit-api';
import { UpdatePositionAtsIntegrationQueryArguments } from '../dto/query-arguments/update-position-ats-integration.query-arguments';
import { LinkedAtsJobResponse } from '../dto/response/position.response';

type UseUpdatePositionAtsIntegrationMutationType = [
  ({ jobId, jobTitle, syncAutomatically }: UpdatePositionAtsIntegrationQueryArguments) => void,
  AppSelectorType<LinkedAtsJobResponse, ApiError>
];

export const useUpdatePositionAtsIntegrationMutation = (): UseUpdatePositionAtsIntegrationMutationType => {
  const [
    updatePositionAtsIntegration,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = positionQuery.useUpdatePositionAtsIntegrationMutation();

  const meta = {
    isLoading, isError, error: error as ApiError, isSuccess, data, resetState: reset,
  };

  return [
    updatePositionAtsIntegration,
    { ...meta },
  ];
};
