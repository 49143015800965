import styled from 'styled-components';
import { HeadlineFont, TitleBold } from '../../themes/fonts';
import { THEME } from '../../themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  min-height: 500px;
`;

export const Title = styled.div`
  ${TitleBold};
  margin-bottom: ${THEME.SPACING.BASE};
`;

export const SubTitle = styled.div`
  ${HeadlineFont};
  font-weight: 500;
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;
