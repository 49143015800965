import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { isEqual } from 'lodash';
import STRING_KEYS from '../../../../../../language/keys';
import StepContainer from '../step-container/step-container.component';
import RequirementTipNotification from '../../requirement-tip-notification/requirement-tip-notification.component';
import { EditJobRequirement } from '../../edit-job-requirement/edit-job-requirement.component';
import {
  BACKGROUND_GUIDANCE_MODAL_DATA,
} from '../../../../../../components/dialogs/guidance-modal/do-dont-guidance-modal/do-dont-guidance-modal.consts';
import { ANALYTICS_EVENTS } from '../../../../../../consts/analytics';

const IndustryExperienceStep = ({ companyBackground, changeHandler, backButtonClickHandler }: IndustryExperienceStepProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [isNotificationTipOpen, setIsNotificationTipOpen] = useState(true);
  const [internalCompanyBackground, setInternalCompanyBackground] = useState<PositionJobRequirementState[]>(JSON.parse(JSON.stringify(companyBackground)));

  const isContinueDisabled = (): boolean => {
    return isEqual(internalCompanyBackground, companyBackground);
  };

  return (
    <>
      <StepContainer
        title={ translate(STRING_KEYS.MATCH_TUNE.INDUSTRY_EXPERIENCE_STEP.TITLE) }
        subtitle={ translate(STRING_KEYS.MATCH_TUNE.INDUSTRY_EXPERIENCE_STEP.SUBTITLE) }
        backButtonHandler={ backButtonClickHandler }
        continueButtonHandler={ () => changeHandler(internalCompanyBackground) }
        isContinueButtonDisabled={ isContinueDisabled() }
        eventName={ ANALYTICS_EVENTS.MATCH_TUNE_INDUSTRY_EXPERIENCE_STEP_VIEWED }
      >
        <EditJobRequirement
          selectedJobRequirements={ internalCompanyBackground }
          setSelectedJobRequirementsHandler={ (updatedData) => setInternalCompanyBackground(updatedData) }
          enableRequirement
          emptyStateText={ translate(STRING_KEYS.MATCH_TUNE.INDUSTRY_EXPERIENCE_STEP.EMPTY_STATE_TEXT) }
          guidanceModalData={ {
            title: translate(STRING_KEYS.GUIDANCE_MODAL.COMPANY_BACKGROUND_MODAL_TITLE),
            data: BACKGROUND_GUIDANCE_MODAL_DATA,
          } }
        />
      </StepContainer>
      <RequirementTipNotification isOpen={ isNotificationTipOpen } onClose={ () => setIsNotificationTipOpen(false) } />
    </>
  );
};

export default IndustryExperienceStep;
