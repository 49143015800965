import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import { SOCIAL_NETWORKS } from '../../../../../../../consts';
import STRING_KEYS from '../../../../../../../language/keys';
import {
  SocialIcon,
  SocialIconWrapper,
} from './social-link-item.styled';

const logger = log.getLogger('WIDGET_SOCIAL_LINK_ITEM');

type SocialLinkItemProps = {
  socialLink: SocialLinkData,
  trackSocialLinkClick?: (socialChannel: string) => void,
}

export const SocialLinkItem = ({
  socialLink,
  trackSocialLinkClick,
}:SocialLinkItemProps): JSX.Element | null => {
  const { t: translate } = useTranslation();
  const socialNetworkInfo = SOCIAL_NETWORKS.find((network) => {
    return network.type === socialLink.type;
  });

  if (!socialNetworkInfo) {
    logger.warn('got an unknown social network:', socialLink);

    return null;
  }

  const handleOnLinkClick = () => {
    if (typeof trackSocialLinkClick === 'function') {
      trackSocialLinkClick(socialLink.type);
    }
  };

  return (
    <a
      href={ socialLink.url }
      target="_blank"
      rel="noreferrer"
      onClick={ handleOnLinkClick }
    >
      <SocialIconWrapper>
        <SocialIcon
          src={ socialNetworkInfo.icon }
          alt={ translate(
            STRING_KEYS.LOGO_ALT,
            { name: translate(socialNetworkInfo.titleKey) },
          ) }
        />
      </SocialIconWrapper>
    </a>
  );
};

SocialLinkItem.defaultProps = {
  trackSocialLinkClick: undefined,
};
