import { AxiosError } from 'axios';

export const API_HEADERS = {
  SESSION_TRACKING_ID: 'x-session-tracking-id',
  ANALYTICS_ID: 'x-analytics-id',
  APP_METADATA_TOKEN: 'x-app-metadata-token',
  APP_VERSION: 'x-app-version',
};

export const HTTP_CODES = {
  UNAUTHORIZED: 401,
};

// TODO [refactor] move to rtl-base-query
export interface ApiError extends AxiosError {
  isApiError: boolean
  error: {
    serverError: {
      internalErrorCode: number,
      message: string,
      fieldsErrors: string[],
      timestamp: string,
      path: string,
      statusCode: number,
    },
    errorMetadata: {
      url: string | undefined,
      axiosStatusCode: number,
      axiosErrorMessage: string,
      responseTime: number,
      correlationId: string,
      censoredToken: string | null,
      tokenExpirationTime: string,
      windowNavigatorOnline: boolean,
    }
  }
}

export const INTERNAL_SERVER_ERROR_CODES = {
  PERSONAL_EMAIL_ERROR_CODE: 5023,
  POSITION_INTERNAL_ERROR_CODE_QUOTA: 6010,
  ACCOUNT_EXPIRED_ERROR_CODE: 2001,
};
