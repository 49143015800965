import SvgIcon from '../../../SvgIcon/index';
import {
  StyledUserMenuRow,
  StyledUserMenuTitle,
} from './user-menu-item.styled';

type UserMenuItemProps = {
  title: string,
  onClick: () => void,
  Icon: React.FC,
}

// todo: why need SvgIcon wrapper?
// todo: rename Icon to SvgIcon without wrapper?
function UserMenuItem({ title, onClick, Icon }: UserMenuItemProps): JSX.Element {
  return (
    <StyledUserMenuRow onClick={ onClick }>
      <SvgIcon Icon={ Icon } size="small" />
      <StyledUserMenuTitle>
        {title}
      </StyledUserMenuTitle>
    </StyledUserMenuRow>
  );
}

export default UserMenuItem;
