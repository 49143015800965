import { useTranslation } from 'react-i18next';
import { Badge, BadgeProps as BadgeType } from '../../../badge/badge.component';
import STRING_KEYS from '../../../../language/keys';
import { ConnectionScoreEnum } from '../../../../enums/connection-score.enum';
import { HighScoreIcon, MedLowIcon } from './reachability-badge.styled';

type ReachabilityBadgeProps = {
  score: ConnectionScoreEnum,
}

export const ReachabilityBadge = ({ score }: ReachabilityBadgeProps): JSX.Element | null => {
  const { t: translate } = useTranslation();

  const getBadgeData = (): BadgeType | null => {
    switch (score) {
      case ConnectionScoreEnum.StrongConnection:
        return {
          title: translate(STRING_KEYS.TALENT_NETWORK.HIGH_SCORE_TITLE),
          description: translate(STRING_KEYS.TALENT_NETWORK_BADGE_HIGH_SCORE_DESCRIPTION),
          Icon: <HighScoreIcon />,
        };
      case ConnectionScoreEnum.MediumConnection:
      case ConnectionScoreEnum.LowConnection:
        return {
          title: translate(STRING_KEYS.TALENT_NETWORK.MED_LOW_SCORE_TITLE),
          description: translate(STRING_KEYS.TALENT_NETWORK_BADGE_MED_LOW_SCORE_DESCRIPTION),
          Icon: <MedLowIcon />,
        };
      default:
        return null;
    }
  };

  const badgeData = getBadgeData();

  if (!badgeData) {
    return null;
  }

  return (
    <Badge
      title={ badgeData.title }
      description={ badgeData.description }
      Icon={ badgeData.Icon }
    />
  );
};
