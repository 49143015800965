import { toggleSetValue } from '../../../utils';
import { Container } from './checkbox-button-group.styled';
import CheckboxItem from './checkbox-item/checkbox-item.component';
import { CheckboxButtonGroupContainerProps } from './checkbox-button-group';
import { CheckboxButtonGroupDirection } from './checkbox-button-group.enums';
import { CheckboxItemSize } from './checkbox-item/checkbox-item.enums';

const CheckboxButtonGroupContainer = <T, >({
  items,
  selectedItems,
  direction = CheckboxButtonGroupDirection.Column,
  clickHandler,
  changeHandler,
  ...otherProps
}: CheckboxButtonGroupContainerProps<T>): JSX.Element => {
  const onCheckboxItemClick = (id: T) => {
    clickHandler?.(id);

    const clonedSet = new Set(selectedItems);
    toggleSetValue(clonedSet, id);
    changeHandler(clonedSet);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Container { ...otherProps } direction={ direction }>
      {
        items.map(({
          id, title, description, Icon,
        }) => (
          <CheckboxItem
            key={ `checkbox_button_group_container_item_${id}` }
            id={ id }
            onClick={ onCheckboxItemClick }
            title={ title }
            description={ description }
            Icon={ Icon }
            selected={ selectedItems.has(id) }
            size={ direction === CheckboxButtonGroupDirection.Row ? CheckboxItemSize.Small : CheckboxItemSize.Medium }
          />
        ))
      }
    </Container>
  );
};

export default CheckboxButtonGroupContainer;
