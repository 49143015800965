import styled from 'styled-components';
import BaseInput from '../../../../../../components/Common/perfect-base-input/perfect-base-input.component';
import { THEME } from '../../../../../../themes';
import { OverlineFont } from '../../../../../../themes/fonts';

export const InputContainer = styled(BaseInput)`
  width: 240px;
  height: 56px;
  border-radius: ${THEME.RADIUS.ROUNDISH};
  background-color: ${THEME.COLORS.LIGHT_GREY};

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  };
  input[type=number] {
    -moz-appearance: textfield;
    background-color: transparent;
    font-weight: 600;
  };
`;

export const InputLabel = styled.div`
  ${OverlineFont};
  text-transform: uppercase;
  color: ${THEME.COLORS.TRUE_BLACK};
  opacity: ${THEME.OPACITY.MED};
`;
