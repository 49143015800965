import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInViewport } from 'react-in-viewport';
import STRING_KEYS from '../../../../../language/keys';
import SkillsList from './skills-list/skills-list.component';
import {
  Container,
  ExperienceTitle,
  SkillsCounter,
  SkillsTitle,
  Wrapper,
} from './skills-widget.styled';
import { ToggleButton } from './ToggleButton';

const COMPONENT_NAME = 'skills_widget';
export const SKILLS_TO_SHOW = 12;

type SkillsWidgetProps = {
  skills: SkillData[],
  trackSkillsComponentExpand?: (componentName: string) => void,
}

export const SkillsWidget = ({
  skills,
  trackSkillsComponentExpand,
}:SkillsWidgetProps):
 JSX.Element => {
  const containerRef = useRef(null);
  const {
    inViewport,
    enterCount,
  } = useInViewport(
    containerRef,
    {},
    {},
  );
  const [isExpanded, setExpanded] = useState(false);
  const { t: translate } = useTranslation();

  const handleExpand = () => {
    setExpanded((prev) => !prev);
    trackSkillsComponentExpand?.(COMPONENT_NAME);
  };

  const showExpandButton = skills && skills.length > SKILLS_TO_SHOW;

  return (
    <Container>
      <ExperienceTitle>
        {translate(STRING_KEYS.EXPERIENCE)}
      </ExperienceTitle>
      <Wrapper ref={ containerRef }>
        <SkillsCounter
          end={ skills.length }
          duration={ inViewport && enterCount === 1 ? 1 : 0 }
          useEasing
          redraw
        />
        <SkillsTitle>
          {translate(STRING_KEYS.SKILLS)}
        </SkillsTitle>
      </Wrapper>
      <Wrapper>
        <SkillsList
          skills={ skills }
          isExpanded={ isExpanded }
        />
      </Wrapper>
      {showExpandButton && (
        <ToggleButton
          isExpanded={ isExpanded }
          onExpand={ handleExpand }
        />
      )}
    </Container>
  );
};

SkillsWidget.defaultProps = {
  trackSkillsComponentExpand: undefined,
};
