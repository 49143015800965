import { useHistory, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AppRouting } from '../../../../../services/appRoutingResolver';
import { PageName } from '../../../../../enums/page-name.enum';
import { SequenceModeEnum } from '../../../../../store/rtk-query/apis/sequence/enums/sequence-mode.enum';
import { SequencePreferencesDto } from '../../../../../store/rtk-query/apis/sequence/dto/sequence-preferences.dto';
import STRING_KEYS from '../../../../../language/keys';
import { useGetPositionSequencePreferencesSelector } from '../../../../../store/rtk-query/apis/sequence/selectors/position-sequence-preferences.selector';
import {
  AutoIcon,
  CampaignIcon, Container, GenAiIcon, Label, LabelContainer, ManualIcon, PreferencesItem, PreferencesItemTitle,
} from './conversation-preferences.styled';

const ConversationPreferences = (): JSX.Element => {
  const { positionId } = useParams<MatchUrlParams>();
  const history = useHistory();
  const location = useLocation();
  const { t: translate } = useTranslation();
  const pageName = useMemo(() => AppRouting.getPageName(location.pathname), [location.pathname]);
  const { data: { sequencePreferences = {} as SequencePreferencesDto } } = useGetPositionSequencePreferencesSelector(positionId);
  const { mode } = sequencePreferences;
  const campaignAuto = mode === SequenceModeEnum.Auto;
  const campaignCustom = mode === SequenceModeEnum.Custom;

  const onGenAIPreferencesClick = () => {
    history.push(AppRouting.buildGenAIPreferencesPageUrl(positionId));
  };

  const onCampaignsClick = () => {
    history.push(AppRouting.buildCampaignsPreferencesPageUrl(positionId));
  };

  const generateCampaignLabel = () => {
    if (campaignAuto) {
      return (
        <>
          <Label>{translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.PREFERENCES.AUTO_LABEL)}</Label>
          <AutoIcon />
        </>
      );
    }

    if (campaignCustom) {
      return (
        <>
          <Label>{translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.PREFERENCES.CUSTOM_LABEL)}</Label>
        </>
      );
    }

    return (
      <>
        <Label>{translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.PREFERENCES.MANUAL_LABEL)}</Label>
        <ManualIcon />
      </>
    );
  };

  return (
    <Container>
      <PreferencesItem
        onClick={ onGenAIPreferencesClick }
      >
        <PreferencesItemTitle
          isActive={ pageName === PageName.OutreachContentPreferences }
        >
          <GenAiIcon />
          {translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.PREFERENCES.GEN_AI_TITLE)}
        </PreferencesItemTitle>
      </PreferencesItem>
      <PreferencesItem
        onClick={ onCampaignsClick }
      >
        <PreferencesItemTitle
          isActive={ pageName === PageName.OutreachCampaignPreferences }
        >
          <CampaignIcon />
          <div>{translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.PREFERENCES.CAMPAIGN_TITLE)}</div>
        </PreferencesItemTitle>
        <LabelContainer>
          { generateCampaignLabel() }
        </LabelContainer>
      </PreferencesItem>
    </Container>
  );
};

export default ConversationPreferences;
