import { useGetOpenPositionsQuery } from '../../../../store/rtk-query/apis/position/hooks/get-open-positions.query-hook';
import { useMyUserSelector } from '../../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { useAppState } from '../../../../store/selectors';
import { useSocketConnectorListener } from '../../../../hooks/use-socket-connector-listener.hooks';
import {
  BackgroundContainer,
  ChildrenWrapper,
  PageContainer,
} from './application-layout.styled';

const ApplicationLayout = ({ children }: ApplicationLayoutProps): JSX.Element | null => {
  const {
    showSplashScreen,
  } = useAppState();
  const { data: { accountId } } = useMyUserSelector();
  useGetOpenPositionsQuery(!accountId);
  useSocketConnectorListener();

  if (showSplashScreen) {
    return null;
  }

  return (
    <PageContainer>
      <BackgroundContainer />
      <ChildrenWrapper>
        { children }
      </ChildrenWrapper>
    </PageContainer>
  );
};

export default ApplicationLayout;
