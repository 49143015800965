import { QueryMethods } from '../../enums/query-methods.enums';
import { GetQuery, PostQuery, PutQuery } from '../../@types/rtk-query';
import {
  ENGAGEMENT_TYPE_PATH_PARAM, MATCH_ID_PATH_PARAM, POSITION_ID_PATH_PARAM, ROUTES,
} from './match.consts';
import { GetMatchByIdQueryArguments } from './dto/query-arguments/get-match-by-id.query-arguments';
import { PerformMatchEngagementQueryArguments } from './dto/query-arguments/perform-match-engagement.query-arguments';
import { SetMatchAsViewedQueryArguments } from './dto/query-arguments/set-match-as-viewed.query-arguments';
import { GetPendingReviewMatchesQueryArguments } from './dto/query-arguments/get-pending-review-matches.query-arguments';

export const getPendingReviewMatchByIdQuery = ({ positionId }: GetPendingReviewMatchesQueryArguments): GetQuery => ({
  url: ROUTES.PENDING_REVIEW_MATCHES
    .replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Get,
});

export const getMatchByIdQuery = ({ positionId, matchId }: GetMatchByIdQueryArguments): GetQuery => ({
  url: ROUTES.MATCH_BY_ID
    .replace(POSITION_ID_PATH_PARAM, positionId)
    .replace(MATCH_ID_PATH_PARAM, matchId),
  method: QueryMethods.Get,
});

export const performMatchEngagement = ({
  positionId,
  matchId,
  engagementType,
}: PerformMatchEngagementQueryArguments): PostQuery<undefined> => ({
  url: ROUTES.PERFORM_MATCH_INTERACTION
    .replace(POSITION_ID_PATH_PARAM, positionId)
    .replace(MATCH_ID_PATH_PARAM, matchId)
    .replace(ENGAGEMENT_TYPE_PATH_PARAM, engagementType),
  method: QueryMethods.Post,
});

export const setMatchAsViewed = ({ matchId, positionId }: SetMatchAsViewedQueryArguments): PutQuery => ({
  url: ROUTES.VIEW_MATCH
    .replace(POSITION_ID_PATH_PARAM, positionId)
    .replace(MATCH_ID_PATH_PARAM, matchId),
  method: QueryMethods.Put,
});
