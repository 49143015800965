import { outreachQuery } from '../outreach.toolkit-api';
import { ConversationsMinimizedResponseDto } from '../dto/response/conversation-minimized.response';

type UseGetConversationsQuery = {
  skip?: boolean,
  refetchOnMountOrArgChange?: boolean,
  positionId: string,
}

export const useGetConversationsByPositionQuery = (args: UseGetConversationsQuery): AppSelectorType<ConversationsMinimizedResponseDto> => {
  const {
    data = { conversations: [], sequenceLength: 0 } as ConversationsMinimizedResponseDto,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch,
    isFetching,
  } = outreachQuery.useGetConversationsByPositionIdQuery(
    { positionId: args.positionId },
    {
      skip: !!args.skip,
      refetchOnMountOrArgChange: !!args.refetchOnMountOrArgChange,
    },
  );

  if (isError) {
    throw new Error('Couldn\'t fetch conversations by position id');
  }

  return {
    data,
    error,
    isLoading,
    isError,
    isSuccess,
    refetch,
    isFetching,
  };
};
