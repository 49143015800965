import styled from 'styled-components';
import { ASSETS } from '../../../../../assets';

export const IncreaseReplayRateIcon = styled(ASSETS.INCREASE_REPLAY_RATE)`
  width: 85px;
  height: 122px;
`;

export const LearnHowLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;
