/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, Ref } from 'react';
import {
  Input,
  InputContainer, AdornmentContainer,
} from './perfect-base-input.styled';
import { BaseInputProps } from './perfect-base-input';
import { PerfectInputSize } from './perfect-base-input.enums';

const BaseInput = forwardRef((
  props: BaseInputProps,
  ref: Ref<HTMLInputElement>,
) => {
  const {
    size = PerfectInputSize.Medium,
    fullWidth,
    disabled,
    EndAdornment,
    StartAdornment,
    placeholder,
    value,
    endAdornmentClickHandler,
    onChange,
    type,
    pattern,
    min,
    max,
    ...otherProps
  } = props;

  return (
    <InputContainer
      disabled={ !!disabled }
      fullWidth={ !!fullWidth }
      size={ size }
      { ...otherProps }
    >
      { !!StartAdornment && <AdornmentContainer>{ StartAdornment }</AdornmentContainer> }
      <Input
        type={ type }
        pattern={ pattern }
        min={ min }
        max={ max }
        ref={ ref }
        disabled={ disabled }
        placeholder={ placeholder }
        value={ value }
        onChange={ onChange }
      />
      { !!EndAdornment &&  (
        <AdornmentContainer onClick={ (event) => endAdornmentClickHandler?.(event) }>
          { EndAdornment }
        </AdornmentContainer>
      )}
    </InputContainer>
  );
});

BaseInput.displayName = 'BaseInput';

export default BaseInput;
