import { StyledPrimaryButton } from './styled';

type PrimaryButtonProps = {
  onClick?: () => void,
  title: string,
  type: 'button' | 'submit' | 'reset' | undefined,
  disabled?: boolean,
  disableMargin?: boolean;
}

/** @deprecated */
const PrimaryButton = ({
  onClick,
  title,
  type = 'button',
  disabled,
  disableMargin,
}: PrimaryButtonProps): JSX.Element => {
  return (
    <StyledPrimaryButton
      disabled={ disabled }
      onClick={ () => {
        if (onClick) {
          onClick();
        }
      } }
      type={ type }
      disableMargin={ disableMargin }
    >
      {title}
    </StyledPrimaryButton>
  );
};

PrimaryButton.defaultProps = {
  disabled: false,
  onClick: undefined,
  disableMargin: false,
};

export default PrimaryButton;
