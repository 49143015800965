import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { GIFS } from '../../../../assets';
import { NOT_FOUND_ERROR_CODES, PAGE_TITLES } from '../../../../consts';
import STRING_KEYS from '../../../../language/keys';
import { ErrorPage } from '../ErrorPage';

const images = [
  {
    src: GIFS.PULP_FICTION,
    alt: 'Awkward Pulp Fiction',
  },
  {
    src: GIFS.HOMER_SIMPSON,
    alt: 'Missing Homer Simpson',
  },
  {
    src: GIFS.CHECK_THIS_OUT,
    alt: 'Check This Out',
  },
];

// selecting image is outside the component to prevent changing on each render
const randomImageIndex = Math.floor(Math.random() * images.length);
const currentImage = images[randomImageIndex];

type NotFoundErrorPageProps = {
  errorCode?: string,
}

export const NotFoundErrorPage = ({
  errorCode,
}: NotFoundErrorPageProps): JSX.Element => {
  // This effect runs once, after the first render
  useEffect(() => {
    document.title = PAGE_TITLES.NOT_FOUND;
  }, []);
  
  const { t: translate } = useTranslation();

  return (
    <ErrorPage
      title={ translate(STRING_KEYS.CANT_FIND_PAGE) }
      content={ translate(STRING_KEYS.PAGE_MOVED_RENAMED_DELETED) }
      errorCode={ errorCode }
      image={ currentImage.src }
      imageAlt={ currentImage.alt }
    />
  );
};

NotFoundErrorPage.defaultProps = {
  errorCode: NOT_FOUND_ERROR_CODES.UNDEFINED,
};
