import { useMemo } from 'react';
import BaseTextEditorControls from '../base-text-editor-controls/base-text-editor-controls.component';
import { BaseTextEditorControlsProps, ButtonsInlineStyles } from '../base-text-editor-controls/base-text-editor-controls';
import { PerfectTextEditorStyleControl } from '../../perfect-text-editor.enums';
import {
  BoldIcon, ItalicIcon, UnderlineIcon,
} from './inline-style-controls.styled';

const InlineStyleControlsComponent = ({ currentInlineStyle, toggleHandler }: BaseTextEditorControlsProps): JSX.Element => {
  const inlineStyles = useMemo<ButtonsInlineStyles[]>(() => (
    [
      { icon: BoldIcon, style: PerfectTextEditorStyleControl.Bold },
      { icon: ItalicIcon, style: PerfectTextEditorStyleControl.Italic },
      { icon: UnderlineIcon, style: PerfectTextEditorStyleControl.Underline },
    ]
  ), []);

  return (
    <BaseTextEditorControls
      currentInlineStyle={ currentInlineStyle }
      toggleHandler={ toggleHandler }
      buttonsInlineStyles={ inlineStyles }
    />
  );
};

export default InlineStyleControlsComponent;
