import { PerfectMenuSize } from '../perfect-base-menu/perfect-base-menu.enums';
import { PerfectButtonHoverVariants } from '../Buttons/perfect-base-button/perfect-base-button.enums';
import { IconButtonVariants } from '../Buttons/icon-button/icon-button.enums';
import { CloseButton, CloseButtonIcon, Container } from './popper.styled';
import { PopperProps } from './popper';

// TODO: change implementation for not using the base menu
const Popper = ({
  isOpen,
  anchorElement,
  placement,
  closeHandler,
  showCloseButton = true,
  children,
}: PopperProps): JSX.Element => {
  return (
    <Container
      isOpen={ isOpen }
      anchorElement={ anchorElement }
      placement={ placement }
      onClose={ closeHandler }
      size={ PerfectMenuSize.Large }
      popperModifiers={ [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top-start', 'top', 'top-end', 'bottom-start', 'left'],
          },
        },
      ] }
    >
      <>
        { showCloseButton && (
          <CloseButton
            type="button"
            variant={ IconButtonVariants.Fill }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
            onClick={ closeHandler }
            StartIcon={ CloseButtonIcon }
          />
        ) }
        { children }
      </>
    </Container>
  );
};

export default Popper;
