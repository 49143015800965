import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ASSETS } from '../../../../../../../assets';
import STRING_KEYS from '../../../../../../../language/keys';
import {
  Container,
  Description,
  Wrapper,
} from './and-more.styled';

export const AndMoreComponent = ():JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <Avatar
        src={ ASSETS.AVATAR_DOTS }
        style={ {
          width: '32px',
          height: '32px',
        } }
      />
      <Wrapper>
        <Description>
          {translate(STRING_KEYS.AND_MORE)}
        </Description>
      </Wrapper>
    </Container>
  );
};
