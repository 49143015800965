import styled, { css } from 'styled-components';
import { THEME } from '../../../../../../../../themes';
import { ASSETS, FLUENT_UI_EMOJIS } from '../../../../../../../../assets';
import { Body2Font } from '../../../../../../../../themes/fonts';

export const Row = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINO};
  flex-grow: 1;
  padding-top: 16px;
`;

export const MultipleExperienceContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.BASE};
  flex-direction: column;
`;

const CompanyNameStyle = css`
  cursor: pointer;

  :hover {
    color: ${THEME.COLORS.PRIMARY_DARK};
    border-bottom: 2px ${THEME.COLORS.PRIMARY_DARK} dashed;
  }
`;

export const CompanyName = styled.span<{ disabled: boolean }>`
  ${({ disabled }) => {
    if (disabled) {
      return '';
    }

    return CompanyNameStyle;
  }};
`;

export const CompanyIcon = styled(ASSETS.INSIGHTS_PANEL_COMPONENT)`
  margin-left: ${THEME.SPACING.MICRO};
  fill: ${THEME.COLORS.ACCENT};
  width: 13px;
  height: 13px;
`;

export const PromotionBadgeWrapper = styled.span`
  margin-left: ${THEME.SPACING.MICRO};
`;

export const SpotlightText = styled.div`
  ${Body2Font};
  line-height: normal;
  font-weight: 500;
  margin-top: ${THEME.SPACING.TINY};
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
`;

export const SpotlightIcon = styled(FLUENT_UI_EMOJIS.STAR)`
  width: 12px;
  height: 12px;
`;

export const SummaryContainer = styled.div`
  ${Body2Font};
   margin-top: ${THEME.SPACING.MICRO};
  margin-bottom: -3px;
`;

export const HighlightedSummary = styled.div`
  color: ${THEME.COLORS.PRIMARY_DARK};
  opacity: ${THEME.OPACITY.MED};
  font-weight: 500;
  line-height: 22px;
  position: relative;
`;

export const GeneratedSummary = styled.div`
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
  line-height: 20px;
  padding-top: ${THEME.SPACING.TINO};
  white-space: pre-line;
`;

export const SeeMoreButton = styled.span`
  display: inline-block;
  background-color: transparent;
  height: fit-content;
  margin-left: ${THEME.SPACING.TINY};
  cursor: pointer;
  text-transform: lowercase;
  
  :hover {
    color: ${THEME.COLORS.ACCENT};
  }
`;

export const ExperienceTagsContainer = styled.div`
  margin-top: ${THEME.SPACING.TINO};
`;
