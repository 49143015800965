import {
  useContext,
} from 'react';
import TextEditorContext from './text-editor.context';
import { TextEditorHookDto } from './text-editor';

const useTextEditor = (): TextEditorHookDto => {
  const { state, actions } = useContext(TextEditorContext);
  const {
    subjectReadOnly, touched, showSubjectEditor, editorInFocus, subjectEditorInFocus, editorBodyState, editorSubjectState, showSubject, showTextEditorHeader,
  } = state;
  const {
    setTouched, setShowSubjectEditor, setEditorInFocus, setSubjectEditorInFocus, setEditorBodyState, setEditorSubjectState, resetEditor, setShowTextEditorHeader,
  } = actions;

  return {
    editorBodyState,
    editorSubjectState,
    setEditorBodyState,
    setEditorSubjectState,
    showSubjectEditor,
    editorInFocus,
    subjectEditorInFocus,
    touched,
    setTouched,
    setShowSubjectEditor,
    setSubjectEditorInFocus,
    setEditorInFocus,
    resetEditor,
    showSubject,
    showTextEditorHeader,
    setShowTextEditorHeader,
    subjectReadOnly,
  } as TextEditorHookDto;
};

export default useTextEditor;
