import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../../language/keys';
import {
  ArrowIcon,
  Button,
  IconWrapper,
} from './styled';

type ToggleButtonProps = {
  isExpanded: boolean,
  onExpand: () => void,
}

export const ToggleButton = ({
  isExpanded,
  onExpand,
}: ToggleButtonProps):
JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Button onClick={ onExpand }>
      <IconWrapper>
        <ArrowIcon isOpen={ isExpanded } />
      </IconWrapper>
      {!isExpanded ?
        translate(STRING_KEYS.SEE_MORE) :
        translate(STRING_KEYS.SEE_LESS)}
    </Button>
  );
};
