import { Container } from './styled';

type ContentProps = {
  children: JSX.Element | JSX.Element[],
  flex?: number,
  width?: string,
}

const ModalContent = ({
  children,
  flex,
  width,
}: ContentProps): JSX.Element => {
  return (
    <Container flex={ flex } width={ width }>
      { children }
    </Container>
  );
};

ModalContent.defaultProps = {
  flex: undefined,
  width: undefined,
};

export default ModalContent;
