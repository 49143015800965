export enum TextEditorActionType {
  SetEditorBody = 'SET_EDITOR_BODY',
  SetEditorSubject = 'SET_EDITOR_SUBJECT',
  SetShowSubject = 'SET_SHOW_SUBJECT',
  SetEditorStateInitialized = 'SET_EDITOR_STATE_INITIALIZED',
  SetSubjectReadOnly = 'SET_SUBJECT_READ_ONLY',
  SetTouched = 'SET_TOUCHED',
  SetShowSubjectEditor = 'SET_SHOW_SUBJECT_EDITOR',
  SetEditorInFocus = 'SET_EDITOR_IN_FOCUS',
  SetSubjectEditorInFocus = 'SET_SUBJECT_EDITOR_IN_FOCUS',
  ResetEditor = 'RESET_EDITOR',
  SetShowTextEditorHeader = 'SET_SHOW_TEXT_EDITOR_HEADER',
}
