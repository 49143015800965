import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMemo, useRef } from 'react';
import STRING_KEYS from '../../../../../language/keys';
import { THEME } from '../../../../../themes';
import { BADGE_TYPE } from '../../../../../pages/match-page/match.page.const';
import { Badge } from '../../../../badge/badge.component';
import { CompaniesBadge } from '../../companies-badge/companies-badge.component';
import { ExperienceBadge } from '../../experience-badge/experience-badge.component';
import { MultilingualBadge } from '../../multilingual-badge/multilingual-badge.component';
import { ProgressionBadge } from '../../progression-badge/progression-badge.component';
import { BadgeWrapper } from '../../badge-wrapper/badge-wrapper.component';
import { ReachabilityBadge } from '../../reachability-badge/reachability-badge.component';
import {
  ActivelyLooking,
  Language,
  PastExperienceCompanyItem,
  Reachability,
  SmartBadge, TenurePeriod,
  TotalYears,
} from '../../../../../@types/smartBadges';
import { setTalentsNetworkDialogData } from '../../../../../store/slices/match-state/match-state.toolkit-slice';
import { ANALYTICS_EVENTS } from '../../../../../consts/analytics';
import {
  Crunchbase,
  ActivelyLookingBadgeIcon,
  AcademicExcellenceBadgeIcon,
  Badges,
  TenurePeriodBadgeIcon,
} from './badges-list.styled';

type BadgesListProps = {
  matchId: string,
  smartBadges: SmartBadge[],
  talentTotalYearsOfExperience: number,
  trackMatchElementViewed: (eventName: string, componentElementType: string, componentMetadata?: string) => void,
}

export const BadgesList = ({
  matchId,
  smartBadges,
  talentTotalYearsOfExperience,
  trackMatchElementViewed,
}: BadgesListProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const componentRef = useRef(null);

  const onBadgeIntersect = (badgeType: string, metadata?: string) => {
    trackMatchElementViewed?.(ANALYTICS_EVENTS.MATCH_PAGE_BADGE_VIEWED, badgeType.toLowerCase(), metadata);
  };

  const defineBadge = (badge: SmartBadge): JSX.Element => {
    const badgeKey = `${matchId}-${badge.type}-${badge.order}`;

    switch (badge.type || badge) {
      case BADGE_TYPE.CAREER_PROGRESSION:
        return (
          <BadgeWrapper
            key={ badgeKey }
            width="auto"
            height="72px"
            backgroundColor={ THEME.COLORS.PURE_WHITE }
            padding="16px"
            intersectionHandler={ () => onBadgeIntersect(badge.type) }
          >
            <ProgressionBadge />
          </BadgeWrapper>
        );
      case BADGE_TYPE.ACADEMIC_EXCELLENCE:
        return (
          <BadgeWrapper
            key={ badgeKey }
            width="auto"
            height="72px"
            backgroundColor={ THEME.COLORS.PURE_WHITE }
            padding="16px"
            intersectionHandler={ () => onBadgeIntersect(badge.type) }
          >
            <Badge
              title={ translate(STRING_KEYS.ACADEMIC_EXCELLENCE) }
              description={ translate(STRING_KEYS.ACADEMIC_EXCELLENCE_DESCRIPTION) }
              Icon={ <AcademicExcellenceBadgeIcon /> }
            />
          </BadgeWrapper>
        );
      case BADGE_TYPE.ACTIVELY_LOOKING: {
        const { companyName } = badge.data as ActivelyLooking;
        const layoffActivelyLooking = !!companyName;

        return (
          <BadgeWrapper
            key={ badgeKey }
            width="auto"
            height="72px"
            backgroundColor={ THEME.COLORS.PURE_WHITE }
            padding="16px"
            intersectionHandler={ () => onBadgeIntersect(badge.type, `${layoffActivelyLooking ? '' : 'not '}layoffActivelyLooking`) }
          >
            <Badge
              title={ translate(STRING_KEYS.ACTIVELY_LOOKING) }
              description={ layoffActivelyLooking ?
                translate(STRING_KEYS.ACTIVELY_LOOKING_LAYOFF_DESCRIPTION, { companyName }) :
                translate(STRING_KEYS.ACTIVELY_LOOKING_DESCRIPTION) }
              Icon={ <ActivelyLookingBadgeIcon /> }
            />
          </BadgeWrapper>
        );
      }
      case BADGE_TYPE.CRUNCHBASE_RANKING:
        return (
          <BadgeWrapper
            key={ badgeKey }
            width="auto"
            height="72px"
            backgroundColor={ THEME.COLORS.PURE_WHITE }
            padding="16px"
            intersectionHandler={ () => onBadgeIntersect(badge.type) }
          >
            <Badge
              title={ translate(STRING_KEYS.CRUNCHBASE_RANKING) }
              description={ translate(STRING_KEYS.CRUNCHBASE_RANKING_DESCRIPTION) }
              Icon={ <Crunchbase /> }
            />
          </BadgeWrapper>
        );
      case BADGE_TYPE.UPCOMING_TENURE_PERIOD: {
        const companyName = (badge?.data as TenurePeriod)?.companyName || '';

        return (
          <BadgeWrapper
            key={ badgeKey }
            width="auto"
            height="72px"
            backgroundColor={ THEME.COLORS.PURE_WHITE }
            padding="16px"
            intersectionHandler={ () => onBadgeIntersect(badge.type, companyName) }
          >
            <Badge
              title={ translate(STRING_KEYS.TENURE_PERIOD) }
              description={ translate(STRING_KEYS.REACHED_AVERAGE, { companyName }) }
              Icon={ <TenurePeriodBadgeIcon /> }
            />
          </BadgeWrapper>
        );
      }
      case BADGE_TYPE.PAST_EXPERIENCE_COMPANY_LIST: {
        const data = (badge.data as PastExperienceCompanyItem[]);

        return (
          <BadgeWrapper
            key={ badgeKey }
            width="auto"
            height="72px"
            backgroundColor={ THEME.COLORS.PURE_WHITE }
            padding="16px"
            intersectionHandler={ () => onBadgeIntersect(badge.type, data?.map((item: PastExperienceCompanyItem) => item.companyName)?.join(',')) }
          >
            <CompaniesBadge companiesList={ data } />
          </BadgeWrapper>
        );
      }
      case BADGE_TYPE.TOTAL_YEARS_OF_EXPERIENCE: {
        // TODO: update after all matches has the updated data
        const { totalYearsOfExperience, totalYearsExperience, totalYearsRelevantExperience } = badge.data as TotalYears;

        return (
          <BadgeWrapper
            key={ badgeKey }
            width="auto"
            height="72px"
            backgroundColor={ THEME.COLORS.PURE_WHITE }
            padding="16px"
            intersectionHandler={ () => onBadgeIntersect(badge.type, `${totalYearsRelevantExperience}`) }
          >
            <ExperienceBadge
              totalExperienceYears={ totalYearsExperience || talentTotalYearsOfExperience }
              totalRelevantExperienceYears={ totalYearsOfExperience || totalYearsRelevantExperience }
            />
          </BadgeWrapper>
        );
      }
      case BADGE_TYPE.LANGUAGES: {
        const languagesList = badge.data as Language[];

        return languagesList && languagesList.length > 1 ? (
          <BadgeWrapper
            key={ badgeKey }
            width="auto"
            height="72px"
            backgroundColor={ THEME.COLORS.PURE_WHITE }
            padding="16px"
            intersectionHandler={ () => onBadgeIntersect(badge.type, languagesList?.map((lang) => lang.name)?.join(',')) }
          >
            <MultilingualBadge languagesList={ badge.data as Language[] } />
          </BadgeWrapper>
        ) : <></>;
      }
      case BADGE_TYPE.REACHABILITY: {
        const { score } = badge.data as Reachability;

        if (score) {
          return (
            <BadgeWrapper
              key={ badgeKey }
              width="auto"
              height="72px"
              backgroundColor={ THEME.COLORS.PURE_WHITE }
              padding="16px"
              infoButtonClickHandler={ () => dispatch(setTalentsNetworkDialogData({ isOpen: true })) }
              intersectionHandler={ () => onBadgeIntersect(badge.type, score) }
            >
              <ReachabilityBadge score={ score } />
            </BadgeWrapper>
          );
        }

        return <></>;
      }
      default:
        return <></>;
    }
  };

  const badges = useMemo(() => {
    return smartBadges.map((badge) => {
      return defineBadge(badge);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchId]);

  return (
    <div ref={ componentRef }>
      <Badges>{ badges }</Badges>
    </div>
  );
};
