import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import TalentNetworkCard from '../../../../../../components/talent-network-card/talent-network-card.component';
import STRING_KEYS from '../../../../../../language/keys';
import { PerfectButtonSize, PerfectButtonVariants } from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { SeeMoreButton } from '../match-side-panel.styled';
import { NetworkedTalentResponseDto } from '../../../../../../store/rtk-query/apis/match/dto/response/match.response';
import {
  ANALYTICS_EVENTS, COMPONENT_NAME, ELEMENT_TYPE, EVENT_GROUP, PRODUCT_AREA,
} from '../../../../../../consts/analytics';
import { PAGE_NAMES } from '../../../../../../consts/pages';
import { useAnalyticsContext } from '../../../../../../contexts/analytics/useAnalyticsContext';
import { useIntersection } from '../../../../../../hooks/useIntersection';
import { UpgradePlanDialog } from '../../../../../../components/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import {
  HeaderContainer, HeaderIcon, HiddenTalentCardsContainer, SeeMoreButtonContainer, TalentNetworkCardWrapper, TalentNetworkContainer, Title,
} from './talent-network-cards.styled';
import { TalentNetworkCardsProps } from './talent-network-cards';

const MAX_CARDS_TO_SHOW = 8;

const TalentNetworkCardsComponent = ({
  networkedTalents, matchTalentName, intersectionHandler, seeMoreClickHandler,
}: TalentNetworkCardsProps):JSX.Element | null => {
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
  const [showMoreTalentCards, setShowMoreTalentCards] = useState(false);
  const { t: translate } = useTranslation();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const componentRef = useRef(null);

  useIntersection({
    element: componentRef,
    callback: () => intersectionHandler?.({
      metadata: `${Math.min(networkedTalents?.length || 0, MAX_CARDS_TO_SHOW)}`,
      metadataType: 'number_of_connections',
    }),
  });

  const sliceRelevantCards = (startIndex: number, lastIndex: number): NetworkedTalentResponseDto[] => {
    return networkedTalents?.slice(startIndex, lastIndex);
  };

  const renderCards = (relevantNetworkTalent: NetworkedTalentResponseDto[]) => {
    return relevantNetworkTalent?.map((networkedTalent) => {
      return (
        <Fragment key={ networkedTalent.talent.id }>
          <TalentNetworkCard
            networkedTalent={ networkedTalent }
            tipClickHandler={ () => setIsUpgradeDialogOpen(true) }
            matchTalentName={ matchTalentName }
          />
        </Fragment>
      );
    });
  };

  const getHideCards = () => sliceRelevantCards(2, MAX_CARDS_TO_SHOW);

  const renderHiddenCards = () => {
    const relevantTalents = getHideCards();
    if (!relevantTalents.length) {
      return null;
    }

    return (
      <div>
        <HiddenTalentCardsContainer
          isDisplayShow={ showMoreTalentCards }
          rows={ getHideCards().length }
        >
          { renderCards(relevantTalents) }
        </HiddenTalentCardsContainer>
        <SeeMoreButtonContainer>
          <SeeMoreButton
            size={ PerfectButtonSize.Small }
            variant={ showMoreTalentCards ? PerfectButtonVariants.Fill : PerfectButtonVariants.Outline }
            onClick={ () => {
              setShowMoreTalentCards((prevState) => !prevState);
              seeMoreClickHandler?.();
            } }
          >
            { showMoreTalentCards ? translate(STRING_KEYS.ENGAGE_PANEL.SEE_MORE_BUTTON.HIDE) : translate(STRING_KEYS.ENGAGE_PANEL.SEE_MORE_BUTTON.SHOW) }
          </SeeMoreButton>
        </SeeMoreButtonContainer>
      </div>
    );
  };

  const onDialogClickBIEvent = (type: string) => {
    const clickOnDialogEventData = {
      eventName: ANALYTICS_EVENTS.CLICKED_ON_PREMIUM_MODAL,
      eventGroup: EVENT_GROUP.CLICK,
      productArea: PRODUCT_AREA.MATCH_PAGE,
      pageName: PAGE_NAMES.MATCH_PAGE,
      componentElementType: type,
      componentName: COMPONENT_NAME.LITE_TALENT_NETWORK,
    };

    analyticsTrackEvent(clickOnDialogEventData);
  };

  const onDialogCloseHandler = () => {
    setIsUpgradeDialogOpen(false);
    onDialogClickBIEvent(ELEMENT_TYPE.CLOSE);
  };

  return (
    <TalentNetworkContainer ref={ componentRef }>
      <HeaderContainer>
        <HeaderIcon />
        <Title>{translate(STRING_KEYS.ENGAGE_PANEL.TALENT_NETWORK.TITLE)}</Title>
      </HeaderContainer>
      <TalentNetworkCardWrapper>
        { renderCards(sliceRelevantCards(0, 2)) }
      </TalentNetworkCardWrapper>
      { renderHiddenCards() }
      { isUpgradeDialogOpen && (
        <UpgradePlanDialog
          isDialogOpen={ isUpgradeDialogOpen }
          onCloseDialog={ onDialogCloseHandler }
        />
      )}
    </TalentNetworkContainer>
  );
};

export default TalentNetworkCardsComponent;
