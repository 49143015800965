import PreferencesContentSection
  from '../../../components/outreach/preferences-content-section/preferences-content-section.component';
import {
  SectionIcon,
  IconWrapper,
  Title,
  TitleContainer,
  Wrapper,
  TitleStartWrapper,
} from './outreach-content-preferences-section.styled';

const OutreachContentPreferencesSection = ({
  title,
  Icon,
  TitleAdornment,
  disableDivider,
  children,
}: OutreachContentPreferencesSectionProps): JSX.Element => {
  const renderTitle = (): JSX.Element | null => {
    if (!title) {
      return null;
    }

    const IconComponent = Icon || <SectionIcon />;

    return (
      <TitleContainer>
        <TitleStartWrapper>
          <IconWrapper>{ IconComponent }</IconWrapper>
          { title && <Title>{ title }</Title>}
        </TitleStartWrapper>
        { TitleAdornment }
      </TitleContainer>
    );
  };

  return (
    <PreferencesContentSection disableDivider={ disableDivider }>
      <Wrapper>
        { renderTitle() }
        { children }
      </Wrapper>
    </PreferencesContentSection>
  );
};

export default OutreachContentPreferencesSection;
