import styled from 'styled-components';
import { ASSETS } from '../../../../../assets';
import { THEME } from '../../../../../themes';

export const CheckedIcon = styled(ASSETS.CHECKED_GHOST)`
  width: 8px;
  height: 8px;
  fill: ${THEME.COLORS.SECONDARY};
`;

export const UnCheckedIcon = styled(ASSETS.CHECKED_GHOST)`
  width: 8px;
  height: 8px;
  fill: ${THEME.COLORS.GREY};
`;
