import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import STRING_KEYS from '../../../../../language/keys';
import { validateEmail } from '../../../../../utils';
import {
  COMPONENT_NAME, ELEMENT_TYPE, EVENT_GROUP,
} from '../../../../../consts/analytics';
import { PAGE_NAMES } from '../../../../../consts/pages';
import { Autocomplete } from '../../../../../components/Common/create-position-autocomplete/autocomplete.component';
import { isValidName } from '../../../utils/onboarding.utls';
import { PressEnterMessage } from '../../../../create-position/components/press-enter-message/press-enter-message.component';
import { useCreatePositionProgressSteps } from '../../../../create-position/hooks/create-position-progress-steps.hooks';
import { getStepIndex } from '../../../utils/onboarding-bi-events.utls';
import { useGetCurrentStep } from '../../../hooks/onboarding-current-step.hooks';
import { CreatePositionStepsEnums } from '../../../../create-position/enums/create-position-steps.enums';
import { CREATE_POSITION_ANALYTICS_EVENTS } from '../../../../create-position/consts/position-analytics.consts';
import { useAnalyticsContext } from '../../../../../contexts/analytics/useAnalyticsContext';
import { useMyUserSelector } from '../../../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { setUserName } from '../../../../../store/slices/onboarding-state/onboarding-state.toolkit-slice';
import { InputStyle } from '../../input/input.component';

type NameAutocompleteProps = {
  onSentenceCompleted: () => void,
}

const NAME_LENGTH_LIMIT = 35;

export const NameAutocomplete = ({
  onSentenceCompleted,
}: NameAutocompleteProps):JSX.Element => {
  const { data: { firstName = '' } } = useMyUserSelector();
  const isFirstNameEmail = validateEmail(firstName || '');
  const userName = !isFirstNameEmail ? firstName : '';
  const [inputValue, setInputValue] = useState<string>(userName || '');
  const [isInputFocused, setInputFocused] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const currentStep = useGetCurrentStep<CreatePositionStepsEnums>();
  const { steps } = useCreatePositionProgressSteps(currentStep);

  const handleComplete = () => {
    const trackEventPayload = {
      eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_INSERT_PARAMETER_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.BASIC_INFO,
      componentName: COMPONENT_NAME.NAME,
      componentElementType: ELEMENT_TYPE.MANUAL,
      componentMetadata: inputValue,
      stepNumber: steps ? getStepIndex(currentStep, steps) : undefined,
    };
    dispatch(setUserName(inputValue));
    analyticsTrackEvent(trackEventPayload);
    setInputFocused(false);
    onSentenceCompleted();
  };

  useEffect(() => {
    if (!isFirstNameEmail) {
      handleComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValueChanged = (value: string) => {
    if ((value === '' || isValidName(value)) && (value.length <= NAME_LENGTH_LIMIT)) {
      setInputValue(value);
    }
  };

  const handleOnBlur = () => {
    if (inputValue.trim().length > 0) {
      handleComplete();
    }
  };

  return (
    <Autocomplete
      InstructionsMessage={ <PressEnterMessage translationKey={ STRING_KEYS.PRESS_ENTER } /> }
      onBlur={ handleOnBlur }
      optionsTitle=""
      autoFocus={ isFirstNameEmail }
      isLoading={ false }
      placeholder={ STRING_KEYS.ONBOARDING.ONBOARDING_NAME_PLACEHOLDER }
      inputValue={ inputValue }
      onValueChange={ handleValueChanged }
      isInputFocused={ isInputFocused }
      setInputFocused={ setInputFocused }
      isDisableOptions
      inputStyle={ InputStyle.Onboarding }
    />
  );
};
