import styled from 'styled-components';
import {
  Body2Font, OverlineFont, Title1Font,
} from '../../../../themes/fonts';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';
import Spinner from '../../../../components/Common/spinner/spinner.component';
import { hexToRgbA } from '../../../../utils/colors';
import IconButton from '../../../../components/Common/Buttons/icon-button/icon-button.component';
import { Scrollbar } from '../../../../themes/scrollbar';

export const ScrollContainer = styled.div`
  ${Scrollbar};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  max-width: ${THEME.BREAKPOINTS.WIDTH.SMALL}px;
  display: flex;
  flex-direction: column;
  padding: ${THEME.SPACING.LARGE} 0 ${THEME.SPACING.BASE};
  align-self: center;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PageBody = styled.div`
  margin-top: ${THEME.SPACING.MEDIUM};
  display: flex;
  justify-content: space-between;
  gap: ${THEME.SPACING.BASE};
`;

export const StatisticSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
  max-width: 644px;
`;

export const PageHeaderRightContainer = styled.div`
  display: flex;
  height: fit-content;
  gap: ${THEME.SPACING.BASE};
`;

export const PositionTitle = styled.div`
  ${Title1Font};
  font-weight: 600;
`;

export const PositionInfoBottom = styled.div`
  ${Body2Font};
  color: ${THEME.COLORS.CONTENT_A};
  display: flex;
  white-space: pre;
  margin-top: ${THEME.SPACING.TINO};
  gap: ${THEME.SPACING.TINO};
  align-items: center;
`;

export const AccentText = styled.span`
  color: ${THEME.COLORS.ACCENT};
`;

export const ConnectPositionToAts = styled.span`
  color: ${THEME.COLORS.ACCENT};
  cursor: pointer;
`;

export const AtsConnected = styled.div`
  display: flex;
  align-items: center;
`;

export const AtsIcon = styled(ASSETS.INFINITY_ARROW_ICON)`
  width: 16px;
  margin-right: ${THEME.SPACING.MICRO};
`;

export const StatisticsCardsMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
  padding: ${THEME.SPACING.MEDIUM};
  padding-right: ${THEME.SPACING.TINO};
  border-radius: ${THEME.RADIUS.CURVY};
  background-color: ${THEME.COLORS.PURE_WHITE};
  height: 256px;
`;

export const StatisticsCardsLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
`;

export const CheckedIcon = styled(ASSETS.CHECKED_ROMB_FILLED)`
  width: 17px;
  height: 17px;
  fill: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW)}
`;

export const Label = styled.div`
  ${OverlineFont};
  font-weight: 600;
  color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW)};
`;

export const StatisticsCardsContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.BASE};
`;

export const ToReviewCardIcon = styled(ASSETS.STARS_GROUP_FILL_MIRROR)``;
export const ToReviewCardMatchingProgressBodyIcon = styled(Spinner)`
  color: ${THEME.COLORS.PRIMARY_DARK};
`;
export const ToReviewCardDailyLimitBodyIcon = styled(ASSETS.HOURGLASS)``;
export const ToReachoutIcon = styled(ASSETS.BUBBLE_HEART)``;
export const ToReplyCardIcon = styled(ASSETS.REPLAY_ARROW)``;

export const PositionPerformanceFunnelContainer = styled.div`
  margin-top: ${THEME.SPACING.BASE};
`;

export const VerticalDivider = styled.div`
  width: 1px;
  background-color: ${hexToRgbA(THEME.COLORS.CONTENT_PRIMARY, THEME.OPACITY.GHOST)};
`;

export const SettingsButton = styled(IconButton)`
  color: ${THEME.COLORS.CONTENT_A};
  padding: ${THEME.SPACING.TINO};
  border: 2px solid ${THEME.COLORS.CONTENT_A};
`;

export const SettingsButtonIcon = styled(ASSETS.COG_FILLED)`
  width: 16px;
  height: 16px;
`;

export const ExportMatchesIcon = styled(ASSETS.DOWNLOAD_FILLED)`
  height: 24px;
  fill: ${THEME.COLORS.PURE_WHITE};
`;

export const RealizationMetricsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${THEME.SPACING.BASE};
`;
