import styled, { css } from 'styled-components';
import { THEME } from '../../../../../../themes';
import { SOCIAL_ICONS } from '../../../../../../assets';

export const Container = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINY};
  height: 40px;
  align-items: center;
`;

export const LinkContainer = styled.a`
  height: unset;
`;

export const IconCss = css`
  width: 20px;
  height: 20px;
  fill: ${THEME.COLORS.PRIMARY_DARK};
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const OtherIcon = styled(SOCIAL_ICONS.OTHER)`
  ${IconCss};
`;

export const StackOverflowIcon = styled(SOCIAL_ICONS.STACK_OVERFLOW)`
  ${IconCss};
`;

export const TwitterIcon = styled(SOCIAL_ICONS.TWITTER)`
  ${IconCss};
`;

export const LinkedinIcon = styled(SOCIAL_ICONS.LINKED_IN)`
  ${IconCss};
`;

export const MediumIcon = styled(SOCIAL_ICONS.MEDIUM)`
  ${IconCss};
`;

export const FacebookIcon = styled(SOCIAL_ICONS.FACEBOOK)`
  ${IconCss};
`;

export const GithubIcon = styled(SOCIAL_ICONS.GITHUB)`
  ${IconCss};
`;
