import { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import TalentNetworkConnectionScore from '../talent-network-connection-score/talent-network-connection-score.component';
import { STRING_KEYS } from '../../../language/keys';
import { ConnectionScoreEnum } from '../../../enums/connection-score.enum';
import { AvatarWithBadge } from '../../Common/avatar-with-badge/avatar-with-badge.component';
import { TalentCardProps } from './base-card';
import {
  AvatarContainer, NameContainer, TalentCardContainer, DescriptionContainer, TipContainer, TipLabel, TipContent, LockIcon,
} from './base-card.styled';

const TalentCard = forwardRef((
  props: TalentCardProps,
  ref: Ref<HTMLDivElement>,
) => {
  const {
    connectionLevel = ConnectionScoreEnum.LowConnection,
    talentName,
    description,
    talentImageUrl,
    badgeImageUrl,
    badgeName = 'institution',
    tip,
    isLocked = false,
    onTipClickHandler,
  } = props;
  const { t: translate } = useTranslation();

  const renderTip = () => {
    if (isLocked) {
      return (
        <TipContainer
          level={ connectionLevel }
          onClick={ onTipClickHandler }
          isClickable={ isLocked }
        >
          <TipContent>{ translate(STRING_KEYS.TALENT_NETWORK.TIPS.UPGRADE_TO_PRO) }</TipContent>
          <LockIcon level={ connectionLevel } />
        </TipContainer>
      );
    }

    if (tip) {
      return (
        <TipContainer level={ connectionLevel }>
          <div>
            <TipLabel>{ translate(STRING_KEYS.TALENT_NETWORK.TIPS.LABEL) }</TipLabel>
            <TipContent bold >{ tip }</TipContent>
          </div>
        </TipContainer>
      );
    }

    return null;
  };

  const renderAvatarWithBadge = () => {
    const hideBadge = isLocked;

    return (
      <AvatarWithBadge
        avatarImage={ talentImageUrl }
        name={ talentName }
        badgeImage={ hideBadge ? undefined : badgeImageUrl }
        badgeAlt={ hideBadge ? undefined : badgeName }
      />
    );
  };

  return (
    <div>
      <TalentCardContainer ref={ ref } showTip={ !!tip || isLocked } >
        <TalentNetworkConnectionScore level={ connectionLevel } />
        <AvatarContainer>
          { renderAvatarWithBadge() }
        </AvatarContainer>
        <NameContainer isLocked={ isLocked }>{ talentName }</NameContainer>
        <DescriptionContainer>{ description }</DescriptionContainer>
      </TalentCardContainer>
      { renderTip() }
    </div>
  );
});

TalentCard.displayName = 'TalentCard';

export default TalentCard;
