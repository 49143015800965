import { LogMessage } from '../models/log-message.interface';
import { RemoteFormattedLog } from '../models/remote-formatted-log.interface';
import { LogLevelPluginLog } from '../models/loglevel-remote-plugin-log.interface';

const constructJsonLog = (timestamp: string, log: LogMessage, stacktrace?: string): RemoteFormattedLog => {
  const {
    msg, level, trackingId, sessionId, fullStory, userData, additionalInfo, error, context,
  } = log;
  let msgStacktrace = stacktrace;
  if (stacktrace && stacktrace === '') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    msgStacktrace = undefined;
  }

  return ({
    message: msg,
    level,
    timestamp,
    context,
    metadata: {
      application: {
        appVersion: process.env.REACT_APP_VERSION,
      },
      userData,
      sessionId,
      trackingId,
      fullStory,
    },
    stacktrace: msgStacktrace,
    error: error ? JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error))) : undefined,
    additionalInfo,
  });
};

export const loglevelPluginLogToRemoteServerLog = (log: LogLevelPluginLog): RemoteFormattedLog => {
  try {
    const parsedMessage: LogMessage = JSON.parse(log.message);
    
    return constructJsonLog(log.timestamp, parsedMessage, log.stacktrace);
  } catch (e) {
    // couldn't parse
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const stacktrace = e?.stacktrace;
    
    return {
      metadata: {
        application: { appVersion: undefined },
        fullStory: undefined,
        sessionId: '',
        trackingId: '',
        userData: undefined,
      },
      context: 'loglevelPluginLogToRemoteServerLog',
      level: 'error',
      message: 'logger parsing error',
      stacktrace,
      timestamp: new Date().toDateString(),
    };
  }
};

export const logMessageToFullStoryLog = (log: LogMessage): RemoteFormattedLog => {
  try {
    const logObject = constructJsonLog(new Date().toDateString(), log);
    logObject.metadata.fullStory = undefined;
    
    return logObject;
  } catch (e) {
    // couldn't parse
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const stacktrace = e?.stacktrace;

    return {
      metadata: {
        application: { appVersion: undefined },
        fullStory: undefined,
        sessionId: undefined,
        trackingId: undefined,
        userData: undefined,
      },
      context: 'logMessageToFullStoryLog',
      level: 'error',
      message: 'logger parsing error',
      stacktrace,
      timestamp: new Date().toDateString(),
    };
  }
  //
  // try {
  //   let additionalInfo; let msg; let error;
  //   // let trackingId; let sessionId;
  //   try {
  //     const firstElem = message[0] as string;
  //     const parsedMessage: LogMessage = JSON.parse(firstElem);
  //     msg = parsedMessage.msg;
  //     // trackingId = parsedMessage.trackingId;
  //     // sessionId = parsedMessage.sessionId;
  //     additionalInfo = parsedMessage.additionalInfo;
  //     error = parsedMessage.error;
  //   } catch (e) {
  //     // couldn't parse
  //     msg = message.join(' ');
  //   }
  //
  //   if (additionalInfo) {
  //     msg = `${msg}. additionalInfo: ${inspect(additionalInfo, false, null, false)}`;
  //   }
  //
  //   if (error) {
  //     msg = `${msg}. Error: ${inspect(error, false, 3, false)}`;
  //   }

  // if (trackingId) {
  //   msg = `(tracking-id: ${trackingId}) - ${msg}`;
  // }
  // if (sessionId) {
  //   msg = `(session-id: ${sessionId}) - ${msg}`;
  // }

  //   if (loggerName) {
  //     msg = `[${loggerName.toString()}] ${msg}`;
  //   }
  //   FullStory.log(methodName as FullStory.LogLevel, msg);
  // } catch (error) {
  //   // Fullstory probably not working or wasn't initiated yet,
  //   // so we need to skip it
  // }
};
