import styled from 'styled-components';

export const Container = styled.div`
  width: fit-content;
`;

export const TypingLoader = styled.div`
  @keyframes typingLoaderKeyframes {
    0%  {box-shadow: 12px 0 #000, -12px 0 #0002;background: #000 }
    33% {box-shadow: 12px 0 #000, -12px 0 #0002;background: #0002}
    66% {box-shadow: 12px 0 #0002, -12px 0 #000; background: #0002}
    100%{box-shadow: 12px 0 #0002, -12px 0 #000; background: #000 }
  }

  width: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: typingLoaderKeyframes 1s infinite linear alternate;
`;

export const SectionLoader = styled.div<{ color?: string }>`
  @keyframes sectionLoaderKeyframes {
    to{width:25px;aspect-ratio: 1}
  }

  width: 60px;
  aspect-ratio: 4;
  --c: ${({ color }) => color || '#000'} 90%, #0000;
  background:
          radial-gradient(circle closest-side at left  6px top 50%,var(--c)),
          radial-gradient(circle closest-side                     ,var(--c)),
          radial-gradient(circle closest-side at right 6px top 50%,var(--c));
  background-size: 100% 100%;
  background-repeat: no-repeat;
  animation: sectionLoaderKeyframes 1s infinite alternate;
`;
