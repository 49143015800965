import { useHistory, Link } from 'react-router-dom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppRouting } from '../../../../../services/appRoutingResolver';
import STRING_KEYS from '../../../../../language/keys';
import { formatLocations } from '../../../../../utils/location';
import Dropdown from '../../../dropdown/dropdown.component';
import { PositionResponse } from '../../../../../store/rtk-query/apis/position/dto/response/position.response';
import {
  useGetOpenPositionsSelector,
  useGetPositionByIdFromPositionsQuerySelector,
} from '../../../../../store/rtk-query/apis/position/selectors/get-positions.selector';
import {
  Container,
  HomeIcon, DividerIcon,
} from './header-toolbar.styled';

type HeaderToolbarProps = {
  positionId: string
}

function HeaderToolbar({ positionId }:HeaderToolbarProps): JSX.Element {
  const { t: translate } = useTranslation();
  const { data: currentPosition } = useGetPositionByIdFromPositionsQuerySelector(positionId);
  const { data: positions } = useGetOpenPositionsSelector();
  const [isActive, setIsActive] = useState<boolean>(false);
  const history = useHistory();

  const onPositionSelect = (selectedPositionId: string): void => {
    history.push(AppRouting.buildMatchesPageUrl(selectedPositionId));
  };

  const onPositionsDropdownOpen = () => {
    setIsActive(true);
  };

  const onPositionsDropdownClose = () => {
    setIsActive(false);
  };

  const getDropdownMenuItems = () => {
    return positions?.map((position: PositionResponse) => ({
      id: position.id,
      title: position.jobTitle,
      subtitle: position.remotePosition ? translate(STRING_KEYS.REMOTE) : formatLocations(position.locations).join(' • '),
    }));
  };

  // Future improvement - add the ability the render components inside dropdown and put there the icons
  return (
    <Container isActive={ isActive }>
      <Link to={ { pathname: AppRouting.ROUTES.HOME } }>
        <HomeIcon />
      </Link>
      <DividerIcon />
      { currentPosition && (
        <Dropdown
          selectedItemId={ currentPosition?.id }
          items={ getDropdownMenuItems() }
          itemSelectHandler={ onPositionSelect }
          menuOpenHandler={ onPositionsDropdownOpen }
          menuCloseHandler={ onPositionsDropdownClose }
        />
      )}
    </Container>
  );
}

export default HeaderToolbar;
