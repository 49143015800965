import { useTranslation } from 'react-i18next';
import { ENGLISH_VOWEL_LETTERS } from '../../../../../consts';
import STRING_KEYS from '../../../../../language/keys';

export const useGetCurrentJobTitlePrefix = (currentJobTitle: string): string => {
  const { t: translate } = useTranslation();
  if (ENGLISH_VOWEL_LETTERS.includes(currentJobTitle?.[0])) {
    return translate(STRING_KEYS.AN);
  }

  return translate(STRING_KEYS.A);
};

export const useGetCurrentJobExperienceWithPostfix = (experiencePeriodInMonths: number | undefined): string | undefined => {
  const { t: translate } = useTranslation();
  if (!experiencePeriodInMonths) {
    return undefined;
  }
  // ONE MONTH (in singular)
  if (experiencePeriodInMonths === 1) {
    return translate(STRING_KEYS.CURRENT_JOB_EXPERIENCE_MONTH,
      { currentJobExperienceMonth: experiencePeriodInMonths });
  }

  // MULTIPLE MONTHS (in plural)
  if (experiencePeriodInMonths < 12) {
    return translate(STRING_KEYS.CURRENT_JOB_EXPERIENCE_MONTHS,
      { currentJobExperienceMonths: experiencePeriodInMonths });
  }

  // ONE YEAR (in singular)
  if (experiencePeriodInMonths < 18) {
    return translate(STRING_KEYS.CURRENT_JOB_EXPERIENCE_YEAR,
      { currentJobExperienceYear: Math.floor(experiencePeriodInMonths / 12) },
    );
  }

  // MULTIPLE YEARS (in plural)
  return translate(STRING_KEYS.CURRENT_JOB_EXPERIENCE_YEARS,
    { currentJobExperienceYears: Math.round(experiencePeriodInMonths / 12) });
};
