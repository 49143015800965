import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { OverlineFont } from '../../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../../utils/colors';

export const Container = styled.div<{ noPadding?: boolean, isVertical?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINO};
  border: solid 1px ${hexToRgbA(THEME.COLORS.PRIMARY, THEME.OPACITY.DIVIDER)};
  border-radius: ${THEME.RADIUS.CURVY};
  background-color: ${THEME.COLORS.PURE_WHITE};
  padding: ${({ noPadding }) => noPadding ? undefined : `${THEME.SPACING.TINO} ${THEME.SPACING.MEDIUM}`};
`;

export const ChildrenContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  ${OverlineFont};
  font-weight: 600;
`;

export const WidgetHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WidgetHeaderLeftContainer = styled.div<{ titleColor?: string }>`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
  color: ${({ titleColor }) => titleColor || THEME.COLORS.PRIMARY_DARK};
`;

export const IconWrapper = styled.div`
  display: flex;
  
  svg {
    height: 24px;
    width: 24px;
  }
`;
