import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { TextInputCommonStyle } from '../base-input-item.styled';

export const Input = styled.input<{ fullWidth: boolean }>`
  ${TextInputCommonStyle};
  height: 48px;
  width: ${({ fullWidth }) => fullWidth ? '100%' : '50%'};
`;

export const InputsContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINY};
`;
