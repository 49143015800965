import {
  useAcceptInvitationActions,
  useAcceptInvitationState,
} from '@frontegg/react';
import { AcceptInvitationStep } from '@frontegg/redux-store';
import log from 'loglevel';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../language/keys';
import { AuthError } from '../Common/AuthError';
import { AppLoader } from '../Common/Loaders/AppLoader';
import { PAGE_TITLES } from '../../consts';
import { AppRouting } from '../../services/appRoutingResolver';

const logger = log.getLogger('ACCEPT_TENANT_INVITATION_SCREEN');

function AcceptTenantInviteScreen(): JSX.Element {
  // This effect runs once, after the first render
  useEffect(() => {
    document.title = PAGE_TITLES.ACCEPT_INVITE;
  }, []);

  const { t: translate } = useTranslation();

  const { error, step } = useAcceptInvitationState();
  const { acceptInvitation } = useAcceptInvitationActions();

  const params = new URLSearchParams(window.location.search);
  const userId = params.get('userId') || '';
  const token = params.get('token') || '';

  if (!userId || !token) {
    AppRouting.navigateToUrl(AppRouting.buildGlobalLoginUrl(window.location));
  }

  if (step === AcceptInvitationStep.success) {
    logger.debug('accepted invitation successfully');
    AppRouting.navigateToUrl(AppRouting.buildGlobalLoginUrl(window.location));
  }

  useEffect(() => {
    acceptInvitation({ token, userId });
  }, [acceptInvitation, token, userId]);

  switch (step) {
    case AcceptInvitationStep.invalid:
    case AcceptInvitationStep.failed: {
      return (
        <AuthError errorMessage={ error || translate(STRING_KEYS.ACTION_FAILED) } />
      );
    }
    default:
    case AcceptInvitationStep.pending:
    case AcceptInvitationStep.success: {
      return <AppLoader title="Accept tenant" />;
    }
  }
}

export default AcceptTenantInviteScreen;
