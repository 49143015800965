import { CustomSnackBar } from '..';
import { ASSETS } from '../../../../assets';
import { THEME } from '../../../../themes';

type AlertSnackBarProps = {
  title?: string,
  description?: string,
  actionTitle?: string,
  shouldAutoDismiss?: boolean,
  onStartClosing ?: () => void,
  onActionClick?: () => void,
  isOpen?: boolean,
  anchor?: AnchorDirections,
  onClose?: () => void,
}

export const AlertSnackBar = ({
  title,
  description,
  actionTitle,
  shouldAutoDismiss,
  onStartClosing,
  onActionClick,
  isOpen,
  onClose,
  anchor,
}: AlertSnackBarProps):JSX.Element => {
  return (
    <CustomSnackBar
      isOpen={ isOpen }
      anchor={ anchor }
      icon={ ASSETS.ALERT }
      iconColor={ THEME.COLORS.ALERT }
      title={ title }
      titleColor={ THEME.COLORS.ALERT }
      description={ description }
      actionTitle={ actionTitle }
      onActionClick={ onActionClick }
      onStartClosing={ onStartClosing  }
      shouldAutoDismiss={ shouldAutoDismiss  }
      onClose={ onClose }
    />
  );
};

AlertSnackBar.defaultProps = {
  onActionClick: undefined,
  description: '',
  title: '',
  actionTitle: '',
  shouldAutoDismiss: false,
  isOpen: false,
  onClose: undefined,
  onStartClosing: undefined,
  anchor: 'top-center',
};
