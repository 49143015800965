import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import { BASIC_DELAY } from '../../../../../consts/delays';
import { NameAutocomplete } from '../../autocompletes/name-autocomplete/name-autocomplete.component';
import { Sentence } from '../../../../../components/Common/sentence-component/sentence.component';

type NameSentenceProps = {
  onSentenceCompleted: () => void,
  startDelay?: number,
}

export const NameSentence = ({
  onSentenceCompleted,
  startDelay,
}: NameSentenceProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Sentence
      text={ translate(STRING_KEYS.ONBOARDING.ONBOARDING_MY_NAME_IS) }
      Item={ <NameAutocomplete onSentenceCompleted={ onSentenceCompleted } /> }
      startDelay={ startDelay }
    />
  );
};

NameSentence.defaultProps = {
  startDelay: BASIC_DELAY,
};
