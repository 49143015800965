import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';
import { CaptionFont, Subtitle1Font } from '../../../../themes/fonts';
import Select from '../../../../components/Common/select/select.component';

export const Container = styled.div`
  display: flex;
  justify-content: space-between
`;

export const LeftContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: ${THEME.SPACING.LOOSE};
`;

export const Icon = styled(ASSETS.MASKS)``;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const Title = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
  letter-spacing: 0.14px;
`;

export const Description = styled.div`
  ${CaptionFont};
  opacity: ${THEME.OPACITY.MED};
  line-height: 1.33;
  letter-spacing: 0.4px;
`;

export const StyledSelect = styled(Select)`
  width: 240px;
  height: 56px;
`;
