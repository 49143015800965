export enum SidePanelDirection {
  Right = 'right',
  Left = 'left',
}

export enum SidePanelVariant {
  Permanent = 'permanent',
  Persistent = 'persistent',
  Temporary = 'temporary',
}
