import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { joinStrings } from '../../../../../../utils';
import {
  ExactLocationDto, LocationDto, LocationType, RegionDto,
} from '../../../../../../models/dto/location.dto';
import STRING_KEYS from '../../../../../../language/keys';
import {
  REMOTE_POSITION,
} from '../../../../../../hooks/location-autocomplete-hook/location-autocomplete-hook.consts';
import {
  ISRAEL_COUNTRY_CODE, UNITED_KINGDOM_COUNTRY_CODE, UNITED_STATES_COUNTRY_CODE,
} from '../../../../../../consts/countries';
import { getFlagByCountry } from '../../../../../../utils/location';
import {
  Container, Description, ExactLocationIcon, InfoWrapper, Label, RegionIcon, Wrapper,
} from './location-menu-item.styled';
import { LocationMenuItemDirection, LocationMenuItemSize, LocationMenuItemType } from './location-menu-item.enums';

type LocationMenuItemProps = {
  data?: LocationDto,
  isSelected: boolean,
  clickHandler?: () => void,
  size: LocationMenuItemSize;
  direction: LocationMenuItemDirection;
}

type LocationMenuItemPerType = {
  label?: string;
  description?: string;
  locationType?: LocationMenuItemType;
  icon?: JSX.Element;
}

export const LocationMenuItem = ({
  data,
  isSelected,
  clickHandler,
  size,
  direction,
}: LocationMenuItemProps):JSX.Element => {
  const { t: translate } = useTranslation();

  const {
    label, description, locationType, icon,
  }: LocationMenuItemPerType = useMemo(() => {
    const countryFlag = getFlagByCountry(data?.country);
    let locationMenuItemData: LocationMenuItemPerType;
    switch (data?.type) {
      case LocationType.Region: {
        const region = data as RegionDto;
        const regionIsCountry = region.country === region.region;
        if (regionIsCountry) {
          let translationKey = STRING_KEYS.SPECIFIC_COUNTRY_REMOTE;
          if (region.country === UNITED_STATES_COUNTRY_CODE || region.country === UNITED_KINGDOM_COUNTRY_CODE) {
            translationKey = STRING_KEYS.US_UK_COUNTRY_REMOTE;
          }
          
          locationMenuItemData = {
            label: i18n.t(translationKey, { countryCode: region.countryLong || region.country }), icon: <ExactLocationIcon size={ size } />,
          };
          break;
        }

        locationMenuItemData = {
          label: region.region, description: region.countryLong, locationType: LocationMenuItemType.Area, icon: <RegionIcon size={ size } />,
        };
        break;
      }
      case LocationType.ExactLocation: {
        const exactLocation = data as ExactLocationDto;
        const type = exactLocation.city ? LocationMenuItemType.City : LocationMenuItemType.State;

        let cityStateStr = joinStrings(exactLocation.city, exactLocation.stateLong);
        if (exactLocation?.country === ISRAEL_COUNTRY_CODE) {
          cityStateStr = exactLocation.city || '';
        }

        locationMenuItemData =  {
          label: cityStateStr,
          description: exactLocation.countryLong,
          locationType: exactLocation.country === REMOTE_POSITION.country ? undefined : type,
          icon: <ExactLocationIcon size={ size } />,
        };
        break;
      }
      default:
        return {};
    }

    return {
      ...locationMenuItemData,
      label: `${countryFlag} ${locationMenuItemData.label}`,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Container onClick={ () => { clickHandler?.(); } }
      size={ size }
      isSelected={ isSelected }
    >
      <Wrapper direction={ direction }>
        <Label>{ label }</Label>
        <InfoWrapper direction={ direction }>
          { icon }
          <Description size={ size }>
            { locationType && `${translate(STRING_KEYS.MATCH_TUNE.LOCATIONS_STEP.LOCATION_MENU_ITEM_TYPE[locationType])}, ` }
            { description }
          </Description>
        </InfoWrapper>
      </Wrapper>
    </Container>
  );
};

LocationMenuItem.defaultProps = {
  data: [],
  clickHandler: undefined,
};
