import { ConversationsGroupProps } from './conversation-group';
import ConversationGroupSkeleton from './conversation-group-skeleton/conversation-group-skeleton.component';
import ConversationGroupManualMode from './conversation-group-manual-mode/conversation-group-manual-mode.component';
import ConversationGroupAutoMode from './conversation-group-auto-mode/conversation-group-auto-mode.component';

const ConversationGroup = ({
  conversations, isLoading, isAutoMode, sequenceLength,
}: ConversationsGroupProps): JSX.Element => {
  const renderConversationGroups = () => {
    if (!isAutoMode) {
      return (
        <ConversationGroupManualMode
          conversations={ conversations }
        />
      );
    }

    return <ConversationGroupAutoMode conversations={ conversations } isLoading={ isLoading } sequenceLength={ sequenceLength } />;
  };

  return (
    <>
      { isLoading && <ConversationGroupSkeleton isAutoMode={ isAutoMode } /> }
      { !isLoading &&  renderConversationGroups() }
    </>
  );
};

export default ConversationGroup;
