import { JobDescriptionStep } from '../../../create-position/components/create-position-job-description-step/create-position-description-step.componet';
import { useGetCurrentStep } from '../../hooks/onboarding-current-step.hooks';
import { CreatePositionStepsEnums } from '../../../create-position/enums/create-position-steps.enums';
import {
  useOnboardingContinueWatcherButton,
  useOnboardingSkipWatcherButton,
} from '../../hooks/onboarding-actions.hooks';
import { setOnboardingContinueButtonEnable, setOnboardingContinueButtonDisable } from '../../../../store/slices/onboarding-state/onboarding-state.toolkit-slice';

export const JobDescriptionScreen = (): JSX.Element => {
  const currentStep = useGetCurrentStep<CreatePositionStepsEnums>();
  useOnboardingContinueWatcherButton(currentStep);
  useOnboardingSkipWatcherButton();

  return (
    <>
      <JobDescriptionStep
        setContinueButtonEnable={ setOnboardingContinueButtonEnable }
        setContinueButtonDisable={ setOnboardingContinueButtonDisable }
        isOnboarding
      />
    </>
  );
};
