import { useTranslation } from 'react-i18next';
import { LocationAutocomplete } from '../../../../onboarding/components/autocompletes/location-autocomplete/location-autocomplete.component';
import { Sentence } from '../../../../../components/Common/sentence-component/sentence.component';
import { CREATE_POSITION_INPUT_ID } from '../../../consts/input-ids.consts';

type LocationSentenceProps = {
  textKey: string;
  onSentenceCompleted: () => void,
  onAddAnotherLocation?: () => void,
  showAddAnotherLocationButton: boolean,
  onRemoveLocation?: () => void,
  stepNumber: number,
  locationIndex: number;
  showRemoteOptions: boolean;
}

export const LocationSentence = ({
  textKey,
  onSentenceCompleted,
  stepNumber,
  locationIndex = 0,
  onAddAnotherLocation,
  showAddAnotherLocationButton,
  onRemoveLocation,
  showRemoteOptions,
}: LocationSentenceProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Sentence
      text={ translate(textKey).toLowerCase() }
      Item={  (
        <LocationAutocomplete
          inputElementId={ `${CREATE_POSITION_INPUT_ID.LOCATION}_${locationIndex}` }
          stepNumber={ stepNumber }
          onSentenceCompleted={ onSentenceCompleted }
          onAddAnotherLocation={ onAddAnotherLocation }
          showAddAnotherLocationButton={ showAddAnotherLocationButton }
          onRemoveLocation={ onRemoveLocation }
          locationIndex={ locationIndex }
          showRemoteOptions={ showRemoteOptions }
        />
      ) }
    />
  );
};

LocationSentence.defaultProps = {
  onAddAnotherLocation: undefined,
  onRemoveLocation: undefined,
};
