import { createTheme, ThemeProvider } from '@mui/material';
import { THEME } from '../../../../themes';
import { Loader } from './styled';

export const theme = createTheme({
  palette: {
    primary: {
      main: `${THEME.COLORS.PRIMARY}`,
    },
  },
});

export const IndeterminateCircularLoader = ():JSX.Element => {
  return (
    <ThemeProvider theme={ theme }>
      <Loader />
    </ThemeProvider>
  );
};
