import { STRING_KEYS } from '../../../language/keys';
import { ConnectionScoreEnum } from '../../../enums/connection-score.enum';

export const SCORE_BY_LEVEL = {
  [ConnectionScoreEnum.LowConnection]: {
    levelNumber: 1,
    translationKey: STRING_KEYS.TALENT_NETWORK.TITLE.LOW,
  },
  [ConnectionScoreEnum.MediumConnection]: {
    levelNumber: 2,
    translationKey: STRING_KEYS.TALENT_NETWORK.TITLE.MEDIUM,
  },
  [ConnectionScoreEnum.StrongConnection]: {
    levelNumber: 3,
    translationKey: STRING_KEYS.TALENT_NETWORK.TITLE.STRONG,
  },
};
