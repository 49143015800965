import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { CreatePositionState } from '../create-position-state.initial-state';

export const addCompanyBackgroundReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  const slicedBackgrounds = state.companyBackground.slice();
  slicedBackgrounds.push({ title: '' });
  state.companyBackground = slicedBackgrounds;
};

export const setCompanyBackgroundReducer: CaseReducer<CreatePositionState, PayloadAction<PositionJobRequirementState[]>> = (state, { payload }) => {
  state.companyBackground = payload;
};

export const resetCompanyBackgroundReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  state.companyBackground = [];
};
