import React, {
  SyntheticEvent, useEffect,
  useState,
} from 'react';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import {
  useAuthActions,
  useSocialLoginState,
  useSignUpState,
  useLoginState,
} from '@frontegg/react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PRIVACY_CLASS } from '../../../../consts/fullstory';
import { PAGE_NAMES } from '../../../../consts/pages';
import { COMPONENT_NAME, EVENT_GROUP } from '../../../../consts/analytics';
import { SIGNUP_ERROR_CODE, SIGNUP_ERROR_MESSAGES } from '../../../../consts';
import STRING_KEYS from '../../../../language/keys';
import { validateEmail } from '../../../../utils';
import { getGoogleSocialLoginUrl, passwordValidator } from '../../../../utils/frontegg';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import PrimaryButton from '../../../../components/Common/Buttons/PrimaryButton';
import { PasswordRules } from '../../../../components/Common/PasswordRules';
import { AppRouting } from '../../../../services/appRoutingResolver';
import { useValidateEmailDomain } from '../../hooks/onboarding-react-query.hooks';
import { isPersonalEmailError } from '../../utils/onboarding.utls';
import { ONBOARDING_ANALYTICS_EVENTS } from '../../consts/onboarding-analytics.const';
import { CREATE_POSITION_ANALYTICS_EVENTS } from '../../../create-position/consts/position-analytics.consts';
import LoginAndRegistrationComponent
  from '../../../../components/login-and-reqistration-container/login-and-registration.component';
import DescriptionTitleWithDividers
  from '../../../../components/Common/discription-title-with-dividers/description-title-with-dividers.componet';
import { setUserJustSignedUp } from '../../../../store/slices/app-state/app-state.toolkit-slice';
import { useUrlQuery } from '../../../../hooks/route';
import { PROMO_LOCAL_STORAGE_KEY } from '../../consts/onboarding.const';
import {
  ContinueWithGoogleContainer,
  PageContentsWrapper,
  WorkEmailInput,
  LetsGoButtonWrapper,
  PasswordInput,
  ValidationError,
  Wrapper,
  AlreadyHaveAccountText,
  LoginButton,
  EmailIcon,
  PasswordIcon,
  Background,
  InputWrapper,
} from './onboarding-registration.page.styled';

type RegistrationScreenProps = {
  onScreenCompleted: () => void,
}

export const SIGN_UP_ERRORS = {
  USER_EXIST: 'User already exists',
};

const SOCIAL_LOGIN_OPTION = 'google';
const ONBOARDING_ACCOUNT_NAME = `onboarding-${v4()}`;

const QUERY_PARAM_ERROR_CODE = 'errorCode';

export const RegistrationScreen = ({
  onScreenCompleted,
}: RegistrationScreenProps): JSX.Element => {
  const pageQuery = useUrlQuery();
  const [emailInput, setEmailInput] = useState<string>('');
  const [showPasswordRules, setPasswordRulesVisibility] = useState<boolean>(false);
  const [localEmailError, setLocalEmailError] = useState<boolean>(false);
  const [localPasswordError, setLocalPasswordError] = useState<boolean>(false);
  const [passwordInput, setPasswordInput] = useState<string>('');
  const [termsOfUseModalVisible, setTermsOfUseModalVisible] = useState<boolean>(false);
  const { t: translate } = useTranslation();
  const { analyticsTrackEvent, analyticsPageEvent } = useAnalyticsContext();
  
  const promo = pageQuery.get(AppRouting.URL_PARAMS.PROMO);
  if (promo) {
    localStorage.setItem(PROMO_LOCAL_STORAGE_KEY, promo);
  }

  const dispatch = useDispatch();

  const actions = useAuthActions();
  const { socialLoginsConfigV2 } = useSocialLoginState();
  const { loading: signUpLoading, error: signUpError = '' } = useSignUpState();
  const { loading: loginLoading } = useLoginState();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const errorCode = (queryParams.get(QUERY_PARAM_ERROR_CODE) || 0) as SIGNUP_ERROR_CODE;
  const errorMessageKey = SIGNUP_ERROR_MESSAGES[errorCode] || STRING_KEYS.SIGNUP_ERRORS.UNDEFINED;

  useEffect(() => {
    const email = queryParams.get('email');
    if (email) {
      setEmailInput(email);
    }
    analyticsPageEvent({
      eventName: ONBOARDING_ANALYTICS_EVENTS.SIGN_UP_PAGE_VIEWED,
      eventGroup: EVENT_GROUP.PAGE_VIEW,
      pageName: PAGE_NAMES.SIGN_IN_ONBOARDING_PAGE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signUpUserAction = () => {
    actions.signUpUser({
      email: emailInput,
      companyName: ONBOARDING_ACCOUNT_NAME,
      password: passwordInput,
      events: {
        signUpComplete() {
          dispatch(setUserJustSignedUp());

          actions.login({
            email: emailInput,
            password: passwordInput,
            callback: () => {
              onScreenCompleted();
            },
          });
        },
      },
    });
  };

  const {
    isLoading: isEmailValidationLoading,
    isError: isEmailValidationError,
    mutate: validateEmailDomain,
    error,
  } = useValidateEmailDomain(signUpUserAction);

  const sendTrackAnalyticEvent = (eventName: string) => {
    const trackEventPayload = {
      eventName,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.SIGN_IN_ONBOARDING_PAGE,
      componentName: COMPONENT_NAME.SIGN_IN,
    };

    analyticsTrackEvent(trackEventPayload);
  };

  const loginTrackAnalyticEvent = () => {
    const trackEventPayload = {
      eventName: ONBOARDING_ANALYTICS_EVENTS.ONBOARDING_LOGIN_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.SIGN_IN_ONBOARDING_PAGE,
    };

    analyticsTrackEvent(trackEventPayload);
  };

  const handleSignUpClick = async (event: SyntheticEvent) => {
    // preventing the refresh of the browser upon form completion default behavior
    event.preventDefault();
    sendTrackAnalyticEvent(CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_LETS_GO_CLICKED);

    if (!validateEmail(emailInput)) {
      setLocalEmailError(true);

      return;
    }

    if (!passwordValidator(passwordInput)) {
      setLocalPasswordError(true);

      return;
    }

    validateEmailDomain({ email: emailInput });
  };

  const handleContinueWithGoogle = () => {
    sendTrackAnalyticEvent(CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_CONTINUE_WITH_GOOGLE_CLICKED);
    const googleConfiguration =
    socialLoginsConfigV2?.find((config) => config.type === SOCIAL_LOGIN_OPTION);

    const redirectUrl = AppRouting.buildSocialLoginSuccessUrl(window.location);

    window.location.href = getGoogleSocialLoginUrl(
      googleConfiguration?.clientId as string,
      redirectUrl,
    );
  };

  const handleEmailInputChange = (e: SyntheticEvent) => {
    const element = e.target as HTMLInputElement;

    actions.resetSignUpState();

    if (validateEmail(element.value)) {
      setLocalEmailError(false);
    }

    setEmailInput(element.value);
  };

  const handlePasswordInputChange = (e: SyntheticEvent) => {
    const element = e.target as HTMLInputElement;

    actions.resetSignUpState();

    if (passwordValidator(element.value)) {
      setLocalPasswordError(false);
    }

    setPasswordInput(element.value);
  };

  // const handleOpenTermsOfUseModal = () => {
  //   setTermsOfUseModalVisible(true);
  // };

  const handleCloseTermsOfUseModal = () => {
    setTermsOfUseModalVisible(false);
  };

  const definePasswordErrors = () => {
    if (localPasswordError) {
      return (
        <ValidationError>
          { translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_ENTER_VALID_PASSWORD) }
        </ValidationError>
      );
    }

    return null;
  };

  const defineEmailErrors = () => {
    if (isEmailValidationError) {
      if (error) {
        const internalCode = error?.response?.data.internalErrorCode || 0;
        if (isPersonalEmailError(internalCode)) {
          return (
            <ValidationError>
              { translate(STRING_KEYS.ONBOARDING.PERSONAL_EMAIL_ERROR) }
            </ValidationError>
          );
        }
      }

      return (
        <ValidationError>
          { translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_ENTER_WORK_EMAIL) }
        </ValidationError>
      );
    }

    if (localEmailError) {
      return (
        <ValidationError>
          { translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_ENTER_VALID_EMAIL) }
        </ValidationError>
      );
    }

    return null;
  };

  const defineSignupError = () => {
    switch (signUpError) {
      case SIGN_UP_ERRORS.USER_EXIST: {
        return translate(STRING_KEYS.ONBOARDING.USER_EXIST);
      }
      default: {
        return signUpError;
      }
    }
  };

  const handleLoginClick = () => {
    loginTrackAnalyticEvent();

    AppRouting.navigateToUrl(AppRouting.buildGlobalLoginUrl(window.location));
  };

  return (
    <LoginAndRegistrationComponent
      headerSticky
      error={ !!errorCode && translate(errorMessageKey) }
      isLoading={ signUpLoading || loginLoading || isEmailValidationLoading }
      termsOfUseModalVisible={ termsOfUseModalVisible }
      handleCloseTermsOfUseModal={ handleCloseTermsOfUseModal }
      title={ translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_TITLE) }
      subTitle={ translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_SUBTITLE) }
    >
      <PageContentsWrapper>
        <ContinueWithGoogleContainer onClick={ handleContinueWithGoogle }>
          { translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_CONTINUE_WITH_GOOGLE) }
        </ContinueWithGoogleContainer>
        <DescriptionTitleWithDividers
          text={ translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_DESCRIPTION) }
        />
        <form
          id="app-signup-form"
          style={ { position: 'relative' } }
          onSubmit={ handleSignUpClick }
        >
          <EmailIcon />
          <InputWrapper>
            <WorkEmailInput
              autoComplete="new-password"
              defaultValue={ emailInput || '' }
              onChange={ handleEmailInputChange }
              placeholder={
                translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_WORK_EMAIL_PLACEHOLDER)
              }
              isError={ localEmailError || isEmailValidationError }
            />
          </InputWrapper>
          { defineEmailErrors() }
          {validateEmail(emailInput) && (
            <div style={ { position: 'relative' } }>
              <PasswordIcon />
              <InputWrapper>
                <PasswordInput
                  className={ PRIVACY_CLASS.EXCLUDE }
                  type="password"
                  onChange={ handlePasswordInputChange }
                  onFocus={ () => {
                    setPasswordRulesVisibility(true);
                  } }
                  onBlur={ () => {
                    setPasswordRulesVisibility(false);
                  } }
                  placeholder={
                    translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_CHOOSE_YOUR_PASSWORD)
                  }
                  isError={ localPasswordError }
                />
              </InputWrapper>
            </div>
          )}
          { definePasswordErrors() }

          <PasswordRules
            isShowPasswordRules={ showPasswordRules }
            passwordInput={ passwordInput }
          />

          {signUpError && (
            <ValidationError>
              {defineSignupError()}
            </ValidationError>
          )}

          <LetsGoButtonWrapper
            isLoading={ (signUpLoading || loginLoading || isEmailValidationLoading) }
          >
            <PrimaryButton
              title={ translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_LETS_GO_BUTTON) }
              type="submit"
              // PrimaryButton component has to have onClick prop
              onClick={ () => null }
            />
          </LetsGoButtonWrapper>
        </form>
        <Wrapper>
          <AlreadyHaveAccountText>
            {translate(STRING_KEYS.ONBOARDING.ALREADY_HAVE_AN_ACCOUNT)}
          </AlreadyHaveAccountText>
          <LoginButton onClick={ handleLoginClick }>
            {translate(STRING_KEYS.ONBOARDING.LOGIN)}
          </LoginButton>
        </Wrapper>

      </PageContentsWrapper>
      <Background />
    </LoginAndRegistrationComponent>

  );
};
