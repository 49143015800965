import { useSelector } from 'react-redux';
import { RootReducerState } from '../../../index';
import { CompleteStepPayload } from '../../../../modules/onboarding/@types/onboarding-step-complete';
import { createLocationObject, setDefaultMileRadius } from '../../../../utils/location';

export const useCreateOnboardingLetsBeginSelector = (): Partial<CompleteStepPayload> => {
  return useSelector<RootReducerState, Partial<CompleteStepPayload>>(({ createPosition, onboardingReducer }: RootReducerState) => {
    const locations = createLocationObject(createPosition.selectedLocations);
    locations.exactLocations = setDefaultMileRadius(locations.exactLocations);

    return {
      userRole: onboardingReducer.roleName,
      userName: onboardingReducer.userName,
      companyLinkedinUrl: onboardingReducer.selectedCompany.linkedInUrl,
      jobTitleName: createPosition.selectedJobTitle.name,
      locations: createPosition.isRemote ? undefined : locations,
      remotePosition: createPosition.isRemote,
      promo: onboardingReducer.promo,
    };
  });
};
