import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import StepContainer from '../step-container/step-container.component';
import STRING_KEYS from '../../../../../../language/keys';
import { useCreatePositionFeedbackMutation } from '../../../../../../store/rtk-query/apis/position/hooks/create-position-feedback.mutation-hook';
import { Input } from '../../../../../onboarding/components/input/input.component';
import { ANALYTICS_EVENTS } from '../../../../../../consts/analytics';
import { InputContainer } from './position-feedback-step.styled';

const PositionFeedbackStep = ({ positionId, completeStepHandler, backButtonClickHandler }: PositionFeedbackStepProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [feedback, setFeedback] = useState('');
  const [createPositionFeedback, { isLoading: isCreateFeedbackLoading, isSuccess: isCreateFeedbackSuccess }] = useCreatePositionFeedbackMutation();

  const sendFeedback = () => {
    if (feedback) {
      createPositionFeedback({ positionId, feedback });
    }
  };

  useEffect(() => {
    if (isCreateFeedbackSuccess) {
      completeStepHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateFeedbackSuccess]);

  return (
    <StepContainer
      title={ translate(STRING_KEYS.MATCH_TUNE.POSITION_FEEDBACK_STEP.TITLE) }
      subtitle={ translate(STRING_KEYS.MATCH_TUNE.POSITION_FEEDBACK_STEP.SUBTITLE) }
      backButtonHandler={ backButtonClickHandler }
      continueButtonHandler={ sendFeedback }
      isContinueButtonDisabled={ !feedback }
      isContinueButtonLoading={ isCreateFeedbackLoading }
      eventName={ ANALYTICS_EVENTS.MATCH_TUNE_POSITION_FEEDBACK_STEP_VIEWED }
    >
      <InputContainer>
        <Input
          value={ feedback }
          placeholder={ translate(STRING_KEYS.MATCH_TUNE.POSITION_FEEDBACK_STEP.INPUT_PLACEHOLDER) }
          onValueChange={ (value) => setFeedback(value) }
          autoFocus
          onEnter={ sendFeedback }
        />
      </InputContainer>
    </StepContainer>
  );
};

export default PositionFeedbackStep;
