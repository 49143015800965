import { useTranslation } from 'react-i18next';
import BaseFunnelTag from '../base-tag/base-funnel-tag.component';
import STRING_KEYS from '../../../../../../language/keys';
import { BuildingIcon, SparklesIcon } from './people-matched-tags.styled';

export const OutOfCompanies = ({ companyCount }: PeopleMatchedTagProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseFunnelTag
      emoji={ <BuildingIcon /> }
      text={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.PEOPLE_MATCHED.TAGS.OUT_OF_COMPANIES, {
        count: companyCount,
      }) }
    />
  );
};

export const GreatMix = ({ companyCount }: PeopleMatchedTagProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseFunnelTag
      emoji={ <SparklesIcon /> }
      text={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.PEOPLE_MATCHED.TAGS.GREAT_MIX, {
        count: companyCount,
      }) }
    />
  );
};
