import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import STRING_KEYS from '../../../../language/keys';
import { LookingRoleSentence } from '../sentences-components/looking-role-sentence/looking-role-sentence.component';
import { BreakLine } from '../../../../components/Common/break-line-container/break-line.container';
import { CreatePositionContainer } from '../create-position-container/create-position-container.component';
import {
  resetPositionJobTitle,
  resetPositionLocation,
} from '../../../../store/slices/create-position-state/create-position-state.toolkit-slice';
import LocationSentenceContainer from '../sentences-components/location-sentence/location-sentence-container.component';

enum SCREEN_STEPS {
  POSITION_JOB_TITLE,
  POSITION_LOCATION,
  CONTINUE_BUTTON,
}

export const CreatePositionLetsBeginStep = ({
  stepNumber,
  setContinueButtonEnable,
  setContinueButtonDisable,
  setContinueButtonShow,
}: CreatePositionLetsBeginProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [currentLocalStep, setCurrentLocalStep] = useState(SCREEN_STEPS.POSITION_JOB_TITLE);

  useEffect(() => {
    if (currentLocalStep >= SCREEN_STEPS.CONTINUE_BUTTON) {
      dispatch(setContinueButtonEnable());
      dispatch(setContinueButtonShow());
    } else {
      dispatch(setContinueButtonDisable());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocalStep]);

  return (
    <CreatePositionContainer
      title={ translate(STRING_KEYS.CREATE_POSITION.CREATE_POSITION_LETS_BEGIN_TITLE) }
      isOnboarding={ false }
    >
      <>
        {currentLocalStep >= SCREEN_STEPS.POSITION_JOB_TITLE && (
          <>
            <BreakLine />
            <LookingRoleSentence
              stepNumber={ stepNumber }
              onSentenceCompleted={ () => {
                setCurrentLocalStep(SCREEN_STEPS.POSITION_LOCATION);
              } }
              onPositionChange={ () => {
                setCurrentLocalStep(SCREEN_STEPS.POSITION_JOB_TITLE);
                dispatch(resetPositionJobTitle());
                dispatch(resetPositionLocation());
              } }
            />
          </>
        )}

        {currentLocalStep >= SCREEN_STEPS.POSITION_LOCATION && (
          <LocationSentenceContainer
            stepNumber={ stepNumber }
            onSentenceCompleted={ () => setCurrentLocalStep(SCREEN_STEPS.CONTINUE_BUTTON) }
            onSentenceInProgress={ () => setCurrentLocalStep(SCREEN_STEPS.POSITION_LOCATION) }
          />
        )}
      </>
    </CreatePositionContainer>
  );
};
