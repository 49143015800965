import { MAX_ITEMS_TO_SHOW } from '../worked-with-widget.component';
import { AndMoreComponent } from './and-more/and-more.component';
import {
  List,
} from './workers-list.styled';
import { WorkedWithItem } from './worked-with-item/worked-with-item.component';

type WorkersListProps = {
  workers: FormattedWorkedWith[],
}

export const WorkersList = ({
  workers,
}:WorkersListProps):JSX.Element => {
  const getList = () => {
    return (
      workers.slice(0, MAX_ITEMS_TO_SHOW)
        .map((workedWith) => {
          return (
            <WorkedWithItem
              key={ workedWith.iconUrl }
              workedWith={ workedWith }
            />
          );
        })
    );
  };

  return (
    <List>
      {getList()}
      {workers.length > MAX_ITEMS_TO_SHOW && <AndMoreComponent />}
    </List>
  );
};
