import styled from 'styled-components';
import { slideInAnimation } from '../../../animations';
import { ELEMENTS_DURATION } from '../../../consts/durations';

export const Container = styled.div<{
    delay?:number
}>`
  z-index: 2;
  animation: ${({ delay }) => slideInAnimation({
    animationDuration: ELEMENTS_DURATION.WELCOME_SCREEN_SENTENCE_DURATION,
    animationDelay: delay || 0,
    translateStart: '64px',
    translateEnd: '0',
    timingFunction: 'linear',
    fillMode: 'both',
  })}
`;
