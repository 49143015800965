import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/base-query';
import { startTrial } from './account-plan.toolkit-queries';
import { REDUCER_PATH } from './account-plan.consts';

export const accountPlanQuery = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    startTrial: builder.mutation<void, void>({
      query: startTrial,
    }),
  }),
});
