import { ChangeEvent } from 'react';
import { InputContainer, TimerIcon } from './time-picker.styled';

const TimePicker = ({
  time,
  changeHandler,
}: TimePickerProps): JSX.Element => {
  return (
    <InputContainer
      type="time"
      value={ time }
      onChange={ (event: ChangeEvent<HTMLInputElement>) => changeHandler(event.target.value) }
      EndAdornment={ <TimerIcon /> }
    />
  );
};

export default TimePicker;
