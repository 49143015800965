import { Container, LeftContainer, BoldText } from './text-editor-header.styled';

const TextEditorHeader = ({
  label,
  infoText,
  Icon,
  EndAdornment,
  ...otherProps
}: TextEditorHeaderProps): JSX.Element => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Container { ...otherProps }>
      <LeftContainer>
        { Icon }
        <div>
          { label && <div>{label}</div> }
          { infoText && <BoldText>{infoText}</BoldText> }
        </div>
      </LeftContainer>
      { EndAdornment }
    </Container>
  );
};

export default TextEditorHeader;
