import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { Subtitle2Font } from '../../../../themes/fonts';

export const ClosingReasonsWrapper = styled.div`
  text-align: center;
  margin-top: ${THEME.SPACING.LOOSE};
`;

export const CloseReason = styled.div`
  ${Subtitle2Font};
  width: fit-content;
  font-weight: bold;
  line-height: 1.71;
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.CURVY};
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  background-color: ${THEME.COLORS.LIGHT_GREY};
  margin: auto;
  margin-top: ${THEME.SPACING.TINO};
  cursor: pointer;

  :hover {
    background-color: rgb(253,241,248);
    color: ${THEME.COLORS.ACCENT};
  }
`;
