import { ConversationBaseResponseDto } from '../dto/response/conversation-base.response';
import { ConversationsMinimizedResponseDto } from '../dto/response/conversation-minimized.response';

export const conversationsArchivedWebsocketListener = (
  socketPayload: Partial<ConversationBaseResponseDto>,
  updateCachedData: (args: (cacheData: ConversationsMinimizedResponseDto) => void) => void,
): void => {
  updateCachedData((cacheData: ConversationsMinimizedResponseDto) => {
    const foundConversation = cacheData?.conversations?.find((conversation) => conversation.id === socketPayload.id);
    if (foundConversation) {
      foundConversation.isArchived = socketPayload.isArchived;
      foundConversation.archiveReason = socketPayload.archiveReason;
    }
  });
};

export const conversationArchivedWebsocketListener = (
  socketPayload: Partial<ConversationBaseResponseDto>,
  cacheConversationId: string,
  updateCachedData: (args: (conversation: ConversationBaseResponseDto) => void) => void,
): void => {
  updateCachedData((conversation: ConversationBaseResponseDto) => {
    if (cacheConversationId === socketPayload.id) {
      conversation.isArchived = socketPayload.isArchived;
      conversation.archiveReason = socketPayload.archiveReason;
    }
  });
};
