import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { EditorState } from 'draft-js';
import TextEditorContext, { decorators } from '../../../../../../components/Common/perfect-text-editor/context/text-editor.context';
import useTextEditorReducer from '../../../../../../components/Common/perfect-text-editor/context/text-editor.reducer';
import STRING_KEYS from '../../../../../../language/keys';
import ToggleButton from '../toggle-button/toggle-button.component';
import {
  contentToHtml,
  convertMentionsToTokens,
} from '../../../../../../components/Common/perfect-text-editor/perfect-text-editor.utils';
import { decodeHtml } from '../../../../../../utils';
import { Editor } from './step-editor.styled';
import { useCustomModeMentionsOptions } from './custom-mode-mentions-options.hook';

const SUBJECT_EDITOR_ID = 'custom_steps_editor_subject';
const BODY_EDITOR_ID = 'custom_steps_body';

const StepEditor = ({
  step, changeMessageHandler, changeSubjectHandler, subject, message, sendAsReply, sandAsReplyHandler,
}: StepEditorProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [state, actions] = useTextEditorReducer({
    editorInFocus: true,
    showSubjectEditor: true,
    subjectReadOnly: !!sendAsReply,
  });

  const { editorBodyState, editorSubjectState } = state;
  const { setSubjectReadOnly, setEditorSubjectState } = actions;

  const mentions = useCustomModeMentionsOptions();

  const onClickSubjectReadOnly = (value: boolean) => {
    if (!value) {
      setEditorSubjectState(EditorState.createEmpty(decorators));
    }
    setSubjectReadOnly(value);
    sandAsReplyHandler(value);
  };

  useEffect(() => {
    const currentState = editorSubjectState.getCurrentContent();
    let subjectPlainText;
    if (currentState.hasText()) {
      subjectPlainText = convertMentionsToTokens(currentState, true);
    } else {
      subjectPlainText = currentState.getPlainText();
    }
    changeSubjectHandler(decodeHtml(subjectPlainText));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorSubjectState]);

  useEffect(() => {
    const bodyHtml = contentToHtml(editorBodyState.getCurrentContent(), true);
    if (bodyHtml === '<br/>') {
      changeMessageHandler('');
    } else {
      changeMessageHandler(bodyHtml);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorBodyState]);

  useEffect(() => {
    if (sendAsReply) {
      changeSubjectHandler(translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.EDITOR.SUBJECT_AS_REPLY));
    }
    // eslint-disable-next-line
  }, [sendAsReply]);

  const conditionalSubject = useMemo(() => {
    return sendAsReply ? translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.EDITOR.SUBJECT_AS_REPLY) : subject;
    // eslint-disable-next-line
  }, [sendAsReply, subject]);

  const renderSwitch = () => {
    if (step === 1) {
      return undefined;
    }

    return (
      <ToggleButton
        checked={ !!sendAsReply }
        setChecked={ onClickSubjectReadOnly }
        label={ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.EDITOR.SWITCH_LABEL) }
      />
    );
  };

  return (
    <div>
      <TextEditorContext.Provider
        value={ {
          state,
          actions,
        } }
      >
        <Editor
          subjectContainerId={ `${SUBJECT_EDITOR_ID}_${step}` }
          bodyContainerId={ `${BODY_EDITOR_ID}_${step}` }
          showSubject
          mentions={ mentions }
          subject={ conditionalSubject }
          message={ message }
          ActionButton={ renderSwitch() }
        />
      </TextEditorContext.Provider>
    </div>
  );
};

export default StepEditor;
