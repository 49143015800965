import styled from 'styled-components';
import { THEME } from '../../../../../themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
  margin-top: ${THEME.SPACING.BASE};
  width: 100%;
  padding: 0 ${THEME.SPACING.MEDIUM};
`;

export const Header = styled.span`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINO};
`;
