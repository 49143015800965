import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { TalentNetworkEducationDto } from '../../../../modules/application/components/talent-network/mutual-education-sentence/mutual-education-sentence';
import EducationInformationSkeleton from './education-information-skeleton/education-information-skeleton.component';
import EducationItem from './education-item/education-item.component';

import {
  Container,
  Header,
} from './education-information.style';

type EducationInformationProps = {
  data: Array<MatchEducationHistory> | undefined,
  isLoading: boolean,
  trackMatchElementViewed?: (eventName: string, componentElementType: string, componentElement?: string) => void,
  companyEducationMap: Map<string, Map<number, TalentNetworkEducationDto[]>>,
}

export const EducationInformation = ({
  data,
  isLoading,
  trackMatchElementViewed,
  companyEducationMap,
} : EducationInformationProps) : JSX.Element => {
  const { t: translate } = useTranslation();

  if (isLoading) {
    return (
      <EducationInformationSkeleton />
    );
  }

  return (
    <Container>

      <Header>
        {translate(STRING_KEYS.EDUCATION)}
      </Header>

      {
        data?.map((educationHistoryItem, index) => {
          const {
            institutionId,
            badges,
            endDateObject,
            institutionLogo,
            institutionName,
            startDateObject,
            fieldOfStudy,
            degree,
            educationId,
          } = educationHistoryItem;

          const key = `${institutionId}-${startDateObject?.year}
          -${endDateObject?.year}-${degree}-${fieldOfStudy}`;

          let talentNetworkEducationMap;
          if (companyEducationMap && institutionId) {
            talentNetworkEducationMap = companyEducationMap.get(institutionId);
          }

          return (
            <EducationItem
              key={ key }
              badges={ badges }
              endDateObject={ endDateObject }
              institutionLogo={ institutionLogo }
              institutionName={ institutionName }
              startDateObject={ startDateObject }
              fieldOfStudy={ fieldOfStudy }
              educationId={ educationId }
              degree={ degree }
              isLastItem={ index === data.length - 1 }
              trackMatchElementViewed={ trackMatchElementViewed }
              talentNetworkEducationMap={ talentNetworkEducationMap }
            />
          );
        })
      }
    </Container>
  );
};

EducationInformation.defaultProps = {
  trackMatchElementViewed: undefined,
};
