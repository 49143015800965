/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import STRING_KEYS from '../../../../language/keys';
import {
  BASIC_DELAY,
  LETS_BEGIN_TYPING_NAME_SENTENCE_DELAY,
} from '../../../../consts/delays';
import { CompanyDialog } from '../../components/company-dialog/company-dialog.component';
import { CompanySentence } from '../../components/sentences/company-sentence/company-sentence.component';
import { LookingRoleSentence } from '../../../create-position/components/sentences-components/looking-role-sentence/looking-role-sentence.component';
import { MyRoleSentence } from '../../components/sentences/my-role-sentence/my-role-sentence.component';
import { NameSentence } from '../../components/sentences/name-sentence/name-sentence.component';
import { COMPONENT_NAME, EVENT_GROUP } from '../../../../consts/analytics';
import {
  useShouldRetryFetchAllowedCompanies,
} from '../../../../store/selectors';
import { PAGE_NAMES } from '../../../../consts/pages';
import { ONBOARDING_ANALYTICS_EVENTS } from '../../consts/onboarding-analytics.const';
import { getStepIndex } from '../../utils/onboarding-bi-events.utls';
import { useCreatePositionProgressSteps } from '../../../create-position/hooks/create-position-progress-steps.hooks';
import { useGetCurrentStep } from '../../hooks/onboarding-current-step.hooks';
import { CreatePositionStepsEnums } from '../../../create-position/enums/create-position-steps.enums';
import { BreakLine } from '../../../../components/Common/break-line-container/break-line.container';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import {
  Container,
  Wrapper,
  PageTitle,
} from '../../../create-position/components/create-position-container/create-position-container.styled';
import {
  resetPositionJobTitle,
  resetPositionLocation,
} from '../../../../store/slices/create-position-state/create-position-state.toolkit-slice';
import { useGetAllowedCompaniesQuery } from '../../../../store/rtk-query/apis/onboarding/hooks/get-allowed-companies.query-hook';
import {
  resetCompany,
  setCompany, setOnboardingContinueButtonDisable,
  setOnboardingContinueButtonEnable,
  setOnboardingContinueButtonShow, setPromo,
} from '../../../../store/slices/onboarding-state/onboarding-state.toolkit-slice';
import LocationSentenceContainer
  from '../../../create-position/components/sentences-components/location-sentence/location-sentence-container.component';
import { PROMO_LOCAL_STORAGE_KEY } from '../../consts/onboarding.const';
import { BodyWrapper } from './onboarding-lets-begin.page.styled';

enum SCREEN_STEPS {
  MY_NAME,
  MY_ROLE,
  MY_COMPANY,
  POSITION_JOB_TITLE,
  POSITION_LOCATION,
  CONTINUE_BUTTON,
}

const MAX_ALLOWED_COMPANIES_RETRY = 10;

export const LetsBeginScreen = ({
  disabledAnimation,
}: LetsBeginScreenProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [isShowCompanyDialog, setShowCompanyDialog] = useState<boolean>(false);
  const [currentLocalStep, setCurrentLocalStep] = useState(SCREEN_STEPS.MY_NAME);
  const maxRetry = useRef(0);
  const dispatch = useDispatch();
  const shouldRetryFetchAllowedCompanies = useShouldRetryFetchAllowedCompanies();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const currentStep = useGetCurrentStep<CreatePositionStepsEnums>();
  const { steps } = useCreatePositionProgressSteps(currentStep);
  const stepNumber = getStepIndex(currentStep, steps);
  const { refetch, data: { allowedCompanies } } = useGetAllowedCompaniesQuery();

  const promo = localStorage.getItem(PROMO_LOCAL_STORAGE_KEY);
  if (promo) {
    dispatch(setPromo(promo));
  }

  useEffect(() => {
    if (currentLocalStep >= SCREEN_STEPS.CONTINUE_BUTTON) {
      dispatch(setOnboardingContinueButtonEnable());
      dispatch(setOnboardingContinueButtonShow());
    } else {
      dispatch(setOnboardingContinueButtonDisable());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocalStep]);

  useEffect(() => {
    if (
      shouldRetryFetchAllowedCompanies &&
      allowedCompanies?.length === 0 &&
      currentLocalStep < SCREEN_STEPS.MY_COMPANY &&
      maxRetry.current <= MAX_ALLOWED_COMPANIES_RETRY
    ) {
      refetch?.();
      maxRetry.current++;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRetryFetchAllowedCompanies]);

  const handleOpenCompanyDialog = () => {
    setShowCompanyDialog(true);
    const trackEventPayload = {
      eventName: ONBOARDING_ANALYTICS_EVENTS.ONBOARDING_COMPANY_LINKEDIN_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.BASIC_INFO,
      componentName: COMPONENT_NAME.COMPANY,
      stepNumber,
    };

    analyticsTrackEvent(trackEventPayload);
  };

  const handleCloseCompanyDialog = () => {
    setShowCompanyDialog(false);
  };

  return (
    <>
      <Container>
        <Wrapper>
          <PageTitle includeAnimationDelay={ !disabledAnimation }>
            {translate(STRING_KEYS.ONBOARDING.ONBOARDING_LETS_BEGIN_TITLE)}
          </PageTitle>
          <BodyWrapper includeWelcomeScreenDelay={ !disabledAnimation }>
            {currentLocalStep >= SCREEN_STEPS.MY_NAME && (
              <NameSentence
                onSentenceCompleted={ () => setCurrentLocalStep((prevStep) => {
                  return prevStep > SCREEN_STEPS.MY_ROLE ? prevStep : SCREEN_STEPS.MY_ROLE;
                }) }
                startDelay={ !disabledAnimation ?
                  LETS_BEGIN_TYPING_NAME_SENTENCE_DELAY : BASIC_DELAY }
              />
            )}

            {currentLocalStep >= SCREEN_STEPS.MY_ROLE && (
              <MyRoleSentence
                onSentenceCompleted={ () => setCurrentLocalStep((prevStep) => {
                  return prevStep > SCREEN_STEPS.MY_COMPANY ? prevStep : SCREEN_STEPS.MY_COMPANY;
                }) }
              />
            )}

            {currentLocalStep >= SCREEN_STEPS.MY_COMPANY && (
              <CompanySentence
                onOpenCompanyDialog={ handleOpenCompanyDialog }
                onSentenceCompleted={ () => setCurrentLocalStep(SCREEN_STEPS.POSITION_JOB_TITLE) }
                onCompanyChange={ () => {
                  setCurrentLocalStep(SCREEN_STEPS.MY_COMPANY);
                  dispatch(resetCompany());
                  dispatch(resetPositionJobTitle());
                  dispatch(resetPositionLocation());
                } }
              />
            )}

            {currentLocalStep >= SCREEN_STEPS.POSITION_JOB_TITLE && (
              <>
                <BreakLine />
                <LookingRoleSentence
                  stepNumber={ stepNumber }
                  onSentenceCompleted={ () => {
                    setCurrentLocalStep(SCREEN_STEPS.POSITION_LOCATION);
                  } }
                  onPositionChange={ () => {
                    setCurrentLocalStep(SCREEN_STEPS.POSITION_JOB_TITLE);
                    dispatch(resetPositionJobTitle());
                    dispatch(resetPositionLocation());
                  } }
                />
              </>
            )}

            {currentLocalStep >= SCREEN_STEPS.POSITION_LOCATION && (
              <LocationSentenceContainer
                stepNumber={ stepNumber }
                onSentenceCompleted={ () => setCurrentLocalStep(SCREEN_STEPS.CONTINUE_BUTTON) }
                onSentenceInProgress={ () => setCurrentLocalStep(SCREEN_STEPS.POSITION_LOCATION) }
              />
            )}
          </BodyWrapper>
        </Wrapper>
      </Container>
      {isShowCompanyDialog && (
        <CompanyDialog
          onClose={ handleCloseCompanyDialog }
          onDone={ (company) => {
            setCurrentLocalStep(SCREEN_STEPS.POSITION_JOB_TITLE);
            dispatch(setCompany({
              companyLinkedInUrl: company?.sanitizedLinkedinUrl || '',
              name: company?.name || '',
            }));
          } }
        />
      )}
    </>
  );
};
