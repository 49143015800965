import styled from 'styled-components';
import { THEME } from '../../../themes';

export const Container = styled.div<{isShowPasswordRules: boolean}>`
  ${({ isShowPasswordRules }) => {
    if (isShowPasswordRules) {
      return `
        max-height: 70px;
        opacity: 1;
        transform: scaleY(1);
        padding: ${THEME.SPACING.TINO};
      `;
    }

    return `
      max-height: 0;
      opacity: 0;
      transform: scaleY(0);
      padding: 0;
    `;
  }}
  width: 100%;
  transition: all 0.3s;
`;

export const RuleContainer = styled.div`
  color: ${THEME.COLORS.SUCCESS};
`;
