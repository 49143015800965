import { useTranslation } from 'react-i18next';
import { PerfectButtonHoverVariants } from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../../language/keys';
import {
  ActionButton,
  Container,
  ContentContainer,
  Description,
  ErrorIcon,
  Title,
} from './conversation-message-error.styled';
import { ConversationMessageErrorProps } from './conversation-message-error.d';

const ConversationMessageError = ({ actionButtonHandler, isLoading, wasBounced }: ConversationMessageErrorProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const titleTextKey = wasBounced ? STRING_KEYS.OUTREACH_PAGE.SEND_MESSAGE_BANNER_ERROR.BOUNCED_TITLE : STRING_KEYS.OUTREACH_PAGE.SEND_MESSAGE_BANNER_ERROR.TITLE;
  const descriptionTextKey = wasBounced ?
    STRING_KEYS.OUTREACH_PAGE.SEND_MESSAGE_BANNER_ERROR.BOUNCED_DESCRIPTION : STRING_KEYS.OUTREACH_PAGE.SEND_MESSAGE_BANNER_ERROR.DESCRIPTION;

  return (
    <Container>
      <ContentContainer>
        <ErrorIcon />
        <div>
          <Title>{ translate(titleTextKey) }</Title>
          <Description>{ translate(descriptionTextKey) }</Description>
        </div>
      </ContentContainer>
      {!wasBounced && (
        <ActionButton
          isLoading={ isLoading }
          disabled={ isLoading }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          onClick={ actionButtonHandler }
        >
          { translate(STRING_KEYS.OUTREACH_PAGE.SEND_MESSAGE_BANNER_ERROR.BUTTON) }
        </ActionButton>
      )}
    </Container>
  );
};

export default ConversationMessageError;
