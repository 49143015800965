import { outreachQuery } from '../outreach.toolkit-api';
import { ApiError } from '../../../../../consts/api';
import { ArchiveConversationByIdQueryArguments } from '../dto/query-arguments/archive-conversation-by-id.query-arguments';

type UseArchiveConversationByIdMutationType = [
  (request: ArchiveConversationByIdQueryArguments) => void,
  AppSelectorType<void | undefined, ApiError>
];

export const useArchiveConversationByIdMutation = (): UseArchiveConversationByIdMutationType => {
  const [
    archiveConversation,
    {
      data, isLoading, isError, error, isSuccess,
    }] = outreachQuery.useArchiveConversationMutation();

  const meta = {
    isLoading, isError, error: error as ApiError, isSuccess, data,
  };

  return [
    archiveConversation,
    { ...meta },
  ];
};
