import styled from 'styled-components';
import { THEME } from '../../../themes';
import { Body1Font, CaptionFont, Subtitle1Font } from '../../../themes/fonts';
import { ASSETS } from '../../../assets';
import { TextEllipsis } from '../../../themes/text';

const COMPONENT_HEIGHT = 64;

export const InputContainer = styled.div`
  position: relative;
  flex-grow: 1;
  height: 100%;
  padding: 0 ${THEME.SPACING.MEDIUM};
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const activeStyle = `
  & ${InputContainer} {
    background-color: ${THEME.COLORS_RGB.ACCENT_DIVIDER};
  }
`;

export const Container = styled.div<{ disabled?: boolean, active?: boolean }>`
  cursor: ${({ disabled }) => disabled ? 'auto' : 'pointer'};
  display: flex;
  flex-direction: row;
  height: ${COMPONENT_HEIGHT}px;
  background-color: ${({ disabled }) => disabled ? THEME.COLORS.LIGHT_GREY : THEME.COLORS_RGB.ACCENT_GHOST};
  position: relative;
  transition: all 0.2s;
  border: 1px solid ${({ disabled }) => disabled ? THEME.COLORS.LIGHT_GREY : THEME.COLORS_RGB.PRIMARY_DARK_GHOST};
  ${({ active }) => active ? activeStyle : null};

  ${({ disabled }) => {
    if (disabled) {
      return '';
    }

    return `
      :hover {${activeStyle}}`;
  }};
`;

export const IconContainer = styled.div`
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${THEME.COLORS_RGB.PRIMARY_DARK_GHOST};
  flex-shrink: 0;
`;

export const DefaultIcon = styled(ASSETS.PERFECT_MARK)`
  width: 24px;
`;

export const InputImage = styled.img`
  width: 32px;
  border-radius: ${THEME.RADIUS.CIRCLE};
`;

export const Title = styled.div`
  ${Subtitle1Font};
  color: ${THEME.COLORS.CONTENT_A};
  padding-left: ${THEME.SPACING.BASE};
  
  &:hover {
    color: ${THEME.COLORS.ACCENT};
  }
`;

export const PlaceholderContainer = styled.div<{ disabled?: boolean }>`
  ${Subtitle1Font};
  font-weight: bold;
  text-align: left;
  color: ${({ disabled }) => disabled ? THEME.COLORS_RGB.PRIMARY_DARK_LOW : THEME.COLORS.PRIMARY_DARK};
`;

export const SelectedItemSubtitle = styled.div`
  ${CaptionFont};
  ${TextEllipsis};
`;

export const Placeholder = styled.div`
  color: ${THEME.COLORS.ACCENT};
`;

export const Arrow = styled(ASSETS.ARROW_DOWN)<{ isMenuOpen?: boolean }>`
  width: 20px;
  ${({ isMenuOpen }) => isMenuOpen ? 'transform: rotate(180deg)' : null};
  transform-origin: center;
  transition: transform 0.1s ease;
`;

export const Input = styled.input`
  ${Body1Font};
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
`;
