import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { createCheckoutSession } from '../api/payment';
import { StripeCheckoutSession } from '../@types/api';

export const usePaymentInteraction = (
  onErrorCallback?: (error: AxiosError) => void,
): UseMutationResult<StripeCheckoutSession, AxiosError, string> => {
  return useMutation(
    (callbackUrl: string) => {
      const encodedCallbackUrl = Buffer.from(callbackUrl).toString('Base64');

      return createCheckoutSession(encodedCallbackUrl);
    }, {
      onSuccess: (data) => {
        window.location.href = data.url;
      },
      onError: onErrorCallback,
    },
  );
};
