/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({
  containerId, className, children, isGlobalPortal, removeAfterUnmount = true,
}: PortalProps): JSX.Element => {
  const target = useRef<any>(null);

  useEffect(
    () => () => {
      if (target.current && removeAfterUnmount) {
        target.current?.remove();
        target.current = null;
      }
    },
    [removeAfterUnmount],
  );

  if (!target.current) {
    const div = document.getElementById(containerId);
    target.current = div || document.createElement('div');
    target.current.setAttribute('id', containerId);
    if (className) {
      target.current.classList.add(className);
    }
    if (isGlobalPortal) {
      document.body.appendChild(target.current);
    }
  }

  return createPortal(children, target.current, containerId);
};

export default Portal;
