import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { CaptionFont, Subtitle1Font, Subtitle2Font } from '../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../utils/colors';
import { ASSETS } from '../../../../../assets';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: ${THEME.SPACING.BASE};
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
  width: 100%;
`;

export const Title = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
`;

export const Description = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;

export const MessageContainer = styled.div`
  ${Subtitle2Font};
  line-height: 1.43;
  letter-spacing: 0.25px;
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
  border-top-right-radius: ${THEME.RADIUS.ROUNDISH};
  border-top-left-radius: ${THEME.RADIUS.ROUNDISH};
  border-bottom-right-radius: ${THEME.RADIUS.ROUNDISH};
  background-color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
  width: fit-content;
  padding: ${THEME.SPACING.BASE};
`;

export const MessageIcon = styled(ASSETS.QUOTE_BUBBLE_OUTLINE)`
  width: 16px;
  height: 16px;
  fill: ${THEME.COLORS.PRIMARY_DARK};
`;
export const Message = styled.div`
  width: 100%;
`;
