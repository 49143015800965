import { SequenceCreationCompletedWebsocketPayload } from '../dto/websocket-payloads/sequence-creation-completed.websocket-payload';
import { ConversationBaseResponseDto } from '../../outreach/dto/response/conversation-base.response';
import { ConversationsMinimizedResponseDto } from '../../outreach/dto/response/conversation-minimized.response';

const assignSocketPayloadDataToConversation = (
  socketPayload: SequenceCreationCompletedWebsocketPayload,
  conversation: ConversationBaseResponseDto,
) => {
  conversation.nextOpenLinkedinTask = socketPayload.nextOpenLinkedinTask;
  conversation.nextScheduledMessage = socketPayload.nextScheduledMessage;
  conversation.sequenceCreationStatus = socketPayload.sequenceCreationStatus;
  conversation.sequenceLength = socketPayload.sequenceLength;
  conversation.status = socketPayload.status;
  conversation.wasReplied = socketPayload.wasReplied;
};

export const sequenceCreationCompletedConversationWebsocketListener = (
  socketPayload: SequenceCreationCompletedWebsocketPayload,
  cacheConversationId: string,
  updateCachedData: (args: (conversation: ConversationBaseResponseDto) => void) => void,
): void => {
  updateCachedData((conversation: ConversationBaseResponseDto) => {
    if (cacheConversationId === socketPayload.id) {
      assignSocketPayloadDataToConversation(socketPayload, conversation);
    }
  });
};

export const sequenceCreationCompletedConversationsWebsocketListener = (
  socketPayload: SequenceCreationCompletedWebsocketPayload,
  updateCachedData: (args: (cacheData: ConversationsMinimizedResponseDto) => void) => void,
): void => {
  updateCachedData((cacheData: ConversationsMinimizedResponseDto) => {
    const foundConversation = cacheData?.conversations?.find((conversation) => conversation.id === socketPayload.id);

    if (foundConversation) {
      assignSocketPayloadDataToConversation(socketPayload, foundConversation);
    }
  });
};
