import { IndeterminateCircularLoader } from '../../Common/Loaders/IndeterminateCircularLoader';
import { IndeterminateLinearLoader } from '../../Common/Loaders/IndeterminateLinearLoader';

// todo: change & beautify skeleton
export const WithSkeletonLoader = (
  isLoading: boolean,
  content: JSX.Element | string | undefined,
  minWidth?: number | string | null,
  type?: 'circle' | 'line',
): JSX.Element | string | undefined => {
  if (isLoading) {
    return (
      <div style={ { minWidth: minWidth || 50 } }>
        {type === 'circle' ? <IndeterminateCircularLoader /> : <IndeterminateLinearLoader />}
      </div>
    );
  }

  return content;
};
