export const COLORS = {
  WHITE0: '#ffffff',
  WHITE1: '#fffefe',
  WHITE3: '#fcfcfc',
  PURPLE: '#000072',
  DARK_PURPLE: '#000040',
  LIGHT_PURPLE: '#7e27e4',
  GREEN: '#33cc66',
  YELLOW: '#f1c40f',
  RED: '#ff0031',
  PINK: '#ff0660',
  BLUE: '#00bbff',
  GREY: '#e2e1e6',
  MID_GREY: '#eeeef5',
  LIGHT_GREY: '#f5f5f7',
  LIGHT_PINK: '#ffe5ef',
  OPACITY: 'rgba(0, 0, 0, 0.05)',
  BLACK: '#010a14',
  BLACK2: '#00001f',
  BLACK3: '#000026',
  DARK_BLUE: '#021d3d',
  TRANSPARENT: 'transparent',
};
