import {
  Container,
  ImageWrapper,
  Wrapper,
  CompanyTrendContainer,
  Description,
} from './child-widget.styled';

type ChildWidgetProps = {
  description: JSX.Element;
  icon: JSX.Element;
}

export const ChildWidget = ({
  description,
  icon,
}: ChildWidgetProps):JSX.Element => {
  return (
    <CompanyTrendContainer>
      <Container>
        <div>
          <ImageWrapper>{ icon }</ImageWrapper>
        </div>
        <Wrapper>
          <Description>{ description }</Description>
        </Wrapper>
      </Container>
    </CompanyTrendContainer>
  );
};
