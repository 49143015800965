import styled from 'styled-components';
import { THEME } from '../../../themes';
import { ButtonFont } from '../../../themes/fonts';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin: 0 auto;
  height: 100vh;
`;

export const LogoContainer = styled.div`
  display: block;
  margin-top: ${THEME.SPACING.TINO};
  margin-bottom: ${THEME.SPACING.GRAND};
`;

export const GoLink = styled.a`
  ${ButtonFont}
  color: ${THEME.COLORS.ACCENT};
  margin-top: ${THEME.SPACING.GRAND};
  margin-bottom: ${THEME.SPACING.XLOOSE};
  text-align: center;

  :hover {
    opacity: 0.8;
  }
`;
