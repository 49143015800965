import { LocationDto } from '../../../models/dto/location.dto';
import { LevelOfExperience } from '../../../enums/level-of-experience.enum';
import { LevelOfDegreeEnum } from '../../../enums/level-of-degree';

export type CreatePositionState = {
  jobDescriptionTempUrls:  string,
  jobDescriptionS3TempUrls:  string,
  selectedLevelsOfExperience: LevelOfExperience[],
  degrees: LevelOfDegreeEnum[],
  userInputFieldsOfStudy: PositionJobRequirementState[],
  companyBackground: PositionJobRequirementState[],
  skills: PositionJobRequirementState[],
  selectedJobTitle: {
    name: string,
  },
  selectedLocations: LocationDto[],
  isRemote: boolean,
  error: string,
  errorCode: number,
  loading: boolean,
  isContinueButtonEnabled: boolean,
  showContinueButtonShow: boolean,
  showSkipButtonShow: boolean,
  showButtonLoaderShow: boolean,
  createdId: string,
}

export const initialCreatePositionState: CreatePositionState = {
  jobDescriptionTempUrls: '',
  jobDescriptionS3TempUrls: '',
  selectedLevelsOfExperience: [],
  degrees: [],
  userInputFieldsOfStudy: [] as PositionJobRequirementState[],
  companyBackground: [] as PositionJobRequirementState[],
  skills: [] as PositionJobRequirementState[],
  selectedJobTitle: {
    name: '',
  },
  selectedLocations: [],
  isRemote: false,
  createdId: '',
  error: '',
  errorCode: 0,
  loading: false,
  isContinueButtonEnabled: false,
  showContinueButtonShow: false,
  showSkipButtonShow: false,
  showButtonLoaderShow: false,
};
