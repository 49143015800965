import log from 'loglevel';
import axios from 'axios';
import React, { ErrorInfo, ReactNode } from 'react';
import ErrorStackParser from 'error-stack-parser';
import StackTraceGPS from 'stacktrace-gps';
import { FatalErrorPage } from '../Common/Errors/FatalErrorPage';
import { FATAL_ERRORS_CODE } from '../../store/slices/app-state/app-state.consts';

type ErrorBoundaryProps = {
  children: ReactNode
}

type ErrorBoundaryState = {
  hasError: boolean
}
const logger = log.getLogger('StackTraceGPS');

const stackTracGps = new StackTraceGPS({
  /* We send extra ajax function to fetch source maps from url */
  ajax: (url) => {
    logger.debug('stackTracGps called "ajax" function', {
      url,
    });

    return new Promise((resolve, reject) => {
      // TODO: in the future fetch source map according to tag... (e.g: 1.2.1/1.2.3)
      axios.get(url).then((response) => {
        logger.debug('stackTracGps successfully retrieved source map response', {
          url,
        });
        resolve(response.data);
      }).catch((reason) => {
        logger.error('stackTracGps error while calling get request to source map files', reason, {
          url,
        });
        reject(reason);
      });
    });
  },
});

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private logger = log.getLogger(ErrorBoundary.name);

  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return {
      hasError: true,
    };
  }

  async componentDidCatch(error: Error, errorInfo: ErrorInfo): Promise<void> {
    // original impl: https://stackoverflow.com/questions/50223991/how-to-make-create-react-app-production-error-boundary-map-to-source-code
    const stackFrames = ErrorStackParser.parse(error);

    if (stackFrames && stackFrames.length > 0) {
      try {
        const stackFrameInfo = await stackTracGps.pinpoint(stackFrames[0]);
        this.logger.error('Received an uncaught error in React Error Boundary', error, {
          errorMessage: error.message || 'N/A',
          errorInfo,
          stackFrame: stackFrameInfo,
        });
      } catch (e) {
        this.logger.error('Error while calling "gps.pinpoint" stack frame in React Error Boundary', e);
        // if no stack frame info available make sure to log the original error Boundary
        this.logger.error('Received an uncaught error in React Error Boundary', error, {
          errorMessage: error.message || 'N/A',
          errorInfo,
        });
      }
    } else {
      // if no stack frame info available make sure to log the original error Boundary
      this.logger.error('Received an uncaught error in React Error Boundary', error, {
        errorMessage: error.message || 'N/A',
        errorInfo,
      });
    }
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // reminder: we're outside the store/language providers
      return <FatalErrorPage errorCode={ FATAL_ERRORS_CODE.UNCAUGHT_BOUNDARY_EXCEPTION } />;
    }

    return this.props.children;
  }
}
