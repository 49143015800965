import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import log from 'loglevel';
import { useHistory } from 'react-router-dom';
import { useSendMatchEngageActionMutation } from '../../../../store/rtk-query/apis/match/hooks/send-match-feedback-action.mutation-hook';
import { MatchInteractionTypeEnum } from '../../../../enums/match-interaction-type.enum';
import { ASSETS } from '../../../../assets';
import STRING_KEYS from '../../../../language/keys';
import { PAGE_NAMES } from '../../../../consts/pages';
import { useGetPositionByIdQuery } from '../../../../store/rtk-query/apis/position/hooks/get-position-by-id.query-hook';
import { AlertSnackBar } from '../../../../components/Common/CustomSnackBar/AlertSnackBar';
import MatchesCarousel from '../../components/matches-carousel/matches-carousel.component';
import MatchingProgress from '../../components/matching-progress/matching-progress.component';
import PositionStatistics from '../../components/position-statistics/position-statistics.component';
import { positionQuery } from '../../../../store/rtk-query/apis/position/position.toolkit-api';
import { PositionRunStatusEnum } from '../../../../enums/position-run-status.enum';
import { useGetPositionStatisticsQuerySelector } from '../../../../store/rtk-query/apis/position/selectors/get-position.selector';
import EngagementFeedback from '../../components/engagement-feedback/engagement-feedback.component';
import {
  ANALYTICS_EVENTS,
  COMPONENT_NAME,
  EVENT_GROUP,
  PRODUCT_AREA,
} from '../../../../consts/analytics';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { MatchResponse } from '../../../../store/rtk-query/apis/match/dto/response/match.response';
import { matchQuery } from '../../../../store/rtk-query/apis/match/match.toolkit-api';
import PositionAdjustments from '../../components/position-adjustments/position-adjustments.component';
import { PAGE_TITLES } from '../../../../consts';
import { AppRouting } from '../../../../services/appRoutingResolver';
import { usePrevious } from '../../../../hooks/states';
import PageLoader from '../../components/page-loader/page-loader.component';
import {
  PerfectButtonSize,
  PerfectButtonVariants,
} from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { ByeBiasMenu } from '../../components/byebias-menu/byebias-menu.component';
import { enableFlashingFavicon, setSnackbar } from '../../../../store/slices/app-state/app-state.toolkit-slice';
import { useUpdatePositionAnonymizeTalentDetailsMutation } from '../../../../store/rtk-query/apis/position/hooks/update-anonymize-talent-details.mutation-hook';
import { AppSnackbarVariant } from '../../../../components/Common/CustomSnackBar/app-snackbar/app-snackbar.enums';
import {
  Container,
  EditPositionButton,
  MatcherFab,
  Row,
} from './matches.styled';
import { MatchesPageContentType } from './matches.enums';

const MATCH_INTERACTION_TYPE_TO_EVENT_NAME = {
  [MatchInteractionTypeEnum.Engage]: ANALYTICS_EVENTS.MATCH_ENGAGE_BUTTON_CLICKED,
  [MatchInteractionTypeEnum.Decline]: ANALYTICS_EVENTS.MATCH_DECLINE_BUTTON_CLICKED,
  [MatchInteractionTypeEnum.Undo]: ANALYTICS_EVENTS.MATCH_ENGAGEMENT_UNDO_BUTTON_CLICKED,
};

const MIN_MATCHES_TO_SHOW = 7;

const logger = log.getLogger('MATCHES_PAGE');

const MatchesPage = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { positionId } = useParams<MatchUrlParams>();
  const previousPositionId = usePrevious(positionId);
  const [engagedMatch, setEngagedMatch] = useState<MatchResponse | null>(null);
  const [pageType, setPageType] = useState<MatchesPageContentType | undefined>();
  const previousPageType = usePrevious(pageType);
  const [undoButtonLoading, setUndoButtonLoading] = useState<boolean>(false);
  const { data: position } = useGetPositionByIdQuery(positionId);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState<string | undefined>(undefined);
  const { data: positionStatistics, isLoading: isPositionLoading } = useGetPositionStatisticsQuerySelector(positionId);
  const [getPosition] = positionQuery.useLazyGetPositionByIdQuery();
  const { analyticsPageEvent, analyticsTrackEvent } = useAnalyticsContext();
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const showEditPositionPreferencesButton = useMemo(() => {
    if (pageType === MatchesPageContentType.PositionAdjustmentsDefault) {
      return false;
    }

    return !positionStatistics.matchingProgress;
  }, [pageType, positionStatistics.matchingProgress]);

  const [
    getPendingReviewMatches,
    {
      data: pendingToReviewMatchesData,
      isFetching: isMatchFetching,
      isLoading: isMatchLoading,
    },
  ] = matchQuery.useLazyGetPendingReviewMatchesQuery();

  const [updateAnonymizeTalentDetails, {
    isError: isUpdateAnonymizeTalentDetailsError,
    isLoading: isUpdateAnonymizeTalentDetailsLoading,
  }] = useUpdatePositionAnonymizeTalentDetailsMutation();

  const pendingToReviewMatches = pendingToReviewMatchesData?.results || [];

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    let type;

    if (showLoader || !positionStatistics.positionId) {
      type = undefined;
    } else if (positionStatistics.isDailyLimit) {
      type = MatchesPageContentType.PositionStatistics;
    } else if (positionStatistics.matchingProgress && pendingToReviewMatches.length < MIN_MATCHES_TO_SHOW) {
      type = MatchesPageContentType.MatchingProgress;
    } else if (positionStatistics.lastRunFailed) {
      if (positionStatistics.noResults) {
        type =
          position?.latestRun?.deadEndRecommendations ?
            MatchesPageContentType.PositionAdjustmentsRecommendations :
            MatchesPageContentType.PositionAdjustmentsDefault;
      } else {
        type = MatchesPageContentType.PositionNoResultsError;
      }
    } else if (pendingToReviewMatches?.length) {
      type = MatchesPageContentType.MatchesCarousel;
    }

    setPageType(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pendingToReviewMatches.length,
    positionStatistics.positionId,
    positionStatistics.lastRunFailed,
    positionStatistics.isDailyLimit,
    positionStatistics.matchingProgress,
    position.hasReachedMatchTuneThreshold,
    showLoader,
  ]);

  useEffect(() => {
    setShowLoader(true);
    if (previousPositionId) {
      setPageType(undefined);
    }
    if (positionId) {
      getPendingReviewMatches({ positionId });
      getPosition(positionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionId]);

  useEffect(() => {
    if (pageType) {
      document.title = `${position.jobTitle}${PAGE_TITLES.CUSTOM_PAGE_SUFFIX}`;
      analyticsPageEvent({
        eventName: ANALYTICS_EVENTS.MATCHES_PAGE_VIEWED,
        eventGroup: EVENT_GROUP.PAGE_VIEW,
        pageName: pageType.toLowerCase(),
        productArea: PRODUCT_AREA.MATCH_PAGE,
        pagePositionId: positionId,
        pagePositionName: position.jobTitle,
        pagePositionStatus: position.status,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageType]);

  useEffect(() => {
    if ((isMatchLoading || isMatchFetching || isPositionLoading || isUpdateAnonymizeTalentDetailsLoading) && !pollingIntervalRef.current) {
      setShowLoader(true);
    }

    if (!isMatchLoading && !isMatchFetching && !isPositionLoading && !isUpdateAnonymizeTalentDetailsLoading) {
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchLoading, isMatchFetching, isPositionLoading, isUpdateAnonymizeTalentDetailsLoading, pollingIntervalRef.current]);

  useEffect(() => {
    if (isUpdateAnonymizeTalentDetailsError) {
      dispatch(setSnackbar({
        title: translate(STRING_KEYS.ALERT),
        description: translate(STRING_KEYS.GLOBAL_ERROR_MESSAGE),
        variant: AppSnackbarVariant.Error,
        anchor: 'bottom-center',
      }));
    }
  }, [dispatch, isUpdateAnonymizeTalentDetailsError, translate]);

  useEffect(() => {
    if (engagedMatch) {
      setTimeout(() => {
        setEngagedMatch(null);
      }, 15000); // TODO Check with product time to show
    }
  }, [engagedMatch]);

  const [
    engageByType,
    { isLoading: engageRequestLoading },
  ] = useSendMatchEngageActionMutation();

  useEffect(() => {
    if (positionStatistics.matchingProgress && !pollingIntervalRef.current && !engageRequestLoading) {
      pollingIntervalRef.current = setInterval(() => {
        getPendingReviewMatches({ positionId });
        getPosition(positionId);
      }, 10000);
    }

    if (!positionStatistics.matchingProgress && pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [getPendingReviewMatches, getPosition, positionId, positionStatistics.matchingProgress, engageRequestLoading]); // TODO Socket backup, will be removed after fix the socket disconnecting problems

  useEffect(() => {
    if (previousPageType === MatchesPageContentType.MatchingProgress && pageType === MatchesPageContentType.MatchesCarousel) {
      dispatch(enableFlashingFavicon());
    }
  }, [previousPageType, pageType, dispatch]);

  const sendMatchAnalyticsTrackEvent = (match: MatchResponse, eventName: string, eventGroup: string, parentComponentName?: string) => {
    const trackEventPayload = {
      eventName,
      eventGroup,
      pageName: PAGE_NAMES.MATCH_PAGE,
      pagePositionId: position.id,
      pagePositionName: position.jobTitle,
      pagePositionStatus: position.status,
      componentName: COMPONENT_NAME.ENGAGEMENT_FAB,
      componentParentName: parentComponentName,
      matchId: match.id,
      matchTalentId: match.talent?.id,
      matchStatus: match.status,
      matchEngagementStatus: match.engagement?.status,
    };
    analyticsTrackEvent(trackEventPayload);
  };

  const handleGetPosition = async () => {
    const { data: updatedPosition } = await getPosition(positionId);
    if (!updatedPosition?.hasReachedMaxDailyMatches) {
      dispatch(positionQuery.util.updateQueryData('getPositionById', positionId, (cachedPosition) => {
        cachedPosition.latestRun = {
          status: PositionRunStatusEnum.InProgress,
          triggeredAt: updatedPosition?.lastEngagedAt || new Date().toISOString(),
          noResults: false,
        };
      }));
      dispatch(positionQuery.util.updateQueryData('getOpenPositions', undefined, (cachedPositions) => {
        const cachedPosition = cachedPositions.find(({ id }) => id === positionId);
        if (cachedPosition) {
          cachedPosition.latestRun = {
            status: PositionRunStatusEnum.InProgress,
            triggeredAt: new Date().toISOString(),
            noResults: false,
          };
        }
      }));
    }
  };

  const handleMatchEngagement = useCallback(async (engagementType: MatchInteractionTypeEnum, match: MatchResponse, isLastMatch = false) => {
    if (isLastMatch) {
      setShowLoader(true);
      setEngagedMatch(null);
    } else {
      setEngagedMatch(match);
    }
    sendMatchAnalyticsTrackEvent(match, MATCH_INTERACTION_TYPE_TO_EVENT_NAME[engagementType], EVENT_GROUP.CLICK);
    try {
      await engageByType({
        positionId,
        matchId: match.id,
        engagementType,
        isLastMatch,
      });
      if (isLastMatch) {
        getPendingReviewMatches({ positionId });
        await handleGetPosition();
      }
    } catch (error) {
      logger.error('Engaged process got error, the page will be updated', error);
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingToReviewMatches, engageByType, positionId, getPosition, dispatch, getPendingReviewMatches]);

  const onUndoButtonClick = async () => {
    setUndoButtonLoading(true);
    if (engagedMatch) {
      await handleMatchEngagement(MatchInteractionTypeEnum.Undo, engagedMatch);
    }
    getPendingReviewMatches({ positionId });
    setEngagedMatch(null);
    setUndoButtonLoading(false);
  };

  const onEditPositionPreferencesButtonClick = () => {
    history.push(AppRouting.buildMatchTunePagePath(positionId));
  };

  const getMatchesPageContent = () => {
    if (showLoader) {
      return (<PageLoader />);
    }

    switch (pageType) {
      case MatchesPageContentType.PositionStatistics:
        return (<PositionStatistics />);
      case MatchesPageContentType.MatchingProgress:
      case MatchesPageContentType.PositionNoResultsError:
        return (
          <MatchingProgress />
        );
      case MatchesPageContentType.PositionAdjustmentsRecommendations:
      case MatchesPageContentType.PositionAdjustmentsDefault:
        return (
          <PositionAdjustments
            position={ position }
            adjustments={ position.latestRun?.deadEndRecommendations }
            editPositionPreferencesButtonClickHandler={ onEditPositionPreferencesButtonClick }
          />
        );
      case MatchesPageContentType.MatchesCarousel:
        return (
          <MatchesCarousel
            matches={ pendingToReviewMatches }
            matchEngagementHandler={ handleMatchEngagement }
            disableActions={ engageRequestLoading }
            anonymizeTalentDetails={ position.anonymizeTalentDetails }
          />
        );
      default:
        return (<></>);
    }
  };

  const byeBiasToggleHandler = (isChecked: boolean) => {
    updateAnonymizeTalentDetails(positionId, isChecked);
  };

  return (
    <>
      <Container>
        { getMatchesPageContent() }
        { showEditPositionPreferencesButton && (
          <MatcherFab>
            { position.id && (
              <ByeBiasMenu isByeBiasEnabled={ position.anonymizeTalentDetails } toggleHandler={ byeBiasToggleHandler } />
            )}
            <Row>
              <EditPositionButton
                size={ PerfectButtonSize.Large }
                id="edit-position-button"
                disabled={ position.hasReachedMaxDailyMatches }
                onClick={ onEditPositionPreferencesButtonClick }
                type="button"
                StartIcon={ ASSETS.MATCHTUNE }
                variant={ PerfectButtonVariants.Fill }
              >
                MatchTune
              </EditPositionButton>
              {engagedMatch && (
                <EngagementFeedback
                  matchId={ engagedMatch?.id }
                  undoButtonLoading={ undoButtonLoading }
                  onUndoClickHandler={ onUndoButtonClick }
                />
              )}
            </Row>
          </MatcherFab>
        )}
      </Container>
      <AlertSnackBar
        title={ translate(STRING_KEYS.ALERT) }
        anchor="top-center"
        description={ snackbarErrorMessage }
        isOpen={ !!snackbarErrorMessage }
        shouldAutoDismiss
        onClose={ () => setSnackbarErrorMessage(undefined) }
      />
    </>
  );
};

export default MatchesPage;
