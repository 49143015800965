import { useTranslation } from 'react-i18next';
import Dialog from '../base-dialog/base-dialog.component';
import STRING_KEYS from '../../../language/keys';
import { ANIMATED_FLUENT_UI_EMOJIS } from '../../../assets';
import {
  Description,
  DialogImage,
  HeaderContainer,
  TextContainer,
  Title,
} from './automating-campaign-dialog.styled';

const AutomatingCampaignDialog = ({ isOpen, closeHandler }: AutomatingCampaignDialog): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Dialog
      isOpen={ isOpen }
      onClose={ () => closeHandler?.() }
      widthSize="800px"
      portalUniqueId={ AutomatingCampaignDialog.name }
      disableCloseButton
    >
      <HeaderContainer>
        <DialogImage
          src={ ANIMATED_FLUENT_UI_EMOJIS.HIGH_VOLTAGE }
          alt="High Voltage"
        />
        <TextContainer>
          <Title>{ translate(STRING_KEYS.AUTOMATING_CAMPAIGN_DIALOG.TITLE) }</Title>
          <Description>{ translate(STRING_KEYS.AUTOMATING_CAMPAIGN_DIALOG.DESCRIPTION) }</Description>
        </TextContainer>
      </HeaderContainer>
    </Dialog>
  );
};

export default AutomatingCampaignDialog;
