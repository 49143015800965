import styled from 'styled-components';
import { THEME } from '../../../../themes';
import {
  Body2Font, Subtitle2Font, Title3Font,
} from '../../../../themes/fonts';
import { ASSETS } from '../../../../assets';
import { DividerSection } from '../../../../components/Common/Divider/styled';
import { hexToRgbA } from '../../../../utils/colors';
import IconButton from '../../../../components/Common/Buttons/icon-button/icon-button.component';
import { TextEllipsis } from '../../../../themes/text';

export const PositionCardContainer = styled.div<{ isPositionClosed: boolean }>`
  width: 600px;
  height: 320px;
  padding: ${THEME.SPACING.LOOSE};
  border-radius: ${THEME.RADIUS.CURVY};
  background-color: ${THEME.COLORS.SURFACE_PRIMARY};
  display: flex;
  flex-direction: column;
  cursor: ${({ isPositionClosed }) => isPositionClosed ? undefined : 'pointer'};
  
  :hover {
    outline: ${({ isPositionClosed }) => isPositionClosed ? undefined : `2px solid ${hexToRgbA(THEME.COLORS.PRIMARY, THEME.OPACITY.GHOST)}`};
  };
`;

export const HeaderContainer = styled.div<{ isPositionClosed?: boolean }>`
  display: flex;
  justify-content: space-between;
  opacity: ${({ isPositionClosed }) => isPositionClosed ? THEME.OPACITY.LOW : undefined};
`;

export const PositionTitleContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINO};
  align-items: center;
`;

export const PositionTitleWrapper = styled.div`
  ${Title3Font};
  ${TextEllipsis};
  max-width: 460px;
  font-weight: 600;
  line-height: 1.2;
  white-space: pre;
`;

export const PositionInfoBottom = styled.div`
  ${Body2Font};
  margin-top: ${THEME.SPACING.TINY};
  text-wrap: normal;
`;

export const AtsConnected = styled.div`
  display: flex;
  align-items: center;
`;

export const AtsIcon = styled(ASSETS.INFINITY_ARROW_INVERTED_ICON)`
  width: 26px;
  height: 16px;
`;

export const AtsTooltipConnected = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const LinkedAts = styled.div`
  ${Subtitle2Font};
  line-height: 1.71;
  letter-spacing: 0.1px;
`;

export const LinkedAtsJobTitle = styled.div`
  ${Body2Font};
  letter-spacing: 0.46px;
  color: ${THEME.COLORS.ACCENT};
  text-decoration: underline;
`;

export const AtsLogo = styled.img`
  height: 12px
`;

export const BodyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${THEME.SPACING.MEDIUM};
  flex-grow: 1;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  height: 64px;
`;

export const Divider = styled(DividerSection)`
  width: calc(100% + ${THEME.SPACING.LOOSE} + ${THEME.SPACING.LOOSE});
  margin-left: -${THEME.SPACING.LOOSE};
`;

export const UserPickerContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.LOOSE};
`;

export const SettingsButton = styled(IconButton)`
  height: 30px;
  width: 30px;
  color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW)};
`;

export const SettingsButtonIcon = styled(ASSETS.COG)`
  width: 24px;
  height: 22px;
`;

export const PositionClosedText = styled.div`
  ${Body2Font};
  display: flex;
  gap: ${THEME.SPACING.TINO};
`;

export const BoldText = styled.span`
  font-weight: 600;
`;
