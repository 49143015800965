import log from 'loglevel';
import { PositionResponse } from '../dto/response/position.response';
import { MatchFlowFinishedWebsocketDto } from '../../match/dto/socket/match-flow-finished.socket';
import { getMatchingStatus } from '../transformers/position-response.transformer';

const logger = log.getLogger('POSITION_TOOLKIT_API_MATCH_FLOW_FINISHED_WEBSOCKET_LISTENERS');

export const matchFlowFinishedPositionCacheWebsocketListeners = (
  socketPayload: MatchFlowFinishedWebsocketDto,
  updateCachedData: (args: (position: PositionResponse) => void) => void,
): void => {
  logger.log('Got MatchFlowFinishedSuccessfully event at getPositionById request', { socketPayload });
  updateCachedData((cachePosition: PositionResponse) => {
    const { position } = socketPayload;
    if (position.id === cachePosition.id) {
      cachePosition.latestRun = position.latestRun;
      cachePosition.statistics = position.statistics;
      cachePosition.matchingStatus = getMatchingStatus(position);
    }
  });
};

export const matchFlowFinishedPositionsCacheWebsocketListeners = (
  socketPayload: MatchFlowFinishedWebsocketDto,
  updateCachedData: (args: (positions: PositionResponse[]) => void) => void,
): void => {
  logger.log('Got MatchFlowFinishedSuccessfully event at getPositions request', { socketPayload });
  updateCachedData((positions: PositionResponse[]) => {
    const { position } = socketPayload;
    const foundPosition = positions.find(({ id }) => id === position.id);
    if (foundPosition) {
      foundPosition.latestRun = position.latestRun;
      foundPosition.statistics = position.statistics;
      foundPosition.matchingStatus = getMatchingStatus(position);
    }
  });
};
