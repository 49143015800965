import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { STRING_KEYS } from '../../../../../../language/keys';
import {
  Header, LearnWhyContainer, CloseButton, Title, Description, Backdrop,
} from './learn-why-pop-up.styled';

type LearnWhyPopUpProps = {
  onCloseLearnWhyClick: () => void,
  modalRef: RefObject<HTMLDivElement> | null,
  position: 'top' | 'bottom',
}

export const LearnWhyPopUp = ({
  onCloseLearnWhyClick,
  modalRef,
  position,
}: LearnWhyPopUpProps):JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <>
      <Backdrop onClick={ onCloseLearnWhyClick } />
      <LearnWhyContainer ref={ modalRef }
        modalPosition={ position }
      >
        <Header>
          <CloseButton onClick={ onCloseLearnWhyClick } />
        </Header>
        <Title>
          {translate(STRING_KEYS.LEARN_MORE_TITLE)}
        </Title>
        <Description>
          {translate(STRING_KEYS.LEARN_MORE_DESCRIPTIONS)}
        </Description>
      </LearnWhyContainer>
    </>
  );
};
