import { positionQuery } from '../position.toolkit-api';
import { CreatePositionFeedbackQueryArguments } from '../dto/query-arguments/create-position-feedback.query-arguments';
import { ApiError } from '../../../../../consts/api';

type UseCreatePositionFeedbackMutationType = [
  ({ positionId, feedback }: CreatePositionFeedbackQueryArguments) => void,
  AppSelectorType<void, ApiError>
];

export const useCreatePositionFeedbackMutation = (): UseCreatePositionFeedbackMutationType => {
  const [
    createPositionFeedback,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = positionQuery.useCreatePositionFeedbackMutation();

  const meta = {
    isLoading, isError, error: error as ApiError, isSuccess, data, resetState: reset,
  };

  return [
    createPositionFeedback,
    { ...meta },
  ];
};
