import styled from 'styled-components';
import { ASSETS } from '../../../../../../../../../assets';
import { THEME } from '../../../../../../../../../themes';
import Select from '../../../../../../../../../components/Common/select/select.component';

export const StyledSelect = styled(Select)`
  width: 184px;
  height: 56px;
`;

export const MilesRadiusSelectLabelWrapper = styled.div`
  display: flex;
  gap: ${THEME.SPACING.MICRO};
`;

export const MilesRadiusSelectLabelIcon = styled(ASSETS.RADIUS)`
  width: 12px;
`;
