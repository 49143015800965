import { useTranslation } from 'react-i18next';
import { PieChart as Pie } from 'react-minimal-pie-chart';
import { useInViewport } from 'react-in-viewport';
import { useRef } from 'react';
import { WIDGET_COLORS } from '../../consts';
import STRING_KEYS from '../../../../../../language/keys';
import { THEME } from '../../../../../../themes';
import {
  ChartContainer,
  ChartStyle,
  Experience,
  Years,
  Wrapper,
} from './experience-pie-chart.styled';

const MAX_SEGMENTS_TO_SHOW = 5;
const CHART_PADDING_ANGLE = 3;

export const ExperiencePieChart = ({
  data,
  totalYears,
  isSmall = false,
}: ExperiencePieChartProps):JSX.Element => {
  const containerRef = useRef(null);
  const {
    inViewport,
    enterCount,
  } = useInViewport(
    containerRef,
    {},
    {},
  );
  const { t: translate } = useTranslation();
  const chartData: PieChartData[] = data
    .sort((a, b) => b.durationInMonths - a.durationInMonths)
    .slice(0, MAX_SEGMENTS_TO_SHOW)
    .map((item, index: number) => {
      return {
        ...item,
        color: WIDGET_COLORS[index % WIDGET_COLORS.length],
      };
    });

  const renderYearsLabel = () => {
    if (totalYears === undefined) {
      return null;
    }

    return (
      <Wrapper>
        <Experience
          end={ totalYears }
          duration={ inViewport && enterCount === 1 ? 1 :  0 }
          decimal="."
          decimals={ 1 }
          useEasing
          redraw
        />
        <Years>
          {translate(STRING_KEYS.YEARS)}
        </Years>
      </Wrapper>
    );
  };

  return (
    <ChartContainer ref={ containerRef }>
      <Pie
        data={ chartData.map((chart) => ({
          value: chart.durationInMonths,
          title: chart.title,
          color: chart.color,
        })) }
        paddingAngle={ CHART_PADDING_ANGLE }
        segmentsStyle={ (segmentIndex) => segmentIndex === 0 ?
          { strokeWidth: 10, color: THEME.COLORS.ACCENT } :
          { strokeWidth: 8, color: THEME.COLORS.ACCENT } }
        animate={ inViewport && enterCount === 1 }
        animationEasing="ease-in"
        animationDuration={ 1000 }
        startAngle={ 180 }
        style={ ChartStyle(isSmall) }
        reveal={ inViewport ? 100 : 0 }
      />
      { renderYearsLabel() }
    </ChartContainer>
  );
};

ExperiencePieChart.defaultProps = {
  totalYears: undefined,
  isSmall: false,
};
