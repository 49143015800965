import styled from 'styled-components';
import BaseInput from '../Common/perfect-base-input/perfect-base-input.component';
import { THEME } from '../../themes';
import { ASSETS } from '../../assets';
import { hexToRgbA } from '../../utils/colors';

export const InputContainer = styled(BaseInput)`
  width: 96px;
  border-radius: ${THEME.RADIUS.ROUNDISH};
  input[type="time"] {
    -webkit-appearance: none;

  }
  input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

export const TimerIcon = styled(ASSETS.OUTLINED_TIMER)`
  width: 16px;
  height: 16px;
  fill: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.LOW)};
`;
