import { Trans } from 'react-i18next';
import STRING_KEYS from '../../../../../../language/keys';
import {
  Container, Icon, Text,
} from './track-email-opens-banner.styled';

const TrackEmailOpensBanner = (): JSX.Element => {
  return (
    <>
      <Container>
        <Icon />
        <Text>
          <Trans
            i18nKey={ STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.TRACK_EMAIL_OPENS.BANNER_TEXT }
          />
        </Text>
      </Container>
    </>
  );
};

export default TrackEmailOpensBanner;
