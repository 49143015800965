import styled from 'styled-components';
import { THEME } from '../../../themes';
import { Subtitle2Font, Title3Font } from '../../../themes/fonts';

export const NotificationDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE}
`;

export const EmojiContainer = styled.div`
  width: 64px;
  height: 64px;
  font-size: 49px;
`;

export const TitleContainer = styled.div`
  ${Title3Font};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const DescriptionContainer = styled.div`
  ${Subtitle2Font};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;
