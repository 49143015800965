import { PositionResponse } from '../dto/response/position.response';
import { positionQuery } from '../position.toolkit-api';
import { ApiError } from '../../../../../consts/api';

export const useGetPositionByIdQuery = (positionId: string, skip = false): AppSelectorType<PositionResponse, ApiError> => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch,
  } = positionQuery.useGetPositionByIdQuery(
    positionId,
    { skip: skip || !positionId },
  );

  return {
    data: data || {} as PositionResponse,
    error: error as ApiError,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
