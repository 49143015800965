import styled from 'styled-components';

export const StyledIconsSection = styled.div<{
  justifyContent?:string,
}>`
  display:flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
`;
