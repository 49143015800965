import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { Body2Font } from '../../../../../themes/fonts';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.MEDIUM};
  height: 56px;
  color: ${THEME.COLORS.TRUE_BLACK};
`;

export const LeftContainer = styled.div`
  ${Body2Font};
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
`;

export const BoldText = styled.div`
  font-weight: bold;
`;
