import React from 'react';
import { PositionSettingsItemProps } from './position-settings-item';
import { FilterMenuItem, IconContainer } from './position-settings-item.styled';

const PositionSettingsItem = ({
  onClick,
  icon,
  text,
}: PositionSettingsItemProps): JSX.Element => {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <FilterMenuItem onClick={ onClick }>
      <IconContainer>
        { icon }
      </IconContainer>
      <>{ text }</>
    </FilterMenuItem>
  );
};
export default PositionSettingsItem;
