import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { OutreachState } from '../outreach-state.initial-state';

export const conversationsSortedReducers: CaseReducer<OutreachState, PayloadAction<void>> = (state) => {
  state.shouldSortConversations = false;
};

export const resetConversationsSortReducers: CaseReducer<OutreachState, PayloadAction<void>> = (state) => {
  state.shouldSortConversations = true;
};
