import { TalentNetworkDialogData } from './reducers/talents-network-modal-open.reducers';

export type MatchState = {
  talentsNetworkDialogData: TalentNetworkDialogData,
}

export const initialMatchState: MatchState = {
  talentsNetworkDialogData: {
    isOpen: false,
    selectedExperienceIds: new Set(),
    selectedEducationIds: new Set(),
  },
};
