import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/base-query';
import { positionQuery } from '../position/position.toolkit-api';
import { MATCH_STATUSES } from '../../../../consts';
import { MatchInteractionTypeEnum } from '../../../../enums/match-interaction-type.enum';
import socketManager from '../../../../services/socket-io-manager';
import { SocketNamespace } from '../../../../enums/socket-namespace.enum';
import { socketTalentAcquisitionConnected, socketTalentAcquisitionDisconnected } from '../../../slices/app-state/app-state.toolkit-slice';
import { SocketEventName } from '../../../../enums/socket-event-name.enum';
import { REDUCER_PATH } from './match.consts';
import {
  getMatchByIdQuery, getPendingReviewMatchByIdQuery, performMatchEngagement, setMatchAsViewed,
} from './match.toolkit-queries';
import { GetMatchByIdQueryArguments } from './dto/query-arguments/get-match-by-id.query-arguments';
import { PerformMatchEngagementQueryArguments } from './dto/query-arguments/perform-match-engagement.query-arguments';
import { SetMatchAsViewedQueryArguments } from './dto/query-arguments/set-match-as-viewed.query-arguments';
import { MatchResponse } from './dto/response/match.response';
import { getMatchByIdResponseTransformer } from './transformers/match-response.transformer';
import { GetPendingReviewMatchesQueryArguments } from './dto/query-arguments/get-pending-review-matches.query-arguments';
import { newMatchForPendingMatchesWebsocketListeners } from './websocket-listeners/match-published.websocket-listeners';
import { MatchPublishedWebsocketDto } from './dto/socket/match-published.socket';
import { MatchEngagementResponse } from './dto/response/match-engagement.response';
import { contactDetailsLookupCompletedMatchWebsocketHandler } from './websocket-listeners/contact-details-lookup-completed.websocket-listeners';
import { ContactDetailsLookupCompletedSocketDto } from './dto/socket/contact-details-lookup-completed.socket';

const ENGAGEMENT_ACTION_TO_ENGAGEMENT_STATUS: Record<string, string> = {
  [MatchInteractionTypeEnum.Engage]: MATCH_STATUSES.ENGAGED,
  [MatchInteractionTypeEnum.Decline]: MATCH_STATUSES.DECLINED,
  [MatchInteractionTypeEnum.Undo]: MATCH_STATUSES.TO_REVIEW,
};

export const matchQuery = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getPendingReviewMatches: builder.query<PaginationResponse<MatchResponse[]>, GetPendingReviewMatchesQueryArguments>({
      query: getPendingReviewMatchByIdQuery,
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, dispatch },
      ) {
        const socket = socketManager.getSocket({
          namespace: SocketNamespace.TalentAcquisition,
          onSocketConnected: () => {
            dispatch(socketTalentAcquisitionConnected());
          },
          onSocketDisconnected: () => {
            dispatch(socketTalentAcquisitionDisconnected());
          },
        });
        if (socket) {
          await cacheDataLoaded;
          socket.on(
            SocketEventName.MatchPublished,
            (websocketDto: MatchPublishedWebsocketDto) => newMatchForPendingMatchesWebsocketListeners(websocketDto, arg.positionId, updateCachedData),
          );
        }
      },
    }),
    getMatchById: builder.query<MatchResponse, GetMatchByIdQueryArguments>({
      query: getMatchByIdQuery,
      transformResponse: getMatchByIdResponseTransformer,
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, dispatch },
      ) {
        const socket = socketManager.getSocket({
          namespace: SocketNamespace.TalentAcquisition,
          onSocketConnected: () => {
            dispatch(socketTalentAcquisitionConnected());
          },
          onSocketDisconnected: () => {
            dispatch(socketTalentAcquisitionDisconnected());
          },
        });
        if (socket) {
          await cacheDataLoaded;
          socket.on(
            SocketEventName.ContactDetailsLookupCompleted,
            (socketPayload: ContactDetailsLookupCompletedSocketDto) => contactDetailsLookupCompletedMatchWebsocketHandler(socketPayload, updateCachedData),
          );
        }
      },
    }),
    performMatchEngagement: builder.mutation<MatchEngagementResponse, PerformMatchEngagementQueryArguments>({
      query: performMatchEngagement,
      async onQueryStarted(
        {
          positionId, matchId, engagementType, userData,
        },
        { dispatch, queryFulfilled },
      ) {
        const pendingReviewMatchesPatchResult = dispatch(
          matchQuery.util.updateQueryData('getPendingReviewMatches', { positionId }, (draft) => {
            const foundMatch = draft.results.find((match) => match.id === matchId);
            if (foundMatch) {
              foundMatch.engagement = {
                history: foundMatch.engagement?.history || [],
                status: ENGAGEMENT_ACTION_TO_ENGAGEMENT_STATUS[engagementType],
              };
            }
          }),
        );
        const patchResult = dispatch(
          positionQuery.util.updateQueryData('getPositionById', positionId, (draft) => {
            const foundMatch = draft.matches.find((match) => match.id === matchId);
            if (foundMatch) {
              foundMatch.engagementStatus = ENGAGEMENT_ACTION_TO_ENGAGEMENT_STATUS[engagementType];
            }

            // TODO: refetch statistics
            const statisticsArray = draft.statistics.engagementStatusStatistics.slice();
            const toReviewStatistics = statisticsArray.find(({ name }) => name === MATCH_STATUSES.TO_REVIEW);
            if (toReviewStatistics) {
              switch (engagementType) {
                case MatchInteractionTypeEnum.Engage:
                  draft.statistics.newConversations++;
                  toReviewStatistics.amount--;
                  break;
                case MatchInteractionTypeEnum.Decline:
                  toReviewStatistics.amount--;
                  break;
                default:
                  toReviewStatistics.amount++;
                  break;
              }
              draft.statistics.engagementStatusStatistics = statisticsArray;
            }
          }),
        );
        const positionsPatchResult = dispatch(
          positionQuery.util.updateQueryData('getOpenPositions', undefined, (draft) => {
            const foundPosition = draft.find((position) => position.id === positionId);
            if (foundPosition) {
              // TODO: refetch statistics
              const statisticsArray = foundPosition.statistics.engagementStatusStatistics.slice();
              const toReviewStatistics = statisticsArray.find(({ name }) => name === MATCH_STATUSES.TO_REVIEW);
              if (toReviewStatistics) {
                switch (engagementType) {
                  case MatchInteractionTypeEnum.Engage:
                    foundPosition.statistics.newConversations++;
                    toReviewStatistics.amount--;
                    break;
                  case MatchInteractionTypeEnum.Decline:
                    toReviewStatistics.amount--;
                    break;
                  default:
                    toReviewStatistics.amount++;
                    break;
                }
                foundPosition.statistics.engagementStatusStatistics = statisticsArray;
              }
            }
          }),
        );
        const matchPatchResult = dispatch(
          matchQuery.util.updateQueryData('getMatchById', { positionId, matchId }, (draft) => {
            if (draft.engagement) {
              draft.engagement.status = ENGAGEMENT_ACTION_TO_ENGAGEMENT_STATUS[engagementType];
              // TODO [refactor]: find way to get user from store
              if (userData) {
                draft.engagement.history.push({
                  type: engagementType,
                  statusAfterActionPerformed: ENGAGEMENT_ACTION_TO_ENGAGEMENT_STATUS[engagementType],
                  timestamp: new Date().toISOString(),
                  user: userData,
                });
              }
            }
          }),
        );
        try {
          const { data: engagementResponse } = await queryFulfilled;
          dispatch(
            positionQuery.util.updateQueryData('getPositionById', positionId, (draft) => {
              draft.hasReachedMatchTuneThreshold = engagementResponse.hasReachedMatchTuneThreshold;
            }),
          );
          dispatch(
            positionQuery.util.updateQueryData('getOpenPositions', undefined, (draft) => {
              const foundPosition = draft.find((position) => position.id === positionId);
              if (foundPosition) {
                foundPosition.hasReachedMatchTuneThreshold = engagementResponse.hasReachedMatchTuneThreshold;
              }
            }),
          );
        } catch {
          patchResult?.undo();
          positionsPatchResult?.undo();
          matchPatchResult?.undo();
          pendingReviewMatchesPatchResult?.undo();
        }
      },
    }),
    setMatchAsViewed: builder.mutation<void, SetMatchAsViewedQueryArguments>({
      query: setMatchAsViewed,
      async onQueryStarted(
        { positionId, matchId, userId }, { dispatch, queryFulfilled },
      ) {
        // TODO [refactor]: find a better name instead of passing userId
        const matchPatchResult = dispatch(
          matchQuery.util.updateQueryData('getMatchById', { positionId, matchId }, (match) => {
            if (match) {
              match.viewedBy.push({ userId, timestamp: new Date().toISOString() });
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          matchPatchResult.undo();
        }
      },
    }),
  }),
});
