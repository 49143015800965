import { format } from 'date-fns';
import {
  Fragment, useCallback, useEffect, useRef, useState,
} from 'react';
import { MessageResponseDto } from '../../../../../store/rtk-query/apis/outreach/dto/response/message.response';
import { useMarkConversationMessageAsSeenMutation } from '../../../../../store/rtk-query/apis/outreach/hooks/mark-conversation-message-as-seen.mutation-hook';
import { ScheduledMessageTypeEnum } from '../../../../../store/rtk-query/apis/outreach/enums/scheduled-message-type.enum';
import { ScheduledMessageStatusEnum } from '../../../../../store/rtk-query/apis/outreach/enums/scheduled-message-status.enum';
import { MessageDirectionEnum } from '../../../../../store/rtk-query/apis/outreach/enums/message-direction.enum';
import { MessageTypeEnum } from '../../../../../store/rtk-query/apis/outreach/enums/message-type.enum';
import { MessageTriggerTypeEnum } from '../../../../../store/rtk-query/apis/outreach/enums/message-trigger-type.enum';
import { ConversationProps } from './conversation';
import ConversationMessageItem from './conversation-massage-item/conversation-massage-item.component';
import {
  Container, MessageGroup, MessageGroupDate, MessagesContainer,
} from './conversation.styled';

const MESSAGE_GROUP_DATE_FORMAT = 'MMM d, yyyy';

const Conversation = ({
  conversation, isLocked,
}: ConversationProps): JSX.Element => {
  const [markConversationMessageAsSeen] = useMarkConversationMessageAsSeenMutation();
  const [messageMapByDate, setMessageMapByDate] = useState<Map<string, MessageResponseDto[]>>(new Map());
  const endOfConversationRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const hasUnseenMessage = conversation.messageHistory?.some((message) => message.messageDirection === MessageDirectionEnum.Inbound && !message.seen);
    if (hasUnseenMessage && !isLocked) {
      markConversationMessageAsSeen({ conversationId: conversation.id, positionId: conversation.positionId });
    }

    const messages = conversation?.messageHistory?.slice() || [];

    if (
      conversation.nextScheduledMessage?.type === ScheduledMessageTypeEnum.Scheduled &&
      conversation.nextScheduledMessage?.status === ScheduledMessageStatusEnum.Failed
    ) {
      messages.push({
        id: conversation.nextScheduledMessage.id,
        recipient: conversation.nextScheduledMessage.recipient,
        from: conversation.nextScheduledMessage?.senderUser.email || '',
        senderUserId: conversation.nextScheduledMessage?.senderUser?.id,
        type: MessageTypeEnum.Email,
        messageDirection: MessageDirectionEnum.Outbound,
        seen: false,
        payload: conversation.nextScheduledMessage.payload,
        opened: false,
        actionTime: conversation?.nextScheduledMessage?.sendAt || new Date().toISOString(),
        userInfo: conversation.nextScheduledMessage?.senderUser,
        isError: true,
        triggerType: MessageTriggerTypeEnum.Automated,
        sequenceStep: conversation.nextScheduledMessage.sequenceStep,
      });
    }

    const messageMap = new Map<string, MessageResponseDto[]>();
    messages?.forEach((message) => {
      const dateKey = format(new Date(message.actionTime), MESSAGE_GROUP_DATE_FORMAT);
      const dayMessages: MessageResponseDto[] = messageMap.get(dateKey) || [];
      dayMessages.push(message);
      messageMap.set(dateKey, dayMessages);
    });
    setMessageMapByDate(messageMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation?.id, conversation.messageHistory, conversation.nextScheduledMessage]);

  useEffect(() => {
    if (endOfConversationRef.current) {
      endOfConversationRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messageMapByDate]);

  const renderMessages = useCallback(() => {
    return Array.from(messageMapByDate, ([date, messages]) => (
      <MessageGroup key={ date }>
        <MessageGroupDate>{ date }</MessageGroupDate>
        { messages.map((message) => (
          <Fragment key={ message.id }>
            <ConversationMessageItem conversationId={ conversation.id } message={ message } />
          </Fragment>
        ))}
      </MessageGroup>
    ));
  }, [conversation.id, messageMapByDate]);

  if (!messageMapByDate.size) {
    return <></>;
  }

  return (
    <Container>
      <MessagesContainer>
        { renderMessages() }
      </MessagesContainer>
      <div ref={ endOfConversationRef } />
    </Container>
  );
};

export default Conversation;
