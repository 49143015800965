import styled, { css } from 'styled-components';
import { THEME } from '../../../../../../../themes';
import Tag from '../../../../../../../components/Common/tag/tag.component';
import { hexToRgbA } from '../../../../../../../utils/colors';
import { FLUENT_UI_EMOJIS } from '../../../../../../../assets';
import { COMPANY_SIZE_ICON_MAP } from '../../../../../consts/company-size-icon-map';
import { CompanySizeTierEnum } from '../../../../../../../enums/company-size-tier.enum';
import { TextWrapper } from '../../../../../../../components/Common/tag/tag.styled';
import { TextEllipsis } from '../../../../../../../themes/text';

const exactStyle = css`
  background-color: ${hexToRgbA(THEME.COLORS.SUCCESS, THEME.OPACITY.GHOST)};

  :hover {
    background-color: ${hexToRgbA(THEME.COLORS.SUCCESS, THEME.OPACITY.FOCUS)};
  }
  
  &.active {
    background-color: ${hexToRgbA(THEME.COLORS.SUCCESS, THEME.OPACITY.DIVIDER)};
    outline: 2px solid ${THEME.COLORS.SUCCESS};
  }
`;

const similarStyle = css`
  background-color: ${hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.GHOST)};

  :hover {
    background-color: ${hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.FOCUS)};
  }

  &.active {
    background-color: ${hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.DIVIDER)};
    outline: 2px solid ${THEME.COLORS.SECONDARY};
  }
`;

const transparentStyle = css<{ disableHover?: boolean }>`
  background-color: transparent;

  :hover {
    background-color: ${({ disableHover }) => disableHover ? undefined : hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.GHOSTLY)};
  }
`;

const regularStyle = css<{ disableHover?: boolean }>`
  background-color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.GHOST)};

  :hover {
    background-color: ${({ disableHover }) => disableHover ? undefined : hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.FOCUS)};
  }

  &.active {
    background-color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.DIVIDER)};
    outline: 2px solid ${THEME.COLORS.CONTENT_A};
  }
`;

export const Container = styled.div`
  margin-top: ${THEME.SPACING.TINO};
  display: flex;
  flex-wrap: wrap;
  gap: ${THEME.SPACING.MICRO};
`;

export const TagStyle = css`
  transition: background-color 200ms;

  ${TextWrapper} {
    ${TextEllipsis};
    max-width: 32ch;
  }
`;

export const ExactAttributeTag = styled(Tag)`
  ${TagStyle};
  ${exactStyle};
`;

export const SimilarAttributeTag = styled(Tag)`
  ${TagStyle};
  ${similarStyle};
`;

export const AdditionalTag = styled(Tag)<{ disableHover?: boolean }>`
  ${TagStyle};
  ${transparentStyle};
  
  & svg {
    filter: grayscale(100%);
  }
`;

export const CompanySizeTag = styled(Tag)<{askedByUser?: boolean}>`
  ${TagStyle};
  ${({ askedByUser }) => {
    if (askedByUser) {
      return exactStyle;
    }
    
    return regularStyle;
  }};
`;

export const MoreTags = styled(Tag)`
  background-color: transparent;
  border: 1px solid ${hexToRgbA(THEME.COLORS.PRIMARY, THEME.OPACITY.DIVIDER)};
  color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW)};
  padding: 0 ${THEME.SPACING.TINY};
`;

export const SkillTagAskedByUserIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SkillTagAskedByUserIcon = styled.img`
  width: 8px;
  height: fit-content;
`;

export const SkillTagIcon = styled(FLUENT_UI_EMOJIS.HIGH_VOLTAGE)``;
export const IndustryTagIcon = styled(FLUENT_UI_EMOJIS.OFFICE_BUILDING)``;
export const SmallCompanyIcon = styled(COMPANY_SIZE_ICON_MAP[CompanySizeTierEnum.Small])``;
export const MediumCompanyIcon = styled(COMPANY_SIZE_ICON_MAP[CompanySizeTierEnum.Medium])``;
export const LargeCompanyIcon = styled(COMPANY_SIZE_ICON_MAP[CompanySizeTierEnum.Large])``;
export const VeryLargeCompanyIcon = styled(COMPANY_SIZE_ICON_MAP[CompanySizeTierEnum.VeryLarge])``;
