import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { THEME } from '../../../../themes';
import { TalentAtsIndicationProps } from './talent-ats-indication';
import { Container, AtsIndicationIcon } from './talent-ats-indication.styled';

const TalentAtsIndication = ({ previousFamiliarity, ...otherProps }: TalentAtsIndicationProps): JSX.Element => {
  const [translationKey, backgroundColor] = useMemo(() => {
    let _translationKey;
    let _backgroundColor;

    if (previousFamiliarity.candidateInAts) {
      _translationKey = STRING_KEYS.MATCH_CARD.ATS_INDICATION.CANDIDATE_IN_ATS;
      _backgroundColor = THEME.COLORS.DECOR;
    } else if (previousFamiliarity.previouslyInteractedInAts) {
      _translationKey = STRING_KEYS.MATCH_CARD.ATS_INDICATION.PREVIOUSLY_INTERACTED;
    } else if (previousFamiliarity.engagedInDifferentPosition) {
      _translationKey = STRING_KEYS.MATCH_CARD.ATS_INDICATION.ENGAGED_ELSEWHERE;
    }

    return [_translationKey, _backgroundColor];
  }, [previousFamiliarity]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Container backgroundColor={ backgroundColor } { ...otherProps }>
      <AtsIndicationIcon />
      <div>
        <Trans i18nKey={ translationKey } />
      </div>
    </Container>
  );
};

export default TalentAtsIndication;
