export const FUNNEL_GRAPH_START_POSITION_MAX = 0.42;
export const FUNNEL_GRAPH_START_POSITION_MIN = 0.50;
export const FUNNEL_GRAPH_OPTIMAL_REVIEWED_PEOPLE = 110;
export const FUNNEL_GRAPH_OPTIMAL_LIKED_PEOPLE = 0.45;
export const FUNNEL_GRAPH_OPTIMAL_REPLIED_PEOPLE = 0.15;

export const FUNNEL_TAG_MIN_TALENTS_COMPANIES_CARDINALITY = 10;
export const FUNNEL_TAG_TALENTS_COMPANIES_CARDINALITY_RATE = 5;
export const FUNNEL_TAG_MIN_OPTIMAL_OR_EXCELLENT = 50;
export const FUNNEL_TAG_MIN_KEEP_IT_UP = 30;
export const FUNNEL_TAG_MAX_KEEP_IT_UP = 49;
export const FUNNEL_TAG_MIN_OPTIMAL_MATCHES_COUNT = 50;
export const FUNNEL_TAG_MIN_GREAT_START = 10;
export const FUNNEL_TAG_MAX_GREAT_START = 20;
export const FUNNEL_TAG_WELL_DONE = 100;
export const FUNNEL_TAG_HIGH_OPEN_RATE = 70;
export const FUNNEL_TAG_REPLAY_RATE_LOW = 10;
export const FUNNEL_TAG_MIN_REPLAY_RATE_MED = 10;
export const FUNNEL_TAG_MAX_REPLAY_RATE_MED = 18;
