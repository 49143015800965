import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { CaptionFont, OverlineFont, Title1Font } from '../../../../../../themes/fonts';

export const Container = styled.div`
  width: 208px;
  min-height: 180px;
  border-radius: 24px;
  border: solid 1px ${THEME.COLORS_RGB.BLACK_DIVIDER};
  background-color: ${THEME.COLORS.SURFACE_PRIMARY};
`;

export const BodyContainer = styled.div`
  padding: ${THEME.SPACING.MEDIUM} ${THEME.SPACING.TINO} ${THEME.SPACING.TINO} ${THEME.SPACING.MEDIUM};
  min-height: 160px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InsightsCardTitle = styled.div<{color?: string}>`
  color:${({ color }) => color || THEME.COLORS.ACCENT};
  ${OverlineFont};
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: unset;
  display: flex;
  align-items: center;
  margin-left: 5px;
  text-transform: uppercase;
`;

export const InsightsCardDescription = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const InsightsValueContainer = styled.div`
  ${Title1Font};
  line-height: 1.64;
  color: ${THEME.COLORS.PRIMARY_DARK};
  span {
    font-weight: 600;
  }
`;

export const ActionContainer = styled.div`
  ${CaptionFont};
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.MEDIUM} ;
  font-weight: 600;
  color: ${THEME.COLORS.PRIMARY_DARK};
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ActionIconContainer = styled.div`
  margin-left: ${THEME.SPACING.TINY};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
