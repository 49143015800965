import { outreachQuery } from '../outreach.toolkit-api';
import { ConversationByIdQueryArguments } from '../dto/query-arguments/conversation-by-id.query-arguments';

type UseCreateGoogleEmailSenderMutationType = [
  ({ conversationId, positionId }: ConversationByIdQueryArguments) => void,
  AppSelectorType<void>
];

export const useMarkConversationMessageAsSeenMutation = (): UseCreateGoogleEmailSenderMutationType => {
  const [
    markConversationMessageAsSeen,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = outreachQuery.useMarkConversationMessageAsSeenMutation();

  const meta = {
    isLoading, isError, error, isSuccess, data, resetState: reset,
  };

  return [
    markConversationMessageAsSeen,
    { ...meta },
  ];
};
