import { BADGE_TYPE, WIDGET_TYPE } from '../../../../../pages/match-page/match.page.const';
import { IntersectionWrapper } from '../../../../../components/Common/intersection-wrapper/intersection-wrapper.component';
import { Language } from '../../../../../@types/smartBadges';
import { WidgetsContainerProps } from './widgets-container';
import ExperienceByIndustryWidget from './experience-by-industry-widget/experience-by-industry-widget.component';
import LikelihoodToMoveWidget from './likelihood-to-move-widget/likelihood-to-move-widget.component';
import TalentNetworkWidget from './talent-network-widget/talent-network-widget.component';
import { AcademicExcellenceBadge } from './badge-widget/academic-excellence-badge/academic-excellence-badge.component';
import { Container } from './widgets-container.styled';
import { MultilingualBadge } from './badge-widget/multilingual-badge/multilingual-badge.component';
import ExplainabilityWidget from './explainability-widget/explainability-widget.component';

const WidgetsContainer = ({ match, trackMatchWidgetViewed }: WidgetsContainerProps): JSX.Element => {
  const renderWidget = (widgetComponent: JSX.Element, widgetType: string): JSX.Element | null => {
    return (
      <IntersectionWrapper intersectionHandler={ () => trackMatchWidgetViewed?.(widgetType) }>
        { widgetComponent }
      </IntersectionWrapper>
    );
  };

  const renderLikelihoodToMoveWidget = () => {
    const likelihoodToMoveWidget = match.smartWidgets.find((widget) => widget.type === WIDGET_TYPE.LIKELIHOOD_TO_MOVE_METER);

    if (!likelihoodToMoveWidget) {
      return undefined;
    }

    const widgetData = likelihoodToMoveWidget?.data as MoveMeterData;

    const layoffWidget =
      likelihoodToMoveWidget?.childWidgets?.find((widget) => widget.type === WIDGET_TYPE.WTM_COMPANY_LAYOFF_TALENT || widget.type === WIDGET_TYPE.WTM_COMPANY_LAYOFFS);
    const personalTenureWidget = match.smartWidgets.find((widget) => widget.type === WIDGET_TYPE.PERSONAL_TENURE_COMPARISON);
    let childWidget;

    if (layoffWidget) {
      childWidget = layoffWidget;
    } else if (personalTenureWidget) {
      childWidget = {
        type: personalTenureWidget.type,
        order: personalTenureWidget.order,
      };
    }

    return renderWidget(
      (
        <LikelihoodToMoveWidget
          data={ widgetData }
          talentName={ match.talent.firstName }
          currentJobExperienceInMonths={ match.talentInfo.currentJobExperienceInMonths }
          childWidgets={ childWidget ? [childWidget] : undefined }
        />
      ),
      likelihoodToMoveWidget.type,
    );
  };

  const renderTalentNetworkWidget = () => {
    if (!match.networkedTalents?.length) {
      return null;
    }

    return renderWidget(
      (<TalentNetworkWidget networkedTalent={ match?.networkedTalents?.[0] } />),
      WIDGET_TYPE.TALENT_NETWORK,
    );
  };

  const renderAcademicExcellenceBadge = () => {
    const academicExcellenceBadge = match.smartBadges.find((badge) => badge.type === BADGE_TYPE.ACADEMIC_EXCELLENCE);
    if (!academicExcellenceBadge) {
      return null;
    }

    return renderWidget(
      (<AcademicExcellenceBadge />),
      academicExcellenceBadge.type,
    );
  };

  const renderMultilingualBadge = () => {
    const multilingualBadge = match.smartBadges.find((badge) => badge.type === BADGE_TYPE.LANGUAGES);
    const languagesList = multilingualBadge?.data as Language[];
    if (!multilingualBadge || !languagesList?.length) {
      return null;
    }

    return renderWidget(
      (<MultilingualBadge languages={ languagesList } />),
      multilingualBadge.type,
    );
  };

  const renderExperienceByIndustryWidget = () => {
    const experienceByIndustryWidget = match.smartWidgets.find((widget) => widget.type === WIDGET_TYPE.EXPERIENCE_BY_INDUSTRY);

    if (!experienceByIndustryWidget) {
      return null;
    }

    return renderWidget(
      (<ExperienceByIndustryWidget experienceByIndustries={ experienceByIndustryWidget.data as ExperienceByIndustryData[] } />),
      experienceByIndustryWidget.type,
    );
  };

  return (
    <Container>
      <ExplainabilityWidget match={ match } />
      { renderLikelihoodToMoveWidget() }
      { renderTalentNetworkWidget() }
      { renderAcademicExcellenceBadge() }
      { renderMultilingualBadge() }
      { renderExperienceByIndustryWidget() }
    </Container>
  );
};

export default WidgetsContainer;
