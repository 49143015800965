import { useTranslation } from 'react-i18next';
import BadgeWidget from '../badge-widget.component';
import STRING_KEYS from '../../../../../../../language/keys';
import { Icon } from './academic-excellence-badge.styled';

export const AcademicExcellenceBadge = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BadgeWidget
      title={ translate(STRING_KEYS.MATCH_WIDGETS.ACADEMIC_EXCELLENCE.TITLE) }
      description={ translate(STRING_KEYS.MATCH_WIDGETS.ACADEMIC_EXCELLENCE.DESCRIPTION) }
      Icon={ <Icon /> }
    />
  );
};
