import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { Body2Font, OverlineFont, Subtitle1Font } from '../../../../../themes/fonts';
import { ASSETS } from '../../../../../assets';
import NotificationDialog from '../../../../../components/dialogs/notification-dialog/notification-dialog.component';
import { NOTIFICATION_DIALOG_SIDE_PADDING } from '../../../../../components/dialogs/notification-dialog/notification-dialog.styled';

export const StyledNotificationDialog = styled(NotificationDialog)`
  width: 400px;
`;

export const NotificationDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
  position: relative;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const InfoLabel = styled.div`
  ${OverlineFont};
  text-transform: uppercase;
  opacity: ${THEME.OPACITY.MED};
`;

export const TitleContainer = styled.div`
  ${Subtitle1Font};
  font-weight: 600;
`;

export const DescriptionContainer = styled.div`
  ${Body2Font};
  opacity: ${THEME.OPACITY.MED};
`;

export const BackgroundBanner = styled(ASSETS.REQUIRED_NOTIFICATION_TIP_BACKGROUND_BANNER)`
  width: 112px;
  height: 88px;
  position: absolute;
  right: -${NOTIFICATION_DIALOG_SIDE_PADDING};
  top: 50%;
  transform: translate(0, -25%);
`;
