import { Children } from 'react';
import {
  CustomMessageWrapper,
  CustomMessageItem,
} from './custom-message.styled';

type CustomMessageProps = {
    children: JSX.Element | JSX.Element[] | undefined,
}

export const CustomMessage = ({
  children,
}:CustomMessageProps): JSX.Element | null => {
  const childrenLength = Children.count(children);

  if (children && childrenLength > 0) {
    return (
      <CustomMessageWrapper>
        {
          Children
            .map(children,
              (optionItem:JSX.Element) => (
                <CustomMessageItem key={ optionItem.key }>
                  {optionItem}
                </CustomMessageItem>
              ))
        }
      </CustomMessageWrapper>
    );
  }

  return null;
};
