import CompanyProperties from '../CompanyProperties';
import { CompanyModalSkeleton } from '../CompanyModalSkeleton';
import { CompanyInsightData } from '../../../../hooks/get-company-by-id.hook';
import { formatExactLocation } from '../../../../utils/location';
import Tag from '../../tag/tag.component';
import {
  ModalHeader,
  CompanyLogoImage,
  CompanyNameAndLocationWrapper,
  CompanyBaseDetailsWrapper,
  CompanyNameLabel,
  CompanyLocationLabel,
  Divider,
  CompanyOtherDetailsWrapper,
  CompanyDescription,
  LogoFallback,
  TagIcon,
  TagsWrapper,
} from './styled';

type ModalContentsProps = {
  companyInsightData: CompanyInsightData,
  isLoading: boolean,
}

function CompanyModalContents({
  companyInsightData,
  isLoading,
} : ModalContentsProps): JSX.Element {
  const {
    companyName,
    companyLogo,
    industries,
    companyEnrichedData,
  } = companyInsightData;

  if (isLoading) {
    return <CompanyModalSkeleton />;
  }

  const renderTags = () => {
    if (!industries?.length) {
      return null;
    }

    return (
      <TagsWrapper>
        { industries.map((industry) => (
          <Tag
            key={ industry.value }
            text={ industry.value }
            Icon={ TagIcon }
          />
        )) }
      </TagsWrapper>
    );
  };

  return (
    <>
      <ModalHeader>
        <CompanyLogoImage src={ companyLogo }>
          <LogoFallback />
        </CompanyLogoImage>
        <CompanyBaseDetailsWrapper >
          <CompanyNameAndLocationWrapper>
            {companyName && (
              <CompanyNameLabel>
                {companyName}
              </CompanyNameLabel>
            )}
            {companyEnrichedData.companyLocation && (
              <CompanyLocationLabel>
                {formatExactLocation(companyEnrichedData.companyLocation)}
              </CompanyLocationLabel>
            )}
          </CompanyNameAndLocationWrapper>
          { renderTags() }
        </CompanyBaseDetailsWrapper>
      </ModalHeader>
      <Divider />
      <CompanyOtherDetailsWrapper>
        <CompanyProperties
          name={ companyName }
          size={ companyEnrichedData.companySize }
          foundedAt={ companyEnrichedData.companyFoundedAt }
          website={ companyEnrichedData.companyWebsite }
          linkedinUrl={ companyEnrichedData.companyLinkedinUrl }
          ipoStatus={ companyEnrichedData.companyIpoStatus }
          lastFundingType={ companyEnrichedData.companyLastFundingType }
        />

        <CompanyDescription>
          {companyEnrichedData.companyFullDescription}
        </CompanyDescription>
      </CompanyOtherDetailsWrapper>
    </>
  );
}

export default CompanyModalContents;
