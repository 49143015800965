import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import STRING_KEYS from '../../../../../language/keys';
import {
  ButtonsContainer,
  Container,
  MatchTuneBody,
  Subtitle,
  Title,
  TitleContainer,
} from '../../../pages/match-tune/match-tune.styled';
import MatchTuneCard from '../match-tune-card/match-tune-card.component';
import { MatchTuneStep } from '../../../pages/match-tune/match-tune.enums';
import { EditPositionPreferencesFormikKeys } from '../../../../../components/dialogs/edit-position-dialog/edit-position-dialog.enums';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
  PerfectButtonVariants,
} from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { AppRouting } from '../../../../../services/appRoutingResolver';
import { useSkipMatchTuneMutation } from '../../../../../store/rtk-query/apis/position/hooks/skip-match-tune.mutation-hook';
import { MatchTuneMainScreenProps } from './match-tune-main-screen';
import {
  Button,
  CardsContainer,
  GhostButton,
  CompanySizeIcon,
  IndustryExperienceIcon,
  JobTitleIcon,
  LevelOfExperienceIcon,
  PositionFeedbackIcon,
  SkillsIcon,
  LocationIcon,
  ConfirmButtonIcon,
  SkipButtonIcon,
  EducationIcon,
} from './match-tune-main-screen.styled';

const MatchTuneMainScreen = ({
  positionId,
  hasReachedMatchTuneThreshold,
  continueButtonClickHandler,
  cardClickHandler,
  isTouched,
  isContinueButtonDisabled,
  isLoading,
}: MatchTuneMainScreenProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const [skipMatchTune, { isLoading: isSkipMatchTuneLoading }] = useSkipMatchTuneMutation();
  const subtitleKey = hasReachedMatchTuneThreshold ? STRING_KEYS.MATCH_TUNE.SUBTITLE : STRING_KEYS.MATCH_TUNE.SUBTITLE_MANUAL;
  const titleKey = hasReachedMatchTuneThreshold ? STRING_KEYS.MATCH_TUNE.TITLE : STRING_KEYS.MATCH_TUNE.TITLE_MANUAL;

  const onSkipMatchTuneButtonClick = () => {
    if (hasReachedMatchTuneThreshold) {
      skipMatchTune(positionId);
    }
    history.push(AppRouting.buildMatchesPageUrl(positionId));
  };

  return (
    <Container>
      <TitleContainer>
        <Subtitle>{ translate(subtitleKey) }</Subtitle>
        <Title>{ translate(titleKey) }</Title>
      </TitleContainer>
      <MatchTuneBody>
        <CardsContainer>
          <MatchTuneCard
            text={ translate(STRING_KEYS.MATCH_TUNE.STEPS_CARDS.LOCATIONS) }
            Icon={ LocationIcon }
            clickHandler={ () => cardClickHandler(MatchTuneStep.Locations) }
            isSelected={ isTouched(EditPositionPreferencesFormikKeys.Locations, EditPositionPreferencesFormikKeys.RemotePosition) }
            disabled={ isLoading }
          />
          <MatchTuneCard
            text={ translate(STRING_KEYS.MATCH_TUNE.STEPS_CARDS.LEVELS_OF_EXPERIENCE) }
            Icon={ LevelOfExperienceIcon }
            clickHandler={ () => cardClickHandler(MatchTuneStep.LevelsOfExperience) }
            isSelected={ isTouched(EditPositionPreferencesFormikKeys.LevelsOfExperience, EditPositionPreferencesFormikKeys.OverallLevelsOfExperience) }
            disabled={ isLoading }
          />
          <MatchTuneCard
            text={ translate(STRING_KEYS.MATCH_TUNE.STEPS_CARDS.COMPANY_BACKGROUND) }
            Icon={ IndustryExperienceIcon }
            clickHandler={ () => cardClickHandler(MatchTuneStep.IndustryExperience) }
            isSelected={ isTouched(EditPositionPreferencesFormikKeys.CompanyBackground) }
            disabled={ isLoading }
          />
          <MatchTuneCard
            text={ translate(STRING_KEYS.MATCH_TUNE.STEPS_CARDS.SKILLS) }
            Icon={ SkillsIcon }
            clickHandler={ () => cardClickHandler(MatchTuneStep.Skills) }
            isSelected={ isTouched(EditPositionPreferencesFormikKeys.Skills) }
            disabled={ isLoading }
          />
          <MatchTuneCard
            text={ translate(STRING_KEYS.MATCH_TUNE.STEPS_CARDS.COMPANY_SIZES) }
            Icon={ CompanySizeIcon }
            clickHandler={ () => cardClickHandler(MatchTuneStep.CompanySize) }
            isSelected={ isTouched(EditPositionPreferencesFormikKeys.CompanySizes) }
            disabled={ isLoading }
          />
          <MatchTuneCard
            text={ translate(STRING_KEYS.MATCH_TUNE.STEPS_CARDS.JOB_TITLE) }
            Icon={ JobTitleIcon }
            clickHandler={ () => cardClickHandler(MatchTuneStep.JobTitle) }
            isSelected={
              isTouched(EditPositionPreferencesFormikKeys.JobTitle, EditPositionPreferencesFormikKeys.PrioritizedTitles, EditPositionPreferencesFormikKeys.ExcludedTitles)
            }
            disabled={ isLoading }
          />
          <MatchTuneCard
            text={ translate(STRING_KEYS.MATCH_TUNE.STEPS_CARDS.EDUCATION) }
            Icon={ EducationIcon }
            clickHandler={ () => cardClickHandler(MatchTuneStep.Education) }
            isSelected={
              isTouched(EditPositionPreferencesFormikKeys.FieldsOfStudy, EditPositionPreferencesFormikKeys.Degrees)
            }
            disabled={ isLoading }
          />
          <MatchTuneCard
            text={ translate(STRING_KEYS.MATCH_TUNE.STEPS_CARDS.POSITION_FEEDBACK) }
            Icon={ PositionFeedbackIcon }
            clickHandler={ () => cardClickHandler(MatchTuneStep.PositionFeedback) }
            disabled={ isLoading }
          />
        </CardsContainer>
        <ButtonsContainer>
          <GhostButton
            onClick={ onSkipMatchTuneButtonClick }
            variant={ PerfectButtonVariants.Ghost }
            size={ PerfectButtonSize.Large }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
            StartIcon={ SkipButtonIcon }
            disabled={ isLoading }
            isLoading={ isSkipMatchTuneLoading }
          >
            { translate(STRING_KEYS.MATCH_TUNE.SKIP_BUTTON) }
          </GhostButton>
          <Button
            onClick={ continueButtonClickHandler }
            size={ PerfectButtonSize.Large }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
            StartIcon={ ConfirmButtonIcon }
            disabled={ isContinueButtonDisabled }
            isLoading={ isLoading }
          >
            { translate(STRING_KEYS.MATCH_TUNE.CONFIRM_BUTTON) }
          </Button>
        </ButtonsContainer>
      </MatchTuneBody>
    </Container>
  );
};

export default MatchTuneMainScreen;
