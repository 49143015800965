import { useSelector } from 'react-redux';
import { RootReducerState } from '../../../index';
import { CreatePositionBasicInfoSelector } from '../../../../modules/create-position/@types/create-position-selectors';

export const useCreatePositionBasicInfoSelector = (): CreatePositionBasicInfoSelector => {
  return useSelector(({ createPosition }: RootReducerState) => {
    return {
      jobTitle: createPosition.selectedJobTitle.name,
      locations: createPosition.selectedLocations,
      isRemote: createPosition.isRemote,
    };
  });
};
