import { useMemo } from 'react';
import {
  ActionIcon,
  Container,
  Count,
  Footer,
  Header,
  TitleIconWrapper,
  Title, BodyIconWrapper,
} from './statistic-card.styled';
import { StatisticCardProps } from './statistic-card';

const StatisticCard = ({
  title,
  TitleIcon,
  count,
  activeColor,
  BodyIcon,
  clickHandler,
}: StatisticCardProps): JSX.Element => {
  const isActive = useMemo(() => count > 0, [count]);

  const getHeader = () => {
    if (BodyIcon) {
      return (
        <BodyIconWrapper>
          <BodyIcon />
        </BodyIconWrapper>
      );
    }

    return (
      <>
        <TitleIconWrapper><TitleIcon /></TitleIconWrapper>
        <Count $isActive={ isActive }>{ count > 0 ? '+' : undefined}{ count }</Count>
      </>
    );
  };

  return (
    <Container
      $isActive={ isActive }
      backgroundColor={ activeColor }
      onClick={ (event) => clickHandler?.(event) }
    >
      <Header>
        { getHeader() }
      </Header>
      <Footer>
        <Title $isActive={ isActive } isDark={ !!BodyIcon }>{ title }</Title>
        { isActive && (<ActionIcon $isActive={ isActive } color={ activeColor } />) }
      </Footer>
    </Container>
  );
};

export default StatisticCard;
