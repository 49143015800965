import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../language/keys';
import { loremIpsum } from '../../../mock/loremIpsum';
import PrimaryButton from '../Buttons/PrimaryButton';
import { Divider } from '../Divider';
import Modal from '../Modal';
import ModalContent from '../Modal/ModalContent';
import ModalFooter from '../Modal/ModalFooter';
import ModalHeader from '../Modal/ModalHeader';
import {
  SubTitle,
  Title,
  ContentWrapper,
  ChapterBody,
  CloseButtonWrapper,
} from './terms-of-use-modal.styled';

const TermsOfUseModal = ({
  onModalClose,
}:TermsOfUseModalProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Modal
      onClose={ onModalClose }
      isLoading={ false }
      height={ 550 }
      width={ 960 }
      backgroundBlur
    >
      <ModalHeader>

        <Title>
          { translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_TERMS_OF_USE_TITLE)}
        </Title>

        <SubTitle>
          {
            translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_TERMS_OF_USE_SUBTITLE)
          }
        </SubTitle>

      </ModalHeader>

      <ModalContent>

        <ContentWrapper>

          <Divider />

          <ChapterBody>
            { loremIpsum }
          </ChapterBody>

          <Divider />

        </ContentWrapper>

      </ModalContent>

      <ModalFooter>
        <CloseButtonWrapper>
          <PrimaryButton
            title={ translate(STRING_KEYS.ACCEPT) }
            onClick={ onModalClose }
            type="button"
          />
        </CloseButtonWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default TermsOfUseModal;
