import styled from 'styled-components';
import { ASSETS } from '../../../../../assets';
import { THEME } from '../../../../../themes';

export const SeeExamplesButton = styled.span`
  color: ${THEME.COLORS.ACCENT};
  text-decoration: underline;
  cursor: pointer;
`;

export const AiIcon = styled(ASSETS.AI)`
  width: 16px;
`;
