/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { CreatePositionExperienceStep } from '../../../create-position/components/create-position-experience-step/create-position-experience-step.componet';
import { useCreatePositionProgressSteps } from '../../../create-position/hooks/create-position-progress-steps.hooks';
import { getStepIndex } from '../../utils/onboarding-bi-events.utls';
import { useGetCurrentStep } from '../../hooks/onboarding-current-step.hooks';
import { CreatePositionStepsEnums } from '../../../create-position/enums/create-position-steps.enums';
import {
  setOnboardingContinueButtonDisable,
  setOnboardingContinueButtonEnable,
  setOnboardingContinueButtonHide,
  setOnboardingContinueButtonShow,
} from '../../../../store/slices/onboarding-state/onboarding-state.toolkit-slice';

export const OnboardingExperiencePage = (): JSX.Element => {
  const currentStep = useGetCurrentStep<CreatePositionStepsEnums>();
  const { steps } = useCreatePositionProgressSteps(currentStep);
  const stepNumber = getStepIndex(currentStep, steps);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOnboardingContinueButtonHide());
  }, []);

  return (
    <CreatePositionExperienceStep
      stepNumber={ stepNumber }
      setContinueButtonEnable={ setOnboardingContinueButtonEnable }
      setContinueButtonDisable={ setOnboardingContinueButtonDisable }
      setContinueButtonShow={ setOnboardingContinueButtonShow }
      isOnboarding
    />
  );
};
