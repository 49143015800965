import { Trans, useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import CampaignPageBaseOption from '../campaign-base-option/campaign-base-option.component';
import STRING_KEYS from '../../../../../language/keys';
import {
  ItemContainer,
  SoonIcon,
} from '../campaign-custom-mode/selection-button/select-type-popup/select-type-popup.styled';
import {
  SELECTION_AUTOMATIC_ITEMS,
  SELECTION_MANUAL_ITEMS,
} from '../campaign-custom-mode/selection-button/select-type-popup/select-type-popup.consts';
import { SelectionItem } from '../campaign-custom-mode/selection-button/select-type-popup/select-type-popup';
import { OutreachSequencePreferencesFormikKeys } from '../../../pages/campaign/campaign.enums';
import SelectItem from '../../../../../components/Common/select/select-item/select-item.component';
import { CampaignMessagingTypeSectionProps } from './campaign-messaging-type-section';
import {
  Icon,
  MenuLabel,
  MenuSubtitle,
  MenuTitle,
  MenuTitleContainer,
  StyledSelect,
} from './campaign-messaging-type-section.styled';

const CampaignMessagingTypeSection = ({ messageType, changeHandler }: CampaignMessagingTypeSectionProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const onMessageTypeChange = (value: string) => {
    changeHandler(OutreachSequencePreferencesFormikKeys.MessageType, value);
  };

  const renderItems = (items: SelectionItem[]) => items.map(({
    translationKey, disabled, Icon: ItemIcon, type,
  }) => (
    <SelectItem
      key={ translationKey }
      value={ type }
      disabled={ disabled }
    >
      <ItemContainer>
        <ItemIcon />
        <Trans i18nKey={ translationKey } />
        { disabled && <SoonIcon /> }
      </ItemContainer>
    </SelectItem>
  ));

  const MessageTypeSelect = useMemo(() => {
    return (
      <StyledSelect
        placeholder={ translate(STRING_KEYS.CAMPAIGN_PAGE.MESSAGING_TYPE_SECTION.PLACEHOLDER) }
        value={ messageType }
        onChangeHandler={ onMessageTypeChange }
        menuProps={ {
          Title: (
            <MenuTitleContainer>
              <MenuTitle>{ translate(STRING_KEYS.CAMPAIGN_PAGE.MESSAGING_TYPE_SECTION.MENU.TITLE) }</MenuTitle>
              <MenuSubtitle>{ translate(STRING_KEYS.CAMPAIGN_PAGE.MESSAGING_TYPE_SECTION.MENU.SUBTITLE) }</MenuSubtitle>
            </MenuTitleContainer>
          ),
        } }
      >
        <MenuLabel>{ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.AUTOMATIC_LABEL) }</MenuLabel>
        { renderItems(SELECTION_AUTOMATIC_ITEMS) }
        <MenuLabel>{ translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.MANUAL_LABEL) }</MenuLabel>
        { renderItems(SELECTION_MANUAL_ITEMS) }
      </StyledSelect>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageType, translate]);

  return (
    <CampaignPageBaseOption
      title={ translate(STRING_KEYS.CAMPAIGN_PAGE.MESSAGING_TYPE_SECTION.TITLE) }
      description={ translate(STRING_KEYS.CAMPAIGN_PAGE.MESSAGING_TYPE_SECTION.DESCRIPTION) }
      Icon={ Icon }
      EndAdornment={ MessageTypeSelect }
    />
  );
};

export default CampaignMessagingTypeSection;
