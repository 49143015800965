import { MouseEvent } from 'react';
import { PerfectButtonHoverVariants } from '../../../../Buttons/perfect-base-button/perfect-base-button.enums';
import { IconButtonVariants } from '../../../../Buttons/icon-button/icon-button.enums';
import { Button, TokensIcon } from './mentions-control.styled';

const MentionsControl = ({ toggleHandler, disabled }: MentionsControlProps): JSX.Element => {
  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    toggleHandler?.();
  };

  return (
    <>
      <Button
        type="button"
        disabled={ disabled }
        hoverVariant={ PerfectButtonHoverVariants.Grow }
        variant={ IconButtonVariants.Ghost }
        StartIcon={ TokensIcon }
        onClick={ (e: MouseEvent<HTMLButtonElement>) => onClick(e) }
      />
    </>
  );
};

export default MentionsControl;
