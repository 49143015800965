import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { Body1Font, CaptionFont, Subtitle1Font } from '../../../../../../themes/fonts';
import { ASSETS } from '../../../../../../assets';
import { Autocomplete } from '../../../../../../components/Common/create-position-autocomplete/autocomplete.component';

export const Container = styled.div`
  display: flex;
  width: 640px;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
`;

export const Card = styled.div`
  background-color: ${THEME.COLORS.PURE_WHITE};
  padding: ${THEME.SPACING.MEDIUM};
  border-radius: ${THEME.RADIUS.ROUND};
  color: ${THEME.COLORS.CONTENT_A}:
`;

export const Title = styled.div`
  ${Subtitle1Font};
  margin-bottom: ${THEME.SPACING.TINY};
`;

export const Description = styled.div`
  ${Body1Font};
  margin-bottom: ${THEME.SPACING.BASE};
  opacity: ${THEME.OPACITY.MED};
  line-height: 24px;
`;

export const SimilarItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const SimilarTitlesLoadingWrapper = styled.div`
  ${CaptionFont};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
  padding: ${THEME.SPACING.LARGE} 0;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const StyledAutocomplete = styled(Autocomplete)`
  font-size: 24px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
`;

export const InputIcon = styled(ASSETS.PLUS_SIGN_FILLED)`
  color: ${THEME.COLORS.PRIMARY_DARK};
  opacity: ${THEME.OPACITY.DIVIDER};
  width: 25px;
  height: 25px;
`;
