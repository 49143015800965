import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import {
  PerfectButtonHoverVariants, PerfectButtonVariants,
} from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import {
  ActionButton,
  ArrowIcon, Background,
  Body, BodyDescription, BodyTitle, ButtonIcon,
  Container,
  Header,
  HeaderIconContainer,
  HeaderLabel,
  HeaderLabelContainer,
  RateChip,
  HeaderIcon,
} from './base-position-assistant.styled';
import { PositionAssistantBaseProps } from './base-position-assistant';

const BasePositionAssistant = ({
  title, description, action, rate, Icon, isDark = false,
}: PositionAssistantBaseProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const {
    title: actionTitle,
    handler: actionHandler,
    variant = PerfectButtonVariants.Fill,
    showIcon = true,
  } = action || {};

  return (
    <Container>
      <Background isDark={ isDark } />
      <Header>
        <HeaderLabelContainer>
          <HeaderIconContainer>
            <HeaderIcon />
          </HeaderIconContainer>
          <HeaderLabel>
            { translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.LABEL) }
          </HeaderLabel>
        </HeaderLabelContainer>
        {
          rate && (
            <RateChip
              StartAdornment={ <ArrowIcon /> }
            >
              {`+${rate}%`}
            </RateChip>
          )
        }
      </Header>
      <Body>
        <Icon />
        <BodyTitle isDark={ isDark }>
          { title }
        </BodyTitle>
        <BodyDescription isDark={ isDark }>
          { description }
        </BodyDescription>
        {
          action && (
            <ActionButton
              hoverVariant={ PerfectButtonHoverVariants.Grow }
              EndIcon={ showIcon ? ButtonIcon : undefined }
              onClick={ actionHandler }
              variant={ variant }
            >
              { actionTitle }
            </ActionButton>
          )
        }
      </Body>
    </Container>
  );
};
export default BasePositionAssistant;
