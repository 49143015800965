import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { ASSETS } from '../../../../../assets';
import { HorizontalDivider } from '../../../../../components/Common/horizontal-divider/horizontal-divider.component';
import { fadeInAnimation } from '../../../../../animations';
import { ELEMENTS_DURATION } from '../../../../../consts/durations';
import { HeadlineFont } from '../../../../../themes/fonts';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MEDIUM};
  width: 720px;
  align-items: center;
`;

export const StyledDivider = styled(HorizontalDivider)`
  flex-grow: 1;
  width: unset;
`;

export const PlusIcon = styled(ASSETS.PLUS_SIGN_FILLED)`
  width: 24px;
  height: 24px;
  opacity: ${THEME.OPACITY.DIVIDER};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const DividerContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.BASE};
  align-items: center;
  width: 100%;
`;

export const InputContainer = styled.div`
  position: relative;
  font-size: 24px;
  text-align: center;

  & .input-wrapper {
    height: 32px;
  }
  
  & input {
    text-align: center;
  }
`;

export const GuidanceModalButtonWrapper = styled.div`
  margin-top: -${THEME.SPACING.LOOSE};
`;

export const EmptyStateMessage = styled.div`
  ${HeadlineFont};
  font-weight: 300;
  opacity: ${THEME.OPACITY.LOW};
  text-align: center;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const SuggestionListContainer = styled.div`
  display: inline-flex;
  gap: ${THEME.SPACING.TINY};
  flex-wrap: wrap;
  justify-content: center;

  animation: ${fadeInAnimation({
    animationDuration: ELEMENTS_DURATION.JOB_DESCRIPTION_TITLE_DURATION,
    animationDelay: 0,
  })
}
`;
