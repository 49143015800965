import axiosInstance from '.';

export const getLinkedInProfile =  async (text: string): Promise<OnboardingCompanyResponse[]> => {
  // TODO: change to rtk
  const response = await axiosInstance.get('/v1/onboarding/companies/linkedin/profile', {
    params: {
      linkedinUrl: text,
    },
  });

  return [response.data];
};
