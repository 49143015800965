import { createContext } from 'react';
import { RudderStackDestination } from '../../services/rudderStackAnalyticsAgent';
import { PageEventPayload, TrackEventPayload } from '../../@types/analytics/eventPayload';

export interface AnalyticsContextValue {
  analyticsTrackEvent: (trackEventPayload: TrackEventPayload, destinations?: RudderStackDestination[]) => void,
  analyticsPageEvent: (pageEventPayload: PageEventPayload, destinations?: RudderStackDestination[]) => void
}

const defaultAnalyticsContext = {
  analyticsTrackEvent: () => null,
  analyticsPageEvent: () => null,
};

export const AnalyticsContext = createContext<AnalyticsContextValue>(
  defaultAnalyticsContext,
);
