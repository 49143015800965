import React from 'react';
import {
  ActionButtonsContainer,
  Container,
  IconContainer,
  LeftContainer,
  Title,
  TitleContainer,
} from './preferences-body-header.styled';

const PreferencesBodyHeaderComponent = ({
  title,
  titleAdornment,
  Icon,
  ActionButtons,
}: PreferencesBodyHeaderProps): JSX.Element => {
  return (
    <Container>
      <LeftContainer hasTitleAdornment={ !!titleAdornment }>
        <IconContainer>
          <Icon />
        </IconContainer>
        <TitleContainer>
          <Title>{ title }</Title>
          { titleAdornment }
        </TitleContainer>
      </LeftContainer>
      <ActionButtonsContainer>
        { ActionButtons }
      </ActionButtonsContainer>
    </Container>
  );
};

export default PreferencesBodyHeaderComponent;
