import styled from 'styled-components';
import { THEME } from '../../../themes';

export const IconFallback = styled.div<{ fallbackImage: string }>`
  width: 100%;
  height: 100%;
  padding: ${THEME.SPACING.MEDIUM};
  border-radius: ${THEME.RADIUS.BASE};
  position: relative;
  :before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(${({ fallbackImage }) => fallbackImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
