import styled from 'styled-components';
import { THEME } from '../../../themes';
import { CaptionFont } from '../../../themes/fonts';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: ${THEME.SPACING.GRAND};
`;

export const ErrorContainer = styled.div`
  width: 464px;
  margin-top: -${THEME.SPACING.BASE};
  margin-bottom: ${THEME.SPACING.BASE};
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  width: 90%;
  max-width: 400px;
  position: relative;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill::first-line,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${THEME.COLORS_RGB.GHOST_GRAY} inset !important;
    -webkit-text-fill-color: ${THEME.COLORS_RGB.CONTENT_PRIMARY_MED} !important;
}
`;

export const ValidationError = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  width: 100%;
  color: ${THEME.COLORS.ALERT};
  margin-top: ${THEME.SPACING.TINY};
`;

export const LetsGoButtonWrapper = styled.div<{isLoading: boolean}>`
  width: 100%;
  margin-top: ${THEME.SPACING.TINY};
  opacity: ${({ isLoading }) => isLoading ? THEME.OPACITY.LOW : THEME.OPACITY.HIGHEST};
  pointer-events: ${({ isLoading }) => isLoading ? 'none' : ''};
`;

export const Container = styled.div`
  width: 95%;
`;

export const ForgotPassword = styled.div`
  ${CaptionFont};
  position: relative;
  top: 10px;
  left: 126px;
  font-weight: 600;
  line-height: 1.58;
  letter-spacing: 1.15px;
  color: ${THEME.COLORS.PRIMARY};
  cursor: pointer;
  text-transform: uppercase;
`;
