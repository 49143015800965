import styled from 'styled-components';
import { slideInAnimation } from '../../../../../animations';
import { SCREEN_ANIMATED_DELAY } from '../../../../../consts/delays';
import { ELEMENTS_DURATION } from '../../../../../consts/durations';

export const Wrapper = styled.div`
  position: fixed;
  z-index: 5;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: white;
  animation: ${slideInAnimation({
    animationDuration: ELEMENTS_DURATION.SCREEN_ANIMATED_DURATION,
    animationDelay: SCREEN_ANIMATED_DELAY,
    translateStart: '1000px',
    translateEnd: '0',
    timingFunction: 'linear',
    fillMode: 'both',
  })}
`;
