import { useTranslation } from 'react-i18next';
import { formatExactLocation } from '../../../../../../utils/location';
import STRING_KEYS from '../../../../../../language/keys';
import { formatMonths } from '../../../../../../utils';
import {
  BaseWidgetStyled,
  Icon,
  ItemList,
  LevelOfExperienceIcon,
  LocationIcon,
} from './explainability-widget.styled';
import ExplainabilityWidgetItem from './explainability-widget-item/explainability-widget-item.component';
import { ExplainabilityWidgetProps } from './explainability-widget';
import ExplainabilityWidgetCompanyBackground
  from './explainability-widget-item/explainability-widget-attributes/explainability-widget-company-background/explainability-widget-company-background.component';
import ExplainabilityWidgetSkills
  from './explainability-widget-item/explainability-widget-attributes/explainability-widget-skills/explainability-widget-skills.component';
import ExplainabilityWidgetCompanySize
  from './explainability-widget-item/explainability-widget-company-size/explainability-widget-company-size.component';
import ExplainabilityWidgetEducation
  from './explainability-widget-item/explainability-widget-education/explainability-widget-education.component';

const ExplainabilityWidget = ({ match }: ExplainabilityWidgetProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <BaseWidgetStyled
      title={ translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY_WIDGET.LABEL) }
      Icon={ Icon }
    >
      <ItemList>
        <ExplainabilityWidgetItem
          label={ translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY_WIDGET.ITEMS_LABELS.LOCATION) }
          value={ formatExactLocation(match.talent.location) }
          Icon={ LocationIcon }
        />
        <ExplainabilityWidgetItem
          label={ translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY_WIDGET.ITEMS_LABELS.LEVEL_OF_EXPERIENCE) }
          value={ `${formatMonths(match.talent.totalMonthsRelevantTenure)}` }
          Icon={ LevelOfExperienceIcon }
        />
        <ExplainabilityWidgetSkills
          explainability={ match.explainability }
        />
        <ExplainabilityWidgetCompanyBackground
          explainability={ match.explainability }
        />
        <ExplainabilityWidgetCompanySize
          employmentHistory={ match.talent.employmentHistory }
        />
        <ExplainabilityWidgetEducation match={ match } />
      </ItemList>
    </BaseWidgetStyled>
  );
};

export default ExplainabilityWidget;
