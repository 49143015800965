// Container related components
import styled from 'styled-components';
import BaseButton from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { THEME } from '../../../../../../themes';
import { hexToRgbA } from '../../../../../../utils/colors';

export const ShowMoreButton = styled(BaseButton)`
  color: ${THEME.COLORS.PRIMARY};
  border: unset;
  border: 2px solid ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.GHOST)};
  margin-top: ${THEME.SPACING.TINY};
`;
