import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { format } from 'date-fns';
import STRING_KEYS from '../../../../../language/keys';
import { PositionState } from '../../../../../enums/position-state.enum';
import {
  BodyContainer,
  BoldText,
  Divider,
  FooterContainer,
  HeaderContainer,
  PositionCardContainer,
  PositionClosedText,
  PositionInfoBottom,
  PositionTitleContainer,
} from '../position-card.styled';
import PositionLocation from '../../../../../components/Common/position-location/position-location.component';
import { ClosedPositionCardProps } from './closed-position-card';

const TIME_FORMAT = 'LLL d, yyyy, h:mm a';

const ClosedPositionCard = ({
  position,
}: ClosedPositionCardProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const isPositionClosed = useMemo(() => position.state !== PositionState.Open, [position?.state]);

  const renderFooterContent = () => {
    const timestamp = position.positionClosure?.closedAt ? format(new Date(position.positionClosure?.closedAt), TIME_FORMAT) : translate(STRING_KEYS.UNKNOWN);

    return (
      <PositionClosedText>
        <BoldText>{ translate(STRING_KEYS.HOMEPAGE.POSITION_CARD.CLOSED_TEXT) }</BoldText>
        {position.positionClosure && translate(STRING_KEYS.HOMEPAGE.POSITION_CARD.CLOSED_INFO, {
          username: position.positionClosure?.closedBy?.userName || translate(STRING_KEYS.UNKNOWN),
          timestamp,
        })}
      </PositionClosedText>
    );
  };

  return (
    <PositionCardContainer isPositionClosed>
      <HeaderContainer isPositionClosed={ isPositionClosed }>
        <div>
          <PositionTitleContainer title={ position.jobTitle }>{position.jobTitle}</PositionTitleContainer>
          <PositionInfoBottom>
            <PositionLocation remotePosition={ !!position.remotePosition } locations={ position.locations } />
          </PositionInfoBottom>
        </div>
      </HeaderContainer>
      <BodyContainer />
      <Divider />
      <FooterContainer>
        { renderFooterContent() }
      </FooterContainer>
    </PositionCardContainer>
  );
};
export default ClosedPositionCard;
