import { LogLevelNumbers, RootLogger } from 'loglevel';

const logLevelMap = new Map<string, number>([
  ['TRACE', 0],
  ['DEBUG', 1],
  ['INFO', 2],
  ['WARN', 3],
  ['ERROR', 4],
  ['SILENT', 5],
  ['FATAL', 5],
]);

const DEFAULT_ERROR_LEVEL = 4;

/** Middleware that decides if this log will be forwarded to the console */
const  consoleLoggingMiddleware = (logger: RootLogger): void => {
  const originalFactory = logger.methodFactory;
  logger.methodFactory =
    (methodName: string, logLevel: LogLevelNumbers, loggerName: string | symbol) => {
      const rawMethod = originalFactory(methodName, logLevel, loggerName);
      const consoleLogLevel: string = process.env.REACT_APP_CONSOLE_LOG_LEVEL || 'error';
      let desiredConsoleLogLevelNumber = logLevelMap.get(consoleLogLevel.toUpperCase());
      if (desiredConsoleLogLevelNumber === undefined) {
        desiredConsoleLogLevelNumber = DEFAULT_ERROR_LEVEL;
      }

      return (...message: unknown[]) => {
        if (desiredConsoleLogLevelNumber as number <= Number(logLevel)) {
          rawMethod(...message);
        }
      };
    };
  logger.setLevel(logger.getLevel());
};

export default consoleLoggingMiddleware;
