import styled, { css } from 'styled-components';
import { THEME } from '../../../../../themes';
import { selectionElevation } from '../../../../../themes/elevations';
import { Body1Font, Subtitle1Font } from '../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../utils/colors';

const RegularStyle = css`
  border: solid 1px ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.GHOST)};
`;

const SelectedStyle = css`
  border: solid 2px ${THEME.COLORS.ACCENT};
  
  &:before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: ${THEME.RADIUS.LOOPY};
    background-color: ${THEME.COLORS.ACCENT};
    position: absolute;
    top: -3px;
    right: -3px;
    border: 3px solid ${THEME.COLORS.PURE_WHITE};
  }
`;

const CardHoverStyle = css`
  transform: scale(1.05);
`;

export const Container = styled.div<{ isSelected?: boolean, disabled?: boolean }>`
  width: 144px;
  height: 160px;
  position: relative;
  border-radius: ${THEME.RADIUS.CURVY};
  ${selectionElevation};
  background-color: ${THEME.COLORS.PURE_WHITE};
  padding: ${THEME.SPACING.TINO};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${Body1Font};
  gap: ${THEME.SPACING.BASE};
  ${({ isSelected }) => isSelected ? SelectedStyle : RegularStyle};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  transition: transform 0.1s;

  &:hover {
    ${({ disabled }) => disabled ? undefined : CardHoverStyle};
  }
`;

export const Text = styled.div`
  ${Subtitle1Font};
  color: ${THEME.COLORS.CONTENT_A};
  max-width: 84px;
  text-align: center;
  font-weight: 600;
`;
