import { CaseReducer, AnyAction } from '@reduxjs/toolkit';
import { AppState } from '../app-state.initial-state';
import { LOGIN_STATE } from '../app-state.consts';

export const getMyUserFulfilledExtraReducer: CaseReducer<AppState, AnyAction> = (state) => {
  state.loginState = LOGIN_STATE.LOGGED_IN;
};

export const getMyUserPendingExtraReducer: CaseReducer<AppState, AnyAction> = (state) => {
  state.loginState = LOGIN_STATE.LOGIN_IN_PROGRESS;
};
