import styled  from 'styled-components';
import { THEME } from '../../../../../../themes';
import { Subtitle2Font } from '../../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../../utils/colors';
import { ASSETS } from '../../../../../../assets';

export const MentionContainer = styled.span`
  ${Subtitle2Font};
  padding: ${THEME.SPACING.MICRO} ${THEME.SPACING.TINY};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  background-color: ${hexToRgbA(THEME.COLORS.WARNING, THEME.OPACITY.GHOST)};
  color: ${THEME.COLORS.CONTENT_A};
  white-space: nowrap;
  span {
    margin-left: 2px;
  }
`;
export const Brackets = styled(ASSETS.BRACKETS)`
  fill: ${THEME.COLORS.WARNING};
  width: 12px;
  height: 12px;
`;
