import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { ReactComponent as CLOSE_DIALOG } from '../../../../assets/close_dialog.svg';

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  height: 60px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${THEME.SPACING.MEDIUM};
  padding-right: ${THEME.SPACING.LOOSE};
  margin-bottom: ${THEME.SPACING.MICRO}; 
`;

export const CloseIcon = styled(CLOSE_DIALOG)`
  width: 32px;
  height: 32px;
`;
