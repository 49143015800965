import styled from 'styled-components';
import { THEME } from '../../../../../themes';

export const Wrapper = styled.div<{
  width: string,
  height: string,
  padding: string,
  backgroundColor: string,
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  border-radius: ${THEME.RADIUS.CURVY};
  box-shadow: 0 0 12px 0 ${THEME.COLORS_RGB.BLACK_DIVIDER};
  border: solid 1px ${THEME.COLORS_RGB.BLACK_DIVIDER};
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: relative;
`;
