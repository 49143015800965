import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { pageContentWidth } from '../match-content.styled';

export const WidgetsContainer = styled.div`
  display: flex;
  marginLeft: auto;
  flex-direction: column;
  gap: ${THEME.SPACING.MEDIUM};
`;

export const PageContent = styled.div`
  display: flex;
  width: ${pageContentWidth};
  margin: ${THEME.SPACING.XLOOSE} auto;
`;

export const TalentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`;
