import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RegularTag,
  SimilarTag,
  TagRow,
} from '../explainability-info-menu.styled';
import { IndustryTagIcon } from '../../match-card/timeline/experience-container/experience-item-tags/experience-item-tags.styled';
import { FLUENT_UI_EMOJIS } from '../../../../../assets';
import { ExplainabilityTypeEnum } from '../../../../../enums/explainability-type.enum';
import { createSkillIcon } from '../../match-card/timeline/experience-container/experience-item-tags/experience-item-tags.component';
import STRING_KEYS from '../../../../../language/keys';
import RelevanceExplanationSection
  from '../sections/relevance-explenation-section/relevance-explanation-section.component';
import SelfEvidenceSection from '../sections/self-evidence-section/self-evidence-section.component';
import VerificationSourceSection from '../sections/verification-source-section/verification-source-section.component';
import HeaderSection from '../sections/header-section/header-section.component';
import { SimilarAttributeInfoMenuProps } from './similar-attribute-info-menu';
import { Container, SimilarExplanation } from './similar-attribute-info-menu.styled';

const SimilarAttributeInfoMenu = ({
  type,
  value,
  originalValue,
  similarExplanation,
  relevanceExplanation,
  origins,
}: SimilarAttributeInfoMenuProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const [title, Icon] = useMemo(() => {
    if (type === ExplainabilityTypeEnum.Skill) {
      return [translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY.SIMILAR_SKILL), createSkillIcon(FLUENT_UI_EMOJIS.HIGH_VOLTAGE_PURPLE)];
    }

    return [translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY.SIMILAR_SKILL), IndustryTagIcon];
  }, [translate, type]);

  return (
    <Container>
      <>
        <HeaderSection
          title={ title }
          Icon={ <Icon /> }
        >
          <div>
            <TagRow>
              <SimilarTag text={ value } />
              <div>
                { translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY.INFO_MENU.IS_SIMILAR_TO) }
              </div>
              <RegularTag text={ originalValue } />
            </TagRow>
            <SimilarExplanation>
              { similarExplanation }
            </SimilarExplanation>
          </div>
        </HeaderSection>
        <RelevanceExplanationSection relevanceExplanation={ relevanceExplanation } />
        { origins && (
          <>
            <VerificationSourceSection type={ type } value={ value } origins={ origins } />
            <SelfEvidenceSection origins={ origins } />
          </>
        ) }
      </>
    </Container>
  );
};

export default SimilarAttributeInfoMenu;
