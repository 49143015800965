import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { AddIcon, CardContainer, Title } from './create-position-card.styled';
import { CreatePositionCardProps } from './create-position-card';

const CreatePositionCard = ({ clickHandler }: CreatePositionCardProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <CardContainer onClick={ clickHandler }>
      <AddIcon />
      <Title>
        { translate(STRING_KEYS.HOMEPAGE.POSITION_CARD.CREATE_POSITION_CARD_TITLE) }
      </Title>
    </CardContainer>
  );
};
export default CreatePositionCard;
