import styled from 'styled-components';

import { THEME } from '../../themes';
import {
  ButtonFont,
  CaptionFont,
  HeadlineFont,
  Title2Font,
} from '../../themes/fonts';

export const PageContainer = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
`;

export const LoaderWrapper = styled.div`
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

export const Header = styled.div`
  ${Title2Font};
  line-height: 1.38;
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  text-transform: capitalize;
  font-weight: bold;
  display: block;
  width: 98%;
  margin-top: 64px;
  text-align: center;
`;

export const Accent = styled.span`
  color: ${THEME.COLORS.ACCENT};
`;

export const PerfectLogo = styled.img`
  display: block;
  width: auto;
  height: 64px;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  margin-top: ${THEME.SPACING.XLOOSE};
  text-align: center;
  flex-direction: column;
`;

export const PageDescription = styled.div`
  ${HeadlineFont};
  color: ${THEME.COLORS.CONTENT_A};
  font-weight: 400;
  max-width: 640px;
  margin-top: ${THEME.SPACING.MEDIUM};
`;

export const BackToLoginText = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const BackToLoginButton = styled.div`
  ${ButtonFont};
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  justify-self: center;
  cursor: pointer;
  margin-top: ${THEME.SPACING.TINY};
  color: ${THEME.COLORS.ACCENT};
`;
