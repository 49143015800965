import BaseInputItem from '../base-input-item.component';
import SecondarySwitch from '../../../outreach/secondary-switch/secondary-switch.component';

const SwitchInputItem = ({
  title,
  description,
  value,
  changeHandler,
  disabled,
}: SwitchInputItemProps): JSX.Element => {
  return (
    <BaseInputItem
      title={ title }
      description={ description }
    >
      <SecondarySwitch
        checked={ value }
        changeHandler={ changeHandler }
        disabled={ disabled }
      />
    </BaseInputItem>
  );
};

export default SwitchInputItem;
