import styled from 'styled-components';
import { THEME } from '../../../../../../../themes';
import { Body2Font, OverlineFont } from '../../../../../../../themes/fonts';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${THEME.COLORS.PURE_WHITE};
  border-radius: ${THEME.RADIUS.TIDY} ${THEME.SPACING.TINO} ${THEME.SPACING.TINO} ${THEME.RADIUS.TIDY};
  overflow: hidden;
  min-height: 44px;
`;

export const Wrapper = styled.div<{ borderColor?: string }>`
  border-left: 6px solid ${({ borderColor }) => borderColor || THEME.COLORS.SUCCESS};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
  padding: ${THEME.SPACING.TINY};
  flex-grow: 1;
`;

export const IconWrapper = styled.div`
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const TextContainer = styled.div`
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const Label = styled.div`
  ${OverlineFont};
  opacity: ${THEME.OPACITY.LOW};
  line-height: normal;
  margin-bottom: ${THEME.SPACING.MICRO};
`;

export const Value = styled.div`
  ${Body2Font};
  font-weight: 600;
  line-height: normal;
`;
