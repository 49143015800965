import { useEffect } from 'react';
import { useSocialLoginState, useSocialLoginActions } from '@frontegg/react';
import { ISocialLoginProviderConfigurationV2, SocialLoginProviders } from '@frontegg/rest-api';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../language/keys';
import { ContinueWithGoogleButton } from './social-login.styled';

const logger = log.getLogger('SOCIAL_LOGIN');

type SocialLoginOptionsProps = {
  onGoogleLoginClick: (configuration: ISocialLoginProviderConfigurationV2) => void,
};

export const SocialLoginOptions = ({
  onGoogleLoginClick,
}: SocialLoginOptionsProps): JSX.Element | null => {
  const { t: translate } = useTranslation();
  const { firstLoad, error, socialLoginsConfigV2 } = useSocialLoginState();
  const { loadSocialLoginsConfiguration } = useSocialLoginActions();

  useEffect(() => {
    if (firstLoad) {
      loadSocialLoginsConfiguration();
    }
  }, [loadSocialLoginsConfiguration, firstLoad]);

  useEffect(() => {
    if (error) {
      logger.warn('got frontegg error while loading social-login:', error);
    }
  }, [error]);

  const isSocialLoginAvailable = () => {
    if (error || !socialLoginsConfigV2 || socialLoginsConfigV2.length <= 0) {
      return false;
    }

    return socialLoginsConfigV2.some((item) => item.active);
  };

  const defineSocialLoginButton = (buttonConfiguration: ISocialLoginProviderConfigurationV2) => {
    switch (buttonConfiguration.type) {
      case SocialLoginProviders.Google: {
        return (
          <ContinueWithGoogleButton key={ SocialLoginProviders.Google } onClick={ () => onGoogleLoginClick(buttonConfiguration) }>
            { translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_CONTINUE_WITH_GOOGLE) }
          </ContinueWithGoogleButton>
        );
      }
      default: {
        return null;
      }
    }
  };

  if (!socialLoginsConfigV2 || !isSocialLoginAvailable()) {
    logger.debug(
      "social login isn't available. hiding the buttons",
    );

    // no social options to show
    return null;
  }

  return (
    <>
      {socialLoginsConfigV2.map((buttonConfiguration) => {
        if (!buttonConfiguration.active) {
          return null;
        }

        return defineSocialLoginButton(buttonConfiguration);
      })}
    </>
  );
};
