import styled, { css } from 'styled-components';
import IconButton from '../../../Buttons/icon-button/icon-button.component';
import { hexToRgbA } from '../../../../../utils/colors';
import { THEME } from '../../../../../themes';

export const iconCss = css`
  width: 13px;
  height: 13px;
`;

export const InlineStyleContainer = styled.div`
  display: flex;
  gap: 2px;
`;

export const Button = styled(IconButton)<{ active: boolean }>`
  color: ${THEME.COLORS.PRIMARY_DARK};
  background-color: ${({ active }) => active ? hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER) : undefined};
`;
