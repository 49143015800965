import styled from 'styled-components';
import { Body2Font } from '../../../../../../themes/fonts';
import { THEME } from '../../../../../../themes';
import { FLUENT_UI_EMOJIS } from '../../../../../../assets';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const Text = styled.div`
  ${Body2Font};
`;

export const Icon = styled(FLUENT_UI_EMOJIS.SPIDER_WEB)``;
