import { useTranslation } from 'react-i18next';
import CampaignPageAutoModeSwitchOption, {
  AUTO_MODE_ID,
  CUSTOM_MODE_ID,
} from '../campaign-auto-mode-switch-option/campaign-auto-mode-switch-option.component';
import STRING_KEYS from '../../../../../language/keys';
import SelectItem from '../../../../../components/Common/select/select-item/select-item.component';
import { SequenceFollowupsModeEnum } from '../../../../../store/rtk-query/apis/sequence/enums/sequence-followups-mode.enum';
import { OutreachSequencePreferencesFormikKeys } from '../../../pages/campaign/campaign.enums';
import { Container, Icon, StyledSelect } from './campaign-auto-mode-followups-number-section.styled';
import {
  defaultFollowupValue,
  followupsNumberMaxSelectValue,
} from './campaign-auto-mode-followups-number-section.consts';
import { CampaignPageAutoModeFollowupsNumberSectionProps } from './campaign-auto-mode-followups-number-section';

const modeEnumToId = (mode: SequenceFollowupsModeEnum): string => {
  if (mode === SequenceFollowupsModeEnum.Auto) {
    return AUTO_MODE_ID;
  }

  return CUSTOM_MODE_ID;
};

const modeIdToEnum = (modeId: string): SequenceFollowupsModeEnum => {
  if (modeId === AUTO_MODE_ID) {
    return SequenceFollowupsModeEnum.Auto;
  }

  return SequenceFollowupsModeEnum.Custom;
};

const CampaignPageAutoModeFollowupsNumberSection = ({ mode, followupsNumber, changeHandler }: CampaignPageAutoModeFollowupsNumberSectionProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const onModeChange = (selectedValue: string) => {
    changeHandler(OutreachSequencePreferencesFormikKeys.FollowupsMode, modeIdToEnum(selectedValue));
    changeHandler(OutreachSequencePreferencesFormikKeys.FollowupsNumber, selectedValue === AUTO_MODE_ID ? undefined : defaultFollowupValue);
  };

  const renderActionComponent = () => {
    return (
      <StyledSelect
        value={ followupsNumber?.toString() }
        onChangeHandler={ (selectedValue) => changeHandler(OutreachSequencePreferencesFormikKeys.FollowupsNumber, parseInt(selectedValue)) }
      >
        { [...Array.from(Array(followupsNumberMaxSelectValue))].map((_, index) => {
          const followupValue = index + 1;

          return (
            <SelectItem
              value={ followupValue.toString() }
              key={ followupValue }
            >
              <div>{ followupValue }</div>
            </SelectItem>
          );
        }) }
      </StyledSelect>
    );
  };

  return (
    <Container>
      <CampaignPageAutoModeSwitchOption
        title={ translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.FOLLOWUPS_NUMBER.TITLE) }
        description={ translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.FOLLOWUPS_NUMBER.DESCRIPTION) }
        infoText={ translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.FOLLOWUPS_NUMBER.INFO_TEXT) }
        Icon={ Icon }
        selectedValue={ modeEnumToId(mode) }
        ActionComponent={ renderActionComponent() }
        changeHandler={ onModeChange }
      />
    </Container>
  );
};

export default CampaignPageAutoModeFollowupsNumberSection;
