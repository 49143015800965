import { Trans, useTranslation } from 'react-i18next';
import BaseRealizationMetrics from '../base-component/base-component.component';
import STRING_KEYS from '../../../../../language/keys';
import { IntercomManager } from '../../../../../services/intercom-manager';
import {
  CalculatingTitle, HoursSaved, SmileIcon, Underline,
} from './effort-invested.styled';

const MIN_VISITS_DURATION_TO_SHOW = 5;

// temp - remove in a month
const FEATURE_RELEASE_DATE = new Date('2024-07-01');

export type EffortInvestedProps = {
  timeSavedInSeconds: number;
  totalPageVisitsDurationInSeconds: number;
  positionCreatedAt: Date;
};

const EffortInvested = ({ timeSavedInSeconds, totalPageVisitsDurationInSeconds, positionCreatedAt }: EffortInvestedProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const hoursSaved = Math.ceil((timeSavedInSeconds || 0) / 60 / 60);
  const minutesInvested = Math.floor((totalPageVisitsDurationInSeconds || 0) / 60);
  const hours = Math.round((minutesInvested / 60) * 10) / 10;
  const hoursInvested = hours < 1 ? hours : Math.round(hours);

  const shouldShowData = minutesInvested >= MIN_VISITS_DURATION_TO_SHOW && positionCreatedAt > FEATURE_RELEASE_DATE;

  const onOptimizeClick = () => {
    IntercomManager.showChat();
  };

  const renderTitle = () => {
    if (!shouldShowData) {
      return (
        <CalculatingTitle>
          <Trans
            i18nKey={ STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.EFFORT_INVESTED.CALCULATING_TITLE }
          />
        </CalculatingTitle>
      );
    }
    
    return (
      <Trans
        i18nKey={ STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.EFFORT_INVESTED.TITLE }
      >
        <strong />
        {{ hoursInvested }}
      </Trans>
    );
  };

  const renderDescription = () => {
    if (shouldShowData && hoursSaved <= 0) {
      return (
        <Trans
          defaults={ STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.EFFORT_INVESTED.NEGATIVE_TIME_DESCRIPTION }
          components={ { Underline: <Underline onClick={ onOptimizeClick } /> } }
        />
      );
    }

    return (
      <Trans
        defaults={ STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.EFFORT_INVESTED.DESCRIPTION }
        components={ { hoursSaved: <HoursSaved shouldShowData={ shouldShowData } /> } }
        values={ { hoursSaved } }
      />
    );
  };

  return (
    <BaseRealizationMetrics
      label={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.EFFORT_INVESTED.LABEL) }
      LabelIcon={ SmileIcon }
      title={ renderTitle() }
      description={ renderDescription() }
      tooltipText={ translate(STRING_KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.EFFORT_INVESTED.TOOLTIP_TEXT) }
    />
  );
};

export default EffortInvested;
