import styled, { css, keyframes } from 'styled-components';
import { THEME } from '../../../../themes';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';

const shake = keyframes`
  from {
    transform: scale(1);
  }
  10% {
    transform: scale(0.91);
  }
  17% {
    transform: scale(0.98);
  }
  33% {
    transform: scale(0.87);
  }
  45% {
    transform: scale(1);
  }
`;

export const ContinueButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.ACCENT};
  color: ${THEME.COLORS.PURE_WHITE};
  width: 160px;
`;

export const LoaderWrapper = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonWrapper = styled.div<{
  enabled?: boolean,
}>`
  ${({ enabled }) => enabled ?
    css`animation: ${shake} 2.5s infinite ease-out both` : ''};
`;

export const Loader = styled.div`
  @keyframes rotateKeyframe{
    100%{transform: rotate(1turn)}
  }

  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
          radial-gradient(farthest-side, ${THEME.COLORS.ACCENT} 94%, #0000) top/8px 8px no-repeat,
          conic-gradient(#0000 30%, ${THEME.COLORS.ACCENT});
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px),#000 0);
  animation: rotateKeyframe 1s infinite linear;
`;
