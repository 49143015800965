import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../language/keys';
import { Banner } from '../Banner';
import { PerfectLogo } from '../PerfectLogo';
import { AppRouting } from '../../../services/appRoutingResolver';
import { Container, GoLink, LogoContainer } from './styled';

type AuthErrorProps = {
  errorMessage: string,
};

export const AuthError = ({
  errorMessage,
}: AuthErrorProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <LogoContainer>
        <PerfectLogo size="large" />
      </LogoContainer>

      <Banner
        caption={ translate(STRING_KEYS.WHOOPS) }
        message={ errorMessage }
        type="alert"
      />

      <GoLink href={ AppRouting.buildGlobalLoginUrl(window.location) }>
        {translate(STRING_KEYS.GO_BACK_TO_AUTH)}
      </GoLink>
    </Container>
  );
};
