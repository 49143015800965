import { useSelector } from 'react-redux';
import { RootReducerState } from '../../../index';
import { CreatePositionCompanyBackgroundSelector } from '../../../../modules/create-position/@types/create-position-selectors';

export const usePositionBackgroundSelector = (): CreatePositionCompanyBackgroundSelector => {
  return useSelector(({ createPosition }: RootReducerState) => {
    return {
      companyBackground: createPosition.companyBackground,
      skills: createPosition.skills,
    };
  });
};
