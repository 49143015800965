import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import STRING_KEYS from '../../../../../language/keys';
import Tooltip from '../../../../../components/tooltip/tooltip.component';
import { AppRouting } from '../../../../../services/appRoutingResolver';
import {
  ConversationArchiveReasonEnum,
} from '../../../../../store/rtk-query/apis/outreach/enums/conversation-archive-reason.enum';
import { ArchivedConversation, ArchiveIcon, Underline } from './conversations-archive-chip.styled';
import { ConversationsArchivedChipProps } from './conversations-archive-chip';

const ConversationsArchivedChip = ({ archiveReason }: ConversationsArchivedChipProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const { positionId } = useParams<{ positionId: string }>();

  const onTooltipLinkClick = useCallback(() => {
    history.push(AppRouting.buildCampaignsPreferencesPageUrl(positionId));
  }, [history, positionId]);

  const Description = useMemo(() => (
    <Trans
      defaults={ STRING_KEYS.OUTREACH_PAGE.ARCHIVED_CONVERSATION.EMAIL_LESS.TOOLTIP }
      components={ { Underline: <Underline onClick={ onTooltipLinkClick } /> } }
    />
  ), [onTooltipLinkClick]);

  const renderChipContent = () => {
    if (archiveReason === ConversationArchiveReasonEnum.MissingEmail) {
      return (
        <Tooltip
          content={ Description }
          customTooltipStyle={ {
            maxWidth: '400px',
          } }
        >
          <ArchivedConversation
            StartAdornment={ <ArchiveIcon /> }
          >
            { translate(STRING_KEYS.OUTREACH_PAGE.ARCHIVED_CONVERSATION.EMAIL_LESS.TITLE) }
          </ArchivedConversation>
        </Tooltip>
      );
    }

    return (
      <ArchivedConversation
        StartAdornment={ <ArchiveIcon /> }
      >
        { translate(STRING_KEYS.OUTREACH_PAGE.ARCHIVED_CONVERSATION.DEFAULT.TITLE) }
      </ArchivedConversation>
    );
  };

  return (
    <>{ renderChipContent() }</>
  );
};

export default ConversationsArchivedChip;
