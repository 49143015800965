import log from 'loglevel';
import * as rudderanalytics from 'rudder-sdk-js';
import userflow from 'userflow.js';
import { apiOptions } from 'rudder-sdk-js';
import { EVENT_TYPE } from '../consts/analytics';
import {
  AccountOutputPayload,
  CampaignOutputPayload,
  CompanyOutputPayload,
  ComponentElementOutputPayload,
  ComponentOutputPayload,
  EngagementOutputPayload,
  ExperienceOutputPayload,
  LocationEventPayload,
  MatchOutputPayload,
  OutreachOutputPayload,
  PageOutputPayload,
  PositionOutputPayload,
  RudderStackPageEventPayload,
  RudderStackTrackEventPayload,
  TalentOutputPayload,
  UserOutputPayload,
} from '../@types/analytics/eventPayload';
import { UserIdentifyMethod } from '../store/slices/app-state/app-state.enums';
import { EmailSenderIntegrationStatusEnum } from '../store/rtk-query/apis/outreach/hooks/get-email-sender.query-hook';
import { CONFIG } from '../config';
import analyticsIdManager from './analyticsIdManager';
import UtmTrackingService from './utmTracker';

// https://github.com/rudderlabs/rudder-sdk-js/blob/production/integrations/index.js
export enum RudderStackDestination {
  INTERCOM = 'INTERCOM',
  FULLSTORY = 'FULLSTORY',
  GA4 = 'GA4',
  HS = 'HS',
  GA = 'GA',
  GTM = 'GTM',
}

export type IdentifyUserPayloadType = {
  userId: string,
  firstName: string,
  lastName: string,
  email: string | undefined,
  avatar: string,
  accountId: string,
  accountName: string | undefined,
  accountPlan: string | undefined,
  accountAtsConnected: boolean | undefined,
  method: UserIdentifyMethod,
  firstLogin: false,
  intercomUserHash: string | undefined,
  emailConnectionStatus: EmailSenderIntegrationStatusEnum | undefined,
};

class RudderStackAnalyticsAgent {
  private logger = log.getLogger(RudderStackAnalyticsAgent.name);

  private rudderStackAnalyticsAgent: typeof rudderanalytics | undefined;

  private userFlowInitialized = false;

  private userFlowIdentified = false;

  public isInitialized = () => {
    return !!this.rudderStackAnalyticsAgent;
  }

  public init = (writeKey: string, dataPlaneURL: string, apiUrl: string) => {
    const startInitDate = new Date();
    userflow.init(CONFIG.USERFLOW_TOKEN);
    this.userFlowInitialized = true;

    return new Promise<void>((resolve, reject) => {
      if (!writeKey) {
        reject(new Error('Missing RudderStack connection property - write key'));

        return;
      }

      if (!dataPlaneURL) {
        reject(new Error('Missing RudderStack connection property - data plane url'));

        return;
      }

      if (!apiUrl) {
        log.warn('RudderStack apiUrl not provided', {
          writeKey,
          dataPlaneURL,
          apiUrl,
        });
        // reject(new Error('missing rudderstack connection property - api url'));
        //
        // return;
      }

      this.logger.debug('Loading RudderStack analytics agent', {
        writeKey,
        dataPlaneURL,
        apiUrl,
      });

      try {
        rudderanalytics.load(writeKey, dataPlaneURL, {
          logLevel: 'ERROR',
          /*
          This property enables the JavaScript SDK to make a call to load the Google AdSense library. If RudderStack fails to load this library, it concludes that an ad blocker is enabled on the page.
          */
          sendAdblockPage: true,
          sendAdblockPageOptions: {
            integrations: {
              All: true,
            },
          },
          integrations: { All: true },
          configUrl: apiUrl,
        });

        rudderanalytics.ready(async () => {
          this.rudderStackAnalyticsAgent = rudderanalytics;

          this.logger.debug('RudderStack analytics agent is ready');

          const readyCallback = new Date();

          this.logger.info(`Rudderstack ready callback was called, time durations is ${readyCallback.getTime() - startInitDate.getTime()} ms`);

          resolve();
        });
      }  catch (error) {
        const errorInfo = JSON.stringify({
          // https://stackoverflow.com/a/44862693
          error: JSON.stringify(error, ['message', 'arguments', 'type', 'name']),
          writeKey,
          dataPlaneURL,
          apiUrl,
        });

        reject(new Error(`Failed to load RudderStack analytics agent ${errorInfo}`));
      }
    });
  }

  public identify = ({
    userId,
    firstName,
    lastName,
    email,
    avatar,
    accountId,
    accountName,
    accountPlan,
    method = UserIdentifyMethod.Local,
    firstLogin = false,
    intercomUserHash,
    emailConnectionStatus,
    accountAtsConnected,
  }: IdentifyUserPayloadType): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    let fullName = firstName;
    if (lastName) {
      fullName += ` ${lastName}`;
    }

    if (this.userFlowInitialized) {
      userflow.identify(userId, {
        name: fullName,
        email,
        firstLogin,
        method,
        first_name: firstName,
        last_name: lastName,
        displayName: fullName,
        // this is a dedicated trait for our HubSpot integration
        app_user_id: userId,
        account_id: accountId,
        emailConnectionStatus,
        accountId,
        accountName,
        accountPlan,
        avatar,
        ...RudderStackAnalyticsAgent.getCampaign(),
        account_ats_connected: accountAtsConnected,
      }).then(() => {
        this.userFlowIdentified = true;
      });
    }

    return new Promise((resolve) => {
      if (!this.rudderStackAnalyticsAgent) {
        this.logger.warn(
          'Identify event was not sent, rudderjs not initialized',
          { userId, accountId, method },
        );
        resolve();

        return;
      }

      const options: rudderanalytics.apiOptions = {};
      if (intercomUserHash) {
        options.Intercom = {
          user_hash: intercomUserHash,
        };
      }

      this.rudderStackAnalyticsAgent?.identify(userId, {
        firstLogin,
        method,
        first_name: firstName,
        last_name: lastName,
        name: fullName,
        email,
        displayName: fullName,
        // this is a dedicated trait for our HubSpot integration
        app_user_id: userId,
        account_id: accountId,
        account_ats_connected: accountAtsConnected,
        emailConnectionStatus,
        company: {
          id: accountId,
          name: accountName,
          plan: accountPlan,
        },
        avatar,
        ...RudderStackAnalyticsAgent.getCampaign(),
      },
      options,
      () => {
        resolve();
      });
    });
  }

  public track = (trackPayload: RudderStackTrackEventPayload, destinations?: RudderStackDestination[]) => {
    if (this.userFlowIdentified) {
      userflow.track(trackPayload.eventName || '', {
        event_group: trackPayload.eventGroup,
        fail_reason: trackPayload.failReason,
        referral: trackPayload.referral,
        groupId: RudderStackAnalyticsAgent.getAccount(trackPayload)?.id,
        accountId: RudderStackAnalyticsAgent.getAccount(trackPayload)?.id,
        product_area: trackPayload.productArea,
        action_source_location: trackPayload.actionSourceLocation,
        event_type: EVENT_TYPE.USER_ACTION,
        session_tracking_id: trackPayload.sessionTrackingId,
        session: analyticsIdManager.getId(),
      });
    }

    if (!this.rudderStackAnalyticsAgent) {
      this.logger.warn(
        `Track event was not sent for the ${trackPayload.eventName} event,
        rudderjs not initialized`,
      );

      return;
    }

    let options: apiOptions;
    if (destinations) {
      options = {
        integrations: {
          All: false,
        },
      };

      // eslint-disable-next-line no-restricted-syntax
      for (const destination of destinations) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        options.integrations[destination] = true;
      }
    }

    this.rudderStackAnalyticsAgent?.track(
      trackPayload.eventName || '',
      {
        event_group: trackPayload.eventGroup,
        fail_reason: trackPayload.failReason,
        referral: trackPayload.referral,
        account: RudderStackAnalyticsAgent.getAccount(trackPayload),
        groupId: RudderStackAnalyticsAgent.getAccount(trackPayload)?.id,
        product_area: trackPayload.productArea,
        action_source_location: trackPayload.actionSourceLocation,
        user: RudderStackAnalyticsAgent.getUser(trackPayload),
        page: RudderStackAnalyticsAgent.getPage(trackPayload),
        event_type: EVENT_TYPE.USER_ACTION,
        session_tracking_id: trackPayload.sessionTrackingId,
        component: RudderStackAnalyticsAgent.getComponent(trackPayload),
        match: RudderStackAnalyticsAgent.getMatch(trackPayload),
        outreach: RudderStackAnalyticsAgent.getOutreach(trackPayload),
        session: analyticsIdManager.getId(),
        campaign: RudderStackAnalyticsAgent.getCampaign(),
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options,
    );
  }

  public page = (pageEventPayload: RudderStackPageEventPayload, destinations?: RudderStackDestination[]) => {
    if (!this.rudderStackAnalyticsAgent) {
      this.logger.warn(
        'Page event was not sent for the page event named: ' +
        `${pageEventPayload.pageName}, rudderjs not initialized`,
      );

      return;
    }

    let options: apiOptions;
    if (destinations) {
      options = {
        integrations: {
          All: false,
        },
      };

      // eslint-disable-next-line no-restricted-syntax
      for (const destination of destinations) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        options.integrations[destination] = true;
      }
    }

    this.rudderStackAnalyticsAgent?.page(
      pageEventPayload.pageName || '',
      {
        event_name: pageEventPayload.eventName,
        event_group: pageEventPayload.eventGroup,
        product_area: pageEventPayload.productArea,
        account: RudderStackAnalyticsAgent.getAccount(pageEventPayload),
        user: RudderStackAnalyticsAgent.getUser(pageEventPayload),
        event_type: EVENT_TYPE.USER_ACTION,
        session_tracking_id: pageEventPayload.sessionTrackingId,
        page: RudderStackAnalyticsAgent.getPage(pageEventPayload),
        match: RudderStackAnalyticsAgent.getMatch(pageEventPayload),
        outreach: RudderStackAnalyticsAgent.getOutreach(pageEventPayload),
        session: analyticsIdManager.getId(),
        campaign: RudderStackAnalyticsAgent.getCampaign(),
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options,
    );
  }

  public reset = () => {
    if (this.userFlowInitialized) {
      userflow.reset();
    }
    if (!this.rudderStackAnalyticsAgent) {
      this.logger.warn(
        'Reset event was not sent, rudderjs not initialized',
      );

      return;
    }

    this.rudderStackAnalyticsAgent?.reset(true);
    this.rudderStackAnalyticsAgent = undefined;
  }

  public group = (
    accountId: string,
    groupInfo: Record<string, string>,
  ) => {
    if (this.userFlowInitialized) {
      userflow.group(accountId, groupInfo);
    }

    if (!this.rudderStackAnalyticsAgent) {
      this.logger.warn(
        'Group event was not sent, rudderjs not initialized',
      );

      return;
    }
    this.rudderStackAnalyticsAgent?.group(accountId, groupInfo);
  }

  private static getPagePosition(
    eventPayload: RudderStackTrackEventPayload | RudderStackPageEventPayload,
  ): PositionOutputPayload | undefined  {
    if (!eventPayload.pagePositionId) {
      return undefined;
    }

    const ats = !eventPayload.pagePositionAtsName ? undefined : {
      name: eventPayload.pagePositionAtsName,
      is_automatic: eventPayload.pagePositionAtsIsAutomatic,
    };

    return {
      id: eventPayload.pagePositionId,
      name: eventPayload.pagePositionName,
      status: eventPayload.pagePositionStatus,
      ats,
    };
  }

  private static getPage(
    eventPayload: RudderStackTrackEventPayload | RudderStackPageEventPayload,
  ): PageOutputPayload {
    return {
      view_id: eventPayload.pageViewId,
      name: eventPayload.pageName,
      referrer_view_id: eventPayload.pageReferrerId,
      position: RudderStackAnalyticsAgent.getPagePosition(eventPayload),
      step_number: eventPayload.stepNumber,
    };
  }

  private static getAccount(
    eventPayload: RudderStackTrackEventPayload | RudderStackPageEventPayload,
  ): AccountOutputPayload {
    return {
      id: eventPayload.accountId,
      pricing_plan: eventPayload.accountPlan,
      status: eventPayload.companyStatus,
      company: RudderStackAnalyticsAgent.getAccountCompany(eventPayload),
      positions_quota: eventPayload.positionQuota,
    };
  }

  private static getAccountCompany(
    eventPayload: RudderStackTrackEventPayload,
  ): CompanyOutputPayload | undefined {
    // TODO: enrich in transformer...
    return {
      name: eventPayload.companyName,
    };
  }

  private static getUser(
    eventPayload: RudderStackTrackEventPayload | RudderStackPageEventPayload,
  ): UserOutputPayload {
    return {
      id: eventPayload.userId,
      type: eventPayload.userType,
      permission: eventPayload.userPermission,
    };
  }

  private static getTalent(
    eventPayload: RudderStackTrackEventPayload | RudderStackPageEventPayload,
  ): TalentOutputPayload | undefined {
    if (!eventPayload.matchTalentId) {
      return undefined;
    }

    return {
      id: eventPayload.matchTalentId,
    };
  }

  private static getMatchEngagement(
    eventPayload: RudderStackTrackEventPayload | RudderStackPageEventPayload,
  ): EngagementOutputPayload | undefined {
    if (!eventPayload.matchTalentId) {
      return undefined;
    }

    return {
      status: eventPayload.matchEngagementStatus,
    };
  }

  private static getMatchExperienceCompany(
    eventPayload: RudderStackTrackEventPayload,
  ): CompanyOutputPayload | undefined {
    if (!eventPayload.matchExperienceCompanyId) {
      return undefined;
    }

    return {
      id: eventPayload.matchExperienceCompanyId,
      linkedin_url: eventPayload.matchExperienceCompanyName,
      name: eventPayload.matchExperienceCompanyLinkedinURL,
    };
  }

  private static getMatchExperience(
    eventPayload: RudderStackTrackEventPayload,
  ): ExperienceOutputPayload | undefined {
    return {
      company: RudderStackAnalyticsAgent.getMatchExperienceCompany(eventPayload),
      period: eventPayload.matchExperiencePeriod,
    };
  }

  private static getMatch(
    eventPayload: RudderStackTrackEventPayload | RudderStackPageEventPayload,
  ): MatchOutputPayload | undefined  {
    if (!eventPayload.matchId) {
      return undefined;
    }

    return {
      id: eventPayload.matchId,
      talent: RudderStackAnalyticsAgent.getTalent(eventPayload),
      status: eventPayload.matchStatus,
      engagement: RudderStackAnalyticsAgent.getMatchEngagement(eventPayload),
      experience: RudderStackAnalyticsAgent.getMatchExperience(eventPayload),
    };
  }

  private static getOutreach(
    eventPayload: RudderStackTrackEventPayload | RudderStackPageEventPayload,
  ): OutreachOutputPayload | undefined  {
    if (!eventPayload.conversationId) {
      return undefined;
    }

    return {
      conversationId: eventPayload.conversationId,
    };
  }

  private static getComponentPosition(
    eventPayload: RudderStackTrackEventPayload,
  ): PositionOutputPayload | undefined  {
    if (!eventPayload.pagePositionId) {
      return undefined;
    }

    return {
      id: eventPayload.componentPositionId,
      name: eventPayload.componentPositionName,
      status: eventPayload.componentPositionStatus,
    };
  }

  private static getComponentLocation(
    eventPayload: RudderStackTrackEventPayload,
  ): LocationEventPayload | undefined {
    if (!eventPayload.componentLocation) {
      return undefined;
    }

    return {
      city: eventPayload.componentLocation?.city,
      state: eventPayload.componentLocation?.state,
      country: eventPayload.componentLocation?.country,
      region: eventPayload.componentLocation?.region,
    };
  }

  private static getComponentCompany(
    eventPayload: RudderStackTrackEventPayload,
  ): CompanyOutputPayload | undefined {
    if (!eventPayload.componentCompany?.id) {
      return undefined;
    }

    return {
      id: eventPayload.componentCompany.id,
      linkedin_url: eventPayload.componentCompany.sanitizedLinkedinUrl,
      name: eventPayload.componentCompany.name,
    };
  }

  private static getComponentElement(
    eventPayload: RudderStackTrackEventPayload,
  ): ComponentElementOutputPayload | undefined {
    if (!eventPayload.componentElementType) {
      return undefined;
    }

    return {
      type: eventPayload.componentElementType,
    };
  }

  private static getComponent(
    eventPayload: RudderStackTrackEventPayload,
  ): ComponentOutputPayload {
    return {
      name: eventPayload.componentName,
      parent: eventPayload.componentParentName,
      location_in_parent: eventPayload.componentPositionInParent,
      element: RudderStackAnalyticsAgent.getComponentElement(eventPayload),
      position: RudderStackAnalyticsAgent.getComponentPosition(eventPayload),
      metadata: eventPayload.componentMetadata && String(eventPayload.componentMetadata),
      metadata_struct: eventPayload.componentMetadataStruct,
      metadata_type: eventPayload.componentMetadataType,
      metadata_location: RudderStackAnalyticsAgent.getComponentLocation(eventPayload),
      company: RudderStackAnalyticsAgent.getComponentCompany(eventPayload),
      status: eventPayload.componentStatus,
    };
  }

  private static getCampaign(): CampaignOutputPayload {
    return UtmTrackingService.getAllUTMParamFromCookies();
  }
}

export default new RudderStackAnalyticsAgent();
