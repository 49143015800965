import styled from 'styled-components';
import { CaptionFont, Subtitle1Font } from '../../../../../../themes/fonts';
import { THEME } from '../../../../../../themes';
import Meter
  from '../../../../../../components/match/match-content/smart-widgets/move-meter-widget/meter/meter.component';
import { FLUENT_UI_EMOJIS } from '../../../../../../assets';
import { HorizontalDivider } from '../../../../../../components/Common/horizontal-divider/horizontal-divider.component';

export const Container = styled.div`
  width: 100%;
`;

export const StyledMeter = styled(Meter)`
  margin-top: ${THEME.SPACING.TINO};
  justify-content: space-between;
`;

export const Title = styled.div<{ color?: string }>`
  ${Subtitle1Font};
  font-weight: 700;
  color: ${({ color }) => color || THEME.COLORS.TRUE_BLACK};
`;

export const InfoText = styled.div`
  color: ${THEME.COLORS.CONTENT_A};
  ${CaptionFont};
  margin-top: ${THEME.SPACING.BASE};
`;

export const Icon = styled(FLUENT_UI_EMOJIS.RUNNING_SHOE)``;

export const StyledDivider = styled(HorizontalDivider)`
  height: 4px;
  margin: ${THEME.SPACING.TINO} 0;
`;
