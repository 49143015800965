import { useEffect } from 'react';
import { ANALYTICS_EVENTS, EVENT_GROUP, EVENT_TYPE } from '../../../../consts/analytics';
import { RudderStackDestination } from '../../../../services/rudderStackAnalyticsAgent';
import {
  CREATE_POSITION_ANALYTICS_EVENTS,
  PAGE_NAMES,
} from '../../../create-position/consts/position-analytics.consts';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { MatchingMakingTransitionScreen } from '../../../../components/Common/matching-making-transition/matching-making-transition.component';
import { useMyUserSelector } from '../../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { useGetMyAccountSelector } from '../../../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import { useGetMyUserQuery } from '../../../../store/rtk-query/apis/user/hooks/get-my-user.query-hook';
import { useGetPositionsSelector } from '../../../../store/rtk-query/apis/position/selectors/get-positions.selector';
import { replaceSubDomain } from '../../../../utils/url';
import { CONFIG } from '../../../../config';
import { AppRouting } from '../../../../services/appRoutingResolver';
import { useGetOpenPositionsQuery } from '../../../../store/rtk-query/apis/position/hooks/get-open-positions.query-hook';
import { ONBOARDING_ANALYTICS_EVENTS } from '../../consts/onboarding-analytics.const';
import { accountQuery } from '../../../../store/rtk-query/apis/account/account.toolkit-api';

export const AllDoneTransitionScreen = (): JSX.Element => {
  const { analyticsPageEvent, analyticsTrackEvent } = useAnalyticsContext();
  const { data: { subDomainName } } = useGetMyAccountSelector();
  const { data: positions } = useGetPositionsSelector();
  const { data: { isOnboarding: isUserInOnboardingState, accountId } } = useMyUserSelector();
  const [getAccount] = accountQuery.useLazyGetAccountByIdQuery();
  useGetMyUserQuery({ pollingIntervalInMs: isUserInOnboardingState ? 3000 : undefined });
  useGetOpenPositionsQuery(isUserInOnboardingState);

  useEffect(() => {
    if (!isUserInOnboardingState && subDomainName && positions?.length) {
      const redirectUrl =
        replaceSubDomain(window.location.origin, CONFIG.ONBOARDING_SUBDOMAIN, subDomainName);
      AppRouting.navigateToUrl(`${redirectUrl}${AppRouting.buildMatchesPageUrl(positions[0].id)}`);
    }
  }, [isUserInOnboardingState, subDomainName, positions]);

  useEffect(() => {
    if (!isUserInOnboardingState) {
      getAccount(accountId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserInOnboardingState]);

  useEffect(() => {
    if (accountId) {
      analyticsPageEvent({
        eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_FINISH_PAGE_VIEWED,
        eventGroup: EVENT_GROUP.PAGE_VIEW,
        pageName: PAGE_NAMES.FINISHED_ONBOARDING,
      });

      const intercomTrackEventPayload = {
        eventName: ANALYTICS_EVENTS.INTERCOM_TRIGGER_ONBOARDING_COMPLETED,
        event_type: EVENT_TYPE.INTERCOM_TRIGGER,
        pageName: PAGE_NAMES.FINISHED_ONBOARDING,
      };
      // send only to the INTERCOM integration
      analyticsTrackEvent(intercomTrackEventPayload, [RudderStackDestination.INTERCOM]);

      const trackEventPayload = {
        eventName: ONBOARDING_ANALYTICS_EVENTS.ONBOARDING_COMPLETED,
        eventGroup: EVENT_GROUP.PAGE_VIEW,
        pageName: PAGE_NAMES.FINISHED_ONBOARDING,
      };

      analyticsTrackEvent(trackEventPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  return (
    <MatchingMakingTransitionScreen />
  );
};
