import i18n from 'i18next';
import { format } from 'date-fns';
import { ORDER } from '../consts';
import { TimeOfDay } from '../enums/date.enum';
import { CONFIG } from '../config';
import { DateObject } from '../@types/match';
import STRING_KEYS from '../language/keys';

const TOTAL_MONTHS_IN_YEAR = 12;

export const getMonthsDiff = (startDate: DateObject, endDate: DateObject): number => {
  const yearsDiff = endDate.year - startDate.year;

  // https://talentfabric.atlassian.net/browse/APP-499?focusedCommentId=12353
  // adding 1 = inclusive calculaation (like Linkedin)
  return ((12 * yearsDiff) + endDate.month - startDate.month) + 1;
};

/**
 * Calculate total years & monthes diff between 2 dates,
 * and return a string representing this diff
 * @param startDate string
 * @param endDate string
 * @returns string
 */
export const getYearsAndMonthsDiffString = (
  startDate: DateObject,
  endDate?: DateObject | null): string => {
  let month: number;

  if (endDate) {
    month = getMonthsDiff(startDate, endDate);
  } else {
    const today = new Date();

    month = getMonthsDiff(
      startDate,
      {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
      },
    );
  }

  const years = Math.floor(month / 12);
  const months = month % 12;

  let yearsString = '';
  let monthsString = '';

  if (years > 1) {
    yearsString = `${years} years`;
  } else if (years === 1) {
    yearsString = '1 year';
  }

  if (months > 1) {
    monthsString = `${months} mos`;
  } else if (months === 1) {
    monthsString = '1 month';
  }

  return `${yearsString} ${monthsString}`.trim();
};

/**
 * Convert date to a dots-format like: 15.1.2021
 * @param date Date
 * @returns string - formatted date
 */
export const formatDateWithDots = (date: Date): string | null => {
  if (!date) {
    return null;
  }

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear().toString().substr(-2);

  return `${day}.${month}.${year}`;
};

/**
 * Compare 2 dates for sorting function
 * @param dateA string format of the first date
 * @param dateB string format of the second date
 * @param sorting order ASC or DESC
 * @returns number representing the diff between the dates (in miliseconds)
 */
export const compareDates = (dateA: string, dateB: string, sorting?: ORDER): number => {
  const timeA = new Date(dateA).getTime();
  const timeB = new Date(dateB).getTime();

  if (sorting === ORDER.ASCENDING) {
    // ASCENDING
    return timeA - timeB;
  }

  // DESCENDING
  return timeB - timeA;
};

/**
 * Compare endDate and today
 * @param date object with type Plan which contains endDate, startDate, isExpired e.t.c
 * @returns return differrence of number
 */

export const diffInDays = (date: Date | null):number | null => {
  if (!date) {
    return null;
  }
  const oneDay  = 24 * 60 * 60 * 1000;
  const toDay = new Date().valueOf();
  const endDate = new Date(date).valueOf();
  const diffDays = Math.abs(endDate - toDay);

  return Math.round(diffDays / oneDay);
};

export const diffInHours = (date1: Date, date2: Date): number => {
  const hour = (60 * 60 * 1000);
  const msBetweenDates = date1.getTime() - date2.getTime();

  return msBetweenDates / hour;
};

export const convertMonthsToYears = (month: number):number => {
  return Number((month / TOTAL_MONTHS_IN_YEAR).toFixed(1));
};

/**
 * Calculate total months diff between 2 dates,
 * and return a string representing this diff
 * @param startDate string
 * @param endDate string
 * @returns string
 */
export const getMonthsDifference = (startDate: DateObject, endDate?: DateObject | null): string => {
  if (!startDate) {
    return '';
  }

  let months: number;

  if (endDate) {
    months = getMonthsDiff(startDate, endDate);
  } else {
    const today = new Date();

    months = getMonthsDiff(
      startDate,
      {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
      },
    );
  }

  let monthsString = '';

  if (months > 1) {
    monthsString = `${months} mos`;
  } else if (months === 1) {
    monthsString = '1 month';
  }

  return `${monthsString}`.trim();
};

/**
 * Check if current hour between range start time and range end time
 * @param currentHour  number,
 * @param rangeStartTime number
 * @param rangeEndTime number
 * @returns boolean
 */
const checkTimeRange = (
  currentHour: number,
  rangeStartTime: number,
  rangeEndTime: number): boolean => {
  return currentHour >= rangeStartTime && currentHour < rangeEndTime;
};

export const currentTimeOfDayText = (): TimeOfDay => {
  const now = new Date();
  const currentHour = now.getHours();
  const {
    MORNING,
    AFTERNOON,
  } = CONFIG.TIME_PERIOD;

  if (checkTimeRange(currentHour, MORNING.START, MORNING.END)) {
    return TimeOfDay.Morning;
  }
  if (checkTimeRange(currentHour, AFTERNOON.START, AFTERNOON.END)) {
    return TimeOfDay.Afternoon;
  }

  return TimeOfDay.Evening;
};

export const todayTomorrowOrFormatDate = (date: Date, formatString = 'dd.MM.yy'): string => {
  const currentDate = new Date();

  if (date.toDateString() === currentDate.toDateString()) {
    return i18n.t(STRING_KEYS.TODAY);
  }
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);

  if (date.toDateString() === tomorrow.toDateString()) {
    return i18n.t(STRING_KEYS.TOMORROW);
  }

  return format(date, formatString);
};
