import { Trans, useTranslation } from 'react-i18next';
import {
  browserName, isChrome, isEdge, isFirefox,
} from 'react-device-detect';
import { CircularProgress } from '@mui/material';
import { useState } from 'react';
import Dialog from '../base-dialog/base-dialog.component';
import STRING_KEYS from '../../../language/keys';
import { OAuthLoginIframeContainer } from '../../oauth-frames/iframe-container/oauth-iframe-container.component';
import { CONFIG } from '../../../config';
import { MicrosoftLogo } from '../../oauth-frames/microsoft-email-oauth-frame/microsoft-login/microsoft-login.styled';
import { GoogleLogo } from '../../oauth-frames/google-email-oauth-frame/google-login/google-login.styled';
import { DialogWidthSize } from '../base-dialog/base-dialog.enums';
import BaseSwitch from '../../Common/base-switch/base-switch.component';
import { THEME } from '../../../themes';
import { BaseSwitchSize } from '../../Common/base-switch/base-switch.enums';
import {
  BodyContainer,
  ButtonsContainer, CheckedIcon,
  Description,
  HeaderContainer,
  HeaderIcon, ReadPermissionsContainer, ReadPermissionsInfoText,
  TextContainer,
  Title, UnCheckedIcon,
} from './outreach-initialization-dialog.styled';

export const OutreachInitializationDialog = ({
  closeDialogHandler,
  googleSuccessHandler,
  microsoftSuccessHandler,
  microsoftErrorHandler,
  isDialogOpen,
  isCreateEmailSenderLoading,
}: OutreachInitializationDialogProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [readPermissionsApproved, setReadPermissionsApproved] = useState<boolean>(true);

  const onCloseDialogHandler = () => {
    closeDialogHandler();
  };
  
  const doesBrowserSupportIntegration = isChrome || isEdge || isFirefox || browserName === 'Brave';

  return (

    <Dialog
      isOpen={ isDialogOpen }
      onClose={ onCloseDialogHandler }
      widthSize={ DialogWidthSize.Large }
    >
      <HeaderContainer>
        <HeaderIcon />
      </HeaderContainer>
      <BodyContainer>
        <TextContainer>
          <Title>{translate(STRING_KEYS.OUTREACH_INITIALIZATION_DIALOG.TITLE)}</Title>
          <Description>
            {
              doesBrowserSupportIntegration ?
                translate(STRING_KEYS.OUTREACH_INITIALIZATION_DIALOG.DESCRIPTION) :
                (
                  <Trans
                    defaults={ STRING_KEYS.OUTREACH_INITIALIZATION_DIALOG.BROWSER_COMPATIBILITY }
                    components={ { bold: <strong /> } }
                  />
                )
            }
          </Description>
        </TextContainer>
        <>
          {doesBrowserSupportIntegration && (
            <ButtonsContainer>
              { isCreateEmailSenderLoading && <CircularProgress size="32px" /> }
              { !isCreateEmailSenderLoading && (
                <>
                  <OAuthLoginIframeContainer
                    successHandler={ googleSuccessHandler }
                    iframeSrc={ CONFIG.GOOGLE_EMAIL_OAUTH_URL }
                    buttonIcon={ <GoogleLogo /> }
                    buttonText={ translate(STRING_KEYS.CONTINUE_WITH_GOOGLE) }
                    readPermissionsApproved={ readPermissionsApproved }
                  />
                  <OAuthLoginIframeContainer
                    successHandler={ microsoftSuccessHandler }
                    errorHandler={ microsoftErrorHandler }
                    iframeSrc={ CONFIG.MICROSOFT_EMAIL_OAUTH_URL }
                    buttonIcon={ <MicrosoftLogo /> }
                    buttonText={ translate(STRING_KEYS.CONTINUE_WITH_MICROSOFT) }
                    readPermissionsApproved={ readPermissionsApproved }
                  />
                  <ReadPermissionsContainer>
                    <BaseSwitch
                      checked={ readPermissionsApproved }
                      checkedColor={ THEME.COLORS.ACCENT }
                      switchChangeHandler={ setReadPermissionsApproved }
                      size={ BaseSwitchSize.Small }
                      CheckedIcon={ <CheckedIcon /> }
                      UnCheckedIcon={ <UnCheckedIcon /> }
                    />
                    <ReadPermissionsInfoText>
                      <Trans i18nKey={ STRING_KEYS.OUTREACH_INITIALIZATION_DIALOG.READ_PERMISSIONS_INFO_TEXT } />
                    </ReadPermissionsInfoText>
                  </ReadPermissionsContainer>
                </>
              ) }
            </ButtonsContainer>
          )}
        </>
      </BodyContainer>
    </Dialog>
  );
};
