import { useAppSelector } from '../../../../selectors';
import { onboardingQuery } from '../onboarding.toolkit-api';

export const getAllowedCompaniesSelector = onboardingQuery.endpoints.getAllowedCompanies.select;

export const useGetAllowedCompaniesSelector = (): AppSelectorType<OnboardingAllowedCompanyResponse> => {
  const {
    data: companies, isLoading, isError, isSuccess, error,
  } = useAppSelector(getAllowedCompaniesSelector());

  return {
    data: companies || {} as OnboardingAllowedCompanyResponse,
    error,
    isLoading,
    isError,
    isSuccess,
  };
};
