import styled from 'styled-components';
import { THEME } from '../../../../themes';
import BaseButton from '../../../Common/Buttons/perfect-base-button/perfect-base-button.component';
import { hexToRgbA } from '../../../../utils/colors';
import { StartIconContainer } from '../../../Common/Buttons/perfect-base-button/perfect-base-button.styled';
import { ASSETS } from '../../../../assets';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const InputWrapper = styled.div`
  margin-top: ${THEME.SPACING.BASE};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINO};
  justify-content: center;
  margin-top: ${THEME.SPACING.MEDIUM};
`;

export const ConfirmButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.ACCENT};
  width: 130px;
`;

export const BackButton = styled(BaseButton)`
  background-color: transparent;
  color: ${THEME.COLORS.PRIMARY_DARK};
  border-color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
  padding: 0;
  width: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
  
  & ${StartIconContainer} {
    margin: 0;
  }
`;

export const BackButtonIcon = styled(ASSETS.ARROW_BACK_COMPONENT)`
  transform: rotate(180deg);
  width: 17px;
`;

export const ContactCsLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
