import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { OnboardingState } from '../onboarding-state.initial-state';

export const setOnboardingContinueButtonEnableReducer: CaseReducer<OnboardingState, PayloadAction<undefined>> = (state) => {
  state.isContinueButtonEnabled = true;
};

export const setOnboardingContinueButtonDisableReducer: CaseReducer<OnboardingState, PayloadAction<undefined>> = (state) => {
  state.isContinueButtonEnabled = false;
};

export const setOnboardingContinueButtonHideReducer: CaseReducer<OnboardingState, PayloadAction<undefined>> = (state) => {
  state.showContinueButtonShow = false;
};

export const setOnboardingContinueButtonShowReducer: CaseReducer<OnboardingState, PayloadAction<undefined>> = (state) => {
  state.showContinueButtonShow = true;
};

export const setOnboardingSkipButtonHideReducer: CaseReducer<OnboardingState, PayloadAction<undefined>> = (state) => {
  state.showSkipButtonShow = false;
};

export const setOnboardingSkipButtonShowReducer: CaseReducer<OnboardingState, PayloadAction<undefined>> = (state) => {
  state.showSkipButtonShow = true;
};

export const setOnboardingLoaderButtonShowReducer: CaseReducer<OnboardingState, PayloadAction<undefined>> = (state) => {
  state.showButtonLoaderShow = true;
};

export const setOnboardingLoaderButtonHideReducer: CaseReducer<OnboardingState, PayloadAction<undefined>> = (state) => {
  state.showButtonLoaderShow = false;
};
