import log from 'loglevel';
import { useEffect, useState } from 'react';
import { CodeResponse, GoogleOAuthProvider } from '@react-oauth/google';
import { CONFIG } from '../../../config';
import { extractDomainFromUrl } from '../../../utils';
import { GoogleLogin } from './google-login/google-login.component';

const logger = log.getLogger('GOOGLE_EMAIL_OAUTH_FRAME');

// this component is used within iframe!
export const GoogleEmailOAuthFrame = (): JSX.Element | null => {
  const [parentOrigin, setParentOrigin] = useState<string | null>(null);
  const searchParams = new URLSearchParams(window.location.search);
  const readPermissionsApproved = searchParams.get('readPermissionsApproved') || true;

  const handleNewMessage = (message: MessageEvent) => {
    if (message.type !== 'message') {
      logger.debug('skipping new message of type', message.type);

      return;
    }

    const senderOrigin = extractDomainFromUrl(message.origin);

    if (senderOrigin !== CONFIG.URL_DOMAIN) {
      logger.debug(`ignoring messages of unknown origins. senderOrigin: ${senderOrigin}`);

      return;
    }

    // todo: skip messages by origin (when servers are ready)
    if (!message.data.parentOrigin) {
      logger.debug('skipping message without expected data', { message });

      return;
    }

    logger.debug('got new message with data', { message });

    setParentOrigin(message.data.parentOrigin);
  };

  useEffect(() => {
    window.addEventListener('message', handleNewMessage);

    return () => {
      window.removeEventListener('message', handleNewMessage);
    };
  }, []);

  if (!CONFIG.OUTREACH_GOOGLE_CLIENT_ID) {
    return null;
  }

  if (!parentOrigin) {
    return null;
  }

  const onGoogleLoginSuccess = (codeResponse: CodeResponse) => {
    if (parentOrigin) {
      window.parent.postMessage({ code: codeResponse.code }, parentOrigin);
    }
  };

  const onGoogleLoginError = (error: unknown) => {
    window.parent.postMessage({ error }, parentOrigin);
  };

  return (
    <GoogleOAuthProvider clientId={ CONFIG.OUTREACH_GOOGLE_CLIENT_ID }>
      <GoogleLogin successHandler={ onGoogleLoginSuccess } errorHandler={ onGoogleLoginError } readPermissionsApproved={ readPermissionsApproved !== 'false' } />
    </GoogleOAuthProvider>
  );
};
