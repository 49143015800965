import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { HeadlineFont } from '../../../../themes/fonts';

export const SkipButton = styled.div<{isDisabled: boolean}>`
    ${HeadlineFont};
    font-weight: normal;
    color: ${THEME.COLORS.ACCENT};
    display: inline-block;
    margin-left: ${THEME.SPACING.BASE};
    cursor: pointer;
    opacity: ${({ isDisabled }) => isDisabled ? THEME.OPACITY.LOW : THEME.OPACITY.HIGHEST};
    pointer-events: ${({ isDisabled }) => isDisabled ? 'none' : 'all'};

    :before {
      content: '▶▶';
      letter-spacing: -6px;
      margin-right: ${THEME.SPACING.TINO};
    }
  `;
