import { outreachQuery } from '../outreach.toolkit-api';
import { SendMessageQueryArguments } from '../dto/query-arguments/send-message-query.arguments';
import { MessageResponseDto } from '../dto/response/message.response';

type UseSendMessageMutationType = [
  (request: SendMessageQueryArguments) => void,
  AppSelectorType<MessageResponseDto | undefined>
];

export const useSendMessageMutation = (): UseSendMessageMutationType => {
  const [
    sendMessage,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = outreachQuery.useSendMessageMutation();

  const meta = {
    isLoading, isError, error, isSuccess, data, resetState: reset,
  };

  return [
    sendMessage,
    { ...meta },
  ];
};
