import {
  useSocialLoginActions,
  useSocialLoginState,
} from '@frontegg/react';
import { SocialLoginProviders } from '@frontegg/rest-api';
import log from 'loglevel';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SIGNUP_ERROR_CODE } from '../../consts';
import { AppLoader } from '../Common/Loaders/AppLoader';
import { AppRouting } from '../../services/appRoutingResolver';
import { setUserIdentifyMethod } from '../../store/slices/app-state/app-state.toolkit-slice';
import { UserIdentifyMethod } from '../../store/slices/app-state/app-state.enums';

const logger = log.getLogger('SOCIAL_SUCCESS_SCREEN');

const SOCIAL_LOGIN_ERRORS_RESPONSES = {
  WORK_EMAIL: 'Please sign in with your work email.',
};

function AccountSocialSuccessScreen(): JSX.Element {
  const { error: socialLoginError } = useSocialLoginState();
  const { loginViaSocialLogin, setSocialLoginError } = useSocialLoginActions();

  const dispatch = useDispatch();

  const params = new URLSearchParams(window.location.search);
  const code = params.get('code') || '';

  useEffect(() => {
    if (!code) {
      logger.warn('invalid code parameter in url. cannot continue with social login');
      setSocialLoginError({ error: 'Social Login failed' });

      return;
    }

    // todo: in the future - support more providers
    const payload = {
      code,
      provider: SocialLoginProviders.Google,
      redirectUri: AppRouting.buildSocialLoginSuccessUrl(window.location),
    };

    dispatch(setUserIdentifyMethod(UserIdentifyMethod.Google));

    loginViaSocialLogin(payload);
  }, [code, dispatch, loginViaSocialLogin, setSocialLoginError]);

  // --------------------
  // Render
  // --------------------

  if (!code) {
    logger.debug('no code provided, navigating to auth page');
    AppRouting.navigateToUrl(AppRouting.buildGlobalLoginUrl(window.location));
  } else if (socialLoginError) {
    let errorCode = SIGNUP_ERROR_CODE.GOOGLE_LOGIN_FAILED;

    if (socialLoginError === SOCIAL_LOGIN_ERRORS_RESPONSES.WORK_EMAIL) {
      errorCode = SIGNUP_ERROR_CODE.WORK_EMAIL;
    }

    logger.warn(
      'failed to login with SSO. navigating the user to signup screen. frontegg error:',
      socialLoginError,
      '. signup internal app error code:',
      errorCode,
    );

    AppRouting.navigateToUrl(AppRouting.buildGlobalOnboardingUrl(window.location, errorCode));
  }

  return <AppLoader title="Social Login" />;
}

export default AccountSocialSuccessScreen;
