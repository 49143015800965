import styled, { css } from 'styled-components';
import { hexToRgbA } from '../../../utils/colors';
import { THEME } from '../../../themes';

export const Container = styled.div`
  display: flex;
  gap: ${THEME.SPACING.MICRO};
`;

const StepBase = css`
  width: 8px;
  height: 4px;
  border-radius: ${THEME.RADIUS.NANO};
`;

export const EmptyStep = styled.div`
  ${StepBase};
  background-color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
`;

export const CompletedStep = styled.div`
  ${StepBase};
  display: flex;
  background-color: ${THEME.COLORS.PRIMARY_DARK};
  justify-content: center;
  align-items: center;
`;
