import { ApiError } from '../../../../../consts/api';
import { matchQuery } from '../match.toolkit-api';

type UseSetMatchAsViewedActionResponseType = [
  (positionId: string, matchId: string, userId: string) => void,
  AppSelectorType<void, ApiError>
];

export const useSetMatchAsViewed = (): UseSetMatchAsViewedActionResponseType => {
  const [
    setMatchAsViewed,
    {
      data, isLoading, isError, error, isSuccess,
    }] = matchQuery.useSetMatchAsViewedMutation();

  const meta = {
    isLoading, isError, error: error as ApiError, isSuccess, data,
  };

  return [
    (positionId: string, matchId: string, userId: string) => setMatchAsViewed({ positionId, matchId, userId }),
    { ...meta },
  ];
};
