import styled  from 'styled-components';
import { HEADER_HEIGHT } from '../../../../components/Common/headers/header/header.styled';

export const LayoutContainer = styled.div`
  height: 100vh;
  position: relative;
  padding-top: ${HEADER_HEIGHT}px;
`;

export const Body = styled.div`
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  flex-direction: column;
`;

export const LayoutHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const SelectionGroupContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
