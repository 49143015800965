/* eslint-disable react/jsx-props-no-spreading */
import React, {
  Children, Dispatch, SetStateAction, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputStyle } from '../../../modules/onboarding/components/input/input.component';
import { scrollToElementById } from '../../../utils';
import useClickOutsideListener from '../../../hooks/use-click-outside-listener.hooks';
import { DotsLoaderVariation } from '../Loaders/dots-loader/dots-loader.enums';
import {
  Container,
  EndAdornmentContainer,
  InputWrapper,
  InstructionsMessageWrapper,
  LoadingIndicatorWrapper,
  StyledDotsLoader,
  StyledOptionList,
} from './autocomplete.styled';
import { CustomMessage } from './custom-message/custom-message.component';
import { ErrorMessage } from './error-message/error-message.component';

enum ItemTriggerEnum  {
  Mouse = 'mouse',
  Arrow = 'arrow',
}

type AutocompleteProps = {
  inputElementId?: string;
  isLoading: boolean,
  showLoadingIndication?: boolean;
  optionsTitle?: string,
  placeholder: string,
  label?: React.ReactNode,
  children?: JSX.Element | JSX.Element[],
  inputValue: string,
  isDisableOptions?: boolean,
  onValueChange: (value: string, index?:number) => void,
  onEnterClick?: (value: string, index?:number) => void,
  isInputFocused?: boolean,
  setInputFocused?: Dispatch<SetStateAction<boolean>>,
  inputStyle?: InputStyle,
  onBlur?: () => void,
  onFocus?: () => void,
  isDisableTyping?: boolean,
  autoFocus?: boolean,
  isCustomMessage?: boolean,
  isError?: boolean,
  errorMessage?: string,
  InstructionsMessage?: JSX.Element | null,
  StartAdornment?: JSX.Element,
  EndAdornment?: JSX.Element,
  alternativeEnterChars?: string[],
  alternativeEnterCharClickHandler?: () => void,
  menuOffset?: [number, number];
}

type ActiveItemType = {
  id: number,
  trigger: ItemTriggerEnum,
}

export const Autocomplete = ({
  inputElementId,
  isLoading,
  showLoadingIndication,
  optionsTitle,
  children,
  placeholder,
  label,
  inputValue,
  onValueChange,
  isDisableOptions,
  isInputFocused,
  inputStyle = InputStyle.Regular,
  setInputFocused,
  onBlur,
  onFocus,
  isDisableTyping = false,
  autoFocus = true,
  isCustomMessage = false,
  isError = false,
  errorMessage = '',
  InstructionsMessage = null,
  StartAdornment,
  EndAdornment,
  onEnterClick,
  alternativeEnterChars,
  alternativeEnterCharClickHandler,
  menuOffset,
  ...other
}: AutocompleteProps): JSX.Element => {
  const  { t: translate } = useTranslation();
  const [isOpenMenu, setOpenMenu] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<ActiveItemType | null>(null);
  const ref = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const childrenLength = Children.count(children);

  const handleInputBlur = () => {
    setInputFocused?.(false);
    if (onBlur) {
      onBlur();
    }

    if (inputRef.current) {
      inputRef.current.blur(); // Directly blur input without state dependencies
    }
  };

  useClickOutsideListener(
    handleInputBlur,
    ref.current as HTMLElement,
  );

  useEffect(() => {
    if (activeItem != null && activeItem.trigger === 'arrow') {
      scrollToElementById(activeItem.id);
    }
  }, [activeItem]);

  const closeOptionList = () => {
    setActiveItem(null);
    setOpenMenu(false);
    setInputFocused?.(false);
    if (inputRef.current) {
      inputRef.current.blur(); // Directly blur input without state dependencies
    }
  };

  const openOptionList = () => {
    setOpenMenu(true);
  };

  const handleEnterClick = () => {
    onEnterClick?.(inputValue, activeItem?.id);
    if (activeItem !== null) {
      onValueChange('', activeItem.id);
    }
    handleInputBlur();
    if (isInputFocused) {
      closeOptionList();
    }
  };

  const handleArrowDown = () => {
    setActiveItem((item) => {
      if (item !== null) {
        const id = item.id >= childrenLength - 1 ? 0 : item.id + 1;

        return {
          id,
          trigger: ItemTriggerEnum.Arrow,
        };
      }

      return {
        id: 0,
        trigger: ItemTriggerEnum.Arrow,
      };
    });
  };

  const handleArrowUp = () => {
    setActiveItem((item) => {
      if (item?.id === 0) {
        return {
          id: childrenLength - 1,
          trigger: ItemTriggerEnum.Arrow,
        };
      }

      if (item !== null) {
        return {
          id: item.id - 1,
          trigger: ItemTriggerEnum.Arrow,
        };
      }

      return item;
    });
  };

  const handleInputFocus = () => {
    setInputFocused?.(true);
    openOptionList();
    onFocus?.();
  };

  const isValueEmpty = !inputValue || (inputValue && inputValue.trim().length === 0);

  const defineOptionsList = (): JSX.Element | null => {
    return (
      <StyledOptionList
        inputRef={ ref }
        onClick={ closeOptionList }
        optionsTitle={ optionsTitle || '' }
        activeIndex={ activeItem?.id || activeItem?.id === 0 ? activeItem?.id : null }
        onMouseEnter={ (index: number) => setActiveItem({
          id: index,
          trigger: ItemTriggerEnum.Mouse,
        }) }
        onMouseLeave={ () => setActiveItem(null) }
        inputStyle={ inputStyle }
        closeHandler={ () => {
          closeOptionList();
        } }
        menuOffset={ menuOffset }
      >
        { children }
      </StyledOptionList>
    );
  };

  const defineCustomMessage = (): JSX.Element | null => {
    return (
      <CustomMessage>
        { children }
      </CustomMessage>
    );
  };

  const defineContent = () => {
    if (!isDisableOptions && isOpenMenu && !isLoading) {
      if (isCustomMessage) {
        return defineCustomMessage();
      }

      return defineOptionsList();
    }

    return null;
  };

  const defineLoadingIndication = () => {
    return (
      <LoadingIndicatorWrapper isLoading={ isLoading }>
        <StyledDotsLoader variation={ DotsLoaderVariation.Typing } />
      </LoadingIndicatorWrapper>
    );
  };

  const defineInstructionsMessage = () => {
    if (isInputFocused && InstructionsMessage) {
      return <InstructionsMessageWrapper>{ InstructionsMessage }</InstructionsMessageWrapper>;
    }

    return null;
  };

  const renderEndAdornment = () => {
    const loadingIndication = showLoadingIndication && isInputFocused && !isDisableOptions && defineLoadingIndication();
    if (!loadingIndication && !EndAdornment) {
      return undefined;
    }

    return (
      <EndAdornmentContainer>
        { loadingIndication }
        { EndAdornment }
      </EndAdornmentContainer>
    );
  };

  return (
    <Container
      ref={ ref }
      isInputFocused={ !!isInputFocused }
      isValueEmpty={ isValueEmpty }
      isError={ isError }
      errorMessage={ errorMessage }
      { ...other }
    >
      <InputWrapper
        className="input-wrapper"
      >
        <Input
          id={ inputElementId }
          isError={ isError }
          autoFocus={ autoFocus }
          placeholder={ translate(placeholder) }
          label={ label }
          value={ isDisableTyping ? inputValue || translate(placeholder) : inputValue }
          style={ inputStyle }
          onValueChange={ isDisableTyping ? null : onValueChange }
          onFocus={ handleInputFocus }
          onEnter={ handleEnterClick }
          alternativeEnterChars={ alternativeEnterChars }
          alternativeEnterCharClickHandler={ alternativeEnterCharClickHandler }
          onArrowUp={ handleArrowUp }
          onArrowDown={ handleArrowDown }
          isDisableTyping={ isDisableTyping }
          isInputFocused={ !!isInputFocused }
          isListOpened={ !isDisableOptions && isOpenMenu && !isLoading }
          StartAdornment={ StartAdornment }
          EndAdornment={ renderEndAdornment() }
          ref={ inputRef }
        />
      </InputWrapper>
      { defineInstructionsMessage() }
      { defineContent() }
      { isError && errorMessage && <ErrorMessage errorMessage={ errorMessage } /> }
    </Container>
  );
};

Autocomplete.defaultProps = {
  inputElementId: undefined,
  optionsTitle: '',
  isDisableOptions: false,
  children: [],
  onBlur: undefined,
  onFocus: undefined,
  isDisableTyping: false,
  autoFocus: true,
  isCustomMessage: false,
  isError: false,
  errorMessage: '',
  InstructionsMessage: null,
  StartAdornment: undefined,
  EndAdornment: undefined,
  onEnterClick: undefined,
  setInputFocused: undefined,
  isInputFocused: false,
  inputStyle: InputStyle.Regular,
  showLoadingIndication: true,
  label: undefined,
  alternativeEnterChars: undefined,
  alternativeEnterCharClickHandler: undefined,
  menuOffset: undefined,
};
