import { outreachQuery } from '../outreach.toolkit-api';
import { DeleteEmailSenderQueryArguments } from '../dto/query-arguments/delete-email-sender.query-arguments';

type UseSendMessageMutationType = [
  (request: DeleteEmailSenderQueryArguments) => void,
  AppSelectorType<void | undefined>
];

export const useDeleteEmailSenderQuery = (): UseSendMessageMutationType => {
  const [
    deleteEmailSender,
    {
      data, isLoading, isError, error, isSuccess,
    }] = outreachQuery.useDeleteEmailSenderMutation();

  const meta = {
    isLoading, isError, error, isSuccess, data,
  };

  return [
    deleteEmailSender,
    { ...meta },
  ];
};
