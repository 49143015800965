import { forwardRef, ReactElement, Ref } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { HeaderContainer } from './base-dialog-header.styled';

const BaseDialogHeader = forwardRef((
  props: TransitionProps & {
    children: ReactElement | ReactElement[] | string;
  },
  ref: Ref<HTMLDivElement>,
) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <HeaderContainer { ...props } ref={ ref } >
      { props.children }
    </HeaderContainer>
  );
});

BaseDialogHeader.displayName = 'BaseDialogHeader';

export default BaseDialogHeader;
