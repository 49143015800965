import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { CreatePositionState } from '../create-position-state.initial-state';

export const addSkillReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  const slicedSkills = state.skills.slice();
  slicedSkills.push({ title: '' });
  state.skills = slicedSkills;
};

export const setSkillsReducer: CaseReducer<CreatePositionState, PayloadAction<PositionJobRequirementState[]>> = (state, { payload }) => {
  state.skills = payload;
};

export const resetSkillsReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  state.skills = [];
};
