import styled from 'styled-components';
import { THEME } from '../../../../../../../../themes';
import { CaptionFont } from '../../../../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../../../../utils/colors';

export const PromotedBadgeContainer = styled.div`
  padding: 0 ${THEME.SPACING.TINY};
  display: flex;
  align-items: center;
  border-radius: 0.8em 0.3em;
  background: transparent linear-gradient(
          to right,
          ${hexToRgbA(THEME.COLORS.WARNING, 0.1)},
          ${hexToRgbA(THEME.COLORS.WARNING, 0.7)} 4%,
          ${hexToRgbA(THEME.COLORS.WARNING, 0.3)}
  );
  box-decoration-break: clone;
  width: fit-content;
`;

export const PromotionLabel = styled.span`
  ${CaptionFont};
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.21px;
`;
