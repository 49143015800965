import { useRef } from 'react';
import { useIntersection } from '../../../../hooks/useIntersection';
import { InfoButton, Wrapper } from './badge-wrapper.styled';

type BadgeWrapperProps = {
  children: JSX.Element,
  width: string,
  height: string,
  padding: string,
  backgroundColor: string,
  infoButtonClickHandler?: () => void,
  intersectionHandler?: () => void,
}

export const BadgeWrapper = ({
  children,
  width,
  height,
  padding,
  backgroundColor,
  infoButtonClickHandler,
  intersectionHandler,
}:BadgeWrapperProps): JSX.Element => {
  const componentRef = useRef(null);
  useIntersection({
    element: componentRef,
    callback: () => intersectionHandler?.(),
  });

  return (
    <Wrapper
      ref={ componentRef }
      width={ width }
      height={ height }
      padding={ padding }
      backgroundColor={ backgroundColor }
    >
      { infoButtonClickHandler && <InfoButton onClick={ () => infoButtonClickHandler?.() } /> }
      {children}
    </Wrapper>
  );
};

BadgeWrapper.defaultProps = {
  infoButtonClickHandler: undefined,
  intersectionHandler: undefined,
};
