export enum PerfectMenuVariants {
  Dense = 'DENSE',
  Regular = 'REGULAR',
}

export enum PerfectMenuSize {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',
}
