export enum EditPositionPreferencesFormikKeys {
  JobTitle = 'jobTitle',
  PrioritizedTitles = 'prioritizedTitles',
  ExcludedTitles = 'excludedTitles',
  Locations = 'locations',
  RemotePosition = 'remotePosition',
  LevelsOfExperience = 'levelsOfExperience',
  OverallLevelsOfExperience = 'overallLevelsOfExperience',
  CompanyBackground = 'companyBackground',
  CompanySizes = 'companySizes',
  Degrees = 'degrees',
  FieldsOfStudy = 'fieldsOfStudy',
  Skills = 'skills',
}
