import { useMemo } from 'react';
import { ConversationMinimizedResponseDto } from '../../../store/rtk-query/apis/outreach/dto/response/conversation-minimized.response';

export const useMapConversationsById = (
  conversations: ConversationMinimizedResponseDto[],
): Map<string, ConversationMinimizedResponseDto> => {
  return useMemo(() => {
    return conversations.reduce((acc, conversation) => {
      if (!acc.has(conversation.id)) {
        acc.set(conversation.id, conversation);
      }

      return acc;
    }, new Map<string, ConversationMinimizedResponseDto>());
  }, [conversations]);
};
