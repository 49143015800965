import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import log from 'loglevel';
import { BROADCAST_CHANNEL_MESSAGE_TYPE } from '../consts/broadcast_channel';
import BroadcastChannelManager from '../services/broadcastChannelManager';
import { logout } from '../store/slices/app-state/app-state.toolkit-slice';

const logger = log.getLogger('BROADCAST_CHANNEL_WATCHER');

export const useBroadcastChannelWatcher = (): void => {
  const dispatch = useDispatch();

  const handleLogoutMessageReceived = () => {
    logger.debug(
      'detected logout message from another tab (by broadcast-channel), logging out the user',
    );

    dispatch(logout(true));
  };

  // add listeners
  useEffect(() => {
    const { LOGOUT } = BROADCAST_CHANNEL_MESSAGE_TYPE;

    BroadcastChannelManager.addMessageListener(LOGOUT, handleLogoutMessageReceived);

    return () => {
      BroadcastChannelManager.removeMessageListener(LOGOUT, handleLogoutMessageReceived);
    };
  });
};
