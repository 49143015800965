import { useTranslation } from 'react-i18next';
import { WIDGET_COLORS } from '../../consts';
import STRING_KEYS from '../../../../../../language/keys';
import { convertMonthToFloatYears } from '../../../../../../utils';
import {
  ListContainer,
  Container,
  RoleCircle,
  RoleName,
  ExperienceValue,
} from './roles-list.styled';

const MAX_SEGMENTS_TO_SHOW = 5;

type RolesListProps = {
  data: ExperiencePieChartData[],
  disablePadding?: boolean,
}

export const RolesList = ({
  data,
  disablePadding,
}: RolesListProps):JSX.Element => {
  const { t: translate } = useTranslation();
  const chartData: PieChartData[] = data
    .sort((a, b) => b.durationInMonths - a.durationInMonths)
    .slice(0, MAX_SEGMENTS_TO_SHOW)
    .map((item, index: number) => {
      return {
        ...item,
        color: WIDGET_COLORS[index % WIDGET_COLORS.length],
      };
    });

  const getExperienceValue = (value: number) => {
    if (value >= 12) {
      return translate(STRING_KEYS.X_YEARS, {
        value: convertMonthToFloatYears(value),
      });
    }

    return translate(STRING_KEYS.X_MONTHS, { value });
  };

  return (
    <ListContainer>
      {
        chartData.slice(0, 5).map((item: PieChartData, index: number) => {
          return (
            <Container key={ `${item.title}` } disablePadding={ disablePadding }>
              <RoleCircle color={ WIDGET_COLORS[index % WIDGET_COLORS.length] } />
              <RoleName>{item.title}</RoleName>
              <ExperienceValue>
                {getExperienceValue(item.durationInMonths)}
              </ExperienceValue>
            </Container>
          );
        })
      }
    </ListContainer>
  );
};

RolesList.defaultProps = {
  disablePadding: false,
};
