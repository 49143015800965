import { forwardRef, ReactElement, Ref } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { BodyContainer } from './full-screen-dialog-body.styled';

const FullScreenDialogBody = forwardRef((
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<HTMLDivElement>,
) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BodyContainer { ...props } ref={ ref }>
      { props.children }
    </BodyContainer>
  );
});

FullScreenDialogBody.displayName = 'FullScreenDialogBody';

export default FullScreenDialogBody;
