import styled from 'styled-components';
import { FLUENT_UI_EMOJIS } from '../../../../../../assets';
import { IconCss } from '../base-tag/base-funnel-tag.styled';

export const LetterIcon = styled(FLUENT_UI_EMOJIS.LOVE_LETTER)`
  ${IconCss};
`;

export const TrophyIcon = styled(FLUENT_UI_EMOJIS.TROPHY)`
  ${IconCss};
`;

export const StarStruckIcon = styled(FLUENT_UI_EMOJIS.STAR_STRUCK)`
  ${IconCss};
`;
