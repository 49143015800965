import { useState } from 'react';
import { useDispatch } from 'react-redux';
import STRING_KEYS from '../../../../../language/keys';
import { Autocomplete } from '../../../../../components/Common/create-position-autocomplete/autocomplete.component';
import {
  COMPONENT_NAME, ELEMENT_TYPE, EVENT_GROUP,
} from '../../../../../consts/analytics';
import { PAGE_NAMES } from '../../../../../consts/pages';
import { SelectFromListMessage } from '../select-from-list-message/select-from-list-message.component';
import { getStepIndex } from '../../../utils/onboarding-bi-events.utls';
import { useCreatePositionProgressSteps } from '../../../../create-position/hooks/create-position-progress-steps.hooks';
import { useGetCurrentStep } from '../../../hooks/onboarding-current-step.hooks';
import { CreatePositionStepsEnums } from '../../../../create-position/enums/create-position-steps.enums';
import { CREATE_POSITION_ANALYTICS_EVENTS } from '../../../../create-position/consts/position-analytics.consts';
import { useAnalyticsContext } from '../../../../../contexts/analytics/useAnalyticsContext';
import { setRole } from '../../../../../store/slices/onboarding-state/onboarding-state.toolkit-slice';
import { InputStyle } from '../../input/input.component';
import { RoleItem } from './role-item/role-item.component';

type RoleAutocompleteProps = {
  roles: RoleItem[],
  onSentenceCompleted: () => void,
}

export const RoleAutocomplete = ({
  roles,
  onSentenceCompleted,
}: RoleAutocompleteProps):JSX.Element => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isInputFocused, setInputFocused] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const currentStep = useGetCurrentStep<CreatePositionStepsEnums>();
  const { steps } = useCreatePositionProgressSteps(currentStep);

  const handleComplete = (selectedRole: RoleItem) => {
    const selectedValue = selectedRole?.roleEnum || '';
    const trackEventPayload = {
      eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_INSERT_PARAMETER_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.BASIC_INFO,
      componentName: COMPONENT_NAME.EMPLOYEE_TYPE,
      componentElementType: ELEMENT_TYPE.AUTOCOMPLETE,
      componentMetadata: selectedValue,
      stepNumber: steps ? getStepIndex(currentStep, steps) : undefined,
    };
    onSentenceCompleted();
    dispatch(setRole(selectedValue));
    analyticsTrackEvent(trackEventPayload);
    setInputFocused(false);
  };

  const handleEnterClicked = (selectedRole: RoleItem) => {
    setInputValue(selectedRole?.name || '');
    handleComplete(selectedRole);
  };

  const itemClickedHandler = (value: string, index?:number) => {
    const isEnterClicked = index !== undefined && index >= 0;
    if (isEnterClicked) {
      handleEnterClicked(roles[index]);
    } else {
      setInputValue(value);
    }
  };

  return (
    <Autocomplete
      InstructionsMessage={ <SelectFromListMessage /> }
      optionsTitle=""
      isDisableTyping
      isLoading={ false }
      placeholder={ STRING_KEYS.ONBOARDING.ONBOARDING_ROLE_PLACEHOLDER }
      inputValue={ inputValue }
      onValueChange={ itemClickedHandler }
      isInputFocused={ isInputFocused }
      setInputFocused={ setInputFocused }
      inputStyle={ InputStyle.Onboarding }
    >
      {roles.map((role) => {
        return (
          <RoleItem
            isSelected={ inputValue === role.name }
            onSelect={ (value) => {
              itemClickedHandler(value?.name || '');
              handleComplete(value);
            } }
            key={ role.id } // TODO: change id from the server or insert to formatter in response
            role={ role }
          />
        );
      })}
    </Autocomplete>
  );
};
