import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { CaptionFont } from '../../../../../../themes/fonts';
import BaseButton from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { ASSETS } from '../../../../../../assets';
import { TextInputCommonStyle } from '../base-input-item.styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const Description = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;

export const MessageContainer = styled.div`
  position: relative;
`;

export const Message = styled.textarea`
  ${TextInputCommonStyle};
  width: 100%;
  height: 112px;
`;

export const AutoFillButton = styled(BaseButton)`
  ${CaptionFont};
  font-weight: bold;
  line-height: 1.58;
  letter-spacing: 1.15px;
  color: ${THEME.COLORS.SECONDARY};
  height: 24px;
  position: absolute;
  right: 0;
  top: ${THEME.SPACING.TINY};
`;

export const ButtonIcon = styled(ASSETS.MAGIC_WAND)`
  height: 24px;
`;
