/* eslint-disable react/no-array-index-key */
import { Slide } from '@mui/material';
import { useRef } from 'react';
import { SlideBody } from './create-position-slider.styled';
import { SliderComponentProps } from './create-position-slider';

const TIMEOUT = 500;

function PositionSlider({ slides }: SliderComponentProps): JSX.Element {
  const containerRef = useRef(null);

  return (
    <>
      { slides.map((slide, index) => (
        <Slide
          key={ `react_slide_${index}` }
          direction={ slide.direction }
          in={ slide.active }
          timeout={ {
            enter: TIMEOUT,
            exit: TIMEOUT,
          } }
          container={ containerRef.current }
          mountOnEnter
          unmountOnExit
        >
          <SlideBody>
            { slide.component }
          </SlideBody>
        </Slide>
      )) }
    </>
  );
}

export default PositionSlider;
