import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { THEME } from '../../../../../../themes';

export const Title = styled(Skeleton)`
  display: flex;
  width: 170px;
  height: 12px;
  border-radius: ${THEME.RADIUS.CIRCLE};
`;

export const SubTitle = styled(Skeleton)`
  display: flex;
  width: 257px;
  height: 20px;
  border-radius: ${THEME.RADIUS.CIRCLE};
`;

export const Description = styled(Skeleton)`
  display: flex;
  width: 131px;
  height: 20px;
  border-radius: ${THEME.RADIUS.CIRCLE};
`;

export const Content = styled(Skeleton)`
  display: flex;
  width: 296px;
  border-radius: ${THEME.RADIUS.ROUND};
`;
