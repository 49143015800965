import styled from 'styled-components';
import TextEditorHeader from '../text-editor-header.component';
import { hexToRgbA } from '../../../../../../utils/colors';
import { THEME } from '../../../../../../themes';
import { OverlineFont } from '../../../../../../themes/fonts';
import IconButton from '../../../../../../components/Common/Buttons/icon-button/icon-button.component';
import { ASSETS } from '../../../../../../assets';

export const AutoModeEditorHeader = styled(TextEditorHeader)`
  background-color: ${hexToRgbA(THEME.COLORS.SECONDARY, THEME.OPACITY.DIVIDER)};
`;

export const Icon = styled(ASSETS.FOLDER_LIGHTNING)`
  color: ${THEME.COLORS.SECONDARY};
  height: 24px;
`;

export const EndAdornment = styled.div`
  display: flex;
  align-items: center;
  ${OverlineFont};
  letter-spacing: 0.33px;
  text-transform: capitalize;
  gap: ${THEME.SPACING.BASE};
`;

export const MenuButton = styled(IconButton)`
  color: ${THEME.COLORS.CONTENT_A};
  width: 24px;
  height: 24px;
`;

export const MenuButtonIcon = styled(ASSETS.HORI_DOTS)`
  width: 16px;
  transform: rotate(-90deg);
`;
