import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { AppState } from '../app-state.initial-state';

type PayloadType = {
  code: string,
  message: string,
  info: string,
}
export const setFatalErrorReducer: CaseReducer<AppState, PayloadAction<PayloadType>> = (state, { payload }) => {
  state.fatalErrorCode = payload.code;
  state.fatalErrorMessage = payload.message;
  state.fatalErrorInfo = payload.info;
};
