import {
  BodyContainer, Container, HeaderContainer, PageTitle, PositionInfo, Wrapper,
} from './create-position-container.styled';

export const CreatePositionContainer = ({
  title,
  info,
  isOnboarding,
  children,
}: CreatePositionContainerProps): JSX.Element => {
  return (
    <Container topPadding={ !isOnboarding }>
      <Wrapper>
        <HeaderContainer>
          { info && (
            <PositionInfo>
              { info }
            </PositionInfo>
          ) }
          <PageTitle>
            { title }
          </PageTitle>
        </HeaderContainer>
        <BodyContainer>
          { children }
        </BodyContainer>
      </Wrapper>
    </Container>
  );
};
