import React from 'react';
import { Message } from './style';

type ErrorMessageProps = {
  message: string
};

export const ErrorMessage = ({ message }: ErrorMessageProps): JSX.Element => {
  return (
    <Message>
      {message}
    </Message>
  );
};
