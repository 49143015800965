import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import {
  Company,
  Counter,
  Reference,
  Title,
  WidgetContainer,
} from './worked-with-widget.styled';
import { WorkersList } from './workers-list/workers-list.component';

export const MAX_ITEMS_TO_SHOW = 4;

type WorkedWithWidgetProps = {
  workedWithList: FormattedWorkedWith[],
  companyName: string,
}

export const WorkedWithWidget = ({
  workedWithList,
  companyName,
}
:WorkedWithWidgetProps)
:JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <WidgetContainer>
      <Reference>
        {translate(STRING_KEYS.REFERENCE)}
      </Reference>
      <Title>
        {translate(STRING_KEYS.WORKED_WITH)}
        <Counter>
          {translate(STRING_KEYS.PEOPLE, {
            counter: workedWithList.length > MAX_ITEMS_TO_SHOW ?
              `${MAX_ITEMS_TO_SHOW}+` : `${workedWithList.length}`,
          })}
        </Counter>
        {translate(STRING_KEYS.AT)}
        <Company>{companyName}</Company>
      </Title>
      <WorkersList workers={ workedWithList } />
    </WidgetContainer>
  );
};
