import { outreachQuery } from '../outreach.toolkit-api';
import { MessageSuggestionResponseDto } from '../dto/response/message-suggestion.response';
import { GetMessageSuggestionQueryArguments } from '../dto/query-arguments/get-message-suggestion.query-arguments';

type UseGetMessageSuggestionMutationType = [
  (queryArguments: GetMessageSuggestionQueryArguments) => void,
  AppSelectorType<MessageSuggestionResponseDto | undefined>
];

export const useGetMessageSuggestionMutation = (): UseGetMessageSuggestionMutationType => {
  const [
    getMessageSuggestion,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = outreachQuery.useGetMessageSuggestionMutation();

  const meta = {
    isLoading, isError, error, isSuccess, data, resetState: reset,
  };

  return [
    getMessageSuggestion,
    { ...meta },
  ];
};
