import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../../language/keys';
import {
  IconContainer, EmptyContainer, TitleContainer, DescriptionContainer,
} from './conversation-group-empty.styled';

const ConversationGroupEmpty = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <EmptyContainer>
      <IconContainer>{translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP_EMPTY.EMOJI)}</IconContainer>
      <TitleContainer>{translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP_EMPTY.TITLE)}</TitleContainer>
      <DescriptionContainer>{translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP_EMPTY.DESCRIPTION)}</DescriptionContainer>
    </EmptyContainer>
  );
};

export default ConversationGroupEmpty;
