import styled from 'styled-components';
import BaseButton from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { ASSETS } from '../../../../../../assets';
import { StartIconContainer } from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.styled';
import { THEME } from '../../../../../../themes';
import { hexToRgbA } from '../../../../../../utils/colors';

export const ConfirmButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.ACCENT};
  width: 130px;
`;

export const BackButton = styled(BaseButton)`
  background-color: transparent;
  color: ${THEME.COLORS.PRIMARY_DARK};
  border-color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
  padding: 0;
  width: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
  
  & ${StartIconContainer} {
    margin: 0;
  }
`;

export const BackButtonIcon = styled(ASSETS.ARROW_BACK_COMPONENT)`
  transform: rotate(180deg);
  width: 17px;
`;
