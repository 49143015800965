import { sequenceQuery } from '../sequence.toolkit-api';
import { ApiError } from '../../../../../consts/api';
import { UpdateCustomStepsQueryArguments } from '../dto/query-arguments/update-custom-steps.query-arguments';
import { CustomStep } from '../dto/sequence-preferences.dto';

type UseCustomStepsMutationType = [
  (updateCustomSteps: UpdateCustomStepsQueryArguments) => void,
  AppSelectorType<CustomStep[] | undefined, ApiError>
];

export const useUpdateCustomStepsMutation = (): UseCustomStepsMutationType => {
  const [
    updateCustomSteps,
    {
      data, isLoading, isError, error: apiError, isSuccess, reset,
    }] = sequenceQuery.useUpdateCustomStepsMutation();

  const meta = {
    isLoading, isError, error: apiError as ApiError, isSuccess, data, resetState: reset,
  };

  return [
    updateCustomSteps,
    { ...meta },
  ];
};
