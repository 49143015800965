import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { Title2Font, Title3Font } from '../../../../themes/fonts';
import { fadeInAnimation } from '../../../../animations';

export const PageContainer = styled.div`
  overflow-y: auto;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${THEME.SPACING.XLOOSE};
  padding-bottom: ${THEME.SPACING.LARGE};
  padding-top: ${THEME.SPACING.XLARGE};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  color: ${THEME.COLORS.CONTENT_A};
  font-family: ${THEME.FONT_FAMILY.MERRIWEATHER};
  gap: ${THEME.SPACING.TINO};
  & span {
    font-family: ${THEME.FONT_FAMILY.MERRIWEATHER};
  }
`;

export const Subtitle = styled.div`
  ${Title3Font};
  opacity: ${THEME.OPACITY.MED};
  animation: ${fadeInAnimation({
    animationDuration: 400,
    animationDelay: 0,
  })};
`;

export const Title = styled.div`
  ${Title2Font};
  font-family: ${THEME.FONT_FAMILY.MERRIWEATHER};
  line-height: 1.38;
  white-space: break-spaces;
  animation: ${fadeInAnimation({
    animationDuration: 400,
    animationDelay: 300,
  })};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINO};
`;

export const MatchTuneBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${THEME.SPACING.XLOOSE};
  animation: ${fadeInAnimation({
    animationDuration: 400,
    animationDelay: 600,
  })};
`;
