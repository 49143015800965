import { Skeleton } from '@mui/material';
import { THEME } from '../../../../../themes';
import { Container, FlexContainer } from './message-skeleton.styled';

const MessageSkeleton = ({ showAnimation }: MessageSkeletonProps): JSX.Element => {
  const animation = showAnimation ? undefined : false;

  return (
    <Container>
      <FlexContainer>
        <Skeleton animation={ animation } variant="rectangular" width="70px" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="70px" sx={ { fontSize: '12px', borderRadius: THEME.RADIUS.CIRCLE } } />
      </FlexContainer>
      <FlexContainer>
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
      </FlexContainer>
      <FlexContainer>
        <Skeleton animation={ animation } variant="rectangular" width="100%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
      </FlexContainer>
      <FlexContainer>
        <Skeleton animation={ animation } variant="rectangular" width="175px" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
        <Skeleton animation={ animation } variant="rectangular" width="175px" sx={ { fontSize: '12px', borderRadius: THEME.RADIUS.CIRCLE } } />
      </FlexContainer>
      <FlexContainer>
        <Skeleton animation={ animation } variant="rectangular" width="85%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
      </FlexContainer>
      <FlexContainer>
        <Skeleton animation={ animation } variant="rectangular" width="75%" sx={ { fontSize: '6px', borderRadius: THEME.RADIUS.CIRCLE } } />
      </FlexContainer>
    </Container>
  );
};

export default MessageSkeleton;
