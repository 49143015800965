import Intercom, { show } from '@intercom/messenger-js-sdk';
import { CONFIG } from '../config';

export class IntercomManager {
  private static isInitialized = false;

  // eslint-disable-next-line no-useless-constructor,@typescript-eslint/no-empty-function
  private constructor() {}
  
  public static init = (intercomUserHash: string): void => {
    if (intercomUserHash && CONFIG.INTERCOM_APP_ID) {
      Intercom({
        app_id: CONFIG.INTERCOM_APP_ID,
        user_hash: intercomUserHash,
      });

      this.isInitialized = true;
    }
  }
  
  public static showChat = (): void => {
    if (this.isInitialized) {
      show();
    }
  }
}
