/* eslint-disable react/jsx-props-no-spreading */
import { RadioProps } from '@mui/material/Radio/Radio';
import { Radio } from '@mui/material';
import { THEME } from '../../../themes';

const PerfectRadio = (props: RadioProps): JSX.Element => {
  return (
    <Radio
      { ...props }
      sx={ {
        color: THEME.COLORS.ACCENT,
        '&.Mui-checked': {
          color: THEME.COLORS.ACCENT,
        },
        '& .MuiSvgIcon-root': {
          fontSize: 18,
        },
      } }
    />
  );
};

export default PerfectRadio;
