import React from 'react';
import { Trans } from 'react-i18next';
import STRING_KEYS from '../../../../../../../language/keys';
import { ChildWidget } from '../child-widget/child-widget.component';
import { WidgetIcon } from './personal-tenure-comparison.styled';

export const PersonalTenureComparison = ():JSX.Element => {
  return (
    <ChildWidget
      description={ <Trans i18nKey={ STRING_KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.CHILD_WIDGETS.PERSONAL_TENURE_COMPARISON.DESCRIPTION } /> }
      icon={ <WidgetIcon /> }
    />
  );
};
