import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../app-state.initial-state';
import { AppSnackbarDto } from '../dto/app-snackbar.dto';

export const setSnackbarReducer: CaseReducer<AppState, PayloadAction<AppSnackbarDto>> = (state, { payload }) => {
  state.snackbar = payload;
};

export const deleteSnackbarReducer: CaseReducer<AppState, PayloadAction> = (state) => {
  state.snackbar = undefined;
};
