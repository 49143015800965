import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import STRING_KEYS from '../../../../../language/keys';
import { getExperienceSentenceData, prepareMatchSummaryText } from '../talent-info-sentence/talent-info-sentence.utils';
import { TalentInfoSentence } from '../talent-info-sentence/talent-info-sentence.component';
import { useGetCurrentJobExperienceWithPostfix, useGetCurrentJobTitlePrefix } from '../talent-info-sentence/talent-info-sentence.hooks';
import { TextTyping } from '../../../../Common/text-typing/text-typing.component';
import {
  TalentInfoContainer,
} from './talent-info.styled';

export const TalentInfo = ({
  matchId,
  matchSummary,
  matchViewed,
  firstName,
  currentJobTitle,
  currentCompanyName,
  currentJobExperienceInMonths,
  highlightWords,
}: TalentInfoProps): JSX.Element | null => {
  const { t: translate } = useTranslation();
  const [initialMatchViewedValue, setInitialMatchViewedValue] = useState(false);
  const jobTitlePrefix = useGetCurrentJobTitlePrefix(currentJobTitle);
  const currentJobExperienceWithPostfix = useGetCurrentJobExperienceWithPostfix(currentJobExperienceInMonths);

  useEffect(() => {
    // Need to save the initial value because the seen value changes in rtk optimistic update
    setInitialMatchViewedValue(matchViewed);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchId]);

  const renderIntroduceSentenceString = () => {
    if (!firstName || !currentJobTitle) {
      return '';
    }

    return translate(STRING_KEYS.NEW_MATCH_TALENT_BIO_SENTENCE_1_1, {
      firstName,
      currentJobTitle,
      currentJobTitlePrefix: jobTitlePrefix,
    });
  };

  const renderExperienceSentenceString = () => {
    const { translationKey, options } = getExperienceSentenceData({
      firstName,
      currentJobTitle,
      currentJobExperienceInMonths,
      currentCompanyName,
      currentJobExperienceWithPostfix,
      jobTitlePrefix,
    }) || {};

    if (!translationKey) {
      return '';
    }

    return translate(translationKey, options);
  };

  const getInfoText = () => {
    if (matchSummary) {
      return prepareMatchSummaryText(matchSummary, highlightWords, currentJobTitle);
    }

    return renderIntroduceSentenceString() + renderExperienceSentenceString();
  };

  const renderTalentInformationText = () => {
    const textComponent = (
      <TalentInfoSentence
        firstName={ firstName }
        currentJobTitle={ currentJobTitle }
        highlightWords={ highlightWords }
        currentJobExperienceInMonths={ currentJobExperienceInMonths }
        currentCompanyName={ currentCompanyName }
        matchSummary={ matchSummary }
      />
    );

    if (initialMatchViewedValue) {
      return textComponent;
    }

    return (
      <TextTyping
        typingText={ getInfoText() }
        ghostText={ textComponent }
        shouldStartTyping
      />
    );
  };

  return (
    <TalentInfoContainer>
      { renderTalentInformationText() }
    </TalentInfoContainer>
  );
};

TalentInfo.defaultProps = {
  currentCompanyName: undefined,
  currentJobExperienceInMonths: undefined,
};
