import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { fadeInAnimation } from '../../../../animations';
import { Scrollbar } from '../../../../themes/scrollbar';
import PreferencesPageContentComponent from '../../components/outreach/preferences-page-content/preferences-page-content.component';
import { OverlineFont } from '../../../../themes/fonts';
import { ASSETS } from '../../../../assets';

export const OutreachPageContentWrapper = styled(PreferencesPageContentComponent)`
  justify-content: space-between;
  align-items: center;
`;

export const ConversationContainer = styled.div`
  height: 100%;
  ${Scrollbar};
  width: 100%;
`;

export const ConversationHeaderWrapper = styled.div`
  width: 100%;
`;

export const ConversationWrapper = styled.div`
  height: 100%;
  animation: ${fadeInAnimation({
    animationDuration: 800,
    animationDelay: 0,
  })};
`;

export const MessageSuggestionsWrapper = styled.div`
  width: fit-content;
  margin: 0 auto;
  display: flex;
  height: 100%;
  align-items: end;
  padding-bottom: ${THEME.SPACING.XLOOSE};
`;

export const MessageHintsContainer = styled.div`
  ${OverlineFont};
  background-color: ${THEME.COLORS.LIGHT_GREY};
  border-radius: ${THEME.SPACING.TINY};
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.BASE};
  display: flex;
  gap: ${THEME.SPACING.MEDIUM};
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;
`;

export const SpicesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.BASE};
  flex-wrap: wrap;
`;

export const Spice = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
  flex-shrink: 0;
`;

export const ToneOfVoiceIcon = styled(ASSETS.MASKS)`
  width: 18px;
`;

export const SpicesIcon = styled(ASSETS.SPICES)`
  width: 22px;
`;

export const TooltipTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MICRO};
  text-transform: lowercase;

  & > *:first-letter {
    text-transform: uppercase;
  }
`;

export const TooltipTitle = styled.div`
  font-weight: 500;
`;

export const ArchivedConversationContainer = styled.div`
  margin-top: ${THEME.SPACING.MICRO};
  margin-bottom: ${THEME.SPACING.LOOSE};
`;
