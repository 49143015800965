import { useState, useEffect, RefObject } from 'react';

type UseIntersectionProps = {
  element: RefObject<HTMLElement>,
  callback?: () => void,
  triggerCallbackOnce?: boolean,
  threshold?: number,
};

export const useIntersection = ({
  element,
  callback,
  triggerCallbackOnce = true,
  threshold = 0.5,
}: UseIntersectionProps): boolean => {
  const [isVisible, setState] = useState(false);
  const [alreadyViewed, setAlreadyViewed] = useState<boolean>(false);

  useEffect(() => {
    const refElement = element.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (!triggerCallbackOnce || !alreadyViewed) {
            callback?.();

            if (triggerCallbackOnce && refElement) {
              observer.unobserve(refElement);
            }
          }
          setAlreadyViewed(true);
        }
        setState(entry.isIntersecting);
      }, { threshold },
    );

    if (refElement) {
      observer.observe(refElement);
    }

    return () => {
      if (refElement) {
        observer.unobserve(refElement);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isVisible;
};
