import { useTranslation } from 'react-i18next';
import { MeterBarStyleEnum } from '../../../../../../components/match/match-content/smart-widgets/move-meter-widget/meter/meter.enums';
import { MOVE_METER_TYPES } from '../../../../../../components/match/match-content/smart-widgets/consts';
import STRING_KEYS from '../../../../../../language/keys';
import { THEME } from '../../../../../../themes';
import MoveMeterChildWidgetsContainer
  from '../../../../../../components/match/match-content/smart-widgets/move-meter-widget/move-meter-child-widgets-container/move-meter-child-widgets-container.component';
import BaseWidget from '../base-widget/base-widget.component';
import {
  Container,
  Icon,
  InfoText,
  StyledDivider,
  StyledMeter,
  Title,
} from './likelihood-to-move-widget.styled';

const MOVE_METER_TYPE_TO_SCORE = {
  [MOVE_METER_TYPES.SOMEWHAT_LIKELY_LOW]: 4,
  [MOVE_METER_TYPES.SOMEWHAT_LIKELY_HIGH]: 5,
  [MOVE_METER_TYPES.LIKELY_TO_MOVE_LOW]: 6,
  [MOVE_METER_TYPES.LIKELY_TO_MOVE_HIGH]: 7,
  [MOVE_METER_TYPES.VERY_LIKELY_LOW]: 8,
  [MOVE_METER_TYPES.VERY_LIKELY_HIGH]: 9,
};

const MOVE_METER_TYPE_TO_COLOR = {
  [MOVE_METER_TYPES.SOMEWHAT_LIKELY_LOW]: THEME.COLORS.WARNING,
  [MOVE_METER_TYPES.SOMEWHAT_LIKELY_HIGH]: THEME.COLORS.WARNING,
  [MOVE_METER_TYPES.LIKELY_TO_MOVE_LOW]: THEME.COLORS.SECONDARY,
  [MOVE_METER_TYPES.LIKELY_TO_MOVE_HIGH]: THEME.COLORS.SECONDARY,
  [MOVE_METER_TYPES.VERY_LIKELY_LOW]: THEME.COLORS.ACCENT,
  [MOVE_METER_TYPES.VERY_LIKELY_HIGH]: THEME.COLORS.ACCENT,
};

const MOVE_METER_LEVEL_TRANSLATION_KEY_MAP = {
  [MOVE_METER_TYPES.SOMEWHAT_LIKELY_LOW]: STRING_KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.LEVEL.LOW,
  [MOVE_METER_TYPES.SOMEWHAT_LIKELY_HIGH]: STRING_KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.LEVEL.LOW,
  [MOVE_METER_TYPES.LIKELY_TO_MOVE_LOW]: STRING_KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.LEVEL.MEDIUM,
  [MOVE_METER_TYPES.LIKELY_TO_MOVE_HIGH]: STRING_KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.LEVEL.MEDIUM,
  [MOVE_METER_TYPES.VERY_LIKELY_LOW]: STRING_KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.LEVEL.HIGH,
  [MOVE_METER_TYPES.VERY_LIKELY_HIGH]: STRING_KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.LEVEL.HIGH,
};

const TOTAL_BAR_SIZE = 8;

const LikelihoodToMoveWidget = ({
  data,
  childWidgets,
  talentName,
}: LikelihoodToMoveWidgetProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const level = MOVE_METER_TYPE_TO_SCORE[data.type] || 0;
  const color = MOVE_METER_TYPE_TO_COLOR[data.type];

  const renderSubWidgetsContainer = () => {
    if (!childWidgets) {
      return null;
    }

    return (
      <MoveMeterChildWidgetsContainer childWidgets={ childWidgets } />
    );
  };

  return (
    <>
      <BaseWidget
        title={ translate(STRING_KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.TITLE) }
        Icon={ Icon }
      >
        <Container>
          <Title color={ color } >{ translate(MOVE_METER_LEVEL_TRANSLATION_KEY_MAP[data.type]) }</Title>
          <StyledMeter
            level={ level }
            totalBarSize={ TOTAL_BAR_SIZE }
            barStyle={ MeterBarStyleEnum.Horizontal }
            color={ color }
          />
          <InfoText>{ translate(STRING_KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.DESCRIPTION, { talentName }) }</InfoText>
          { !!childWidgets?.length && <StyledDivider /> }
          <div>
            { renderSubWidgetsContainer() }
          </div>
        </Container>
      </BaseWidget>
    </>
  );
};

export default LikelihoodToMoveWidget;
