import { css } from 'styled-components';
import { THEME } from './index';

export const HeadlineFont = css`
    font-weight: bold;
    font-size: ${THEME.FONT_SIZE.LARGE};
    line-height: 1;
    letter-spacing: 0.15px;
`;

export const Subtitle1Font = css`
    font-weight: 500;
    font-size: ${THEME.FONT_SIZE.MEDIUM};
    line-height: 1;
    letter-spacing: 0.14px;
`;

export const Body1Font = css`
    font-weight: normal;
    font-size: ${THEME.FONT_SIZE.MEDIUM};
    letter-spacing: 0.44px;
`;

export const Subtitle2Font = css`
    font-weight: 500;
    font-size: ${THEME.FONT_SIZE.BASE};
    letter-spacing: 0.1px;
    line-height: 1;
`;

export const Body2Font = css`
    font-weight: normal;
    font-size: ${THEME.FONT_SIZE.BASE};
    line-height: 1.5;
    letter-spacing: 0.25px;
`;

// changed 'bold' to 600 - to match zeplin style
export const ButtonFont = css`
    font-weight: 600;
    font-size: ${THEME.FONT_SIZE.BASE};
    line-height: 1.5;
    letter-spacing: 1.34px;
    text-transform: uppercase;
`;

export const CaptionFont = css`
    font-weight: normal;
    font-size: ${THEME.FONT_SIZE.TINO};
    line-height: 1.5;
    letter-spacing: 0.4px;
`;

export const OverlineFont = css`
    font-weight: normal;
    font-size: ${THEME.FONT_SIZE.TINY};
    line-height: 1.5;
    letter-spacing: 1.5px;
    text-transform: uppercase;
`;

export const LargeTitleFont = css`
    font-weight: 200;
    font-size: ${THEME.FONT_SIZE.XGIANT};
    letter-spacing: -0.9px;
    line-height: 1;
`;

export const Title1Font = css`
    font-weight: 400;
    font-size: ${THEME.FONT_SIZE.XGRAND};
    line-height: 1;
    letter-spacing: normal;
`;

export const Title2Font = css`
    font-weight: 400;
    font-size: ${THEME.FONT_SIZE.GRAND};
    letter-spacing: normal;
    line-height: 1;
`;

export const Title3Font = css`
    font-weight: 400;
    font-size: ${THEME.FONT_SIZE.XLARGE};
    letter-spacing: normal;
    line-height: 1;
`;

export const TitleBold = css`
    font-weight: 700;
    font-size: ${THEME.FONT_SIZE.GIANT};
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.17;
`;

export const PerfectFeature = css`
  font-family: ${THEME.FONT_FAMILY.PERFECT_FEATURES}, serif;
  vertical-align: middle;
`;
