import styled, { keyframes } from 'styled-components';
import { THEME } from '../../../themes';
import { temporaryElevation } from '../../../themes/elevations';
import { hexToRgbA } from '../../../utils/colors';

export const NOTIFICATION_DIALOG_SIDE_PADDING = THEME.SPACING.LOOSE;

const slideInBlurred = keyframes`
 0% {
   transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
   transform-origin: 50% 100%;
   filter: blur(40px);
   opacity: 0;
  }
  100% {
    transform: translateY(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
`;

export const NotificationDialogContainer = styled.div`
  ${temporaryElevation};
  position: fixed;
  left: 0;
  bottom: 0;
  height: fit-content;
  max-width: 480px;
  margin-left: ${THEME.SPACING.BASE};
  margin-bottom: ${THEME.SPACING.BASE};
  background-color: ${THEME.COLORS.SURFACE_PRIMARY};
  border: solid 1px ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
  border-radius: ${THEME.RADIUS.LOOPY};
  z-index: 999;
  display: flex;
  gap: ${THEME.SPACING.BASE};;
  flex-direction: column;
  padding: ${THEME.SPACING.MEDIUM} ${NOTIFICATION_DIALOG_SIDE_PADDING};
  overflow: hidden;
  animation: ${slideInBlurred} 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
`;
