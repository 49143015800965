import styled from 'styled-components';
import BaseWidget from '../base-widget/base-widget.component';
import { THEME } from '../../../../../../themes';
import { FLUENT_UI_EMOJIS } from '../../../../../../assets';

export const BaseWidgetStyled = styled(BaseWidget)`
  background-color: ${THEME.COLORS.LIGHT_GREY};
`;

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MICRO};
  flex-grow: 1;
`;

export const Icon = styled(FLUENT_UI_EMOJIS.CRYSTAL_BALL)``;

export const LocationIcon = styled(FLUENT_UI_EMOJIS.ROUND_PUSHPIN)``;
export const LevelOfExperienceIcon = styled(FLUENT_UI_EMOJIS.MILITARY_MEDAL)``;
