import styled from 'styled-components';
import { THEME } from '../../../themes';
import {
  Title3Font,
  Body1Font,
  Subtitle1Font,
  CaptionFont,
} from '../../../themes/fonts';
import { Button } from '../../Common/Buttons/Button';
import Dialog from '../base-dialog/base-dialog.component';

export const HeaderContainer = styled(Dialog.Header)`
  margin-top: ${THEME.SPACING.MEDIUM};
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  flex-direction: column;
`;

export const BodyContainer = styled(Dialog.Body)`
  padding: ${THEME.SPACING.LOOSE} ${THEME.SPACING.GRAND};
`;

export const Title = styled.div`
  ${Title3Font};
  font-weight: bold;
  line-height: 1.5;
`;

export const Description = styled.div`
  ${Body1Font};
  line-height: 1.5;
  opacity: ${THEME.OPACITY.MED};
`;

export const EmailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const EmailInput = styled.input<{isError: boolean}>`
  ${Subtitle1Font};
  display: inline-block;
  flex-grow: 1;
  border: 1px solid ${({ isError }) => {
    return isError ? THEME.COLORS.ALERT : THEME.COLORS_RGB.PRIMARY_DARK_GHOST;
  }};;
  vertical-align: middle;
  overflow: hidden;
  border-radius: ${THEME.RADIUS.BASE};
  padding: 14px 14px 14px 22px;
  color: ${THEME.COLORS_RGB.LOW_GRAY};

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${THEME.COLORS_RGB.LOW_GRAY};
  }
`;

export const StyledButtonWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: ${THEME.SPACING.TINY};

  button {
    border-radius: ${THEME.RADIUS.BASE};

    :after {
      border-radius: ${THEME.RADIUS.BASE};
    }
  }
`;

export const StyledButton = styled(Button).attrs({
  backgroundColor: THEME.COLORS.ACCENT,
  buttonHeight: '46px',
})`
`;

export const EmailNotValid = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.ALERT};
  text-align: left;
  margin-top: ${THEME.SPACING.MICRO};
`;

export const EmailExist = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.PRIMARY};
  text-align: left;
  margin-top: ${THEME.SPACING.MICRO};
`;

export const ModalContentWrapper = styled.div`
  position: relative;
`;
