import styled from 'styled-components';
import { THEME } from '../../../themes';
import { Subtitle1Font } from '../../../themes/fonts';
import { ASSETS } from '../../../assets';
import { hexToRgbA } from '../../../utils/colors';
import { lowElevation } from '../../../themes/elevations';
import DropdownMenu from './dropdown-menu/dropdown-menu.component';

export const DEFAULT_DROPDOWN_MENU_WIDTH_PX = 390;

export const Container = styled.div<{ filterMenuOpen: boolean }>`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
  color: ${({ filterMenuOpen }) => filterMenuOpen ? THEME.COLORS.ACCENT : THEME.COLORS.CONTENT_A};
  background-color: ${({ filterMenuOpen }) => filterMenuOpen ? hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER) : undefined};
  padding: ${THEME.SPACING.TINY} ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.BASE};
  &:hover {
    color: ${THEME.COLORS.ACCENT};
  }
  ${({ filterMenuOpen }) => {
    if (filterMenuOpen) {
      return lowElevation;
    }
    
    return undefined;
  }};
`;

export const Title = styled.div`
  ${Subtitle1Font};
`;

export const ArrowDownIcon = styled(ASSETS.ARROW_DOWN)`
  height: 20px;
  fill: currentColor;
`;

export const CustomDropdownMenu = styled(DropdownMenu)`
  right: unset;
  left: 0;
`;
