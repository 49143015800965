import styled from 'styled-components';
import { THEME } from '../../../themes';
import {
  Body1Font,
  CaptionFont,
  Title2Font,
  Subtitle2Font,
} from '../../../themes/fonts';
import { Button } from '../../Common/Buttons/Button';
import { ASSETS } from '../../../assets';
import Dialog from '../base-dialog/base-dialog.component';

export const DialogContainer = styled.div`
  padding: ${THEME.SPACING.LARGE} ${THEME.SPACING.XLOOSE} ${THEME.SPACING.XLOOSE};
`;

export const HeaderContainer = styled(Dialog.Header)`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: auto;
`;

export const BodyContainer = styled(Dialog.Body)`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 32px 0;
`;

export const FooterContainer = styled(Dialog.Footer)`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

export const ModalIcon = styled(ASSETS.INFINITY_ARROW_ICON)`
  width: 48px;
`;

export const Title = styled.div`
  ${Title2Font};
  font-weight: bold;
  line-height: 1.5;
`;

export const Description = styled.div`
  ${Body1Font};
  color: ${THEME.COLORS_RGB.PRIMARY_DARK_MED};
  line-height: 1.5;
`;

export const StyledButton = styled(Button).attrs({
  backgroundColor: THEME.COLORS.ACCENT,
  buttonHeight: '48px',
  buttonWidth: 'fit-content',
})`
`;

export const ModalContentWrapper = styled.div`
  position: relative;
`;

export const HelpContainer = styled.div`
  color: ${THEME.COLORS_RGB.PRIMARY_DARK_MED};
  margin-top: ${THEME.SPACING.BASE};
`;

export const HelpButton = styled.span`
  text-decoration: underline;
  color: ${THEME.COLORS.ACCENT};
  cursor: pointer;
`;

export const AutoSyncContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${THEME.SPACING.MEDIUM} 0;
`;

export const AutoSyncTextContainer = styled.div`
  margin-left: ${THEME.SPACING.BASE};
  text-align: left;
`;

export const AutoSyncTitle = styled.div`
  ${Subtitle2Font};
  font-weight: 600;
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const AutoSyncInfoText = styled.div`
  ${CaptionFont};
  margin-top: ${THEME.SPACING.TINY};
  color: ${THEME.COLORS_RGB.PRIMARY_DARK_MED};
`;
