import { SkeletonTheme } from 'react-loading-skeleton';
import { THEME } from '../../../../../../themes';
import { WidgetWrapper } from '../../widget-wrapper/widget-wrapper.component';
import {
  Content,
  Description,
  SubTitle,
  Title,
} from './smart-widget-item-skeleton.styled';

type SmartWidgetItemSkeletonProps = {
  wrapperHeight: string,
  contentHeight: string,
}

export const SmartWidgetItemSkeleton = ({
  wrapperHeight,
  contentHeight,
}:SmartWidgetItemSkeletonProps): JSX.Element => {
  return (
    <WidgetWrapper
      width="344px"
      height={ wrapperHeight }
      backgroundColor={ THEME.COLORS.LIGHT_GREY }
      padding={ THEME.SPACING.BASE }
    >
      <SkeletonTheme
        baseColor={ THEME.COLORS.SURFACE_PRIMARY }
        highlightColor={ THEME.COLORS.LIGHT_GREY }
      >
        <Title />
        <SubTitle />
        <Description />
        <Content height={ contentHeight } />
      </SkeletonTheme>
    </WidgetWrapper>
  );
};
