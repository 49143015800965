import styled, { keyframes } from 'styled-components';
import { THEME } from '../../../../themes';

const scale = keyframes`
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
  }
`;

export const CustomMessageWrapper = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${THEME.SPACING.BASE};
  width: 100%;
  border-radius: ${THEME.RADIUS.ROUNDISH};
  perspective: 1px;
  margin-bottom: ${THEME.SPACING.BASE};
  box-shadow: unset;
  position: absolute;
  top: 35px;
  left: 0;
  animation: ${scale} 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;

export const CustomMessageItem = styled.div`
  position: relative;
  background-color: transparent;
`;
