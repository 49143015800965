import { Icon as IconUrl } from '../../../../../Common/Icon';
import { getIconBasedId } from '../../../../../../utils';
import SvgIcon from '../../../../../Common/SvgIcon';
import {
  BadgeContent,
  Container,
  Content,
} from './education-item-badges.styled';

type BadgeProps = {
  badges: Array<Badge>
}

export const Badges = ({ badges }: BadgeProps): JSX.Element => {
  const defineBadgeContent = (badge: Badge) => {
    if (badge.iconUrl) {
      return (
        <BadgeContent key={ badge.iconUrl }>
          {badge.iconUrl && (
            <IconUrl
              src={ badge.iconUrl }
              size="micro"
            />
          )}
          <Content>{badge.text}</Content>
        </BadgeContent>
      );
    } if (badge.iconId) {
      const Icon = getIconBasedId(badge?.iconId);

      return (
        <BadgeContent key={ badge.iconId }>
          {Icon && <SvgIcon Icon={ Icon } size="micro" />}
          <Content>{badge.text}</Content>
        </BadgeContent>
      );
    }

    return <></>;
  };

  return (
    <Container>
      {badges.map((badge: Badge) => defineBadgeContent(badge))}
    </Container>
  );
};
