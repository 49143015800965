import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/base-query';
import { REDUCER_PATH } from './company.consts';
import {
  GetCompanyByIdQueryArguments,
} from './dto/query-arguments/get-company-by-id.query-arguments';
import { CompanyInfoResponse } from './dto/response/company-info.response';
import { getCompanyByIdQuery } from './company.toolkit-queries';

export const companyQuery = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getCompanyById: builder.query<CompanyInfoResponse, GetCompanyByIdQueryArguments>({
      query: getCompanyByIdQuery,
    }),
  }),
});
