import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppState } from '../../../store/selectors';
import { disableFlashingFavicon } from '../../../store/slices/app-state/app-state.toolkit-slice';
import NotificationService from '../../../services/notification-service';
import FaviconService from '../../../services/favicon-service';
import STRING_KEYS from '../../../language/keys';

const DEFAULT_FAVICON_PATH = '/favicon.png';
const NOTIFICATION_FAVICON_PATH = '/favicon-notification.png';

const useFlashingFavicon = (): void => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [originalTitle, setOriginalTitle] = useState(document.title);
  const [flashingInterval, setFlashingInterval] = useState<ReturnType<typeof setInterval> | null>(null);

  const { isFaviconFlashing } = useAppState();

  useEffect(() => {
    let toggleFavicon = false;

    if (isFaviconFlashing && document.visibilityState !== 'visible') {
      NotificationService.playNotificationSound();

      setOriginalTitle(document.title);
      document.title = translate(STRING_KEYS.MATCH_TUNE.MATCHES_READY);

      const intervalId = setInterval(() => {
        toggleFavicon = !toggleFavicon;
        toggleFavicon ? FaviconService.setFavicon(DEFAULT_FAVICON_PATH) : FaviconService.setFavicon(NOTIFICATION_FAVICON_PATH);
      }, 1000);
      setFlashingInterval(intervalId);
    } else if (flashingInterval) {
      clearInterval(flashingInterval);
      setFlashingInterval(null);
      FaviconService.setFavicon(DEFAULT_FAVICON_PATH);
    }

    return () => {
      if (flashingInterval) {
        clearInterval(flashingInterval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFaviconFlashing]);

  // Use Page Visibility API to detect when the user comes back to the tab
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && isFaviconFlashing) {
        dispatch(disableFlashingFavicon());
        document.title = originalTitle;
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFaviconFlashing, dispatch]);
};

export default useFlashingFavicon;
