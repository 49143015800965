import { GetQuery, PatchQuery, PostQuery } from '../../@types/rtk-query';
import { QueryMethods } from '../../enums/query-methods.enums';
import { ROUTES } from './onboarding.consts';
import { UpdateOnboardingProgressQueryArguments } from './dto/query-arguments/update-onboarding-progress.query-arguments';
import { UpdateOnboardingProgressRequest } from './dto/request/update-onboarding-progress.request';

export const loadOnboardingQuery = (): PostQuery<undefined> => ({
  url: ROUTES.ONBOARDING,
  method: QueryMethods.Post,
});

export const updateOnboardingProgressQuery = ({ step, dynamicUpdateParams }: UpdateOnboardingProgressQueryArguments): PatchQuery<UpdateOnboardingProgressRequest> => {
  return ({
    url: ROUTES.ONBOARDING,
    method: QueryMethods.Patch,
    data: { step, dynamicUpdateParams },
  });
};

export const getAllowedCompaniesQuery = (): GetQuery => ({
  url: ROUTES.ALLOWED_COMPANIES,
  method: QueryMethods.Get,
});
