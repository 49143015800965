import { useForgotPasswordState } from '@frontegg/react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ForgotPasswordStep } from '@frontegg/redux-store';
import { IndeterminateLinearLoader } from '../Common/Loaders/IndeterminateLinearLoader';
import STRING_KEYS from '../../language/keys';
import { PAGE_TITLES, SUCCESS_CODE } from '../../consts';
import { AppRouting } from '../../services/appRoutingResolver';
import { PageHeader } from '../Common/headers/page-header/page-header.component';
import ForgotPasswordForm from './ForgotPasswordForm';
import {
  Accent,
  BackToLoginButton,
  BackToLoginText,
  Header,
  LoaderWrapper,
  PageContainer,
  PageDescription,
  Wrapper,
} from './styled';

function ForgotPasswordScreen(): JSX.Element {
  // This effect runs once, after the first render
  useEffect(() => {
    document.title = PAGE_TITLES.FORGOT_PASSWORD;
  }, []);

  const { t: translate } = useTranslation();
  const activateState = useForgotPasswordState();
  const { loading: isLoading, error: errorMessage, step } = activateState;

  if (step === ForgotPasswordStep.success) {
    AppRouting.navigateToUrl(AppRouting.buildGlobalLoginUrl(window.location, SUCCESS_CODE.FORGOT_EMAIL_SENT));
  }

  return (
    <PageContainer>
      <PageHeader />
      {isLoading && (
        <LoaderWrapper>
          <IndeterminateLinearLoader />
        </LoaderWrapper>
      )}
      <Header>
        {translate(STRING_KEYS.FORGOT_YOUR)} <Accent>{translate(STRING_KEYS.PASSWORD)}?</Accent>
      </Header>
      <PageDescription>
        {translate(STRING_KEYS.FORGOT_PASSWORD_PAGE_DESCRIPTION)}
      </PageDescription>
      <ForgotPasswordForm errorMessage={ errorMessage } />
      <Wrapper>
        <BackToLoginText>
          {translate(STRING_KEYS.BACK_TO_LOGIN_DESCRIPTION)}
        </BackToLoginText>
        <BackToLoginButton onClick={ () => AppRouting.navigateToUrl(AppRouting.buildGlobalLoginUrl(window.location)) }>
          {translate(STRING_KEYS.BACK_TO_LOGIN)}
        </BackToLoginButton>
      </Wrapper>
    </PageContainer>
  );
}

export default ForgotPasswordScreen;
