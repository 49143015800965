import styled from 'styled-components';
import { CaptionFont } from '../../../../../../themes/fonts';
import { THEME } from '../../../../../../themes';
import { ASSETS } from '../../../../../../assets';
import { ProgressStepTypes } from './progress-bar-step.enum';

export const BarStepContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BarStepTitle = styled.div<{ step: ProgressStepTypes }>`
  ${CaptionFont};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.33;
  letter-spacing: 0.19px;
  padding-left: ${THEME.SPACING.TINY};
  text-decoration: ${({ step }) => step === ProgressStepTypes.Finished ? 'line-through' : undefined};
  color: ${({ step }) => step === ProgressStepTypes.Waiting ? THEME.COLORS_RGB.CONTENT_A_034 : THEME.COLORS.CONTENT_A};
`;

export const StepsDividerIcon = styled(ASSETS.ARROW_RIGHT)`
  margin: 0 ${THEME.SPACING.BASE};
  fill: ${THEME.COLORS_RGB.CONTENT_A_034};
`;

export const StepCompetedIcon = styled(ASSETS.STEP_COMPLETED)`
  fill: ${THEME.COLORS.PRIMARY_DARK};
`;

export const StepCurrentIcon = styled(ASSETS.STEP_CURRENT)`
  fill: ${THEME.COLORS.ACCENT};
`;

export const StepNextIcon = styled(ASSETS.STEP_NEXT)`
  fill: ${THEME.COLORS_RGB.CONTENT_A_034};
`;
