import React from 'react';
import ReactDOM from 'react-dom';
import './match_preview.css';
import reportWebVitals from '../../reportWebVitals';
import '../../language';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { MatchPreviewFrame } from '../../components/MatchPreviewFrame';

// const LOG_TAG = '[ENTRY_MATCH_PREVIEW_INDEX]';

// TODO [refactor]: delete
// Main render
ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <MatchPreviewFrame />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(log.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
