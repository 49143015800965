import { ContentState, EditorState } from 'draft-js';
import { useReducer, useRef } from 'react';
import { actions, TextEditorActionReturnType, TextEditorAction } from './text-editor.actions';
import { TextEditorActionType } from './text-editor.enums';
import { TextEditorState } from './text-editor';
import { initialState } from './text-editor.context';

export const reducer = (state: TextEditorState, action: TextEditorAction): TextEditorState => {
  switch (action.type) {
    case TextEditorActionType.SetEditorBody:
      return { ...state, editorBodyState: action.payload as EditorState };
    case TextEditorActionType.SetEditorSubject:
      return { ...state, editorSubjectState: action.payload as EditorState };
    case TextEditorActionType.SetShowSubject:
      return { ...state, showSubject: action.payload as boolean };
    case TextEditorActionType.SetSubjectReadOnly:
      return { ...state, subjectReadOnly: action.payload as boolean };
    case TextEditorActionType.SetTouched:
      return { ...state, touched: action.payload as boolean };
    case TextEditorActionType.SetShowSubjectEditor:
      return { ...state, showSubjectEditor: action.payload as boolean };
    case TextEditorActionType.SetEditorInFocus:
      return { ...state, editorInFocus: action.payload as boolean };
    case TextEditorActionType.SetSubjectEditorInFocus:
      return { ...state, subjectEditorInFocus: action.payload as boolean };
    case TextEditorActionType.ResetEditor:
      return {
        ...state,
        editorBodyState: EditorState.push(state.editorBodyState, ContentState.createFromText(''), 'delete-character'),
        editorSubjectState: EditorState.push(state.editorSubjectState, ContentState.createFromText(''), 'delete-character'),
        editorInFocus: false,
      };
    case TextEditorActionType.SetShowTextEditorHeader:
      return { ...state, showTextEditorHeader: action.payload as boolean };
    default:
      return state;
  }
};

export default (customInitState?: Partial<TextEditorState>): [TextEditorState, TextEditorActionReturnType] => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...customInitState });
  const { current: bondedActions } = useRef(actions(dispatch));

  return [state, bondedActions];
};
