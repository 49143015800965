import { PerfectButtonHoverVariants } from '../../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { IconButtonVariants } from '../../../../../../../components/Common/Buttons/icon-button/icon-button.enums';
import {
  AcceptButton,
  AcceptIcon, ButtonsContainer, Container, RejectButton, RejectIcon, Text,
} from './similar-title-item.styled';
import { SimilarTitleItemProps } from './similar-title-item';

const SimilarTitleItem = ({
  text,
  status,
  acceptHandler,
  rejectHandler,
}: SimilarTitleItemProps): JSX.Element => {
  return (
    <Container status={ status }>
      <ButtonsContainer>
        <RejectButton
          type="button"
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          variant={ IconButtonVariants.Ghost }
          StartIcon={ RejectIcon }
          onClick={ rejectHandler }
        />
        <AcceptButton
          type="button"
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          variant={ IconButtonVariants.Ghost }
          StartIcon={ AcceptIcon }
          onClick={ acceptHandler }
        />
      </ButtonsContainer>
      <Text>{ text }</Text>
    </Container>
  );
};

export default SimilarTitleItem;
