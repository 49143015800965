import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../../language/keys';
import { nthNumber } from '../../../../../../language/utils';
import { OutreachIcon, StepChip } from './custom-mode-step-chip.styled';

const CustomModeStepChip = ({ step, color, backgroundColor }: CustomModeStepChipProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <StepChip
      color={ color }
      backgroundColor={ backgroundColor }
      StartAdornment={ (
        <OutreachIcon
          color={ color }
        />
      ) }
    >
      <strong>{`${step}${nthNumber(step)} `}</strong><span>{translate(STRING_KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.STEP_LABEL)}</span>
    </StepChip>
  );
};

export default CustomModeStepChip;
