import {
  Children,
  cloneElement,
  isValidElement,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PerfectMenuSize } from '../perfect-base-menu/perfect-base-menu.enums';
import BaseMenu from '../perfect-base-menu/perfect-base-menu.component';
import {
  ArrowIcon,
  ArrowIconContainer,
  Container,
  ContentContainer,
  Label,
  LabelContainer,
  LabelIconWrapper,
  Placeholder,
  Value,
} from './select.styled';
import { SelectProps } from './select';

const Select = ({
  label,
  LabelIcon,
  placeholder,
  value,
  onChangeHandler,
  children,
  StartAdornment,
  menuProps,
  disabled,
  formatDisplayValue,
  ...otherProps
}: SelectProps): JSX.Element => {
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);
  const anchorElementRef = useRef<HTMLDivElement>(null);

  const [selectedElement, setSelectedElement] = useState<SelectItemProps | null>(null);

  const onContainerClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      anchorElement ? setAnchorElement(null) : setAnchorElement(anchorElementRef?.current);
    },
    [anchorElement]);

  const onOptionsMenuClose = () => setAnchorElement(null);

  const onSelectItemClick = (selectedValue: string) => {
    onChangeHandler(selectedValue);
    setAnchorElement(null);
  };

  const childrenWithProps = useMemo(() => {
    return Children.map(
      children,
      (child) => {
        const item = child as ReactElement<PropsWithChildren<SelectItemProps>>;

        // Checking isValidElement is the safe way and avoids a TS error too.
        if (isValidElement(item)) {
          const selected = value !== undefined && value === item.props.value;

          if (selected) {
            setSelectedElement(item.props);
          }

          return cloneElement(item, {
            onClickHandler: onSelectItemClick,
            selected,
            value: item.props.value,
            children: item.props.children,
          });
        }

        return item;
      });
    // eslint-disable-next-line
  }, [children, value]);

  const renderValueOrPlaceholder = (): JSX.Element | undefined => {
    if (selectedElement) {
      if (formatDisplayValue) {
        return <Value>{ formatDisplayValue(selectedElement) }</Value>;
      }

      return <Value>{ selectedElement.children }</Value>;
    }

    if (value) {
      return <Value>{ value }</Value>;
    }

    if (placeholder) {
      return <Placeholder>{ placeholder }</Placeholder>;
    }

    return undefined;
  };

  const renderLabel = (): JSX.Element | undefined => {
    if (!label && !LabelIcon) {
      return undefined;
    }

    return (
      <LabelContainer>
        { LabelIcon && (
          <LabelIconWrapper>
            { LabelIcon }
          </LabelIconWrapper>
        ) }
        {label && <Label>{ label }</Label>}
      </LabelContainer>
    );
  };

  return (
    <>
      <Container
        ref={ anchorElementRef }
        onClick={ (event) => !disabled && onContainerClick(event) }
        isActive={ !!anchorElement }
        disabled={ disabled }
        // eslint-disable-next-line react/jsx-props-no-spreading
        { ...otherProps }
      >
        { StartAdornment }
        <ContentContainer>
          { renderLabel() }
          { renderValueOrPlaceholder() }
        </ContentContainer>
        <ArrowIconContainer>
          <ArrowIcon />
        </ArrowIconContainer>
      </Container>
      <BaseMenu
        isOpen={ !!anchorElement }
        anchorElement={ anchorElement || undefined }
        placement="auto-start"
        onClose={ onOptionsMenuClose }
        size={ PerfectMenuSize.Large }
        Title={ menuProps?.Title }
      >
        { childrenWithProps }
      </BaseMenu>
    </>
  );
};

export default Select;
