import { matchQuery } from '../match.toolkit-api';
import { MatchResponse } from '../dto/response/match.response';
import { ApiError } from '../../../../../consts/api';

export const getMatchByIdQuery = (positionId = '', matchId = ''): AppSelectorType<MatchResponse, ApiError> => {
  const {
    data,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
    refetch,
  } = matchQuery.useGetMatchByIdQuery(
    { positionId, matchId },
    {
      skip: !positionId || !matchId,
    },
  );

  return {
    data: data || {} as MatchResponse,
    error: error as ApiError,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    refetch,
  };
};
