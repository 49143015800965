import {
  Badge,
  Container,
  InfoText,
  DescriptionContainer,
  EndContainer,
  IconContainer,
  StartContainer,
  TextContainer,
  Title,
  Description,
} from './campaign-base-option.styled';

const CampaignPageBaseOption = ({
  title,
  description,
  infoText,
  badgeText,
  Icon,
  children,
  EndAdornment,
}: CampaignPageAutoModeBaseOptionProps): JSX.Element => {
  return (
    <Container>
      <StartContainer>
        <IconContainer><Icon /></IconContainer>
        <TextContainer>
          <Title>{ title }</Title>
          <DescriptionContainer>
            { badgeText && <Badge>{ badgeText }</Badge> }
            { description && <Description>{ description }</Description> }
            { infoText && <InfoText>{ infoText }</InfoText> }
          </DescriptionContainer>
          { children }
        </TextContainer>
      </StartContainer>
      <EndContainer>
        { EndAdornment }
      </EndContainer>
    </Container>
  );
};

export default CampaignPageBaseOption;
