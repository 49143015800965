import { ASSETS } from '../../../../assets';
import SvgIcon from '../../../Common/SvgIcon';
import {
  Circle, IconWrapper, RuleTitle, RuleWrapper,
} from './styled';

type RuleProps = {
  title: string,
  isValid: boolean,
}
export const Rule = ({
  title,
  isValid,
}: RuleProps):JSX.Element => {
  const renderIcon = () => {
    if (isValid) {
      return (
        <IconWrapper>
          <SvgIcon
            Icon={ ASSETS.CHECKED_CIRCLE_DONE_FILLED }
            size="micro"
            fill="#27b386" // TODO should use regular styled-component icon component
          />
        </IconWrapper>
      );
    }

    return <Circle />;
  };

  return (
    <RuleWrapper>
      {renderIcon()}
      <RuleTitle>
        {title}
      </RuleTitle>
    </RuleWrapper>
  );
};
