import { AxiosError } from 'axios';
import log from 'loglevel';
import { useMutation, UseMutationResult } from 'react-query';
import { sendVerificationEmail, validateUserEmailDomain, verifyEmail } from '../services/onboarding.network-requests';

const logger = log.getLogger('ONBOARDING_QUERIES');

export const useSendVerificationEmail = ():
UseMutationResult<unknown, AxiosError, void> => {
  return useMutation(() => {
    return sendVerificationEmail();
  }, {
    onSuccess: () => {
      logger.debug('successfully sent verification email to user');
    },
    onError: (error) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const errorStatusCode = error?.response?.status || 999;
      const isInternalServerError = errorStatusCode > 499;
      if (isInternalServerError) {
        logger.error('Failed to send a verification email to the user: ', errorStatusCode, error, { isInternalServerError });
      } else {
        logger.warn('Failed to send a verification email to the user: ', errorStatusCode, error, { isInternalServerError });
      }
    },
  });
};

type useVerifyEmailParams = {
  code: string,
}

export const useVerifyEmail = (onSuccessCallback: () => void):
UseMutationResult<unknown, AxiosError,
useVerifyEmailParams, unknown> => {
  return useMutation(({
    code,
  }:useVerifyEmailParams) => {
    return verifyEmail(code);
  }, {
    onSuccess: () => {
      logger.debug('successfully verified user email');
      onSuccessCallback();
    },
    onError: (error) => {
      const errorStatusCode = error?.response?.status || 999;
      const isInternalServerError = errorStatusCode > 499;
      if (isInternalServerError) {
        logger.error('Failed to verify user email, status: ', errorStatusCode, error, { isInternalServerError });
      } else {
        logger.warn('Failed to verify user email, status: ', errorStatusCode, error, { isInternalServerError });
      }
    },
    retry: false,
  });
};

type useValidateEmailDomainParams = {
  email: string,
}

export const useValidateEmailDomain = (onSuccessCallback: () => void):
UseMutationResult<unknown, AxiosError, useValidateEmailDomainParams, unknown> => {
  return useMutation(({
    email,
  }:useValidateEmailDomainParams) => {
    return validateUserEmailDomain(email);
  }, {
    onSuccess: () => {
      logger.debug('successfully validated user sign up email.');
      onSuccessCallback();
    },
    onError: (error) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const errorStatusCode = error?.response?.status || 999;
      const isInternalServerError = errorStatusCode > 499;
      if (isInternalServerError) {
        logger.error('Failed to validate user sign up email. status: ', errorStatusCode, error, { isInternalServerError });
      } else {
        logger.warn('Failed to validate user sign up email. status: ', errorStatusCode, error, { isInternalServerError });
      }
    },
    retry: false,
  });
};
