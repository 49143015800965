import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
  PerfectButtonVariants,
} from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../../../language/keys';
import {
  ButtonsContainer, Container, MatchTuneBody, Subtitle, Title, TitleContainer,
} from '../../../../pages/match-tune/match-tune.styled';
import { useAnalyticsContext } from '../../../../../../contexts/analytics/useAnalyticsContext';
import { EVENT_GROUP } from '../../../../../../consts/analytics';
import { BackButton, BackButtonIcon, ConfirmButton } from './step-container.styled';

const StepContainer = ({
  title,
  subtitle,
  backButtonHandler,
  continueButtonHandler,
  isContinueButtonDisabled,
  isContinueButtonLoading,
  eventName,
  children,
}: StepContainerProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { analyticsTrackEvent } = useAnalyticsContext();

  useEffect(() => {
    const payload = {
      eventName,
      eventGroup: EVENT_GROUP.IMP,
    };
    analyticsTrackEvent(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <TitleContainer>
        <Subtitle>{ title }</Subtitle>
        <Title>{ subtitle }</Title>
      </TitleContainer>
      <MatchTuneBody>
        { children }
        <ButtonsContainer>
          <BackButton
            StartIcon={ BackButtonIcon }
            variant={ PerfectButtonVariants.Outline }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
            size={ PerfectButtonSize.Large }
            onClick={ backButtonHandler }
          />
          <ConfirmButton
            onClick={ continueButtonHandler }
            size={ PerfectButtonSize.Large }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
            disabled={ isContinueButtonDisabled }
            isLoading={ isContinueButtonLoading }
          >
            { translate(STRING_KEYS.CONFIRM) }
          </ConfirmButton>
        </ButtonsContainer>
      </MatchTuneBody>
    </Container>
  );
};

export default StepContainer;
