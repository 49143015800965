import { createSlice } from '@reduxjs/toolkit';
import { positionQuery } from '../../rtk-query/apis/position/position.toolkit-api';
import { initialCreatePositionState } from './create-position-state.initial-state';
import {
  resetJobDescriptionFieldsReducer,
  setJobDescriptionS3TempUrlReducer,
  setJobDescriptionTempUrlReducer,
} from './reducers/create-position-job-description.reducer';
import {
  resetEducationFieldsReducer,
  setDegreesReducer,
  setUserInputFieldOfStudyReducer,
} from './reducers/create-position-education-step.reducer';
import { resetExperienceLevelsReducer, setLevelsOfExperienceReducer } from './reducers/create-position-expirience-step.reducer';
import {
  addCompanyBackgroundReducer,
  resetCompanyBackgroundReducer,
  setCompanyBackgroundReducer,
} from './reducers/create-position-industries-step.reducer';
import {
  addPositionLocationReducer,
  removePositionLocationReducer,
  resetPositionJobTitleReducer,
  resetPositionLocationReducer,
  setPositionJobTitleReducer,
  setPositionRemoteLocationReducer,
  updatePositionLocationReducer,
} from './reducers/create-position-lets-begin-step.reducer';
import {
  addSkillReducer,
  resetSkillsReducer,
  setSkillsReducer,
} from './reducers/create-position-skills.reducer';
import {
  resetErrorReducer, resetStateReducer,
  setContinueButtonDisableReducer,
  setContinueButtonEnableReducer,
  setContinueButtonHideReducer,
  setContinueButtonShowReducer, setSkipButtonHideReducer, setSkipButtonShowReducer,
} from './reducers/create-position-ui.reducer';
import { createPositionsFulfilledExtraReducer, createPositionsPendingExtraReducer, createPositionsRejectedExtraReducer } from './extra-reducers/positions.reducers';

export const CreatePositionStateSlice = createSlice({
  name: 'create-position-state',
  initialState: initialCreatePositionState,
  reducers: {
    setJobDescriptionTempUrl: setJobDescriptionTempUrlReducer,
    setJobDescriptionS3TempUrl: setJobDescriptionS3TempUrlReducer,
    resetJobDescriptionFields: resetJobDescriptionFieldsReducer,
    setDegrees: setDegreesReducer,
    setUserInputFieldOfStudy: setUserInputFieldOfStudyReducer,
    resetEducationFields: resetEducationFieldsReducer,
    setLevelsOfExperience: setLevelsOfExperienceReducer,
    resetExperienceLevels: resetExperienceLevelsReducer,
    setCompanyBackground: setCompanyBackgroundReducer,
    addCompanyBackground: addCompanyBackgroundReducer,
    resetCompanyBackground: resetCompanyBackgroundReducer,
    setPositionRemoteLocation: setPositionRemoteLocationReducer,
    addPositionLocation: addPositionLocationReducer,
    updatePositionLocation: updatePositionLocationReducer,
    removePositionLocation: removePositionLocationReducer,
    setPositionJobTitle: setPositionJobTitleReducer,
    resetPositionJobTitle: resetPositionJobTitleReducer,
    resetPositionLocation: resetPositionLocationReducer,
    addSkill: addSkillReducer,
    setSkills: setSkillsReducer,
    resetSkills: resetSkillsReducer,
    resetError: resetErrorReducer,
    setContinueButtonEnable: setContinueButtonEnableReducer,
    setContinueButtonDisable: setContinueButtonDisableReducer,
    setContinueButtonHide: setContinueButtonHideReducer,
    setContinueButtonShow: setContinueButtonShowReducer,
    setSkipButtonShow: setSkipButtonShowReducer,
    setSkipButtonHide: setSkipButtonHideReducer,
    resetState: resetStateReducer,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(positionQuery.endpoints.createPosition.matchPending, createPositionsPendingExtraReducer)
      .addMatcher(positionQuery.endpoints.createPosition.matchFulfilled, createPositionsFulfilledExtraReducer)
      .addMatcher(positionQuery.endpoints.createPosition.matchRejected, createPositionsRejectedExtraReducer);
  },
});

export const {
  setJobDescriptionTempUrl,
  setJobDescriptionS3TempUrl,
  resetJobDescriptionFields,
  setDegrees,
  setUserInputFieldOfStudy,
  resetEducationFields,
  setLevelsOfExperience,
  resetExperienceLevels,
  setCompanyBackground,
  resetCompanyBackground,
  addCompanyBackground,
  setPositionRemoteLocation,
  addPositionLocation,
  updatePositionLocation,
  removePositionLocation,
  setPositionJobTitle,
  resetPositionJobTitle,
  resetPositionLocation,
  addSkill,
  setSkills,
  resetSkills,
  resetError,
  setContinueButtonEnable,
  setContinueButtonDisable,
  setContinueButtonHide,
  setContinueButtonShow,
  setSkipButtonShow,
  setSkipButtonHide,
  resetState,
} = CreatePositionStateSlice.actions;

export default CreatePositionStateSlice.reducer;
