/* eslint-disable react/jsx-props-no-spreading,react/no-array-index-key */
import { Trans, useTranslation } from 'react-i18next';
import {
  ChangeEvent, KeyboardEvent, useCallback, useState, MouseEvent, useRef, useEffect, useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { KEYBOARD_KEYS } from '../../../../../../consts/keyboard_keys';
import { PerfectChipVariants } from '../../../../../../components/Common/perfect-chip/perfect-chip.enums';
import { validateEmail } from '../../../../../../utils';
import STRING_KEYS from '../../../../../../language/keys';
import AlertTextEditorHeader
  from '../../text-editor-header/alert-text-editor-header/alert-text-editor-header.component';
import Tooltip from '../../../../../../components/tooltip/tooltip.component';
import { AppRouting } from '../../../../../../services/appRoutingResolver';
import { EmailsInputProps, OutreachEmail } from './emails-input';
import {
  CheckedChipIcon,
  Container,
  EmailChip,
  EmailsContainer,
  EmailsInputContainer, EndAdornmentContainer, EndAdornmentIcon, EndAdornmentLabel,
  FromUserIcon,
  Input,
  Label,
  Me, Underline,
} from './emails-input.styled';

const EmailsInput = ({
  emails,
  setEmailsHandler,
  alertActionButtonHandler,
  errorAlertBackgroundColor,
}: EmailsInputProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const { positionId } = useParams<{ positionId: string }>();
  const [inputValue, setInputValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const showEndAdornment = useMemo(() => {
    return !emails.length;
  }, [emails]);

  const onEmailsContainerClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsInputFocused(true);
  };

  useEffect(() => {
    if (inputRef?.current && isInputFocused) {
      inputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef?.current, isInputFocused]);

  const onInputValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setInputValue(event.currentTarget.value);
  };

  const onInputBlur = () => {
    setTimeout(() => {
      if (document.activeElement !== inputRef.current) {
        setIsInputFocused(false);
      }
    }, 300);

    if (validateEmail(inputValue)) {
      setEmailsHandler([
        ...emails.map((email) => ({ ...email, selected: false })),
        {
          value: inputValue,
          selected: true,
        },
      ]);
      setInputValue('');
    }
  };

  const onChipClick = useCallback((event: MouseEvent<HTMLDivElement>, index: number) => {
    const filteredEmails = emails.map((email, i) => {
      if (i === index) {
        email.selected = !email.selected;
      } else if (email.selected) {
        email.selected = false;
      }

      return email;
    });
    setEmailsHandler(filteredEmails);
  }, [emails, setEmailsHandler]);

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KEYBOARD_KEYS.BACKSPACE && !inputValue) {
      const tempEmails = [...emails];
      tempEmails.pop();
      setEmailsHandler(
        tempEmails,
      );
    }
    if (event.key === KEYBOARD_KEYS.ENTER && validateEmail(inputValue)) {
      setEmailsHandler([
        ...emails.map((email) => ({ ...email, selected: false })),
        {
          value: inputValue,
          selected: true,
        },
      ]);
      setInputValue('');
    }
  };

  const onTooltipLinkClick = useCallback(() => {
    history.push(AppRouting.buildCampaignsPreferencesPageUrl(positionId));
  }, [history, positionId]);

  const Description = useMemo(() => (
    <Trans
      defaults={ STRING_KEYS.OUTREACH_PAGE.EMAILS.EMAIL_LESS.TOOLTIP }
      components={ { Underline: <Underline onClick={ onTooltipLinkClick } /> } }
    />
  ), [onTooltipLinkClick]);

  const renderEmailsChips = useCallback(() => {
    return emails.map((email: OutreachEmail, index) => {
      return (
        <EmailChip
          variant={ PerfectChipVariants.Outline }
          key={ `${email.value}_${index}` }
          selected={ email.selected }
          onClick={ (e) => onChipClick(e, index) }
          StartAdornment={ (
            email.selected ? <CheckedChipIcon /> : undefined
          ) }
        >
          { email.value }
        </EmailChip>
      );
    });
  }, [emails, onChipClick]);

  const renderAlert = () => {
    if (emails.length) {
      return null;
    }

    return (
      <AlertTextEditorHeader
        label={ translate(STRING_KEYS.OUTREACH_PAGE.EMAILS.MISSING_ALERT_LABEL) }
        infoText={ translate(STRING_KEYS.OUTREACH_PAGE.EMAILS.MISSING_ALERT_DESCRIPTION) }
        actionButtonHandler={ alertActionButtonHandler }
        backgroundColor={ errorAlertBackgroundColor }
      />
    );
  };

  return (
    <Container isAlert={ !isInputFocused && !emails.length }>
      { renderAlert() }
      <EmailsContainer onClick={ onEmailsContainerClick }>
        <FromUserIcon />
        <Label>{ translate(STRING_KEYS.OUTREACH_PAGE.EMAILS.FROM.LABEL) }</Label>
        <Me>{ translate(STRING_KEYS.OUTREACH_PAGE.EMAILS.FROM.USER_EMAIL_ALIAS) }</Me>
        <Label>{ translate(STRING_KEYS.OUTREACH_PAGE.EMAILS.TO.LABEL) }</Label>
        <EmailsInputContainer>
          <>
            <>{ renderEmailsChips() }</>
            <Input
              ref={ inputRef }
              value={ inputValue }
              onChange={ onInputValueChange }
              onKeyDown={ onKeyDown }
              onBlur={ onInputBlur }
              onFocus={ () => setIsInputFocused(true) }
            />
          </>
        </EmailsInputContainer>
        {
          showEndAdornment && (
            <Tooltip
              content={ Description }
              customTooltipStyle={ {
                maxWidth: '320px',
              } }
            >
              <EndAdornmentContainer
                onClick={ onTooltipLinkClick }
              >
                <EndAdornmentLabel>{translate(STRING_KEYS.OUTREACH_PAGE.EMAILS.EMAIL_LESS.TITLE)}</EndAdornmentLabel>
                <EndAdornmentIcon />
              </EndAdornmentContainer>
            </Tooltip>
          )
        }
      </EmailsContainer>
    </Container>
  );
};

export default EmailsInput;
