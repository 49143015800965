import styled from 'styled-components';
import { COMPANY_SIZE_ICON_MAP } from '../../modules/application/consts/company-size-icon-map';
import { CompanySizeTierEnum } from '../../enums/company-size-tier.enum';

export const SmallCompanySizeIcon = styled(COMPANY_SIZE_ICON_MAP[CompanySizeTierEnum.Small])``;

export const MediumCompanySizeIcon = styled(COMPANY_SIZE_ICON_MAP[CompanySizeTierEnum.Medium])``;

export const LargeCompanySizeIcon = styled(COMPANY_SIZE_ICON_MAP[CompanySizeTierEnum.Large])``;

export const VeryLargeCompanySizeIcon = styled(COMPANY_SIZE_ICON_MAP[CompanySizeTierEnum.VeryLarge])``;
