import { ChangeEvent, useEffect, useState } from 'react';
import { withShowHide } from '../../HOC/WithShowHide';
import DropdownMenu from '../dropdown/dropdown-menu/dropdown-menu.component';
import {
  Arrow,
  Container,
  DefaultIcon,
  IconContainer,
  Input,
  InputContainer,
  InputImage,
  Placeholder,
  PlaceholderContainer,
  SelectedItemSubtitle,
} from './autocomplete.styled';

function Autocomplete({
  filterMenuOpen,
  selectedItemId,
  items,
  disabled,
  itemSelectHandler,
  placeholder,
  image,
  menuOpenHandler,
  menuCloseHandler,
}: AutocompleteProps): JSX.Element {
  const [inputValue, setInputValue] = useState<string | null>(null);

  useEffect(() => {
    if (filterMenuOpen) {
      if (menuOpenHandler) {
        menuOpenHandler();
      }
    } else if (menuCloseHandler) {
      menuCloseHandler();
      setInputValue(null);
    }
  }, [filterMenuOpen, menuOpenHandler, menuCloseHandler]);

  const getSelectedItem = (): DropdownMenuItem | undefined => {
    if (selectedItemId) {
      return items?.find((item) => item.id === selectedItemId);
    }

    return undefined;
  };

  const selectedItem = getSelectedItem();

  const onInputChange = (data: ChangeEvent<HTMLInputElement>) => {
    setInputValue(data.currentTarget.value || null);
  };

  const getItems = () => {
    if (inputValue) {
      const lowercaseInput = inputValue.toLowerCase();

      return items.filter((item: DropdownMenuItem) => item.title.toLowerCase().includes(lowercaseInput));
    }

    return items;
  };

  const renderPlaceholder = () => {
    return (
      <PlaceholderContainer disabled={ disabled }>
        {
          selectedItem ? (
            <>
              <div>{ selectedItem.title }</div>
              <SelectedItemSubtitle>{ selectedItem.subtitle }</SelectedItemSubtitle>
            </>
          ) : (<Placeholder>{ placeholder }</Placeholder>)
        }
      </PlaceholderContainer>
    );
  };

  return (
    <Container disabled={ disabled } active={ filterMenuOpen && !disabled }>
      <IconContainer>
        { image ? <InputImage src={ image } /> : <DefaultIcon /> }
      </IconContainer>
      <InputContainer>
        { (!filterMenuOpen || disabled) && renderPlaceholder() }
        { filterMenuOpen && !disabled && (
          <>
            <Input
              autoFocus
              placeholder={ placeholder }
              onChange={ onInputChange }
            />
            <DropdownMenu
              items={ getItems() }
              selectedItemId={ selectedItemId }
              onItemSelect={ (itemId: string) => itemSelectHandler && itemSelectHandler(itemId) }
            />
          </>
        ) }
        { !disabled && <Arrow isMenuOpen={ filterMenuOpen } /> }
      </InputContainer>
    </Container>
  );
}
export default withShowHide(Autocomplete);
