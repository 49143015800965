import { useReducer, useRef } from 'react';
import { MatchCardAction, MatchCardActionsReturnType, MatchCardState } from './match-card';
import { MatchCardActionType } from './match-card.enums';
import { initialState } from './match-card.context';
import { actions } from './match-card.actions';

export const reducer = (state: MatchCardState, action: MatchCardAction): MatchCardState => {
  switch (action.type) {
    case MatchCardActionType.SetIsExpanded: {
      return { ...state, isExpanded: action.payload };
    }
    default:
      return state;
  }
};

// TODO: create infra
export default (customInitState?: Partial<MatchCardState>): [MatchCardState, MatchCardActionsReturnType] => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...customInitState });
  const { current: bondedActions } = useRef(actions(dispatch));

  return [state, bondedActions];
};
