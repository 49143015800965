/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, Ref } from 'react';
import { ChipProps } from './perfect-chip';
import { ChipContainer, TextWrapper } from './perfect-chip.styled';
import { PerfectChipVariants } from './perfect-chip.enums';

const Chip = forwardRef((
  props: ChipProps,
  ref: Ref<HTMLDivElement>,
) => {
  const {
    variant = PerfectChipVariants.Fill, StartAdornment, EndAdornment, children, ...otherProps
  } = props;

  return (
    <ChipContainer
      ref={ ref }
      variant={ variant }
      { ...otherProps }
    >
      { StartAdornment }
      <TextWrapper>
        {children}
      </TextWrapper>
      { EndAdornment }
    </ChipContainer>
  );
});

Chip.displayName = 'Chip';

export default Chip;
