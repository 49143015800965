import { useMemo } from 'react';
import { Loader, StyledChip, WarningIcon } from './outreach-chip.styled';
import { LoadingChipProps } from './outreach-chip';
import { OutreachChipTypeEnum } from './outreach-chip.enums';

const OutreachChip = ({
  text,
  isLoading,
  type = OutreachChipTypeEnum.Regular,
  EndAdornment,
}: LoadingChipProps): JSX.Element => {
  const StartAdornment = useMemo(() => {
    if (isLoading) {
      return (
        <Loader
          size="16px"
          thickness={ 6 }
        />
      );
    }

    if (type === OutreachChipTypeEnum.Warning) {
      return (
        <WarningIcon />
      );
    }

    return undefined;
  }, [isLoading, type]);

  return (
    <StyledChip
      type={ type }
      StartAdornment={ StartAdornment }
      EndAdornment={ EndAdornment }
    >
      {text}
    </StyledChip>
  );
};

export default OutreachChip;
