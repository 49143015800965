import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { LOCKED_PROFILE_IMAGES } from '../../assets';
import { EducationConnectionResponseDto, EmploymentConnectionResponseDto } from '../../store/rtk-query/apis/match/dto/response/match.response';
import STRING_KEYS from '../../language/keys';
import { ConnectionScoreEnum } from '../../enums/connection-score.enum';
import { getRandomIntBetweenRange } from '../../utils/number';
import { useShouldLockFeature } from '../../hooks/should-lock-feature.hook';
import { TalentNetworkCardProps } from './talent-network-card';
import TalentCard from './base-card/base-card.component';

const getTipTranslationKeyPerConnectionLevel = (connectionLevel: ConnectionScoreEnum): string => {
  switch (connectionLevel) {
    case ConnectionScoreEnum.LowConnection:
      return STRING_KEYS.TALENT_NETWORK.TIPS.LOW_CONNECTION;
    case ConnectionScoreEnum.MediumConnection:
      return STRING_KEYS.TALENT_NETWORK.TIPS.MEDIUM_CONNECTION;
    case ConnectionScoreEnum.StrongConnection:
      return STRING_KEYS.TALENT_NETWORK.TIPS.STRONG_CONNECTION;
    default:
      return STRING_KEYS.TALENT_NETWORK.TIPS.MEDIUM_CONNECTION;
  }
};

const TalentNetworkCard = ({
  networkedTalent, matchTalentName, tipClickHandler,
}: TalentNetworkCardProps): JSX.Element => {
  const {
    talent,
    connectionCompoundScore: connectionLevel,
    employmentConnections,
    educationConnections,
  } = networkedTalent;
  const { t: translate } = useTranslation();
  const isLocked = useShouldLockFeature();

  const educationWithMaxScore = educationConnections[0];
  const experienceWithMaxScore = employmentConnections[0];

  const getNetworkTranslationKey = (educationConnection: EducationConnectionResponseDto, experienceConnection: EmploymentConnectionResponseDto): string => {
    if (experienceConnection && educationConnection) {
      return STRING_KEYS.TALENT_NETWORK.CARDS.EDUCATION_AND_EXPERIENCE;
    }

    if (experienceConnection) {
      if (experienceConnection.sameRole) {
        return STRING_KEYS.TALENT_NETWORK.CARDS.EXPERIENCE.SAME_ROLE;
      }

      if (experienceConnection.similarRole) {
        return STRING_KEYS.TALENT_NETWORK.CARDS.EXPERIENCE.SIMILAR_ROLE;
      }

      return STRING_KEYS.TALENT_NETWORK.CARDS.EXPERIENCE.WORKED_WITH;
    }

    if (educationConnection) {
      return STRING_KEYS.TALENT_NETWORK.CARDS.EDUCATION.SAME_UNIVERSITY;
    }

    return '';
  };

  const descriptionTranslationKey = getNetworkTranslationKey(educationWithMaxScore, experienceWithMaxScore);
  const talentName = `${talent.firstName} ${talent.lastName || ''}`;

  const getTalentImage = useMemo(() => {
    if (isLocked) {
      const randomIndex = getRandomIntBetweenRange(0, Object.keys(LOCKED_PROFILE_IMAGES).length - 1);

      return Object.values(LOCKED_PROFILE_IMAGES)[randomIndex];
    }

    return talent.profileImage || '';
  }, [isLocked, talent.profileImage]);

  return (
    <TalentCard
      talentName={ talentName }
      connectionLevel={ connectionLevel }
      description={ translate(descriptionTranslationKey, {
        companyName: experienceWithMaxScore?.companyName || '',
        institutionName: educationWithMaxScore?.institutionName || '',
        jobTitle: experienceWithMaxScore?.jobTitle || '',
        talentName: matchTalentName,
      }) }
      talentImageUrl={ getTalentImage }
      badgeImageUrl={ experienceWithMaxScore?.companyLogo || educationWithMaxScore?.institutionLogo || '' }
      badgeName={ experienceWithMaxScore?.companyName || educationWithMaxScore?.institutionName || '' }
      tip={ translate(getTipTranslationKeyPerConnectionLevel(connectionLevel), {
        name: talentName,
      }) }
      isLocked={ isLocked }
      onTipClickHandler={ tipClickHandler }
    />
  );
};

export default TalentNetworkCard;
