import { FC } from 'react';
import { useAppState } from '../../store/selectors';
import { useIsSuperUser } from '../../hooks/superuser';
import RudderstackAnalyticsAgent, { RudderStackDestination } from '../../services/rudderStackAnalyticsAgent';
import { APP_USER_PERMISSION_TYPE, APP_USER_TYPE } from '../../consts/analytics';
import trackingIdManager from '../../services/trackingIdManager';
import { PageEventPayload, TrackEventPayload } from '../../@types/analytics/eventPayload';
import { useMyUserSelector } from '../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { useGetMyAccountSelector } from '../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import { AnalyticsContext } from './context';

type AnalyticsProviderProps = {
  children: JSX.Element | JSX.Element[],
  productArea?: string,
}

export const AnalyticsProvider: FC<AnalyticsProviderProps> = ({
  children,
  productArea,
}: AnalyticsProviderProps) => {
  const { data: user } = useMyUserSelector();
  const isSuperUser = useIsSuperUser();
  const { data: currentAccount } = useGetMyAccountSelector();
  const {
    id: accountId,
    displayName: companyName,
    status: companyStatus,
    plan,
  } = currentAccount;
  const { currentPageId, referrerPageId } = useAppState();

  const sessionTrackingId = trackingIdManager.getId();

  const generateBasicAnalyticPayload = () => ({
    accountId: accountId || undefined,
    companyName: companyName || undefined,
    companyStatus: companyStatus || undefined,
    accountPlan: plan?.pricingPlan || undefined,
    userId: user?.id || undefined,
    userType: APP_USER_TYPE,
    userPermission: isSuperUser ? APP_USER_PERMISSION_TYPE.SUPER_USER : APP_USER_PERMISSION_TYPE.REGULAR,
    sessionTrackingId,
    pageViewId: currentPageId,
    pageReferrerId: referrerPageId,
    productArea,
  });

  const analyticsTrackEvent = (trackEventPayload: TrackEventPayload, destinations?: RudderStackDestination[]) => {
    RudderstackAnalyticsAgent.track({
      ...generateBasicAnalyticPayload(),
      ...trackEventPayload,
    },
    destinations,
    );
  };

  const analyticsPageEvent = (pageEventPayload: PageEventPayload, destinations?: RudderStackDestination[]) => {
    RudderstackAnalyticsAgent.page({
      ...pageEventPayload,
      ...generateBasicAnalyticPayload(),
    },
    destinations,
    );
  };

  return (
    <AnalyticsContext.Provider
      value={ {
        analyticsTrackEvent,
        analyticsPageEvent,
      } }
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

AnalyticsProvider.defaultProps = {
  productArea: undefined,
};
