import styled from 'styled-components';
import PerfectTextEditor from '../../../../../../components/Common/perfect-text-editor/perfect-text-editor.component';
import { BodyEditorContainer } from '../../../../../../components/Common/perfect-text-editor/perfect-text-editor.styled';

export const Editor = styled(PerfectTextEditor)`
  height: 240px;
  & ${BodyEditorContainer} {
    & .DraftEditor-editorContainer {
      max-height: 95px;
    }
  }
`;
