import { GetQuery } from '../../@types/rtk-query';
import { QueryMethods } from '../../enums/query-methods.enums';
import { COMPANY_ID_PATH_PARAM, ROUTES } from './company.consts';
import { GetCompanyByIdQueryArguments } from './dto/query-arguments/get-company-by-id.query-arguments';

export const getCompanyByIdQuery = ({ companyId }: GetCompanyByIdQueryArguments): GetQuery => ({
  url: ROUTES.COMPANY_BY_ID
    .replace(COMPANY_ID_PATH_PARAM, companyId),
  method: QueryMethods.Get,
});
