import { matchQuery } from '../match.toolkit-api';
import { PerformMatchEngagementQueryArguments } from '../dto/query-arguments/perform-match-engagement.query-arguments';
import { ApiError } from '../../../../../consts/api';
import { MatchEngagementResponse } from '../dto/response/match-engagement.response';

type UsePerformMatchEngagementResponseType = [
  (queryArguments: PerformMatchEngagementQueryArguments) => void,
  AppSelectorType<MatchEngagementResponse | undefined, ApiError>
];

export const useSendMatchEngageActionMutation = (): UsePerformMatchEngagementResponseType => {
  const [
    performMatchEngagement,
    {
      data, isLoading, isError, error, isSuccess,
    }] = matchQuery.usePerformMatchEngagementMutation();

  const meta = {
    isLoading, isError, error: error as ApiError, isSuccess, data,
  };

  return [
    (queryArguments: PerformMatchEngagementQueryArguments) => performMatchEngagement(queryArguments).unwrap(),
    { ...meta },
  ];
};
