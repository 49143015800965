import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { THEME } from '../../../../themes';
import { Subtitle1Font } from '../../../../themes/fonts';

export const SomethingElseWrapper = styled.div`
  margin-top: 74px;
  text-align: center;
`;

export const SomethingElseReasonButton = styled(TextField)`
  ${Subtitle1Font};
  font-weight: 300;
  text-align: left;
  width: 100%;

  .MuiOutlinedInput-root {
    maxHeight: 100px;
    padding: 10px;
  }

  .MuiInput-root {
    padding-right: 0;
    padding-bottom: ${THEME.SPACING.TINY};

    :before {
      border-bottom: 1px solid ${THEME.COLORS_RGB.GHOST_GRAY};
    }

    :after {
      border-bottom: 2px solid ${THEME.COLORS.ACCENT};
    }

    input {
      padding: ${THEME.SPACING.TINY} ${THEME.SPACING.MICRO};
      min-width: 200px;
    }

    :hover {
      :before {
        border-bottom: 1px solid ${THEME.COLORS_RGB.GHOST_GRAY} !important;
      }
    }
  }

  textArea {

    ::-webkit-scrollbar {
      border-radius: 100px;
    }

    ::-webkit-scrollbar-thumb {
      border: 6px solid transparent;
      border-radius: 100px;
      background-color: rgba(236, 9, 130, ${THEME.OPACITY.LOW});
      background-clip: content-box;
    }

    ::placeholder {
      opacity: ${THEME.OPACITY.LOW};
    }
  }
`;

export const ClosePositionButtonWrapper = styled.div`
  margin-top: ${THEME.SPACING.MEDIUM};
  text-align: center;
  position: absolute;
  bottom: ${THEME.SPACING.LARGE};
  left: calc(50% - 102px)
`;

export const ButtonIconStyle = {
  verticalAlign: 'middle',
  marginRight: THEME.SPACING.TINY,
  color: THEME.COLORS.PURE_WHITE,
};
