import log from 'loglevel';
import { NOT_FOUND_ERROR_CODES } from '../../consts';
import { useAppState } from '../../store/selectors';
import { NotFoundErrorPage } from '../Common/Errors/NotFoundErrorPage';
import { FatalErrorPage } from '../Common/Errors/FatalErrorPage';
import usePageView from '../../hooks/usePageView';
import OnboardingRouter from '../../modules/onboarding/onboarding.router';
import ApplicationRouter from '../../modules/application/application.router';
import { useUserWatcher } from '../../hooks/user-watcher.hooks';
import { useBroadcastChannelWatcher } from '../../hooks/broadcast-watcher.hooks';
import { useActivityWatcher } from '../../hooks/activity-watcher.hooks';
import { usePerfectAuthentication } from '../../hooks/perfect-authentication.hook';
import { SubDomainType } from '../../store/slices/app-state/app-state.enums';
import { FATAL_ERRORS_CODE } from '../../store/slices/app-state/app-state.consts';
import PlanExpiredRouter from '../../modules/trial-ended/plan-expired.router';
import { useIsSuperUser } from '../../hooks/superuser';
import AuthRouter from './auth.router';
import CompleteUserActivationRouter from './complete-user-activation.route';

const logger = log.getLogger('APP_ROUTER');

const MainRouter = (): JSX.Element | null => {
  const {
    subDomainType,
    fatalErrorCode,
    fatalErrorMessage,
    fatalErrorInfo,
  } = useAppState();
  usePageView();
  const {
    isAuthenticated, shouldCompleteUserActivationFlow, isOnboarding, requiredApisLoading, isPlanExpired,
  } = usePerfectAuthentication();
  const isSuperUser = useIsSuperUser();
  useUserWatcher();
  useBroadcastChannelWatcher();
  useActivityWatcher({ disableTrackPageVisits: isSuperUser || !isAuthenticated || isOnboarding });

  if (fatalErrorCode !== FATAL_ERRORS_CODE.NONE) {
    logger.error(
      `${fatalErrorMessage || 'Fatal error occurred (without a specific message)'}.`,
      'Showing a fatal page',
      {
        appErrorCode: fatalErrorCode,
        errorData: fatalErrorInfo,
      },
    );

    return <FatalErrorPage errorCode={ fatalErrorCode } />;
  }

  if ((subDomainType === SubDomainType.None)) {
    logger.warn('Missing sub-domain! showing an error page', {
      pageUrl: window.location.href,
    });

    return <NotFoundErrorPage errorCode={ NOT_FOUND_ERROR_CODES.MISSING_SUB_DOMAIN } />;
  }

  if (isPlanExpired) {
    return <PlanExpiredRouter />;
  }

  if (isOnboarding) {
    logger.debug('Found Onboarding sub-domain. showing Onboarding screen');

    return (
      <OnboardingRouter requiredApisLoading={ requiredApisLoading } />
    );
  }

  if (shouldCompleteUserActivationFlow) {
    return <CompleteUserActivationRouter />;
  }

  if (isAuthenticated) {
    return <ApplicationRouter />;
  }

  if (!requiredApisLoading) {
    return <AuthRouter />;
  }

  return null;
};

export default MainRouter;
