import { useTranslation } from 'react-i18next';
import CampaignPageAutoModeSwitchOption, {
  AUTO_MODE_ID,
  CUSTOM_MODE_ID,
} from '../campaign-auto-mode-switch-option/campaign-auto-mode-switch-option.component';
import STRING_KEYS from '../../../../../language/keys';
import SelectItem from '../../../../../components/Common/select/select-item/select-item.component';
import { SequenceScheduleModeEnum } from '../../../../../store/rtk-query/apis/sequence/enums/sequence-schedule-days-mode.enum';
import { SequenceScheduleDaysModeEnum } from '../../../../../store/rtk-query/apis/sequence/enums/sequence-schedule-mode.enum';
import { OutreachSequencePreferencesFormikKeys } from '../../../pages/campaign/campaign.enums';
import TimePicker from '../../../../../components/time-picker/time-picker.component';
import {
  ActionComponent,
  Container,
  DayTypeSelect,
  Icon,
} from './campaign-auto-mode-send-time-section.styled';
import { CampaignPageAutoModeSendTimeSectionProps } from './campaign-auto-mode-send-time-section';
import { DEFAULT_SCHEDULE_DAYS_TYPE, DEFAULT_SCHEDULE_TIME } from './campaign-auto-mode-send-time-section.consts';

const modeEnumToId = (mode: SequenceScheduleModeEnum): string => {
  if (mode === SequenceScheduleModeEnum.Auto) {
    return AUTO_MODE_ID;
  }

  return CUSTOM_MODE_ID;
};

const modeIdToEnum = (modeId: string): SequenceScheduleModeEnum => {
  if (modeId === AUTO_MODE_ID) {
    return SequenceScheduleModeEnum.Auto;
  }

  return SequenceScheduleModeEnum.Custom;
};

const CampaignPageAutoModeSendTimeSection = ({
  mode,
  daysType,
  sendTime,
  changeHandler,
}: CampaignPageAutoModeSendTimeSectionProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const renderDayTypeSelect = () => {
    return (
      <DayTypeSelect
        placeholder={ translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.SEND_TIME.DAY_TYPE_SELECT_PLACEHOLDER) }
        value={ daysType }
        onChangeHandler={ (selectedValue) => changeHandler(OutreachSequencePreferencesFormikKeys.ScheduleDaysType, selectedValue) }
      >
        <SelectItem value={ SequenceScheduleDaysModeEnum.BusinessDays }>
          <div>{ translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.SEND_TIME.DAY_TYPE.BUSINESS_DAY) }</div>
        </SelectItem>
        <SelectItem value={ SequenceScheduleDaysModeEnum.AnyDay }>
          <div>{ translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.SEND_TIME.DAY_TYPE.ANY_DAY) }</div>
        </SelectItem>
      </DayTypeSelect>
    );
  };

  const renderActionComponent = () => {
    return (
      <ActionComponent>
        { renderDayTypeSelect() }
        <TimePicker
          time={ sendTime }
          changeHandler={ (time: string) => changeHandler(OutreachSequencePreferencesFormikKeys.ScheduleTime, time) }
        />
      </ActionComponent>
    );
  };

  const onModeChange = (selectedValue: string) => {
    changeHandler(OutreachSequencePreferencesFormikKeys.ScheduleMode, modeIdToEnum(selectedValue));
    const isAutoMode = selectedValue === AUTO_MODE_ID;
    changeHandler(OutreachSequencePreferencesFormikKeys.ScheduleTime, isAutoMode ? undefined : DEFAULT_SCHEDULE_TIME);
    changeHandler(OutreachSequencePreferencesFormikKeys.ScheduleDaysType, isAutoMode ? undefined : DEFAULT_SCHEDULE_DAYS_TYPE);
  };

  return (
    <Container>
      <CampaignPageAutoModeSwitchOption
        title={ translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.SEND_TIME.TITLE) }
        description={ translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.SEND_TIME.DESCRIPTION) }
        infoText={ translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.SEND_TIME.INFO_TEXT) }
        Icon={ Icon }
        selectedValue={ modeEnumToId(mode) }
        ActionComponent={ renderActionComponent() }
        changeHandler={ onModeChange }
      />
    </Container>
  );
};

export default CampaignPageAutoModeSendTimeSection;
