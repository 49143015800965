import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { SourceList } from '../../explainability-info-menu.styled';
import STRING_KEYS from '../../../../../../language/keys';
import { wrapSubstringWithHtmlElement } from '../../../../../../utils';
import { ExplainabilityOriginSourceEnum } from '../../../../../../enums/explainability-origin-source.enum';
import BaseSection from '../base-section/base-section.component';
import { SelfEvidenceSectionProps } from './self-evidence-section';
import { Icon, QuoteText } from './self-evidence-section.styled';

const SelfEvidenceSection = ({ origins }: SelfEvidenceSectionProps): JSX.Element | null => {
  const { t: translate } = useTranslation();

  const quotes = useMemo(() => (
    origins?.filter((origin) => (
      origin?.paragraph &&
      origin?.source &&
      [ExplainabilityOriginSourceEnum.SpecificRoleText, ExplainabilityOriginSourceEnum.GeneralProfileText].includes(origin.source)
    )).map((origin) => (
      <QuoteText key={ `${origin.quote}` }>{ parse(wrapSubstringWithHtmlElement(origin.paragraph as string, origin.quote as string, 'strong')) }</QuoteText>
    ))
  ), [origins]);

  if (!quotes?.length) {
    return null;
  }

  return (
    <BaseSection
      title={ translate(STRING_KEYS.MATCH_CARD.EXPLAINABILITY.INFO_MENU.SELF_EVIDENCE_SECTION_TITLE) }
      Icon={ <Icon /> }
    >
      <SourceList>
        { quotes }
      </SourceList>
    </BaseSection>
  );
};

export default SelfEvidenceSection;
